import React, { useState } from "react";
import { parseISO, format, formatRelative } from 'date-fns'
import enUS from 'date-fns/locale/en-US';

export function useForceUpdate(){
  const [value, set] = useState(true); //boolean state
  return () => set(!value); // toggle the state to force render
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function stripEmojis(str) {
  return str
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    )
    .replace(/\s+/g, ' ')
    .trim();
}

export function getIdInfoFromUrl(url) {
  const paths = (url || "").split("/");
  let id = paths.length > 0 ? parseInt(paths[paths.length-1]) : null;

  if (!Number.isInteger(id))
    id = null;

  let info = {
    id,
    type: null
  }

  if (id) {
    if (paths.includes("order")) {
      info.type = "order";
    }
    else if (paths.includes("sign") || paths.includes("sign2")) {
      info.type = "sign";
    }
    else if (paths.includes("project") || paths.includes("projectboard")) {
      info.type = "project";
    }
    else if (paths.includes("workflowjob")) {
      info.type = "workflowjob";
    } 
    else if (paths.includes("job")) {
      info.type = "job";
    }
    else if (paths.includes("user")) {
      info.type = "user";
    }
  }

  return info;
}

export function removeNulls(values) {
  let data = {}

  for (const key in values) {
    const value = values[key]
    if (value != null && value != 'null')
      data[key] = value;
  }

  return data
}

export function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return '';//value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength == 0) return value;
  
  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}
export function parsePhoneNumber(value) {
  if (!value) return '';

  return value.replace(/[^\d]/g, '')
}
export function isValidPhoneNumber(value) {
  if (!value) return false;
  
  return parsePhoneNumber(value).length == 10;
}

export function formatFileSize(bytes, si) {
  if (!bytes) return "";
  
  var thresh = si ? 1000 : 1024;
  if(Math.abs(bytes) < thresh) {
      return bytes + ' B';
  }
  var units = si
      ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
      : ['KB','MB','GB','TB','PB','EiB','ZB','YB'];
  var u = -1;
  do {
      bytes /= thresh;
      ++u;
  } while(Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1)+' '+units[u];
}

export function areObjectsEqual(obj1, obj2) {
  // TODO: Not the best check, doesn't handle object with keys in different order
  return JSON.stringify(obj1) == JSON.stringify(obj2);
}

export function isDateWeekend(value, options={}) {
  if (value) {
    const dt = new Date(Date.parse(value));
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
    const dow = dtDateOnly.getDay();

    return dow == 0 || dow == 6;
  }
  else 
    return false;
}

export function convertDateWithoutTime(value) {
  if (value) {
    const dt = new Date(Date.parse(value));
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
    
    return dtDateOnly;
  }
}

export function convertDateStringWithoutTime(value) {
  const date = new Date();
  const dateParts = value.split("T")[0].split("-");

  date.setFullYear(dateParts[0])
  date.setMonth(dateParts[1] - 1)
  date.setDate(dateParts[2]) 
  
  return date;
}

export function formatDate(value, fmt="MM/dd/yyyy", options={}) {
  if (value) {
    const dt = new Date(Date.parse(value));
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
    
    return format(options.local ? parseISO(value) : dtDateOnly, fmt);
  }
  else 
    return "";
}
export function formatDateForInput(value) {
  return formatDate(value, "yyyy-MM-dd");
}

export function formatDateTime(value, fmt="MM/dd/yyyy @ h:mm a") {
  if (value instanceof Date)
    return format(value, fmt);
  else if (value)
    return format(parseISO(value), fmt);
  else 
    return "";
}
export function formatTime(value, fmt="h:mm a") {
  if (value)
    return format(parseISO(value), fmt);
  else 
    return "";
}

export function isValidEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

export function formateRelativeDate(value) {
  const formatRelativeLocale = {
    lastWeek: "'Last' eeee",
    yesterday: "'Yesterday'",
    today: "'Today'",
    tomorrow: "'Tomorrow'",
    nextWeek: "'Next' eeee",
    other: 'MM/dd/yyyy',
  };
  
  const locale = {
    ...enUS,
    formatRelative: (token) => formatRelativeLocale[token],
  };

  return formatRelative(Date.parse(value), new Date(), { locale });
}

export function formatPriceInK(num) {
  return "$" + Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatPrice(number, decPlaces, decSep, thouSep) {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
  decSep = typeof decSep === "undefined" ? "." : decSep;
  thouSep = typeof thouSep === "undefined" ? "," : thouSep;

  var sign = number < 0 ? "-" : "";
  var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
  var j = 0;//(j = i.length) > 3 ? j % 3 : 0;
  
  if (i.length > 3) {
    j = 3;
    if (decPlaces)
      j -= decPlaces;
  }

  /*
  return "$" + sign +
    (j ? i.substr(0, j) + thouSep : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
  */
 return "$" + sign + numberWithCommas(i + (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : ""))
}

export function formatPriceList(priceList, sep=", ") {
  let prices = "";

  if (priceList && priceList.length > 0) {
    for (const price of priceList.split(",")) {
      prices = prices + (prices.length ? sep:"") + formatPrice(parseFloat(price));
    }
  }

  console.log("formatPriceList", priceList, prices)
  return prices;
}

export function restrictLetters(event) {
  const chars = " &',-.0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  const regex = new RegExp("^[a-zA-Z0-9]+$");
  const keyCode = !event.charCode ? event.which : event.charCode;
  const key = String.fromCharCode(keyCode);

  //console.log("restrictLetters", keyCode, key)
  if (keyCode != 13 && !regex.test(key)) {
      let valid = false;

      for (var i = 0; i < chars.length; i++) {
        const char = chars.charAt(i);

        if (char == key) {
          valid = true;
          break;
        }
      }

      if (!valid) {
        event.preventDefault();
        return false;
      }
  }
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export function linkifyText(text, target="_top") {
  // http://, https://, ftp://
  var urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;

  // www. sans http:// or https://
  var pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

  // Email addresses
  var emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;

  return text
      .replace(urlPattern, '<a href="$&" target="'+target+'">$&</a>')
      .replace(pseudoUrlPattern, '$1<a href="http://$2" target="'+target+'">$2</a>')
      .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>');
}

export function sanitizeFilename(filename) {
  //let sanitized = sanitize(filename.toLowerCase());
  //return sanitized.replace(" ", "-");
  return filename.replace(/[^a-z0-9.]/gi, '_').toLowerCase();
}

export function sanitizePartIconFilename(partName) {
  //let sanitized = sanitize(filename.toLowerCase());
  //return sanitized.replace(" ", "-");
  return partName.toLowerCase().replace("#","");
}

export function isError(obj) {
    return obj != null && obj.constructor.name === "Error"
}
export function isObject(obj) {
    return obj != null && obj.constructor.name === "Object"
}
export function isString(x) {
  return Object.prototype.toString.call(x) === "[object String]"
}

export const pick = function(sourceObject, keys) {
  const newObject = {};
  keys.forEach(key => { newObject[key] = sourceObject[key]; });
  return newObject;
};
