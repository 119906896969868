import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import styled from "styled-components"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlug, faSpinner } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import BooleanField from "../components/fields/field-boolean"
import Loading from "../components/loading"

import { postData } from "../common/services/server"

const Parts = styled.div`
    > div {
        border: 1px solid #ccc;
        padding: 5px;
        margin-bottom: 2px;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;

        &.current {
            background-color: #eee;

            button {
                display: none;
            }
        }
    }
`

function PowerDrawer(props) {
  const [isProcessing, setIsProcessing] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [powerParts, setPowerParts] = useState([]);
  const [selectedPart, setSelectedPart] = useState({id: 0});

  useEffect(() => {
      setIsLoading(true);

      postData("parts/data", {type:"power"}, 
        function(response) {
          setPowerParts(response);
        },
        function(error) {
          alert("An loading power options.");
        },
        function() {
            setIsLoading(false);
        }
      );

      if (props.shape) {
        setSelectedPart(props.shape.parts.find(p => p.type_name=="power"));
      }
  }, [props.group]);

  function onSave(part) {
      props.onSaved(part)
  }

  return (
      <>
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPlug} />
            &nbsp;
            Power
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {isLoading ? 
                <Loading /> 
            : 
            <>
                <Parts>
                {powerParts.map((part, index) => (
                    <div key={index} className={selectedPart && part.id == selectedPart.part_id ? "current":""}>
                        {part.title}

                        <Button onClick={() => onSave(part)} size="sm" variant="outline-primary">
                            <FontAwesomeIcon icon={isProcessing && isProcessing.id == part.id ? faSpinner:faCheck} spin={isProcessing && isProcessing.id == part.id} />{' '}
                            Select Power
                        </Button>
                    </div>
                ))}
                </Parts>
            </>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
      </>
    )
}

export default PowerDrawer;