import React, { useState } from "react";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { useParams, useHistory } from "react-router-dom";

import Content from "../components/content"
import PageHeader from "../components/page-header";
import NotesPanel from "./panels/panel-notes"

import { useLocalStorage } from "../hooks/useLocalStorage"
import { Permissions } from "../common/services/auth";
import PageLayout from "../components/page-layout";

function NotesPage(props) {
    const history = useHistory();
    let { tab } = useParams();

    if (!tab) tab = "all";

    const [counter, setCounter] = useState(0); // force refresh

    return (
        <PageLayout>
            <PageHeader 
                title="Note Manager"
                help="notes"
                tab={tab}
                tabUrl={"/notes/{tab}"}
                tabs={[
                    {name:"all", title:"All Notes"},
                    {name:"pinned", title:"Pinned", icon:faThumbtack},
                ]}
                onTab={(tab) => {
                    history.push("/notes/" + tab);
                }}
                onNewNote={(note) => {
                    console.log("onNewNote", note)
                    setCounter(counter+1);
                }}
            />
            <Content permission={Permissions.Notes}>
                <NotesPanel
                    filters={{
                        pinned_ind: tab == "pinned" ? 1 : -1,
                        counter: counter
                    }}
                    haveTabs={true}
                />
            </Content>
        </PageLayout>
    );
}

export default NotesPage;