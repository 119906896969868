import React from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import FieldLabel from "./label"
import HelpText from "./help-text"

import UserSelect from "./user-select";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

function FieldUser(props) {
    let groupStyle = props.groupStyle || {};

    if (props.layout == "horizontal") {
        groupStyle.display = "grid";
        groupStyle.gridTemplateColumns = "125px auto";
        groupStyle.alignItems = "center";
    }
        
    return (
        <Form.Group style={groupStyle}>
            {props.label && 
                <FieldLabel 
                    required={props.required} 
                    bold={props.labelBold}
                    style={props.labelStyle || {marginBottom: "0px"}}
                >
                    {props.label}
                </FieldLabel>
            }
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                    </InputGroup.Text>
                </InputGroup.Prepend>

                <UserSelect
                    name={props.name} 
                    required={props.required}
                    default={props.value} 
                    onChange={(user) => { props.onChange({
                            target: {
                                name: props.name,
                                value: user
                            }
                        }); 
                    }}
                />
            </InputGroup>
            {props.instructions && <HelpText>{props.instructions}</HelpText>}
        </Form.Group>
    )
}

export default FieldUser