import React, { useState, useEffect, useContext } from "react";
import produce from "immer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash, faCommentDots, faListAlt, faClone, faEnvelope, faSms } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    BooleanCell,
    CurrencyCell, 
    TextCell,
    DateTimeCell, 
    ButtonCell,
    LinkCell,
    GoogleImageCell, 
    UserCell,
    FilterHeaderCell,
    SortHeaderCell,    
    SortTypes,
    EditActionsCell 
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Loading from "../../components/loading"
import Toolbar from "../../components/toolbar"
import Paginator from "../../components/paginator"

import EmailDrawer from "../../drawers/drawer-email";
import Button from "../../components/buttons/button";

import { postData, postExternalData } from "../../common/services/server"
import { formatPrice, removeNulls } from "../../helpers/utility"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

import settings from "../../settings"
import clsx from "clsx";
import Alert from "../../components/alert";

function SignsPanel(props) {
    const history = useHistory();
    const windowDimensions = useWindowDimensions();

    const [signs, setSigns] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [sort, setSort] = useState({column: "added_date", dir: SortTypes.DESC});
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({page:1,pages:1,total:0});
    const [isLoading, setIsLoading] = useState(false);
    const [busyRow, setBusyRow] = useState(null);
    const [filters, setFilters] = useState({designNo: "", added_date: props.filters ? props.filters.date : null });
    const [selected, setSelected] = useState(null);

    const haveToolbar = props.buttons && props.buttons.length > 0;

    useBodyClass(`noscroll`);

    function loadSigns(options) {
        setIsLoading(true);

        postData("signs/data", removeNulls({ 
            ...filters,
            sort: sort.column,
            sortdir: sort.dir,
            page: page,
            perPage: 100,
            ...props.filters,
            ...options
        }),
            function(result) {
                setSigns(result.signs);
                setPagination(result.pagination)
            },
            function(error) {
                alert("Error loading signs")
            },
            function() {
                setIsLoading(false);
            }
        );
    }

    useEffect(
        () => {
            loadSigns();
        }, 
        [props.filters, page, sort]
    );

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }

    function onSign(sign) {
        if (sign.id)
            history.push("/signs/sign/" + sign.id);
    }
    function onVersions(sign) {
        if (sign.id)
            history.push("/signs/sign/history/" + sign.id);
    }
    function onOrder(sign) {
        if (sign.order_id)
            history.push("/orders/order/" + sign.order_id);
    }
    function onAction(action, sign) {
        console.log("action", action, sign)
    
        if (action == "edit")
            window.open(settings.SIGNEDIT_URL + sign.uid + "&template=-1");
        else if (action == "clone") {
            setBusyRow(sign);

            postData("mutate/signs/sign/clone", { 
                sign_id: sign.id
            },
                function(result) {
                    setSigns(produce(draft => {
                        draft.push(result);
                    }));
                },
                function(error) {
                    alert("Error cloning sign")
                },
                function() {
                    setBusyRow(null);
                }
            );
        }
        else if (action == "details")
            window.open(settings.SIGNDETAILS_URL + sign.uid);
        else if (action == "email") {
            setSelected({mode:"email", sign, type:"email"})
        }
        else if (action == "sms") {
            setSelected({mode:"email", sign, type:"sms"})
        }
        else if (action == "delete") {
            setBusyRow(sign);

            postData("signs/delete", { 
                sign_id: sign.id
            },
                function(result) {
                    setSigns(produce(draft => {
                        draft.splice(draft.findIndex(s => s.id == sign.id), 1);
                    }));
                },
                function(error) {
                    alert("Error deleting sign")
                },
                function() {
                    setBusyRow(null);
                }
            );
        }
    }

    function onFilterChange(filter, value) {
        setFilters(produce(draft => {
            draft[filter] = value
        })); 
    }

    function onFilter() {
        loadSigns({page:1})
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
            <>
                {haveToolbar && 
                    <Toolbar buttons={props.buttons} onButton={props.onButton} />
                }
                <Table
                    rowHeight={50}
                    rowsCount={signs.length}
                    width={windowDimensions.width - dimensions.dataTableWidthOffset}
                    height={windowDimensions.height - dimensions.dataTableHeightOffset - (haveToolbar ? 40:0)}
                    headerHeight={70}
                    touchScrollEnabled={true}
                >
                    <Column
                        header={
                            <FilterHeaderCell filter={filters.designNo} onFilterChange={(value) => onFilterChange("designNo", value)} onFilter={onFilter} sort={sort} onSortChange={onSortChange}>
                                 Design #
                            </FilterHeaderCell>
                        }
                        columnKey="id"
                        fixed={!isMobile}
                        cell={({rowIndex, ...props}) => {
                            const signId = signs[rowIndex]["id"];

                            return (
                                <Cell {...props} className="center">
                                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                        <a href={"#/signs/sign/" + signId} className="text-blue-500" onClick={(e) => {
                                            e.preventDefault();
                                            onSign(signs[rowIndex])
                                        }}>
                                            {signId}
                                            {signs[rowIndex]["custom_ind"] == 1 && 
                                                <span className="text-xs text-red-500">*</span>
                                            }
                                        </a>
                                    </div>
                                </Cell>
                            )
                        }}
                        width={125}
                    />

                    <Column
                        header={
                            <FilterHeaderCell filter={filters.text} onFilterChange={(value) => onFilterChange("text", value)} onFilter={onFilter}>
                                 Preview
                            </FilterHeaderCell>
                        }
                        columnKey="preview_url"
                        cell={<GoogleImageCell data={signs} maxwidth={125} maxheight={35} />}
                        width={150}
                    />
                    {props.filters.user_id ?
                        <Column
                            header={<Cell>Order #</Cell>}
                            columnKey="order_id"
                            cell={({rowIndex, ...props}) => (
                                <Cell>
                                {(signs[rowIndex].order_ids || "").split(",").map((order_id, index) => {
                                    return (
                                        <>
                                            {index > 0 && <span>, </span>}
                                            <a href={"#/orders/order/" + order_id} target="_blank">{order_id}</a>
                                        </>
                                    )
                                })}
                                </Cell>
                            )}                            
                            width={125}
                        />
                    :
                        <Column
                            header={
                                <FilterHeaderCell filter={filters.user} onFilterChange={(value) => onFilterChange("user", value)} onFilter={onFilter}>
                                    User
                                </FilterHeaderCell>
                            }                        
                            columnKey="user_name"
                            cell={({rowIndex, ...props}) => (
                                <UserCell 
                                    userId={signs[rowIndex].user_id}
                                    userName={signs[rowIndex].user_name}
                                    userEmail={signs[rowIndex].user_email}
                                    onUser={() => history.push("/users/user/" + signs[rowIndex].user_id)}
                                />
                            )}
                            width={225}
                        />
                    }
                    <Column
                        header={
                            <FilterHeaderCell filter={filters.added_date} onFilterChange={(value) => onFilterChange("added_date", value)} onFilter={onFilter} sort={sort} onSortChange={onSortChange}>
                                Date Created
                            </FilterHeaderCell>
                        }
                        columnKey="added_date_local"
                        cell={<DateTimeCell data={signs} local />}
                        width={155}
                    />
                    <Column
                        header={
                            <FilterHeaderCell filter={filters.added_date} onFilterChange={(value) => onFilterChange("added_location", value)} onFilter={onFilter} sort={sort} onSortChange={onSortChange}>
                                Created From
                            </FilterHeaderCell>
                        }
                        columnKey="added_location"
                        cell={<TextCell data={signs} />}
                        width={155}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Cost
                            </SortHeaderCell>
                        }
                        columnKey="estimate"
                        cell={({rowIndex, ...props}) => (
                            <Cell>
                                <div className={clsx(signs[rowIndex].estimate_expired_ind==1  && "line-through")}>
                                    {formatPrice(signs[rowIndex].estimate)}
                                </div>
                                {signs[rowIndex].estimate_expired_ind==1 &&
                                    <Alert size="tiny" variant="danger">
                                        EXPIRED
                                    </Alert>
                                }
                            </Cell>
                        )}                         
                        width={100}
                    />
                    <Column
                        header={<Cell>Versions</Cell>}
                        columnKey="version"
                        cell={<ButtonCell data={signs} onClick={(sign) => onVersions(sign)} />}
                        align="center"
                        width={80}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Custom
                            </SortHeaderCell>
                        }
                        columnKey="custom_ind"
                        cell={<BooleanCell data={signs} />}
                        align="center"
                        width={80}
                    />
                    <Column
                        header={<Cell>Device</Cell>}
                        cell={({rowIndex, ...props}) => {
                            const sign = signs[rowIndex];

                            return (
                                <Cell {...props}>
                                    {sign.device && 
                                        <>
                                            <div>{sign.device} ({sign.os})</div>
                                            <div>{sign.browser}</div>
                                        </>
                                    }
                                </Cell>                                
                            )
                        }}
                        width={175}
                    />
                    <Column
                        header={<Cell>Actions</Cell>}
                        cell={
                            <EditActionsCell 
                                data={signs} 
                                actions={[
                                    {action:"details", title:"Details", variant:"success", icon:faListAlt},
                                    {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, permission:Permissions.Signs, permissionAction:Actions.Edit},
                                    {action:"email", title:"Email", variant:"secondary", icon:faEnvelope, permission:Permissions.Emails, permissionAction:Actions.Send},
                                    {action:"sms", title:"Text", variant:"primary", icon:faSms, permission:Permissions.Emails, permissionAction:Actions.Send},
                                    {action:"clone", title:"Clone", variant:"warning", icon:faClone, permission:Permissions.Signs, permissionAction:Actions.Clone},
                                    {action:"delete", title:"Delete", variant:"danger", icon:faTrash, permission:Permissions.Signs, permissionAction:Actions.Delete},
                                ]}
                                busy={busyRow}
                                onClick={onAction} 
                            />
                        }
                        align="center"
                        width={225}
                    />
                </Table>
                <Paginator
                    {...pagination}
                    item="Sign"
                    items="Signs"
                    onPage={(page) => setPage(page)}
                />
                { selected && selected.mode == "email" && 
                    <EmailDrawer 
                        bodyUrl={"emails/template?template=Send%20Sign&sign_id=" + selected.sign.id + "&type=" + selected.type}
                        email={{
                            template: "simple",
                            type: selected.type || "email",
                            to: selected.type == "sms" ? selected.sign.user_phoneno : selected.sign.user_email,
                            subject: "Sign Design #" + selected.sign.id,
                            sign_id: selected.sign.id,
                            sign_title: "#" + selected.sign.id,                            
                            body: "",                            
                        }}
                        show={true}
                        onSaved={(email) => {
                            setSelected(null);
                        }}
                        onHide={ () => setSelected(null) } 
                    /> 
                }
            </>
            }
        </>
    );
}

export default SignsPanel;