import React, { useState } from "react"
import styled from "styled-components"
import { addDays, addBusinessDays, differenceInCalendarDays, isWeekend, parseISO, format } from 'date-fns'
import Alert from 'react-bootstrap/Alert';
import { isTablet, isMobile } from "react-device-detect"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

import Button from './buttons/button';
import JobTaskDrawer from "../drawers/drawer-jobtask"
import JobShipDateDrawer from "../drawers/drawer-jobshipdate"

import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import JobTaskList from "./job-tasklist";

const Schedule = styled.table`
 border: ${isMobile ? 0:1}px solid #ccc;
 border-radius: 5px;
 padding: 5px;
 font-size: 12px;
 width: ${props => isTablet && !props.expanded ? "auto":"100%"};

 tbody tr {
     display: ${isMobile ? "block":"table-row"};
 }

 th {
     background-color: #eee;
     padding: 4px 8px;
     border: 1px solid #ccc;
     text-align: center;
     white-space: nowrap;
 }
 td {
    display: ${isMobile ? "block":"table-cell"};
    padding: 5px;
    border: 1px solid #ccc;
    vertical-align: top;
    margin-bottom: ${isMobile ? 2:0}px;

    h3 {
        text-align: center;
        font-size: 16px;
    }
    h4 {
        font-size: 16px;
        background-color: #eee;
        text-align: center;
        padding: 5px;
        margin-bottom: 8px;
    }
 }
 .weekend {
    background-color: #eee;
    color: #999;
    width: ${isTablet ? "10px":"auto"};
 }
 .empty {
    display: ${isTablet ? "none":"table-cell"};
 }
`

function JobSchedule({job, ...props}) {
    const [selected, setSelected] = useState(null);
    const [selectedDay, setSelectedDay] = useState("");
    const [canEdit, setCanEdit] = useState(userHasPermission(Permissions.Jobs, Actions.Edit));

    // const shipDate = parseISO(job.ship_date.split("T")[0]);
    // const startDate = addBusinessDays(shipDate, -(job.days-1));
    const firstTask = job.tasks[0];
    const lastTask = job.tasks[job.tasks.length-1];
    
    const shipDate = lastTask ? parseISO(lastTask.target_date.split("T")[0]) : null;
    const startDate = firstTask ? parseISO(firstTask.target_date.split("T")[0]) : null;

    const days = [];
    let day = 1;

    if (shipDate && startDate) {
        for (let i=0; i<=differenceInCalendarDays(shipDate, startDate); i++) {
            const dayDate = addDays(startDate, i);
            const weekend = isWeekend(dayDate);

            days.push({
                day: weekend ? 0 : day,
                date: dayDate,
                weekend
            });     
            
            if (!weekend) day++
        }
    }

    function onTask(task) {
        console.log("onTask",task)
        setSelected({mode:"Task", task});
    }

    return (
      <>
        {job.status_name == "hold" ? 
            <Alert variant="secondary">
                Job is Paused or on Hold
            </Alert>
        :
            <Schedule expanded={props.expanded}>
            {!isMobile && 
                <thead>
                    <tr>
                    {days.map((day,index) => {
                        const dayTasks = job.tasks.filter(t => t.target_day == day.day);

                        return (
                            <th key={index} className={day.weekend ? "weekend" : dayTasks.length==0 ? "empty" : ""}>
                                {day.day > 0 ? <>Day {day.day}</> : ' '}
                            </th>
                        )
                    })}
                    </tr>
                    <tr>
                    {days.map((day,index) => {
                        const dayTasks = job.tasks.filter(t => t.target_day == day.day);

                        return (
                            <th key={index} className={day.weekend ? "weekend" : dayTasks.length == 0 ? "empty":""}>
                                {(!isTablet || !day.weekend) ? format(day.date, day.weekend ? "EEE": isTablet ? "do" : "EEE MMM do") : ""}
                                {(index==days.length-1 && canEdit && !isTablet) && 
                                    <Button variant="outline-primary" size="icon" onClick={() => setSelected({mode:"ShipDate"})} style={{marginLeft: "8px"}}>
                                        <FontAwesomeIcon icon={faCalendar} />
                                    </Button>                        
                                }
                            </th>
                        )
                    })}
                    </tr>
                </thead>
            }
            <tbody>
                <tr>
                {days.map((day,index) => {
                    const disabled = day.day > 1 && job.order_status_name != "payment" && job.order_status_name != "paris" && job.id > 0;
                    const canEditTask = canEdit && !disabled;
                    const dayTasks = job.tasks.filter(t => t.target_day == day.day);

                    return (
                        <td key={index} className={day.weekend ? "weekend" : dayTasks.length == 0 ? "empty" : ""} onClick={() => setSelectedDay("job"+job.id+"day"+day.day)}>
                            {isMobile && 
                                <h3>{day.day > 0 ? <>Day {day.day}</> : ' '}</h3>
                            }
                            {isMobile && 
                                <h4>{format(day.date, day.weekend ? "EEE":"EEE MMM do")}</h4>
                            }     

                            <JobTaskList 
                                expanded={!isTablet || props.expanded || selectedDay==("job"+job.id+"day"+day.day)}
                                tasks={dayTasks} 
                                users={props.users}
                                disabled={disabled}
                                onTask={(task) => canEditTask ? onTask(task) : null}
                                onJobChanged={props.onJobChange}
                                onTaskChanged={(task, taskChanges) => {
                                    console.log("job-schedule.onTaskChanged", task, taskChanges);

                                    let jobData = JSON.parse(JSON.stringify(job));

                                    let taskIdx = jobData.tasks.findIndex(t => t.id == task.id);

                                    if (taskIdx != -1) {
                                        for (const prop of Object.keys(taskChanges)) {
                                            console.log(prop, taskChanges[prop]);
                                            
                                            jobData.tasks[taskIdx][prop] = taskChanges[prop];
                                        }
                                    }

                                    if (props.onJobChanged)
                                        props.onJobChanged(jobData);
                                    else if (props.onJobChange)
                                        props.onJobChange(jobData);
                                }}                                
                            />
                        </td>
                    )
                })}
                </tr>
            </tbody>
            </Schedule>
        }
        { (selected && selected.mode == "Task") && 
            <JobTaskDrawer 
                job={job}
                task={selected.task} 
                show={true}
                onSaved={(job) => {
                    props.onJobChange(job);
                    setSelected(null);
                }}
                onHide={ () => setSelected(null) } 
            /> 
        }
        { (selected && selected.mode == "ShipDate") && 
            <JobShipDateDrawer
                show={true}
                job={job}
                onJobChange={(result) => {
                    props.onJobChange(result);
                    setSelected(null);
                }}
                onHide={ () => setSelected(null) } /> 
        }
      </>
    );
}

export default JobSchedule