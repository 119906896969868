import React, { useState, useRef } from "react";

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import TextAreaField from "../components/fields/field-textarea"
import FileField from "../components/fields/field-file"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData, uploadImage } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

function ApprovalRequestDrawer(props) {
  const formRef = useRef(null);

  const [processing, setProcessing] = useState("");
  const [formData, setFormData] = useState({
                notes: ""
            });
  const [validated, setValidated] = useState(false);

  const [users, setUsers] = useState(false);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setProcessing("send");

      const {file, thumbnailData, ...otherData} = formData;
      const data = {orderitem_id: props.item.id, ...otherData};

      postData("approvals/save", removeNulls(data), 
        function(response) {
          if (file) {
            uploadImage("approvals/upload?id=" + response.id, file, thumbnailData, null, 
              function(progress) {
                console.log("progress", progress)
              },
              function(response) {
                props.onSaved(response);
              },
              function(error) {
                alert("An error occured uploading the image.");
              },
              function() {
                setProcessing("");
              }
            );
          }
          else {
            props.onSaved(response);
            setProcessing("");
          }
        },
        function(error) {
          alert("An error occured saving the approval.");
          setProcessing("");
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    console.log("handleFormChange", event.target.name, event.target.value)
    let field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faEnvelope} />
            &nbsp;
            Request Approval
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
            <FileField 
                name="file" 
                label="Approval File" 
                instructions="Optional file that will be shown the customer for the approval."
                onFile={(file) => {
                  console.log("art file", file)
                  setFormData(produce(draft => {
                    draft.file = file;
                  }));
                }} 
                onThumbnail={(thumbnailData) => {
                  setFormData(produce(draft => {
                    draft.thumbnailData = thumbnailData;
                  }));
                }}
                onPreview={() => {

                }}
            />

            <TextAreaField 
                name="notes" 
                label="Comments" 
                labelBold={true}
                required
                value={formData.notes} 
                onChange={(e) => handleFormChange(e)} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={processing == "send"}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Approval" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ApprovalRequestDrawer;