import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faClipboardList, faClone } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import NumberField from "../components/fields/field-number"
import TextAreaField from "../components/fields/field-textarea"
import CategoryField from "../components/fields/field-category"
import SelectField from "../components/fields/field-select"
import ListField from "../components/fields/field-list"
import BooleanField from "../components/fields/field-boolean"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

function ProductDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(props.product);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    console.log("setting product data", props.product)
    if (props.product) {
      setFormData(props.product);
    }
  }, [props.product]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("products/save", removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the product.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();

    //console.log("handleFormChange", event.target.name, event.target.value)
    setFormData(produce(draft => {
      draft[event.target.name] = event.target.value;

      if (event.target.name == "category_id")
          draft["category"] = "";
      else if (event.target.name == "category")
          draft["category_id"] = 0;
    }));
  }

  const { product, clone } = props;

  const isPartsProduct = formData.category_id == 100183;

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={clone ? faClone : faClipboardList} />
            &nbsp;
            {clone && "Clone"} Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
            {clone && 
              <ListField 
                name="clone_product_id" 
                label="Existing Product" 
                value={formData.clone_product_id} 
                required
                list={props.products}
                onChange={(e) => handleFormChange(e)} 
              />            
            }

            <CategoryField 
                name="category_id" 
                label="Category" 
                type="product"
                required
                allowNew={true}
                value={formData.category_id} 
                onChange={(e) => handleFormChange(e)} />

            <TextField 
                name="title" 
                label="Title" 
                required 
                value={formData.title} 
                layout="horizontal"
                onChange={(e) => handleFormChange(e)} />

            {isPartsProduct ? 
              <>
                <TextField 
                  name="part_number" 
                  label="Part Number" 
                  value={formData.part_number} 
                  layout="horizontal"                 
                  onChange={(e) => handleFormChange(e)} />
                <TextField 
                  name="version_number" 
                  label="Part Version" 
                  value={formData.version_number} 
                  layout="horizontal"                  
                  onChange={(e) => handleFormChange(e)} />
              </>
            :
              <TextField 
                  name="short_title" 
                  label="Short Title" 
                  value={formData.short_title} 
                  layout="horizontal"                  
                  onChange={(e) => handleFormChange(e)} />
            }
            <TextField 
                name="mwo_title" 
                label="MWO Title" 
                maxlength={4}
                value={formData.mwo_title} 
                layout="horizontal"               
                onChange={(e) => handleFormChange(e)} />

            <TextAreaField 
                name="description" 
                label="Short Description" 
                rows={3}
                value={formData.description} 
                onChange={(e) => handleFormChange(e)} />

            {!isPartsProduct && 
              <>
                <TextAreaField 
                    name="fulldescription" 
                    label="Full Description" 
                    rows={3}
                    value={formData.fulldescription} 
                    onChange={(e) => handleFormChange(e)} />
                <TextAreaField 
                    name="includes" 
                    label="Includes Overview" 
                    instructions="Individual includes items are defined on the Content page."
                    rows={3}
                    value={formData.includes} 
                    onChange={(e) => handleFormChange(e)} />
                <BooleanField 
                    name="featured_ind" 
                    label="Featured?" 
                    layout="horizontal"
                    value={formData.featured_ind} 
                    
                    onChange={(e) => handleFormChange(e)} />
                <BooleanField 
                    name="pattern_ind" 
                    label="Install Pattern?" 
                    value={formData.pattern_ind} 
                    layout="horizontal"
                    onChange={(e) => handleFormChange(e)} />
              </>
            }

            { (props.product.bundle_ind == 0 || (props.product.status != "Hidden" && props.product.builder_type)) && 
              <TextField 
                  name="standard_sizes" 
                  label="Standard Size List" 
                  required
                  value={formData.standard_sizes} 
                  layout="horizontal"
                  onChange={(e) => handleFormChange(e)} />
            }
            { (props.product.bundle_ind == 0 || (props.product.status != "Hidden" && props.product.builder_type)) && 
              <NumberField 
                  name="default_size" 
                  label="Default Size" 
                  required
                  value={formData.default_size} 
                  layout="horizontal"
                  onChange={(e) => handleFormChange(e)} />
            }
            { (props.product.bundle_ind == 0 || (props.product.status != "Hidden" && props.product.builder_type)) && 
              <>
                <Form.Row>
                  <Col>
                    <NumberField 
                        name="min_size" 
                        label="Min Size" 
                        required
                        value={formData.min_size} 
                        layout="horizontal"
                        onChange={(e) => handleFormChange(e)} />
                  </Col>
                  <Col>
                    <NumberField 
                        name="max_size" 
                        label="Max Size" 
                        required
                        value={formData.max_size} 
                        layout="horizontal"
                        onChange={(e) => handleFormChange(e)} />
                  </Col>
                </Form.Row>
                {props.product &&
                  <Form.Row>
                    <Col>
                      <SelectField 
                        name="min_size_product_id" 
                        label="Smaller Product"                 
                        labelField="title"
                        value={formData.min_size_product_id} 
                        url={"products/data?bundle_ind=0&builder_type=" + props.product.builder_type +"&cabinet_ind=1"}
                        layout="horizontal"
                        onChange={(e) => handleFormChange(e)} />
                    </Col>
                    <Col>
                      <SelectField 
                        name="max_size_product_id" 
                        label="Larger Product"                 
                        labelField="title"
                        value={formData.max_size_product_id} 
                        url={"products/data?bundle_ind=0&builder_type=" + props.product.builder_type +"&cabinet_ind=1"}
                        layout="horizontal"
                        onChange={(e) => handleFormChange(e)} />
                    </Col>                  
                  </Form.Row>
                }
              </>
            }
            
            { (props.product.bundle_ind == 1 || props.product.status == "Active") && 
              <>
                <TextField 
                    name="install_video_code" 
                    label="Installation Video Code" 
                    value={formData.install_video_code} 
                    instructions="Not the entire url.  Just the video code/id is needed: https://youtu.be/<strong>gzBi4yVcetg</strong>&rel=0"
                    layout="horizontal"
                    onChange={(e) => handleFormChange(e)} />
                <TextField 
                    name="shipping_video_code" 
                    label="Shipping Video Code" 
                    value={formData.shipping_video_code} 
                    instructions="Not the entire url.  Just the video code/id is needed: https://youtu.be/<strong>gzBi4yVcetg</strong>&rel=0"
                    layout="horizontal"
                    onChange={(e) => handleFormChange(e)} />

              </>
            }

            {isPartsProduct && 
              <>
                <ListField 
                  name="production_method" 
                  label="Production Method" 
                  value={formData.production_method} 
                  required
                  layout="horizontal"
                  list={[
                    {value: "BOD", label: "BOD"},
                    {value: "Stock", label: "Stock"},
                    {value: "Outsite Order", label: "Outsite Order"},
                  ]}
                  onChange={(e) => handleFormChange(e)} 
                />              
                {formData.production_method != "BOD" &&
                  <NumberField 
                    name="min_stock_qty" 
                    label="Min Stock Qty" 
                    value={formData.min_stock_qty} 
                    layout="horizontal"
                    onChange={(e) => handleFormChange(e)} />
                }
                {formData.production_method != "BOD" &&
                  <NumberField 
                    name="reorder_qty" 
                    label="Reorder Qty" 
                    value={formData.reorder_qty} 
                    layout="horizontal"
                    onChange={(e) => handleFormChange(e)} />
                }
                {formData.production_method != "BOD" &&
                  <NumberField 
                    name="reorder_days" 
                    label="Reorder Days" 
                    value={formData.reorder_days} 
                    layout="horizontal"
                    onChange={(e) => handleFormChange(e)} />
                }
                {formData.production_method == "Outsite Order" &&
                  <TextField 
                    name="vendor" 
                    label="Vendor" 
                    value={formData.vendor} 
                    layout="horizontal"
                    onChange={(e) => handleFormChange(e)} />
                }
                {formData.production_method == "Outsite Order" &&
                  <TextField 
                    name="vendor_contact" 
                    label="Vendor Contact" 
                    value={formData.vendor_contact} 
                    layout="horizontal"
                    onChange={(e) => handleFormChange(e)} />
                }                                
              </>
            }

            <ListField 
              name="status" 
              label="Status" 
              value={formData.status} 
              required
              layout="horizontal"
              list={[
                {value: "Active", label: "Active"},
                {value: "Draft", label: "Draft"},
                {value: "Admin", label: "Admin"},
                {value: "Internal", label: "Internal"},
                {value: "Hidden", label: "Hidden"},
                {value: "Deleted", label: "Deleted"}
              ]}
              onChange={(e) => handleFormChange(e)} 
            />

          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Product" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ProductDrawer;