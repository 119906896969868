import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

import ShapePreview from "../components/shape-preview"
import SelectField from "../components/fields/field-select";
import ColorBox from "../components/color-box";
import Panel from "./panel";
import { List, ListItem } from "../components/list"
import { getIconForType } from "../helpers/icons";
import { useGetJobWarnings } from "../api/queries/workflowjobs";
import { useWorkflowJobToggleWarning, useWorkflowResetWarnings } from "../api/mutations/workflowjobs";

function JobWorkflowWarningsPanel({
    jobId,
    resetOnLoad
}) {
    const [initialized, setInitialized] = useState(resetOnLoad ? false:true);

    const warningsQuery = useGetJobWarnings(jobId, {enabled: initialized});
    const warnings = warningsQuery.data;

    const resetWarnings = useWorkflowResetWarnings();

    useEffect(
        () => {
            if (!initialized) {
                resetWarnings.mutate(jobId, {
                    onSuccess: () => setInitialized(true)
                });
            }
        }, 
        []
    );

    const toggleWarning = useWorkflowJobToggleWarning(jobId, {
        onError: () => window.alert("Error updating warning")
    });

    return (
      <Panel query={initialized ? warningsQuery : resetWarnings} checkReloading={!initialized}>
        {warnings && 
            <List className="mt-2 w-full mb-20">
            {warnings.map(warning => (
                <ListItem 
                    key={warning.id} 
                    disabled={warning.active_ind == 0}
                    className={clsx(
                        "grid grid-cols-[240px,1fr,175px] items-center gap-2",
                        warning.active_ind == 0 && warning.value == "Yes" && "!bg-red-200",
                    )}
                >
                    <div className="flex items-center gap-2">
                        <FontAwesomeIcon icon={getIconForType("warning")} size="lg" />
                        {warning.title}
                    </div>
                    <div className="flex items-center gap-2">
                     {warning.colors ?
                        <List direction="horizontal">
                            {warning.colors.map(color => (
                                <ListItem size="sm">
                                    <ColorBox color={color} />
                                    {color.name}
                                </ListItem>
                            ))}
                        </List>
                    : warning.shapes ?
                        <List direction="horizontal">
                            {warning.shapes.map(shape => (
                                <ListItem size="sm">
                                    <ShapePreview path={shape.path} solid={true} width={35} height={25} />
                                </ListItem>
                            ))}
                        </List>                        
                    : warning.message ?
                        <div className="text-red-500 text-xs">
                            {warning.message}
                        </div>
                    :
                        null
                    }
                    </div>
                    <div className="flex items-center justify-end gap-1">
                        {(toggleWarning.isLoading && toggleWarning.variables?.warningTypeId == warning.id ) && 
                            <FontAwesomeIcon icon={faSpinner} spin />
                        }
                        <SelectField
                            name="warning_value"
                            prepend="Active?"
                            prependClassName={warning.active_ind == 1 ? "bg-green-200" : ""}
                            value={""+warning.active_ind || "0"}
                            items={[
                                {id: "0", label: "No"},
                                {id: "1", label: "Yes"},
                            ]}
                            invalid={warning.active_ind == 0}
                            valid={warning.active_ind != 0}        
                            onChange={(e, value) => {
                                toggleWarning.mutate({warningTypeId: warning.id, activeInd: value});
                            }}
                        />
                    </div>
                </ListItem>
            ))}
            </List>
        }
      </Panel>    
    )
}

export default JobWorkflowWarningsPanel;