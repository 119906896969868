import React, { useState, useEffect } from "react"
import produce from "immer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import Loading from "../components/loading";
import { postData } from "../common/services/server";
import { formatDateTime, removeNulls } from "../helpers/utility";
import BoardFile from "../components/board/board-file";
import UploadButton from "../components/buttons/button-upload";

function FilesPanel({
    userId,
    orderId,
    signId,
    projectId,
    jobId,
    workflowJobId,
    onFileChanged
}) {
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState(null);
    const [warnings, setWarnings] = useState([]);

    useEffect(
        () => {
            setLoading(true);

            if (workflowJobId) {
                postData("files/warnings", removeNulls({ 
                    workflowjob_id: workflowJobId,
                }),
                    function(result) {
                        setWarnings(result);
                    },
                    function(error) {
                        window.alert("Error loading file warnings")
                    }
                );  
            }

            postData("files/data", removeNulls({ 
                user_id: userId,
                order_id: orderId,
                sign_id: signId,
                project_id: projectId,
                job_id: jobId,
                workflowjob_id: workflowJobId,
            }),
                function(result) {
                    setFiles(result);
                },
                function(error) {
                    window.alert("Error loading files data")
                },
                function() {
                    setLoading(false);
                }
            );            
        }, 
        [userId, orderId, signId, projectId, jobId, workflowJobId]
    );

    function fileChanged(fileData) {
        console.log("fileChanged", fileData)
        setFiles(produce(draft => {
            const idx = draft.findIndex(f => f.id == fileData.id);

            if (idx == -1)
                draft.unshift(fileData)
            else {
                for (const key of Object.keys(fileData)) {
                    draft[idx][key] = fileData[key];
                }
            }
        }));

        if (onFileChanged)
            onFileChanged(fileData, 'uploaded');
    }

    return (
        <>
            {loading ? 
                <Loading />
            : files ?
                <>
                    {warnings.length > 0 &&
                        <div className="space-y-1 mb-2">
                            {warnings.map((warning, index) => (
                                <div key={index} className="bg-red-500 text-white rounded px-2 py-1 flex items-center gap-2 text-sm">
                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                    {warning.warning}
                                </div>
                            ))}
                        </div>
                    }

                    <div className="grid gap-1 pb-[40px]">
                        {files.map((file, index) => (
                            <BoardFile
                                key={index} 
                                file={file} 
                                className={file.related_ind == 1 ? "!bg-gray-100" : ""}
                                onDelete={() => {
                                    setFiles(produce(draft => {
                                        const idx = draft.findIndex(f => f.id == file.id);

                                        if (idx != -1) {
                                            draft.splice(idx, 1);
                                        }
                                    }));

                                    if (onFileChanged)
                                        onFileChanged(file, 'deleted');
                                }}
                            />
                        ))} 
                    </div>
                </>
            :
              null
            }  

            <div className="absolute bottom-2 left-2 right-2 p-1 flex mt-2 z-10 bg-purple-300">
                <UploadButton
                    uploadUrl={
                        jobId ? "jobs/upload?job_id=" + jobId :
                        workflowJobId ? "jobs/upload?job_id=" + workflowJobId :
                        projectId ? "projects/upload?project_id=" + projectId :
                        signId ? "signs/upload?sign_id=" + signId :
                        null
                    }
                    label="Upload File"
                    className="bg-white border border-gray-300 !text-black"
                    inputClassName="!cursor-pointer"
                    multiple={true}
                    disabled={loading}
                    onUpload={(upload) => {
                        fileChanged(upload);
                    }}
                />                          
            </div>                    
        </>
    );
}

export default FilesPanel