import React from "react"
import { useHistory } from "react-router-dom";

import { useGetSigns } from "../api/queries/signs";
import storage from "../settings/storage";
import Panel from "./panel";
import ZoomableImage from "../components/image-zoomable";
import { formatDateTime, formatPrice } from "../helpers/utility";

export default function SignsPanel({
    projectId,
    ignoreSignId,
    onSign,
}) {
    const history = useHistory();

    const signsQuery = useGetSigns({
        project_id: projectId,
        ignore_sign_id: ignoreSignId
    });
    const signs = signsQuery.data;

    return (
        <Panel query={signsQuery}>
            {signs &&
                <div className="grid gap-1">
                    {signs.map(sign => (
                        <div key={sign.id} className="border rounded p-1">
                            <div className="grid grid-cols-[140px,1fr] gap-2">
                                <ZoomableImage
                                    url={storage.root + sign.preview_url}
                                    maxHeight="50px"
                                    maxWidth="140px"
                                />
                                <button 
                                    className="text-left"
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        if (onSign)
                                            onSign(sign);
                                        else 
                                            history.push("/signs/sign/" + sign.id)
                                    }}
                                >
                                    <div className="flex flex-col flex-1 gap-2">
                                        {sign.custom_ind == 1 && "Custom "}
                                        Design #{sign.id}
                                    </div>
                                    <div className="text-xs">
                                        {sign.title && 
                                            <div>{sign.title}</div>
                                        }
                                        <div>{sign.size_height}x{sign.size_width}</div>
                                        <div className="text-green-500">{formatPrice(sign.estimate)}</div>
                                        <div className="text-gray-400">Added {formatDateTime(sign.added_date)}</div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    ))}   
                </div>
            }          
        </Panel>
    );
}