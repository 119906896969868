import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faFlagUsa, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faPaintBrush, faPalette, faDesktop, faClipboardCheck, faFileAlt, faTasks, faIdCard } from "@fortawesome/free-solid-svg-icons";

import Content from "../components/content"
import PageHeader from "../components/page-header";
import QaPanel from "./panels/panel-qa"
import ColorsPanel from "./panels/panel-colors";
import LandingPagesPanel from "./panels/panel-landingpages";
import StationsPanel from "./panels/panel-stations";
import StylesPanel from "./panels/panel-styles";
import StatesPanel from "./panels/panel-states";
import HolidaysPanel from "../panels/holidays";
import CompaniesPanel from "./panels/panel-companies";
import ContentPanel from "./panels/panel-content";
import Button from "../components/buttons/button";

import RolesPanel from "./panels/panel-roles";
import SkillsPanel from "./panels/panel-skills";
import EmailTemplatesPanel from "./panels/panel-emailtemplates";

import ColorDrawer from "../drawers/drawer-color"
import LandingPageDrawer from "../drawers/drawer-landingpage"

import { userHasPermission, Permissions } from "../common/services/auth";
import PageLayout from "../components/page-layout";

function DataPage(props) {
    const history = useHistory();
    let { tab } = useParams();

    const [selectedTab, setSelectedTab] = useState({tab: "", title: "Data"});
    const [selected, setSelected] = useState(null)
    const [refresh, setRefresh] = useState(1);

    useEffect(
        () => {
            switch (tab) {
                case "qa":
                    setSelectedTab({tab, title: "QA", permission: Permissions.QAChecklists}); break;
                case "pages":
                    setSelectedTab({tab, title: "Landing Page", permission: Permissions.LandingPages}); break;
                case "stations":
                    setSelectedTab({tab, title: "Station", permission: Permissions.Stations}); break;
                case "styles":
                    setSelectedTab({tab, title: "Style", permission: Permissions.Styles}); break;
                case "colors":
                    setSelectedTab({tab, title: "Color", permission: Permissions.Colors}); break;
                case "states":
                    setSelectedTab({tab, title: "State", permission: Permissions.ShippingData}); break;
                case "companies":
                    setSelectedTab({tab, title: "Company", permission: Permissions.Companies}); break;
                case "roles":
                    setSelectedTab({tab, title: "Security Role", permission: Permissions.Roles}); break;
                case "skills":
                    setSelectedTab({tab, title: "Production Skills", permission: Permissions.Roles}); break;
                case "emailtemplates":
                    setSelectedTab({tab, title: "Email Templates", permission: Permissions.EmailTemplates}); break;
                case "content":
                    setSelectedTab({tab, title: "Content", permission: Permissions.ProductContent}); break;
                case "holidays":
                    setSelectedTab({tab, title: "Holiday", permission: Permissions.Companies}); break;
                default:
                    setSelectedTab({tab, title: "Data"})
            }
        }, 
        [tab]
    );

    return (
        <PageLayout>
            <PageHeader 
                title={selectedTab.title + " Manager"}
                help={selectedTab.tab}
            >
                {selectedTab.tab == "colors" ? 
                    <Button onClick={() => setSelected({mode:"createcolor"})} variant="outline-success" size="sm">
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        Create Color
                    </Button>
                : selectedTab.tab == "pages" ? 
                    <Button onClick={() => setSelected({mode:"createpage"})} variant="outline-success" size="sm">
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        Create Page
                    </Button>           
                : 
                    null
                }                    
            </PageHeader>
            <Content permission={selectedTab.permission}>
                {selectedTab.tab == "qa" && 
                    <QaPanel />
                }
                {selectedTab.tab == "pages" &&
                    <LandingPagesPanel refresh={refresh} />
                }
                {selectedTab.tab == "stations" &&
                    <StationsPanel />
                }
                {selectedTab.tab == "styles" &&
                    <StylesPanel />
                }
                {selectedTab.tab == "colors" &&
                    <ColorsPanel />
                }
                {selectedTab.tab == "states" &&
                    <StatesPanel />
                }
                {selectedTab.tab == "companies" && 
                    <CompaniesPanel />
                }
                {selectedTab.tab == "roles" && 
                    <RolesPanel />
                }   
                {selectedTab.tab == "skills" && 
                    <SkillsPanel />
                }                   
                {selectedTab.tab == "content" && 
                    <ContentPanel /> 
                }  
                {selectedTab.tab == "holidays" && 
                    <HolidaysPanel /> 
                }                                                                                
            </Content>
            {(selected && selected.mode == "createcolor") && 
                <ColorDrawer 
                    color={{}}
                    show={true}
                    onSaved={(color) => {
                        setSelected(null)
                    }}
                    onHide={() => setSelected(null)}
                />
            }
            {(selected && selected.mode == "createpage") && 
                <LandingPageDrawer 
                    page={{}}
                    show={true}
                    onSaved={(color) => {
                        setSelected(null)
                        setRefresh(refresh+1);
                    }}
                    onHide={() => setSelected(null)}
                />
            }   
        </PageLayout>
    );
}

export default DataPage;