import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faClipboardList, faDesktop, faStickyNote, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import TextAreaField from "../components/fields/field-textarea"
import NumberField from "../components/fields/field-number"
import SelectField from "../components/fields/field-select"
import GoogleImage from "../components/google-image"
import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import FieldEmployee from "../components/fields/field-employee";
import FieldLabel from "../components/fields/label";
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'
import { FormControl, InputGroup } from "react-bootstrap";
import { colors } from "../settings/settings";
import Loading from "../components/loading";
import storage from "../settings/storage";

function JobTaskDrawer(props) {
  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(props.task);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    console.log("props.task", props.task)
    setFormData(props.task);
  }, [props.task]);
  
  useEffect(() => {
    if (props.taskId && props.jobId) {
      setLoading(true)

      postData("jobs/task", {
        job_id: props.job.id,
        id: props.taskId
      }, 
        function(response) {
          setFormData(response);
        },
        function(error) {
          alert("An error occured loading the task.");
        },
        function() {
          setLoading(false);
        }
      ); 
    }   
  }, [props.taskId, props.jobId]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("jobs/savetask", removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the task.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();

    console.log("handleFormChange", event.target)
    //console.log("handleFormChange", event.target.name, event.target.value)
    setFormData(produce(draft => {
      draft[event.target.name] = event.target.value;
    }));
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faClipboardList} />
            &nbsp;
            Job Task
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? 
            <Loading />
          : formData ?
            <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
              {props.job && 
                <>
                  <InputGroup className="mb-2">
                      <FormControl
                        as="input" 
                        value={props.job.title}
                        disabled
                      />              
                      <InputGroup.Append>
                          <Button onClick={() => window.open("#/jobs/job/" + props.job.id)}>
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                          </Button>
                      </InputGroup.Append>
                  </InputGroup> 
                  {props.job.preview_url && 
                    <div style={{textAlign: "center", margin:"20px 0px"}}>
                      <img src={storage.root + props.job.preview_url} style={{maxHeight: "80px", maxWidth: "225px"}} />
                    </div>
                  }
                </>               
              }             
              <Form.Row>
                <Col>
                    <TextField 
                        name="task" 
                        label="Task" 
                        disabled={true}
                        value={formData.name} 
                    />
                </Col>
                {formData.dependent_name && 
                  <Col>
                    <TextField 
                        name="dependenttask" 
                        label="Dependent Task" 
                        disabled={true}
                        value={formData.dependent_name} 
                    />
                  </Col>
                }
              </Form.Row>

              {(formData.mwos.length > 1) ?
                <TextAreaField 
                  name="mwo" 
                  label="MWOs"
                  rows={3}
                  disabled={true}
                  error="Multiple MWO stickers match"
                  value={formData.mwo_titles.replace(/,/g, '\n')} 
              />      
              :
                <TextField 
                    name="mwo" 
                    label="MWO"
                    disabled={true}
                    error={formData.mwo && !formData.mwo_title ? "MWO is not valid. Scanning will not work at the stations." : ""}
                    value={formData.mwo_title || formData.mwo} 
                />
              }

              <Form.Row>
                <Col>
                  <SelectField 
                      name="status_id" 
                      label="Status"                 
                      labelField="title"
                      required
                      value={formData.status_id} 
                      url={"jobs/taskstatuses"}
                      onChange={(e) => handleFormChange(e)} />
                </Col>
                <Col>
                  <NumberField 
                    name="target_day" 
                    label="Target Day" 
                    required
                    step={1}
                    value={formData.target_day} 
                    onChange={(e) => handleFormChange(e)} />
                </Col>
              </Form.Row>

              {formData.status_id == 19 && 
                <TextAreaField 
                    name="problem" 
                    label="Problem" 
                    rows={3}
                    value={formData.problem} 
                    onChange={(e) => handleFormChange(e)} />
              }

              <FieldEmployee 
                name="assigned_user_id" 
                production={true}
                label="Assigned Employee" 
                value={formData.assigned_user_id} 
                onChange={(e) => handleFormChange(e)} />

              <FieldLabel>
                Production Estimate
              </FieldLabel>
              <InputGroup className="mb-2">
                  <FormControl
                    name="production_estimate"
                    as="input" 
                    type="number"                   
                    value={formData.production_estimate} 
                    onChange={handleFormChange} 
                  />              
                  <InputGroup.Append>
                      <InputGroup.Text>
                        Minutes
                      </InputGroup.Text>
                  </InputGroup.Append>
              </InputGroup>

              <TextAreaField 
                  name="comments" 
                  label="Comments" 
                  rows={3}
                  value={formData.comments} 
                  onChange={(e) => handleFormChange(e)} />

              {(formData.notes && formData.notes.length > 0) && 
                <div style={{marginBottom: "10px"}}>
                  <div>Notes</div>
                  {(formData.notes.map(note => (
                      <div>
                        <FontAwesomeIcon icon={faStickyNote} style={{color: colors.orangeLight}} />{' '}
                        {note.notes}
                      </div>
                  )))}
                </div>
              }

              {(formData.files && formData.files.length > 0) && 
                <div style={{marginBottom: "10px"}}>
                  <div>Photos</div>
                  <div style={{
                    display: "flex",
                    gap: "5px",
                    flexFlow: "wrap"
                  }}>
                    {(formData.files.map(file => (
                      <GoogleImage
                        root
                        src={file.url}
                        link={file.url}
                        style={{
                          height: "50px",
                          width: "auto"
                        }}
                      />
                    )))}
                  </div>
                </div>
              }            

              {(formData.stations.length > 0) && 
                <>
                  <div>Production Stations</div>
                  {(formData.stations.map(station => (
                      <div>
                        <FontAwesomeIcon icon={faDesktop} />{' '}
                        <a href={"#/station/" + station.id} target="_blank">
                          {station.name}
                        </a>
                      </div>
                  )))}
                </>
              }
            </Form>
          :
            null
          }
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Task" 
                disabled={loading}
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default JobTaskDrawer;