import React from 'react';
import { useParams } from "react-router-dom";
import { PDFViewer, Document, Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';

import Loading from '../loading';
import storage from '../../settings/storage';
import Error from '../error';
import { formatDate } from '../../helpers/utility';
import { useGetPackingSlips } from '../../api/queries/pdfs';

const styles = StyleSheet.create({
    viewer: {
        height: "100vh"
    },
    page: {
        margin: "0px",
        padding: "10px",
        fontSize: "12px",
        minHeight: "100%",     
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
    },
    title: {
        fontWeight: "bold",
        fontSize: "25px"        
    },
    footer: {
        textAlign: "center",
        color: "#999",
    },
    address: {
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "right",
    },
    tableHeader: {
        backgroundColor: "#000000",
        color: "#ffffff",
        fontWeight: "bold",
        padding: "5px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "16px",
        marginTop: "10px",
    },
    tableData: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "16px",
    },
    tableCell: {
        padding: "5px",
        fontSize: "12px",
        border: "1px solid black",
        width: "33.33%",
    },
    itemsTableCell: {
        padding: "2px",
        fontSize: "12px",
        border: "1px solid black",
        width: "25%",
    }    
});

export default function PackingSlipsPDF() {
    let { orderId } = useParams();

    const query = useGetPackingSlips(orderId);
    const order = query.data?.order;
    const boxes = query.data?.boxes;
   
    return (
      <>
        {query.isLoading ?
            <Loading />
        : query.isError ?
            <Error message={query.error.message} />
        :
            <PDFViewer width="100%" showToolbar={true} style={styles.viewer}>
                <Document>
                {boxes.map((box,index) => (
                    <Page key={index} size="LETTER">
                        <View style={styles.page}>
                            <View style={styles.header} fixed>
                                <Image 
                                    src="https://storage.googleapis.com/signmonkey-148101.appspot.com/images/logo.png"
                                    style={{
                                        height: "50px"
                                    }}
                                />
                                <View>
                                    <Text style={styles.title}>
                                        PACKING SLIP
                                    </Text>
                                    <View style={{display:"flex", alignItems:"flex-end"}}>
                                        <Text>
                                            {formatDate(new Date())}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ flex: 1 }}>
                                <View style={styles.address}>
                                    <View><Text style={{marginBottom:"10px"}}>SHIP TO:</Text></View>
                                    <View><Text>{order.ship_name}</Text></View>
                                    <View><Text>{order.ship_address1}</Text></View>
                                    {order.ship_address2 && <View><Text>{order.ship_address2}</Text></View>}
                                    <View style={{display:"flex", flexDirection:"row", alignItems: "center", justifyContent: "flex-end", gap: "5px"}}>
                                        <Text>{order.ship_city}</Text> <Text>{order.ship_state}</Text> <Text>{order.ship_zipcode}</Text>
                                    </View>
                                </View>
    
                                <View style={styles.tableHeader}>
                                    <Text>Order</Text>
                                    <Text>Job</Text>
                                    <Text>Package</Text>
                                </View>
                                <View style={styles.tableData}>
                                    <View style={styles.tableCell}><Text>#{order.id}</Text></View>
                                    <View style={[styles.tableCell, {textAlign:"center"}]}><Text>{box.job_title}</Text></View>
                                    <View style={[styles.tableCell, {textAlign:"right"}]}><Text>{index+1} of {boxes.length}</Text></View>
                                </View>

                                <View style={styles.tableHeader}>
                                    <Text>Item</Text>
                                    <Text>Size</Text>
                                    <Text>Description</Text>
                                    <Text>Quantity</Text>
                                </View>

                                <View style={{display:"flex"}}>
                                {box.items.map((item,index) => (
                                    <View key={"item"+index} style={styles.tableData} wrap={false}>
                                        <View style={styles.itemsTableCell}><Text>{item.title}</Text></View>
                                        <View style={styles.itemsTableCell}><Text>{item.size}</Text></View>
                                        <View style={styles.itemsTableCell}><Text>{item.description}</Text></View>
                                        <View style={styles.itemsTableCell}><Text>{item.quantity || 1}</Text></View>                                   
                                    </View>
                                ))}
                                </View>
                            </View>
                            <View style={styles.footer}>
                                <Text>Copyright © {new Date().getFullYear()} Paris Inc. DBA SignMonkey.com. All rights reserved.</Text>
                            </View>
                        </View>
                    </Page>
                ))}
                </Document>
            </PDFViewer>
        }
      </>
    )
}