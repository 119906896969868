import React, { useState, useEffect } from "react";
import styled from "styled-components"

import produce from "immer"
import { faTrash, faSpinner, faPencilAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import InputGroup from  'react-bootstrap/InputGroup';
import FormControl from  'react-bootstrap/FormControl';

import Loading from "../../components/loading"
import FaceArtDrawer from "../../drawers/drawer-faceart";
import CategoryDrawer from "../../drawers/drawer-category";
import GoogleImage from "../../components/google-image"
import Button from "../../components/buttons/button";

import { postData } from "../../common/services/server"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import storage from "../../settings/storage"
import { removeNulls } from "../../helpers/utility"
import { userHasPermission } from "../../common/services/auth";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CategoriesWidth = 200;
const Padding = 10;

const Interface = styled.div`
    display: flex;
`
const Categories = styled.div`
    width: ${CategoriesWidth}px;
    flex-shrink: 0;
    margin-right: ${Padding}px;
    border: 1px solid #ccc;
    padding: 0px;
    overflow: auto;  

    .card-header {
        padding: 5px 10px;
    }

    > h2 {
        background-color: #f6f7f8;
        background-image: linear-gradient(#fff, #efefef);
        font-size: 16px;
        font-weight: 700;
        padding: 15px 10px;
        border-bottom: 1px solid #ccc;
        margin: 0px;
    }

    ul {
        list-style-type: none;
        margin: 0px 0px 25px 0px;
        padding: 0px;

        li {
            padding: 5px;
            border-bottom: 1px solid #ccc;
            cursor: pointer;
            font-size: 12px;
            display: flex;
            justify-items: center;
            justify-content: space-between;

            &:hover {
                background-color: #eee;
            }

            &.selected {
                background-color: #ccc;
            }

            .badge {
                flex: 0;
            }
        }
    }

    > div {
        position: sticky;
        right: 0px;
        bottom: 0px;
        padding: 0px;

        button {
            padding: 8px;
            width: 100%;
        }
    }
`

const FaceArts = styled.div`
    width: 100%;

    > div.art {
        display: flex;
        flex-flow: wrap;
        
        > div {
            margin: 5px;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            position: relative;

            img {
                margin-bottom: 10px;
                max-height: 100px;
            }
            button {
                margin-top: 10px;
                margin-right: 3px;
            }
            .badge {
                position: absolute;
                top: -5px;
                right: -5px;
            }
        }
    }
`

function FaceArtPanel(props) {
    const history = useHistory();
    const windowDimensions = useWindowDimensions();

    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(null);
    const [categoryTitle, setCategoryTitle] = useState("");
    const [facearts, setFacearts] = useState([]);

    const [loading, setLoading] = useState("");
    const [busyRow, setBusyRow] = useState(null);
    const [busy, setBusy] = useState("");
    const [selected, setSelected] = useState(null);

    useEffect(
        () => {
            setLoading("categories");

            postData("categories/data?type=faceart", { uncategorized: 0 },
                function(result) {
                    setCategories(result);
                },
                function(error) {
                    alert("Error loading categories")
                },
                function() {
                    setLoading("");
                }
            );
        }, 
        []
    );

    useEffect(
        () => {
            if (category) {
                setLoading("facearts");

                postData("facearts/forcategory", {category_id: category.id},
                    function(result) {
                        setFacearts(result);
                    },
                    function(error) {
                        alert("Error loading face art")
                    },
                    function() {
                        setLoading("")
                    }
                );   
            }

            setCategoryTitle(category ? category.title : "");
        }, 
        [category]
    );

    function onEdit(cat, faceart) {
        setSelected({mode:"faceart", faceart})
    }

    function onRemove(cat, faceart) {
        console.log("onRemove",cat,faceart)
        setCategories(produce(draft => {
            const catIndex = draft.findIndex(c => c.id == cat.id);
            console.log("cat index", catIndex)
            if (catIndex != -1) {
                const artIndex = draft[catIndex].facearts.findIndex(f => f.id == faceart.id);
                console.log("faceart index", artIndex)

                draft[catIndex].facearts[artIndex].removing = true;
            }
        }));    

        postData("facearts/removefromcategory", {faceart_id: faceart.id, category_id: cat.id},
            function(result) {
                setCategories(produce(draft => {
                    const catIndex = draft.findIndex(c => c.id == cat.id);
                    if (catIndex != -1) {
                        const artIndex = draft[catIndex].facearts.findIndex(f => f.id == faceart.id);

                        draft[catIndex].facearts.splice(artIndex, 1);
                        draft[catIndex].faceart_count = draft[catIndex].facearts.length;
                    }
                }));
            },
            function(error) {
                alert("Error removing face art")
            }
        );  
    }

    function onUpdateCategory() {
        setBusy("categoryupdate");

        console.log("update category", category.id, categoryTitle);

        postData("facearts/updatecategory", removeNulls({ 
            category_id: category.id,
            title: categoryTitle
        }),
            function() {
                const index = categories.findIndex(c => c.id == category.id);

                setCategories(produce(draft => {
                    draft[index].title = categoryTitle;
                }));
            },
            function(error) {
                alert("Error updating")
            },
            function() {
                setBusy("");
            }
        );
    }
    function onDeleteCategory() {
        setBusy("categorydelete");

        postData("facearts/deletecategory", removeNulls({ 
            category_id: category.id
        }),
            function(result) {
                const index = categories.findIndex(c => c.id == category.id);

                setCategories(produce(draft => {
                    draft.splice(index, 1);
                }));
                setCategory(null);
            },
            function(error) {
                alert("Error deleting")
            },
            function() {
                setBusy("");
            }
        );
    }
        
    return (
        <>  
            <Interface>
                <Categories style={{height: windowDimensions.height - dimensions.headerHeight - dimensions.pageWithTabsPadding*2}}>
                    { loading == "categories" ?
                        <Loading size="sm" />
                    :
                    <>
                        <ul>
                            {categories.map(cat => (
                                <li key={cat.id} onClick={() => setCategory(cat)} className={category && category.id == cat.id ? "selected":""}>
                                    {cat.title}
                                    <Badge 
                                        variant={cat.faceart_count == 0 ? "danger":"primary"}
                                    >
                                        {cat.faceart_count}
                                    </Badge>
                                </li> 
                            ))}
                        </ul>
                        {userHasPermission("Face Art", "Categorize") &&
                            <div>
                                <button onClick={() => setSelected({mode:"createcat"})}>
                                    Create Category
                                </button>
                            </div>
                        }
                    </>
                    }
                </Categories>
                { loading == "facearts" ? 
                    <Loading /> 
                :
                    <>
                    <FaceArts>                    
                        {(category && userHasPermission("Face Art", "Categorize")) && 
                            <InputGroup size="sm" className="mb-3">
                                <FormControl
                                    defaultValue={categoryTitle}
                                    onChange={(e) => { 
                                        setCategoryTitle(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            onUpdateCategory();
                                        }
                                    }}
                                />
                                <InputGroup.Append>
                                    <Button onClick={onUpdateCategory} variant="outline-primary">
                                        <FontAwesomeIcon icon={busy == "categoryupdate" ? faSpinner : faSave} spin={busy == "categoryupdate"} />{' '}
                                        Update
                                    </Button>
                                    <Button onClick={onDeleteCategory} variant="outline-danger">
                                        <FontAwesomeIcon icon={busy == "categorydelete" ? faSpinner : faTrash} spin={busy == "categorydelete"} />{' '}
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        }

                        <div className="art">
                        {facearts.map(faceart => (
                            <div key={faceart.id}>
                                <a href={storage.root + faceart.url} target="_blank">
                                    <GoogleImage root src={faceart.url_thumb || faceart.url} style={{ width: "75px" }} />
                                </a>
                                <div>
                                    <small>{faceart.name}</small>
                                </div>
                                <div align="center">
                                    {userHasPermission("Face Art", "Edit") &&
                                        <Button variant="outline-warning" size="sm" onClick={() => onEdit(category, faceart)}>
                                            <FontAwesomeIcon icon={faPencilAlt} />
                                        </Button>
                                    }
                                    {userHasPermission("Face Art", "Delete") &&
                                        <Button variant="outline-danger" size="sm" onClick={() => onRemove(category, faceart)}>
                                            <FontAwesomeIcon icon={faceart.removing ? faSpinner: faTrash} spin={faceart.removing} />
                                        </Button>
                                    }
                                </div>
                                <Badge variant="secondary" title="Used">{faceart.used}</Badge>
                            </div>
                        ))}
                        </div>
                    </FaceArts>
                    </>
                }
            </Interface>

            { selected && selected.mode == "faceart" && 
                <FaceArtDrawer 
                    categories={categories}
                    faceart={selected.faceart} 
                    show={true}
                    onSaved={(faceart) => {
                        console.log("faceart changed", faceart)
                        //setCounter(counter+1); // force refresh
                        setFacearts(produce(draft => {
                            const index = draft.findIndex(f => f.id == faceart.id);

                            console.log("index", index)
                            if (index != -1)
                                draft[index] = faceart;
                        }));  

                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { selected && selected.mode == "createcat" && 
                <CategoryDrawer 
                    type="faceart"
                    category={{id: 0}}
                    show={true}
                    onSaved={(cat) => {
                        setCategories(produce(draft => {
                            draft.push(cat);

                            draft.sort((a, b) => {
                                var keyA = a.title;
                                var keyB = b.title;

                                // Compare the 2 dates
                                if (keyA < keyB) return -1;
                                if (keyA > keyB) return 1;
                                return 0;
                            });
                        }));  

                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
        </>
    );
}

export default FaceArtPanel;