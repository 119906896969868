import React, { useState, useEffect } from "react";
import { faPlus, faStar, faListAlt, faBug, faUser, faCheck, faTrash, faInbox, faEnvelope, faClock, faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { useParams, useHistory } from "react-router-dom";

import Content from "../components/content"
import PageHeader from "../components/page-header";
import EmailsPanel from "./panels/panel-emails";
import EmailQueuePanel from "./panels/panel-emailqueue";

import { Permissions, Actions } from "../common/services/auth";
import PageLayout from "../components/page-layout";

function EmailsPage(props) {
    const history = useHistory();
    let { tab } = useParams();

    if (!tab) tab = "received";

    return (
        <PageLayout>
            <PageHeader 
                title="Email Manager"
                help="emails"
                tab={tab}
                tabUrl={"/emails/{tab}"}
                tabs={[
                    {name:"received", title:"Inbox", icon:faInbox},
                    {name:"completed", title:"Completed", icon:faCheck},
                    {name:"sent", title:"Sent", icon:faEnvelope}, 
                    {name:"spam", title:"Spam", icon:faBug},
                    {name:"deleted", title:"Deleted", icon:faTrash, permission: Permissions.Emails, action: Actions.Restore},
                    {name:"all", title:"All", icon:faListAlt},
                    {name:"queue", title:"Pending", align: "right", icon:faClock, permission: Permissions.EmailsScheduled},
                ]}
                onTab={(tab) => {
                    history.push("/emails/" + tab);
                }}
            >
            </PageHeader>
            <Content>
                {tab == "queue" ? 
                    <EmailQueuePanel 
                        haveTabs={true}
                    />                
                :
                    <EmailsPanel
                        filters={{
                            inbox: tab,
                        }}
                        haveTabs={true}
                    />
                }
            </Content>             
        </PageLayout>
    );
}

export default EmailsPage;