import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import Loading from "./loading";
import ZoomableImage from "./image-zoomable";
import storage from "../settings/storage";
import { getIconForType } from "../helpers/icons";
import { formatDate, formatDateTime, formatPhoneNumber } from "../helpers/utility";
import { useGetCardData } from "../api/queries/cards";
import Error from "./error";
import settings from "../settings";
import FieldText from "./fields/field-text";
import EmailDrawer from "../drawers/drawer-email"
import Button from "./buttons/button";
import FieldLabel from "./fields/field-label";

function Card({
    query,
    name,
    title,
    url,
    actions,
    selected,
    className,
    onClick,
    children
}) {
    return (
        <div 
            className={clsx(
                "rounded-md relative bg-white border-2 border-purple-200",
                (url || onClick) && "cursor-pointer",
                className,
                // "hover:scale-[1.01]"
            )}
            onClick={onClick}
        >
            {title && 
                <div className={clsx(
                    "flex items-center justify-between border-b border-gray-100 p-1 text-sm",
                    selected && "bg-purple-200"
                )}>
                    <div className="flex items-center gap-1">
                        <FontAwesomeIcon icon={getIconForType(name)} fixedWidth />
                        {title}
                    </div>
                    {url ? 
                        <div>
                            <Link to={url} target="_blank" onClick={(e) => e.stopPropagation()}>
                                <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" className="text-gray-400 hover:text-blue-500" />
                            </Link>
                        </div>
                    : actions ?
                        actions
                    :
                        null
                    }
                </div>
            }
            <div className="p-1 text-xs">
                {(query.isLoading || query.isFetching) ?
                    <Loading size="sm" label="" padding={5} />
                : query.error ?
                    <Error error={query.error} />    
                :
                    children
                }
            </div>
        </div>
    )    
}

export function CardProps({
    items
}) {
    return (
        <div className="grid gap-1 text-xs">
            {items.map((item,index) => (
                <div key={index} className={clsx(
                    "grid gap-1 items-stretch",
                    item.label ? "grid-cols-[60px,1fr]" : "",
                )}>
                    {item.label && 
                        <div className="bg-gray-100 p-[2px]">
                            {item.label}:
                        </div>
                    }
                    <div className="self-center break-all">
                        {(item.list && item.value) ? 
                            <>
                            {item.value.split(",").map((listitem, index) => (
                                <div key={index}>{listitem}</div>
                            ))}
                            </>
                        :
                            item.value
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}

export function OrderCard({
    card,
    className,
    onClick
}) {
    const cardQuery = useGetCardData(card.type, card.id);
    const data = cardQuery.data;

    return (
        <Card 
            query={cardQuery}
            name="order"
            title={"Order #" + card.id}
            url={"/orders/order/"+card.id}
            className={className}
            onClick={onClick}
        >
            {data && 
                <div className="grid grid-cols-2 gap-2">
                    <CardProps
                        items={[
                            {value: formatDateTime(data.order_date, "MM/dd/yyyy,h:mm a"), list:true},
                            {value: "$"+data.total},
                        ]}
                    />
                    <CardProps
                        items={[
                            {label: "State", value: data.ship_state},
                            {label: "Status", value: data.status_title},
                        ]}
                    />
                </div>
            }
        </Card>
    )
}

export function OrdersCard({
    card,
    className,
    onClick
}) {
    const cardQuery = useGetCardData(card.type, null, card);
    const data = cardQuery.data;

    return (
        <Card 
            query={cardQuery}
            name="orders"
            className={className}
            onClick={onClick}
        >   
            {data &&    
                <div className="text-lg flex items-center gap-1">
                    <FontAwesomeIcon icon={getIconForType("orders")} size="xs" fixedWidth />

                    <span className={clsx(
                        "rounded px-1 w-[35px] text-center",
                        data.total < 0 ? "bg-gray-400" : "text-gray-400 border border-gray-400",
                    )}>
                        {data.total}
                    </span>

                    Order{data.total == 1 ? "":"s"}
                </div>
            }
        </Card>
    )
}

export function JobCard({
    card,
    className,
    onClick
}) {
    const cardQuery = useGetCardData(card.type, card.id);
    const data = cardQuery.data;

    return (
        <Card 
            query={cardQuery}
            name="job"
            title={data ? data.title : "Job #" + card.id}
            url={"/jobs/job/"+card.id}
            className={className}
            onClick={onClick}
        >
            {data && 
                <div className="grid grid-cols-2 gap-2">
                    <CardProps
                        items={[
                            {label: "Status", value: data.status_title},
                        ]}
                    />
                    <CardProps
                        items={[
                            {label: "Ships", value: formatDate(data.ship_date)},
                        ]}
                    />
                </div>
            }
        </Card>
    )
}

export function CartCard({
    card,
    className,
    onClick
}) {
    const cardQuery = useGetCardData(card.type, card.id);
    const data = cardQuery.data;

    return (
        <Card 
            query={cardQuery}
            name="cart"
            title={"Cart #" + card.id}
            className={className}
            onClick={onClick}
        >
            {data && 
                <CardProps
                    items={[
                        {label: "User", value: data.user_name},
                        {label: "Items", value: data.item_count},
                        {label: "Total", value: "$"+data.total},
                    ]}
                />
            }
        </Card>
    )
}

export function UserCard({
    card,
    className,
    onClick
}) {
    const cardQuery = useGetCardData(card.type, card.id);
    const data = cardQuery.data;

    return (
        <Card 
            query={cardQuery}            
            name="user"
            title={data ? data.first_name+" "+data.last_name : card.id ? "User #"+card.id : "No User"}
            url={"/users/user/"+card.id}
            className={className}
            onClick={onClick}
        >      
            {data && 
                <CardProps
                    items={[
                        {label: "Email", value: data.email},
                        {label: "Phone", value: formatPhoneNumber(data.phoneno)},
                    ]}
                />
            }
        </Card>
    )
}

export function SignCard({
    card,
    className,
    onClick
}) {
    const cardQuery = useGetCardData(card.type, card.id);
    const data = cardQuery.data;

    return (
        <Card 
            query={cardQuery}
            name="sign"
            title={"Sign Design #" + card.id}
            url={"/signs/sign/" + card.id}
            className={className}
            onClick={onClick}
        >            
            {data &&
                <>
                    <div className="mb-1">
                        <ZoomableImage
                            url={storage.root + data.preview_url}
                            maxHeight={"25px"}
                        />
                    </div>                
                    <CardProps
                        items={[
                            // {label: "Version", value: data.version},
                            // {label: "Size", value: data.dimensions},
                            {label: "Products", value: data.products, list:true},
                        ]}
                    />
                </>
            }
        </Card>
    )
}

export function SignsCard({
    card,
    className,
    onClick
}) {
    const cardQuery = useGetCardData(card.type, null, card);
    const data = cardQuery.data;

    return (
        <Card 
            query={cardQuery}
            name="signs"
            className={className}
            onClick={onClick}
        >   
            {data && 
                <div className="flex items-center justify-between">
                    <div className="text-lg flex items-center gap-1">
                        <FontAwesomeIcon icon={getIconForType("signs")} size="xs" fixedWidth />

                        <span className={clsx(
                            "rounded px-2 w-[35px] text-center",
                            data.total > 0 ? "bg-purple-400 text-white" : "border border-purple-500 text-gray-400"
                        )}>
                            {data.total}
                        </span>
                        {card.label || "Related"} Sign{data.total == 1 ? "":"s"}
                    </div>
                </div>                
            }
        </Card>
    )
}

export function EmailsCard({
    card,
    className,
    onClick
}) {
    const cardQuery = useGetCardData(card.type, null, card);
    const data = cardQuery.data;

    return (
        <Card 
            query={cardQuery}
            name="emails"
            className={className}
            onClick={onClick}
        >   
            {data && 
                <div className="text-lg flex items-center gap-1">
                    <FontAwesomeIcon icon={getIconForType("emails")} size="xs" fixedWidth />

                    <span className={clsx(
                        "rounded px-2 w-[35px] text-center",
                        data.total > 0 ? "bg-blue-500" : "border border-blue-500 text-gray-400",
                        data.total >= 100 && "text-sm !px-1 py-1"
                    )}>
                        {data.total >= 100 ? "99+" : data.total}
                    </span>
                    Email{data.total == 1 ? "":"s"}
                </div>
            }
        </Card>
    )
}

export function NotesCard({
    card,
    className,
    onClick
}) {
    const cardQuery = useGetCardData(card.type, null, card);
    const data = cardQuery.data;

    return (
        <Card 
            query={cardQuery}
            name="notes"
            className={className}
            onClick={onClick}
        >   
            {data && 
                <div className="flex items-center justify-between">
                    <div className="text-lg flex items-center gap-1">
                        <FontAwesomeIcon icon={getIconForType("note")} size="xs" fixedWidth />

                        <span className={clsx(
                            "rounded px-2 w-[35px] text-center",
                            data.total > 0 ? "bg-yellow-500" : "border border-yellow-500 text-gray-400"
                        )}>
                            {data.total}
                        </span>
                        Note{data.total == 1 ? "":"s"}
                    </div>
                    {(data.related_total > 0) &&
                        <div className="text-lg flex items-center gap-1">
                            <span className={clsx(
                                "rounded px-2 min-w-[30px] text-center",
                                "border border-gray-500 text-gray-400"
                            )}>
                                {data.related_total}
                            </span>
                            Related
                        </div>
                    }
                </div>                
            }
        </Card>
    )
}

export function TasksCard({
    card,
    className,
    onClick
}) {
    const cardQuery = useGetCardData(card.type, null, card);
    const data = cardQuery.data;

    return (
        <Card 
            query={cardQuery}
            name="tasks"
            className={className}
            onClick={onClick}
        >   
            {data &&    
                <div className="text-lg flex items-center gap-1">
                    <FontAwesomeIcon icon={getIconForType("tasks")} size="xs" fixedWidth />

                    <span className={clsx(
                        "rounded px-2 w-[35px] text-center",
                        data.total > 0 ? "bg-green-500" : "border border-green-500 text-gray-400"
                    )}>
                        {data.total}
                    </span>
                    Task{data.total == 1 ? "":"s"}
                </div>
            }
        </Card>
    )
}

export function FilesCard({
    card,
    className,
    onClick
}) {
    const cardQuery = useGetCardData(card.type, null, card);
    const data = cardQuery.data;

    return (
        <Card 
            query={cardQuery}
            name="files"
            className={className}
            onClick={onClick}
        >   
            {data &&
                <div className="flex items-center justify-between">
                <div className="text-lg flex items-center gap-1">
                    <FontAwesomeIcon icon={getIconForType("files")} size="xs" fixedWidth />

                    <span className={clsx(
                        "rounded px-2 w-[35px] text-center",
                        data.total > 0 ? "bg-gray-500 text-white" : "border border-gray-500 text-gray-400"
                    )}>
                        {data.total}
                    </span>
                    File{data.total == 1 ? "":"s"}
                </div>
                {(data.related_total > 0) &&
                    <div className="text-lg flex items-center gap-1">
                        <span className={clsx(
                            "rounded px-2 min-w-[30px] text-center",
                            "border border-gray-500 text-gray-400"
                        )}>
                            {data.related_total}
                        </span>
                        Related
                    </div>
                }
                </div>
            }
        </Card>
    )
}

export function ProjectCard({
    card,
    className,
    onClick
}) {
    const cardQuery = useGetCardData(card.type, card.id);
    const data = cardQuery.data;

    return (
        <Card 
            query={cardQuery}
            name="project"
            title={"Project #" + card.id}
            url={"/projects/project/" + card.id}
            className={className}
            onClick={onClick}
        >
            {data &&       
                <CardProps
                    items={[
                        {label: "Manager", value: data.manager_name},
                        {label: "From", value: data.created_from},
                    ]}
                />
            }
        </Card>
    )
}

export function InteractiveBuilderCard({
    card,
    className,
    onClick
}) {
    const [send, setSend] = useState(false);

    const cardQuery = useGetCardData("sign", card.id);
    const data = cardQuery.data;

    const link = settings.BUILDER_INTERACTIVE_URL + "?uid=" + data?.uid;

    return (
      <>
        <Card 
            query={cardQuery}
            name="interactivebuilder"
            title={"Interactive Builder"}
            actions={
                <Button
                    icon={faEnvelope}
                    size="tiny"
                    onClick={() => setSend(true)}
                >
                    Email Link
                </Button>
            }
            className={className}
        >
            {data &&       
               <div className="space-y-1">
                    <div className="text-xs text-gray-500">
                        {link}
                    </div>
               </div>
            }
        </Card>

        { send && 
            <EmailDrawer 
                show={true}
                email={{
                    to: data.user_email,
                    subject: "Interactive Sign Builder",
                    body: link
                }}
                onSaved={ (email) => {
                    setSend(false);
                }} 
                onHide={ () => setSend(false) } 
            /> 
        }           
      </>
    )
}