import React, { useState } from "react";
import styled from "styled-components";
import produce from "immer"

import JobShapes from "../../components/job-shapes"
import PanelJobMwos from "../../panels/panel-job-mwos";

const Columns = styled.div`
  display: flex;
  gap: 10px;
`

function JobMWOsPanel({job}) {
    const [shapes, setShapes] = useState([]);

    return (
      <Columns>
        <PanelJobMwos
          job={job}
          shapes={shapes}
        />

        <JobShapes 
          job={job} 
          partTypes={["face"]}
          selectable={true}
          selected={shapes}
          onSelection={(shape, checked) => {
            
            setShapes(produce(draft => {
              const index = draft.findIndex(s => s.id == shape.id);
              console.log(index)
              
              if (index != -1) {
                if (!checked) 
                  draft.splice(index, 1);
              }
              else {
                if (checked)
                  draft.push(shape)
              }

            }));

          }}
          onSelectAll={(allShapes) => {
            // are they all already selected
            if (shapes.length == allShapes.length)
              setShapes([])
            else
              setShapes(allShapes);
          }}
        />
      </Columns>      
    );
}

export default JobMWOsPanel;