import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { isMobile } from "react-device-detect"

import produce from "immer"
import { faTrash, faSpinner, faPencilAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    BooleanCell,
    GoogleImageCell, 
    SortHeaderCell,
    SortTypes,
    EditActionsCell 
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Loading from "../../components/loading"
import ContentDrawer from "../../drawers/drawer-content";
import Toolbar from "../../components/toolbar"
import ListField from "../../components/fields/field-list"

import { postData } from "../../common/services/server"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

const CategoriesWidth = 200;
const Padding = 10;

const Interface = styled.div`
    display: flex;
`
const Categories = styled.div`
    width: ${CategoriesWidth}px;
    flex-shrink: 0;
    margin-right: ${Padding}px;
    border: 1px solid #ccc;
    padding: 0px;
    overflow: auto;  
    position: relative;

    > h2 {
        background-color: #f6f7f8;
        background-image: linear-gradient(#fff, #efefef);
        font-size: 16px;
        font-weight: 700;
        padding: 15px 10px;
        border-bottom: 1px solid #ccc;
        margin: 0px;
    }

    ul {
        list-style-type: none;
        margin: 0px 0px 25px 0px;
        padding: 0px;

        li {
            padding: 5px;
            border-bottom: 1px solid #ccc;
            cursor: pointer;
            font-size: 12px;
            display: flex;
            justify-items: center;
            justify-content: space-between;

            &:hover {
                background-color: #eee;
            }

            &.selected {
                background-color: #ccc;
            }
        }
    }

    > div {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding: 0px;

        button {
            padding: 8px;
            width: 100%;
        }
    }
`

const Contents = styled.div`
    width: 100%;
`

function ContentPanel(props) {
    const history = useHistory();
    const windowDimensions = useWindowDimensions();

    const [products, setProducts] = useState([]);
    const [parts, setParts] = useState([]);
    const [shippingMethods, setShippingMethods] = useState([]);

    const [category, setCategory] = useState(null);
    const [contents, setContents] = useState([]);
    const [sort, setSort] = useState({column: "list_order", dir: SortTypes.ASC});

    const [loading, setLoading] = useState(false);
    const [busyRow, setBusyRow] = useState(null);
    const [busy, setBusy] = useState("");
    const [selected, setSelected] = useState(null);

    const [filters, setFilters] = useState({});

    useEffect(
        () => {
            postData("content/categories", {},
                function(result) {
                    setProducts(result.products);
                    setParts(result.parts);
                    setShippingMethods(result.shippingMethods);
                },
                function(error) {
                    alert("Error loading data")
                }
            );   
        }, 
        []
    );

    useEffect(
        () => {
            if (category) {
                setLoading(true);

                postData("content/type", {type: category, ...filters},
                    function(result) {
                        setContents(result);
                    },
                    function(error) {
                        alert("Error loading content")
                    },
                    function() {
                        setLoading(false)
                    }
                );   
            }
        }, 
        [category, filters, sort]
    );

    function onEdit(content) {
        console.log("onEdit", content)
        setSelected({mode:"content", content})
    }

    function onRemove(content) {
        if (window.confirm("Delete this piece of content?")) {
            postData("content/delete", {content_id: content.id, type: category},
                function(result) {
                },
                function(error) {
                    alert("Error deleting content")
                }
            );  
        }
    }
 
    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }

    function onFilter(name, value) {
        setFilters(produce(draft => {
          draft[name] = value;
        }));
    }

    const haveToolbar = props.filterOptions;

    return (
        <>  
            <Interface>
                <Categories style={{height: windowDimensions.height - dimensions.headerHeight - dimensions.pageWithTabsPadding*2}}>
                    <h2>
                        Content Types
                    </h2>
                    <ul>
                        <li onClick={() => setCategory("installation")} className={category == "installation" ? "selected":""}>
                            Installation
                        </li> 
                        <li onClick={() => setCategory("construction")} className={category == "construction" ? "selected":""}>
                            Construction
                        </li>                         
                        <li onClick={() => setCategory("includes")} className={category == "includes" ? "selected":""}>
                            Includes
                        </li> 
                        <li onClick={() => setCategory("notincludes")} className={category == "notincludes" ? "selected":""}>
                            Not Included
                        </li>                         
                    </ul>
                    {category && 
                        <div>
                            <button onClick={() => setSelected({mode:"content", content: {type:category, id:0}})}>
                                Create New Content
                            </button>
                        </div>
                    }
                </Categories>
                <div>
                { loading ? 
                    <Loading /> 
                : category ?
                    <>
                    {props.filterOptions && 
                        <Toolbar>
                            <label>Product:</label>{' '}
                            <ListField 
                              name="product" 
                              value={filters.product_id} 
                              list={products}
                              onChange={(e) => {
                                onFilter("product_id", e.target.value);
                              }}
                              groupClassName="nomargin"
                              size="sm"
                            />
                        </Toolbar>
                    }
                    <Table
                        rowHeight={100}
                        rowsCount={contents.length}
                        width={windowDimensions.width - dimensions.dataTableWidthOffset}
                        height={windowDimensions.height - dimensions.dataTableHeightOffset}
                        headerHeight={50}
                        touchScrollEnabled={true}
                    >
                        <Column
                            header={<Cell>Preview</Cell>}
                            columnKey="preview_url"
                            cell={<GoogleImageCell data={contents} maxwidth={175} maxheight={60} />}
                            fixed={!isMobile}
                            width={140}
                        />
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    Title
                                </SortHeaderCell>
                              }
                            columnKey="title"
                            cell={<TextCell data={contents} small />}
                            width={300}
                        />
                        <Column
                            header={<Cell>Description</Cell>}
                            columnKey="description"
                            cell={<TextCell data={contents} small />}
                            width={300}
                        />
                        <Column
                            header={<Cell>Products</Cell>}
                            columnKey="product_list"
                            cell={<TextCell data={contents} small list />}
                            width={250}
                        />
                        <Column
                            header={<Cell>Parts</Cell>}
                            columnKey="part_list"
                            cell={<TextCell data={contents} small list />}
                            width={250}
                        />
                        <Column
                            header={<Cell>Optional</Cell>}
                            columnKey="optional_ind"
                            cell={<BooleanCell data={contents} />}
                            align="center"
                            width={100}
                        />
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    List Order
                                </SortHeaderCell>
                              }
                            columnKey="list_order"
                            cell={<TextCell data={contents} small />}
                            width={110}
                        />
                        <Column
                            header={<Cell>Actions</Cell>}
                            cell={
                                <EditActionsCell 
                                    data={contents}  
                                    actions={[
                                        {action:"edit", icon:faPencilAlt, title:"Edit", variant:"warning", permission:Permissions.ProductContent, permissionAction:Actions.Edit},
                                        {action:"delete", icon:faTrash, title:"Delete", variant:"danger", permission:Permissions.ProductContent, permissionAction:Actions.Delete}
                                    ]}
                                    busy={busyRow}
                                    onClick={(action,content) => {
                                        if (action == "edit")
                                            onEdit(content);
                                        else if (action == "delete")
                                            onRemove(content)
                                    }}
                                />
                            }
                            align="center"
                            width={110}
                        />
                    </Table>
                    </>
                :
                null
                }
                </div>
            </Interface>

            { selected && selected.mode == "content" && 
                <ContentDrawer 
                    content={selected.content} 
                    show={true}
                    type={category}
                    products={products}
                    parts={parts}
                    shippingMethods={shippingMethods}
                    onSaved={(content) => {
                        setContents(produce(draft => {
                            const index = contents.findIndex(c => c.id == selected.content.id);

                            if (index == -1)
                                draft.push(content)
                            else {
                                if (content.type != selected.content.type)
                                    draft.splice(index, 1)
                                else
                                    draft[index] = content;
                            }

                            draft.sort((a, b) => {
                                var keyA = a.title;
                                var keyB = b.title;

                                // Compare the 2 dates
                                if (keyA < keyB) return -1;
                                if (keyA > keyB) return 1;
                                return 0;
                            }); 
                        }));

                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
        </>
    );
}

export default ContentPanel;