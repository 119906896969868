import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faSave, faPencilAlt, faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons'

import TextAreaField from "../components/fields/field-textarea"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

const Notes = styled.div`
    margin-bottom: 15px;
    
    > div {
        border: 1px solid #eee;
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 5px;
        font-size: 12px;
    }
`

function HelpDrawer(props) {
  const formRef = useRef(null);

  let blankNote = {
      id: 0,
      type: "help-"+props.type,
      notes: ""
  }

  const [note, setNote] = useState(null);
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(blankNote);
  const [validated, setValidated] = useState(false);

  useEffect(
    () => {
      setIsLoading(true);

      postData("notes/data", { type: "help-"+props.type }, 
        function(response) {
          setNotes(response)
        },
        function(error) {
          alert("An error occured loading the notes.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }, 
    []
  );

  useEffect(
    () => {
        console.log("note changed", note, blankNote)
      setFormData(JSON.parse(JSON.stringify(note || blankNote)));
    }, 
    [note]
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("notes/save?id=" + (note ? note.id : 0), removeNulls(formData), 
        function(response) {
          setNotes(produce(draft => {
            const index = draft.findIndex(n => n.id == note.id)

            if (index != -1)
                draft[index] = response;
            else
                draft.push(response);
          }));

          setNote(null);

        },
        function(error) {
          alert("An error occured saving the note.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    
    const field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  function onDeleteNote(note) {
      setIsProcessing(note)

      postData("notes/delete", {note_id: note.id}, 
        function(response) {
          setNotes(produce(draft => {
            const index = draft.findIndex(n => n.id == note.id)

            if (index != -1)
                draft.splice(index, 1);
          }));

          setNote(null);
        },
        function(error) {
          alert("An error occured deleting the note.");
        },
        function() {
          setIsProcessing(false);
        }
      );
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faQuestionCircle} />
            &nbsp;
            Help Notes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Notes>
            {notes.map(note => (
                <div key={note.id}>
                    <FontAwesomeIcon icon={isProcessing == note ? faSpinner:faTrash} spin={isProcessing == note} onClick={() => onDeleteNote(note)} style={{float:"right", margin: "5px"}} />
                    <FontAwesomeIcon icon={faPencilAlt} onClick={() => setNote(note)} style={{float:"right", margin: "5px"}} />
                    {note.notes}
                </div>
            ))}
            </Notes>

            <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
                <TextAreaField 
                    name="notes" 
                    label="New Note" 
                    labelBold={true}
                    required
                    value={formData.notes} 
                    onChange={(e) => handleFormChange(e)} />

                <ButtonProcessing 
                    processing={isProcessing}
                    onClick={onSave} 
                    variant="outline-success" 
                    caption="Save Note" 
                    icon={faSave} /> 
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default HelpDrawer;