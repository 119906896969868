import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faFileAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom";

import Loading from "../../components/loading"
import Toolbar from "../../components/toolbar"
import UploadButton from "../../components/buttons/button-upload"

import { postData } from "../../common/services/server"
import { removeNulls, formatFileSize, formatDateTime } from "../../helpers/utility"
import storage from "../../settings/storage"
import { OverlayTrigger, Popover } from "react-bootstrap";

const Files = styled.div`
    display: flex;
    gap: 10px;
    flex-flow: wrap;

    > a {
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 150px;
        overflow: hidden;
        font-size: 80%;
        word-break: break-all;
        cursor: pointer;
        display: flex;
        flex-flow: column;    
        box-sizing: border-box;
        padding: 3px;
        position: relative;

        &:hover {
            background-color: #eee;
        }
    }

    img {
        width: 144px;
        height: 150px;
        object-fit: contain;
    }        

    svg {
        font-size: 75px;
        color: #ccc;
        display: block;
        margin: 20px auto;

        &.photo {
            position: absolute;
            right: 5px;
            top: 5px;
            font-size: 25px;
            margin: 0px;
            cursor: pointer;

            &.active {
                color: #ffb900;
            }
        }
    }

    div.file {
        flex: 1;
        display: flex;
        flex-flow: column;
    }
    div.props {
        margin: 5px;
        text-align: center;
    }
    div.preview {
        flex: 1;
    }
`

function FilesPanel(props) {
    const history = useHistory();
    
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(null);

    const [busy, setBusy] = useState([]);

    const haveToolbar = props.uploadPath && props.uploadPath.length > 0;

    useEffect(
        () => {
          setIsLoading(true);

          postData("files/data", removeNulls({ 
              ...props.filters,
          }),
            function(result) {
                setFiles(result);
            },
            function(error) {
                alert("Error loading files")
            },
            function() {
              setIsLoading(false);
            }
          );
        }, 
        [props.filters]
    );

    function onFileChanged(file) {
        const rowIndex = files.findIndex(selected.file)

        console.log("onFileChanged", file, rowIndex)

        setFiles(produce(draft => {
            if (rowIndex == -1)
                draft.push(file);
            else
                draft[rowIndex] = file;
        }));
    }
    function onAction(action, file) {
        if (action == "delete") {
            setBusy(produce(draft => {
                draft.push("delete" + file.id)
            }));

            postData(props.deletePath || "files/delete", {
                file_id: file.id
            },
                function(result) {
                    setFiles(produce(draft => {
                        const index = draft.findIndex(f => f.id == file.id);
                        draft.splice(index, 1);
                    }));
                },
                function(error) {
                    alert("Error deleting file.")
                },
                function() {
                    setBusy(produce(draft => {
                        const idx = draft.indexOf("delete" + file.id);

                        if (idx != -1) {
                            draft.splice(idx, 1);
                        }
                    }))
                }
            );
        }
    }

    function onStar(file, active) {
        setBusy(produce(draft => {
            draft.push("star" + file.id)
        }));

        postData("jobs/" + (active ? "removefilefrommap":"addfiletomap"), {
            file_id: file.id
        },
            function(result) {
                setFiles(produce(draft => {
                    const idx = draft.findIndex(f => f.id == file.id);
                    draft[idx].photo_id = result.id;
                }));
            },
            function(error) {
                alert("Error updating photo.")
            },
            function() {
                setBusy(produce(draft => {
                    const idx = draft.indexOf("star" + file.id);

                    if (idx != -1) {
                        draft.splice(idx, 1);
                    }
                }))
            }
        );
    }

    function onCreate() {
        console.log("create")
        setSelected({mode:"create", file:{}});
    }

    function onFileUploaded(result) {
        setFiles(produce(draft => {
            draft.push(result);
        }));
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :
                <>    
                {haveToolbar && 
                    <Toolbar>
                        <UploadButton
                            uploadUrl={props.uploadPath}
                            label={"Upload File" + (props.uploadMultiple?"s":"")}
                            multiple={props.uploadMultiple}
                            onUpload={onFileUploaded}
                        /> 
                    </Toolbar>
                }
                    <Files>
                    {files.map(file => (                                                
                        <a key={file.id} href={storage.root + file.url} target="_blank">
                            <OverlayTrigger overlay={
                                <Popover style={{backgroundColor: "black", color: "white", borderRadius: "5px", padding: "10px"}}>
                                    <div><span style={{color: "#ccc"}}>File Size:</span> {formatFileSize(file.filesize || 0)}</div>
                                    <div><span style={{color: "#ccc"}}>Date Added:</span> {formatDateTime(file.added_date)}</div>
                                    <div><span style={{color: "#ccc"}}>Added By:</span> {file.added_user_name}</div>
                                    <div><span style={{color: "#ccc"}}>Source:</span> {file.source}</div>
                                </Popover>
                            }>
                                <div className="file">
                                    <div className="preview">
                                        {file.image_ind == 1 ? 
                                            <img src={storage.root + (file.preview_url || file.url)} />
                                        :
                                            <FontAwesomeIcon icon={faFileAlt} />
                                        }
                                    </div>

                                    <div className="props">
                                        {file.filename}
                                    </div>

                                    {(props.photos && file.image_ind == 1) && 
                                        <FontAwesomeIcon 
                                            icon={busy.indexOf("star" + file.id) == -1 ? faStar : faSpinner} 
                                            spin={busy.indexOf("star" + file.id) != -1}
                                            className={"photo" + (file.photo_id ? " active":"")} 
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                onStar(file, file.photo_id ? true : false);
                                            }}
                                        />
                                    }
                                </div>
                            </OverlayTrigger>
                        </a>
                    ))}
                    </Files>
                </>
            }
        </>
    );
}

export default FilesPanel;