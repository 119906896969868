import React, { useState, useEffect } from "react";
import produce from "immer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faEnvelope, faSpinner} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import DateField from "../components/fields/field-date"
import TimeField from "../components/fields/field-time"

import Loading from "../components/loading"
import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import settings from '../settings'

import { postData, postExternalData } from "../common/services/server"

function EmailTemplateSendDrawer(props) {
  const [processing, setProcessing] = useState("loading");
  const [option, setOption] = useState("admins");

  const [scheduledDate, setScheduledDate] = useState(moment.utc().format('YYYY-MM-DD'));
  const [scheduledTime, setScheduledTime] = useState(null);
  const [included, setIncluded] = useState([]);
  const [includeOptions, setIncludeOptions] = useState({});
  const [excluded, setExcluded] = useState([]);
  const [excludeOptions, setExcludeOptions] = useState({});

  const [matchCount, setMatchCount] = useState(null);

  const [sent, setSent] = useState(false);

  const options = [
    {
        name: "admins",
        label: "Admins",
        days: false
    },
    {
        name: "active",
        label: "Active",
        description: "User has saved or updated a sign."
    },
    {
        name: "notactive",
        label: "Not Active",
        description: "User registered in the last year, but hasn't been active."
    },
    {
        name: "cart",
        label: "Shopping Cart",
        description: "User has an active shopping cart."
    },
    {
        name: "ordered",
        label: "Ordered",
        description: "User has placed an order."
    },
    {
        name: "notordered",
        label: "Not Ordered",
        description: "User hasn't placed an order."
    },
    {
        name: "partners",
        label: "Partners",
        description: "User is assigned a partner coupon.",
        days: false
    }
  ]

  useEffect(
    () => {
        setProcessing("included");

        postExternalData(settings.SITE_API_URL +"emails/templatesendcount", {
            included: included.join(","),
            excluded: excluded.join(","),
            ...includeOptions,
            ...excludeOptions
        }, 
            function(response) {
                setMatchCount(response);
            },
            function(error) {
                alert("An error occured loading template send matches.");
            },
            function() {
                setProcessing("");
            }
        );      
    }, 
    [included, includeOptions, excluded, excludeOptions]
  );

  function onSend() {
       if (window.confirm("Are you sure you want to send this email template to the " + matchCount + " selected users?")) {
        setProcessing("sending");

        postExternalData(settings.SITE_API_URL + "emails/sendtemplate", { 
            template_id: props.template.id,
            scheduled_date: scheduledDate,
            scheduled_time: scheduledTime,
            included: included.join(","),
            excluded: excluded.join(","),
            ...includeOptions,
            ...excludeOptions            
        },
            function(result) {
                setSent(true);
            },
            function(error) {
                alert("Error sending email template.")
            },
            function() {
                setProcessing("");
            }
        );
      }
  }

  function onIncludeChange(e) {
        const name = e.target.value;

        if (e.target.checked)
            setIncluded(produce(draft => {
                draft.push(name)
            }))
        else {
            let index = included.findIndex(i => i == name)

            if (index != -1) {
                setIncluded(produce(draft => {
                    draft.splice(index, 1);
                }))    
            }
        }
  }
  function onExcludeChange(e) {
    const name = e.target.value;

    if (e.target.checked)
        setExcluded(produce(draft => {
            draft.push(name)
        }))
    else {
        let index = excluded.findIndex(i => i == name)

        if (index != -1) {
            setExcluded(produce(draft => {
                draft.splice(index, 1);
            }))    
        }
    }
  }  
  function onIncludeOptionChange(option, value) {
    setIncludeOptions(produce(draft => {
        draft[option] = value;
    }))
  }
  function onExcludeOptionChange(option, value) {
    setExcludeOptions(produce(draft => {
        draft[option] = value;
    }))
  }  

  return (
      <Modal show={props.show} enforceFocus={false} onHide={props.onHide} size="lg" backdrop="static" keyboard={false} scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faEnvelope} />
            &nbsp;
            Send Email Template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {processing == "loading" ?
                <Loading />
            : sent ? 
                <>
                    <p style={{color:"green"}}>
                        The template has been added to the pending email queue for the selected users.
                    </p>
                    <p>
                        Queued emails are sent every hour to part of the queue.  So, it could take 
                        a while for all of them to be sent if a large number of users were selected.
                    </p>
                </>
            :
                <>
                    <h5>
                        Included:
                    </h5>

                    {options.map((option) => (
                        <div key={option.name} style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <div>
                                <Form.Check 
                                    name="included"
                                    value={option.name}
                                    checked={included.find(i => i == option.name) || false}
                                    label={option.label}
                                    type="checkbox" 
                                    onChange={onIncludeChange}
                                /> 
                                {option.description && 
                                    <small style={{display: "block", marginLeft: "20px", marginTop: "-5px", color: "#999"}}>
                                        {option.description}
                                    </small>                            
                                }
                            </div>
                            {(option.days !== false && included.find(i => i == option.name)) && 
                                <FormControl 
                                    as="select" 
                                    name={"include_" + option.name + "_days"}
                                    value={includeOptions["include_" + option.name + "_days"] || 30} 
                                    onChange={(e) => onIncludeOptionChange("include_" + option.name + "_days", e.target.value)}
                                    style={{width: "auto"}}
                                >    
                                    <option value={30}>Last 30 Days</option>
                                    <option value={60}>Last 60 Days</option>
                                    <option value={182}>Last 6 Months</option>
                                    <option value={365}>Last Year</option>
                                </FormControl>
                            }                            
                        </div>
                    ))}
                   
                   <h5>
                        Excluded:
                    </h5>

                    {options.map((option) => (
                        <div key={option.name} style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <div>
                                <Form.Check 
                                    name="excluded"
                                    value={option.name}
                                    checked={excluded.find(i => i == option.name) || false}
                                    label={option.label}
                                    type="checkbox" 
                                    onChange={onExcludeChange}
                                /> 
                                {option.description && 
                                    <small style={{display: "block", marginLeft: "20px", marginTop: "-5px", color: "#999"}}>
                                        {option.description}
                                    </small>                            
                                }
                            </div>
                            {(option.days !== false && excluded.find(i => i == option.name)) && 
                                <FormControl 
                                    as="select" 
                                    name={"exclude_" + option.name + "_days"}
                                    value={excludeOptions["exclude_" + option.name + "_days"] || 30} 
                                    onChange={(e) => onExcludeOptionChange("exclude_" + option.name + "_days", e.target.value)}
                                    style={{width: "auto"}}
                                >    
                                    <option value={30}>Last 30 Days</option>
                                    <option value={60}>Last 60 Days</option>
                                    <option value={182}>Last 6 Months</option>
                                    <option value={365}>Last Year</option>
                                </FormControl>
                            }                            
                        </div>
                    ))}                   
            
                    <div style={{margin: '20px 0px'}}>
                        {processing == "included" ? 
                            <div><FontAwesomeIcon icon={faSpinner} spin /> ...</div>
                        : matchCount && 
                            <div><strong>{matchCount}</strong> Users Matched</div>
                        }
                    </div>


                    <DateField 
                        name="scheduled_date"                         
                        prepend="Scheduled Date"
                        required
                        value={scheduledDate} 
                        onChange={(e) => {
                            setScheduledDate(e.target.value);
                        }} 
                    />
                    <TimeField 
                        name="scheduled_time" 
                        prepend="Scheduled Time" 
                        value={scheduledTime} 
                        onChange={(e) => {
                            setScheduledTime(e.target.value);
                        }} 
                    />
                </>   
                             
            }
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={processing == "sending"}
                onClick={onSend} 
                variant="outline-success" 
                caption="Send Template" 
                disabled={sent || (included.length == 0 && excluded.length == 0)}
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                {sent ? "Close" : "Cancel"}
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default EmailTemplateSendDrawer;