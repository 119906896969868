import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import produce from "immer"
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faStar, faSpinner, faPencilAlt } from "@fortawesome/free-solid-svg-icons"

import ChartBox from "../charts/chartbox"
import PhotoDrawer from "../../drawers/photo";

import { postData } from "../../common/services/server"
import storage from "../../settings/storage"
import { Alert } from "react-bootstrap";

const Photos = styled.div`
    display: flex;
    gap: 10px;
    flex-flow: wrap;

    > div {
        border: 1px solid #ccc;
        border-radius: 5px;
        width: 150px;
        overflow: hidden;
        font-size: 80%;
        word-break: break-all;
        cursor: pointer;
        display: flex;
        flex-flow: column;    
        box-sizing: border-box;
        padding: 3px;
        position: relative;

        &:hover {
            background-color: #eee;
        }
    }

    img {
        width: 144px;
        height: 150px;
        object-fit: contain;
    }        

    svg {
        font-size: 75px;
        color: #ccc;
        display: block;
        margin: 20px auto;

        &.photo {
            position: absolute;
            right: 5px;
            top: 5px;
            font-size: 25px;
            margin: 0px;
            cursor: pointer;

            &.active {
                color: #ffb900;
            }
            &.edit {
                color: black;
                top: 35px;
                font-size: 20px;
                right: 8px;
            }
        }
    }

    div.file {
        flex: 1;
        display: flex;
        flex-flow: column;
    }
    div.props {
        margin: 5px;
        text-align: center;
    }
    div.preview {
        flex: 1;
    }
`

function NewPhotosWidget(props) {
    const history = useHistory();

    const [photos, setPhotos] = useState([]);
    const [photo, setPhoto] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [busy, setBusy] = useState([]);
    const [error, setError] = useState(null);

    useEffect(
        () => {
            setLoading(true);

            postData("photos/new", {},
                function(result) {
                    setPhotos(result);
                },
                function(error) {
                    setError("Error loading photos")
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        [refresh]
    );  

    function onStar(photo, active) {
        setBusy(produce(draft => {
            draft.push("star" + photo.id)
        }));

        postData("jobs/" + (active ? "removefilefrommap":"addfiletomap"), {
            file_id: photo.file_id
        },
            function(result) {
                setPhotos(produce(draft => {
                    const idx = draft.findIndex(f => f.id == photo.id);

                    if (idx != -1)
                        draft.splice(idx, 1);
                }));
            },
            function(error) {
                alert("Error updating photo.")
            },
            function() {
                setBusy(produce(draft => {
                    const idx = draft.indexOf("star" + photo.id);

                    if (idx != -1) {
                        draft.splice(idx, 1);
                    }
                }))
            }
        );
    }

    return (
        <>
            <ChartBox 
                icon={faImage}
                title="New Photos" 
                loading={loading}
                error={error}
                refresh={true}
                onRefresh={() => setRefresh(refresh++)}
            >
                <Photos>
                {photos.map(photo => (
                    <div key={photo.id}>
                        <div className="file">
                            <div className="preview">
                                <a href={storage.root + photo.url} target="_blank">
                                    <img src={storage.root + (photo.preview_url || photo.url)} />
                                </a>
                            </div>

                            { photo.job_id &&
                                <div className="props">
                                    <div>
                                        <Link to={"/jobs/job/" + photo.job_id}>
                                            {photo.job_title || photo.job_id}
                                        </Link>
                                    </div>
                                    {photo.job_post_photos_ind == 0 && 
                                        <Alert size="sm" variant="danger" style={{padding: "0px"}}>
                                            No Online
                                        </Alert>
                                    }
                                </div>
                            }                            

                            <FontAwesomeIcon 
                                icon={busy.indexOf("star" + photo.id) == -1 ? faStar : faSpinner} 
                                spin={busy.indexOf("star" + photo.id) != -1}
                                className="photo star active" 
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    onStar(photo, true);
                                }}
                            />
                            <FontAwesomeIcon 
                                icon={faPencilAlt} 
                                className="photo edit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    setPhoto(photo);
                                }}
                            />                            
                        </div>                        
                    </div>
                ))}
                </Photos>
                {(!loading && photos.length == 0) && 
                    <p style={{textAlign:"center"}}>
                        No photos found.  View <Link to="/photos">all photos</Link>.
                    </p>
                }
            </ChartBox>

            { photo && 
                <PhotoDrawer 
                    photoIds={[photo.id]}
                    show={true}
                    onSaved={(photoData) => {
                        // remove it now that it has been processed
                        setPhotos(produce(draft => {
                            const idx = draft.findIndex(f => f.id == photo.id);
        
                            if (idx != -1)
                                draft.splice(idx, 1);
                        }));                        
                        setPhoto(null);
                    }}
                    onNewKeyword={(keyword) => {

                    }}
                    onHide={() => setPhoto(null)} 
                /> 
            }            
        </>
    );
}

export default NewPhotosWidget