import React, { useState } from "react";
import styled from 'styled-components'

import Form from 'react-bootstrap/Form';

import produce from "immer"

import Button from "../buttons/button";
import Dropzone from "../dropzone"
import FileUpload from "./field-fileupload"
import FieldLabel from "./label"
import HelpText from "./help-text"

const Upload = styled.div`
    
`
const Content = styled.div`
    display: flex;
`
const Actions = styled.div`
    text-align: right;
`
const Files = styled.div`
    flex: 1;
`

function FieldFilesUpload(props) {
    const [files, setFiles] = useState([]);

    function onFilesAdded(newfiles) {
        console.log("dropzone onFilesAdded", newfiles);

        if (props.variant === "simple") {
          // don't keep around old ones
          setFiles(newfiles);
        }
        else {
          setFiles(produce(files, draft => {
              console.log("isarray", Array.isArray(newfiles))

              newfiles.forEach((file) => {
                draft.push(file);
              })
          }));
        }
    }

    function onClear() {
        setFiles([]);
    }

    function renderActions() {
        if (files.length && !props.autoClear) {
            return (
              <Button onClick={onClear} variant="outline-secondary" size="sm">
                Clear
              </Button>
            );
        } 
    }

    let groupStyle = props.groupStyle || {};

    if (props.layout == "horizontal") {
        groupStyle.display = "grid";
        groupStyle.gridTemplateColumns = "125px auto";
        groupStyle.alignItems = "center";
    }

    return (
      <Upload>
        {props.label ? 
          <Form.Group style={groupStyle}>
              <FieldLabel 
                  required={props.required} 
                  bold={props.labelBold}
                  style={props.labelStyle || {marginBottom: "0px"}}
              >
                  {props.label}
              </FieldLabel>
              <div>
                <Dropzone 
                  variant={props.variant} 
                  size={props.size}
                  label={props.label}
                  onFilesAdded={onFilesAdded} 
                />
              </div>         
              {(props.instructions) && <HelpText>{props.instructions}</HelpText>}
          </Form.Group>
        :
            <div>
              <Dropzone 
                variant={props.variant} 
                size={props.size}
                label={props.label}
                onFilesAdded={onFilesAdded} 
              />
            </div>         
        }

        <Content>
          <Files>
            {files.map(file => {
              return (
                <FileUpload 
                  variant={props.variant} 
                  thumbnail={props.thumbnail}
                  key={file.name} 
                  name={props.name} 
                  file={file} 
                  external={props.external}
                  uploadUrl={props.uploadUrl} 
                  onUpload={(file) => {
                    if (props.onUpload) {
                      props.onUpload(file);

                      if (props.autoClear) {
                        onClear();
                      }
                    }
                  }} 
                />
              )
            })}
          </Files>
        </Content>
        <Actions>{renderActions()}</Actions>
      </Upload>
    )
}

export default FieldFilesUpload