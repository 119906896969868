import React, { useState, useEffect } from "react";
import produce from "immer"
import { faPencilAlt, faTrash, faUser, faListAlt, faClone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    CurrencyCell, 
    TextCell,
    ButtonCell, 
    LinkCell,
    GoogleImageCell, 
    UserCell,
    FilterHeaderCell,
    SortHeaderCell,    
    SortTypes,
    EditActionsCell 
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Loading from "../../components/loading"
import Toolbar from "../../components/toolbar"
import Paginator from "../../components/paginator"

import SignTemplateDrawer from "../../drawers/drawer-signtemplate";
import SignProductsDrawer from "../../drawers/drawer-signproducts";

import { postData } from "../../common/services/server"
import { removeNulls, formatDate } from "../../helpers/utility"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

import settings from "../../settings"

function SignTemplatesPanel(props) {
    const history = useHistory();
    const windowDimensions = useWindowDimensions();

    const [signs, setSigns] = useState([]);
    const [sort, setSort] = useState({column: "added_date", dir: SortTypes.DESC});
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({page:1,pages:1,total:0});
    const [isLoading, setIsLoading] = useState(false);
    const [busyRow, setBusyRow] = useState(null);
    const [filters, setFilters] = useState({designNo: "", added_date: props.filters ? props.filters.date : null });
    const [selected, setSelected] = useState(null);

    const haveToolbar = props.buttons && props.buttons.length > 0;

    useBodyClass(`noscroll`);

    function loadSigns(options) {
        setIsLoading(true);

        postData("signs/templates", removeNulls({ 
            ...filters,
            sort: sort.column,
            sortdir: sort.dir,
            page: page,
            perPage: 100,
            ...props.filters,
            ...options
        }),
            function(result) {
                setSigns(result.signs);
                setPagination(result.pagination)
            },
            function(error) {
                alert("Error loading signs")
            },
            function() {
                setIsLoading(false);
            }
        );
    }

    useEffect(
        () => {
            loadSigns();
        }, 
        [props.filters, page, sort]
    );

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }

    function onSign(sign) {
        if (sign.id)
            history.push("/signs/sign/" + sign.id);
    }

    function onAction(action, sign) {
        console.log("action", action, sign)
    
        if (action == "edit")
            setSelected({mode: "sign", sign})
        else if (action == "delete") {
            setBusyRow(sign);

            postData("signs/delete", { 
                sign_id: sign.id
            },
                function(result) {
                    setSigns(produce(draft => {
                        draft.splice(draft.findIndex(s => s.id == sign.id), 1);
                    }));
                },
                function(error) {
                    alert("Error deleting sign")
                },
                function() {
                    setBusyRow(null);
                }
            );
        }
    }

    function onFilterChange(filter, value) {
        setFilters(produce(draft => {
            draft[filter] = value
        })); 
    }

    function onFilter() {
        loadSigns({page:1})
    }

    function onProducts(sign) {
        setSelected({mode: "products", sign})
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
            <>
                {haveToolbar && 
                    <Toolbar buttons={props.buttons} onButton={props.onButton} />
                }
                <Table
                    rowHeight={75}
                    rowsCount={signs.length}
                    width={windowDimensions.width - dimensions.dataTableWidthOffset}
                    height={windowDimensions.height - dimensions.dataTableHeightOffset}
                    headerHeight={70}
                    touchScrollEnabled={true}
                >
                    <Column
                        header={
                            <FilterHeaderCell filter={filters.designNo} onFilterChange={(value) => onFilterChange("designNo", value)} onFilter={onFilter} sort={sort} onSortChange={onSortChange}>
                                 Design #
                            </FilterHeaderCell>
                        }
                        columnKey="id"
                        fixed={!isMobile}
                        cell={
                            <LinkCell 
                                data={signs} 
                                urlPrefix="#/signs/sign/"
                                onClick={(sign) => onSign(sign)} 
                            />
                        }
                        width={125}
                    />
                    <Column
                        header={<Cell>Preview</Cell>}
                        columnKey="preview_url"
                        cell={<GoogleImageCell data={signs} maxwidth={125} maxheight={35} />}
                        width={150}
                    />
                    <Column
                        header={<Cell>Created</Cell>}                        
                        columnKey="user_name"
                        cell={({rowIndex, ...props}) => (
                            <Cell>
                                {signs[rowIndex].user_email}
                                <div><small>{formatDate(signs[rowIndex].added_date_local, "MM/dd/yyyy", true)}</small></div>
                            </Cell>
                        )}
                        width={225}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Cost
                            </SortHeaderCell>
                        }
                        columnKey="estimate"
                        cell={<CurrencyCell data={signs} />}
                        width={100}
                    />
                    <Column
                        header={<Cell>Products</Cell>}
                        columnKey="product_count"
                        cell={<ButtonCell data={signs} onClick={(sign) => onProducts(sign)} />}
                        width={90}
                    />
                    <Column
                        header={<Cell>Landing Pages</Cell>}
                        columnKey="pages"
                        cell={<TextCell data={signs} small />}
                        width={325}
                    />                                       
                    <Column
                        header={<Cell>Actions</Cell>}
                        cell={
                            <EditActionsCell 
                                data={signs} 
                                actions={[
                                    {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, permission:Permissions.SignTemplates, permissionAction:Actions.Edit},
                                    {action:"delete", title:"Delete", variant:"danger", icon:faTrash, permission:Permissions.SignTemplates, permissionAction:Actions.Delete},
                                ]}
                                busy={busyRow}
                                onClick={onAction} 
                            />
                        }
                        align="center"
                        width={175}
                    />
                </Table>
                <Paginator
                    {...pagination}
                    item="Template"
                    items="Templates"
                    onPage={(page) => setPage(page)}
                />
                { selected && selected.mode == "sign" && 
                    <SignTemplateDrawer 
                        show={true}
                        sign={selected.sign}
                        onSaved={(signData) => {
                            setSigns(produce(draft => {
                                const index = draft.findIndex(s => s.id == signData.id);

                                if (index != -1) {
                                    draft[index] = signData;
                                }
                            }));

                            setSelected({mode:"sign", sign:signData});

                            //setSelected(null);
                        }}
                        onHide={ () => setSelected(null) } 
                    /> 
                }
                { selected && selected.mode == "products" && 
                    <SignProductsDrawer 
                        show={true}
                        sign={selected.sign}
                        onChanged={(productsCount) => {
                            setSigns(produce(draft => {
                                const signIndex = draft.findIndex(s => s.id == selected.sign.id);
                    
                                draft[signIndex].product_count = productsCount;
                            }));                          
                        }}
                        onHide={ () => setSelected(null) } 
                    /> 
                }                  
            </>
            }
        </>
    );
}

export default SignTemplatesPanel;