import React, { useState, useEffect } from "react";
import produce from "immer"
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserCheck, faUserAltSlash, faCog, faMinus, faPhoneSquare, faPhone, faMobile, faMobileAlt } from "@fortawesome/free-solid-svg-icons";

import Loading from "../../components/loading"
import UserSkillsDrawer from "../../drawers/drawer-userskills";
import StationUserDrawer from "../../drawers/drawer-stationuser";
import JobRoleTasksDrawer from "../../drawers/drawer-jobroletasks";
import JobTaskDrawer from "../../drawers/drawer-jobtask";

import { postData } from "../../common/services/server"
import { colors } from "../../settings/settings"
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatDate } from "../../helpers/utility";
import storage from "../../settings/storage";

const Layout = styled.div`
    display: grid;
    grid-template-columns: 50% 49%;
    gap: 10px;
`

const Users = styled.div`
    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;

        background-color: #ccc;
        border-radius: 5px;
        margin-bottom: 2px;
        font-size: 14px;
        padding: 5px 10px;

        > div {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        div.roles {
            display: flex;
            align-items: center;            
            gap: 5px;
            white-space: nowrap;

            flex-flow: wrap;
            font-size: 80%;
            justify-content: end;

            div {
                border: 1px solid #ccc;
                background-color: white;
                border-radius: 5px;
                padding: 2px 5px;
                cursor: pointer;

                &:first-child {
                    border: 2px solid black;
                }

                &.Inactive {
                    background-color: transparent;
                }

                &:hover {
                    border-color: ${colors.green};
                }
            }
        }

        a {
            color: black;
        }

        svg {
            font-size: 25px;  
            cursor: pointer;
            color: #eee;
            
            &:hover {
                color: ${colors.green};
            }
        }

        &.Active { 
            background-color: ${colors.lightGreen}; 

            svg {
                color: black;
            }
        }
    }

    svg.cog {
        font-size: 12px;
        color: #ccc !important;

        &:hover {
            color: black !important;
        }
    }
`
const Roles = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 5px;

    > div {
        border: 1px solid #ccc;
        padding: 0px;
        margin-bottom: 5px;

        &.users0 {
            border-color: red;
            
            h2 {
                background-color: ${colors.lightRed};
            }
        }

        h2 {
            display: flow-root;
            font-size: 80%;
            margin: 0px;
            padding: 3px;
            font-weight: normal;
            text-align: center;
            background-color: #eee;
        }

        > div {
            display: flex;
            flex-flow: wrap;
            font-size: 80%;
            gap: 5px;
            padding: 0px 5px 5px 5px;
            margin-top: 5px;

            > div {
                background-color: ${colors.lightGreen};
                border: 1px solid ${colors.lightGreen};
                padding: 2px 4px;
                border-radius: 3px;
                cursor: pointer;

                &:hover {
                    border-color: ${colors.green};
                }
            }            
        }

        .status {
            padding: 3px;
            border-radius: 3px;
            margin-left: 2px;
            font-size: 80%;
        }
    }
`

function JobsEmployeeRow({user, onToggle, onToggleRole, onChange}) {
    const [expanded, setExpanded] = useState(false);
    const [edit, setEdit] = useState(false);
    const [phone, setPhone] = useState(null);
    const [task, setTask] = useState(null);

    return (
        <>
            <div key={user.id} className={user.status}>
                <div>
                    <FontAwesomeIcon 
                        icon={user.status == "Active" ? faUserCheck : faUserAltSlash} 
                        onClick={() => {
                            onToggle(user);
                        }}
                    />
                    <div>
                        <div className="nowrap">
                            <a href={"#/users/user/" + user.id}>
                                {user.first_name} {user.last_name}
                            </a>
                            {' '}
                            {user.status == "Active" && 
                                <FontAwesomeIcon 
                                    icon={faMobileAlt} 
                                    style={{
                                        fontSize: "90%",
                                        color: "#999",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        setPhone(user)
                                    }}
                                />
                            }
                        </div>

                        <div className="flex gap-5 pill-list">
                            {user.tasks.filter(t => user.status=="Active" || t.assigned_user_id).map((task,index) => (
                                <div 
                                    key={index}  
                                    className={task.assigned_user_id == user.id ? "status-inprogress" : "status-active"}
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onClick={() => setTask(task)}
                                >
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip className="tooltip-props">
                                                <small>
                                                    <div><img src={storage.root + task.job_preview_url} style={{ maxHeight: "50px", maxWidth: "125px"}} /></div>
                                                    <div>{task.tasktype}</div>
                                                    <div>{task.role} Role</div>
                                                    <div><span>Due</span> {formatDate(task.target_date)}</div>
                                                </small>
                                            </Tooltip>
                                        }
                                    >                                                    
                                        <div>{task.job_title}</div>
                                    </OverlayTrigger>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                
                <div>
                    <div className="roles">
                        {user.status == "Active" && user.roles.filter(r => r.production && (expanded || r.status == "Active")).map(role => (
                            <div 
                                key={role.id} 
                                className={role.status}
                                onClick={() => {
                                    onToggleRole(user, role);
                                }}
                            >
                                {role.name}
                            </div>
                        ))}  
                    </div>
                    {user.status == "Active" &&
                        <Button
                            size="sm"
                            variant="outline-secondary"
                            style={{width: "35px"}}
                            onClick={() => {
                                setExpanded(prev => !prev);
                            }}
                        >
                            {expanded ? 
                                "-"
                            :
                                user.roles.filter(r => r.production && r.status == "Inactive").length
                            }
                        </Button>
                    }
                    <FontAwesomeIcon 
                        icon={faCog} 
                        className="cog"
                        onClick={() => setEdit(true)}
                    />   
                </div>
            </div>

            { edit && 
                <UserSkillsDrawer 
                    user={user}
                    onChange={onChange}
                    onHide={ () => setEdit(false) } 
                /> 
            }   
            { phone && 
                <StationUserDrawer 
                    user={phone}
                    onHide={ () => setPhone(null) } 
                /> 
            }  

            { task && 
                <JobTaskDrawer
                    job={{
                        id: task.job_id,
                        title: task.job_title,
                        preview_url: task.job_preview_url,
                    }}
                    jobId={task.job_id}
                    taskId={task.jobtask_id} 
                    show={true}
                    onSaved={(jobData) => {

                    }}
                    onHide={ () => setTask(null) } 
                /> 
            }                                                                   
        </>
    );
}

function JobsEmployees(props) {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(
        () => {
            setIsLoading(true);
            
            postData("users/production", {
                tasks_ind: 1
            },
                function(result) {
                    setUsers(result);
                },
                function(error) {
                    alert("Error loading employees")
                },
                function() {
                    setIsLoading(false);
                }
            );
            postData("roles/production", {
                current_tasks_ind: 1
            },
                function(result) {
                    setRoles(result);
                },
                function(error) {
                    alert("Error loading roles")
                }
            );            
        }, 
        []
    );

    function toggleUser(user) {
        let status = users.find(u => u.id == user.id).status == "Active" ? "Inactive" : "Active";

        setUsers(produce(draft => {
            let idx = draft.findIndex(u => u.id == user.id);

            if (idx != -1) {
                draft[idx].status = status;
            }
        }))

        if (status) {
            postData("users/setstatus", {
                user_id: user.id,
                status
            },
                function() {
                },
                function(error) {
                    alert("Error updating employee status")
                }
            );        
        }
    }

    function toggleUserRole(user, role) {
        console.log("toggleUserRole", user, role);

        setUsers(produce(draft => {
            let userIdx = draft.findIndex(u => u.id == user.id);
            let roleIdx = draft[userIdx].roles.findIndex(r => r.id == role.id);

            let userRole = draft[userIdx].roles[roleIdx];

            console.log({userIdx})
            console.log({roleIdx});
            console.log(userRole.id);

            userRole.status = userRole.status == "Active" ? "Inactive" : "Active";

            console.log(userRole.status);

            postData("users/setrolestatus", {
                user_id: user.id,
                role_id: userRole.id,
                status: userRole.status
            },
                function() {
                },
                function(error) {
                    alert("Error updating employee role status")
                }
            );              
        }))
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
                <Layout>
                    <div>
                        <Users>
                        {users.map(user => {
                            return (
                                <JobsEmployeeRow 
                                    key={user.id} 
                                    user={user}
                                    onToggle={toggleUser}
                                    onToggleRole={toggleUserRole}
                                    onChange={(rolesData) => {
                                        console.log({rolesData})
                                        setUsers(produce(draft => {
                                            const userIdx = draft.findIndex(u => u.id == user.id);
                
                                            if (userIdx != -1) {
                                                draft[userIdx].roles = rolesData;
                                            }
                                        }));
                                    }}
                                />
                            )
                        })}
                        </Users>
                    </div>
                    <Roles>
                        {roles.map(role => {
                            const roleUsers = users.filter(u => u.status=='Active' && u.roles.findIndex(r => r.name == role.name && r.status == "Active") != -1);

                            return (
                                <div key={role.id} className={"users" + roleUsers.length}>
                                    <h2>
                                        {role.name}

                                        {Object.keys(role.tasksByStatus).map((status,i)=>{
                                            return (
                                                <div 
                                                    key={i}
                                                    className={"status status-" + status}
                                                    style={{
                                                        float:"right",
                                                        cursor:"pointer"
                                                    }}
                                                    onClick={() => setRole(role)}
                                                >
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>{role.tasksByStatus[status][0].status_title}</Tooltip>
                                                        }
                                                    >                                                    
                                                        <span>{role.tasksByStatus[status].length}</span>
                                                    </OverlayTrigger>
                                                </div>
                                            )
                                        })}
                                    </h2>
                                    <div>
                                        {roleUsers.map(user => (
                                            <div 
                                                key={user.id}
                                                onClick={() => {
                                                    toggleUserRole(user, role);
                                                }}
                                                className={user.tasks && user.tasks.filter(t => t.role_id == role.id && t.status_name=="inprogress").length > 0 ? "status-inprogress":""}                                               
                                            >
                                                <FontAwesomeIcon icon={faUser} />{' '}
                                                {user.first_name} {user.last_name.substring(0, 1)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        })}                        
                    </Roles>
                </Layout>
            }    

            { role && 
                <JobRoleTasksDrawer 
                    role={role}
                    show={true}
                    onChange={(jobData) => {
                        console.log({jobData})
                    }}
                    onHide={ () => setRole(null) } 
                /> 
            }                                           
        </>
    );
}

export default JobsEmployees;