import React, { useState, useEffect } from "react"
import { Chart } from 'react-google-charts'
import { format, subMonths, parseISO } from 'date-fns'

import ChartBox from "./chartbox"
import ListField from "../fields/field-list"

import { postData } from "../../common/services/server"
import { formatPrice } from "../../helpers/utility"

function OrdersCurrentMonthChart(props) {
    const [dates, setDates] = useState([]);
    const [date, setDate] = useState(format(new Date(), "M/1/yyyy"));
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [error, setError] = useState(null);

    useEffect(
        () => {
            let dateOptions = [];

            for (let i=0; i<12; i++) {
                const dateOption = subMonths(new Date(), i);

                dateOptions.push({value: format(dateOption, "M/1/yyyy"), label: format(dateOption, "MMMM yyyy")});
            }

            setDates(dateOptions);
        }, 
        []
    );

    useEffect(
        () => {
            setLoading(true);

            postData("orders/chart/month", {
                month: format(Date.parse(date), "M"),
                year: format(Date.parse(date), "yyyy")
            },
                function(result) {
                    setData(result);

                    let runningTotal = 0;

                    for (const row of result) {
                        if (row[1])
                            runningTotal = row[1];
                    }

                    setTotal(runningTotal);
                },
                function(error) {
                    setError("Error loading data")
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        [date, refresh]
    );

    return (
        <ChartBox 
            title={"Monthly Orders"}
            height={330}
            loading={loading}
            error={error}
            refresh={true}
            onRefresh={() => setRefresh(refresh++)}
        >
            <ListField 
                name="date" 
                value={date} 
                list={dates}
                onChange={(e) => {
                    setDate(e.target.value)
                }} 
            />

            <Chart 
                width={'100%'}
                height={'270px'}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={data} 
                options={{
                    chartArea: {width: '85%', height: '75%', top: 10, right: 0},
                    hAxis: {
                      title: 'Day',
                      showTextEvery: 5
                    },
                    vAxis: {
                      title: '',
                    },
                    tooltip: {isHtml: true},
                    series: {
                        0: { color: '#ff0000' },
                        1: { color: '#cccccc' },
                      }                    
                }}
            />

            {total && 
                <div style={{textAlign: "center", fontWeight: "bold", padding: "3px"}}>
                    Total for Month = {formatPrice(total)}
                </div>
            }
        </ChartBox>
    );
}

export default OrdersCurrentMonthChart