import React from "react";

import GroupPreview from "../components/group-preview"
import Panel from "./panel";
import { List, ListItem } from "../components/list"

import { useGetJobGroups } from "../api/queries/workflowjobs";
import Alert from "../components/alert";

export default function JobWorkflowGroupsPanel({jobId}) {
    const groupsQuery = useGetJobGroups(jobId);
    const groups = groupsQuery.data;

    return (
        <Panel query={groupsQuery}>
            {groups &&
                <div className="flex gap-2">
                    <List>
                        {groups.map(group => (
                            <ListItem key={group.id} className="grid grid-cols-[125px,1fr] items-center">
                                <GroupPreview group={group} solid={true} width={100} height={50} />
                                <div className="space-y-1">
                                    <List direction="horizontal" className="items-stretch">
                                        <ListItem className="flex-col h-full">
                                            <div className="text-xs text-gray-400">Size</div>
                                            <div className="text-sm">{group.size}</div>
                                        </ListItem>
                                        <ListItem className="flex-col h-full">
                                            <div className="text-xs text-gray-400">Product</div>
                                            <div className="text-sm">{group.product}</div>
                                        </ListItem>
                                        <ListItem className="flex-col h-full">
                                            <div className="text-xs text-gray-400">Style</div>
                                            <div className="text-sm">{group.style}</div>
                                            {group.resembles_ind == 1 && 
                                                <div className="text-xs text-red-500">
                                                    * Similar
                                                </div>
                                            }
                                        </ListItem>
                                    </List>

                                    {group.multipiece_ind == 1 &&
                                        <Alert size="tiny" variant="danger">
                                            Group is multipiece
                                        </Alert>
                                    }
                                </div>
                            </ListItem>
                        ))}
                    </List>
                </div>
            }
        </Panel>
    )
}
