import React, { useEffect, useRef } from "react";
import styled from "styled-components"
import QRCode from "qrcode.react";

function MiniMwoSticker({job, mwo}) {
    const stickerRef = useRef();

    useEffect(
        () => {
            const canvas = stickerRef.current.children[0];
            var ctx = canvas.getContext("2d");

            let temp = ctx.getImageData(0,0,canvas.width,canvas.height)
            
            canvas.width = 100;

            ctx.putImageData(temp,0,0)

            canvas.style.border = "1px solid black"
            canvas.style.width = canvas.width + "px";
            canvas.style.height = canvas.height + "px";

            const padding = 4;
            const fontSize = 12;

            ctx.fillStyle = "black";
            ctx.font = fontSize+"px Arial";

            var textString = mwo.id,
            textWidth = ctx.measureText(textString).width;

            ctx.fillText(textString, (canvas.width/2) + ((canvas.width/4)-(textWidth/2)), fontSize + padding);

            if (mwo.total > 1) {
                textString = mwo.index + " of " + mwo.total;
                textWidth = ctx.measureText(textString).width;

                ctx.fillText(textString, (canvas.width/2) + ((canvas.width/4)-(textWidth/2)), canvas.height - padding - (fontSize/2));
            }

            var textString = mwo.short_title,
            textWidth = ctx.measureText(textString).width;

            ctx.fillText(textString, (canvas.width/2) + ((canvas.width/4)-(textWidth/2)), canvas.height - padding - (fontSize/2));
        }, 
        [stickerRef.current]
    );

    return (
        <div ref={stickerRef}>
            <QRCode 
                value={"Job:" + (job ? job.id:0) + ":MWO:" + mwo.name} 
                size={25} 
                renderAs="canvas" 
            />
        </div>
    )
}

export default MiniMwoSticker;