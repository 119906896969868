import React, { useState } from "react";

import ProductPermitDrawer from "../drawers/drawer-productpermit";

import BoardField from "../components/board/board-field"

function Panel({
  product,
  onChange
}) {
  const [permit, setPermit] = useState(null);
  const [busy, setBusy] = useState("");

  return (
    <>
      <BoardField
        type="googleimage"
        label="Product Icon"
        info="150px by 100px"
        infoWarning="Needs to be a JPG image file."
        value={"2020/products/" + product.name + "/icon.jpg?" + (new Date().getTime())}
        buttons={[
          {
              type: "upload",
              uploadUrl: "products/uploadimage?type=icon&product_id=" + product.id,
              busy: busy == "preview",
              onUpload: (result) => {
                 console.log("uploaded", result)
                 onChange({
                  preview_url: result.url + "?" + (new Date().getTime())
                 })
              }
          },
      ]}        
      />
       
      {permit && 
        <ProductPermitDrawer
            permit={permit}
            product={product}
            parts={product.parts}
            onSaved={(permitData) => {
              const permitsData = JSON.parse(JSON.stringify(product.permits));
              const permitIdx = permitsData.findIndex(p => p.id == permitData.id);
                  
              if (permitIdx != -1)
                permitsData[permitIdx] = permitData
              else 
                permitsData.push(permitData);
          
              onChange({
                  permits: permitsData
              })
              setPermit(null);
            }}
            onHide={ () => setPermit(null) } 
        />
      }            
    </>
  );
}

export default Panel;