import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import Content from "../components/content"
import PageHeader from "../components/page-header";

import { faCreditCard, faShapes } from "@fortawesome/free-solid-svg-icons";
import MyDashboard from "./dashboards/dashboard-mine";
import OrdersDashboard from "./dashboards/dashboard-orders";
import SignsDashboard from "./dashboards/dashboard-signs";
import { Permissions } from "../common/services/auth";
import PageLayout from "../components/page-layout";

function HomePage(props) {
  const history = useHistory();
  let { tab } = useParams();

  if (!tab) tab = "mine";

  //const [tab, setTab] = useState(tab)

  return (
    <PageLayout>
      <PageHeader 
          title="Dashboard"
          tab={tab}
          tabs={[
              {name:"mine", title:"Mine"},
              {name:"signs", title:"Signs", icon:faShapes, permission: Permissions.Signs},
              {name:"orders", title:"Orders", icon:faCreditCard, permission: Permissions.Orders},
          ]}
          onTab={(tab) => {
              history.push("/dashboard/" + tab);

              //setTab(tab)
          }}
      />
      <Content>
        {tab == "mine" && <MyDashboard />}
        {tab == "signs" && <SignsDashboard />}
        {tab == "orders" && <OrdersDashboard />}
      </Content>
    </PageLayout>
  );
}

export default HomePage;