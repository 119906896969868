import React, { useState, useEffect, useRef, createFactory } from "react";
import styled from "styled-components"
import { useLocation } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faStickyNote, faPlus, faExclamationCircle, faCreditCard, faSpinner, faThumbtack } from '@fortawesome/free-solid-svg-icons'

import TextAreaField from "../components/fields/field-textarea"
import ListField from "../components/fields/field-list"
import UserField from "../components/fields/field-user"

import Loading from "../components/loading";
import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import storage from '../settings/storage'
import { postData } from "../common/services/server"
import { removeNulls, getIdInfoFromUrl } from '../helpers/utility'

const Tags = styled.div`
    columns: 2 auto;
    margin-bottom: 8px;
`
const Associations = styled.ul`
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-flow: wrap;
    align-items: stretch;
    list-style-type: none;
    max-width: 460px;

    li {
      padding: 0px;
      margin: 0px 5px 5px 0px;
      max-width: 225px;

      div.input-group {
        margin: 0px;
      }
      button {
        min-width: 66px;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
      }
    }
`

function NoteDrawer(props) {
  const formRef = useRef(null);

  let blankNote = {
      id: 0,
      types: [],
      users: [],
      priority: 0
  }

  let location = useLocation();

  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(props.note || blankNote);
  const [validated, setValidated] = useState(false);

  const [isSearching, setIsSearching] = useState({});
  const [searchResults, setSearchResults] = useState({});

  const [types, setTypes] = useState([]);
  const [newType, setNewType] = useState("");

  const associationTypes = [
    {name: "users", column: "user_id", title: "User"},
    {name: "signs", column: "sign_id", title: "Sign"},
    {name: "projects", column: "project_id", title: "Project"},
    {name: "orders", column: "order_id", title: "Order"},
    {name: "jobs", column: "job_id", title: "Job"},
  ]

  useEffect(
    () => {
      console.log(props.note)
      if (props.note && props.note.id) {
        setLoading(true);

        postData("notes/notedata", {
          id: props.note.id
        },
          function(result) {
              setFormData(result);
          },
          function(error) {
              alert("Error loading note data")
          },
          function() {
            setLoading(false);
          }
        );        
      }
      else {
        const info = getIdInfoFromUrl(location.pathname);

        if (info.id && info.type) {
          setFormData(produce(draft => {
            draft[info.type + "_id"] = info.id;
            draft[info.type + "_title"] = "#" + info.id;
          }));
        }
      }

      postData("notes/types", {},
        function(result) {
            setTypes(result);
        },
        function(error) {
            alert("Error loading note data")
        },
        function() {
        }
      );
    }, 
    [props.note]
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("notes/save?id=" + (props.note ? props.note.id : 0), removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the note.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
   
    const field = event.target.name;
    let value = event.target.value;

    if (field == "user_id") {
        value = event.target.value.id;
    }

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  function handlePinnedChange(e) {
    const checked = e.target.checked;

    setFormData(produce(draft => {
      draft.pinned_ind = checked ? 1 : 0;
    }));
  }

  function onTypeChange(e) {
    console.log("type changed", e.target.value, e.target.checked)
    const type = e.target.value;
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.types ? draft.types.indexOf(type) : -1;

        if (index != -1)
            draft.types.splice(index, 1);
        if (!draft.types)
            draft.types = [];
        if (checked)
            draft.types.push(type);
    }));
  }

  function onNewType() {
    setTypes(produce(draft => {
        draft.push({id:0, type:newType, hidden_ind: 0});
    }));
    setFormData(produce(draft => {
        draft.types.push(newType);
    }));
    setNewType("");
  }

  function onAddAssociation(type) {
    if (!formData[type.title.toLowerCase() + "_title"]) {
      setFormData(produce(draft => {
        console.log(type.title, draft[type.column], draft[type.title.toLowerCase() + "_title"])

        if (draft[type.column] == 0)
          draft[type.column] = null;
        else
          draft[type.column] = 0;
      }));
    }
  }

  function handleSearch(type, query) {
    setIsSearching(produce(draft => {
      draft[type.name] = true;
    }));

    postData("search/" + type.name, {search: query},
        function(matches) {
            console.log("search", type.name, matches)
            
            setSearchResults(produce(draft => {
              draft[type.name] = matches;
            }));
        },
        function(error) {
            console.log(error);
        },
        function() {
          setIsSearching(produce(draft => {
            draft[type.name] = false;
          }));
        }
    );
  }

  function onAssociationChange(type, result) {
      console.log("onAssociationChange", type, result)
      setFormData(produce(draft => {
        draft[type.column] = result ? result.id : 0;
      }));
  }

  const haveJob = formData.job_id;

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" backdrop="static" keyboard={false} scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faStickyNote} />
            &nbsp;
            {props.note && props.note.id ? "Update":"Create"} Note
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? 
            <Loading />
          :
            <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef} className="grid gap-2">
              <TextAreaField 
                  name="notes" 
                  label="Note" 
                  labelBold={true}
                  required
                  value={formData.notes} 
                  autofocus
                  onChange={(e) => handleFormChange(e)} />

              {!props.internal &&
                <div className="mt-1">
                  <h6>
                    Associations
                    <strong style={{color:"red"}}>*</strong>
                  </h6>

                  <Associations>
                    {associationTypes.map(type => (
                    <li key={type.name}>
                      <InputGroup className="h-full">
                        <InputGroup.Prepend>
                          <Button 
                            type="button" 
                            onClick={(e) => {
                              e.stopPropagation();
                              onAddAssociation(type);
                            }} 
                            variant="outline-secondary" 
                            size="tiny" 
                            style={{width: "75px"}}>
                            {type.title}
                          </Button>
                        </InputGroup.Prepend>
                        { formData[type.column] != null && 
                          <AsyncTypeahead
                              id={"note_"+type.name+"_search"}
                              options={searchResults[type.name]}
                              useCache={false}
                              allowNew={false}
                              isLoading={isSearching[type.name] || false}
                              labelKey="title"
                              minLength={2} 
                              defaultInputValue={formData[type.title.toLowerCase() + "_title"] || ""}
                              onSearch={(query) => handleSearch(type, query)}
                              onChange={ (selected) => onAssociationChange(type, selected[0]) }
                              placeholder="Search..."
                              searchText={<><FontAwesomeIcon icon={faSpinner} spin />&nbsp;Searching...</>}
                              renderMenuItemChildren={(option, props) => (
                                  <div key={option.id} style={{display: "flex"}}>
                                    <img src={storage.root + (option.preview_url || "images/nothumbnail.png")} width={40} style={{minWidth:"40px", marginRight:"8px"}} />
                                    <div>
                                      { option.title }
                                    </div>
                                  </div>
                              )}
                              filterBy={(option, props) => {
                                return true;
                              }}
                          />
                        }
                      </InputGroup>
                    </li>
                    ))}
                  </Associations>
                </div>
              }

              <h6>Tags</h6>
              <Tags>
              {types.filter(t => t.hidden_ind == (haveJob ? 1:0)).map(type => (
                  <div key={type.id}>
                      <Form.Check 
                          name="type"                                
                          defaultValue={type.type}
                          checked={formData.types && formData.types.indexOf(type.type) != -1}
                          inline 
                          label={type.type} 
                          type="checkbox" 
                          onChange={onTypeChange}
                      />
                  </div>
              ))}
              </Tags>
              <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                      <InputGroup.Text>
                          Add Tag
                      </InputGroup.Text>
                  </InputGroup.Prepend>

                  <FormControl
                      value={newType}
                      onKeyPress={event => {                        
                          if (event.key === "Enter") {
                              event.preventDefault();
                              event.stopPropagation();
      
                              onNewType();
                          }
                      }}
                      onChange={(event) => {
                          setNewType(event.target.value);
                      }}
                  />
                  <InputGroup.Append>
                      <Button onClick={onNewType} disabled={newType.length == 0} variant="outline-secondary" size="sm">
                          <FontAwesomeIcon icon={faPlus} />
                      </Button>
                  </InputGroup.Append>
              </InputGroup>

              {formData.sign_id ? 
                <div>
                  <FontAwesomeIcon icon={faCreditCard} fixedWidth />
                  {' '}
                  <Form.Check 
                    checked={formData.priority  == 1}
                    label={"Include On Any Orders for Sign #" + formData.sign_id}
                    type="checkbox" 
                    inline
                    onChange={(e) => {
                      handleFormChange({
                        target: {
                          name: "priority",
                          value: e.target.checked ? 1:0
                        }
                      })
                    }}
                  />    
                </div>
              : formData.user_id ? 
                <div>
                  <FontAwesomeIcon icon={faCreditCard} fixedWidth />
                  {' '}
                  <Form.Check 
                    checked={formData.priority  == 1}
                    label="Include On Any Orders for User"
                    type="checkbox" 
                    inline
                    onChange={(e) => {
                      handleFormChange({
                        target: {
                          name: "priority",
                          value: e.target.checked ? 1:0
                        }
                      })
                    }}
                  />    
                </div>                
              :
                <ListField 
                  name="priority" 
                  label="Priority" 
                  labelBold={true}
                  required
                  value={formData.priority} 
                  type="radio"
                  list={[
                    {value: 0, label: "Normal"},
                    {value: 1, label: "Important"},
                    {value: 2, label: "Urgent", icon: faExclamationCircle},
                  ]}
                  onChange={(e) => handleFormChange(e)} 
                />  
              }

              <div>
                <FontAwesomeIcon icon={faThumbtack} fixedWidth />
                {' '}
                <Form.Check 
                    name="pinned_ind"         
                    checked={formData.pinned_ind == 1 || formData.pinned_ind == "on"}
                    label="Pin To My Dashboard"
                    inline
                    type="checkbox" 
                    onChange={handlePinnedChange}
                />
              </div>
            </Form>
          }
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success"                 
                caption="Save Note" 
                icon={faSave} 
                disabled={isProcessing || loading}
            /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default NoteDrawer;