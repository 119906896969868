import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetPhotosFromIds = (photoIds) => {  
  return useQuery({ 
    queryKey: ["photos", photoIds], 
    queryFn: ({signal}) => query("photos/listforids", {ids: photoIds.join(",")}, signal) 
  });
};

export const useGetPhotoCategories = () => {  
    return useQuery({ 
      queryKey: ["photocategories"], 
      queryFn: ({signal}) => query("photos/categories", {}, signal) 
    });
};
  
export const useGetPhotoProducts = () => {  
    return useQuery({ 
      queryKey: ["photoproducts"], 
      queryFn: ({signal}) => query("photos/products", {}, signal) 
    });
};

export const useGetPhotoPages = () => {  
    return useQuery({ 
      queryKey: ["photopages"], 
      queryFn: ({signal}) => query("photos/pages", {}, signal) 
    });
};