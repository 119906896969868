import React from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Pill({
    icon, 
    className,
    size,
    variant,
    children
}) {
  return (
    <div className={clsx(
        "border border-gray-300 bg-white px-2 py-1 rounded-full flex items-center gap-2",
        size == "sm" ? "text-xs" : "text-sm",
        variant == "danger" && "!bg-red-500 text-white",
        variant == "outline-danger" && "!border-red-500 text-red-500",
        className,
    )}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {children}
    </div>
  )
}
