import React, { useState } from "react";
import styled from "styled-components"

import Modal from 'react-bootstrap/Modal';

import Button from "../components/buttons/button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { getJobStatuses } from "../helpers/jobs";
import { colors } from "../settings/settings";
import { useWorkflowJobUpdateProp } from "../api/mutations/workflowjobs";

const Statuses = styled.div`
    display: grid;
    gap: 5px;

    > button {
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 8px;
        background-color: white;
        display: flex;
        align-items: center;
        gap: 5px;

        &:hover {
            background-color: #eee;
        }
        &.selected {
            border-color: ${colors.green};
            color: ${colors.green};
        }
    }
`

function WorkflowJobStatusDrawer(props) {
  const updateJobStatus = useWorkflowJobUpdateProp(props.job.id, "status_id");

  console.log(updateJobStatus)
  function onStatus(statusId) {
    updateJobStatus.mutate(statusId, {
      onSuccess: () => {
        props.onHide();
      },
      onError: () => {
        window.alert("Error updating status");
      }
    });
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faWrench} />
            &nbsp;
            Job Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Statuses>
            {getJobStatuses().map(status => (
                <button 
                    key={status.id}
                    className={status.id == props.job.status_id ? "selected":""}
                    onClick={() => onStatus(status.id)}
                    disabled={updateJobStatus.isLoading}
                >
                    <FontAwesomeIcon icon={updateJobStatus.isLoading && updateJobStatus.variables == status.id ? faSpinner : status.icon} spin={updateJobStatus.isLoading && updateJobStatus.variables == status.id} />
                    {status.title}
                </button>
            ))}
            </Statuses>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default WorkflowJobStatusDrawer;