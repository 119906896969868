import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faChevronRight, faSync } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import GoogleImage from "../components/google-image"
import GroupPreview from "../components/group-preview"
import ShapePreview from "../components/shape-preview"
import Loading from "../components/loading"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

const Sign = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;

  img { margin-right: 10px; }
`
const Buttons = styled.div`
  margin-top: 15px;
`

function JobShapesDrawer(props) {
  const formRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  
  const [sign, setSign] = useState(null)

  const [formData, setFormData] = useState({});

  const [step, setStep] = useState("groups");
  const [groups, setGroups] = useState([]);
  const [shapes, setShapes] = useState([]);

  const [validated, setValidated] = useState(false);

  useEffect(
    () => {
        setFormData({});

        if (props.job.sign_id) {
          setLoading(true)

          postData("signs/signdata", {id: props.job.sign_id}, 
            function(response) {
              setSign(response.sign);
            },
            function(error) {
              alert("An error occured loading sign data.");
            },
            function() {
              setLoading(false)
            }
          );
        }
        else {
          setStep("shapes")
          setLoading(false)
        }
    }, 
    [props.job]
  );

  function getShapeIds() {
    const ids = [];
    for (const shape of shapes) {
      ids.push(shape.signshape_id);
    }

    console.log("getShapeIds", ids)
    return ids;
  }

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onGroupChange(e) {
    console.log("group changed", e.target.value, e.target.checked)
    const groupId = e.target.value;
    const group = sign.groups.find(g => g.id == groupId);
    const checked = e.target.checked;

    setGroups(produce(draft => {
        const index = draft.findIndex(g => g.id == groupId);

        if (checked) {
          draft.push(group);
        }
        else if (index != -1) {
          draft.splice(index, 1)
        }
        
    }));

    setShapes(produce(draft => {
      for (const shape of group.shapes.filter(s => s.code != null && s.code != ' ')) {
        if (checked)
          draft.push(shape);
        else 
          draft.splice(draft.findIndex(s => s.signshape_id == shape.signshape_id), 1);
      }
    }));
  }
  function onShapeChange(e) {
    console.log("shape changed", e.target.value, e.target.checked)
    const shapeId = e.target.value;
    const checked = e.target.checked;

    setShapes(produce(draft => {
        const index = draft.findIndex(s => s.signshape_id == shapeId);

        if (checked) {
          for (const group of groups) {
            const shape = group.shapes.find(s => ""+s.signshape_id == ""+shapeId)

            console.log(shape)
            if (shape)
              draft.push(shape);
          }
        }
        else if (index != -1) {
          draft.splice(index, 1)
        }
        
    }));
  }

  function onReset() {
    setIsProcessing(true);

    const data = {...formData, shape_ids: getShapeIds() };

    console.log("formData", formData)
    postData("jobs/"+props.mode.toLowerCase()+"?id=" + props.job.id, removeNulls(data), 
      function(response) {
        props.onJobChange(response);
      },
      function(error) {
        alert("An error occured updating the job.");
      },
      function() {
        setIsProcessing(false);
      }
    );
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faSync} />
            &nbsp;
            Reset Job {props.mode == "ResetShapes" ? "Shapes":""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>

            <Sign>
                <GoogleImage 
                    root 
                    src={props.job.preview_url} 
                    style={{
                        maxWidth: "125px",
                        maxHeight: "75px"
                    }}
                />
            </Sign>
                
            {loading ? 
              <Loading />
            :
              <>
                {props.job.sign_id &&
                  <h5>
                    Job Groups
                    {(step == "shapes" || step == "props" || step == "tasks") && 
                      <Button variant="outline-secondary" size="sm" onClick={() => setStep("groups")} style={{float:"right"}}>
                        Edit
                      </Button>
                    }
                  </h5>
                }

                {step == "groups" &&
                  <>
                    {sign && sign.groups.map(group => (
                        <div key={group.id} style={{display: "flex", alignItems: "center", border: "1px solid #ccc", padding: "0px 10px", borderRadius:"4px", marginBottom: "5px"}}>
                            <Form.Check 
                                name="group_id"                                
                                value={group.id}
                                checked={groups.findIndex(g => g.id == group.id) != -1}
                                inline 
                                type="checkbox" 
                                onChange={onGroupChange}
                            />
                            <GroupPreview group={group} solid={true} width={100} height={50} />

                            <div style={{flex:"1", textAlign:"right"}}>
                              {group.product_title}
                            </div>
                        </div>
                    ))}
                    <Buttons>
                      <Button variant="outline-primary" disabled={groups.length == 0} onClick={() => setStep("shapes")}>
                        Continue {' '}
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Button>
                    </Buttons>
                  </>          
                }

                {(step != "groups") && 
                  <h5>
                    Job Shapes
                    {(step == "props" || step == "tasks") && 
                      <Button variant="outline-secondary" size="sm" onClick={() => setStep("shapes")} style={{float:"right"}}>
                        Edit
                      </Button>
                    }
                  </h5>
                }
                {step == "shapes" &&
                  <>
                    {groups.map(group => (
                      (group.shapes.filter(s => s.code != null && s.code != ' ').map(shape => (
                        <div key={shape.signshape_id} style={{display: "flex", alignItems: "center", border: "1px solid #ccc", padding: "5px 10px", borderRadius:"4px", marginBottom: "5px"}}>
                            <Form.Check 
                                name="shape_id"                                
                                value={shape.signshape_id}
                                checked={shapes.findIndex(s => s.signshape_id == shape.signshape_id) != -1}
                                inline 
                                type="checkbox" 
                                onChange={onShapeChange}
                            />
                            {shape.path && <ShapePreview path={shape.path} solid={true} width={50} height={35} />}
                            {!shape.path && <>{shape.code}</>}
                        </div>
                      )))
                    ))}
                    <Buttons>
                      <Button 
                        variant="outline-primary" 
                        disabled={shapes.length == 0 && props.job.sign_id} 
                        onClick={onReset}
                      >
                        <FontAwesomeIcon icon={isProcessing ? faSpinner:faSync} spin={isProcessing} />{' '}
                        Reset {props.mode == "ResetShapes" ? "Shapes":"Job"}                        
                      </Button>
                    </Buttons>
                  </>
                }

              </>
            }
          </Form>
        </Modal.Body>
      </Modal>
    )
}

export default JobShapesDrawer;