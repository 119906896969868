import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import BoardField from "../components/board/board-field"

function Panel({
  product,
  onChange
}) {

  return (
    <>
        <BoardField
            type="input"
            label="Video Code"
            value={product.install_video_code}
            saveUrl={`products/updateprop?product_id=${product.id}&prop=install_video_code`}
            onSaved={onChange}
            suffix={
            <a href={"https://www.youtube.com/watch?v=" + product.install_video_code} target="_blank">
                <FontAwesomeIcon icon={faExternalLinkAlt} />
                &nbsp;
                Youtube
            </a>
            }
        />    
        <BoardField
            type="input"
            label="Document Url"
            value={product.install_url}
            saveUrl={`products/updateprop?product_id=${product.id}&prop=install_url`}
            onSaved={onChange}
        />  
    </>
  );
}

export default Panel;