import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetStandardShapes = (options={}) => {
  return useQuery({ 
    queryKey: ["shapes", "standard"], 
    queryFn: ({signal}) => query("shapes/standard/list", {}, signal),
    ...options
  });
}

export const useGetCustomShapesForStyle = (styleId, options={}) => {
  return useQuery({ 
    queryKey: ["shapes", "custom", styleId], 
    queryFn: ({signal}) => query("shapes/custom/list", {style_id: styleId}, signal),
    ...options
  });
}
