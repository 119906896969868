import React from "react";
import { faPlus, faStar } from '@fortawesome/free-solid-svg-icons'

import MwoSticker from "../components/mwo-sticker"
import DrawerTemplateData from "./template-data";
import ButtonMutate from "../components/buttons/button-mutate";

import { colors } from "../settings/settings";
import { useGetMWOs } from "../api/queries/mwos";
import { getIconForType } from "../helpers/icons";

function WorkflowMwosDrawer({
    mwoName,
    mwoId,
    existingIds=[],
    params={},
    selectMutation,
    createMutation,
    onMutateSuccess,
    onHide
}) {
  const mwosQuery = useGetMWOs(params);
  const mwos = mwosQuery.data;

  return (
      <DrawerTemplateData
        icon={getIconForType("mwo")}
        title="Workflow MWOs"
        query={mwosQuery}
        onHide={onHide}
        buttons={createMutation ?
                <ButtonMutate
                    icon={faStar}
                    caption="Create New MWO"
                    size="sm"
                    variant="outline-success"
                    mutation={createMutation}
                    onMutateSuccess={onMutateSuccess}
                />                
            :
                null
        }
      >
        {mwos &&
            <>
                {mwos.map(mwo => (
                    <div key={mwo.id} style={{
                        padding: "10px",
                        borderBottom: "1px solid #ccc",
                        backgroundColor: (mwoId == mwo.id) ? colors.lightGreen : existingIds.includes(mwo.id) ? "#eee" : "white"
                    }}>
                        <h5>{mwo.title}</h5>

                        <div className="flex items-center justify-between gap-2">
                            <div style={{flex: 1}}>
                                <MwoSticker
                                    mwo={mwo}
                                    type={mwo.type}
                                    style={{
                                        margin: "0px",
                                        padding: "0px",
                                    }}
                                />
                            </div>
                            <div style={{padding: "10px"}}>
                                <ButtonMutate
                                    icon={faPlus}
                                    caption="Select"
                                    size="sm"
                                    variant="outline-success"
                                    disabled={existingIds.includes(mwo.id)}
                                    mutation={selectMutation}
                                    mutationData={mwo.id}
                                    onMutateSuccess={onMutateSuccess}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </>
        }
      </DrawerTemplateData>
    )
}

export default WorkflowMwosDrawer;