import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal';
import produce from "immer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faDesktop, faUserLock, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ListField from "../components/fields/field-list"
import { postData } from "../common/services/server";
import { colors } from "../settings/settings";
import Loading from "../components/loading";
import { parseISO } from "date-fns";
import { formatDate } from "../helpers/utility";
import storage from "../settings/storage";

const Tasks = styled.div`
  > div {
    display: grid;
    grid-template-columns: 200px 1fr;

    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 5px;

    > div:first-child {
      background-color: #eee;
      padding: 10px;
      text-align: center;
    }
    > div:last-child {
      > div {
        display: grid;
        grid-template-columns: 1fr 80px 150px;
        gap: 5px;
        border-bottom: 1px solid #eee;
        padding: 3px;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 90%;

            &:last-child {
                display: block;
            }
        }
        .late {
            background-color: ${colors.lightRed};
        }
      }
    }
  }
`
const Task = styled(Button)`
    margin: 0px;
    cursor: default !important;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    &:hover {
        background-color: initial;
        color: initial;
    }

    svg {
        margin-right: 5px;
    }
`

function WorkflowJobTasksDrawer(props) {
  const [jobs, setJobs] = useState([])
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState([]);

  useEffect(
    () => {      
        postData("jobs/taskstatuses", {},
            function(results) {
                setStatuses(results)
            },
            function(error) {
                alert("Error loading tasks statuses")
            }
        );       
    }, 
    []
  );

  useEffect(
    () => {      
      if (props.jobs) {
        let roleJobs = [];

        for (const job of props.jobs) {
            let jobTasks = job.tasks.filter(t => t.role_id == props.role.id && t.status_name != "completed" && t.status_name != "skipped" && t.status_name != "invalid");
            
            if (jobTasks.length > 0) {
                roleJobs.push({
                    ...job,
                    tasks: jobTasks
                });
            }
        }

        setJobs(roleJobs);
      }
      else {
        setLoading(true)

        postData("workflowjobs/active", {
            role_id: props.role.id,
            // active_ind: 1,
            // today_ind: 1,
        },
            function(result) {
                setJobs(result);
            },
            function(error) {
                alert("Error loading jobs")
            },
            function() {
                setLoading(false);
            }
        );        
      }
    }, 
    [props.jobs]
  );

  function removeBusy(name) {
    setBusy(produce(draft => {
      let idx = draft.indexOf(name);

      if (idx != -1) {
        draft.splice(idx, 1);
      }  
    }))
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faUserLock} />
            &nbsp;
            {props.role.name} Tasks
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loading ?
                <Loading />
            :
                <Tasks>
                {jobs.map(job => (
                <div key={job.id}>
                    <div>
                        <img src={storage.root + job.preview_url} height="30" style={{maxHeight: "20px", maxWidth: "100px"}} />                        

                        <div>
                            {job.title}
                            <a href={"#/jobs/job/" + job.id} target="_blank" style={{marginLeft: "5px"}}>
                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </a>
                        </div>
                    </div>
                    <div>
                    {job.tasks.map(task => (
                        <div key={task.id}>
                            <Task 
                                variant={
                                    task.status == "active" ? 
                                        "outline-success"
                                    : task.status == "completed" ? 
                                        "success"
                                    : task.status == "pending" ? 
                                        "outline-secondary"
                                    : task.status == "inprogress" ? 
                                        "primary"
                                    : task.status == "active" ? 
                                        "outline-primary"
                                    : task.status == "paused" ? 
                                        "outline-warning"
                                    : task.status == "problem" ? 
                                        "warning"
                                    : task.status == "qa" ? 
                                        "outline-info"
                                    : task.status == "skipped" ? 
                                        "secondary"
                                    : task.status == "deleted" || task.status == "invalid" ? 
                                        "dark"
                                    : "light"
                                }
                                size="sm" 
                                style={{
                                    textDecoration: task.status == "invalid" ? "line-through":"none"
                                }}
                            >
                                <div>
                                    {task.computer_ind == 1 && 
                                        <FontAwesomeIcon icon={faDesktop} />
                                    }
                                    {task.task}
                                    {task.status == "inprogress" &&
                                        <FontAwesomeIcon icon={faSpinner} spin={true} style={{marginLeft: "5px"}} />
                                    }
                                </div>
                            </Task>
                            <div className={task.late == 1 ? "late":""}>
                                {formatDate(parseISO(task.target_date), "EEE M/d")}
                            </div>
                            <div style={{
                                alignSelf: "center"
                            }}>
                                {task.status_title}
                                {/* {task.status == "pending" &&
                                    <div style={{
                                        color: "#999",
                                        fontSize: "80%"
                                    }}>
                                        on {task.dependent_name}
                                    </div>
                                }
                                {(task.status == "inprogress" && task.assigned_user) &&
                                    <div style={{
                                        color: "#999",
                                        fontSize: "80%"
                                    }}>
                                        by {task.assigned_user}
                                    </div>
                                }                                 */}
                            </div>

                            {/* <ListField 
                                name="status_id" 
                                value={task.status_id} 
                                list={statuses}
                                disabled={busy.indexOf("status" + task.id) != -1}
                                groupStyle={{
                                    margin: "0px"
                                }}
                                onChange={(e) => {
                                    e.persist();

                                    const statusId = e.target.value;

                                    setBusy(produce(draft => {
                                        draft.push("status" + task.id)
                                    }));

                                    postData("jobs/updatetaskprop", {
                                        job_id: job.id,
                                        jobtask_id: task.id,
                                        prop: "status_id",
                                        value: statusId
                                    },
                                        function(response) {
                                            props.onChange({
                                                ...response,
                                                ship_date: response.ship_date.split("T")[0]
                                            });
                                        },
                                        function(error) {
                                            alert("Error updating job task status")
                                        },
                                        function() {
                                        removeBusy("status" + task.id);
                                        }
                                    );
                                }} 
                            />                       */}
                        </div>
                    ))}
                    </div>
                </div>
                ))}              
                </Tasks>
            }
        </Modal.Body>
      </Modal>
    )
}

export default WorkflowJobTasksDrawer;