import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaintBrush, faCaretDown } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"

const Styles = styled.table`
    width: 100%;
    tr th {
        background-color: #e0e0e0;
        padding: 10px;
        font-size: 12px;
    }
    tr td {
        padding: 5px 10px;
        border-bottom: 1px solid #ccc;
        font-size: 12px;
    }
`

function SelectStylesDrawer({
    product, 
    existingStyles,
    onSelect, 
    onHide
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [styles, setStyles] = useState([]);

  useEffect(() => {
      setIsProcessing(true);

      postData("styles/data", {}, 
        function(results) {
          setStyles(results);
        },
        function(error) {
          alert("An error occured loading the styles.");
        },
        function() {
          setIsProcessing(false);
        }
      );   
  }, []);

  return (
    <>
        <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faPaintBrush} />
                &nbsp;
                Select Styles
            </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minWidth: "800px"}}>
                { isProcessing ? 
                    <Loading />
                :
                styles.length == 0 ?
                    <p style={{color:"red"}}>No styles found.</p>
                :
                    <Styles>
                        <thead>
                            <tr>
                                <th>Style</th>
                            </tr>
                        </thead>
                        <tbody>
                            {styles.map(style => (
                                <tr key={style.id}>
                                    <td>
                                        <Form.Check 
                                            name="style_id"                                
                                            value={style.id}
                                            checked={style.selected_ind == 1}
                                            disabled={existingStyles && existingStyles.find(s => s.id == style.id)}
                                            inline 
                                            label={style.name} 
                                            size="sm"
                                            type="checkbox" 
                                            onChange={(e) => {
                                                e.persist();
                                                
                                                setStyles(produce(draft => {
                                                    draft[styles.findIndex(s => s.id == style.id)].selected_ind = e.target.checked ? 1:0;
                                                }))
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Styles>
                }
            </Modal.Body>
            <Modal.Footer style={{display: "flex", justifyContent: "space-between"}}>
                <Button 
                    variant="outline-success" 
                    onClick={() => onSelect(styles.filter(s => s.selected_ind == 1))}
                >
                    Select Styles
                </Button>

                <Button variant="outline-secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>        
    </>
    )
}

export default SelectStylesDrawer;