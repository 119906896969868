import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Button from "../components/buttons/button";
import Panel from "../panels/panel";

import { postData } from "../common/services/server"
import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import { useGetWorkflows } from "../api/queries/workflows";
import { List, ListItem } from "../components/list";
import Badge from "../components/badge";
import ButtonMutate from "../components/buttons/button-mutate";
import { useWorkflowCreate } from "../api/mutations/workflows";
import PageLayout from "../components/page-layout";

function WorkflowsPage(props) {
    const history = useHistory();

    const workflowsQuery = useGetWorkflows();
    const workflows = workflowsQuery.data;

    const createWorkflow = useWorkflowCreate();

    return (
        <PageLayout>
            <PageHeader 
                title="Workflows"
                help="workflows"
            >
                {userHasPermission(Permissions.Workflows, Actions.Create) &&
                    <ButtonMutate
                        icon={faPlus}
                        caption="Create Workflow"
                        size="sm"
                        variant="outline-success"
                        mutation={createWorkflow}
                        onMutateSuccess={(result) => history.push("/workflow/" + result.id)}
                    />
                }
            </PageHeader>
            <Content>
                <Panel query={workflowsQuery}>
                  {workflows && 
                    <List>
                        {workflows.map(workflow => {
                            return (
                                <ListItem>
                                    <div className="flex items-center gap-1 w-full">
                                        <FontAwesomeIcon icon={faProjectDiagram} />
                                        <Link to={"/workflow/" + workflow.id} className="block w-full">
                                            {workflow.name}
                                        </Link>
                                        <Badge variant="light">Version {workflow.version}</Badge>
                                    </div>
                                </ListItem>
                            )
                        })}
                    </List>
                  }
                </Panel>
            </Content>
        </PageLayout>
    );
}

export default WorkflowsPage;