import React, { useState, useEffect } from "react";
import produce from "immer"
import styled from "styled-components"
import { Badge, Form, FormControl } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faLevelUpAlt, faPlug, faWrench, faTrash } from '@fortawesome/free-solid-svg-icons'
import InputGroup from 'react-bootstrap/InputGroup';

import Loading from "../../components/loading"
import GoogleImage from "../../components/google-image"
import MwoSticker from "../../components/mwo-sticker"

import { postData } from "../../common/services/server"
import ProductMwoDrawer from "../../drawers/drawer-productmwo";
import { getTitleForMwoStickerType } from "../../helpers/mwo";
import { colors } from "../../settings/settings";
import ButtonProcessing from "../../components/buttons/button-processing";

const Layout = styled.table`
    width: 100%;

    th {
        font-weight: normal;
    }

    thead {
        tr {
            th {
                text-align: left;
                padding: 5px;
                background-color: #eee;
                border: 1px solid #ccc;
                white-space: nowrap;

                &:first-child {
                    min-width: 200px;                    
                    max-width: 200px;
                }
            }
        }
    }
    tbody {
        th {
            position: -webkit-sticky; /* for Safari */
            position: sticky;
            left: 0px;
            background-color: white;            
            min-width: 200px;
            max-width: 200px;
            text-align: center;
            border-bottom: 1px solid #ccc;  
            border-right: 1px solid #ccc;                      
        }
        td {
            vertical-align: top;
            white-space: nowrap;
            font-size: 12px;
            padding: 3px;
            border: 1px solid #ccc;
        }
    }
`
const MWOList = styled.div`
    display: flex;
    gap: 10px;
`
const MwoContainer = styled.div`
    width: 350px;

    h3 {
        margin: 0px;
        font-size: 100%;
        text-align: center;
    }
    .stickerBox {
        width: 350px;
        margin: 0px !important;
        cursor: pointer;
    }
`

function ProductMWOsPanel({products, categories}) {
  const [loading, setLoading] = useState(false);
  
  const [categoryId, setCategoryId] = useState(null);
  const [categoryProducts, setCategoryProducts] = useState([]);

  const [mwos, setMWOs] = useState([]);
  const [mwoTypes, setMwoTypes] = useState([]);
  const [mwoType, setMwoType] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [parts, setParts] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);

  const [selected, setSelected] = useState(null);
  const [showParts, setShowParts] = useState(false);
  const [showType, setShowType] = useState(false);
  const [showTasks, setShowTasks] = useState(false);

  const [busy, setBusy] = useState("");

  useEffect(
    () => {   
        setLoading("mwos");

        postData("products/mwos", {},
          function(result) {
            setMWOs(result);
          },
          function(error) {
              alert("Error loading product MWOs")
          },
          function() {
            setLoading("")
          }
        );   
        postData("products/tasks", {},
          function(result) {
            setTasks(result);
          },
          function(error) {
              alert("Error loading product tasks")
          }
        );        
        postData("products/allparts", {},
          function(result) {
            setParts(result);
          },
          function(error) {
              alert("Error loading product parts")
          }
        );      
        postData("content/shippingmethods", {},
          function(result) {
            setShippingMethods(result);
          },
          function(error) {
              alert("Error loading shipping methods")
          }
        );                   
    }, 
    []
  );

  useEffect(
    () => {   
        if (!categoryId && categories && categories.length)
            setCategoryId(categories[0].id)
    }, 
    [categories]
  );

  useEffect(
    () => {   
        const catProducts = products.filter(p => p.sign_ind == 1 && p.category_id == categoryId);

        setCategoryProducts(catProducts);        
        setMwoTypes([...new Set(mwos.filter(m => catProducts.find(p => p.id == m.product_id)).map(item => item.title))]);
    }, 
    [categoryId, mwos]
  );

  return (
    <>
    {loading != "" ?
        <Loading />
    :
        <>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                margin: "10px"
            }}>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>Product Category</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl 
                        as="select" 
                        name="category" 
                        value={categoryId} 
                        onChange={(e) => {
                            setCategoryId(e.target.value)
                        }}
                    >
                        {categories.map((cat,index) => (
                            <option key={index} value={cat.id}>
                                {cat.title}
                            </option>
                        ))}
                    </FormControl>                    
                </InputGroup>

                <Form.Check 
                    type="checkbox"
                    inline
                    label={
                        <div style={{whiteSpace:"nowrap"}}>Show Parts</div>
                    }
                    value={1}
                    checked={showParts}   
                    onChange={(e) => {
                        setShowParts(e.target.checked);
                    }}
                />  
                <Form.Check 
                    type="checkbox"
                    inline
                    label={
                        <div style={{whiteSpace:"nowrap"}}>Show Type</div>
                    }
                    value={1}
                    checked={showType}   
                    onChange={(e) => {
                        setShowType(e.target.checked);
                    }}
                />  
                <Form.Check 
                    type="checkbox"
                    inline
                    label={
                        <div style={{whiteSpace:"nowrap"}}>Show Tasks</div>
                    }
                    value={1}
                    checked={showTasks}   
                    onChange={(e) => {
                        setShowTasks(e.target.checked);
                    }}
                />                                                            
            </div>

            <div
                style={{
                    width: "calc(100vw - 57px)",
                    overflow: "auto"
                }}
            >
                <Layout>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>
                                <InputGroup style={{width: "400px"}}>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Sticker Filter</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl 
                                        as="select" 
                                        name="type" 
                                        value={mwoType} 
                                        onChange={(e) => {
                                            setMwoType(e.target.value)
                                        }}
                                    >
                                        <option></option>
                                        {mwoTypes.map(type => (
                                            <option key={type} value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </FormControl>                    
                                </InputGroup>                                
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {categoryProducts.map(prod => (
                        <tr key={prod.id}>
                            <th>
                                <GoogleImage 
                                    src={"products/" + prod.name + "/icon.jpg?v6"} 
                                    style={{ maxHeight: "40px", marginBottom: "10px" }} 
                                />

                                {prod.title} 
                            </th>
                            <td>
                                <MWOList>
                                {mwos.filter(m => m.product_id == prod.id && (!mwoType || m.title == mwoType)).map(mwo => (
                                    <MwoContainer onClick={() => setSelected({product: prod, mwo})}>
                                        <h3>{mwo.title}</h3>
                                        <MwoSticker
                                            mwo={mwo}
                                            type={mwo.type}
                                            noMargin
                                            
                                        />

                                        {(showParts || showType || showTasks) && 
                                            <div style={{display:"flex", gap: "3px", flexFlow: "wrap", textTransform: "capitalize", marginTop: "3px"}}>
                                                {showType && 
                                                    <Badge variant="outline-primary" size="sm" style={{border: "1px solid "+ colors.blue}}>
                                                        {getTitleForMwoStickerType(mwo.stickers)}
                                                    </Badge>
                                                }

                                                {(showParts && mwo.part_list && mwo.part_list.length) && 
                                                    <>
                                                    {mwo.part_list.split(",").map((part, index) => {
                                                        const partId = mwo.parts.split(",")[index];
                                                        const validForProduct = parts.filter(p => p.product_id == prod.id && p.title == part).length > 0;

                                                        return (
                                                            <Badge variant={validForProduct ? "secondary":"danger"} size="sm">
                                                                <FontAwesomeIcon icon={faPlug} style={{marginRight: "3px"}} />
                                                                {part}

                                                                {!validForProduct && 
                                                                    <ButtonProcessing
                                                                        size="sm"
                                                                        icon={faTrash}
                                                                        variant="danger"
                                                                        processing={busy == mwo.id+"part"+partId}
                                                                        style={{
                                                                            marginLeft: "8px",
                                                                            padding: "2px",
                                                                            fontSize: "90%"
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();

                                                                            setBusy(mwo.id+"part"+partId)

                                                                            postData("mwos/removepart", {
                                                                                mwo_id: mwo.id,
                                                                                part_id: partId
                                                                            },
                                                                                function(result) {
                                                                                    setMWOs(produce(draft => {
                                                                                        let idx = draft.findIndex(m => m.id == mwo.id);

                                                                                        if (idx != -1) {
                                                                                            draft[idx] = result;
                                                                                        }
                                                                                    }));
                                                                                },
                                                                                function(error) {
                                                                                    alert("Error updating the MWO")
                                                                                },
                                                                                function() {
                                                                                setBusy("")
                                                                                }
                                                                            );                                                                              
                                                                        }}
                                                                    />
                                                                }
                                                            </Badge>
                                                        )
                                                    })}
                                                    </>
                                                }
                                                {(showTasks) && 
                                                    <>
                                                    {(mwo.task_list || "").split(",").map(task => (
                                                        <Badge variant="primary" size="sm">
                                                            <FontAwesomeIcon icon={faWrench} style={{marginRight: "3px"}} />
                                                            {task}
                                                        </Badge>
                                                    ))}
                                                    </>
                                                }                                                
                                            </div>    
                                        }                                    
                                    </MwoContainer>                                
                                ))} 
                                </MWOList>                           
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Layout>
            </div>
        </>
    }

    { selected && 
        <ProductMwoDrawer 
            product={{
                ...selected.product,           
                jobtasks: tasks.filter(t => t.product_id == selected.product.id),
                parts: parts.filter(t => t.product_id == selected.product.id),
                mwos: mwos.filter(m => m.product_id == selected.product.id),
                shippingmethods: shippingMethods
            }}
            mwo={selected.mwo} 
            show={true}
            onDelete={() => {
                setMWOs(produce(draft => {
                    const idx = draft.findIndex(m => m.id == selected.mwo.id);

                    if (idx != -1) {
                        draft.splice(idx, 1);
                    }
                }));

                setSelected(null);                
            }}
            onSaved={(mwoData) => {
                setMWOs(produce(draft => {
                    const idx = draft.findIndex(m => m.id == mwoData.id);

                    if (idx != -1) {
                        for (const prop of Object.keys(mwoData)) {
                            draft[idx][prop] = mwoData[prop];
                        }
                    }
                }));

                setSelected(null);
            }}
            onHide={ () => setSelected(null) } 
        /> 
    }    
    </>
  );
}

export default ProductMWOsPanel;