import React, { useState, useEffect } from "react"
import styled from "styled-components"
import produce from "immer"
import { faClipboardCheck, faClipboard } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom";

import ChartBox from "../charts/chartbox"
import TaskRow from "../tasks/task-row"

import { postData } from "../../common/services/server"
import { getUser } from "../../common/services/auth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Tasks = styled.div`
    div.task {
        border-bottom: 1px solid #eee;
    }
`
const Unassigned = styled(Link)`
    color: red;
    padding: 5px;
`

function AssignedTasks(props) {
    const history = useHistory();
    const user = getUser();

    const [tasks, setTasks] = useState([]);
    const [unassignedTasks, setUnassignedTasks] = useState([]);
    const [loading, setLoading] = useState("");
    const [refresh, setRefresh] = useState(1);
    const [error, setError] = useState(null);

    useEffect(
        () => {
            setLoading("tasks");

            postData("tasks/data", {
                status: "Active",
                assigned_user_id: user.id
            },
                function(result) {
                    setTasks(result.tasks);
                },
                function(error) {
                    setError("Error loading tasks")
                },
                function() {
                    setLoading("");
                }
            );

            postData("tasks/data", {
                status: "Active",
                list_name: "unassigned"
            },
                function(result) {
                    setUnassignedTasks(result.tasks);
                }
            );            
        }, 
        [refresh]
    );

    return (
        <>
            <ChartBox 
                icon={faClipboardCheck}
                title="My Tasks" 
                height={150}
                nopadding
                loading={loading == "tasks"}
                error={error}
                refresh={true}
                onRefresh={() => setRefresh(refresh++)}
            >
                <Tasks>
                {tasks.map(task => (
                    <TaskRow
                        key={task.id} 
                        task={task} 
                        onClick={(task) => {
                            history.push("/tasks/task/" + task.id);
                        }}
                        onTaskChanged={taskData => {
                            setTasks(produce(draft => {
                                const index = draft.findIndex(t => t.id == taskData.id)
                                
                                if (index != -1)
                                    draft[index] = taskData;
                            }));
                        }}
                    />
                ))}
                {(!loading && tasks.length == 0) && 
                    <p style={{textAlign:"center"}}>
                        No tasks found.  View <Link to="/tasks">all tasks</Link>.
                    </p>
                }
                </Tasks>

                {(unassignedTasks.length > 0) && 
                    <Unassigned to="/tasks/unassigned/0">
                        <FontAwesomeIcon icon={faClipboard} />{' '}
                        {unassignedTasks.length} Unassigned Task{unassignedTasks.length == 1 ? "":"s"}
                    </Unassigned>
                }
            </ChartBox>
        </>
    );
}

export default AssignedTasks