import React from "react";
import styled from "styled-components"

function MwoStickerBox(props) {
    return (
        <div className="stickerBox" style={{
            ...props.style,
            backgroundColor: props.type=="temporary" ? "white":"inherit"
        }}>
            <div className={props.className}>
                {props.children}
            </div>
        </div>
    )
}

export default MwoStickerBox;