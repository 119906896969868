import React, { useState, useEffect } from "react";

import ListGroup from 'react-bootstrap/ListGroup'
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faSpinner, faPhone, faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading";
import CallTemplateDrawer from "../drawers/calltemplate";
import Button from "../components/buttons/button";

import { getJsonData, postData } from "../common/services/server"

import settings from "../settings"
import PageLayout from "../components/page-layout";

function CallTemplatesPage(props) {
  const blankTemplate = { 
      id:0, 
      type:"call", 
      name:"", 
      trigger_id:"", 
      trigger_delay_amount:"1", 
      trigger_delay_type:"days", 
      trigger_cancel_id:"", 
      body:""
    };

  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({ templates: [] });
  const [processing, setProcessing] = useState([]);
  const [template, setTemplate] = useState(blankTemplate);

  useEffect(
    () => {
      setIsLoading(true);

      let source = getJsonData(
        settings.API_URL + 'emailtemplates/data?type=call',
        function(data) {
          setData(data);
        },
        function(error, status) {
          window.alert("Error loading call template data.")
        },
        function() {
          setIsLoading(false);
        }
      );

      return () => {
        if (source) source.cancel();
      };
    }, 
    []
  );


  function onCreate() {
    setTemplate(blankTemplate);
    setIsOpen(!isOpen);
  }
  function onSaved(calltemplate) {
    console.log("onSaved", calltemplate)
    setIsOpen(false);

    setData(produce(data, draft => {
        var indexes = draft.templates.map(function(x) { return parseInt(x.id); });
        var index = indexes.indexOf(parseInt(calltemplate.id));

        console.log(index)

        if (index == -1)
          draft.templates.unshift(calltemplate);
        else
          draft.templates[index] = calltemplate;
    }));
  }
  function onEdit(callTemplate) {
    console.log("onEdit call template", callTemplate)
    setTemplate(callTemplate);
    setIsOpen(true);
  }
  function onDelete(calltemplate) {
    if (window.confirm("Delete this call template?")) {
        setProcessing(produce(processing, draft => {
            draft.push(calltemplate.id);
        }));

      postData("email/template/delete", { id: calltemplate.id },
        function(result) {
          setData(produce(data, draft => {
              var indexes = draft.templates.map(function(x) { return parseInt(x.id); });
              var index = indexes.indexOf(parseInt(calltemplate.id));

              draft.templates.splice(index, 1);
          }));
        },
        function(error) {
          alert("An error occured deleting the template.")
        },
        function() {
          setProcessing(produce(processing, draft => {
              draft.splice(processing.indexOf(calltemplate.id), 1);
          }));
        }
      );
    }
  }

  function formatTriggerDelay(delay) {
    if (delay == "" || delay == null || delay == "0")
        return "";
    else {
        var amount = parseInt(delay);
        var type = delay.replace(/\d/g,'');

        return amount + " " + (amount == 1 ? type.substring(0, type.length-1) : type);
    }
  }

  return (
    <PageLayout>
      <PageHeader title="Call Template Manager">
        <Button onClick={onCreate} variant="outline-success" size="sm">
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;
            Create Template
        </Button>
      </PageHeader>
      <Content>
        {
          isLoading 
          ?
          <Loading />
          :
            <ListGroup>
                {data.templates.map(template => (
                    <ListGroup.Item key={template.id} className="small">
                        <span className="float-right">
                          { processing.indexOf(template.id) != -1 
                              ?
                                <FontAwesomeIcon icon={faSpinner} spin />
                              : 
                                <>
                                    <Button variant="outline-primary" size="sm" onClick={ () => onEdit(template) }>
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    </Button>
                                    <Button variant="outline-danger" size="sm" onClick={ () => onDelete(template) }>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </>
                          }
                        </span>

                        <FontAwesomeIcon icon={ faPhone } />
                        &nbsp;
                        <strong>{template.name}:</strong>
                        &nbsp;
                        Call User {formatTriggerDelay(template.trigger_delay)} after {template.trigger_title}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        }
        <CallTemplateDrawer show={isOpen} template={template} onSaved={onSaved} onHide={ () => setIsOpen(false) } />
      </Content>
    </PageLayout>
  );
}

export default CallTemplatesPage;