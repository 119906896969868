import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClone, faExclamation, faExclamationCircle, faExclamationTriangle, faFont, faMagic, faPaintBrush, faPallet, faPlug, faPlus, faRuler, faSave, faShapes, faSpinner, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { produce } from "immer";
import { Link } from "react-router-dom";
import { useQueryClient } from '@tanstack/react-query';

import Panel from "./panel";
import { List, ListItem } from "../components/list";
import GroupPreview from "../components/group-preview";
import { Actions, Permissions, getUser, userHasPermission } from "../common/services/auth";
import BoardField from "../components/board/board-field";
import DrawerProductSelect from "../drawers/product-select";
import DrawerStyleSelect from "../drawers/style-select";
import DropdownMenu from "../components/dropdownmenu";
import ColorBox from "../components/color-box";
import DrawerPartSelect from "../drawers/part-select";
import Button from "../components/buttons/button";

import { useGetOtherSignsUsingCustomGroup, useGetSign, useGetSignGroupNewDimensions, useGetSignGroupsWithDetails } from "../api/queries/signs";
import { useGetFrontEndPartTypes } from "../api/queries/parts";
import { 
    useAddSignGroupPart, 
    useChangeSignGroupPart, 
    useChangeSignGroupPartColor, 
    useChangeSignGroupProduct, 
    useChangeSignGroupStyle, 
    useRemoveSignGroupPart, 
    useRemoveSignPart,
    useRemoveSignGroup,
    useCloneSignGroup,
    useSetSignGroupSize,
    useSetSignGroupRelatedShapeSize,
    useSetSignGroupProp,
    useSetSignGroupLetters,
    useSetSignGroupShape,
    useRemoveSignGroupRelatedShape,
    useMakeSignGroupCustom,
    useRemoveSignGroupGraphic,
    useSetSignGroupDimensions,
    useAddGroupToSign,
} from "../api/mutations/signs";
import Alert from "../components/alert";
import ColorSelectDrawer from "../drawers/drawer-colorselect";
import DrawerTemplate from "../drawers/template";
import FieldNumber from "../components/fields/field-number";
import Loading from "../components/loading";
import Pill from "../components/pill";
import DrawerTemplateData from "../drawers/template-data";
import { useGetCustomShapesForStyle, useGetStandardShapes } from "../api/queries/shapes";
import ShapePreview from "../components/shape-preview";
import FieldLabel from "../components/fields/field-label";
import ButtonMutate from "../components/buttons/button-mutate";
import ZoomableImage from "../components/image-zoomable";
import storage from "../settings/storage";
import UploadButton from "../components/buttons/button-upload";

function OtherSignsWarning({signId, group}) {
    const {data:otherSigns, isFetching, error} = useGetOtherSignsUsingCustomGroup(signId, group.id, {
        enabled: group.style_id == 99
    });

    return (
        otherSigns ?
            <div className="grid gap-1 mb-2">
                <Alert variant="outline-danger" icon={faExclamationCircle} iconSize="lg" size="sm">
                    <div>
                    This custom shape is being used on the following other 
                    signs.  Changes to it will also affect these signs.
                    </div>
                </Alert>
                <div className="flex items-center gap-1">
                    {otherSigns.map(sign => (
                        <Pill key={sign.id} variant="outline-danger">
                            <Link to={"/signs/sign/"+sign.id} target="_blank">
                                #{sign.id}
                            </Link>
                        </Pill>
                    ))}
                </div>
            </div>
        :
            null
    )
}

function getPossibleGroupSizes(group) {
    let sizeOptions = [];

    let size = parseInt(group.min_size) || 1;

    while (size <= (group.max_size || 99)) {
        sizeOptions.push({
            id: size,
            label: size
        });

        size = size + (parseFloat(group.product_size_increments) || 1);

        if (group.product_size_increments == "1.00")
            size = parseInt(size);
    }

    return sizeOptions;
}

function RelatedShapesDrawer({
    signId,
    group,
    onHide
}) {
    const setSignGroupRelatedShapeSize = useSetSignGroupRelatedShapeSize(signId, group.id);
    const removeSignGroupRelatedShape = useRemoveSignGroupRelatedShape(signId, group.id);
 
    return (
        <DrawerTemplate
            icon={faShapes}
            title="Group Sub Shapes"
            drawerClassName="max-w-[500px]"
            header={
                <OtherSignsWarning signId={signId} group={group} />
            }
            onHide={onHide}
        >
            <div className="grid gap-2">
                <FieldLabel 
                    prepend="Group Size" 
                    value={group.product_size_increments == "1.00" ? parseInt(group.size) : group.size} 
                />

                <List>
                    {group.shapes.map(shape => (
                        <ListItem key={shape.shapes_related_id}>
                            <div className="grid grid-cols-[35px,200px,1fr,auto] gap-4 items-center w-full">
                                <ShapePreview path={shape.path} width={25} height={25} />
                                <div>
                                    <BoardField
                                        type="select"
                                        name="size" 
                                        label="Size"
                                        required
                                        value={group.product_size_increments == "1.00" ? parseInt(shape.related_size) : parseFloat(shape.related_size)} 
                                        selectProps={{
                                            items: getPossibleGroupSizes(group)
                                        }}
                                        saveMutation={setSignGroupRelatedShapeSize}
                                        onSave={(value) => {
                                            return {
                                                shapes_related_id: shape.shapes_related_id,
                                                size: value
                                            }
                                        }}
                                    />
                                </div>
                                <div></div>
                                <ButtonMutate
                                    icon={faTrash}
                                    variant="none-danger"
                                    size="sm"
                                    confirmation="Delete this related sub shape from the group?"
                                    mutation={removeSignGroupRelatedShape}
                                    mutationData={shape.shapes_related_id}
                                />
                            </div>
                        </ListItem>
                    ))}
                </List>
            </div>
        </DrawerTemplate>
    )
}

function CustomShapesDrawer({
    signId,
    group,
    onHide
}) {
    const [selected, setSelected] = useState(null);

    const customShapesQuery = useGetCustomShapesForStyle(group.style_id);
    const shapes = customShapesQuery.data;
    const setGroupShape = useSetSignGroupShape(signId, group.id, {
        onSuccess: onHide,
        onError: () => window.alert("Error updating the group")
    });

    return (
        <DrawerTemplateData
            icon={faShapes}
            title="Group Custom Shape"
            query={customShapesQuery}
            onHide={onHide}
        >
            { shapes &&
                <List>
                    {shapes.map(shape => (
                        <ListItem 
                            key={shape.id} 
                            busy={selected && selected.id==shape.id && setGroupShape.isLoading}
                            count={shape.pricing_modifier}
                            onClick={() => {
                                setSelected(shape);
                                setGroupShape.mutate(shape.id);
                            }}
                        >
                            <div>
                                {shape.code}
                                <div className="text-sm text-gray-400">
                                    {shape.description}
                                </div>
                            </div>
                        </ListItem>
                    ))}
                </List>
            }
        </DrawerTemplateData>
    )
}

function ShapesDrawer({
    signId,
    group,
    onHide
}) {
    const [selected, setSelected] = useState(null);

    const shapesQuery = useGetStandardShapes();
    const shapes = shapesQuery.data;
    const setGroupShape = useSetSignGroupShape(signId, group.id, {
        onSuccess: onHide,
        onError: () => window.alert("Error updating the group")
    });

    return (
        <DrawerTemplateData
            icon={faShapes}
            title="Group Shape"
            query={shapesQuery}
            onHide={onHide}
        >
            { shapes &&
                <div className="flex items-stretch gap-2 flex-wrap">
                    {shapes.map(shape => (
                        <button 
                            key={shape.id} 
                            className="border rounded p-1 hover:bg-gray-100"
                            disabled={selected != null}
                            onClick={() => {
                                setSelected(shape);
                                setGroupShape.mutate(shape.id);
                            }}
                        >
                            {(selected && selected.id == shape.id) ?
                                <FontAwesomeIcon icon={faSpinner} spin size="lg" className="text-gray-300 m-2" />
                            :
                                <ShapePreview path={shape.path} width={40} height={40} />
                            }
                        </button>
                    ))}
                </div>
            }
        </DrawerTemplateData>
    )
}

function GroupSizeDrawer({
    signId,
    group,
    onHide
}) {
    const [formData, setFormData] = useState({})
    const [sizes, setSizes] = useState([])
    const [showShapes, setShowShapes] = useState(false);

    const {data:sizeData, isFetching, error} = useGetSignGroupNewDimensions(signId, group.id, formData, {
        enabled:!!formData.size
    });
    const setGroupSize = useSetSignGroupSize(signId, group.id, {
        onSuccess: onHide,
        onError: () => window.alert("Error updating the group")
    });

    useEffect(() => {
        setFormData({
            size: group.size
        })    

        setSizes(getPossibleGroupSizes(group));
    }, [group]);

    return (
        <DrawerTemplate
            icon={faRuler}
            title="Group Size"
            onHide={onHide}
        >
            {(group.min_size && group.max_size) ?
                <Alert variant="danger" size="sm" icon={faExclamationTriangle}>
                    Product has a size range of {group.min_size} to {group.max_size}
                </Alert>
            : group.min_size ?
                <Alert variant="danger" size="sm" icon={faExclamationTriangle}>
                    Product has a minimum size of {group.min_size}
                </Alert>
            : group.max_size ?
                <Alert variant="danger" size="sm" icon={faExclamationTriangle}>
                    Product has a maximum size of {group.max_size}
                </Alert>
            :
                null
            }

            <BoardField
                type="select"
                name="size" 
                label="Size"
                value={parseInt(group.size)} 
                selectProps={{
                    items: sizes
                }}
                //onBlur={(e) => updateDimensions()}
                onChange={(value) => {
                    setFormData(produce(draft => {
                        draft.size = value;
                    }));
                }} 
                saveMutation={setGroupSize}
            />

            {isFetching ?
                <Loading size="sm" />
            : sizeData ?
                <>
                    <BoardField 
                        type="label"
                        label="Dimensions"
                        value={`${sizeData.height}" x ${sizeData.width}"`}
                    />

                    {sizeData.backdrop && 
                        <div>
                            Overall Dimensions (includes background)

                            <BoardField 
                                type="label"
                                label="Dimensions"
                                value={`${sizeData.heightWithBackdrop}" x ${sizeData.widthWithBackdrop}"`}
                            />                          
                        </div>
                    }

                    {(sizeData.shapes?.length > 0) && 
                        <div className="mt-3">
                            <div className="flex items-center gap-1 text-gray-400">
                                <input type="checkbox" checked={showShapes} onChange={(e) => setShowShapes(e.target.checked)} />
                                Show Shape Dimensions
                            </div>

                            {showShapes && 
                                <div className="grid gap-1">
                                    {sizeData.shapes.map(shape => (
                                        <div key={shape.signshape_id} className="grid grid-cols-[50px,1fr] items-center gap-2">
                                            <div className="border rounded text-3xl text-center p-1">
                                                {shape.shape}
                                            </div>
                                            <div>
                                                {shape.height.toFixed(2)} x {shape.width.toFixed(2)}
                                                {/* <div className="text-xs text-gray-400">
                                                    ({shape.height_ratio.toFixed(2)} x {shape.width_ratio.toFixed(2)})
                                                </div> */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    }
                </>
            :
                null
            }
    </DrawerTemplate>
    )
}

function CustomGroupSizeDrawer({
    signId,
    group,
    groupType,
    onHide
}) {
    const [formData, setFormData] = useState({})

    useEffect(() => {
        setFormData({
            size: group.size,
            size_height: group.custom_size_height || group.size_height,
            size_width: group.custom_size_width || group.size_width,
            overall_size_height: group.overall_size_height,
            overall_size_width: group.overall_size_width,
        })    
    }, [group]);

    function handleFormChange(event) {
        const field = event.target.name;
        const value = event.target.value;
    
        //console.log("handleFormChange", event.target.name, event.target.value)
        setFormData(produce(draft => {
          draft[field] = value;
        }));
    }

    const signGroupNewDimensions = useGetSignGroupNewDimensions(signId, group.id, formData, {
        enabled: formData.size_height && formData.size_width && groupType != "custom letters" ? true:false
    });
    const dimensions = signGroupNewDimensions.data;

    const setGroupDimensions = useSetSignGroupDimensions(signId, group.id, {
        onSuccess: onHide
    });

    const isDirty =
            (formData.size_height != (group.custom_size_height || group.size_height)) ||
            (formData.size_width != (group.custom_size_width || group.size_width)) ||
            (groupType == "custom letters" && formData.size != group.size)
            ;

    return (
        <DrawerTemplate
            icon={faRuler}
            title="Custom Group Size"
            onHide={onHide}
        >
            {group.material_min &&
                <Alert variant="danger" size="sm" icon={faExclamationTriangle}>
                    Product has a material minimum size of {group.material_min}"
                </Alert>
            }
            {group.material_max_1 &&
                <Alert variant="danger" size="sm" icon={faExclamationTriangle}>
                    Product has a material maximum size of {group.material_max_1}"
                    {group.material_max_2 && 
                    '/' + group.material_max_2 + '"'
                    }
                </Alert>
            }
            {group.min_size &&
                <Alert variant="danger" size="sm" icon={faExclamationTriangle}>
                    Product has size constraint of {group.min_size} to {group.max_size}
                </Alert>
            }  

            <div className={clsx(group.backdrop_ind == 1 && "grid grid-cols-2")}>
                <div>
                    <FieldNumber
                        name="size_height"
                        prepend="Height"
                        prependClassName="w-[100px]"
                        append="In"
                        value={formData.size_height} 
                        onChange={(e) => handleFormChange(e)} 
                    />
                    <FieldNumber
                        name="size_width"
                        prepend="Width"
                        prependClassName="w-[100px]"
                        append="In"
                        value={formData.size_width} 
                        onChange={(e) => handleFormChange(e)} 
                    />
                </div>
                {group.backdrop_ind == 1 && 
                    <div>
                        <FieldNumber
                            name="overall_size_height"
                            prepend="Overall Height"
                            prependClassName="w-[120px]"
                            append="In"
                            value={formData.overall_size_height} 
                            onChange={(e) => handleFormChange(e)} 
                        />
                        <FieldNumber
                            name="overall_size_width"
                            prepend="Overall Width"
                            prependClassName="w-[120px]"
                            append="In"
                            value={formData.overall_size_width} 
                            onChange={(e) => handleFormChange(e)} 
                        />
                    </div>                
                }
            </div>

            {groupType == "custom letters" ?
                <FieldNumber
                    name="size"
                    prepend="Size"
                    prependClassName="w-[100px]"
                    instructions={"Height of UPPERCASE letters in this Style"} 
                    value={formData.size} 
                    onChange={(e) => handleFormChange(e)} 
                    append={
                        <ButtonMutate
                            icon={faSave}
                            size="sm"
                            variant={isDirty ? "primary":"none-secondary"}
                            mutation={setGroupDimensions}
                            mutationData={{
                                height: formData.size_height,
                                width: formData.size_width,
                                overallHeight: formData.overall_size_height || null,
                                overallWidth: formData.overall_size_width || null,
                                size: formData.size
                            }}
                        />
                    }
                />   
                :           
                <FieldLabel
                    prepend="Size"
                    prependClassName="w-[100px]"
                    value={dimensions?.size} 
                    append={
                        <ButtonMutate
                            icon={faSave}
                            size="sm"
                            variant={isDirty ? "primary":"none-secondary"}
                            mutation={setGroupDimensions}
                            mutationData={{
                                height: formData.size_height,
                                width: formData.size_width,
                                overallHeight: formData.overall_size_height || null,
                                overallWidth: formData.overall_size_width || null,
                            }}
                        />
                    }
                />     
            }     
        </DrawerTemplate>
    )
}

const GroupWarning  = ({warning}) => (
    <div className="rounded bg-red-300 flex items-center gap-1 px-[5px] text-xs">
        <FontAwesomeIcon icon={faExclamation} size="sm" />
        {warning.warning}
    </div>
)
const GroupIdField = ({signId, group, canEdit}) => {
    const cloneGroup = useCloneSignGroup(signId, {
        onError: () => window.alert("Error cloning the group")
    });
    const removeGroup = useRemoveSignGroup(signId, {
        onError: () => window.alert("Error removing the group")
    });
    const makeGroupCustom = useMakeSignGroupCustom(signId, {
        onError: () => window.alert("Error updating the group")
    });

    return (
        <div className="flex items-center justify-center gap-2">
            <div className="">
                {group.id}
            </div>

            {canEdit && 
                <DropdownMenu
                    icon={faBars}
                    size="sm"
                    className="!p-1 !rounded-none"
                    busy={removeGroup.isLoading || cloneGroup.isLoading || makeGroupCustom.isLoading}
                    menu={[
                        {name: "custom", caption: "Make Group Custom", icon:faMagic, confirmation:"Make this group custom?", hidden: group.custom_ind==1},
                        {name: "clone", caption: "Clone Group", icon:faClone, confirmation:"Add a copy of this group?"},
                        {name: "delete", caption: "Remove Group", icon:faTrash, confirmation:"Remove this group from the sign?"},
                    ]}
                    onMenuItem={(item) => {
                        if (item.name == "delete")
                            removeGroup.mutate(group.id);
                        else if (item.name == "clone") 
                            cloneGroup.mutate(group.id);
                        else if (item.name == "custom")
                            makeGroupCustom.mutate(group.id);
                    }}
                />
            }
        </div>
    )
}
const GroupPreviewField = ({group}) => (
    <div className="px-2">
        <GroupPreview group={group} solid={true} width={100} height={50} />
    </div>
)
const GroupCustomIndField = ({group, groupType}) => (
    <div className={clsx(
        "rounded text-xs p-[2px] text-center border",
        groupType.startsWith("custom") ? "!border-red-500 text-red-500" : "bg-gray-300"
    )}>
        {groupType.toUpperCase()}
    </div>
)
function GroupShapesField({signId, group, groupType, canEdit}) {
    const [edit, setEdit] = useState(false);
    
    const setSignGroupLetters = useSetSignGroupLetters(signId, group.id);
    const hasRelatedShapes = group.shapes.filter(s => s.shapes_related_id != null).length > 0;

    return (
        <>
            { (canEdit && groupType.includes("letters") && !hasRelatedShapes) ?
                <BoardField
                    type="text"
                    label="Letters"
                    value={group.shapes.map(s => s.code).join("")}
                    saveMutation={setSignGroupLetters}
                />    
            : (group.shapes[0].style_id == 99 && group.shapes[0].related_style_id == 99) ?       
                <BoardField
                    type="children"
                    label="Sub Shapes"
                >
                    {group.shapes.map((shape,index) => (
                        <div key={shape.id} className="flex items-center gap-1">
                            <div className="bg-gray-200 p-1">Shape {index+1}</div>
                            <div>Size {shape.related_size}</div>
                        </div>
                    ))}
                </BoardField>
            :
                <BoardField
                    type="label"
                    label={groupType.includes("letters") ? "Letters":"Shape"}
                    value={group.shapes.map(s => s.code).join("")}
                    onEdit={(canEdit && (groupType != "custom shape" || group.style_id != 99)) ? () => { setEdit(true)} : null}
                    onEditDisabled={!canEdit}
                />
            }

            {/* // TODO: Related Shapes Drawer needs reviewed/redone */}
            { (edit && hasRelatedShapes) ?
                <RelatedShapesDrawer
                    show={true}
                    signId={signId}
                    group={group}
                    onHide={() => setEdit(false)}
                />
            : (edit && groupType == "custom shape") ?
                <CustomShapesDrawer
                    show={true}
                    signId={signId}
                    group={group}
                    onSaved={() => setEdit(false)}
                    onHide={() => setEdit(false)}
                />                
            : (edit && groupType == "shape") ?
                <ShapesDrawer
                    show={true}
                    signId={signId}
                    group={group}
                    onSaved={() => setEdit(false)}
                    onHide={() => setEdit(false)}
                />                
            :
                null
            }  
        </>                   
    )
}
function GroupProductField({signId, group, canEdit}) {
    const [edit, setEdit] = useState(false);
    const [applyAll, setApplyAll] = useState(false);
    
    const changeGroupProduct = useChangeSignGroupProduct(signId, group.id, {
        applyAll,
        onSuccess: () => setEdit(false)
    });

    return (
        <>
            <BoardField
                type="label"
                label="Product"
                value={group.product}
                onEdit={canEdit ? () => { setEdit(true)} : null}
                onEditDisabled={!canEdit}
            /> 

        {edit && 
            <DrawerProductSelect
                title="Change Product"
                filters={{
                    exists_signgroup_id: group.id,
                    //convert_product_id: group.product_id,
                    builder_type: group.product_builder_type
                }}                
                selectCaption="Change To"
                selectMutation={changeGroupProduct}
                selectMutationDataProp="id"
                buttons={
                    <div className="flex flex-1 items-center gap-1">
                        <input 
                            type="checkbox"
                            name="applyall"
                            checked={applyAll}
                            onChange={(e) => setApplyAll(e.target.checked)}
                        />
                        Apply change to all <strong>"{group.product_builder_type}"</strong> groups in this sign
                    </div>
                }                
                onHide={() => setEdit(false)}
            />     
        }            
        </>       
    )
}
function GroupStyleField({signId, group, canEdit}) {
    const [edit, setEdit] = useState(false);

    const changeGroupStyle = useChangeSignGroupStyle(signId, group.id, {
        onSuccess: () => setEdit(false)
    });
    
    const haveRelated = group.shapes[0]?.related_style != null;
    const isChangeable = group.style_id != 0 && 
        (group.style_id != 99 || haveRelated);

    return (
        <>
            <BoardField
                type="label"
                label="Style"
                value={group.shapes[0]?.related_style ?? group.style}
                onEdit={(canEdit && isChangeable) ? () => { setEdit(true)} : null}
                onEditDisabled={!canEdit}
            /> 
            
            {edit && 
                <DrawerStyleSelect
                    drawerClassName="max-w-[500px]"
                    title="Change Style"
                    filters={{
                        product_id: group.product_id,
                        exists_shape_id: group.shapes[0].shape_id,
                        builder_type: group.product_builder_type
                    }}
                    header={
                        <OtherSignsWarning signId={signId} group={group} />
                    }
                    selectCaption="Change To"
                    selectMutation={changeGroupStyle}
                    selectMutationDataProp="id"
                    onHide={() => setEdit(false)}
                />     
            }                   
        </>
    )
}
function GroupSizeField({signId, group, groupType, canEdit}) {
    const [edit, setEdit] = useState(false);

    const height = group.custom_size_height || group.size_height;
    const width = group.custom_size_width || group.size_width;

    return (
        <>
            <BoardField
                type="children"
                label="Size"
                error={group.size == 0 || height == 0 || width == 0}
                onEdit={canEdit ? () => { setEdit(true)} : null}
                onEditDisabled={!canEdit}
            >
                <div>{group.product_size_increments == "1.00" ? parseInt(group.size) : group.size}</div>
                <div className="text-gray-400">
                    {height} x {width}
                </div>
                {(group.overall_size_height || group.overall_size_width) && 
                    <div className="text-gray-400">
                        {group.overall_size_height} x {group.overall_size_width} (overall)
                    </div>            
                }
                {group.warnings.filter(w => w.prop=="size").map((warning,index) => (
                    <GroupWarning key={index} warning={warning} />
                ))}                
            </BoardField>

            {edit &&
                <>
                {group.custom_ind == 1 ? 
                    <CustomGroupSizeDrawer 
                        signId={signId} 
                        group={group} 
                        groupType={groupType}
                        onHide={() => setEdit(false)} 
                    />
                :
                    <GroupSizeDrawer 
                        signId={signId} 
                        group={group} 
                        onHide={() => setEdit(false)} 
                    />
                }
                </>
            }
        </>        
    )
}
const GroupMultiPartField = ({signId, group, canEdit}) => {
    const setSignGroupProp = useSetSignGroupProp(signId, group.id, "multipart_group");

    // Multi Part Logo option only applies to custom shapes
    const isApplicable = group.product_builder_type == "shapes";

    return (
        (isApplicable && canEdit) ?
            <BoardField
                name="multipart_group"
                type="select"
                label="Multi Part Logo"
                selectProps={{
                    items: [
                        {id: "1", label: "MPL 1"},
                        {id: "2", label: "MPL 2"},
                        {id: "3", label: "MPL 3"},
                        {id: "4", label: "MPL 4"},
                        {id: "5", label: "MPL 5"},
                        {id: "6", label: "MPL 6"},
                        {id: "7", label: "MPL 7"},
                        {id: "8", label: "MPL 8"},
                        {id: "9", label: "MPL 9"},
                        {id: "10", label: "MPL 10"},            
                    ]
                }}
                disabled={!canEdit}
                value={group.multipart_group}
                saveMutation={setSignGroupProp}
            />  
        :
            <BoardField
                type="label"
                label="Multi Part Logo"
                value={group.multipart_group ? "MPL " + group.multipart_group : "N/A"}
            />
    )
}
const GroupCostField = ({field, group}) => (
    group.estimate_expired_ind == 1 ? 
        <BoardField
            type="children"
            label="Parts Cost"
        >   
            <span className="text-red-500">Expired</span>
        </BoardField>  
    :
        <BoardField
            type="currency"
            label="Parts Cost"
            value={group.estimate}
            error={group.estimate <= 0}
        />  
)
const GroupHeaderField = ({field}) => (
    <div className="text-center text-sm p-1">
        {field.label}
    </div>       
)
function GroupPartTypeField({signId, type, group, canEdit}) {
    const parts = group.parts.filter(p => p.type_id == type.id);

    const [applyAll, setApplyAll] = useState(false);
    const [part, setPart] = useState(null);
    const [colorPart, setColorPart] = useState(null);

    const addGroupPart = useAddSignGroupPart(signId, group.id, {
        applyAll,
        onSuccess: () => setPart(null)
    });
    const changeGroupPart = useChangeSignGroupPart(signId, group.id, part?.part_id, {
        applyAll,
        onSuccess: () => setPart(null)
    });
    const removeGroupPart = useRemoveSignGroupPart(signId, group.id, {
        onError: () => window.alert("Error removing the part")
    });
    const removePart = useRemoveSignPart(signId, {
        onError: () => window.alert("Error removing the part")
    });
    const changeGroupPartColor = useChangeSignGroupPartColor(signId, group.id, {
        applyAll,
        onError: () => window.alert("Error updating the part color")
    });

    return (
      <>
        <BoardField
            type="children"
            label={type.title}
            buttons={
                (parts.length == 0 && canEdit) ?
                    [<Button
                        size="tiny" 
                        variant="none-secondary"
                        icon={faPlus}
                        onClick={() => setPart({})}
                    />]
                :
                    null
            }
        >
            <div className="grid gap-1 text-xs">
            {parts.map(part => {
                //const isLEDs = type.name == "lighting" && part.name.toLowerCase().includes("leds");
                
                return (
                    <React.Fragment key={part.id}>
                        <div className="flex items-center justify-between gap-1">
                            <div className="flex items-center gap-1">
                                {part.color_ind == 1 && 
                                    <ColorBox size="small" color={{
                                        name: part.color,
                                        hex_day: part.color_hex
                                    }}
                                    />
                                }
                                {part.title}
                            </div>

                            {canEdit &&
                                <div className="flex items-center gap-1">
                                    <DropdownMenu
                                        icon={faBars}
                                        size="sm"
                                        className="!p-1 !rounded-none"
                                        busy={removeGroupPart.isLoading || removePart.isLoading || changeGroupPartColor.isLoading}
                                        menu={[
                                            {name: "change", caption: "Change Part", icon:faPlug},
                                            {name: "color", caption: "Change Color", icon:faPaintBrush, hidden: part.color_ind == 0},
                                            {name: "delete", caption: "Remove Part", icon:faTrash, confirmation:"Remove this part from the group?"},
                                            {name: "deleteall", caption: "Remove Part All Groups", icon:faTrashAlt, confirmation:"Remove this part from all sign groups?"},
                                        ]}
                                        onMenuItem={(item) => {
                                            if (item.name == "change")
                                                setPart(part);
                                            else if (item.name == "color")
                                                setColorPart(part)
                                            else if (item.name == "delete") {
                                                removeGroupPart.mutate(part.part_id);
                                            }
                                            else if (item.name == "deleteall") {
                                                removePart.mutate(part.part_id);
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </div>

                        {group.warnings.filter(w => w.part_id==part.id).map((warning,index) => (
                            <GroupWarning key={index} warning={warning} />
                        ))}
                    </React.Fragment>
                )
            })}
            {group.warnings.filter(w => w.type_id == type.id).map((warning, index) => (
                <GroupWarning key={index} warning={warning} />
            ))}
            </div>
        </BoardField>  

        {part && 
            <DrawerPartSelect
                title={part.id ? "Change Part" : "Add Part"}
                header={
                    <Alert variant="warning" size="sm" className="mb-2">
                        Parts are limited to the <strong>{group.product}</strong> product
                    </Alert>
                }
                footer={
                    <div className="mt-4 flex items-center gap-1">
                        <input 
                            type="checkbox"
                            name="applyall"
                            checked={applyAll}
                            onChange={(e) => setApplyAll(e.target.checked)}
                        />
                        Apply change to all groups in this sign
                    </div>
                }
                filters={{
                    product_id: group.product_id,
                    type: type.name,
                    exists_signgroup_id: group.id
                }}                
                selectCaption={part.id ? "Change To" : "Add"}
                selectMutation={part.id ? changeGroupPart : addGroupPart}
                selectMutationDataProp="id"
                onHide={() => setPart(null)}
            />     
        }   
        {colorPart && 
            <ColorSelectDrawer
                show={true}
                part={{
                    id: colorPart.part_id,
                    title: colorPart.title
                }}
                footer={
                    <div className="flex flex-1 items-center gap-1">
                        <input 
                            type="checkbox"
                            name="applyall"
                            checked={applyAll}
                            onChange={(e) => setApplyAll(e.target.checked)}
                        />
                        Apply change to all groups in this sign
                    </div>
                }
                onColor={(color) => {
                    changeGroupPartColor.mutate({partId: colorPart.part_id, colorId: color.id});
                    setColorPart(null);
                }}
                onHide={() => setColorPart(null)}
            />
        }                
      </>
    )
}
function GroupGraphic({signId, group, graphic, canEdit}) {

    const removeGroupGraphic = useRemoveSignGroupGraphic(signId, group.id);

    return (
        <BoardField
            type="children"
            label={graphic.type}
            labelClassName="capitalize"
        >
            <div className="flex items-center justify-between gap-2">
                {(graphic.type == "image" || graphic.type == "svg") ?
                    <ZoomableImage 
                        url={storage.root + (graphic.faceart_url_thumb || graphic.faceart_url)}
                        zoomUrl={storage.root + graphic.faceart_url}
                        maxHeight="20px"
                        maxWidth="50px"
                    />
                : graphic.type == "text" ?
                    graphic.text
                :
                    "?"
                }

                {canEdit && 
                    <ButtonMutate
                        icon={faTrash}
                        size="icon"
                        variant="none-danger"
                        confirmation="Delete this graphic?"
                        mutation={removeGroupGraphic}
                        mutationData={graphic.id}
                    />
                }
            </div>
        </BoardField>
    )
}

const GroupGraphicsField = ({signId, signType, group, groupType, canEdit}) => {
    const queryClient = useQueryClient()

    const graphics = group.graphics.filter(g => g.face >= 0);
    const faceArts = group.parts.filter(p => p.faceart_id);

    return (
        <div>
            {graphics.map(graphic => (
                <GroupGraphic 
                    key={graphic.id}
                    signId={signId}
                    group={group}
                    graphic={graphic}
                    canEdit={canEdit}
                />
            ))}
            {faceArts.map(part => (
                <GroupGraphic 
                    key={part.id}
                    signId={signId}
                    group={group}
                    graphic={{
                        type: "image",
                        faceart_url_thumb: part.faceart_url_thumb,
                        faceart_url: part.faceart_url
                    }}
                    canEdit={false}
                />
            ))}    

            {(graphics.length == 0 && faceArts.length == 0) && 
                <div className="text-sm text-gray-400 p-1">
                    No Graphics Found
                </div>
            }

            {(canEdit && signType == "custom" && groupType.includes("custom")) &&
                <div className="flex items-center gap-1 mt-2">
                    <Button 
                        onClick={() => setSelected({mode:"browsefiles", group:item})} 
                        size="sm" 
                        variant="outline-secondary" 
                    >
                        Browse
                    </Button>

                    <UploadButton
                        uploadUrl={"signs/uploadgraphic?sign_id=" + signId + "&group_id=" + group.id}
                        label="Upload"
                        size="sm"
                        className="bg-white border !border-blue-500 !text-blue-500"
                        thumbnail={true}
                        extensions={["jpg","png"]}
                        onUpload={() => {
                            queryClient.invalidateQueries(["signs", ""+signId, "groups", "details"]);
                        }}
                    />                     
                </div>
            }        
        </div>
    )
}

// TODO: Test with cabinets

export default function SignGroupsPanel({
    signId,
}) {
    const [canEdit] = useState(userHasPermission(Permissions.Signs, Actions.Edit));

    const { data:sign } = useGetSign(signId);
    const groupsQuery = useGetSignGroupsWithDetails(signId);
    const groups = groupsQuery.data;

    const addSignGroup = useAddGroupToSign(signId, {
        onError: () => window.alert("Error updating the sign")
    });

    const { data:partTypes } = useGetFrontEndPartTypes();

    const fields = [
        {name: "id", component: GroupIdField},
        {name: "preview", component: GroupPreviewField},
        {name: "custom", component: GroupCustomIndField},
        {name: "shapes", component: GroupShapesField},
        {name: "product", component: GroupProductField},
        {name: "style", component: GroupStyleField},
        {name: "size", component: GroupSizeField},
        {name: "multipart", component: GroupMultiPartField, hidden: groups?.filter(g => g.product_builder_type == "shapes").length<=0},
        {name: "estimate", component: GroupCostField},
        {name: "parts", label: "Group Parts", component: GroupHeaderField},
    ]

    function determineGroupType(group) {
        let type = group.product_builder_type;
        
        if (type == "shapes" || type == "cabinet") 
            type = "shape";
        else 
            type = "letters"

        if (group.custom_ind==1 || group.resembles_ind==1) {
            type = "custom " + type
        }

        return type;
    }

    function isGroupIsEditable(group) {
        return sign?.custom_ind == 1;
    }

    return (
       <Panel query={groupsQuery}>
            <List direction="horizontal" className="gap-2">
                {(groups && sign) && 
                    <div className="grid gap-x-2 gap-y-1" style={{
                        gridTemplateColumns: `repeat(${groups.length}, 275px)`
                    }}>
                        {fields.filter(f => !f.hidden).map(field => (
                            <React.Fragment key={field.name}>
                                {groups.map(group => (
                                    <field.component 
                                        key={group.id}
                                        field={field} 
                                        signId={signId}
                                        group={group}
                                        signType={sign.custom_ind == 1 ? "custom":"builder"}
                                        groupType={determineGroupType(group)}
                                        canEdit={canEdit && isGroupIsEditable(group)}
                                    />
                                ))}
                            </React.Fragment>
                        ))}

                        {partTypes && partTypes.map(type => (
                            <React.Fragment key={type.id}>
                                {groups.map(group => (
                                    <GroupPartTypeField 
                                        key={group.id+"_"+type.id}
                                        type={type} 
                                        signId={signId}
                                        group={group}
                                        canEdit={canEdit && isGroupIsEditable(group)}
                                    />
                                ))}
                            </React.Fragment>
                        ))}

                        {groups && groups.map(group => (
                            <GroupHeaderField 
                                key={group.id}
                                field={{
                                    label: "Group Graphics"
                                }}
                            />
                        ))}

                        {groups && groups.map(group => (
                            <GroupGraphicsField 
                                key={group.id}
                                signId={signId}
                                group={group}
                                signType={sign.custom_ind == 1 ? "custom":"builder"}
                                groupType={determineGroupType(group)}
                                canEdit={canEdit && isGroupIsEditable(group)}
                            />
                        ))}
                    </div>                    
                }

                {(groups && groups.length==0) && 
                    <div className="p-2 grid gap-2">
                        <div className="text-lg text-gray-500">
                            No Groups Found
                        </div>
                        {(canEdit && sign?.custom_ind==1) && 
                            <div className="flex gap-1">
                                <ButtonMutate
                                    icon={faFont}
                                    caption="Add Letters Group"
                                    size="sm"
                                    variant="outline-success"
                                    mutation={addSignGroup}
                                    mutationData="letters"
                                />
                                <ButtonMutate
                                    icon={faShapes}
                                    caption="Add Shapes Group"
                                    size="sm"
                                    variant="outline-success"
                                    mutation={addSignGroup}
                                    mutationData="shape"
                                />
                            </div>
                        }
                    </div>
                }
            </List>
       </Panel>
    )
}