import React from "react";
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faListAlt, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { isMobile } from "react-device-detect"

import { colors } from "../settings/settings";

const Workers = styled.div`
    display: ${isMobile ? "block":"flex"};
    margin-right: ${isMobile ? 0:15}px;
    margin-bottom: ${isMobile ? 0:10}px;
    background-color: ${isMobile ? "white":"#eee"};
    flex-flow: ${isMobile ? "wrap":"initial"};
    justify-content: space-between;

    button {        
        align-items: center;
        font-size: 12px;
        margin: 2px;
        padding: 5px;
        position: relative;
        flex-basis: 23.5%;
        width: ${isMobile ? "23.75%":"auto"};

        svg {
            display: block;
            margin: 0px auto ${isMobile ? 0:5}px auto;
        }
    }
`
const Worker = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 5px;
    white-space: nowrap;

    button {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        white-space: nowrap;
        align-items: center;
    
        width: auto;
    }
`
const WorkerTaskCount = styled.div`
    background-color: ${colors.red};
    color: white;
    position: absolute;
    padding: 1px 4px;
    border-radius: 5px;
    right: -2px;
    top: -2px;

    &.tasks0 {
        background-color: #ccc;
    }
`

function StationEmployees({
    workers,
    selected,
    onWorker
}) {
    const selectedWorker = selected ? workers.find(w => w.id == selected.id) : null;

    return (
        <>
        {(isMobile && selectedWorker) ? 
            <Worker>
                <Button variant="primary">
                    <FontAwesomeIcon icon={selected.busy ? faSpinner:faUserTie} spin={selected.busy} size="lg" />
                    <div>{selectedWorker.first_name} {selectedWorker.last_name}</div>
                </Button>   
                <Button 
                    variant="outline-secondary" 
                    onClick={() => onWorker(null)}
                    style={{
                        border: "0px"
                    }}
                >
                    <FontAwesomeIcon icon={faListAlt} size="lg" />
                    <div>Other Employees</div>
                </Button>                                      
            </Worker>
        :
            <Workers>
            {workers.map(worker => (
                <Button 
                    key={worker.id} 
                    onClick={() => onWorker(worker)} 
                    variant={selected && selected.id == worker.id ? "primary":"outline-secondary"}
                    style={{
                        backgroundColor: selected && selected.id == worker.id ? "#007bff" : "unset"
                    }}
                >
                    <FontAwesomeIcon icon={worker.busy ? faSpinner:faUserTie} spin={worker.busy} size="2x" />
                    <div>{worker.first_name}</div>
                    <div>{worker.last_name}</div>

                    {Number.isInteger(worker.task_count) && 
                        <WorkerTaskCount className={"tasks" + worker.task_count}>
                            {worker.task_count}
                        </WorkerTaskCount>
                    }
                </Button>
            ))}
            </Workers>
        }
        </>
    );
}

export default StationEmployees;