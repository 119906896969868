import React, { useState, useEffect } from "react"
import { Chart } from 'react-google-charts'
import { format, subMonths, parseISO } from 'date-fns'

import ChartBox from "./chartbox"
import ListField from "../fields/field-list"

import { postData } from "../../common/services/server"
import { formatPrice } from "../../helpers/utility"

function SignProductsMonthChart(props) {
    const [dates, setDates] = useState([]);
    const [date, setDate] = useState(format(new Date(), "M/1/yyyy"));
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [error, setError] = useState(null);

    useEffect(
        () => {
            let dateOptions = [];

            for (let i=0; i<12; i++) {
                const dateOption = subMonths(new Date(), i);

                dateOptions.push({value: format(dateOption, "M/1/yyyy"), label: format(dateOption, "MMMM yyyy")});
            }

            setDates(dateOptions);
        }, 
        []
    );

    useEffect(
        () => {
            setLoading(true);

            postData("signs/chart/products", {
                month: format(Date.parse(date), "M"),
                year: format(Date.parse(date), "yyyy")
            },
                function(result) {
                    setData(result);
                },
                function(error) {
                    setError("Error loading data")
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        [date, refresh]
    );

    return (
        <ChartBox 
            title={"Monthly Sign Products"}
            height={330}
            loading={loading}
            error={error}
            refresh={true}
            onRefresh={() => setRefresh(refresh++)}
        >
            <ListField 
                name="date" 
                value={date} 
                list={dates}
                onChange={(e) => {
                    setDate(e.target.value)
                }} 
            />

            <Chart
                width={'100%'}
                height={'300px'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                    chartArea: {width: '95%', height: '75%', top: 10, right: 10},
                    tooltip: {isHtml: true},
                    is3D: true,
                }}
            />
        </ChartBox>
    );
}

export default SignProductsMonthChart