import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import styled from "styled-components"
import { isMobile } from "react-device-detect"

import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote, faClipboardCheck, faPlug, faCogs, faUser, faSpinner, faShapes, faSync, faExclamationTriangle, faThumbsDown, faCalendar, faTrash, faChevronUp, faChevronDown, faUsersCog, faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import produce from "immer"
import Dropdown from 'react-bootstrap/Dropdown'
import Alert from 'react-bootstrap/Alert'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Button from '../components/buttons/button'
import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"
import GoogleImage from "../components/google-image"
import SelectField from "../components/fields/field-select"

import JobShapesPanel from "./panels/panel-job-shapes"
import JobPrefabPanel  from "./panels/panel-job-prefab"
import JobTasksPanel from "./panels/panel-job-tasks"
import JobMWOsPanel  from "./panels/panel-job-mwos"
import JobQcPanel from "./panels/panel-job-qc"
import JobUlPanel from "./panels/panel-job-ul"
//import JobShippingPanel  from "./panels/panel-job-shipping"

import JobShapesDrawer from "../drawers/drawer-jobshapes"

import FilesPanel from "./panels/panel-files"
import NotesPanel from "./panels/panel-noteslist"
import TasksPanel from "./panels/panel-tasks"
import ResourcesPanel from "../panels/panel-resources";

import { postData } from "../common/services/server"
import { formatDate } from "../helpers/utility"
import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import PageLayout from "../components/page-layout";

const JobHeader = styled.div`
    display: ${isMobile ? "block":"flex"};
    height: ${isMobile ? "auto":"150px"};
    width: ${isMobile ? "100%":"auto"};
    margin: 10px 0px 0px 0px;

    > div {
        border: 1px solid #ccc;
        padding: 0px;
        border-radius: 5px;
        margin-right: 5px;
        min-width: 150px;
        background-color: white;
        height: ${isMobile ? "auto":"150px"};
        overflow: auto;

        > h3 {
            background-color: #999;
            color: white;
            padding: 5px;
            text-align: center;
            font-size: 14px;
            margin: 1px 1px ${isMobile?1:8}px 1px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            svg {
                cursor: pointer;
                float: right;
            }
        }
        > div {
            margin: 2px 8px;
        }

        &.preview {
            display: flex;
            align-items: center;
            padding: 5px;
        }
    }
`

function JobPage(props) {
    const history = useHistory();
    let { tab, id } = useParams();

    if (!tab) tab = "";

    const [isLoading, setIsLoading] = useState(true);
    const [job, setJob] = useState(null);
    const [selected, setSelected] = useState(null);
    const [busy, setBusy] = useState("");
    const [open, setOpen] = useState(null);
    
    useEffect(
        () => {
            setIsLoading(true);

            postData("jobs/jobdata", { 
                id: id
            },
                function(result) {
                    console.log(result)
                    setJob(result);
                },
                function(error) {
                    alert("Error loading job")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        [id]
    );

    function onChecklistAnswer(type, question, checked) {
        const field = type.toLowerCase().replace(/\s/g, "") + "_ind";

        console.log("checklist field update", field);

        setJob(produce(draft => {
            draft[field] = 1;
        }));
    }

    function onJobChange(updatedJob) {
        console.log("onJobChange", updatedJob);

        setJob(updatedJob);
        setSelected(null);
    }

    function onJobPropChange(prop, value) {
        console.log("onJobPropChange", prop, value);

        setJob(produce(draft => {
            draft[prop] = value;
        }))
    }    

    function onStatus(statusId) {
        setBusy("status");

        postData("jobs/setstatus", { 
            id: job.id,
            status_id: statusId
        },
            function(result) {
                if (statusId == 99)
                    history.push("/jobs")
                else 
                    setJob(result);
            },
            function(error) {
                alert("Error updating job")
            },
            function() {
                setBusy("");
            }
        );
    }

    function onReset(type) {
        if (type === "Shapes" || type == "Job") {
            setSelected({mode: "Reset"+type})
        }
        else if (window.confirm(type ? "Reset Job " + type + "?" : "Reset Entire Job?")) {
            setBusy("reset")

            postData("jobs/reset" + (type ? type.toLowerCase() : ""), { 
                id: job.id,
                job_id: job.id
            },
                function(result) {
                    setJob(result);
                },
                function(error) {
                    alert("Error updating job")
                },
                function() {
                    setBusy("");
                }
            );
        }
    }

    function onDelete() {
        if (window.confirm("Are you sure you want to Delete this job?")) {
            setBusy("delete")

            postData("jobs/delete", { 
                id: job.id
            },
                function(result) {
                    setJob(result);
                },
                function(error) {
                    alert("Error deleting job")
                },
                function() {
                    setBusy("");
                }
            );
        }
    }

    function toggleOpen(type) {
        setOpen(open == type ? "" : type);
    }

    const paused = job && job.status_name == "hold";
    const completed = job && job.status_name == "completed";

    return (
        <PageLayout>
            <PageHeader 
                title={job ? job.title : "Job #" + id}
                help="job"
                parent={{
                    url: "/jobs",
                    title: "Jobs"
                }}
                tab={tab}
                tabUrl={"/jobs/job/{tab}/" + id}
                tabs={[
                    //{name:"shapes", title:"Shapes", icon:faShapes, hidden:job ? !job.sign_id : true, permission: Permissions.Jobs},
                    {name:"prefab", title:"Prefab", icon:faPlug, permission: Permissions.Jobs},
                    {name:"production", title:"Production", icon:faCogs, permission: Permissions.Jobs},
                    {name:"mwos", title:"MWOs", permission: Permissions.Jobs},
                    {name:"resources", title:"Resources", permission: Permissions.Jobs, hidden: !job || job.type != "Part"},
                    {name:"files", title:"Files", permission: Permissions.Jobs},
                    {name:"qc", title:"QC", permission: Permissions.QA},
                    {name:"ul", title:"UL Numbers", permission: Permissions.UL},
                    //{name:"shipping", title:"Shipping", permission: Permissions.Jobs},
                    {name:"notes", title:"Notes", icon:faStickyNote, count: job ? job.note_count : null, hideOnNoCount: false, permission: Permissions.Jobs},
                    {name:"tasks", title:"Tasks", icon:faClipboardCheck, count: job ? job.task_count : null, hideOnNoCount: false, permission: Permissions.Jobs},
                ]}
                header={
                    !isLoading && 
                        <JobHeader>
                            <div className="preview">
                                <GoogleImage root src={job.preview_url} style={{ maxWidth: "200px", maxHeight: "100px"}} />
                            </div>
                            {job.sign_id && 
                                <div>
                                    <h3>
                                        Sign
                                        {isMobile && 
                                            <FontAwesomeIcon icon={open == "sign"?faChevronUp:faChevronDown} onClick={() => toggleOpen("sign")} />
                                        }
                                    </h3>
                                    {(!isMobile || open=="sign") &&
                                        <>
                                            <div>
                                                <Link to={"/signs/sign/" + job.sign_id}>
                                                    #{job.sign_id}
                                                </Link>
                                            </div>
                                            <div style={{ fontSize: "10px", maxWidth: "300px" }}>
                                                <span dangerouslySetInnerHTML={{__html: job.sign_description}} />
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                            {(job.accessory_id || job.type == "Part") && 
                                <div>
                                    <h3>
                                        Product
                                        {isMobile && 
                                            <FontAwesomeIcon icon={open == "product"?faChevronUp:faChevronDown} onClick={() => toggleOpen("product")} />
                                        }
                                    </h3>
                                    {(!isMobile || open=="product") &&
                                        <div>
                                            <div dangerouslySetInnerHTML={{__html: job.orderitem_title}} />
                                            {(job.orderitem_description && job.orderitem_description.length > 0) && 
                                                <div>
                                                    Description: 
                                                    <span style={{color: "red"}} dangerouslySetInnerHTML={{__html: job.orderitem_description}} />
                                                </div>
                                            }
                                            <div>
                                                Quantity: 
                                                <span style={{color: job.orderitem_quantity > 1 ? "red":"initial"}}>
                                                    {job.orderitem_quantity}
                                                </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            {job.quote_id && 
                                <div>
                                    <h3>
                                        Project
                                        {isMobile && 
                                            <FontAwesomeIcon icon={open == "project"?faChevronUp:faChevronDown} onClick={() => toggleOpen("project")} />
                                        }
                                    </h3>
                                    {(!isMobile || open=="project") &&
                                        <div>
                                            <Link to={"/projects/project/" + job.quote_id}>
                                                #{job.quote_id}
                                            </Link>
                                        </div>
                                    }
                                </div>
                            }
                            {(job.type != "Part") && 
                                <div>
                                    <h3>
                                        Order
                                        {isMobile && 
                                            <FontAwesomeIcon icon={open == "order"?faChevronUp:faChevronDown} onClick={() => toggleOpen("order")} />
                                        }
                                    </h3>
                                    {(!isMobile || open=="order") &&
                                        <>
                                            <div>
                                                <Link to={"/orders/order/" + job.order_id}>
                                                    #{job.order_id}
                                                </Link>
                                            </div>
                                            <div>
                                                <FontAwesomeIcon icon={faUser} />{' '}
                                                <Link to={"/users/user/" + job.user_id}>
                                                    {job.user_name}
                                                </Link>           
                                            </div>
                                            <div>
                                                Ordered: {formatDate(job.order_date)}
                                            </div>
                                            <div>
                                                Ships: {formatDate(job.ship_date)}
                                            </div>
                                        </>
                                    }
                                </div>
                            }

                            {(job.jobtype_id != 1 && job.type != "Part") && 
                                <div>
                                    <h3>
                                        Job
                                        {isMobile && 
                                            <FontAwesomeIcon icon={open == "job"?faChevronUp:faChevronDown} onClick={() => toggleOpen("job")} />
                                        }
                                    </h3>
                                    {(!isMobile || open=="job") &&
                                        <>
                                            <div>
                                                Job Type: {job.jobtype_title}
                                            </div>
                                            {job.parent_job_id && 
                                                <div>
                                                    Parent Job: <Link to={"/jobs/job/" + job.parent_job_id}>#{job.parent_job_id}</Link>
                                                </div>
                                            }
                                            <div>
                                                ${job.jobtype_cost}
                                            </div>
                                            <div>
                                                <small>{job.jobtype_notes}</small>
                                            </div>
                                        </>
                                    }
                                </div>                            
                            }

                            {job.childjob_count > 0 &&
                                <div>
                                    <h3>
                                        Related Jobs
                                        {isMobile && 
                                            <FontAwesomeIcon icon={open == "childjobs"?faChevronUp:faChevronDown} onClick={() => toggleOpen("childjobs")} />
                                        }
                                    </h3>
                                    {(!isMobile || open=="childjobs") &&
                                        <>
                                            {job.childjobs.map((childjob,index) => (
                                                <div key={index}>
                                                    <Link to={"/jobs/job/" + childjob.id}>
                                                        {childjob.title}
                                                    </Link>
                                                </div>
                                            ))}
                                        </>
                                    }                                    
                                </div>
                            }
                        </JobHeader>
                }
                notices={
                    (job && job.warnings && job.warnings.length > 0) && 
                        <>
                            {job.warnings.map((warning,index) => (
                                <Alert key={index} variant="danger">
                                    <FontAwesomeIcon icon={warning.type == "QA" ? faThumbsDown:faExclamationTriangle} />{' '}
                                    {warning.warning}
                                </Alert>
                            ))}
                        </>                    
                }
                onTab={(tab) => {
                    history.push("/jobs/job/" + tab + "/" + id);
                }}
                onNewNote={(note) => {
                    console.log("onNewNote", note)
                    if (note.job_id && note.job_id == job.id) {
                        setJob(produce(draft => {
                            draft.note_count++;
                        }));
                    }
                }}
                onNewTask={(task) => {
                    console.log("onNewTask", task)
                    if (task.job_id && task.job_id == job.id) {
                        setJob(produce(draft => {
                            draft.task_count++;
                        }));
                    }
                }}
            >
                {(job && job.status_id != 99 && userHasPermission(Permissions.Jobs, Actions.Reset)) && 
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary">
                            {busy == "reset" && 
                                <FontAwesomeIcon icon={faSpinner} spin />
                            } 
                            Reset
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => onReset('Shapes')}>
                                <FontAwesomeIcon icon={faShapes} fixedWidth />{' '}
                                Reset Shapes...
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => onReset('Prewire')}>
                                <FontAwesomeIcon icon={faPlug} fixedWidth />{' '}
                                Reset Prewire
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => onReset('Production')}>
                                <FontAwesomeIcon icon={faCogs} fixedWidth />{' '}
                                Reset Production
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => onReset('Job')}>
                                <FontAwesomeIcon icon={faSync} fixedWidth />{' '}
                                Reset Entire Job
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                }
                {busy == "status" && 
                    <FontAwesomeIcon icon={faSpinner} spin />
                }                
                <SelectField
                    name="status_id"
                    value={job ? job.overall_status_id : 0}
                    url="jobs/statuses"
                    labelField="title"
                    disabled={busy == "status" || !userHasPermission(Permissions.Jobs, Actions.Edit)}
                    onChange={(e) => {
                        onStatus(e.target.value);
                    }}
                    style={{
                        backgroundColor: job && job.status_name == "hold" ? "#f8d7da":"auto",
                        borderColor: job && job.status_name == "hold" ? "red":"auto",
                        color: job && job.status_name == "hold" ? "red":"auto"
                    }}
                />
                {userHasPermission(Permissions.Jobs, Actions.Delete) &&
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip>Delete Job</Tooltip>
                        }
                    >   
                        <Button onClick={onDelete} variant="outline-danger" size="icon">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </OverlayTrigger>
                }
            </PageHeader>
            <Content permission={Permissions.Jobs}>
            <>
                {
                    isLoading 
                ?
                    <Loading />
                : job.status_id == 99 ? 
                    <Alert variant="danger">
                        This job has been deleted.
                    </Alert>
                :    
                    <>
                        {tab === "shapes" &&  
                            <JobShapesPanel 
                                job={job} 
                                onJobChange={onJobChange}
                                onPropChange={onJobPropChange}
                            /> 
                        }
                        {tab === "prefab" &&  
                            <JobPrefabPanel 
                                job={job} 
                                onJobChange={onJobChange}
                            /> 
                        }
                        {tab === "production" &&  
                            <JobTasksPanel 
                                job={job} 
                                onJobChange={onJobChange}
                            /> 
                        }
                        {tab === "mwos" &&  
                            <JobMWOsPanel 
                                job={job} 
                                onJobChange={onJobChange}
                            /> 
                        }
                        {tab === "qc" &&  
                            <JobQcPanel 
                                job={job} 
                                onJobChange={onJobChange}
                            /> 
                        }
                        {tab === "ul" &&  
                            <JobUlPanel 
                                job={job} 
                                onJobChange={onJobChange}
                            /> 
                        }
                        {/* {tab === "shipping" &&  
                            <JobShippingPanel 
                                job={job} 
                                onJobChange={onJobChange}
                            /> 
                        } */}
                        {tab === "resources" &&  
                            <ResourcesPanel
                                type = "job"
                                type_id = {job.id}
                                resources = {job.resources || []}
                            />
                        }                        
                        {tab === "files" &&  
                            <FilesPanel
                                filters={{
                                    job_id: job.id
                                }}
                                photos={true}
                                haveTabs={true}
                                headerHeight={160}
                                uploadPath={"jobs/upload?job_id=" + job.id}
                                uploadMultiple
                            />
                        }
                        {tab === "notes" &&
                            <NotesPanel
                                filters={{
                                    job_id: job.id
                                }}
                                haveTabs={true}
                            />
                        }   
                        {tab === "tasks" &&
                            <TasksPanel
                                filters={{
                                    job_id: job.id,
                                    status: "Active"
                                }}
                                haveTabs={true}
                            />
                        }                       
                    </>
                }
            </>
            </Content>

            { (selected && (selected.mode == "ResetShapes" || selected.mode == "ResetJob")) && 
                <JobShapesDrawer 
                    show={true}
                    job={job}
                    mode={selected.mode}
                    onJobChange={onJobChange}
                    onHide={ () => setSelected(null) } /> 
            }
        </PageLayout>
    );
}

export default JobPage;