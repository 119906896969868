import React, { useState } from "react"
import { faCalendar } from "@fortawesome/free-solid-svg-icons"

import ChartBox from "../charts/chartbox"
import CalendarWeek from "../calendar/calendar-week"
import CalendarPrefsButton from "../calendar/calendar-prefsbutton"

function ScheduleWidget(props) {
    const [prefs, setPrefs] = useState({})

    return (
        <>
            <ChartBox 
                icon={faCalendar}
                title={
                    <>
                        <div style={{flex:"1"}}>Calendar</div>
                        <CalendarPrefsButton
                            size="sm"
                            onPrefsChanged={(newPrefs) => setPrefs(newPrefs)}
                        />                          
                    </>    
                }                    
            >
                <CalendarWeek calendarPrefs={prefs} />
            </ChartBox>        
        </>
    );
}

export default ScheduleWidget