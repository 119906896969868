import React, { useState } from "react"
import { clsx } from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import Badge from "../badge";

function BoardFields({
    label,
    info,
    className,    
    collapsible,
    collapsed,
    count,
    children
}) {
    const [open, setOpen] = useState(!collapsed);

    return (
        <div
            className={clsx(
                "bg-white border rounded border-gray-300 p-1 grid gap-1",
                className,
            )}
        >
            <div 
                className={clsx(
                    collapsible && "flex items-center justify-between gap-1 p-2 cursor-pointer"
                )}
                onClick={() => collapsible ? setOpen(prev => !prev) : null}
            >
                <div>
                    {label && 
                        <h3 className={clsx(
                            "text-sm font-semibold capitalize",
                            count > 0 && "flex items-center gap-2",
                            collapsible ? "text-left" : "text-center"
                        )}>
                            {label}
                            {(count > 0) && 
                                <Badge variant="secondary" size="sm" className="ml-1">{count}</Badge>
                            }
                        </h3>
                    }
                    {info && 
                        <div className={clsx(
                            "text-xs",
                            collapsible ? "text-left" : "text-center"
                        )}>
                            {info}
                        </div>
                    }
                </div>
                {collapsible && 
                    <div>
                        <FontAwesomeIcon icon={open ? faCaretUp:faCaretDown} />
                    </div>
                }
            </div>

            <div className={clsx(
                open ? "block" : "hidden"
            )}>
                <div className="grid gap-[2px]">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default BoardFields