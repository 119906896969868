import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { produce } from "immer";
import { useParams } from "react-router-dom";
import { faBars, faBoxOpen, faCalendar, faClipboardCheck, faExclamationTriangle, faEye, faCog, faPencilAlt, faPlug, faPlus, faProjectDiagram, faQrcode, faRedo, faSync, faTrash } from "@fortawesome/free-solid-svg-icons";
import { isBefore, parseISO } from "date-fns";

import PageHeader from "../components/page-header";
import Content from "../components/content"
import CardLayout, { CardLayoutPanel } from "../components/cardlayout";
import ZoomableImage from "../components/image-zoomable";
import TabList from "../components/tabs";
import ButtonMutate from "../components/buttons/button-mutate";
import JobWorkflowWarningsPanel from "../panels/jobworkflow-warnings";
import JobWorkflowPrefabPanel from "../panels/jobworkflow-prefab";
import JobWorkflowPowerPanel from "../panels/jobworkflow-power";
import JobWorkflowSchedulePanel from "../panels/jobworkflow-schedule";
import JobWorkflowPanel from "../panels/jobworkflow-workflow";
import JobWorkflowMWOsPanel from "../panels/jobworkflow-mwos";
import JobWorkflowQcPanel from "../panels/jobworkflow-qc";
import Loading from "../components/loading";
import DropdownMenu from "../components/dropdownmenu";
import Error from "../components/error";

import storage from "../settings/storage";
import { Actions, Permissions, userHasPermission } from "../common/services/auth";
import { formatDate } from "../helpers/utility";
import { useGetJobData } from "../api/queries/workflowjobs";
import { useWorkflowJobAddPowerset, useWorkflowJobDelete, useWorkflowJobUpgrade, useWorkflowJobReset, useWorkflowJobResetPower, useWorkflowJobResetWorkflow, useWorkflowJobResyncWorkflow, useWorkflowJobToggleNote, useWorkflowJobUpdateProp, useWorkflowJobRescheduleWorkflow } from "../api/mutations/workflowjobs";
import Alert from "../components/alert";
import Button from "../components/buttons/button";
import DrawerTemplateDataSelect from "../drawers/template-dataselect";
import { getIconForType } from "../helpers/icons";
import { useGetStatuses } from "../api/queries/statuses";
import { JobStatuses } from "../helpers/data";
import PageLayout from "../components/page-layout";

function WorkflowJobPage() {
    const [canDelete] = useState(userHasPermission(Permissions.Jobs, Actions.Delete));
    const [canEdit] = useState(userHasPermission(Permissions.Jobs, Actions.Edit));

    let { tab, id } = useParams();

    if (!tab) tab = "";

    const [cards, setCards] = useState([]);
    const [tabs, setTabs] = useState([
        {name: "prefab", title: "Prefab", icon: faEye, component: JobWorkflowPrefabPanel},
        {
            name: "power", 
            title: "Power", 
            icon: faPlug, 
            menu: [
                {name: "addpowerset", caption: "Add Powerset", icon: faPlus},
                {name: "resetpower", caption: "Reset Power", icon: faSync, confirmation: "Reset Job Power?"},
            ],
            component: JobWorkflowPowerPanel
        },
        {
            name: "workflow", 
            title: "Workflow", 
            icon: faProjectDiagram, 
            menu: [
                {name: "reset", caption: "Reset Workflow", icon: faSync, confirmation: "Reset Job Workfow?"},
                {name: "resync", caption: "Reprocess Checks", icon: faRedo},
                {name: "reschedule", caption: "Recalculate Schedule", icon: faCalendar},
            ],
            component: JobWorkflowPanel
        },
        {
            name: "mwos", 
            title: "MWOs", 
            icon: faQrcode, 
            component:  JobWorkflowMWOsPanel
        },
        {name: "schedule", title: "Schedule", icon: faCalendar, component: JobWorkflowSchedulePanel},
        {
            name: "warnings", 
            title: "Warnings", 
            icon: faExclamationTriangle, 
            component: JobWorkflowWarningsPanel
        },
        {name: "qc", title: "QC", icon: faClipboardCheck, component: JobWorkflowQcPanel},
        // {name: "history", title: "History", icon: faHourglass, component: BlankTab},
    ]);

    const [selectedTab, setSelectedTab] = useState(null);
    const [changeStatus, setChangeStatus] = useState(false);

    const {data:job, isLoading, error} = useGetJobData(id);

    const updateJobStatus = useWorkflowJobUpdateProp(id, "status_id");
    const upgradeJob = useWorkflowJobUpgrade(id, {
        onError: () => window.alert("Error upgrading job")
    });
    const deleteJob = useWorkflowJobDelete({
        onError: () => window.alert("Error deleting job")
    });
    const resetJob = useWorkflowJobReset({
        onError: () => window.alert("Error resetting job")
    });
    const resetWorkflow = useWorkflowJobResetWorkflow({
        onError: () => window.alert("Error resetting job")
    });
    const resyncWorkflow = useWorkflowJobResyncWorkflow(id);  
    const rescheduleWorkflow = useWorkflowJobRescheduleWorkflow(id, {
        onError: () => window.alert("Error resetting job schedule")
    });  
    const addPowerset = useWorkflowJobAddPowerset(id);
    const resetPower = useWorkflowJobResetPower(id);

    const jobStatuses = useGetStatuses("jobs", { enabled: changeStatus });

    useEffect(
        () => {
            if (job) {
                setCards([
                    {type: "user", id: job.user_id},
                    {type: "order", id: job.order_id},
                    {type: "sign", id: job.sign_id, hidden: !job.sign_id},
                    {type: "project", id: job.project_id, hidden: !job.project_id},
                    {type: "notes", workflowjob_id: job.id},
                    {type: "files", workflowjob_id: job.id},
                    {type: "tasks", workflowjob_id: job.id},
                ]);    
                
                if (job.warnings_count > 0) {
                    setTabs(produce(draft => {
                        draft.find(t => t.name == "warnings").variant = "danger"
                    }))
                }
            }
        }, 
        [job]
    );

    useEffect(
        () => {
          setSelectedTab(tabs.find(t => t.name == tab))
        }, 
        [tab]
    );

    return (
      <PageLayout>
        <div>
            <PageHeader 
                pageTitle={`Job #${id}`} 
            />        
            <Content loading={isLoading || deleteJob.isLoading || resetJob.isLoading} permission={Permissions.Jobs} error={error} padding={1}>
                <>
                    {job ?
                        <CardLayout
                            cards={cards}
                            noScroll
                        >
                            <div className="p-1 grid gap-1">
                                <div className="flex gap-2 mb-2">
                                    <ZoomableImage
                                        url={storage.root + job.preview_url}
                                        height="70px"
                                    />
                                    <div>
                                        <h1 className="flex items-center gap-2 text-xl font-bold mb-2">
                                            {job.title}

                                            {canEdit && 
                                                <DropdownMenu
                                                    icon={faBars}
                                                    className="text-gray-400"
                                                    menu={[
                                                        {name: "reset", caption: "Reset Entire Job", icon:faSync, confirmation:"Reset this entire job?"},
                                                        {name: "delete", caption: "Delete Job", icon:faTrash, confirmation:"Delete this entire job? This can NOT be undone!", hidden: !canDelete},
                                                    ]}
                                                    onMenuItem={(item) => {
                                                        if (item.name == "reset")
                                                            resetJob.mutate(job.id);
                                                        else if (item.name == "delete")
                                                            deleteJob.mutate(job.id);                                                       
                                                    }}
                                                />
                                            }
                                        </h1>
                                        <div>
                                            <span className="text-gray-500">Ships:</span>
                                            <span className={clsx(
                                                "ml-1",
                                                isBefore(parseISO(job.ship_date), new Date()) && "text-red-500"
                                            )}>
                                                {formatDate(job.ship_date, "EEEE MMM do")}
                                            </span>
                                        </div>
                                        <div className="flex items-center gap-1">
                                            <span className="text-gray-500">Status:</span>
                                            <span className={clsx(
                                                job.status_id == JobStatuses.hold.id && "bg-yellow-500 rounded px-1",
                                                job.status_id == JobStatuses.completed.id && "bg-green-500 rounded px-1",
                                                job.status_id == JobStatuses.void.id && "bg-red-500 rounded px-1",
                                            )}>
                                                {job.status_title}
                                            </span>
                                            {canEdit && 
                                                <Button
                                                    icon={faPencilAlt}
                                                    size="icon"
                                                    variant="none-light"
                                                    onClick={() => setChangeStatus(true)}
                                                />
                                            }                                            
                                        </div>                                        
                                    </div>
                                </div>

                                <TabList 
                                    tabs={tabs}
                                    disabled={job.initialized_ind == 0}
                                    selectedTab={selectedTab}
                                    onTab={(tab) => {
                                        window.location.hash = `#/workflowjob/${tab.name}/${id}`;
                                    }}
                                    onTabMenuItem={(item) => {
                                        console.log(item)

                                        if (item.name == "reset") {
                                            resetWorkflow.mutate(job.id);
                                        }
                                        else if (item.name == "resync") {
                                            resyncWorkflow.mutate(job.id);                                            
                                        }
                                        else if (item.name == "reschedule") {
                                            rescheduleWorkflow.mutate(job.id);
                                        }
                                        else if (item.name == "resetpower") {
                                            resetPower.mutate()
                                        }
                                        else if (item.name == "addpowerset") {
                                            addPowerset.mutate();                                          
                                        }
                                    }}                                    
                                />

                                <CardLayoutPanel autoHeight={true} autoHeightOffset={5} className="p-1 grid gap-1">
                                    {(addPowerset.isLoading || resetPower.isLoading || resetWorkflow.isLoading || resyncWorkflow.isLoading || rescheduleWorkflow.isLoading) ?
                                        <Loading />  
                                    : (job.initialized_ind == 0) ? 
                                        <div className="m-10">
                                            <ButtonMutate
                                                icon={faCog}
                                                size="lg"
                                                caption="Upgrade Job"
                                                variant="outline-success"
                                                className="block mx-auto"
                                                mutation={upgradeJob}                  
                                            />
                                        </div>
                                    : (addPowerset.error || resetPower.error) ? 
                                        <Error message={addPowerset.error.message || resetPower.error.message || resetWorkflow.error.message || resyncWorkflow.error.message} />                             
                                    : selectedTab ? 
                                        <selectedTab.component
                                            jobId={job.id}
                                            resetOnLoad={true}
                                        />        
                                    :
                                        null
                                    }
                                </CardLayoutPanel>
                            </div>
                        </CardLayout>
                    :
                        <div className="p-2">
                            <Alert variant="danger">Job Not Found</Alert>
                        </div>
                    }
                </>
            </Content>
        </div>

        {changeStatus && 
            <DrawerTemplateDataSelect
                icon={getIconForType("job")}
                title={"Change Job Status"}
                query={jobStatuses}
                itemRender={(status) => (
                    <div className={clsx(
                        "rounded px-1",
                        JobStatuses[status.name]?.bgcolor,
                        JobStatuses[status.name]?.fgcolor,
                    )}>
                        {status.title}
                    </div>                
                )}
                itemExistsCheck={(item) => item.id == job.status_id}
                selectMutation={updateJobStatus}
                selectMutationDataProp="id"
                selectMutationSuccess={() => {
                    setChangeStatus(false)
                }}
                onHide={() => setChangeStatus(false)}
            />            
        }
      </PageLayout>
    );
}

export default WorkflowJobPage;