import React, { useState, useEffect } from "react";
import { faPlug, faTrash } from "@fortawesome/free-solid-svg-icons";

import GroupPreview from "../components/group-preview"
import Button from "../components/buttons/button";
import ColorBox from "../components/color-box";
import LedSelectDrawer from "../drawers/drawer-ledselect";
import { List, ListItem } from "../components/list"
import DrawerPartSelect from "../drawers/part-select";
import ButtonPost from "../components/buttons/button-post";
import Panel from "./panel";
import { useGetJobLEDs } from "../api/queries/workflowjobs";
import { useWorkflowJobAddPart, useWorkflowJobRemoveGroupPart, useWorkflowJobRemoveGroupPartType, useWorkflowJobResetPower, useWorkflowJobUpdateDimensionProp, useWorkflowJobUpdatePartProp } from "../api/mutations/workflowjobs";
import ButtonMutate from "../components/buttons/button-mutate";

export default function JobWorkflowLEDsPanel({
    jobId,
    resetOnLoad
}) {
    const [initialized, setInitialized] = useState(resetOnLoad ? false:true);

    const groupsQuery = useGetJobLEDs(jobId, {enabled: initialized});
    const groups = groupsQuery.data;

    const resetPower = useWorkflowJobResetPower(jobId);

    useEffect(
        () => {
            if (!initialized) {
                resetPower.mutate(jobId, {
                    onSuccess: () => setInitialized(true)
                });
            }
        }, 
        []
    );

    const [part, setPart] = useState(null);
    const [addGroup, setAddGroup] = useState(null);

    const addGroupPart = useWorkflowJobAddPart(jobId, addGroup?.id, {
        onSuccess: () => setAddGroup(null)
    });
    const updateDimensionProp = useWorkflowJobUpdateDimensionProp(jobId, {
        // onSuccess: () => setPart(null)
        onError: () => window.alert("Error updating group")
    });
    const removeJobGroupPart = useWorkflowJobRemoveGroupPart(jobId, {
        // onError: () => window.alert("Error updating group")
    });   
    const removeGroupPartType = useWorkflowJobRemoveGroupPartType(jobId, {
        onError: () => window.alert("Error updating group")
    });   
    
    return (
      <>
        <Panel query={initialized ? groupsQuery : resetPower} checkReloading={!initialized}>
            {groups && 
                <div className="flex gap-2">
                    <List>
                        {groups.map(group => (
                            <ListItem 
                                key={group.id} 
                                busy={
                                    (removeGroupPartType.isLoading && removeGroupPartType.variables?.jobGroupId == group.id) ||
                                    (updateDimensionProp.isLoading && updateDimensionProp.variables?.jobGroupId == group.id) 
                                }
                                menu={[
                                    {name: "add", caption: "Add Lighting Part", icon:faPlug},
                                    {name: "remove", caption: "Remove Lighting", icon:faTrash, confirmation:"Remove all ligthing from this group?", hidden: group.lit_ind == 0}
                                ]}
                                onMenuItem={(item) => {
                                    if (item.name == "remove") {
                                        removeGroupPartType.mutate({jobGroupId: group.id, type:"lighting"});
                                    }
                                    else if (item.name == "add") {
                                        setAddGroup(group);
                                    }
                                }}
                            >
                                <div className="grid grid-cols-[125px,1fr] items-center">
                                    <div className="text-xs">
                                        <GroupPreview group={group} solid={true} width={100} height={50} />
                                        <div>
                                            {group.product}
                                        </div>
                                        <div>
                                            Size {group.size}
                                        </div>
                                        {group.style_type == "letters" && 
                                            <div>
                                                {group.style}
                                            </div>
                                        }                                        
                                    </div>
                                    {(group.parts.length > 0) ?
                                        <div className="flex items-stretch gap-1">
                                            {group.parts.map(part => (
                                                <div key={part.id} className="border border-gray-300 flex flex-col gap-1 rounded p-1">
                                                    <div className="flex flex-1 items-center justify-between gap-2">
                                                        {part.title}

                                                        <ButtonMutate
                                                            icon={faTrash}
                                                            size="icon"
                                                            variant="light-danger"
                                                            className="!border-none"
                                                            confirmation="Remove this lighting part?"
                                                            mutation={removeJobGroupPart}
                                                            mutationData={{
                                                                jobGroupId: group.id,
                                                                partId: part.id
                                                            }}
                                                        />
                                                    </div>

                                                    {part.name != "none" && 
                                                        <Button 
                                                            size="tiny"
                                                            variant="outline-secondary"
                                                            className="flex items-center gap-1 !py-1 w-full"
                                                            onClick={() => setPart(part)}
                                                        >
                                                            <ColorBox color={part.color} />
                                                            {part.led_name} <span className="text-sm text-gray-300 ml-1">({part.watts} watts)</span>
                                                        </Button>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    :
                                        <div className="text-red-500">
                                            Unlit
                                        </div>
                                    }
                                </div>
                            </ListItem>
                        ))}
                    </List>
                </div>
            }
        </Panel>

        {addGroup && 
            <DrawerPartSelect
                filters={{
                    type: "lighting",
                    product_id: addGroup.product_id,
                    exists_jobworkflowgroup_id: addGroup.id
                }}
                itemExistsCheck={(item) => item.exists_ind == 1}
                selectMutation={addGroupPart}
                selectMutationDataProp="id"
                onHide={() => setAddGroup(null)}
            />           
        }

        {part && 
            <LedSelectDrawer
                onLed={(led) => {
                    updateDimensionProp.mutate({
                        jobGroupId: part.jobgroup_id,
                        prop: "led_id",
                        value: led.id
                    })

                    setPart(null);
                }}
                onHide={() => setPart(null)}
            />
        }
      </>
    )
}
