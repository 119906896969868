import React from "react";
import styled from 'styled-components'
import { isMobile } from "react-device-detect"

const Properties = styled.div`
    display: ${isMobile ? "block":"flex"};
    align-items: flex-start;
`

function PropertyColumns(props) {
    return (
        <Properties className="properties">
            {props.children}
        </Properties>
    )
}

export default PropertyColumns