import React, { useState } from "react";
import styled from "styled-components"
import Form from "react-bootstrap/Form";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { formatDate } from "../../helpers/utility";
import { postData } from "../../common/services/server"
import { colors } from "../../settings/settings";
import { getUser } from "../../common/services/auth";

import TaskLink from "./task-link"

const Row = styled.div`
    display: flex;
    justify-content: space-between; 
    align-items: center;
    margin-bottom: 1px;
    font-size: 12px;
    padding: 4px;
    cursor: pointer;

    &.complete {
        text-decoration: line-through;
    }

    &:hover {
        background-color: ${colors.lightPurpleHover};
    }

    > div:nth-child(1) {
        flex: 1;
        display: flex;
        align-items: center;

        a {
            display: block;
        }
    }
    > div:nth-child(2) {
        display: flex;
        align-items: center;

        span {
            background-color: #eee;
            padding: 2px;
            white-space: nowrap;
            display: inline-block;
            margin-left: 5px;
            border-radius:  4px;
        }

        svg {
            margin-left: 5px;
        }
    }

    .custom-switch {
        padding-left: 2.5rem;
        margin-right: -7px;
    }
`

function TaskRow({task, active, onClick, onTaskChanged, assign}) {
    const user = getUser();
    const [busy, setBusy] = useState("");

    function onToggleComplete(complete) {
        setBusy("complete");

        postData("tasks/" + (complete ? "complete":"uncomplete"), {task_id: task.id},
            function(result) {
                if (onTaskChanged)
                    onTaskChanged(result);
            },
            function(error) {
                window.alert("Error updating task.");
            },
            function() {
                setBusy("");
            }
        );          
    }

    return (
        <Row className={(active ? "task active ":"task ") + task.status.toLowerCase()}>
            <div onClick={(e) => {if (onClick) { onClick(task); e.stopPropagation(); e.preventDefault(); }}}>
                {assign && 
                    <>
                    {busy == "assign" ? 
                        <FontAwesomeIcon icon={faSpinner} spin />
                    :
                        <Form.Check 
                            type="checkbox"
                            checked={task.users && task.users.find(tu => tu.id == user.id)}
                            onChange={(e) => {
                                e.stopPropagation();

                                setBusy("assign");

                                postData("tasks/"+(e.target.checked ? "":"un")+"assign", {task_id: task.id, user_id: user.id},
                                    function(result) {
                                        if (onTaskChanged)
                                            onTaskChanged(result);
                                    },
                                    function(error) {
                                        window.alert("Error updating task.");
                                    },
                                    function() {
                                        setBusy("");
                                    }
                                );                                  
                            }}                    
                        />
                    }
                    </>
                }
                <TaskLink task={task} />
            </div>
            <div>
                {task.due_date && 
                    <div>
                        <span>
                        {task.due_time && 
                            <>{task.due_time} on </>
                        }
                        {formatDate(task.due_date, "M/dd")}
                        </span>
                    </div>
                }
                {busy == "complete" ? 
                    <FontAwesomeIcon icon={faSpinner} spin />
                :
                    <OverlayTrigger
                        overlay={
                            <Tooltip>{task.status == "Complete" ? "Mark Not Completed":"Mark Complete"}</Tooltip>
                        }
                    >      
                        <span>
                        <Form.Check 
                            type="switch"
                            id={"taskcomplete" + task.id}
                            checked={task.status == "Complete"}
                            onClick={(e) => {
                                console.log("taskcomplete click",task.id, e.target.checked)
                                e.stopPropagation();
                            }}
                            onChange={(e) => {
                                console.log("taskcomplete",task.id, e.target.checked)
                                e.stopPropagation();
                                onToggleComplete(e.target.checked)
                            }}
                        />  
                        </span>
                    </OverlayTrigger>
                }
            </div>                                         
        </Row>        
    );
}

export default TaskRow;