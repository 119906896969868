export function getDecorationTypes() {
    return [
       {value: "paint", label: "Paint"},
       {value: "unpainted", label: "Unpainted"},
       {value: "plastic", label: "Plastic"},
       {value: "overlay", label: "Overlay"},
       {value: "vinyl", label: "Vinyl"},
       {value: "print", label: "Print"},
       {value: "coil", label: "Coil"},
       {value: "other", label: "Other"},
    ];
}

export const TaskStatuses = {
    completed: {id:8,  title:"Complete"},
    pending: {id:15, title:"Waiting"},
    inprogress: {id:16, title:"In Progress"},
    active: {id:17, title:"Active"},
    paused: {id:18, title:"Paused"},
    problem: {id:19, title:"Problem"},
    qa: {id:20, title:"Inspection"},
    skipped: {id:88, title:"Skipped"},
    invalid: {id:89, title:"Invalid"},
    deleted: {id:99, title:"Deleted"},

    isInvalid: (statusId) => statusId==88 || statusId==89 || statusId==99,
    areAllInvalid: (statusIds) => {
        let invalid = true;

        for (const statusId of statusIds) {
            if (statusId!=88 && statusId!=89 && statusId!=99) {
                invalid = false;
            }
        }

        return invalid;
    }
}

export const JobStatuses = {
    wait: {id:3, title:"Pre-Build"},
    building: {id:4, title:"Manufacturing"},
    qa: {id:5, title:"UL/QC"},
    shipping: {id:6, title:"Packing"},
    shipped: {id:7, title:"Shipped"},
    completed: {id:8,  title:"Complete", bgcolor: "bg-green-500", fgcolor: "white"},
    hold: {id:9, title:"Hold", bgcolor: "bg-yellow-500"},
    void: {id:12, title:"Void", bgcolor: "bg-red-500", fgcolor: "white"},
    approval: {id:50, title:"Art Approval"},   
    deleted: {id:99, title:"Deleted", bgcolor: "bg-black", fgcolor: "white"},

    isInvalid: (statusId) => statusId==12 || statusId==99,
}