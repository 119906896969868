import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import GoogleImage from "../components/google-image"

import { postData } from "../common/services/server"
import Coupon from "../components/coupon";

const Products = styled.table`
    width: 100%;
    thead tr th {
        background-color: #e0e0e0;
        padding: 5px;
    }
    tbody tr td {
        padding: 5px;
    }
`

function CouponProductsDrawer(props) {
  const [loading, setLoading] = useState(true);

  const [products, setProducts] = useState([]);

  useEffect(() => {
        setLoading(true);

        postData("products/data", {}, 
            function(results) {
                for (const product of results) {
                    product.selected = props.coupon.product_ids.indexOf(product.id) != -1;
                }

                setProducts(results);
            },
            function(error) {
                alert("An error occured loading the product parts.");
            },
            function() {
                setLoading(false);
            }
        );
  }, []);

  useEffect(() => {
    const selected = products.filter(p => p.selected);

    props.onChanged(selected.length, selected.map(p => p.id))
  }, [products]);  

  function onProductChange(e) {
    const productId = e.target.value;
    const checked = e.target.checked;

    setProducts(produce(draft => {
        const product = draft.find(p => p.id == productId);

        product.selected = checked;
    }));

    postData("coupons/toggleproduct", {
            coupon_id: props.coupon.id,
            product_id: productId,
            checked_ind: checked ? 1 : 0
        }, 
        function(response) {
        },
        function(error) {
            alert("An error occured updating the coupon.");
        },
        function() {
        }
    );
  }

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faClipboardCheck} />
                &nbsp;
                Coupon Products
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{marginBottom: "15px"}}>
                    Coupon <Coupon code={props.coupon.code} />
                </div> 

                { loading ? 
                    <Loading />
                :
                products.length == 0 ?
                    <p style={{color:"red"}}>No products found.</p>
                : 
                    <>
                        <p>
                            Select the products that this coupon is valid for:
                        </p>

                        <Products>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                </tr>
                            </thead>
                            <tbody>
                            {products.map(product => (
                                <tr key={product.id}>
                                    <td>
                                        <Form.Check 
                                            name="product_id"                                
                                            value={product.id}
                                            checked={product.selected}
                                            inline 
                                            label={product.title} 
                                            type="checkbox" 
                                            onChange={onProductChange}
                                        />
                                    </td>                               
                                </tr>
                            ))}
                            </tbody>
                        </Products>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default CouponProductsDrawer;