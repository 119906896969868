import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faProjectDiagram } from '@fortawesome/free-solid-svg-icons'

import DrawerTemplateDataSelect from "./template-dataselect";
import TextField from "../components/fields/field-text";

import { postData } from "../common/services/server"
import { getIconForType } from "../helpers/icons";
import Button from "../components/buttons/button";
import { useGetWorkflowItems } from "../api/queries/workflows";
import { useWorkflowAddItem, useWorkflowCreateTask } from "../api/mutations/workflows";
import ButtonMutate from "../components/buttons/button-mutate";

function AddWorkflowTasksDrawer({
    workflowId,
    onAdded,
    onHide
}) {
  const [loading, setLoading] = useState("");
  const [newTaskName, setNewTaskName] = useState("");

  const itemsQuery = useGetWorkflowItems({exists_workflow_id: workflowId});

  const addItem = useWorkflowAddItem(workflowId, {
    onSuccess: () => {
        itemsQuery.refetch();
    }
  });
  const createTask = useWorkflowCreateTask(workflowId);

  return (
        <DrawerTemplateDataSelect
            icon={faProjectDiagram}
            title={"Add Workflow Tasks"}
            query={itemsQuery}
            itemRender={(item) => (
                <div className="flex items-center gap-1">
                    <FontAwesomeIcon icon={getIconForType("workflow_" + item.type)} fixedWidth />
                    {item.name}
                </div>                
            )}
            itemFilter={(item) => {
                if (newTaskName) {
                    return item.name?.toLowerCase().includes(newTaskName.toLocaleLowerCase());
                } else return true;
            }}
            itemExistsCheck={(item) => item.exists_ind == 1}
            selectMutation={addItem}
            selectMutationDataProp="id"
            selectCaption="Add To Workflow"
            buttons={
                <div className="w-full">
                    <TextField
                        name="newTaskName"
                        prepend="New Task:"
                        value={newTaskName}
                        disabled={loading == "newtask"}
                        append={
                            <ButtonMutate
                                icon={faPlus}
                                caption="Create"
                                disabled={newTaskName.length == 0 || loading == "newtask"}
                                variant="solid-success"
                                size="sm"
                                mutation={createTask}
                                mutationData={newTaskName}
                                onMutateSuccess={onAdded}
                            />
                        }                        
                        onChange={(e, value) => { 
                            setNewTaskName(value);
                        }}                      
                    />
                </div>                
            }
            onHide={onHide}
        />
    )
}

export default AddWorkflowTasksDrawer;