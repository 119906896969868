import React from "react";
import moment from 'moment';

import SelectField from "./field-select"

function FieldTime(props) {
    var start  = moment().hours(7).startOf('hour');
    let times = [];

    for (let i=0; i<45; i++) {
        times.push({value: start.format("HH:mm"), label: start.format("hh:mm a")});

        start.add(15, 'minutes');
    }

    return (
        <SelectField
            {...props}
            idField="value"
            items={times}
        />
    )
}

export default FieldTime