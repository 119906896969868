import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import Button from "../components/buttons/button";
import Content from "../components/content"
import PageHeader from "../components/page-header";
import EmailTemplatesPanel from "./panels/panel-emailtemplates"
import EmailTemplateDrawer from "../drawers/drawer-emailtemplate"
import PageLayout from "../components/page-layout";

function EmailTemplatesPage(props) {
    const history = useHistory();
    let { tab } = useParams();

    if (!tab) tab = "active";

    const [refresh, setRefresh] = useState(1);
    const [create, setCreate] = useState(false);

    return (
        <PageLayout>
            <PageHeader 
                title={"Email Templates Manager"}
                help={"emailtemplates"}
                tab={tab}
                tabUrl={"/emailtemplates/{tab}"}
                tabs={[
                    {name:"active", title:"Active"},
                    {name:"inactive", title:"Inactive"},
                ]}
                onTab={(tab) => {
                    history.push("/emailtemplates/" + tab);
                }}                
            >
              <Button onClick={() => setCreate(true)} variant="success" size="sm">
                  <FontAwesomeIcon icon={faPlus} />{' '}
                  Create Template
              </Button>              
            </PageHeader>
            <Content>
                {tab == "active" && 
                    <EmailTemplatesPanel 
                      filters={{
                        status: "Active"
                      }}
                    /> 
                }
                {tab == "inactive" && 
                    <EmailTemplatesPanel 
                      filters={{
                        status: "Inactive"
                      }}
                    /> 
                }
            </Content>

             {create && 
                <EmailTemplateDrawer 
                    show={true}
                    template={{body:""}} 
                    onSaved={(template) => {
                        setCreate(false);
                        setRefresh(refresh+1);
                    }}
                    onHide={ () => setCreate(false) } /> 
            }                     
        </PageLayout>
    );
}

export default EmailTemplatesPage;