import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetWorkflowJobNotes = (workflowJobId, options={}) => {      
  return useQuery({ 
    queryKey: ["notes", "workflowjob", workflowJobId], 
    queryFn: ({signal}) => query("notes/list", {workflowjob_id: workflowJobId}, signal),
    ...options
  });
};

