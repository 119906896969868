import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetJob = (jobId, params, options={}) => {  
  return useQuery({ 
    queryKey: ["jobs", ""+jobId], 
    queryFn: ({signal}) => query("jobs/job", {
      id: jobId,
      ...params
    }, signal),
    ...options
  });
};
