import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Col from 'react-bootstrap/Col';

import settings from "../../settings"
import { getUser } from "../../common/services/auth"

import FieldLabel from "./label"
import HelpText from "./help-text"
import { getJsonData } from "../../common/services/server";

function FieldEmployee(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
  
    useEffect(
        () => {
          setIsLoading(true);
              
          const curUser = getUser();
    
          let source = getJsonData(
            settings.API_URL + (props.production ? 'users/production' : 'users/employees'),
            function(data) {

              if (!data.find(u => u.id == curUser.id)) {
                data.push(curUser);
              }

              setData(data);

              if (props.onData)
                props.onData(data)

            },
            function(error, status) {
              window.alert("Error loading employee data.")
            },
            function() {
              setIsLoading(false);
            }
          );   
    
          return () => {
            if (source) source.cancel();
          };
        }, 
        []
      );

    return (
        <Form.Group as={props.column ? Col:undefined}>
            {props.label && <FieldLabel required={props.required} bold={props.labelBold}>{props.label}</FieldLabel>}
            <FormControl 
                as="select" 
                name={props.name} 
                value={props.value} 
                required={props.required}
                disabled={props.disabled}
                size={props.size} 
                onChange={(e) => {
                  const user = data.find(u => u.id == e.target.value);
                  props.onChange(e, user)
                }}
                style={props.style}
            >
                <option value=""></option>
                {data.map(item => (
                    <option key={item.id} value={item.id}>{item.first_name} {item.last_name}</option>
                ))}
            </FormControl>
            {props.instructions && <HelpText>{props.instructions}</HelpText>}
        </Form.Group>
    )
}

export default FieldEmployee