import React, { useState } from "react";

import JobShapes from "../../components/job-shapes"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

function JobShapesPanel({job, ...props}) {
    const [canEdit, setCanEdit] = useState(userHasPermission(Permissions.Jobs, Actions.Edit));

    return (
        <JobShapes 
            job={job} 
            changepower={canEdit}
            changetrim={canEdit}
            changeprops={canEdit}
            onPropChanged={(prop, value) => {
                if (props.onPropChange) {
                    props.onPropChange(prop, value)
                }
            }}
        />
    );

}

export default JobShapesPanel;