import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"
import { Badge, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlug, faPlus, faTruckMoving, faTrash, faLevelUpAlt } from "@fortawesome/free-solid-svg-icons";

import ProductTaskDrawer from "../drawers/drawer-productjobtask";
import SelectTaskDrawer from "../drawers/drawer-selectjobtasktype";
import SelectSignDrawer from "../drawers/drawer-selectsign";
import JobTasksDrawer from "../drawers/drawer-jobtasks";

import BoardFields from "../components/board/board-fields"
import BoardColumns from "../components/board/board-columns"
import BoardField from "../components/board/board-field"

import { colors } from "../settings/settings";
import ButtonProcessing from "../components/buttons/button-processing";
import { postData } from "../common/services/server";

const Task = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    padding: 1px 4px;

    &.selected {
        background-color: ${colors.blue};
        color: white;

        &:hover {
            background-color: ${colors.blue};
        }
    }
    &:hover {
        background-color: #eee;
    }
`

const DayTaskList = ({ 
    product,
    tasks, 
    selectedTask,
    parentTaskId=null, 
    level=0, 
    onClick, 
}) => {
    const levelTasks = tasks.filter(t => 
      (t.dependent_tasktype_id == parentTaskId) || // child
      (t.dependent_tasktype_id == null && parentTaskId == null) || // root
      (t.dependent_tasktype_id && level==0 && tasks.find(t2 => t2.id == t.dependent_tasktype_id) == null) // prev day
    );

    return (
      <>
        {levelTasks.map(task => {
            const childTasks = tasks.filter(t => t.dependent_tasktype_id == task.id);

            return (
                <div key={task.id}>
                    <div style={{display: "flex", alignItems: "center", marginLeft: (level * 16)+"px"}}>
                        {(level > 0) && 
                            <FontAwesomeIcon icon={faLevelUpAlt} rotation={90} style={{marginRight:"5px"}} />
                        }

                        <Task 
                            className={selectedTask && selectedTask.id == task.id ? "selected":""}
                            onClick={() => onClick(task)}
                        >
                            {task.name}
                            {(task.parts && task.parts.length > 0) && 
                                <div>
                                { task.parts.length == 1 ?
                                    <>
                                    {task.parts.map(partId => {
                                        const part = product.parts.find(p => p.id == partId);
                                        return (
                                            <Badge size="sm" style={{border: "1px solid #ccc"}}>
                                                <FontAwesomeIcon icon={faPlug} style={{color: "#999", marginRight:" 3px"}} />
                                                {part ? (part.part_number ? (part.part_number + " - "):"") + part.title : "?"}
                                            </Badge>
                                        )
                                    })}
                                    </>
                                : task.parts.length > 1 ? 
                                    <>
                                        <Badge size="sm" style={{border: "1px solid #ccc"}}>
                                            <FontAwesomeIcon icon={faPlug} style={{color: "#999", marginRight:" 3px"}} />
                                            {task.parts.length} Parts
                                        </Badge>                                    
                                    </>
                                :
                                    ""
                                }
                                </div>
                            }
                            {(task.shippingmethods && task.shippingmethods.length > 0) && 
                                <FontAwesomeIcon icon={faTruckMoving} style={{color: "#999"}} />
                            }
                        </Task>      
                    </div>

                    {(childTasks.length > 0) &&
                        <DayTaskList 
                            product={product}
                            tasks={tasks} 
                            selectedTask={selectedTask}
                            parentTaskId={task.id} 
                            level={level+1} 
                            onClick={onClick} 
                        />
                    }
                </div>
            )
        })}
      </>
    )
}

function Panel({
  products,
  product,
  onChange,
}) {
  const [task, setTask] = useState(null);
  const [selectTask, setSelectTask] = useState(false);
  const [busy, setBusy] = useState("");
  const [selectSign, setSelectSign] = useState(false);
  const [previewJob, setPreviewJob] = useState(false);

  function onTaskChange(taskData) {    
    console.log("onTaskChange", taskData)
    setTask(produce(draft => {
        for (const prop of Object.keys(taskData)) {
            draft[prop] = taskData[prop];
        }          
    }));

    const tasksData = JSON.parse(JSON.stringify(product.jobtasks));
    const mwosData = JSON.parse(JSON.stringify(product.mwos));
    const taskIdx = tasksData.findIndex(t => t.id == task.id);

    if (taskIdx != -1) {
        for (const prop of Object.keys(taskData)) {
            tasksData[taskIdx][prop] = taskData[prop];
        } 

        if (taskData.mwo) {
            const mwoIdx = mwosData.findIndex(m => m.id == taskData.mwo.id);

            if (mwoIdx != -1)
                mwosData[mwoIdx] = taskData.mwo
            else 
                mwosData.push(taskData.mwo);
        }

        onChange({
            jobtasks: tasksData,
            mwos: mwosData
        })    
    }
  }

  let productionDays = product.production_days

  for (const task of product.jobtasks) {
    if (task.target_day && task.target_day > productionDays)
        productionDays = task.target_day;
  }

  return (
    <>  
        {product.sign_ind == 1 && 
            <BoardField
            type="number"
            label="Production Days"
            value={product.production_days}
            saveUrl={`products/updateprop?product_id=${product.id}&prop=production_days`}
            onSaved={onChange}
            />
        }  
        <BoardField
          type="boolean"
          label="Has Pattern"
          info="Does this product have an install pattern?"
          value={product.pattern_ind}
          saveUrl={`products/updateprop?product_id=${product.id}&prop=pattern_ind`}
          onSaved={onChange}
        />   
        <BoardField
          type="select"
          label="Workflow"
          value={product.workflow_id}
          optionsUrl="query/workflows/list?type=workflow"
          suffix={
            <Button
                size="sm"
                variant="outline-primary"
                disabled={!product.workflow_id}
                onClick={() => {
                    window.open("#/workflow/" + product.workflow_id);
                }}
            >
                Edit Workflow
            </Button>
          }            
          saveUrl={`products/updateprop?product_id=${product.id}&prop=workflow_id`}
          onSaved={onChange}
        />   

        <BoardFields label="Job Tasks">
            <div style={{
                display: "grid",
                gridTemplateColumns: "50% 50%",
                gap: "5px"
            }}>
                <div>
                    {[...Array(productionDays)].map((x, i) => {
                        const day = i+1;
                        let dayTasks = product.jobtasks.filter(t => t.target_day == day);

                        /*
                        dayTasks.sort((a, b) => {
                            var keyA = a.dependent_tasktype_id;
                            var keyB = b.dependent_tasktype_id;

                            // Compare the 2 values
                            if (keyA < keyB) return -1;
                            if (keyA > keyB) return 1;
                            return 0;
                        });
                        */

                        return (
                            <BoardField 
                                key={i}
                                type="children"
                                label={"Day " + day}
                            >
                                <DayTaskList
                                    product={product}
                                    tasks={dayTasks}
                                    selectedTask={task}
                                    onClick={(task) => {
                                        console.log(task)
                                        setTask(task)
                                    }}
                                />
                            </BoardField>
                        )
                    })}   
                </div>
                <div>
                    {(task && task.id) ? 
                        <>
                            <BoardField
                                type="text"
                                label="Name"
                                value={task.name}
                                saveUrl={`products/updatetaskprop?product_id=${product.id}&jobtasktype_id=${task.id}&prop=name`}
                                onSaved={onTaskChange}                                
                            />
                            <BoardField
                                type="number"
                                label="Target Day"
                                value={task.target_day}
                                saveUrl={`products/updatetaskprop?product_id=${product.id}&jobtasktype_id=${task.id}&prop=target_day`}
                                onSaved={onTaskChange}                                
                            />   
                            <BoardField
                                type="list"
                                label="Dependent Task"
                                labelWarning={task.target_day > 1 && !task.dependent_tasktype_id}
                                info="The task that must be completed before this task becomes active."
                                value={task.dependent_tasktype_id || ""}
                                items={product.jobtasks.filter(t => t.id != task.id && t.target_day <= task.target_day)}
                                saveUrl={`products/updatetaskprop?product_id=${product.id}&jobtasktype_id=${task.id}&prop=dependent_tasktype_id`}
                                onSaved={onTaskChange}                                
                            />    
                            <BoardField
                                type="boolean"
                                label="Requires QA"
                                info="Task must have QA before starting?"
                                value={task.dependent_qa_ind}
                                saveUrl={`products/updatetaskprop?product_id=${product.id}&jobtasktype_id=${task.id}&prop=dependent_qa_ind`}
                                onSaved={onTaskChange}                                
                            />          
                            <BoardField
                                type="list"
                                label="MWO"
                                blankLabel="Any MWO"
                                info="The MWO Sticker to scan to start the task"
                                value={task.mwo ? task.mwo.id : ""}
                                items={product.mwos}
                                saveUrl={`products/updatetaskprop?product_id=${product.id}&jobtasktype_id=${task.id}&prop=mwo_id`}
                                onSaved={onTaskChange}    
                                onChange={(value) => {
                                    setTask(produce(draft => {
                                        if (value)
                                            draft.mwo = product.mwos.find(m => m.id == value);
                                        else 
                                            draft.mwo = null;
                                    }))
                                }}                                                                 
                            />   

                            <BoardFields label="Task Visibility" info="Only show this task in the job when...">
                                <BoardField
                                    type="list"
                                    label="Visibility"
                                    value={task.visibility || "always"}
                                    items={[
                                        {value: "always", label: "Always"},
                                        {value: "parts", label: "Specific Parts"},
                                        {value: "shippingmethods", label: "Shipping Method"},
                                    ]}
                                    saveUrl={`products/updatetaskprop?product_id=${product.id}&jobtasktype_id=${task.id}&prop=visibility`}
                                    onSaved={onTaskChange}    
                                    onChange={(value) => {
                                        setTask(produce(draft => {
                                            draft.visibility = value;
                                        }))
                                    }}                            
                                />
                                {task.visibility == "parts" && 
                                    <BoardField
                                        type="list"
                                        name="parts"
                                        listType="checkbox"
                                        listDirection="vertical"
                                        label="Parts"
                                        value={task.parts || []}
                                        items={product.parts.map(p => {
                                            return {
                                                value: p.id,
                                                label: (p.part_number ? (p.part_number+" - "):"") + p.title
                                            }
                                        })}
                                        saveUrl={`products/updatetaskvisibility?product_id=${product.id}&jobtasktype_id=${task.id}&visibility=parts`}
                                        onSaved={onTaskChange}    
                                    />
                                }
                                {task.visibility == "shippingmethods" && 
                                    <BoardField
                                        type="list"
                                        name="shippingmethods"
                                        listType="checkbox"
                                        listDirection="vertical"
                                        label="Shipping Methods"
                                        value={task.shippingmethods || []}
                                        items={product.shippingmethods.map(p => {
                                            return {
                                                value: p.id,
                                                label: p.title
                                            }
                                        })}
                                        saveUrl={`products/updatetaskvisibility?product_id=${product.id}&jobtasktype_id=${task.id}&visibility=shippingmethods`}
                                        onSaved={onTaskChange}    
                                    />                                
                                }
                            </BoardFields>

                            <BoardFields label="Production Estimate">
                                <BoardColumns>
                                    <BoardField
                                        type="number"
                                        label="Minutes"
                                        value={task.production_estimate}
                                        saveUrl={`products/updatetaskprop?product_id=${product.id}&jobtasktype_id=${task.id}&prop=production_estimate`}
                                        onSaved={onTaskChange}                                
                                    />  
                                    <BoardField
                                        type="list"
                                        label="Calculation"
                                        value={task.production_estimatecalc}
                                        items={[
                                            {value: "fixed", label: "Fixed Length"},                                        
                                            {value: "xShapeSize", label: "Multiply By Shape Size"},
                                            {value: "xShapeCount", label: "Multiply By Shape Count"},
                                        ]}
                                        saveUrl={`products/updatetaskprop?product_id=${product.id}&jobtasktype_id=${task.id}&prop=production_estimatecalc`}
                                        onSaved={onTaskChange}                                
                                    />  
                                </BoardColumns>  
                            </BoardFields>                          
                            
                            <BoardField
                                type="select"
                                label="Job Status"
                                info="Status of the job when this task is active"
                                optionsUrl="jobs/statuses"
                                value={task.job_status_id}
                                saveUrl={`products/updatetaskprop?product_id=${product.id}&jobtasktype_id=${task.id}&prop=job_status_id`}
                                onSaved={onTaskChange}                                
                            />  
                            <BoardField
                                type="select"
                                label="Production Station"
                                info="Controls which station this task shows up on in the shop."
                                optionsUrl="stations/data"
                                value={task.station_id || ""}
                                labelField="name"
                                saveUrl={`products/updatetaskprop?product_id=${product.id}&jobtasktype_id=${task.id}&prop=station_id`}
                                onSaved={onTaskChange}                                
                            />   
                            <BoardField
                                type="select"
                                label="Production Skill"
                                info="Controls which employees can perform the task."
                                optionsUrl="users/rolesata?production_ind=1"
                                value={task.role_id || ""}
                                labelField="name"
                                saveUrl={`products/updatetaskprop?product_id=${product.id}&jobtasktype_id=${task.id}&prop=role_id`}
                                onSaved={onTaskChange}                                
                            />   

                            <BoardFields label="Dependent Tasks" info="The following tasks are dependent on this task:">
                                {product.jobtasks.filter(t => t.dependent_tasktype_id == task.id).map(tsk => (
                                    <div key={tsk.id} style={{fontSize:"12px"}}>
                                        - {tsk.name}
                                    </div>
                                ))}
                                {(product.jobtasks.filter(t => t.dependent_tasktype_id == task.id).length == 0) && 
                                    <p style={{color: "#ccc", margin: "20px", textAlign: "center"}}>No tasks found</p>
                                }
                            </BoardFields>

                            <div style={{marginTop: "10px"}}>
                                <ButtonProcessing
                                    caption="Delete Task"
                                    icon={faTrash}
                                    size="sm"
                                    variant="outline-danger"
                                    processing={busy == "delete"}
                                    onClick={() => {
                                        if (window.confirm("Are you sure you want to delete this production task?")) {
                                            setBusy("delete")

                                            postData("products/deletetasktype", {
                                                    product_id: product.id,
                                                    id: task.id 
                                                },
                                                function(result) {
                                                    const tasksData = JSON.parse(JSON.stringify(product.jobtasks));
                                                    const taskIdx = tasksData.findIndex(t => t.id == task.id);
                                                
                                                    if (taskIdx != -1) {
                                                        tasksData.splice(taskIdx, 1);
                                                
                                                        onChange({
                                                            jobtasks: tasksData
                                                        })    
                                                    }         
                                                    
                                                    setTask(null);
                                                },
                                                function(error) {
                                                    alert("Error deleting the task")
                                                },
                                                function() {
                                                setBusy("");
                                                }
                                            );
                                        }
                                    }}
                                />
                            </div>                                            
                        </>
                    :
                        <p style={{textAlign:"center", margin: "50px", color: "#999"}}>
                            Select A Task
                        </p>
                    }
                </div>
            </div> 

            <div style={{margin:"10px 1px", display:"flex", gap:"5px"}}>
                <ButtonProcessing
                    caption="Add Existing Task"
                    icon={faPlus}
                    size="sm"
                    processing={busy == "addexisting"}
                    variant="outline-primary"
                    onClick={() => setSelectTask(true)}
                />                
                <Button
                    size="sm"
                    variant="outline-success"
                    onClick={() => {
                        setTask({
                            product_id: product.id,                            
                        })
                    }}
                >
                    Create Job Task
                </Button>

                <ButtonProcessing 
                    caption="Preview Sign"
                    icon={faEye}
                    size="sm"
                    variant="outline-secondary"
                    processing={busy == "preview"}
                    style={{marginLeft: "auto"}}
                    onClick={() => {
                        setSelectSign(true)
                    }}
                />                
            </div>
        </BoardFields>     

        {selectTask && 
            <SelectTaskDrawer
                label="Add"
                products={products.filter(p => p.id != product.id)}
                parts={product.parts}
                existingTasks={product.jobtasks}
                onSelect={(taskData) => {
                    setSelectTask(false);
                    setBusy("addexisting");
                    
                    postData("products/addtask", {
                            id: product.id,
                            task_id: taskData.id
                        }, 
                        function(result) {
                            const tasksData = JSON.parse(JSON.stringify(product.jobtasks));

                            tasksData.push(result);
                        
                            onChange({
                                jobtasks: tasksData
                            })   
        
                            setTask(result);                            
                        },
                        function(error) {
                            alert("An error occured adding the task.");
                        },
                        function() {
                            setBusy("")
                        }
                    );
                }}
                onHide={ () => setSelectTask(false) } 
            />
        } 
        {(task && !task.id) && 
            <ProductTaskDrawer
                product={product}
                task={task}
                onSaved={(taskData) => {
                    console.log("on task saved",taskData)

                    const tasksData = JSON.parse(JSON.stringify(product.jobtasks));
                    const taskIdx = tasksData.findIndex(t => t.id == taskData.id);
                
                    if (taskIdx != -1)
                        tasksData[taskIdx] = taskData
                    else 
                        tasksData.push(taskData);
                
                    onChange({
                        jobtasks: tasksData
                    })   

                    setTask(null);
                }}
                onHide={ () => setTask(null) } 
            />
        }  
        {selectSign && 
            <SelectSignDrawer 
                show={true}
                prompt="Which sign do you want to preview?"
                filters={{
                    product_id: product.id
                }}
                onSelect={(sign) => {
                    console.log({sign})
                    setBusy("preview");
                    setSelectSign(false);

                    postData("jobs/preview", {
                        sign_id: sign.id,
                        tasks: true
                    }, 
                        function(result) {
                            setPreviewJob(result);
                        },
                        function(error) {
                            alert("An error occured generating the preview.");
                        },
                        function() {
                            setBusy("")
                        }
                    );   
                }} 
                onHide={ () => setSelectSign(false) } 
            /> 
        }     
        {previewJob && 
          <JobTasksDrawer 
            show={true}
            job={previewJob}
            onHide={() => setPreviewJob(null)}
          />
        }                           
    </>
  );
}

export default Panel;