import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"
import { useHistory, Link } from "react-router-dom";
import ToggleButton from 'react-bootstrap/ToggleButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell, 
    CurrencyCell,
    DateCell,
    LinkCell,
    UserCell,
    SortHeaderCell,
    SortTypes,
} 
from "../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"
import Paginator from "../components/paginator"
import ListField from "../components/fields/field-list"
import SelectField from "../components/fields/field-select"

import { postData } from "../common/services/server"
import { useBodyClass } from "../hooks/useBodyClass"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { dimensions } from "../settings/settings"
import { removeNulls, formatPrice, formatPriceList, formatDate } from "../helpers/utility"

const Report = styled.div`
    @media print{@page {size: landscape}}

    table.data {
        display: none;
    }

    @media print
    {    
        .fixedDataTableLayout_main {
            display: none;
        }
        .paginator {
            display: none;
        }
        table.data {
            display: block;
            font-size: 12px;
            
            th {
                background-color: #eee;
                border: 1px solid #ccc;
                padding: 3px;
            }
            td {
                border: 1px solid #ccc;
                padding: 3px;
                vertical-align: top;
            }
        }
    }
`

const Filters = styled.div`
    display: flex;
    margin-bottom: 8px;
    margin-top: -8px;

    > * {
        margin-right: 8px;
    }

    label {
        background-color: #eee;
        padding: 15px 10px;
        border-radius: 5px;
        margin: 0px;
        margin-right: 10px;
    }
    span {
        font-size: 10px;
    }
`

function OrdersReport(props) {
    const history = useHistory();

    const windowDimensions = useWindowDimensions();

    const [data, setData] = useState([]);    
    const [totals, setTotals] = useState({});    
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({page:1,pages:1,total:0});
    const [sort, setSort] = useState({column: "order_date", dir: SortTypes.DESC});
    const [filters, setFilters] = useState({
        company_id: 1,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    });

    useBodyClass(`noscroll`);

    function loadData(options={}) {
        setLoading(true);

        postData("reports/orders", removeNulls({ 
            ...filters,
            sort: sort.column,
            sortdir: sort.dir,
            page: page,
            perPage: filters.month && filters.year ? 1000 : 100,
            ...options
        }),
            function(result) {
                setData(result.data);
                setPagination(result.pagination)

                const records = result.data
                    .filter(r => r.status_id != 1 && r.status_id != 2 && r.status_id != 14 && r.status_id != 66);

                if (records.length) {
                    setTotals({
                        cost: records.map(order => order.cost || 0).reduce((prev, next) => parseFloat(prev) + parseFloat(next)),
                        discount: records.map(order => order.discount || 0).reduce((prev, next) => parseFloat(prev) + parseFloat(next)),
                        shipping: records.map(order => order.shipping || 0).reduce((prev, next) => parseFloat(prev) + parseFloat(next)),
                        shipping_actual: records.map(order => order.shipping_actual || 0).reduce((prev, next) => parseFloat(prev) + parseFloat(next)),
                        shipping_surcharge: records.map(order => order.shipping_surcharge || 0).reduce((prev, next) => parseFloat(prev) + parseFloat(next)),
                        tax: records.map(order => order.tax || 0).reduce((prev, next) => parseFloat(prev) + parseFloat(next)),
                        total: records.map(order => order.total || 0).reduce((prev, next) => parseFloat(prev) + parseFloat(next)),
                        payments: records.map(order => order.payments || 0).reduce((prev, next) => parseFloat(prev) + parseFloat(next))
                    })
                }
            },
            function(error) {
                alert("Error loading data")
            },
            function() {
                setLoading(false);
            }
        );
    }

    useEffect(
        () => {
            loadData();
        }, 
        [page, sort, filters]
    );

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }

    function onFilterChange(filter, value) {
        setFilters(produce(draft => {
            draft[filter] = value
        })); 
    }

    function onFilter() {
        loadData({page:1})
    }

    const years = [];

    for (let i=-5; i<5; i++) {
        const year = new Date().getFullYear() + i;

        years.push({id:year, title: year});
    }

    return (
        <>
            <PageHeader 
                title="Orders Report"
                help="ordersreport"
            />
            <Content>
                {
                    loading
                ?
                    <Loading />
                :  
                    <Report>
                        <Filters>
                            <label>Filters:</label>
                            <div>
                                <span>Company</span>
                                <ListField 
                                    name="company_id" 
                                    value={filters.company_id} 
                                    list={[
                                        {id:1, title:"SignMonkey"},
                                        {id:2, title:"Paris Signs"},
                                    ]}
                                    onChange={(e) => {
                                        onFilterChange("company_id", e.target.value);
                                    }}
                                    groupClassName="nomargin"
                                    size="sm"
                                />
                            </div>
                            <div>
                                <span>Month</span>
                                <ListField 
                                    name="month" 
                                    value={filters.month} 
                                    list={[
                                        {id:1, title:"January"},
                                        {id:2, title:"February"},
                                        {id:3, title:"March"},
                                        {id:4, title:"April"},
                                        {id:5, title:"May"},
                                        {id:6, title:"June"},
                                        {id:7, title:"July"},
                                        {id:8, title:"August"},
                                        {id:9, title:"September"},
                                        {id:10, title:"October"},
                                        {id:11, title:"November"},
                                        {id:12, title:"December"},
                                    ]}
                                    onChange={(e) => {
                                        onFilterChange("month", e.target.value);
                                    }}
                                    groupClassName="nomargin"
                                    size="sm"
                                />
                            </div>
                            <div>
                                <span>Year</span>
                                <ListField 
                                    name="year" 
                                    value={filters.year} 
                                    list={years}
                                    onChange={(e) => {
                                        onFilterChange("year", e.target.value);
                                    }}
                                    groupClassName="nomargin"
                                    size="sm"
                                />
                            </div>
                            <div>
                                <span>State</span>
                                <SelectField 
                                    name="state_id" 
                                    url="states/data"
                                    labelField="abbrev2"
                                    size="sm"
                                    value={filters.state_id} 
                                    onChange={(e) => onFilterChange("state_id", e.target.value)} />                                  
                            </div>
                        </Filters>
                        <Table
                            rowHeight={85}
                            rowsCount={data.length}
                            width={windowDimensions.width - dimensions.dataTableWidthOffset}
                            height={windowDimensions.height - dimensions.dataTableHeightOffset - 15}
                            headerHeight={50}
                            footerHeight={35}
                            touchScrollEnabled={true}
                            rowClassNameGetter={(rowIndex) => {
                                const statusId = data[rowIndex].status_id;

                                if (statusId == 1 || statusId == 2) 
                                    return "Pending"
                                else if (statusId == 14 || statusId == 66)
                                    return "Invalid"
                            }}                            
                        >
                            <Column
                                header={
                                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                        Order
                                    </SortHeaderCell>
                                }
                                fixed
                                columnKey="order_id"
                                cell={
                                    <LinkCell data={data} onClick={(order) => history.push("/orders/order/" + order.order_id)} />
                                }
                                width={105}
                            />
                            <Column
                                header={
                                    <Cell>
                                        Status
                                    </Cell>
                                }
                                columnKey="status"
                                cell={<TextCell data={data} />}
                                width={125}
                            />                            
                            <Column
                                header={<Cell>Items</Cell>}
                                columnKey="items"
                                cell={({rowIndex, ...props}) => {
                                    const item = data[rowIndex];
                                    const signs = item.signs ? item.signs.split(",") : [];
                                    const items = item.items ? item.items.split(",") : [];
                                    
                                    return (
                                      <Cell {...props}>
                                        <div style={{display:"flex", flexFlow:"wrap"}}>
                                            {signs.map((sign,index) => (
                                                <span key={index} style={{border:"1px solid #ccc", borderRadius:"3px", padding:"3px", marginRight:"3px", fontSize:"12px", whiteSpace:"nowrap"}}>
                                                    <Link to={"/signs/sign/" + sign}>Sign #{sign}</Link>
                                                </span>
                                            ))}
                                            {items.map((itm,index) => (
                                                <span key={index} style={{border:"1px solid #ccc", borderRadius:"3px", padding:"3px", marginRight:"3px", fontSize:"10px", whiteSpace:"nowrap"}}>
                                                    {itm}
                                                </span>
                                            ))}
                                        </div>
                                      </Cell>
                                )}}
                                width={265}
                            />
                            <Column
                                header={<Cell>Jobs</Cell>}
                                columnKey="jobs"
                                cell={({rowIndex, ...props}) => {
                                    const item = data[rowIndex];
                                    const jobs = item.jobs ? item.jobs.split(",") : [];

                                    return (
                                      <Cell {...props}>
                                            {jobs.map(job => (
                                                <span key={job} style={{border:"1px solid #ccc", borderRadius:"3px", padding:"3px", marginRight:"3px", fontSize:"12px"}}>
                                                    <Link to={"/jobs/job/" + job}>#{job}</Link>
                                                </span>
                                            ))}
                                      </Cell>
                                )}}
                                width={200}
                            />
                            <Column
                                header={<Cell>Customer</Cell>}                        
                                columnKey="user_name"
                                cell={({rowIndex, ...props}) => (
                                    <UserCell 
                                        userId={data[rowIndex].user_id}
                                        userName={data[rowIndex].user_name}
                                        userEmail={data[rowIndex].user_email}
                                        onUser={() => history.push("/users/user/" + data[rowIndex].user_id)}
                                    />
                                )}
                                width={225}
                            />
                            <Column
                                header={<Cell>Cost</Cell>}
                                columnKey="cost"
                                cell={<CurrencyCell data={data} />}
                                footer={<Cell align="right">{formatPrice(totals.cost)}</Cell>}
                                width={125}
                            />
                            <Column
                                header={<Cell>Discount</Cell>}
                                columnKey="discount"
                                cell={<CurrencyCell data={data} />}
                                footer={<Cell align="right">{formatPrice(totals.discount)}</Cell>}
                                width={125}
                            />
                            <Column
                                header={<Cell>Shipping Surcharge</Cell>}
                                columnKey="shipping_surcharge"
                                cell={<CurrencyCell data={data} />}
                                footer={<Cell align="right">{formatPrice(totals.shipping_surcharge)}</Cell>}
                                width={125}
                            />
                            <Column
                                header={<Cell>Estimated Shipping</Cell>}
                                columnKey="shipping"
                                cell={<CurrencyCell data={data} />}
                                footer={<Cell align="right">{formatPrice(totals.shipping)}</Cell>}
                                width={125}
                            />
                            <Column
                                header={<Cell>Actual Shipping</Cell>}
                                columnKey="shipping_actual"
                                cell={<CurrencyCell data={data} />}
                                footer={<Cell align="right">{formatPrice(totals.shipping_actual)}</Cell>}
                                width={125}
                            />
                            <Column
                                header={<Cell>Tax</Cell>}
                                columnKey="tax"
                                cell={<CurrencyCell data={data} />}
                                footer={<Cell align="right">{formatPrice(totals.tax)}</Cell>}
                                width={125}
                            />
                            <Column
                                header={<Cell>Total</Cell>}
                                columnKey="total"
                                cell={<CurrencyCell data={data} />}
                                footer={<Cell align="right">{formatPrice(totals.total)}</Cell>}
                                width={125}
                            />
                            <Column
                                header={<Cell>Payments</Cell>}
                                columnKey="payments"
                                cell={({rowIndex, ...props}) => {
                                    const item = data[rowIndex];
                                    const payments = item.payments ? item.payments.split(",") : [];
                                    const dates = item.payment_dates ? item.payment_dates.split(",") : [];

                                    return (
                                      <Cell {...props}>
                                            {payments.map((payment, index) => (
                                                <div key={index}>
                                                    {formatPrice(parseFloat(payment))}
                                                    {' '}
                                                    <small>
                                                        {' '}on{' '}
                                                        {dates[index]}
                                                    </small>
                                                </div>
                                            ))}
                                      </Cell>
                                )}}                                
                                footer={<Cell align="right">{formatPrice(totals.payments)}</Cell>}
                                width={175}
                            />
                            {!filters.company_id && 
                                <Column
                                    header={
                                        <Cell>
                                            Company
                                        </Cell>
                                    }
                                    columnKey="company"
                                    cell={<TextCell data={data} />}
                                    width={125}
                                />
                            }
                            <Column
                                header={
                                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                        Order Date
                                    </SortHeaderCell>
                                }
                                columnKey="order_date"
                                cell={<DateCell data={data} />}
                                width={135}
                            />
                        </Table>
                        <Paginator
                            {...pagination}
                            item="Order"
                            items="Orders"
                            onPage={(page) => {
                                console.log("page", page)
                                setPage(page);
                            }}
                        />
                        <table className="data">
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Items</th>
                                    <th>Jobs</th>
                                    <th>Customer</th>
                                    <th>Cost</th>
                                    <th>Discount</th>
                                    <th>Estimated Shipping</th>
                                    <th>Actual Shipping</th>
                                    <th>Tax</th>
                                    <th>Total</th>
                                    <th>Payments</th>
                                    {!filters.company_id && <th>Company</th>}
                                    <th>Order Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row,index) => {
                                    const signs = row.signs ? row.signs.split(",") : [];
                                    const items = row.items ? row.items.split(",") : [];
                                    const jobs = row.jobs ? row.jobs.split(",") : [];

                                    const payments = row.payments ? row.payments.split(",") : [];
                                    const dates = row.payment_dates ? row.payment_dates.split(",") : [];

                                    return (
                                        <tr index={index}>
                                            <td>{row.order_id}</td>
                                            <td>
                                                {signs.map((sign,index) => (
                                                    <div key={index}>
                                                        Sign #{sign}
                                                    </div>
                                                ))}
                                                {items.map((itm,index) => (
                                                    <div key={index}>
                                                        {itm}
                                                    </div>
                                                ))}
                                            </td>
                                            <td>
                                                {jobs.map((job,index) => (
                                                    <div key={index}>
                                                        #{job}
                                                    </div>
                                                ))}
                                            </td>
                                            <td>
                                                <div>{row.user_email}</div>
                                            </td>
                                            <td>{formatPrice(row.cost)}</td>
                                            <td>{formatPrice(row.discount)}</td>
                                            <td>{formatPrice(row.shipping)}</td>
                                            <td>{formatPrice(row.shipping_actual)}</td>
                                            <td>{formatPrice(row.tax)}</td>
                                            <td>{formatPrice(row.total)}</td>
                                            <td nowrap>
                                                {payments.map((payment,index) => (
                                                    <div key={index} style={{whiteSpace: "nowrap"}}>
                                                        {formatPrice(payment)}
                                                        {' '}
                                                        <small>
                                                            on{' '}
                                                            {dates[index]}
                                                        </small>
                                                    </div>
                                                ))}
                                            </td>
                                            {!filters.company_id && <td>{row.company}</td>}
                                            <td>{formatDate(row.order_date)}</td>
                                        </tr> 
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>{formatPrice(totals.cost)}</td>
                                    <td>{formatPrice(totals.discount)}</td>
                                    <td>{formatPrice(totals.shipping)}</td>
                                    <td>{formatPrice(totals.shipping_actual)}</td>
                                    <td>{formatPrice(totals.tax)}</td>
                                    <td>{formatPrice(totals.total)}</td>
                                    <td>{formatPrice(totals.payments)}</td>
                                    {!filters.company_id && <td>&nbsp;</td>}
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tfoot>
                        </table>
                    </Report>
                }
            </Content>
        </>
    );
}

export default OrdersReport;