import React, { useState } from "react";
import clsx from "clsx";

import DrawerTemplateData from "./template-data";

export default function DrawerTemplateDataList({    
    query,
    selectCaption,
    itemClassName,
    itemRender,
    ...props
  }) {
  return (
    <DrawerTemplateData
      {...props}
      query={query}
      noDataFound={query.data?.length == 0}
    >
        <div className="grid gap-1">
            {query.data?.map((item,index) => {
                return (
                    <div key={index} className={itemClassName}>
                        {itemRender(item)}                        
                    </div>
                )
            })}
        </div>
    </DrawerTemplateData>  
  )
}
