import React, { useState, useEffect } from "react";
import produce from "immer"
import styled from "styled-components"
import { Badge, Form, FormControl } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faLevelUpAlt, faPlug, faBorderAll, faTruckMoving } from '@fortawesome/free-solid-svg-icons'
import InputGroup from 'react-bootstrap/InputGroup';

import Loading from "../../components/loading"
import GoogleImage from "../../components/google-image"

import { postData } from "../../common/services/server"
import ProductTaskDrawer from "../../drawers/drawer-productjobtask";

const Layout = styled.table`
    width: 100%;

    @media print
    {            
       font-size: 80%;
    }

    th {
        font-weight: normal;
    }

    thead {
        tr {
            th {
                text-align: center;
                padding: 5px;
                background-color: #eee;
                border: 1px solid #ccc;
                white-space: nowrap;

                &:first-child {
                    min-width: 200px;                    
                }
            }
        }
    }
    tbody {
        th {
            min-width: 200px;
            text-align: center;
            border-bottom: 1px solid #ccc;   
            position: -webkit-sticky; /* for Safari */
            position: sticky;
            left: 0px;
            background-color: white;
            border-right: 1px solid #ccc;                     
        }
        td {
            vertical-align: top;
            white-space: nowrap;
            font-size: 12px;
            padding: 3px;
            border: 1px solid #ccc;
        }
    }
`
const Task = styled.div`
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    margin-bottom: 2px;
    cursor: pointer;
`
const FiltersContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px;

    @media print
    {            
        display: none !important;
    }    
`
const Filters = styled.div`
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 5px;

    @media print
    {            
        display: none !important;
    }

    > label {
        background-color: #eee;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 5px;
        margin: 0px;
    }
`

const TaskList = ({ 
    product,
    tasks, 
    parentTaskId=null, 
    level=0, 
    onTask, 
    showParts,
    showShipping,
    showMWO,
}) => {
    const levelTasks = tasks.filter(t => 
      (t.dependent_tasktype_id == parentTaskId) || // child
      (t.dependent_tasktype_id == null && parentTaskId == null) || // root
      (t.dependent_tasktype_id && level==0 && tasks.find(t2 => t2.id == t.dependent_tasktype_id) == null) // prev day
    );

    return (
      <>
        {levelTasks.map(task => {
            const childTasks = tasks.filter(t => t.dependent_tasktype_id == task.id);

            return (
                <div key={task.id}>
                    <div style={{display: "flex", alignItems: "center", marginLeft: (level * 16)+"px"}}>
                        {(level > 0) && 
                            <FontAwesomeIcon icon={faLevelUpAlt} rotation={90} style={{marginRight:"5px"}} />
                        }
                        <Task onClick={() => onTask(task)}>
                            {task.name}
                            {(showParts || showMWO || showShipping) && 
                                <div style={{display:"flex", gap:"2px"}}>
                                    {(showParts && task.parts_titles && task.parts_titles.length > 0) && 
                                        <>
                                        {task.parts_titles.split(",").map(part => {
                                            return (
                                                <Badge size="sm" variant="secondary">
                                                    <FontAwesomeIcon icon={faPlug} style={{color: "white", marginRight:" 3px"}} />
                                                    {part}
                                                </Badge>
                                            )
                                        })}
                                        </>
                                    }
                                    {(showShipping && task.shippingmethods && task.shippingmethods.length > 0) && 
                                        <>
                                        {task.shippingmethods.split(",").map(shippingMethod => {
                                            return (
                                                <Badge size="sm" variant="secondary">
                                                    <FontAwesomeIcon icon={faTruckMoving} style={{color: "white", marginRight:" 3px"}} />
                                                    {shippingMethod}
                                                </Badge>
                                            )
                                        })}
                                        </>
                                    }                                    
                                    {(showMWO && task.mwo_title && task.mwo_title.length > 0) && 
                                        <Badge size="sm" style={{backgroundColor: "#eee", border: "1px solid #ccc"}}>
                                            <FontAwesomeIcon icon={faBorderAll} style={{color: "#999", marginRight:" 3px"}} />
                                            {task.mwo_title}
                                        </Badge>
                                    }                                    
                                </div>
                            }
                        </Task>
                    </div>

                    {(childTasks.length > 0) &&
                        <TaskList 
                            product={product}
                            tasks={tasks} 
                            parentTaskId={task.id} 
                            level={level+1} 
                            onTask={onTask} 
                            showParts={showParts}
                            showShipping={showShipping}
                            showMWO={showMWO}
                        />
                    }
                </div>
            )
        })}
      </>
    )
}

function ProductTasksPanel({products, categories}) {
  const [loading, setLoading] = useState(false);
  
  const [categoryId, setCategoryId] = useState(null);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [compareProducts, setCompareProducts] = useState([]);

  const [tasks, setTasks] = useState([]);
  const [mwos, setMWOs] = useState([]);
  const [parts, setParts] = useState([]);
  const [shippingMethods, setShippingmethods] = useState([])

  const [selected, setSelected] = useState(null);
  const [maxDay, setMaxDay] = useState(1);
  const [showTaskParts, setShowTaskParts] = useState(false);
  const [showTaskShipping, setShowTaskShipping] = useState(false);
  const [showTaskMWO, setShowTaskMWO] = useState(false);

  useEffect(
    () => {   
        setLoading("permits");

        postData("products/tasks", {},
          function(result) {
            setTasks(result);
          },
          function(error) {
              alert("Error loading product tasks")
          },
          function() {
            setLoading("");
          }
        );
        postData("products/mwos", {},
          function(result) {
            setMWOs(result);
          },
          function(error) {
              alert("Error loading product MWOs")
          }
        );   
        postData("products/allparts", {},
          function(result) {
            setParts(result);
          },
          function(error) {
              alert("Error loading product parts")
          }
        );   
        postData("content/shippingmethods", {},
          function(result) {
            setShippingmethods(result);
          },
          function(error) {
              alert("Error loading shipping methods")
          }
        );              
    }, 
    []
  );

  useEffect(
    () => {   
        if (!categoryId && categories && categories.length)
            setCategoryId(categories[0].id)
    }, 
    [categories]
  );

  useEffect(
    () => {   
        setCategoryProducts(products.filter(p => p.sign_ind == 1 && p.category_id == categoryId));        
    }, 
    [categoryId]
  );

  useEffect(
    () => {   
        if (compareProducts && compareProducts.length)
            setMaxDay(Math.max(...tasks.filter(t => compareProducts.find(p => p.id == t.product_id)).map(t => t.target_day)));
        else 
            setMaxDay(5);
    }, 
    [compareProducts, tasks]
  );  

  return (
    <>
    {loading != "" ?
        <Loading />
    :
        <>
            <FiltersContainer>
                <Filters>
                    <label>Product Category</label>
                    <FormControl 
                        as="select" 
                        name="category" 
                        value={categoryId} 
                        onChange={(e) => {
                            setCategoryId(e.target.value)
                        }}
                    >
                        {categories.map((cat,index) => (
                            <option key={index} value={cat.id}>
                                {cat.title}
                            </option>
                        ))}
                    </FormControl>
                    <label>Products</label>
                    <div style={{fontSize: "80%"}}>
                        {categoryProducts.map(prod => (
                            <Form.Check 
                                type="checkbox"
                                inline
                                label={
                                    <div style={{whiteSpace:"nowrap"}}>{prod.title}</div>
                                }
                                value={1}
                                checked={compareProducts.find(p => p.id == prod.id)}   
                                onChange={(e) => {
                                    e.persist();

                                    setCompareProducts(produce(draft => {
                                        const idx = draft.findIndex(p => p.id == prod.id);

                                        if (e.target.checked && idx == -1) {
                                            draft.push(prod);
                                        }
                                        else if (idx != -1) {
                                            draft.splice(idx, 1);
                                        }    
                                    }))
                                }}
                            />
                        ))}
                    </div>       
                </Filters>

                <div>
                    <Form.Check 
                        type="checkbox"
                        label={
                            <div style={{whiteSpace:"nowrap"}}>Show Parts</div>
                        }
                        value={1}
                        checked={showTaskParts}   
                        onChange={(e) => {
                            setShowTaskParts(e.target.checked);
                        }}
                    />   
                    <Form.Check 
                        type="checkbox"
                        label={
                            <div style={{whiteSpace:"nowrap"}}>Show Shipping</div>
                        }
                        value={1}
                        checked={showTaskShipping}   
                        onChange={(e) => {
                            setShowTaskShipping(e.target.checked);
                        }}
                    />                                     
                    <Form.Check 
                        type="checkbox"
                        label={
                            <div style={{whiteSpace:"nowrap"}}>Show MWO</div>
                        }
                        value={1}
                        checked={showTaskMWO}   
                        onChange={(e) => {
                            setShowTaskMWO(e.target.checked);
                        }}
                    />  
                </div>                         
            </FiltersContainer>

            <div
                style={{
                    width: "calc(100vw - 57px)",
                    overflow: "auto"
                }}
            >
                <Layout>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>

                            {[...Array(maxDay)].map((d, i) => (
                                <th key={i}>
                                    Day {i+1}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                    {compareProducts.map(prod => (
                        <tr key={prod.id}>
                            <th>
                                <GoogleImage 
                                    src={"products/" + prod.name + "/icon.jpg?v6"} 
                                    style={{ maxHeight: "40px", marginBottom: "10px" }} 
                                />

                                {prod.title} 
                            </th>


                            {[...Array(maxDay)].map((d, i) => (
                                <td key={i}>
                                    <TaskList
                                        product={prod}
                                        tasks={tasks.filter(t => t.product_id == prod.id && t.target_day == (i+1))}
                                        showParts={showTaskParts}
                                        showShipping={showTaskShipping}
                                        showMWO={showTaskMWO}
                                        onTask={(task) => setSelected({product: prod, task})}
                                    />    
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </Layout>
            </div>
        </>
    }

    { selected && 
        <ProductTaskDrawer 
            product={{
                ...selected.product,                
                parts: parts.filter(t => t.product_id == selected.product.id),
                shippingMethods,
                jobtasks: tasks.filter(t => t.product_id == selected.product.id),
                mwos: mwos.filter(m => m.product_id == selected.product.id)
            }}
            task={selected.task} 
            show={true}
            onSaved={(taskData) => {
                setTasks(produce(draft => {
                    const idx = draft.findIndex(t => t.id == taskData.id);

                    if (idx != -1) {
                        for (const prop of Object.keys(taskData)) {
                            draft[idx][prop] = taskData[prop];
                        }
                    }
                }));

                setSelected(null);
            }}
            onHide={ () => setSelected(null) } 
        /> 
    }    
    </>
  );
}

export default ProductTasksPanel;