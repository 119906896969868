import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faBars } from "@fortawesome/free-solid-svg-icons";

import DropdownMenu from "./dropdownmenu";
import Badge from "./badge";

export function AccordionItem({
    title,    
    count=null,
    countVariant,
    onCountClick,
    menu,
    onMenuItem,    
    size,
    disabled,
    initiallyExpanded,
    headerClassName,
    contentClassName,
    children
}) {
    const [expanded, setExpanded] = useState(false);

    useEffect(
        () => {
            if (expanded && disabled)            
                setExpanded(false);
        }, 
        [disabled]
    );
    useEffect(
        () => {
            setExpanded(initiallyExpanded);
        }, 
        [initiallyExpanded]
    );

    return (
        <li>
            <div 
                className={clsx(
                    "border border-gray-500 bg-white flex items-center justify-between cursor-pointer w-full",
                    size == "sm" ? "p-1" : "p-3",
                    disabled && "!bg-gray-100",
                    headerClassName,
                )}
                onClick={() => !disabled ? setExpanded((prev) => !prev) : null}
            >
                {title}
                <div className="flex items-center gap-1">
                    {(count > 0) &&
                        <Badge variant={countVariant || "secondary"} value={count} onClick={onCountClick} />
                    }
                    {(menu && menu.length > 0) && 
                        <DropdownMenu
                            icon={faBars}
                            menu={menu}
                            disabled={disabled}
                            onMenuItem={onMenuItem}
                        />
                    }                    

                    <FontAwesomeIcon icon={expanded ? faCaretUp:faCaretDown} className={clsx("m-2", disabled && "text-gray-200" )} />
                </div>
            </div>   
            {expanded && 
                <div className={clsx(
                    "bg-gray-100",
                    size == "sm" ? "p-1" : "p-2",
                    contentClassName,
                )}>
                    {children}
                </div>
            }         
        </li>
    )
}

export function Accordion({
    className,
    children
}) {
  return (
    <ul className={clsx(`
            w-full
            [&>li:first-child>div]:rounded-t
            [&>li:last-child>div]:rounded-b
            [&>li:last-child>div]:border-t-0
        `,
        className
    )}>
        {children}
    </ul>
  )
}
