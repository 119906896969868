import React from "react"
import { Link } from "react-router-dom"

import BoardField from "../components/board/board-field";
import Panel from "./panel";
import { useGetProject } from "../api/queries/projects";
import { useSetProjectProp } from "../api/mutations/projects"
import { formatPhoneNumber } from "../helpers/utility";

function ProjectDetailsPanel({
    projectId,
}) {
    const projectQuery = useGetProject(projectId);
    const project = projectQuery.data;

    const setTitle = useSetProjectProp(projectId, "title");
    const setDescription = useSetProjectProp(projectId, "description");
    const setManagerUserId = useSetProjectProp(projectId, "manager_user_id");
    const setStatusId = useSetProjectProp(projectId, "status_id");

    function formatDetails(details) {
        let phoneLabelPos = details.indexOf("<th>Phone Number</th>");

        if (phoneLabelPos != -1) {
            let phoneStartPos = details.indexOf("<td>", phoneLabelPos);

            if (phoneStartPos != -1) {
                let phoneEndPos = details.indexOf("</td>", phoneStartPos);

                if (phoneEndPos != -1) {
                    let phoneNo = details.substring(phoneStartPos+4, phoneEndPos);

                    console.log({phoneNo})
                    details = details.replace(phoneNo, formatPhoneNumber(phoneNo));
                }
            }
        }

        return details;
    }
        
    return (
        <Panel query={projectQuery}>
            {project && 
                <div className="grid gap-1">         
                    <BoardField
                        label="ID"
                        type="children">
                        <Link to={"/projects/project/" + project.id} className="text-blue-500">
                            #{project.id}
                        </Link>
                    </BoardField>

                    <BoardField
                        label="Title"
                        type="text"
                        value={project.title}
                        saveMutation={setTitle}
                    />
                    <BoardField
                        label="Description"
                        type="paragraph"
                        value={project.description}
                        saveMutation={setDescription}
                    />
                    <BoardField
                        label="Manager"
                        type="select"
                        value={project.manager_user_id}
                        optionsUrl="users/employees"
                        labelField="name"
                        saveMutation={setManagerUserId}
                    />
                    <BoardField
                        label="Status"
                        type="select"
                        value={project.status_id}
                        optionsUrl="projects/statuses"
                        saveMutation={setStatusId}
                    />
                    <BoardField
                        label="Created"
                        type="label"
                        format="datetime"
                        value={project.created_date}
                    />                                                                          
                    <BoardField
                        label="Created From"
                        type="label"
                        value={project.created_from || "Unknown"}
                    />

                    {project.details && 
                        <BoardField
                            type="html"
                            value={formatDetails(project.details)}
                        />
                    }                
                </div>
            }
        </Panel>
    );
}

export default ProjectDetailsPanel