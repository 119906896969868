import React from "react"
import styled from "styled-components"

import Loading from "../loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Box = styled.div`
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    margin-bottom: 20px;
    
    h2 {
        display: flex;
        align-items: center;
        margin: 0px;
        padding: 10px;
        font-size: 14px;
        background-color: #eee;
        border-bottom: 1px solid #ccc;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        svg {
            margin-right: 5px;
        }
    }

    > div {
        padding: ${props => props.nopadding ? 0:10}px;
    }
`

function ChartBox(props) {
    return (
        <Box nopadding={props.nopadding}>
            {props.title && 
                <h2>
                    {props.icon && 
                        <><FontAwesomeIcon icon={props.icon} />{' '}</>
                    }
                    {props.title}
                </h2>
            }
            <div style={{minHeight:props.height+"px"}}>
                {props.loading ?
                    <Loading nolabel={true} />
                : props.error ?
                    <div style={{textAlign: "center", color:"red", margin: "25px"}}>
                        {props.error}
                    </div>
                :
                    props.children
                }
            </div>
        </Box>
    );
}

export default ChartBox