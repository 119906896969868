import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import './global.css';
import './App.css';

import { isLoggedIn } from './common/services/auth';
import { PusherProvider } from './common/services/pusher';

import HomePage from "./pages/home"
import EmailPage from "./pages/email"
import EmailsPage from "./pages/emails"
import CouponsPage from "./pages/coupons"
import InboxAdminPage from "./pages/inboxes"
import CallTemplatesPage from "./pages/calltemplates"
import EmailTemplatesPage from "./pages/emailtemplates"
import BlogPage from "./pages/blog"
import PhotosPage from "./pages/photos"
import ProductsPage from "./pages/products"
import PartsPage from "./pages/parts"
import LoginPage from "./pages/login"
import ShapesPage from "./pages/shapes"
import OrdersPage from "./pages/orders"
import OrderPage from "./pages/order"
import JobsPage from "./pages/jobs"
import JobPage from "./pages/job"
import WorkflowJobsPage from "./pages/workflowjobs"
import WorkflowJobPage from "./pages/workflowjob"
import NotesPage from "./pages/notes"
import SignsPage from "./pages/signs"
import SignPage from "./pages/sign"
import SignPage2 from "./pages/sign2"
import SignLogoPage from "./pages/signlogo"
import InteractiveBuilderPage from "./pages/interactivebuilder"
import UsersPage from "./pages/users"
import UserPage from "./pages/user"
import ProjectsPage from "./pages/projects"
import ProjectPage from "./pages/project"
import ProjectBoardPage from "./pages/projectboard"
import TasksPage from "./pages/tasks"
import ArtPage from "./pages/art"
import DataPage from "./pages/data"
import StationPage from "./pages/station"
import CalendarPage from "./pages/calendar"
import KerningPage from "./pages/kerning"
import SmsPage from "./pages/sms"

import WorkflowsPage from "./pages/workflows"
import WorkflowPage from "./pages/workflow"

import UlReport from "./reports/ul"
import ShippingReport from "./reports/shipping"
import OrdersReport from "./reports/orders"
import PaymentsReport from "./reports/payments"
import SiteHeader from "./components/site-header";

import InstallationCoverPagePDF from "./components/pdfs/install_coverpage";
import PackingSlipsPDF from "./components/pdfs/packing_slips";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5*60*1000,
    },
  },
});

const ProtectedRoute = ({ component: Component, embedded, ...rest }) => {
  if (!isLoggedIn()) console.log("location.hash", window.location.hash);
  
  return (
    <Route {...rest} render={(props) => (
          isLoggedIn() 
          ? 
            <Component {...props} embedded={embedded} /> 
          : 
            <Redirect to={'/login/' + encodeURIComponent((window.location.hash || "#/").substring(1))} />   
    )} />
  );
};

function App() {  
  const [location, setLocation] = useState(window?.location?.hash);

  useEffect(
    () => {
      setLocation(window.location.hash)
    }, 
    [window.location.hash, window.location.href]
  );

  const isCustomPage = location.includes("/station/") || location.includes("/pdfs/");
  const isEmbeddedPage = location.includes("embeded=1") ? true:false;

  return (
    <QueryClientProvider client={queryClient}>   
      <PusherProvider>
        <Router>
            {(!isCustomPage && !isEmbeddedPage) && 
              <SiteHeader />
            }

            <Route path="/login/:returnto" component={LoginPage} />
            <ProtectedRoute exact path="/" component={HomePage} />
            <ProtectedRoute path='/dashboard/:tab?' component={HomePage} />
            <ProtectedRoute path='/calendar/:tab?' component={CalendarPage} />
            <ProtectedRoute path='/users/user/:tab?/:id' component={UserPage} />
            <ProtectedRoute path='/users/:tab?' exact component={UsersPage} />
            <ProtectedRoute path='/interactivebuilder/:id' exact component={InteractiveBuilderPage} />
            <ProtectedRoute path='/signs/sign/:tab?/:id' component={SignPage} embedded={isEmbeddedPage} />
            <ProtectedRoute path='/signs/sign2/:tab?/:id' component={SignPage2} />
            <ProtectedRoute path='/signs/:tab?' exact component={SignsPage} />
            <ProtectedRoute path='/signlogos/:id/:tab?' component={SignLogoPage} />
            <ProtectedRoute path='/coupons/:tab?' component={CouponsPage} />
            <ProtectedRoute path='/notes/:tab?' component={NotesPage} />

            <ProtectedRoute path='/tasks/:type?/:id?' component={TasksPage} />

            <ProtectedRoute path='/orders/order/:tab?/:id' component={OrderPage} />
            <ProtectedRoute path='/orders/:tab?' exact component={OrdersPage} />
            <ProtectedRoute path='/jobs/job/:tab?/:id' component={JobPage} />
            <ProtectedRoute path='/jobs/:tab?' exact component={JobsPage} />
            <ProtectedRoute path='/projectboard/:id' component={ProjectBoardPage} />
            <ProtectedRoute path='/projects/project/:id' component={ProjectPage} />
            <ProtectedRoute path='/projects/:tab?' exact component={ProjectsPage} />
            <ProtectedRoute path='/shapes/:tab?' component={ShapesPage} />
            <ProtectedRoute path='/products/:tab?' component={ProductsPage} />

            <ProtectedRoute path='/workflowjobs/:tab?' exact component={WorkflowJobsPage} />
            <ProtectedRoute path='/workflowjob/:tab?/:id' component={WorkflowJobPage} />
            <ProtectedRoute path='/workflow/:workflowId?' component={WorkflowPage} />
            <ProtectedRoute path='/workflows' component={WorkflowsPage} />

            <ProtectedRoute path='/parts/:tab?/:partId?' component={PartsPage} />
            
            <ProtectedRoute path='/emails/email/:id' exact component={EmailPage} />
            <ProtectedRoute path='/emails/:tab?' exact component={EmailsPage} />
            
            <ProtectedRoute path='/art/:tab?' component={ArtPage} />
            <ProtectedRoute path='/photos/:tab?' component={PhotosPage} />
            <ProtectedRoute path='/data/:tab?' component={DataPage} />
            <ProtectedRoute path='/station/:stationId?' component={StationPage} />
            <ProtectedRoute path='/blog' component={BlogPage} />
            <ProtectedRoute path='/inboxes' component={InboxAdminPage} />
            <ProtectedRoute path='/call/templates' render={(props) => <CallTemplatesPage />} />
            <ProtectedRoute path='/emailtemplates/:tab?' component={EmailTemplatesPage} />
            
            <ProtectedRoute path='/kerning/:styleId?' component={KerningPage} />

            <ProtectedRoute path='/reports/ul' component={UlReport} />
            <ProtectedRoute path='/reports/shipping' component={ShippingReport} />
            <ProtectedRoute path='/reports/orders' component={OrdersReport} />
            <ProtectedRoute path='/reports/payments' component={PaymentsReport} />

            <ProtectedRoute path='/pdfs/install/:orderItemId' component={InstallationCoverPagePDF} />
            <ProtectedRoute path='/pdfs/packingslips/:orderId' component={PackingSlipsPDF} />

            <ProtectedRoute path='/sms/:tab?' component={SmsPage} />
        </Router>
      </PusherProvider>  
    </QueryClientProvider>  
  )  
}

function SignMonkey() {
  return (
    <App />
  );
}

export default SignMonkey;