import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { isTablet, isMobile } from "react-device-detect"

import ListGroup from 'react-bootstrap/ListGroup'

import Content from "../components/content"
import PageHeader from "../components/page-header";
import JobsPanel from "./panels/panel-jobs";
import JobsEmployees from "./panels/panel-jobsemployees";
import JobsDashboard from "./panels/panel-jobsdashboard";
//import JobsManager from "./panels/panel-jobsmanager2";
import PendingJobsPanel from "./panels/panel-pendingjobs";
import ActiveJobsPanel from "./panels/panel-activejobs";
import OrderDrawer from "../drawers/drawer-order";
import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import Loading from "../components/loading";
import { postData } from "../common/services/server";
import PageLayout from "../components/page-layout";

function JobsPage(props) {
    const history = useHistory();
    let { tab } = useParams();

    if (!tab) tab = "dashboard";

    const [isLoading, setIsLoading] = useState(true);
    const [tabs, setTabs] = useState([])
    const [selected, setSelected] = useState(null);
    const [counter, setCounter] = useState(0);

    useEffect(
        () => {
            setIsLoading(true);

            postData("jobs/stats", {},
                function(result) {
                    setTabs([
                        {name:"employees", title:"Employees", permission: Permissions.Jobs },
                        {name:"dashboard", title:"Dashboard", permission: Permissions.Jobs },
                        //{name:"manager", title:"Manager", permission: Permissions.Jobs },
                        {name:"pending", title:isTablet ? "Pending" : "Pending Jobs", count:result.pending_count, permission: Permissions.Jobs, action: Actions.Create},
                        {name:"active", title:isTablet ? "Active" : "Active Jobs", count:result.active_count, permission: Permissions.Jobs},
                        {name:"hold", title:isTablet ? "On Hold" : "Jobs on Hold", count:result.hold_count, permission: Permissions.Jobs},
                        {name:"all", title:isTablet ? "All" : "All Jobs", permission: Permissions.Jobs},
                        {name:"reports", title:"Reports", align: "right"},
                    ]);             
                },
                function(error) {
                    alert("Error loading jobs")
                },
                function() {
                    setIsLoading(false)
                }
            );
        }, 
        []
    );

    return (
        <PageLayout>
            <PageHeader 
                title="Job Manager"
                help="jobs"
                tab={tab}
                tabUrl={"/jobs/{tab}"}
                tabs={tabs}
                onTab={(tab) => {
                    history.push("/jobs/" + tab);
                }}
            />
            <Content permission={Permissions.Jobs}>
                {isLoading ?
                    <Loading />
                : 
                    <>
                    {tab == "employees" &&
                        <JobsEmployees
                            haveTabs={true}
                        />
                    }                      
                    {tab == "dashboard" &&
                        <JobsDashboard
                            haveTabs={true}
                        />
                    }                
                    {/* {tab == "manager" &&
                        <JobsManager
                            haveTabs={true}
                        />
                    }                 */}
                    {tab == "all" &&
                        <JobsPanel
                            haveTabs={true}
                        />
                    }
                    {tab == "hold" &&
                        <JobsPanel
                            filters={{
                                status_id: 9
                            }}
                            haveTabs={true}
                        />
                    }                    
                    {tab == "active" &&
                        <ActiveJobsPanel
                            haveTabs={true}
                        />
                    }
                    {tab == "pending" &&
                        <PendingJobsPanel
                            haveTabs={true}
                        />
                    }
                    {tab == "reports" &&
                        <ListGroup>
                            {userHasPermission(Permissions.UL) &&
                                <ListGroup.Item>
                                    <Link to="/reports/ul">
                                        UL Report
                                    </Link>
                                </ListGroup.Item>
                            }
                        </ListGroup>
                    }
                    </>
                }
            </Content>
            { selected && selected.mode == "create" && 
                <OrderDrawer 
                    order={selected.order} 
                    show={true}
                    onSaved={(order) => {
                        setCounter(counter+1); // force refresh
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
        </PageLayout>
    );
}

export default JobsPage;