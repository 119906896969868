import React, { useState, useEffect } from "react";
import styled from "styled-components"
import Form from 'react-bootstrap/Form';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import produce from "immer"

import Loading from "../../components/loading"

import { postData } from "../../common/services/server"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";


function UserRolesPanel(props) {
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [busy, setBusy] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    useEffect(
        () => {
          setIsLoading(true);

          postData("users/roles", {
              user_id: props.user.id,
              permissions_ind: 1
            },
            function(result) {
                setRoles(result);
            },
            function(error) {
                alert("Error loading roles")
            },
            function() {
              setIsLoading(false);
            }
          );       
        }, 
        [props.user]
    );
    
    function onCheck(e) {
        const roleId = e.target.value;
        const checked = e.target.checked;

        const maxListOrder = roles.reduce(function(prev, current) {
            return (prev && prev.list_order > current.list_order) ? prev.list_order : current.list_order
        }) 

        console.log({maxListOrder});

        setRoles(produce(draft => {
          const index = draft.findIndex(r => r.id == roleId);
                  
          console.log(((maxListOrder||0)+1));

          draft[index].checked_ind = checked ? 1 : 0; 
          draft[index].list_order = checked ? ((maxListOrder||0)+1) : 0; 
        }));

        setBusy(produce(draft => {
            if (draft.indexOf(""+roleId) == -1)
                draft.push(""+roleId);
        }))

        postData("users/togglerole", {
            user_id: props.user.id,
            role_id: roleId,
            checked_ind: checked ? 1:0
          },
          function(result) {              
          },
          function(error) {
              alert("Error updating roles")
          },
          function() {
            setBusy(produce(draft => {
                const index = draft.indexOf(""+roleId);
                if (index != -1)
                    draft.splice(index, 1)
            }))    
          }
        );
    }

    function onPermissions(role) {
        setBusy(produce(draft => {
            if (draft.indexOf("perms"+role.id) == -1)
                draft.push("perms"+role.id);
        }))

        postData("roles/permissions", {
            role_id: role.id,
            filter_role_id: role.id,
        },
            function(result) {
                setRoles(produce(draft => {
                    const index = draft.findIndex(r => r.id == role.id);

                    if (index != -1) {
                        draft[index].permissions = result;
                    }
                }));
            },
            function(error) {
                alert("Error loading permissions")
            },
            function() {
                setBusy(produce(draft => {
                    const index = draft.indexOf("perms"+role.id);
                    if (index != -1)
                        draft.splice(index, 1)
                }))                    
            }
        );           
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
                <div>
                    {roles.map(role => (
                        <div key={role.id} className={role.checked_ind == 1 ? "checked":""}>
                            <div className="flex items-center justify-between border border-gray-300 p-1">
                                <div>
                                    <Form.Check 
                                        type="checkbox"
                                        value={role.id}
                                        checked={role.checked_ind == 1}
                                        label={role.name}
                                        onChange={onCheck}                    
                                    />

                                    {busy.indexOf(""+role.id) != -1 && 
                                        <FontAwesomeIcon icon={faSpinner} spin style={{marginLeft: "8px"}} />
                                    }
                                </div>
                                {!role.permissions && 
                                    <FontAwesomeIcon 
                                        icon={busy.indexOf("perms"+role.id) != -1 ? faSpinner:faPlus} 
                                        spin={busy.indexOf("perms"+role.id) != -1}
                                        onClick={() => onPermissions(role)} 
                                    />
                                }
                            </div>

                            <div className="grid gap-1">
                                {role.permissions && role.permissions.map(permission => (
                                    <div className="text-sm">
                                        - {permission.name}

                                        <div className="flex items-center gap-1">
                                            {permission.actions.filter(a => a.selected_ind == 1).map(action => (
                                                <div className="bg-gray-300 rounded p-1 status-waiting text-xs">
                                                    {action.name}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            }
        </>
    );
}

export default UserRolesPanel;