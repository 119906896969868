import React from 'react'
import clsx from 'clsx'

export default function ProgressBar({
    progress,
    className,
    progressClassName
}) {
    return (
        <div className={clsx(
            "h-full rounded-full bg-gray-200",
            className
        )}>
            <div 
                className={clsx(
                    "h-full rounded-full bg-gray-500",
                    progressClassName
                )} 
                style={{
                    width: progress + "%",
                }}></div>
        </div>
    )
}

// class Progress extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {}
//   }
//   render() {
//     return (
//       <ProgressBar>
//         <ProgressLine
//           error={this.props.error}
//           style={{ width: this.props.progress + '%' }}
//         />
//       </ProgressBar>
//     )
//   }
// }

// export default Progress