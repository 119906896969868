import React, { Component } from 'react'
import styled from 'styled-components'

const ProgressBar = styled.div`
    width: 100%;
    height: 8px;
    background-color: #eee;
    border-radius: 5px;
`
const ProgressLine = styled.div`
    background-color: ${props => props.error ? "red" : "rgba(103, 58, 183, 1)"};
    height: 100%;
    margin: 0;
    border-radius: 5px;
`

class Progress extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <ProgressBar>
        <ProgressLine
          error={this.props.error}
          style={{ width: this.props.progress + '%' }}
        />
      </ProgressBar>
    )
  }
}

export default Progress

/*
<div class="mb-5 h-2 rounded-full bg-gray-200">
  <div class="h-2 rounded-full bg-orange-500" style="width: 50%"></div>
</div>
*/