import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { addDays, addMonths, addBusinessDays } from "date-fns";
import produce from "immer"
import { faSave } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"
import TaskForm from "../components/forms/form-task"
import TaskIcon from "../components/tasks/task-icon"
import TextAreaField from "../components/fields/field-textarea"
import SelectField from "../components/fields/field-select"
import UserField from "../components/fields/field-user"
import DateField from "../components/fields/field-date"
import FieldEmployee from "../components/fields/field-employee";

import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { getCurrentUserId } from "../common/services/auth";
import { getIdInfoFromUrl } from "../helpers/utility";

function TaskDrawer(props) {
  let location = useLocation();

  const [loading, setLoading] = useState("");
  const [taskId, setTaskId] = useState(0);
  const [task, setTask] = useState(null);

  useEffect(
    () => {
      console.log("props.task", props.task)
      if (props.task) {
        if (props.task.id > 0)
          setTaskId(props.task.id);
        else {
          setTask({
            ...props.task            
          });

          if (props.task.user_id || props.task.user_email) {
            setLoading("user");

            postData("users/userdata", { id: props.task.user_id, email: props.task.user_email },
                function(result) {
                  if (result) {
                    setTask(produce(draft => {
                      draft.user_id = result.id;
                      draft.user_title = result.name;
                    }))
                  }
                },
                function(error) {
                    window.alert("Error loading user data.");
                },
                function() {
                    setLoading("");
                }
            );          
          }
        }
      }
      else {
        const info = getIdInfoFromUrl(location.pathname);
      
        const newTaskData = {
          assigned_user_id: getCurrentUserId()
        };
  
        if (info.id && info.type) {
          newTaskData[info.type + "_id"] = info.id;
          newTaskData[info.type + "_title"] = "#" + info.id;
        }
        
        console.log({newTaskData})

        setTask(newTaskData);
      }
    }, 
    [props.task]
  );

  useEffect(
    () => {
      if (taskId && taskId > 0) {
        setLoading("task");

        postData("tasks/taskdata", { id: taskId, ...associations },
            function(result) {
                setTask(result);
            },
            function(error) {
                window.alert("Error loading task.");
            },
            function() {
                setLoading("");
            }
        );
      }
    }, 
    [taskId]
  );

  function onCreateTask() {
    setLoading("creating")

    postData("tasks/save?id=0", task, 
      function(response) {
        if (props.onSaved)
          props.onSaved(response);
        else
          setTask(response);
      },
      function(error) {
        alert("An error occured saving the task.");
      },
      function() {
        setLoading("")
      }
    );    
  }

  console.log({task})
  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" backdrop="static" keyboard={false} scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <TaskIcon task={task} />
            &nbsp;
            <span style={{textTransform: "capitalize"}}>
              {(task && task.id > 0) ? task.type : "Task"}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loading == "task" ? 
                <Loading /> 
            : (task && task.id > 0) ? 
                <TaskForm 
                    task={task}
                    onBusy={(busy) => {
                        setLoading(busy ? "task":"")
                    }}
                    onTask={(task) => {
                      setTaskId(task.id);
                    }}
                    onTaskUpdated={(taskData) => {
                        setTask(taskData);
                        props.onSaved(taskData);
                    }}
                    onTaskDeleted={() => {                                    
                      props.onDeleted();
                    }}
                />
            : task ?
                <div>
                    <SelectField 
                        name="type" 
                        url="tasks/types"
                        value={task.type} 
                        idField="name"
                        labelField="title"
                        onChange={(e) => {
                          if (e.persist) e.persist();

                          setTask(produce(draft => {
                            draft.type = e.target.value;

                            if (e.target.value.indexOf("followup") != -1) {
                              draft.due_date = addBusinessDays(new Date(), 1);
                            }
                          }))                          
                        }} 
                    />           
                    {((task.type == "followupemail" || task.type == "followupcall") && loading != "user") &&
                      <>
                        {/* Don't prompt if associating with a project since we'll get the user from it  */}
                        {((!task.project_id || task.project_id==0) && (!task.quote_id || task.quote_id==0)) && 
                          <UserField 
                            name="user_id"
                            label="Customer" 
                            required
                            value={{id:task.user_id, name: task.user_title || ""}}
                            onChange={(e) => {
                              if (e.persist) e.persist();

                              setTask(produce(draft => {
                                draft.user_id = e.target.value ? e.target.value.id : null;
                                draft.user_title = e.target.value ? e.target.value.name : null;
                              }))
                            }} 
                          />
                        }
                        <DateField 
                          name="due_date" 
                          label="Followup Date" 
                          required 
                          value={task.due_date} 
                          quickValues={{
                            "Today": new Date(),
                            "Next Work Day": addBusinessDays(new Date(), 1),
                            "Next Week": addDays(new Date(), 7),
                            "Next Month": addMonths(new Date(), 1),
                          }}
                          onChange={(e) => {
                            if (e.persist) e.persist();

                            setTask(produce(draft => {
                              draft.due_date = e.target.value;
                            }))
                          }} 
                        />
                      </>
                    }       
                    <TextAreaField 
                        name="task" 
                        label="Description"
                        value={task.task} 
                        autofocus
                        rows={3}
                        onChange={(e) => {
                          if (e.persist) e.persist();

                          setTask(produce(draft => {
                            draft.task = e.target.value;
                          }))
                        }} 
                      />
                    
                    <FieldEmployee 
                      name="assigned_user_id" 
                      label="Assigned To"
                      value={task.assigned_user_id}
                      onChange={(e) => {
                        if (e.persist) e.persist();

                        setTask(produce(draft => {
                          draft.assigned_user_id = e.target.value;
                        }))
                      }} 

                    />

                    {(task.sign_id && task.sign_id > 0) &&
                        <Form.Check 
                            checked={1}
                            label={"Associate with Sign " + task.sign_title}
                            type="checkbox" 
                            onChange={(e) => {
                              setTask(produce(draft => {
                                draft.sign_id = null;
                              }))                              
                            }}
                        />                    
                    }
                    {(task.user_id && task.user_id > 0) &&
                        <Form.Check 
                            checked={1}
                            label={"Associate with User " + task.user_title}
                            type="checkbox" 
                            onChange={(e) => {
                              setTask(produce(draft => {
                                draft.user_id = null;
                              }))                              
                            }}
                        />                    
                    }                    
                    {((task.quote_id && task.quote_id > 0) || ((task.project_id && task.project_id > 0))) &&
                        <Form.Check 
                            checked={1}
                            label={"Associate with Project " + (task.quote_title || task.project_title)}
                            type="checkbox" 
                            onChange={(e) => {
                              setTask(produce(draft => {
                                draft.quote_id = null;
                              }))                              
                            }}
                        />                    
                    }  
                    {(task.order_id && task.order_id > 0) &&
                        <Form.Check 
                            checked={1}
                            label={"Associate with Order " + task.order_title}
                            type="checkbox" 
                            onChange={(e) => {
                              setTask(produce(draft => {
                                draft.order_id = null;
                              }))                              
                            }}
                        />                    
                    }  
                    {(task.job_id && task.job_id > 0) &&
                        <Form.Check 
                            checked={1}
                            label={"Associate with Job " + task.job_title}
                            type="checkbox" 
                            onChange={(e) => {
                              setTask(produce(draft => {
                                draft.job_id = null;
                              }))                              
                            }}
                        />                    
                    }  

                    <br />
                    <div> 
                        <ButtonProcessing 
                            processing={loading == "creating"}
                            onClick={onCreateTask} 
                            disabled={!task.task || task.task.length == 0}
                            variant="outline-success" 
                            caption={"Create " + (task.type == "list" ? "List":"Task" )}
                            icon={faSave} 
                        />                                                    
                    </div>
                </div>
            : null
            }
        </Modal.Body>
      </Modal>
    )
}

export default TaskDrawer;