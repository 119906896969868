import React from "react";
import { Button } from "react-bootstrap"

import PanelTable from "./panel-table"
import storage from "../settings/storage";

function Panel({
  parts,
  onChange
}) {

  return (
    <>
      <PanelTable>
        <thead>
            <tr>
              <th>Part</th>
              <th>Type</th>
          </tr>
        </thead>
        <tbody>
            {(parts || []).map(part => (
                <tr key={part.id}>
                    <td>
                      <div style={{display: "grid", gridTemplateColumns: "40px 1fr", gap: "10px"}}>
                        <img 
                            src={storage.root + "2020/parts/" + part.name + "/icon.jpg?" + (new Date().getTime())} 
                            height="30"
                            onError={(e) => e.target.src = storage.root + "images/nothumbnail.jpg"}
                            style={{ maxHeight: "20px", maxWidth: "40px" }} 
                        />

                        <div>
                            {part.part_number && 
                                <>
                                    {part.part_number}
                                    &nbsp;-&nbsp;
                                </>
                            }
                            {part.title}
                        </div>
                      </div>
                    </td>
                    <td>
                      {part.type}
                    </td>
                </tr>
            ))}
        </tbody>
      </PanelTable>   

      <div style={{marginTop: "10px"}}>
        <Button
            variant="outline-secondary"
            size="sm"
            onClick={onChange}
        >
            Manage Parts
        </Button>               
      </div>

    </>
  );
}

export default Panel;