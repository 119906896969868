import React from "react";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components"
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faListAlt } from "@fortawesome/free-solid-svg-icons";

import TaskIcon from "./task-icon"

const MyLink = styled(Link)`
    color: black;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
`
const Icons = styled.span`
    svg {
        margin-left: 3px;
        color: #666;
    }
`

function TaskLink({task, onClick}) {
    const history = useHistory();

    return (
        <MyLink to={"/tasks/task/" + (task.id || task.url)} onClick={onClick} className="task">
            <TaskIcon task={task} />{' '}
            {task.task || task.title}

            <Icons>
                {task.list_url && 
                    <OverlayTrigger
                        overlay={
                            <Tooltip>List: {task.list_title}</Tooltip>
                        }
                    >                
                        <FontAwesomeIcon icon={faListAlt} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            history.push(task.list_url);
                        }} className="link" />
                    </OverlayTrigger> 
                }
                {task.project_url && 
                    <OverlayTrigger
                        overlay={
                            <Tooltip>Project: {task.project_title}</Tooltip>
                        }
                    >                
                        <FontAwesomeIcon icon={faArchive} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            history.push(task.project_url);
                        }} className="link" />
                    </OverlayTrigger> 
                }
            </Icons>
        </MyLink>    
    );
}

export default TaskLink;