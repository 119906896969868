import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Card from  'react-bootstrap/Card';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faShapes, faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import NumberField from "../components/fields/field-number"
import SelectField from "../components/fields/field-select"
import BooleanField from "../components/fields/field-boolean"
import ListField from "../components/fields/field-list"
import ShapePreview from "../components/shape-preview"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls, restrictLetters } from '../helpers/utility'
import { ConsoleView } from "react-device-detect";
import Loading from "../components/loading";

const ShapeRow = styled.div`
  display: flex;
  align-items: end;
`

function SignGroupDrawer(props) {
  const formRef = useRef(null);

  const [products, setProducts] = useState([])
  const [shapes, setShapes] = useState([])
  const [partTypes, setPartTypes] = useState([])
  const [parts, setParts] = useState([])

  const [isProcessing, setIsProcessing] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const [formData, setFormData] = useState(props.group);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    postData("parts/types/data", {}, 
      function(results) {
        setPartTypes(results.filter(pt => pt.name != 'auto'))
      },
      function(error) {
        alert("An error occured loading the part types.");
      }
    );
  }, []);

  useEffect(() => {
    if (formData.product_id) {
      postData("parts/data", {product_id: formData.product_id}, 
        function(results) {
          setParts(results)
        },
        function(error) {
          alert("An error occured loading the parts.");
        }
      );
    }
  }, [formData.product_id]);

  useEffect(() => {
    const group = props.group;
    let partIds = [];
    let letters = ""

    if (group && group.parts) {
      for (const part of group.parts) {
        partIds.push(part.id);
      }
    }
    if (group && group.shapes && group.product.builder_type == "letters") {
      for (const shape of group.shapes) {
        letters += shape.code;
      }
    }

    console.log("setting form data", group.shapes)
    setFormData({
        ...group, 
        shape_id: group.shapes && group.shapes.length ? group.shapes[0].id : 0,
        partIds,
        letters
    });
  }, [props.group]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      if (formData.size && formData.size > 0) {
        setIsProcessing(true);

        postData("signs/savegroup", removeNulls(formData), 
          function(response) {
            props.onSaved(response);
          },
          function(error) {
            alert("An error occured saving the group.");
          },
          function() {
            setIsProcessing(false);
          }
        );
      }
      else alert('Invalid size')
    }
  }

  let product = null;
  let shape = null;

  if (formData && formData.product_id && products.length) {
    product = products.find(p => p.id == formData.product_id);
  }
  if (formData && formData.shape_id && shapes.length) {
    shape = shapes.find(s => s.id == formData.shape_id);
  }

  function autoSetSizedBasedGroupParts(size) {
    console.log("autoSetSizedBasedGroupParts", size);

    let changed = false;
    let partIds = JSON.parse(JSON.stringify(formData.partIds));

    // remove invalid parts based on their size
    for (let i=partIds.length-1; i>=0; i--) {
      let partId = partIds[i]
      let part = product.parts.find(p => p.id == partId);

      if (part) {
        let remove = false;

        if (part.conditional_size_min && size < part.conditional_size_min)
            remove = true;
        if (part.conditional_size_max && size > part.conditional_size_max)
            remove = true;

        if (remove) {
            console.log("* removing part", part.name)
            partIds.splice(i, 1);
            changed = true;
        }
      }
    }
    // add parts based on their size
    for (const part of product.parts) {
      // is it sized based?
      if (part.conditional_size_min || part.conditional_size_max) {
          let add = false;

          if (size >= (part.conditional_size_min || 0) && size <= (part.conditional_size_max || 99)) {                    
              add = true;
          }

          // make sure it doesn't already exist
          if (add && partIds.find(p => p == part.id)) {
              add = false;
          }

          if (add) {
              console.log("* adding part", part.name)
              partIds.push(part.id);
              changed = true;
          }
      }
    }      

    if (changed) {
      setFormData(produce(draft => {
        draft.partIds = partIds;
      }));
    }    
  }  
  function updateDimensions(values = {}) {
    const data = removeNulls({...formData, ...values});

    if (data.custom_size_height || data.custom_size_width) {
      let changed = false;

      if (data.custom_size_height && product.material_min && data.custom_size_height < product.material_min) {
        data.custom_size_height = product.material_min;
        changed = true;
      }
      if (data.custom_size_width && product.material_min && data.custom_size_width < product.material_min) {
        data.custom_size_width = product.material_min;
        changed = true;
      }
      
      console.log("updateDimensions")
      console.log("custom_size_height", data.custom_size_height);
      console.log("custom_size_width", data.custom_size_width);

      console.log("product.material_min", product.material_min);
      console.log("product.material_max_1", product.material_max_1);
      console.log("product.material_max_2", product.material_max_2);

      if (data.custom_size_height && product.material_max_1 && data.custom_size_height > product.material_max_1) {
        data.custom_size_height = product.material_max_1;
        changed = true;
      }
      if (data.custom_size_width && product.material_max_1 && data.custom_size_width > product.material_max_1 && product.builder_type != "letters") {
        data.custom_size_width = product.material_max_1;
        changed = true;
      }  

      if (data.custom_size_height && data.custom_size_width && product.material_max_1 && product.material_max_2 && product.builder_type != "letters") {
        if (data.custom_size_height > product.material_max_2 && data.custom_size_width > product.material_max_2) {
          data.custom_size_width = product.material_max_2;
          changed = true;
        }
      }

      if (changed) {
        setFormData(produce(draft => {
          draft.custom_size_height = data.custom_size_height;
          draft.custom_size_width = data.custom_size_width;
        }))
      }
    }

    if (formData.resembles_ind == 1 && product && product.builder_type == "letters")
      return;

    // size is manual for custom letters
    setCalculating(true);

    postData("groups/dimensions", data,
        function(result) {
          setFormData(produce(draft => {
            if (draft.resembles_ind == 1) {
              console.log("custom size", result.size, draft.size)
              draft.size = result.size;
            } else {
              draft.size_width = result.width || 0;
              draft.size_height = result.height || 0;
            }
          }));

          if (result.size)
            autoSetSizedBasedGroupParts(result.size);
        },
        function(error) {
            console.log(error);
            alert("Error calculating dimensions.")
        },
        function() {
            setCalculating(false);
        }
    );
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();

    const field = event.target.name;
    const value = event.target.value;

    console.log(event.target)
    console.log("handleFormChange", field, value);

    //console.log("handleFormChange", event.target.name, event.target.value)
    setFormData(produce(draft => {
      draft[field] = value;

      if (field == "product_id") {
        const product = products.find(p => p.id == value);

        //if (product.default_style_id != null)
        //  draft.style_id = product.default_style_id;
          
        draft.partIds = [];

        for (const part of product.parts) {
          let add = part.required_ind == 1 || part.default_ind == 1;

          // handle sized based parts
          if (!add && (part.conditional_size_min || part.conditional_size_max)) {
            if (draft.size >= (part.conditional_size_min || 0) && draft.size <= (part.conditional_size_max || 99)) {                    
              add = true;
            }
          }

          if (add)
            draft.partIds.push(part.id);
        }
      }
    }));

    if (field == "product_id" || field == "style_id" || field == "shape_id" || field == "resembles_ind") {
      let params = {}; params[field] = value;
    
      updateDimensions(params);
    }
    else if (field == "size" && formData.partIds) {
      autoSetSizedBasedGroupParts(value)
    }
  }

  function onPartChange(e) {
    const partId = parseInt(e.target.value);
    const checked = e.target.checked;

    //console.log("onPartChange", partId, checked)
    //console.log(formData.partIds);

    setFormData(produce(draft => {
        const index = draft.partIds ? draft.partIds.indexOf(partId) : -1;
        
        if (index != -1)
            draft.partIds.splice(index, 1);
        if (!draft.partIds)
            draft.partIds = [];
        if (checked)
            draft.partIds.push(partId);
    }));
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faShapes} />
            &nbsp;
            Sign Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
            <ListField 
                name="resembles_ind" 
                label="Custom" 
                value={formData.resembles_ind} 
                list={[
                    {value: "0", label: "No"},
                    {value: "1", label: "Yes"},
                ]}
                onChange={(e) => handleFormChange(e)} 
            />

            <SelectField 
                name="product_id" 
                label="Product"                 
                labelField="title"
                required
                value={formData.product_id} 
                url={"products/data?bundle_ind=0&sign_ind=1&complete_ind=1"}
                onData={(data) => setProducts(data)}
                onChange={(e) => handleFormChange(e)} 
            />

            {product ? 
              <>
                {formData.product_id && 
                  <SelectField 
                      name="style_id" 
                      label="Style"           
                      idField="id"      
                      labelField="name"
                      required
                      value={formData.style_id} 
                      url={"styles/data?product_id=" + formData.product_id}
                      onChange={(e) => handleFormChange(e)} />
                }
                {(product && product.builder_type != "letters" && formData.resembles_ind == 0 && formData.style_id != null) && 
                  <ShapeRow>
                    <div style={{flex: 1}}>
                      <SelectField 
                          name="shape_id" 
                          label="Shape"                 
                          labelField="name"
                          required
                          value={formData.shape_id} 
                          url={"shapes/data?style_id="+formData.style_id+"&user_ind=0&category_ind=1"}
                          onData={(data) => {
                            setShapes(data)
                            console.log("shapes loaded", data, formData.shape_id)
                          }}
                          onChange={(e) => handleFormChange(e)} />
                    </div>
                    <div style={{padding: "5px", marginLeft: "5px", border:"1px solid #ccc", borderRadius:"4px"}}>
                      <ShapePreview 
                          width={80}
                          height={55}
                          path={shape ? shape.path : ""}
                      />
                    </div>
                  </ShapeRow>
                }                
                {(product && product.builder_type != "letters" && formData.resembles_ind == 1) && 
                    <SelectField 
                        name="shape_id" 
                        label="Shape Type"                 
                        labelField="name_withmod"
                        required
                        value={formData.shape_id} 
                        url={"shapes/data?style_id="+formData.style_id+"&custom_ind=1&user_ind=0&category_ind=0"}
                        onChange={(e) => handleFormChange(e)} />
                }

                {(product && product.builder_type == "letters") && 
                  <TextField 
                      name="letters" 
                      label="Letters" 
                      required
                      value={formData.letters} 
                      onKeyPress={restrictLetters}
                      onBlur={(e) => updateDimensions()}
                      onChange={(e) => handleFormChange(e)} />
                }
                {(product && product.builder_type == "shapes") &&
                  <ListField 
                      name="multipart_group" 
                      label="Multiple Piece Logo" 
                      value={formData.multipart_group} 
                      list={[
                          {value: "1", label: "MPL 1"},
                          {value: "2", label: "MPL 2"},
                          {value: "3", label: "MPL 3"},
                          {value: "4", label: "MPL 4"},
                          {value: "5", label: "MPL 5"},
                          {value: "6", label: "MPL 6"},
                          {value: "7", label: "MPL 7"},
                          {value: "8", label: "MPL 8"},
                          {value: "9", label: "MPL 9"},
                          {value: "10", label: "MPL 10"},
                      ]}
                      onChange={(e) => handleFormChange(e)} 
                  />
                }

                {(product && product.material_min) &&
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    color: "red",
                    marginBottom: "5px"
                  }}> 
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    Product has a material minimum size of {product.material_min}"
                  </div>
                }
                {(product && product.material_max_1) &&
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    color: "red",
                    marginBottom: "5px"
                  }}> 
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    Product has a material maximum size of {product.material_max_1}"
                    {product.material_max_2 && 
                      '/' + product.material_max_2 + '"'
                    }
                  </div>
                }

                {formData.resembles_ind == 1 && 
                    <Form.Row style={{maxWidth: "475px"}}>
                        <NumberField 
                          column={true}
                          name="custom_size_height" 
                          label="Custom Height" 
                          value={formData.custom_size_height} 
                          onBlur={(e) => updateDimensions()}
                          onChange={(e) => handleFormChange(e)} />
                        <NumberField 
                          column={true}
                          name="custom_size_width" 
                          label="Custom Width" 
                          value={formData.custom_size_width} 
                          onBlur={(e) => updateDimensions()}
                          onChange={(e) => handleFormChange(e)} />
                    </Form.Row>
                }

                <NumberField 
                    name="size" 
                    label={formData.resembles_ind == 1 && product.builder_type == "letters" ? "Height of UPPERCASE letters in this Style":"Size"} 
                    value={formData.size} 
                    readonly={formData.resembles_ind == 1 && formData.resembles_ind == 0} 
                    disabled={formData.resembles_ind == 1 && formData.resembles_ind == 0}
                    onBlur={(e) => updateDimensions()}
                    onChange={(e) => handleFormChange(e)} 
                    instructions={product && product.min_size ? "Product has size constraint of " + product.min_size + " to " + product.max_size : ""}
                    instructionsStyle={{color: product && product.min_size && (!formData.size || formData.size<product.min_size || formData.size>product.max_size) ? "red":""}}
                />

                {formData.resembles_ind != 1 && 
                    <Form.Row style={{maxWidth: "475px"}}>
                        <NumberField 
                          column={true}
                          name="size_height" 
                          label={calculating ? <>Height <FontAwesomeIcon icon={faSpinner} spin /></> : "Height"}
                          value={formData.size_height}
                          readonly 
                          disabled
                          onChange={(e) => handleFormChange(e)} />
                        <NumberField 
                          column={true}
                          name="size_width" 
                          label={calculating ? <>Width <FontAwesomeIcon icon={faSpinner} spin /></> : "Width"}
                          value={formData.size_width} 
                          readonly
                          disabled
                          onChange={(e) => handleFormChange(e)} />
                    </Form.Row>
                }

                <p>Parts</p>

                <Card>
                {partTypes.map(type => (
                    <div key={type.id}>
                        <Card.Header>
                            {type.title}
                        </Card.Header>
                        <div>
                          <div style={{padding: "10px"}}>
                            {product && product.parts.filter(p => p.type_id == type.id).map(part => {
                                let msg = "";
                                let title = part.title;
                                let productPart;

                                //if (product) {
                                //  productPart = product.parts.find(p => p.id == part.id);

                                //  if (productPart && productPart.required_ind == 1)
                                if (part.required_ind == 1)
                                    msg = "* Required For Product"
                                //}

                                const checked = formData.partIds && formData.partIds.indexOf(part.id) != -1;
                                
                                if (part.conditional_size_min || part.conditional_size_max)
                                  title = title + " (sizes " + (part.conditional_size_min||0) + " to " + (part.conditional_size_max||99) + ")";

                                return (
                                  <div key={part.id}>
                                      <Form.Check 
                                          name="type"                                
                                          value={part.id}
                                          checked={checked}
                                          disabled={part.required_ind == 1 && checked}
                                          inline 
                                          label={title} 
                                          type="checkbox" 
                                          onChange={onPartChange}
                                      />
                                      <div style={{float:"right", color: "red"}}>
                                        {msg}
                                      </div>
                                  </div>
                                )
                            })}
                          </div>
                        </div>
                    </div>
                ))}
                </Card>
              </>
              : formData.product_id &&
              <Loading />
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                disabled={!product}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Group" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default SignGroupDrawer;