import React, { useState, useEffect } from "react";
import produce from "immer"

import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    ButtonCell,
    EditActionsCell
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Loading from "../../components/loading"
import RoleDrawer from "../../drawers/drawer-role"
import RoleEmployeesDrawer from "../../drawers/drawer-roleemployees"
import RolePermissionsDrawer from "../../drawers/drawer-rolepermissions"
import RoleWorkflowTasksDrawer from "../../drawers/drawer-roleworkflowtasks";

import { postData } from "../../common/services/server"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { getUser, Permissions, Actions } from "../../common/services/auth";

function SkillsPanel(props) {
    const user = getUser();
    const windowDimensions = useWindowDimensions();

    const [skills, setSkills] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    const [busyRow, setBusyRow] = useState(null);

    useBodyClass(`noscroll`);

    useEffect(
        () => {
            setIsLoading(true);

            postData("roles/data", {
                skills_ind: 1
            },
                function(result) {
                    setSkills(result.roles);
                },
                function(error) {
                    alert("Error loading skills")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        []
    );

    function onAction(action, role) {
        console.log("action", action, role)
        if (action == "edit")
            setSelected({mode:action, role});
        else if (action == "delete") {
            if (window.confirm("Delete this skill?")) {
                setBusyRow(role);
    
                postData("roles/delete", { 
                    role_id: role.id
                },
                    function(result) {
                        setRoles(produce(draft => {
                            draft.splice(draft.findIndex(r => r.id == role.id), 1);
                        }));
                    },
                    function(error) {
                        alert("Error deleting skill")
                    },
                    function() {
                        setBusyRow(null);
                    }
                );
            }            
        }        
    }
    
    function onTasks(role) {
        setSelected({mode:"tasks", role});
    }
    function onEmployees(role) {
        setSelected({mode:"employees", role});
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
                <Table
                    rowHeight={50}
                    rowsCount={skills.length}
                    width={windowDimensions.width - dimensions.dataTableWidthOffset}
                    height={windowDimensions.height - dimensions.headerHeight - (props.haveTabs ? dimensions.pageWithTabsPadding : dimensions.pagePadding)*2}
                    headerHeight={35}
                    touchScrollEnabled={true}
                >
                    <Column
                        header={<Cell>Skill Name</Cell>}
                        columnKey="name"
                        fixed={!isMobile}
                        cell={<TextCell data={skills} />}
                        width={240}
                    />
                    <Column
                        header={<Cell>Description</Cell>}
                        columnKey="description"
                        cell={<TextCell data={skills} small />}
                        width={300}
                    />       
                    <Column
                        header={<Cell>Workflow Tasks</Cell>}
                        columnKey="workflowtask_count"
                        cell={
                            <ButtonCell 
                                data={skills} 
                                onClick={(skill) => onTasks(skill)} 
                            />
                        }
                        align="center"
                        width={200}
                    />                                 
                    <Column
                        header={<Cell>Employees</Cell>}
                        columnKey="employee_count"
                        cell={<ButtonCell data={skills} onClick={(skill) => onEmployees(skill)} />}
                        align="center"
                        width={100}
                    />
                    
                    <Column
                        header={<Cell>Actions</Cell>}
                        cell={
                            <EditActionsCell 
                                data={skills} 
                                actions={[
                                    {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, permission:Permissions.Roles, permissionAction:Actions.Edit},
                                    {action:"delete", title:"Delete", variant:"danger", icon:faTrash, isVisible: (skill) => skill.name != "Admin", permission:Permissions.Roles, permissionAction:Actions.Delete },
                                ]}
                                busy={busyRow}
                                onClick={onAction} 
                            />
                        }
                        align="center"
                        width={150}
                    />
                </Table>
            }

            {(selected && selected.mode=="edit") && 
                <RoleDrawer 
                    role={selected.role} 
                    skill={true}
                    onSaved={(roleData) => {
                        setSkills(produce(draft => {
                            const index = draft.findIndex(r => r.id == selected.role.id);                            
                            const role = draft[index];

                            draft[index] = Object.assign(role, roleData);
                        }));         
                        setSelected(null);
                    }} 
                    onHide={ () => setSelected(null) } 
                />
            } 

            {(selected && selected.mode=="tasks") && 
                <RoleWorkflowTasksDrawer 
                    role={selected.role} 
                    onHide={ () => setSelected(null) } 
                />
            }             
            {(selected && selected.mode=="employees") && 
                <RoleEmployeesDrawer 
                    role={selected.role} 
                    onChanged={(count) => {
                        setSkills(produce(draft => {
                            const index = draft.findIndex(r => r.id == selected.role.id);
                
                            draft[index].employee_count = count;
                        }));                        
                    }} 
                    onHide={ () => setSelected(null) } 
                />
            } 
            {(selected && selected.mode=="permissions") && 
                <RolePermissionsDrawer 
                    role={selected.role} 
                    onChanged={(count) => {
                        setSkills(produce(draft => {
                            const index = draft.findIndex(r => r.id == selected.role.id);
                
                            draft[index].permission_count = count;
                        }));                        
                    }}                     
                    onHide={ () => setSelected(null) } 
                />
            }             
        </>
    );
}

export default SkillsPanel;