import React from "react";
import styled from "styled-components"
import {Cell} from 'fixed-data-table-2';
import { faPencilAlt, faUserEdit, faBars, faEnvelope, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from 'react-tooltip'

import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

import Button from "../buttons/button";
import ActionButton from "../buttons/button-action"
import Badge from "../badge"

import { formatPrice, formatDate, formatDateTime, formatTime } from "../../helpers/utility"
import { isPast, parseISO, differenceInDays } from 'date-fns'
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";
import ZoomableImage from "../image-zoomable";
import storage from "../../settings/storage";

const PropsCell = styled(Cell)`
    font-size: 12px;
    display: flex;
    
    div.prop {
      display: flex;
    }

    label {
        margin: 0px 3px 1px 0px;
        max-width: 110px;
        min-width: 50px;
        padding: 2px 4px;
        background-color: #eee;
        border-radius: 3px;
    }
`

const SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var {columnKey, sort, onSortChange, children, ...props} = this.props;
    return (
      <Cell {...props}>
        <a onClick={this._onSortChange} style={{cursor: "pointer"}}>
          {children} {columnKey==sort.column ? (sort.dir === SortTypes.DESC ? '↓' : '↑') : ''}
        </a>
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sort ?
          reverseSortDirection(this.props.sort.dir) :
          SortTypes.DESC
      );
    }
  }
}

class FilterHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onFilterChange = this._onFilterChange.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var {columnKey, filter, onFilterChange, onFilter, sort, onSortChange, children, ...props} = this.props;
    return (
      <Cell {...props}>
        {onSortChange ? 
            <a onClick={this._onSortChange} style={{cursor: "pointer"}}>
              {children} {columnKey==sort.column ? (sort.dir === SortTypes.DESC ? '↓' : '↑') : ''}
            </a>
          :  
            children
        }

        <>
            {props.options ? 
              <FormControl
                  as="select"
                  value={filter}
                  onChange={(event) => {
                    onFilterChange(event.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onFilter(e);
                    }
                  }}                
              >
                {props.options && <option value=""></option>}
                {props.options && props.options.map((item,index) => (
                      <option 
                        key={index} 
                        value={item[props.idField || "id"]}
                      >
                        {item[props.labelField || "title"]}
                      </option>
                ))}
              </FormControl>
            :
              <InputGroup size="sm">            
                <FormControl
                    as="input"
                    value={filter}
                    onChange={(event) => {
                      onFilterChange(event.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onFilter(e);
                      }
                    }}                
                />
                <InputGroup.Append>
                    <Button onClick={onFilter} variant="outline-secondary" size="icon">
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </InputGroup.Append>
              </InputGroup>
            }
            </>
      </Cell>
    );
  }

  _onFilterChange(e) {
    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sort ?
          reverseSortDirection(this.props.sort.dir) :
          SortTypes.DESC
      );
    }
  }
  _onSortChange(e) {
    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sort ?
          reverseSortDirection(this.props.sort.dir) :
          SortTypes.DESC
      );
    }
  }
}

class SelectCell extends React.Component {
  render() {
    const {data, rowIndex, columnKey, items, onChange, disabled, ...props} = this.props;
    let value = data[rowIndex][columnKey];


    return (
      <Cell {...props}>
        <FormControl
            as="select"
            value={value}
            disabled={disabled}
            size="sm"
            onChange={(event) => {
              onChange(data[rowIndex], event.target.value);
            }}
        >
          {this.props.items && <option value=""></option>}
          {this.props.items && this.props.items.map(item => (
                <option key={item.id} value={item.id}>{item[props.labelField || "title"]}</option>
          ))}
        </FormControl>
      </Cell>
    );
  }
}

class CollapseCell extends React.PureComponent {
  render() {
    const {data, rowIndex, columnKey, collapsedRows, callback, ...props} = this.props;
    let value = columnKey ? data[rowIndex][columnKey] : 1;

    return (
      <Cell {...props}>
        {value > 0 &&
          <a onClick={() => callback(rowIndex)}>
            {collapsedRows.has(rowIndex) ? '\u25BC' : '\u25BA'}
          </a>
        }
      </Cell>
    );
  }
}

class TextCell extends React.PureComponent {
    render() {
      const {data, rowIndex, columnKey, small, tiny, list, ...props} = this.props;
      let value = data[rowIndex][columnKey];

      if (list && value) {
        value = value.split(",").map(x => "&bull; " + x).join("<br />");
      }

      return (
        <Cell {...props}>
          {small ? 
            <small dangerouslySetInnerHTML={{__html:value}} />
          : tiny ?
            <span style={{fontSize: "10px"}} dangerouslySetInnerHTML={{__html:value}} />
          :
            value
          }
        </Cell>
      );
    }
}

class BooleanCell extends React.PureComponent {
    render() {
      const {data, rowIndex, columnKey, ...props} = this.props;
      const value = data[rowIndex][columnKey];

      return (
        <Cell {...props}>
          <Badge size="sm" variant={value == 1 ? "success" : value == 0 ? "danger" : "secondary"}>
            {value == 1 ? "Yes" : value == 0 ? "No" : "?"}
          </Badge>
        </Cell>
      );
    }
}

class GoogleImageCell extends React.PureComponent {
  render() {
    const {data, rowIndex, columnKey, zoomColumnKey, ...props} = this.props;
    const url = data[rowIndex][columnKey];
    const zoomUrl = zoomColumnKey && data[rowIndex][zoomColumnKey] ;

    return (
      <Cell {...props}>
        {url && 
          <ZoomableImage 
            url={storage.root + url}
            zoomUrl={storage.root + (zoomUrl || url)}
            maxHeight={props.maxheight + "px"}
            maxWidth={props.maxwidth + "px"}
          />
        }
      </Cell>
    );
  }
}

class ButtonCell extends React.PureComponent {
  render() {
    const {data, rowIndex, columnKey, onClick, isDisabled, isHidden, ...props} = this.props;
    const row = data[rowIndex];

    return (
      <Cell {...props} className="center">
        <div align="center">
          <Button 
            disabled={isDisabled ? isDisabled(row) : false}
            style={{
              display: isHidden && isHidden(row) ? "none":"initial",
            }}
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              onClick(row)
            }}
          >
            {row[columnKey]}
          </Button>
        </div>
      </Cell>
    );
  }
}

class LinkCell extends React.PureComponent {
  render() {
    const {data, rowIndex, columnKey, tooltipColumnKey, small, icon, iconFormatter, onClick, ...props} = this.props;
    const linkStyles = {
      border: "0px",
      color: "blue",
      backgroundColor: "transparent",
      padding: "0px",
      textAlign: "left",
      wordBreak: "break-all"
    }

    return (
      <Cell {...props} className="center">
          <a href={props.urlPrefix ? props.urlPrefix + data[rowIndex][props.urlColumn || "id"] : "#"} 
            style={linkStyles} 
            title={tooltipColumnKey ? data[rowIndex][tooltipColumnKey] : null} 
            onClick={(e) => {
              e.preventDefault();
              onClick(data[rowIndex])
            }}
          >
            {icon && 
              <FontAwesomeIcon icon={icon} fixedWidth />
            }
            {iconFormatter && iconFormatter(data[rowIndex])}
            {small ? 
              <small>{data[rowIndex][columnKey]}</small>
            :
              data[rowIndex][columnKey]
            }
          </a>
      </Cell>
    );
  }
}

class SimpleFormCell extends React.PureComponent {
  render() {
    const {data, rowIndex, columnKey, placeholder, disabled, onChange, onApply, busy, ...props} = this.props;

    return (
      <Cell {...props} className="center">
        <InputGroup className="mb-2" size="sm">
            <FormControl
                placeholder={placeholder}
                defaultValue={data[rowIndex][columnKey]}
                disabled={disabled}
                onChange={(event) => {
                    onChange(rowIndex, event.target.value);
                }}
            />
            <InputGroup.Append>
                <Button onClick={() => onApply(rowIndex)} disabled={disabled} variant="outline-secondary">
                    <FontAwesomeIcon icon={busy ? faSpinner : faCheck} spin={busy} />
                </Button>
            </InputGroup.Append>
        </InputGroup>
      </Cell>
    );
  }
}

class UserCell extends React.PureComponent {
  render() {
    const {userId, userName, userEmail, onUser, onChange, ...props} = this.props;

    let name = userName;

    if (userId && userEmail && userEmail.indexOf(userName) == 0)
      name = "";

    return (
      <Cell {...props} style={{width:"100%"}}>
          {onChange && 
            <Button onClick={onChange} variant="outline-secondary" size="sm" style={{marginLeft: "5px", float: "right"}}>
                <FontAwesomeIcon icon={faUserEdit} />
            </Button>
          }
          <div style={{lineHeight: "1em"}}>
            {userId && 
              <a href={"#/users/user/" + userId} onClick={(e) => { onUser(); if (e) e.preventDefault(); }}>
                <div style={{color:"black"}}>{name}</div>
                <div style={{wordBreak: "break-all"}}>
                  <small>{userEmail}</small>
                </div>
              </a>
            }
          </div>
      </Cell>
    );
  }
}

class EmailCell extends React.PureComponent {
  render() {
    const {data, rowIndex, columnKey, onEmail, ...props} = this.props;
    const user = data[rowIndex];
    const email = user[columnKey];

    return (
      <Cell {...props}>
        <div style={{
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}>
          { (email && email.length) && 
            <Button onClick={onEmail} variant="outline-secondary" size="sm" style={{marginRight: "5px"}}>
                <FontAwesomeIcon icon={faEnvelope} />
            </Button>
          }
          <small>{ email }</small>
          {user.email_ind == 0 && 
            <Badge variant="danger" style={{
              marginLeft: "5px",
              fontSize: "60%",
              border: "1px solid #dc3545",
              backgroundColor: "white",
              color: "#dc3545"
            }}>
              Unsubscribed
            </Badge>
          }
        </div>
      </Cell>
    );
  }
}

class CurrencyCell extends React.PureComponent {
  render() {
    const {data, rowIndex, columnKey, na, list, colorize, ...props} = this.props;
    const value = data[rowIndex][columnKey] || 0;
    return (
      <Cell {...props}>
        <div align="right" style={{color: colorize ? value > 0 ? 'green' : 'red' : 'inherit'}}>
          {data[rowIndex][columnKey] ?
            formatPrice(value)
            :
            na ? 
              'N/A'
            :
              ''
          }
        </div>
      </Cell>
    );
  }
}

class NumberCell extends React.PureComponent {
  render() {
    const {data, rowIndex, columnKey, na, list, percentage, ...props} = this.props;
    const value = data[rowIndex][columnKey] || 0;
    return (
      <Cell {...props}>
        <div align="right">
          {Math.round(data[rowIndex][columnKey])}%
        </div>
      </Cell>
    );
  }
}

class DateCell extends React.PureComponent {
  render() {
    const {data, rowIndex, columnKey, timeColumnKey, late, time, ...props} = this.props;
    const date = data[rowIndex][columnKey];

    return (
      <Cell {...props}>
        {time ? formatDateTime(date) : formatDate(date)}
        {(late && date && differenceInDays(parseISO(date), new Date()) < 0) && 
          <>
            {' '}
            <span style={{backgroundColor:"red", padding:"2px 4px", color:"white", borderRadius:"3px"}}>
              LATE
            </span>
          </>
        }
        {(timeColumnKey && data[rowIndex][timeColumnKey]) &&
          <div><small>{data[rowIndex][timeColumnKey]}</small></div>
        }
      </Cell>
    );
  }
}

class DateTimeCell extends React.PureComponent {
  render() {
    const {data, rowIndex, columnKey, timeColumnKey, late, local, ...props} = this.props;
    const date = data[rowIndex][columnKey];

    return (
      <Cell {...props} style={{lineHeight: "1em"}}>
        {formatDate(date, "MM/dd/yyyy", {local})}<br />
        <small>{formatTime(date)}</small>
      </Cell>
    );
  }
}

class PropertiesCell extends React.PureComponent {
    render() {
      const {data, rowIndex, columnKey, ...props} = this.props;
      return (
        <PropsCell {...props}>
          {props.children}
        </PropsCell>
      );
    }
}

class EditActionCell extends React.PureComponent {
    render() {
      const {data, rowIndex, columnKey, onClick, permission, permissionAction, ...props} = this.props;

      return (
        <Cell {...props}>
            <ActionButton 
                action="edit"
                variant="warning"
                icon={faPencilAlt}
                disabled={permission && !userHasPermission(permission, permissionAction||Actions.View)}
                onClick={ () => onClick(data[rowIndex]) } 
                />
        </Cell>
      );
    }
}

class EditActionsCell extends React.PureComponent {
  render() {
    const {data, actions, busy, rowIndex, columnKey, onClick, permission, permissionAction, ...props} = this.props;
    return (
      <Cell {...props}>
        {actions && actions.map(action => {
          const visible = !action.isVisible || action.isVisible(data[rowIndex]);

          if (visible) {
            return (
              <span key={action.action} data-tip={action.title} data-place="left">
                <ActionButton 
                    action={action.action}
                    busy={busy == data[rowIndex]}
                    disabled={busy == data[rowIndex] || (permission && !userHasPermission(permission, permissionAction||Actions.View)) || (action.permission && !userHasPermission(action.permission, action.permissionAction||Actions.View))}
                    variant={action.variant}
                    active={action.isActive ? action.isActive(data[rowIndex]) : false}
                    reset="reset"
                    icon={action.icon}
                    caption={action.caption}
                    size="tiny"
                    className="!px-2"
                    onClick={ () => onClick(action.action, data[rowIndex]) } 
                />
              </span>
            )
          }
        })}
        <ReactTooltip />
      </Cell>
    );
  }
}

export { 
    TextCell, 
    BooleanCell, 
    CurrencyCell, 
    NumberCell,
    ButtonCell,
    LinkCell,
    DateCell, 
    DateTimeCell,
    UserCell,
    SortHeaderCell, 
    FilterHeaderCell,
    SelectCell,
    SimpleFormCell,
    SortTypes,
    GoogleImageCell, 
    PropertiesCell, 
    EditActionCell,
    EditActionsCell,
    CollapseCell,
    EmailCell
  }