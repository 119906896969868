import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faPlug, faPlus, faProjectDiagram } from '@fortawesome/free-solid-svg-icons'

import DrawerTemplateDataSelect from "./template-dataselect";
import GoogleImage from "../components/google-image";
import { useGetProducts } from "../api/queries/products";

export default function DrawerProductSelect({
  filters,
  ...props
}) { 
  const [productFilters, setProductFilters] = useState(filters);
  const getProducts = useGetProducts(productFilters);

  return (
    <DrawerTemplateDataSelect
        {...props}
        icon={faClipboardList}
        title={props.title || "Select Product"}
        query={getProducts}
        header={
          <>
            {(productFilters && productFilters.builder_type) ?
              <div className="mb-2">
                <label for="productShowAllCheckbox" className="flex items-center gap-1">
                  <input 
                    type="checkbox" 
                    onClick={() => {
                      setProductFilters({
                        ...productFilters,
                        builder_type: null
                      })
                    }}
                  />
                  Show All Products
                </label>
              </div>
            :
              null
            }
          </>
        }
        itemRender={(product) => (
            <div className="flex items-center gap-1">
                <GoogleImage
                  src={"products/" + product.name + "/icon.jpg?" + (new Date().getTime())} 
                  style={{ maxHeight: "40px" }} 
                />

                {product.title}
            </div>                
        )}
        itemExistsCheck={(item) => item.exists_ind == 1}
    />
  )
}