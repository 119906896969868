import React, { useState } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import DrawerTemplateDataSelect from "./template-dataselect";
import ZoomableImage from "../components/image-zoomable";
import FieldText from "../components/fields/field-text";
import { useGetSigns } from "../api/queries/signs";
import { getIconForType } from "../helpers/icons";
import { useDebounce } from "../hooks/useDebounce";
import { formatPrice } from "../helpers/utility";
import storage from "../settings/storage";
import Button from "../components/buttons/button";

export default function SelectSignDrawer({
    title,
    filters,
    ...props
  }) {
    const [search, setSearch] = useState("");
    const [debounceSearch, setDebounceSearch] = useState("");

    // const debouncedRequest = useDebounce(() => {
    //     setDebounceSearch(search);
    // });

    const getSigns = useGetSigns({...filters, search: debounceSearch}, {
        enabled: debounceSearch.length > 0
    });

    return (
      <DrawerTemplateDataSelect
          {...props}
          icon={getIconForType("sign")}
          title={title || "Select Sign"}
          query={getSigns}
          header={
            <div className="mb-2 sticky top-0">
                <FieldText
                    name="search"
                    value={search}
                    prepend="Sign Search"
                    instructions="Search by design #, user email or user name"
                    onChange={(e,value) => {
                        setSearch(value);
                        // debouncedRequest();
                    }}
                    onEnter={() => setDebounceSearch(search)}
                    append={
                        <Button
                            icon={faSearch}
                            size="icon"
                            variant="outline-secondary"
                            disabled={!search}
                            onClick={() => setDebounceSearch(search)}
                        />
                    }
                />
            </div>
          }
          itemRender={(sign) => (
              <div className="flex items-center gap-2 text-sm">
                  <ZoomableImage
                    url={storage.root + sign.preview_url}
                    containerClassName="w-[150px]"
                    maxHeight="60px"
                    maxWidth="150px"
                  />
                  <div>
                    <div>Design #{sign.id}</div>
                    <div className="text-green-500">{formatPrice(sign.estimate)}</div>
                    <div className="text-xs">{sign.size_height} x {sign.size_width}</div>
                  </div>
              </div>                
          )}
          itemExistsCheck={(item) => item.exists_ind == 1}
      />
    )
}