import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from "../components/loading";
import { postData } from "../common/services/server";
import BoardField from "../components/board/board-field";
import clsx from "clsx";
import DrawerTemplate from "./template";

function Field({
  field,
  data,
  mutation,

  onSave,
  onSaved,
  onSaveError,
  onSaveSettled,
}) {
  const options = {
    onSave,
    onSaved,
    onSaveError,
    onSaveSettled,
  }                  

  if (field.valueProp)
    options.value = data[field.valueProp];
  else if (field.valueFormatter && field.type != "children")
    options.value = field.valueFormatter(data);

  return (
    <div>
      <BoardField 
        {...field} 
        {...options} 
        saveMutation={mutation}
      >
        {field.type == "children" ? field.valueFormatter(data) : null}
      </BoardField>
    </div>
  )
}

export default function DrawerTemplateBoardForm({
    title,
    icon,
    
    data,
    query,

    fields,
    onSaved,
    onHide
  }) {
  
  const boardData = query ? query.data : data;

  return (
      <DrawerTemplate
        icon={icon}
        title={title}
        onHide={onHide}
      >
      {query?.isFetching ? 
        <Loading />
      : query?.error ?
        <Error message={query.error} />
      : boardData ?
        <div className="grid">
          {fields.filter(f => !f.hiddenCheck || !f.hiddenCheck(boardData)).map((field,index) => (
            field.type == "columns" ?
              <div 
                className={clsx(
                  "grid",
                  field.columns.length == 2 && "grid-cols-2",
                )}
              >
                {field.columns.map((colField,colIndex) => (
                  <Field 
                    key={colIndex} 
                    field={colField}
                    data={boardData}
                    mutation={colField.mutation}
                    onSaved={onSaved}
                  />                        
                ))}
              </div>
            :
              <Field 
                key={index} 
                field={field}
                data={boardData}
                mutation={field.mutation}
                onSaved={onSaved}
              />
          ))}
        </div>            
      :
        <div className="text-gray-500 p-2">
            No data found.
        </div>
      }
      </DrawerTemplate>
    )
}
