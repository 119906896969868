import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faSquare, faListAlt, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { isMobile } from "react-device-detect"

import { colors } from "../../settings/settings";
import storage from "../../settings/storage";
import StationEmployees from "../../components/station-employees";


function EmployeeStationPanel({
    workers,
    tasks,
    onTask
}) {
    const [selectedWorker, setSelectedWorker] = useState(null);
    const [workerTasks, setWokerTasks] = useState([]);

    useEffect(
        () => {
            if (selectedWorker && tasks)
                setWokerTasks(tasks.filter(t => t.assigned_user_id == selectedWorker.id))
            else 
                setWokerTasks([]);
        }, 
        [selectedWorker, tasks]
    );

    return (
        <section>
            <StationEmployees
                workers={workers}
                selected={selectedWorker}
                onWorker={(worker) => {
                    setSelectedWorker(worker);
                }}
            />

            {selectedWorker && 
                <>
                {(workerTasks.length > 0) ? 
                    <table>
                        <thead>
                            <tr>
                                <th>Job</th>
                                <th>Task</th>
                            </tr>
                        </thead>
                        <tbody>                    
                        {workerTasks.map(task => (
                            <tr key={task.id} onClick={() => onTask(task)} className={task.status_name + (task.priority ? " priority":"")}>
                                <td>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                <img src={storage.root + task.job_preview_url} style={{maxHeight: "50px", maxWidth: "150px"}} />
                                                <div>{task.status}</div>
                                            </Tooltip>
                                        }
                                    >
                                        <span className={task.status_name}>
                                            <FontAwesomeIcon icon={task.status_id == 16 ? faSpinner : faSquare} spin={task.status_id == 16} />
                                        </span>
                                    </OverlayTrigger>
                                    {task.job_title}
                                </td>
                                <td>{task.tasktype}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>   
                :
                    <table style={{

                    }}>
                        <tbody>
                            <tr>
                                <td style={{
                                    textAlign: "center",
                                    fontSize: "150%",
                                    color: "#999",
                                    border: "0px"
                                }}>
                                    No Tasks Found
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                </>                     
            }
        </section>
    );
}

export default EmployeeStationPanel;