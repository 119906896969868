import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { faBars, faExclamationCircle, faExclamationTriangle, faClone, faHandshake, faTimesCircle, faBan, faListAlt, faPencilAlt, faThumbsUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import ZoomableImage from "../components/image-zoomable";
import DropdownMenu from "../components/dropdownmenu";
import Button from "../components/buttons/button";
import Alert from "../components/alert";
import ButtonMutate from "../components/buttons/button-mutate";
import Panel from "./panel";
import storage from "../settings/storage";
import settings from "../settings";
import { postData } from "../common/services/server"
import { Actions, Permissions, userHasPermission } from "../common/services/auth";
import { useGetSign } from "../api/queries/signs";
import { useApproveSign, useCloneSign, useDeleteSign, useRecalculateSignPrice, useRemoveSignFromProject } from "../api/mutations/signs";
import { formatPrice } from "../helpers/utility";
import Loading from "../components/loading";

export default function SignHeaderPanel({    
    signId,
    signQuery,
    size,
    onCloned,
    onDeleted,
    onRemovedFromProject,
}) {
    const [reCalcData, setReCalcData] = useState(null);

    const [canEdit] = useState(userHasPermission(Permissions.Signs, Actions.Edit));
    const [canDelete] = useState(userHasPermission(Permissions.Signs, Actions.Delete));

    const sign = signQuery.data;

    const recalculateSignPrice = useRecalculateSignPrice({
        onSuccess: (result) => {
            setReCalcData(result);
        }
    });
    const approveSign = useApproveSign();
    const cloneSign = useCloneSign({
        onSuccess: onCloned,
        onError: () => window.alert("Error cloning sign")
    });
    const deleteSign = useDeleteSign({
        onSuccess: onDeleted,
        onError: () => window.alert("Error deleting sign")
    });
    const removeFromProject = useRemoveSignFromProject({
        onSuccess: onRemovedFromProject,
        onError: () => window.alert("Error removing sign")
    });

    return (
        <div className="flex gap-2 mb-2">
            <div className={clsx(
                "bg-white border border-gray-200 !border-dashed rounded text-center",
                size == "sm" ? "w-[300px]" : "w-[400px]",
                size == "sm" ? "h-[100px]" : "h-[150px] ",
            )}>                
                {sign ?
                    <ZoomableImage
                        url={storage.root + sign.preview_url}
                        maxHeight={size == "sm" ? "90px":"140px"}
                        maxWidth={size == "sm" ? "290px":"390px"}
                        containerClassName="!border-0"
                    />
                : signId ?
                    <Loading nolabel padding={20} size="md" />
                :
                    null
                }
            </div>
            <div className={clsx("flex flex-col flex-1", size=="sm" ? "gap-1" : "gap-2")}>
                {signQuery.isInitialLoading ?
                    <h1 className="text-xl font-bold mb-2">
                        Loading...
                    </h1>
                : sign ?
                    <>
                        <h1 className={clsx("flex items-center gap-2 text-xl font-bold", size=="sm" ? "mb-1":"mb-2")}>
                            {sign.custom_ind == 1 && "Custom "}
                            Design #{sign.id}

                            <DropdownMenu
                                icon={faBars}
                                className="text-gray-400"
                                menu={[
                                    {name: "interactivebuilder", caption: "Interactive Builder", icon:faHandshake, hidden: sign.custom_ind == 1},
                                    {name: "clone", caption: "Clone Sign", icon:faClone, disabled:!canEdit, confirmation:"Make a copy of this sign?"},
                                    {name: "removeproject", caption: "Remove From Project", icon:faBan, disabled:!canEdit, confirmation:"Remove this sign from its project?", hidden: !sign.project_id},
                                    {name: "delete", caption: "Delete Sign", icon:faTrash, disabled:!canDelete, confirmation:"Delete this sign?"},
                                ]}
                                onMenuItem={(item) => {
                                    if (item.name == "delete")
                                        deleteSign.mutate(sign.id);
                                    else if (item.name == "clone")
                                        cloneSign.mutate(sign.id);
                                    else if (item.name == "removeproject")
                                        removeFromProject.mutate(sign.id);
                                    else if (item.name == "interactivebuilder")
                                        window.open("#/interactivebuilder/" + sign.id);
                                }}
                                busy={cloneSign.isLoading || deleteSign.isLoading || removeFromProject.isLoading}
                            />
                        </h1> 

                        {(sign.estimate_expired_ind == 1) ? 
                            <Alert 
                                icon={faExclamationCircle} 
                                size="sm" 
                                variant="danger"
                                className="!pl-2"
                                button={
                                    <ButtonMutate
                                        mutation={recalculateSignPrice}
                                        mutationData={sign.id}
                                        caption="Recalculate"
                                        size="sm"
                                        variant="danger"
                                        disabled={!canEdit}
                                    />
                                }
                            >
                                Sign price has expired.
                            </Alert>
                        : reCalcData ? 
                            <Alert 
                                size="sm"
                                variant="outline-secondary"
                                button={
                                    <Button 
                                        icon={faTimesCircle}
                                        size="icon"
                                        variant="none-secondary"
                                        onClick={() => setReCalcData(null)}
                                    />   
                                }
                            >
                                New price is <strong>{formatPrice(reCalcData.estimate)}</strong>
                                <span className={clsx(
                                    "ml-2",
                                    reCalcData.estimate > reCalcData.previous ? "text-green-500" :
                                    reCalcData.estimate < reCalcData.previous ? "text-red-500" : 
                                    "text-gray-500"
                                )}>
                                    {reCalcData.estimate > reCalcData.previous ? "+" : ""}
                                    {(reCalcData.estimate - reCalcData.previous).toFixed(2)}
                                </span>
                            </Alert>
                        :
                            null
                        }

                        <div className="flex gap-1">
                            {((sign.status == "Draft" || sign.estimate_approved_ind == 0) && userHasPermission(Permissions.Signs, Actions.Approve)) && 
                                <ButtonMutate
                                    mutation={approveSign}
                                    mutationData={sign.id}
                                    confirmation="Approve this sign?"
                                    icon={faThumbsUp}
                                    size="sm"
                                    variant="danger"
                                >
                                    Approve Sign
                                </ButtonMutate>
                            }
                                                    
                            <Button 
                                icon={faListAlt}
                                onClick={(e) => {window.open(settings.SIGNDETAILS_URL + sign.uid); e.preventDefault()}} 
                                href={settings.SIGNDETAILS_URL + sign.uid} 
                                variant="outline-success" 
                                size="xs"
                            >
                                Website Details
                            </Button>

                            {sign.custom_ind == 0 && 
                                <Button 
                                    icon={faPencilAlt}
                                    onClick={(e) => {window.open(settings.SIGNEDIT_URL + sign.uid + "&template=-1"); e.preventDefault()}} 
                                    href={settings.SIGNEDIT_URL + sign.uid + "&template=-1"} 
                                    variant="outline-warning" 
                                    size="xs"
                                >
                                    Website Builder
                                </Button>
                            }     
                        </div>     

                        {(sign.job_ids && sign.job_ids.length) && 
                            <Alert size="sm" variant="danger" icon={faExclamationTriangle}>
                                <div>
                                    <div>This sign is associated with the following jobs:</div>
                                    <div className="flex items-center gap-1 flex-wrap">
                                        {sign.job_ids.split(",").map(jobId => (
                                            <Link to={"/jobs/job/" + jobId} className="underline">
                                                #{jobId}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </Alert>
                        }
                    </>                                                            
                : signId ?
                    <>Sign Not Found</>
                :
                    <>No Sign Selected</>
                }
            </div>
        </div>
    );
}