import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { fabric } from 'fabric'

import UploadButton from "./buttons/button-upload"

const Preview = styled.div`
    position: relative;

    form { 
        display: none;
        position: absolute;
        right: 0px;
        top: 0px;
    }
    form.busy  {
        display: block;
    }

    &:hover {
        form {
            display: block;
        }
    }
`

function ShapePreview(props) {
    const canvasRef = useRef();

    useEffect(
        () => {
            var element = canvasRef.current;
            var canvas = new fabric.StaticCanvas(element);
            
            canvas.setWidth(props.width);
            canvas.setHeight(props.height);
        
            var path = new fabric.Path(props.path);

            path.fillRule = "evenodd";

            canvas.add(path);
        
            path.fill = props.color || "black";
            
            path.scaleToWidth(props.width);

            if (path.getScaledHeight() > props.height)
                path.scaleToHeight(props.height);

            if (!props.align)
                path.center();
            else 
                path.centerV();

            return () => {
                canvas.dispose();
            }    
        }, 
        [props.path, props.width, props.height, props.color]
    );

    return (
      <Preview>
        <canvas ref={canvasRef} />
        { props.upload && 
            <UploadButton
                extensions={["svg"]}
                uploadUrl={props.uploadUrl}
                onUpload={props.onUpload}
            />   
        }      
      </Preview>
    );
}

export default ShapePreview