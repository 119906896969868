import React, { useState, useEffect } from "react";
import produce from "immer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faPencilAlt, faListAlt } from "@fortawesome/free-solid-svg-icons";
import { useParams, useHistory } from "react-router-dom";

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell, 
    EditActionsCell,
    DateCell,
    LinkCell,
    SortHeaderCell,
    SortTypes,
} 
from "../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"
import Paginator from "../components/paginator"
import UlDrawer from "../drawers/drawer-ul"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"
import { removeNulls } from "../helpers/utility"
import { useBodyClass } from "../hooks/useBodyClass"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { dimensions, colors } from "../settings/settings"
import PageLayout from "../components/page-layout";

function UlReport(props) {
    const history = useHistory();
    let { tab } = useParams();

    if (!tab) tab = "customers";

    const windowDimensions = useWindowDimensions();

    const [types, setTypes] = useState([]);
    const [type, setType] = useState("");
    const [data, setData] = useState([]);    
    const [loading, setLoading] = useState("types");
    const [selected, setSelected] = useState(null);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({page:1,pages:1,total:0});
    const [sort, setSort] = useState({column: "added_date", dir: SortTypes.DESC});
    const [busyRow, setBusyRow] = useState(null);

    useBodyClass(`noscroll`);

    useEffect(
        () => {
            postData("ul/types", {},
                function(result) {
                    const tabs = [];

                    for (const ul of result) {
                        tabs.push({name: ul.prefix, title: ul.prefix});
                    }

                    setTypes(tabs);
                },
                function(error) {
                    window.alert("Error loading data")
                },
                function() {
                    setLoading("");
                }
            );
        }, 
        []
    );

    useEffect(
        () => {
            if (type && type.length) {
                setPagination({page:1,pages:1,total:0});
                setLoading("data");

                postData("ul/type", {
                    type,
                    sort: sort.column,
                    sortdir: sort.dir,
                    page: page,
                    perPage: 100,
                },
                    function(result) {
                        setData(result.data);
                        setPagination(result.pagination);
                    },
                    function(error) {
                        window.alert("Error loading data")
                    },
                    function() {
                        setLoading("");
                    }
                );
            }
        }, 
        [type, page, sort]
    );

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }

    function onAction(action, ul) {
        console.log("action", action, ul)
    
        if (action == "edit")
            setSelected({mode:"ul", ul});
        else if (action == "delete") {
          setBusyRow(ul);
    
          postData("ul/delete", {
            ul_id: ul.id
          },
            function(result) {
              setData(produce(draft => {
                const index = draft.findIndex(e => e.id == ul.id);
                draft.splice(index, 1);
              }));
            },
            function(error) {
                alert("Error deleting UL.")
            },
            function() {
              setBusyRow(null)
            }
          );
        }
    }

    function onCreate() {
        setSelected({mode:"ul", ul:{}})
    }

    return (
        <PageLayout>
            {
                loading == "types" 
            ?
                <Loading />
            :  
                <>
                <PageHeader 
                    title="UL Report"
                    help="ulreport"
                    tab={type}
                    tabs={types}
                    onTab={(tab) => {
                        setType(tab);
                    }}
                >
                    <Button onClick={onCreate} variant="outline-success" size="sm">
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        Add UL
                    </Button>
                </PageHeader>
                <Content>
                    {
                        loading == "data" 
                    ?
                        <Loading />
                    :  
                        <>
                            <Table
                                rowHeight={45}
                                rowsCount={data.length}
                                width={windowDimensions.width - dimensions.dataTableWidthOffset}
                                height={windowDimensions.height - dimensions.dataTableHeightOffset}
                                headerHeight={35}
                                touchScrollEnabled={true}
                            >
                                <Column
                                    header={
                                        <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                            UL
                                        </SortHeaderCell>
                                    }
                                    columnKey="ul"
                                    fixed={true}
                                    cell={<TextCell data={data} />}
                                    width={125}
                                />
                                <Column
                                    header={
                                        <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                            Job Number
                                        </SortHeaderCell>
                                    }
                                    columnKey="job_id"
                                    cell={
                                        <LinkCell data={data} onClick={(ul) => history.push("/jobs/job/" + ul.job_id)} />
                                    }
                                    width={170}
                                />
                                <Column
                                    header={
                                        <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                            Part
                                        </SortHeaderCell>
                                    }
                                    columnKey="name"
                                    cell={({rowIndex, ...props}) => {
                                        const ul = data[rowIndex];
                                        
                                        return (
                                          <Cell {...props}>
                                              {ul.name}{' '}
                                              {ul.similar_ind == 1 && 
                                                <span style={{color:"red"}}>
                                                    * Similar
                                                </span>
                                              }
                                          </Cell>
                                    )}}
                                    width={225}
                                />
                                <Column
                                    header={
                                        <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                            Date Used
                                        </SortHeaderCell>
                                    }
                                    columnKey="added_date"
                                    cell={<DateCell data={data} />}
                                    width={135}
                                />
                                <Column
                                    header={<Cell>Actions</Cell>}
                                    cell={
                                        <EditActionsCell 
                                            data={data} 
                                            actions={[
                                                {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt},
                                                {action:"delete", title:"Delete", variant:"danger", icon:faTrash},
                                            ]}
                                            busy={busyRow}
                                            onClick={onAction} 
                                        />
                                    }
                                    align="center"
                                    width={135}
                                />
                            </Table>
                            <Paginator
                                {...pagination}
                                item="UL Number"
                                items="UL Numbers"
                                onPage={(page) => {
                                    console.log("page", page)
                                    setPage(page);
                                }}
                            />
                        </>
                    }
                </Content>
                </>
            }
            {(selected && selected.mode == "ul") && 
                <UlDrawer
                    show={true}
                    ul={selected.ul}
                    onSaved={(ul) => {
                        // new prefix?
                        if (!types.find(x => x.name == ul.prefix)) {
                            setTypes(produce(draft => {
                                draft.push({name: ul.prefix, title: ul.prefix})
                            }));
                        }
                        // diff prefix selected?
                        if (type != ul.prefix) {
                            setType({name: ul.prefix, title: ul.prefix})
                        }

                        setData(produce(draft => {
                            const index = draft.findIndex(e => e.id == ul.id);

                            if (index == -1)
                                draft.unshift(ul)
                            else
                                draft[index] = ul
                        }));

                        setSelected(null);
                    }}
                    onHide={() => setSelected(null)}
                />
            }
        </PageLayout>
    );
}

export default UlReport;