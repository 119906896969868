import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetSigns = (params, options) => {  
  return useQuery({ 
    queryKey: ["signs", "list", params], 
    queryFn: ({signal}) => query("signs/list", params, signal),
    ...options
  });
};

export const useGetDraftSigns = (params, options) => {  
  return useQuery({ 
    queryKey: ["draftsigns", "list"], 
    queryFn: ({signal}) => query("signs/drafts", params, signal),
    staleTime: 0,
    cacheTime: 0,
    ...options
  });
};

export const useGetSignChats = (params, options) => {  
  return useQuery({ 
    queryKey: ["signchats", "list"], 
    queryFn: ({signal}) => query("signs/chats", params, signal),
    staleTime: 0,
    cacheTime: 0,
    ...options
  });
};

export const useGetSignChat = (signId, options) => {  
  return useQuery({ 
    queryKey: ["signchats", ""+signId], 
    queryFn: ({signal}) => query("signs/chat", {sign_id: signId}, signal),
    staleTime: 0,
    cacheTime: 0,
    ...options
  });
};

export const useGetDraftSign = (uid, options) => {  
  return useQuery({ 
    queryKey: ["draftsigns", uid], 
    queryFn: ({signal}) => query("signs/draft", {uid}, signal),
    staleTime: 0,
    cacheTime: 0,
    ...options
  });
};

export const useGetSign = (signId, params, options) => {  
  return useQuery({ 
    queryKey: ["signs", ""+signId, params], 
    queryFn: ({signal}) => query("signs/sign", {id: signId, ...params}, signal),
    ...options
  });
};

export const useGetSignDetails = (signId, params={}, options={}) => {  
  return useQuery({ 
    queryKey: ["signs", ""+signId, "details", params], 
    queryFn: ({signal}) => query("signs/sign/details", {id: signId, ...params}, signal),
    ...options
  });
};

export const useGetSignGroups = (signId) => {
  return useQuery({ 
    queryKey: ["signs", ""+signId, "groups"], 
    queryFn: ({signal}) => query("signs/sign/groups", {id: signId}, signal) 
  });
}

export const useGetSignGroupsWithDetails = (signId) => {
  return useQuery({ 
    queryKey: ["signs", ""+signId, "groups", "details"], 
    queryFn: ({signal}) => query("signs/sign/groups", {
      id: signId,
      include_shapes: true,
      include_parts: true,
      include_graphics: true,
      include_warnings: true,
    }, signal) 
  });
}

export const useGetSignPriceData = (signId, options={}) => {
  return useQuery({ 
    queryKey: ["signs", ""+signId, "pricedata"], 
    queryFn: ({signal}) => query("signs/sign/pricedata", {id: signId}, signal),
    staleTime: 0,
    cacheTime: 0,
    ...options
  });
}

export const useGetSignHistory = (signId, options={}) => {
  return useQuery({ 
    queryKey: ["signs", ""+signId, "history"], 
    queryFn: ({signal}) => query("signs/sign/history", {id: signId}, signal),
    ...options
  });
}

export const useGetSignGroupNewDimensions = (signId, signGroupId, params, options={}) => {
  return useQuery({ 
    queryKey: ["signs", ""+signId, "dimensions", params], 
    queryFn: ({signal}) => query("signs/sign/group/calcnewdimensions", { sign_id: signId, signgroup_id: signGroupId, ...params}, signal),
    ...options 
  });
}

export const useGetOtherSignsUsingCustomGroup = (signId, signGroupId, options={}) => {
  return useQuery({ 
    queryKey: ["signs", ""+signId, signGroupId, "OtherSignsUsingShape"], 
    queryFn: ({signal}) => query("signs/sign/group/othersignusingshape", { sign_id: signId, signgroup_id: signGroupId}, signal),
    ...options 
  });
}

