import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCog, faDesktop, faMobileAlt, faQrcode, faUserAlt, faUserLock, faWrench } from '@fortawesome/free-solid-svg-icons'

import { postData } from "../common/services/server"
import Button from "../components/buttons/button";
import Loading from "../components/loading";
import PropertyList from "../components/properties/property-list";
import Property from "../components/properties/property";
import { formatDate } from "../helpers/utility";
import { getStatusIcon } from "../helpers/jobs";

function StationUserDrawer({user, onHide}) {
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState(null);

  useEffect(() => {
      setLoading(true);

      postData("stations/home", {
        user_id: user.id
      }, 
        function(response) {
            setTask(response ? response.task : null);
        },
        function(error) {
          alert("An error occured loading data.");
        },
        function() {
            setLoading(false);
        }
      );
  }, []);

  return (
      <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faMobileAlt} />
            &nbsp;
            {user.first_name}'s Station Phone
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loading ? 
                <Loading />
            : task ?
                <>
                    <PropertyList>
                        <Property label="Job" value={task.job_title} url={"#/jobs/job/" + task.job_id} newtab={true} />
                        <Property label="Task" value={task.tasktype} icon={faCog} />  
                        <Property 
                            label="Target Date" 
                            value={formatDate(task.target_date)} 
                            icon={faCalendar} 
                            styles={{
                                color: task.late_ind == 1 ? "red" : "default"
                            }}
                        />  
                        <Property label="Status">
                            <FontAwesomeIcon icon={getStatusIcon(task.status_id)} spin={task.status_name == "inprogress"} />{' '}

                            <span className={"status-" + task.status_name} style={{borderRadius: "4px", padding: "1px 4px"}}>
                                {task.status}
                            </span>
                        </Property>
                        {task.mwo_title && 
                         <Property label="MWO" value={task.mwo_title} icon={faQrcode} />
                        }
                        {task.assigned_user_name && 
                         <Property label="Assigned To" value={task.assigned_user_name} icon={faUserAlt} />
                        }
                        {task.role && 
                         <Property label="Role" value={task.role} icon={faUserLock} />
                        }
                        {task.station_title && 
                         <Property label="Station" value={task.station_title} icon={faDesktop} />
                        }
                    </PropertyList>                    
                </>
            :
                <>
                    <p style={{
                        margin: "25px",
                        textAlign: "center",
                        color: "#999"
                    }}>
                        No Available Task Found
                    </p>
                </>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default StationUserDrawer;