import React from "react"
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import Button from "./button";
import { areObjectsEqual } from "../../helpers/utility";

function ButtonMutate({
    mutation,
    mutationData,
    onMutate,
    onMutateSuccess,
    onMutateError,
    onMutateSettled,

    confirmation,
    errorMessage,
    caption,
    capture,
    children,
    ...props
}) {
    const isBusy = mutation.isLoading && (        
        (mutationData && areObjectsEqual(mutation.variables, mutationData)) ||
        (onMutate && areObjectsEqual(mutation.variables, onMutate())) ||
        (!mutationData && !onMutate)
    );

    return (
        <Button 
            {...props}
            onClick={(e) => {
                if (capture) {
                    e.preventDefault();
                    e.stopPropagation();
                }

                if (confirmation && !window.confirm(confirmation)) {
                    return;
                }

                if (onMutate)
                    mutationData = onMutate();

                mutation.mutate(mutationData, {
                    onSuccess: (result) => {
                        if (onMutateSuccess)
                            onMutateSuccess(result, mutationData)
                    },
                    onError: () => {
                        if (onMutateError)
                            onMutateError();
                        else if (errorMessage)
                            window.alert(errorMessage);
                        else 
                            window.alert("Error posting data to the server.")
                    },
                    onSettled: onMutateSettled
                });
            }} 
            disabled={isBusy || props.disabled}
            icon={isBusy ? faSpinner : props.icon}
            iconSpin={isBusy}
        >
            {caption || children || null}
        </Button>
    );
}

export default ButtonMutate