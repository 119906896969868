import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components'
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faSave, faRss, faLink, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import UploadField from "../components/fields/field-filesupload"
import { postData } from "../common/services/server"
import storage from "../settings/storage";
import { formatDate, formatTime, formatFileSize } from "../helpers/utility";
import ZoomableImage from "../components/image-zoomable";

const Files = styled.table`
    width: 100%;
    
    th {
        background-color: #eee;
        padding: 5px;
        font-size: 12px;

        img {
            margin-right: 5px;
        }
    }
    td {
        padding: 5px;
        border-bottom: 1px solid #ccc;
        font-size: 12px;
    }
    td:nth-child(1) {
        word-break: break-all;
    }
    td:nth-child(2) {
        white-space: nowrap;
    }
    td:nth-child(3) {
        white-space: nowrap;
    }
`

function BlogFilesDrawer(props) {
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setLoading(true);

    postData("blog/blogdata", { 
        id: props.post.id,
    },
      function(result) {
        setFiles(result.files);
      },
      function(error) {
        alert("Error loading blog data")
      },
      function() {
        setLoading(false);
      }
    );
  }, [props.post]);

  function onDelete(file) {
    if (window.confirm("Delete the selected file?")) {
        setBusy("delete"+file.id);

        postData("blog/deletefile", {
            blog_id: props.post.id,
            id: file.id
        }, 
            function(response) {
                setFiles(produce(draft => {
                    const index = draft.findIndex(l => l.id == file.id)

                    if (index != -1)
                        draft.splice(index, 1);
                }));

                props.onChange(files.length - 1)
            },
            function(error) {
                alert("An error occured removing the file.");
            },
            function() {
                setBusy("")
            }
        );    
    }
  }

  function handleFileUpload(file) {
    setFiles(produce(draft => {
        draft.push(file);
    }));

    props.onChange(files.length + 1)
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faLink} />
            &nbsp;
            Blog Files
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{display:"flex", alignItems: "center", marginBottom: "15px", padding: "8px", backgroundColor: "#eee", border: "1px solid #ccc", borderRadius: "4px"}}>
                <FontAwesomeIcon icon={faRss} />
                &nbsp;
                {props.post.title}
            </div>

            { loading ? 
                <Loading /> 
              :
              <>
                <Files>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>Filename</th>
                            <th>Size</th>
                            <th>Date Added</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                    {files.map(file => (
                        <tr key={file.id}>
                            <td>
                                {file.preview_url && 
                                    <ZoomableImage
                                        url={storage.root + file.preview_url}
                                        maxWidth="40px"
                                        maxHeight="40px"
                                    />
                                }
                            </td>
                            <td width="40%">
                                <a href={storage.root + (file.version_url || file.url)} target="_blank">
                                    {file.filename}
                                </a>
                            </td>
                            <td>
                                {formatFileSize(file.filesize)}
                            </td>
                            <td>
                                <div>{formatDate(file.added_date)}</div>
                                <div><small>{formatTime(file.added_date)}</small></div>
                            </td>
                            <td align="right">
                                <Button variant="outline-danger" onClick={() => onDelete(file)}>
                                    <FontAwesomeIcon icon={busy == "delete"+file.id ? faSpinner:faTrash} spin={busy == "delete"+file.id} />
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Files>

                <br />

                <UploadField
                    name="attachments"
                    variant="simple"
                    autoClear
                    uploadUrl={"blog/uploadfile?id=" + props.post.id}
                    onUpload={(file) => handleFileUpload(file)}
                />
              </>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default BlogFilesDrawer;