import React, { useState, useEffect } from "react";
import produce from "immer"
import styled from "styled-components"
import ProgressBar from 'react-bootstrap/ProgressBar'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSpinner, faPencilAlt, faTrash, faSave, faListAlt, faPalette, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect"

import Loading from "../../components/loading"
import ShapePreview from "../../components/shape-preview"
import ListField from "../../components/fields/field-list"
import NumberField from "../../components/fields/field-number"

import PowersetDrawer from "../../drawers/drawer-powerset"
import PowerDrawer from "../../drawers/drawer-power"
import DimensionsDrawer from "../../drawers/drawer-dimensions"
import JobShapePartDrawer from "../../drawers/drawer-jobshapepart"
import ColorSelectDrawer from "../../drawers/drawer-colorselect"
import Button from "../../components/buttons/button";

import { postData } from "../../common/services/server"
import { formatDate, removeNulls } from "../../helpers/utility";
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";
import Tooltip from "react-bootstrap/esm/Tooltip";
import SelectPartsDrawer from "../../drawers/drawer-selectparts";
import { colors } from "../../settings/settings";

const Table = styled.table`
  width: 100%;
  font-size: 12px;
  margin-bottom: 5px;

  th {
    background-color: #eee;
    padding: 5px 10px;
    border: 1px solid #ccc;
  }
  td {
    padding: 5px 10px;
    border: 1px solid #ccc;
    vertical-align: top;

    &.nopadding {
      padding: 1px;
    }
    &.noborder {
      border: 0px;
    }
  }

  table.leds {
    width: 100%;
    font-size: 85%;

    th, td { padding: 2px 5px; }
  }

  table th {
    width: 40%;
  }
`
const Shapes = styled.div`
  .form-group {
    margin-bottom: 2px;
  }
`
const Powersets = styled.div`
  margin-bottom: 20px;
`

function JobPrefabPanel({job, ...props}) {
  const [canEdit, setCanEdit] = useState(userHasPermission(Permissions.Jobs, Actions.Edit));

  const [isLoading, setIsLoading] = useState(true);
  //const [isProcessing, setIsProcessing] = useState(false);
  const [shapes, setShapes] = useState([]);
  const [powersets, setPowersets] = useState([]);
  const [prewireShapesEdit, setPrewireShapesEdit] = useState([]);
  const [leds, setLeds] = useState([]);
  const [busy, setBusy] = useState("");
  const [selected, setSelected] = useState(null)
  const [dirty, setDirty] = useState(false);
  const [haveCustomShapes, setHaveCustomShapes] = useState(false);

  const stickers = Array(1,2,3,4,5,6);

  useEffect(
    () => {
        setIsLoading(true);

        postData("jobs/prewire", {
              id: job.id
          }, 
          function(response) {
            setShapes(response.shapes)
            setPowersets(response.powersets)
            setLeds(response.leds);
            setHaveCustomShapes(response.shapes.filter(s => s.resembles_ind == 1).length > 0);
          },
          function(error) {
            alert("An error occured loading job prewire data.");
          },
          function() {
            setIsLoading(false);
          }
        );
    }, 
    [job]
  );

  useEffect(
    () => {
      if (shapes) {
        setPowersets(produce(draft => {
          for (let psIndex=0; psIndex < draft.length; psIndex++) {
            let powerset = draft[psIndex];

            for (let pIndex = 0; pIndex < powerset.power.length; pIndex++) {
              const power = powerset.power[pIndex];
              let watts = 0;

              for (const shape of shapes.filter(s => s.power_id == power.id)) {
                watts += parseFloat(shape.watts_total || 0);
              }
              
              power.watts_used = (Math.round(watts * 100) / 100);
            }
          }
        }));
      }
    }, 
    [shapes, powersets]
  );

  function onPowersetSaved(result) {
    setPowersets(result);
    setSelected(null);
  }

  function onDeletePowerset(powerset) {
    if (window.confirm("Delete this powerset and its power supplies?")) {
      setSelected({mode:"DeletePowerset", powerset})

      postData("jobs/deletepowerset", {
            job_id: job.id,
            id: powerset.id
        }, 
        function(response) {
          setPowersets(response)
        },
        function(error) {
          alert("An error occured updating the job prewire data.");
        },
        function() {
          setSelected(null);
        }
      );
    }
  }

  function onPowerSaved(result) {
    setPowersets(result);
    setSelected(null);
  }

  function onDeletePower(powerset, power) {
    if (window.confirm("Delete this power supply?")) {
      setSelected({mode:"DeletePowe", power})

      postData("jobs/deletepower", {
            job_id: job.id,
            powerset_id: powerset.id,
            power_id: power.id
        }, 
        function(response) {
          setPowersets(response)
        },
        function(error) {
          alert("An error occured updating the job prewire data.");
        },
        function() {
          setSelected(null);
        }
      );
    }
  }

  function onSaveShapes() {
    let data = {
      job_id: job.id
    }

    //let changedStandardPrewire = [];

    for (const shape of shapes) {
      data["jobshape_" + shape.id + "_powerset_id"] = shape.powerset_id;
      data["jobshape_" + shape.id + "_power_id"] = shape.power_id;
      data["jobshape_" + shape.id + "_powerset_order"] = shape.powerset_order;
      data["jobshape_" + shape.id + "_extension_size"] = shape.extension_size;
      data["jobshape_" + shape.id + "_extension2_size"] = shape.extension2_size;
      data["jobshape_" + shape.id + "_bridge_ind"] = shape.bridge_ind;

      for (const part of shape.parts.filter(p => p.type_name=="lighting")) {
        data["jobpart_" + part.id + "_leds"] = part.num_leds;
        data["jobpart_" + part.id + "_led_id"] = part.led_id;

        /*
        for (const sticker of stickers) {
          data["jobpart_" + part.id + "_sticker" + sticker] = part["prewire_sticker" + sticker];
        }  

        if (part.dirty && part.dimension_id && part.dimension_custom_ind == 0 && part.dimension_temp_ind == 0) {
          changedStandardPrewire.push(shape.code + ": " + part.short_title + ": Size " + shape.size);
        }
        */
      }
      for (const part of shape.parts.filter(p => p.quantity_ind == 1)) {
        data["jobpart_" + part.id + "_quantity"] = part.quantity;
      }      
    }

    // if (changedStandardPrewire.length && !window.confirm("You're about to update the standard prewire for shape parts:\n\n" + changedStandardPrewire.join("\n") + "\n\nAre you sure you want to continue?" )) {
    //   return; // cancel save
    // }

    setBusy("SaveShapes")

    // Were any standard prewire (dimensions) changed?  If so, then confirm the change
    postData("jobs/saveshapes", removeNulls(data), 
      function(response) {
        setShapes(response);
        setDirty(false);
      },
      function(error) {
        alert("An error occured updating the job prewire data.");
      },
      function() {
        setBusy(null);
      }
    );
  }

  function onAddPowerset() {
    let blankPowerset = {
      job_id: job.id, 
      name: "Powerset " + (powersets.length+1)
    }

    if (powersets.length) {
      blankPowerset.color_id = powersets[0].color_id;
      blankPowerset.color_hex = powersets[0].color_hex;
      blankPowerset.color_name = powersets[0].color_name;
    }

    setSelected({mode:"Powerset", powerset:blankPowerset});    
  }

  function onAddPowerSupply(powerset) {
    setSelected({mode: "Power", power: {powerset_id: powerset.id}});

    /*
    postData("jobs/addpower", {job_id: job.id, powerset_id: powerset.id}, 
      function(response) {
        setPowersets(response)
      },
      function(error) {
        alert("An error occured updating the job prewire data.");
      },
      function() {
        setBusy(null);
      }
    );
    */
  }

  /*
  function addSticker(shape, part) {
    setShapes(produce(draft => {
      const sIndex = draft.findIndex(s => s.id == shape.id)
      const pIndex = draft[sIndex].parts.findIndex(p => p.id == part.id)

      for (const sticker of stickers) {
        if (!part["prewire_sticker" + sticker] || part["prewire_sticker" + sticker].length == 0) {
          draft[sIndex].parts[pIndex]["prewire_sticker" + sticker] = "No Prewire Defined";
          break;
        }
      }
    })); 
    setDirty(true);
  }
  */

  function getShapePowerOptions(shape) {
    let power = [];
    const powerset = powersets.find(p => p.id == shape.powerset_id);

    if (powerset) {
      if (powerset.type == "Master" || (powerset.power && powerset.power.length)) 
        power = powerset.power;
      else if (powerset.parent_id) {
        let parentId = powerset.parent_id;
        let idx = 1;

        while (parentId) {
          const parent = powersets.find(p => p.id == parentId);
  
          if (parent.power && parent.power.length) {
            power = parent.power;
            parentId = null;
          }
          else {
            parentId = parent.parent_id;
          }

          idx++;
          if (idx > 25) break;
        }
      }
    }

    return power;
  }

  return (
    <>
        { isLoading ? 
          <Loading />
        :
          <>
            {(job.lit_ind == 1 || job.raceway_ind == 1) &&
              <>
                <h5>
                  Powersets
                </h5>
                <Powersets>
                  <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Part</th>
                      <th>Options</th>
                      {job.lit_ind == 1 && 
                        <th>Power Supplies</th>
                      }
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {powersets.map(powerset => (
                    <tr key={powerset.id}>
                      <td>
                        {powerset.name}
                      </td>
                      <td>
                        {powerset.part_title}
                      </td>
                      <td valign="top">
                        <Table>
                          <tbody>                        
                            {powerset.height && <tr><th>Size</th><td>{powerset.height}" x {powerset.length}"</td></tr>}
                            {powerset.color_id && <tr><th>Color</th><td style={{backgroundColor: "#"+ powerset.color_hex}}>{powerset.color_name}</td></tr>}
                            {powerset.type && <tr><th>Type</th><td>{powerset.type}</td></tr>}
                            {powerset.parent_id && <tr><th>Parent</th><td>{powerset.parent_name}</td></tr>}
                            {(powerset.extension_size && powerset.extension_size != 0) && <tr><th>Extension</th><td>{powerset.extension_size} ft</td></tr>}
                            {powerset.power_side && <tr><th>Power Side</th><td>{powerset.power_side}</td></tr>}
                          </tbody>
                        </Table>
                      </td>
                      {job.lit_ind == 1 && 
                        <td>
                          {powerset.power.length > 0 && 
                            <Table>
                              <thead>
                                <tr>
                                  <th>Model</th>
                                  <th>Color</th>
                                  <th>Watts Allocated</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                              {powerset.power.map(power => {
                                const colors = power.color.split("/");

                                return (
                                  <tr key={power.id}>
                                  <td>{power.model}</td>
                                  <td>
                                    <div style={{display:"flex"}}>
                                      {colors.map(color => (
                                        <div key={color} style={{backgroundColor: color, flex: 1}}>
                                          &nbsp;
                                        </div>
                                      ))}
                                    </div> 
                                  </td>
                                  <td>
                                    <ProgressBar 
                                      now={(power.watts_used/power.watts_out)*100} 
                                      label={`${power.watts_used}`} 
                                      variant={power.watts_used > power.watts_max_load ? "danger":"success"}
                                    />
                                  </td>
                                  {canEdit && 
                                    <td width="90">
                                      <Button 
                                        size="sm"
                                        variant="outline-warning"
                                        onClick={() => setSelected({mode: "Power", power})}
                                      >
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                      </Button>
                                      <Button 
                                        size="sm"
                                        variant="outline-danger"
                                        onClick={() => onDeletePower(powerset, power)}
                                      >
                                        <FontAwesomeIcon icon={selected && selected.mode=="DeletePower" && selected.power == power ? faSpinner:faTrash} spin={selected && selected.mode=="DeletePower" && selected.power == power} />
                                      </Button> 
                                    </td>
                                  }
                                </tr>
                                )
                              })}
                              </tbody>
                            </Table>
                          }
                          {(powerset.type == "Master" && canEdit) && 
                            <Button 
                              size="sm" 
                              variant="outline-success"
                              onClick={() => onAddPowerSupply(powerset)}
                            >
                                <FontAwesomeIcon icon={faPlus} />{' '}
                                Add Power Supply
                            </Button>
                          }
                        </td>
                      }
                      {canEdit &&
                        <td width="115">
                          <Button 
                            size="sm"
                            variant="outline-warning"
                            onClick={() => setSelected({mode: "Powerset", powerset})}
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />{' '}
                            Edit
                          </Button>
                          <Button 
                            size="sm"
                            variant="outline-danger"
                            onClick={() => onDeletePowerset(powerset)}
                          >
                            <FontAwesomeIcon icon={selected && selected.mode=="DeletePowerset" && selected.powerset == powerset ? faSpinner:faTrash} spin={selected && selected.mode=="DeletePowerset" && selected.powerset == powerset} />
                          </Button>      
                        </td>
                      }
                    </tr>
                  ))}
                  </tbody>
                  </Table>
                  {canEdit &&
                    <Button 
                      size="sm" 
                      variant="outline-success"
                      onClick={onAddPowerset}
                    >
                        <FontAwesomeIcon icon={faPlus} />{' '}
                        Add Powerset
                    </Button>
                  }
                </Powersets>
              </>
            }

            <h5>Shape Data</h5>
            <Shapes>
              <Table>
              <thead>
                <tr>
                  <th>Shape</th>
                  {!isMobile &&
                    <th>Size</th>
                  }
                  <th>Parts</th>
                  {job.lit_ind == 1 &&                  
                    <th>LEDs</th>
                  }
                  <th>Options</th>
                  {(job.lit_ind == 1 || job.raceway_ind == 1) &&
                    <th>Power</th>
                  }
                </tr>
              </thead>
              <tbody>
              {shapes.map(shape => {
                  const isLit = shape.parts.filter(p => p.type_name=="lighting").length > 0;

                  return (
                      <tr key={shape.id}>
                        <td style={{verticalAlign: "top"}}>
                          <div>
                            {shape.product_title}
                          </div>                          
                          <div style={{margin: "10px"}}>
                            <ShapePreview
                                path={shape.path}
                                width={100}
                                height={50}
                            />
                          </div>
                          {shape.style_id == 0 && 
                            <div>
                              {shape.code}
                            </div>
                          }
                          {isMobile &&
                            <div>
                              Size {shape.size}
                            </div>
                          }                          
                          {shape.style_id > 0 && 
                            <div>
                              {shape.style_name}
                            </div>
                          }
                          {shape.resembles_ind == 1 && 
                              <div style={{color:"red"}}>
                                  * Similar
                              </div>
                          }
                        </td>
                        {!isMobile &&
                          <td align="center">
                            {shape.size}
                          </td>
                        }
                        <td className="nopadding">
                          <table className="parts">
                          <tbody>
                            {shape.parts.map(part => (
                              <tr key={part.id}>
                                <th>{part.type_title}</th>
                                <td>
                                  {part.title}
                                  {part.material_title && 
                                    <div style={{color: "#999"}}>
                                        - {part.material_title}
                                    </div>
                                  }
                                </td>
                                <td 
                                  width="16"
                                  style={{
                                    backgroundColor: part.color_ind == 1 ? "#"+part.color_hex : "#eee",
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    cursor: canEdit && part.color_ind == 1 ? "pointer" : "default"
                                  }}
                                  onClick={() => {
                                    if (canEdit && part.color_ind == 1)
                                      setSelected({mode:"ShapePartColor", shape, part})
                                  }}                                    
                                >
                                  {part.color_ind == 1 ?
                                    <OverlayTrigger
                                      placement="top"
                                      trigger={isMobile ? "none":"hover"}
                                      overlay={
                                          <Tooltip>
                                            <div>Code: {part.color_code}</div>
                                            <div>Name: {part.color_name}</div>
                                            <div>Hex: #{part.color_hex}</div>
                                          </Tooltip>                            
                                      }
                                    >
                                      <FontAwesomeIcon icon={busy && busy == ("ShapePartColor" + part.id) ? faSpinner : faPalette} spin={busy && busy == ("ShapePartColor" + part.id)} />
                                    </OverlayTrigger>                                                            
                                  : part.quantity_ind == 1 ?
                                    <input 
                                      type="number" 
                                      value={part.quantity || 0} 
                                      onChange={(e) => {                                        
                                        if (e.persist) e.persist();
      
                                        setShapes(produce(draft => {
                                          const index = draft.findIndex(s => s.id == shape.id)
      
                                          if (index != -1) {
                                            const idx = draft[index].parts.findIndex(p => p.id == part.id);

                                            draft[index].parts[idx].quantity = e.target.value;
                                          }
                                        }));  
                                        setDirty(true);
                                      }}                                       
                                      style={{
                                        width: "50px"
                                      }}
                                    />
                                  :
                                    <>&nbsp;</>
                                  }
                                </td>
                                <td 
                                  width="16"
                                  style={{
                                    verticalAlign: "middle",
                                    cursor: canEdit ? "pointer" : "default"
                                  }}
                                  onClick={() => {
                                    if (canEdit)
                                      setSelected({mode:"ShapePart", shape, part})
                                  }}                                  
                                >                                  
                                  <FontAwesomeIcon 
                                    icon={faPencilAlt} 
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          </table>

                          {canEdit &&
                            <Button variant="link" size="sm" onClick={() => setSelected({mode:"AddShapePart", shape})}>
                              <FontAwesomeIcon icon={faPlus} />{' '}
                              Add Part
                            </Button>
                          }
                        </td>
                        {job.lit_ind == 1 &&                        
                          <td className="nopadding">
                            {shape.parts.filter(p => p.type_name=="lighting").map(part => (
                              <table key={part.id} className="leds">
                              <tbody>
                                <tr>
                                  <th>Part</th>
                                  <td>{part.title}</td>
                                </tr>
                                {part.name != "none" && 
                                  <tr>
                                    <th>
                                      {shape.leds_estimate_ind != 0 && 
                                        <div style={{color:"red"}}>
                                          Estimated
                                        </div>
                                      }
                                      LEDs                                  
                                    </th>
                                    <td>
                                      <NumberField 
                                        name="num_leds" 
                                        value={part.num_leds} 
                                        size="sm"
                                        width="full"
                                        disabled={!canEdit}
                                        isInvalid={part.dimension_id && part.num_leds != part.prewire_leds}
                                        style={{
                                          backgroundColor: !part.num_leds ? colors.lightRed : "white"
                                        }}
                                        onChange={(e) => {
                                          if (e.persist) e.persist();

                                          setShapes(produce(draft => {
                                            const sIndex = draft.findIndex(s => s.id == shape.id)
                                            const pIndex = draft[sIndex].parts.findIndex(p => p.id == part.id)

                                            // flag the shape and part as being changed
                                            draft[sIndex].dirty = true;
                                            draft[sIndex].parts[pIndex].dirty = true;

                                            draft[sIndex].parts[pIndex].num_leds = e.target.value;
                                            draft[sIndex].parts[pIndex].watts_total = parseInt(e.target.value) * parseFloat(part.watts || 0)

                                            let shapeWatts = 0;

                                            for (const shapePart of shape.parts.filter(p => p.type_name == "lighting")) {
                                              // is this the one that is being updated?
                                              if (shapePart.id == part.id)
                                                shapeWatts += draft[sIndex].parts[pIndex].watts_total;
                                              else
                                                shapeWatts += parseFloat(shapePart.watts_total || 0);
                                            }
                              
                                            draft[sIndex].watts_total = shapeWatts;

                                            // custom leds auto fill...
                                            /*
                                            if (shape.resembles_ind == 1) {                                          
                                              let prewireSticker = draft[sIndex].parts[pIndex].prewire_sticker1;
    
                                              if (!prewireSticker || prewireSticker.length == 0 || prewireSticker == "No Prewire Defined" || prewireSticker.match(/CUSTOM \d+ .*estimate/g)) {
                                                draft[sIndex].parts[pIndex].prewire_sticker1 = "CUSTOM "+(e.target.value||0)+" LED (estimate)"
                                              }
                                            }
                                            */
                                          })); 
                                          setDirty(true);
                                        }} 
                                      />
                                    </td>
                                  </tr>
                                }
                                {part.name != "none" && 
                                  <tr>
                                    <th>Color</th>
                                    <td>
                                      <ListField 
                                        name="led_id" 
                                        value={part.led_id || ""} 
                                        size="sm"
                                        list={leds}
                                        disabled={!canEdit}
                                        onChange={(e) => {
                                          if (e.persist) e.persist();

                                          setShapes(produce(draft => {
                                            const sIndex = draft.findIndex(s => s.id == shape.id)
                                            const pIndex = draft[sIndex].parts.findIndex(p => p.id == part.id)

                                            // flag the shape and part as being changed
                                            draft[sIndex].dirty = true;
                                            draft[sIndex].parts[pIndex].dirty = true;

                                            draft[sIndex].parts[pIndex]["led_id"] = e.target.value;
                                          })); 
                                          setDirty(true);
                                        }} 
                                      />
                                    </td>
                                  </tr>
                                }
                                {part.name != "none" &&
                                  <tr>
                                    <th>Watts</th>
                                    <td>
                                      <NumberField 
                                          name="watts" 
                                          value={Math.round(shape.watts_total * 100) / 100} 
                                          size="sm"
                                          width="full"
                                          disabled={true}                                      
                                      />
                                    </td>
                                  </tr>
                                }
                                  
                                {/* part.num_leds==part.prewire_leds */}
                                {part.name != "none" && part.dimension_id && stickers.map((sticker, index) => (
                                    ((sticker == 1 || (part["prewire_sticker" + sticker] && part["prewire_sticker" + sticker].length))) ? 
                                      <tr key={index}>
                                        <th>
                                          {part.dimension_temp_ind == 1 && 
                                            <div style={{color:"red"}}>
                                              Estimated
                                            </div>
                                          }
                                          Prewire {sticker}
                                        </th>
                                        <td>              
                                          <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                            justifyContent: "space-between"
                                          }}>  
                                            {part["prewire_sticker" + sticker]}                        
                                            
                                            {sticker == 1 && part.prewire_confirmed_date ? 
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                      Confirmed by {part.prewire_confirmed_user_name} on {formatDate(part.prewire_confirmed_date)}                                              
                                                    </Tooltip>                            
                                                }
                                              >
                                                <Button size="sm" variant="outline-success" style={{fontSize: "10px", padding: "4px"}}>
                                                  <FontAwesomeIcon icon={faThumbsUp} style={{color: colors.green}} />
                                                </Button>
                                              </OverlayTrigger>                                             
                                            : sticker == 1 ?
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                      Confirm This Prewire?
                                                    </Tooltip>                            
                                                }
                                              >                                            
                                                <Button size="sm" variant="outline-secondary" style={{fontSize: "10px", padding: "4px"}}
                                                  onClick={() => {
                                                    setBusy("prewireconfirm"+part.id)

                                                    postData("shapes/confirmdimension", {
                                                        dimension_id: part.dimension_id,
                                                        size: shape.size,
                                                        jobpart_id: part.id,
                                                      }, 
                                                        function(result) {
                                                          setShapes(produce(draft => {
                                                            let sidx = draft.findIndex(s => s.id == shape.id);
                                                            let pidx = draft[sidx].parts.findIndex(p => p.id == part.id);

                                                            if (pidx != -1) {
                                                              draft[sidx].leds_estimate_ind = 0; // since it is confirmed

                                                              draft[sidx].parts[pidx].dimension_custom_ind = 0; // since it is confirmed
                                                              draft[sidx].parts[pidx].dimension_temp_ind = 0; // since it is confirmed
                                                              draft[sidx].parts[pidx].dimension_id = result.id; // might have changed
                                                              draft[sidx].parts[pidx].prewire_confirmed_date = result.confirmed_date;
                                                              draft[sidx].parts[pidx].prewire_confirmed_user_name = result.confirmed_user_name;
                                                            }
                                                          }))
                                                        },
                                                        function(error) {
                                                          alert("An error occured confirming the prewire sticker.");
                                                        },
                                                        function() {
                                                          setBusy("")
                                                        }
                                                    );                                                  
                                                  }}
                                                >
                                                  <FontAwesomeIcon icon={busy == ("prewireconfirm"+part.id) ? faSpinner:faThumbsUp} spin={busy == ("prewireconfirm"+part.id)} style={{color: "#ccc"}} />
                                                </Button>
                                              </OverlayTrigger>
                                            :
                                              null
                                            }
                                          </div>
                                        </td>
                                      </tr>
                                      :
                                      null
                                ))}
                                
                                {part.name != "none" && 
                                  <tr>
                                    <td className="noborder" colspan="2">
                                      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                        <Button variant="link" size="sm" onClick={() => setSelected({mode:"dimensions", shape, part})}>
                                          <FontAwesomeIcon icon={faListAlt} />{' '}
                                          View/Change Stickers
                                        </Button>
                                        {/* {canEdit &&
                                          <Button variant="link" size="sm" onClick={() => addSticker(shape, part)}>
                                            <FontAwesomeIcon icon={faPlus} />{' '}
                                            Add Sticker
                                          </Button>
                                        } */}
                                      </div>
                                    </td>
                                  </tr>
                                }
                              </tbody>
                              </table>
                            ))}
                          </td>
                        }
                        <td>
                          {shape.faces > 1 && 
                            <ListField 
                                name="bridge_ind" 
                                label="Bridge"
                                disabled={!canEdit}
                                value={shape.bridge_ind || 0} 
                                list={[
                                  {value:"0", label:"No"},
                                  {value:"1", label:"Yes"},
                                ]}
                                onChange={(e) => {
                                  if (e.persist) e.persist();

                                  setShapes(produce(draft => {
                                    const index = draft.findIndex(s => s.id == shape.id)

                                    draft[index].bridge_ind = e.target.value;
                                  }));  
                                  setDirty(true);
                                }} 
                            />                
                          }
                          {job.lit_ind == 1 &&
                            <ListField 
                                name="extension_size" 
                                label={"Extension from Letter to Powerstrip"}
                                disabled={!canEdit}
                                value={shape.extension_size || ""} 
                                list={[
                                  {value:"2", label:"2 ft extension"},
                                  {value:"4", label:"4 ft extension"},
                                  {value:"6", label:"6 ft extension"},
                                  {value:"8", label:"8 ft extension"},
                                  {value:"10", label:"10 ft extension"},
                                  {value:"12", label:"12 ft extension"},
                                ]}
                                onChange={(e) => {
                                  if (e.persist) e.persist();

                                  setShapes(produce(draft => {
                                    const index = draft.findIndex(s => s.id == shape.id)
    
                                    draft[index].extension_size = e.target.value;
                                  }));  
                                  setDirty(true);
                                }} 
                            />
                          }
                          {job.lit_ind == 1 &&
                            <ListField 
                                name="extension2_size" 
                                label={"Extension to Next Letter"}
                                disabled={!canEdit}
                                value={shape.extension2_size || ""} 
                                list={[
                                  {value:"2", label:"2 ft extension"},
                                  {value:"4", label:"4 ft extension"},
                                  {value:"6", label:"6 ft extension"},
                                  {value:"8", label:"8 ft extension"},
                                  {value:"10", label:"10 ft extension"},
                                  {value:"12", label:"12 ft extension"},
                                ]}
                                onChange={(e) => {
                                  if (e.persist) e.persist();

                                  setShapes(produce(draft => {
                                    const index = draft.findIndex(s => s.id == shape.id)
    
                                    draft[index].extension2_size = e.target.value;
                                  }));  
                                  setDirty(true);
                                }} 
                            />    
                          }                      
                        </td>
                        {(job.lit_ind == 1 || job.raceway_ind == 1) &&
                          <td>
                            <ListField 
                              name="powerset_id" 
                              label="Powerset"
                              disabled={!canEdit}
                              value={shape.powerset_id || 0} 
                              list={powersets}
                              onChange={(e) => {
                                if (e.persist) e.persist();

                                setShapes(produce(draft => {
                                  const index = draft.findIndex(s => s.id == shape.id)
                                  const powersetId = e.target.value;

                                  draft[index].powerset_id = powersetId;
                                  draft[index].power_id = null;
                                  
                                  let powerShapes = shapes.filter(s => s.powerset_id == powersetId);
                                  // let nextOrder = (powerShapes.length ? Math.max.apply(Math, powerShapes.map(function(o) { return o.powerset_order; })) : 0) + 1;

                                  // console.log({nextOrder});

                                  // if (!nextOrder) nextOrder = 1;

                                  // draft[index].powerset_order = nextOrder;
                                }));
                                setDirty(true);
                              }} 
                            />
                            {(job.lit_ind == 1 && shape.powerset_id) && 
                              <ListField 
                                name="power_id" 
                                label="Power Supply"
                                disabled={!canEdit}
                                value={shape.power_id || 0} 
                                list={getShapePowerOptions(shape)}
                                onChange={(e) => {
                                  if (e.persist) e.persist();

                                  setShapes(produce(draft => {
                                    const index = draft.findIndex(s => s.id == shape.id)
    
                                    draft[index].power_id = e.target.value;
                                  }));
                                  setDirty(true);
                                }} 
                              />
                            }
                            {(shape.powerset_id || !isLit) && 
                              <NumberField 
                                name="powerset_order" 
                                label="Powerset Order"
                                width="full"
                                disabled={!canEdit}
                                isInvalid={shapes.filter(s => s.powerset_order == shape.powerset_order).length > 1}
                                value={shape.powerset_order || 0} 
                                onChange={(e) => {
                                  if (e.persist) e.persist();

                                  setShapes(produce(draft => {
                                    const index = draft.findIndex(s => s.id == shape.id)
    
                                    draft[index].powerset_order = e.target.value;
                                  })); 
                                  setDirty(true);
                                }} 
                              />
                            }
                          </td>
                        }
                      </tr>
                  )
              })}
              </tbody>
              </Table>
              {(dirty && canEdit) && 
                <Button 
                  size="lg" 
                  variant="primary"
                  onClick={onSaveShapes}
                  disabled={busy=="SaveShapes"}
                  style={{
                    position: "fixed",
                    right: "20px",
                    bottom: "20px",
                    zIndex: 999
                  }}
                >
                    <FontAwesomeIcon icon={busy=="SaveShapes" ? faSpinner:faSave} spin={busy=="SaveShapes"} />{' '}
                    Save Prefab Data
                </Button>
              }
            </Shapes>

            { (selected && selected.mode == "AddShapePart") &&             
              <SelectPartsDrawer 
                label="Add Parts"
                icon={faPlus}
                busy={busy == "AddParts"}
                product={{
                  id: selected.shape.product_id,
                  title: selected.shape.product_title
                }} 
                existingParts={selected.shape.parts.map(sp => {
                  return {
                    id: sp.part_id
                  }
                })}
                onSelect={(parts) => {
                  console.log(parts)

                  setBusy("AddParts");

                  postData("jobs/addpart", { 
                      job_id: job.id, 
                      jobshape_id: selected.shape.id, 
                      part_ids: parts.map(p => p.id).join(",")
                  },
                      function(result) {
                          //setShapes(result);
                          setShapes(produce(draft => {
                            const idx = draft.findIndex(s => s.id == selected.shape.id);

                            if (idx != -1) {
                              for (const part of parts) {
                                draft[idx].parts.push(part);
                              }
                            }
                          }));

                          setSelected(null);
                      },
                      function(error) {
                          alert("Error adding parts.")
                      },
                      function() {
                          setBusy(null)
                      }
                  );
                }} 
                onHide={ () => setSelected(null) } 
              />             
            }            
            { (selected && selected.mode == "ShapePart") &&             
              <JobShapePartDrawer 
                shape={selected.shape} 
                part={selected.part}
                onSaved={(shapesData) => {
                  setShapes(shapesData);
                  setSelected(null);
                }} 
                onHide={ () => setSelected(null) } 
              />             
            }
            {(selected && selected.mode == "ShapePartColor") && 
                <ColorSelectDrawer
                    show={true}
                    part={{
                      id: selected.part.part_id
                    }}
                    color={{
                      code: selected.part.color_code,
                      name: selected.part.color_name,
                      hex_day: selected.part.color_hex,
                    }}
                    onColor={(color) => {
                        setBusy("ShapePartColor" + selected.part.id)

                        postData("jobs/updatepartcolor", removeNulls({ 
                            job_id: job.id, 
                            jobshape_id: selected.shape ? selected.shape.id : null, 
                            parttype_id: selected.partType ? selected.partType.id : null,
                            part_id: selected.part ? selected.part.id : null, 
                            color_id: color.id 
                        }),
                            function(result) {
                                setShapes(result);
                            },
                            function(error) {
                                alert("Error updating part.")
                            },
                            function() {
                                setBusy(null)
                            }
                        );

                        setSelected(null);
                    }}
                    onHide={() => setSelected(null)}
                />
            }            
            { (selected && selected.mode == "Powerset") && 
              <PowersetDrawer 
                powerset={selected.powerset} 
                powersets={powersets}
                onSaved={onPowersetSaved} 
                onHide={ () => setSelected(null) } 
              /> 
            }
            { (selected && selected.mode == "Power") && 
              <PowerDrawer 
                job={job}
                power={selected.power} 
                onSaved={onPowerSaved} 
                onHide={ () => setSelected(null) } 
              /> 
            }
            { selected && selected.mode == "dimensions" && 
              <DimensionsDrawer 
                show={true} 
                shape={{...selected.shape, id:selected.shape.shape_id}} 
                part={selected.part}
                onChange={(shapesData) => {
                  setShapes(shapesData);
                  setSelected(null);
                }} 
                onHide={ () => setSelected(null) } 
              /> 
            }          
          </>
        }
    </>
    );
}

export default JobPrefabPanel;