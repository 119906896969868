import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetFutureHolidays = (options={}) => {
  return useQuery({ 
    queryKey: ["holidays", "future"], 
    queryFn: ({signal}) => query("holidays/future", {}, signal),
    ...options
  });
}
