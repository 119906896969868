import React, { useState, useEffect } from "react";
import produce from "immer"
import styled from "styled-components";
import { Badge, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { parseISO, differenceInCalendarDays, differenceInBusinessDays } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faExternalLinkSquareAlt, faStickyNote, faUserLock } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect"

import Loading from "../../components/loading"
import JobShipDateDrawer from "../../drawers/drawer-jobshipdate";
import JobStatusDrawer from "../../drawers/drawer-jobstatus";
import JobTaskDrawer from "../../drawers/drawer-jobtask";
import JobTasksDrawer from "../../drawers/drawer-jobtasks";
import JobRoleTasksDrawer from "../../drawers/drawer-jobroletasks";

import { postData } from "../../common/services/server"
import { formatDate, isDateWeekend, removeNulls } from "../../helpers/utility"
import { colors } from "../../settings/settings"
import storage from "../../settings/storage";

import parisLogo from "../../images/paris.jpg"

const Layout = styled.div`
    display: grid;
    grid-template-columns: 1fr 150px;
    gap: 10px;

    font-size: 13px;

    a {
        color: ${colors.blue};
    }
`
const Calendar = styled.table`
    border: 1px solid #ccc;
    margin-bottom: 10px;
    min-width: 100%;

    thead th {
        background-color: #eee;
        padding: 2px 5px;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        white-space: nowrap;
        text-align: center;
        font-weight: normal;
        z-index: 999;
    }
   
    tbody td {
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 3px;
        vertical-align: middle;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        color: #999;

        cursor: pointer;

        &:hover {
            background-color: #eee;
        }
        &.selected {
            background-color: #999;
            color: white;
        }        
    }
`
const Jobs = styled.table`
    width: 100%;
    border: 1px solid #ccc;

    thead th {
        position: -webkit-sticky; /* for Safari */
        position: sticky;
        top: 0px;
        background-color: #eee;
        padding: 2px 5px;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        white-space: nowrap;
        text-align: center;
        font-weight: normal;
        z-index: 999;
    }
   
    tbody td {
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 3px;
        vertical-align: middle;
        white-space: nowrap;
    }
`
const Roles = styled.div`
    > div {
        background-color: #ccc;
        border-radius: 5px;
        margin-bottom: 2px;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        gap: 5px;
        padding: 5px;
        cursor: pointer;
        white-space: nowrap;

        .active { color: ${colors.green}; }
        .completed { color: white; background-color: ${colors.green} !important; } 
        .pending { }
        .inprogress { color: white; background-color: ${colors.blue} !important; }
        .paused, &.problem { color: ${colors.orange}; }

        .badge {
            font-size: 100%;
        }

        &:hover {
            background-color: #eee;
        }
    }

    .status {
        padding: 3px;
        border-radius: 3px;
        margin-left: 2px;
        font-size: 80%;
    }    
`

// function padToTwoDigits(num) {
//     return num.toString().padStart(2, '0');
// }

function RolesList({roles, jobs, onJobChange, onSelected}) {
    const [role, setRole] = useState(null);

    return (
        <>
            <Roles>
            {roles.map(role => {
                let taskCount = 0;

                for (const job of jobs) {
                    taskCount += job.tasks.filter(t => t.role_id == role.id && t.status_name != "completed" && t.status_name != "skipped" && t.status_name != "invalid").length;
                }

                return (
                    <div key={role.id} onClick={() => setRole(role)}>
                        {role.name}

                        <div>
                        {Object.keys(role.tasksByStatus).map((status,i)=>{
                            return (
                                <div 
                                    key={i}
                                    className={"status status-" + status}
                                    style={{
                                        float:"right",
                                        cursor:"pointer"
                                    }}
                                    onClick={() => setRole(role)}
                                >
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>{role.tasksByStatus[status][0].status_title}</Tooltip>
                                        }
                                    >                                                    
                                        <span>{role.tasksByStatus[status].length}</span>
                                    </OverlayTrigger>
                                </div>
                            )
                        })}
                        </div>
                    </div>
                )
            })}
            </Roles>

            { role && 
                <JobRoleTasksDrawer 
                    role={role}
                    show={true}
                    onChange={onJobChange}
                    onHide={ () => setRole(null) } 
                /> 
            }            
        </>
    )
}

function ShipCalendar({jobs, onDayChange}) {
    const [calendar, setCalendar] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(
        () => {
            const today = new Date();
            const todayDate = formatDate(today, "yyyy-MM-dd"); //today.getFullYear() + "-" + padToTwoDigits(today.getMonth()+1) + "-" + padToTwoDigits(today.getDate());// formatDate(today, "yyyy-MM-dd");
        
            if (jobs && jobs.length) {
                const days = [];

                for (const job of jobs) {
                    const date = job.ship_date.split("T")[0];
                    const shipDate = parseISO(date)
                    const dateStr = formatDate(shipDate, "M/d");
                    const idx = days.findIndex(d => d.label == dateStr);

                    if (idx == -1) {
                        const isWeekend = isDateWeekend(shipDate);
                        const daysDiff = differenceInCalendarDays(today, shipDate);

                        days.push({
                            date: shipDate,
                            shipDate: date,
                            jobs: [job],
                            isWeekend,
                            isLate: daysDiff > 0,
                            isToday: dateStr == todayDate,
                            label: dateStr,
                        })
                    }
                    else {
                        days[idx].jobs.push(job);
                    }                    
                }

                setCalendar(days);
            }
        }, 
        [jobs]
    );

    return (
        <Calendar>
            <thead>
                <tr>
                    {calendar.map((day,index) => {
                        return (
                            <th key={index}>
                                {day.label}
                            </th>
                        )
                    })}                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    {calendar.map((day,index) => {
                        return (
                            <td 
                                key={index} 
                                style={{
                                    color: day.isToday ? colors.lightGreen : day.isLate ? colors.red : selected==day.shipDate ? "white": "#999"
                                }}
                                onClick={() => {
                                    setSelected(selected == day.shipDate ? null : day.shipDate);
                                    onDayChange(selected == day.shipDate ? null : day.shipDate);
                                }}
                                className={selected == day.shipDate ? "selected":""}
                            >
                                {day.jobs.length}
                            </td>
                        )
                    })}
                </tr>
            </tbody>
        </Calendar>
    )
}

function JobsDashboard(props) {
    const history = useHistory();

    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);

    const [filters, setFilters] = useState({signmonkey: true, parissigns: true})
    const [shipDate, setShipDate] = useState(null);
    const [job, setJob] = useState(null);
    const [roles, setRoles] = useState([]);
    const [selected, setSelected] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [busy, setBusy] = useState("");

    const today = new Date();

    useEffect(
        () => {
            setIsLoading(true);
            
            postData("jobs/active", removeNulls({ 
                sort: "ship_date",
                sortdir: "asc",
                ...props.filters
            }),
                function(result) {
                    const today = new Date();

                    for (let job of result) {
                        job.ship_date = job.ship_date.split("T")[0];

                        const days = differenceInCalendarDays(today, parseISO(job.ship_date));

                        job.today_ind = days == 0 ? 1 : 0;
                        job.late_ind = days > 0 ? 1 : 0;

                        // for (let task of job.tasks) {
                        //     task.day = formatDate(parseISO(task.target_date), "EEE M/d")                      
                        // }
                    }

                    const activeJobs = result.filter(j => j.status_name != "hold");

                    setJobs(activeJobs);
                    setFilteredJobs(activeJobs);
                },
                function(error) {
                    alert("Error loading jobs")
                },
                function() {
                    setIsLoading(false);
                }
            );

            postData("roles/production", {
                current_tasks_ind: 1
            },
                function(result) {
                    setRoles(result);
                },
                function(error) {
                    alert("Error loading roles")
                }
            );

        }, 
        []
    );

    useEffect(
        () => {
            console.log({filters});

            setFilteredJobs(jobs.filter(j => {
                return (!filters.ship_date || j.ship_date == filters.ship_date) && 
                       (
                           (filters.signmonkey && j.company_id == 1) ||
                           (filters.parissigns && j.company_id == 2)
                       );
            }));
        }, 
        [filters]
    );

    function jobChanged(job, jobData) {
        setJobs(produce(draft => {
            const idx = draft.findIndex(j => j.id == job.id);

            if (idx != -1) {
                for (const prop of Object.keys(jobData)) {
                    draft[idx][prop] = jobData[prop];
                }
            }
        }))
    }

    function onJobChange(jobData) {
        setJob(jobData);
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
                <>
                    <div style={{
                        marginBottom: "10px"
                    }}>
                        <Form.Check 
                            type="checkbox"
                            inline
                            label="SignMonkey"
                            value={1}
                            checked={filters.signmonkey}   
                            onChange={(e) => {
                                e.persist();

                                setFilters(produce(draft => {
                                    draft.signmonkey = e.target.checked
                                }))
                            }}
                        />   
                        <Form.Check 
                            type="checkbox"
                            inline
                            label={
                                <div className="flex items-center gap-1"> 
                                    <img src={parisLogo} height="16" alt="Paris Signs Logo" className="h-4" />
                                    Paris Signs
                                </div>
                            }
                            value={1}
                            checked={filters.parissigns}   
                            onChange={(e) => {
                                e.persist();

                                setFilters(produce(draft => {
                                    draft.parissigns = e.target.checked
                                }))
                            }}
                        />                                                  
                    </div>

                    <ShipCalendar 
                        jobs={filteredJobs} 
                        onDayChange={(date) => {
                            setShipDate(date);
                        }}
                    />
                    <Layout>   
                        <div>
                            <Jobs>
                                <thead>
                                    <tr>
                                        <th>Preview</th>
                                        <th>Job #</th>
                                        <th>Order</th>
                                        <th>Order Date</th>
                                        <th>&nbsp;</th>
                                        <th>Job Date</th>
                                        <th>&nbsp;</th>
                                        <th>Ship Date</th>
                                        <th>Status</th>
                                        <th>Notes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {filteredJobs.filter(j => !shipDate || j.ship_date == shipDate).map(job => {
                                    const shipDate = parseISO(job.ship_date);
                                    const days = differenceInCalendarDays(today, shipDate);

                                    return (
                                        <tr key={job.id}>
                                            <td>
                                                <img src={storage.root + job.preview_url} height="30" style={{maxHeight: "30px", maxWidth: "150px"}} />
                                            </td>
                                            <td>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}>
                                                    <div>
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();

                                                            if (isMobile)
                                                                history.push("/jobs/job/" + job.id);
                                                            else
                                                                setJob(job);
                                                        }}>
                                                            {job.title}
                                                        </a>
                                                        &nbsp;
                                                        {!isMobile &&
                                                            <a href={"#/jobs/job/" + job.id} target="_blank">
                                                                <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
                                                            </a>
                                                        }   
                                                    </div>

                                                    {job.company_id == 2 &&
                                                        <img src={parisLogo} alt="Paris Signs" height="16" width="30" />
                                                    }                                         
                                                </div>
                                            </td>
                                            <td>
                                                <a href={"#/orders/order/" + job.order_id} target="_blank" style={{margin: "0px 3px"}}>
                                                    #{job.order_id}
                                                </a>  
                                            </td>
                                            <td>
                                                {formatDate(job.order_date)}
                                            </td>
                                            <td style={{
                                                textAlign: "center",
                                                color: "#ccc"
                                            }}>
                                                {differenceInBusinessDays(parseISO(job.added_date), parseISO(job.order_date))}
                                            </td>
                                            <td>
                                                {formatDate(job.added_date)}
                                            </td>
                                            <td style={{
                                                textAlign: "center",
                                                color: "#ccc"
                                            }}>
                                                {differenceInBusinessDays(parseISO(job.ship_date), parseISO(job.added_date))}
                                            </td>
                                            <td>
                                                <Button 
                                                    size="sm" 
                                                    variant="outline-secondary" 
                                                    style={{
                                                        padding:"1px 4px",
                                                        backgroundColor: job.today_ind == 1 ? colors.lightGreen : job.late_ind == 1 ? colors.lightRed : null,
                                                    }}
                                                    onClick={() => setSelected({mode:"ShipDate", job})}
                                                >
                                                    {formatDate(job.ship_date)}
                                                </Button>
                                            </td>
                                            <td>
                                                <Button 
                                                    size="sm" 
                                                    variant="outline-secondary" 
                                                    style={{padding:"1px 4px"}}
                                                    onClick={() => setSelected({mode:"Status", job})}
                                                >
                                                    {job.status_title}
                                                </Button>
                                            </td>
                                            <td>
                                                {(job.note_count > 0) &&
                                                    <>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                {job.notes.map(note => (
                                                                    <div key={note.id} style={{
                                                                        fontSize: "10px",
                                                                        backgroundColor: "#ffc107",
                                                                        color: "black",
                                                                        borderRadius: "4px",
                                                                        margin: "3px 0px",
                                                                        padding: "3px",
                                                                        textAlign: "left"
                                                                    }}>
                                                                        {note.notes}
                                                                    </div>
                                                                ))}
                                                                </Tooltip>                            
                                                            }
                                                        >         
                                                            <div style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                gap: "4px",
                                                                backgroundColor: "#ffc107",
                                                                padding: "0px 5px",
                                                                borderRadius: "4px"
                                                            }}>                                                  
                                                                <FontAwesomeIcon icon={faStickyNote} />
                                                                {job.note_count}
                                                            </div>
                                                        </OverlayTrigger>    
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Jobs>
                        </div>

                        <RolesList
                            roles={roles}
                            jobs={jobs}
                            onJobChange={onJobChange}
                        />
                    </Layout>
                </>
            }

            { job && 
                <JobTasksDrawer 
                    job={job}
                    roles={roles}
                    show={true}
                    onChange={(jobData) => {
                        setJobs(produce(draft => {
                            const jobIdx = draft.findIndex(j => j.id == job.id);

                            if (jobIdx != -1) {
                                draft[jobIdx] = jobData;
                            }
                        }));
                        setJob(jobData);

                        //setJob(null);
                    }}
                    onHide={ () => setJob(null) } 
                /> 
            }
            { (selected && selected.mode == "Task") && 
                <JobTaskDrawer 
                    job={selected.job}
                    task={selected.task} 
                    show={true}
                    onSaved={(jobData) => {
                        setJobs(produce(draft => {
                            const jobIdx = draft.findIndex(j => j.id == selected.job.id);

                            if (jobIdx != -1) {
                                draft[jobIdx] = jobData;
                            }
                        }));                        

                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { (selected && selected.mode == "ShipDate") && 
                <JobShipDateDrawer
                    show={true}
                    job={selected.job}
                    onJobChange={(jobData) => {
                        jobChanged(selected.job, jobData)
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } /> 
            }  
            { (selected && selected.mode == "Status") && 
                <JobStatusDrawer
                    show={true}
                    job={selected.job}
                    onJobChange={(jobData) => {
                        jobChanged(selected.job, jobData)
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } /> 
            }                        
        </>
    );
}

export default JobsDashboard;