import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Badge from 'react-bootstrap/Badge'
import Alert from 'react-bootstrap/Alert'
import FormControl from 'react-bootstrap/FormControl'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSpinner, faTrash, faPencilAlt, faSave, faImage, faPrint, faClone, faListAlt, faPlug, faThumbsUp, faExclamationCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import produce from "immer"

import SignGroupDrawer from "../../drawers/drawer-signgroup"
import SignGroupSizeDrawer from "../../drawers/drawer-signgroupsize"
import PowerGroupDrawer from "../../drawers/drawer-signgrouppower"
import ColorSelectDrawer from "../../drawers/drawer-colorselect"
import FilesSelectDrawer from "../../drawers/drawer-fileselect"
import RelatedShapesDrawer from "../../drawers/drawer-relatedshapes"
import ShapeSizeDrawer from "../../drawers/drawer-shapesize"

import SelectField from "../../components/fields/field-select"

import ColorBox from "../../components/color-box"
import ShapePreview from "../../components/shape-preview"
import GoogleImage from "../../components/google-image";
import UploadButton from "../../components/buttons/button-upload"
import Button from "../../components/buttons/button";

import { postData } from "../../common/services/server"
import { formatPrice } from "../../helpers/utility"
import { colors } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";
import { Link } from "react-router-dom";

const Groups = styled.div`
    display: flex;

    > div.add {
        min-width: 125px;
        text-align: center;
        color: #eee;
        border: 1px dashed #eee;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg {
            display: block;            
            margin-bottom: 10px;
        }
        &:hover {
            color: blue;
            border-color: blue;
        }
    }
    > div {
        min-width: 300px;
        border: 1px solid #eee;
        padding: 5px;
        border-radius: 5px;
        margin-right: 5px;

        table {
            width: 100%;
            font-size: 80%;

            th {
                background-color: #eee;
                padding: 4px 6px;
                border-bottom: 1px solid white;
                border-radius: 3px;
                width: 90px;                
            }
            td {
                padding: 4px 6px;

                > div {
                    display: flex;
                    justify-content: space-between;
                }
                > div > div > div {
                    display: flex;
                    align-items: center;
                }
                div.color > div {
                    margin-left: 0px;
                }
            }
        }
        h3 {
            font-size: 17px;
            margin: 15px 0px 5px 0px;
        }
        div.color {
            display: inline-block;
        }
        .cost {
            color: ${colors.green};
        }
    }  
    
    td.remove {
        width: 20px;
        color: #eee;
        cursor: pointer;

        &:hover {
            color: red;
        }
    }

    div.change .color {
        cursor: pointer;
    }

    div.edit {
        margin-top: 10px;
        display: flex;

        button {
            display: block;
            width: 100%;
            flex-basis: 50%;

            &.clone {
                flex-basis: 35%;
            }
            &.delete {
                flex-basis: 15%;

                svg {
                    margin: 0px;
                }
            }
            svg {
                margin-right: 5px;
            }
        }
    }
`

function BoardSignGroups(props) {

    const [groups, setGroups] = useState([])
    const [customShapes, setCustomShapes] = useState({})
    const [selected, setSelected] = useState(null);
    const [busy, setBusy] = useState(null);

    useEffect(
        () => {
            let groupData = [];
            let shapesData = {}

            if (sign.groups) {
                for (const group of sign.groups) {
                    let partTypes = [];

                    for (const part of group.parts) {
                        let partType = partTypes.find(pt => pt.title == part.type_title);
            
                        if (!partType) {
                            partType = {
                                title: part.type_title,
                                list_order: part.type_name == "auto" ? 99 : 0,
                                parts: []
                            }
                            
                            partTypes.push(partType)
                        }
            
                        partType.parts.push(part);
                    }
            
                    partTypes.sort(function(a, b) {
                        var keyA = new Date(a.list_order);
                        var keyB = new Date(b.list_order);
                        
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    });
            
                    const backdrop_ind = group.parts.filter(p => p.type_name == "backdrop").length > 0 ? 1 : 0;

                    groupData.push({...group, partTypes, backdrop_ind})

                    // look for custom shapes
                    if (group.shapes) {
                        for (const shape of group.shapes) {
                            if (shape.code == "CUSTOM") {
                                shapesData[group.id] = "CST";

                                if (shape.related && shape.related.length) {
                                    shapesData[group.id] += ":";

                                    for (const relshape of shape.related) {
                                        shapesData[group.id] += relshape.code;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
            setGroups(groupData);
            setCustomShapes(shapesData);
        }, 
        [props.sign]
    );

    const { sign, stats } = props;
    const custom = sign.custom_ind == 1;

    const [canEdit, setCanEdit] = useState(!custom || userHasPermission(Permissions.SignsCustom, Actions.Edit))
    const [canApprove, setCanApprove] = useState(userHasPermission(Permissions.Signs, Actions.Approve))

    function onCloneGroup(group) {
        setBusy({mode:"clone", group})

        postData("signs/clonegroup", { sign_id: sign.id, group_id: group.id },
            function(result) {
                props.onSignChange(result);
            },
            function(error) {
                alert("Error cloning group.")
            },
            function() {
                setBusy(null)
            }
        );
    }
    function onDeleteGroup(group) {
        setBusy({mode:"delete", group})

        postData("signs/deletegroup", { sign_id: sign.id, group_id: group.id },
            function(result) {
                props.onSignChange(result);
            },
            function(error) {
                alert("Error deleting group.")
            },
            function() {
                setBusy(null)
            }
        );
    }

    function onPartColor(group, part) {
        setSelected({mode:"partcolor", group, part})
    }

    function onRemovePart(group, part) {
        setBusy({mode:"removepart", group, part})

        console.log("onRemovePart", group.id, part.id)

        postData("signs/removepart", { sign_id: sign.id, group_id: group.id, part_id: part.id },
            function(result) {
                props.onSignChange(result);
            },
            function(error) {
                alert("Error removing part.")
            },
            function() {
                setBusy(null)
            }
        );
    }

    function onGraphicUploaded(result) {
        props.onSignChange(result);  
    }

    function onRemoveGraphic(group, graphic) {
        setBusy({mode:"removegraphic", group, graphic})

        console.log("onRemoveGraphic", group.id, graphic.id)

        postData("signs/removegraphic", { sign_id: sign.id, group_id: group.id, graphic_id: graphic.id },
            function(result) {
                props.onSignChange(result);
            },
            function(error) {
                alert("Error removing graphic.")
            },
            function() {
                setBusy(null)
            }
        );
    }

    function onApproveGroupPrice(group) {
        setBusy({mode:"approveprice", group})

        postData("signs/approveshapeprice", { sign_id: sign.id, group_id: group.id, code: customShapes[group.id] },
            function(result) {
                props.onSignChange(result, {recalculate:false});

                if (props.onSignApproved)
                    props.onSignApproved();
            },
            function(error) {
                alert("Error approving custom shape.")
            },
            function() {
                setBusy(null)
            }
        );
    }

    function onApproveAllGroupPrices() {
        setBusy({mode:"approveprices"})

        let data = { 
            sign_id: sign.id
        }

        for (const group of groups) {
            data["group" + group.id] = customShapes[group.id]
        }

        postData("signs/approveshapeprices", data,
            function(result) {
                props.onSignChange(result, {recalculate:false});

                if (props.onSignApproved)
                    props.onSignApproved();
            },
            function(error) {
                alert("Error approving custom shapes.")
            },
            function() {
                setBusy(null)
            }
        );        
    }

    function onUpdatePriceModifier(shapeId, priceMod) {
        setBusy("price_modifier")

        postData("shapes/savepricemodifier", {id:shapeId, pricing_modifier:priceMod}, 
            function(response) {
                props.onSignChange(response);
            },
            function(error) {
                alert("An error occured updating the shape.");
            },
            function() {
                setBusy("");
            }
        );        
    }

    function onUpdateConsumption(shapeId, consumption) {
        setBusy("consumption")

        postData("shapes/saveconsumption", {id:shapeId, consumption}, 
            function(response) {
                props.onSignChange(response);
            },
            function(error) {
                alert("An error occured updating the shape.");
            },
            function() {
                setBusy("");
            }
        );        
    }

    function onUpdateSubShapeStyle(shapeId, fromStyleId, toStyleId) {
        setBusy("subshapestyle")

        postData("shapes/savesubshapestyle", {
            id: shapeId, 
            from_style_id: fromStyleId,
            to_style_id: toStyleId
        }, 
            function(response) {
                props.onSignChange(response);
            },
            function(error) {
                alert("An error occured updating the shape.");
            },
            function() {
                setBusy("");
            }
        );  
    }

    function onResetJobShapes(jobId) {
        if (window.confirm("Reset Job Shapes?")) {
            setBusy({mode:"job" + jobId});

            postData("jobs/resetshapes?id=" + jobId, {},
                function(result) {
                },
                function(error) {
                    alert("Error updating job")
                },
                function() {
                    setBusy("");
                }
            );
        }
    }

    const multipleGroupsCustomShapes = groups.filter(g => g.custom_ind == 1 && g.custom_approved_ind == 0).length > 1;

    return (
        <>
        <div style={{maxWidth: "calc(100vw - 80px)"}}>
            {(sign && !props.hideApprove && (sign.status == "Draft" || sign.estimate_approved_ind == 0) && userHasPermission(Permissions.Signs, Actions.Approve)) &&
                <div style={{backgroundColor: colors.lightRed, flexBasis: "100%", border: "1px solid #ccc", borderRadius: "5px", padding: "5px", margin: "2px", fontSize:"80%", textAlign:"center"}}>
                    <Button
                        size="sm"
                        variant="outline-danger"
                        style={{padding: "2px 6px"}}
                        onClick={() => {
                            setBusy("approve");
                            
                            postData("signs/approve", { 
                                sign_id: sign.id
                            },
                                function(result) {
                                    if (props.onSignApproved)
                                        props.onSignApproved();                                
                                },
                                function(error) {
                                    alert("Error approving sign")
                                },
                                function() {
                                    setBusy("");
                                }
                            );                                    
                        }}
                    >
                        <FontAwesomeIcon icon={busy == "approve" ? faSpinner : faThumbsUp} spin={busy == "approve"} />{' '}
                        Approve Sign
                    </Button>                            
                </div>
            }        
            {multipleGroupsCustomShapes && 
                <Alert variant="secondary" style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                        <FontAwesomeIcon icon={faThumbsUp} /> {' '}
                        This sign has custom groups that require review and approval.
                    </div>
                    <Button 
                        onClick={() => onApproveAllGroupPrices()} 
                        size="sm"  
                        disabled={!canApprove}
                        variant="danger"
                        style={{whiteSpace: "nowrap"}}
                    >
                        <FontAwesomeIcon icon={busy && busy.mode=="approveprices" ? faSpinner:faThumbsUp} spin={busy && busy.mode=="approveprices"} />{' '}
                        Approve All Custom Prices
                    </Button>                  
                </Alert>
            }
            {(stats.jobs > 0) && 
                stats.job_ids.split(",").map(jobId => (
                    <Alert variant="warning" style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                            <FontAwesomeIcon icon={faExclamationCircle} /> {' '}
                            This sign is associated with Job <Link to={"/jobs/job/" + jobId}>#{jobId}</Link>.  You should <strong>reset</strong> the job shapes after 
                            you make any changes to its groups to keep everything in sync.
                        </div>
                        <Button variant="warning" onClick={() => onResetJobShapes(jobId)} size="sm" style={{whiteSpace: "nowrap"}}>
                            <FontAwesomeIcon icon={busy && busy.mode=="job"+jobId ? faSpinner:faSync} spin={busy && busy.mode=="job"+jobId}/>{' '}
                            Reset Job Shapes
                        </Button>
                    </Alert>                
                ))
            }
        </div>

        <Groups>
            {groups.map(item => {
                const isPrinted = item.parts.find(p => p.name.indexOf("printedface") != -1);
                
                return (
                    <div key={item.id}>
                        <table>
                        <tbody>
                            <tr>
                                <th>ID</th>
                                <td>
                                    {item.id}
                                </td>
                            </tr>
                            {(item.shapes.length == 1) && 
                                <>
                                <tr>
                                    <th>Shape</th>
                                    <td>
                                        {(item.custom_ind == 0 || item.custom_approved_ind == 1) ? 
                                                <div style={{flexFlow:"wrap"}}>
                                                    {(item.custom_size_width && item.custom_size_height) ?
                                                        <span style={{color:"red"}}>CST {item.shapes[0].code}</span>
                                                    :
                                                        item.shapes[0].code
                                                    }
                                                </div>                                        
                                            :
                                                <>
                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip>
                                                                Custom shape needs a unique code assigned to it.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <FormControl
                                                            value={customShapes[item.id]}
                                                            style={{border: "1px solid red"}}
                                                            disabled={!canEdit}
                                                            onChange={(event) => {
                                                                event.persist();

                                                                setCustomShapes(produce(draft => {
                                                                    draft[item.id] = event.target.value;
                                                                }))
                                                            }}   
                                                        />                                                        
                                                    </OverlayTrigger>                                                
                                                </>
                                        }   

                                        {(item.custom_ind == 1 || item.resembles_ind == 1) && 
                                            <Badge size="sm" variant="danger"> 
                                                CUSTOM
                                            </Badge>
                                        }
                                    </td>
                                </tr>
                                {(item.shapes[0].related_count > 0) && 
                                    <tr>
                                        <th>Sub Shapes</th>
                                        <td>
                                            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                                <div>
                                                {item.shapes[0].related.map(shape => (
                                                    shape.code
                                                ))}
                                                </div>

                                                <Button onClick={() => setSelected({mode:"subshapes", group:item})} size="sm" disabled={!canEdit} variant="outline-secondary" style={{padding:"4px"}}>
                                                    <FontAwesomeIcon icon={faPencilAlt} />
                                                </Button>   
                                            </div>                                         
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <th>Preview</th>
                                    <td>
                                        <ShapePreview
                                            path={item.shapes[0].path}
                                            width={100}
                                            height={50}
                                        />
                                    </td>
                                </tr>
                                </>
                            }
                            {(item.shapes.length > 1) && 
                                <tr>
                                    <th>Letters</th>
                                    <td style={{ fontWeight: "bold", fontSize: "125%"}}>
                                        {item.shapes.map(shape => (
                                            shape.code
                                        ))}
                                    </td>
                                </tr>
                            }
                            <tr>
                                <th>Product</th>
                                <td>
                                    {item.product.parent_title || item.product.title}

                                    {item.custom_ind == 1 && 
                                        <span style={{color:"red"}}>
                                            * Custom
                                        </span>
                                    }
                                </td>
                            </tr>
                            {item.multipart_group && 
                                <tr>
                                    <th>Multiple Logo Piece</th>
                                    <td>
                                        MPL {item.multipart_group}
                                    </td>
                                </tr> 
                            }                           
                            <tr>
                                <th>Style</th>
                                <td>
                                    {item.style.name}
                                    {(item.resembles_ind == 1 && item.style.id != 99) && 
                                        <span style={{color:"red"}}>
                                            &nbsp; * Similar
                                        </span>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>Size</th>
                                <td>
                                    <div style={{display:"flex", justifyContent: "space-between", alignItems: "center"}}> 
                                        <div>
                                            {item.size}
                                            {(item.shapes.length > 1 && item.spacing) && 
                                                <small>&nbsp;({item.spacing} spacing)</small>
                                            }
                                        </div>
                                        {(item.custom_ind == 1 && item.custom_approved_ind == 0) && 
                                            <Button onClick={() => setSelected({mode:"groupsize", group:item})} size="sm" disabled={!canEdit} variant="outline-secondary" style={{padding:"4px"}}>
                                                <FontAwesomeIcon icon={faPencilAlt} />
                                            </Button>
                                        }                                        
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Dimensions</th>
                                <td>
                                    <div className="flex items-center justify-between">
                                        {item.custom_size_height || item.size_height}" x {item.custom_size_width || item.size_width}"

                                        {(item.custom_ind == 1 && item.custom_approved_ind == 0) && 
                                            <Button onClick={() => setSelected({mode:"shapesize", group:item, shape:item.shapes[0]})} size="sm" disabled={!canEdit} variant="outline-secondary" style={{padding:"4px"}}>
                                                <FontAwesomeIcon icon={faPencilAlt} />
                                            </Button>
                                        }
                                    </div>

                                    {item.backdrop_ind == 1 && 
                                        <div>
                                            <small className="text-red-500">* size include background</small>
                                        </div>
                                    }                                    
                                </td>
                            </tr>

                            {(item.shapes[0].related_count > 0) && 
                                <tr>
                                    <th>Similar To</th>
                                    <td>
                                        {item.custom_approved_ind == 0 ?
                                            <div style={{display:"flex"}}> 
                                                <SelectField 
                                                    name="style_id" 
                                                    labelField="name_and_price_modifier"
                                                    groupStyle={{margin:"0px"}}
                                                    disabled={!canEdit}
                                                    style={{borderColor: "red"}}
                                                    value={item.shapes[0].related[0].to_style_id || item.shapes[0].related[0].style_id} 
                                                    url={"styles/data"}
                                                    onChange={(event) => {
                                                        event.persist();

                                                        setGroups(produce(draft => {
                                                            const idx = draft.findIndex(g => g.id == item.id);

                                                            draft[idx].shapes[0].related[0].to_style_id = event.target.value;
                                                            draft[idx].shapes[0].style_changed = true;
                                                        }))
                                                    }} />
                                                <Button onClick={() => onUpdateSubShapeStyle(item.shapes[0].id, item.shapes[0].related[0].style_id, item.shapes[0].related[0].to_style_id)} size="sm" disabled={!canEdit} variant={item.shapes[0].style_changed ? "danger":"outline-danger"} style={{padding:"5px"}}>
                                                    <FontAwesomeIcon icon={busy=="subshapestyle" ? faSpinner:faSave} spin={busy=="subshapestyle"} />
                                                </Button>                                                      
                                            </div>
                                        :
                                            <div>
                                                {item.shapes[0].related[0].to_style_name || item.shapes[0].related[0].style_name}                                                
                                            </div>
                                        }
                                    </td>
                                </tr>
                            }
                            {(item.custom_ind == 1 || item.resembles_ind == 1) && 
                            <>
                                <tr>
                                    <th>Complexity Adjustment</th>
                                    <td>
                                        {(true || item.custom_approved_ind == 0) ?
                                            <div style={{display:"flex"}}> 
                                                <FormControl 
                                                    as="select" 
                                                    name="complexity" 
                                                    disabled={!canEdit}
                                                    style={{border: "1px solid red"}}
                                                    value={item.shapes[0].pricing_modifier} 
                                                    onChange={(event) => {
                                                        event.persist();

                                                        setGroups(produce(draft => {
                                                            const idx = draft.findIndex(g => g.id == item.id);

                                                            draft[idx].shapes[0].pricing_modifier = event.target.value;
                                                            draft[idx].shapes[0].pricing_modifier_changed = true;
                                                        }))
                                                    }}>
                                                    
                                                    <option value={1}>None</option>
                                                    <option value={1.1}>+ Complexity .1</option>
                                                    <option value={1.2}>+ Complexity .2</option>
                                                    <option value={1.3}>+ Complexity .3</option>
                                                    <option value={1.4}>+ Complexity .4</option>
                                                    <option value={1.5}>+ Complexity .5</option>
                                                </FormControl>                                            
                                                <Button onClick={() => onUpdatePriceModifier(item.shapes[0].id, item.shapes[0].pricing_modifier)} size="sm" variant={item.shapes[0].pricing_modifier_changed ? "danger":"outline-danger"} style={{padding:"5px"}}>
                                                    <FontAwesomeIcon icon={busy=="price_modifier" ? faSpinner:faSave} spin={busy=="price_modifier"} />
                                                </Button>                                            
                                            </div>
                                        :                                                    
                                            item.shapes[0].pricing_modifier
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>Shape Consumption</th>
                                    <td>
                                        {(true || item.custom_approved_ind == 0) ?
                                            <div style={{display:"flex"}}> 
                                                <FormControl 
                                                    as="select" 
                                                    name="consumption" 
                                                    disabled={!canEdit}
                                                    style={{border: "1px solid red"}}
                                                    value={item.shapes[0].consumption} 
                                                    onChange={(event) => {
                                                        event.persist();

                                                        setGroups(produce(draft => {
                                                            const idx = draft.findIndex(g => g.id == item.id);

                                                            draft[idx].shapes[0].consumption = event.target.value;
                                                            draft[idx].shapes[0].consumption_changed = true;
                                                        }))
                                                    }}
                                                >
                                                {[...Array(100)].map((e, i) => <option key={i} value={(i+1)/100}>{i+1}%</option>)}
                                                </FormControl>                                               
                                                <Button onClick={() => onUpdateConsumption(item.shapes[0].id, item.shapes[0].consumption)} size="sm" variant={item.shapes[0].consumption_changed ? "danger":"outline-danger"} style={{padding:"5px"}}>
                                                    <FontAwesomeIcon icon={busy=="consumption" ? faSpinner:faSave} spin={busy=="consumption"} />
                                                </Button>                                            
                                            </div>
                                        :
                                            <>{item.shapes[0].consumption*100}%</>
                                        }
                                    </td>
                                </tr>                                
                            </>
                            }
                            <tr>
                                <th>Parts Only Cost</th>
                                <td>
                                    {props.busy == "price" ? 
                                        <>
                                            <FontAwesomeIcon icon={faSpinner} spin />{' '}
                                            Calculating...
                                        </>
                                    : (item.custom_ind && item.custom_approved_ind == 0) ?
                                        <>
                                            <Badge variant="danger" style={{fontSize:"100%", padding:"5px", marginBottom:"5px"}}>
                                                {formatPrice(item.estimate)}                                            
                                            </Badge>
                                            <div>
                                                <Button 
                                                    onClick={() => onApproveGroupPrice(item)} 
                                                    size="sm" 
                                                    block 
                                                    variant="outline-danger"
                                                    disabled={customShapes[item.id] == "CUSTOM" || !canApprove}
                                                >
                                                    <FontAwesomeIcon icon={busy && busy.mode=="approveprice" && busy.group==item ? faSpinner:faThumbsUp} spin={busy && busy.mode=="approveprice" && busy.group==item} />{' '}
                                                    Approve Price                                                
                                                </Button>                                            
                                            </div>                                            
                                        </>
                                    :                                        
                                        formatPrice(item.estimate)
                                    }
                                    
                                </td>
                            </tr>
        
                        </tbody>
                        </table>

                        <h3>Parts</h3>
                        <table>
                        <tbody>
                        {item.partTypes.map((partType,index) => (
                            <tr key={index}>
                            <th>{ partType.title }</th>
                            <td>
                            {partType.parts.map((part,index) => {
                                const removingPart = busy && busy.mode == "removepart" && busy.group == item && busy.part == part;
                                let colorPart = part;
                                let leds = false;
                                let ledChange = false;

                                if (part.name == "face_leds" || part.name == "cabinet_leds")
                                    colorPart = item.parts.find(p => p.type_name == "face");
                                if (part.name == "back_leds")
                                    ledChange = true;
                                else if (part.type_name == "lighting" && part.name.indexOf("leds") != -1)
                                    leds = true;

                                let color = colorPart ? colorPart.color : null;

                                if (colorPart && part.type_name == "lighting" && isPrinted) {
                                    color = {
                                        name: "White",
                                        hex_day: "FFFFFF",
                                        led_color: "White",
                                        led_hex: "FFFFFF"
                                    }
                                }

                                return (
                                    <div key={index}>
                                        <div style={{width:"100%"}}>
                                            { (!part.color_id || part.title != part.type_title) && 
                                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                                    {part.title}

                                                    {part.type_name == "power" && 
                                                        <Button onClick={() => setSelected({mode:"power", group:item})} size="sm" disabled={!canEdit} variant="outline-secondary" style={{padding:"4px"}}>
                                                            <FontAwesomeIcon icon={faPlug} />
                                                        </Button>
                                                    }
                                                </div> 
                                            }
                                            { ((colorPart && (colorPart.color_id || colorPart.color_ind==1))) && 
                                                <div className={custom && (!leds || ledChange) ? "change":""}>
                                                    {(busy && busy.group == item && busy.part == colorPart) ?
                                                        <><FontAwesomeIcon icon={faSpinner} spin={true} />{' '}</>
                                                    :
                                                        <ColorBox 
                                                            color={{name: color ? leds ? color.led_color : color.name : "?", hex_day: leds ? color.led_hex : color ? color.hex_day : null}} 
                                                            size="small" 
                                                            onClick={custom && (!leds || ledChange) ? () => onPartColor(item, colorPart) : null}
                                                        />
                                                    }
                                                    { color ? leds ? color.led_color : color.name : "" }
                                                </div>
                                            }
                                            {(part.decoration_type) && 
                                                <div style={{marginLeft: "20px"}}>
                                                    <small>- {part.decoration_type}</small>
                                                </div>
                                            }
                                            { (part.type_name == "face" && isPrinted) &&
                                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                                    <div>
                                                        <span style={{width: "16px", border: "1px solid #ccc", borderRadius: "3px", paddingLeft: "1px"}}>
                                                            <FontAwesomeIcon icon={faPrint} size="1x" />
                                                        </span>
                                                        &nbsp;
                                                        Printed
                                                    </div>

                                                    {(item.graphics.length == 0) && 
                                                        <Button onClick={() => onRemovePart(item, part)} size="sm" disabled={!canEdit} variant="outline-secondary" style={{padding:"4px"}}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    }                                                    
                                                </div>
                                            }
                                        </div>
                                        {(custom && false) && 
                                            <div className="remove" onClick={() => onRemovePart(item, part)}>
                                                <FontAwesomeIcon icon={removingPart ? faSpinner : faTrash} spin={removingPart} size="sm" />
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                            </td>
                            </tr>
                        ))}
                        </tbody>
                        </table>

                        <h3 style={{display:"flex", justifyContent: "space-between"}}>
                            Graphics

                            {custom && 
                                <div style={{display:"flex"}}>
                                    {sign.quote_id && 
                                        <Button onClick={() => setSelected({mode:"browsefiles", group:item})} size="sm" disabled={!canEdit} variant="secondary" style={{padding: "4px", lineHeight: "1em", fontSize: "12px", marginRight: "2px"}}>
                                            <FontAwesomeIcon icon={busy && busy.mode=="addingfile" && busy.group==item ? faSpinner:faListAlt} spin={busy && busy.mode=="addingfile" && busy.group==item} />{' '}
                                            Browse
                                        </Button>
                                    }
                                    <UploadButton
                                        uploadUrl={"signs/uploadgraphic?sign_id=" + sign.id + "&group_id=" + item.id}
                                        label="Upload"
                                        size="sm"
                                        thumbnail={true}
                                        disabled={!canEdit}
                                        extensions={["jpg","png"]}
                                        onUpload={onGraphicUploaded}
                                        style={{float: "right"}}
                                    /> 
                                </div>
                            }
                        </h3>
                        <table>
                        <tbody>
                        {item.graphics.filter(g => g.face >= 0).map(graphic => {
                            const removingGraphic = busy && busy.mode == "removegraphic" && busy.group == item && busy.graphic == graphic;

                            return (
                                <tr key={graphic.id}>
                                    <th>
                                    { 
                                        graphic.type == "svg" ?
                                            "SVG"
                                        : graphic.type == "text" ?
                                            "Text"
                                        : graphic.type == "image" ?
                                            "Image"
                                        : graphic.type
                                    }
                                    </th>
                                    <td>
                                    { 
                                        graphic.type == "svg" ?
                                            <GoogleImage 
                                                root 
                                                src={graphic.faceart_url_thumb || graphic.faceart_url} 
                                                link={graphic.faceart_url} 
                                                style={{maxHeight: "25px", maxWidth: "75px"}} 
                                            />
                                        : graphic.type == "text" ?
                                            graphic.text + " (" + graphic.font_name + ")"
                                        : graphic.type == "image" ?
                                            <GoogleImage 
                                                root 
                                                src={graphic.faceart_url_thumb || graphic.faceart_url} 
                                                link={graphic.faceart_url}
                                                style={{maxHeight: "25px", maxWidth: "75px"}} 
                                            />
                                        : graphic.type
                                    }
                                    </td>
                                    {custom && 
                                        <td className="remove" onClick={() => canEdit ? onRemoveGraphic(item, graphic) : null}>
                                            <FontAwesomeIcon icon={removingGraphic ? faSpinner : faTrash} spin={removingGraphic} size="sm" />
                                        </td>
                                    }
                                </tr>
                            )
                        })}
                        {item.parts.filter(p => p.faceart_id).map(part => {
                            return (
                                <tr key={part.id}>
                                    <th>
                                        Image
                                    </th>
                                    <td>
                                        <GoogleImage 
                                            root 
                                            src={part.faceart_url_thumb || part.faceart_url} 
                                            link={part.faceart_url}
                                            style={{maxHeight: "25px", maxWidth: "75px"}} 
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                        </table>
                        {(item.graphics.filter(g => g.face >= 0).length == 0 && item.parts.filter(p => p.faceart_id).length == 0) &&
                            <p>No Graphics Found</p>
                        } 

                        <div className="edit">
                            {(custom && canEdit) && 
                                <>
                                    <Button variant="outline-primary" size="sm" onClick={() => setSelected({mode:"group", group:item})}>
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                        Edit Group
                                    </Button>
                                    <Button variant="outline-secondary" size="sm" onClick={() => onCloneGroup(item)} className="clone">
                                        <FontAwesomeIcon icon={busy && busy.mode=="clone" && busy.group==item ? faSpinner:faClone} spin={busy && busy.mode=="clone" && busy.group==item} />
                                        Clone
                                    </Button>
                                </>
                            }
                            <Button variant="outline-danger" size="sm" onClick={() => onDeleteGroup(item)} className="delete">
                                <FontAwesomeIcon icon={busy && busy.mode=="delete" && busy.group==item ? faSpinner:faTrash} spin={busy && busy.mode=="delete" && busy.group==item} />
                            </Button>
                        </div>
                    </div>
                )
            })}

            {(custom && canEdit) && 
                <div className="add" onClick={() => setSelected({mode:"group", group:{ sign_id: sign.id }})}>
                    <FontAwesomeIcon icon={faPlus} size="3x" />
                    Add Group
                </div>
            }
        </Groups>
        {(selected && selected.mode == "partcolor") && 
            <ColorSelectDrawer
                show={true}
                part={selected.part}
                onColor={(color) => {
                    console.log("color", color)
                    setBusy(selected)

                    postData("signs/updatepartcolor", { sign_id: sign.id, group_id: selected.group.id, part_id: selected.part.id, color_id: color.id },
                        function(result) {
                            props.onSignChange(result);
                        },
                        function(error) {
                            alert("Error updating part.")
                        },
                        function() {
                            setBusy(null)
                        }
                    );

                    setSelected(null);
                }}
                onHide={() => setSelected(null)}
            />
        }
        {(selected && selected.mode == "browsefiles") && 
            <FilesSelectDrawer
                show={true}
                filters={{
                    project_id: sign.quote_id
                }}
                onFile={(file) => {
                    console.log("file", file)
                    setBusy({mode:"addingfile", group: selected.group, file})

                    postData("signs/addgraphic", { sign_id: sign.id, group_id: selected.group.id, file_id: file.id },
                        function(result) {
                            props.onSignChange(result);
                        },
                        function(error) {
                            alert("Error adding graphic.")
                        },
                        function() {
                            setBusy(null)
                        }
                    );

                    setSelected(null);
                }}
                onHide={() => setSelected(null)}
            />
        }
        {(selected && selected.mode == "group") && 
            <SignGroupDrawer
                show={true}
                group={selected.group}
                onSaved={(result) => {
                    props.onSignChange(result);
                    setSelected(null);
                }}
                onHide={() => setSelected(null)}
            />
        }
        {(selected && selected.mode == "power") && 
            <PowerGroupDrawer
                show={true}
                group={selected.group}
                groups={props.sign.groups}
                onSaved={(result) => {
                    props.onSignChange(result);
                    setSelected(null);
                }}
                onHide={() => setSelected(null)}
            />
        }       
        {(selected && selected.mode == "subshapes") && 
            <RelatedShapesDrawer
                show={true}
                group={selected.group}
                onSaved={(shapes) => {
                    setGroups(produce(draft => {
                        const index = draft.findIndex(g => g.id == selected.group.id);

                        draft[index].shapes[0].related = shapes;
                        draft[index].shapes[0].related_count = shapes.length;
                    }));

                    setSelected(null);

                    props.onSignChange();
                }}
                onHide={() => setSelected(null)}
            />
        }    
        {(selected && selected.mode == "shapesize") && 
            <ShapeSizeDrawer
                show={true}
                group={selected.group}
                size={selected.group.size}
                shape={selected.shape}
                style={{}}
                onSaved={(shape) => {
                    setGroups(produce(draft => {
                        const index = draft.findIndex(g => g.id == selected.group.id);
                        const shapeIndex = draft[index].shapes.findIndex(s => s.id == selected.shape.id);

                        draft[index].shapes[shapeIndex] = shape;
                    }));

                    setSelected(null);

                    props.onSignChange();
                }}
                onHide={() => setSelected(null)}
            />
        }  
        {(selected && selected.mode == "groupsize") && 
            <SignGroupSizeDrawer
                show={true}
                group={selected.group}
                onSaved={(data) => {
                    setSelected(null);

                    props.onSignChange();
                }}
                onHide={() => setSelected(null)}
            />
        }                             
        </>
    );
}

export default BoardSignGroups;