import React from "react";

import Field from "./field";
import Input from "./input";

function FieldUrl(props) {
    return (
        <Field 
            {...props}
            instructions="Enter full URL including the http:// or https:// prefix"
        >
            <Input 
                type="text" 
                step={props.step || 1}
                className="border-0 m-0 p-1 w-full"                
                {...props}
            />
        </Field>
    )
}

export default FieldUrl