import React, { Component } from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'

const DropzoneBox = styled.div`
    height: ${props => props.variant==="simple" ? "auto":"100px"};
    width: ${props => props.variant==="simple" ? "auto":"100px"};
    background-color: #fff;
    border: ${props => props.variant==="simple" ? "1px solid #ccc":"2px dashed rgb(187, 186, 186)"};
    border-radius: ${props => props.variant==="simple" ? "3px":"50%"};
    padding: ${props => props.size=="sm" ? "3px 6px" : props.variant==="simple" ? "5px 10px":"0px"};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${props => props.variant==="simple" ? "unset":"column"};
    font-size: ${props => props.size=="sm" ? 10 : 12}px;

    &.highlight {
        background-color: rgb(188, 185, 236);
    }
    svg {
        font-size: ${props => props.size=="sm" ? 15:24}px;
        margin-right: ${props => props.variant==="simple" ? 8:0}px;
    }
`;

const FileInput = styled.input`
    display: none;
`

class Dropzone extends Component {
  constructor(props) {
    super(props)
    this.state = { hightlight: false }
    this.fileInputRef = React.createRef()

    this.openFileDialog = this.openFileDialog.bind(this)
    this.onFilesAdded = this.onFilesAdded.bind(this)
    this.onDragOver = this.onDragOver.bind(this)
    this.onDragLeave = this.onDragLeave.bind(this)
    this.onDrop = this.onDrop.bind(this)
  }

  openFileDialog() {
    if (this.props.disabled) return
    this.fileInputRef.current.click()
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return

    const files = evt.target.files

    console.log("onFilesAdded dropzone", files);
    
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files)
      this.props.onFilesAdded(array)
    }
  }

  onDragOver(evt) {
    evt.preventDefault()

    if (this.props.disabled) return

    this.setState({ hightlight: true })
  }

  onDragLeave() {
    this.setState({ hightlight: false })
  }

  onDrop(event) {
    event.preventDefault()

    if (this.props.disabled) return

    const files = event.dataTransfer.files
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files)
      this.props.onFilesAdded(array)
    }
    this.setState({ hightlight: false })
  }

  fileListToArray(list) {
    const array = []
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i))
    }
    return array
  }

  render() {
    return (
      <DropzoneBox
        className={this.state.hightlight ? 'highlight' : ''}
        variant={this.props.variant || "normal"}
        size={this.props.size || "normal"}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
      >
        <FileInput
          ref={this.fileInputRef}
          type="file"
          multiple
          onChange={this.onFilesAdded}
        />

        <FontAwesomeIcon icon={faCloudUploadAlt} />

        <span>Upload {this.props.label || "Files"}</span>
      </DropzoneBox>
    )
  }
}

export default Dropzone