import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect"
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStickyNote } from '@fortawesome/free-solid-svg-icons'

import { useHistory, Link } from "react-router-dom";

import Loading from "../../components/loading"

import { postData } from "../../common/services/server"
import { formatDateTime } from "../../helpers/utility";
import GoogleImage from "../../components/google-image";
import NotesIcon from "../../components/notes-icon";

const Table = styled.table`
  width: 100%;
  font-size: 12px;

  th {
    background-color: #eee;
    padding: 5px 10px;
    border: 1px solid #ccc;
  }
  td {
    padding: 5px 10px;
    border: 1px solid #ccc;
    vertical-align: top;
  }
`

function WorkflowPendingJobsPanel(props) {
    const history = useHistory();

    const [orderItems, setOrderItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(
        () => {
            setIsLoading(true);

            postData("workflowjobs/pending", {},
                function(result) {
                    setOrderItems(result);
                },
                function(error) {
                    alert("Error loading pending jobs")
                },
                function() {
                    setIsLoading(false)
                }
            );
        }, 
        []
    );

    return (
        <>
            {
              isLoading 
            ?
              <Loading />
            :    
              <Table>
                <thead>
                  <tr>
                    <th>Order #</th>
                    <th>Notes</th>
                    <th>Order Date</th>
                    <th>Preview</th>
                    <th>Customer</th>
                    <th>Order Status</th>
                    <th>Order Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {orderItems.map((orderItem,index) => (
                      <tr key={index}>
                        <td>
                          <Link to={"/orders/order/items/" + orderItem.order_id}>
                            #{orderItem.order_id}
                          </Link>
                        </td>
                        <td>
                          <NotesIcon notes={orderItem.notes} />
                        </td>
                        <td>
                          {formatDateTime(orderItem.order_date)}
                        </td>
                        <td>
                          <GoogleImage root src={orderItem.preview_url} style={{width: "125px"}} />
                        </td>
                        <td>
                          <Link to={"/users/user/" + orderItem.user_id}>
                            {orderItem.user_name}
                          </Link>
                        </td>
                        <td>
                          {orderItem.status_title}
                        </td>
                        <td>
                          {orderItem.notes.map(note => (
                                <div key={note.id} style={{
                                    fontSize: "10px",
                                    backgroundColor: "#eee",
                                    color: "black",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    margin: "3px 0px",
                                    padding: "3px",
                                    textAlign: "left"
                                }}>
                                    {note.notes}
                                </div>
                          ))}                          
                        </td>
                      </tr>
                  ))}                  
                </tbody>
              </Table>
            }
        </>
    );
}

export default WorkflowPendingJobsPanel;