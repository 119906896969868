import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

import settings from "../../settings"
import { getJsonData } from "../../common/services/server";

export default function Select({
    type,
    name,
    size,
    required,
    readonly,
    disabled,
    isInvalid,
    value,
    items,
    itemsQuery,
    url,
    className,
    style,
    onChange,
    ...props
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(items || []);
  
    useEffect(
      () => {
        if (url && url.length > 0) {
          setIsLoading(true);
  
          let source = getJsonData(
            settings.API_URL + url,
            function(data) {
              setData(data);
  
              if (props.onData) {
                const updatedData = props.onData(data);
  
                if (updatedData)
                  setData(updatedData);
              }
            },
            function(error, status) {
              window.alert("Error loading dropdown data.")
            },
            function() {
              setIsLoading(false);
            }
          );
  
          return () => {
            if (source) source.cancel();
          };
        }
      }, 
      [url]
    );
  
    useEffect(
      () => {
        if (items)
          setData(items)
      }, 
      [items]
    );
  
    useEffect(
      () => {
        if (itemsQuery && itemsQuery.data)
          setData(itemsQuery.data)
      }, 
      [itemsQuery]
    );  

    return (
      isLoading ?
        <div className="flex items-center gap-2">
            <FontAwesomeIcon icon={faSpinner} spin />
            Loading...
        </div>
      :
        <select 
          name={name}
          required={required}
          value={value}
          disabled={disabled}
          readonly={readonly}
          className={clsx(
            "invalid:bg-red-100 invalid:text-red-500",
            className,
          )}
          style={style}
          onChange={(e) => {
            if (onChange)
                onChange(e, e.target.value, data.find(d => (props.idField ? d[props.idField] : d.id || d.value) == e.target.value)) 
        }}          
        >
            <option value="">{props.emptyLabel || ""}</option>
            {data.map((item, index) => {
              const id = props.idField ? item[props.idField] : item.id || item.value;

              return (
                <option 
                  key={id || index} 
                  value={id}
                >
                  {props.onLabel ? props.onLabel(item) : (item[props.labelField] || item.label)}
                </option>
              )
            })}
        </select>
    )
}
