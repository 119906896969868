import React, { useState, useEffect } from "react";
import styled from "styled-components"

import produce from "immer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSpinner, faPencilAlt, faSave } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

import Loading from "../../components/loading"
import GoogleImage from "../../components/google-image"
import Button from "../../components/buttons/button";

import { postData } from "../../common/services/server"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

const Styles = styled.div`
    h2 {
        font-size: 100%;
        padding: 5px;
        margin: 0px;
        background-color: #eee;
        border: 1px solid #ccc;
        border-radius: 3px;
    }
`
const DesignerFills = styled.div`
    display: flex;

    > div {
        margin: 5px;
        border: 1px solid #eee;
        padding: 5px;
        position: relative;

        button {
            background-color: white;
            position: absolute;
            right: 3px;
            bottom: 3px;
        }
    }
`

function DesignerFillsPanel(props) {
    const history = useHistory();

    const [designerFills, setDesignerFills] = useState([]);
    const [styles, setStyles] = useState([]);
    const [loading, setLoading] = useState("");
    const [busy, setBusy] = useState("");

    useEffect(
        () => {
            setLoading("designerfills");

            postData("facearts/designerfills", {},
                function(result) {
                    setDesignerFills(result);

                    let stylesData = []

                    for (const fill of result) {
                        if (stylesData.findIndex(s => s.id == fill.style_id) == -1) {
                            stylesData.push({ id: fill.style_id, name: fill.style_name });
                        }
                    }

                    setStyles(stylesData);
                },
                function(error) {
                    alert("Error loading designer fills")
                },
                function() {
                    setLoading("");
                }
            );
        }, 
        []
    );
   
    function onDelete(faceart) {
        postData("facearts/delete", {faceart_id: faceart.id},
            function(result) {
                setDesignerFills(produce(draft => {
                    const idx = draft.findIndex(f => f.id == faceart.id);
                    if (idx != -1) {
                        draft.splice(idx, 1);
                    }
                }));
            },
            function(error) {
                alert("Error deleting designer fill.")
            }
        );  
    }

    return (
        <>  
            { loading == "designerfills" ?
                <Loading size="sm" />            
            :
                <Styles>
                    {styles.map(style => (
                        <div>
                            <h2 key={style.id}>
                                {style.name}
                            </h2> 
                            <DesignerFills>
                                {designerFills.filter(f => f.style_id == style.id).map(faceart => (
                                    <div key={faceart.id}>
                                        <GoogleImage root src={faceart.url_thumb || faceart.url} style={{ width: "75px" }} />
                                        {userHasPermission(Permissions.FaceArt, Actions.Delete) &&
                                            <Button variant="outline-danger" size="sm" onClick={() => onDelete(faceart)}>
                                                <FontAwesomeIcon icon={busy == faceart.id ? faSpinner: faTrash} spin={busy == faceart.id} />
                                            </Button>
                                        }
                                    </div>
                                ))}                            
                            </DesignerFills>
                        </div>
                    ))}
                </Styles>
            }               
        </>
    );
}

export default DesignerFillsPanel;