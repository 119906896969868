import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faDesktop, faPaperclip, faUser } from "@fortawesome/free-solid-svg-icons";
import { differenceInMinutes, formatDistanceToNow, parseISO } from "date-fns";
import { clsx } from "clsx";

import Panel from "./panel";
import ZoomableImage from "../components/image-zoomable";
import DropdownMenu from "../components/dropdownmenu";

import { useGetSignChats, useGetSignChat } from "../api/queries/signs";
import storage from "../settings/storage";
import Button from "../components/buttons/button";
import DrawerTemplateData from "../drawers/template-data";
import { getIconForType } from "../helpers/icons";
import { formatDateTime } from "../helpers/utility";

function ChatDrawer({
    signId, 
    onHide
}) {
    const [selected, setSelected] = useState([]);

    const chatQuery = useGetSignChat(signId);    
    const messsages = chatQuery.data;

    return (
        <DrawerTemplateData
            icon={getIconForType("chat")}
            title="Sign Chat"
            query={chatQuery}
            onHide={onHide}
        >
            <div className="space-y-2">
                {(messsages || []).map(message => {
                    
                    return (
                        <div key={message.id} className={clsx(
                            "flex flex-col",
                            message.role == "admin" && "items-start",
                            message.role == "customer" && "justify-end items-end"
                        )}>
                            <div className={clsx(
                                    "border rounded-md p-1",
                                    (message.message == "Admin" || message.message == "Sign" || message.message == "Saved")  && "!bg-white",
                                    message.role == "admin" && "bg-purple-200",
                                    message.role == "customer" && "bg-gray-200"
                            )}>
                                { message.message == "Admin" ? 
                                    <div className="flex items-center gap-1">
                                        <FontAwesomeIcon icon={faUser} />
                                        {message.user_name} Joined Chat
                                    </div>
                                : message.message == "Sign" ?
                                    "Sent Sign Changes"
                                : message.message == "Saved" ? 
                                    "Saved Sign"
                                : message.file_id ?
                                    <a href={storage.root + message.url} target="_blank" className="text-blue-500 hover:underline">
                                        <FontAwesomeIcon icon={faPaperclip} className="mr-1" />
                                        {message.filename}
                                    </a>
                                :
                                    message.message
                                }
                            </div>
                            <div className="text-xs text-gray-500">
                                {formatDateTime(message.added_date)}
                            </div>
                        </div>
                    )
                })}
            </div>
        </DrawerTemplateData>
    )
}

export default function SignChatsPanel({
}) {
    const [signId, setSignId] = useState(null);

    const signsQuery = useGetSignChats();
    const signs = signsQuery.data;

    return (
      <>
        <Panel query={signsQuery}>
            {signs &&
                <div className="space-y-2">
                    {signs.map(sign => {
                        const minutes = differenceInMinutes(new Date(), parseISO(sign.updated_date || sign.added_date));

                        return (
                        <div key={sign.id} className={clsx(
                            "relative border rounded p-1",
                            minutes < 5 && "!border-red-500"
                        )}>
                            <div className="grid md:grid-cols-[210px,1fr,auto] gap-3">
                                <div>
                                    <ZoomableImage
                                        url={storage.root + sign.preview_url}
                                        maxHeight="100px"
                                        width="200px"
                                    />
                                </div>
                                <div className="space-y-1">
                                    <button 
                                        className="block text-blue-500 hover:underline"
                                        onClick={() => {
                                            window.open("#/signs/sign/" + sign.id)
                                        }}
                                    >
                                        Design #{sign.id}
                                    </button>
                                    {sign.user_id && 
                                        <button 
                                            className="block text-sm text-blue-500 hover:underline"
                                            onClick={() => {
                                                window.open("#/users/user/" + sign.user_id)
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faUser} className="mr-1" />
                                            {sign.user_name || sign.user_email || sign.user_id}
                                        </button>
                                    }
                                    <div className="text-gray-400 space-x-1">
                                        <span>Last Chat:</span>
                                        <span className={clsx(
                                            minutes < 5 && "text-red-500",
                                        )}>
                                            {formatDistanceToNow(parseISO(sign.updated_date || sign.added_date))}
                                        </span>
                                        <span>ago</span>
                                    </div>
                                </div>
                                <div className="self-center md:p-2 flex gap-1">
                                    <Button
                                        className="whitespace-nowrap"
                                        onClick={() => setSignId(sign.id)}
                                    >
                                        {sign.message_count}
                                        &nbsp;
                                        Message{sign.message_count == 1 ? "":"s"}
                                    </Button>
                                    <Button
                                        className="whitespace-nowrap"
                                        onClick={() => window.location=`#/interactivebuilder/${sign.id}`}
                                    >
                                        <FontAwesomeIcon icon={faDesktop} />
                                    </Button>                                    
                                </div>
                            </div>
                        </div>
                        )
                    })}   
                </div>
            }          
        </Panel>

        { signId && 
            <ChatDrawer
                signId={signId}
                onHide={ () => setSignId(null) } 
            />             
        }
      </>
    );
}