import React from "react"
import { Link } from "react-router-dom";

import BoardField from "../components/board/board-field";
import BoardPills from "../components/board/board-pills";
import BoardPill from "../components/board/board-pill";
import Panel from "./panel";

import { useGetUser } from "../api/queries/users";
import { useSetUserProp } from "../api/mutations/users";

export default function UserDetailsPanel({
    userId,
}) {
    const userQuery = useGetUser(userId);
    const user = userQuery.data;

    const setFirstName = useSetUserProp(userId, "first_name");
    const setLastName = useSetUserProp(userId, "last_name");
    const setEmail = useSetUserProp(userId, "email");
    const setPhoneNo = useSetUserProp(userId, "phoneno");

    return (
        <Panel query={userQuery}>
            {user &&
                <>
                    <BoardField
                        label="ID"
                        type="children">
                        <Link to={"/users/user/" + user.id} className="text-blue-500">
                            #{user.id}
                        </Link>
                    </BoardField>
                    <BoardField
                        label="First Name"
                        type="text"
                        value={user.first_name}
                        saveMutation={setFirstName}
                    />
                    <BoardField
                        label="Last Name"
                        type="text"
                        value={user.last_name}
                        saveMutation={setLastName}
                    />
                    <BoardField
                        label="Email"
                        type="email"
                        value={user.email}
                        saveMutation={setEmail}
                    />   
                    <BoardField
                        label="Phone No"
                        type="phone"
                        value={user.phoneno}
                        saveMutation={setPhoneNo}
                    />   

                    {/* <div style={{margin: "10px 4px"}}>
                        <BoardPills>
                            <BoardPill count={user.sign_count} countType="Sign" />    
                            <BoardPill count={user.note_direct_count} countType="Note" />    
                            <BoardPill count={user.project_count} countType="Project" />    
                            <BoardPill 
                                count={user.cart_count} 
                                countType="Cart" 
                            />    
                            <BoardPill 
                                count={user.order_count} 
                                countType="Order" 
                            /> 
                        </BoardPills>
                    </div>                                                                                                  */}
                </>
            }
        </Panel>
    );
}