import React, { useState } from "react";
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSpinner, faSave, faInfoCircle, faDollarSign, faListAlt } from "@fortawesome/free-solid-svg-icons";
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { isMobile } from "react-device-detect"

import PropertyCols from "../../components/properties/property-cols"
import PropertyList from "../../components/properties/property-list"
import Property from "../../components/properties/property"
import GoogleImage from "../../components/google-image"
import SignPriceDrawer from "../../drawers/signprice"
import FilesSelectDrawer from "../../drawers/drawer-fileselect"

import UserSelect from "../../components/fields/user-select"
import ListField from "../../components/fields/field-list"
import Button from "../../components/buttons/button";
import UploadButton from "../../components/buttons/button-upload"

import { formatPrice, formatDateTime } from "../../helpers/utility"
import GoogleLink from "../../components/google-link";
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

const Preview = styled.div`
  margin-bottom: 15px;
  display: flex;

  > div {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    min-height: 100px;

    flex: 1;
      flex-basis: 50%;
      position: relative;

      .buttons {
          display: flex;
          position: absolute;
          right: 10px;
          bottom: 10px;

          button {
            padding: 2px 7px;
            margin-left: 5px;              
          }
      }
  }
  > div:nth-child(1) {
      margin-right: 10px;
  }

  div.technical {
      > div {
          display: flex;

          a {
              flex: 1;
          }
      }
  }

  img {
    display: block;
    margin: 0px auto;
    max-height: 200px;
  }
`

function SignOverviewPanel(props) {
    const history = useHistory();

    const [selected, setSelected] = useState(null);

    const { sign, stats } = props;

    const [canEdit, setCanEdit] = useState(userHasPermission(Permissions.Signs, Actions.Edit))
 
    //console.log(moment.utc(sign.estimate_expire_date));
    //console.log(moment.now())

    function onPreviewUploaded(result) {
        //props.onSignChanged(result);
        props.onSignPropChange("preview_url", result.preview_url)
    }
    function onTechnicalUploaded(result) {
        //props.onSignChanged(result);
        props.onSignPropChange("technical_filename", result.technical_filename)
        props.onSignPropChange("technical_url", result.technical_filename)
    }

    return (
    <>
        <Preview>
            <div>
                <GoogleImage root src={sign.preview_url} />

                <div className="buttons">
                    {sign.quote_id && 
                        <Button onClick={() => setSelected({mode:"browsefiles", type:"preview"})} size="sm" disabled={!canEdit} variant="secondary">
                            <FontAwesomeIcon icon={props.busy && props.busy.preview_file_id ? faSpinner:faListAlt} spin={props.busy && props.busy.preview_file_id} />{' '}
                            Browse
                        </Button>
                    }
                    <UploadButton
                        uploadUrl={"signs/uploadpreview?sign_id=" + sign.id}
                        label={(sign.custom_ind == 1 ? "Upload" : "Override") + " Preview Image"}
                        extensions={["jpg","png"]}
                        disabled={!canEdit}
                        onUpload={onPreviewUploaded}
                    /> 
                </div>
            </div>

            {sign.custom_ind == 1 &&
                <div className="technical">
                    <h6>Technical File</h6>

                    {sign.technical_filename && 
                        <div>
                            <GoogleLink root src={sign.technical_url} label={sign.technical_filename} />
                        </div>
                    }

                    <div className="buttons">
                        {sign.quote_id && 
                            <Button onClick={() => setSelected({mode:"browsefiles", type:"technical"})} size="sm" disabled={!canEdit} variant="secondary">
                                <FontAwesomeIcon icon={props.busy && props.busy.technical_file_id ? faSpinner:faListAlt} spin={props.busy && props.busy.technical_file_id} />{' '}
                                Browse
                            </Button>
                        }
                        <UploadButton
                            uploadUrl={"signs/uploadtechnical?sign_id=" + sign.id}
                            label="Upload Technical File"
                            disabled={!canEdit}
                            onUpload={onTechnicalUploaded}
                        /> 
                    </div>
                </div>
            }
        </Preview>

        <PropertyCols>
            <PropertyList maxWidth={"500px"}>
                <Property label="Builder Sign?">
                    <Form.Check 
                        type="radio"
                        value={1}
                        checked={sign.custom_ind == 1}
                        disabled={!canEdit}
                        label="No"
                        inline
                        onChange={(e) => {
                            console.log("custom no clicked", e.target.checked)
                            if (e.target.checked)
                                props.onSaveSignProp("custom_ind", 1);
                        }}
                    />
                    &nbsp;
                    <Form.Check 
                        type="radio"
                        value={0}
                        checked={sign.custom_ind != 1}
                        disabled={!canEdit}
                        label="Yes"
                        inline
                        onChange={(e) => {
                            console.log("custom yes clicked", e.target.checked)

                            if (e.target.checked)
                                props.onSaveSignProp("custom_ind", 0);
                        }}
                    />

                    {(props.busy && props.busy.custom_ind) && <FontAwesomeIcon icon={faSpinner} spin />}

                    {sign.custom_groups_ind == 1 && 
                        <div>
                            <Badge size="sm" variant="danger"> 
                                Sign contains custom shapes
                            </Badge>
                        </div>
                    }
                </Property>
                <Property label="Title">
                    <InputGroup>
                        <FormControl
                            value={sign.title || ""}
                            disabled={!canEdit}
                            onChange={(event) => props.onSignPropChange("title", event.target.value) }
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    props.onSaveSignProp("title");
                                }
                            }}                    
                        />

                        <InputGroup.Append>
                            <Button onClick={() => props.onSaveSignProp("title")} disabled={!canEdit} variant={props.dirty.title ? "primary":"outline-secondary"} size="sm">
                                <FontAwesomeIcon icon={props.busy && props.busy.title ? faSpinner:faSave} spin={props.busy && props.busy.title} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Property>
                <Property label="User">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faUser} onClick={() => history.push("/users/user/" + sign.user_id)} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <UserSelect
                            name="user"
                            disabled={!canEdit}
                            default={{
                                id: sign.user_id,
                                name: sign.user_name || "",
                                email: sign.user_email || ""
                            }}
                            onChange={(user) => {
                                props.onSignPropChange("user_id", user ? user.id : null);
                                props.onSignPropChange("user_name", user ? user.name : "");
                                props.onSignPropChange("user_email", user ? user.email : "");
                            }}
                        />

                        <InputGroup.Append>
                            <Button onClick={() => props.onSaveSignProp("user_id")} disabled={!canEdit} variant={props.dirty.user_id ? "primary":"outline-secondary"} size="sm">
                                <FontAwesomeIcon icon={props.busy && props.busy.user ? faSpinner:faSave} spin={props.busy && props.busy.user} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Property>
                {sign.custom_ind == 0 && <Property label="Size" value={sign.size_height + '" x ' + sign.size_width + '"'} />}
                {sign.custom_ind == 1 &&
                    <Property label="Size" danger={!sign.size_height || !sign.size_width || sign.size_height == 0 || sign.size_width == 0}>
                        <div style={{display:"flex"}}>
                            <div>
                                Height<br />
                                <InputGroup>
                                    <FormControl
                                        value={sign.size_height || "0"}
                                        disabled={!canEdit}
                                        isInvalid={!sign.size_height || sign.size_height == 0}
                                        rows={3}
                                        onChange={(event) => props.onSignPropChange("size_height", event.target.value) }            
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            "
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                            <div>
                                Width<br />
                                <InputGroup>
                                    <FormControl
                                        value={sign.size_width|| "0"}
                                        disabled={!canEdit}
                                        isInvalid={!sign.size_width || sign.size_width == 0}
                                        rows={3}
                                        onChange={(event) => props.onSignPropChange("size_width", event.target.value) }            
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            "
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                        <Button onClick={() => { props.onSaveSignProp("size_height"); props.onSaveSignProp("size_width"); }} disabled={!canEdit} variant={props.dirty.size_height || props.dirty.size_width ? "primary":"outline-secondary"}>
                                            <FontAwesomeIcon icon={props.busy && props.busy.size_height ? faSpinner:faSave} spin={props.busy && props.busy.size_height} />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                        </div>
                    </Property>
                }
                <Property label="Area" value={sign.area + " sq ft"} danger={!sign.area || sign.area == 0} />
                <Property 
                    label="Cost" 
                    value={
                        <>
                            {formatPrice(sign.estimate)}
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            
                            <Button variant="outline-success" size="tiny" disabled={!canEdit} onClick={props.onRecalculatePrice}>
                                <FontAwesomeIcon icon={faDollarSign} />{' '}
                                Recalculate
                            </Button>

                            &nbsp;
                            <Button variant="outline-secondary" size="tiny" onClick={() => setSelected({mode: "price"})}>
                                <FontAwesomeIcon icon={faInfoCircle} />{' '}
                                Calculation Data
                            </Button>

                            {(sign.custom_groups_ind == 1 && sign.custom_approved_ind == 0) && 
                                <div>
                                    <Badge size="md" variant="danger"> 
                                        Sign contains custom shapes that need review and approval
                                    </Badge>
                                </div>
                            }                            
                        </>
                    }
                    danger={!sign.estimate || sign.estimate <= 0}
                />               
                <Property label="Cost Expiration">
                    <InputGroup>
                        <FormControl
                            type="date"
                            disabled={!canEdit}
                            value={sign.estimate_expire_date ? moment.utc(sign.estimate_expire_date).format('YYYY-MM-DD') : ""}
                            onChange={(event) => props.onSignPropChange("estimate_expire_date", event.target.value) }            
                        />
                        {sign.estimate_expire_date && 
                            <InputGroup.Append>
                                <InputGroup.Text style={{color: moment.utc(sign.estimate_expire_date).diff(moment.now(), 'days') < 0 ? "red":"black"}}>
                                    {moment.utc(sign.estimate_expire_date).diff(moment.now(), 'days')+1} Days
                                </InputGroup.Text>
                            </InputGroup.Append>
                        }
                        <InputGroup.Append>
                            <Button onClick={() => props.onSaveSignProp("estimate_expire_date")} disabled={!canEdit} variant={props.dirty.estimate_expire_date ? "primary":"outline-secondary"} size="sm">
                                <FontAwesomeIcon icon={props.busy && props.busy.estimate_expire_date ? faSpinner:faSave} spin={props.busy && props.busy.estimate_expire_date} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Property>
                <Property 
                    label="Discount" 
                    value={formatPrice(sign.discount)}
                />
                <Property label="Description" styles={{display:"block"}}>
                    <div dangerouslySetInnerHTML={{__html: sign.description}} style={{marginBottom:"5px"}} />
                    <Button variant="outline-success" disabled={!canEdit} onClick={props.onRegenerateDesc} size="tiny">
                        <FontAwesomeIcon icon={props.busy && props.busy.description ? faSpinner:faListAlt} spin={props.busy && props.busy.description} />{' '}
                        Regenerate
                    </Button>
                </Property>
                <Property label="Options" sublabel="(Customer Can See)">
                    <InputGroup>
                        <FormControl
                            as="textarea" 
                            value={sign.options || ""}
                            disabled={!canEdit}
                            rows={3}
                            onChange={(event) => props.onSignPropChange("options", event.target.value) }            
                        />

                        <InputGroup.Append>
                            <Button onClick={() => props.onSaveSignProp("options")} disabled={!canEdit} variant={props.dirty.options ? "primary":"outline-secondary"} size="sm">
                                <FontAwesomeIcon icon={props.busy && props.busy.options ? faSpinner:faSave} spin={props.busy && props.busy.options} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Property>
                <Property label="Options Notes" sublabel="(Only Admins Can See)">
                    <InputGroup>
                        <FormControl
                            as="textarea" 
                            value={stats.note || ""}
                            disabled={!canEdit}
                            rows={3}
                            onChange={(event) => props.onSignPropChange("note", event.target.value) }            
                        />

                        <InputGroup.Append>
                            <Button onClick={() => props.onSaveSignProp("note")} disabled={!canEdit} variant={props.dirty.note ? "primary":"outline-secondary"} size="sm">
                                <FontAwesomeIcon icon={props.busy && props.busy.note ? faSpinner:faSave} spin={props.busy && props.busy.note} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Property>                
                <Property label="Options Cost">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faDollarSign} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            value={sign.options_cost || 0}
                            disabled={!canEdit}
                            onChange={(event) => props.onSignPropChange("options_cost", event.target.value) }
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    props.onSaveSignProp("options_cost");
                                }
                            }}                    
                        />
                        <InputGroup.Append>
                            <Button onClick={() => props.onSaveSignProp("options_cost")} disabled={!canEdit} variant={props.dirty.options_cost ? "primary":"outline-secondary"} size="sm">
                                <FontAwesomeIcon icon={props.busy && props.busy.options_cost ? faSpinner:faSave} spin={props.busy && props.busy.options_cost} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>

                    {(parseInt(sign.options_cost) == 0 && parseInt(sign.template_options_cost) != 0) && 
                        <div style={{marginTop: "8px"}}>
                            Template sign had an options cost of <strong style={{color: "red"}}>${parseFloat(sign.template_options_cost)}</strong>.

                            <div>
                                <Button 
                                    size="tiny"
                                    variant="outline-primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        props.onSaveSignProp("options_cost", parseFloat(sign.template_options_cost))
                                    }}
                                >
                                    Apply Cost
                                </Button>
                            </div>
                        </div>
                    }
                </Property>
            </PropertyList>

            <div style={{flex: 1}}>
                <PropertyList maxWidth={"500px"}>
                    <Property label="Unique ID" value={sign.uid} />
                    {sign.template_ind == 1 && 
                        <Property label="Template" value="Yes" /> 
                    }
                    {sign.template_ind == 1 && 
                        <Property label="Template Properties">
                            <div>
                                {(props.busy && props.busy.prop_nofacecolor_ind) && <FontAwesomeIcon icon={faSpinner} spin />}
                                <Form.Check 
                                    type="checkbox"
                                    value={1}
                                    disabled={!canEdit}
                                    checked={sign.prop_nofacecolor_ind == 1}
                                    label="Face Color Not Editable"
                                    inline
                                    onChange={(e) => {
                                        console.log("template prop clicked", e.target.checked)
                                        props.onSaveSignProp("prop_nofacecolor_ind", e.target.checked ? 1:0);
                                    }}
                                />
                            </div>                        
                        </Property> 
                    }
                    {sign.quote_id && 
                        <Property label="Project" value={sign.quote_id} url={"/projects/project/"+sign.quote_id} />
                    }
                </PropertyList>
                <br />
                <PropertyList maxWidth={"500px"}>
                    <Property label="Shipping Method">
                        <InputGroup>
                            <ListField 
                                name="shipping_method_id" 
                                value={sign.shipping_method_id || 0} 
                                list={[
                                    {value: -1, label: "Auto"},
                                    {value: 1, label: "UPS"},
                                    {value: 2, label: "Freight"},
                                    {value: 3, label: "Pickup"},
                                    {value: 4, label: "Other"},
                                ]}
                                onChange={(e) => {
                                    props.onSignPropChange("shipping_method_id", e.target.value)
                                }} 
                                groupClassName="nomargin"
                            />

                            <InputGroup.Append>
                                <Button onClick={() => props.onSaveSignProp("shipping_method_id")} disabled={!canEdit} variant={props.dirty.shipping_method_id ? "primary":"outline-secondary"} size="sm">
                                    <FontAwesomeIcon icon={props.busy && props.busy.shipping_method_id ? faSpinner:faSave} spin={props.busy && props.busy.shipping_method_id} />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>

                        {sign.shipping_method_override_date && 
                            <div>
                                <Badge size="md" variant="warning"> 
                                    Shipping method was manually selected on {formatDateTime(sign.shipping_method_override_date)}
                                </Badge>
                            </div>
                        }   
                    </Property>                    
                    {/* {sign.shipping_method_override_id ? 
                    :
                        <Property label="Shipping Method" value={sign.shipping_method} />
                    } */}
                    <Property label="Shipping Adjustment">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                value={sign.shipping_adjustment || 0}
                                disabled={!canEdit}
                                onChange={(event) => props.onSignPropChange("shipping_adjustment", event.target.value) }
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        props.onSaveSignProp("shipping_adjustment");
                                    }
                                }}                    
                            />
                            <InputGroup.Append>
                                <Button onClick={() => props.onSaveSignProp("shipping_adjustment")} disabled={!canEdit} variant={props.dirty.shipping_adjustment ? "primary":"outline-secondary"} size="sm">
                                    <FontAwesomeIcon icon={props.busy && props.busy.shipping_adjustment ? faSpinner:faSave} spin={props.busy && props.busy.shipping_adjustment} />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Property>                    
                    <Property label="Shipping Estimate">
                        {formatPrice(sign.shipping || 0)} (base + parts + adjustment)
                        <div><small>* signs saved after 7/8/2022</small></div>
                    </Property>
                    <Property label="Shipping Boxes" value={sign.shipping_boxes} />
                </PropertyList>
                <br />
                <PropertyList maxWidth={"500px"}>
                    <Property label="Created Date" value={formatDateTime(sign.added_date_local)} />
                    <Property label="Created By" value={sign.added_user || "Anonymous"} />
                    <Property label="Created From" value={sign.added_location} />

                    {sign.device && 
                        <>
                            <Property label="Created Device" value={sign.device} />
                            <Property label="Created OS" value={sign.os} />
                            <Property label="Created Browser" value={sign.browser} />
                        </>
                    }
                    {sign.ipaddress && 
                        <Property label="Created IP" value={sign.ipaddress} />
                    }

                    {sign.template_sign_id && 
                        <Property label="Template Sign" value={sign.template_sign_id} url={"/signs/sign/"+sign.template_sign_id} />
                    }
                    {sign.updated_date && <Property label="Last Updated Date" value={formatDateTime(sign.updated_date)} />}
                    {sign.updated_user && <Property label="Last Updated By" value={sign.updated_user} />}
                </PropertyList>
            </div>
        </PropertyCols>

        { selected && selected.mode == "price" && 
            <SignPriceDrawer 
                signId={sign.id} 
                show={true}
                onHide={ () => setSelected(null) } 
            /> 
        }
        {(selected && selected.mode == "browsefiles") && 
            <FilesSelectDrawer
                show={true}
                filters={{
                    project_id: sign.quote_id
                }}
                onFile={(file) => {
                    console.log("file", file)
                    if (selected.type == "preview") {
                        props.onSaveSignProp("preview_file_id", file.id);
                        props.onSignPropChange("preview_url", file.preview_url)
                    }
                    else if (selected.type == "technical") {
                        props.onSaveSignProp("technical_file_id", file.id);
                        props.onSignPropChange("technical_filename", file.filename)
                        props.onSignPropChange("technical_url", file.url)                
                    }

                    setSelected(null);
                }}
                onHide={() => setSelected(null)}
            />
        }
    </>
    );
}

export default SignOverviewPanel;