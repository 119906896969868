import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"

import Form from 'react-bootstrap/Form';
import InputGroup from  'react-bootstrap/InputGroup';

import { 
    setKey,
    setLanguage,
    fromAddress
} from "react-geocode";

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faSave } from '@fortawesome/free-solid-svg-icons'

import TextAreaField from "../components/fields/field-textarea"
import TextField from "../components/fields/field-text"
import JobSelect from "../components/fields/job-select"
import { Accordion, AccordionItem } from "../components/accordion";

import GoogleImage from "../components/google-image"
import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { deepClone, removeNulls } from '../helpers/utility'
import { google } from "../settings/settings"

import Loading from "../components/loading";
import { useGetPhotoCategories, useGetPhotoPages, useGetPhotoProducts, useGetPhotosFromIds } from "../api/queries/photos";
import DrawerTemplateData from "./template-data";
import Alert from "../components/alert";
import ZoomableImage from "../components/image-zoomable";
import storage from "../settings/storage";
import FieldLabel from "../components/fields/field-label";
import Field from "../components/fields/field";
import FieldJob from "../components/fields/field-job";

setKey(google.googleMapsKey);
setLanguage("en");

const CheckboxList = styled.div`
    padding: 10px;
    font-size: 14px;
    margin-bottom: 8px;
    max-height: 400px;
    overflow: auto;
`

function PhotoDrawer({
    photoIds,
    onSaved,
    onHide,
}) {
  const formRef = useRef(null);

  const photosQuery = useGetPhotosFromIds(photoIds);
  const photos = photosQuery.data;
  
  const {data:categories, isLoading:categoriesLoading} = useGetPhotoCategories();
  const {data:pages, isLoading:pagesLoding} = useGetPhotoPages();
  const {data:products, isLoading:productsLoading} = useGetPhotoProducts();
  
  const [isProcessing, setIsProcessing] = useState(false);
  const [loadingMap, setLoadingMap] = useState(false);
  const [formData, setFormData] = useState(null);
  const [validated, setValidated] = useState(false);

  useEffect(
    () => {
        if (!photos || photos.length==0) return;

        let photo = photos[0];

        setFormData({
            ...photo,
            categories: photo.category_ids?.split(",").map(c => parseInt(c)) ?? [],
            products: photo.product_ids?.split(",").map(p => parseInt(p)) ?? [],
            pages: photo.page_ids?.split(",").map(p => parseInt(p)) ?? [],
        });
    }, 
    [photos]
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      let {...data} = formData;

      postData("photos/save?ids=" + (photos ? photos.map(p => p.id).join(",") : ""), removeNulls(data), 
        function(response) {
          onSaved(response);
        },
        function(error) {
          alert("An error occured saving the photo(s).");
        },
        function() {
            setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  function onCategoryChange(e) {
    const category = parseInt(e.target.value);
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.categories ? draft.categories.indexOf(category) : -1;

        if (index != -1)
            draft.categories.splice(index, 1);
        if (!draft.categories)
            draft.categories = [];
        if (checked)
            draft.categories.push(category);
    }));
  }
  function onProductChange(e) {
    const product = parseInt(e.target.value);
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.products ? draft.products.indexOf(product) : -1;

        if (index != -1)
            draft.products.splice(index, 1);
        if (!draft.products)
            draft.products = [];
        if (checked)
            draft.products.push(product);
    }));
  }
  function onPageChange(e) {
    const page = parseInt(e.target.value);
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.pages ? draft.pages.indexOf(page) : -1;

        if (index != -1)
            draft.pages.splice(index, 1);
        if (!draft.pages)
            draft.pages = [];
        if (checked)
            draft.pages.push(page);
    }));
  }
  function onSignMapChange(e) {
    const checked = e.target.checked;

    setFormData(produce(draft => {
        draft.map_ind = checked ? 1:0;
    }));

    if (checked && !formData.maplocation_title) {
        setLoadingMap(true);

        postData("map/jobdata?id=" + formData.job_id, {}, 
            async function(response) {
                if (response && response.address) {
                    // default to coords already stored
                    let lat = response.latitude;
                    let lng = response.longitude;

                    // if not coords, then look them up
                    if (!response.latitude) {
                        const location = await fromAddress(response.address + " " + response.city + " " + response.state + " " + response.zipcode);    

                        lat = location.results[0].geometry.location.lat;
                        lng  = location.results[0].geometry.location.lng;        
                    }

                    setFormData(produce(draft => {
                        draft.maplocation_id = response.id;
                        draft.maplocation_title = response.title;
                        draft.address = response.address;
                        draft.city = response.city;
                        draft.state = response.state;
                        draft.zipcode = response.zipcode;

                        draft.lat = lat;
                        draft.lng = lng;
                    }))
                }
            },
            function(error) {
             alert("An error occured loading map data.");
            },
            function() {
                setLoadingMap(false);
            }
        );
    }
  }

  return (
    <DrawerTemplateData
        icon={faImage}
        title={`Update Photo${photoIds.length != 1 ?"s":""}`}
        query={photosQuery}
        noDataFound={photosQuery.data?.length == 0}
        buttons={
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption={"Save Photo" + (photos && photos.length > 1 ? "s":"")} 
                icon={faSave} 
            /> 
        }
        onHide={onHide}
    >
        {(photos && formData) &&
            <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef} className="grid gap-2">
                {(photos && photos.length > 0) &&
                    <div className="flex gap-2">
                        {photos.map(photo => (
                            <ZoomableImage
                                key={photo.id} 
                                url={storage.root + photo.url_thumb} 
                                zoomUrl={storage.root + photo.url} 
                                maxHeight="100px"
                            />
                        ))}
                    </div>
                }

                {(photos && photos.length > 1) && 
                    <Alert size="sm" variant="danger">
                        Multiple photos selected.  When saved all of the information entered below will be applied to all of the photos.
                    </Alert>
                }

                <TextField 
                    name="title" 
                    prepend="Title *" 
                    prependClassName="w-[100px]"
                    instructions="Also used for the ALT tag"
                    required
                    value={formData.title || ""} 
                    onChange={(e) => handleFormChange(e)} />
                <TextAreaField 
                    name="description" 
                    prepend="Description" 
                    prependClassName="w-[100px]"
                    value={formData.description || ""} 
                    rows={2}
                    onChange={(e) => handleFormChange(e)} />
                <TextAreaField 
                    name="keywords" 
                    prepend="Keywords" 
                    prependClassName="w-[100px]"
                    value={formData.keywords || ""} 
                    rows={3}
                    onChange={(e) => handleFormChange(e)} />

                <FieldJob
                    prepend="Job #"
                    prependClassName="w-[100px]"
                    value={formData.job_id}
                    jobQueryOptions={{
                        include_maplocation_ind: 1
                    }}
                    onChange={(e, job) => {
                        console.log("job changed", job)

                        if (job) {
                            setFormData(produce(draft => {
                                draft.job_id = job.id;
                                draft.job_title = job.title;

                                // if (job.products) {
                                //     console.log("job products", job.proucts, job.products.split(","))
                                //     draft.products = job.products.split(",");
                                // }

                                draft.maplocation_id = job.mapLocation.id;
                                draft.map_ind = 0;
                                draft.address = job.mapLocation?.address;
                                draft.city = job.mapLocation?.city;
                                draft.state = job.mapLocation?.state;
                                draft.zipcode = job.mapLocation?.zipcode;
                            }));
                        }                    
                    }}
                />

                {(formData.address && formData.address.length > 0) && 
                    <div style={{marginBottom: "8px"}}>
                        <div>
                            <Form.Check 
                                name="type"                                
                                defaultValue={formData.maplocation_id}
                                checked={formData.map_ind == 1}
                                inline 
                                label="Add Photo To Sign Map"
                                type="checkbox" 
                                onChange={onSignMapChange}
                            />
                        </div>
                        {formData.map_ind == 1 &&
                            <div style={{margin: "5px 0px 15px 0px"}}>
                                {loadingMap ?
                                    <Loading size="sm" />
                                :
                                    <>
                                        <TextField 
                                            prepend="Map Title"
                                            prependClassName="w-[100px]"
                                            name="maplocation_title" 
                                            required
                                            value={formData.maplocation_title} 
                                            onChange={(e) => handleFormChange(e)} />

                                        <FieldLabel
                                            prepend="Map Address"
                                            prependClassName="w-[100px]"
                                        >
                                            <div>{formData.address}</div>
                                            <div>{formData.city} {formData.state}, {formData.zipcode}</div>
                                        </FieldLabel>
                                    </>
                                }
                            </div>
                        }
                    </div>
                }
                
                <Accordion>
                    <AccordionItem
                        title="Categories"
                        disabled={categoriesLoading || !categories}
                        count={formData.categories.length}
                    >
                        <CheckboxList>
                        {categories?.filter(c => c.title != "[Uncategorized]").map(category => (
                            <div key={category.id}>
                                <Form.Check 
                                    name="category"                                
                                    defaultValue={category.id}
                                    checked={formData.categories.includes(category.id)}
                                    inline 
                                    label={category.title} 
                                    type="checkbox" 
                                    onChange={onCategoryChange}
                                />
                            </div>
                        ))}
                        </CheckboxList>
                    </AccordionItem>
                    <AccordionItem
                        title="Products"
                        disabled={productsLoading || !products}
                        count={formData.products.length}
                    >
                        <CheckboxList>
                        {products?.map(product => (
                            <div key={product.id}>
                                <Form.Check 
                                    name="type"                                
                                    defaultValue={product.id}
                                    checked={formData.products.includes(product.id)}
                                    inline 
                                    label={product.title} 
                                    type="checkbox" 
                                    onChange={onProductChange}
                                />
                            </div>
                        ))}
                        </CheckboxList>
                    </AccordionItem>
                    <AccordionItem
                        title="Landing Pages"
                        disabled={pagesLoding || !pages}
                        count={formData.pages.length}
                    >
                        <CheckboxList>
                        {pages?.map(page => (
                            <div key={page.id}>
                                <Form.Check 
                                    name="type"                                
                                    defaultValue={page.id}
                                    checked={formData.pages.includes(page.id)}
                                    inline 
                                    label={page.title} 
                                    type="checkbox" 
                                    onChange={onPageChange}
                                />
                            </div>
                        ))}
                        </CheckboxList>
                    </AccordionItem>
                </Accordion>
            </Form>
        }
    </DrawerTemplateData>
  )
}

export default PhotoDrawer;