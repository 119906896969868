import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faQuestionCircle, faPlus } from "@fortawesome/free-solid-svg-icons";

import Content from "../components/content"
import PageHeader from "../components/page-header";
import FaceArtPanel from "./panels/panel-faceart"
import FaceArtGridPanel from "./panels/panel-faceartgrid"
import DesignerFillsPanel from "./panels/panel-designerfills"
import FaceArtDrawer from "../drawers/drawer-faceart";
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"
import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import PageLayout from "../components/page-layout";

function ArtPage(props) {
    const history = useHistory();
    let { tab } = useParams();

    if (!tab) tab = "faceart";

    const [categories, setCategories] = useState([]);
    const [selected, setSelected] = useState(null);
    const [counter, setCounter] = useState(0);

    useEffect(
        () => {
            if (tab == "faceart" && categories.length == 0) {
                postData("categories/data?type=faceart", { uncategorized: 0 },
                    function(result) {
                        setCategories(result);
                    },
                    function(error) {
                        alert("Error loading categories")
                    }
                );
            }
        }, 
        [tab]
    );

    function onCreate() {
        setSelected({mode:"create"})
    }

    return (
        <PageLayout>
            <PageHeader 
                title="Art Manager"
                help="faceart"
                tab={tab}
                tabUrl={"/art/{tab}"}
                tabs={[
                    {name:"faceart", title:"Face Art"},
                    {name:"designerfills", title:"Designer Fills"},
                    {name:"uncategorized", title:"Uncategorized", icon:faQuestionCircle}
                ]}
                onTab={(tab) => {
                    history.push("/art/" + tab);
                }}
            >
                {userHasPermission(Permissions.FaceArt, Actions.Categorize) &&
                    <Button onClick={onCreate} variant="outline-success" size="sm">
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        Create Face Art
                    </Button>
                }
            </PageHeader>
            <Content permission="Face Art">
                {tab == "faceart" && 
                    <FaceArtPanel 
                        counter={counter}
                        haveTabs={true}
                    />
                }
                {tab == "uncategorized" && 
                    <FaceArtGridPanel 
                        counter={counter}
                        type="uncategorized"
                        haveTabs={true}
                    />
                }
                {tab == "designerfills" && 
                    <DesignerFillsPanel 
                        counter={counter}
                        haveTabs={true}
                    />            
                }
            </Content>
            { selected && selected.mode == "create" && 
                <FaceArtDrawer 
                    categories={categories}
                    faceart={{}} 
                    show={true}
                    onSaved={(faceart) => {
                        setCounter(counter+1); // force refresh
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
        </PageLayout>
    );
}

export default ArtPage;