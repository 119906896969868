import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIcons, faSave } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import TextField from "../components/fields/field-text"
import FileField from "../components/fields/field-file"
import UserField from "../components/fields/field-user"

import { postData, uploadImage } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

function FaceArtDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(props.faceart)));
  const [validated, setValidated] = useState(false);

  const { faceart } = props;

  useEffect(() => {
    setFormData(produce(draft => {
        draft.categoryIds = faceart.categories ? faceart.categories.split(",") : [];
    }));

  }, [props.faceart]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      const {categoryIds, file, thumbnailData, previewData, ...data} = formData;

      data.category_ids = categoryIds.join(",");

      postData("facearts/save?id=" + (props.faceart.id||0), removeNulls(data), 
        function(response) {
          if (file) {
            uploadImage("facearts/upload?id=" + response.id, file, thumbnailData, previewData, 
              function(progress) {
                console.log("progress", progress)
              },
              function(response) {
                props.onSaved(response);
              },
              function(error) {
                alert("An error occured uploading the face art.");
              },
              function() {
                setIsProcessing(false);
              }
            );
          }
          else {
            setIsProcessing(false);
            props.onSaved(response);
          }
        },
        function(error) {
          alert("An error occured saving the face art.");
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;
    let value = event.target.value;

    if (field == "user_id") {
      value = event.target.value.id;
    }

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  function onCategoryChange(e) {
    console.log("category changed", e.target.value, e.target.checked)
    const categoryId = e.target.value;
    const checked = e.target.checked;

    setFormData(produce(draft => {
        if (checked) {
            draft.categoryIds.push(categoryId)
        }
        else {
            const index = draft.categoryIds.indexOf(categoryId);

            if (index != -1)
                draft.categoryIds.splice(index, 1);
        }
    }));
  }

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faIcons} />
                &nbsp;
                Face Art
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
                    <FileField 
                        name="file" 
                        label="Art File" 
                        instructions="If uploading a SVG file it should be 60px tall when exported/saved to ensure it scales consistently with the other shapes."
                        onFile={(file) => {
                          console.log("art file", file)
                          setFormData(produce(draft => {
                            draft.file = file;
                          }));
                        }} 
                        onThumbnail={(thumbnailData) => {
                          setFormData(produce(draft => {
                            draft.thumbnailData = thumbnailData;
                          }));
                        }}
                        onPreview={(previewData) => {
                          setFormData(produce(draft => {
                            draft.previewData = previewData;
                          }));
                        }}
                    />

                    <TextField 
                        name="name" 
                        label="Name" 
                        value={formData.name} 
                        onChange={(e) => handleFormChange(e)} />
                    <TextField 
                        name="keywords" 
                        label="Keywords" 
                        value={formData.keywords} 
                        onChange={(e) => handleFormChange(e)} />
                    <UserField 
                        name="user_id" 
                        label="User" 
                        value={{id:formData.user_id, name: formData.user_name || "Anonymous"}}
                        onChange={(e) => handleFormChange(e)} 
                        instructions="If a user is assigned then this face art will only be available to them."    
                    />

                    <h4>Categories</h4>
                    {props.categories.map(category => (
                        <div key={category.id}>
                            <Form.Check 
                                name="category_id"                                
                                value={category.id}
                                checked={formData.categoryIds ? formData.categoryIds.indexOf(""+category.id) != -1 : false}
                                inline 
                                label={category.title} 
                                type="checkbox" 
                                onChange={onCategoryChange}
                            />
                        </div>
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonProcessing 
                    processing={isProcessing}
                    onClick={onSave} 
                    variant="outline-success" 
                    caption="Save Face Art" 
                    icon={faSave} /> 
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default FaceArtDrawer;