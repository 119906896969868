import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faUser, faTrash, faSpinner, faInbox } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading";
import UserSelect from "../components/fields/user-select"
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import produce from "immer"


function InboxUsersDrawer(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [users, setUsers] = useState([]);

  const [processing, setProcessing] = useState([]);  

  useEffect(
    () => {
      setIsLoading(true);

      postData("inboxes/users/data", {inbox_id: props.inbox.id},
        function(result) {
          setUsers(result);
        },
        function(error) {
            alert("Error loading inbox users.")
        },
        function() {
          setIsLoading(false);
        }
      );
    }, 
    [props.inbox]
  );

  function onAddUser(user) {
    if (!user) return;
    console.log("user selected", user)
    setIsAdding(true);

    postData("inbox/user/add", { 
            inbox_id: props.inbox.id,
            user_id: user.id 
        }, 
        function(response) {
            props.onChanged(users.length + 1);

            setUsers(produce(users, draft => {
                draft.push(user);
            }));
        },
        function(error) {
            alert("An error occured updating the inbox.");
        },
        function() {
            setIsAdding(false);
        }
    );
  }

  function onRemoveUser(user) {
        console.log("removeUser", user)
        console.log(processing);

        setProcessing(produce(processing, draft => {
            draft.push(user.id);
        }));

        console.log(processing);

        postData("inbox/user/remove", { 
                inbox_id: props.inbox.id,
                user_id: user.id 
            }, 
            function(response) {
                props.onChanged(users.length - 1);

                setUsers(produce(users, draft => {
                    var indexes = draft.map(function(x) { return x.id; });
                    var index = indexes.indexOf(user.id);
                    
                    draft.splice(index, 1);
                }));
            },
            function(error) {
                alert("An error occured updating the inbox.");
            },
            function() {
                setProcessing(processing.filter(i => i == user.id));
            }
        );
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faInbox} />
            &nbsp;
            {props.inbox.title} Employees
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { 
                isLoading 
                ?
                    <Loading />
                :
                <>
                    <ListGroup>
                        {users.map(user => (
                            <ListGroup.Item key={user.id} className="small">
                                <span className="float-right">
                                    <ButtonProcessing 
                                        processing={false}
                                        icon={faTrash}
                                        variant="outline-danger" 
                                        size="sm"
                                        onClick={ () => onRemoveUser(user) }
                                    />
                                </span>

                                <FontAwesomeIcon icon={faUser} />
                                &nbsp;
                                {user.name}
                            </ListGroup.Item>
                        ))}

                        { isAdding &&
                            <ListGroup.Item key="adding" className="small">
                                <FontAwesomeIcon icon={faSpinner} spin />
                                &nbsp;
                                Adding...
                            </ListGroup.Item>
                        }
                    </ListGroup>
                    <br />
                    <Form.Group>
                        <Form.Label>Add Employee</Form.Label>
                        <UserSelect name="user" employees={true} onChange={onAddUser} />
                    </Form.Group>
                </>
            }
        </Modal.Body>
      </Modal>
    )
}

export default InboxUsersDrawer;