import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard, faPlug } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"
import storage from "../settings/storage";

function PartProductsDrawer(props) {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([])

  useEffect(() => {
        postData("parts/products", { part_id: props.part.id }, 
            function(results) {
                setProducts(results);
            },
            function(error) {
                alert("An error occured loading the part products.");
            },
            function() {
                setLoading(false);
            }
        );
  }, []);

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faPlug} />
                &nbsp;
                {props.part.title} Products
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? 
                    <Loading />
                :
                    (products.map(product => (
                        <div key={product.id} style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}>
                            <div style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                            }}>
                                <div style={{
                                    width: "35px",
                                    textAlign: "center",
                                }}>
                                    <img
                                        src={storage.root + "2020/products/" + product.name + "/icon.jpg?" + (new Date().getTime())} 
                                        onError={(e) => e.target.src = storage.root + "images/nothumbnail.jpg"}
                                        width="35"
                                        style={{ maxWidth: "35px" }} 
                                    />
                                </div>
                                {product.title}
                            </div>

                            <Button
                            >
                                Add To Part
                            </Button>
                        </div>
                    )))
                }                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default PartProductsDrawer;