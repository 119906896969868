import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const usetGetUnreadNotifications = (options={}) => {
  return useQuery({ 
    queryKey: ["notifications", "unread"], 
    queryFn: ({signal}) => query("notifications/unread", {}, signal),
    ...options
  });
}

export const usetGetRecentNotifications = (options={}) => {
  return useQuery({ 
    queryKey: ["notifications", "recent"], 
    queryFn: ({signal}) => query("notifications/recent", {}, signal),
    ...options
  });
}
