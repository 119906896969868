import React from "react";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from "../components/loading";

function DrawerTemplateSimpleList({
    title,
    icon,
    loading,
    error,
    items,
    itemClassName,
    itemRender,
    onHide
  }) {

  return (
      <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title className="flex items-center gap-2">
            {icon && <FontAwesomeIcon icon={icon} />}
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loading ? 
                <Loading />
            : (items && items.length > 0) ?
                <div className="grid gap-1">
                    {items.map((item,index) => (
                        <div key={index} className={itemClassName}>
                            {itemRender(item)}
                        </div>
                    ))}
                </div>                
            :
                <div className="text-gray-500 p-2">
                    No items found.
                </div>
            }
        </Modal.Body>
      </Modal>
    )
}

export default DrawerTemplateSimpleList;