import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import styled from "styled-components"
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSave, faShapes, faTrash } from '@fortawesome/free-solid-svg-icons'

import SelectField from "../components/fields/field-select"
import ListField from "../components/fields/field-list"
import Loading from "../components/loading"
import ShapePreview from "../components/shape-preview"
import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"

import { postData } from "../common/services/server"

function RelatedShapesDrawer(props) {
  const [isProcessing, setIsProcessing] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [styles, setStyles] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [formData, setFormData] = useState({});
  const [offsets, setOffsets] = useState([]);
  
  useEffect(() => {
    setFormData(produce(draft => {
        draft.shapes = props.group.shapes[0].related;
    }))

    let values = [];

    for (let i = 10; i <= 100; i++) {
      values.push({id: i - props.group.size, label: i});
    }

    setOffsets(values);    
  }, [props.group]);

  function onSave(part) {
      setIsProcessing(part);

      let shapeIds = [];
      let offsets = [];

      for (const shape of formData.shapes) {
        shapeIds.push(shape.id);
        offsets.push(shape.size_offset);
      }

      postData("signs/setrelatedshapes", {
        sign_id: props.group.sign_id,
        group_id: props.group.id,
        related_shape_ids: shapeIds.join(","),
        related_shape_offsets: offsets.join(",")
      }, 
        function(response) {
          props.onSaved(formData.shapes);
        },
        function(error) {
          alert("An error occured updating the shapes.");
        },
        function() {
          setIsProcessing(null);
        }
      );
  }

  function onRemoveShape(shapeIndex) {
    setFormData(produce(draft => {
        //let shapeIndex = draft.shapes.findIndex(s => s.id == shape.id);

        draft.shapes.splice(shapeIndex, 1);
    }))
  }

  function onAddShape(shape) {
    shape.style_name = styles.find(s => s.id == shape.style_id).name;
    shape.size_offset = 0;

    setFormData(produce(draft => {
        draft.shapes.push(shape);
        draft.shape = null;
        draft.shape_id = null;        
    }))      
  }

  function handleFormChange(event, shapeIndex=null) {
    if (event.persist) event.persist();

    const field = event.target.name;
    const value = event.target.value;

    console.log("handleFormChange", field, value, shapeIndex);

    //console.log("handleFormChange", event.target.name, event.target.value)
    setFormData(produce(draft => {
      draft[field] = value;

      if (field == "shape_id")
        draft.shape = shapes.find(s => s.id == value);

      if (field == "offset" && shapeIndex != null) {
        //const idx = draft.shapes.findIndex(s => s.id == shape.id);
        draft.shapes[shapeIndex].size_offset = value;
      }
      else if (field == "style" && shapeIndex != null) {
        const style = styles.find(s => s.id == value);
        const styleShape = style.shapes.find(s => s.code == draft.shapes[shapeIndex].code)

        draft.shapes[shapeIndex].style_id = value;
        draft.shapes[shapeIndex].id = styleShape.id;
        draft.shapes[shapeIndex].path = styleShape.path;
      }

    }));
  }

  return (
      <>
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faShapes} />
            &nbsp;
            Group Sub Shapes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {isLoading ? 
                <Loading /> 
            : 
            <>
                {(formData.shapes && formData.shapes.length > 0) ? 
                    <Table>
                        <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th style={{verticalAlign: "middle"}}>
                                Size Base <strong>{props.group.size}</strong>
                              </th>
                              <th>
                                <Button 
                                  size="sm" 
                                  variant="outline-secondary"
                                  onClick={() => {
                                    setFormData(produce(draft => {
                                      for (let shape of draft.shapes) {
                                        shape.size_offset = 0;
                                      }
                                    }));
                                  }}
                                >
                                  Reset
                                </Button>
                              </th>
                            </tr>
                            <tr>
                                <th>Preview</th>
                                <th>Shape</th>
                                <th>Style</th>
                                <th>Size</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formData.shapes.map((shape, index) => (
                                <tr key={index}>
                                    <td><ShapePreview path={shape.path} solid={true} width={50} height={35} /></td>
                                    <td>{shape.code}</td>
                                    <td>
                                      {styles ? 
                                        <ListField 
                                          name="style" 
                                          value={shape.style_id} 
                                          list={styles.filter(s => s.shapes?.find(sh => sh.code == shape.code))}
                                          onChange={(e) => handleFormChange(e, index)} />                                        
                                        :
                                        shape.style_name
                                      }
                                    </td>
                                    <td style={{display:"flex", alignItems:"baseline"}}>
                                      <ListField 
                                          name="offset" 
                                          value={shape.size_offset} 
                                          list={offsets}
                                          onChange={(e) => handleFormChange(e, index)} />                                      
                                    </td>
                                    <td>
                                        <Button onClick={() => onRemoveShape(index)} size="sm" variant="outline-secondary" style={{padding:"4px"}}>
                                            <FontAwesomeIcon icon={faTrash} />
                                         </Button>                                           
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    :
                        <p>No shapes found.</p>
                }

                <br />

                <h5>Add Sub Shape</h5>

                {formData.shapes && 
                  <SelectField 
                      name="style_id" 
                      label="Style"
                      labelField="name"
                      value={formData.style_id} 
                      url={"styles/data?codes=" + (formData.shapes.map(s => s.code).join(","))}
                      onData={(data) => setStyles(data)}
                      onChange={(e) => handleFormChange(e)} />
                }
                {formData.style_id &&
                    <SelectField 
                        name="shape_id" 
                        label="Shapes"
                        labelField="code"
                        value={formData.shape_id} 
                        url={"shapes/data?style_id=" + formData.style_id}
                        onData={(data) => setShapes(data)}
                        onChange={(e) => handleFormChange(e)} />                    
                }
                {formData.shape && 
                  <div>
                      <label>Shape</label>
                      <div style={{display:"flex"}}>
                          <ShapePreview path={formData.shape.path} solid={true} width={50} height={35} />
                          <Button onClick={() => onAddShape(formData.shape)} variant="outline-primary">
                              <FontAwesomeIcon icon={faPlus} />
                              Add Shape
                          </Button>                         
                      </div>
                  </div>
                }
            </>
            }
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Shapes" 
                icon={faSave} />           
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
      </>
    )
}

export default RelatedShapesDrawer;