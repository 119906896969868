import React, { useState, useEffect } from "react";
import produce from "immer"

import { useParams, useHistory } from "react-router-dom";
import { faStickyNote, faCopy, faArchive, faShapes, faShoppingCart, faCreditCard, faPlus, faPlusSquare, faComments, faUser, faKey, faScrewdriver } from "@fortawesome/free-solid-svg-icons";

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"

import UserOverviewPanel from "./panels/panel-user-overview"
import OrdersPanel from "./panels/panel-orders"
import FilesPanel from "./panels/panel-files"
import NotesPanel from "./panels/panel-noteslist"
import SignsPanel from "./panels/panel-signs"
import CartsPanel from "./panels/panel-carts"
import EmailsPanel from "./panels/panel-emails"
import ProjectsPanel from "./panels/panel-projects"
import UserRolesPanel from "./panels/panel-userroles"
import UserSkillsPanel from "./panels/panel-userskills"

import EmailDrawer from "../drawers/drawer-email";
import ProjectDrawer from "../drawers/drawer-project"

import { postData } from "../common/services/server"
import { userHasPermission, Permissions, Actions, userIsAdmin } from "../common/services/auth";
import PageLayout from "../components/page-layout";

function UserPage(props) {
    const history = useHistory();
    let { id, tab } = useParams();

    if (!tab) tab = "overview";

    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [busy, setBusy] = useState({});
    const [dirty, setDirty] = useState({});
    const [selected, setSelected] = useState(null);
    const [buttonBusy, setButtonBusy] = useState("");

    const isEmployee = user && user.role == "admin";
    const isAdmin = userIsAdmin()

    useEffect(
        () => {
            setIsLoading(true);

            postData("users/userdata", { 
                id: id
            },
                function(result) {
                    setUser(result);
                },
                function(error) {
                    alert("Error loading user")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        [id]
    );

    return (
        <PageLayout>
            <PageHeader 
                title={user && user.name ? user.name : "User #"+id}
                help="user"
                parent={{
                    url: "/users" + (user && isEmployee ? "/employees":"/customers"),
                    title: user ? (isEmployee ? "Employees" : "Customers") : "Users"
                }}
                tab={tab}
                tabUrl={"#/users/user/{tab}/" + id}
                tabs={[
                    {name:"overview", title:"Details"},
                    {name:"roles", title:"Roles", icon:faKey, hidden: !user, permission: isEmployee ? Permissions.Roles : null, hidden: !isEmployee},
                    {name:"skills", title:"Skills", icon:faScrewdriver, hidden: !user, permission: isEmployee ? Permissions.Roles : null, hidden: !isAdmin},
                    {name:"signs", title:"Signs", icon:faShapes, count: user ? user.sign_count : null},
                    {name:"projects", title:"Projects", icon:faArchive, count: user ? user.project_count : null},
                    {name:"carts", title:"Cart", icon:faShoppingCart, count: user ? user.cart_count : null},
                    {name:"orders", title:"Orders", icon:faCreditCard, count: user ? user.order_count : null},
                    {name:"files", title:"Files", icon:faCopy},
                    {name:"emails", title:"Emails", icon:faComments, count: user ? user.email_count : null},
                    {name:"notes", title:"Notes", icon:faStickyNote, count: user ? user.note_count : null},
                ]}
                onTab={(tab) => {
                    //setSelectedTab(tab);
                    history.push("/users/user/" + tab + "/" + id)
                }}
            />
            <Content>
            <>
                {
                    isLoading 
                ?
                    <Loading />
                :    
                    <>
                        {tab == "overview" && 
                            <UserOverviewPanel
                                user={user}
                                busy={busy}
                                dirty={dirty}
                                haveTabs={true}
                                onUserPropChange={(prop, value) => {
                                    setUser(produce(draft => {
                                        draft[prop] = value;
                                    }));
                                    setDirty(produce(draft => {
                                        draft[prop] = true
                                    }));
                                }}
                                onUserPropBusy={(prop, busy=true) => {
                                    setBusy(produce(draft => {
                                        draft[prop] = busy
                                    }));
                                }}
                                onSaveUserProp={(prop, value=null) => {
                                    console.log("onSaveUserProp", prop, value)

                                    setBusy(produce(draft => {
                                        draft[prop] = true
                                    }));

                                    if (value) {
                                        setUser(produce(draft => {
                                            draft[prop] = value;
                                        }));
                                    }

                                    postData("users/updateprop", { 
                                        user_id: user.id,
                                        prop: prop,
                                        value: value != null ? value : user[prop]
                                    },
                                        function(result) {
                                            setUser(result);
                                            setDirty(produce(draft => {
                                                draft[prop] = false
                                            }));
                                        },
                                        function(error) {
                                            alert("Error updating user")
                                        },
                                        function() {
                                            setBusy(produce(draft => {
                                                draft[prop] = false
                                            }));
                                        }
                                    );
                                }}
                                onUserChanged={(userData) => {
                                    console.log("user changed", userData)
                                    setUser(userData); 
                                    setDirty({});
                                }}
                            />                            
                        }
                        {tab === "projects" &&  
                                <ProjectsPanel
                                    filters={{
                                        user_id: user.id
                                    }}
                                    haveTabs={true}
                                    buttons={[
                                        {
                                            name: "addproject",
                                            label: "Create Project",
                                            variant: "outline-secondary",
                                            icon: faPlus,
                                            busy: buttonBusy == "addproject",
                                            disabled: !userHasPermission(Permissions.Projects, Actions.Create)
                                        }
                                    ]}
                                    onButton={(name) => {
                                        if (name == "addproject") {
                                            setSelected({mode:"addproject"});
                                        }
                                    }}
                                />
                        }
                        {tab === "orders" &&  
                                <OrdersPanel
                                    filters={{
                                        user_id: user.id
                                    }}
                                    haveTabs={true}
                                />
                        }
                        {tab === "roles" &&  
                                <UserRolesPanel
                                    user={user}
                                    haveTabs={true}
                                />
                        }
                        {tab === "skills" &&  
                                <UserSkillsPanel
                                    user={user}
                                    haveTabs={true}
                                />
                        }                        
                        {tab === "signs" &&  
                                <SignsPanel
                                    filters={{
                                        user_id: user.id
                                    }}
                                    haveTabs={true}
                                    buttons={[
                                        {
                                            name: "addsign",
                                            label: "Create Builder Sign",
                                            variant: "outline-secondary",
                                            icon: faPlus,
                                            busy: buttonBusy == "addsign"
                                        },
                                        {
                                            name: "addcustomsign",
                                            label: "Create Custom Sign",
                                            variant: "outline-secondary",
                                            icon: faPlusSquare,
                                            busy: buttonBusy == "addcustomsign",
                                            disabled: !userHasPermission(Permissions.SignsCustom, Actions.Create)
                                        }
                                    ]}
                                    onButton={(name) => {
                                        console.log("button", name)
                                        if (name == "addsign") {
                                            setSelected({mode:"addsign"})

                                            postData("signs/create", { customer_id: user.id },
                                                function(result) {
                                                    history.push("/signs/sign/builder/" + result.id)
                                                },
                                                function(error) {
                                                    alert("Error creating sign")
                                                },
                                                function() {
                                                    setButtonBusy("");
                                                }
                                            );
                                        }
                                        else if (name == "addcustomsign") {
                                            setButtonBusy("addcustomsign");
    
                                            postData("signs/createcustom", { customer_id: user.id },
                                                function(result) {
                                                    history.push("/signs/sign/" + result.id)
                                                },
                                                function(error) {
                                                    alert("Error creating sign")
                                                },
                                                function() {
                                                    setButtonBusy("");
                                                }
                                            );
                                        }
                                    }}
                                />
                        }
                        {tab === "carts" &&  
                                <CartsPanel
                                    filters={{
                                        user_id: user.id
                                    }}
                                    haveTabs={true}
                                />
                        }
                        {tab === "files" &&  
                                <FilesPanel
                                    filters={{
                                        user_id: user.id
                                    }}
                                    haveTabs={true}
                                    uploadPath={"users/upload?user_id=" + user.id}
                                    uploadMultiple
                                />
                        }
                        {tab === "emails" &&  
                                <EmailsPanel
                                    filters={{
                                        email: user.email,
                                        phoneno: user.phoneno
                                    }}
                                    haveTabs={true}
                                    buttons={[
                                        {
                                            name: "Email Customer",
                                            label: "Email Customer",
                                            variant: "outline-secondary",
                                            icon: faUser,
                                            disabled: !userHasPermission(Permissions.Emails, Actions.Send)
                                        },
                                    ]}
                                    onButton={(name) => {
                                        console.log("button", name)
                                        setSelected({mode:"email", template:name})
                                    }}
                                />
                        }
                        {tab === "notes" &&
                                <NotesPanel
                                    filters={{
                                        user_id: user.id
                                    }}
                                    haveTabs={true}
                                />
                        }                   
                    </>
                }
            </>
            </Content>
            { selected && selected.mode == "email" && 
                <EmailDrawer 
                    // bodyUrl={"emails/template?template=" + escape(selected.template)}
                    email={{
                        template: "simple",
                        to: user.email,
                        subject: selected.subject,
                        body: ""
                    }}
                    show={true}
                    onSaved={(email) => {
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { selected && selected.mode == "addproject" && 
                <ProjectDrawer 
                    project={{
                        user_id: user.id,
                        user_name: user.name
                    }}
                    show={true}
                    onSaved={(project) => {
                        history.push("/projects/project/" + project.id)
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
        </PageLayout>
    );
}

export default UserPage;