import React, { useEffect, useState, useRef } from "react";

export default function AutoHeightAndWidthPanel({
    hoffset,
    voffset,
    className,
    style,
    children
}) {
    const panelRef = useRef();
    const [panelTop, setPanelTop] = useState(null);
    const [panelLeft, setPanelLeft] = useState(null);

    useEffect(() => {
        if (panelRef.current) {
            setPanelTop(panelRef.current.offsetTop);
            setPanelLeft(panelRef.current.offsetLeft);
        }

    }, [panelRef.current]);

    return (
        <div ref={panelRef} className={className} style={{
            height: `calc(100vh - ${panelTop + (voffset||0)}px)`,
            width: `calc(100vw - ${panelLeft + (hoffset||0)}px)`,
            overflow: "auto",
            ...style
        }}>
            {children}
        </div>
    )
}
