import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus, faSpinner, faListAlt, faArrowCircleDown, faArrowCircleUp, faSave } from '@fortawesome/free-solid-svg-icons'

import SignProductsDrawer from "../drawers/drawer-signproducts";
import Loading from "../components/loading"
import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import GoogleImage from "../components/google-image"
import SignSelect from "../components/fields/sign-select"

import { postData } from "../common/services/server"
import FieldText from "../components/fields/field-text";

const Items = styled.table`
    width: 100%;
    font-size: 12px;

    thead {
        th {
            background-color: #eee;
            padding: 5px;
        }
    }
    tbody {
        td {
            border-bottom: 1px solid #eee;
            padding: 5px;
            white-space: nowrap;

            button {
                margin-left:1px;
            }
        }
    }
`
const AddItems = styled.div`
`

function PageSignsDrawer(props) {
  const [removing, setRemoving] = useState(null);
  const [adding, setAdding] = useState(null);
  const [saving, setSaving] = useState([]);
  const [dirty, setDirty] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moving, setMoving] = useState(null);
  const [signs, setSigns] = useState([]);
  const [sign, setSign] = useState(null);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setLoading(true);

    postData("pages/signs", { 
        page_id: props.page.id
    },
      function(result) {
        setSigns(result);
      },
      function(error) {
        alert("Error loading page signs")
      },
      function() {
        setLoading(false);
      }
    );
  }, [props.page]);


  function onAddSign() {
      setAdding(true);

      postData("pages/addsign", {
        page_id: props.page.id,
        sign_id: sign.id
      }, 
        function(response) {
            setSigns(response);
            props.onChange(response);
        },
        function(error) {
          alert("An error occured adding the sign.");
        },
        function() {
            setAdding(false);
        }
      );
  }

  function onRemove(sign) {
      setRemoving(sign)

      postData("pages/removesign", {
            page_id: props.page.id,
            sign_id: sign.id
        }, 
        function(response) {
            const rowIndex = signs.indexOf(sign)

            setSigns(produce(draft => {
                delete draft[rowIndex];
            }));

            props.onChange(response);
        },
        function(error) {
          alert("An error occured removing the sign.");
        },
        function() {
            setRemoving(null);
        }
      );
  }

  function onDown(sign,index) {
    const signIds = [];

    setMoving(sign.id);

    setSigns(produce(draft => {
        const temp = draft[index+1]
        
        draft[index+1] = sign;
        draft[index] = temp;

        console.log("down", draft.length);

        for (let i=0; i<draft.length; i++) {
            console.log(i, draft[i].id)
            signIds.push(draft[i].id);
        }

        console.log("down", signIds)

        postData("pages/setsignorder", {
                page_id: props.page.id,
                sign_ids: signIds.join(",")
            }, 
            function(response) {
                setMoving(null);
            },
            function(error) {
                alert("An error occured update the sign ordering.");
            }
        );
    }));
  }
  function onUp(sign,index) {
    const signIds = [];

    setMoving(sign.id);

    setSigns(produce(draft => {
        const temp = draft[index-1]

        draft[index-1] = sign;
        draft[index] = temp;

        for (let i=0; i<draft.length; i++) {
            signIds.push(draft[i].id);
        }

        console.log("up", signIds)

        postData("pages/setsignorder", {
                page_id: props.page.id,
                sign_ids: signIds.join(",")
            }, 
            function(response) {
                setMoving(null);
            },
            function(error) {
                alert("An error occured update the sign ordering.");
            }
        );
    }));
  }

  function onSaveTitle(sign) {
    setSaving(produce(draft => {
        draft.push(sign.id+"title");
    }));

    postData("pages/setsigntitle", {
            page_id: props.page.id,
            sign_id: sign.id,
            title: sign.title
        }, 
        function(response) {
            setDirty(produce(draft => {
                draft.splice(draft.indexOf(sign.id+"title"), 1);
            }));
        },
        function(error) {
          alert("An error occured updating the sign.");
        },
        function() {
            setSaving(produce(draft => {
                draft.splice(draft.indexOf(sign.id+"title"), 1);
            }));
        }
    );
  }
  function onSaveKeywords(sign) {
    setSaving(produce(draft => {
        draft.push(sign.id+"keywords");
    }));

    postData("pages/setsignkeywords", {
            page_id: props.page.id,
            sign_id: sign.id,
            keywords: sign.keywords
        }, 
        function(response) {
            setDirty(produce(draft => {
                draft.splice(draft.indexOf(sign.id+"title"), 1);
            }));        
        },
        function(error) {
          alert("An error occured updating the sign.");
        },
        function() {
            setSaving(produce(draft => {
                draft.splice(draft.indexOf(sign.id+"keywords"), 1);
            }));
        }
    );
  }  

  return (
    <>
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faListAlt} />
            &nbsp;
            Landing Page Signs
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { loading ? 
                <Loading /> 
              :
                <>
                <AddItems style={{marginBottom: "20px"}}>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Add Sign
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <SignSelect
                            name="sign_id"
                            onChange={(sign) => {
                                console.log(sign)
                                setSign(sign);
                            }}
                        />
                        <InputGroup.Append>
                            <Button onClick={onAddSign} size="sm" variant="outline-secondary">
                                <FontAwesomeIcon icon={adding ? faSpinner : faPlus} spin={adding} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </AddItems>                
                <Items>
                    <thead>
                        <tr>
                            <th>Sign</th>
                            <th>Properties</th>
                            <th>Products</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                    {signs.map((sign,index) => {
                        const savingTitle = saving.indexOf(sign.id+"title") != -1;
                        const savingKeywords = saving.indexOf(sign.id+"keywords") != -1;
                        const dirtyTitle = dirty.indexOf(sign.id+"title") != -1;
                        const dirtyKeywords = dirty.indexOf(sign.id+"keywords") != -1;

                        return (
                        <tr key={sign.id}>
                            <td>
                                <div>
                                    Design #{sign.id}
                                </div>
                                <GoogleImage 
                                    root 
                                    src={sign.preview_url} 
                                    style={{
                                        maxWidth: "125px",
                                        maxHeight: "75px"
                                    }}
                                />
                            </td>
                            <td>
                                <FieldText
                                    name="title"     
                                    prepend="Title:"
                                    prependClassName="w-[75px] flex items-center"
                                    inputClassName="!w-[250px]"
                                    value={sign.title || ""}
                                    append={
                                        <Button onClick={() => onSaveTitle(sign)} disabled={!dirtyTitle} size="icon" variant={dirtyTitle ? "primary":"outline-secondary"}>
                                            <FontAwesomeIcon icon={savingTitle ? faSpinner : faSave} spin={savingTitle} />
                                        </Button>
                                    }
                                    onChange={(event) => {
                                        if (event.persist) event.persist();
                                        
                                        setSigns(produce(draft => {
                                            const index = draft.findIndex(s => s.id == sign.id)

                                            draft[index].title = event.target.value;
                                        }));   
                                        console.log("title change", event.target.value);

                                        setDirty(produce(draft => {
                                            const index = draft.indexOf(sign.id + "title")

                                            console.log("dirty index", index)
                                            if (index == -1)
                                                draft.push(sign.id + "title");
                                        }));                                            
                                    }}
                                />
                                <FieldText
                                    name="keywords"
                                    prepend="Keywords:"
                                    prependClassName="w-[75px] flex items-center"  
                                    inputClassName="!w-[250px]"
                                    value={sign.keywords || ""}
                                    append={
                                        <Button onClick={() => onSaveKeywords(sign)} disabled={!dirtyKeywords} size="icon" variant={dirtyKeywords ? "primary":"outline-secondary"}>
                                            <FontAwesomeIcon icon={savingKeywords ? faSpinner : faSave} spin={savingKeywords} />
                                        </Button>
                                    }
                                    onChange={(event) => {
                                        if (event.persist) event.persist();
                                        
                                        setSigns(produce(draft => {
                                            const index = draft.findIndex(s => s.id == sign.id)

                                            draft[index].keywords = event.target.value;
                                        }));             
                                        setDirty(produce(draft => {
                                            const index = draft.indexOf(sign.id + "keywords")

                                            if (index == -1)
                                                draft.push(sign.id + "keywords");
                                        }));                               
                                    }}
                                />                                    
                            </td>
                            <td align="center">
                                <Button size="sm" variant="outline-dark" onClick={() => setSelected({mode:"products", sign})}>
                                    {sign.product_count}
                                </Button>
                            </td>
                            <td align="right">
                                <Button
                                    variant={index==0 ? "outline-secondary":"secondary"}
                                    disabled={index == 0 || moving != null}
                                    size="sm"
                                    onClick={() => onUp(sign, index)}
                                >
                                    <FontAwesomeIcon icon={moving == sign.id ? faSpinner : faArrowCircleUp} spin={moving == sign.id} />
                                </Button>
                                <Button
                                    variant={index==signs.length-1 ? "outline-secondary":"secondary"}
                                    disabled={index == signs.length-1 || moving != null}
                                    size="sm"
                                    onClick={() => onDown(sign, index)}
                                >
                                    <FontAwesomeIcon icon={moving == sign.id ? faSpinner : faArrowCircleDown} spin={moving == sign.id} />
                                </Button>
                                <ButtonProcessing 
                                    action="delete"
                                    size="sm"
                                    processing={removing == sign}
                                    variant="danger"
                                    icon={faTimes}
                                    onClick={ () => onRemove(sign) } 
                                />
                            </td>
                        </tr>
                        )
                    })}
                    </tbody>
                </Items>
                {(signs && signs.length > 10) && 
                    <AddItems style={{marginTop: "20px"}}>
                        <InputGroup size="sm" className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Add Sign
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <SignSelect
                                name="sign_id"
                                onChange={(sign) => {
                                    console.log(sign)
                                    setSign(sign);
                                }}
                            />
                            <InputGroup.Append>
                                <Button onClick={onAddSign} size="sm" variant="outline-secondary">
                                    <FontAwesomeIcon icon={adding ? faSpinner : faPlus} spin={adding} />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </AddItems>
                }
                </>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
      { selected && selected.mode == "products" && 
        <SignProductsDrawer 
            show={true}
            sign={selected.sign}
            onChanged={(productsCount) => {
                setSigns(produce(draft => {
                    const signIndex = draft.findIndex(s => s.id == selected.sign.id);
        
                    draft[signIndex].product_count = productsCount;
                }));                          
            }}
            onHide={ () => setSelected(null) } 
        /> 
      } 
    </>       
)}

export default PageSignsDrawer;