import React from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownMenu from "./dropdownmenu";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Badge from "./badge";

export default function Tabs({
    direction = "horizontal",
    disabled,
    tabs,
    tabBusy,
    className,
    selectedTab,
    onTab,
    onTabMenuItem
}) {
    return (
        <nav className={clsx(
            "flex border-gray-300 gap-[1px]",
            direction == "horizontal" && "border-b pl-1",
            direction == "vertical" && "flex-col border rounded h-full pt-1 pl-1",
            className,
        )}>
            {tabs.filter(t => !t.hidden).map(tab => {
                const selected = selectedTab && selectedTab.name == tab.name;

                return (
                    <button 
                        key={tab.name}
                        className={clsx(
                            "flex items-center gap-1 p-2",
                            "border-gray-300",
                            tab.variant && tab.variant == "danger" ? "text-red-500" : "text-gray-500",
                            direction == "vertical" ? "rounded-l" : "rounded-t",
                            "hover:bg-gray-100",
                            (disabled || tab.disabled) && "text-gray-200",
                            selected && "!bg-white !text-black relative",
                            selected && direction == "horizontal" && "top-[1px] border-t border-l border-r",
                            selected && direction == "vertical" && "left-[1px] border-t border-l border-b",
                        )}
                        disabled={disabled || tab.disabled}
                        onClick={() => onTab(tab)}
                    >
                        {tab.icon && <FontAwesomeIcon icon={tab.icon} fixedWidth />}                        
                        {tab.title}

                        {tab.count != undefined && 
                            <Badge size="sm" variant={tab.countVariant} value={tab.count} />
                        }

                        {(selected && tab.menu && tab.menu.filter(m => !m.hidden).length > 0) && 
                            <div className="border border-gray-200 rounded">
                                <DropdownMenu
                                    icon={faBars}
                                    size="sm"
                                    className="!p-1 !rounded-none"
                                    menu={tab.menu}
                                    busy={tabBusy}
                                    onMenuItem={onTabMenuItem}
                                />
                            </div>
                        }
                    </button>
                )
            })}         
        </nav>
    )
}