import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faUserCheck, faRandom, faPencilAlt, faRetweet, faPrint, faExclamationTriangle, faWrench, faThumbsUp, faPlus, faThumbsDown, faBan, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import AddSignDrawer from "../../drawers/drawer-addsign"
import AddAccessoryDrawer from "../../drawers/drawer-addaccessory"
import OrderChangeSignDrawer from "../../drawers/drawer-orderchangesign"
import OrderItemDrawer from "../../drawers/drawer-orderitem"
import JobCreateDrawer from "../../drawers/drawer-jobcreate"
import EmailDrawer from "../../drawers/drawer-email";
import ApprovalRequestDrawer from "../../drawers/drawer-approvalrequest";
import ApprovalDrawer from "../../drawers/drawer-approval";
import SelectSignDrawer from "../../drawers/drawer-selectsign";
import JobInitialTasksDrawer from "../../drawers/drawer-jobinitialtasks";

import Loading from "../../components/loading"
import Button from "../../components/buttons/button";
import ButtonProcessing from "../../components/buttons/button-processing"
import GoogleImage from "../../components/google-image"
import { postData } from "../../common/services/server"
import { formatPrice, formatDate } from "../../helpers/utility"
import { Link } from "react-router-dom";
import { userHasPermission, userIsAdmin, Permissions, Actions } from "../../common/services/auth";
import { Toast } from "react-bootstrap";
import { colors } from "../../settings/settings";
import settings from '../../settings'

const Items = styled.table`
    font-size: 12px;

    tbody {
        th {
            background-color: #eee;
            padding: 10px;
            width: 100px;
        }
        td {
            border: 1px solid #eee;
            padding: 10px;
            min-width: 200px;
            max-width: 350px;
            vertical-align: bottom;

            button {                
                margin-left: 2px;
            }
        }
    }

    &.void tbody td {
        color: #ccc;
        text-decoration: line-through;
    }
`
const AddItems = styled.div`
    padding: 20px;
    
    button {
        width: 100%;
        text-align: center;
        display: block;
        margin-bottom: 5px;

        svg {
            margin-right: 5px;
        }
    }
`
const Job = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    margin-bottom: 4px;

    a {
        color: blue;
    }
`
const Approval = styled.a`
    cursor: pointer;

    &:hover {
        background-color: #eee;
    }
`

function OrderItemsPanel(props) {
    const history = useHistory();

    const [removing, setRemoving] = useState(null);
    const [updating, setUpdating] = useState(null);
    const [adding, setAdding] = useState(false);
    const [loading, setLoading] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [job, setJob] = useState(null);
    const [selected, setSelected] = useState(null);
    const [busy, setBusy] = useState("");
  
    function onRemove(item) {
        setRemoving(item)
  
        postData("orders/removeitem", {
              order_id: props.order.id,
              item_id: item.id
          }, 
          function(response) {
              props.onOrderChange(response);
          },
          function(error) {
            alert("An error occured removing the item.");
          },
          function() {
              setRemoving(null);
          }
        );
    }

    function onEdit(item) {
        setSelected({mode:"item", item});
    }
    function onChangeSign(item) {
        setSelected({mode:"changesign", item});
    }
    function onCreateJob(item) {
        setSelected({mode:"job", item});
    }
    function onAddSign() {
        setSelected({mode:"addsign"})
    }
    function onAddAccessory() {
        setSelected({mode:"addaccessory"})
    }
    function onAddMod() {
        setSelected({mode:"item", item:{id:0, order_id:props.order.id}})
    }
    function onApprovalRequest(item) {
        setSelected({mode:"approvalrequest", item});
    }
    function onApproval(item, approval) {
        setSelected({mode:"approval", item, approval});
    }

    const { order } = props;

    const haveSignOptions = props.order.items.filter(i => i.sign_options).length > 0;
    const haveSignNote = props.order.items.filter(i => i.sign_note).length > 0;

    return (
        <>
        { loading ? 
            <Loading /> 
          :
            <>
            <Items>
                <tbody>

                {props.orderNo && 
                    <tr>
                        <th>
                            Order
                        </th>
                        {props.order.items.map(item => (
                            <td key={item.id}>
                                <Link to={"/orders/order/" + item.order_id}>
                                    #{item.id}
                                </Link>
                            </td>
                        ))}
                    </tr>
                }

                <tr>
                    <th>
                        Item
                    </th>
                    {props.order.items.map(item => (
                        <td key={item.id}>
                            {item.sign_id ? 
                                <>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <div>
                                            <Link to={"/signs/sign/" + item.sign_id}>
                                                <span dangerouslySetInnerHTML={{__html: item.title}} />
                                            </Link>
                                            {item.sign_version && 
                                                <div>Version {item.sign_version}</div>
                                            }
                                        </div>

                                        {/* <ButtonProcessing
                                            size="sm"
                                            variant="outline-secondary"
                                            icon={faRetweet}
                                            processing={busy == "replaceSign"+item.id}
                                            style={{marginLeft: "auto"}}
                                            onClick={ () => setSelected({mode:"replaceSign", item}) } 
                                        /> */}
                                    </div>
                                    {item.orig_sign_id &&
                                        <div>
                                            <small>
                                                Original sign ordered 
                                                &nbsp;
                                                <Link to={"/signs/sign/" + item.orig_sign_id}>#{item.orig_sign_id} {item.orig_sign_version && <>&nbsp;V{item.orig_sign_version}</>}</Link>
                                            </small>
                                        </div>
                                    }
                                </>
                            :
                                <span dangerouslySetInnerHTML={{__html: item.title}} />
                            }
                            {item.description && 
                                <div><small style={{color:"#999"}}>{item.description}</small></div>
                            }
                        </td>
                    ))}

                    {(props.add && userHasPermission(Permissions.Orders, Actions.Edit) && !order.voided) && 
                        <td rowSpan="8" style={{border: "0px"}}>
                            <AddItems>
                                <Button onClick={onAddSign} variant="outline-primary">
                                    <FontAwesomeIcon icon={faPlus} />
                                    Add Sign
                                </Button>                            
                                <Button onClick={onAddAccessory} variant="outline-secondary">
                                    <FontAwesomeIcon icon={faPlus} />
                                    Add Accessory
                                </Button>                            
                                <Button onClick={onAddMod} variant="outline-secondary">
                                    <FontAwesomeIcon icon={faPlus} />
                                    Add Mod
                                </Button>                            
                            </AddItems>
                        </td>
                    }
                </tr>

                <tr>
                    <th>
                        Quantity
                    </th>
                    {props.order.items.map(item => (
                        <td key={item.id} style={{color: item.quantity > 1 ? "red":"initial"}}>
                            {item.quantity}
                        </td>
                    ))}
                </tr>

                <tr>
                    <th>
                        Product
                    </th>
                    {props.order.items.map(item => (
                        <td key={item.id}>
                            {item.product}
                        </td>
                    ))}
                </tr>
                
                {(haveSignOptions || haveSignNote) &&
                    <tr>
                        <th>
                            Sign Options
                        </th>
                        {props.order.items.map(item => (
                            <td key={item.id}>
                                <ul style={{margin: "0px", paddingLeft: "15px"}}>
                                {item.sign_options && <li>{item.sign_options}</li>}
                                {item.sign_note && <li>{item.sign_note}</li>}
                                </ul>
                            </td>
                        ))}
                    </tr>
                }

                <tr>
                    <th>
                        Preview
                    </th>
                    {props.order.items.map(item => (
                        <td key={item.id}>
                            {item.sign_id ? 
                                <Link to={"/signs/sign/" + item.sign_id}>
                                    <GoogleImage 
                                        root 
                                        src={item.preview_url} 
                                        style={{
                                            maxWidth: "125px",
                                            maxHeight: "75px"
                                        }}
                                    />
                                </Link>
                            :
                                <GoogleImage 
                                    root 
                                    src={item.preview_url} 
                                    style={{
                                        maxWidth: "125px",
                                        maxHeight: "75px"
                                    }}
                                />
                            }
                        </td>
                    ))}
                </tr>

                {(!order.voided) && 
                    <tr>
                        <th>
                            Approval
                        </th>
                        {props.order.items.map(item => (
                            <td key={item.id}>
                                <>
                                    <div>
                                    {props.order.approvals.filter(a => a.orderitem_id == item.id).map(approval => (
                                        <Approval key={approval.id} onClick={() => onApproval(item, approval)} style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginBottom: "5px"}}>
                                            {formatDate(approval.requested_date)}
                                            <FontAwesomeIcon 
                                                icon={approval.approved_ind == 1 ? faThumbsUp : approval.approved_ind == 0 ? faThumbsDown : approval.approved_ind == 99 ? faUserCheck : faBan} 
                                                style={{color: approval.approved_ind == 1 ? "green" : approval.approved_ind == 0 ? "red" : "grey"}}
                                            />
                                        </Approval>   
                                    ))}
                                    </div>
                                    <ButtonProcessing 
                                        action="approval"
                                        size="sm"
                                        variant="outline-secondary"
                                        icon={faEnvelope}
                                        disabled={!userHasPermission(Permissions.Orders, Actions.Edit)}
                                        caption="Request Approval"
                                        style={{display:"block", width:"100%"}}
                                        onClick={ () => onApprovalRequest(item) } 
                                    />
                                </>
                            </td>
                        ))}
                    </tr>
                }
                <tr>
                    <th>
                        Cost
                    </th>
                    {props.order.items.map(item => (
                        <td key={item.id}>{ formatPrice(item.cost) }</td>
                    ))}
                </tr>

                <tr>
                    <th>
                        Discount
                    </th>
                    {props.order.items.map(item => (
                        <td key={item.id}>-{ formatPrice(item.discount || 0) }</td>
                    ))}
                </tr>

                <tr>
                    <th>
                        Shipping
                    </th>
                    {props.order.items.map(item => (
                        <td key={item.id}>{ formatPrice(item.shipping || 0) }</td>
                    ))}
                </tr>

                <tr>
                    <th>
                        Jobs
                    </th>
                    {props.order.items.map(item => (
                        <td key={item.id} style={{verticalAlign: "top"}}>
                            {(order.buildready_ind == 0 || order.prebuild_ind == 0) &&
                                <small style={{display:"block", color:"red", marginBottom:"5px"}}>
                                    <FontAwesomeIcon icon={faExclamationTriangle} style={{marginRight:"4px"}} />
                                    Checklists Not Complete
                                </small>
                            }
                            {(order.status_id != 13 && order.company_id == 1) &&
                                <small style={{display:"block", color:"red", marginBottom:"5px"}}>
                                    <FontAwesomeIcon icon={faExclamationTriangle} style={{marginRight:"4px"}} />
                                    Payment Not Complete
                                </small>
                            }

                            {item.jobs && 
                                <>
                                    {item.jobs.map(job => (
                                        <Job key={job.id}>
                                            <Link to={"/jobs/job/" + job.id}>
                                                {job.title}
                                            </Link>
                                            <div>
                                                {job.status_title}
                                            </div>
                                        </Job>
                                    ))}
                                </>
                            }
                        </td>
                    ))}
                </tr>

                <tr>
                    <th>
                        Workflow Jobs
                    </th>
                    {props.order.items.map(item => (
                        <td key={item.id} style={{verticalAlign: "top"}}>
                            {item.workflowjobs && 
                                <>
                                    {item.workflowjobs.map(job => (
                                        <Job key={job.id}>
                                            <Link to={"/workflowjob/" + job.id}>
                                                {job.title}
                                            </Link>           

                                            {job.status_title}
                                        </Job>
                                    ))}
                                </>
                            }
                        </td>
                    ))}
                </tr>

                <tr>
                    <th>
                        &nbsp;
                    </th>
                    {props.order.items.map(item => (
                        <td key={item.id} style={{verticalAlign: "top"}}>
                            <>
                                {(!order.voided || order.id == 3047 || order.user_id == 1) &&
                                    <Button
                                        variant="outline-success"                                            
                                        onClick={ () => onCreateJob(item) } 
                                        disabled={order.id != 3047 && (props.order.approvals.filter(a => a.orderitem_id == item.id).find(a => a.approved_ind != 1 && a.approved_ind !=99) != null || !userHasPermission(Permissions.Jobs, Actions.Create))}
                                        style={{display:"block", width:"100%"}}
                                    >
                                        <FontAwesomeIcon icon={faWrench} />{' '}
                                        Create Job...
                                    </Button>
                                }
                                {(userIsAdmin()) &&
                                    <Button
                                        variant="outline-success"                                            
                                        onClick={() => props.onOrderItem(item)} 
                                        style={{display:"block", width:"100%", marginTop: "5px"}}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />{' '}
                                        Create Workflow Job
                                    </Button>
                                }                                
                            </>
                            {(item.jobs && item.jobs.length > 0) &&
                                <Button
                                    variant="outline-secondary"                                            
                                    // onClick={ () => {
                                    //     window.open(settings.API_URL + "pdf/install?orderitem_id=" + item.id);
                                    // }} 
                                    href={`/#/pdfs/install/${item.id}`}
                                    target="_blank"
                                    style={{display:"block", width:"100%", marginTop: "5px"}}
                                >
                                    <FontAwesomeIcon icon={faPrint} />{' '}
                                    Installation Cover Page
                                </Button>                                    
                            }                            
                        </td>
                    ))}
                </tr>

                {(props.actions && userHasPermission(Permissions.Orders, Actions.Edit) && !order.voided) && 
                    <tr>
                        <th>&nbsp;</th>
                        {props.order.items.map(item => (
                            <td key={item.id} className="whitespace-nowrap">
                                {item.type == "sign" && 
                                    <ButtonProcessing 
                                        action="change"
                                        size="sm"
                                        processing={updating == item}
                                        variant="secondary"
                                        icon={faRandom}
                                        caption="Replace"
                                        onClick={ () => onChangeSign(item) } 
                                    />
                                }
                                <ButtonProcessing 
                                    action="edit"
                                    size="sm"
                                    variant="warning"
                                    icon={faPencilAlt}
                                    onClick={ () => onEdit(item) } 
                                />
                                <ButtonProcessing 
                                    action="delete"
                                    size="sm"
                                    processing={removing == item}
                                    variant="danger"
                                    icon={faTimes}
                                    onClick={ () => onRemove(item) } 
                                />
                            </td>
                        ))}
                    </tr>
                }

                {}
                </tbody>
            </Items>

            { (jobs.length > 0) && 
                <div
                    style={{
                        position: 'absolute',
                        bottom: 25,
                        right: 25,
                    }}
                >
                    {jobs.map(job => (                    
                        <Toast style={{backgroundColor: colors.lightPurple}}>
                            <Toast.Header>
                                <strong>Job Created</strong>
                            </Toast.Header>
                            <Toast.Body>
                                <a href={"/#/jobs/job/" + job.id} target="_blank">
                                    {job.title} 
                                </a>
                            </Toast.Body>
                        </Toast>
                    ))}
                </div>
            }

            { selected && selected.mode == "changesign" && 
                <OrderChangeSignDrawer 
                    item={selected.item} 
                    show={true}
                    onChange={(result) => {
                        props.onOrderChange(result);
                        setSelected(null);
                    }} 
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { selected && selected.mode == "item" && 
                <OrderItemDrawer 
                    item={selected.item} 
                    show={true}
                    onChange={(result) => {
                        props.onOrderChange(result);
                        setSelected(null);
                    }} 
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { selected && selected.mode == "job" && 
                <JobCreateDrawer 
                    order={props.order}
                    orderitem={selected.item} 
                    show={true}
                    onCreate={(jobs) => {
                        setJobs(jobs);

                        let orderData = JSON.parse(JSON.stringify(props.order));
                        let itemIdx = orderData.items.findIndex(i => i.id == selected.item.id);

                        if (itemIdx != -1) {
                            for (const job of jobs) {
                                orderData.items[itemIdx].jobs.push(job);
                            }

                            props.onOrderChange(orderData);
                        }

                        if (jobs[0].jobtype == "repair") {
                            setJob(jobs[0]);
                            setSelected({mode: "jobtasks"})
                        }
                        else if (jobs.length == 1) {
                            history.push("/jobs/job/" + jobs[0].id);
                        }

                        setSelected(null)
                    }} 
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { selected && selected.mode == "jobtasks" && 
                <JobInitialTasksDrawer
                    job={job}
                    show={true}
                    onSaved={() => {
                        history.push("/jobs/job/" + job.id);
                    }} 
                    onHide={ () => setSelected(null) } 
                /> 
            }            
            { selected && selected.mode == "addsign" && 
                <AddSignDrawer 
                    show={true}
                    order={props.order}
                    onAdd={(result) => {
                        props.onOrderChange(result);
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { selected && selected.mode == "replaceSign" && 
                <SelectSignDrawer 
                    show={true}
                    prompt="Which sign do you want to replace it with?"
                    filters={{
                        user_id: order.user_id
                    }}
                    onSelect={(sign) => {
                        console.log({sign})
                        setBusy("replaceSign" + selected.item.id);
                        setSelected(null);

                        postData("orders/replacesign", {
                            order_id: order.id,
                            orderitem_id: selected.item.id,
                            sign_id: sign.id
                        }, 
                            function(result) {
                                props.onOrderChange(result);
                            },
                            function(error) {
                                alert("An error occured updating the order.");
                            },
                            function() {
                                setBusy("")
                            }
                        );   
                    }} 
                    onHide={ () => setSelected(null) } 
                /> 
            }            
            { selected && selected.mode == "addaccessory" && 
                <AddAccessoryDrawer 
                    show={true}
                    order={props.order}
                    onAdd={(result) => {
                        props.onOrderChange(result);
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }   
            { selected && selected.mode == "approvalemail" && 
                <EmailDrawer 
                    bodyUrl={"emails/template?template=Art%20Approval&approval_id=" + selected.approval.id}
                    email={{
                        order_id: order.id,
                        template: "simple",
                        from: "production@signmonkey.com",
                        to: order.user_email,
                        subject: "Order #" + order.id + " Art Approval",
                        body: ""
                    }}
                    show={true}
                    onSaved={(email) => {
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }   
            { selected && selected.mode == "approvalrequest" && 
                <ApprovalRequestDrawer 
                    order={order}
                    item={selected.item}
                    show={true}
                    onSaved={(result) => {
                        props.onOrderChange();
                        setSelected({mode:"approvalemail", item: selected.item, approval: result});
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }  
            { selected && selected.mode == "approval" && 
                <ApprovalDrawer 
                    order={order}
                    approval={selected.approval}
                    show={true}
                    onEmail={() => {
                        setSelected({mode:"approvalemail", item: selected.item, approval: selected.approval});
                    }}
                    onSaved={(approval) => {
                        props.onApprovalChanged(approval);
                        setSelected(null)
                    }}
                    onDelete={() => {
                        props.onApprovalDeleted(selected.approval);
                        setSelected(null)
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }  
            </>
        }
        </>
    );
}

export default OrderItemsPanel;