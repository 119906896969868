import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { formatDate, formatDateTime } from "../../helpers/utility";

import BoardPill from "./board-pill";
import BoardPills from "./board-pills";

const Task = styled.div`
    margin: 2px 2px 0px 2px;
    padding: 5px;
    background-color: white;
    border-left: 8px solid #dc3545;
    font-size: 10px;
    border-radius: 5px;

    &.completed {
        border-color: #28a745;
    }

    div.info {
        margin-top: 5px;
        color: #666;
    }
`

function BoardTask({
    task,
    onEdit
}) {
    return (
        <Task className={task.completed_date ? "completed" : ""}>
            {onEdit && 
                <FontAwesomeIcon icon={faEdit} onClick={onEdit} style={{cursor: "pointer", float: "right", margin: "3px"}} />
            }

            <div style={{marginBottom:"5px"}}>
                {task.type_title}: 
                
                <a href={"/#/tasks/task/" + task.id} target="_blank">
                    {task.task}
                </a>
            </div>

            {task.due_date && 
                <div className="info">
                    <span>Due On <strong style={{color:"red"}}>{formatDate(task.due_date)}</strong></span>
                </div>                
            }

            <BoardPills>
                {task.job_id && 
                    <BoardPill label="Job" value={task.job_title} />
                }
                {task.order_id && 
                    <BoardPill label="Order" value={task.order_title} />
                }
                {task.quote_id && 
                    <BoardPill label="Project" value={task.quote_title} />
                }
                {task.sign_id && 
                    <BoardPill label="Sign" value={task.sign_title} />
                }
                {task.user_id && 
                    <BoardPill label="User" value={task.user_title} />
                }
            </BoardPills>

            <div className="info">
                {task.assigned_users ? 
                    <span>Assigned To <strong>{task.assigned_users.split(",").map(u => u.split(" ")[0]).join(", ")}</strong></span>
                : 
                    <span>Unassigned</span>
                }
            </div>
            <div className="info">
                Added by <strong>{task.added_user}</strong> on <strong>{formatDateTime(task.added_date)}</strong>
            </div>
        </Task>
    );
}

export default BoardTask