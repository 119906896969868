import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faPlug } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import NumberField from "../components/fields/field-number"
import SelectField from "../components/fields/field-select"
import ListField from "../components/fields/field-list"
import ColorBox from "../components/color-box";
import ColorSelector from "../drawers/drawer-colorselect"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

function PowersetDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(props.powerset || {});
  const [parts, setParts] = useState([]);
  const [validated, setValidated] = useState(false);
  const [browseColors, setBrowseColors] = useState(false);

  useEffect(() => {
    console.log("powerset drawer", props.powerset)
    setFormData(props.powerset);
  }, [props.powerset]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("jobs/savepowerset", removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the powerset.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();

    const field = event.target.name;
    const value = event.target.value;

    console.log("handleFormChange", field, value)
    
    setFormData(produce(draft => {
      draft[field] = value;

      if (field == "part_id") {
        draft.height = null;
        draft.length = null;
      }
    }));
  }

  function onColorSelected(color) {
    setFormData(produce(draft => {
      draft.color_id = color ? color.id : null;
      draft.color_name = color ? color.name : null;
      draft.color_hex = color ? color.hex_day : null;
    }));

    setBrowseColors(false)
  }

  let part = null;

  if (formData.part_id)
    part = parts.find(p => p.id == formData.part_id);

  return (
      <>
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPlug} />
            &nbsp;
            Powerset
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
            <SelectField 
                name="part_id" 
                label="Power Part"                 
                labelField="title"
                required
                value={formData.part_id} 
                url={"jobs/powerparts"}
                onData={(data) => setParts(data)}
                onChange={(e) => handleFormChange(e)} />

            <TextField 
                name="name" 
                label="Name" 
                value={formData.name} 
                onChange={(e) => handleFormChange(e)} />

            {(part && part.name == "raceway") && 
              <>
                <ListField 
                    name="type" 
                    label="Type"                 
                    required
                    value={formData.type} 
                    list={[
                      {value:"Master", label:"Master"},
                      {value:"Attached", label:"Attached"},
                      {value:"Separated", label:"Separated"},
                    ]}
                    onChange={(e) => handleFormChange(e)} />

                {(formData.type=="Attached" || formData.type=="Separated") && 
                  <ListField 
                      name="parent_id" 
                      label="Parent"                 
                      required
                      value={formData.parent_id} 
                      list={props.powersets}
                      onChange={(e) => handleFormChange(e)} />
                }

                <ListField 
                    name="extension_size" 
                    label="Extension"
                    value={formData.extension_size} 
                    list={[
                      {value:"0", label:"0 ft"},
                      {value:"4", label:"4 ft"},
                      {value:"6", label:"6 ft"},
                      {value:"8", label:"8 ft"},
                      {value:"10", label:"10 ft"},
                      {value:"12", label:"12 ft"},
                    ]}
                    onChange={(e) => handleFormChange(e)} />

                <Form.Row>
                    <Form.Group as={Col}>
                        <NumberField 
                            name="height" 
                            label="Height" 
                            value={formData.height} 
                            onChange={(e) => handleFormChange(e)} 
                        />  
                    </Form.Group>
                    <Form.Group as={Col}>
                        <NumberField 
                            name="length" 
                            label="Length" 
                            value={formData.length} 
                            onChange={(e) => handleFormChange(e)} 
                        />  
                    </Form.Group>
                    <Form.Group as={Col}>
                      <label className="form-label" style={{fontWeight: "normal"}}>Color</label>
                      <div style={{display: "flex", alignItems: "center"}}>
                          <ColorBox                                         
                              color={formData.color_id ? { name: formData.color_name, hex_day: formData.color_hex } : null}
                              size="large"
                              onClick={() => setBrowseColors(true)}
                          />
                          {formData.color_id && formData.color_name}
                      </div>
                    </Form.Group>
                </Form.Row> 
              </>           
            }
            {(part && (part.name == "raceway" || part.name == "cabinet")) && 
                <ListField 
                    name="power_side" 
                    label="Power Side"                 
                    value={formData.power_side} 
                    list={[
                      {value:"Left", label:"Left"},
                      {value:"Right", label:"Right"},
                      {value:"Top", label:"Top"},
                      {value:"Bottom", label:"Bottom"},
                      {value:"Other", label:"Other"},
                    ]}
                    onChange={(e) => handleFormChange(e)} />
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Powerset" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
      {browseColors && 
        <ColorSelector
            onColor={onColorSelected}
            onHide={() => setBrowseColors(false)}
        />
      }
      </>
    )
}

export default PowersetDrawer;