import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faProjectDiagram, faSpinner } from '@fortawesome/free-solid-svg-icons'

import ResourcesPanel from "../panels/panel-resources";
import Button from "../components/buttons/button";
import DrawerTemplate from "./template";
import { getIconForType } from "../helpers/icons";
import FieldLabel from "../components/fields/field-label";

export default function WorkflowTaskResourcesDrawer({
    workflowId,
    task,
    onChange,
    onHide
}) {
  return (
      <DrawerTemplate
        icon={getIconForType("resources")}
        title="Task Resources"
        header={
            <FieldLabel prepend="Workflow Task" value={task.name} />
        }
        onHide={onHide}
      >
        <ResourcesPanel
            type = "workflowitem"
            type_id = {task.workflowitem_id}
            resources = {task.resources || []}
            onChange = {onChange}
        />
      </DrawerTemplate>
    )
}
