import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faDotCircle, faUser } from "@fortawesome/free-solid-svg-icons";
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

import {postData} from "../common/services/server"
import storage from '../settings/storage'

const Form = styled.div`
    display: flex;

    input, button {
        border-radius: 3px;
        margin-right: 3px;
        border: 0px;
    }
`
const Result = styled(Link)`
    display: flex;
    text-decoration: none;
    color: black;

    div.icon {
        flex: 0;
        margin-right: 8px;
        width: 40px;
        min-width: 40px;
    }
    small {
        display: block;
    }
    svg {
        color: #eee;
        display: block;
        margin: 0px auto;
    }
    img {
        max-width: 40px;
        max-height: 40px;
    }
    strong {
        background-color: yellow;
    }
`

function highlightText(text, search) {
    if (text)
        return text.replace(search,"<strong>"+search+"</strong>")
    return text;
}

function getResultLink(result) {
    if (!result) return;
    
    if (result.type == "sign")
        return "/signs/sign/" + result.id;
    else if (result.type == "user")
        return "/users/user/" + result.id;
    else if (result.type == "job")
        return "/jobs/job/" + result.id;
    else if (result.type == "project")
        return "/projects/project/" + result.id;
    else if (result.type == "order")
        return "/orders/order/" + result.id;
}

function SearchResult({ result, search }) {
    return (
        <Result to={getResultLink(result)}>
            <div className="icon">
                {
                    result.preview_url ?  
                        <img src={storage.root + result.preview_url} width={40} />
                    : result.type === "user" ?
                        <FontAwesomeIcon icon={faUser} size="2x" />
                    :
                        <FontAwesomeIcon icon={faDotCircle} size="2x" />
                }
            </div>
            <div>
                <div dangerouslySetInnerHTML={{__html: highlightText(result.title, search)}} />
                {result.subtitle && 
                    <small dangerouslySetInnerHTML={{__html: highlightText(result.subtitle, search)}} />
                }
            </div>
        </Result>
    );
}

function SiteSearchBox(props) {
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(null);
    const [token, setToken] = useState(null);

    function handleSearch(query) {
        setIsLoading(true);
        setResults([])

        if (token) {
            token.cancel();
        }

        setToken(postData("search/site", {search: query},
            function(matches) {
                setResults(matches);
            },
            function(error) {
                console.log(error);
            },
            function() {
                setIsLoading(false);
            }
        ));
    }

    function onChange(result) {
        if (!result) return;
        
        console.log(result)
        setSelected(result)

        history.push(getResultLink(result));
    }

    return (
        <>
            <Form className="search">
                <AsyncTypeahead
                    id="sitesearch"
                    options={results}
                    useCache={false}
                    allowNew={false}
                    isLoading={isLoading}
                    labelKey="title"
                    minLength={2} 
                    defaultInputValue={props.default ? props.default.name : ""}
                    inputProps={{ required: props.required }}
                    onSearch={handleSearch}
                    onChange={ (selected) => onChange(selected[0]) }
                    placeholder="Search for ..."
                    searchText={<><FontAwesomeIcon icon={faSpinner} spin />&nbsp;Searching...</>}
                    filterBy={(option, props) => {
                        return true;
                    }}
                    renderMenuItemChildren={(option, props) => (
                        <SearchResult key={option.id} result={option} search={props.text} />
                    )}
                />
            </Form>
        </>
    );
}

export default SiteSearchBox