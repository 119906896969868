import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelope, faPhone, faComments, faClipboardList, faPlus, faComment, faArchive, faSpinner, faShapes, faExternalLinkAlt, faSms, faCopy, faClipboardCheck, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { addBusinessDays } from "date-fns";

import { useParams, useHistory } from "react-router-dom";
import produce from "immer"

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"
import GoogleImage from "../components/google-image";
import UploadButton from "../components/buttons/button-upload"

import BoardPanel from "../components/board/board-panel"
import BoardPill from "../components/board/board-pill"
import BoardPills from "../components/board/board-pills"
import BoardNote from "../components/board/board-note"
import BoardTask from "../components/board/board-task"
import BoardFile from "../components/board/board-file"
import BoardEmail from "../components/board/board-email"
import BoardCheckList from "../components/board/board-checklist"
import BoardField from "../components/board/board-field";
import BoardSignFields from "../components/board/board-fields-sign";
import BoardSignGroups from "../components/board/board-signgroups";

import AddSignDrawer from "../drawers/drawer-addsign";
import NoteDrawer from "../drawers/drawer-note";
import TaskDrawer from "../drawers/drawer-task";
import EmailDrawer from "../drawers/drawer-email";
import ViewEmailDrawer from "../drawers/drawer-viewemail"
import OrdersDrawer from "../drawers/drawer-orders";
import CartsDrawer from "../drawers/drawer-carts";
import SelectSignsDrawer from "../drawers/drawer-selectsigns";
import SmsMessageList from "../components/sms/message-list";

import { postData, postExternalData } from "../common/services/server"
import { userHasPermission, Permissions, Actions, getUser } from "../common/services/auth";
import { formatDate, formatDateTime, formatPhoneNumber, formatPrice, removeNulls } from "../helpers/utility";
import { colors } from "../settings/settings";
import settings from "../settings";
import clsx from "clsx";
import PageLayout from "../components/page-layout";

const Board = styled.div`
    position: absolute;
    top: 45px;
    left: 55px;
    right: 30%;
    bottom: 0px;
    padding: 10px;

    display: grid;
    grid-template-rows: auto auto auto 1fr;
    gap: 5px;
`
const BuilderContainer = styled.div`
    position: relative;
    overflow: auto;
    border: 1px solid #ccc;
    border-radius: 5px;

    > p {
        font-size: 20px;
        text-align: center;
        margin: 100px;
        color: #999;
    }

    iframe {
        border: 0px;
    }
`
const BuilderTabs = styled.div`
    margin-left: 10px;

    ul {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        display: flex;
        gap: 10px;

        li {
            font-size: 14px;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            border: 1px solid white;
            padding: 2px 6px;
            cursor: pointer;
            background-color: white;

            span {
                background-color: #dc3545;
                padding: 1px 5px;
                color: white;
                border-radius: 3px;
                display: inline-block;
                margin-left: 10px;
                font-size: 80%;
            }
            &.selected {
                border-color: #ccc;
                border-bottom-color: white;
                position: relative;
                top: 7px;
                z-index: 99;
            }
            &:hover {
                background-color: #eee;
            }
        }
    }
`

const Builder = styled.iframe`
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;

    width: 100%;
    height: 100%;
`
const Panels = styled.div`
    position: absolute;
    top: 45px;
    right: 0px;
    width: 30%;
    bottom: 0px;
    overflow: auto;

    background-color: #ccc;
    padding: 2px;
    display: grid;    

    > div {
        border-bottom: 1px solid #ccc;

        > h3 {
            padding: 6px;
            background-color: white;
            font-size: 15px;
            font-weight: 500;
            margin: 0px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            &.green, &.green:hover {
                background-color: ${colors.lightGreen};
            }

            > div {
                display: flex;
                align-items: center;

                > form input {
                    cursor: pointer;
                }
                > form > div {
                    background-color: #eee !important;
                    color: black !important;
                    border: 1px solid #ccc !important;
                    border-radius: 3px !important;
                    padding: 1px 4px !important;
                    margin-right: 5px !important;
                    font-size: 12px !important;

                    &:hover {
                        background-color: white !important;
                        border-color: black !important;
                    }

                    svg {
                        display: none;
                    }
                }
            }

            &:hover {
                background-color: #eee;
            }

            .badge {
                margin-left: 5px;
            }

            button {
                background-color: #eee;
                color: black;
                border: 1px solid #ccc;
                border-radius: 3px;
                padding: 1px 4px;
                font-size: 12px;

                &:hover {
                    border-color: black;
                    background-color: white;
                    color: black;
                }

                svg {
                    color: #666;
                    margin-right: 8px;
                }                
            }
        }
    }
`
const SignPill = styled.div`
    display: flex;
    gap: 3px;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;
    border-left-width: ${props => props.custom ? "8px" : "1px"};
    border-color: ${props => props.approval ? "red" : "#eee"};

    &.selected {
        background-color: #eee;
        border-color: ${colors.blue};

        &:hover {
            border-color: ${colors.blue};
        }    
    }

    &:hover {
        background-color: #eee;
        border-color: #ccc;
    }

    .cost {
        color: green;
    }
`

function ProjectBoardPage(props) {
    const builderRef = useRef();

    let { id } = useParams();

    const [loading, setLoading] = useState("project");
    const [busy, setBusy] = useState({});
    const [project, setProject] = useState(null);
    const [selected, setSelected] = useState({}); 
    const [selectedSignId, setSelectedSignId] = useState(null);
    const [reloadSelectedSignCounter, setReloadSelectedSign] = useState(0);
    const [selectedPanel, setSelectedPanel] = useState(""); 
    const [panelGridTemplate, setPanelGridTemplate] = useState("");
    const [builderTab, setBuilderTab] = useState("");

    useEffect(
        () => {
            setLoading("project");

            postData("projects/projectalldata", { 
                id: id
            },
                function(result) {
                    setProject(result);
                },
                function(error) {
                    alert("Error loading project")
                },
                function() {
                    setLoading("");
                }
            );            
        }, 
        [id]
    );

    useEffect(
        () => {
            // have we already loaded the data for this sign?
            if (selectedSignId) {//} && !project.signs[selectedSignId].details) {
                setLoading("sign")
                notifyBuilder("Reset");

                postExternalData(settings.SITE_API_URL + "builder/signdata?id=" + selectedSignId, {},
                    function(result) {
                        setProject(produce(draft => {
                            draft.signs[selectedSignId] = {
                                ...result, 
                                note: draft.signs[selectedSignId].note, 
                                details: true
                            };
                        }));

                        notifyBuilder("LoadSign", result);
                        notifyBuilder("User", getUser());
                    },
                    function(error) {
                        alert("Error loading sign data")
                    },
                    function() {
                        setLoading("");
                    }
                );  
            }
        }, 
        [selectedSignId, reloadSelectedSignCounter]
    );

    useEffect(
        () => {
            let row1 = panels.length;
            let row2 = row1+1;
    
            if (selectedPanel.length) {
                const panelIndex = panels.findIndex(p => p.name == selectedPanel);
        
                row1 = panelIndex + 1;
                row2 = panels.length - row1;
            }
    
            setPanelGridTemplate(`repeat(${row1}, min-content) 1fr repeat(${row2}, min-content)`);
        }, 
        [selectedPanel]
    );

    function projectUpdated(projectData) {
        // merge data
        if (projectData) {
            setProject(produce(draft => {
                for (const prop of Object.keys(projectData)) {
                    console.log(prop, projectData[prop])                
                    draft[prop] = projectData[prop];
                }
            }));
        }
    }    

    function userUpdated(userData) {
        // merge data
        setProject(produce(draft => {
            if (!draft.userDetails)
                draft.userDetails = {};

            for (const prop of Object.keys(userData)) {
                console.log(prop, userData[prop])                
                draft.user[prop] = userData[prop];
                draft.userDetails[prop] = userData[prop];
            }
        }));
    }   

    function signUpdated(signData, source="") {
        console.log("signUpdated", signData);

        // merge data
        setProject(produce(draft => {
            for (const prop of Object.keys(signData)) {
                draft.signs[signData.id || selectedSignId][prop] = signData[prop];
            }
        }));     
        
        if (source != "builder")
            notifyBuilder("SignUpdated", signData);
    }  

    function closeSelection() {
        setSelected({})
    }

    function addSign(signData, source="") {
        setProject(produce(draft => {
            draft.signs[signData.id] = signData;
        }));

        setSelectedSignId(signData.id);

        if (!builderTab || builderTab == "")
            setBuilderTab("builder");
    }

    function reloadSelectedSign() {
        setReloadSelectedSign(state => state + 1);
    }

    function onBuilderMessage(event) {
        const message = event.data;
        
        console.log("onBuilderMessage", message);

        if (message.type == "SignSaved") {
            // is this a new sign?
            if (Object.keys(project.signs).findIndex(s => s == message.data.id) == -1) 
                addSign(message.data, "builder");
            else
                signUpdated(message.data, "builder");       
        }
    }

    useEffect(() => {
        window.addEventListener("message", onBuilderMessage, false);    
    
        // clean up
        return () => window.removeEventListener("message", onBuilderMessage)
    })      
      
    function notifyBuilder(messageType, messageData) {
        console.log("notifyBuilder", messageType)

        if ( builderRef && builderRef.current) {
            let message = {
                type: messageType,
                data: messageData,
            }
            
            builderRef.current.contentWindow.postMessage(message, "*");
        }
    }

    const panels = [
        {name: "project", title: "Project", icon: faArchive },
        {name: "sign", title: "Sign", icon: faShapes },
        {name: "user", title: "User", icon: faUser },
        {name: "checklist", title: "Checklist", icon: faClipboardList },
        {name: "notes", title: "Notes", icon: faStickyNote, count: project && project.note_count },
        {name: "tasks", title: "Tasks", icon: faClipboardCheck, count:  project && project.task_count },
        {name: "files", title: "Files", icon: faCopy, count:  project && project.file_count },
        {name: "emails", title: "Emails", icon: faEnvelope, count:  project && project.email_count },
        {name: "sms", title: "Text Messages", icon: faSms, count:  project && project.sms_count },
    ];

    function togglePanel(panelName) {
        setSelectedPanel(selectedPanel == panelName ? "" : panelName);

        // is this the first time we are loading user details?
        if (panelName == "user" && !project.userDetails) {
            setLoading("user")

            postData("users/userdata", { 
                id: project.user_id
            },
                function(result) {
                    setProject(produce(draft => {
                        draft.userDetails = result;
                    }));
                },
                function(error) {
                    alert("Error loading user")
                },
                function() {
                    setLoading("");
                }
            );            
        }      
        else if (panelName == "notes" || panelName == "tasks" || panelName == "files" || panelName == "emails" | panelName == "sms") {
            if (!project[panelName]) {
                setLoading(panelName)

                let params = {};

                if (panelName == "emails") {
                    params.user_id = project.user_id;
                    params.type = "email";
                }
                else if (panelName == "sms") {
                    params.user_id = project.user_id;
                    params.type = "sms";
                }
                else {
                    params.project_id = project.id;
                }

                postData((panelName=="sms"?"emails":panelName) + "/data", removeNulls(params),
                    function(result) {
                        setProject(produce(draft => {
                            if (result.pagination)
                                draft[panelName] = result[panelName=="sms"?"emails":panelName];
                            else
                                draft[panelName] = result;
                        }));
                    },
                    function(error) {
                        alert("Error loading " + panelName)
                    },
                    function() {
                        setLoading("");
                    }
                );
            }
        }    
    }

    function onSendEmail(type="email", template=null, templateData=null) {
        let subject = "";
        let preview = "";
        let templateUrl = null;
        
        if (template == "Send Sign") {
            subject = "Sign Design #" + selectedSignId;
        }
        else if (template == "Send Signs") {
            subject = "Sign Designs";
            templateUrl = "emails/template?template=" + escape(template) + "&sign_ids=" + templateData;
        }
        else if (template == "Sign Approved") {
            subject = "Sign Design #" + selectedSignId + " APPROVED";
            preview = "Your Sign Can Now Be Purchased";
        }
        if (template && !templateUrl)
            templateUrl = "emails/template?template=" + escape(template) + "&sign_id=" + selectedSignId;

        setSelected({
            mode:"email", 
            email:{
                type,
                to: type=="sms" ? project.user.phoneno : project.user.email,
                subject,
                preview,
                quote_id: project.id,
                project_title: "#" + project.id,
                sign_id: selectedSignId,
                sign_title: selectedSignId ? "#" + selectedSignId : null,
            },
            templateUrl
        });
    }

    function updateSelectedSignPrice() {
        setLoading("sign");

        postExternalData(settings.SITE_API_URL + "signs/updateprice", {sign_id: selectedSignId}, 
            function(result) {
                signUpdated(result);
            },
            function(error) {
                alert("An error occured calculating the sign price.");
            },
            function() {
                setLoading("");
            }
        );
    }

    function formatDetails(details) {
        let phoneLabelPos = details.indexOf("<th>Phone Number</th>");

        if (phoneLabelPos != -1) {
            let phoneStartPos = details.indexOf("<td>", phoneLabelPos);

            if (phoneStartPos != -1) {
                let phoneEndPos = details.indexOf("</td>", phoneStartPos);

                if (phoneEndPos != -1) {
                    let phoneNo = details.substring(phoneStartPos+4, phoneEndPos);

                    console.log({phoneNo})
                    details = details.replace(phoneNo, formatPhoneNumber(phoneNo));
                }
            }
        }

        return details;
    }

    return (
        <PageLayout>
            <PageHeader 
                pageTitle={"Project #" + id}
                onNewNote={(note) => {
                    console.log("onNewNote", note)
                    if (note.quote_id && note.quote_id == project.id) {
                        setProject(produce(draft => {
                            draft.note_count++;
                        }));
                    }
                }}
                onNewTask={(task) => {
                    console.log("onNewTask", task)
                    if (task.quote_id && task.quote_id == project.id) {
                        setProject(produce(draft => {
                            draft.task_count++;
                        }));
                    }
                }}                
            />
            <Content padding={1} permission={Permissions.Projects}>
            <>
                {
                    loading == "project"
                ?
                    <Loading />
                :    
                    <>
                        <Board>
                            <div style={{display: "grid", gridTemplateColumns: "35% 64%", gap: "5px"}}>
                                <BoardPanel>
                                    <h2>
                                        Project #{project.id}    
                                    </h2>
                                    {project.title &&
                                        <div style={{color: "#666"}}>
                                            <small>{project.title}</small>
                                        </div>
                                    }                                    
                                </BoardPanel>    
                                <BoardPanel>
                                    <h2>
                                        <FontAwesomeIcon icon={faUser} />
                                        &nbsp;
                                        {project.user.name}
                                    </h2>

                                    <BoardPills>
                                        {project.user.city &&
                                            <BoardPill value={project.user.city} />
                                        }
                                        {project.user.state &&
                                            <BoardPill value={project.user.state} />
                                        }

                                        <BoardPill
                                            icon={faEnvelope}
                                            value={project.user.email || "[No Email]"}
                                            buttonIcon={project.user.email ? faComments : null}
                                            buttonColor="#007bff"
                                            buttonClick={() => {
                                                onSendEmail();
                                            }}
                                        />
                                        <BoardPill
                                            icon={faPhone}
                                            value={project.user.phoneno ? formatPhoneNumber(project.user.phoneno) : "[No Phone]"}
                                            buttonIcon={project.user.phoneno ? faComments : null}
                                            buttonColor="#007bff"
                                            buttonClick={() => {
                                                onSendEmail("sms")
                                            }}                                        
                                        />

                                    </BoardPills>                                
                                </BoardPanel>    
                            </div>

                            <BoardPanel>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    fontSize: "12px",
                                }}>
                                    <BoardPills>
                                        {Object.keys(project.signs).map((signId, index) => {
                                            const sign = project.signs[signId];

                                            return (
                                                <SignPill 
                                                    key={index} 
                                                    custom={sign.custom_ind == 1}
                                                    approval={sign.custom_groups_approved_ind == 0}
                                                    className={selectedSignId == sign.id ? "selected":""}
                                                    onClick={() => {
                                                        setBuilderTab(project.signs[sign.id].custom_ind == 1 ? "groups":"builder")
                                                        setSelectedSignId(sign.id);

                                                        if (selectedPanel == "sign")
                                                            setSelectedPanel("");
                                                    }}
                                                >
                                                    <GoogleImage root src={sign.preview_url} style={{maxWidth: "60px", maxHeight: "40px"}} />
                                                    <div>
                                                        #{sign.id}
                                                        <div className={clsx("cost", sign.estimate_expired_ind == 1 && "line-through !text-red-500")}>
                                                            {formatPrice(sign.estimate)}
                                                        </div>
                                                    </div>
                                                </SignPill>
                                            )
                                        })}   

                                        {(busy == "addsign") && 
                                            <SignPill>
                                                <FontAwesomeIcon icon={faSpinner} spin />
                                                Adding...
                                            </SignPill>                                    
                                        }

                                        <DropdownButton id="dropdown-add-button" variant="success" title="Create Sign" className="green">
                                            <Dropdown.Item onClick={(e) => {
                                                setBusy("addsign");

                                                postData("signs/create", { quote_id: project.id },
                                                    function(result) {
                                                        addSign(result);
                                                    },
                                                    function(error) {
                                                        alert("Error creating sign")
                                                    },
                                                    function() {
                                                        setBusy("");
                                                    }
                                                );
                                            }}>
                                                Builder Sign
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) =>  {
                                                setBusy("addsign");

                                                postData("signs/createcustom", { quote_id: project.id },
                                                    function(result) {
                                                        addSign(result);
                                                    },
                                                    function(error) {
                                                        alert("Error creating sign")
                                                    },
                                                    function() {
                                                        setBusy("");
                                                    }
                                                );
                                            }}>
                                                Custom Sign
                                            </Dropdown.Item>
                                        </DropdownButton>

                                        <div>
                                            <button 
                                                className="blue" 
                                                onClick={() => setSelected({mode: "addsign"})}
                                                style={{height: "100%"}}
                                            >
                                                <FontAwesomeIcon icon={faPlus} fixedWidth />
                                                Existing
                                            </button>
                                        </div>
                                    </BoardPills>
                                </div>
                            </BoardPanel> 

                            <BuilderTabs>
                                <ul>
                                    <li 
                                        className={builderTab == "builder" ? "selected":""}
                                        onClick={() => setBuilderTab("builder")}
                                    >
                                        Builder
                                    </li>
                                    <li 
                                        className={builderTab == "groups" ? "selected":""}
                                        onClick={() => setBuilderTab("groups")}
                                    >
                                        Groups

                                        {(selectedSignId && project.signs[selectedSignId].custom_groups_ind == 1) && 
                                            <span>
                                                Custom Shapes
                                            </span>
                                        }
                                    </li>
                                </ul>                                
                            </BuilderTabs>

                            <BuilderContainer>
                                { loading == "sign" ? 
                                    <Loading label="Loading Sign Data..." size="sm" color="black" />
                                : (selectedSignId && builderTab=="groups") ?
                                    <div style={{padding: "5px"}}>
                                        <BoardSignGroups 
                                            sign={project.signs[selectedSignId]} 
                                            stats={{}}
                                            busy={busy}
                                            onSignChange={(updatedSign, options) => {
                                                setLoading("sign");
                                        
                                                if (!options || options.recalculate!==false) {
                                                    updateSelectedSignPrice();
                                                }
                                            }}
                                            onSignApproved={() => {   
                                                reloadSelectedSign();
                                            }}
                                        /> 
                                    </div>
                                : (selectedSignId && builderTab=="builder" && project.signs[selectedSignId].custom_ind == 1) ?
                                    <p>Custom sign can't be loaded in Builder</p>
                                : !selectedSignId ?
                                    <p>No Sign Selected</p>
                                :
                                  null
                                }

                                <Builder 
                                    ref={builderRef} 
                                    src={settings.BUILDER_ADMIN_URL} 
                                    style={{ visibility: selectedSignId && loading != "sign" && project.signs[selectedSignId].custom_ind == 0 && builderTab == "builder" ? "visible":"hidden"}} 
                                />
                            </BuilderContainer>
                        </Board>
                        <Panels
                            style={{
                                gridTemplateRows: panelGridTemplate,
                            }}
                        >
                            {panels.map((panel, index) => (
                                <>
                                    <div key={index}>
                                        <h3 
                                            onClick={() => togglePanel(panel.name)}
                                        >
                                            <div>
                                                <FontAwesomeIcon icon={panel.icon} fixedWidth />
                                                {panel.title}
                                                {(panel.name == "sign" && selectedSignId) &&
                                                    <span>&nbsp; #{selectedSignId}</span>
                                                }
                                            </div>  
                                            <div>
                                            { panel.name == "user" &&
                                                <button onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    window.open("/#/users/user/" + project.user_id);
                                                }}>
                                                    <FontAwesomeIcon icon={faExternalLinkAlt} style={{margin: "0px"}} />
                                                 </button>
                                            }
                                            { (panel.name == "sign" && selectedSignId) &&
                                                <>
                                                    <button onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        window.open("/#/signs/sign/" + selectedSignId);
                                                    }}>
                                                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{margin: "0px"}} />
                                                    </button>
                                                    <button onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        window.open(settings.SIGNDETAILS_URL + project.signs[selectedSignId].uid);
                                                    }} style={{marginLeft:"5px"}}>
                                                        Details
                                                    </button>                                                    
                                                </>
                                            }                                                
                                            { panel.name == "notes" &&
                                                <button onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    setSelected({mode:"note"});                                                    
                                                }}>
                                                    Add Note
                                                </button>
                                            }
                                            { panel.name == "tasks" &&
                                                <button onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    setSelected({mode:"task"});
                                                }}>
                                                    Add Task
                                                </button>
                                            }
                                            { panel.name == "sms" &&
                                                <button onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    onSendEmail("sms")
                                                }}>
                                                    Send
                                                </button>
                                            }                                            
                                            { panel.name == "emails" &&
                                            <>
                                                <DropdownButton 
                                                    id="dropdown-email-button" 
                                                    title="Send" 
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <Dropdown.Item onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        onSendEmail();                                                        
                                                    }}>
                                                        Blank Email
                                                    </Dropdown.Item>

                                                    {selectedSignId &&
                                                        <Dropdown.Item onClick={(e) =>  {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            onSendEmail("email", "Send Sign");
                                                        }}>
                                                            Email Sign #{selectedSignId}
                                                        </Dropdown.Item>
                                                    }
                                                    {selectedSignId &&
                                                        <Dropdown.Item onClick={(e) =>  {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            onSendEmail("email", "Sign Approved");
                                                        }}>
                                                            Email Sign #{selectedSignId} Approved
                                                        </Dropdown.Item>
                                                    }
                                                    <Dropdown.Item onClick={(e) =>  {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setSelected({mode:"emailsigns"});
                                                    }}>
                                                        Email Project Signs
                                                    </Dropdown.Item>                                                                                                    
                                                </DropdownButton>                                                
                                            </>
                                            }                                            
                                            { panel.name == "files" &&
                                                <UploadButton
                                                    uploadUrl={"projects/upload?project_id=" + project.id}
                                                    label="Upload"
                                                    multiple={true}
                                                    onUpload={(upload) => {
                                                        setProject(produce(draft => {
                                                            if (!draft.files)
                                                                draft.files = [];

                                                            draft.files.unshift(upload)
                                                            draft.file_count++;
                                                        }))
                                                    }}
                                                />                                             
                                            }                          

                                            { panel.name == "project" ?       
                                                <BoardPill
                                                    value={project.status_title || "Unknown"}
                                                    color={
                                                        project.status_name == "new" ? colors.lightRed :
                                                        project.status_name == "completed" ? colors.green :
                                                        project.status_name == "working" ? colors.orange :
                                                        null
                                                    }                                                
                                                />    
                                            : panel.name == "checklist" ? 
                                                <span className={"badge " + (project.checklist_answer_count >= project.checklist_count ? "badge-success" : "badge-danger")}>
                                                    {project.checklist_answer_count} of {project.checklist_count}
                                                </span>
                                            : panel.name == "tasks" ? 
                                                <span className={"badge " + (project.task_completed_count >= project.task_count ? "badge-success" : "badge-danger")}>
                                                    {project.task_completed_count} of {project.task_count}
                                                </span>

                                            :
                                                <span className="badge badge-secondary">{panel.count}</span>                                        
                                            }
                                            </div>
                                        </h3>                               
                                    </div>
                                    {(panel.name == "project" && selectedPanel == "project") && 
                                        <div>
                                            <BoardField
                                                label="Title"
                                                type="text"
                                                value={project.title}
                                                saveUrl={`projects/updateprop2?project_id=${project.id}&prop=title`}
                                                onSaved={projectUpdated}
                                            />
                                            <BoardField
                                                label="Description"
                                                type="paragraph"
                                                value={project.description}
                                                saveUrl={`projects/updateprop2?project_id=${project.id}&prop=description`}
                                                onSaved={projectUpdated}
                                            />
                                            <BoardField
                                                label="Manager"
                                                type="select"
                                                value={project.manager_user_id}
                                                optionsUrl="users/employees"
                                                labelField="name"
                                                saveUrl={`projects/updateprop2?project_id=${project.id}&prop=manager_user_id`}
                                                onSaved={projectUpdated}
                                            />
                                            <BoardField
                                                label="Status"
                                                type="select"
                                                value={project.status_id}
                                                optionsUrl="projects/statuses"
                                                saveUrl={`projects/updateprop2?project_id=${project.id}&prop=status_id`}
                                                onSaved={projectUpdated}
                                            />
                                            <BoardField
                                                label="Created"
                                                type="label"
                                                format="datetime"
                                                value={project.created_date}
                                            />                                                                          
                                            <BoardField
                                                label="Created From"
                                                type="label"
                                                value={project.created_from || "Unknown"}
                                            />

                                            {project.details && 
                                                <BoardField
                                                    type="html"
                                                    value={formatDetails(project.details)}
                                                />
                                            }
                                        </div>
                                    }
                                    {(panel.name == "sign" && selectedPanel == "sign") && 
                                        <div>
                                            {loading == "sign" ?
                                                <Loading color="black" nolabel />
                                            : selectedSignId ? 
                                                <BoardSignFields 
                                                    sign={project.signs[selectedSignId]}
                                                    onSignChanged={(signData) => {
                                                        signUpdated(signData);

                                                        // was a property changed that affects the sign price?
                                                        if (signData.options_cost || signData.shipping_method_id)
                                                            updateSelectedSignPrice();
                                                    }}
                                                    onSignCloned={(signData) => {
                                                        addSign(signData);
                                                    }}
                                                    onSignRemoved={() => {
                                                        const signId = selectedSignId;

                                                        setSelectedSignId(null);
                                                        setProject(produce(draft => {
                                                            delete draft.signs[signId];
                                                        }))
                                                    }}
                                                />
                                            :
                                                <p style={{margin:"20px", textAlign:"center"}}>
                                                    No Sign Selected
                                                </p>
                                            }
                                        </div>                                    
                                    }                                    
                                    {(panel.name == "user" && selectedPanel == "user") && 
                                        <div>
                                            {loading == "user" ?
                                                <Loading color="black" nolabel />
                                            :
                                                <div>
                                                    <BoardField
                                                        label="ID"
                                                        type="label"
                                                        value={project.userDetails.id}
                                                    />                                                    
                                                    <BoardField
                                                        label="First Name"
                                                        type="text"
                                                        value={project.userDetails.first_name}
                                                        saveUrl={`users/updateprop2?user_id=${project.user_id}&prop=first_name`}
                                                        onSaved={userUpdated}
                                                    />
                                                    <BoardField
                                                        label="Last Name"
                                                        type="text"
                                                        value={project.userDetails.last_name}
                                                        saveUrl={`users/updateprop2?user_id=${project.user_id}&prop=last_name`}
                                                        onSaved={userUpdated}
                                                    />
                                                    <BoardField
                                                        label="Email"
                                                        type="email"
                                                        value={project.userDetails.email}
                                                        saveUrl={`users/updateprop2?user_id=${project.user_id}&prop=email`}
                                                        onSaved={userUpdated}
                                                    />   
                                                    <BoardField
                                                        label="Phone No"
                                                        type="phone"
                                                        value={project.userDetails.phoneno}
                                                        saveUrl={`users/updateprop2?user_id=${project.user_id}&prop=phoneno`}
                                                        onSaved={userUpdated}
                                                    />   

                                                    <div style={{margin: "10px 4px"}}>
                                                        <BoardPills>
                                                            <BoardPill count={project.userDetails.sign_count} countType="Sign" />    
                                                            <BoardPill count={project.userDetails.note_direct_count} countType="Note" />    
                                                            <BoardPill count={project.userDetails.project_count} countType="Project" />    
                                                            <BoardPill 
                                                                count={project.userDetails.cart_count} 
                                                                countType="Cart" 
                                                                onClick={() => {
                                                                    setSelected({mode:"carts", user_id: project.user_id})
                                                                }}
                                                            />    
                                                            <BoardPill 
                                                                count={project.userDetails.order_count} 
                                                                countType="Order" 
                                                                onClick={() => {
                                                                    setSelected({mode:"orders", user_id: project.user_id})
                                                                }}
                                                            /> 
                                                        </BoardPills> 
                                                    </div>                                                                                                 
                                                </div>
                                            }
                                        </div>
                                    }
                                    {(panel.name == "checklist" && selectedPanel == "checklist") && 
                                        <div>
                                            <BoardCheckList
                                                type="Project"
                                                item="project"
                                                itemId={project.id}
                                                onAnswer={(data) => {
                                                    console.log(data);
                                                    setProject(produce(draft => {
                                                        draft.checklist_answer_count = data.answer_count;
                                                    }))
                                                }}
                                            />
                                        </div>
                                    }                                       
                                    {(panel.name == "notes" && selectedPanel == "notes") && 
                                        <div>
                                            {loading == "notes" ?
                                                <Loading color="black" nolabel />
                                            :
                                                <div>
                                                    {project.notes.map((note, index) => (
                                                        <BoardNote key={index} note={note} onEdit={() => setSelected({mode:"note", note})} />
                                                    ))}                                                      
                                                </div>
                                            }
                                        </div>
                                    }   
                                    {(panel.name == "tasks" && selectedPanel == "tasks") && 
                                        <div>
                                            {loading == "tasks" ?
                                                <Loading color="black" nolabel />
                                            :
                                                <div>
                                                    {project.tasks.map((task, index) => (
                                                        <BoardTask key={index} task={task} onEdit={() => setSelected({mode:"task", task})} />
                                                    ))}                                                      
                                                </div>
                                            }
                                        </div>
                                    }  
                                    {(panel.name == "files" && selectedPanel == "files") && 
                                        <div>
                                        {loading == "files" ?
                                            <Loading color="black" nolabel />
                                        :
                                            <div>
                                                {project.files.map((file, index) => (
                                                    <BoardFile 
                                                        key={index} 
                                                        file={file} 
                                                        onDelete={() => {
                                                            setProject(produce(draft => {
                                                                const idx = draft.files.findIndex(f => f.id == file.id);

                                                                if (idx != -1) {
                                                                    draft.files.splice(idx, 1);
                                                                }
                                                            }));
                                                        }}
                                                    />
                                                ))}                                                      
                                            </div>
                                        }
                                        </div>
                                    }
                                    {(panel.name == "emails" && selectedPanel == "emails") && 
                                        <div>
                                        {loading == "emails" ?
                                            <Loading color="black" nolabel />
                                        :
                                            <div>
                                                {project.emails.map((email, index) => (
                                                    <BoardEmail 
                                                        key={index} 
                                                        email={email} 
                                                        onClick={() => {
                                                            setSelected({mode:"viewemail", email})
                                                        }}
                                                    />
                                                ))}                                                      
                                            </div>
                                        }
                                        </div>
                                    }
                                    {(panel.name == "sms" && selectedPanel == "sms") && 
                                        <div>
                                        {loading == "sms" ?
                                            <Loading color="black" nolabel />
                                        :
                                            <SmsMessageList
                                                messages={project.sms}
                                            />
                                        }
                                        </div>
                                    }    
                                </>                                
                            ))}
                        </Panels>                                  
                    </>
                }
            </>
            </Content>   

            { selected.mode == "note" && 
                <NoteDrawer 
                    show={true}
                    note={selected.note}
                    onSaved={ (note) => {
                        setProject(produce(draft => {
                            if (!draft.notes)
                                draft.notes = [];
                
                            if (selected.note) {
                                let noteIndex = draft.notes.findIndex(n => n.id == selected.note.id);

                                if (noteIndex != -1)
                                    draft.notes[noteIndex] = note;
                            }
                            else {
                                draft.notes.unshift(note);
                                draft.note_count++;
                            }
                        }));

                        closeSelection();
                    }} 
                    onHide={ () => closeSelection() } 
                /> 
            }
            { selected.mode == "task" && 
                <TaskDrawer 
                    show={true}
                    task={selected.task}
                    onSaved={ (task) => {
                        setProject(produce(draft => {
                            if (!draft.tasks)
                                draft.tasks = [];
                
                            if (selected.task) {
                                let taskIndex = draft.tasks.findIndex(t => t.id == selected.task.id);

                                if (taskIndex != -1)
                                    draft.tasks[taskIndex] = task;
                            }
                            else {                                
                                draft.tasks.unshift(task);
                                draft.task_count++;
                            }
                        }));

                        closeSelection();
                    }} 
                    onHide={ () => closeSelection() } 
                />                 
            }
            { selected.mode == "email" && 
                <EmailDrawer 
                    show={true}
                    reply={selected.reply ? selected.email : null}
                    bodyUrl={selected.templateUrl}
                    email={selected.email}
                    onSaved={ (email) => {
                        closeSelection()
                    }} 
                    onHide={ () => closeSelection() } 
                /> 
            }    
            { selected.mode == "viewemail" && 
                <ViewEmailDrawer 
                    show={true}
                    email={selected.email}
                    onSaved={ (email) => {
                        closeSelection()
                    }} 
                    onReply={() => {
                        setSelected({mode: "email", reply: true, email: selected.email})
                    }}
                    onCompleted={() => {
                        closeSelection()
                    }}
                    onHide={ () => closeSelection() } 
                /> 
            }    
            { selected.mode == "emailsigns" && 
                <SelectSignsDrawer 
                    show={true}
                    prompt="Which signs do you want to include in the email?"
                    filters={{
                        project_id: project.id
                    }}
                    onSelect={ (signs) => {
                        console.log({signs})
                        onSendEmail("email", "Send Signs", signs.map(s => s.id).join(","))
                    }} 
                    onHide={ () => closeSelection() } 
                /> 
            }                
            {selected.mode == "addsign" && 
                <AddSignDrawer 
                    show={true}
                    project={project}
                    onAdd={(projectData, signData) => {
                        addSign(signData);
                        closeSelection();
                    }}
                    onHide={ () => closeSelection() } 
                /> 
            }   
            {selected.mode == "orders" && 
                <OrdersDrawer 
                    show={true}
                    filters={selected}
                    onHide={ () => closeSelection() } 
                /> 
            }                                     
            {selected.mode == "carts" && 
                <CartsDrawer 
                    show={true}
                    filters={selected}
                    onHide={ () => closeSelection() } 
                /> 
            }              
        </PageLayout>
    );
}

export default ProjectBoardPage;