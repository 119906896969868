import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faUser, faEnvelope, faSpinner, faCheck, faTrash, faPaperclip, faShapes, faUserPlus, faExternalLinkAlt, faShoppingCart, faArchive, faPencilAlt, faArrowRight } from '@fortawesome/free-solid-svg-icons'

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"
import PropertyList from "../components/properties/property-list"
import Property from "../components/properties/property"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"
import { Permissions } from "../common/services/auth";
import { formatDateTime } from '../helpers/utility'
import { colors } from "../settings/settings";
import storage from "../settings/storage"
import PageLayout from "../components/page-layout";

const ReplyEmail = styled.div`
  padding: 5px 10px;
  border-radius: 3px;
  background-color: ${colors.lightPurple};
  margin: 2px;
  border: 1px solid #e0e0e0;

  a {
    float: right;
    border: 1px solid #ccc;
    background-color: white;
    padding: 3px 6px;
    font-size: 11px;
    border-radius: 3px;
    color: black;
  }
`
const EmailBody = styled.div`
  max-width: 90vw;
  overflow: auto;

  @media (max-width: 425px) {
    .smheader, .smfooter {
      display: none;
    }
  }  
`

function EmailPage(props) {
    let { id, tab } = useParams();

    const search = props.location.search;
    const params = new URLSearchParams(search);
    const embeded = params.get("embeded") == 1;

    const [email, setEmail] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(
        () => {
            setIsLoading(true);

            postData("emails/emaildata", { 
                id: id
            },
                function(result) {
                    setEmail(result);
                },
                function(error) {
                    alert("Error loading email")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        [id]
    );

    return (
        <PageLayout>
            <PageHeader 
                title="Email"
                embeded={embeded}
                parent={{
                    url: "/emails",
                    title: "Emails"
                }}
            />
            <Content embeded={embeded} permission={Permissions.Projects}>
            <>
                {
                    isLoading 
                ?
                    <Loading />
                :    
                    <>           
                        {email.reply && 
                            <ReplyEmail>
                                <>
                                This is a reply to a previous email.
                                
                                <a 
                                    href="#" 
                                    onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    props.onEmail(email.reply)
                                    }}
                                >
                                    View Email
                                </a>
                                </>
                            </ReplyEmail> 
                        }
                        <PropertyList maxWidth="100%">
                            <Property label="Subject" value={email.subject} />

                            <Property label="From" styles={{display: "flex", alignItems:"center", justifyContent: "space-between"}}>
                                {email.from}
                            </Property>
                            <Property label="To" styles={{display: "flex", alignItems:"center", justifyContent: "space-between"}}>
                                {email.to} 
                            </Property>
                             
                            <Property label={email.status == "Sent" ? "Date Sent" : "Date Received"} value={formatDateTime(email.added_date)} />
                            {(email.attachments.length > 0) &&
                                <Property label="Attachments" styles={{display:"block"}}>
                                {email.attachments.map(attachment => (
                                    <div key={attachment.id}>
                                        <FontAwesomeIcon icon={faPaperclip} fixedWidth />
                                        {' '}
                                        <a href={storage.root + attachment.version_url} target="_blank">
                                            {attachment.filename}
                                        </a>
                                    </div>
                                ))}
                                </Property>
                            }
                        </PropertyList>

                        <EmailBody>
                            <span dangerouslySetInnerHTML={{__html: email.body}} />
                        </EmailBody>

                        {(email.replies.length > 0) &&
                            <>
                            <br />
                            <PropertyList>
                                <Property label="Email Replies" styles={{display:"block"}}>
                                {email.replies.map(reply => (
                                    <div key={reply.id}>
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        {' '}
                                        <a 
                                            href="#" 
                                            onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            props.onEmail(reply)
                                            }}
                                        >
                                            {formatDateTime(reply.added_date)}
                                        </a>
                                    </div>
                                ))}
                                </Property>
                            </PropertyList>
                            </>
                        }
                    </>
                }
            </>
            </Content>
        </PageLayout>
    );
}

export default EmailPage;