import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup'
import InputGroup from 'react-bootstrap/InputGroup';
import Badge from 'react-bootstrap/Badge';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faInbox, faTrash, faSpinner, faQuestionCircle, faPlusCircle, faSave, faBan, faUser, faBars } from '@fortawesome/free-solid-svg-icons'

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading";
import InboxUsersDrawer from "../drawers/inboxusers";
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"
import PageLayout from "../components/page-layout";

const CreateInputGroup = styled(InputGroup)`
  margin-bottom: 8px;
`

function InboxAdminPage(props) {
  const employee = null;

  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [isUsersOpen, setIsUsersOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [inboxes, setInboxes] = useState([]);
  const [inbox, setInbox] = useState(null);
  const [processing, setProcessing] = useState([]);
  const [converting, setConverting] = useState([]);

  let nameInput = null;

  useEffect(
    () => {
      postData("inboxes/data?email_ind=1&recommended_ind=1", {},
        function(result) {
          setInboxes(result);
        },
        function(error) {

        },
        function() {
          setIsLoading(false);
        }
      );
    }, 
    []
  );

  function onInboxAction(inbox, action) {
    console.log("onInboxAction", action, inbox)
    setProcessing([...processing, inbox.name]);

    postData('admin/inbox/' + action, {
          inboxId: inbox.id,
          name: inbox.name,
          userId: employee.id
        },
        function(result) {
          setInboxes(produce(inboxes, draft => {
            var indexes = draft.map(function(x) { return x.name; });
            var index = indexes.indexOf(inbox.name);

            console.log(index)

            if (result && result.name) {
              if (index != -1)
                draft[index] = result;
              else 
                draft.unshift(result);
            }
            else {
              draft.splice(index, 1);
            }
          }));
        },
        function(error) {
          alert("An error occured processing the inbox action.");
        },
        function() {
          setProcessing(processing.filter(i => i.name == inbox.name));
          setIsCreating(false);
        }
    );
  }

  function onInboxAlias(inbox) {
    setConverting(produce(converting, draft => {
      draft.push(inbox.name);
    }));
  }
  function onConvertToAlias(inbox) {
    setConverting(produce(converting, draft => {
      draft.splice(converting.indexOf(inbox.name), 1);
    }));
  }
  function onCancelConvert(inbox) {
    setConverting(produce(converting, draft => {
      draft.splice(converting.indexOf(inbox.name), 1);
    }));
  }

  function onCreate() {
    setIsCreating(!isCreating);
    if (nameInput)
      nameInput.focus();
  }
  function onSave(event) {
    const form = event.currentTarget;

    //if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    //}

    setValidated(true);
    onInboxAction({ id: 0, name: nameInput.value}, "create")
  }
  function onUsers(inbox) {
    setInbox(inbox);
    setIsUsersOpen(true);
  }
  function onUsersChanged(userCount) {
    console.log("onUsersChanged", inbox.id, userCount)
    setInboxes(produce(inboxes, draft => {
        var indexes = draft.map(function(x) { return x.id; });
        var index = indexes.indexOf(inbox.id);
        
        draft[index].user_count = userCount;
    }));
  }

  return (
    <PageLayout>
      <PageHeader title="Inbox Manager" help="inboxes">
        <Button onClick={onCreate} variant="outline-success" size="sm">
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;
            Create Inbox
        </Button>
      </PageHeader>
      <Content>
        { isCreating && 
          <Form noValidate validated={validated} onSubmit={(e) => this.onSave(e)}>
            <CreateInputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text><FontAwesomeIcon icon={faInbox} /></InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl required ref={(input) => { nameInput = input; }} />
              <InputGroup.Append>
                <InputGroup.Text>@signmonkey.com</InputGroup.Text>
                <Button type="submit" onClick={onSave} variant="success">
                  <FontAwesomeIcon icon={faSave} />
                </Button>
                <Button onClick={onCreate} variant="light">
                  <FontAwesomeIcon icon={faBan} />
                </Button>
              </InputGroup.Append>
            </CreateInputGroup>
          </Form>
        }
        {
          isLoading 
          ?
            <Loading />
          :
            <ListGroup>
                {inboxes.map(inbox => (
                    <ListGroup.Item key={inbox.name} variant={ inbox.recommended_ind == 1 ? "secondary" : ""} className="small">
                        <span className="float-right">
                          {
                            inbox.id && inbox.id != 0 && 
                            <Button onClick={() => onUsers(inbox) } variant="light" size="sm">
                              <FontAwesomeIcon icon={faUser} />
                              <Badge pill>{inbox.user_count}</Badge>
                            </Button>
                          }
                          { processing.indexOf(inbox.name) != -1 
                              ?
                                  <FontAwesomeIcon icon={faSpinner} spin />
                              :
                                  ( 
                                    inbox.id && inbox.id != 0 
                                    ?
                                      <Button variant="outline-danger" size="sm" onClick={ () => onInboxAction(inbox, "delete") }>
                                          <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    :
                                      converting.indexOf(inbox.name) != -1
                                      ?
                                      <InputGroup>
                                        <InputGroup.Prepend>
                                          <InputGroup.Text><FontAwesomeIcon icon={faInbox} /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl as="select" name="inbox">
                                          {inboxes.map(inbox => (
                                            (inbox.id && inbox.id != 0 &&
                                              <option key={inbox.id} value={inbox.id}>{inbox.title}</option>
                                            )
                                          ))}
                                        </FormControl>
                                        <InputGroup.Append>
                                          <Button type="button" onClick={ () => onConvertToAlias(inbox) } variant="success">
                                            <FontAwesomeIcon icon={faSave} />
                                          </Button>
                                          <Button onClick={ () => onCancelConvert(inbox) } variant="light">
                                            <FontAwesomeIcon icon={faBan} />
                                          </Button>
                                        </InputGroup.Append>
                                      </InputGroup>
                                      :
                                      <Dropdown alignRight>
                                        <Dropdown.Toggle size="sm" variant="outline-success">
                                          <FontAwesomeIcon icon={faBars} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item as="button" onClick={ () => onInboxAlias(inbox) }>
                                            <FontAwesomeIcon icon={faPlusCircle} />&nbsp;Make Inbox Alias
                                          </Dropdown.Item>
                                          <Dropdown.Item as="button" onClick={ () => onInboxAction(inbox, "create") }>
                                            <FontAwesomeIcon icon={faInbox} />&nbsp;Covert To Inbox
                                          </Dropdown.Item>
                                          <Dropdown.Item as="button" onClick={ () => onInboxAction(inbox, "deleteemails") }>
                                            <FontAwesomeIcon icon={faTrash} />
                                            &nbsp;
                                            Delete Emails
                                            &nbsp;
                                            <Badge variant="danger">{inbox.email_count}</Badge>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                  )
                          }
                        </span>

                        <FontAwesomeIcon icon={ inbox.recommended_ind == 1 ? faQuestionCircle : faInbox } />
                        &nbsp;
                        {inbox.name}
                        {inbox.aliases && inbox.aliases.length && 
                          <div><small className="text-muted">{inbox.aliases}</small></div>
                        }
                    </ListGroup.Item>
                ))}
            </ListGroup>
        }
        { inbox && <InboxUsersDrawer show={isUsersOpen} inbox={inbox} onChanged={onUsersChanged} onHide={ () => setIsUsersOpen(false) } /> }
      </Content>
    </PageLayout>
  );
}

export default InboxAdminPage;