import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faExclamationTriangle, faPencilAlt, faSpinner, faCommentDots, faEllipsisH, faUser, faSearch, faExternalLinkAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'

import SelectField from "../fields/field-select"
import ListField from "../fields/field-list"
import EmployeeField from "../fields/field-employee"
import Button from "../buttons/button";

import { postData } from "../../common/services/server"
import { formatDateForInput, formatDateTime, formatPrice, isObject, isString, removeNulls } from "../../helpers/utility";
import Badge from "react-bootstrap/esm/Badge";
import UploadButton from "../buttons/button-upload";
import GoogleLink from "../google-link";
import { colors } from "../../settings/settings";
import ZoomableImage from "../image-zoomable";
import storage from "../../settings/storage";
import Loading from "../loading";
import ColorBox from "../color-box";
import Field from "../fields/field";
import { useGetUser } from "../../api/queries/users";
import UserSelectDrawer from "../../drawers/drawer-userselect";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import clsx from "clsx";
import Input from "../fields/input";
import ButtonMutate from "../buttons/button-mutate";
import TextArea from "../fields/textarea";
import FileSelectDrawer from "../../drawers/drawer-fileselect";
import Tooltip from "../tooltip";
import Select from "../fields/select";

const OldField = styled.div`
    margin: 1px;

    > label {
        margin: 0px;
        font-weight: bold;
    }

    .input-group-text {
        font-size: 11px !important;
        padding-left: 4px !important;
        text-align: left;
        white-space: break-spaces;
    }
    .input-group-prepend .input-group-text {
        width: 80px;
    }
    &.highlight .input-group-prepend .input-group-text {
        background-color: ${colors.lightGreen};
    }
    &.error .input-group-prepend .input-group-text {
        background-color: ${colors.lightRed};
    }

    .input-group-append {
        background-color: white;

        button {
            border-color: #ccc;
        }
    }

    div.html {
        font-size: 14px;
        margin: 5px;
    }
    div.label {
        background-color: #f3f4f6;
        overflow-wrap: break-word;
        flex: 1;
        font-size: 12px;
        padding: 4px;
        border: 1px solid #ced4da;
        // border-top: 1px solid #ced4da;
        // border-left: 1px solid #ced4da;
        // border-bottom: 1px solid #ced4da;
        display: flex;
        align-items: center;     
        justify-content: space-between;   

        button {
            background-color: white;
            padding: 0px 6px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 13px;
        }
    }

    .form-control.google {
        button, input {
            background-color: rgba(150,150,150,.8);
            color: black;
            padding: 1px 6px;
            font-size: 12px;
            line-height: 12px;
            border: 0px;
        }
    }
    .form-control {
        form {
            div {
                background-color: rgba(150,150,150,.8);
                padding: 1px 6px;
                color: black;
            }
        }

        select {
            font-size: 14px;
        }
    }
    .form-control:not(textarea) {
        height: auto !important;
    }
`

// TODO: Maybe always have a border on input/etc controls to make it clearer that they are editable as oppsed to just being labels?


function BoardButton({
    button
}) {
    return (
        <Button 
            onClick={button.onClick} 
            disabled={button.disabled || button.busy}
            variant={button.variant || "solid-secondary"}
            size="tiny"
        >
            {button.icon ?
                <FontAwesomeIcon icon={button.busy ? faSpinner : button.icon} spin={button.busy} />
            : button.busy ?
                <FontAwesomeIcon icon={faSpinner} spin={true} />
            :
                null 
            }
            {button.label}
        </Button>
    )
}

function BoardButtons({
    buttons
}) {
    return (
        <div className="flex items-center gap-1 h-full">
        {buttons.map((button, index) => (    
            <BoardButton key={index} button={button} />
        ))}
        </div>        
    )
}

function BoardFieldContainer({
    label,
    labelClassName,
    saveValue,
    
    required,
    disabled,
    dirty,

    highlight,
    error,
    info,
    infoType,
    infoWarning,

    suffix,
    buttons,

    saveMutation,    
    onSave,
    onSaved,
    onSaveError,
    onSaveSettled,

    onEdit,
    onEditDisabled,
    onBrowse,
    onBrowseDisabled,

    children,
    childrenClassName,
}) {
    return (
        <Field
            prepend={label}
            prependClassName={"w-[80px] text-xs !px-1 rounded-l flex items-center h-full " + labelClassName}
            invalid={error}
            valid={highlight}
            className="!p-[0px] h-full"
            append={
                (((buttons && buttons.length>0) || suffix) || onEdit || saveMutation) ?
                  <div className="text-xs flex self-center items-center justify-center gap-1 h-full">
                    {suffix}

                    {onEdit && 
                        <Button 
                            variant="none-secondary"
                            disabled={onEditDisabled || (saveMutation && required && !saveValue)}
                            onClick={onEdit} 
                            size="tiny"
                        >
                            <FontAwesomeIcon icon={faPencilAlt} fixedWidth />
                        </Button> 
                    }
                    {onBrowse && 
                        <Button 
                            variant="none-secondary"
                            disabled={onBrowseDisabled}
                            onClick={onBrowse} 
                            size="tiny"
                        >
                            <FontAwesomeIcon icon={faEllipsisH} fixedWidth />
                        </Button> 
                    }

                    {((buttons && buttons.length>0) || saveMutation) &&
                        <>
                            {buttons?.map((button,index) => (
                                <React.Fragment key={index}>
                                    {React.isValidElement(button) ? 
                                        button
                                        :
                                        <BoardButton button={button} />
                                    }
                                </React.Fragment>
                            ))}
                            {saveMutation &&
                                <ButtonMutate
                                    icon={faSave}
                                    variant={dirty ? "primary":"none-secondary"} 
                                    disabled={disabled || (required && !saveValue)}
                                    size="tiny"
                                    mutation={saveMutation}
                                    mutationData={saveValue}
                                    onMutate={() => {
                                        if (onSave)
                                            return onSave(saveValue);
                                        else 
                                            return saveValue
                                    }}
                                    onMutateSuccess={onSaved}
                                    onMutateError={onSaveError}
                                    onMutateSettled={onSaveSettled}
                                />
                            }
                        </>
                    }
                  </div>
                :
                  null 
            }   
            appendClassName="!px-1"
        >
            <div className={clsx(
                "gap-1 text-xs h-full bg-gray-100", 
                info && infoType == "icon" ? "flex items-center" : "grid",
                childrenClassName
            )}>
                {children}

                {(info && infoType == "icon") ?
                    <Tooltip content={info}>
                        <FontAwesomeIcon icon={faInfoCircle} className="text-gray-400" />
                    </Tooltip>
                : (info && infoType == "error") ? 
                    <div className="text-xs text-red-500">
                        {info}
                    </div>                    
                : info ? 
                    <div className="text-xs text-gray-400">
                        {info}
                    </div>
                :
                    null
                }     
                {infoWarning && 
                    <div className="text-xs text-orange-500 flex items-center gap-1">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        {infoWarning}
                    </div>
                } 
            </div>             
        </Field>
    )
}

function BoardUserField({
    name,
    value,
    saveMutation,
    ...props
}) {
    const { data:user, isFetching, error } = useGetUser(value, {enabled: !!value});

    const [busy, setBusy] = useState(false);
    const [browse, setBrowse] = useState(false);

    const buttons = [
        <Button 
            size="tiny" 
            variant="outline-secondary"
            icon={busy ? faSpinner:faSearch}
            iconSpin={busy}
            disabled={!saveMutation || busy}
            onClick={() => setBrowse(true)}
        />
    ]

    return (
      <>
        <BoardFieldContainer 
            {...props} 
            saveValue={value} 
            buttons={buttons}
        >
            {isFetching ?
                <FontAwesomeIcon icon={faSpinner} spin />
            : user ?
                <div className="flex items-center gap-1 text-sm">
                    <FontAwesomeIcon icon={faUser} />
                    {user.name}

                    <Link to={"/users/user/" + user.id} target="_blank">
                        <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" className="ml-1 text-blue-500" />
                    </Link>
                </div>
            : value ?
                `User ${value} Not Found`
            : 
                ""
            }
        </BoardFieldContainer>
        
        {browse && 
            <UserSelectDrawer
                show={true}
                actions={[
                    {
                        label: "Select User",                        
                        onClick: (userData) => {
                            setBusy(true);
                            
                            saveMutation.mutate(userData.id, {
                                onError: (error) => {
                                    alert("Error updating field");
                                },
                                onSettled: () => {
                                    setBusy(false);
                                    setBrowse(false);
                                }            
                            });
                        }
                    }
                ]}
                onHide={() => setBrowse(false)}
            />
        }
      </>
    )
}

function BoardChildrenField({
    children,
    ...props
}) {
    return (
        <BoardFieldContainer {...props} childrenClassName="p-1">
            {children}
        </BoardFieldContainer>
    )
}

function BoardHtmlField({
    value,
    ...props
}) {
    return (
        <BoardFieldContainer {...props} childrenClassName="p-1 text-xs">
            <div dangerouslySetInnerHTML={{__html: value}} />
        </BoardFieldContainer>
    )
}

function BoardLabelField({
    value,
    ...props
}) {
    return (
        <BoardFieldContainer {...props} childrenClassName="p-1">
            <div className="self-center">
                {value}
            </div>
        </BoardFieldContainer>
    )
}

function BoardColorField({
    value,
    colorProps,
    ...props
}) {
    return (
        <BoardFieldContainer {...props} childrenClassName="p-1">
            <div className="flex items-center gap-2">
                <ColorBox color={{
                    id: value,
                    name: colorProps.colorName,
                    hex_day: colorProps.colorHex
                }}/>
                {colorProps.colorName}
            </div>  
        </BoardFieldContainer>      
    )
}

function BoardCurrencyField({
    value,
    ...props
}) {
    return (
        <BoardFieldContainer {...props} childrenClassName="p-1">
            <div className="self-center">
                {formatPrice(value || 0)}
            </div>
        </BoardFieldContainer>
    )
}

function BoardSelectField({  
    name,
    required,
    disabled,
    value,
    selectProps,  
    ...props
}) {
    const [dirty, setDirty] = useState(false);
    const [fieldValue, setFieldValue] = useState(value);

    useEffect(
        () => {
            console.log("select value", value)
            setFieldValue(value || "");
            setDirty(false);
        }, 
        [value]
    );

    return (
        <BoardFieldContainer 
            {...props} 
            saveValue={fieldValue}
            onSaved={() => setDirty(false)}
            dirty={dirty} 
            childrenClassName="p-0 self-center"
        >
            <Select
                name={name}
                required={required}
                disabled={disabled}
                value={fieldValue} 
                className="!p-[2px]"
                onChange={(e, value, item) => {
                    console.log(e.target.value)
                    setFieldValue(e.target.value);
                    setDirty(true);

                    if (props.onChange) props.onChange(e.target.value, item);
                }}                 
                {...selectProps}
            /> 
        </BoardFieldContainer>
    )
}

function BoardInputField({  
    name,
    type,
    required,
    readonly,
    disabled,
    value,
    numberProps,
    ...props
}) {
    const [dirty, setDirty] = useState(false);
    const [fieldValue, setFieldValue] = useState(value);

    useEffect(
        () => {
            setFieldValue(value || "");
            setDirty(false);
        }, 
        [value]
    );

    return (
        <BoardFieldContainer 
            {...props} 
            disabled={disabled}
            saveValue={fieldValue}
            onSaved={() => setDirty(false)}
            dirty={dirty} 
            childrenClassName="p-0 self-center !flex items-stretch gap-0 bg-white"
        >
            {(numberProps && numberProps.format == "currency") && 
                <div className="self-center">$</div>
            }
            <Input 
                name={name}
                type={type}
                className="py-[2px] px-[6px] w-full"
                required={required}
                disabled={disabled}
                readonly={readonly}
                value={fieldValue} 
                onChange={(e) => {
                    setFieldValue(e.target.value);
                    setDirty(true);

                    if (props.onChange) props.onChange(e.target.value);
                }}
               
            /> 
            {(numberProps && numberProps.format == "percent") && 
                <div className="self-center">%</div>
            }
        </BoardFieldContainer>
    )
}

function BoardTextareaField({  
    name,
    type,
    required,
    readonly,
    disabled,
    value,
    textareaProps={
        // rows
    },
    ...props
}) {
    const [dirty, setDirty] = useState(false);
    const [fieldValue, setFieldValue] = useState(value);

    useEffect(
        () => {
            setFieldValue(value || "");
            setDirty(false);
        }, 
        [value]
    );

    return (
        <BoardFieldContainer 
            {...props} 
            saveValue={fieldValue}
            onSaved={() => setDirty(false)}
            dirty={dirty} 
            childrenClassName="p-0 self-center"
        >
            <TextArea 
                name={name}
                rows={textareaProps.rows || 3}
                className="py-[2px] px-[6px]"
                required={required}
                disabled={disabled}
                readonly={readonly}
                value={fieldValue} 
                onChange={(e) => {
                    setFieldValue(e.target.value);
                    setDirty(true);

                    if (props.onChange) props.onChange(e.target.value);
                }}
               
            /> 
        </BoardFieldContainer>
    )
}

function BoardCheckboxesField({  
    name,
    required,
    disabled,
    value, // comma separated list
    checkboxesProps={
        // items
        // itemsQuery
        // idField
        // labelField
        // direction
    },
    ...props
}) {
    const [dirty, setDirty] = useState(false);
    const [fieldValue, setFieldValue] = useState("");

    const {
        items,
        itemsQuery,
        idField,
        labelField
    } = checkboxesProps;

    useEffect(
        () => {
            setFieldValue(value || "");
            setDirty(false);
        }, 
        [value]
    );

    return (
        <BoardFieldContainer 
            {...props} 
            saveValue={fieldValue}
            onSaved={() => setDirty(false)}
            dirty={dirty} 
            childrenClassName="p-1 self-center"
        >
           {(itemsQuery && itemsQuery.isFetching) ?
                <FontAwesomeIcon icon={faSpinner} spin />
            : 
                <div 
                    className={clsx(
                        checkboxesProps.direction != "vertical" && "flex items-center flex-wrap gap-2",
                        checkboxesProps.direction == "vertical" && "grid gap-1 max-h-[200px] overflow-y-auto",
                    )}
                >
                    {(items || itemsQuery.data || []).map((item,index) => {
                        const id = "" + item[idField || "id" || "value"]; // always work with strings
                        const label = item[labelField || "label" || "title"];
                        const checked = fieldValue.split(",").includes(id);

                        return (
                            <div key={index} className="flex items-center gap-1">
                                <input 
                                    type="checkbox" 
                                    name={name}
                                    value={id}
                                    checked={checked}
                                    onChange={(e) => {
                                        let fieldValues = fieldValue.split(",").filter(v => v.length);

                                        if (e.target.checked)
                                            fieldValues.push(id);
                                        else {
                                            fieldValues.splice(fieldValues.indexOf(id), 1)
                                        }

                                        setFieldValue(fieldValues.join(","))
                                        setDirty(true); 
                                    }}                                    
                                />
                                {label}
                            </div>
                        )
                    })}
                </div> 
           }
        </BoardFieldContainer>
    )
}

function BoardFileField({  
    name,
    required,
    disabled,
    value, // filename
    fileProps={
        // url
        // upload: true,
        // uploadUrl: "signs/uploadtechnical?sign_id=" + sign.id,
        // uploadFilenameProp: "" -- name of the prop from the upload result that contains the upload filename
        // uploadFileUrlProp: ""
        // extensions: ["jpg","png"]
        // browse: true,
        // browseFilters: {
        //     sign_id: sign.id,
        //     project_id: sign.quote_id,
        //     user_id: sign.user_id
        // }
        // browseMutation
    },
    ...props
}) {
    const [browse, setBrowse] = useState(false);
    const [fieldValue, setFieldValue] = useState("");
    const [fieldUrl, setFieldUrl] = useState("");

    useEffect(
        () => {
            setFieldValue(value || "");
        }, 
        [value]
    );
    useEffect(
        () => {
            if (fileProps)
                setFieldUrl(fileProps.url || "");
        }, 
        [fileProps]
    );

    return (
      <>
        <BoardFieldContainer 
            {...props} 
            childrenClassName="p-1 self-center"
            suffix={
                (fileProps.upload || fileProps.browse) ?
                    <>
                        {fileProps.browse &&
                            <Button 
                                icon={faSearch}
                                iconSpin={fileProps.browseMutation.isLoading}
                                variant="outline-secondary"
                                size="tiny"
                                disabled={disabled || fileProps.browseMutation.isLoading}
                                onClick={() => setBrowse(true)}
                            >
                                Browse
                            </Button>
                        }
                        {fileProps.upload &&
                            <UploadButton
                                uploadUrl={fileProps.uploadUrl}
                                disabled={disabled}
                                label="Upload"
                                size="tiny"
                                // TODO: Redo this using our standard button
                                className="!bg-transparent border !border-gray-500 !text-gray-500 hover:!bg-gray-500 hover:!text-white [&>svg]:!text-gray-500 [&>svg]:hover:!text-white !text-xs !p-[2px] cursor-pointer"
                                extensions={fileProps.extensions}
                                onUpload={(result) => {
                                    setFieldValue(result[fileProps.uploadFilenameProp || "filename"]);
                                    setFieldUrl(result[fileProps.uploadFileUrlProp || "url"]);

                                    if (fileProps.onUpload)
                                        fileProps.onUpload(result)
                                }}
                            />                        
                        }
                    </>
                :
                    null
            }
        >
            {fieldUrl ?
                <a href={storage.root + fieldUrl} target="_blank" className="text-blue-500 self-center">
                    {fieldValue}
                </a>
            :
                fieldValue
            }
        </BoardFieldContainer>

        {browse && 
            <FileSelectDrawer
                show={true}
                filters={fileProps.browseFilters}
                onFile={(file) => {
                    setFieldValue(file.filename);                   
                    fileProps.browseMutation.mutate(file.id);
                    setBrowse(false);
                }}
                onHide={() => setBrowse(false)}
            />
        }        
      </>
    )
}
 
function BoardImageField({  
    name,
    required,
    disabled,
    value, // url
    imageProps={
        // zoom: true,
        // zoomUrl: ...
        // upload: true,
        // uploadUrl: "signs/uploadtechnical?sign_id=" + sign.id,
        // uploadFileUrlProp: ""
        // extensions: ["jpg","png"]
        // browse: true,
        // browseFilters: {
        //     sign_id: sign.id,
        //     project_id: sign.quote_id,
        //     user_id: sign.user_id
        // }
        // browseMutation
    },
    ...props
}) {
    const [browse, setBrowse] = useState(false);
    const [url, setUrl] = useState("");
    const [zoomUrl, setZoomUrl] = useState("");

    useEffect(
        () => {
            setUrl(value || "");
        }, 
        [value]
    );
    useEffect(
        () => {
            if (imageProps)
                setZoomUrl(imageProps.zoomUrl || "");
        }, 
        [imageProps]
    );

    return (
      <>
        <BoardFieldContainer 
            {...props} 
            childrenClassName="p-1 self-center"
            suffix={
                (imageProps.upload || imageProps.browse) ?
                    <>
                        {imageProps.browse &&
                            <Button 
                                icon={faSearch}
                                iconSpin={imageProps.browseMutation.isLoading}
                                variant="outline-secondary"
                                size="tiny"
                                disabled={disabled || imageProps.browseMutation.isLoading}
                                onClick={() => setBrowse(true)}
                            >
                                Browse
                            </Button>
                        }
                        {imageProps.upload &&
                            <UploadButton
                                uploadUrl={imageProps.uploadUrl}
                                disabled={disabled}
                                label="Upload"
                                size="tiny"
                                // TODO: Redo this using our standard button
                                className="!bg-transparent border !border-gray-500 !text-gray-500 hover:!bg-gray-500 hover:!text-white [&>svg]:!text-gray-500 [&>svg]:hover:!text-white !text-xs !p-[2px] cursor-pointer"
                                extensions={imageProps.extensions}
                                onUpload={(result) => {
                                    setUrl(result[imageProps.uploadFileUrlProp || "url"]);
                                    setZoomUrl(result[imageProps.uploadFileUrlProp || "url"]);

                                    if (imageProps.onUpload)
                                        imageProps.onUpload(result)
                                }}
                            />                        
                        }
                    </>
                :
                    null
            }
        >
            <div className="max-w-[60px]">
                <ZoomableImage
                    url={storage.root + url}
                    zoomUrl={storage.root + zoomUrl}
                    maxHeight="20px"
                    maxWidth="50px"
                />
            </div>
        </BoardFieldContainer>

        {browse && 
            <FileSelectDrawer
                show={true}
                filters={imageProps.browseFilters}
                onFile={(file) => {
                    setUrl(file.url);                   
                    imageProps.browseMutation.mutate(file.id);
                    setBrowse(false);
                }}
                onHide={() => setBrowse(false)}
            />
        }        
      </>
    )
}

function BoardCommaListField({  
    name,
    value,
    ...props
}) {
    return (
        <BoardFieldContainer 
            {...props} 
            childrenClassName="p-1 self-center"
        >
            {isString(value) ? 
                <div className="flex items-center gap-1 flex-wrap">
                    {value.split(",").map(item => (
                        <div className="rounded bg-gray-200 px-1 py-[2px]">
                            {item}
                        </div>
                    ))}
                </div>
            :
                ""
            }
        </BoardFieldContainer>
    )
}

function BoardField(props) {
    const {
        id,
        type,
        name,
        label, 
        html,
    
        required,
        
        info,
        infoType,
        infoWarning,
        inset,
    
        value, 
        children,
        format,
        disabled,
    
        highlight,
        error,
    
        selectProps,
        inputProps,
        numberProps,
        textareaProps,
        colorProps,
        fileProps,
        imageProps,

        optionsUrl,
        labelField,
        idField,
        labelWarning,
        blankLabel,
    
        buttons, // label button(s)
    
        items, // list 
        listType, // list
        listDirection, //list
        emptyLabel, // select
        loading,
    
        colorName,
        colorHex,
    
        variant, // badge
        url,
        suffix,
        zoom,
        zoomUrl,
    
        saveUrl,
        saveMutation,


        saveIcon,
        onSaved,
        onEdit,
        onEditDisabled,
        onBrowse,
        onChange,
    
        onClick
    } = props;

    const [busy, setBusy] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [fieldValue, setFieldValue] = useState("");

    useEffect(
        () => {
            setFieldValue(value || "");
            setDirty(false);
        }, 
        [value, id]
    );

    function onSave() {
        setBusy(true);
        setErrorMessage("");

        let saveValue = fieldValue;

        if (type == "user") {
            saveValue = fieldValue.id;
        }

        if (saveMutation) {
            saveMutation.mutate(saveValue, {
                onError: (error) => {
                    console.log("error", error)
                    alert("Error updating field");
                },
                onSettled: () => {
                    setBusy(false);
                }            
            });
        }
        else {
            postData(saveUrl, removeNulls({ 
                value: saveValue
            }),
                function(result) {
                    setDirty(false);
                    
                    if (onSaved) onSaved(result);
                },
                function(error, status, response) {
                    if (response && response.data && response.data.message)
                        setErrorMessage(response.data.message);
                    
                    if (!response || !response.data || !response.data.message)
                        alert("Error updating field");
                },
                function() {
                    setBusy(false);
                }
            );    
        }    
    }

    return (
        type == "user" ?
            <BoardUserField {...props} />
        : type == "children" ?
            <BoardChildrenField {...props} />
        : type == "html" ?
            <BoardHtmlField {...props} />
        : type == "label" ?
            <BoardLabelField {...props} />
        : type == "number" && (saveMutation || numberProps) ?
            <BoardInputField {...props} type="number" inputProps={inputProps} />
        : type == "date" && saveMutation ?
            <BoardInputField {...props} type="date" value={formatDateForInput(props.value)} inputProps={inputProps} />
        : type == "currency" ?
            <BoardCurrencyField {...props} />
        : (type == "select" && selectProps) ?
            <BoardSelectField {...props} selectProps={selectProps} />
        : (type == "color") ?
            <BoardColorField {...props} />
        : (type == "text" && saveMutation) ?
            <BoardInputField {...props} inputProps={inputProps} />
        : (type == "textarea" && saveMutation) ?
            <BoardTextareaField {...props} textareaProps={textareaProps} />
        : (type == "checkboxes") ?
            <BoardCheckboxesField {...props} />
        : (type == "file") ?
            <BoardFileField {...props} fileProps={fileProps} />
        : (type == "image") ?
            <BoardImageField {...props} imageProps={imageProps} />
        : (type == "commalist") ?
            <BoardCommaListField {...props} />
        
        // TODO: Convert the rest of the types to separate components
        
        :
            <OldField className={error || errorMessage ? "error" : highlight ? "highlight":""}>
                { type == "badge" ?
                    <Badge 
                        size="sm" 
                        variant={variant || "secondary"}
                        style={{
                            display: "block",
                            fontWeight: "normal"
                        }}
                    > 
                        {value}
                    </Badge>
                :
                    <InputGroup size="sm" className="h-full">
                        <InputGroup.Prepend>
                            <InputGroup.Text style={{color: required && !fieldValue ? "red":"inherit"}}>
                                {label}
                                {required && " *"}

                                {labelWarning && 
                                    <FontAwesomeIcon icon={faExclamationTriangle} style={{marginLeft:"5px", color: "orange"}} />
                                }
                            </InputGroup.Text>
                        </InputGroup.Prepend>  
                        {type == "currency" ?
                            <InputGroup.Prepend style={{width:"20px"}}>
                            <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>                  
                        : type == "percent" && 
                            <InputGroup.Prepend style={{width:"20px"}}>
                            <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>                  
                        }

                        {type == "select" ?
                            <>
                            {loading ?
                                <div className="form-control">
                                    <Loading size="sm" padding={1} nolabel />
                                </div>                          
                            :   
                                <div className="form-control">
                                    <SelectField 
                                        url={optionsUrl}
                                        required
                                        disabled={disabled}
                                        labelField={labelField || "title"}
                                        idField={idField || "id"}
                                        value={fieldValue} 
                                        emptyLabel={emptyLabel}
                                        groupStyle={{margin: "0px", flex: "1"}}
                                        style={{border: "0px", paddingLeft: "0px"}}
                                        onChange={(e) => {
                                            setFieldValue(e.target.value);
                                            setDirty(true);

                                            if (onChange) onChange(e.target.value);
                                        }} 
                                    />   
                                    {(info && info.length > 0) && 
                                        <Form.Text muted>
                                            {info}
                                        </Form.Text>
                                    }
                                    {(infoWarning && infoWarning.length > 0) && 
                                        <Form.Text style={{color: "orange"}}>
                                            <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
                                            {infoWarning}
                                        </Form.Text>
                                    }
                                </div>
                            }
                            </>               
                        : type == "list" ?
                            <>
                            {loading ?
                                <div className="form-control">
                                    <Loading size="sm" padding={1} nolabel />
                                </div>                          
                            :
                                <div className="form-control">
                                    <ListField 
                                        required
                                        name={name}
                                        disabled={disabled}
                                        labelField={labelField || "title"}
                                        blankLabel={blankLabel}
                                        value={fieldValue} 
                                        groupStyle={{margin: "0px", flex: "1", maxHeight: "200px", overflow: "auto"}}
                                        style={{border: "0px", paddingLeft: "0px"}}
                                        type={listType}
                                        direction={listDirection}
                                        list={items}
                                        onChange={(e) => {
                                            setFieldValue(e.target.value);
                                            setDirty(true);

                                            if (onChange) {
                                                onChange(e.target.value);
                                            }
                                        }} 
                                    />
                                    {(info && info.length > 0) && 
                                        <Form.Text muted>
                                            {info}
                                        </Form.Text>                            
                                    }
                                    {(infoWarning && infoWarning.length > 0) && 
                                        <Form.Text style={{color: "orange"}}>
                                            <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
                                            {infoWarning}
                                        </Form.Text>
                                    }                            
                                </div>
                            }
                            </>
                        : type == "boolean" ?
                            <div className="form-control" style={{display: "flex"}}>
                                <Form.Check 
                                    type="radio"
                                    value={0}
                                    checked={fieldValue != 1}
                                    disabled={disabled}
                                    label="No"
                                    inline
                                    size="sm"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setFieldValue(0);
                                            setDirty(true);

                                            if (onChange) 
                                                onChange(0);                           
                                        }
                                    }}
                                />
                                &nbsp;
                                <Form.Check 
                                    type="radio"
                                    value={1}
                                    checked={fieldValue == 1}
                                    disabled={disabled}
                                    label="Yes"
                                    inline
                                    size="sm"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setFieldValue(1);
                                            setDirty(true);

                                            if (onChange) 
                                                onChange(1);
                                        }
                                    }}
                                />  

                                {(info && info.length > 0) && 
                                    <Form.Text muted>
                                        {info}
                                    </Form.Text>                            
                                }     
                                {(infoWarning && infoWarning.length > 0) && 
                                    <Form.Text style={{color: "orange"}}>
                                        <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
                                        {infoWarning}
                                    </Form.Text>
                                }                                                   
                            </div>
                        : type == "countbutton" ?
                            <div className="label">  
                                <button onClick={onClick} disabled={disabled}>
                                    {value}
                                </button>
                            </div>
                        : type == "employee" ? 
                            <EmployeeField 
                                value={fieldValue} 
                                disabled={disabled}
                                wide
                                onChange={(e) => {
                                    setFieldValue(e.target.value);
                                    setDirty(true);

                                    if (onChange) 
                                        onChange(e.target.value);
                                }} 
                            />       
                        : type == "color" ?
                            <div className="form-control">
                                <div className="flex items-center gap-2">
                                    <ColorBox color={{
                                        id: fieldValue,
                                        name: colorName,
                                        hex_day: colorHex
                                    }}/>
                                    {colorName}
                                </div>
                            </div>
                        : (type=="googleimage" || type=="googlelink") ?
                            <div className="form-control google" style={{position: "relative", height: "auto"}}>
                                {type=="googleimage" ? 
                                    <ZoomableImage 
                                        url={storage.root + value}
                                        zoomUrl={zoomUrl ? storage.root + zoomUrl : null}
                                        maxHeight="80px"
                                    />
                                :
                                    <GoogleLink 
                                        root 
                                        src={url} 
                                        label={value} 
                                    />
                                }

                                {(info && info.length > 0) && 
                                    <Form.Text muted>
                                        {info}
                                    </Form.Text>                            
                                }   
                                {(infoWarning && infoWarning.length > 0) && 
                                    <Form.Text style={{color: "orange"}}>
                                        <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
                                        {infoWarning}
                                    </Form.Text>
                                }

                                {buttons && 
                                    <div style={{position: "absolute", display: "flex", gap: "2px", right: "2px", bottom: "1px"}}>
                                        {buttons.map((button, index) => (    
                                            <React.Fragment key={index}>
                                            {button.type == "browse" ? 
                                                <Button size="tiny" onClick={button.onClick} disabled={button.disabled}>
                                                    {button.icon ?
                                                        <FontAwesomeIcon icon={button.busy ? faSpinner : button.icon} spin={button.busy} />
                                                    : button.busy ?
                                                        <FontAwesomeIcon icon={faSpinner} spin={true} />
                                                    :
                                                        null 
                                                    }                                                
                                                    Browse
                                                </Button>                                   
                                            : button.type == "upload" ? 
                                                <UploadButton
                                                    uploadUrl={button.uploadUrl}
                                                    disabled={button.disabled}
                                                    label="Upload"
                                                    size="sm"
                                                    extensions={type=="googleimage" ? ["jpg","png"] : null}
                                                    onUpload={button.onUpload}
                                                />
                                            :
                                                null
                                            }
                                            </React.Fragment>
                                        ))}                         
                                    </div>
                                }
                            </div>     
                        : type == "pills" ? 
                            <div className="form-control" style={{
                                display: "flex",
                                flexFlow: "wrap",
                                gap: "5px"
                            }}>
                                {(value || "").split(",").filter(v => v.length > 0).map((item, index) => (                                
                                    <div key={index} style={{
                                        backgroundColor: "#eee",
                                        border: "1px solid #ccc",
                                        padding: "3px 8px",
                                        borderRadius: "10px",
                                        fontSize: "85%",
                                    }}>
                                        {item}
                                    </div>
                                ))}
                            </div>                        
                        : type == "commalist" ? 
                            <div className="label">{isString(fieldValue) ? fieldValue.replace(",", ", ") : ""}</div>                        
                        : (inset || (info && info.length) || (infoWarning && infoWarning.length) || (errorMessage && errorMessage.length)) ?
                            <div className="form-control">
                                <FormControl
                                    as={type == "paragraph" || type=="textarea" ? "textarea":"input"}
                                    type={type == "number" || type=="date" ? type : type=="currency"||type=="percent" ? "number" : "text"}
                                    rows={type == "paragraph" ? 5 : type=="textarea" ? 3 : 1}
                                    value={fieldValue}
                                    disabled={disabled || busy}
                                    onChange={(event) => {
                                        setFieldValue(event.target.value); 
                                        setDirty(true);
                                        setErrorMessage("");

                                        if (onChange) 
                                            onChange(event.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && type != "paragraph" && type != "textarea") {
                                            console.log("enter")
                                            onSave();
                                        }
                                    }}
                                />
                                {(info && info.length > 0) && 
                                    <Form.Text muted>
                                        {info}
                                    </Form.Text>
                                }
                                {(infoWarning && infoWarning.length > 0) && 
                                    <Form.Text style={{color: "orange"}}>
                                        <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
                                        {infoWarning}
                                    </Form.Text>
                                }     
                                {(errorMessage && errorMessage.length > 0) && 
                                    <Form.Text style={{color: "red"}}>
                                        <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
                                        {errorMessage}
                                    </Form.Text>
                                }                                                            
                            </div>
                        :
                            <FormControl
                                as={type == "paragraph" || type=="textarea" ? "textarea":"input"}
                                type={type == "number" || type=="date" ? type : type=="currency"||type=="percent" ? "number" : "text"}
                                rows={type == "paragraph" ? 5 : type=="textarea" ? 3 : 1}
                                value={fieldValue}
                                disabled={disabled || busy}
                                onChange={(event) => {
                                    setFieldValue(event.target.value); 
                                    setDirty(true);
                                    setErrorMessage("");

                                    if (onChange) 
                                        onChange(event.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && type != "paragraph" && type != "textarea") {
                                        onSave();
                                    }
                                }}
                            />
                        }

                        {suffix && 
                            <InputGroup.Append>
                                <InputGroup.Text>{suffix}</InputGroup.Text>
                            </InputGroup.Append>                    
                        }
                        {onBrowse && 
                            <InputGroup.Append>
                                <Button 
                                    variant="outline-secondary"
                                    onClick={onBrowse} 
                                    disabled={disabled}
                                    size="tiny"
                                >
                                    <FontAwesomeIcon icon={faEllipsisH} fixedWidth />
                                </Button>                            
                            </InputGroup.Append>
                        }                    
                        {onEdit && 
                            <InputGroup.Append>
                                <Button 
                                    variant="outline-secondary"
                                    disabled={onEditDisabled}
                                    onClick={onEdit} 
                                    size="tiny"
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} fixedWidth />
                                </Button>                            
                            </InputGroup.Append>
                        }
                        {(saveUrl || saveMutation) &&
                            <InputGroup.Append>
                                <Button 
                                    onClick={() => onSave()} 
                                    variant={dirty ? "primary":"outline-secondary"} 
                                    disabled={disabled || busy || (required && !fieldValue)}
                                    size="tiny"
                                >
                                    <FontAwesomeIcon icon={busy ? faSpinner: (saveIcon || faSave)} spin={busy} fixedWidth />
                                </Button>
                            </InputGroup.Append>
                        } 
                    </InputGroup>
                }
            </OldField>
        
    );
}

export default BoardField