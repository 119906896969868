import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import NumberField from "../components/fields/field-number"
import CurrencyField from "../components/fields/field-currency"
import FileField from "../components/fields/field-file"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import GoogleImage from "../components/google-image"
import { postData, uploadImage } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

const Sign = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;

    img { margin-right: 10px; }
`

function OrderItemDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(
    () => {
        setFormData(JSON.parse(JSON.stringify(props.item)));
    }, 
    [props.item]
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      const {file, thumbnailData, previewData, ...data} = formData;

      postData("orders/saveitem?id=" + props.item.id + "&order_id=" + props.item.order_id, removeNulls(data), 
        function(response) {

          if (file) {
            uploadImage("orders/uploadpreview?id=" + response.orderitem_id + "&order_id=" + props.item.order_id, file, thumbnailData, previewData, 
              function(progress) {
                console.log("progress", progress)
              },
              function(response) {
                props.onChange(response)
              },
              function(error) {
                alert("An error occured uploading the item preview.");
              },
              function() {
                setIsProcessing(false);
              }
            );
          }
          else {
            setIsProcessing(false);
            props.onChange(response)
          }

        },
        function(error) {
          alert("An error occured updating the order.");
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;

    setFormData(produce(draft => {
      draft[field] = event.target.value;
    }));
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPencilAlt} />
            &nbsp;
            {props.item.id ? "Edit":"Add"} Order Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>

            {props.item.preview_url && 
              <Sign>
                  <GoogleImage 
                      root 
                      src={props.item.preview_url} 
                      style={{
                          maxWidth: "125px",
                          maxHeight: "75px"
                      }}
                  />
              </Sign>
            }
                
            {!props.item.sign_id && 
              <FileField 
                  name="file" 
                  label="Preview File" 
                  onFile={(file) => {
                    console.log("preview file", file)
                    setFormData(produce(draft => {
                      draft.file = file;
                    }));
                  }} 
                  onThumbnail={(thumbnailData) => {
                    setFormData(produce(draft => {
                      draft.thumbnailData = thumbnailData;
                    }));
                  }}
                  onPreview={(previewData) => {
                    setFormData(produce(draft => {
                      draft.previewData = previewData;
                    }));
                  }}
              />
            }

            <TextField 
                    name="title" 
                    label="Title" 
                    required
                    value={formData.title} 
                    onChange={(e) => handleFormChange(e)} 
                />  
            <TextField 
                    name="description" 
                    label="Description" 
                    value={formData.description} 
                    onChange={(e) => handleFormChange(e)} 
                />  
            <NumberField 
                    name="quantity" 
                    label="Quantity" 
                    required
                    value={formData.quantity || 1} 
                    onChange={(e) => handleFormChange(e)} 
                />                  
            <Form.Row style={{maxWidth: "475px"}}>
                <Form.Group as={Col}>
                    <CurrencyField 
                        name="cost" 
                        label="Cost" 
                        labelBold={false}
                        required
                        value={formData.cost} 
                        onChange={(e) => handleFormChange(e)} 
                    />  
                </Form.Group>
                <Form.Group as={Col}>
                    <CurrencyField 
                        name="discount" 
                        label="Discount" 
                        labelBold={false}
                        value={formData.discount} 
                        onChange={(e) => handleFormChange(e)} 
                    />  
                </Form.Group>
                <Form.Group as={Col}>
                    <CurrencyField 
                        name="shipping" 
                        label="Shipping" 
                        labelBold={false}
                        value={formData.shipping} 
                        onChange={(e) => handleFormChange(e)} 
                    /> 
                </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Item" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default OrderItemDrawer;