import React, { useState, useEffect } from "react";
import produce from "immer"
import clsx from "clsx";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faStickyNote, faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import Loading from "../../components/loading"
import JobStatusDrawer from "../../drawers/drawer-workflowjobstatus";
import JobTaskDrawer from "../../drawers/drawer-jobworkflowtask";
import JobTasksDrawer from "../../drawers/drawer-jobworkflowtasks";

import { formatDate, formatDateTime } from "../../helpers/utility"
import storage from "../../settings/storage";

import parisLogo from "../../images/paris.jpg"
import ZoomableImage from "../../components/image-zoomable";
import { useGetWorkflowJobDashboardData } from "../../api/queries/workflowjobs";
import Button from "../../components/buttons/button";
import DrawerTemplateDataList from "../../drawers/template-datalist";
import { useGetWorkflowJobNotes } from "../../api/queries/notes";
import Note from "../../components/note";
import { List, ListItem } from "../../components/list";
import JobWorkflowPanel from "../../panels/jobworkflow-workflow";

// function padToTwoDigits(num) {
//     return num.toString().padStart(2, '0');
// }

function JobsDashboard(props) {
    const [filteredJobs, setFilteredJobs] = useState([]);

    const [filters, setFilters] = useState({signmonkey: true, parissigns: true})
    const [selectedJob, setSelectedJob] = useState(null);
    const [selected, setSelected] = useState(null);

    const workflowJobDashboardQuery = useGetWorkflowJobDashboardData();
    const jobs = workflowJobDashboardQuery.data;

    const workflowJobNotesQuery = useGetWorkflowJobNotes(selected && selected.mode == "Notes" ? selected.job.id : null, {enabled: selected && selected.mode == "Notes"?true:false});

    useEffect(
        () => {
            if (jobs) {
                console.log({filters});

                setFilteredJobs(jobs.filter(j => {
                    return (!filters.ship_date || j.ship_date == filters.ship_date) && 
                        (
                            (filters.signmonkey && j.company_id == 1) ||
                            (filters.parissigns && j.company_id == 2)
                        );
                }));
            }
        }, 
        [jobs, filters]
    );

    return (
        <>
            {
                workflowJobDashboardQuery.isLoading 
            ?
                <Loading />
            :    
                <>
                    <div style={{
                        marginBottom: "10px"
                    }}>
                        <Form.Check 
                            type="checkbox"
                            inline
                            label="SignMonkey"
                            value={1}
                            checked={filters.signmonkey}   
                            onChange={(e) => {
                                e.persist();

                                setFilters(produce(draft => {
                                    draft.signmonkey = e.target.checked
                                }))
                            }}
                        />   
                        <Form.Check 
                            type="checkbox"
                            inline
                            label={
                                <div className="flex items-center gap-1"> 
                                    <img src={parisLogo} height="16" alt="Paris Signs Logo" className="h-4" />
                                    Paris Signs
                                </div>
                            }
                            value={1}
                            checked={filters.parissigns}   
                            onChange={(e) => {
                                e.persist();

                                setFilters(produce(draft => {
                                    draft.parissigns = e.target.checked
                                }))
                            }}
                        />                                                  
                    </div>

                    <List>
                        {filteredJobs.map(job => {
                            return (
                                <React.Fragment key={job.id}>
                                    <ListItem
                                        size="sm"
                                        headerClassName={clsx(
                                            job.status_name == "hold" && "!bg-gray-300"
                                        )}
                                        count={job.note_count}
                                        countVariant={job.note_count > 0 ? "warning":null}
                                        onCountClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setSelected({mode:"Notes", job});
                                        }}
                                        menu={[
                                            {name: "status", icon: faPencilAlt, caption: "Change Status"}
                                        ]}
                                        onMenuItem={(item) => {
                                            if (item.name == "status")
                                                setSelected({mode:"Status", job});
                                        }}
                                        actions={
                                            <button
                                                className="p-1 rounded hover:bg-gray-100"
                                                onClick={() => setSelectedJob(selectedJob == job ? null : job)}
                                            >
                                                <FontAwesomeIcon icon={selectedJob == job ? faCaretUp:faCaretDown} />
                                            </button>
                                        }
                                    >
                                        <div className="grid grid-cols-[160px,190px,auto,1fr] gap-3">
                                            <div>
                                                <ZoomableImage
                                                    url={storage.root + job.preview_url}
                                                    height={30}
                                                    maxHeight="30px"
                                                    maxWidth="150px"
                                                />
                                            </div>
                                            <div>
                                                <div className="flex items-center gap-2">
                                                    <a href={"#/workflowjob/" + job.id} target="_blank" className="text-blue-500 font-bold hover:underline" onClick={(e) => e.stopPropagation()}>
                                                        {job.title}
                                                    </a>
                                                    {job.company_id == 2 &&
                                                        <img src={parisLogo} alt="Paris Signs" height="16" width="30" />
                                                    }
                                                </div>
                                                <div className="text-sm">
                                                    Order:&nbsp;
                                                    <a href={"#/orders/order/" + job.order_id} target="_blank" className="text-gray-500 hover:underline">
                                                        #{job.order_id}
                                                    </a>  
                                                </div>
                                            </div>
                                            <div className="text-xs grid grid-cols-[100px,auto] items-center gap-1">
                                                <div>Scheduled Ship:</div>
                                                <Button
                                                    size="tiny" 
                                                    variant="outline-secondary" 
                                                    style={{
                                                        padding:"1px 4px",
                                                    }}
                                                    onClick={() => setSelected({mode:"ShipDate", job})}
                                                >
                                                    {formatDate(job.ship_date)}
                                                </Button>                                                
                                                <div>Predicted Ship:</div>
                                            </div>
                                            <div>
                                                <div className="flex flex-wrap justify-end gap-1">
                                                    {job.tasks.map(task => (
                                                        <button 
                                                            key={task.id} 
                                                            className={clsx("status status-" + task.status, "text-xs hover:scale-[1.05]")}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();

                                                                setSelected({mode: "Task", job, task});
                                                            }}
                                                        >
                                                            {task.name}
                                                        </button>
                                                    ))}

                                                    {(job.tasks.length > 1) &&
                                                        <button 
                                                            className="status status-pending text-xs hover:scale-[1.05]"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                setSelected({mode: "Tasks", job});
                                                            }}
                                                        >
                                                            ...
                                                        </button>                                                    
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </ListItem>
                                    {selectedJob == job && 
                                        <div className="p-2 bg-gray-100">
                                            <JobWorkflowPanel 
                                                jobId={selectedJob.id} 
                                                showTasksList={false}
                                                overflow={true}
                                            />
                                        </div>
                                    }
                                </React.Fragment>                                
                            )
                        })}
                    </List>

                    {(!workflowJobDashboardQuery.isError && filteredJobs.length == 0) && 
                        <div className="text-gray-500 text-xl">
                            No Jobs Found
                        </div>
                    }
                    {workflowJobDashboardQuery.isError && 
                        <div className="text-red-500 text-xl">
                            Error Loading Jobs
                        </div>                
                    }
                </>
            }

            { (selected && selected.mode == "Task") && 
                <JobTaskDrawer 
                    jobId={selected.job.id}
                    jobWorkflowId={selected.task.job_workflow_id || selected.task.id} 
                    show={true}
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { (selected && selected.mode == "Tasks") && 
                <JobTasksDrawer 
                    filters={{
                        job_id: selected.job.id,
                        statuses: ['active', 'inprogress', 'paused', 'problem'],
                    }}
                    show={true}
                    onTask={(job, task) => setSelected({mode: "Task", job, task})}
                    onHide={ () => setSelected(null) } 
                /> 
            }              
            { (selected && selected.mode == "Status") && 
                <JobStatusDrawer
                    show={true}
                    job={selected.job}                    
                    onHide={ () => setSelected(null) } /> 
            }  
            { (selected && selected.mode == "Notes") && 
                <DrawerTemplateDataList
                    title="Job Notes"
                    icon={faStickyNote}
                    query={workflowJobNotesQuery}
                    checkReloading={true}
                    itemRender={(note) => (
                        <Note note={note} />
                    )}
                    onHide={() => setSelected(null)}
                />
            }                                    
        </>
    );
}

export default JobsDashboard;