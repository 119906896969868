import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShapes } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"

const Products = styled.table`
    width: 100%;
    tr th {
        background-color: #e0e0e0;
        padding: 10px;
        font-size: 12px;
    }
    tr td {
        padding: 5px 10px;
        border-bottom: 1px solid #ccc;
        font-size: 12px;
    }
`

function SelectProductsDrawer({
    product, 
    existingProducts,
    onSelect, 
    onHide
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
      setIsProcessing(true);

      postData("products/data2", {}, 
        function(results) {
          setProducts(results.products.filter(p => p.id != product.id));
        },
        function(error) {
          alert("An error occured loading the products.");
        },
        function() {
          setIsProcessing(false);
        }
      );   
  }, []);

  return (
    <>
        <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faShapes} />
                &nbsp;
                Select Products
            </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minWidth: "800px"}}>
                { isProcessing ? 
                    <Loading />
                :
                products.length == 0 ?
                    <p style={{color:"red"}}>No products found.</p>
                :
                    <Products>
                        <thead>
                            <tr>
                                <th>Product</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map(product => (
                                <tr key={product.id}>
                                    <td>
                                        <Form.Check 
                                            name="product_id"                                
                                            value={product.id}
                                            checked={product.selected_ind == 1}
                                            disabled={existingProducts && existingProducts.find(p => p.id == product.id)}
                                            inline 
                                            label={product.title} 
                                            size="sm"
                                            type="checkbox" 
                                            onChange={(e) => {
                                                e.persist();
                                                
                                                setProducts(produce(draft => {
                                                    draft[products.findIndex(p => p.id == product.id)].selected_ind = e.target.checked ? 1:0;
                                                }))
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Products>
                }
            </Modal.Body>
            <Modal.Footer style={{display: "flex", justifyContent: "space-between"}}>
                <Button 
                    variant="outline-success" 
                    onClick={() => onSelect(products.filter(p => p.selected_ind == 1))}
                >
                    Select Products
                </Button>

                <Button variant="outline-secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>        
    </>
    )
}

export default SelectProductsDrawer;