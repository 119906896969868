import React from "react";

import Field from "./field";
import Input from "./input";

function FieldNumber({
    step,
    ...props
}) {
    return (
        <Field {...props}>
            <Input 
                type="number" 
                step={step || 1}
                className="border-0 m-0 p-1 w-full"
                {...props}
            />
        </Field>
    )
}

export default FieldNumber