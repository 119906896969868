import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'

import storage from "../../settings/storage"
import { formatDateTime } from '../../helpers/utility'
import { linkifyText } from "../../helpers/utility";
import { colors } from '../../settings/settings';

const Messages = styled.div`
  > div {
    white-space: pre-line;
    background-color: #eee;
    padding: 5px;
    font-size: 80%;
    border-radius: 8px;
    margin-bottom: 3px;
    width: 70%;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    word-wrap: break-word;

    small {
      color: #999;
    }
    
    > div:nth-child(2) {
      display: flex;
    } 

    &.Sent {
      margin-left: 0px;
      background-color: ${colors.lightPurple};
    }
    &.New {
      border: 1px solid red;
      cursor: pointer;
    }

    a {
      color: blue;
    }
    svg {
      font-size: 16px;    
      color: #999;  
    }
  }
`

function SmsMessageList({ messages, onMessage }) {

  return (
    <Messages>
    {messages.map(message => (
        <div 
          key={message.id} 
          className={message.status + " " + (message.isNew ? "New":"")}
          style={{cursor: onMessage ? "pointer":"default"}}
          onClick={(e) => {
            //console.log("click")
            //e.preventDefault();
            e.stopPropagation();

            if (onMessage)
                onMessage(message)
          }}                              
        >
          <div>
            <div dangerouslySetInnerHTML={{__html: linkifyText(message.body, "_blank")}} />
            <div><small>{formatDateTime(message.added_date)}</small></div>
          </div>
          {(message.attachment_count > 0) && 
            <div>
              {message.attachments.split(",").map((attachment,index) => (
                  <div key={index}>
                      <a href={storage.root + attachment} target="_blank">
                        <FontAwesomeIcon icon={faPaperclip} fixedWidth />
                      </a>
                  </div>
              ))}                                  
            </div>
          }
        </div>
    ))}
    </Messages>
  );
}

export default SmsMessageList;