import React from "react";

import Field from "./field";

function FieldLabel({value, children, ...props}) {
    return (
        <Field {...props}>
            <div className="bg-gray-100 p-1 self-center h-full">
                {value || children}
            </div>
        </Field>
    )
}

export default FieldLabel