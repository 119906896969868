import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Card from  'react-bootstrap/Card';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faShapes, faSpinner } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import GoogleImage from "../components/google-image"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

const Preview = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 15px;

  img {
    display: block;
    margin: 0px auto;
    max-height: 100px;
  }
`

function SignTemplateDrawer(props) {
  const [pages, setPages] = useState([])
  const [products, setProducts] = useState([])

  const [busy, setBusy] = useState("");
  const [formData, setFormData] = useState(props.group);

  useEffect(() => {
    postData("products/data", {}, 
      function(results) {
        setProducts(results.filter(p => p.url))
      },
      function(error) {
        alert("An error occured loading the product data.");
      }
    );

    postData("pages/data", {}, 
      function(results) {
        setPages(results)
      },
      function(error) {
        alert("An error occured loading the page data.");
      }
    );    
  }, []);

  useEffect(() => {
    let signData = JSON.parse(JSON.stringify(props.sign));

    signData.productIds = signData.product_ids.split(",");
    signData.pageIds = signData.page_ids.split(",");

    setFormData(signData);
  }, [props.sign]);


  function onPageChange(e) {
    const page_id = e.target.value;
    const checked = e.target.checked;

    setBusy("page" + page_id);

    postData("signs/" + (checked ? "add":"remove") + "templatepage", {
        sign_id: props.sign.id,
        page_id
    }, 
        function(response) {
            props.onSaved(response);
        },
        function(error) {
            alert("An error occured updating the template sign.");
        },
        function() {
            setBusy("");
        }
    );
  }

  function onProductChange(e) {
    const product_id = e.target.value;
    const checked = e.target.checked;

    setBusy("product" + product_id);

    postData("signs/" + (checked ? "add":"remove") + "templateproduct", {
        sign_id: props.sign.id,
        product_id
    }, 
        function(response) {
            props.onSaved(response);
        },
        function(error) {
            alert("An error occured updating the template sign.");
        },
        function() {
            setBusy("");
        }
    );
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faShapes} />
            &nbsp;
            Sign Template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Preview>
               <GoogleImage root src={props.sign.preview_url} />
            </Preview>

            <Card>
                <Card.Header>Products</Card.Header>
                <Card.Body>
                {products && products.map(product => (
                    <div key={product.id}>
                        {busy == ("product" + product.id) ? 
                            <div>
                                <FontAwesomeIcon icon={faSpinner} spin />
                                {product.title}
                            </div>
                        :
                            <Form.Check 
                                name="product"                                
                                value={product.id}
                                checked={formData.productIds && formData.productIds.indexOf(""+product.id) != -1}
                                label={product.title} 
                                type="checkbox" 
                                onChange={onProductChange}
                            />
                        }
                    </div>
                ))}
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>Landing Pages</Card.Header>
                <Card.Body>
                {pages && pages.map(page => (
                    <div key={page.id}>
                        {busy == ("page" + page.id) ? 
                            <div>
                                <FontAwesomeIcon icon={faSpinner} spin />
                                {page.type}
                            </div>
                        :                        
                            <Form.Check 
                                name="page"                                
                                value={page.id}
                                checked={formData.pageIds && formData.pageIds.indexOf(""+page.id) != -1}
                                label={page.type} 
                                type="checkbox" 
                                onChange={onPageChange}
                            />
                        }
                    </div>
                ))}
                </Card.Body>
            </Card>            
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default SignTemplateDrawer;