import React from "react"
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../tooltip";

function Button({
    icon,
    iconSpin,
    variant,
    size,
    disabled,
    confirmation,
    onClick,
    href,
    target,
    style,
    className,
    tooltip,
    tooltipPlacement,
    tooltipClassName,
    children
}) {
    const history = useHistory();

    return (
        <Tooltip content={tooltip} placement={tooltipPlacement} className={tooltipClassName}>
            <button 
                disabled={disabled}
                className={clsx(
                    "rounded !border",
                    "hover:scale-[1.01]",

                    size == "sm" ? 
                        "!text-sm !px-4 !py-1" : 
                    size == "lg" ? 
                        "text-lg px-8 py-3" : 
                    size == "tiny" || size == "xs" ? 
                        "text-xs p-[2px]" :
                    size == "icon" ?
                        "p-1 text-xs" :
                        "px-6 py-2",

                    variant == "solid-success" && "bg-white border-green-500 text-green-500 hover:!bg-green-500 hover:!text-white",
                    variant == "solid-secondary" && "bg-white border-gray-500 text-gray-500 hover:!bg-gray-500 hover:!text-white",
                    variant == "solid-primary" && "bg-white border-blue-500 text-blue-500 hover:!bg-blue-500 hover:!text-white",
                    variant == "solid-warning" && "bg-white border-yellow-500 text-yellow-500 hover:!bg-yellow-500 hover:text-white [&>svg]:!text-yellow-500 [&>svg]:hover:!text-white",
                    variant == "solid-danger" && "bg-white border-red-500 text-red-500 hover:!bg-red-500 hover:text-white [&>svg]:!text-red-500 [&>svg]:hover:!text-white",

                    variant == "outline-success" && "border-green-500 text-green-500 hover:bg-green-500 hover:text-white [&>svg]:!text-green-500 [&>svg]:hover:!text-white",
                    variant == "outline-primary" && "border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white [&>svg]:!text-blue-500 [&>svg]:hover:!text-white",
                    variant == "outline-secondary" && "border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white [&>svg]:!text-gray-500 [&>svg]:hover:!text-white",
                    variant == "outline-light" && "border-gray-300 text-gray-300 hover:bg-gray-400 hover:text-white [&>svg]:!text-gray-400 [&>svg]:hover:!text-white",
                    variant == "outline-warning" && "border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-white [&>svg]:!text-yellow-500 [&>svg]:hover:!text-white",
                    variant == "outline-danger" && "border-red-500 text-red-500 hover:bg-red-500 hover:text-white [&>svg]:!text-red-500 [&>svg]:hover:!text-white",

                    variant == "secondary-danger" && "border-gray-500 text-gray-500 hover:border-red-500 hover:text-red-500 [&>svg]:!text-gray-500 [&>svg]:hover:!text-red-500",
                    variant == "light-danger" && "border-gray-300 text-gray-300 hover:border-red-500 hover:text-red-500 [&>svg]:!text-gray-300 [&>svg]:hover:!text-red-500",

                    variant == "none-secondary" && "border-none text-gray-500 hover:bg-gray-500 hover:text-white",
                    variant == "none-danger" && "border-none text-gray-500 hover:bg-red-500 hover:text-white",
                    variant == "none-light" && "border-none text-gray-300 hover:bg-gray-300 hover:text-white",

                    variant == "success" && "border-green-600 bg-green-500 text-white hover:bg-green-600",
                    variant == "primary" && "border-blue-600 bg-blue-500 text-white hover:bg-blue-600",
                    variant == "secondary" && "border-gray-600 bg-gray-500 text-white hover:bg-gray-600",
                    variant == "light" && "border-gray-400 bg-gray-300 text-black hover:bg-gray-400",
                    variant == "warning" && "border-yellow-600 bg-yellow-500 text-white hover:bg-yellow-600",
                    variant == "danger" && "border-red-600 bg-red-500 text-white hover:bg-red-600",
                    
                    className,

                    disabled && "!bg-gray-100 !text-gray-400 !border-gray-400 !hover:bg-gray-100 !hover:text-gray-400",
                )}
                style={style}
                onClick={(e) => {
                    if (confirmation) {
                        if (!window.confirm(confirmation)) {
                            return;
                        }
                    }
                    
                    if (onClick) {
                        onClick(e);
                    }
                    else if (href && href.length) {
                        if (href.indexOf("http") == -1 && !target)
                            history.push(href);
                        else
                            window.open(href);
                    }
                }} 
            >
                {icon && 
                    <FontAwesomeIcon 
                        icon={iconSpin ? faSpinner: icon} 
                        spin={iconSpin}
                        fixedWidth
                        className={clsx(
                            children && "mr-1",
                        )} 
                    />
                }
                {children}
            </button>
        </Tooltip>
    );
}

export default Button