import React from "react";

import Field from "./field";
import TextArea from "./textarea";

function FieldTextArea(props) {
    return (
        <Field {...props}>
            <TextArea                 
                className="border-0 m-0 p-1 w-full"
                {...props}
            />
        </Field>
    )
}

export default FieldTextArea