import React, { useState } from "react";

import SelectField from "./field-select";

function CouponSelect(props) {
    const [coupons, setCoupons] = useState([]);

    function onCoupon(couponId) {
        props.onChange(coupons.find(c => c.id == couponId))
    }

    return (
        <SelectField 
            name={props.name}
            label={props.label}                 
            labelField="code"
            value={props.value} 
            wide
            url={"coupons/data?"}
            onData={(data) => setCoupons(data)}
            onChange={(e) => onCoupon(e.target.value)} 
            groupStyle={{marginBottom:"0px"}}
        />
    );
  }

export default CouponSelect