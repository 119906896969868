import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import GoogleImage from "../components/google-image"
import { postData } from "../common/services/server"
import { formatPrice } from "../helpers/utility"

const Items = styled.table`
    width: 100%;
    font-size: 12px;

    thead {
        th {
            background-color: #eee;
            padding: 5px;
        }
    }
    tbody {
        td {
            border-bottom: 1px solid #eee;
            padding: 5px;
        }
    }
`

function CouponOrdersDrawer(props) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setLoading(true);

    postData("coupons/orders", { 
        coupon_id: props.coupon.id
    },
      function(result) {
        setItems(result);
      },
      function(error) {
        alert("Error loading coupon orders")
      },
      function() {
        setLoading(false);
      }
    );
  }, [props.coupon]);

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faTag} />
            &nbsp;
            Purchase Code {props.coupon ? props.coupon.code : ""} Orders
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { loading ? 
                <Loading /> 
              :
                <Items>
                    <thead>
                        <tr>
                            <th>Order</th>
                            <th>User</th>
                            <th>Preview</th>
                            <th>Total</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {items.map(order => (
                        <tr key={order.id}>
                            <td>
                                #{order.id}
                            </td>
                            <td>
                                {order.user_name}
                            </td>
                            <td>
                                <GoogleImage 
                                    root 
                                    src={order.preview_url} 
                                    style={{
                                        maxWidth: "125px",
                                        maxHeight: "75px"
                                    }}
                                />
                                {order.title}
                            </td>
                            <td>{ formatPrice(order.total) }</td>
                            <td>{order.status_title}</td>
                        </tr>
                    ))}
                    </tbody>
                </Items>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default CouponOrdersDrawer;