import React, { useState } from "react";
import styled from 'styled-components'

import CheckListForm from "../../components/forms/form-checklist"
import CheckListInfoForm from "../../components/forms/form-checklistinfo"

const Checklist = styled.div`
    display: flex;

    > div {
        flex-basis: 50%;
    }
`

function JobQcPanel(props) {
    const [questionName, setQuestionName] = useState(null);

    const { job } = props;

    return (
      <Checklist>
        <CheckListForm
            type="Job"
            item="job"
            itemId={job.id}
            onAnswer={props.onAnswer}
            onQuestion={(question) => {
                console.log(question)
                setQuestionName(question ? question.name : null);
            }}
        />
        <CheckListInfoForm
            questionName={questionName}
            item="job"
            itemId={job.id}
        />
      </Checklist>
    );
}

export default JobQcPanel;