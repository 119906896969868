import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faPencilAlt, faPlus, faSpinner, faCheck, faImage, faExclamationTriangle, faThumbsUp } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"
import ShapePreview from "../components/shape-preview"
import DimensionDrawer from "./drawer-dimension"
import UploadButton from "../components/buttons/button-upload"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"
import PropertyList from "../components/properties/property-list";
import Property from "../components/properties/property";
import { formatDate } from "../helpers/utility";
import { getUser } from "../common/services/auth";
import storage from "../settings/storage";
import ZoomableImage from "../components/image-zoomable";

function DimensionsDrawer(props) {
  const { shape, part } = props;

  const [styleId, setStyleId] = useState(shape.style_id);
  const [dimensions, setDimensions] = useState([]);
  const [busy, setBusy] = useState("");
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setLoading(true);

    postData("shapes/dimensions", {
      shape_id: styleId == -1 ? 6 : styleId == -2 ? 20 : shape.id, 
      style_id: styleId == -1 || styleId == -2 ? 0 : styleId
    }, 
        function(response) {
          setDimensions(response);
        },
        function(error) {
          alert("An error occured loading data.");
        },
        function() {
          setLoading(false);
        }
    );

  }, [props.shape, styleId]);

  function onUseSticker(dimension) {
    setBusy("dimension" + dimension.id)

    postData("jobs/setpartdimension", {
        job_id: part.job_id,
        jobpart_id: part.id,
        dimension_id: dimension.id
      }, 
        function(result) {
          props.onChange(result)
        },
        function(error) {
          alert("An error occured updating the part.");
        },
        function() {
          setBusy("")
        }
    );    
  }

  function onConfirm(dimension) {
    setBusy("confirm" + dimension.id)

    postData("shapes/confirmdimension", {        
        dimension_id: dimension.id
      }, 
        function(result) {
          setDimensions(produce(draft => {
            let idx = draft.findIndex(d => d.id == dimension.id);

            if (idx != -1) {
              draft[idx] = result;
            }
          }))
        },
        function(error) {
          alert("An error occured confirming the sticker.");
        },
        function() {
          setBusy("")
        }
    );    
  }

  // function onDelete(dimension) {
  //   if (window.confirm("Delete this prewire data?")) {
  //     setBusy(produce(draft => {
  //       draft.push(dimension.id)
  //     }))

  //     postData("shapes/deletedimension", {dimension_id: dimension.id}, 
  //         function() {
  //           setDimensions(produce(draft => {
  //             draft.splice(draft.findIndex(d => d.id == dimension.id), 1);
  //           }))
  //         },
  //         function(error) {
  //           alert("An error occured deleting the data.");
  //         },
  //         function() {
  //           setBusy(produce(draft => {
  //             draft.splice(draft.findIndex(d => d.id == dimension.id), 1);
  //           }))
  //         }
  //     );
  //   }
  // }

  const isStandard = !part || (part.prewire_custom_ind == 0 && shape.resembles_ind == 0);

  return (
    <>
      <Modal show={props.show} animation={false} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faLightbulb} />
            &nbsp;
            Shape Prewire Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <PropertyList>
              <Property label="Shape">
                <ShapePreview 
                    path={props.shape.path} 
                    width={50} 
                    height={50} 
                />
              </Property>
              {part && 
                <Property label="Part" value={part.title} />
              }
              {shape.size &&
                <Property label="Size" value={shape.size} />
              }
            </PropertyList>

            <br />

            {isStandard &&
              <Alert variant="danger">
                <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                Warning.  These are <strong>standard</strong> prewire stickers. Changing them will affect 
                all current and future jobs.
              </Alert>
            }

            { loading ? 
                <Loading /> 
              :
                <>
                    <Tabs defaultActiveKey={styleId} onSelect={(tab) => {setStyleId(tab)}}>
                        <Tab eventKey={shape.style_id} title={shape.style_name} />
                        {(part && shape.style_id != 1 && shape.style_id != 0 && shape.style_id != 99) &&
                          <Tab eventKey={1} title="Standard Bold" />
                        }
                        {(part && (shape.style_id == 0 || shape.style_id == 99)) &&
                          <Tab eventKey={-1} title="Standard Circle" />
                        }
                        {(part && (shape.style_id == 0 || shape.style_id == 99)) &&
                          <Tab eventKey={-2} title="Standard Square" />
                        }
                    </Tabs>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Size</th>
                                <th>Part</th>
                                <th>LEDs</th>
                                <th>Stickers</th>
                                <th>Used</th>
                                <th>Confirmed</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                        {dimensions.map(dimension => {
                            //const deleting = busy.indexOf(dimension.id) != -1;

                            return (
                              <tr key={dimension.id}>
                                  <td>{dimension.size}</td>
                                  <td>{dimension.part}</td>
                                  <td>{dimension.num_leds}</td>
                                  <td>
                                      {[...Array(dimension.prewire_stickercount)].map((item, index) => ( 
                                          <div key={index} style={{
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                            padding: "2px 6px",
                                            fontSize: "80%",
                                            backgroundColor: "white",
                                          }}>
                                            {dimension["prewire_sticker" + (index+1)]}
                                          </div> 
                                      ) )}                                      
                                  </td>
                                  <td>{dimension.used_count}</td>
                                  <td>
                                    {dimension.confirmed_date ?
                                      <div style={{display:"flex", justifyContent: "space-between"}}>
                                        <div>{dimension.confirmed_user_name} on {formatDate(dimension.confirmed_date)}</div>
                                        {dimension.preview_url && 
                                          <ZoomableImage
                                            url={storage.root + dimension.preview_url}
                                            zoomUrl={storage.root + dimension.url}
                                            maxWidth={100}
                                            maxHeight={50}
                                          />
                                        }
                                      </div>
                                    :
                                      <div style={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                      }}>
                                        No
                                        <Button onClick={() => onConfirm(dimension)} size="sm" variant="outline-success" style={{marginRight:"5px"}}>
                                            <FontAwesomeIcon icon={busy == ("confirm" + dimension.id) ? faSpinner : faThumbsUp} spin={busy == ("confirm" + dimension.id)} />{' '}
                                            Confirm
                                        </Button>                                        
                                      </div>
                                    }
                                  </td>
                                  <td style={{whiteSpace: "nowrap"}}>
                                      {part && 
                                        <Button onClick={() => onUseSticker(dimension)} size="sm" variant="outline-success" style={{marginRight:"5px"}}>
                                            <FontAwesomeIcon icon={busy == ("dimension" + dimension.id) ? faSpinner :faCheck} spin={busy == ("dimension" + dimension.id)} />{' '}
                                            Use Sticker
                                        </Button>
                                      }
                                      {isStandard &&                       
                                        <Button onClick={() => setSelected({mode:"dimension", dimension})} size="sm" variant="outline-warning">
                                            <FontAwesomeIcon icon={faPencilAlt} />
                                        </Button>
                                      }
                                      {dimension.confirmed_date && 
                                        <UploadButton
                                          uploadUrl={"shapes/uploaddimension?dimension_id=" + dimension.id}
                                          label="Photo"
                                          thumbnail={true}
                                          multiple={false}
                                          extensions={["jpg"]}
                                          onUpload={(file) => {
                                              setDimensions(produce(draft => {
                                                let idx = draft.findIndex(d => d.id == dimension.id);

                                                if (idx != -1) {
                                                  draft[idx].confirmed_file_id = file.id;
                                                  draft[idx].url = file.url;
                                                  draft[idx].preview_url = file.url;
                                                }
                                              }));
                                            }}
                                        /> 
                                      }
                                      {/* {dimension.used_count == 0 && 
                                        <>
                                          {' '}
                                          <Button onClick={() => onDelete(dimension)} size="sm" variant="outline-danger">
                                            <FontAwesomeIcon icon={deleting?faSpinner:faTrash} spin={deleting} />
                                          </Button>                                
                                        </>
                                      } */}
                                  </td>
                              </tr>
                            )
                        })}
                        </tbody>
                    </Table>   
                </>          
            }
        </Modal.Body>
        <Modal.Footer>
            {isStandard &&    
              <Button variant="outline-success" onClick={() => setSelected({mode:"dimension", dimension:{shape_id:props.shape.id, style_id:styleId, custom_ind:0}})}>
                <FontAwesomeIcon icon={faPlus} />{' '}
                Add Missing Size
              </Button>
            }
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
      { selected && selected.mode == "dimension" && 
        <DimensionDrawer 
          show={true} 
          dimension={selected.dimension} 
          onSaved={(dimension) => {
            setDimensions(produce(draft => {
              const index = draft.findIndex(d => d.id == dimension.id);

              if (index == -1)
                draft.push(dimension)
              else 
                draft[index] = dimension;
            }))

            setSelected(null);
          }} 
          onHide={ () => setSelected(null) } 
        /> 
      }
    </>
  )
}

export default DimensionsDrawer;