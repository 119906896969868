import React, { useState, useEffect } from "react";
import styled from "styled-components"
import InputGroup from  'react-bootstrap/InputGroup';
import FormControl from  'react-bootstrap/FormControl';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import produce from "immer"

import Loading from "../../components/loading"
import ColorBox from "../../components/color-box"
import Button from "../../components/buttons/button";
import { postData } from "../../common/services/server"

import { formatDate, removeNulls } from '../../helpers/utility'

const Shapes = styled.table`
    width: 100%;
    font-size: 80%;
    margin-bottom: 10px;

    th {
        background-color: #eee;
        border: 1px solid #ccc;
        padding: 4px 6px;
    }
    td {
        padding: 4px 6px;
        white-space: nowrap;

        div.color > div {
            margin-left: 0px;
        }

        border: 1px solid #ccc;
    }

    div.color {
      display: inline-block;
      vertical-align: middle;
    }

`

function JobUlPanel({job, ...props}) {
  const [isLoading, setIsLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(
    () => {
      setIsLoading(true);

      postData("jobs/ul", {
            id: job.id
        }, 
        function(response) {
          setItems(response)
        },
        function(error) {
          alert("An error occured loading job UL data.");
        },
        function() {
          setIsLoading(false);
        }
      );
    }, 
    [job]
  );

  function onUpdateUL() {
    setProcessing(true);

    let data = {
      job_id: job.id
    }

    for (const item of items) {
      data[item.type + item.id] = item.ul;
    }

    postData("jobs/updateul", removeNulls(data), 
      function(response) {
        //setItems(response)
      },
      function(error) {
        alert("An error occured updating job UL data.");
      },
      function() {
        setProcessing(false);
      }
    );
  }

	function onUlDown(item) {
		const ul = item.ul

		if (ul.length >= 8) {
			var prefix = ul.substr(0, ul.length-6);
			var seqstr = ul.substr(ul.length - 6);
			var seq = parseInt(seqstr);
			var zerod = seqstr.length > (seq+"").length;

			if (!isNaN(seq)) {
        setItems(produce(draft => {
          const index = draft.findIndex(i => i.type==item.type && i.id==item.id);
          
          for (let i=index+1; i<items.length; i++) {
            seq++;
            draft[i].ul = prefix + (zerod ? "0":"") + seq;
          }
        }));
			}
			else alert("That doesn't look like a UL number to me...")
		}
		else alert("That doesn't look like a UL number to me...")
	}

  return (
  <>
      { isLoading ? 
        <Loading />
      :
        <>
          <Shapes>
              <thead>
                  <tr>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Properties</th>
                      <th>UL</th>
                      <th>Date Used</th>
                  </tr>
              </thead>
              <tbody>
              {items.map(item => {
                return (
                    <tr key={item.id}>
                        <td>
                            {item.type}
                        </td>
                        <td>
                            {item.name}
                        </td>
                        <td>
                          {item.type == "Shape" && 
                            <>
                              {item.face && 
                                <ColorBox 
                                    color={{name: item.face.color_name, hex_day: item.face.color_hex}} 
                                    size="small" 
                                />
                              }
                              {item.style}, Size: {item.size}
                            </>
                          }
                          {item.type == "Powerset" && 
                            <>{item.size} {item.style}</>
                          }
                        </td>
                        <td>
                          <InputGroup size="sm">
                              <FormControl
                                  defaultValue={item.ul}
                                  onChange={(e) => { 
                                    e.persist();

                                    setItems(produce(draft => {
                                      draft[draft.findIndex(i => i.type==item.type && i.id==item.id)].ul = e.target.value;
                                    }));
                                  }}
                              />
                              <InputGroup.Append>
                                  <Button disabled={!item.ul || item.ul.length == 0} onClick={() => onUlDown(item)} variant="outline-secondary">
                                      <FontAwesomeIcon icon={faArrowAltCircleDown} />
                                  </Button>
                              </InputGroup.Append>
                          </InputGroup>
                        </td>
                        <td>
                            {formatDate(item.date)}
                        </td>
                    </tr>
                )
              })}
              </tbody>
          </Shapes>

          <Button onClick={onUpdateUL} variant="outline-success">
            <FontAwesomeIcon icon={processing ? faSpinner:faSave} spin={processing} />{' '}
            Update UL
          </Button>
        </>
      }
  </>
  );
}

export default JobUlPanel;