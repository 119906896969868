import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faShoppingCart, faBan, faUserTie, faHandshake, faTimesCircle, faQuestionCircle, faEnvelope, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Howl } from 'howler';

import Tooltip from "./tooltip";
import EmailViewDrawer from "../drawers/drawer-viewemail"

import { PusherContext } from "../common/services/pusher";
import { isLoggedIn, userHasAnyRole } from "../common/services/auth";
// import { usePageVisibility } from "../hooks/usePageVisibility";
import { usetGetRecentNotifications } from "../api/queries/notifications";
import { useMarkNotificationViewed, useMarkAllNotificationsViewed } from "../api/mutations/notifications";
import { formatDistanceToNow, parseISO } from "date-fns";

//Pusher.logToConsole = true;

import alert from "../sounds/interactiverequest.wav";

function NotificationIcon({notification, onViewed}) {
    const markViewed = useMarkNotificationViewed({
        onSuccess: () => {
            if (onViewed) onViewed();
        }
    });

    return (
        <Tooltip 
            placement="left"
            contentClassName="!min-w-0 !max-w-[400px] whitespace-nowrap"
            content={
                <div className="grid text-xs text-left space-y-1">
                    <div>{notification.message}</div>
                    {notification.type == "project" && 
                        <div>Project #{notification.type_id}</div>
                    }                    
                    {notification.type == "sign" && 
                        <div>Sign #{notification.type_id}</div>
                    }
                    {notification.type == "order" && 
                        <div>Order #{notification.type_id}</div>
                    }
                    {notification.added_date && 
                        <div className="text-xs">
                            Received {formatDistanceToNow(parseISO(notification.added_date))} ago
                        </div>
                    }
                </div>                                                
            }
        >     
            <button 
                className={clsx(
                    "relative group",
                    "border rounded !border-gray-300 p-1 text-2xl text-gray-500 block w-full",
                    "hover:bg-white", 
                    notification.type == "order" && "hover:!border-green-400 !text-green-500", 
                    notification.type == "email" && "hover:!border-blue-400 !text-blue-500", 
                    notification.message.includes("Interactive Help Request") && "!bg-red-500 !text-white", 
                    notification.message.includes("Checkout Failed") && "hover:!border-red-500 text-red-500",
                    notification.message.includes("Sign Pro Request") && "hover:!border-purple-400 !text-purple-500",
                )}
                onClick={() => {
                    if (notification.viewed_ind == 0) {
                        markViewed.mutate(notification.id);
                    }

                    if (notification.type == "order")
                        window.open("#/orders/order/" + notification.type_id);
                    else if (notification.type == "cart")
                        window.open("#/orders/carts?id=" + notification.type_id);
                    else if (notification.message.includes("Interactive Help Request"))
                        window.open("#/interactivebuilder/" + notification.type_id)
                    else if (notification.type == "user")
                        window.open("#/users/user/" + notification.type_id);
                    else if (notification.type == "sign")
                        window.open("#/signs/sign/" + notification.type_id);
                    else if (notification.type == "project")
                        window.open("#/projects/project/" + notification.type_id);
                }}
            >
                <FontAwesomeIcon 
                    icon={
                        markViewed.isLoading ? faSpinner :
                        notification.type == "order" ? faCreditCard :
                        notification.type == "email" ? faEnvelope :
                        notification.type == "cart" || notification.message.includes("Checkout Failed") ? faShoppingCart :
                        notification.message.includes("Interactive Help Request") ? faHandshake :
                        notification.message.includes("Sign Pro Request") ? faUserTie :
                        faQuestionCircle
                    } 
                    spin={markViewed.isLoading}
                />

                <FontAwesomeIcon 
                    icon={faTimesCircle} 
                    size="xs" 
                    className="absolute bg-white rounded-full text-red-500 -right-1 -top-1 hidden group-hover:block"
                    style={{fontSize: "12px"}} 
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        markViewed.mutate(notification.id);
                    }}
                />
            </button>
        </Tooltip>   
    )
}

function SiteNotifications() {
    const pusher = useContext(PusherContext);

    const [monitoring, setMonitoring] = useState(false);
    const [emailId, setEmailId] = useState(null);
    //const [notifications, setNotifications] = useState([]);
    //const [reconnect, setReconnect] = useState(0);

    const markAllViewed = useMarkAllNotificationsViewed({
        onError: () => {
            window.alert("Error updating notifications")
        }
    });

    // const isPageVisible = usePageVisibility()

    const canSeeNotifications = userHasAnyRole(["Sales Manager", "Sales Minor"]) ? true:false;

    const recentQuery = usetGetRecentNotifications({ 
        enabled: canSeeNotifications,
        refetchOnWindowFocus: true, 
        staleTime: 1*60*1000 
    }); // 5 minutes
    const recent = recentQuery.data;

    useEffect(() => {
        if (!pusher) {
            console.log("SiteNotifications pusher undefined");
            return;
        }

        // only applicable to sales people right now
        if (!canSeeNotifications) return;

        pusher.connection.bind('state_change', function(states) {
            console.log("pusher state", states.current);
            setMonitoring(states.current == "connected")
        });
        
        const channel = pusher.subscribe("notifications");

        channel.bind_global(function (event, data) {
            console.log(`The event ${event} was triggered`);
            console.log(data)

            // Is is something we care about?
            if (data && data.type && data.id) {
                recentQuery.refetch();
                // setNotifications(produce(draft => {        
                //     let idx = draft.findIndex(n => n.type == data.type && n.id == data.id);
        
                //     // prevent duplicates
                //     if (idx == -1) {
                //         draft.push(data)
                //     }
                // }));

                // Do we need an audio notification?
                if (data.message == "Interactive Help Request") {
                    var sound = new Howl({
                        src: alert,
                        autoplay: false,
                        loop: false,
                        volume: 1,
                        onend: function() {
                          console.log('Sound Finished!');
                        }
                    });

                    sound.play();
                }
            }

            // setNotifications(produce(draft => {        
            //     let idx = draft.findIndex(n => n.type == notification.type && n.id == notification.id);
    
            //     // prevent duplicates
            //     if (idx == -1) {
            //         draft.push(notification)
            //     }
            // }))            
        })

        return () => {
          pusher.unsubscribe("notifications")
        };
    }, [pusher]); // [reconnect]

    // useEffect(() => {
    //     console.log("isPageVisible", isPageVisible)
    // }, [isPageVisible]); 

    return (
        (isLoggedIn() && canSeeNotifications) ? 
            <>
                <div className="bg-gray-300 h-full relative print:hidden">
                    <ul className="flex flex-col m-1">
                        {/* {notifications?.map((notification, index) => (
                            <NotificationIcon 
                                key={index} 
                                notification={notification} 
                                onViewed={() => {
                                    setNotifications(produce(draft => {        
                                        let idx = draft.findIndex(n => n.type == data.type && n.id == data.id);
                            
                                        draft[idx].viewed_ind = 1;
                                    }));
                                }}                            
                            />
                        ))} */}
                        {recent?.map((notification, index) => (
                            <NotificationIcon 
                                key={index} 
                                notification={notification} 
                                onViewed={() => {
                                    recentQuery.refetch();

                                    if (notification.type == "email") {
                                        setEmailId(notification.type_id)
                                    }
                                }}
                            />
                        ))}
                    </ul>

                    {recent?.length > 0 && 
                        <div className="absolute bottom-1 w-full">
                            <button 
                                className="border rounded !border-gray-300 p-1 text-2xl text-gray-500 block w-full hover:bg-white"
                                onClick={() => {
                                    markAllViewed.mutate();
                                }}
                            >
                                <FontAwesomeIcon icon={markAllViewed.isLoading ? faSpinner:faBan} spin={markAllViewed.isLoading} />
                            </button>
                        </div>
                    }                                       
                </div>

                {emailId && 
                    <EmailViewDrawer 
                        show={true}
                        email={{
                            id: emailId
                        }} 
                        onHide={ () => setEmailId(null) } /> 
                }                
            </> 
        :
            <div></div>
    );
}

export default SiteNotifications