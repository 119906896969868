import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { addDays, addMonths, addBusinessDays } from "date-fns";
import produce from "immer"
import { faSave } from "@fortawesome/free-solid-svg-icons";

import TextAreaField from "../components/fields/field-textarea"
import SelectField from "../components/fields/field-select"
import UserField from "../components/fields/field-user"
import DateField from "../components/fields/field-date"
import FieldEmployee from "../components/fields/field-employee";
import DrawerTemplateData from "./template-data";
import ButtonMutate from "../components/buttons/button-mutate";
import { getIdInfoFromUrl, removeNulls } from "../helpers/utility";
import { getIconForType } from "../helpers/icons";

import { useAddTask } from "../api/mutations/tasks";
import { useGetNewTask } from "../api/queries/tasks";

export default function DrawerNewTask({
  onSaved,
  onHide
}) {  
  let location = useLocation();

  const [task, setTask] = useState({});

  const addTask = useAddTask({
    onSuccess: (data, variables, context) => {
      if (onSaved) onSaved(data)
      onHide();
    }
  });

  const newTaskQuery = useGetNewTask(removeNulls({...getIdInfoFromUrl(location.pathname)}));

  useEffect(
    () => {
        if (newTaskQuery.data) {
          setTask(newTaskQuery.data);
        }
    }, 
    [newTaskQuery.data]
  );

  return (
      <DrawerTemplateData
        title="Create Task"
        icon={getIconForType("task")}
        query={newTaskQuery}
        buttons={
          <ButtonMutate
            mutation={addTask}
            mutationData={task}
            icon={faSave}
            caption="Save Task"
            disabled={!task?.type || !task?.task}
            variant="outline-success"
          />
        }
        onHide={onHide}
      >
        {task && 
          <div className="grid gap-1">
            <SelectField 
                name="type" 
                url="tasks/types"
                value={task.type} 
                idField="name"
                labelField="title"
                onChange={(e) => {
                  if (e.persist) e.persist();

                  setTask(produce(draft => {
                    draft.type = e.target.value;

                    if (e.target.value.indexOf("followup") != -1) {
                      draft.due_date = addBusinessDays(new Date(), 1);
                    }
                  }))                          
                }} 
            />    

            {((task.type == "followupemail" || task.type == "followupcall")) &&
              <>
                {(!task.user_id || task.user_id==0) &&
                  <UserField 
                    name="user_id"
                    label="Customer" 
                    required
                    value={{id:task.user_id, name: task.user_title || ""}}
                    onChange={(e) => {
                      if (e.persist) e.persist();

                      setTask(produce(draft => {
                        draft.user_id = e.target.value ? e.target.value.id : null;
                        draft.user_title = e.target.value ? e.target.value.name : null;
                      }))
                    }} 
                  />
                }

                <DateField 
                  name="due_date" 
                  label="Followup Date" 
                  required 
                  value={task.due_date} 
                  quickValues={{
                    "Today": new Date(),
                    "Next Work Day": addBusinessDays(new Date(), 1),
                    "Next Week": addDays(new Date(), 7),
                    "Next Month": addMonths(new Date(), 1),
                  }}
                  onChange={(e) => {
                    if (e.persist) e.persist();

                    setTask(produce(draft => {
                      draft.due_date = e.target.value;
                    }))
                  }} 
                />
              </>
            }       

            <TextAreaField 
                name="task" 
                label="Description"
                value={task.task} 
                autofocus
                rows={3}
                onChange={(e) => {
                  if (e.persist) e.persist();

                  setTask(produce(draft => {
                    draft.task = e.target.value;
                  }))
                }} 
              />
                      
            <FieldEmployee 
              name="assigned_user_id" 
              label="Assigned To"
              value={task.assigned_user_id}
              onChange={(e) => {
                if (e.persist) e.persist();

                setTask(produce(draft => {
                  draft.assigned_user_id = e.target.value;
                }))
              }} 
            />

            {(task.sign_id && task.sign_id > 0) &&
                <Form.Check 
                    checked={1}
                    label={"Associate with Sign: " + task.sign_title}
                    type="checkbox" 
                    onChange={(e) => {
                      setTask(produce(draft => {
                        draft.sign_id = null;
                      }))                              
                    }}
                />                    
            }
            {(task.user_id && task.user_id > 0) &&
                <Form.Check 
                    checked={1}
                    label={"Associate with User: " + task.user_title}
                    type="checkbox" 
                    onChange={(e) => {
                      setTask(produce(draft => {
                        draft.user_id = null;
                      }))                              
                    }}
                />                    
            }                    
            {((task.quote_id && task.quote_id > 0) || ((task.project_id && task.project_id > 0))) &&
                <Form.Check 
                    checked={1}
                    label={"Associate with Project: " + (task.quote_title || task.project_title)}
                    type="checkbox" 
                    onChange={(e) => {
                      setTask(produce(draft => {
                        draft.quote_id = null;
                      }))                              
                    }}
                />                    
            }  
            {(task.order_id && task.order_id > 0) &&
                <Form.Check 
                    checked={1}
                    label={"Associate with Order: " + task.order_title}
                    type="checkbox" 
                    onChange={(e) => {
                      setTask(produce(draft => {
                        draft.order_id = null;
                      }))                              
                    }}
                />                    
            }  
            {(task.job_id && task.job_id > 0) &&
                <Form.Check 
                    checked={1}
                    label={"Associate with Job: " + task.job_title}
                    type="checkbox" 
                    onChange={(e) => {
                      setTask(produce(draft => {
                        draft.job_id = null;
                      }))                              
                    }}
                />                    
            } 
            {(task.workflowjob_id && task.workflowjob_id > 0) &&
                <Form.Check 
                    checked={1}
                    label={"Associate with Workflow Job: " + task.workflowjob_title}
                    type="checkbox" 
                    onChange={(e) => {
                      setTask(produce(draft => {
                        draft.workflowjob_id = null;
                      }))                              
                    }}
                />                    
            }                        
          </div>
        }
      </DrawerTemplateData> 
    )
}
