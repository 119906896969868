import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import BoardColumns from "../components/board/board-columns";
import BoardFields from "../components/board/board-fields";
import BoardField from "../components/board/board-field"
import PanelTable from "./panel-table";

import PricesDrawer from "../drawers/drawer-prices"

import { formatPrice } from "../helpers/utility";

function Panel({
  product,
  onChange
}) {
  //const [prices, setPrices] = useState([]);
  const [selected, setSelected] = useState(null);

//   useEffect(
//     () => {
//       setPrices(JSON.parse(JSON.stringify(product.prices)));
//     }, 
//     [product]
//   );

  return (
    <>
        <BoardFields>
        {(product.standard_sizes && product.standard_sizes.length > 0 ) ?
            <PanelTable>
                <thead>
                    <tr>
                        <th>Standard Sizes</th>
                        {product.standard_sizes.split(",").map(size => (
                            <th key={size} style={{textAlign:"center"}}>
                                {size}
                            </th>
                        ))}
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Base Price</th>
                        {product.standard_sizes.split(",").map(size => (
                            <td key={size} align="center">
                                {formatPrice((product.prices.find(p => p.part_id == null && p.size == size) || {price: 0}).price)}
                            </td>
                        ))}  
                        <td align="right">
                            <Button 
                                size="sm" 
                                variant="outline-primary"
                                onClick={() => setSelected({product})}
                            >
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>
                        </td>                      
                    </tr>
                    {product.parts.filter(p => product.prices.filter(p2 => p2.part_id == p.id && p2.price > 0).length > 0).map(part => (                            
                        <tr key={part.id}>
                            <th>{part.title}</th>
                            {product.standard_sizes.split(",").map(size => (
                                <td key={size} align="center">
                                    {formatPrice((product.prices.find(p => p.part_id == part.id && p.size == size) || {price: 0}).price)}
                                </td>
                            ))}  
                            <td align="right">
                                <Button 
                                    size="sm" 
                                    variant="outline-primary"
                                    onClick={() => setSelected({product, part})}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>
                            </td>                                  
                        </tr>
                    ))}                      
                </tbody>
            </PanelTable>
        :
            <p>Product doesn't have standard sizes defined?</p>
        }
        </BoardFields>

        <br />
        
        <BoardFields label="Part Prices">
        {(product.standard_sizes && product.standard_sizes.length > 0 ) ?
                <PanelTable>
                    <thead>
                        <tr>
                            <th>Part</th>
                            <th>Price Type</th>
                            <th>Price Calculation</th>
                            <th style={{textAlign:"right"}}>Setup Cost</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {product.parts.filter(p => p.price_type && p.price_type.length > 0).map(part => (                            
                            <tr key={part.id}>
                                <th>{part.title}</th>
                                <td>
                                    {part.price_type}
                                </td>
                                <td>
                                    {/* {part.price_cost && <>{part.price_cost}%&nbsp;</>}
                                    {(part.price_cost && part.price_fixed) && <>&nbsp;-&nbsp;</>}
                                    {part.price_fixed && <>${part.price_fixed}&nbsp;</>} */}

                                    {part.price_type == "fixed" ?
                                        <>${part.price_fixed || 0}</>
                                    : part.price_type == "cost_sqin" ?
                                        <>[shape sq inches] * {part.price_cost}</>
                                    : part.price_type == "cost_sqin_fixed" ?
                                        <>([shape sq inches] * {part.price_cost}) + ${part.price_fixed || 0}</>
                                    : part.price_type == "cost_perimin" ?
                                        <>[shape perim inches] * {part.price_cost}</>
                                    : part.price_type == "cost_perimin_fixed" ?
                                        <>([shape perim inches] * {part.price_cost}) + ${part.price_fixed || 0}</>
                                    : part.price_type == "cost_size" ?
                                        <>[shape size] * {part.price_cost}</>
                                    : part.price_type == "cost_size_fixed" ?
                                        <>([shape size] * {part.price_cost}) + ${part.price_fixed || 0}</>
                                    : part.price_type == "cost_part_size" ?
                                        <>([shape height] * {part.price_cost}) + ${part.price_fixed || 0}</>
                                    :
                                        <>?</>
                                    }
                                </td>
                                <td align="right">
                                    ${part.price_setup || 0}
                                </td>
                                <td align="right">
                                    <Button 
                                        size="sm" 
                                        variant="outline-primary"
                                        onClick={() => setSelected({product, part})}
                                    >
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    </Button>
                                </td>                                  
                            </tr>
                        ))}                      
                    </tbody>
                </PanelTable>
            :
                <p>Product doesn't have any parts with pricing data?</p>
            }            
        </BoardFields>

        {selected && 
            <PricesDrawer
                product={selected.product}
                part={selected.part}
                prices={product.prices}
                onChange={(data) => {
                    console.log("drawer onChange")
                    //setPrices(data.prices);

                    if (onChange)
                        onChange(data)
                }}
                onHide={ () => setSelected(null) } 
            />
        }   
    </>   
  );
}

export default Panel;