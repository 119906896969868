import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSpinner, faEnvelope, faPhone, faExclamationTriangle, faThumbsUp } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading";
import UserSelect from "../components/fields/user-select"
import PropertyList from "../components/properties/property-list"
import Property from "../components/properties/property"
import { postData } from "../common/services/server"
import { formatDateTime, formatPhoneNumber } from "../helpers/utility";
import { getIconForType } from "../helpers/icons";
import DrawerTemplateData from "./template-data";
import JobSelect from "../components/fields/job-select";
import { useGetJob, useGetJobSearch } from "../api/queries/jobs";
import DrawerTemplate from "./template";
import FieldLabel from "../components/fields/field-label";
import Field from "../components/fields/field";
import ZoomableImage from "../components/image-zoomable";
import storage from "../settings/storage";
import Error from "../components/error";
import Alert from "../components/alert";

export default function JobSelectDrawer({
    title,
    queryOptions,
    onSelect,
    onHide
}) {
    const [jobId, setJobId] = useState(null);
    
    const { data: job, isFetching, error } = useGetJob(jobId, {include_maplocation_ind: 1}, {enabled: !!jobId});

    return (
      <DrawerTemplate
        icon={getIconForType("job")} 
        title={title || "Select Job"}
        header={
            <div>
                <JobSelect 
                    queryOptions={queryOptions}
                    onChange={(jobData) => setJobId(jobData?.id)} 
                />
            </div>
        }
        buttons={[
            <Button
                disabled={!job}
                variant="outline-success"
                onClick={() => onSelect(job)}
            >
                Select Job
            </Button>
        ]}      
        onHide={onHide}
      >
        {isFetching ?
            <Loading />
        : error ?
            <Error error={error} />
        : job ?
            <div className="grid gap-1">
                <FieldLabel 
                    prepend="Job" 
                    prependClassName="w-[100px]"
                    value={job.title} 
                />
                <Field
                    prepend="Preview"
                    prependClassName="w-[100px]"
                >
                    <ZoomableImage 
                        url={storage.root + job.preview_url}
                        maxHeight="100px"
                    />
                </Field>

                {(job.allow_socialmedia_ind == 0) &&
                    <Alert variant="danger" icon={faExclamationTriangle}>
                        Customer doesn't allow posting on social media.
                    </Alert>
                }
                {(job.allow_socialmedia_ind == 1) &&
                    <Alert variant="success" icon={faThumbsUp}>
                        Customer allows posting on social media.
                    </Alert>
                }

            </div>
        :
            null
        }
      </DrawerTemplate>
    )
}