import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components'
import Form from 'react-bootstrap/Form';
import produce from "immer"
import Table from "react-bootstrap/Table";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruckMoving, faSpinner, faSave } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"
import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from "../helpers/utility";

const ItemsTable = styled(Table)`
    td {
        vertical-align: middle;
    }
    tr.highlight td {
        background-color:#eee !important;
    }
`

function ShippingItemsDrawer(props) {
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    // setLoading(true);

    // postData("orders/shippingitems", { 
    //     id: props.order.id,
    //     shipping_id: props.shipment.id
    // },
    //   function(result) {
    //     setItems(result);
    //   },
    //   function(error) {
    //     alert("Error loading order items")
    //   },
    //   function() {
    //     setLoading(false);
    //   }
    // );
    setItems(props.shipping.items);

  }, [props.shipping]);


  function onSave() {
    setBusy(true);

    let data = { id: props.order.id }

    for (const item of items) {
        data[item.id] = item.shipping_id;
    }

    postData("orders/setshippingitems", removeNulls(data), 
        function(response) {
            props.onChange(response);
        },
        function(error) {
            alert("An error occured updating the shipping data.");
        },
        function() {
            setBusy(false)
        }
    );
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faTruckMoving} />
            &nbsp;
            {props.shipment.shippingmethod_title}-{props.shipment.boxno}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { loading ? 
                <Loading /> 
              :
                <>
                {props.order.items.map((orderitem, index) => (
                    <div key={index}>
                    {orderitem.jobs.map((job, index) => (
                        <div key={index}>
                            <div className="flex items-center justify-between mb-1">
                                <h5>{job.title}</h5>
                                <div>
                                    <button 
                                        onClick={() => {
                                            setItems(produce(draft => {
                                                for (const item of items.filter(i => i.job_id == job.id && (!i.shipping_id || i.shipping_id == props.shipment.id))) {
                                                    const index = draft.findIndex(i => i.id == item.id)

                                                    draft[index].shipping_id = props.shipment.id;
                                                }
                                            }));                                              
                                        }}
                                        className="border rounded px-1 hover:bg-gray-200"
                                    >
                                        Select All
                                    </button>
                                </div>
                            </div>
                            <ItemsTable bordered size="sm">
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Size</th>
                                        <th>Description</th>
                                        <th>Shipment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {items.filter(i => i.job_id == job.id && (!i.shipping_id || i.shipping_id == props.shipment.id)).map((item, index) => (
                                    <tr key={index} className={item.shipping_id == props.shipment.id ? "" : !item.shipping_id ? "alert-danger" : "highlight"}>
                                        <td>
                                            {busy == item &&
                                                <>
                                                    <FontAwesomeIcon icon={faSpinner} spin />{' '}
                                                </>
                                            }
                                            <span dangerouslySetInnerHTML={{__html: item.title}} />
                                        </td>                          
                                        <td>
                                            {item.size ? 
                                                (item.size == Math.floor(item.size) ? Math.trunc(item.size) : item.size)
                                            : 
                                                (item.height && item.width) ? item.height + '" x ' + item.width + '"' : ''
                                            }
                                        </td>
                                        <td>
                                            {item.description}
                                        </td>
                                        <td>
                                            <Form.Check 
                                                value={1}
                                                checked={item.shipping_id == props.shipment.id}
                                                label={props.shipment.shippingmethod_title + "-" + props.shipment.boxno}
                                                type="checkbox"
                                                onChange={(e) => {
                                                    if (e.persist) e.persist();
                                                    
                                                    console.log(item.title, item.shipping_id, e.target.checked)
                                                    setItems(produce(draft => {
                                                        const index = draft.findIndex(i => i.id == item.id)

                                                        draft[index].shipping_id = e.target.checked ? props.shipment.id : null;
                                                    }));                                                    
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </ItemsTable>
                        </div>
                    ))}
                    </div>
                ))}
                </>
            }
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={busy}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Shipments" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ShippingItemsDrawer;