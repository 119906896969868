import React, { useState, useEffect, useRef } from "react";

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faCreditCard } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import CurrencyField from "../components/fields/field-currency"
import SelectField from "../components/fields/field-select"

import PropertyList from "../components/properties/property-list"
import Property from "../components/properties/property"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls, formatPrice } from '../helpers/utility'

function PaymentDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  const isVoided = !props.payment.id && props.order.voided;

  useEffect(
    () => {
        setFormData(JSON.parse(JSON.stringify({
          ...props.payment, 
          amount: isVoided ? props.order.total*-1 : props.payment.amount,
          notes: isVoided ? "Voided" : props.payment.notes || ""
        })));
    }, 
    [props.payment]
  );
  useEffect(
    () => {
        setOrderData(JSON.parse(JSON.stringify({
          ...props.order,
          total: parseFloat(props.order.total),
          payments_total: parseFloat(props.order.payments_total),
          newtotal: parseFloat(props.order.total),
          newpayments_total: parseFloat(props.order.payments_total)
        })));
    }, 
    [props.order]
  );  
  useEffect(
    () => {
        console.log("orderData", orderData.total, orderData.newtotal, orderData.payments_total, orderData.newpayments_total)
    }, 
    [orderData]
  ); 

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("orders/savepayment?id=" + (props.payment.id || 0) + "&order_id=" + props.order.id, removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the payment.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;

    setFormData(produce(draft => {
      draft[field] = event.target.value;
    }));

    console.log(field, event.target.value)

    if (field == "amount") {
      setOrderData(produce(draft => {
        draft.newtotal = draft.total;
        draft.newpayments_total = draft.payments_total;

        if (event.target.value < 0)
          draft.newtotal = draft.total + parseFloat(event.target.value);
        else
          draft.newpayments_total = draft.payments_total + parseFloat(event.target.value);  
      }));      
    }
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faCreditCard} />
            &nbsp;
            {isVoided ? "VOID" : props.payment.id ? "Update": formData.amount < 0 ? "Refund" : "Add"} Payment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
            <div className="space-y-1">
              <CurrencyField 
                  name="amount" 
                  label="Amount" 
                  layout="horizontal"
                  labelBold={false}
                  required
                  value={formData.amount} 
                  onChange={(e) => handleFormChange(e)} 
              />  
              <SelectField 
                  name="type_id" 
                  label="Type" 
                  layout="horizontal"
                  required 
                  url="orders/paymenttypes"
                  labelField="name"
                  value={formData.type_id} 
                  onChange={(e) => handleFormChange(e)} 
              />
              
              {formData.type_id == 1 && 
                <TextField 
                  name="cc_last4" 
                  label="Credit Card Last 4 Numbers" 
                  layout="horizontal"
                  value={formData.cc_last4} 
                  onChange={(e) => handleFormChange(e)} 
                />              
              }
              {formData.type_id == 2 && 
                <TextField 
                  name="check_no" 
                  label="Check Number" 
                  layout="horizontal"
                  value={formData.check_no} 
                  onChange={(e) => handleFormChange(e)} 
                />              
              }
              {formData.type_id == 6 && 
                <TextField 
                  name="affirm_id" 
                  label="Affirm ID" 
                  layout="horizontal"
                  value={formData.affirm_id} 
                  onChange={(e) => handleFormChange(e)} 
                />              
              }            
              <TextField 
                  name="notes" 
                  label="Notes"
                  layout="horizontal" 
                  value={formData.notes} 
                  onChange={(e) => handleFormChange(e)} 
              />  

              {!props.payment.id &&
                <div className="mt-2">
                  <label>Order Information</label>

                  <PropertyList>
                    <Property label="Order Total">
                        {formatPrice(parseFloat(orderData.total))}
                    </Property>
                    {(orderData.newtotal != orderData.total) && 
                      <Property label="New Order Total">
                        {formatPrice(parseFloat(orderData.newtotal))}
                      </Property>              
                    }
                    <Property label="Existing Payments" value={formatPrice(orderData.payments_total)}/>
                    {(orderData.newpayments_total != orderData.payments_total) && 
                      <Property label="New Payments Total">
                        {formatPrice(parseFloat(orderData.newpayments_total))}
                      </Property>              
                    }
                    <Property label="New Balance">
                        <span style={{color: orderData.total > orderData.payments_total ? "red":"inherit"}}>
                            {formatPrice(orderData.newpayments_total - parseFloat(orderData.newtotal))}
                        </span>
                    </Property>
                  </PropertyList>
                </div>
              }
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Payment" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default PaymentDrawer;