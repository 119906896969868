import React from "react";
import { formatDateTime } from "../helpers/utility";

function Note({
    note
}) {
    return (
        <div className="bg-yellow-200 p-1 border border-yellow-500 rounded space-y-1">
            <div>{note.notes}</div>

            <div className="flex items-center gap-1 text-xs [&>*]:rounded [&>*]:bg-yellow-100 [&>*]:p-1">
                {note.job_title && <div>Job: {note.job_title}</div>}
                {note.order_title && <div>Order: {note.order_title}</div>}
                {note.project_title && <div>Project: {note.project_title}</div>}
                {note.sign_title && <div>Sign: {note.sign_title}</div>}
                {note.user_title && <div>User: {note.user_title}</div>}
            </div>
            <div className="text-xs text-gray-500 p-1 flex items-center gap-1">
                <span>{note.added_user_name}</span>
                <span>-</span>
                <span>{formatDateTime(note.added_date)}</span>
            </div>
        </div>
    );
  }

export default Note