import svgpath from "svgpath"

const settings = {
    backgroundWidth: 5000,
    backgroundHeight: 5000,
    BACKGROUND_COLOR: "#FFFFFF",
    PIXELS_IN_INCH: 7, // The number of pixels that represent an inch in this tool
    SVG_EXPORT_SIZE: 10, // The size (based on pixels_in_inch) that svg fonts and shapes are exported
    FUSE_SPACING: 30, // when fused we need to add some spacing so that the letters are bunched up together
    STROKE_SIZE: 2,
    SPACE_SIZE: 30, // width that is used for a space char
    SPACING_ADJUSTMENT: -0.66, // adjustment amount made to letter spacing when added to their default value
    LETTER_DEPTH: 10, // side depth (# of layers) of each letter,
    GROUP_PADDING: 5,
    CHARS: " &',-.0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
    STANDARD_SIZES: [10,12,16,18,20,24,30,36,42,48],
    DEFAULT_LETTERS: "Letters",
    MAX_SQFT: 36
}

export function initFabric(isMobile, PUBLIC_URL) {
  const fabric = window.fabric;

  //
  // Global fabric mods

  fabric.Object.prototype.transparentCorners = false;
  //fabric.Object.prototype.originX = fabric.Object.prototype.originY = 'center';

  fabric.Object.prototype.lockUniScaling = true;
  fabric.Object.prototype.lockRotation = true;
  fabric.Object.prototype.lockScalingFlip = true;
  fabric.Object.prototype.hasRotatingPoint = false;

  fabric.Object.prototype.originX = "left";
  fabric.Object.prototype.originY = "top";

  fabric.Object.prototype.cornerSize = isMobile ? 20 : 13;
  fabric.Object.prototype.cornerColor = "white";
  fabric.Object.prototype.cornerStrokeColor = "black";
  fabric.Object.prototype.cornerPadding = 5;

  fabric.Object.prototype.objectCaching = false;

  fabric.Object.prototype.customiseCornerIcons({
    settings: {
        cornerSize: 25,
        cornerShape: 'circle',
        cornerBackgroundColor: 'white',
        cornerPadding: 15
    },
    tl: {
        icon: PUBLIC_URL + '/icons/menu.svg',
        settings: {
          cornerOffsetX: -5,
          cornerOffsetY: -5
        }
    },
    tr: {
        icon: PUBLIC_URL + '/icons/delete.svg',
        settings: {
          cornerStrokeColor: 'white',
          cornerBackgroundColor: '#dc3545',
          cornerOffsetX: 5,
          cornerOffsetY: -5
        }
    },
    bl: {
        icon: PUBLIC_URL + '/icons/edit.svg',
        settings: {
          cornerStrokeColor: 'white',
          cornerSize: 35,
          cornerPadding: 20,
          cornerOffsetX: -10,
          cornerOffsetY: 10,
          cornerBackgroundColor: '#f38922'
        }
    },
    br: {
        icon: PUBLIC_URL + '/icons/resize2.svg',
        settings: {
          cornerSize: 30,
          cornerOffsetX: 5,
          cornerOffsetY: 5
        }
    },
    mb: {
      icon: PUBLIC_URL + '/icons/dot.svg',
      settings: {
        cornerShape: 'rect',
        cornerSize: 20
      }
    },
    mt: {
      icon: PUBLIC_URL + '/icons/dot.svg',
      settings: {
        cornerShape: 'rect',
        cornerSize: 20
      }
    },
    mr: {
      icon: PUBLIC_URL + '/icons/dot.svg',
      settings: {
        cornerShape: 'rect',
        cornerSize: 20
      }
    },
    ml: {
      icon: PUBLIC_URL + '/icons/dot.svg',
      settings: {
        cornerShape: 'rect',
        cornerSize: 20
      }
    },
    // only is hasRotatingPoint is not set to false
    mtr: {
      icon: PUBLIC_URL + '/icons/dot.svg',
      settings: {
        cornerBackgroundColor: 'black',
        cornerStrokeColor: 'white',
        cornerSize: 12
      }
    },
  }, function() {
     // canvas.renderAll();
  } );

  fabric.Shape = fabric.Group;
  fabric.Raceway = fabric.Group;
  fabric.Face = fabric.Path;
  fabric.Side = fabric.Path;
  fabric.Trim = fabric.Path;
  fabric.Top = fabric.Path;
  fabric.Graphics = fabric.Group;
  fabric.Button = fabric.Group;
  fabric.Backlight = fabric.Path;
  fabric.Svg = fabric.Path;
}

export function addSignGroups(canvas, groups, options={}) {
    groups.forEach((groupData) => {
      addSignGroup(canvas, groupData, options);
    });
};

export function addSignGroup(canvas, groupData, options={}) {
    let groupOptions = {
      face: groupData.parts.find(x => x.type_name === "face"),
      side: groupData.parts.find(x => x.type_name === "side"),
      trim: groupData.parts.find(x => x.type_name === "trim"),
      raceway: groupData.parts.find(x => x.type_name === "mount" && x.name === "raceway"),
      lit: groupData.parts.find(x => x.type_name === "lighting" && x.name.indexOf("led") != -1),
      halo: groupData.parts.find(x => x.type_name === "lighting" && x.name.indexOf("back") != -1),
      backdrop: groupData.parts.find(x => x.type_name === "backdrop"),
      spacing: groupData.spacing,
      anchor: groupData.anchor,
      flipped: groupData.flipped_ind == 1, //groupData.product.builder_type === "shapes"
      rotate: groupData.rotate_angle,
      nightViewMode: options.nightViewMode
    }

    const group = createObjects(
      canvas,
      groupData.shapes, 
      groupData.size,
      groupOptions
    );

    if (groupData.resizable) {
      group.lockUniScaling = false;
      group.lockScalingX = false;
      group.lockScalingX = false;
    }
  
    group.type = groupData.product.builder_type    
    group.data = groupData;
    group.options= groupOptions;
    group.added = true;

    group.left = parseInt(options.position_x || groupData.position_x);
    group.top = parseInt(options.position_y || groupData.position_y);

    if (group.left == 0 && group.top == 0)
        group.center();
        
    group.setCoords();

    group.sizeBox = new window.fabric.Text(parseInt(group.data.custom_size_height || group.data.size_height) + '" x ' + parseInt(group.data.custom_size_width || group.data.size_width) +'"', { 
        fill: "black",
        textBackgroundColor: "white",
        fontSize: 25,
        fontWeight: 500,
        fontFamily: "Arial",
        textAlign: "right",
        opacity: 1,
        visible: false
    });

    // initial position
    group.sizeBox.type = "size";
    group.sizeBox.left = (group.getScaledWidth()/2) - group.sizeBox.width - 15;
    group.sizeBox.top = (group.getScaledHeight()/2) + 15;

    group.add(group.sizeBox);

    return group;
};
  
export function getGroupById(canvas, id) {
    let obj = null;

    canvas.getObjects().forEach((object) => {
      if (object.data && object.data.id == id) 
        obj = object;
    });

    return obj;
};

export function updateSignGroup(canvas, group, groupData, options={}) {
    if (group) {
      canvas.remove(group);
    }

    return addSignGroup(canvas, groupData, options);
}

export function createObjects(canvas, shapes, size, options={}) {
   //console.log("canvas.createObjects", shapes, size, options)

    // group for all of the shapes
    const group = new window.fabric.Group();

    //group.type = type;
    group.type = shapes.length > 1 ? "letters":"shapes";
    group.hasControls = true; // no end user controls, they have to use our edit modal

    group.lockScalingX = false;
    group.lockScalingY = false;

    group.lockMovementX = false;
    group.lockMovementY = false;

    group.lockScalingFlip = true;
    group.lockRotation = true;

    group.setControlsVisibility({
        bl: true,
        br: true,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: true,
        tr: true,
        mtr: false
    });

    group.padding = settings.GROUP_PADDING; // padding between border and objects
    group.borderDashArray = [7, 7];
    group.borderColor = "black";
    //group.borderColor = groupBorderColor ? "#" + groupBorderColor.hex_day : "#ff0000";

    //group.strokeWidth = 3;
    group.originX = "left";
    group.originY = "top";

    /*
    var backlightColor = backlightInd == 1 ? backlightColor.hex_day == "ffffff" ? "#ffff94" : "#" + backlightColor.hex_day : "#000000";
    var backlightShadow = {
    color: backlightColor,
    affectStroke: true,
    blur: 25,
    offsetX: 0,
    offsetY: 0
    };
    */

    //let x = 0;
//    let lastKerningData = {}; // Kerning data is in letter pairs. This is the data from the previous letter to check.

    let height = size * settings.PIXELS_IN_INCH;
    let needsBaseline = shapes.length > 1;

    // create a fake line in the group that represents the base size of the letter and the 0x0 point
    if (needsBaseline) {
        var sizeline = new window.fabric.Line([0, 0, 5, height], {
            type: "sizer",
            fill: "green",
            selectable: false,
            hasControls: false,
            hasBorders: false,
            opacity: 1
        });

        group.addWithUpdate(sizeline);
    }

    let haloShadow = null;
    let haloColor = null;
    let backdropColor = null;

    if (options.halo && options.halo.color) {
        haloColor = options.halo.color.hex_day == "ffffff" ? "#ffff94" : "#" + options.halo.color.hex_day;

        haloShadow = {
            color: haloColor,
            affectStroke: true,
            blur: 25,
            offsetX: 0,
            offsetY: 0
        };
    }
    if (options.backdrop) {
        backdropColor = options.backdrop.color ? options.backdrop.color : options.backdrop.default_color ? options.backdrop.default_color : {hex_day: "FFFFFF", hex_night: "FFFFFF"};
    }

    for (let shapeIndex=0; shapeIndex<shapes.length; shapeIndex++) {
        let shapeData = shapes[shapeIndex];

        //console.log("shape", shapeData);

        let path = createObject(shapeData, size, options);

        path.data = JSON.parse(JSON.stringify(shapeData));
        path.data.size = size;

        path.hasBorders = false;
        path.hasControls = false;

        // set the inital spacing to the default
        //path.data.spacing = path.data.kerning_default ? (path.data.kerning_default + settings.SPACING_ADJUSTMENT) : 0;

        //path.top = 0;
        path.left = 0;//x;

        group.addWithUpdate(path);
/*
        lastKerningData = {
            default: shapeData.kerning_default || 0,
            minimum: shapeData.kerning_minimum || 0
        };
*/
        // Copy the first layer from the path/shape into our backlight layer
        if (haloShadow) {
            const faces = path.getObjects("face");

            if (faces.length) {
                const face = faces[0];

                face.clone((newpath) => {
                    newpath.type = "backlight";
                    newpath.id = "backlight" + shapeIndex;
                    newpath.left = face.left;
                    newpath.top = face.lettersGroup;
                    newpath.fill = haloColor;
                    newpath.stroke = haloColor;
                    newpath.strokeWidth = 1;
                    newpath.setShadow(haloShadow);

                    group.add(newpath);

                    //newpath.sendToBack();
                });
            }
        }
        if (options.backdrop) {
            if (shapeData.backdrop_path) {
                let backdropPath = shapeData.backdrop_path;
                
                if (options.rotate && options.flipped) {
                    backdropPath = svgpath(shapeData.backdrop_path).scale(-1, 1).rotate(90).toString();
                }
                else if (options.rotate) {
                    backdropPath = svgpath(shapeData.backdrop_path).rotate(90).toString();
                }
                else if (options.flipped) {
                    backdropPath = svgpath(shapeData.backdrop_path).scale(-1, 1).toString();
                }

                const backdrop = new window.fabric.Path(backdropPath);
                const top = path.getObjects("face")[0];
                
                backdrop.type = "backdrop";
                backdrop.id = "backdrop" + shapeIndex;
                backdrop.left = top.left;
                backdrop.top = top.top;
                backdrop.fill = "#"+backdropColor.hex_day;
                backdrop.color_day = backdropColor.hex_day;
                backdrop.color_night = backdropColor.hex_night;
                //backdrop.stroke = "#"+backdropColor;
                backdrop.strokeWidth = 0;

                backdrop.scaleX = top.scaleX;
                backdrop.scaleY = top.scaleY;
                //backdrop.scaleX = ((settings.PIXELS_IN_INCH * size * shapeData.backdrop_width_ratio) / top.scaleX);// / top.scaleX)/1000;
                //backdrop.scaleY = ((settings.PIXELS_IN_INCH * size * shapeData.backdrop_height_ratio) / top.scaleY);// / top.scaleY)/1000;
                
                //if (shapeIndex == 0 || shapeIndex == shapes.length-1)
                //    group.addWithUpdate(backdrop);
                //else
                    group.add(backdrop);  

            }
            else {
                // create placeholder
                const backdrop = new window.fabric.Rect({ width: 10, height: 10, fill: "red" });
                backdrop.opacity = 0;
                backdrop.type = "backdrop";
                backdrop.id = "backdrop" + shapeIndex;

                group.add(backdrop);  
            }

            /*
            const tops = path.getObjects("face");

            if (tops.length) {
                const top = tops[0];

                top.clone((path) => {
                    path.type = "backdrop";
                    path.id = "backdrop" + shapeIndex;
                    path.left = top.left;
                    path.top = top.top;
                    path.fill = "#"+backdropColor;
                    path.stroke = "#"+backdropColor;
                    path.strokeWidth = 15;
                    path.scaleX = path.scaleX;
                    path.scaleY = path.scaleY;

                    group.add(path);
                    //path.left = top.left - ((path.getScaledWidth()-top.getScaledWidth())/2);
                    //path.top = top.top - ((path.getScaledHeight()-top.getScaledHeight())/2);
                });
            }
            */
        }    
    };

    //
    // Calculate and show the font baseline. Used for raceways and snapping.
    if (needsBaseline) 
        addBaseline(group, size);

    // Add it all to the canvas
    //this.getRoot().addWithUpdate(group);
    canvas.add(group);

    // set the initial spacing of the letters
    adjustLetterSpacing(group, options);

    if (needsBaseline) 
        adjustBaseline(group);

    // FIX: The area/bounds of the group has changed since we manipulated the objects inside of it
    //group.center()
    //group._calcBounds();
    //group._updateObjectsCoords();

    return group;
};

export function getEventPageCoords(e) {
    var pageX = e.e.pageX;
    var pageY = e.e.pageY;

    if (typeof pageX == "undefined") pageX = 0;
    if (typeof pageY == "undefined") pageY = 0;

    if (pageX == 0 && pageY == 0 && e.e.type == "touchmove") {
      if (e.e.touches && e.e.touches.length == 1) {
        pageX = e.e.touches[0].pageX;
        pageY = e.e.touches[0].pageY;
      }
    }

    return {
      x: pageX,
      y: pageY
    };
  };

function createObject(shape, size, options) {
    var group = new window.fabric.Group();

    //console.log(shape.code, size, options)
    group.type = "shape";

    // default
    const depthPerInch = 4;
    let depth = options.depth || 2; // always have a little

    const face = options.face;
    const trim = options.trim;
    const side = options.side;

    if (side) {
        depth = Math.round(parseFloat(side.size) * depthPerInch);
    }

    let i = 1;
    const stepSize = 0.25; //1;

    const baseline = size * settings.PIXELS_IN_INCH;
    const blank = shape.code == " " || shape.path == "";

    if (blank) {
        depth = 0;

        shape.top_offset = 1; // its incorrect in database

        if (shape.width_ratio == 0) 
            shape.width_ratio = 0.5; // ?
    }

    let dropShadow = {
        color: "rgba(1,0,0,0.6)",
        blur: 4,
        offsetX: (settings.SVG_EXPORT_SIZE / size) * 4,
        offsetY: (settings.SVG_EXPORT_SIZE / size) * 4
    };

    let pathData = shape.path;

    if (options.flipped) {
        pathData = svgpath(pathData).scale(-1, 1).toString();
    }

    let widthRatio = shape.width_ratio;
    let heightRatio = shape.height_ratio;

    if (options.rotate) {
        pathData = svgpath(pathData).rotate(90).toString();

        let tempRatio = heightRatio;

        heightRatio = widthRatio;
        widthRatio = tempRatio;
    }
    
    //
    // Create the alternative sides that create the shadow look (alterating horizontal/vertial shifts)
    //
    for (let i = depth + 1; i > 0; i--) {
        let path;

        // special case for blank/space character
        if (blank) {
            path = new window.fabric.Rect({ width: 10, height: 10, fill: "red" });
            path.opacity = 0;
        } 
        else {
            path = new window.fabric.Path(pathData);
        }

        path.fillRule = "evenodd";

        const right = i % 2 == 0;
        const down = !right;
        const viewMode = options.nightViewMode ? "night" : "day";

        //console.log("createObject viewMode", options.nightViewMode)
        // Final face?
        if (i == 1 && face) {
            path.type = "face";
            path.stroke = "#" + (trim ? trim.color["hex_"+viewMode] : face.color["hex_"+viewMode]);

            if (face.color.hex_day == "transparent") {
                path.fill = "transparent";
            }
            else {
                if (viewMode == "night" && options.lit)
                    path.fill = "#" + face.color["hex_day"];
                else
                    path.fill = "#" + face.color["hex_"+viewMode];

                // save these for easy day/night switching
                //if (face.color.colorsystem_id == 2 || !options.lit) {// ? } || ledsInd != 1) {
                path.color_day = face.color.hex_day;
                path.color_night = face.color.hex_day;

                if (face.color.colorsystem_id == 2 || !options.lit) {// ? } || ledsInd != 1) {
                    path.color_night = face.color.hex_night;
                }

                path.stroke_day = trim ? trim.color.hex_day : face.color.hex_day;
                path.stroke_night = trim ? trim.color.hex_night : face.color.hex_night;

                if (face.faceart) {
                    // We aren't loaded until the image is downloaded
                    //group.loaded = false;
            
                    // We don't want this path since we are going to load an image
                    path.fill = "transparent";
                    path.hasFaceArt = true;
                    path.loaded = false;
            
                    let filename = shape.code;

                    if (filename.match(/[a-zA-Z]/) != null && shape.code == shape.code.toLowerCase())
                      filename = filename + "_";
                    else if (shape.code == "-") filename = "hyphen";
                    else if (shape.code == ".") filename = "period";
                    else if (shape.code == "'") filename = "apostrophe";
                    else if (shape.code == ",") filename = "comma";
            
                    const faceArtShapeUrl =
                      "fontart/" +
                      face.faceart.name +
                      "/font-" +
                      shape.style_id +
                      "/" +
                      filename +
                      ".png";

                    window.fabric.Image.fromURL(
                        window.FILE_ASSET_URL + faceArtShapeUrl,
                        (img) => {
                            const last = group.getObjects("face")[0];
                
                            img.type = "image";
                            img.loaded = true;
                            path.loaded = true;
                
                            group.add(img);
                
                            //img.setLeft(-group.width/2 + settings.STROKE_SIZE);
                            //img.setTop(-group.height/2 + last.topOffset - (size*1.2/settings.PIXELS_IN_INCH));// - (settings.STROKE_SIZE*path.strokeWidth));
                
                            //var scale = last.getHeight() / img.getHeight()
                
                            img.scaleX = last.getScaledWidth() / img.width;
                            img.scaleY = last.getScaledHeight() / img.height;
                
                            img.left = last.left;
                            img.top = last.top;
            
                            //last.setStrokeWidth(last.strokeWidth+1);
                            last.bringToFront();
                
                            img.setCoords();
                
                            // only fire the event afer it has been fully loaded and added (sometimes the images take a few seconds to download)
                            //if (group.added) {
                            //}
                
                            group.canvas.renderAll();
            
                            /*
                            setTimeout(() => {
                                manager.canvas.fire("sign:object:image:loaded", group);
                                group.canvas.renderAll();
                            }, 250);
                            */
                        },
                        { crossOrigin: "anonymous" }
                    );
                }
            }
        } 
        else if (i == 2 && trim) {
            //} && !face.image) {
            path.type = "top";

            path.fill = "#" + trim.color["hex_"+viewMode];
            path.stroke = "#" + trim.color["hex_"+viewMode];

            // save these for easy day/night switching
            path.color_day = trim.color.hex_day;
            path.color_night = trim.color.hex_night;
        } 
        else {
            path.type = "side";

            // Were we given multiple (alternating) side colors or just a single solid side color?
            var sideHex = down ? side?side.color["hex_"+viewMode] : face.color["hex_"+viewMode] : side ? side.color["hex_"+viewMode] : face.color["hex_"+viewMode];

            path.fill = "#" + sideHex;
            path.stroke = "#" + sideHex;
            //path.setOpacity(.8)

            // save these for easy day/night switching
            path.color_day = sideHex;
            path.color_night = side ? side.color["hex_"+viewMode] : face.color["hex_"+viewMode];
        }

        //
        // Font shapes are exported at size 10, which is 10x7=70pixels.  Size 10 is our min size, so we
        // need to scale it up proportially to the supplied size
        //

        // Size it...
        path.scaleX = (settings.PIXELS_IN_INCH * size * (shape.custom_width_ratio || widthRatio)) / path.getScaledWidth();
        path.scaleY = (settings.PIXELS_IN_INCH * size * (shape.custom_height_ratio || heightRatio)) / path.getScaledHeight();

        path.strokeLineCap = "round";
        path.strokeWidth = settings.STROKE_SIZE / path.scaleX;

        path.topOffset = shape.top_offset * (size * settings.PIXELS_IN_INCH);

        if (down) {
            path.left = i / 2 + stepSize;
            path.top = baseline - path.topOffset + (i / 2 - stepSize);
        } else {
            path.left = i / 2; 
            path.top = baseline - path.topOffset + (i / 2 + stepSize);
        }

        if (i == 1) {
            path.left = i - 1;
        }

        path.hasBorders = false;
        path.hasControls = false;

        if (i == depth + 1) { //} && options.backlight) {
            // don't need a shadow if we have a halo
            if (!options.halo && !options.noshadow)
                path.setShadow(dropShadow);
        }

        //if (haloShadow && (i == depth-1 || i == depth-2)) {
        //    path.setShadow(haloShadow);
        //}

        group.addWithUpdate(path);
    }

    return group;
};

function addBaseline(group, size) {
    group.setCoords();

    //var top = -(group.getHeight()/2);
    var realHeight = size * settings.PIXELS_IN_INCH; // height of object ignoring any letter desents
    var top = realHeight / 2 - (group.height - realHeight) / 2;
    var left = -group.width / 2;
    //var left = group.getObjects()[0].getLeft();
    
    var baseline = new window.fabric.Line(
        [left, top, left + group.width, top],
        {
            type: "baseline",
            fill: "#ff6405",
            stroke: "#ff6405",
            strokeWidth: 3,
            selectable: false,
            opacity: 0
        }
    );

    group.add(baseline);

    group.baselineTop = top;
    group.baseline = baseline;
};

function adjustBaseline(group) {
    var baseline = group.baseline;

    baseline.set("x1", -group.width / 2);
    baseline.set("x2", group.width / 2);
};

export function getShapeTop(shape) {
    const tops = shape.getObjects("top");
    const path = tops.length ? tops[0] : shape.getObjects("face")[0];

    return path;
}

export function ghostGroup(group, restore) {
    group.ghosted = restore ? false : true;

    group.getObjects("shape").forEach((shape) => {
      // ignore space characters
      if (shape.data.code != " ") {
        shape.getObjects().forEach((layer) => {
          if (layer.type == "face" || layer.type == "image")
            layer.opacity = restore ? 1 : 0.5;
          else 
            layer.visible = restore ? true : false;
        });
      }
    });

    /*
    group.getObjects("raceway").forEach((raceway) => {
      raceway.visible = restore ? true : false;
    });
    */
    group.getObjects().forEach((object) => {
      if (object.type === "backlight" || object.type === "raceway" || object.type === "backdrop")
        object.visible = restore ? true : false;
    });
}

/*
window.fabric.Loading = window.fabric.util.createClass(window.fabric.Object, {

    type: 'loading',

    initialize: function (options) {
        options = options || {};

        this.cog = new Image();
        
        this.cog.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABK1JREFUeNqMVt1ZGzkUvVfS4IW1l8GO82w6IBXE7mCpAFMB+Pt4Z6iApALcAe4AU0HoAJfg7BPYHinnXmmciX+y0YdmJHnQ0bk/R5cvh5cUyFPwRD4EChgEvGWMB36R3+JaiTkmD5gOs8yNb25uLlerFf1pM2yIGA82TEY7xow1oj4GBU6S6yywPNG4JwDH+XGv0Whs7ndN8n97mmPsLCSYgy7ImPQE/pFDyAF+7L0fgTNFUDBcLal90taD1doQ/T6NT9DnW8zkT+jJuQVYukG3hifCVk/L3JOxMBa8VVlSp9MhHKLaB+zpNo1fdgEpmByuMqUAV5viOQLwXNax9KBAFNEEpN1pUwnQmvl6aTza6zNjrCKaymeyOdYAMgfg18iG4T/qw+AC94zvpzDjcwqOXo3VGH26H0xMZ7jPxgT0R2zUi4BYt6bAfEbJvJFZKA4ODgZ5nhcJLE9mk35X21vWC/TXKmiwr2xszoQd/PQv3t/QCzY2twpqBpb5FKOp+hCgzWaTWq0W1Xx0ij5An9WC5VtiLMwvNBrVaSGMvQk5jHQVPN7sb0HzAtE+QJrNgrcUNEARieWCut0ugR0tl8sKcJ5Ahc3jRviPK8ZGTaaBwGKyT+gTiwM4a3Jrba6MbeVXo5F4kp9shn29ndUYC9vLirGDXzRhrYhD8DME5Hkg22df5rDYS/RXmVIsaP/Q/SXs600YnifTjbeSWliEdTYb3QyTqYfdDKTL4B1KS6tVqf6SgGq3P9BvZGpvNIrPCgVKZlGlCDQDxJiCjVppCab05DJHzb+b1Gm36X80cVjLuzozexs0f6IgRkA5XRhzIixRL1+IzhwdHVHrn1Y9oXe1i10aKT6bGGhg1CKK+cT0zCGCs0oXTIogybJMw/779//o48duMvnO9rzLn+Kz8wgS5Shqo4njpCoOQA5Ajb8adHh4SMvVghaLhYb/HsBip88krNVISSEigOlhjmi0LziNhr6wOsgO9C1339vbGznnNAU2AM9Svk235cqKieKGkldAf7DGvTrjnjJnzyQoMu0ZTuZgUqvmlYR+f39XIE4uqCX1E/rDZpCYmKwOOmivAfYK9KF1AM7EdG4uAMLAOjmQideQXOJQkyUisqYiFRhtSFbxCxj8do0T30dmTvLhC+an0MZZVBHX09tBTG4qFigZEJEChjTIEwtRik81Qa7uOQU0IrYAe7FRjqYw6SlYjgAyN1GmHsFIGPfVnxzFuFITKEkfYK+oWZ5qKlIkcZ7UE92oXBmeIgIxtAO5UtSHqo9uiLW+sme5ejSIRASeAFR4LYy8MMzL1aq3EYWzJF28BgMEzGYpBkrMKelgl+P6uTcVY8NjLYyYPwMTCcufSaouH6al9xNJcjC82vDb9uVZKbrWIumNO+waVsu1TCC+Wxcg6xaSpsZSYM2wLO9/U8qZWH+wztQnsfAxV/E3MIKZVf1FsmJVV8mamhEmxZ0X7sSsABsGv1tZJGejmptU7FBUDYzPAXQBwFEEl+9+stFEroJEci2ELwIMmZuWoSTE9DYYcWVCjlJrZWMpeBhlAEqBiulPE84S3ixU5gSTwGGOdyEVNJXxA8nPevshwABHktBS1YoQ+QAAAABJRU5ErkJggg==';
                
        this.callSuper('initialize', options);

        this.rotation = 0;
        this.width = 27;
        this.height = 27;
    },

    _render : function (ctx) {
        //ctx.globalCompositeOperation = 'destination-over';
        ctx.save();
        ctx.clearRect(0,0,27,27);
        ctx.translate(13.5,13.5); // to get it in the origin
        this.rotation +=1;
        ctx.rotate(this.rotation*Math.PI/64); //rotate in origin
        ctx.translate(-13.5,-13.5); //put it back
        ctx.drawImage(this.cog,0,0);
        ctx.restore();
    }
});
*/
export function silhouetteGroup(canvas, group) {
    //console.log("ghostGroup", group.data.id, restore, group.ghosted)
    //group.selectable = false;
    //group.hasBorders = false;
    //group.hasControls = false;
    group.silhouetted = true;

    group.getObjects().forEach((object) => {
        if (object.type == "shape") {
            object.getObjects().forEach((layer) => {
              if (layer.type == "face") {
                layer.fill = "#cccccc";
                layer.stroke = "black";
                layer.strokeWidth = 1;
                layer.opacity = 0.3;
              }
              else {
                group.remove(layer);
                layer.opacity = 0;
                layer.visible = false;
              }
            });
        }
        else if (object.type == "raceway") {
            object.getObjects().forEach((layer) => {
                if (layer.type == "top") {
                  layer.fill = "#cccccc";
                  layer.stroke = "black";
                  layer.strokeWidth = 1;
                  layer.opacity = 0.7;
                }
                else {
                  group.remove(layer);
                  layer.opacity = 0;
                  layer.visible = false;
                }
            }); 
        }
        else group.remove(object);
    });

    //group.opacity = .5
    group.dirty = true;

    // make sure it doesn't already exist 
    /*
    if (group.getObjects("spinner").length == 0) {
        loadSpinner((spinner) => {    

            spinner.scaleToWidth(50);
            spinner.scaleX = spinner.scaleX / group.scaleX;
            spinner.scaleY = spinner.scaleX;
            spinner.left = 0;
            spinner.top = 0;

            group.add(spinner);

            if (group.getScaledWidth() > 250) {
                const loading = new window.fabric.Text("Processing...", { 
                    fill: "#ffffff",
                    stroke: "#000000",
                    strokeWidth: 1,
                    originX: 'left',
                    originY: 'center',
                    fontSize: 25,
                    fontWeight: 600,
                    fontFamily: "Arial",
                    hasBorders: false,
                    hasControls: false      
                });

                spinner.left = 0 - ((spinner.getScaledWidth() + loading.getScaledWidth())) / 2;

                loading.type = "spinner"
                loading.top = spinner.top;
                loading.left = spinner.left + spinner.getScaledWidth()/2 + 5;

                group.add(loading);
            }

            spinner.animate('angle', 1440, { 
                duration: 20000,
                onChange: canvas.renderAll.bind(canvas)
            });

            canvas.renderAll();
        })
    }      
    */  

    canvas.renderAll();
}

function loadSpinner(callback) {

    window.fabric.loadSVGFromURL("/icons/spinner.svg", (objects, options) => {
        var svg = window.fabric.util.groupSVGElements(objects, options);
        
        svg.type = "spinner";
        svg.objectCaching = true;
        svg.scaleToWidth(27);
        svg.originX = 'center';
        svg.originY = 'center';
        svg.left = 0;
        svg.top = 0;

        callback(svg);
    });
}

export function processingGroup(canvas, group, done) {
    if (done) {
        ghostGroup(group, true);
        group.selectable = true;

        group.getObjects("spinner").forEach((spinner) => {
            group.remove(spinner);
        })
    }
    else {
        ghostGroup(group, false);
        
        group.selectable = false;

        loadSpinner((spinner) => {            
            group.add(spinner);
            spinner.opacity = .75;
            //spinner.scaleX = -group.scaleX;
            //spinner.scaleY = -group.scaleY;

            spinner.animate('angle', 720, { 
                duration: 10000,
                onChange: canvas.renderAll.bind(canvas)
            });

            canvas.renderAll();
        })
    }
}

export function adjustLetterSpacing(group, options) {
    var i = 1;
    var x;
    var groupX = group.left;
    var groupW = group.width;
    var groupY = group.top;
    var newGroupWidth = 0;

    // each letter has it's own spacing value, which can vary based on the default and minimum kerning values,
    // this one is the default that is applied to  new letters when edited
//    group.data.spacing = group.data.spacing + amount;

//    if (group.data.spacing < -1) group.data.spacing = -1;

    //console.log("adjustLetterSpacing")
    // process each letter and determine their desired position based on the spacing
    group.getObjects("shape").forEach(function (letter) {
        //letter.data.spacing = parseFloat(letter.data.spacing || 0) + parseFloat(options.spacing || 0);

        //console.log("shape", letter.data);

        letter.data.spacing = 0;

        // start with kerning default
        if (letter.data.kerning_default)
            letter.data.spacing = letter.data.kerning_default;

        // now add in the overall group spacing
        letter.data.spacing += parseFloat(options.spacing || 0);

        if (letter.data.spacing < letter.data.kerning_minimum) {
            letter.data.spacing = letter.data.kerning_minimum;
        }

        var width = 0;
        var spacing = 0;

        // We only want the real with and not the entire group with that includes the trim, sides, etc
        letter.getObjects("face").forEach(function (face) {
            width = face.getScaledWidth();
        });

        if (i == 1) {
            // first letter sets our base position
            x = letter.left;
        } 
        else {
            // space?
            if (letter.data.letter == " ") {
                // base width for a space (inches)
                width = settings.SPACE_SIZE * (letter.data.size / settings.SVG_EXPORT_SIZE);
            }

            // adjust for spacing
            spacing = letter.data.spacing * (letter.data.size / settings.SVG_EXPORT_SIZE) * settings.PIXELS_IN_INCH;

            x = x + spacing;
        }

        letter.x = x;

        i++;
        x += width;
        newGroupWidth += width + spacing;
    });

    // force the bounding rectangle to update to reflect the change
    group.width = newGroupWidth + settings.GROUP_PADDING;

    var i = 1;
    var adjustment = 0;

    //
    // Now process them and set their positions
    group.getObjects("shape").forEach(function (letter) {
        if (i == 1) {
            letter.left = -group.width / 2;
            adjustment = letter.left - letter.x;
        } else {
            letter.left = letter.x + adjustment;
        }
        letter.setCoords();

        // find the background shape that matches this one
        var backShape = group.getObjects("backlight")[i - 1];
        var backDropShape = group.getObjects("backdrop")[i - 1];

        if (backShape) {
            // center these back on the source shapes
            backShape.left = letter.left;
            backShape.top = letter.top;
            backShape.setCoords();
        }
        if (backDropShape) {
            // center these back on the source shapes
            backDropShape.originX = "center";
            backDropShape.originY = "center";
            backDropShape.left = letter.left + (letter.width / 2);
            backDropShape.top = letter.top + (letter.height / 2);
            backDropShape.setCoords();
        }

        i++;
    });

    group.getObjects("backlight").forEach(function (backShape) {
        backShape.sendToBack();
    });
    group.getObjects("backdrop").forEach(function (backShape) {
        backShape.sendToBack();
    });

    //var anchor = options.anchor;

    //if (anchor == "TR" || anchor == "BR" || anchor == "MR") {
    //  group.left = groupX + groupW - group.getWidth();
    //} else if (anchor == "TM" || anchor == "BM") {
    //  group.left = groupX + groupW / 2 - group.getWidth() / 2;
    //} else {
        group.left = groupX;
    //}

    group.top = groupY;
    group.setCoords();

    //group.setCoords();
    //designer.refresh();
    //if (!noEvent) {
    //  canvas.fire('sign:object:spacingChanged', group);
    //}
};

export function isSignGroup(object) {
    return object.type == "letters" || object.type == "shapes"
}

export function getGroups(canvas, includeHidden=false, type=null) {
    const groups = [];

    for (const object of canvas.getObjects()) {
        if ((object.visible || includeHidden) && isSignGroup(object) && (!type || object.type==type))
            groups.push(object)
    }
    
    return groups;
}
export function getGroupCount(canvas, type=null) {
    return getGroups(canvas, false, type).length;
}
export function getLastGroupAdded(canvas) {
    const groups = getGroups(canvas);

    return groups[groups.length-1];
}
export function toggleOverlay(canvas, show) {
    if (show) {
        const vpt = canvas.vptCoords;

        const rect = new window.fabric.Rect({ 
            left: vpt.tl.x,
            top: vpt.tl.y,
            width: vpt.tr.x - vpt.tl.x,//settings.backgroundWidth, 
            height: vpt.bl.y - vpt.tl.y,//settings.backgroundHeight, 
            fill: '#000000', 
            opacity: 0.5 
        });
        
        rect.type = "overlay";
        rect.hasBorders = false;
        rect.hasControls = false;
        rect.selectable = false;

        canvas.add(rect);

        //rect.center();
        rect.sendToBack();

        // Do we have a background layer?  If so, then make sure we are above it.
        canvas.getObjects("background").forEach((object) => {
            rect.bringForward();
        });
    }
    else {
        canvas.getObjects("overlay").forEach((object) => {
            canvas.remove(object);
        });
    }

    canvas.renderAll();
}

export function inNightViewMode(canvas) {
    return canvas.getObjects("overlay").length > 0;
}

export function toggleNightViewMode(canvas, enabled) {
    toggleOverlay(canvas, enabled);

    const mode = enabled ? "night":"day";

    canvas.getObjects().forEach( function(object) {
        if (isSignGroup(object)) {
            object.getObjects().forEach((shape) => {
                if (shape.type == "shape") {
                    shape.getObjects().forEach((piece) => {
                        var color = piece["color_" + mode];
                        var stroke = piece["stroke_" + mode];

                        if (stroke)
                            piece.stroke = "#" + stroke;
                        if (color) {
                            piece.fill = "#" + color;
                            if (!stroke)
                                piece.stroke = "#" + color;
                        }
                    });
                }
                else if (shape.type == "backdrop") {
                    console.log("toggleNightViewMode backdrop", mode, shape.color_day, shape.color_night)
                    shape.fill = "#" + shape["color_" + mode];
                }
            });

            /*
            if  (object.options.backlight) {
                object.getObjects("shape").forEach(function(shape) {
                    var sides = shape.getObjects("side");

                    if (sides.length > 2) {
                        var lastSide = sides[0];
                        var secondLastSide = sides[1];

                        // copy over the halo shadow to make it brighter
                        secondLastSide.setShadow(exists ? lastSide.shadow : null);
                    }
                });
            }
            */
        }
    });    

    canvas.renderAll();
}

export function getGroupLetters(group) {
    let letters = "";
    
    if (group && group.shapes) {
        for (const shape of group.shapes) {
        letters += shape.code;
        }
    }
    
    return letters;
}
export function repositionGroupByAnchor(canvas, group, oldPosition) {
    const newWidth = group.getScaledWidth();
    const newHeight = group.getScaledHeight();
    const anchor = group.data.anchor || "TL";

    const oldRight = oldPosition.left + oldPosition.width;
    const oldBottom = oldPosition.top + oldPosition.height;

    if (anchor === "C" || anchor === "TC" || anchor === "BC" || anchor === "TM" || anchor === "BM")
        group.left = oldPosition.left + (oldPosition.width/2) - (newWidth/2);
    else if (anchor === "TL" || anchor === "ML" || anchor === "BL")
        group.left = oldPosition.left;
    else if (anchor === "TR" || anchor === "MR" || anchor === "BR")
        group.left = oldRight - newWidth;


    if (anchor === "C" || anchor === "ML" || anchor === "MR") 
        group.top = oldPosition.top + (oldPosition.height/2) - (newHeight/2);
    else if (anchor === "TM" || anchor === "TC" || anchor === "TL" || anchor === "TR")
        group.top = oldPosition.top;
    else if (anchor === "BM" || anchor === "BC" || anchor === "BL" || anchor === "BR")
        group.top = oldBottom - newHeight;

    canvas.renderAll();

    group.data.position_x = group.left;
    group.data.position_y = group.top;
    group.data.position_r = group.left + (group.data.size_width * settings.PIXELS_IN_INCH);
    group.data.position_b = group.top + (group.data.size_height * settings.PIXELS_IN_INCH);
}

export function getLastAddedPart(canvas, likePart) {
    const group = getLastGroupAdded(canvas);

    if (group) {
        for (const part of group.data.parts) {
            if (part.type_id == likePart.type_id && part.name == likePart.name)
                return part;
        } 
    }
}

export function switchGroupPartsToProduct(group, newProduct) {
    //console.log("switchGroupPartsToProduct", group.data, newProduct.name)
    
    let data = group.data;
    let actualProduct = newProduct;

    if (newProduct.bundle_ind == 1) {
        if (newProduct.bundle_product1 && newProduct.bundle_product1.builder_type == group.type)
            actualProduct = newProduct.bundle_product1;
        else if (newProduct.bundle_product2 && newProduct.bundle_product2.builder_type == group.type)
            actualProduct = newProduct.bundle_product2;
    }

    //
    // We need to reset all of the product parts and keep all of the existing ones
    // that are still valid 
    const parts = [];

    // Process the existing parts and grab the ones that are valid for the new product
    for (const part of data.parts) {
        if (actualProduct.parts.find(p => p.id == part.id)) {
            parts.push(part);
        }
        else {
            console.log("Part no longer valid", part.id, part.name)
        }
    }

    //console.log("Processing new product parts")

    // Process the new product and add in any missing parts
    for (const part of actualProduct.parts) {
        // Ignore if already exists
        // if (parts.find(p => p.id == part.id)) {
        //     console.log("Part already exists", part.id, part.name)
        // }
        if (part.required_ind == 1 || part.default_ind == 1) {
            // Do we have a part of this type already?
            const existing = parts.find(p => p.type_id == part.type_id)

            if (existing) {
                console.log("Existing part of same type already exists", part.type_name, part.id, part.name, existing.id, existing.name);
            }
            else {
                console.log("New Part is needed", part.id, part.name)

                if (!part.color && part.default_color) {
                    part.color = part.default_color;
                    part.color_id = part.color.id;
                }
                    
                parts.push(part);
            }
        }
        else {
            //console.log("Ingoring new part", part.id, part.name)
        }
    }

    return parts;
}

export function removeGroup(canvas, group, noEvent) {
    canvas.remove(group);

    if (!noEvent) canvas.fire("sign:object:removed", group);
}
export function removeAllGroups(canvas, noEvent) {
    getGroups(canvas).forEach((group) => {
      removeGroup(canvas, group, noEvent);
    })
}

export function refresh(canvas) {
    getGroups(canvas).forEach((obj) => {
      obj.dirty = true;
    });

    canvas.renderAll();
}