import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"
import { faDollarSign, faPencilAlt, faSearch, faTrash, faYenSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputGroup from 'react-bootstrap/InputGroup'

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    EditActionsCell, 
    BooleanCell,
    ButtonCell,
    SortHeaderCell,
    SortTypes,
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Loading from "../../components/loading"
import ColorDrawer from "../../drawers/drawer-color"
import ColorBox from '../../components/color-box'

import ColorSystemsDrawer from "../../drawers/drawer-colorsystems"
import ColorPartsDrawer from "../../drawers/drawer-colorparts"

import { postData } from "../../common/services/server"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";
import BoardField from "../../components/board/board-field";

const CategoriesWidth = 200;
const Padding = 10;

const Interface = styled.div`
    display: flex;
`
const Categories = styled.div`
    width: ${CategoriesWidth}px;
    flex-shrink: 0;
    margin-right: ${Padding}px;
    border: 1px solid #ccc;
    padding: 0px;
    overflow: auto;  
    position: relative;

    ul {
        list-style-type: none;
        margin: 0px 0px 25px 0px;
        padding: 0px;

        li {
            padding: 5px;
            border-bottom: 1px solid #ccc;
            cursor: pointer;
            font-size: 12px;
            display: flex;
            justify-items: center;
            justify-content: space-between;

            &:hover {
                background-color: #eee;
            }

            &.selected {
                background-color: #ccc;
            }
        }
    }

    > div {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding: 0px;

        button {
            padding: 8px;
            width: 100%;
        }
    }
`

function ColorsPanel(props) {
    const windowDimensions = useWindowDimensions();

    const [system, setSystem] = useState(null);
    const [systems, setSystems] = useState([])
    const [colors, setColors] = useState([]);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState({column: "name", dir: SortTypes.ASC});
    const [search, setSearch] = useState("")

    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    const [busyRow, setBusyRow] = useState(null);

    useBodyClass(`noscroll`);

    useEffect(
        () => {
            setIsLoading(true);

            postData("colors/systems", {},
                function(result) {
                    setSystems(result);
                },
                function(error) {
                    alert("Error loading color systems")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        []
    );

    useEffect(
        () => {
            if (system && system.id != 0) {
                postData("colors/data", {
                    colorsystem_id: system.id,
                    sort: sort.column,
                    sortdir: sort.dir,
                    page: page,
                    perPage: 100,
                },
                    function(result) {
                        setColors(result)
                    },
                    function(error) {
                        alert("Error loading colors")
                    }
                );   
            }
        }, 
        [system, sort, page]
    );

    function onAction(action, color) {
        console.log("action", action, color)

        if (action == "delete") {
            setBusyRow(color);

            postData("colors/delete", { 
                color_id: color.id
            },
                function(result) {
                    setColors(produce(draft => {
                        draft.splice(draft.findIndex(c => c.id == color.id), 1);
                    }));
                },
                function(error) {
                    alert("Error deleting color")
                },
                function() {
                    setBusyRow(null);
                }
            );
        }
        else if (action == "edit") {
            setSelected({mode:"color", color})
        }
    }

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }

    function onParts(color) {
        setSelected({mode:"parts", color})
    }
    function onSystems(color) {
        setSelected({mode:"systems", color})
    }

    return (
    <>
        <Interface>
            <Categories style={{height: windowDimensions.height - dimensions.headerHeight - (props.haveTabs ? dimensions.pageWithTabsPadding : dimensions.pagePadding)*2}}>
                <>
                    <ul>
                        {systems.map(sys => (
                            <li key={sys.id} onClick={() => setSystem(sys)} className={system && system.name==sys.name ? "selected":""}>
                                {sys.name}
                            </li>
                        ))}
                        <li onClick={() => setSystem({id: -1, name: "Orphaned"})} className={system && system.name=="Orphaned" ? "selected":""}>
                            [Orphaned Colors]
                        </li>     

                        <li>
                            <BoardField
                                label="Search"
                                type="text"
                                value={search}
                                saveUrl={`colors/search`}
                                saveIcon={faSearch}
                                onSaved={(colors) => {
                                    setSystem({id: 0, name: "Search Results"});
                                    setColors(colors);
                                }}
                            />  
                        </li>                   
                    </ul>
                </>
            </Categories>
            {
                isLoading 
            ?
                <Loading />
            :    
                <Table
                    rowHeight={40}
                    rowsCount={colors.length}
                    width={windowDimensions.width - dimensions.sideBarWidth - dimensions.pagePadding - CategoriesWidth - Padding}
                    height={windowDimensions.height - dimensions.headerHeight - (props.haveTabs ? dimensions.pageWithTabsPadding : dimensions.pagePadding)*2}
                    headerHeight={35}
                    touchScrollEnabled={true}
                >
                <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Name
                            </SortHeaderCell>
                        }
                        columnKey="name"
                        cell={<TextCell data={colors} />}
                        fixed
                        width={170}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Code
                            </SortHeaderCell>
                        }
                        columnKey="code"
                        cell={<TextCell data={colors} />}
                        width={250}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Hex Day
                            </SortHeaderCell>
                        }
                        columnKey="hex_day"
                        cell={({rowIndex, ...props}) => {
                            const color = colors[rowIndex];
                            
                            return (
                              <Cell {...props}>
                                <div style={{display:"flex", alignItems:"center"}}>
                                    <ColorBox color={color} />
                                    {color.hex_day}
                                </div>
                              </Cell>
                            )}}
                        width={125}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Hex Night
                            </SortHeaderCell>
                        }
                        columnKey="hex_night"
                        cell={({rowIndex, ...props}) => {
                            const color = colors[rowIndex];
                            
                            return (
                              <Cell {...props}>
                                <div style={{display:"flex", alignItems:"center"}}>
                                    <ColorBox color={{...color, hex_day: color.hex_night}} />
                                    {color.hex_night}
                                </div>
                              </Cell>
                            )}}
                        width={125}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                LED Color
                            </SortHeaderCell>
                        }
                        columnKey="led_name"
                        cell={<TextCell data={colors} />}
                        width={120}
                    />
                    <Column
                        header={
                            <Cell>
                                Systems
                            </Cell>
                        }
                        columnKey="systems_count"
                        cell={<ButtonCell data={colors} onClick={(color) => onSystems(color)} />}
                        width={120}
                    />                    
                    <Column
                        header={
                            <Cell>
                                Parts
                            </Cell>
                        }
                        columnKey="parts_count"
                        cell={<ButtonCell data={colors} onClick={(color) => onParts(color)} />}
                        width={120}
                    />

                    <Column
                        header={<Cell>Actions</Cell>}
                        cell={
                            <EditActionsCell 
                                data={colors} 
                                actions={[
                                    {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, permission:Permissions.Colors, permissionAction:Actions.Edit},
                                    {action:"delete", title:"Delete", variant:"danger", icon:faTrash, permission:Permissions.Colors, permissionAction:Actions.Delete},
                                ]}
                                busy={busyRow}
                                onClick={onAction} 
                            />
                        }
                        align="center"
                        width={110}
                    />
                </Table>
            }
        </Interface>
        {(selected && selected.mode == "color") && 
            <ColorDrawer 
                color={selected.color}
                show={true}
                onSaved={(color) => {
                    setColors(produce(draft => {
                        const index = draft.findIndex(c => c.id == color.id);

                        if (index == -1)
                            draft.push(color);
                        else
                            draft[index] = color;
                    }));
                    setSelected(null);
                }}
                onHide={() => setSelected(null)}
            />
        }
        {(selected && selected.mode == "parts") && 
            <ColorPartsDrawer 
                color={selected.color}
                show={true}
                onChanged={(partCount) => {
                    setColors(produce(draft => {
                        const index = draft.findIndex(c => c.id == selected.color.id);

                        if (index != -1)
                            draft[index].parts_count = partCount;
                    }));
                }}
                onHide={() => setSelected(null)}
            />
        }
        {(selected && selected.mode == "systems") && 
            <ColorSystemsDrawer 
                color={selected.color}
                show={true}
                onChanged={(systemsCount) => {
                    setColors(produce(draft => {
                        const index = draft.findIndex(c => c.id == selected.color.id);

                        if (index != -1)
                            draft[index].systems_count = systemsCount;
                    }));
                }}
                onHide={() => setSelected(null)}
            />
        }        
    </>
    );
}

export default ColorsPanel;