import React from "react";
import { faPlug } from '@fortawesome/free-solid-svg-icons'

import DrawerTemplateGroupDataSelect from "./template-groupeddataselect";
import { useGetPartsByType } from "../api/queries/parts";

export default function DrawerPartSelect({
    filters,
    ...props
}) { 
    const getParts = useGetPartsByType(filters);

    return (
      <>
        <DrawerTemplateGroupDataSelect
            {...props}
            icon={faPlug}
            title={props.title || "Select Part"}
            query={getParts}
            queryItemsProp="parts"
            formatGroupTitle={(group) => group.title}
            itemRender={(part) => (
                <div>
                    {part.title}
                    {(part.description && part.description.length > 0) &&
                        <div className="text-xs text-gray-400">{part.description}</div>
                    }
                </div>                
            )}
            itemExistsCheck={(item) => item.exists_ind == 1}
        />

      </>
    )
}