import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faPlug } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import DateField from "../components/fields/field-date"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

function UlDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (props.ul) {
      setFormData(props.ul);
    }
  }, [props.ul]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("ul/save", removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the UL.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    
    setFormData(produce(draft => {
        draft[event.target.name] = event.target.value;
    }));
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPlug} />
            &nbsp;
            UL
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
            <TextField 
                name="ul" 
                label="UL Number" 
                required 
                value={formData.ul} 
                onChange={(e) => handleFormChange(e)} />
            <TextField 
                name="job_id" 
                label="Job Number" 
                value={formData.job_id} 
                onChange={(e) => handleFormChange(e)} />
            <TextField 
                name="name" 
                label="Part" 
                value={formData.name} 
                onChange={(e) => handleFormChange(e)} />
            <DateField 
                name="added_date" 
                label="Date Used" 
                value={formData.added_date} 
                onChange={(e) => handleFormChange(e)} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save UL" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default UlDrawer;