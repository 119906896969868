import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"

import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"

import Button from "../components/buttons/button";
import NumberField from "../components/fields/field-number"
import GoogleImage from "../components/google-image"
import { postData } from "../common/services/server"

const Accessory = styled.div`
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;

    > div {
        display: flex;
        align-items: center; 
        margin-bottom: 10px;   
    }

    ul {
        list-style-type: none;
        padding: 0px;

        li {
            display: flex;
            align-items: center;
            padding: 5px;
            justify-content: space-between;
            border-bottom: 1px solid #ccc;

            &:hover {
                background-color: #eee;
            }
        }
    }

    img {
        margin-right: 10px;
    }
`

function AddAccessoryDrawer(props) {
  const [adding, setAdding] = useState(null);
  const [loading, setLoading] = useState(false);

  const [accessories, setAccessories] = useState([])

  useEffect(
    () => {
        setLoading(true);

        postData("products/accessories", {},
          function(result) {
            setAccessories(result);
          },
          function(error) {
            alert("Error loading accessories")
          },
          function() {
            setLoading(false);
          }
        );
    }, 
    []
  );

  function onAdd(size) {
      setAdding(size);

      postData("orders/addaccessory", {order_id: props.order.id, accessory_size_id: size.id, quantity: (size.quantity || 1)}, 
        function(response) {
          props.onAdd(response);
        },
        function(error) {
          alert("An error occured adding the accessory.");
        },
        function() {
          setAdding(null);
        }
      );
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;
            Add Accessory
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { loading ? 
                <Loading /> 
            :
                <div>
                {accessories.map(accessory => (
                    <Accessory key={accessory.id}>
                        <div>
                            <GoogleImage root src={accessory.image_url} style={{maxHeight: "50px", maxWidth: "125px"}} />
                            {accessory.title}
                        </div>
                        <ul>
                            {accessory.sizes.map(size => (
                                <li key={size.id}>
                                    <span dangerouslySetInnerHTML={{__html: size.size}} />
                                    &nbsp;                      
                                    ${size.cost}
                                    &nbsp;
                                    <NumberField 
                                        name="quantity" 
                                        value={size.quantity || 1}
                                        onChange={(e) => {
                                          if (e.persist) e.persist();

                                          setAccessories(produce(draft => {
                                            const acessoryData = draft.find(a => a.id = accessory.id);
                                            const sizeData = acessoryData.sizes.find(s => s.id == size.id);

                                            sizeData.quantity = parseInt(e.target.value);
                                          }))
                                        }} 
                                        width="small"
                                        groupStyle={{
                                          marginBottom: "0px"
                                        }}
                                    />
                                    &nbsp;
                                    <Button 
                                        variant="outline-success"
                                        disabled={adding && adding.id == size.id}
                                        onClick={() => onAdd(size)}
                                    >
                                        <FontAwesomeIcon icon={(adding && adding.id == size.id) ? faSpinner:faPlus} spin={adding && adding.id == size.id} />{' '}
                                        Add To Order
                                    </Button>  
                                </li>
                            ))}
                        </ul>
                    </Accessory>
                ))}
                </div>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default AddAccessoryDrawer;