import React, { useState, useEffect } from "react";
import produce from "immer"
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    CurrencyCell, 
    DateCell, 
    ButtonCell,
    LinkCell,
    GoogleImageCell, 
    UserCell,
    SelectCell,
    FilterHeaderCell,
    SortHeaderCell,
    SortTypes,
    BooleanCell
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import { useHistory } from "react-router-dom";

import Loading from "../../components/loading"
import Paginator from "../../components/paginator"

import { postData } from "../../common/services/server"
import { removeNulls } from "../../helpers/utility"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

function OrdersPanel(props) {
    const history = useHistory();
    const windowDimensions = useWindowDimensions();

    const [canEdit, setCanEdit] = useState(userHasPermission(Permissions.Orders, Actions.Edit))
    const [orders, setOrders] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({page:1,pages:1,total:0});
    const [sort, setSort] = useState({column: "order_date", dir: SortTypes.DESC});
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState({order_date: props.filters ? props.filters.date : null });

    useBodyClass(`noscroll`);

    function loadOrders(options) {
        setIsLoading(true);

        postData("orders/statuses", {},
            function(result) {
                setStatuses(result);
            },
            function(error) {
                alert("Error loading order statuses")
            }
        );        
        postData("orders/data", removeNulls({ 
            ...filters,
            sort: sort.column,
            sortdir: sort.dir,
            page: page,
            perPage: 100,
            ...props.filters,
            ...options
        }),
            function(result) {
                setOrders(result.orders);
                setPagination(result.pagination);
            },
            function(error) {
                alert("Error loading orders")
            },
            function() {
                setIsLoading(false);
            }
        );
    }

    useEffect(
        () => {
            loadOrders();
        }, 
        [props.filters, sort, page]
    );

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }
    function onItems(order) {
        if (order.id)
            history.push("/orders/order/items/" + order.id);
    }
    function onOrder(order) {
        if (order.id)
            history.push("/orders/order/" + order.id);
    }

    function onFilterChange(filter, value) {
        setFilters(produce(draft => {
            draft[filter] = value
        })); 
    }

    function onFilter() {
        loadOrders({page:1})
    }

    function onStatusChange(order, statusId) {
        setOrders(produce(draft => {
            const index = draft.findIndex(o => o.id == order.id);

            if (index != -1) {
                draft[index].status_id = statusId
            }
        }));

        postData("orders/changestatus", { 
            order_id: order.id,
            status_id: statusId
        },
            function(result) {
                
            },
            function(error) {
                alert("Error updating status")
            }
        );
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            : 
            <>   
                <Table
                    rowHeight={50}
                    rowsCount={orders.length}
                    width={windowDimensions.width - dimensions.dataTableWidthOffset}
                    height={windowDimensions.height - dimensions.dataTableHeightOffset}
                    headerHeight={70}
                    touchScrollEnabled={true}
                >
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Order #
                            </SortHeaderCell>
                        }
                        columnKey="id"
                        fixed={!isMobile}
                        cell={<LinkCell 
                                data={orders} 
                                urlPrefix="#/orders/order/"
                                onClick={(order) => onOrder(order)} 
                            />}
                        width={90}
                    />
                    <Column
                        header={<Cell>User</Cell>}
                        
                        columnKey="user_name"
                        cell={({rowIndex, ...props}) => (
                            <UserCell 
                                userId={orders[rowIndex].user_id}
                                userName={orders[rowIndex].user_name}
                                userEmail={orders[rowIndex].user_email}
                                onUser={() => history.push("/users/user/" + orders[rowIndex].user_id)}
                            />
                        )}
                        width={225}
                    />
                    <Column
                        header={
                            <FilterHeaderCell filter={filters.order_date} onFilterChange={(value) => onFilterChange("order_date", value)} onFilter={onFilter} sort={sort} onSortChange={onSortChange}>
                                Date Ordered
                            </FilterHeaderCell>
                        }
                        columnKey="order_date"
                        cell={<DateCell data={orders} time />}
                        width={145}
                    />
                    <Column
                        header={<Cell>Items</Cell>}
                        columnKey="item_count"
                        cell={<ButtonCell data={orders} onClick={(order) => onItems(order)} />}
                        width={70}
                    />
                    <Column
                        header={<Cell>Preview</Cell>}
                        columnKey="preview_url"
                        cell={<GoogleImageCell data={orders} maxwidth={125} maxheight={35} />}
                        width={150}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Total
                            </SortHeaderCell>
                        }
                        columnKey="total"
                        cell={<CurrencyCell data={orders} />}
                        width={100}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Status
                            </SortHeaderCell>
                        }
                        columnKey="status_id"
                        cell={
                            <SelectCell 
                                data={orders} 
                                items={statuses}
                                disabled={!canEdit}
                                onChange={onStatusChange}
                            />
                        }
                        width={200}
                    />
                    <Column
                        header={<Cell>Hear About Us</Cell>}
                        columnKey="hear_about_us"
                        cell={<TextCell data={orders} />}
                        width={150}
                    />      
                    <Column
                        header={<Cell>Social Media</Cell>}
                        columnKey="socialmedia_ind"
                        cell={<BooleanCell data={orders} />}
                        width={80}
                    />
                </Table>
                <Paginator
                    {...pagination}
                    item="Order"
                    items="Orders"
                    onPage={(page) => setPage(page)}
                />
            </>
            }
        </>
    );
}

export default OrdersPanel;