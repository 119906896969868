import React, { useEffect, useRef } from "react";

import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { useBodyClass } from "../../hooks/useBodyClass"

import settings from "../../settings"
import { dimensions } from "../../settings/settings"
import { getUser } from "../../common/services/auth";

function BuilderPanel(props) {
    const builderRef = useRef();
    const windowDimensions = useWindowDimensions();

    useBodyClass(`noscroll`);

    function onBuilderMessage(event) {
        console.log("onBuilderMessage", event)
        const message = event.data;
    
        if (message.type == "Ready" && props.sign) {
            notifyBuilder("Reset");
            notifyBuilder("LoadSign", props.sign);
            notifyBuilder("User", getUser());        
        }
        else if (message.type === "SignSaved") {
          console.log("builder sign saved", message.data)
          props.onSaved(message.data);
        }
    }

    function notifyBuilder(messageType, messageData) {
        console.log("notifyBuilder", messageType)

        if ( builderRef && builderRef.current) {
            let message = {
                type: messageType,
                data: messageData,
            }
            
            builderRef.current.contentWindow.postMessage(message, "*");
        }
        else {
            console.log("builderRef not valid")
        }
    }

    useEffect(() => {
        window.addEventListener("message", onBuilderMessage, false);    
    
        // clean up
        return () => window.removeEventListener("message", onBuilderMessage)
    })       

    useEffect(
        () => {
            if (props.sign) {
                console.log("sign", props.sign)
                notifyBuilder("Reset");
                notifyBuilder("LoadSign", props.sign);
                notifyBuilder("User", getUser());        
            }
        }, 
        [props.sign]
    );    

    return (
        <>
            <iframe 
                ref={builderRef} 
                src={settings.BUILDER_ADMIN_URL} 
                title="Sign Builder" 
                width="100%" 
                height={windowDimensions.height - dimensions.headerHeight - (props.haveTabs ? dimensions.pageWithTabsPadding : dimensions.pagePadding)*2} 
            />     
        </>
    );
}

export default BuilderPanel;