export function generateThumbnails(file, options, callback) {
    let settings = Object.assign({
      thumbnailSize: 900,
      preview: true,
      previewSize: 400,
      format: "png",
      exif: -1
    }, options);
    
    let thumbnails = {
        thumbnail: null,
        preview: null
    }

    generateThumbnail(file, settings.thumbnailSize, settings.format, settings.exif, function(thumbData) {    
      thumbnails.thumbnail = thumbData;
  
      if (settings.preview) {
        generateThumbnail(file, settings.previewSize, settings.format, settings.exif, function(previewData) {
           thumbnails.preview = previewData;
           callback(thumbnails)
        });
      }
      else {
        callback(thumbnails);
      }
      
    });
  
  }
  
export function generateThumbnail(file, size, format, exif, callback) {
  
        var imageType = /image.*/;
  
        if (!file.type.match(imageType) || file.type == "image/vnd.adobe.photoshop") {
          callback(null);
          return;
        }
   
        var reader = new FileReader();
  
        if (reader != null) {
            reader.onload = function(e) {
  
              var canvas = document.createElement('canvas');
              var img = new Image();
              img.onload = function () {
                  var data = "";
  
                  canvas.id = "TempCanvas";
                  
                  var imgHeight = size;
                  var imgWidth = size;
  
                  if (this.width > this.height) {
                    imgHeight = Math.round(size * (this.height/this.width));
                  }
                  else if (this.height > this.width) {
                    imgWidth = Math.round(size * (this.width/this.height));
                  }
  
                  // set proper canvas dimensions before transform & export
                  if (4 < exif && exif < 9) {
                    canvas.width = imgHeight;
                    canvas.height = imgWidth;
                  } else {
                    canvas.width = imgWidth;
                    canvas.height = imgHeight;
                  }
  
                  if (canvas.getContext) {
                      var cntxt = canvas.getContext("2d");
  
                      // transform context before drawing image
                      switch (exif) {
                        case 2: cntxt.transform(-1, 0, 0, 1, imgWidth, 0); break;
                        case 3: cntxt.transform(-1, 0, 0, -1, imgWidth, imgHeight); break;
                        case 4: cntxt.transform(1, 0, 0, -1, 0, imgHeight); break;
                        case 5: cntxt.transform(0, 1, 1, 0, 0, 0); break;
                        case 6: cntxt.transform(0, 1, -1, 0, imgHeight, 0); break;
                        case 7: cntxt.transform(0, -1, -1, 0, imgHeight, imgWidth); break;
                        case 8: cntxt.transform(0, -1, 1, 0, 0, imgWidth); break;
                        default: break;
                      }
  
                      cntxt.drawImage(img, 0, 0, imgWidth, imgHeight);
                      
                      data = canvas.toDataURL("image/" + (format == "jpg" ? "jpeg":format), .8);
                  }
  
                  callback(data);
              }
              img.src = e.target.result;
  
            }; 
            reader.readAsDataURL(file);     
        }
        else callback("Error: No browser support.");
   
}
  
export function getFileOrientation(input, callback) {
    var file = input.files[0];
    var reader = new FileReader();
  
    reader.onload = function(e) {
  
        var view = new DataView(e.target.result);
        if (view.getUint16(0, false) != 0xFFD8)
        {
            return callback(-2);
        }
        var length = view.byteLength, offset = 2;
        while (offset < length) 
        {
            if (view.getUint16(offset+2, false) <= 8) return callback(-1);
            var marker = view.getUint16(offset, false);
            offset += 2;
            if (marker == 0xFFE1) 
            {
                if (view.getUint32(offset += 2, false) != 0x45786966) 
                {
                    return callback(-1);
                }
  
                var little = view.getUint16(offset += 6, false) == 0x4949;
                offset += view.getUint32(offset + 4, little);
                var tags = view.getUint16(offset, little);
                offset += 2;
                for (var i = 0; i < tags; i++)
                {
                    if (view.getUint16(offset + (i * 12), little) == 0x0112)
                    {
                        return callback(view.getUint16(offset + (i * 12) + 8, little));
                    }
                }
            }
            else if ((marker & 0xFF00) != 0xFF00)
            {
                break;
            }
            else
            { 
                offset += view.getUint16(offset, false);
            }
        }
        return callback(-1);
    };
    
    reader.readAsArrayBuffer(file);
}
  
/* Utility function to convert a canvas to a BLOB */
export function dataURLToBlob(dataURL) {
  var BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var contentType = parts[0].split(':')[1];
      var raw = parts[1];

      return new Blob([raw], {type: contentType});
  }

  var parts = dataURL.split(BASE64_MARKER);
  var contentType = parts[0].split(':')[1];
  var raw = window.atob(parts[1]);
  var rawLength = raw.length;

  var uInt8Array = new Uint8Array(rawLength);

  for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], {type: contentType});
}