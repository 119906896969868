import clsx from "clsx"
import React from "react"

function BoardColumns({
    columns=2,
    className,
    children
}) {
    return (
        <div className={clsx(
            "grid items-stretch",
            columns == 2 ? "grid-cols-2 gap-2" : "gap-1",
            className,
        )}>
            {children}
        </div>
    );
}

export default BoardColumns