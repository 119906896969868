import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";

import Field from "./field"
import Button from "../buttons/button";
import { useGetJob } from "../../api/queries/jobs";
import JobSelectDrawer from "../../drawers/job-select";

function FieldJob({
    value,
    jobQueryOptions,
    disabled,
    onChange,
    ...props
}) {
    const { data:job, isFetching, error } = useGetJob(value, null, {enabled: !!value});
    const [browse, setBrowse] = useState(false);

    console.log("FieldJob", value)
    console.log("FieldJob", value)

    return (
      <>
        <Field {...props} disabled={disabled}>
            {isFetching ? 
                <FontAwesomeIcon icon={faSpinner} spin className="self-center text-gray-300" />
            :
                <div className="flex items-center justify-between">
                    <div>{job ? job.title : null}</div>

                    <Button
                        size="icon"
                        disabled={disabled}
                        icon={faSearch}
                        onClick={() => setBrowse(true)}
                    />
                </div>
            }
        </Field>

        {browse && 
            <JobSelectDrawer
                show={true}
                queryOptions={jobQueryOptions}
                onSelect={(jobData) => {
                    onChange({
                            target: {
                                value: jobData.id
                            }
                        },
                        jobData
                    );
                    setBrowse(false);
                }}
                onHide={() => setBrowse(false)}
            />
        }        
      </>
    )
}

export default FieldJob