import React, { useEffect, useState } from "react";
import produce from "immer"
import { Badge } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faPlus, faStar, faSpinner, faPencilAlt, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import ContentDrawer from "../drawers/drawer-content"
import ButtonProcessing from "../components/buttons/button-processing";
import SelectContentDrawer from "../drawers/drawer-selectcontent"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"
import GoogleImage from "../components/google-image";

function Panel({
  product,
  products,
  onChange
}) {
  const [busy, setBusy] = useState("");

  const allTypes = [
    {name: "construction", title: "Product Construction"},
    {name: "installation", title: "Product Installation"},
    {name: "breakdown", title: "Product Breakdown", description: "Used in the builder when selecting a product."},
    {name: "includes", title: "Included With Product"},
    {name: "notincludes", title: "Not Included With Product"},
    {name: "other", title: "Other Content"},
  ]

  const [contents, setContents] = useState([]);
  const [types, setTypes] = useState([]);

  const [selectContent, setSelectContent] = useState(false);
  const [content, setContent] = useState(null);

  useEffect(() => {
      setContents(product.content);     
  }, [product]);

  useEffect(() => {
    let types = [];

    for (const content of contents) {
        if (types.find(t => t.name == content.type) == null)
            types.push(allTypes.find(t => t.name == content.type))
    }

    setTypes(types);
  }, [contents]);

  function onRemoveContent(content) {
    if (window.confirm("Are you sure you want to remove this product content?")) {
        setBusy("remove" + content.id);

        postData("products/content/toggle", {
                id: product.id,
                content_id: content.id,
                checked_ind: 0
            }, 
            function(response) {
                setContents(produce(draft => {
                    const idx = draft.findIndex(c => c.id == content.id);

                    if (idx != -1) {
                        draft.splice(idx, 1);
                    }
                }))
            },
            function(error) {
                alert("An error occured removing the content.");
            },
            function() {
                setBusy("")
            }
        );
    }
  }

  return (
    <>
        { contents.length == 0 ?
            <p>No content found.</p>
        :
            <div>
                {types.map(type => {
                    const typeContent = contents.filter(c => c.type == type.name);

                    return (
                        <div key={type.name}>                        
                            <div style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                marginBottom: "2px"
                            }}>
                                {type.title}
                            </div>
                            <div style={{
                                display: "grid",
                                gap: "5px",
                                marginBottom: "20px",
                            }}>
                            {typeContent.map(content => (
                                <div 
                                    key={content.id}
                                    style={{
                                        border: "1px solid #ccc",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        backgroundColor: "white",
                                        display: "grid",
                                        gridTemplateColumns: "75px 1fr auto",
                                        gap: "5px",
                                    }}
                                >
                                    <div style={{padding:"5px"}}>
                                        <GoogleImage
                                            root
                                            src={content.preview_url}
                                            style={{ 
                                                width: "65px",
                                                maxWidth: "65px"
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            {content.title}
                                            {content.status == "Optional" && 
                                                <Badge variant="danger" style={{marginLeft:"5px"}}>Optional</Badge>
                                            }
                                        </div>
                                        <div>
                                            <small style={{color: "#999"}}>{content.description}</small>
                                        </div>

                                        {content.part_count > 0 && 
                                            <div style={{display:"flex", gap: "3px", flexFlow: "wrap"}}>
                                                {content.part_list.split(",").filter(p => product.parts.find(pp => pp.title == p)).map(part => (
                                                    <Badge variant="secondary" size="sm">
                                                        {part}
                                                    </Badge>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        <ButtonProcessing
                                            icon={faPencilAlt}
                                            size="sm"
                                            variant="outline-primary"
                                            onClick={() => {
                                                setContent(content);
                                            }}
                                        />
                                        <ButtonProcessing
                                            icon={faBan}
                                            size="sm"
                                            variant="outline-danger"
                                            processing={busy == ("remove"+content.id)}
                                            onClick={() => {
                                                onRemoveContent(content);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    )
                })}
            </div>
        }

        <div style={{display: "flex", gap: "5px", marginTop: "10px"}}>
            <Button
                variant="outline-primary"
                size="sm"
                disabled={busy=="adding"}
                onClick={() => setSelectContent(true)}                
            >
                <FontAwesomeIcon icon={busy=="adding" ? faSpinner:faPlus} spin={busy=="adding"} />
                &nbsp;
                Add Existing Content
            </Button>

            <Button
                variant="outline-success"
                size="sm"
                onClick={() => setContent({id: 0, product_id: product.id})}
            >
                <FontAwesomeIcon icon={faStar} />
                &nbsp;
                Create New Content
            </Button>   
        </div>

        { selectContent && 
            <SelectContentDrawer 
                label="Add"
                products={products}
                product={product}
                existingContent={contents} 
                onSelect={(newContent) => {
                    console.log(newContent)
                    setBusy("adding");

                    postData("products/content/toggle", {
                            id: product.id,
                            content_id: newContent.id,
                            status: newContent.status,
                            checked_ind: 1
                        }, 
                        function() {
                            setContents(produce(draft => {
                                draft.push(newContent);
                            }))
                        },
                        function(error) {
                            alert("An error occured adding the content.");
                        },
                        function() {
                            setBusy("")
                        }
                    );

                    setSelectContent(false);
                }} 
                onHide={ () => setSelectContent(false) } 
            />     
        }
        { content && 
            <ContentDrawer 
                product={product}
                content={content} 
                parts={product.parts}
                shippingMethods={product.shippingMethods}
                onSaved={(contentData) => {
                    let updatedContents = JSON.parse(JSON.stringify(contents));

                    let idx = updatedContents.findIndex(c => c.id == contentData.id);

                    if (idx == -1)
                        updatedContents.push(contentData);
                    else 
                        updatedContents[idx] = contentData;

                    setContents(updatedContents);
                    setContent(null);   
                    
                    if (onChange) {
                        onChange({
                            content: updatedContents
                        })
                    }
                }} 
                onHide={ () => setContent(null) } 
            /> 
        }              
    </>
  );
}

export default Panel;