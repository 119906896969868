import React, { useState, useEffect } from "react";
import { produce } from "immer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import GroupPreview from "../components/group-preview";
import ShapePreview from "../components/shape-preview";
import FieldSelect from "../components/fields/field-select";
import { List, ListItem } from "../components/list"
import { postData } from "../common/services/server";
import Panel from "./panel";
import { useGetJobBridges } from "../api/queries/workflowjobs";
import { useWorkflowJobUpdatePartProp, useWorkflowJobUpdateShapeProp } from "../api/mutations/workflowjobs";

function ShapeData({
    jobId,
    shape,
    onChange
}) {
    const updateShapeProp = useWorkflowJobUpdateShapeProp(jobId, {
        onError: () => window.alert("Error updating shape")
    });    

    const isBusy = updateShapeProp.isLoading && updateShapeProp.variables?.jobShapeId == shape.jobshape_id;

    return (
        <div className="flex flex-col gap-1 self-start">
            <div className="flex justify-center">
                <ShapePreview path={shape.path} solid={true} width={25} height={15} />
                {isBusy &&
                    <FontAwesomeIcon icon={faSpinner} spin color="#eee" />
                }
            </div>
            <div className="grid gap-1 text-xs">
                <FieldSelect
                    value={shape.bridge_ind}
                    items={[
                        {id:"0", label:"No Bridge"},
                        {id:"1", label:"Bridge"},
                    ]}
                    size="xs"
                    valid={shape.bridge_ind == 1}
                    onChange={(e,value) => {
                        updateShapeProp.mutate({
                            jobShapeId: shape.jobshape_id, 
                            prop: "bridge_ind",
                            value
                        });
                    }}
                    disabled={isBusy}
                />
            </div>
        </div>
    )
}

export default function GroupBridgesPanel({
    jobId,
}) {
    const groupsQuery = useGetJobBridges(jobId);
    const groups = groupsQuery.data;

    return (
        <Panel query={groupsQuery}>
            {groups && 
                <div className="flex gap-2">
                    <List>
                        {groups.map(group => (
                            <ListItem key={group.id}>
                                <div className="grid grid-cols-[125px,1fr] items-center">
                                    <div className="text-xs">
                                        <GroupPreview group={group} solid={true} width={100} height={50} />
                                        <div>
                                            {group.product}
                                        </div>
                                        <div>
                                            Size {group.size}
                                        </div>
                                        {group.style_type == "letters" && 
                                            <div>
                                                {group.style}
                                            </div>
                                        }                                          
                                    </div>
                                    <div className="flex flex-wrap items-center gap-1">
                                        {group.shapes.map(shape => (
                                            <div key={shape.jobshape_id} className="border border-gray-300 rounded p-1">
                                                <ShapeData
                                                    jobId={jobId}
                                                    shape={shape}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </ListItem>
                        ))}
                    </List>
                </div>                  
            }
        </Panel>
    ) 
}
