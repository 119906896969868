import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faSave, faRss, faLink, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"
import Button from "../components/buttons/button";
import { postData } from "../common/services/server"
import FieldText from "../components/fields/field-text";
import FieldUrl from "../components/fields/field-url";
import FieldSelect from "../components/fields/field-select";

function BlogLinksDrawer(props) {
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState("");
  const [links, setLinks] = useState([]);

  const [newLink, setNewLink] = useState("");
  const [newLinkType, setNewLinkType] = useState("url");

  useEffect(() => {
    setLoading(true);

    postData("blog/blogdata", { 
        id: props.post.id,
    },
      function(result) {
        setLinks(result.links);
      },
      function(error) {
        alert("Error loading blog data")
      },
      function() {
        setLoading(false);
      }
    );
  }, [props.post]);


  function onSave(link) {
    setBusy("save"+link.id);

    postData("blog/savelink", {
        blog_id: props.post.id,
        id: link.id,
        url: link.url,
        type: link.type
    }, 
        function(response) {
            setLinks(produce(draft => {
                const index = draft.findIndex(l => l.id == link.id)

                if (index != -1)
                    draft[index].changed = false;
            }));
        },
        function(error) {
            alert("An error occured updating the link.");
        },
        function() {
            setBusy("")
        }
    );
  }

  function onDelete(link) {
    if (window.confirm("Delete this blog link?")) {
        setBusy("delete"+link.id);

        postData("blog/deletelink", {
            blog_id: props.post.id,
            id: link.id
        }, 
            function(response) {
                setLinks(produce(draft => {
                    const index = draft.findIndex(l => l.id == link.id)

                    if (index != -1)
                        draft.splice(index, 1);
                }));

                props.onChange(links.length - 1)
            },
            function(error) {
                alert("An error occured updating the link.");
            },
            function() {
                setBusy("")
            }
        );
    }
  }
  function onAdd() {
      setBusy("adding")

      postData("blog/addlink", {
          blog_id: props.post.id,
          link: newLink,
          type: newLinkType
      }, 
      function(response) {
          setLinks(produce(draft => {
              draft.push(response);

              setNewLink("");
          }));

          props.onChange(links.length + 1)
      },
      function(error) {
          alert("An error occured adding the link.");
      },
      function() {
          setBusy("")
      }
  );      
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faLink} />
            &nbsp;
            Blog Links
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{display:"flex", alignItems: "center", marginBottom: "15px", padding: "8px", backgroundColor: "#eee", border: "1px solid #ccc", borderRadius: "4px"}}>
                <FontAwesomeIcon icon={faRss} />
                &nbsp;
                {props.post.title}
            </div>

            { loading ? 
                <Loading /> 
              :
                <>
                  {links.map((link,index) => (
                    <div key={link.id} className="flex items-stretch gap-1">
                        <FieldSelect
                            name="type"
                            value={link.type}
                            items={[
                                {id:"url", label:"URL"},
                                {id:"video", label:"Video"},
                            ]}
                            className="h-full"
                            onChange={(e,value) => {
                                setLinks(produce(draft => {
                                    let item = draft[draft.findIndex(l => l.id == link.id)];
                                    
                                    item.type = value;
                                    item.changed = true;
                                }))
                            }}
                        />
                        <FieldText
                            name="link"
                            prepend="URL"
                            value={link.url}
                            groupClassname="w-full"
                            onChange={(e,value) => {
                                setLinks(produce(draft => {
                                    let item = draft[draft.findIndex(l => l.id == link.id)];
                                    
                                    item.url = value;
                                    item.changed = true;
                                }))
                            }}
                            append={
                                <>
                                <Button onClick={() => onSave(link)} variant={link.changed ? "primary":"outline-primary"} disabled={link.url.length == 0} size="icon">
                                    <FontAwesomeIcon icon={busy == "save"+link.id ? faSpinner:faSave} spin={busy=="save"+link.id} />
                                </Button>
                                <Button onClick={() => onDelete(link)} variant="outline-danger" size="icon">
                                    <FontAwesomeIcon icon={busy == "delete"+link.id ? faSpinner:faTrash} spin={busy=="delete"+link.id} />
                                </Button>
                                </>
                            }
                        />
                    </div>                            
                  ))}

                <div className="mt-4">
                    <h5 className="font-bold mb-1">
                        Add New Link
                    </h5>
                    <div className="flex items-stretch gap-1">
                        <FieldSelect
                            name="type"
                            value={newLinkType}
                            items={[
                                {id:"url", label:"URL"},
                                {id:"video", label:"Video"},
                            ]}
                            className="h-full"
                            disabled={busy=="adding"}
                            onChange={(e,value) => {
                                setNewLinkType(value);
                            }}
                        />
                        <FieldText
                            name="link"
                            prepend="URL"
                            value={newLink}
                            groupClassname="w-full"
                            disabled={busy=="adding"}
                            onChange={(e,value) => {
                                setNewLink(value)
                            }}
                            append={
                                <Button 
                                    onClick={onAdd} 
                                    variant="outline-success" 
                                    disabled={newLink.length == 0 || busy=="adding"} 
                                    size="icon"
                                >
                                    <FontAwesomeIcon icon={busy == "adding" ? faSpinner:faPlus} spin={busy=="adding"} />
                                </Button>
                            }
                        />
                    </div>                    
                </div>

                </>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default BlogLinksDrawer;