import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutate } from '../api';

export const useSetUserProp = (userId, prop) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (value) => {
            return mutate("users/user/setprop", {
                id: userId,
                prop,
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["users", ""+userId]);
        },    
  })
};

