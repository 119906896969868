import React from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import FieldLabel from "./label"
import HelpText from "./help-text"

import UserSelect from "./user-select";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import SignSelect from "./sign-select";

function FieldSign(props) {
    let groupStyle = props.groupStyle || {};

    if (props.layout == "horizontal") {
        groupStyle.display = "grid";
        groupStyle.gridTemplateColumns = "125px auto";
        groupStyle.alignItems = "center";
    }
        
    return (
        <Form.Group style={groupStyle}>
            {props.label && 
                <FieldLabel 
                    required={props.required} 
                    bold={props.labelBold}
                    style={props.labelStyle || {marginBottom: "0px"}}
                >
                    {props.label}
                </FieldLabel>
            }
            <div>
                <SignSelect
                    name={props.name} 
                    required={props.required}
                    default={{id: props.value || 0, title: props.value ? "#"+props.value : ""}} 
                    onChange={(sign) => { props.onChange({
                        target: {
                            name: props.name,
                            value: sign
                        }
                    }); 
                }}                        
                />
                {props.instructions && <HelpText>{props.instructions}</HelpText>}
            </div>
        </Form.Group>
    )
}

export default FieldSign