import React, { useState } from "react";
import clsx from 'clsx';
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//import { useIdleTimer } from 'react-idle-timer'
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStickyNote, faUser, faClipboardCheck, faSpinner, faEllipsisH, faComments, faBars, faPlus, faUserLock, faCalendarAlt, faDoorOpen, faBan, faMinus, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect"

import SiteSearchBox from './site-searchbox'
import logoIcon from '../images/logo_square_small.jpg'

import Button from "./buttons/button"
import Buttons from "./buttons/buttons"
import ButtonGroup from "./buttons/group"

import NoteDrawer from "../drawers/drawer-note"
import NewTaskDrawer from "../drawers/task-new";
import EmailDrawer from "../drawers/drawer-email"
import CallDrawer from "../drawers/call";

import { isLoggedIn, getUser, logout, userHasPermission, isImpersonating, getImpersonatingRole, stopImpersonating, Permissions, Actions } from "../common/services/auth"
import { postData } from "../common/services/server"
import { devices, colors } from "../settings/settings"
import { copyTextToClipboard } from "../helpers/utility"

import SiteMenu from "./site-menu";
import { Toast } from "react-bootstrap";
import SiteNotifications from "./site-notifications";

const Header = styled.header`
    background-color: #333;
    padding: 5px;
    display: flex;
    position: fixed;
    top: 0px;
    width: 100vw;
    z-index: 1049;
    
    @media ${devices.mobile} {
        height: 48px;
        overflow: hidden;
    }
    @media print
    {            
        display: none !important;
    }
          
    > a {
        margin-right: 10px;
        margin-left: 5px;
    }
    
    img.icon {
        height: 38px;
        border-radius: 3px;
    }

    div.buttons {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        @media ${devices.mobile} {
            position: fixed;
            left: 0px;
            right: 0px;
            top: 48px;
            padding: 5px;
            z-index: 999;
            background-color: #555;

            .btn {
                padding: 0.375rem 0.5rem;                
            }
        }
    }

    button.menu {
        background-color: ${colors.lightPurple};
        border-color: ${colors.lightPurple};
        color: #333;
    }

    @media print
    {    
        display: none !important;
    }

`

function SiteHeader(props) {
    const history = useHistory();
    const location = useLocation();

    const loggedIn = isLoggedIn();
    const user = getUser();

    const [drawer, setDrawer] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [showButtons, setShowButtons] = useState(isMobile?false:true);
    const [busy, setBusy] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState(null);

    /*
    const handleOnIdle = event => {
      console.log('user is idle', event)
      console.log('last active', getLastActiveTime())
  
      logout(() => {
        history.push('/login/' + encodeURIComponent((window.location.hash || "#/").substring(1)));
      })    
    }
    */
  
    //const handleOnActive = event => {
    //  console.log('user is active', event)
    //  console.log('time remaining', getRemainingTime())
    //}
  
    //const handleOnAction = event => {
    //  console.log('user did something', event)
    //}

    /*
    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
      timeout: 1000 * 60 * 60 * 3, // 3 hours
      onIdle: handleOnIdle,
      //onActive: handleOnActive,
      //onAction: handleOnAction,
      debounce: 500
    })
    */ 

    function onLogout() {
        logout(() => {
            history.push("/");
        })
    }

    // check the logged in user status periodically
    /*
    useInterval(() => {
        if (user) {
            console.log("interval", user)

            postData("users/check", {id:user.id}, 
                function(response) {
                    if (!response || response.updated_date != user.updated_date)
                        onLogout();
                },
                function(error) {
                    onLogout();
                }
            );            
        }
      }, 1000 * 60 * 60); // every hour
    */

    function onNewNote() {
        setDrawer("note");
    }
    function onNewTask() {
        setDrawer("task");
    }
    function onNewEmail() {
        setDrawer("email");
    }
    function onNewSms() {
        setDrawer("sms");
    }    
    function onPhoneCall() {
        setDrawer("call");
    }    
    function onCalendar() {
        history.push("/calendar");
    }

    function onNewPassword() {
        setBusy("password")
        setPassword("");

        postData("users/impersonatepassword", {}, 
            function(response) {
                setPassword(""+response);
                copyTextToClipboard(response);
            },
            function(error) {
                alert("An error occured generating the password.");
            },
            function() {
                setBusy("");
            }
        );
    }
    function onCopyPassword() {
        copyTextToClipboard(password);
    }

    function toggleMenu() {
        setShowMenu(!showMenu);
    }
    function toggleButtons() {
        setShowButtons(!showButtons);
    }
    function onStopImpersonating() {
        stopImpersonating();
        window.location.reload();
    }

    return (
        <>
        <Header>
            {isMobile && 
                <Button onClick={toggleMenu} className="menu">
                    <FontAwesomeIcon icon={faBars} />
                </Button>
            }

            <a href="https://signmonkey.com" target="blank">
                <img src={logoIcon} alt="SignMonkey Admin Home" className="icon" />
            </a>

            { loggedIn && 
            <>
                {(true || userHasPermission(Permissions.Signs) || userHasPermission(Permissions.Jobs) || userHasPermission(Permissions.Orders)) && 
                    <SiteSearchBox />
                }

                {isMobile && 
                    <div style={{flex: 1}}>
                        <Button onClick={toggleButtons} variant="outline-secondary" style={{marginLeft: "auto", display: "block"}} className={clsx(isMobile && "!pb-1")}>
                            <FontAwesomeIcon icon={showButtons ? faMinus:faPlus} />
                        </Button>
                    </div>
                }
                {showButtons && 
                    <div className="buttons">
                    <Buttons>                        
                        {userHasPermission(Permissions.Customers, Actions.Impersonate) &&                        
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip>Generate Impersonate Password</Tooltip>
                                }
                            >
                                <ButtonGroup>
                                    <Button onClick={onNewPassword} variant={password && password.length > 0 ? "outline-secondary" : "none-secondary"} size="sm">
                                        <FontAwesomeIcon icon={busy=="password" ? faSpinner:faUserLock} spin={busy=="password"} />
                                    </Button>
                                    {(password && password.length > 0) && 
                                        <Button onClick={onCopyPassword} variant="light" size="sm">
                                            {password}
                                        </Button>
                                    }
                                </ButtonGroup>
                            </OverlayTrigger>  
                        }                      
                        <ButtonGroup>
                            {userHasPermission(Permissions.Notes, Actions.Create) &&                            
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>Create New Note</Tooltip>
                                    }
                                >
                                    <Button onClick={onNewNote} variant="outline-warning" size="sm">
                                        <FontAwesomeIcon icon={faStickyNote} />
                                    </Button>
                                </OverlayTrigger>  
                            }
                            {userHasPermission(Permissions.Notes, Actions.View) &&                            
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>View All Notes</Tooltip>
                                    }
                                >                          
                                    <Button href="/notes" variant="outline-warning" size="sm">
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </Button>
                                </OverlayTrigger> 
                            }
                        </ButtonGroup>
                        <ButtonGroup>
                            {userHasPermission(Permissions.Tasks, Actions.Create) &&                            
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>Create New Task</Tooltip>
                                    }
                                >   
                                    <Button onClick={onNewTask} variant="outline-success" size="sm">
                                        <FontAwesomeIcon icon={faClipboardCheck} />
                                    </Button>
                                </OverlayTrigger>
                            }
                            {userHasPermission(Permissions.Tasks, Actions.View) &&                            
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>View All Tasks</Tooltip>
                                    }
                                >   
                                    <Button href="/tasks" variant="outline-success" size="sm">
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </Button>
                                </OverlayTrigger>
                            }
                        </ButtonGroup>

                        {(userHasPermission(Permissions.Signs) || userHasPermission(Permissions.Jobs) || userHasPermission(Permissions.Orders)) && 
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip>Calendar</Tooltip>
                                }
                            >                           
                                <Button 
                                    onClick={onCalendar} 
                                    variant="outline-light"
                                    size="sm"
                                >
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </Button>
                            </OverlayTrigger>
                        }

                        {userHasPermission(Permissions.Emails, Actions.Send) &&
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip>Create New Message</Tooltip>
                                }
                            >                           
                                <Button 
                                    onClick={onNewEmail} 
                                    variant="outline-primary"
                                    size="sm"
                                >
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </Button>
                            </OverlayTrigger>
                        }
                      
                        <Dropdown alignRight>
                            <Dropdown.Toggle variant="light">
                                <FontAwesomeIcon icon={faUser} className="text-white" />{' '}
                                <span className="text-white">{!isMobile && user.first_name}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                { userHasPermission(Permissions.Employees) && 
                                    <Dropdown.Item onClick={() => history.push("/users/user/" + user.id)}>
                                        <FontAwesomeIcon icon={faUser} fixedWidth />{' '}
                                        Your Profile
                                    </Dropdown.Item>
                                }
                                <Dropdown.Item>
                                    <FontAwesomeIcon icon={faUserLock} fixedWidth />{' '}
                                    Your Roles
                                    <div style={{marginLeft: "25px"}}>
                                        <small>
                                            {user.roles.map(role => (
                                                <div key={role.name}>- {role.name}</div>
                                            ))}  
                                        </small>
                                    </div>
                                </Dropdown.Item>                                
                              
                                {isImpersonating() &&                                 
                                    <Dropdown.Item onClick={onStopImpersonating}>
                                        <FontAwesomeIcon icon={faBan} fixedWidth />{' '}
                                        Stop Impersonating {getImpersonatingRole().name}
                                    </Dropdown.Item>                                
                                }
                                <Dropdown.Item onClick={onLogout}>
                                    <FontAwesomeIcon icon={faDoorOpen} fixedWidth />{' '}
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Buttons>
                    </div>
                }
            </>
            }
        </Header>
        { (isMobile && showMenu) && 
            <SiteMenu />
        }
        { drawer && drawer == "note" && 
            <NoteDrawer 
                show={true}
                onSaved={ (note) => {
                    if (props.onNewNote)
                        props.onNewNote(note);
                        
                    setDrawer(null);
                }} 
                onHide={ () => setDrawer(null) } 
            /> 
        }
        { drawer && drawer == "task" && 
            <NewTaskDrawer
                show={true}
                onSaved={(result) => {
                    if (props.onNewTask)
                        props.onNewTask(result);
                    setDrawer(null);
                }} 
                onHide={ () => setDrawer(null) } 
            /> 
        }
        { drawer && drawer == "email" && 
            <EmailDrawer 
                show={true}
                onSaved={ (email) => {
                    if (props.onNewEmail)
                        props.onNewEmail(email);
                    setDrawer(null);
                }} 
                onHide={ () => setDrawer(null) } 
            /> 
        }     
        { drawer && drawer == "call" && 
            <CallDrawer 
                show={true}
                call={{}}
                onSaved={ (call) => {
                    if (props.onNewCall)
                        props.onNewCall(call);
                    setDrawer(null);
                }} 
                onHide={ () => setDrawer(null) } 
            /> 
        }

        {message &&
            <Toast 
                key={message} 
                show={true} 
                onClose={() => setMessage(null)}
                style={{
                    position: "fixed",
                    right: "30px",
                    bottom: "30px",
                    zIndex: "99999",
                    minWidth: "300px",
                    borderColor: message.color
                }}              
            >
                <Toast.Header style={{
                    justifyContent: "space-between",
                    backgroundColor: message.lightColor
                }}>
                    <div>
                        {message.icon &&
                            <FontAwesomeIcon icon={message.icon} />
                        }
                        &nbsp;
                        <strong>
                            {message.title}
                        </strong>
                    </div>
                </Toast.Header>
                <Toast.Body>
                    <div>{message.description}</div>
                    <div>
                        <a href={message.url} onClick={() => setMessage(null)}>
                            View {message.type}
                        </a>
                    </div>
                </Toast.Body>
            </Toast>            
        }
        </>
    );
}

export default SiteHeader