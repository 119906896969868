import React from "react"
import styled from "styled-components"
import { colors } from "../../settings/settings";

const Warning = styled.div`
    background-color: ${colors.orangeLight};
    border: 1px solid ${colors.orange};
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
`

function BoardWarning({
    type,
    children,
    style
}) {
    return (
        <Warning className={type} style={style}>
            {children}
        </Warning>
    );
}

export default BoardWarning