import React, { useState, useEffect } from "react";

import produce from "immer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faPencilAlt, faRss, faBook, faCheck, faFilm } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    CurrencyCell, 
    DateCell, 
    ButtonCell,
    UserCell,
    SortHeaderCell,
    SortTypes,
    EditActionsCell, 
    GoogleImageCell
} from "../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import { useParams, useHistory } from "react-router-dom";

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"
import BlogPostDrawer from "../drawers/blogpost"
import BlogLinksDrawer from "../drawers/drawer-bloglinks"
import BlogFilesDrawer from "../drawers/drawer-blogfiles"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"
import { useBodyClass } from "../hooks/useBodyClass"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { dimensions } from "../settings/settings"
import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import PageLayout from "../components/page-layout";

function BlogPage(props) {
    const history = useHistory();
    let { tab } = useParams();

    if (!tab) tab = "Active";

    const windowDimensions = useWindowDimensions();

    const [selectedTab, setSelectedTab] = useState(tab)
    const [posts, setPosts] = useState([]);
    const [sort, setSort] = useState({column: "added_date", dir: SortTypes.DESC});
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    const [busyRow, setBusyRow] = useState(null);

    const [products, setProducts] = useState([]);
    const [parts, setParts] = useState([]);
    const [shippingMethods, setShippingMethods] = useState([]);

    useBodyClass(`noscroll`);

    useEffect(
      () => {
          postData("content/categories", {},
              function(result) {
                  setProducts(result.products);
                  setParts(result.parts);
                  setShippingMethods(result.shippingMethods);
              },
              function(error) {
                  alert("Error loading data")
              }
          );   
      }, 
      []
    );

    useEffect(
        () => {
            setIsLoading(true);

            postData("blog/data", { 
                status: "Active",
                type: selectedTab,
                sort: sort.column,
                sortdir: sort.dir
            },
                function(result) {
                    setPosts(result);
                },
                function(error) {
                    alert("Error loading blog posts")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        [sort, selectedTab]
    );

    function onPostChanged(post) {
        const rowIndex = posts.indexOf(selected.post)

        setPosts(produce(draft => {
            if (rowIndex == -1)
                draft.push(post);
            else
                draft[rowIndex] = post;
        }));
    }

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }

    function onCreate() {
        console.log("create")
        setSelected({mode:"post", post:{}});
    }
    function onAction(action, post) {
        console.log("action", action, post)

        if (action == "edit")
            setSelected({mode:"post", post});
        else if (action == "delete") {
            setBusyRow(post);

            postData("blog/delete", { 
                id: post.id
            },
                function(result) {
                    setPosts(produce(draft => {
                        draft.splice(draft.findIndex(c => c.id == post.id), 1);
                    }));
                },
                function(error) {
                    alert("Error deleting post")
                },
                function() {
                    setBusyRow(null);
                }
            );
        }
        else if (action == "publish") {
          setBusyRow(post);

          postData("blog/publish", { 
              id: post.id
          },
              function(result) {
                  setPosts(produce(draft => {
                      draft[draft.findIndex(p => p.id == post.id)] = result;
                  }));
              },
              function(error) {
                  alert("Error publishing post")
              },
              function() {
                  setBusyRow(null);
              }
          );
      }
    }

    return (
        <PageLayout>
            <PageHeader 
                title="Blog Manager"
                help="blog"
                tab={selectedTab}
                tabUrl={"/blog/{tab}"}
                tabs={[
                    {name:"blog", title:"Blog", icon:faRss},
                    {name:"signclopedia", title:"Signclopedia", icon:faBook},
                ]}
                onTab={(tab) => {
                    history.push("/blog/" + tab);
                    setSelectedTab(tab)
                }}
            >
                {userHasPermission(Permissions.News, Actions.Create) &&
                    <Button onClick={onCreate} variant="outline-success" size="sm">
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        Create Post
                    </Button>
                }
            </PageHeader>
            <Content permission="News">
            <>
                {
                    isLoading 
                ?
                    <Loading />
                :    
                    <Table
                        rowHeight={70}
                        rowsCount={posts.length}
                        width={windowDimensions.width - dimensions.dataTableWidthOffset}
                        height={windowDimensions.height - dimensions.dataTableHeightOffset}
                        headerHeight={50}
                        touchScrollEnabled={true}
                    >
                        <Column
                            header={<Cell>Preview</Cell>
                            }
                            columnKey="preview_url"
                            fixed={!isMobile}
                            cell={<GoogleImageCell data={posts} />}
                            width={80}
                        />                        
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    Title
                                </SortHeaderCell>
                            }
                            columnKey="title"
                            fixed={!isMobile}
                            cell={<TextCell data={posts} />}
                            width={300}
                        />
                        <Column
                            header={<Cell>Level</Cell>}
                            columnKey="level"
                            cell={<TextCell data={posts} />}
                            width={120}
                        />                      
                        <Column
                            header={<Cell>Reference</Cell>}
                            columnKey="reference"
                            cell={<TextCell data={posts} small />}
                            width={300}
                        />                        
                        <Column
                            header={<Cell>Categories</Cell>}
                            columnKey="categories"
                            cell={<TextCell data={posts} small />}
                            width={300}
                        />       
                        <Column
                            header={<Cell>Files</Cell>}
                            columnKey="file_count"
                            cell={<ButtonCell data={posts} onClick={(post) => setSelected({mode:"files", post})} />}
                            align="center"
                            width={80}
                        />                             
                        <Column
                            header={<Cell>Links</Cell>}
                            columnKey="link_count"
                            cell={<ButtonCell data={posts} onClick={(post) => setSelected({mode:"links", post})} />}
                            align="center"
                            width={80}
                        />                                         
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                  Date Created
                                </SortHeaderCell>
                            }
                            columnKey="added_date"
                            cell={<DateCell data={posts} />}
                            width={135}
                        />
                        <Column
                            header={<Cell>Actions</Cell>}
                            cell={
                                <EditActionsCell 
                                    data={posts} 
                                    actions={[
                                        {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, isVisible: () => userHasPermission(Permissions.News, Actions.Edit)},
                                        {action:"publish", title:"Publish", variant:"success", icon:faCheck, isVisible: () => userHasPermission(Permissions.News, Actions.Publish) && selectedTab == "Draft"},
                                        {action:"delete", title:"Delete", variant:"danger", icon:faTrash, isVisible: () => userHasPermission(Permissions.News, Actions.Delete)},
                                    ]}
                                    busy={busyRow}
                                    onClick={onAction} 
                                />
                            }
                            align="center"
                            width={150}
                        />
                    </Table>
                }
            </>
            </Content>
            { selected && selected.mode == "post" && 
                <BlogPostDrawer 
                    post={selected.post} 
                    show={true}
                    products={products}
                    parts={parts}
                    shippingMethods={shippingMethods}
                    onSaved={(post) => {
                        onPostChanged(post);
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { selected && selected.mode == "links" && 
                <BlogLinksDrawer 
                    post={selected.post} 
                    show={true}
                    onChange={(linkCount) => {
                        const rowIndex = posts.indexOf(selected.post)

                        setPosts(produce(draft => {
                            if (rowIndex != -1)
                                draft[rowIndex].link_count = linkCount;
                        }));                        
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { selected && selected.mode == "files" && 
                <BlogFilesDrawer 
                    post={selected.post} 
                    show={true}
                    onChange={(fileCount) => {
                        const rowIndex = posts.indexOf(selected.post)

                        setPosts(produce(draft => {
                            if (rowIndex != -1)
                                draft[rowIndex].file_count = fileCount;
                        }));                        
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }              
        </PageLayout>
    );
}

export default BlogPage;