import React from "react";
import { clsx } from "clsx";
import SiteMenu from "./site-menu";
import SiteNotifications from "./site-notifications";
import { isMobile } from "react-device-detect";

export default function PageLayout({children, embedded}) {
    return (
        <div className={clsx(
            embedded ? "" : "mt-[50px]",
            "items-start",
            isMobile || embedded ? "" : "grid grid-cols-[55px,1fr,55px]"
        )}>
          {!isMobile && !embedded && <SiteMenu /> }
          <div 
            id="page-layout"
            style={{
              height: "calc(100vh - 50px)",
              overflowY: "auto",
            }}
          >
            {children}
          </div>
          {!isMobile && !embedded && <SiteNotifications /> }
        </div>
    );
}
