import React, { useState, useEffect, useRef } from "react";
import { clsx } from "clsx"
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShapes, faVectorSquare, faImage, faCertificate, faUndoAlt, faSearchMinus, faRuler, faUser, faDrawPolygon, faRedoAlt, faSync, faRulerCombined, faTimesCircle, faSpinner, faTrash, faCheck, faSearchPlus, faLowVision, faEraser, faBars, faMagic, faPlus, faPlusCircle, faPowerOff, faPencilAlt, faPaintBrush, faSquare, faBan, faSave } from "@fortawesome/free-solid-svg-icons";

import { fabric } from 'fabric'
import potrace from 'potrace'
import { parseSync } from 'svgson'

import Content from "../components/content"
import PageHeader from "../components/page-header";

import PageLayout from "../components/page-layout";

import { useGetSignLogo } from "../api/queries/signlogos";
import { useGetStyles } from "../api/queries/styles";

import { useBodyClass } from "../hooks/useBodyClass";
import Loading from "../components/loading";
import storage from "../settings/storage";
import { 
    useSetSignLogoMeasurement, 
    useAddSignLogoItem, 
    useUpdateSignLogoItem, 
    useUpdateSignLogoItems, 
    useDeleteSignLogoItem, 
    useUpdateSignLogoItemProp, 
    useRestoreSignLogoImage,
    useCreateSignLogoSign
} from "../api/mutations/signlogos";
import ButtonMutate from "../components/buttons/button-mutate";
import { uploadImage } from "../common/services/server";

import checkeredImage from "../images/checkered.jpg";
import bringFowardIcon from "../images/bring-forward.png";
import sendBackwardsIcon from "../images/send-to-back.png";
import Tooltip from "../components/tooltip";
import { useKeyPress } from "../hooks/useKeyPress";
import AutoHeightAndWidthPanel from "../panels/autoheight-andwidth-panel";
import MagicWand from "../helpers/magicwand";
import Input from "../components/fields/input";
import ShapePreview from "../components/shape-preview";
import { useGetProducts } from "../api/queries/products";
import FieldSelect from "../components/fields/field-select";
import produce from "immer";
import FieldLabel from "../components/fields/field-label";
import Badge from "../components/badge";
import FieldNumber from "../components/fields/field-number";
import { useGetCustomShapesForStyle } from "../api/queries/shapes";
import ZoomableImage from "../components/image-zoomable";

import facesInfoImage from "../images/customsign_facesinfo.jpg";
import sidesInfoImage from "../images/customsign_sidesinfo.jpg";
import FieldUser from "../components/fields/field-user";
import Field from "../components/fields/field";
import UserSelectDrawer from "../drawers/drawer-userselect";

const DEBUG = true;

function polygonPositionHandler(dim, finalMatrix, fabricObject) {
    var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
        y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);

    return fabric.util.transformPoint(
        { x: x, y: y },
        fabric.util.multiplyTransformMatrices(
            fabricObject.canvas.viewportTransform,
            fabricObject.calcTransformMatrix()
    ));
}

// define a function that will define what the control does
// this function will be called on every mouse move after a control has been
// clicked and is being dragged.
// The function receive as argument the mouse event, the current trasnform object
// and the current position in canvas coordinate
// transform.target is a reference to the current object being transformed,
function actionHandler(eventData, transform, x, y) {
    var polygon = transform.target,
        currentControl = polygon.controls[polygon.__corner],
        mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
        polygonBaseSize = polygon._getNonTransformedDimensions(),
        size = polygon._getTransformedDimensions(0, 0),
        finalPointPosition = {
                x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
                y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
        };

    polygon.points[currentControl.pointIndex] = finalPointPosition;

    return true;
}

// define a function that can keep the polygon in the same position when we change its
// width/height/top/left.
function anchorWrapper(anchorIndex, fn) {
    return function(eventData, transform, x, y) {
      var fabricObject = transform.target,
          absolutePoint = fabric.util.transformPoint({
              x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
              y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
          }, fabricObject.calcTransformMatrix()),
          actionPerformed = fn(eventData, transform, x, y),
          newDim = fabricObject._setPositionDimensions({}),
          polygonBaseSize = fabricObject._getNonTransformedDimensions(),
          newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
  		  newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
      
        fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);

        return actionPerformed;
    }
}

function hexToRgb(hex, alpha) {
    var int = parseInt(hex, 16);
    var r = (int >> 16) & 255;
    var g = (int >> 8) & 255;
    var b = int & 255;
  
    return [r,g,b, Math.round(alpha * 255)];
}

function ToolbarButton({
    caption,
    tooltip,
    size,
    icon,
    iconClassname,
    iconRight,
    iconRightClassname,
    image,
    count=null,
    inactive,
    busy,
    disabled,
    onClick
}) {
    return (
        <Tooltip content={tooltip} align="left">
        <button 
            className={clsx(
                "rounded flex gap-1 items-center",
                size == "sm" ? "text-xs p-1" : "py-1 px-2",
                disabled ? "bg-white text-gray-300" :
                inactive ? "!bg-purple-200 hover:!bg-purple-100" : "bg-white hover:!bg-purple-100",
            )}
            disabled={disabled || busy}
            onClick={onClick}
        >
            {icon && <FontAwesomeIcon icon={busy ? faSpinner:icon} spin={busy} className={busy ? "":iconClassname} />}
            {image && <img src={image} className="h-[16px]" />}
            {(!icon && !iconRight && busy) ?
                <FontAwesomeIcon icon={faSpinner} spin />
            :
                caption
            }
            {iconRight && <FontAwesomeIcon icon={busy ? faSpinner:iconRight} spin={busy} className={busy ? "":iconRightClassname} />}
            {count != null && 
                <span className="rounded bg-red-500 text-white px-2">
                    {count}
                </span>
            }
        </button>  
        </Tooltip>
    )
}

function ToolbarGroup({icon, caption, children}) {
    return (
        <div className="bg-white rounded px-1 flex items-center gap-1 h-[28px]">
            {icon && <FontAwesomeIcon icon={icon} />}
            {caption}
            {children}
        </div>
    )    
}

function Toolbar({children}) {
    return (
        <div className="bg-purple-200 p-2 text-sm flex gap-1 items-stretch justify-between">
            {children}
        </div>
    )
}

function ToolbarButtons({children, className, onClose}) {
    return (
        <>
            <div className={clsx("flex gap-1 items-center", className)}>
                {children}
            </div>
            {onClose && 
                <ToolbarButton
                    icon={faTimesCircle}
                    onClick={onClose}
                />            
            }
        </>
    )
}

function ImageCanvas({logo}) {
    const canvasRef = useRef();

    const [logoAltered, setLogoAltered] = useState(false);
    const [imageVisible, setImageVisible] = useState(true);
    const [canvas, setCanvas] = useState(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const [activeControl, setActiveControl] = useState(null);
    const [selected, setSelected] = useState(null);
    
    const [inches, setInches] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [letters, setLetters] = useState("");
    const [mode, setMode] = useState("");
    const [snapshot, setSnapshot] = useState(null);
    const [traceOptions, setTraceOptions] = useState({ 
        threshold: -1,
        turdSize: 2,
        opaque: true // doesn't work
    });
    const [backgroundOptions, setBackgroundOptions] = useState({ 
        threshold: 15,
        blurRadius: 5,
    });
    const [silhouetteOptions, setSilhouetteOptions] = useState({ 
        threshold: 15,
        stroke: 0,
    });
    const [paintOptions, setPaintOptions] = useState({ 
        cursor: null,
        size: 2,
        color: "#000000",
    });

    useBodyClass(`noscroll`);

    const stylesQuery = useGetStyles({builder_type: "letters"});
    
    const saveMeasurement = useSetSignLogoMeasurement(logo.id)
    const restoreImage = useRestoreSignLogoImage(logo.id, {
        onError: () => window.alert("Error updating image")
    })         
    const addItem = useAddSignLogoItem(logo.id, {
        onError: () => window.alert("Error adding item")
    });
    const updateItem = useUpdateSignLogoItem(logo.id, {
        onError: () => window.alert("Error updating item")
    });
    // const updateItemPathType = useUpdateSignLogoItemProp(logo.id, "path_type", {
    //     onError: () => window.alert("Error updating item")
    // })
    const updateItemText = useUpdateSignLogoItemProp(logo.id, "text", {
        onError: () => window.alert("Error updating item")
    })    
    const updateItemPathStyle = useUpdateSignLogoItemProp(logo.id, "style_id", {
        onError: () => window.alert("Error updating item")
    })    
    const updateItemConsumption = useUpdateSignLogoItemProp(logo.id, "consumption", {
        onError: () => window.alert("Error updating item")
    })        
    const updateItems = useUpdateSignLogoItems(logo.id, {
        onError: () => window.alert("Error updating items")
    });    
    const deleteItem = useDeleteSignLogoItem(logo.id, {
        onError: () => window.alert("Error deleting item")
    });

    const arrowPressUp = useKeyPress("ArrowUp", true);
    const arrowPressDown = useKeyPress("ArrowDown", true);
    const arrowPressLeft = useKeyPress("ArrowLeft", true);
    const arrowPressRight = useKeyPress("ArrowRight", true);

    function getImage() {
        return canvas.getObjects("img")[0];
    }
    function replaceImage(canvas, url, callback) {
        console.log("replaceImage", url)
        fabric.Image.fromURL(storage.root + url + "?t=" + new Date().getTime(), function(img) {
            console.log({img})

            for (const img of canvas.getObjects("img")) {
                canvas.remove(img);
            }

            img.type = "img";
            img.hasControls = false;
            img.selectable = false;
                                
            canvas.add(img);

            img.sendToBack(); 
            
            setLogoAltered(true);

            if (callback)
                callback(img);
        }, {crossOrigin: 'anonymous'});        
    }

    function getMeasureControl(canvas) {
        var objects = canvas.getObjects("measure");

        return objects.length ? objects[0] : null;
    }

    function getControlById(canvas, id) {
        return canvas.getObjects().find(c => c.id == id);
    }

    function addMeasureControl(canvas, type, id, points, inches) {
        console.log("addMeasureControl", type, id, points, inches);
        
        const group = new fabric.Group();

        group.type = type;
        group.id = id;

        const dot1 = new fabric.Circle({
            type: "measureDot",
            radius: 6,
            fill: type=="measure" ? 'red':'white',
            stroke: type=="measure" ? 'white':'black',
            strokeWidth: 2,
            originX: 'center',
            originY: 'center',
            selectable: type=="ruler",
            hasControls: false,
        });
        const dot2 = new fabric.Circle({
            type: "measureDot",
            radius: 6,
            fill: type=="measure" ? 'red':'white',
            stroke: type=="measure" ? 'white':'black',
            strokeWidth: 2,
            originX: 'center',
            originY: 'center',
            selectable: type=="ruler",
            hasControls: false,
        });

        dot1.left = points ? points[0] || 0 : 0;
        dot1.top = points ? points[1] || 0 : 0;
        dot2.left = points ? points[2] || 0 : 0;
        dot2.top = points ? points[3] || 0 : 0;
        
        canvas.add(dot1);
        canvas.add(dot2);

        const line1 = new fabric.Line([dot1.left-1, dot1.top, dot2.left-1, dot2.top], {
            type: 'measureLine',
            name: `measure_${id}_line1`,
            stroke: 'white',
            strokeWidth: 3,
            selectable: false,
            hasControls: false,
            evented: false,
        });
        const line2 = new fabric.Line([dot1.left-1, dot1.top, dot2.left-1, dot2.top], {
            type: 'measureLine',
            name: `measure_${id}_line2`,
            stroke: type=="measure" ? 'red':'black',
            strokeWidth: 3,
            strokeDashArray: [7, 7],
            selectable: false,
            hasControls: false,
            evented: false,
        });        

        canvas.add(line1);
        canvas.add(line2);

        dot1.visible = dot1.left > 0 ? true:false;
        dot2.visible = dot1.visible;
        line1.visible = dot1.visible;
        line2.visible = dot1.visible;

        var text = new fabric.Text(inches || "?", { 
            type: "measureText",
            name: `measure_${id}_text`,
            left: line1.left+line1.width/2, 
            top: line1.top+line1.height/2,
            fontFamily: "Serif",
            fontSize: 16,
            backgroundColor: "white",
            selectable: false,
            hasControls: false,
        });

        canvas.add(text);
        text.visible = dot1.visible;

        // fake group ref for easy access during selection
        dot1.parent = group;
        dot2.parent = group;

        dot1.bringToFront();
        dot2.bringToFront();

        group.dot1 = dot1;
        group.dot2 = dot2;
        group.line1 = line1;
        group.line2 = line2;
        group.text = text;

        canvas.add(group);

        return group
    }
    function addRulerControl(canvas, ruler) {
        return addMeasureControl(canvas, ruler.type, ruler.id, ruler.points || [0,0,0,0], ruler.text)
    }
    function addRectControl(canvas, rectData) {
        let points = rectData.points || [0,0,0,0]

        const rect = new fabric.Rect({
            type: "rect",
            id: rectData.id,
            left: points[0],
            top: points[1],
            width: points[2],
            height: points[3],
            fill: "transparent",
            // opacity: .25,
            stroke: "black",                                    
            strokeWidth: 2,
            strokeUniform: true,
            strokeDashArray: [7,7],
            transparentCorners: false,
            cornerStrokeColor: "black",
            cornerStyle: "circle",
            cornerColor: "white",
            selectionBackgroundColor: "rgba(255,0,0,.1)",
            selectable: true,
            hasControls: true,
            lockRotation: true,
            lockScalingFlip: true,
            lockSkewingX: true,
            lockSkewingY: true,
        });   

        rect.setControlsVisibility({
            bl: true,
            br: true,
            mb: true,
            ml: true,
            mr: true,
            mt: true,
            tl: true,
            tr: true,
            mtr: false
        });

        canvas.add(rect);

        rect.visible = rect.width > 0;

        return rect;
    }

    function addBackgroundControl(canvas, pathData) {
        const path = new fabric.Path(pathData, {
            type: "background",
            fill: "#ff0000",
            // width: canvas.width,
            // height: canvas.height,
            opacity: .5,
            selectable: false,
            hasControls: false,
            hasBorders: false,
            evented: false,
        });    

        // path.scale(1/fabric.devicePixelRatio);  
        
        canvas.add(path);

        return path;
    }
    function addSilhouetteControl(canvas, pathData) {
        const path = new fabric.Path(pathData, {
            type: "silhouette",
            fill: "#000000",
            // width: canvas.width,
            // height: canvas.height,
            stroke: "#000000",
            selectable: false,
            hasControls: false,
            hasBorders: false,
            evented: false,
        });    

        // path.scale(1/fabric.devicePixelRatio);  
        
        canvas.add(path);

        path.origLeft = path.left;
        path.origTop = path.top;

        return path;
    }

    function addPolygonControl(canvas, polyData) {
        let points = []
 
        if (polyData.points) {
            let i = 0;

            while (i < polyData.points.length) {
                points.push({
                    x: polyData.points[i],
                    y: polyData.points[i+1],
                })

                i=i+2;
            }
        }

        const polygon = new fabric.Polygon(points, {
            type: "polygon",
            id: polyData.id,
            fill: "transparent",
            // fill: "white",
            // opacity: .25,
            stroke: "black",                                    
            strokeWidth: 2,
            strokeUniform: true,
            strokeDashArray: [7,7],
            transparentCorners: false,
            cornerStrokeColor: "black",
            cornerStyle: "circle",
            cornerColor: "white",
            selectionBackgroundColor: "rgba(255,0,0,.1)",
            selectable: true,
            hasControls: true,
            hasBorders: true,
            objectCaching: false,
        }); 

        const lastControl = polygon.points.length - 1;

        polygon.controls = polygon.points.reduce(function(acc, point, index) {
            acc['p' + index] = new fabric.Control({
                positionHandler: polygonPositionHandler,
                actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
                actionName: 'modifyPolygon',
                pointIndex: index
            });
            return acc;
        }, { });

        canvas.add(polygon);

        return polygon;
    }
    function generatePolygonControl(canvas, control) {
        const points = [];

        for (const dot of control.dots) {
            points.push(dot.left);
            points.push(dot.top);

            canvas.remove(dot);
        };
        for (const line of control.lines) {
            canvas.remove(line);
        };
        
        canvas.remove(control);

        const polygon = addPolygonControl(canvas, {
            id: control.id,
            points
        });

        return polygon;
    }

    function addShapeControl(canvas, shapeData) {
        const shape = addPolygonControl(canvas, shapeData); 

        shape.type = "shape";
        shape.lockMovementX = true;
        shape.lockMovementY = true;   
        shape.hasControls = false;     
        shape.data = shapeData;

        return shape;
    }
    function generateShapeControl(canvas, control) {
        console.log("generateShapeControl", control)        

        const shape = generatePolygonControl(canvas, control);

        //const box = canvas.getObjects("box")[0];

        shape.type = "shape";
        shape.lockMovementX = true;
        shape.lockMovementY = true;
        shape.hasControls = false;
        shape.data = {
            parent_id: control.data.parent_id
        }

        console.log("generateShapeControl", canvas.getObjects("shape"))        

        return shape;
    }

    function addSvgControl(canvas, svgData) {
        const path = new fabric.Path(svgData.path, {
            type: "svg",
            id: svgData.id,
            data: svgData,
            fill: svgData.color || "#aa0000",
            left: svgData.points ? svgData.points[0] : 10,
            top: svgData.points ? svgData.points[1] : 10,

            fillRule: "evenodd",

            transparentCorners: false,
            cornerStrokeColor: "black",
            cornerStyle: "circle",
            cornerColor: "white",
            selectionBackgroundColor: "rgba(255,0,0,.1)",
            selectable: true,
            hasControls: false,
            hasBorders: true,
        });  

        canvas.add(path);

        return path;

        /*
        fabric.loadSVGFromString(svgData.path, function(objects, options) {
            console.log({objects})
            var obj = fabric.util.groupSVGElements(objects, options);

            obj.type = "svg";
            obj.id = svgData.id;
            obj.data = svgData;
            //obj.fillRule = "nonzero";
            obj.fill = svgData.color || "#ff0000";
            obj.left = svgData.points ? svgData.points[0] : 10;
            obj.top = svgData.points ? svgData.points[1] : 10;

            obj.transparentCorners = false;
            obj.cornerStrokeColor = "black";
            obj.cornerStyle = "circle";
            obj.cornerColor = "white";
            obj.selectionBackgroundColor = "rgba(255,0,0,.1)";
            obj.selectable = true;
            obj.hasControls = false;
            obj.hasBorders = true;

            canvas.add(obj);

            if (callback) callback(obj)
        });
        */
    }

    function round(num, decimalPlaces = 0) {
        var p = Math.pow(10, decimalPlaces);
        return Math.round(num * p) / p;
    }    

    function calcRulerDistance(ruler) {
        let x1 = ruler.dot1.left + ruler.dot1.width/2;
        let y1 = ruler.dot1.top + ruler.dot1.height/2;
        let x2 = ruler.dot2.left + ruler.dot2.width/2;
        let y2 = ruler.dot2.top + ruler.dot2.height/2;
    
        const distance = Math.sqrt( (x2-=x1)*x2 + (y2-=y1)*y2 );

        return round(distance,2);
    }
    function calcInches(distance) {
        const measure = getMeasureControl(canvas);
        const measureDistance = calcRulerDistance(measure);
        const scale = distance/measureDistance;

        const inches = parseFloat(measure.text.text) * scale;

        return round(inches,2);
    }      
    function calcPixelsFromInches(inches) {
        const measure = getMeasureControl(canvas);
        const measureDistance = calcRulerDistance(measure);
        const measureInches = parseFloat(measure.text.text);

        const scale = measureDistance/measureInches;
        const pixels = inches * scale;

        console.log("calcPixelsFromInches")
        console.log({measureDistance})
        console.log({measureInches})
        console.log({inches})
        console.log({scale})
        console.log({pixels})

        return round(pixels);
    }  

    function calcRulerInches(canvas, ruler) {
        const rulerDistance = calcRulerDistance(ruler);
        return calcInches(rulerDistance);
    }
    function calcAreaInInches(control) {
        return round((calcInches(control.height) * calcInches(control.width))/12)
    }
    function calcAreaInFeet(control) {
        return round(calcAreaInInches(control)/12);
    }
    function calcRatios(control) {
        const sizeHeight = calcInches(control.height);
        const sizeWidth = calcInches(control.width);

        let height = 0;
        let width = 0;

        if (control.data?.path_type == "letters") {
            // const measure = getMeasureControl(canvas);
            // const measureDistance = calcRulerDistance(measure);
            // const scale = measureDistance/control.height;

            // console.log("scale", scale);
            const scale = 1;

            // if we are dealing with custom letters, then we assume they have measured the tallest capital letter (E)
            height = round(1 * scale, 2);
            width = round((sizeWidth/sizeHeight) * scale, 2);
        }
        else {
            const area = Math.round(sizeHeight * sizeWidth);
            const sqrt = Math.round(Math.sqrt(area));
    
            height = round(sizeHeight / sqrt, 2);
            width = round(sizeWidth / sqrt, 2);
        }

        return {
            height,
            width
        }
    }
    function calcSize(control) {
        if (control.type == "svg" && control.data && control.data.path_type == "letters") {
            return round(calcInches(control.height));
        }
        else {
            const sizeHeight = calcInches(control.height);
            const sizeWidth = calcInches(control.width);
            const area = Math.round(sizeHeight * sizeWidth);
            const sqrt = Math.round(Math.sqrt(area));

            return sqrt;
        }
    }

    function removeControl(canvas, control) {
        if (control.type == "measure" || control.type == "ruler") {
            canvas.remove(control.dot1);
            canvas.remove(control.dot2);
            canvas.remove(control.line1);
            canvas.remove(control.line2);
            canvas.remove(control.text);
            canvas.remove(control);
        }
        else if (control.type == "polygon" || control.type == "shape") {
            for (const dot of control.dots || []) {
                canvas.remove(dot);
            };
            for (const line of control.lines || []) {
                canvas.remove(line);
            };

            control.dots = [];
            control.lines = [];

            canvas.remove(control);
        }
        else if (control.type == "svg") {
            for (const shape of canvas.getObjects("shape").filter(s => s.data.parent_id == control.id)) {
                canvas.remove(shape);
            }
            canvas.remove(control);
        }
        else {
            canvas.remove(control);
        }
    }

    function disableSelections(canvas) {
        for (let obj of canvas.getObjects()) {
            obj.selectable = false;
        }
    }
    function enableSelections(canvas) {
        for (let obj of canvas.getObjects()) {
            obj.selectable = obj.type != "measure" && obj.type != "img";
        }
    }

    function hideImage(canvas) {
        for (let obj of canvas.getObjects("img")) {
            obj.visible = false;
        }
    }
    function showImage(canvas) {
        for (let obj of canvas.getObjects("img")) {
            obj.visible = true;
        }
    }

    function hideAllControls(canvas) {
        for (let obj of canvas.getObjects()) {
            obj.visible = obj.type == "img";
        }
    }
    function showAllControls(canvas) {
        for (let obj of canvas.getObjects()) {
            obj.visible = obj.type != "shape";
        }
    }

    function bringMeasureControlsToFront(canvas) {
        const measure = getMeasureControl(canvas);

        if (measure) {
            measure.line1.bringToFront();
            measure.line2.bringToFront();
            measure.dot1.bringToFront();
            measure.dot2.bringToFront();
            measure.text.bringToFront();
        }

        let controls = canvas.getObjects("ruler");

        for (let control of controls) {
            control.line1.bringToFront();
            control.line2.bringToFront();
            control.dot1.bringToFront();
            control.dot2.bringToFront();
            control.text.bringToFront();
        }        
    }
    function disableMeasureControl(canvas) {
        showAllControls(canvas);

        const control = getMeasureControl(canvas);

        if (control) {
            control.dot1.selectable = false;
            control.dot2.selectable = false;
        }
        else {
            console.log("disableMeasureControl missing Measure Control??", canvas.getObjects())
        }
    }
    function enableMeasureControl(canvas) {
        hideAllControls(canvas);

        const control = getMeasureControl(canvas);

        control.dot1.visible = control.dot1.left > 0;
        control.dot2.visible = control.dot1.visible;
        control.line1.visible = control.dot1.visible;
        control.line2.visible = control.dot1.visible;
        control.text.visible = control.dot1.visible;

        control.dot1.selectable = true;
        control.dot2.selectable = true;
    }
    function positionMeasureControl(canvas, control) {
        control.line1.set({
            x1: control.dot1.left-1,
            y1: control.dot1.top,
            x2: control.dot2.left-2,
            y2: control.dot2.top
        });
        control.line2.set({
            x1: control.dot1.left-1,
            y1: control.dot1.top,
            x2: control.dot2.left-2,
            y2: control.dot2.top
        });

        control.line1.setCoords()
        control.line2.setCoords()
        // control.line.bringToFront();
        // control.dot1.bringToFront();
        // control.dot2.bringToFront();

        control.text.set({
            left: control.line1.left+control.line1.width/2, 
            top: control.line1.top+control.line1.height/2,            
        });

        control.text.bringToFront();
    }
    function updateControlText(canvas, control, value) {
        const wasVisible = control.text.visible;

        canvas.remove(control.text);

        var text = new fabric.Text(value || "", { 
            type: "measureText",
            name: `measure_${control.id}_text`,
            left: control.line1.left+control.line1.width/2, 
            top: control.line1.top+control.line1.height/2,
            fontFamily: "Serif",
            fontSize: 16,
            backgroundColor: "white",
            selectable: false,
            hasControls: false,
            visible: wasVisible
        });

        canvas.add(text);  

        control.text = text;
        control.text.visible = control.dot2.visible;
        
        return text;
    }
    function updateRulerDistances(canvas) {
        let controls = canvas.getObjects("ruler");

        for (let control of controls) {
            updateControlText(canvas, control, calcRulerInches(canvas, control) + " in");
        }
    }

    function updateCursor(canvas) {
        // if (mode == "measure") {
        //     if (activeControl && (!activeControl.dot1.visible || !activeControl.dot2.visible))
        //         canvas.hoverCursor = "pointer"
        //     else
        //         canvas.hoverCursor = "default"
        // }
        // else {
        //     canvas.hoverCursor = "default"
        // }
    }

    function saveControl(control) {
        if (control.type == "rect") {
            updateItem.mutate({
                id: control.id,
                points: [control.left, control.top, control.width, control.height],  
            });
        }
        else if (control.type == "svg") {
            const ratios = calcRatios(control);

            updateItem.mutate({
                id: control.id,
                points: [control.left, control.top, control.width, control.height],  
                text: control.data ? control.data.text : null,
                width_ratio: ratios.width,
                height_ratio: ratios.height,
                size: calcSize(control),
                size_height: calcInches(control.height),
                size_width: calcInches(control.width),    
            });
        }        
        else if (control.type == "polygon" || control.type == "shape") {
            const points = [];

            for (const point of control.points) {
                points.push(point.x);
                points.push(point.y);
            }

            updateItem.mutate({
                id: control.id,
                points,  
                text: control.data ? control.data.text : null
            })            
        }
        else if (control.dot1) {
            updateItem.mutate({
                id: control.id,
                points: [control.dot1.left, control.dot1.top, control.dot2.left, control.dot2.top],    
                text: control.text.text
            })
        }
        else {
            console.log("save control???", control)
        }
    }
    function saveItems(canvas) {
        let items = []

        for (const item of canvas.getObjects()) {
            if (item.type == "ruler") {
                items.push({
                    id: item.id,
                    type: item.type,
                    points: [item.dot1.left, item.dot1.top, item.dot2.left, item.dot2.top],    
                    text: item.text.text
                })
            }
            else if (item.type == "shape" || item.type == "svg") {
                item.data.size = calcSize(item);
                item.data.size_width = calcInches(item.width);
                item.data.size_height = calcInches(item.height);

                const ratios = calcRatios(item);

                items.push({
                    id: item.id,
                    type: item.type,
                    width_ratio: ratios.width,
                    height_ratio: ratios.height,
                    size: item.data.size,
                    size_width: item.data.size_width,
                    size_height: item.data.size_height,
                });
            }
        }

        if (items.length) {
            updateItems.mutate(items);
        }
    }
    function saveOrderings(canvas) {
        let items = []

        let index = 1;

        console.log(canvas.getObjects())
        for (const item of canvas.getObjects().filter(o => o.id)) {
            items.push({
                id: item.id,
                ordering: index
            });

            index++;
        }

        updateItems.mutate(items);
    }    

    function spin(obj) {
        if (!obj) return;

        obj.rotate(0).animate({ angle: 360 }, {
          duration: 3000,
          onChange: canvas.renderAll.bind(canvas),
          onComplete: function(){ 
            spin(obj) 
          },
          easing: function(t, b, c, d) { return c*t/d + b }
        });
      }

    function addSpinnerToControl(canvas, control) {
        const svg = '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><circle cx="3" cy="12" r="2"/><circle cx="21" cy="12" r="2"/><circle cx="12" cy="21" r="2"/><circle cx="12" cy="3" r="2"/><circle cx="5.64" cy="5.64" r="2"/><circle cx="18.36" cy="18.36" r="2"/><circle cx="5.64" cy="18.36" r="2"/><circle cx="18.36" cy="5.64" r="2"/></g></svg>';

        fabric.loadSVGFromString(svg, function(objects, options) {
            var obj = fabric.util.groupSVGElements(objects, options);

            obj.type = "spinner";
            obj.originX = "center";
            obj.originY = "center";
            obj.centeredRotation = true;

            canvas.add(obj);

            obj.left = control.left + control.width/2 - obj.width/2;
            obj.top = control.top + control.height/2 - obj.height/2;

            spin(obj);

            canvas.renderAll();
        })  
    }
    function removeSpinnerControl(canvas) {
        for (const spinner of canvas.getObjects("spinner")) {
            canvas.remove(spinner);
        }
        canvas.renderAll();
    }

    function snapshotArea(canvas, x,y,w,h, scaler=1, format="png") {
        var originalTransform = canvas.viewportTransform;
        canvas.viewportTransform = fabric.iMatrix.slice(0);

        const image = canvas.toDataURL({
                    format,
                    quality: .9,
                    multiplier: scaler,
                    left: x,
                    top: y,
                    width: w,
                    height: h
                });    
        
        canvas.viewportTransform = originalTransform;

        return image; //atob(image.replace("data:image/png;base64,", ""));
    }
    // function calcAreaConsumption(canvas, x,y,w,h) {
    //     hideAllControls(canvas);
    //     canvas.renderAll();

    //     const image = canvas.getContext().getImageData(x,y,w,h);                                                
    //     const data = image.data;
    //     const pixels = data.length/4;
    //     const len = data.length;
    //     let used = 0;

    //     for (var i=0; i<len; i+=4) {
    //       if ((data[i] < 240 || data[i+1] < 240 || data[i+2] < 240)) {
    //         //console.log(i, data[i], data[i+1], data[i+2], data[i+3])

    //         used++;
    //       }
    //     }

    //     console.log({pixels});
    //     console.log({used});

    //     showAllControls(canvas);
    //     canvas.renderAll();

    //     return round(used/pixels,2);
    // }
    function trimCanvas(canvas, threshold = 0) {
        const ctx = canvas.getContext('2d'),
            w = canvas.width, h = canvas.height,
            imageData = ctx.getImageData(0, 0, w, h),
            tlCorner = { x:w+1, y:h+1 },
            brCorner = { x:-1, y:-1 };
    
        for (let y = 0; y < h; y++) {
            for (let x = 0; x < w; x++) {
                const pos = ((y * w + x) * 4);
                const r = imageData.data[pos];
                const g = imageData.data[pos + 1];
                const b = imageData.data[pos + 2];
                const a = imageData.data[pos + 3];

                //console.log(x, y, r, g, b, a)
                if (a > threshold && (r<(255-threshold) && g<(255-threshold) && b<(255-threshold))) {
                    tlCorner.x = Math.min(x, tlCorner.x);
                    tlCorner.y = Math.min(y, tlCorner.y);
                    brCorner.x = Math.max(x, brCorner.x);
                    brCorner.y = Math.max(y, brCorner.y);
                }
            }
        }
    
        const cut = ctx.getImageData(tlCorner.x, tlCorner.y, brCorner.x - tlCorner.x, brCorner.y - tlCorner.y);
    
        canvas.width = brCorner.x - tlCorner.x;
        canvas.height = brCorner.y - tlCorner.y;
    
        ctx.putImageData(cut, 0, 0);
    
        console.log("trimCanvas", w, h, canvas.width, canvas.height)
        return {width:canvas.width, height:canvas.height, x:tlCorner.x, y:tlCorner.y};
    }    
    function calcAreaConsumption(canvas, left, top, width, height) {       
        const image = canvas.getContext("2d").getImageData(left,top,width,height);
        const data = image.data;
        const pixels = data.length/4;

        let transparent = 0;
        let white = 0;
        let used = 0;

        console.log("calcAreaConsumption", left, top, width, height);
        console.log({pixels});

        const r=0, g=1, b=2, a=3;
        const threshold = 245;
        for (var x = 0; x < width; x++) {
            for (var y = 0; y < height; y++) {
                const p = y * (width * 4) + x * 4;
                const red = data[p+r];
                const green = data[p+g];
                const blue = data[p+b];
                const alpha = data[p+a];

                // transparent?
                if (alpha == 0)
                    transparent++;
                // white?
                else if (red >= threshold && green >= threshold && blue >= threshold) 
                    white++;
                else {
                    used++;                    
                }

                // console.log(x, y, red,green,blue,alpha)
            }        
        }
        
        console.log({transparent},{white},{used});
        console.log(round(used/pixels,2));

        return round(used/pixels,2);
    }
    function calcControlConsumption(canvas, control, callback) {
        console.log("calcControlConsumption", control.type);

        for (let obj of canvas.getObjects()) {
            obj.origVisible = obj.visible;            
            obj.visible = false;
        }

        const preStrokeWidth = control.strokeWidth;
        const preSelectionBackgroundColor = control.selectionBackgroundColor;
        control.strokeWidth = 0;
        control.selectionBackgroundColor = "rgba(255,255,255)";
        control.visible = true;

        if (control.type == "shape") {
            const image = getImage(canvas);
            image.visible = true;
            control.absolutePositioned = true;
            image.clipPath = control;
        }

        console.log(canvas.getObjects().filter(o => o.visible))
        canvas.renderAll();

        // const image = canvas.getContext().getImageData(control.left,control.top,control.width,control.height);  
        var prevImage = document.getElementById("tempimage");
        if (prevImage) {
            prevImage.remove();
        }

        var tempImage = document.createElement('img');
        tempImage.id = "tempimage";
        tempImage.onload = function() {
            console.log("temp image loaded")

            var prevCanvas = document.getElementById("tempcanvas");
            if (prevCanvas) {
                prevCanvas.remove();
            }

            var tempCanvas = document.createElement('canvas');

            tempCanvas.id = "tempcanvas";
            tempCanvas.width = control.width;
            tempCanvas.height = control.height;

            if (DEBUG) {
                document.body.appendChild(tempCanvas)
            }

            tempCanvas.style.border = "1px solid black";
            // tempCanvas.style.position = "fixed"
            // tempCanvas.style.bottom = "25px"
            // tempCanvas.style.right = (control.width + 50) + "px"
            // tempCanvas.style.zIndex = 99999;  

            var cntxt = tempCanvas.getContext("2d")
            cntxt.drawImage(tempImage, 0, 0, control.width, control.height);
                         
            const dimensions = trimCanvas(tempCanvas);
            const consumption = calcAreaConsumption(tempCanvas, 0, 0, parseInt(dimensions.width), parseInt(dimensions.height));

            control.strokeWidth = preStrokeWidth;
            control.selectionBackgroundColor = preSelectionBackgroundColor;
    
            if (control.type == "shape") {
                getImage(canvas).clipPath = null;
            }
    
            for (let obj of canvas.getObjects()) {
                obj.visible = obj.origVisible;
            }
    
            canvas.renderAll();
    
            callback(consumption, dimensions);
        }
        tempImage.src = snapshotArea(canvas, control.left,control.top,control.width,control.height)
        
        if (DEBUG)
            document.body.appendChild(tempImage)

        tempImage.style.border = "1px solid black";
        // tempImage.style.position = "fixed"
        // tempImage.style.bottom = "25px"
        // tempImage.style.right = "25px"
        // tempImage.style.zIndex = 99999;



        // for (var p = 0; p<data.length; p+=4)
        // {
        //     // transparent?
        //     if (data[p+a] == 0)
        //         transparent++;
        //     // white?
        //     else if (data[p+r] == 255 && data[p+g] == 255 && data[p+b] == 255) 
        //         white++;
        //     else {
        //         used++;
        //     }
        // }

        // for (var i=0; i<len; i+=4) {
        //     if (data[i+3]==0 || (data[i] < threshold || data[i+1] < threshold || data[i+2] < threshold)) {
        //         // if (i<1000)
        //         if ((data[i] < threshold || data[i+1] < threshold || data[i+2] < threshold))
        //            console.log(i, data[i], data[i+1], data[i+2], data[i+3]);

        //         used++;
        //     }
        // }


        /*
        var tempCanvasElement = document.createElement('canvas');
        var tempCanvas = new fabric.Canvas(tempCanvasElement);

        document.body.appendChild(tempCanvasElement)
        tempCanvas.setWidth(control.width)
        tempCanvas.setHeight(control.height)
        
        control.clone((controlCopy) => {
            tempCanvas.add(controlCopy);

            controlCopy.left = 0;
            controlCopy.top = 0;

            tempCanvas.setBackgroundColor("#00ff00")
            tempCanvas.renderAll();

            setTimeout(() => {
                const image = tempCanvas.getContext().getImageData(0,0,control.width,control.height);  
                console.log({image})                                              
                const data = image.data;
                const pixels = data.length/4;
                const len = data.length;
                let used = 0;
        
                for (var i=0; i<len; i+=4) {
                //if ((data[i] < 240 || data[i+1] < 240 || data[i+2] < 240)) {
                    // if (i<1000)console.log(i, data[i], data[i+1], data[i+2], data[i+3])
                if ((data[i] != 0 && data[i+1] != 0 && data[i+2] != 0)) {
        
                    used++;
                }
                }
        
                console.log({pixels});
                console.log({used});
        
                callback(round(used/pixels,2))
            },500);
        });  
        */      
    } 
    function floodFillAreaToPath(canvas, x, y) {
        console.log("floodFillAreaToPath", x, y)
        const ctx = canvas.getContext();
        // const imageData = ctx.getImageData(0,0,canvas.width*fabric.devicePixelRatio,canvas.height*fabric.devicePixelRatio);  
        const imageData = ctx.getImageData(0,0,canvas.width,canvas.height);  

        var image = {
            data: imageData.data,
            width: canvas.width,// * fabric.devicePixelRatio,
            height: canvas.height,// * fabric.devicePixelRatio,
            bytes: 4
        };
        
        let old = null;
        
        let mask = MagicWand.floodFill(image, x, y, backgroundOptions.threshold, old, true);
        if (mask) mask = MagicWand.gaussBlurOnlyBorder(mask, backgroundOptions.blurRadius, old);
        // let border = MagicWand.getBorderIndices(mask);
        //console.log(mask)
        // console.log(border);

        const simplifyTolerant = 0;
        const simplifyCount = 30;

        var cs = MagicWand.traceContours(mask);
        cs = MagicWand.simplifyContours(cs, simplifyTolerant, simplifyCount);
        
        console.log({cs})

        return MagicWand.convertContoursToPath(cs);        
    }   
    function floodFillAreaToInsidePath(canvas, x, y) {
        console.log("floodFillAreaToInsidePath", x, y)
        const ctx = canvas.getContext();
        // const imageData = ctx.getImageData(0,0,canvas.width*fabric.devicePixelRatio,canvas.height*fabric.devicePixelRatio);  
        const imageData = ctx.getImageData(0,0,canvas.width,canvas.height);  

        var image = {
            data: imageData.data,
            width: canvas.width,// * fabric.devicePixelRatio,
            height: canvas.height,// * fabric.devicePixelRatio,
            bytes: 4
        };
        
        let old = null;
        
        let mask = MagicWand.floodFill(image, x, y, silhouetteOptions.threshold, old, true);
        // if (mask) mask = MagicWand.gaussBlurOnlyBorder(mask, silhouetteOptions.blurRadius, old);

        const simplifyTolerant = 0;
        const simplifyCount = 30;

        var cs = MagicWand.traceContours(mask);
        cs = MagicWand.simplifyContours(cs, simplifyTolerant, simplifyCount);
        
        console.log({cs})
        return MagicWand.convertInternalContoursToPath(cs);        
    }   

    useEffect(
        () => {
            var element = canvasRef.current;
            var canvas = new fabric.Canvas(element);
            
            canvas.enableRetinaScaling = false;
            canvas.selection = false;
            canvas.preserveObjectStacking = true;
            canvas.controlsAboveOverlay = true;
            
            //canvas.setBackgroundColor("#ff0000")
            setCanvas(canvas);
            setInches(logo.measure_inches);

            fabric.Image.fromURL(storage.root + (logo.processed_url || logo.logo_url_thumb || logo.logo_url) + "?t=" + new Date().getTime(), function(img) {
                console.log({img})
                img.type = "img";
                img.hasControls = false;
                img.selectable = false;

                console.log("img", img.width, img.height);
                console.log("devicePixelRatio", fabric.devicePixelRatio);

                canvas.setWidth(img.width)
                canvas.setHeight(img.height)
                canvas.add(img);

                img.sendToBack();

                setDimensions({width: img.width, height: img.height});
            }, {crossOrigin: 'anonymous'});
            

            for (const item of logo.items) {
                if (item.type == "ruler" || item.type == "measure") {
                    addRulerControl(canvas, item);
                }
                else if (item.type == "rect" && item.points) {
                    addRectControl(canvas, item);
                }
                else if (item.type == "polygon" && item.points) {
                    addPolygonControl(canvas, item);
                }
                else if (item.type == "shape" && item.points) {
                    const control = addShapeControl(canvas, item);
                    control.visible = false;
                }                
                else if (item.type == "svg" && item.points && item.path) {
                    addSvgControl(canvas, item);
                }
            }

            bringMeasureControlsToFront(canvas);

            return () => {
                canvas.dispose();
            }            
        }, 
        []
    );

    useEffect(
        () => {
            if (!canvas) return;

            canvas.on("mouse:down", (event) => {
                if (event.e.altKey === true) {
                    canvas.isDragging = true;
                    canvas.lastPosX = event.e.clientX;
                    canvas.lastPosY = event.e.clientY;
                }
                else if (mode == "rect") {
                    const control = getControlById(canvas, activeControl.id);
                    const pointer = canvas.getPointer(event);

                    // second click?
                    if (!control.creating) {
                        control.creating = true;
                        control.left = pointer.x;
                        control.top = pointer.y;    
                        control.visible = true;
                    }
                }
            });             
            canvas.on("mouse:up", (event) => {
                const pointer = canvas.getPointer(event);

                if (canvas.isDragging) {
                    // on mouse up we want to recalculate new interaction
                    // for all objects, so we call setViewportTransform
                    canvas.setViewportTransform(canvas.viewportTransform);
                    canvas.isDragging = false;
                }
                if (mode == "measure" || mode == "ruler") {
                    const control = getControlById(canvas, activeControl.id);

                    if (!control.dot1.visible) {
                        control.dot1.left = pointer.x;
                        control.dot1.top = pointer.y;
                        control.dot1.visible = true;
                        control.line1.visible = true;
                        control.line2.visible = true;
                        control.dot1.setCoords()

                        setActiveControl({...control});
                        
                        saveControl(control);                        
                    }
                    else if (!control.dot2.visible) {
                        control.dot2.left = pointer.x;
                        control.dot2.top = pointer.y;
                        control.dot2.visible = true;
                        control.line1.visible = true;
                        control.line2.visible = true;
                        control.dot2.setCoords()
                        control.line1.setCoords()
                        control.line2.setCoords()

                        positionMeasureControl(canvas, control);

                        setActiveControl({...control});

                        // ruler?
                        if (control.type == "ruler") {
                            setMode("");
                            updateControlText(canvas, control, calcRulerInches(canvas, control) + " in");                            
                        }

                        saveControl(control)
                    }

                    canvas.renderAll();
                }
                else if (mode == "polygon" || mode == "shape") {
                    const control = getControlById(canvas, activeControl.id);

                    if (!control) return;

                    if (!control.dots) control.dots = [];
                    if (!control.lines) control.lines = [];

                    console.log("mouse up, plygon", event.target) 
                    console.log(control)
                    console.log(control.dots)
                    if (event.target && control.dots.length > 0 && event.target.id == control.dots[0].id){
                        setMode(mode == "shape"  ? "shapes":"");
                        const polygon = mode=="shape" ? generateShapeControl(canvas, activeControl) : generatePolygonControl(canvas, activeControl);

                        if (mode=="shape") {
                            calcControlConsumption(canvas, polygon, (consumption, dimensions) => {
                                polygon.data.consumption = consumption;

                                updateItemConsumption.mutate({
                                    id: polygon.id,
                                    value: consumption
                                });

                                const points = [];

                                for (const point of polygon.points) {
                                    points.push(point.x);
                                    points.push(point.y);
                                }
                
                                const ratios = calcRatios(dimensions);

                                updateItem.mutate({
                                    id: polygon.id,
                                    points,  
                                    width_ratio: ratios.width,
                                    height_ratio: ratios.height,
                                    size: calcSize(dimensions),
                                    size_width: calcInches(dimensions.width),
                                    size_height: calcInches(dimensions.height),
                                })

                                enableSelections(canvas);
                                canvas.setActiveObject(polygon);
                                canvas.renderAll();                                
                            });
                        }
                        else {
                            saveControl(polygon);

                            enableSelections(canvas);
                            canvas.setActiveObject(polygon);
                            canvas.renderAll();                            
                        }
                    }
                    else {
                        const min = 99;
                        const max = 999999;                        
                        const random = Math.floor(Math.random() * (max - min + 1)) + min;

                        const dot = new fabric.Circle({
                            type: "polygonDot",
                            id: new Date().getTime() + random,
                            left: pointer.x,
                            top: pointer.y,
                            radius: 6,
                            fill: control.dots.length == 0 ? 'red':'white',
                            stroke: control.dots.length == 0 ? 'white':'black',
                            strokeWidth: 2,
                            originX: 'center',
                            originY: 'center',
                            selectable: false,
                            hasControls: false,
                        });
                        
                        const line = new fabric.Line([dot.left-1, dot.top, dot.left-1, dot.top], {
                            type: 'polygonLine',
                            fill: 'black',
                            stroke: 'black',
                            strokeWidth: 3,
                            strokeDashArray: [7, 7],
                            selectable: false,
                            hasControls: false,
                        });

                        control.dots.push(dot);
                        control.lines.push(line);

                        setActiveControl({...control});

                        canvas.add(line);
                        canvas.add(dot);

                        // make sure the first dot is always on top
                        control.dots[0].bringToFront();
                    }
                }
                else if (mode == "rect") {
                    const control = getControlById(canvas, activeControl.id);

                    // did they just click?
                    if (pointer.x != control.left && pointer.y != control.top) {
                        control.creating = false;
                        control.set({
                            'height'     : pointer.y - control.top,
                            'width'      : pointer.x - control.left,
                            'scaleX'     : 1,
                            'scaleY'     : 1
                        });              
                        
                        // did they draw it from right to left?
                        if (control.width < 0) {
                            console.log("rect draw backwards...")
                            control.set({
                                'left'  : control.left + control.width,
                                'width' : Math.abs(control.width),
                            });    
                        }

                        enableSelections(canvas);
                        setMode("")
                        canvas.setActiveObject(control);
                        canvas.renderAll();
                        saveControl(control);
                    }
                }
                else if (mode == "background") {
                    // const pathData = floodFillAreaToPath(canvas, event.pointer.x*fabric.devicePixelRatio, event.pointer.y*fabric.devicePixelRatio);
                    const pathData = floodFillAreaToPath(canvas, pointer.x, pointer.y);
                    const background = addBackgroundControl(canvas, pathData);   
                    setActiveControl(background);
                    canvas.renderAll();

                }
            }); 
            canvas.on("mouse:move", (options) => {
                if (canvas.isDragging) {
                    var e = options.e;
                    var vpt = canvas.viewportTransform;
                    vpt[4] += e.clientX - canvas.lastPosX;
                    vpt[5] += e.clientY - canvas.lastPosY;
                    canvas.requestRenderAll();
                    canvas.lastPosX = e.clientX;
                    canvas.lastPosY = e.clientY;
                }                
                else if ((mode == "measure" || mode == "ruler") && activeControl && activeControl.dot1.visible && !activeControl.dot2.visible) {
                    const pointer = canvas.getPointer(options.e);

                    activeControl.line1.set({
                        x1: activeControl.dot1.left-1,
                        y1: activeControl.dot1.top,
                        x2: pointer.x,
                        y2: pointer.y
                    });
                    activeControl.line2.set({
                        x1: activeControl.dot1.left-1,
                        y1: activeControl.dot1.top,
                        x2: pointer.x,
                        y2: pointer.y
                    });
                    activeControl.line1.setCoords()
                    activeControl.line2.setCoords()
                    canvas.renderAll();
                }   
                else if (mode == "polygon" || mode == "shape") {
                    const pointer = canvas.getPointer(options.e);

                    if (activeControl && activeControl.lines && activeControl.lines.length > 0) {
                        const firstDot = activeControl.dots[0];
                        const line = activeControl.lines[activeControl.lines.length-1];

                        line.set({
                            x2: pointer.x,
                            y2: pointer.y
                        });
                        line.setCoords()

                        const target = canvas.findTarget(options.e);

                        if (target && target.type == "polygonDot" && firstDot && target.id == firstDot.id) {
                            firstDot.scaleX  = 1.5;
                            firstDot.scaleY  = 1.5;
                            firstDot.opacity = .7;
                        }
                        else {
                            firstDot.scaleX  = 1;
                            firstDot.scaleY  = 1;
                            firstDot.opacity = 1;
                        }
    
                        canvas.renderAll(); 
                    }
                }
                else if (mode == "rect" && activeControl) {
                    const control = getControlById(canvas, activeControl.id);

                    if (control && control.creating) {
                        const pointer = canvas.getPointer(options.e);

                        control.set({
                            'height'     : pointer.y - control.top,
                            'width'      : pointer.x - control.left,
                            'scaleX'     : 1,
                            'scaleY'     : 1
                        });   
                        canvas.renderAll();                        
                    }
                }
                else if (mode == "paint" && paintOptions.cursor) {
                    const pointer = canvas.getPointer(options.e);
                    // console.log("pointer 1", canvas.getPointer(options.e))
                    // console.log("pointer 2", canvas.getPointer(options.e, true))
                    paintOptions.cursor.top = pointer.y - paintOptions.cursor.height/2;
                    paintOptions.cursor.left = pointer.x - paintOptions.cursor.width/2;    
                    canvas.bringToFront(paintOptions.cursor);
                    canvas.renderAll();      
                    
                    //console.log(pointer.x, pointer.y, paintOptions.cursor.left, paintOptions.cursor.top)
                }
            });             
            canvas.on('object:moving', (options => {
                const object = options.target;
            
                if (object.type == "measureDot") {
                    if (object.parent) {
                        positionMeasureControl(canvas, object.parent);
                        canvas.renderAll();
                    }
                }
            }));
            canvas.on('object:modified', (options => {
                const object = options.target;
                        
                console.log("object:modified", object);

                if (object.type == "measureDot") {
                    const control = getControlById(canvas, object.parent.id);

                    if (control) {
                        if (control.type == "ruler") {
                            updateControlText(canvas, control, calcRulerInches(canvas, control) + " in");
                            saveControl(control);
                        }
                        else if (control.type == "measure") {
                            updateRulerDistances(canvas);                        
                            saveItems(canvas);
                            saveControl(control);
                        }
                    }
                }
                else if (object.type == "rect") {
                    object.set({
                        'height'     : object.height * object.scaleY,
                        'width'      : object.width * object.scaleX,
                        'scaleX'     : 1,
                        'scaleY'     : 1
                    });

                    saveControl(object);
                }
                else if (object.type == "polygon" || object.type == "svg" || object.type == "shape") {
                    saveControl(object);
                }
            }));  

            canvas.on('selection:created', (options => {
                const selection = options.selected[0];
                console.log("selection", selection)
                setSelected(selection);
                if (selection.data)
                    setLetters(selection.data.text || "");
            }));                          
            canvas.on('selection:updated', (options => {
                const selection = options.selected[0];
                console.log("selection", selection)
                setSelected(selection);
                if (selection.data)
                    setLetters(selection.data.text || "");
            }));  
            canvas.on('selection:cleared', (options => {
                if (mode == "trace" || mode == "tracing")
                    canvas.setActiveObject(selected); // prevent                    
                else
                    setSelected(null);
            }));  
            canvas.on('path:created', ({path}) => {
                console.log("path created", path)

                path.type = "drawing";
                path.selectable = false;
                setActiveControl(path);
            });

            return () => {
                canvas.off("mouse:down");
                canvas.off("mouse:up");
                canvas.off("mouse:move");
                canvas.off("object:moving");
                canvas.off("object:modified");
                canvas.off("selection:created");
                canvas.off("selection:updated");
                canvas.off("selection:cleared");
                canvas.off("path:created");
            }            
        }
    );

    useEffect(
        () => {
            if (!canvas) return;

            for (let img of canvas.getObjects("img")) {
                img.visible = imageVisible;
            }

            try {
                canvas.renderAll();
            }
            catch(e) {}
        }, 
        [imageVisible]
    );

    useEffect(
        () => {
            console.log("activeControl changed", activeControl)
        }, 
        [activeControl]
    );    
    
    useEffect(
        () => {
            if (arrowPressUp || arrowPressDown || arrowPressLeft || arrowPressRight) {
                const selection = canvas.getActiveObject();

                if (selection) {
                    if (arrowPressUp)
                        selection.top = selection.top - 1;
                    if (arrowPressDown)
                        selection.top = selection.top + 1;
                    if (arrowPressLeft)
                        selection.left = selection.left - 1;
                    if (arrowPressRight)
                        selection.left = selection.left + 1;

                    if (selection.type == "measureDot") {
                        positionMeasureControl(canvas, selection.parent)                        
                    }

                    canvas.renderAll();

                    canvas.fire('object:modified', {target: selection});                    
                }
            }
        }, 
        [arrowPressUp, arrowPressDown, arrowPressLeft, arrowPressRight]
    ); 

    function onZoomIn() {
        canvas.setZoom(zoom + .1);
        canvas.renderAll();
        setZoom(zoom + .1);
    }
    function onZoomOut() {
        canvas.setZoom(zoom - .1);
        canvas.renderAll();
        setZoom(zoom - .1);
    }

    return (
      <>
        <Toolbar>
            {(mode == "" || (selected && (mode=="snapshot" || mode=="consumption"))) ?
                <>
                    {selected ? 
                        <ToolbarButtons onClose={() => {
                            canvas.discardActiveObject();
                            canvas.renderAll();
                        }}>                            
                            <ToolbarGroup caption="Order">
                                <ToolbarButton
                                    size="sm"
                                    image={bringFowardIcon}
                                    tooltip="Bring To Front"
                                    onClick={() => {
                                        console.log("bringToFront", selected.type)

                                        if (selected.type == "measureDot") {
                                            const control = getControlById(canvas, selected.parent.id);
                                            canvas.bringToFront(control.line1)
                                            canvas.bringToFront(control.line2)
                                            canvas.bringToFront(control.text)
                                            canvas.bringToFront(control.dot1)
                                            canvas.bringToFront(control.dot2)
                                        }
                                        else {
                                            canvas.bringToFront(selected)
                                        }
                                        canvas.renderAll();

                                        saveOrderings(canvas);
                                    }}
                                />
                                <ToolbarButton
                                    size="sm"                                    
                                    image={sendBackwardsIcon}
                                    tooltip="Send To Back"
                                    onClick={() => {
                                        console.log("sendToBack")
                                        canvas.sendToBack(selected);
                                        canvas.sendToBack(getImage(canvas));
                                        canvas.renderAll();

                                        saveOrderings(canvas);
                                    }}
                                />  
                            </ToolbarGroup>

                            {(selected.type == "measureDot" && selected.parent.type == "ruler") &&
                                <>
                                    <ToolbarButton
                                        caption="Straighten"
                                        icon={faRulerCombined}
                                        onClick={() => {
                                            const ruler = getControlById(canvas, selected.parent.id);

                                            console.log(selected == ruler.dot1)
                                            console.log(selected == ruler.dot2)

                                            const hDist = Math.abs(ruler.dot1.left - ruler.dot2.left);
                                            const vDist = Math.abs(ruler.dot1.top - ruler.dot2.top);

                                            console.log({hDist}, {vDist});

                                            if (selected == ruler.dot1) {
                                                if (hDist > vDist)
                                                    ruler.dot2.top = ruler.dot1.top;
                                                else 
                                                    ruler.dot2.left = ruler.dot1.left;
                                            }
                                            else {
                                                if (hDist > vDist)
                                                    ruler.dot1.top = ruler.dot2.top;
                                                else 
                                                    ruler.dot1.left = ruler.dot2.left;
                                            }

                                            ruler.dot1.setCoords();
                                            ruler.dot2.setCoords();

                                            //canvas.fire('object:modified', {target: selected});
                                            positionMeasureControl(canvas, ruler);
                                            updateControlText(canvas, ruler, calcRulerInches(canvas, ruler) + " in");
                                            canvas.renderAll();
                                            saveControl(ruler);
                                        }}
                                    />                                    
                                    <ToolbarButton
                                        caption="Remove"
                                        icon={faTrash}
                                        busy={deleteItem.isLoading}
                                        onClick={() => {
                                            const ruler = getControlById(canvas, selected.parent.id);

                                            deleteItem.mutate(ruler.id, {
                                                onSuccess: () => {
                                                    removeControl(canvas, ruler);
                                                    canvas.renderAll();
                                                    setSelected(null);                                            
                                                }
                                            });
                                        }}
                                    />
                                </>
                            }
                            {(selected.type == "rect" || selected.type == "polygon") &&
                            <>
                                <ToolbarButton
                                    caption="Trace Area"
                                    icon={faCertificate}
                                    busy={mode=="snapshot"}
                                    onClick={() => {
                                        if (selected.type == "rect") {
                                            const polygon = new fabric.Polygon([
                                                {x: selected.left+selected.strokeWidth, y:selected.top+selected.strokeWidth},
                                                {x: selected.left+selected.strokeWidth, y:selected.top+selected.height-selected.strokeWidth},
                                                {x: selected.left+selected.width, y:selected.top+selected.height-selected.strokeWidth},
                                                {x: selected.left+selected.width, y:selected.top+selected.strokeWidth},
                                            ], {
                                                type: "polygon"
                                            });

                                            canvas.clipPath = polygon;
                                        }
                                        else
                                            canvas.clipPath = selected;

                                        hideAllControls(canvas);
                                        selected.visible = true;
                                        selected.hasControls = false;
                                        selected.lockMovementX = true;
                                        selected.lockMovementY = true;
                                        setMode("snapshot");
                                        canvas.renderAll();

                                        const preHasBorders = selected.hasBorders;
                                        const preStrokeWidth = selected.strokeWidth;

                                        selected.hasBorders = false;                                        
                                        selected.strokeWidth = 0;
                                        canvas.renderAll();
                                        const image = snapshotArea(canvas, selected.left, selected.top, selected.width, selected.height);
                                        selected.hasBorders = preHasBorders;
                                        selected.strokeWidth = preStrokeWidth;
                                        canvas.renderAll();

                                        uploadImage("signs/uploadlogopart?signlogo_id=" + logo.id, image, null, null, 
                                            function(percent) {
                                                console.log(percent + " uploaded...");
                                            },
                                            function(response) {
                                                setSnapshot(response);
                                    
                                                if (DEBUG) {
                                                    var tempImage = document.createElement('img');

                                                    tempImage.id = "tempsnapshot";
                                                    tempImage.url = storage.root + response.url;

                                                    console.log(tempImage.url);
    
                                                    document.body.appendChild(tempImage)
                                                }

                                                setMode("trace");
                                            },
                                            function(error) {
                                                window.alert("Error uploading image file.");
                                                console.log("error", error)
                                            }
                                        );                                        
                                    }}
                                />    
                            </>
                            }  
                            {(selected.type == "svg") &&
                            <>
                                <ToolbarGroup caption="Type:">
                                    <select 
                                        className="bg-white border border-black"
                                        value={selected.data.path_type}
                                        onChange={(e) => {
                                            selected.data.path_type = e.target.value;

                                            // updateItemPathType.mutate({
                                            //     id: selected.id,
                                            //     value: e.target.value
                                            // });

                                            const ratios = calcRatios(selected);

                                            // need a full update since it could affect how the size is calculated
                                            updateItem.mutate({
                                                id: selected.id,
                                                path_type: e.target.value,
                                                points: [selected.left, selected.top, selected.width, selected.height],  
                                                text: selected.data ? selected.data.text : null,
                                                width_ratio: ratios.width,
                                                height_ratio: ratios.height,
                                                size: calcSize(selected),
                                                size_height: calcInches(selected.height),
                                                size_width: calcInches(selected.width),    
                                            });                                            
                                        }}
                                    >
                                        <option value="shape">Single Shape</option>
                                        <option value="shapes">Multiple Shapes</option>
                                        <option value="letters">Letters</option>
                                    </select>    
                                </ToolbarGroup>
                                {selected.data.path_type == "letters" && 
                                    <ToolbarGroup caption="Letters:">
                                        <input
                                            type="text" 
                                            className="bg-white border border-black w-[100px]"
                                            value={letters}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                selected.data.text = value;
                                                setLetters(value);
                                            }}
                                            onBlur={(e) => {                                                
                                                updateItemText.mutate({
                                                    id: selected.id,
                                                    value: e.target.value
                                                });
                                            }}
                                        />
                                    </ToolbarGroup>
                                }
                                {(selected.data.path_type == "letters" && stylesQuery.data) && 
                                    <ToolbarGroup caption="Style:">
                                        <select 
                                            className="bg-white border border-black"
                                            value={selected.data.style_id}
                                            onChange={(e) => {
                                                selected.data.style_id = e.target.value;

                                                updateItemPathStyle.mutate({
                                                    id: selected.id,
                                                    value: e.target.value
                                                });
                                            }}
                                        >
                                            <option value=""></option>
                                            
                                            {stylesQuery.data.map(style => (
                                                <option key={style.id} value={style.id}>{style.name}</option>
                                            ))}
                                        </select>    
                                    </ToolbarGroup>                                
                                }
                                {(selected.data.path_type == "shapes") && 
                                    <ToolbarButton
                                        caption="Manage Shapes"
                                        icon={faShapes}
                                        count={canvas.getObjects("shape").filter(s => s.data?.parent_id==selected.id).length}
                                        onClick={() => {
                                            setMode("shapes");
                                            hideAllControls(canvas);

                                            for (const shape of canvas.getObjects("shape").filter(s => s.data.parent_id == selected.id)) {
                                                shape.visible = true;
                                            }

                                            console.log(canvas.getObjects("shape"))
                                            const box = new fabric.Rect({
                                                type: "box",
                                                parent_id: selected.id,
                                                left: selected.left,
                                                top: selected.top,
                                                width: selected.width,
                                                height: selected.height,
                                                fill: "transparent",
                                                stroke: "#000000",
                                                strokeWidth: 2,
                                                hasBorders: true,
                                                hasControls: false,
                                                selectable: false,
                                                evented: false
                                            });

                                            canvas.discardActiveObject();
                                            canvas.add(box);
                                            canvas.renderAll();
                                        }}
                                    /> 
                                }
                                {selected.data.path_type == "shape" && 
                                    <ToolbarGroup icon={faLowVision} caption="Consumption:">
                                        <ToolbarButton
                                            size="sm"
                                            caption={selected.data.consumption ? (round(selected.data.consumption*100)+"%") : "?"}
                                            onClick={() => {
                                                setMode("consumption")

                                                calcControlConsumption(canvas, selected, (consumption) => {
                                                    selected.data.consumption = consumption;
                                                    updateItemConsumption.mutate({
                                                        id: selected.id,
                                                        value: consumption
                                                    })
                                                    setMode("");  
                                                });                                              
                                            }}
                                        />  
                                    </ToolbarGroup>                                  
                                }
                            </>                 
                            }  
                            {(selected.type == "rect" || selected.type == "polygon" || selected.type == "svg") &&
                                <ToolbarButton
                                    caption="Remove"
                                    icon={faTrash}
                                    busy={deleteItem.isLoading}
                                    onClick={() => {
                                        deleteItem.mutate(selected.id, {
                                            onSuccess: () => {
                                                canvas.discardActiveObject(selected);
                                                removeControl(canvas, selected)
                                                setSelected(null);                                            
                                            }
                                        });
                                    }}
                                />                     
                            }  
                        </ToolbarButtons>
                    :
                        <ToolbarButtons>
                            <ToolbarButton
                                caption="Edit Logo"
                                icon={faPencilAlt}
                                onClick={() => {                                    
                                    setMode("logo");
                                    hideAllControls(canvas);
                                    updateCursor(canvas);    
                                    canvas.setZoom(1);
                                    canvas.renderAll();
                                }}
                            />     

                            <ToolbarButton
                                caption="Measure"
                                iconRight={faRulerCombined}
                                iconRightClassname="text-red-500"
                                busy={addItem.isLoading && addItem.variables?.type=="measure"}
                                onClick={() => {
                                    let control = getMeasureControl(canvas);

                                    if (control) {
                                        setMode("measure");
                                        setActiveControl(control);
                                        enableMeasureControl(canvas);
                                        updateCursor(canvas);    
                                        canvas.renderAll();
                                    }   
                                    else {
                                        addItem.mutate({type:"measure"}, {
                                            onSuccess: (item) => {
                                                setMode("measure");
                                                setActiveControl(addRulerControl(canvas, item))
                                                enableMeasureControl(canvas);
                                                updateCursor(canvas);        
                                                canvas.renderAll();
                                            }
                                        });
                                    }
                                }}
                            />      
                            <ToolbarButton
                                caption="Add Ruler"
                                iconRight={faRuler}
                                disabled={!logo.measure_pixels || !logo.measure_inches}
                                busy={addItem.isLoading && addItem.variables?.type=="ruler"}
                                onClick={() => {
                                    addItem.mutate({type:"ruler"}, {
                                        onSuccess: (item) => {
                                            setActiveControl(addRulerControl(canvas, item))
                                            setMode("ruler");
                                            updateCursor(canvas);  
                                            canvas.renderAll();      
                                        }
                                    });
                                }}
                            />        

                            <ToolbarGroup caption="Add Group">
                                <ToolbarButton
                                    size="sm"
                                    iconRight={faVectorSquare}
                                    disabled={!logo.measure_pixels || !logo.measure_inches}
                                    busy={addItem.isLoading && addItem.variables?.type=="rect"}
                                    onClick={() => {
                                        addItem.mutate({type:"rect"}, {
                                            onSuccess: (item) => {
                                                setMode("rect")
                                                disableSelections(canvas);
                                                const rect = addRectControl(canvas, item);
                                                setActiveControl(rect);
                                                updateCursor(canvas);        
                                            }
                                        });
                                    }}
                                />                              
                                <ToolbarButton
                                    size="sm"
                                    iconRight={faDrawPolygon}
                                    disabled={!logo.measure_pixels || !logo.measure_inches}
                                    busy={addItem.isLoading && addItem.variables?.type=="polygon"}
                                    onClick={() => {
                                        addItem.mutate({type:"polygon"}, {
                                            onSuccess: (item) => {
                                                setMode("polygon")
                                                disableSelections(canvas);
                                                console.log("add polygon", item)
                                                const polygon = addPolygonControl(canvas, item);
                                                console.log("added polygon contgrol", polygon)
                                                setActiveControl(polygon);
                                                updateCursor(canvas);        
                                            }
                                        });                                    
                                    }}
                                />  
                            </ToolbarGroup>
                        </ToolbarButtons>
                    }
                </>
            : (mode == "logo" || mode == "saveshrink") ?
                <ToolbarButtons onClose={() => {
                    setMode("")
                    showAllControls(canvas);
                    updateCursor(canvas);    
                    canvas.renderAll();
                }}>

                    <ToolbarButton
                        caption={imageVisible?"Hide Logo":"Show Logo"}
                        icon={faImage}
                        disabled={mode != "logo"}
                        onClick={() => {
                            setImageVisible(prev => !prev);                             
                        }}
                    /> 
                    {(logo.processed_url || logoAltered) && 
                        <ToolbarButton
                            caption="Reset Logo"
                            icon={faPowerOff}
                            disabled={mode != "logo"}
                            onClick={() => {
                                replaceImage(canvas, logo.logo_url_thumb || logo.logo_url, () => canvas.renderAll());
                                restoreImage.mutate();                   
                            }}
                        />                     
                    }
                    <ToolbarGroup caption="Rotate">
                        <ToolbarButton
                            size="sm"
                            icon={faUndoAlt}
                            disabled={mode != "logo"}v
                            onClick={() => {
                                const image = getImage();
                                image.rotate(image.angle - 90);

                                const rect = image.getBoundingRect(true, true);

                                canvas.setWidth(rect.width)
                                canvas.setHeight(rect.height);

                                image.center();
                                canvas.renderAll();                
                            }}
                        />
                        <ToolbarButton
                            size="sm"
                            icon={faRedoAlt}
                            disabled={mode != "logo"}
                            onClick={() => {
                                const image = getImage();
                                image.rotate(image.angle + 90);

                                const rect = image.getBoundingRect(true, true);

                                canvas.setWidth(rect.width)
                                canvas.setHeight(rect.height);

                                image.center();
                                canvas.renderAll();                 
                            }}
                        />                            
                    </ToolbarGroup>
                    <ToolbarButton
                        caption="Shrink"
                        icon={faSquare}
                        iconClassname="text-white border-2 border-black"
                        busy={mode=="saveshrink"}
                        onClick={() => {
                            setMode("saveshrink");

                            // const ctx = canvas.getContext("2d");
                            // const image = ctx.getImageData(0,0,canvas.width,canvas.height);
                            const border = 10;
                            // ctx.putImageData(image, border, border, 0, 0, canvas.width-border*2, canvas.height-border*2);
                            // canvas.renderAll();
                            const img = getImage();
                            img.left = border;
                            img.top = border;
                            img.scaleToWidth(canvas.width-border*2);
                            img.scaleToHeight(canvas.height-border*2);
                            canvas.renderAll();

                            const image = snapshotArea(canvas, 0, 0, canvas.width, canvas.height, 1, "png");

                            uploadImage("signs/uploadprocessedlogo?signlogo_id=" + logo.id, image, null, null, 
                                function(percent) {
                                    console.log(percent + " uploaded...");
                                },
                                function(response) {
                                    replaceImage(canvas, response.url, () => {
                                        canvas.renderAll();
                                        setMode("logo");
                                    });
                                },
                                function(error) {
                                    window.alert("Error uploading processed image file.");
                                    console.log("error", error)    
                                    setMode("logo");
                                }
                            );                            
                        }}
                    />                    
                    <ToolbarButton
                        caption="Remove Background"
                        icon={faEraser}
                        disabled={mode != "logo"}
                        onClick={() => {
                            canvas.discardActiveObject();
                            hideAllControls(canvas);
                            canvas.renderAll();
                            setMode("background")                               
                        }}
                    />
                    <ToolbarButton
                        caption="Silhouette"
                        icon={faCertificate}
                        disabled={mode != "logo"}
                        onClick={() => {
                            canvas.discardActiveObject();
                            hideAllControls(canvas);
                            canvas.renderAll();
                            setMode("silhouette")                                 
                        }}
                    />      
                    <ToolbarButton
                        caption="Paint"
                        icon={faPaintBrush}
                        disabled={mode != "logo"}
                        onClick={() => {
                            canvas.isDrawingMode = true;   
                            canvas.freeDrawingCursor = "none";

                            canvas.freeDrawingBrush.width = calcPixelsFromInches(paintOptions.size);
                            canvas.freeDrawingBrush.color = paintOptions.color;                      

                            hideAllControls(canvas);

                            var cursor = new fabric.Circle({ 
                                type: "cursor",
                                left: 0, 
                                top: 0, 
                                radius: canvas.freeDrawingBrush.width / 2, 
                                fill: canvas.freeDrawingBrush.color, 
                                originX: 'left', 
                                originY: 'top',
                                visible: true,
                            })

                            canvas.add(cursor);

                            setPaintOptions(prev => ({...prev, cursor}));

                            canvas.renderAll();
                            setMode("paint")                                 
                        }}
                    />  
                </ToolbarButtons>   
            : ((mode == "measure" || mode == "ruler") && activeControl) ? 
                <ToolbarButtons onClose={() => {
                    setMode("")

                    if (activeControl.id > 0 && activeControl.type == "ruler") {
                        removeControl(canvas, activeControl);
                    }

                    disableMeasureControl(canvas);
                    setActiveControl(null);
                    updateCursor(canvas);     
                    
                    canvas.discardActiveObject();
                    canvas.renderAll();
                }}>
                    { !activeControl.dot1.visible ?
                        <>
                            <FontAwesomeIcon icon={faRuler} />
                            Select the <strong>first</strong> {mode} point on the image
                        </>
                    : !activeControl.dot2.visible ?
                        <>
                            <FontAwesomeIcon icon={faRuler} />
                            Select the <strong>second</strong> {mode} point on the image
                        </>
                    : mode == "measure" ?
                        <>
                            <ToolbarGroup caption="Measurement:">
                                <Input
                                    type="number"
                                    className="border w-[80px]"
                                    value={inches}
                                    onChange={(e,value) => {
                                        setInches(value);
                                        updateControlText(canvas, getMeasureControl(canvas), value + " in");
                                        canvas.renderAll();
                                    }}
                                />
                                <div>Inches</div>
                            </ToolbarGroup>

                            <ButtonMutate
                                caption="Save"
                                variant="primary"
                                size="sm"
                                disabled={!inches}
                                mutation={saveMeasurement}
                                onMutate={() => {
                                    const control = getMeasureControl(canvas);

                                    return {
                                        points: `${control.dot1.left}:${control.dot1.top},${control.dot2.left}:${control.dot2.top}`,
                                        distance: calcRulerDistance(control),
                                        inches
                                    }                                    
                                }}
                                onMutateSuccess={() => {
                                    canvas.discardActiveObject();

                                    setMode("");
                                    disableMeasureControl(canvas);
                                    updateRulerDistances(canvas);
                                    saveItems(canvas);
                                    updateCursor(canvas);

                                    canvas.renderAll();
                                }}
                            />
                        </>
                    :
                        null
                    }              
                </ToolbarButtons>
            : ((mode == "polygon" && activeControl) || (mode == "shape" && activeControl)) ?
                <ToolbarButtons onClose={() => {
                    setMode("")

                    console.log("cancel polygon")
                    console.log({activeControl});

                    if (activeControl) {
                        removeControl(canvas, activeControl);
                    }

                    setActiveControl(null);
                    updateCursor(canvas);     
                    
                    canvas.discardActiveObject();
                    canvas.renderAll();
                }}>
                    { (activeControl.points && activeControl.points.length == 0) ?
                        <>
                            <FontAwesomeIcon icon={faDrawPolygon} />
                            Select the <strong>first</strong> {mode} point on the image
                        </>
                    : 
                        <>
                            <FontAwesomeIcon icon={faDrawPolygon} />
                            Select the <strong>next</strong> {mode} point on the image
                        </>   
                    }                                      
                </ToolbarButtons>
            : (mode == "rect") ?
                <ToolbarButtons>
                    <FontAwesomeIcon icon={faVectorSquare} />
                    Click and drag to draw the rectangle
                </ToolbarButtons>
            : (mode == "shapes") ?
                <ToolbarButtons onClose={() => {
                    setMode("")

                    for (const box of canvas.getObjects("box")) {
                        canvas.remove(box);
                    }
                    if (activeControl) {
                        removeControl(canvas, activeControl);
                    }

                    setActiveControl(null);
                    showAllControls(canvas);
                    updateCursor(canvas);     
                    
                    canvas.discardActiveObject();
                    canvas.renderAll();                    
                }}>
                    <ToolbarButton
                        caption="Add Shape"
                        icon={faPlusCircle}
                        busy={addItem.isLoading && addItem.variables?.type=="shape"}
                        onClick={() => {
                            const box = canvas.getObjects("box")[0];

                            addItem.mutate({type:"shape", parent_id: box.parent_id}, {
                                onSuccess: (item) => {
                                    const shape = addShapeControl(canvas, item);

                                    shape.type = "tempshape";
                                    
                                    setMode("shape")
                                    disableSelections(canvas);
                                    setActiveControl(shape);
                                    updateCursor(canvas);
                                    canvas.renderAll();

                                    console.log(canvas.getObjects("shape"))                                    
                                }
                            });
                        }}
                    />

                    {selected && 
                        <>
                            <ToolbarGroup icon={faLowVision} caption="Consumption:">
                                <ToolbarButton
                                    size="sm"
                                    caption={selected.data.consumption ? (round(selected.data.consumption*100)+"%") : "?"}
                                    onClick={() => {
                                        calcControlConsumption(canvas, selected, (consumption) => {
                                            selected.data.consumption = consumption;
                                            updateItemConsumption.mutate({
                                                id: selected.id,
                                                value: consumption
                                            })
                                        });
                                    }}
                                />  
                            </ToolbarGroup>
                            <ToolbarButton
                                caption="Remove"
                                icon={faTrash}
                                busy={deleteItem.isLoading}
                                onClick={() => {
                                    deleteItem.mutate(selected.id, {
                                        onSuccess: () => {
                                            removeControl(canvas, selected);
                                            canvas.discardActiveObject();
                                            canvas.renderAll();
                                            setSelected(null);                                            
                                        }
                                    });
                                }}
                            />                                
                        </>
                    }
                </ToolbarButtons>
            : (mode == "trace" || mode == "tracing") ?
                <ToolbarButtons onClose={() => {
                    showAllControls(canvas);
                    canvas.clipPath = null;
                    selected.hasControls = true;                            
                    selected.lockMovementX = false;
                    selected.lockMovementY = false;
                    //canvas.discardActiveObject();
                    setSnapshot(null);
                    setMode("");

                    updateCursor(canvas);     
                    canvas.renderAll();
                }}>
                    <ToolbarGroup caption="Color Threshold:">
                        <input 
                            type="range"
                            min={-1}
                            max={255} 
                            step={1}
                            value={traceOptions.threshold} 
                            onChange={e => {
                                const value = parseInt(e.target.value);
                                setTraceOptions(prev => ({...prev, threshold: value}))
                            }}
                        />       
                    </ToolbarGroup>
                    <ToolbarGroup caption="Speckles:">
                        <input 
                            type="range"
                            min={1}
                            max={10} 
                            step={1}
                            value={traceOptions.turdSize} 
                            onChange={e => {
                                const value = parseInt(e.target.value);
                                setTraceOptions(prev => ({...prev, turdSize: value}))
                            }}
                        />       
                    </ToolbarGroup>                        
                    
                    <ToolbarButton
                        caption="Trace"
                        icon={faSearchPlus}
                        busy={mode == "tracing"}
                        onClick={() => {
                            setMode("tracing");
                            // addSpinnerToControl(canvas, selected);

                            const trace = new potrace.Potrace();

                            // You can also pass configuration object to the constructor
                            trace.setParameters({
                                ...traceOptions,
                                color: '#ff0000'
                            });
                            
                            trace.loadImage(storage.root + snapshot.url + "?v=" + new Date().getTime(), function(err) {
                                if (err) {
                                    window.alert("Error tracing image");
                                    setMode("trace")
                                    // removeSpinnerControl(canvas);
                                    throw err
                                };
                            
                                const svg = trace.getSVG();
                    
                                const svgData = parseSync(svg);
                                const paths = [];

                                for (const path of svgData.children.filter(c => c.name=="path")) {
                                    paths.push(path.attributes.d);
                                }

                                setSnapshot(prev => {
                                    return {
                                        ...prev,
                                        pathData: paths.join(" "),
                                        width: svgData.attributes.width,
                                        height: svgData.attributes.height,
                                        svg: "data:image/svg+xml;base64,"+btoa(svg)
                                    }
                                });

                                setMode("trace");

                                canvas.renderAll();
                            });                                                          
                        }}
                    />
                </ToolbarButtons>  
            : (mode == "background" || mode == "savebackground") ?
                <ToolbarButtons onClose={() => {
                    for (const obj of canvas.getObjects("background")) {
                        canvas.remove(obj);
                    }
                    getImage(canvas).clipPath = null;
                    // showAllControls(canvas);
                    setActiveControl(null);
                    canvas.renderAll();

                    setMode("logo");
                }}>
                    <ToolbarGroup caption="Threshold:">
                        <input 
                            type="range"
                            min={0}
                            max={25} 
                            step={1}
                            value={backgroundOptions.threshold} 
                            onChange={e => {
                                const value = parseInt(e.target.value);
                                setBackgroundOptions(prev => ({...prev, threshold: value}))
                            }}
                        />       
                    </ToolbarGroup>
                    <ToolbarGroup caption="Blur">
                        <input 
                            type="range"
                            min={0}
                            max={25} 
                            step={1}
                            value={backgroundOptions.blurRadius} 
                            onChange={e => {
                                const value = parseInt(e.target.value);
                                setBackgroundOptions(prev => ({...prev, blurRadius: value}))
                            }}
                        />       
                    </ToolbarGroup>    
                    <ToolbarButton
                        icon={faMagic}
                        caption="Auto Select"
                        disabled={mode=="savebackground"}
                        onClick={() => {
                            for (const obj of canvas.getObjects("background")) {
                                canvas.remove(obj);
                            }
                            canvas.renderAll();

                            const pathData = floodFillAreaToPath(canvas, 1, 1);
                            const background = addBackgroundControl(canvas, pathData);   
                            setActiveControl(background);                             
                            canvas.renderAll();                          
                        }}
                    />     
                    {(activeControl && activeControl.type=="background") &&
                        <>
                            <ToolbarButton
                                icon={faTrash}
                                caption="Reset"
                                disabled={mode=="savebackground"}
                                onClick={() => {
                                    for (const obj of canvas.getObjects("background")) {
                                        canvas.remove(obj);
                                    }
                                    getImage(canvas).clipPath = null;
                                    canvas.renderAll();
                                }}
                            />
                            <ToolbarButton
                                icon={faEraser}
                                caption="Remove Background"
                                busy={mode=="savebackground"}
                                onClick={() => {
                                    setMode("savebackground");

                                    var group = new fabric.Group();

                                    for (const obj of canvas.getObjects("background")) {
                                        obj.opacity = 1;
                                        group.addWithUpdate(obj);
                                        canvas.remove(obj);
                                    }

                                    group.inverted = true;
                                    group.absolutePositioned = true;
                                    
                                    getImage(canvas).clipPath = group
                                    canvas.renderAll();

                                    const image = snapshotArea(canvas, 0, 0, canvas.width, canvas.height, 1, "png");

                                    uploadImage("signs/uploadprocessedlogo?signlogo_id=" + logo.id, image, null, null, 
                                        function(percent) {
                                            console.log(percent + " uploaded...");
                                        },
                                        function(response) {
                                            replaceImage(canvas, response.url, () => {
                                                // showAllControls(canvas);
                                                setActiveControl(null);
                                                canvas.renderAll();
                                                setMode("logo");
                                            });
                                        },
                                        function(error) {
                                            window.alert("Error uploading processed image file.");
                                            console.log("error", error)

                                            setMode("background");
                                        }
                                    );                                         

                                }}
                            />

                        </>
                    }
                </ToolbarButtons>
            : (mode == "silhouette" || mode == "savesilhouette") ?
                <ToolbarButtons onClose={() => {
                    for (const obj of canvas.getObjects("silhouette")) {
                        canvas.remove(obj);
                    }
                    canvas.renderAll();

                    setSilhouetteOptions(prev => ({...prev, stroke: 0}));                            
                    // showAllControls(canvas);
                    setActiveControl(null);
                    setMode("logo");
                }}>
                    <ToolbarGroup caption="Threshold:">
                        <input 
                            type="range"
                            min={0}
                            max={25} 
                            step={1}
                            value={silhouetteOptions.threshold} 
                            onChange={e => {
                                const value = parseInt(e.target.value);
                                setSilhouetteOptions(prev => ({...prev, threshold: value}))
                            }}
                        />       
                    </ToolbarGroup>  
                    <ToolbarButton
                        icon={faMagic}
                        caption="Auto Select"
                        disabled={mode=="savesilhouette"}
                        onClick={() => {
                            for (const obj of canvas.getObjects("silhouette")) {
                                canvas.remove(obj);
                            }
                            canvas.renderAll();

                            const pathData = floodFillAreaToInsidePath(canvas, 1, 1);
                            console.log({pathData});
                            const silhouette = addSilhouetteControl(canvas, pathData, silhouetteOptions.stroke);   
                            setActiveControl(silhouette);                             
                            canvas.renderAll();                                                            
                        }}
                    />                             
                    {(activeControl && activeControl.type=="silhouette") &&
                        <>
                            <ToolbarGroup caption="Expand:">
                                <input 
                                    type="range"
                                    min={0}
                                    max={25} 
                                    step={1}
                                    value={silhouetteOptions.stroke} 
                                    disabled={!activeControl}
                                    onChange={e => {
                                        const value = parseInt(e.target.value);
                                        setSilhouetteOptions(prev => ({...prev, stroke: value}));

                                        if (activeControl) {
                                            activeControl.strokeWidth = value;
                                            activeControl.left = activeControl.origLeft - (value/2);
                                            activeControl.top = activeControl.origTop - (value/2);
                                            canvas.renderAll();
                                        }
                                    }}
                                />       
                            </ToolbarGroup>                              
                            <ToolbarButton
                                icon={faTrash}
                                caption="Reset"
                                disabled={mode=="savesilhouette"}
                                onClick={() => {
                                    for (const obj of canvas.getObjects("silhouette")) {
                                        canvas.remove(obj);
                                    }
                                    canvas.renderAll();
                                    setActiveControl(null);
                                    setSilhouetteOptions(prev => ({...prev, stroke: 0}));
                                }}
                            />
                            <ToolbarButton
                                icon={faCertificate}
                                caption="Save"
                                busy={mode=="savesilhouette"}
                                onClick={() => {
                                    setMode("savesilhouette");

                                    const image = snapshotArea(canvas, 0, 0, canvas.width, canvas.height, 1, "png");

                                    uploadImage("signs/uploadprocessedlogo?signlogo_id=" + logo.id, image, null, null, 
                                        function(percent) {
                                            console.log(percent + " uploaded...");
                                        },
                                        function(response) {
                                            replaceImage(canvas, response.url, () => {
                                                for (const obj of canvas.getObjects("silhouette")) {
                                                    canvas.remove(obj);
                                                }
                                                                                                    
                                                // showAllControls(canvas);
                                                setActiveControl(null);
                                                setSilhouetteOptions(prev => ({...prev, stroke: 0}));
                                                canvas.renderAll();
                                                setMode("logo");
                                            });
                                        },
                                        function(error) {
                                            window.alert("Error uploading processed image file.");
                                            console.log("error", error)

                                            setMode("silhouette");
                                        }
                                    );

                                }}
                            />
                        </>
                    }
                </ToolbarButtons>
            : (mode == "paint" || mode == "savepaint") ?
                <ToolbarButtons className="!items-stretch" onClose={() => {
                    if (paintOptions.cursor) {
                        canvas.remove(paintOptions.cursor)
                        setPaintOptions(prev => ({...prev, cursor: null}));
                    }
                    for (const obj of canvas.getObjects("drawing")) {
                        canvas.remove(obj);
                    }

                    canvas.isDrawingMode = false;
                    canvas.renderAll();
                    setActiveControl(null);
                    setMode("logo");
                }}>
                    <ToolbarGroup caption="Size:">
                        <select 
                            className="bg-white border border-black"
                            value={paintOptions.size}
                            onChange={(e) => {
                                const value = parseInt(e.target.value);
                                const pixels = calcPixelsFromInches(value);

                                console.log(value, pixels);

                                canvas.freeDrawingBrush.width = pixels;

                                paintOptions.cursor.set({
                                    radius: pixels/2
                                });
                                canvas.renderAll();

                                setPaintOptions(prev => ({...prev, size: value}));  
                            }}
                        >
                            <option value="1">1"</option>
                            <option value="2">2"</option>
                            <option value="3">3"</option>
                            <option value="4">4"</option>
                            <option value="5">5"</option>
                            <option value="6">6"</option>
                            <option value="7">7"</option>
                            <option value="8">8"</option>
                            <option value="9">9"</option>
                            <option value="10">10"</option>
                        </select>                         
                        {/* <input 
                            type="range"
                            min={1}
                            max={25} 
                            step={1}
                            value={paintOptions.size} 
                            onChange={e => {
                                const value = parseInt(e.target.value);
                                canvas.freeDrawingBrush.width = value;

                                paintOptions.cursor.set({
                                    radius: value/2
                                });
                                canvas.renderAll();

                                setPaintOptions(prev => ({...prev, size: value}));                                
                            }}
                        />        */}
                    </ToolbarGroup>  
                    <ToolbarButton
                        icon={faSquare}
                        iconClassname="text-white"
                        caption="White"
                        inactive={paintOptions.color == "#ffffff"}
                        onClick={() => {
                            canvas.freeDrawingBrush.color = "#ffffff";

                            paintOptions.cursor.set({
                                fill: "#efefef"
                            });
                            canvas.renderAll();

                            setPaintOptions(prev => ({...prev, color: "#ffffff"}))
                        }}
                    />
                    <ToolbarButton
                        icon={faSquare}
                        iconClassname="text-black"
                        caption="Black"
                        inactive={paintOptions.color == "#000000"}
                        onClick={() => {
                            canvas.freeDrawingBrush.color = "#000000";

                            paintOptions.cursor.set({
                                fill: "#000000"
                            });
                            canvas.renderAll();

                            setPaintOptions(prev => ({...prev, color: "#000000"}))
                        }}
                    />
                    {/* <ToolbarButton
                        icon={faSquare}
                        iconClassname="text-red-500"
                        caption="Red"
                        inactive={paintOptions.color == "#FF0000"}
                        onClick={() => {
                            canvas.freeDrawingBrush.color = "#FF0000";

                            paintOptions.cursor.set({
                                fill: "#FF0000"
                            });
                            canvas.renderAll();

                            setPaintOptions(prev => ({...prev, color: "#FF0000"}))
                        }}
                    />                     */}
                    {activeControl && 
                        <ToolbarButton
                            icon={faUndoAlt}
                            caption="Undo"
                            disabled={mode=="savepaint"}
                            onClick={() => {
                                canvas.remove(activeControl);
                                canvas.renderAll();
                                setActiveControl(null);
                            }}
                        />  
                    }    
                    {(canvas.getObjects("drawing").length > 0) &&
                        <ToolbarButton
                            icon={faSave}
                            caption="Save"
                            busy={mode=="savepaint"}
                            onClick={() => {
                                setMode("savepaint");

                                if (paintOptions.cursor) {
                                    paintOptions.cursor.visible = false;
                                    canvas.renderAll();
                                }

                                const image = snapshotArea(canvas, 0, 0, canvas.width, canvas.height, 1, "png");

                                uploadImage("signs/uploadprocessedlogo?signlogo_id=" + logo.id, image, null, null, 
                                    function(percent) {
                                        console.log(percent + " uploaded...");
                                    },
                                    function(response) {
                                        replaceImage(canvas, response.url, () => {
                                            for (const obj of canvas.getObjects("drawing")) {
                                                canvas.remove(obj);
                                            }

                                            if (paintOptions.cursor) {
                                                canvas.remove(paintOptions.cursor)
                                                setPaintOptions(prev => ({...prev, cursor: null}));
                                            }
                                                                                                                
                                            setActiveControl(null);
                                            canvas.isDrawingMode = false;
                                            canvas.renderAll();
                                            setMode("logo");
                                        });
                                    },
                                    function(error) {
                                        window.alert("Error uploading processed image file.");
                                        console.log("error", error)

                                        if (paintOptions.cursor) {
                                            paintOptions.cursor.visible = true;
                                        }        
                                    }
                                );
                            }}
                        />                    
                    }
                </ToolbarButtons>                        
            :
                null
            }
        </Toolbar>

        <div
            onClick={(e) => {
                if (e.target && e.target.tagName != "CANVAS") {
                    canvas.discardActiveObject();
                    canvas.renderAll();                    
                }
            }}
        >
            <AutoHeightAndWidthPanel 
                voffset={40} 
                hoffset={60} 
                className="border !border-dashed border-gray-400"
                style={{
                    backgroundImage: `url(${checkeredImage})`
                }}
            >
                <canvas ref={canvasRef} className="!border-2 !border-black" />

                <div className="absolute right-20 bottom-14 flex items-center gap-2">
                    {(zoom != 1) && 
                        <div className="py-1 px-2 text-sm rounded bg-white/80">
                            Hold Shift key to drag and pan around the zoomed image
                        </div>
                    }
                    <button className="bg-purple-200 rounded-sm py-1 px-2 hover:bg-purple-300" onClick={onZoomIn} disabled={mode == "logo"}>
                        <FontAwesomeIcon icon={faSearchPlus} size="2x" />
                    </button>
                    <button className="bg-purple-200 rounded-sm py-1 px-2 hover:bg-purple-300" onClick={onZoomOut} disabled={mode == "logo"}>
                        <FontAwesomeIcon icon={faSearchMinus} size="2x" />
                    </button>
                </div>
            </AutoHeightAndWidthPanel>
        </div>

        <div className="flex justify-between gap-2 text-sm bg-white">
            <div className="flex gap-1">
                <div className="bg-gray-200 py-1 px-2">
                    Dimensions: <strong>{dimensions.height}</strong> x <strong>{dimensions.width}</strong>
                </div>
                {(selected && (selected.type == "rect" || selected.type == "polygon" || selected.type == "svg" || selected.type == "shape")) && 
                    <>
                        <div className="bg-gray-200 py-1 px-2">
                            Selection: <strong>{round(selected.height)}</strong> x <strong>{round(selected.width)}</strong>
                        </div>
                        <div className="bg-gray-200 py-1 px-2">
                            {(selected.data && selected.data.size_height && selected.data.size_width) ?
                                <><strong>{selected.data.size_height}</strong>in x <strong>{selected.data.size_width}</strong>in</>
                            :
                                <><strong>{calcInches(selected.height)}</strong>in x <strong>{calcInches(selected.width)}</strong>in</>
                            }
                        </div>
                        <div className="bg-gray-200 py-1 px-2">
                            Area:&nbsp;
                            <strong>{calcAreaInInches(selected)}</strong>sq in,&nbsp;
                            <strong>{calcAreaInFeet(selected)}</strong>sq ft
                        </div>
                        <div className="bg-purple-200 py-1 px-2">
                            Size:&nbsp;
                            {(selected.data && selected.data.size && false) ? 
                                <strong>{selected.data.size}</strong>
                            :
                                <strong>{calcSize(selected)}</strong>
                            }
                        </div>

                        <div className="bg-gray-200 py-1 px-2">
                            Ratios:&nbsp;
                            {(selected.data && selected.data.height_ratio && selected.data.width_ratio && false) ? 
                                <><strong>{selected.data.height_ratio}h, {selected.data.width_ratio}w</strong></>
                            :
                                <><strong>{calcRatios(selected).height}h, {calcRatios(selected).width}w</strong></>
                            }
                        </div>

                        
                    </>
                }
            </div>
            {(selected && selected.type == "rect") && 
                <div className="flex gap-1">
                    <div className="bg-gray-200 py-1 px-2">
                        
                    </div>
                </div>
            }
        </div>   

        {(snapshot && snapshot.svg) &&
            <div
                className="fixed bottom-[55px] right-[90px] border-black border-2 rounded shadow-md"
                style={{
                    backgroundImage: `url(${checkeredImage})`
                }}
            >
                <img 
                    src={snapshot.svg}
                    className="object-contain max-h-[300px] min-h-[200px] max-w-[400px] min-w-[200px] border"
                />

                <button 
                    className="absolute top-1 right-1" 
                    disabled={addItem.isLoading && addItem.variables?.type=="ruler"}
                    onClick={() => {
                        setSnapshot(prev => ({...prev, svg:null}));
                    }}
                >
                    <FontAwesomeIcon icon={faTimesCircle} />
                </button>
                <button 
                    className="absolute bottom-1 left-1 bg-purple-400 text-white rounded border border-black py-1 px-2 text-sm" 
                    disabled={addItem.isLoading && addItem.variables?.type=="svg"}
                    onClick={() => {
                        addItem.mutate({
                            type: "svg", 
                            points: [selected.left,selected.top,snapshot.width,snapshot.height], 
                            path: snapshot.pathData, 
                            path_type: "shape"
                        }, {
                            onSuccess: (item) => {
                                const control = addSvgControl(canvas, item);

                                control.left = control.left + ((selected.width-control.width)/2);
                                control.top = control.top + ((selected.height-control.height)/2);
                                canvas.fire('object:modified', {target: control}); 

                                deleteItem.mutate(selected.id);

                                canvas.clipPath = null;
                                canvas.remove(selected);

                                calcControlConsumption(canvas, control, (consumption) => {
                                    control.data.consumption = consumption;
                                    control.data.path_type = "shape"; //default

                                    updateItemConsumption.mutate({
                                        id: control.id,
                                        value: consumption
                                    })

                                    showAllControls(canvas);
                                    bringMeasureControlsToFront(canvas);
                                    setSnapshot(null);
                                    setMode("");
                                    canvas.setActiveObject(control);
                                    updateCursor(canvas);   
                                    canvas.renderAll();
                                });                                
                            }
                        });
                    }}
                >
                    <FontAwesomeIcon icon={addItem.isLoading && addItem.variables?.type=="svg" ? faSpinner:faCheck} spin={addItem.isLoading && addItem.variables?.type=="svg"} />
                    Accept Trace
                </button>                
            </div>
        }
      </>     
    )
}

function SignCanvas({logo, query}) {
    const [items, setItems] = useState([]);
    const [uploading, setUploading] = useState(false);

    const [user, setUser] = useState(null);
    const [selectUser, setSelectUser] = useState(false);
    const [faceArt, setFaceArt] = useState({});

    const productsQuery = useGetProducts();
    const products = productsQuery.data;
    const complexityQuery = useGetCustomShapesForStyle(0);

    const createSign = useCreateSignLogoSign(logo.id);

    useEffect(
        () => {
            query.refetch();
        }, 
        []
    );
    useEffect(
        () => {
            setItems(logo.items.filter(i => i.type=="svg" && i.points));
        }, 
        [logo]
    );

    return (
      <>
            {(query.isFetching || productsQuery.isLoading) ?
                <Loading />
            :
            <div className="space-y-1">
                {items.map(item => (
                    <div key={item.id} className="grid grid-cols-[260px,1fr] gap-2">
                        <div className="border rounded p-1">
                            <ShapePreview
                                path={item.path}
                                width={250}
                                height={125}
                            />

                            {(item.faceart_url || faceArt[item.id]) && 
                                <img
                                    src={storage.root + (faceArt[item.id] || item.faceart_url)}
                                    className="max-w-[100px] max-h-[100px]"
                                />
                            }
                            {(item.path_type == "shape" || item.path_type == "shapes") &&
                                <div className="mt-2">
                                    <button
                                        className="border rounded py-1 px-2 text-sm"
                                        disabled={uploading}
                                        onClick={() => {
                                            setUploading(true);

                                            const [x,y,w,h] = item.points;
                                            const canvas = document.createElement('canvas');
                                            const img = new Image();
                                            img.crossOrigin = "anonymous";
                                            img.onload = function () {
                                                canvas.width = this.width;
                                                canvas.height = this.height;
                                
                                                const ctx = canvas.getContext("2d")
                                                ctx.drawImage(img, 0, 0, this.width, this.height)

                                                const imageData = ctx.getImageData(x, y, w, h);

                                                ctx.clearRect(0, 0, this.width, this.height);

                                                canvas.width = w;
                                                canvas.height = h;

                                                ctx.putImageData(imageData, 0, 0);

                                                document.body.appendChild(canvas);

                                                const data = canvas.toDataURL("image/jpeg", .8);
                                                                      
                                                uploadImage("signs/uploadlogofaceart?signlogo_id=" + logo.id + "&signlogoitem_id=" + item.id, data, null, null, 
                                                    function(percent) {
                                                        console.log(percent + " uploaded...");
                                                    },
                                                    function(response) {
                                                        setFaceArt({
                                                            ...faceArt,
                                                            [item.id]: response.url
                                                        });
                                                    },
                                                    function(error) {
                                                        window.alert("Error uploading file.");
                                                        console.log("error", error)
                                                    },
                                                    function() {
                                                        setUploading(false);
                                                    }
                                                );                                                
                                            }
                                            img.src = storage.root + (logo.logo_url_thumb || logo.logo_url);
                                        }}
                                    >
                                        {uploading && 
                                            <FontAwesomeIcon icon={faSpinner} spin={true} className="mr-1" />
                                        }
                                        Capture Face Art
                                    </button>
                                </div>
                            }
                        </div>
                        <div>
                            <FieldSelect
                                prepend="Product"
                                prependClassName="w-[100px]"
                                value={item.product_id}
                                items={
                                    item.path_type == "letters" ?
                                        products.filter(p => p.builder_type=="letters")
                                    :
                                        products.filter(p => p.builder_type=="shapes" || p.builder_type=="cabinet")
                                }
                                labelField="title"
                                required
                                onChange={(e, value) => {
                                    setItems(produce(draft => {
                                        const idx = draft.findIndex(i => i.id == item.id);

                                        draft[idx].product_id = value;
                                        draft[idx].product = products.find(p => p.id == value);
                                    }))
                                }}
                            />
                            <div className="space-y-[1px] mt-1">
                                <div className="text-sm flex items-center gap-2">
                                    <FieldLabel
                                        prepend="Size:"
                                        prependClassName="text-sm w-[100px]"
                                        value={`${item.size} (${item.size_height} x ${item.size_width})`}
                                    />

                                    {(item.product && item.path_type != "shapes") && 
                                        <div className="flex items-center gap-1">
                                            {(item.product.min_size && item.size < item.product.min_size) && 
                                                <Badge variant="danger" size="sm" value={`Size is under product minimum of ${item.product.min_size}`} />
                                            }
                                            {(item.product.max_size && item.size > Math.max(item.product.max_size, item.product.max_size_admin)) && 
                                                <Badge variant="danger" size="sm" value={`Size is over product max of ${Math.max(item.product.max_size, item.product.max_size_admin)}`} />
                                            }
                                            {(item.path_type == "shape" && item.product.material_min && (item.size_height < item.product.material_min || item.size_width < item.product.material_min)) && 
                                                <Badge variant="danger" size="sm" value={`Dimension is under the product material minimum of ${item.product.material_min}`} />
                                            }
                                            {(item.path_type == "shape" && item.product.material_max_1 && (item.size_height > item.product.material_max_1 || item.size_width > item.product.material_max_1)) && 
                                                <Badge variant="danger" size="sm" value={`Dimension is over the product material max 1 of ${item.product.material_max_1}`} />
                                            }
                                            {(item.path_type == "shape" && item.product.material_max_2 && (item.size_height > item.product.material_max_2 && item.size_width > item.product.material_max_2)) && 
                                                <Badge variant="danger" size="sm" value={`Dimension is over the product material max 2 of ${item.product.material_max_2}`} />
                                            }                                        
                                        </div>
                                    }
                                </div>
                                {item.path_type == "shape" && 
                                    <>
                                        <div className="flex">
                                            <FieldLabel
                                                prepend="Consumption:"
                                                prependClassName="text-sm w-[100px]"
                                                className="text-sm"
                                                value={Math.round(((item.consumption || 1) * 100)) + "%"}
                                            />
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <FieldSelect
                                                prepend="Complexity:"
                                                prependClassName="text-sm w-[100px]"
                                                className="text-sm"
                                                itemsQuery={complexityQuery}
                                                idField="pricing_modifier"
                                                onLabel={(item) => `${item.name} (${item.pricing_modifier})`}
                                                value={item.complexity || 1}
                                                onChange={(e, value) => {
                                                    setItems(produce(draft => {
                                                        const idx = draft.findIndex(i => i.id == item.id);
                                                        draft[idx].complexity = value;
                                                    }))
                                                }}                                                
                                            />
                                            {item.complexity > 1 && 
                                                <div className="text-gray-500">
                                                    {complexityQuery.data?.find(d => d.pricing_modifier == item.complexity)?.description}
                                                </div>
                                            }
                                        </div>     
                                        <div className="flex">                         
                                            <FieldNumber
                                                prepend="Faces:"
                                                prependClassName="text-sm w-[100px]"
                                                name="faces"
                                                className="px-1 py-0"
                                                value={item.faces || 1}
                                                onChange={(e, value) => {
                                                    setItems(produce(draft => {
                                                        const idx = draft.findIndex(i => i.id == item.id);
                                                        draft[idx].faces = value;
                                                    }))
                                                }}
                                            />

                                            <ZoomableImage
                                                url={facesInfoImage}
                                                maxHeight="25px"
                                                maxWidth="50px"
                                            />
                                        </div>
                                        <div className="flex">                                        
                                            <FieldNumber
                                                prepend="Sides:"
                                                prependClassName="text-sm w-[100px]"
                                                name="sides"
                                                className="px-1 py-0"
                                                value={item.sides || 1}
                                                onChange={(e, value) => {
                                                    setItems(produce(draft => {
                                                        const idx = draft.findIndex(i => i.id == item.id);
                                                        draft[idx].sides = value;
                                                    }))
                                                }}
                                            />

                                            <ZoomableImage
                                                url={sidesInfoImage}
                                                maxHeight="25px"
                                                maxWidth="50px"
                                            />                                            
                                        </div>
                                    </>
                                }
                                {item.path_type == "shapes" && 
                                    <>
                                        {logo.items.filter(i => i.parent_id == item.id && i.points != null).map((shape,index) => (
                                            <div key={shape.id} className="flex items-center gap-1 text-xs">
                                                <FieldLabel
                                                    prepend={`Shape ${index+1}:`}
                                                    prependClassName="text-xs w-[100px]"
                                                    value={`Size: ${shape.size} (${shape.size_height} x ${shape.size_width})`}
                                                />

                                                {item.product && 
                                                    <div className="flex items-center gap-1">
                                                        {(item.product.min_size && shape.size < item.product.min_size) && 
                                                            <Badge variant="danger" size="sm" value={`Size is under product minimum of ${item.product.min_size}`} />
                                                        }
                                                        {(item.product.max_size && shape.size > Math.max(item.product.max_size, item.product.max_size_admin)) && 
                                                            <Badge variant="danger" size="sm" value={`Size is over product max of ${Math.max(item.product.max_size, item.product.max_size_admin)}`} />
                                                        }
                                                        {(item.path_type == "shape" && item.product.material_min && (shape.size_height < item.product.material_min || shape.size_width < item.product.material_min)) && 
                                                            <Badge variant="danger" size="sm" value={`Dimension is under the product material minimum of ${item.product.material_min}`} />
                                                        }
                                                        {(item.path_type == "shape" && item.product.material_max_1 && (shape.size_height > item.product.material_max_1 || shape.size_width > item.product.material_max_1)) && 
                                                            <Badge variant="danger" size="sm" value={`Dimension is over the product material max of ${item.product.material_max_1}`} />
                                                        }
                                                        {(item.path_type == "shape" && item.product.material_max_2 && (shape.size_height > item.product.material_max_2 || shape.size_width > item.product.material_max_2)) && 
                                                            <Badge variant="danger" size="sm" value={`Dimension is over the product material max of ${item.product.material_max_2}`} />
                                                        }                                        
                                                    </div>
                                                }                                                
                                            </div>
                                        ))}
                                    </>
                                }                                
                                {item.path_type == "letters" && 
                                    <>
                                        <FieldLabel
                                            prepend="Type Style:"
                                            prependClassName="text-sm w-[100px]"
                                            value={item.style}
                                        />                   
                                        <FieldLabel
                                            prepend="Letters:"
                                            prependClassName="text-sm w-[100px]"
                                            value={item.text}
                                        />                                                         
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                ))}

                <div className="pl-[270px] mt-4">
                    <div></div>
                    <div className="space-y-2">
                        <Field
                            prepend="User"
                            prependClassName="w-[100px]"
                            append={
                                <button onClick={() => setSelectUser(true)}>
                                    <FontAwesomeIcon icon={faUser} className="mr-1" />
                                    Select User
                                </button>
                            }
                        >
                            <div className="self-center">
                                {user ? user.name : ""}
                            </div>
                        </Field>
                                        
                        <ButtonMutate
                            icon={faShapes}
                            caption="Create Sign"
                            variant="success"
                            disabled={items.length == 0 || items.filter(i => !i.product_id).length > 0}
                            mutation={createSign}
                            onMutate={() => {
                                return {
                                    user_id: user ? user.id : null,
                                    items: items.map(i => ({
                                        id: i.id,
                                        product_id: i.product_id,
                                        complexity: i.complexity,
                                        faces: i.faces,
                                        sides: i.sides,
                                    }))
                                }
                            }}
                            onMutateSuccess={(data) => {
                                window.open("#/signs/sign/" + data.id)
                            }}
                        />
                    </div>
                </div>                
            </div>
        }

        {selectUser && 
            <UserSelectDrawer
                show={true}
                actions={[
                    {
                        label: "Select User",                        
                        onClick: (userData) => {
                            console.log({userData});

                            setUser(userData)
                            setSelectUser(false)
                        }
                    }                        
                ]}
                onHide={ () => setSelectUser(false) } 
            /> 
            }        
      </>
    )
}

function SignLogosPage(props) {
    const history = useHistory();

    let { id, tab } = useParams();

    const logoQuery = useGetSignLogo(id);
    const logo = logoQuery.data;

    return (
        <PageLayout>
            <PageHeader 
                title="Sign Logo"
                help="signlogo"
            />
            <Content padding={5}>
                {logoQuery.isLoading ? 
                    <Loading />
                :
                    <div className="grid gap-1">
                        <div className="flex gap-1">
                            <button 
                                className={clsx(
                                    "rounded px-2 py-1", 
                                    "hover:bg-gray-100",
                                    (tab=="image" || !tab) && "bg-purple-200 relative top-[2px]"
                                )} 
                                onClick={() => history.push(`/signlogos/${id}/image`)}
                            >
                                <FontAwesomeIcon icon={faImage} className="mr-1" />
                                Logo Image
                            </button>
                            <button 
                                className={clsx(
                                    "rounded px-2 py-1", 
                                    "hover:bg-gray-100",
                                    tab=="sign" && "bg-purple-200"
                                )}
                                onClick={() => history.push(`/signlogos/${id}/sign`)}
                            >
                                <FontAwesomeIcon icon={faShapes} className="mr-1" />
                                Create Logo Sign
                            </button>
                        </div>

                        { (tab == "image" || !tab) ?
                            <ImageCanvas logo={logo} />
                        : (tab == "sign") ?
                            <SignCanvas logo={logo} query={logoQuery} />
                        :
                            null
                        }    
                    </div>
                }
            </Content>
        </PageLayout>
    );
}

export default SignLogosPage;