import React, { useRef, useEffect } from "react"

import { createObjects } from "../helpers/canvas"

function GroupPreview(props) {
    const canvasRef = useRef();

    useEffect(
        () => {
            const element = canvasRef.current;
            const canvas = new window.fabric.StaticCanvas(element);
            
            canvas.setWidth(props.width);
            canvas.setHeight(props.height);
        
            let shapes = props.group.shapes;

            // If this is a shape with sub shapes then we only want to show the 
            // the preview once 
            if (shapes.length && shapes[0].style_id == 99 && shapes[0].related_style_id == 99) {
                shapes = [shapes[0]]

                // override for new sub shapes option so that we have to original ratios for the combined shape and not the sub shape
                if (shapes[0].shape_width_ratio)
                    shapes[0].width_ratio = shapes[0].shape_width_ratio
                if (shapes[0].shape_height_ratio)
                    shapes[0].height_ratio = shapes[0].shape_height_ratio
            }
            
            /*
            const group = new window.fabric.Group();

            const needsBaseline = shapes.length > 1;

            // create a fake line in the group that represents the base size of the letter and the 0x0 point
            if (needsBaseline) {
                const height = props.group.size * settings.PIXELS_IN_INCH;                
                const sizeline = new window.fabric.Line([0, 0, 5, height], {
                    type: "sizer",
                    fill: "green",
                    selectable: false,
                    hasControls: false,
                    hasBorders: false,
                    opacity: 1
                });
        
                group.addWithUpdate(sizeline);
            }

            for (let shapeIndex=0; shapeIndex<shapes.length; shapeIndex++) {
                let shapeData = shapes[shapeIndex];
        
                const path = new window.fabric.Path(shapeData.path);
                        
                path.type = "shape";
                path.data = {}
                path.left = 0;
                path.fill = "white";
                path.stroke = "black";
                path.strokeWidth = 1;

                group.addWithUpdate(path);
            }
            */

            const objects = createObjects(canvas, shapes, 24, {
                depth:0,
                noshadow: true,
                spacing: 3,
                face: {
                    color: {
                        colorsystem_id: 0,
                        hex_day: props.solid ? "000000" : "FFFFFF",
                        hex_night: props.solid ? "000000" : "FFFFFF"
                    }
                },
                trim: {
                    color: {
                        colorsystem_id: 0,
                        hex_day: "000000",
                        hex_night: "000000"     
                    }
                }
            });

            const padding = 5;

            canvas.add(objects);

            objects.scaleToWidth(props.width-padding);

            if (objects.getScaledHeight() > (props.height-padding))
                objects.scaleToHeight(props.height-padding);

            objects.centerV();

            canvas.renderAll();

            return () => {
                canvas.dispose();
            }
        }, 
        [props.group, props.width, props.height]
    );

    return (
      <canvas ref={canvasRef} />
    );
}

export default GroupPreview