import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faQuestionCircle, faPlus } from '@fortawesome/free-solid-svg-icons'

import TextAreaField from "../components/fields/field-textarea"
import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import ListField from "../components/fields/field-list"
import NumberField from "../components/fields/field-number"
import BooleanField from "../components/fields/field-boolean"

import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

const CheckboxList = styled.div`
    padding: 10px;
    font-size: 14px;
    margin-bottom: 8px;
    max-height: 400px;
    overflow: auto;
`

function QaQuestionDrawer(props) {
  const formRef = useRef(null);

  let blankQuestion = {
    products: [],
    parts: [],
    shippingMethods: [],
  }

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");

  useEffect(
    () => {
        let question = props.question;
        let data = {
            ...question,
            products: [],
            parts: [],
            shippingMethods: [],
        }

      if (question) {
        if (question.products) {
            const productIds = question.products.split(",")

            for (const productId of productIds) {
                const prod = props.products.find(p => p.id == productId.trim());

                if (prod)
                    data.products.push(""+prod.id);
            }
        } 
        if (question.parts) {
            const partIds = question.parts.split(",")

            for (const partId of partIds) {
                const part = props.parts.find(p => p.id == partId.trim());

                if (part)
                    data.parts.push(""+part.id);
            }
        } 
        if (question.shippingMethiods) {
            const shippingMethiodIds = question.shippingMethiods.split(",")

            for (const shippingMethiodId of shippingMethiodIds) {
                const method = props.shippingMethiods.find(p => p.id == shippingMethiodId.trim());

                if (method)
                    data.shippingMethods.push(""+method.id);
            }
        } 
      } 
      else data = blankQuestion;

      setFormData(JSON.parse(JSON.stringify(data)));
    }, 
    [props.question]
  );

  useEffect(
    () => {
        postData("qa/categories?type="+props.type, {}, 
            function(response) {
                setCategories(response)
            },
            function(error) {
                alert("An error occured loading categories.");
            }
        );
    }, 
    []
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("qa/savequestion?id=" + (props.question.id||0), removeNulls({...formData, type: props.type}), 
        function(response) {
            props.onSaved(response);
        },
        function(error) {
            alert("An error occured saving the question.");
        },
        function() {
            setIsProcessing(false);
        }
      );
    }
  }

  function onNewCategory() {
    setCategories(produce(draft => {
        const index = draft.findIndex(c => c.id == 0);
        const cat = {id:0, title:newCategory};

        if (index == -1)
            draft.push(cat);
        else 
            draft[index] = cat;
    }));
    setFormData(produce(draft => {
        draft.category_id = 0;
        draft.category = newCategory;
    }));
    setNewCategory("");
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    
    const field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;

      if (field == "category_id" && !value)
        draft.category = null;
        
      if (field == "level") {
          if (value != "Product")
            draft.products = [];
          if (value != "Part")
            draft.parts = [];
          if (value != "Shipping Method")
            draft.shippingMethods = [];
      }
    }));
  }

  function onProductChange(e) {
    const product = e.target.value;
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.products ? draft.products.indexOf(product) : -1;

        if (index != -1)
            draft.products.splice(index, 1);
        if (!draft.products)
            draft.products = [];
        if (checked)
            draft.products.push(product);
    }));
  }
  function onPartChange(e) {
    const part = e.target.value;
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.parts ? draft.parts.indexOf(part) : -1;

        if (index != -1)
            draft.parts.splice(index, 1);
        if (!draft.parts)
            draft.parts = [];
        if (checked)
            draft.parts.push(part);
    }));
  }
  function onShippingMethodChange(e) {
    const method = e.target.value;
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.shippingMethods ? draft.shippingMethods.indexOf(method) : -1;

        if (index != -1)
            draft.shippingMethods.splice(index, 1);
        if (!draft.shippingMethods)
            draft.shippingMethods = [];
        if (checked)
            draft.shippingMethods.push(method);
    }));
  }

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faQuestionCircle} />
                &nbsp;
                QA Question
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
                    <TextAreaField 
                        name="question" 
                        label="Question" 
                        required
                        value={formData.question} 
                        onChange={(e) => handleFormChange(e)} />

                    <ListField 
                        name="category_id" 
                        label="Category" 
                        list={categories}
                        labelField="title"
                        value={formData.category_id} 
                        onChange={(e) => handleFormChange(e)} />
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Add Category
                            </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                            name="category"
                            value={newCategory}
                            onKeyPress={event => {                        
                                if (event.key === "Enter") {
                                    event.preventDefault();
                                    event.stopPropagation();
            
                                    onNewCategory();
                                }
                            }}
                            onChange={(event) => {
                                setNewCategory(event.target.value);
                            }}
                        />
                        <InputGroup.Append>
                            <Button onClick={onNewCategory} disabled={newCategory.length == 0} variant="outline-secondary">
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>

                    {props.type == "Fraudulent Check" &&
                        <NumberField 
                            name="score" 
                            label="Score" 
                            required
                            integer
                            value={formData.score} 
                            onChange={(e) => handleFormChange(e)} />
                    }

                    <ListField 
                        name="level" 
                        label="Level" 
                        instructions="Level controls when the question is displayed."
                        value={formData.level} 
                        list={[
                            {value: "Global", label: "Global"},
                            {value: "Product", label: "Product"},
                            {value: "Part", label: "Part"},
                            {value: "Shipping Method", label: "Shipping Method"},
                        ]}
                        onChange={(e) => handleFormChange(e)} 
                    />

                    {formData.level == "Product" && 
                        <CheckboxList>
                        {props.products.map(product => (
                            <div key={product.id}>
                                <Form.Check 
                                    name="product"                                
                                    defaultValue={product.id}
                                    checked={formData.products && formData.products.indexOf(""+product.id) != -1}
                                    inline 
                                    label={product.title} 
                                    type="checkbox" 
                                    onChange={onProductChange}
                                />
                            </div>
                        ))}
                        </CheckboxList>
                    }
                    {formData.level == "Part" && 
                        <CheckboxList>
                        {props.parts.map(part => (
                            <div key={part.id}>
                                <Form.Check 
                                    name="part"                                
                                    defaultValue={part.id}
                                    checked={formData.parts && formData.parts.indexOf(""+part.id) != -1}
                                    inline 
                                    label={part.title} 
                                    type="checkbox" 
                                    onChange={onPartChange}
                                />
                            </div>
                        ))}
                        </CheckboxList>
                    }
                    {formData.level == "Shipping Method" && 
                        <CheckboxList>
                        {props.shippingMethods.map(method => (
                            <div key={method.id}>
                                <Form.Check 
                                    name="shippingmethod"                                
                                    defaultValue={method.id}
                                    checked={formData.shippingMethods && formData.shippingMethods.indexOf(""+method.id) != -1}
                                    inline 
                                    label={method.title} 
                                    type="checkbox" 
                                    onChange={onShippingMethodChange}
                                />
                            </div>
                        ))}
                        </CheckboxList>
                    }

                    {(props.type == "Build Ready" || props.type == "Pre Build" || props.type == "Job") &&
                        <BooleanField
                            name="warning_ind" 
                            label="Job Warning" 
                            instructions="If user answers this question with No, then a warning will show up on the job page."
                            required
                            value={formData.warning_ind} 
                            onChange={(e) => handleFormChange(e)} />
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonProcessing 
                    processing={isProcessing}
                    onClick={onSave} 
                    variant="outline-success" 
                    caption="Save Question" 
                    icon={faSave} /> 
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default QaQuestionDrawer;