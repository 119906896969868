import React from "react"
import clsx from 'clsx';

function Buttons({
    children
}) {
    return (
        <div className="flex items-stretch gap-1">
            {children}
        </div>
    );
}

export default Buttons