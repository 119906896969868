import React, { useEffect, useState } from "react"
import styled from 'styled-components'

const Background = styled.div`
    position: absolute;
    left:0px;
    right:0px;
    bottom: 0px;
    top: 0px;
    z-index: 98;
    background-color: ${props => props.simple ? "rgba(0,0,0,.5)" : props.transparent ? "transparent" : "rgba(255,255,255,.7)"};
    backdrop-filter: ${props => props.simple || props.transparent ? "none":"blur(1px)"};

    transition: opacity .7s;
    opacity: 0;

    &.open {
        opacity: 1;
    }
`

function Overlay(props) {
    const [open, setOpen] = useState(false);

    useEffect(
        () => {
            setOpen(true);
        }, 
        []
    );

    return (
      <Background 
        simple={props.simple} 
        transparent={props.transparent}
        onClick={props.onClick} 
        className={open ? "open":""} 
        style={props.style}
        />
    );
}

export default Overlay