import React, { useState, useEffect } from "react";
import styled from "styled-components";
import produce from "immer"
import QRCode from "qrcode.react";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Button from "../components/buttons/button";
import MWOsDrawer from "../drawers/drawer-mwos"
import MiniMWOsDrawer from "../drawers/drawer-minimwos"
import { postData } from "../common/services/server"

const Mwos = styled.div`
  border: 1px solid #ccc;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #ccc;

    > div {
      margin-right: 20px;
    }
  }

  label {
    white-space: nowrap;
  }
`

function PanelJobMwos({job, shapes}) {
    const [isLoading, setIsLoading] = useState(true);
    const [mwos, setMwos] = useState([]);
    const [selected, setSelected] = useState(null);
    const [counts, setCounts] = useState({});

    useEffect(
        () => {
            if (job) {
                setIsLoading(true);
    
                postData("mwos/job", {
                        job_id: job.id
                    }, 
                    function(response) {
                        setMwos(response);
                    },
                    function(error) {
                        alert("An error occured loading mwo data.");
                    },
                    function() {
                        setIsLoading(false);
                    }
                );
            }
        }, 
        [job]
      );

    useEffect(
      () => {
        if (mwos && shapes) {
          setCounts(produce(draft => {
            for (const mwo of mwos) {       // maybe we already have a count selected?
              if (mwo.stickers == "count" && !draft[mwo.name]) {
                // do we have a saved job prob with the count?
                if (mwo.sticker_count)
                  draft[mwo.name] = parseInt(mwo.sticker_count);
                else
                  draft[mwo.name] = shapes.length;
              }
              else if (mwo.stickers == "quantity" && !draft[mwo.name]) {
                draft[mwo.name] = parseInt(job.orderitem_quantity);
              }              
            }
          }))          
        }
      }, 
      [mwos, shapes]
    );

    function onMwoChange(e) {
      const mwo = e.target.value;
      const checked = e.target.checked;

      setMwos(produce(draft => {
        const index = draft.findIndex(m => m.name == mwo);

        console.log(mwo, index);
        
        draft[index].selected = checked; 
      }));
    }

    function onSelect(type) {      
      const mwosEnabled = mwos.filter(m => isMwoEnabled(m) && (type=="all" || m.type==type));
      const allAreSelected = mwosEnabled.filter(m => m.selected).length == mwosEnabled.length;

      console.log(mwos.length, mwosEnabled, allAreSelected)
      setMwos(produce(draft => {
        for (const mwo of draft) {
          if (allAreSelected)
            mwo.selected = false;
          else
            mwo.selected = isMwoEnabled(mwo) && (type=="all" || mwo.type==type);
        }
      }));
    }

    function onPrint(mode="standard") {
      setSelected({mode})
    }

    function saveJobProp(prop, value) {
        postData("jobs/saveprop", {
            job_id: job.id,
            prop,
            value
        }, 
        function(response) {
        },
        function(error) {
        }
      );
    }

    function getMwoMissingData(mwo) {
      let enabled = true;
      let missing = null;
        
      console.log("getMwoMissingData", mwo.name);

      // if this mwo has parts then make sure the job contains the part(s)
      if (mwo.part_ids) {
        console.log("-parts", mwo.part_ids, mwo.parts);

        const partIds = mwo.part_ids.split(",");
        const parts = mwo.parts.split(",");
        const mode = mwo.part_modes ? mwo.part_modes.split(",")[0].toLowerCase() : "or";

        console.log("-mode", mode);

        enabled = false;

        for (const shape of shapes) {
          console.log("- shape", shape.code);

          let found = 0;

          for (let i=0; i<partIds.length; i++) {
            const partId = partIds[i];
            const part = parts[i];

            console.log("- part", partId, part);
  
            if (shape.parts && shape.parts.find(sp => sp.part_id == partId)) {
              console.log("- found part")
              found++;
            }

            console.log("- found count", found);

            if (!enabled) {
              enabled = mode == "or" ? found > 0 : found == partIds.length;
            }
          }
        }

        console.log("-enabled", enabled);

        if (!enabled) {
          missing = mwo.parts;
        }
      }
      if (mwo.shipping_method_ids && !missing) {
        console.log("-shipping", mwo.shipping_method_ids);

        enabled = false;

        const methodIds = mwo.shipping_method_ids.split(",");
        const methods = mwo.shipping_methods.split(",");

        for (let i=0; i<methodIds.length; i++) {
          const methodId = methodIds[i];
          
          if (job.shipping_method_id == methodId) {
            enabled = true;
          }
        }

        if (!enabled) {
          missing = mwo.shipping_methods;
        }        
      }
      
      console.log("-missing", missing)
      return missing;
    }

    function isMwoEnabled(mwo) {
        let enabled = true;
        
        // if this mwo has parts then make sure the job contains the part(s)
        if (mwo.part_ids) {          
          enabled = getMwoMissingData(mwo) == null;
        }    
        
        return enabled;
    }

    return (
      <>
        <Mwos>
        <ButtonGroup>
            <Button onClick={() => onSelect('all')} variant="outline-secondary" size="sm">
            All
            </Button>
            <Button onClick={() => onSelect('temporary')} variant="outline-secondary" size="sm">
            Temporary
            </Button>
            <Button onClick={() => onSelect('permanent')} variant="outline-secondary" size="sm">
            Permanent
            </Button>
        </ButtonGroup>

        {mwos.map(mwo => {
            const enabled = isMwoEnabled(mwo);

            return (
            <div key={mwo.id} style={{backgroundColor: enabled?"white":"#eee"}}>
            <div>
                <Form.Check 
                    type="checkbox"
                    value={mwo.name}
                    checked={enabled && mwo.selected}
                    label={mwo.title}
                    disabled={!enabled}                      
                    onChange={onMwoChange}                    
                />

                {((mwo.stickers == "count" || mwo.stickers == "quantity" || mwo.stickers == "count_sticker") && enabled) && 
                <FormControl 
                    as="select" 
                    name={mwo.name + "_count"}
                    size="sm"
                    value={counts[mwo.name]} 
                    onChange={(e) => {
                        if (e.persist) e.persist();

                        setCounts(produce(draft => {
                            draft[mwo.name] = e.target.value
                        }));

                        saveJobProp("stickers_" + mwo.name, e.target.value)
                    }}
                >
                    {[...Array(50)].map((e, i) => <option value={i+1}>{i+1}</option>)}
                </FormControl>                      
                }
            </div>
            {enabled ?
                <QRCode value={"Job:" + job.id + ":MWO:" + mwo.name} size={50} imageSettings={{width:50,height:50}} renderAs="svg" />
            : mwo.missing ?
                <div>
                <OverlayTrigger
                    overlay={
                        <Tooltip>"Missing Item(s): {mwo.missing}</Tooltip>
                    }
                >                
                    <FontAwesomeIcon icon={faInfoCircle} />
                </OverlayTrigger>
                </div>
            : null
            }
            </div>
            )
        })}

        <div style={{display:"block"}}>
            <Button 
            variant="success" 
            block
            disabled={mwos.filter(m => m.selected).length == 0}
            onClick={() => onPrint("standard")}
            >
            <FontAwesomeIcon icon={faPrint} />{' '}
            Print Standard Stickers
            </Button>
            <Button 
            variant="outline-success" 
            block
            disabled={mwos.filter(m => m.selected).length == 0}
            onClick={() => onPrint("mini")}
            >
            <FontAwesomeIcon icon={faPrint} />{' '}
            Print Mini Stickers
            </Button>

            {/* <Dropdown drop="up">
            <Dropdown.Toggle variant="success" disabled={mwos.filter(m => m.selected).length == 0}>
                <FontAwesomeIcon icon={faPrint} />{' '}
                Print Selected MWOs
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item >
                <FontAwesomeIcon icon={faThList} />{' '}
                Standard MWO Stickers
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onPrint("mini")}>
                <FontAwesomeIcon icon={faTh} />{' '}
                Mini MWO Stickers
                </Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown>               */}
        </div>
        </Mwos>

        {(selected && selected.mode == "standard") && 
          <MWOsDrawer 
            show={true}
            job={job}
            mwos={mwos.filter(m => m.selected)}
            counts={counts}
            shapes={shapes}
            onHide={() => setSelected(null)}
          />
        }
        {(selected && selected.mode == "mini") && 
          <MiniMWOsDrawer 
            show={true}
            job={job}
            mwos={mwos.filter(m => m.selected)}
            counts={counts}
            shapes={shapes}
            onHide={() => setSelected(null)}
          />
        }        
      </>
    );
}

export default PanelJobMwos;