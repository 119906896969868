import React, { useState } from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import Button from "./button";

import { postData as postDataToServer } from "../../common/services/server"

function ButtonPost({
    postUrl,
    postData,
    onPosting,
    onPostResult,
    onPostError,
    postErrorMessage,
    caption,
    children,
    ...props
}) {
    const [posting, setPosting] = useState(false);

    return (
        <Button 
            {...props}
            onClick={() => {
                setPosting(true);

                if (onPosting) onPosting(true);

                postDataToServer(postUrl, postData,
                    function(result) {
                        if (onPostResult)
                            onPostResult(result);
                    },
                    function(error) {
                        if (postErrorMessage)
                            window.alert(postErrorMessage);
                        else if (onPostError)
                            onPostError(error);
                        else 
                            window.alert("Error posting data to the server.")
                    },
                    function() {
                        setPosting(false);

                        if (onPosting) onPosting(false);
                    }
                ); 
            }} 
            disabled={posting || props.disabled}
            icon={posting ? faSpinner : props.icon}
            iconSpin={posting}
        >
            {caption || children || null}
        </Button>
    );
}

export default ButtonPost