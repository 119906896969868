import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetWorkflows = (options={}) => {  
  return useQuery({ 
    queryKey: ["workflows", "list"], 
    queryFn: ({signal}) => query("workflows/list", {}, signal),
    ...options
  });
};

export const useGetWorkflow = (workflowId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflows", ""+workflowId], 
      queryFn: ({signal}) => query("workflows/workflow/data", {workflow_id: workflowId}, signal),
      ...options
    });
};

export const useGetWorkflowTask = (workflowId, workflowTaskId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflows", ""+workflowId, "task"+workflowTaskId], 
      queryFn: ({signal}) => query("workflows/workflow/task/data", {
        workflow_id: workflowId,
        workflowtask_id: workflowTaskId,
      }, signal),
      ...options
    });
};

export const useGetWorkflowItems = (params, options={}) => {  
  return useQuery({ 
    queryKey: ["workflows", "items", params], 
    queryFn: ({signal}) => query("workflows/items/list", {
      ...params
    }, signal),
    ...options
  });
};