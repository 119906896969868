import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import OrdersMonthChart from "../../components/charts/chart-ordersmonth"
import OrdersYearChart from "../../components/charts/chart-ordersyear"
import PinnedNotes from "../../components/widgets/widget-pinnednotes"
import AssignedTasks from "../../components/widgets/widget-assignedtasks"
import Calendar from "../../components/widgets/widget-calendar"
import ActiveJobs from "../../components/widgets/widget-activejobs"
import SalesStats from "../../components/widgets/widget-salesstats"
import UnpaidOrders from "../../components/widgets/widget-unpaidorders"
import NewPhotos from "../../components/widgets/widget-newphotos"

import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

function MyDashboard(props) {
  return (
    <>
        {userHasPermission(Permissions.Orders, Actions.Edit) &&    
          <Row>
              <Col xs={12} sm={7}>
                <SalesStats />
              </Col>
              <Col xs={12} sm={5}>
                <UnpaidOrders />
              </Col>
          </Row>    
        }

        {(userHasPermission(Permissions.Photos)) &&        
          <Row>
              <Col xs={12}>
                <NewPhotos />
              </Col>
          </Row>
        }

        {(userHasPermission(Permissions.Jobs) || userHasPermission(Permissions.Orders) || userHasPermission(Permissions.Signs)) &&        
          <Row>
              <Col xs={12}>
                <Calendar />
              </Col>
          </Row>
        }

        <Row>
          {userHasPermission(Permissions.Tasks) &&
            <Col xs={12} sm={6}>
              <AssignedTasks />
            </Col>
          }
          {userHasPermission(Permissions.Notes) &&
            <Col xs={12} sm={6}>
              <PinnedNotes />   
            </Col>
          }
        </Row>
        {userHasPermission(Permissions.Orders) &&
          <Row>
              <Col xs={12} sm={7}>
                <OrdersYearChart />
              </Col>
              <Col xs={12} sm={5}>
                <OrdersMonthChart />
              </Col>
          </Row>
        }
        {userHasPermission(Permissions.Jobs) &&
          <Row>
              <Col xs={12}>
                <ActiveJobs />
              </Col>
          </Row>
        }
    </>
  );
}

export default MyDashboard;