import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import styled from "styled-components"
import produce from "immer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash, faGlobeAmericas, faCaretDown, faCaretUp, faSave, faSpinner, faQuestionCircle, faExclamationTriangle, faBan, faStar } from '@fortawesome/free-solid-svg-icons'
import Badge from 'react-bootstrap/Badge';
import InputGroup from  'react-bootstrap/InputGroup';
import FormControl from  'react-bootstrap/FormControl';
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    DateCell, 
    GoogleImageCell, 
    EditActionsCell,
    LinkCell,
    SortHeaderCell,
    SortTypes
} from "../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import { useParams, useHistory } from "react-router-dom";

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"
import Button from "../components/buttons/button";
import UploadButton from "../components/buttons/button-upload"
import PhotoDrawer from "../drawers/photo";
import Paginator from "../components/paginator"
import { Accordion, AccordionItem } from "../components/accordion";

import { postData } from "../common/services/server"
import { removeNulls, formatFileSize } from "../helpers/utility"
import { useBodyClass } from "../hooks/useBodyClass"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { dimensions } from "../settings/settings"
import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import storage from "../settings/storage"
import clsx from "clsx";
import PageLayout from "../components/page-layout";

const CategoriesWidth = 200;
const Padding = 10;

const Interface = styled.div`
    display: flex;
`
const Categories = styled.div`
    width: ${CategoriesWidth}px;
    margin-right: ${Padding}px;
    border: 1px solid #ccc;
    padding: 0px;
    overflow: auto;

    .card-header {
        padding: 5px 10px;
    }

    > h2 {
        background-color: #f6f7f8;
        background-image: linear-gradient(#fff, #efefef);
        font-size: 16px;
        font-weight: 700;
        padding: 15px 10px;
        border-bottom: 1px solid #ccc;
        margin: 0px;
    }
`

function PhotosPage(props) {
  const history = useHistory();
  const windowDimensions = useWindowDimensions();

  let { tab } = useParams();

  if (!tab) tab = "new";

  const [groupings, setGroupings] = useState([]);
  const [categories, setCategories] = useState([]);

  const [keywords, setKeywords] = useState([]);
  const [products, setProducts] = useState([])
  const [pages, setPages] = useState([])
  const [maplocations, setMapLocations] = useState([]);
  const [jobs, setJobs] = useState([]);

  const [category, setCategory] = useState();
  const [categoryTitle, setCategoryTitle] = useState("");
  const [busy, setBusy] = useState("");
  
  const [photos, setPhotos] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({page:1,pages:1,total:0});
  const [sort, setSort] = useState({column: "added_date", dir: SortTypes.DESC});
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [selected, setSelected] = useState(null);
  const [busyRow, setBusyRow] = useState(null);
  const [reload, setReload] = useState(1);

  useBodyClass(`noscroll`);

  function changeCategories(cats) {
    let groups = [];

    for (const cat of cats) {
        if (groups.indexOf(cat.grouping) == -1) 
            groups.push(cat.grouping)
    }

    setGroupings(groups);
    setCategories(cats);
  }

  useEffect(
    () => {
        if (categories.length == 0) {
            setLoadingCategories(true);

            postData("photos/categories", {},
                function(result) {
                    setKeywords(result);

                    if (tab == "category")
                        changeCategories(result);
                },
                function(error) {
                    alert("Error loading categories")
                },
                function() {
                    setLoadingCategories(false);
                }
            );
        }

        if (products.length == 0) {
            postData("photos/products", {},
                function(result) {
                    setProducts(result);
                    
                    if (tab == "product")
                        changeCategories(result);
                },
                function(error) {
                    alert("Error loading products")
                }
            );
        }

        if (jobs.length == 0) {
            postData("photos/jobs", {},
                function(result) {
                    setJobs(result);
                    if (tab == "job")
                        changeCategories(result);
                },
                function(error) {
                    alert("Error loading jobs")
                }
            );
        }

        if (maplocations.length == 0) {
            postData("photos/maplocations", {},
                function(result) {
                    setMapLocations(result);
                    if (tab == "map")
                        changeCategories(result);
                },
                function(error) {
                    alert("Error loading map locations")
                }
            );
        }

        if (pages.length == 0) {
            postData("photos/pages", {},
                function(result) {
                    setPages(result);
                    if (tab == "page")
                        changeCategories(result);
                },
                function(error) {
                    alert("Error loading landing pages")
                }
            );
        }

        if (tab == "product")
            changeCategories(products)
        else if (tab == "page")
            changeCategories(pages);
        else if (tab == "job")
            changeCategories(jobs);
        else if (tab == "category")
            changeCategories(keywords);
        else if (tab == "map")
            changeCategories(maplocations);
        else if (tab == "problems") {
            changeCategories([]);
            setCategory({id:0})
        }
        else 
            changeCategories([]);
    }, 
    [tab]
  );

  useEffect(
      () => {
          if (cancel)
            cancel.cancel();

          if (tab && (category || tab == "all" || tab == "new")) {
            setLoading(true);

            const cancelToken = postData("photos/data", removeNulls({ 
                type: tab == "category" && category.id == 0 ? "uncategorized": tab, 
                category_id: category ? category.id : null,
                sort: sort.column,
                sortdir: sort.dir,
                page: page,
                perPage: 100,
            }),
                function(result) {
                    setPhotos(result.photos);
                    setPagination(result.pagination)
                },
                function(error) {
                    alert("Error loading photos")
                },
                function() {
                    setLoading(false);
                    setCancel(null);
                }
            );

            setCancel(cancelToken);
        }
      }, 
      [tab, category, page, sort, reload]
  );

  useEffect(
    () => {
        setCategoryTitle(category ? category.title : "");
        setPage(1)
    }, 
    [category]
);

  function onSortChange(columnKey, sortDir) {
      setSort({ column: columnKey, dir: sortDir })
  }

  function onAction(action, photo) {
    if (action == "edit")
        setSelected({mode:"edit", photos: [photo]});
    else if (action == "remove") {
      setBusyRow(photo);

      postData("photos/remove", removeNulls({
        photo_id: photo.id,
        page_id: tab == "page" ? category.id : null,
        product_id: tab == "product" ? category.id : null,
        category_id: tab == "category" ? category.id : null,
        maplocation_id: tab == "map" ? category.id : null
      }),
        function(result) {
          setPhotos(produce(draft => {
            const index = draft.findIndex(p => p.id == photo.id);
            draft.splice(index, 1);
          }));
        },
        function(error) {
            alert("Error removing photos.")
        },
        function() {
          setBusyRow(null)
        }
      );
    }
    else if (action == "delete") {
        if (window.confirm("Delete this photo from the website?")) {
            setBusyRow(photo);
    
            postData("photos/delete", removeNulls({
                photo_id: photo.id
            }),
                function(result) {
                    setPhotos(produce(draft => {
                    const index = draft.findIndex(p => p.id == photo.id);
                    draft.splice(index, 1);
                    }));
                },
                function(error) {
                    alert("Error deleting photo.")
                },
                function() {
                    setBusyRow(null)
                }
            );
        }
    }
  }

  function onUpdateCategory() {
    setBusy("categoryupdate");

    postData("photos/updatecategory", removeNulls({ 
        type: tab, 
        category_id: category.id,
        title: categoryTitle
    }),
        function() {
            const index = keywords.findIndex(c => c.id == category.id);

            setKeywords(produce(draft => {
                  draft[index].title = categoryTitle;
            }));
            setCategories(produce(draft => {
                draft[index].title = categoryTitle;
            }));
        },
        function(error) {
            alert("Error updating")
        },
        function() {
            setBusy("");
        }
    );
  }
  function onDeleteCategory() {
    setBusy("categorydelete");

    postData("photos/deletecategory", removeNulls({ 
        type: tab, 
        category_id: category.id
    }),
        function(result) {
            const index = keywords.findIndex(c => c.id == category.id);

            setKeywords(produce(draft => {
                draft.splice(index, 1);
            }));
            setCategories(produce(draft => {
                draft.splice(index, 1);
            }));
            setCategory(null);
        },
        function(error) {
            alert("Error deleting")
        },
        function() {
            setBusy("");
        }
    );
  }

  const haveToolbar = tab == "category" && category && category.id > 0;

  //{name:"job", title:"Jobs"},
  return (
      <PageLayout>
          <PageHeader 
              title="Photo Manager"
              help={"photos"}
              tab={tab}
              tabUrl={"/photos/{tab}"}
              tabs={[
                    {name:"new", title:"New", icon:faStar},
                    {name:"all", title:"All"},
                    {name:"category", title:"Categories"},
                    {name:"product", title:"Products"},
                    {name:"page", title:"Landing Pages"},
                    {name:"map", title:"Map Photos", icon:faGlobeAmericas},
                    {name:"problems", title:"Problems", icon:faExclamationTriangle},
              ]}
              onTab={(tab) => {
                  history.push("/photos/" + tab);
              }}
          >
              {userHasPermission(Permissions.Photos, Actions.Upload) &&
                <UploadButton
                    uploadUrl={"photos/upload"}
                    label="Upload Photo"
                    thumbnail={true}
                    thumbnailOptions={{
                        thumbnailSize: 1024,
                        preview: true,
                        previewSize: 400,
                        format: "jpg",                      
                    }}
                    multiple={true}
                    extensions={["jpg","png"]}
                    onUploading={(fileCount) => {
                        console.log("Uploading " + fileCount + " files");
                    }}
                    onUpload={(upload, fileIndex) => {
                        console.log("onUpload", upload, fileIndex)
                    }}
                    onUploadsComplete={(uploads) => {
                        console.log("onUploadsComplete", uploads)
                        setSelected({mode:"edit", photos: uploads});
                    }}
                /> 
              }
          </PageHeader>
          <Content permission={Permissions.Photos}> 
            <Interface>
                {(tab != "problems" && tab != "all" && tab != "new") &&
                    <Categories style={{height: windowDimensions.height - dimensions.headerHeight - dimensions.pageWithTabsPadding*2}}>
                        <h2>
                            { tab == "product" ?
                                "Products"
                                : tab == "page" ? 
                                "Landing Pages"
                                : tab == "job" ? 
                                "Jobs"
                                : tab == "map" ? 
                                "Map Locations"
                                : tab == "category" ? 
                                "Categories"
                                :
                                "All Photos"
                            }
                        </h2>
                        {
                            loadingCategories ? 
                                <Loading size="sm" />
                            :
                            tab == "category" ?
                                <ul>
                                    {categories.map(cat => (
                                        <li 
                                            key={cat.id} 
                                            onClick={() => setCategory(cat)} 
                                            className={clsx(
                                                "flex items-center justify-between text-xs p-1 border-b border-gray-300",
                                                category && category.id == cat.id && "bg-purple-300"
                                            )}
                                        >
                                            {cat.title}
                                            <span><Badge variant={cat.photo_count == 0 ? "secondary":"primary"} size="sm">{cat.photo_count}</Badge></span>
                                        </li>
                                    ))}
                                </ul>
                            :
                                groupings.map(group => (
                                    <div key={group} className="px-1 py-[2px] text-xs">
                                        {group.indexOf("[") != -1 ? 
                                            <ul>
                                                {categories.filter(c => c.grouping == group).map(cat => (
                                                    <li 
                                                        key={cat.id} 
                                                        onClick={() => setCategory(cat)} 
                                                        className={clsx(
                                                            "flex items-center justify-between text-xs",
                                                            category && category.id == cat.id && "bg-purple-300"
                                                        )}
                                                    >
                                                        {cat.title}

                                                        <span><Badge variant={cat.photo_count == 0 ? "secondary":"primary"} size="sm">{cat.photo_count}</Badge></span>
                                                    </li>
                                                ))}
                                            </ul>
                                        :
                                            <Accordion size="sm">
                                                <AccordionItem title={group} headerClassName="!p-1" contentClassName="!p-0">
                                                    <ul>
                                                        {categories.filter(c => c.grouping == group).map(cat => (
                                                            <li 
                                                                key={cat.id} 
                                                                onClick={() => setCategory(cat)} 
                                                                className={clsx(
                                                                    "flex items-center justify-between border-b border-gray-300 cursor-pointer text-xs p-2",
                                                                    category && category.id == cat.id && "bg-purple-300",
                                                                )}
                                                            >
                                                                <div>
                                                                    {cat.title}
                                                                </div>

                                                                <Badge variant={cat.photo_count == 0 ? "secondary":"primary"} size="sm">{cat.photo_count}</Badge>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </AccordionItem>
                                            </Accordion>
                                        }
                                    </div>
                                ))
                        }
                    </Categories>
                }
                {
                    loading 
                ?
                    <Loading />
                :  
                    <div>
                        {(tab == "category" && category && category.id > 0 && userHasPermission(Permissions.Photos, Actions.Categorize)) && 
                            <InputGroup size="sm" className="mb-3">
                                <FormControl
                                    defaultValue={categoryTitle}
                                    onChange={(e) => { 
                                        setCategoryTitle(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            onUpdateCategory();
                                        }
                                    }}
                                />
                                <InputGroup.Append>
                                    <Button onClick={onUpdateCategory} variant="outline-primary" size="sm">
                                        <FontAwesomeIcon icon={busy == "categoryupdate" ? faSpinner : faSave} spin={busy == "categoryupdate"} />{' '}
                                        Update
                                    </Button>
                                    <Button onClick={onDeleteCategory} variant="outline-danger" size="sm">
                                        <FontAwesomeIcon icon={busy == "categorydelete" ? faSpinner : faTrash} spin={busy == "categorydelete"} />{' '}
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        }
                    <Table
                        rowHeight={75}
                        rowsCount={photos.length}
                        width={windowDimensions.width - dimensions.sideBarWidth - dimensions.pagePadding - (tab != "problems" && tab != "all" && tab != "new" ? CategoriesWidth+Padding:0)}
                        height={windowDimensions.height - dimensions.dataTableHeightOffset}
                        headerHeight={50}
                        touchScrollEnabled={true}
                    >
                        <Column
                            header={<Cell>Photo</Cell>}
                            columnKey="preview_url"                            
                            fixed={!isMobile}
                            cell={<GoogleImageCell data={photos} zoomColumnKey="url" maxwidth={150} maxheight={50} />}
                            width={150}
                        />
                        {tab == "problems" && 
                            <Column
                                header={<Cell>Problem</Cell>}
                                columnKey="problem"
                                cell={<TextCell data={photos} />}
                                width={200}
                            />
                        }
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    File Name
                                </SortHeaderCell>
                            }
                            columnKey="filename"
                            cell={                            
                            <LinkCell data={photos} small tooltipColumnKey="url" onClick={(file) => {
                                window.open(storage.root + file.url)
                                }} 
                            />
                            }
                            width={225}
                        />
                        <Column
                            header={<Cell>Categories</Cell>}
                            columnKey="categories"
                            cell={<TextCell data={photos} tiny />}
                            width={200}
                        />
                        <Column
                            header={<Cell>Products</Cell>}
                            columnKey="products"
                            cell={<TextCell data={photos} tiny />}
                            width={200}
                        />   
                        <Column
                            header={<Cell>Landing Pages</Cell>}
                            columnKey="pages"
                            cell={<TextCell data={photos} tiny />}
                            width={150}
                        />                                               
                        <Column
                            header={<Cell>Job</Cell>}
                            cell={({rowIndex, ...props}) => (
                                <Cell {...props}>
                                    {photos[rowIndex].job_id ? 
                                        <Link to={"/jobs/job/" + photos[rowIndex].job_id} target="_blank">
                                            #{photos[rowIndex].job_id}
                                        </Link>
                                    : 
                                        ""
                                    }
                                </Cell>
                                )}                            
                            width={100}
                        />
                        <Column
                            header={<Cell>Map</Cell>}
                            cell={({rowIndex, ...props}) => (
                                <Cell {...props}>
                                    {photos[rowIndex].map_ind == 1 ? 
                                        <>
                                            {photos[rowIndex].state}
                                            <div><small style={{fontSize: "10px"}}>{photos[rowIndex].maplocation_title}</small></div>
                                        </>
                                    : 
                                        ""
                                    }
                                </Cell>
                                )}                            
                            width={150}
                        />                        
                        <Column
                            header={<Cell>Size</Cell>}
                            columnKey="filesize"
                            cell={({rowIndex, ...props}) => (
                                <Cell {...props}>
                                    <div align="right">
                                        {formatFileSize(photos[rowIndex].filesize || 0)}
                                    </div>
                                    </Cell>
                                )}
                            width={100}
                        />
                        <Column
                            header={
                                <Cell>
                                    Dimensions
                                </Cell>
                            }
                            columnKey="dimensions"
                            cell={({rowIndex, ...props}) => (
                                <Cell {...props}>
                                    <div style={{color: photos[rowIndex].image_width < 500 || photos[rowIndex].image_height < 500 ? "red":"black"}}>
                                        {photos[rowIndex].image_width} x {photos[rowIndex].image_height}
                                    </div>
                                </Cell>
                            )}
                            width={115}
                        />
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Title
                                </SortHeaderCell>
                            }
                            columnKey="title"
                            cell={<TextCell data={photos} tiny />}
                            width={250}
                        />
                        <Column
                            header={
                                <Cell>
                                    Keywords
                                </Cell>
                            }
                            columnKey="keywords"
                            cell={<TextCell data={photos} tiny />}
                            width={250}
                        />
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    Date Added
                                </SortHeaderCell>
                            }
                            columnKey="added_date"
                            cell={<DateCell data={photos} />}
                            width={135}
                        />              
                        <Column
                            header={<Cell>Actions</Cell>}
                            cell={
                            <EditActionsCell 
                                data={photos} 
                                actions={[
                                    {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, isVisible: () => userHasPermission(Permissions.Photos, Actions.Edit)},
                                    {action:"remove", title:"Remove", variant:"danger", icon:faBan, isVisible: (photo) => tab != "all" && tab != "problems" && category && category.id != 0 && userHasPermission(Permissions.Photos, Actions.Edit)},
                                    {action:"delete", title:"Delete", variant:"danger", icon:faTrash, isVisible: () => userHasPermission(Permissions.Photos, Actions.Delete)},
                                ]}
                                busy={busyRow}
                                onClick={onAction} 
                            />
                            }
                            align="center"
                            width={120}
                        />
                    </Table>
                    <Paginator
                        {...pagination}
                        item="Photo"
                        items="Photos"
                        onPage={(page) => {
                            setPage(page);
                        }}
                    />
                    </div>
                }   
            </Interface>
          </Content>

          { selected && selected.mode == "edit" && 
            <PhotoDrawer 
                show={true}
                photoIds={selected.photos.map(p => p.id)}
                onSaved={(photoData) => {
                    // just force a refresh
                    setReload(prev => prev+1);
                    setSelected(null);
                }}
                onNewKeyword={(keyword) => {
                    setKeywords(produce(draft => {
                        draft.push(keyword)

                        draft.sort((a, b) => {
                            var keyA = a.title;
                            var keyB = b.title;

                            // Compare the 2 dates
                            if (keyA < keyB) return -1;
                            if (keyA > keyB) return 1;
                            return 0;
                        });
                    }));

                    if (tab == "category") {
                        setCategories(produce(draft => {
                            draft.push(keyword)

                            draft.sort((a, b) => {
                                var keyA = a.title;
                                var keyB = b.title;

                                // Compare the 2 dates
                                if (keyA < keyB) return -1;
                                if (keyA > keyB) return 1;
                                return 0;
                            });
                        }));
                    }
                }}
                onHide={() => setSelected(null)} 
            /> 
          }
        </PageLayout>
  );
}

export default PhotosPage;