import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useParams, useHistory } from "react-router-dom";
import { faBars, faDesktop, faHourglass, faFont, faList, faStar, faShapes, faTrash, faPlus, faListAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { produce } from "immer";
import { useQueryClient } from "@tanstack/react-query";

import PageHeader from "../components/page-header";
import Content from "../components/content"
import CardLayout, { CardLayoutPanel } from "../components/cardlayout";
import TabList from "../components/tabs";

import Loading from "../components/loading";
import DropdownMenu from "../components/dropdownmenu";
import Error from "../components/error";
import Alert from "../components/alert";
import SignDetailsPanel from "../panels/sign-details";
import SignGroupsPanel from "../panels/sign-groups";
import SignBuilderPanel from "../panels/panel-sign-builder";
import SignHistoryPanel from "../panels/sign-history";
import { Actions, Permissions, userHasAnyRole, userHasPermission } from "../common/services/auth";
import { useGetProject } from "../api/queries/projects";

import { useAddGroupToSign, useAddProjectSign, useCreateProjectSign } from "../api/mutations/signs";
import { useGetSign, useGetSigns } from "../api/queries/signs";
import SignHeaderPanel from "../panels/sign-header";
import storage from "../settings/storage";
import Tooltip from "../components/tooltip";
import { formatDateTime, formatPrice } from "../helpers/utility";
import SelectSignDrawer from "../drawers/sign-select";
import { useAddUserToProject, useBecomeProjectManager, useDeleteProject } from "../api/mutations/projects";
import UserSelectDrawer from "../drawers/drawer-userselect";
import PageLayout from "../components/page-layout";

export default function ProjectPage() {
    const history = useHistory();
    const queryClient = useQueryClient()
    let { id } = useParams();

    const [canEdit] = useState(userHasPermission(Permissions.Projects, Actions.Edit));
    const [canDelete] = useState(userHasPermission(Permissions.Projects, Actions.Delete));

    const [signId, setSignId] = useState(null);

    const projectQuery = useGetProject(id);
    const project = projectQuery.data;

    const signsQuery = useGetSigns({
        project_id: id,
        orderdir: "ASC",
    });    
    const signs = signsQuery.data;

    const signQuery = useGetSign(signId, {}, {enabled: signId!=null});
    const sign = signQuery.data;

    const [loadingTab, setLoadingTab] = useState("");
    const [tabError, setTabError] = useState(null);
    const [selectSign, setSelectSign] = useState(false);
    const [addUser, setAddUser] = useState(false);

    const [cards, setCards] = useState([]);
    const [tabs, setTabs] = useState([
        {name: "overview", title: "Details", icon: faList, component: SignDetailsPanel},
        {name: "groups", title: "Groups", icon: faShapes, component: SignGroupsPanel},
        {name: "builder", title: "Builder", icon: faDesktop, component: SignBuilderPanel},
        {name: "history", title: "History", icon: faHourglass, component: SignHistoryPanel},
    ]);
    const [selectedTab, setSelectedTab] = useState(null);
    
    const createSign = useCreateProjectSign(id);
    const addSign = useAddProjectSign(id);
    const addSignGroup = useAddGroupToSign(signId, {
        onError: () => window.alert("Error updating the sign")
    });
    const becomeManager = useBecomeProjectManager(id);
    const addUserToProject = useAddUserToProject(id);
    const deleteProject = useDeleteProject(id);

    function onSign(sign) {
        setSignId(sign?.id)
        setSelectedTab(tabs.find(t => t.name == "overview"));
    }

    function reloadSigns() {
        queryClient.invalidateQueries(["signs", "list"]);
        if (signId) {
            queryClient.invalidateQueries(["signs", ""+signId]);
        }
    }

    useEffect(
        () => {
            if (!project) return;

            let projectCards = [
                {type: "project", id: project.id},
                {type: "user", id: project.user_id},
                {type: "signs", project_id: project.id, label: "Project", onSign},
                {type: "notes", project_id: project.id},
                {type: "tasks", project_id: project.id},
                {type: "files", project_id: project.id},
                {
                    type: "emails", 
                    user_ids: project.users.map(u => u.id).concat(project.user_id).join(","),
                    send_project_id: project.id
                },
            ]

            if (project.users.length) {
                let index = projectCards.findIndex(c => c.type == "user");

                for (const user of project.users) {
                    projectCards.splice(index+1, 0, {type: "user", id: user.id});
                }
            }

            setCards(projectCards);
        }, 
        [project]
    );

    // only run the first time the page is loaded so that we don't auto assign the manager on
    // every project change
    useEffect(
        () => {
            if (userHasAnyRole(["Sales Manager", "Sales Minor"])) {
                becomeManager.mutate();
            }
        }, 
        []
    );

    useEffect(
        () => {
            if (!sign) return;

            if (sign.custom_ind == 1) {
                setTabs(produce(draft => {
                    draft.find(t => t.name == "builder").disabled = true;
                    // TODO: Add cabinet options?
                    draft.find(t => t.name == "groups").menu = [
                        {name: "addletters", caption: "Add Letters Group", icon:faFont},
                        {name: "addshape", caption: "Add Shape Group", icon:faShapes},
                    ];
                }));
            }

            setCards(produce(draft => {
                let emailIdx = draft.findIndex(d => d.type == "emails");
                let notesIdx = draft.findIndex(d => d.type == "notes");

                if (emailIdx != -1)
                    draft[emailIdx].send_sign_id = sign.id;
                if (notesIdx != -1)
                    draft[notesIdx].sign_id = sign.id;

            }))
        }, 
        [sign]
    );

    const projectIsDeleted = project?.status_id == 99;

    return (
        <PageLayout>
            <PageHeader pageTitle={`Project #${id}`} />        
            <Content loading={projectQuery.isLoading} permission={Permissions.Projects} error={projectQuery.error} padding={1}>
                <>
                    { projectIsDeleted ?
                        <div className="p-2">
                            <Alert variant="danger">Project Is Deleted</Alert>
                        </div>
                    : project ?
                        <CardLayout cards={cards}>
                            <div className="grid gap-1">
                                <h1 className="flex items-center gap-2 text-xl font-bold">
                                    Project #{project.id}

                                    <DropdownMenu
                                        icon={faBars}
                                        className="text-gray-400"
                                        busy={addUserToProject.isLoading || deleteProject.isLoading}
                                        menu={[
                                            {name: "adduser", caption: "Add Additional User", icon:faUser, disabled:!canEdit},
                                            {name: "delete", caption: "Delete Project", icon:faTrash, disabled:!canDelete, confirmation:"Delete this project?"},
                                        ]}
                                        onMenuItem={(item) => {
                                            if (item.name == "adduser") {
                                                setAddUser(true); 
                                            }
                                            else if (item.name == "delete") {
                                                deleteProject.mutate({
                                                    onSuccess: () => history.push("/projects"),
                                                    onError: () => window.alert("Error deleting project")
                                                })                
                                            }
                                        }}
                                    />
                                </h1>

                                <div className="flex items-stretch flex-wrap gap-1">
                                    {(signs || []).map((sign,index) => (
                                        <button 
                                            key={sign.id}
                                            className={clsx(
                                                "rounded border border-gray-100 p-1 text-sm",
                                                sign.id == signId && "bg-purple-100",
                                            )}
                                            onClick={() => onSign(sign)}
                                        >
                                            {/* <Tooltip 
                                                key={sign.id}
                                                align={index == 0 ? "left":"center"}
                                                content={
                                                    <div className="grid text-xs text-left">
                                                        <div>{sign.custom_ind==1 && "Custom"} Design #{sign.id}</div>
                                                        {sign.title && <div>{sign.title}</div>}
                                                        <div className="text-green-500">{formatPrice(sign.estimate)}</div>
                                                        <div>{sign.size_height} x {sign.size_width}</div>
                                                        <div>Added {formatDateTime(sign.added_date)}</div>
                                                    </div>                                                
                                                }
                                            > */}
                                                <div className="flex items-center gap-1">
                                                    <img
                                                        src={storage.root + sign.preview_url}
                                                        style={{
                                                            maxHeight: "30px",
                                                            maxWidth: "60px"
                                                        }}
                                                        onError={(e) => {
                                                            if (e.target.src != (storage.root + "images/nothumbnail.jpg")) {
                                                                e.target.src = storage.root + "images/nothumbnail.jpg";
                                                            }
                                                            e.target.onerror = null; // just in case to prevent recursive error
                                                            e.target.onError = null; // just in case to prevent recursive error
                                                        }}                                                         
                                                    />
                                                    <div>
                                                        #{sign.id}

                                                        <div className="text-xs text-green-500">
                                                            {formatPrice(sign.estimate)}
                                                        </div>
                                                    </div>
                                                </div>
                                            {/* </Tooltip> */}
                                        </button>
                                    ))}

                                    <DropdownMenu
                                        icon={faPlus}
                                        caption="Sign"
                                        size="sm"
                                        direction="down"
                                        align={signs && signs.length > 0 ? "right" : "left"}
                                        className="h-full bg-white border !border-green-500 text-green-500 hover:!bg-green-100"
                                        busy={createSign.isLoading}
                                        disabled={!canEdit || createSign.isLoading}
                                        menu={[
                                            {name: "create", caption: "Create New Sign", icon:faStar},
                                            {name: "browse", caption: "Add Existing Sign", icon:faListAlt},
                                        ]}
                                        onMenuItem={(item) => {
                                            if (item.name == "create") {
                                                createSign.mutate(null, {
                                                    onSuccess: (newSign) => setSignId(newSign.id)
                                                })
                                            }
                                            else if (item.name == "browse") {
                                                setSelectSign(true)
                                            }
                                        }}
                                    />
                                </div>

                                <SignHeaderPanel
                                    signId={signId}
                                    signQuery={signQuery}
                                    size="sm"
                                    onCloned={(result, data) => onSign(result)}
                                    onDeleted={() => setSignId(null)}
                                    onRemovedFromProject={() => setSignId(null)}
                                />

                                <TabList 
                                    tabs={tabs}
                                    selectedTab={selectedTab}
                                    disabled={!sign}
                                    tabBusy={addSignGroup.isLoading}
                                    onTab={(tab) => {
                                        setSelectedTab(tab);
                                    }}
                                    onTabMenuItem={(item) => {
                                        if (item.name.startsWith("add")) {
                                            addSignGroup.mutate(item.name.substring(3));
                                        }
                                    }}
                                />
                            </div>

                            <CardLayoutPanel className="p-1 grid gap-1">
                                {(loadingTab.length > 0) ?
                                    <Loading label={loadingTab} />   
                                : tabError ? 
                                    <Error message={tabError} />                             
                                : (selectedTab && signId) ? 
                                    <selectedTab.component
                                        signId={signId}
                                        onChange={(data) => {
                                            if (selectedTab.name == "builder") {
                                                reloadSigns();
                                                if (data && data.id)
                                                    onSign(data)
                                            }
                                        }}
                                    />  
                                : selectedTab ?
                                    <p>No Sign Selected</p>
                                :
                                    null
                                }
                            </CardLayoutPanel>
                        </CardLayout>
                    :
                        null
                    }
                </>
            </Content>

            {selectSign && 
                <SelectSignDrawer
                    show={true}
                    title="Add Sign To Project"
                    filters={{
                        exists_project_id: project?.id,
                    }}
                    selectMutation={addSign}
                    selectMutationDataProp="id"
                    selectMutationSuccess={(result, data) => {
                        onSign(data)
                        setSelectSign(false);
                    }}
                    onHide={ () => setSelectSign(false) } 
                /> 
            }
            {addUser && 
                <UserSelectDrawer
                    show={true}
                    actions={[
                        {
                            label: "Add User",                        
                            onClick: (userData) => {
                                addUserToProject.mutate(userData.id, {
                                    onError: (error) => {
                                        alert("Error updating project");
                                    },
                                    onSettled: () => {
                                        setAddUser(false)
                                    }            
                                });
                            }
                        }                        
                    ]}
                    onHide={ () => setAddUser(false) } 
                /> 
            }
        </PageLayout>
    );
}