import React, { useState, useEffect, useContext } from "react";
import styled from 'styled-components'
import produce from "immer"
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faTimes, faSpinner, faUser, faSearch } from '@fortawesome/free-solid-svg-icons'

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Button from "../components/buttons/button";

import Loading from "../components/loading"
import SmsMessageList from "../components/sms/message-list"
import ConversationtDrawer from "../drawers/drawer-smshistory"

import { postData } from "../common/services/server";
import { formatPhoneNumber, removeNulls } from "../helpers/utility";
import { colors } from "../settings/settings";
import PageLayout from "../components/page-layout";

const Messages = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;

    > div {
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 5px;
        flex-basis: 25%;
        cursor: pointer;

        &:hover {
            border-color: black;
        }

        &.Received {
            border-color: ${colors.green};            
        }
        .Received {
            background-color: ${colors.lightGreen};
        }

        h2, h3 {
            font-size: 100%;
            margin: 0px;
        }
        h2 {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
`

function SmsMessagesPage(props) {
    const history = useHistory();

    let { tab } = useParams();

    if (!tab) tab = "active";

    const [messages, setMessages] = useState([]);
    const [conversation, setConversation] = useState(null);
    const [search, setSearch] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [busy, setBusy] = useState("");

    const smsListener = (data) => {
        console.log("sms message", data);
        //setRoom(data);
        onNewMessage({...data, isNew: true});
    };

    useEffect(
        () => {
            setIsLoading(true);

            postData("sms/conversations", removeNulls({ 
                status: tab,
                page: 1,
                perPage: tab == "active" ? 999 : 25,
            }),
                function(result) {
                    setMessages(result.messages);
                },
                function(error) {
                    alert("Error loading text messages")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        [tab]
    );

    function onSearch() {
        setBusy("searching")
        setIsLoading(true);

        postData("sms/conversations", removeNulls({ 
            status: tab,
            search,
            page: 1,
            perPage: 99,
        }),
            function(result) {
                setMessages(result.messages);
            },
            function(error) {
                alert("Error loading text messages")
            },
            function() {
                setBusy("")
                setIsLoading(false);
            }
        );        
    }

    function onNewMessage(message) {
        setMessages(produce(draft => {
            const conversation = draft.find(m => m.phoneno == (message.status == "Received" ? message.from : message.to));

            if (conversation)
                conversation.messages.unshift(message)
            else {
                draft.unshift({
                    phoneno: message.to,
                    messages: [message],
                    users: []
                })
            }
        }));

    }

    function onCloseConversation(message) {
        setMessages(produce(draft => {
            const conversation = draft.find(m => m.phoneno == message.phoneno);

            if (conversation) {
                conversation.closing = true;              
            }
        }));

        postData("sms/closeconversations", { 
                phoneno: message.phoneno,
            },
            function() {
                setMessages(produce(draft => {
                    const index = draft.findIndex(m => m.phoneno == message.phoneno);
        
                    if (index != -1) {
                        draft.splice(index, 1);
                    }
                }));                
            },
            function(error) {
                alert("Error closing text conversation.")
            }
        );          
    }

    return (
        <PageLayout>
            <PageHeader 
                title="SMS Manager" 
                tab={tab}
                tabUrl={"/sms/{tab}"}
                tabs={[
                    {name:"active", title:"Active"},
                    {name:"inactive", title:"Inactive"},
                ]}
                onTab={(tab) => {
                    history.push("/sms/" + tab);
                }}                  
                onNewEmail={(email) => {
                    if (email.type == "sms") {
                        onNewMessage(email);
                    }
                }}
            />
            <Content>
            {(tab == "inactive") && 
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Search
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        as="input"
                        value={search}
                        disabled={isLoading || busy}
                        onChange={(event) => {
                            setSearch(event.target.value); 
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onSearch();
                            }
                        }}
                    />
                    <InputGroup.Append>
                        <Button 
                            variant="outline-secondary" 
                            disabled={isLoading || busy || search.length == 0}
                            onClick={() => onSearch()}
                        >
                            <FontAwesomeIcon icon={busy == "searching" ? faSpinner : faSearch} spin={busy == "searching"} />
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            }                
            {
                isLoading 
            ?
                <Loading />
            :
                messages.length == 0 ?
                <p>
                    No messages found
                </p>    
            :
                <Messages>
                    {messages.map((conversation,index) => (
                        <div 
                            key={index} 
                            className={conversation.messages.length ? conversation.messages[0].status : ""}
                            onClick={() => setConversation(conversation)}
                        >
                            <h2>
                                <div>
                                    <FontAwesomeIcon icon={faPhone} style={{marginRight:"5px"}} />
                                    {formatPhoneNumber(conversation.phoneno)}
                                </div>
                                {(tab == "active") && 
                                    <Button
                                        size="sm"
                                        variant="outline-secondary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            
                                            if (window.confirm("Close this text conversation?")) {
                                                onCloseConversation(conversation);
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon icon={conversation.closing ? faSpinner : faTimes} spin={conversation.closing} />
                                    </Button>
                                }
                            </h2>
                                
                            {(conversation.users && conversation.users.length > 0) &&
                                <h3>
                                    {conversation.users.map((user, index) => (
                                        <div key={index}>
                                            <FontAwesomeIcon icon={faUser} style={{marginRight:"5px"}} />
                                            <a href={"#/users/user/" + user.id} target="_blank">
                                                {user.name}
                                            </a>
                                        </div>
                                    ))}
                                </h3>
                            }

                            <div style={{marginTop: "8px"}}>
                                <SmsMessageList 
                                    messages={conversation.messages}
                                    onMessage={() => setConversation(conversation)} 
                                />
                            </div>
                        </div>
                    ))}
                </Messages>
            }
            </Content>

            {conversation && 
                <ConversationtDrawer 
                    show={true}
                    phoneno={conversation.phoneno}
                    onMessage={ (message) => {
                        conversation.messages.unshift(message)
                        setConversation(null);
                    }} 
                    onHide={ () => setConversation(null) } 
                />                 
            }

            {/* { (mode && mode.type == "message") && 
                <EmailDrawer 
                    show={true}
                    email={{
                        type: "sms",
                        to: mode.conversation.phoneno
                    }}
                    onSaved={ (email) => {
                        mode.conversation.messages.unshift(email)
                        setMode({});
                    }} 
                    onHide={ () => setMode({}) } 
                /> 
            }         */}
        </PageLayout>
    );
}

export default SmsMessagesPage;