import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"
import styled from "styled-components"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faDesktop } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

const Task = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;

    &:hover {
        background-color: #eee;
    }
`

function StationDrawer(props) {
  const formRef = useRef(null);

  const [tasks, setTasks] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(props.station);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
      postData("workflows/items", { type: "task" }, 
        function(response) {
          for (let task of response) {
              if (task.station_id == props.station.id)
                  task.selected_ind = 1;
          }

          setTasks(response)
        },
        function(error) {
          alert("An error occured task data.");
        }
      );
  }, []);

  useEffect(() => {
    console.log("setting station data", props.station)
    if (props.station) {
      setFormData(props.station);
    }
  }, [props.station]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      formData.tasks = [];

      for (const task of tasks) {
        if (task.selected_ind == 1)
            formData.tasks.push(task.id);
      }

      postData("stations/save", removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the station.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function onTaskChange(e) {
    console.log("task changed", e.target.value, e.target.checked)
    const taskTypeId = e.target.value;
    const checked = e.target.checked;

    setTasks(produce(draft => {
        const task = draft.find(t => t.id == taskTypeId);

        task.selected_ind = checked ? 1 : 0;
    }));
  }

  function handleFormChange(event) {
    console.log("handleFormChange", event)
    //console.log("handleFormChange", event.target.name, event.target.value)
    setFormData(produce(draft => {
      draft[event.target.name] = event.target.value;
    }));
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faDesktop} />
            &nbsp;
            Production Station
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>

            <TextField 
                name="name" 
                label="Name" 
                required 
                value={formData.name} 
                onChange={(e) => handleFormChange(e)} />

            <div style={{marginTop: "5px", display: "flex", justifyContent: "space-between"}}>
                <strong>Workflow Task</strong>
                <strong>Station</strong>
            </div>

            {tasks.map(task => (
                <Task key={task.id}>
                    <Form.Check 
                        name="tasktype_id"                                
                        value={task.id}
                        checked={task.selected_ind == 1}
                        inline 
                        label={task.product ? task.name + " - " + task.product : task.name} 
                        style={{color: !task.station_id ? "red":"black"}}
                        type="checkbox" 
                        onChange={onTaskChange}
                    />

                    {task.station && 
                        <div style={{color:"#999", fontSize:"80%"}}>
                            <FontAwesomeIcon icon={faDesktop} />{' '}
                            {task.station}
                        </div>
                    }
                </Task>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Station" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default StationDrawer;