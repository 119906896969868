import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSpinner } from '@fortawesome/free-solid-svg-icons'

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

import { postData } from "../../common/services/server"
import storage from '../../settings/storage'

const Sign = styled.div`
    cursor: pointer
    display: flex;
    font-size: 14px;
    line-height: initial;

    img {
        margin-right: 10px;
    }

    div.user {
        font-size: 12px;
    }

    small {
        color: #999;
    }
`

function SignSelect(props) {
    const selectRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [signs, setSigns] = useState([]);

    useEffect(
        () => {
            if (!props.default) {
                selectRef.current.getInstance().clear()
            }
        }, 
        [props.default]
    );

    function handleSearch(query) {
        setIsLoading(true);

        //Sign #300411
        console.log(query.substring(0, 6))
        if (query.substring(0, 6) == "Sign #") {
            query = query.substring(6)
        }

        postData("search/signs", { search: query },
            function(matches) {
                setSigns(matches);
            },
            function(error) {
                console.log(error);
            },
            function() {
                setIsLoading(false);
            }
        );
    }

    return (
        <AsyncTypeahead
          id={props.name}
          ref={selectRef}
          options={signs}
          useCache={false}
          allowNew={false}
          isLoading={isLoading}
          labelKey="title"
          minLength={2} 
          defaultInputValue={props.default ? props.default.title || props.default.id || "" : ""}
          inputProps={{ required: props.required }}
          onSearch={handleSearch}
          onChange={ (selected) => props.onChange(selected[0]) }
          placeholder="Search for sign..."
          searchText={<><FontAwesomeIcon icon={faSpinner} spin />&nbsp;Searching...</>}
          filterBy={(option, props) => {
            return true;
          }}
          renderMenuItemChildren={(sign, props) => (              
            <Sign key={sign.id}>
                <div className="icon">
                    <img src={storage.root + (sign.preview_url || "images/nothumbnail.png")} width={40} style={{minWidth:"40px"}} />
                </div> 
                <div>
                    <div>
                        Design <strong>#{sign.id}</strong>
                    </div>
                    <div>
                        {sign.subtitle && <small>{sign.subtitle}</small>}
                    </div>
                    {sign.user_name && 
                        <div className="user">
                            <FontAwesomeIcon icon={faUser} />
                            {sign.user_name}
                        </div>
                    }
                </div>
            </Sign>
          )}
        />
    );
  }

export default SignSelect