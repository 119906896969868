export function getMwoStickerTypes() {
    return [
        {value: "individual", label: "Single Sticker"},
        {value: "shapes", label: "1 Sticker Per Shape"},
        {value: "shapes2", label: "2 Stickers Per Shape"},
        {value: "shapes3", label: "3 Stickers Per Shape"},
        {value: "shapes4", label: "4 Stickers Per Shape"},
        {value: "shapes5", label: "5 Stickers Per Shape"},
        {value: "shapesides", label: "1 Sticker Per Shape Side"},
        {value: "shapesfaces", label: "1 Sticker Per Shape Face"},
        {value: "shapesfacesignorebridge", label: "1 Sticker Per Shape Face (ignore bridge)"},
        
        {value: "shapesprodfaces1", label: "1 Sticker Per Product Face"},
        {value: "shapesprodfaces2", label: "2 Stickers Per Product Face"},
        {value: "shapesprodfaces3", label: "3 Stickers Per Product Face"},
        {value: "shapesprodfaces4", label: "4 Stickers Per Product Face"},
        
        {value: "shapesprewire", label: "1 Sticker Per Shape Prewire Sticker"},
        {value: "powersets", label: "1 Sticker Per Powerset"},
        {value: "powersets_powered", label: "1 Sticker Per Powerset with Power"},
        {value: "powersets_power", label: "1 Sticker Per Powerset Power"},
        {value: "count", label: "Manual Sticker Count"},
        {value: "count_sticker", label: "Single Sticker with Count"},
        {value: "quantity", label: "Single Sticker with Quantity"}
    ];
}
export function getWorkflowMwoStickerTypes() {
    return [
        {value: "individual", label: "1 Sticker"},

        {value: "shapes", label: "1 Sticker Per Shape"},
        {value: "shapesides", label: "1 Sticker Per Shape Side"},
        {value: "shapesfaces", label: "1 Sticker Per Shape Face"},
        // {value: "shapesfacesignorebridge", label: "1 Sticker Per Shape Face (ignore bridge)"},

        {value: "single_quantity", label: "1 Sticker with Quantity"},
        {value: "quantity", label: "1 Sticker Per Quantity"},

        {value: "shapesprewire", label: "1 Sticker Per Shape Prewire Sticker"},

        {value: "powersets", label: "1 Sticker Per Powerset"},
        {value: "powersets_powered", label: "1 Sticker Per Powerset with Power"},
        {value: "powersets_power", label: "1 Sticker Per Power Supply"},
    ];
}
export function getTitleForMwoStickerType(value) {
    let types = getMwoStickerTypes();
    let type = types.find(t => t.value == value);

    if (type)
        return type.label;
}