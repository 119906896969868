import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faSyncAlt, faSpinner, faHeart, faLock, faUnlock, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import NumberField from "../components/fields/field-number"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'
import Loading from "../components/loading";

function ShapeSizeDrawer(props) {
  const formRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState("");
 
  const [formData, setFormData] = useState(props.shape);
  const [sizes, setSizes] = useState([]);

  const [locked, setLocked] = useState(true);
  const [dirty, setDirty] = useState(false);
  const [validated, setValidated] = useState(false);

  useEffect(() => {

    if (props.shape) {
      setIsLoading(true);

      postData("shapes/shapedata", { 
         shape_id: props.shape.id,
         size: props.size
      },
          function(result) {
            const {height, width} = result.size;

            setFormData({
              ...result,
              height,
              width,
              height_ratio: width/height,
              width_ratio: height/width
            });
            setSizes(result.sizes);
          },
          function(error) {
              alert("Error loading shape data");
          },
          function () {
            setIsLoading(false);
          }
      ); 
    }
  }, [props.shape]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));

    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing("save");

      let data = removeNulls(formData);

      let size = props.size;
      let width = parseFloat(data.width);
      let height = parseFloat(data.height);

      const sizeHeight = height/7;
      const sizeWidth = width/7;
      const area = Math.round(sizeHeight * sizeWidth);
      const sqrt = Math.round(Math.sqrt(area));

      let heightRatio = sizeHeight / sqrt;
      let widthRatio = sizeWidth / sqrt;

      const adjustment = height / (heightRatio * size);

      widthRatio = widthRatio * adjustment;
      heightRatio = heightRatio * adjustment;

      data.height_ratio = heightRatio;
      data.width_ratio = widthRatio;

      postData("shapes/savesize", data, 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the shape.");
        },
        function() {
          setIsProcessing("");
        }
      );
    }
  }

  function handleFormChange(event) {
      if (event.persist) event.persist();

      const prop = event.target.name;
      const value = parseFloat(event.target.value);

      setFormData(produce(draft => {
        draft[prop] = Math.round(value);

        if (locked) {          
          if (prop == "height") {
            console.log(draft.height_ratio)
            draft.width = Math.round(draft.height * draft.height_ratio);
          }
          else if (prop == "width") {
            console.log(draft.width_ratio)
            draft.height = Math.round(draft.width * draft.width_ratio);
          }
        }


      }));
      setDirty(true);
  }

  function toggleLock() {
    setLocked(!locked);
  }

  function onUpdateSizes() {
  /*
    setIsProcessing("sizes");
    setDirty(false);

    postData("shapes/shapedata", { 
        shape_id: props.shape.id,
        size: props.size,
        width_ratio: formData.width_ratio,
        height_ratio: formData.height_ratio
    },
        function(result) {
          setSizes(result.sizes);
        },
        function(error) {
            alert("Error loading size data");
        },
        function () {
          setIsProcessing("");
        }
    );     
  */
}

  const product = props.group.product;

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faHeart} />
            &nbsp;
            Shape Dimensions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? 
            <Loading />
          :  
            <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>

            {(product && product.material_min) &&
              <div style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "red",
                marginBottom: "5px"
              }}> 
                <FontAwesomeIcon icon={faExclamationTriangle} />
                Product has a material minimum size of {product.material_min}"
              </div>
            }
            {(product && product.material_max_1) &&
              <div style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "red",
                marginBottom: "5px"
              }}> 
                <FontAwesomeIcon icon={faExclamationTriangle} />
                Product has a material maximum size of {product.material_max_1}"
                {product.material_max_2 && 
                  '/' + product.material_max_2 + '"'
                }
              </div>
            }  

            <p>What are the dimensions of the complete letters/shape<br /> at <strong>Size {props.size}</strong></p>

            <div style={{display: "flex", alignItems: "center"}}>
                <div>
                  <div>
                    <NumberField
                        name="height"
                        label="Height"
                        value={formData.height}
                        required
                        margin="dense"
                        onChange={handleFormChange}
                    />
                  </div>
                  <div>
                    <NumberField
                        name="width"
                        label="Width"
                        value={formData.width}
                        required
                        margin="dense"
                        onChange={handleFormChange}
                    />
                  </div>
                </div>
                <div style={{padding: "10px"}}>
                    <FontAwesomeIcon 
                      icon={locked ? faLock : faUnlock} 
                      onClick={toggleLock} 
                      style={{color: locked ? "gold":"#ccc"}}
                      size="3x" 
                    />
                </div>
            </div>

            { false && 
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div>

                <NumberField 
                    name="width_ratio" 
                    label="Width Ratio" 
                    required
                    step={0.01}
                    value={formData.width_ratio} 
                    onChange={(e) => handleFormChange(e)} />
                <NumberField 
                    name="height_ratio" 
                    label="Height Ratio" 
                    required
                    step={0.01}
                    value={formData.height_ratio} 
                    onChange={(e) => handleFormChange(e)} />

              </div>
              <div style={{padding: "0px 10px"}}>
                    <div style={{textAlign:"center"}}>
                      <strong>Sample Sizes</strong>
                    </div>
                    <Table bordered striped style={{width:"100%"}}>
                        <tbody>
                        {sizes.map(size => (
                        <tr key={size}>
                            <th>Size {size.size}:</th>
                            <td>{size.height}" x {size.width}"</td>
                        </tr>  
                        ))}      
                    </tbody>
                    </Table>
                    <Button variant={dirty ? "success":"outline-secondary"} size="sm" block onClick={onUpdateSizes}>
                        <FontAwesomeIcon icon={isProcessing == "sizes" ? faSpinner : faSyncAlt} spin={isProcessing == "sizes"} />{' '}
                        Refresh Sizes
                    </Button>
              </div>
            </div>
            }

          </Form>
          }
          </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing == "save"}
                onClick={onSave} 
                variant="outline-success" 
                caption="Update Shape" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ShapeSizeDrawer;