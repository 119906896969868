import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faScrewdriver, faUserLock } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import TextAreaField from "../components/fields/field-textarea"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

function RoleDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (props.role) {
      setFormData(JSON.parse(JSON.stringify(props.role)));
    }
  }, [props.role]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      let data = {...formData};

      if (props.skill)
        data.name = "Production - " + data.name;

      postData("roles/save", removeNulls(data), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the role.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();

    setFormData(produce(draft => {
      draft[event.target.name] = event.target.value;
    }));
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={props.skill ? faScrewdriver:faUserLock} />
            &nbsp;
            {props.skill ? "Skill":"Role"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
            <TextField 
                name="name" 
                label="Name" 
                required
                value={formData.name} 
                onChange={(e) => handleFormChange(e)} />   
            <TextAreaField 
                name="description" 
                label="Description" 
                value={formData.description} 
                rows={3}
                onChange={(e) => handleFormChange(e)} />                   
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption={"Save " + (props.skill ? "Skill":"Role")} 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default RoleDrawer;