import React from 'react';
import { useParams } from "react-router-dom";
import { PDFViewer, Document, Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { useGetInstallationCoverPage } from '../../api/queries/pdfs';
import Loading from '../loading';
import storage from '../../settings/storage';
import Error from '../error';
import { formatDate } from '../../helpers/utility';

const styles = StyleSheet.create({
    viewer: {
        height: "100vh"
    },
    header: { 
        fontWeight: "extrabold",
        fontSize: "15px",
        marginBottom: "5px",
    },
    paragraph: {
        fontSize: "10px",
    },
    label: {
        fontWeight: "extrabold",
        width: "120px",
        backgroundColor: "#eee",
        padding: "6px",
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
    },
    labelRows: {
        display: "flex",
        gap: "3px",
    },
    labelRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        border: "1px solid #ccc",
        borderRadius: "4px",
    }
});

export default function InstallationCoverPagePDF() {
    let { orderItemId } = useParams();

    const query = useGetInstallationCoverPage(orderItemId);
    const sign = query.data;
   
    const regex = /(<([^>]+)>)/gi;
    let description = sign?.description?.replace(regex, "");

    return (
      <>
        {query.isLoading ?
            <Loading />
        : query.isError ?
            <Error message={query.error.message} />
        :
            <PDFViewer width="100%" showToolbar={true} style={styles.viewer}>
                <Document>
                    <Page size="LETTER" orientation="landscape" wrap={false}>
                        <View style={{
                            border: "3px solid black",
                            flex: 1,
                            height: "100%",
                            margin: "0px",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            fontSize: "12px"
                        }}>
                            <View style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                                <Image 
                                    src={`https://storage.googleapis.com/signmonkey-148101.appspot.com/${sign.company=="paris" ? "files/companies/paris/mwo.png?v3":"images/logo.png"}`}
                                    style={{
                                        height: "50px"
                                    }}
                                />
                                <Text style={{
                                    fontWeight: "bold",
                                    fontSize: "20px"
                                }}>
                                    INSTALLATION INSTRUCTIONS
                                </Text>
                            </View>
                            <View>
                            <View style={{
                                border: "1px solid #ccc",
                                borderRadius: "10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "5px",
                            }}>
                                <Image 
                                    src={storage.root + sign.preview_url}
                                    style={{
                                        maxHeight: "150px",
                                        maxWidth: "600px",
                                        objectFit: "scale-down",
                                    }}
                                />
                            </View>
                            </View>
                            <View style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                                <View>
                                    {(sign.custom_ind == 1) &&
                                        <Text>
                                            * Customer's drawing may not be to scale
                                        </Text>
                                    }
                                </View>
                                {sign.lit_ind == 1 &&
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "5px",
                                    }}>
                                        <Image 
                                            src="https://storage.googleapis.com/signmonkey-148101.appspot.com/images/ul.png"
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                            }}
                                        />                            
                                        <Text>
                                            All our signs are inspected and have the UL label
                                        </Text>
                                    </View>
                                }
                            </View>
                            <View style={{
                                flex: 1,
                            }}>
                                <Text style={styles.header}>Description:</Text>
                                {(sign.size_height != 0 && sign.size_width) && 
                                    <View>
                                        <Text>{sign.size_height}" x {sign.size_width}", {(parseFloat(sign.size_height)*parseFloat(sign.size_width)/12/12).toFixed(2)} square feet.</Text>
                                    </View>
                                }
                                <View style={styles.paragraph}>
                                    {description?.split("&middot;").filter(l => l.trim().length > 0).map((line, index) => (
                                        <View key={index}><Text>- {line}</Text></View>
                                    ))} 
                                </View>
                                {(sign.option) && 
                                    <View>
                                        <Text>{sign.options}</Text>
                                    </View>
                                }

                                <View style={{display: "flex", flexDirection: "row", gap: "15px", marginTop: "10px"}}>
                                    <View style={{width: "50%"}}>
                                        <Text style={styles.header}>Sign Includes:</Text>
                                        <Text>Your sign will come complete with everything you need to install and light your sign. The only part you will need to supply is screws from you local hardware store to attach your sign to the wall.</Text>
                                    </View>
                                    <View style={[styles.labelRows,{width: "50%"}]}>
                                        <View style={styles.labelRow}>
                                            <Text style={styles.label}>ORDER DATE</Text>
                                            <Text>{formatDate(sign.order_date)}</Text>
                                        </View>
                                        <View style={styles.labelRow}>
                                            <Text style={styles.label}>SHIP DATE</Text>
                                            <Text>{formatDate(sign.ship_date)}</Text>
                                        </View>
                                        <View style={styles.labelRow}>
                                            <Text style={styles.label}>JOBS</Text> 
                                            <Text>#{sign.job_ids}</Text>
                                        </View>
                                        <View style={styles.labelRow}>
                                            <Text style={styles.label}>SIGN DESIGN</Text> 
                                            <Text>#{sign.id}</Text>
                                        </View>
                                        <View style={styles.labelRow}>
                                            <Text style={styles.label}>CUSTOMER EMAIL</Text>
                                            <Text>{sign.user_email}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{
                                textAlign: "center",
                                color: "#999",
                            }}>
                                <Text>Copyright © {new Date().getFullYear()} Paris Inc. DBA SignMonkey.com. All rights reserved.</Text>
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        }
      </>
    )
}