import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { faList, faPencilAlt, faPlus, faSave, faSpinner, faTrash, faUser, faChevronCircleRight, faChevronCircleLeft, faTimes, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import produce from "immer"

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading";
import TaskForm from "../components/forms/form-task"
import TextField from "../components/fields/field-text"
import SelectField from "../components/fields/field-select"
import TextAreaField from "../components/fields/field-textarea"
import HtmlField from "../components/fields/field-html"
import TaskRow from "../components/tasks/task-row"
import ListField from "../components/fields/field-list"
import TaskDrawer from "../drawers/drawer-task"
import GoogleImage from "../components/google-image"
import Button from "../components/buttons/button";

import settings from '../settings'
import { getUser } from "../common/services/auth";
import { postData, postExternalData } from "../common/services/server"
import { colors } from "../settings/settings";
import { formatPrice, formatDateTime, removeNulls } from "../helpers/utility"
import PageLayout from "../components/page-layout";

const Panels = styled.div`
    display: flex;

    div.lists {
        padding: 8px;
        height: calc(100vh - 95px);
        position: relative;
        border-right: 1px solid #eee;
        background-color: #eee;            

        svg {
            margin-right: 5px;
        }

        div.standard {
            height: calc(100vh - 310px);
            overflow: auto;
            background-color: #eee;  
            font-size: 90%;

            > div {
                border-bottom: 1px solid white;
                border-left: 1px solid white;
                border-right: 1px solid white;
                background-color: #eee;
            }
        }        
        div.actions {
            position: absolute;
            left: 0px;
            bottom: 102px;
            right: 0px;
            border-bottom: 1px solid #eee;
            background-color: white;

            > div {
                display: flex;
                justify-content: normal;
                flex-flow: column;
                align-items: normal;                
            }
        }
        div.secondary {
            position: absolute;
            left: 0px;
            bottom: 0px;
            right: 0px;
        }

        > div > div {
            display: flex;
            align-items: center;
            justify-content: space-between; 
            cursor: pointer;           
            padding: 5px;
            background-color: white;
          
            &.active {
                background-color: ${colors.lightPurple};
            }            
            &:hover {
                background-color: ${colors.lightPurpleHover};
            }
        }

        .badge {
            float: right;
            margin-left: 5px;
        }
    }
    div.tasks {
        position: relative;
        border-left: 1px solid white;
        border-right: 1px solid #e0e0e0;

        div.tasklist {
            max-height: calc(100vh - 165px);
            overflow: auto;
            padding: 0px 0px 50px 0px;
            
        }
        div.actions {
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            border-top: 1px solid #e0e0e0;
            background-color: white;
            padding: 10px;

            svg {
                margin-right: 5px;
            }
            > * {
                cursor: pointer;
            }
        }

        div.task {
            border-bottom: 1px solid #eee;

            &.active {
                background-color: #eee;
            }            
        }
    }
    > div.task {
        display: flex;
        flex-direction: column;                
        flex: 1;
        position: relative;

        > div:nth-child(1) {
            margin: 5px;
        }
    }
    > div.info {
        border-left: 1px solid #eee;
        max-width: 300px;
        max-height: calc(100vh - 95px);
        overflow: auto;
        min-width: 200px;

        > div > div {
            border-bottom: 1px solid #eee;
            padding: 5px;
            background-color: white;
            cursor: pointer;
            font-size: 80%;
            display: flex;

            > div {
                flex-basis: 50%;

                > div {
                    white-space: nowrap;
                }
            }

            img {
                max-height: 60px;
                margin-right: 5px;
            }
            &:hover {
                background-color: #eee;
            }
        }     
    }
    > div.followup {
        position: relative;
        padding: 10px;
        width: 400px;
        min-width: 400px;
        flex: 0;
        background-color: #eee;

        div.actions {
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            border-top: 1px solid #e0e0e0;
            border-left: 1px solid #e0e0e0;
            background-color: white;
            padding: 10px;

            svg {
                margin-right: 5px;
            }
            > * {
                cursor: pointer;
            }
        }           
    }

    h2 {
        margin: 0px;
        padding: 8px;
        background-color: ${colors.lightPurple};
        font-size: 100%;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: capitalize;

        .custom-switch {
            padding-left: 2.5rem;
            margin-right: -9px;
        }

        svg {
            margin-left: 8px;
            cursor: pointer;
        }
    }
`
const InfoPanel = styled.div`
    position: fixed;
    left: 56px;
    top: 49px;
    right: 450px;
    bottom: 0px;
    background-color: rgba(255,255,255,.8);
    backdrop-filter: blur(3px);
    z-index: 99;
    
    button {
        position: absolute;
        right: 10px;
        top: 10px;
        border: 1px solid #ccc;
        padding: 5px;
        border-radius: 3px;
        background-color: white;
        z-index: 99;

        &:hover {
            background-color: #eee;
        }
    }

    iframe {
        border: 1px solid #ccc;
        position: absolute;
        left: 10px;
        top: 10px;
        bottom: 24px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        box-shadow: 3px 3px 6px #999;            
    }    
`
const Label = styled.div`
    padding: 0px 3px;
    background-color: #eee;
    display: inline-block;
    border-radius: 3px;
    line-height: 1em;

    &.sent {
        background-color: #e7f7e7;
      }
      &.delivered {
        background-color: #c4f5c4;
      }
      &.bounced {
        background-color: #dc3545;
        color: white;
      }
      &.dropped {
        background-color: #dc3545;
        color: white;
      }
      &.clicked {
        background-color: #338e33;
        color: white;
      }
      &.opened {
        background-color: #5ebb5e;
        color: white;
      }
`

function TasksPage(props) {
    const user = getUser();
    const history = useHistory();
    let { type, id } = useParams();

    const [loading, setLoading] = useState("");
    const [lists, setLists] = useState([]);
    const [list, setList] = useState(null);
    const [listData, setListData] = useState(null);

    const [tasks, setTasks] = useState([]);
    const [tasksPage, setTasksPage] = useState(1);
    const [pagination, setPagination] = useState()
    const [task, setTask] = useState(null);
    
    const [infoType, setInfoType] = useState(null);
    const [infoData, setInfoData] = useState(null);
    const [infoItem, setInfoItem] = useState(null);
    const [emailTemplates, setEmailTemplates] = useState([]);

    const [formData, setFormData] = useState({});
    const [selected, setSelected] = useState({})
    const [search, setSearch] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [sortBy, setSortyBy] = useState("due_date");

    const [newListTitle, setNewListTitle] = useState("");

    useEffect(
        () => {
            console.log("params changes", type, id);

            if (type == "task" && id) {
                //onTask({id});
                setLoading("task");
        
                postData("tasks/taskdata", { id },
                    function(result) {
                        setTask(result);
                    },
                    function(error) {
                        window.alert("Error loading task.");
                    },
                    function() {
                        setLoading("");
                    }
                );                
            }
            else if (id) {
                setList({name: type, id});
            }
        }, 
        [type, id]
    );

    useEffect(
        () => {
          setLoading("lists");

          postData("tasks/lists", {},
              function(result) {
                setLists(result);

                // do we have a list for the current user?  if so, then auto select
                if (!type) {
                    let found = false;

                    for (const lst of result) {
                        if (lst.name == "assigned" && lst.id == user.id) {
                            setList(lst);
                            found = true;
                        }
                    }

                    if (!found) {
                        setList(result.find(lst => lst.name == "unassigned"));
                    }
                }

                if (!list && type == "list") {
                    setListData(result.find(l => l.name == type && l.id == id));
                }
              },
              function(error) {
                window.alert("Error loading task data.");
              },
              function() {
                  setLoading("");
              }
          );
        }, 
        []
    );

    useEffect(
        () => {
            console.log("list changed", list)
            if (list) {
                setLoading("tasks");

                postData("tasks/data", { 
                    list_name: list.name, 
                    list_id: list.id, 
                    search,
                    sort: sortBy, 
                    sortdir: "asc",
                    page: tasksPage
                },
                    function(result) {
                        setTasks(result.tasks);
                        setPagination(result.pagination);
                    },
                    function(error) {
                        window.alert("Error loading tasks.");
                    },
                    function() {
                        setLoading("");
                    }
                );
            }

            setListData(list ? lists.find(l => l.name == list.name && l.id == list.id) : null);
        }, 
        [list, sortBy, tasksPage, search]
    );

    function onList(list) {
        console.log("onList", list)
        //setList(list);
        setSearch("");
        setTasksPage(1);
        setTask(null);
        setInfoType(null);

        history.push("/tasks/" + list.name + "/" + list.id)
    }

    function onTask(task) {
        setInfoType(null);
        history.push("/tasks/task/" + task.id)
    }
    function onCreateList() {
        setSelected({mode: "createlist"})
    }
    function onCreateTask() {
        setSelected({mode: "createtask"})
    }

    function onPrevTasksPage(e) {
        e.preventDefault();
        e.stopPropagation();

        setTasksPage(state => state-1);
    }
    function onNextTasksPage(e) {
        e.preventDefault();
        e.stopPropagation();

        setTasksPage(state => state+1);
    }

    function onSaveList() {
        setLoading("lists");
        setSelected({})

        postData("tasks/savelist", {
                title: newListTitle
            },
            function(result) {
                setLists(produce(draft => {
                    draft.push(result);
                }));                

                setList(result)
                setNewListTitle("");
            },
            function(error) {
                window.alert("Error saving list.");
            },
            function() {
                setLoading("");
            }
        );        
    }    
    function onCancelList() {
        setSelected({})
    }
    function onEditList() {
        onTask(list);
    }
    function onDeleteList() {
        if (window.confirm("Delete this list and all of its tasks?")) {
            setLoading("deletelist");

            postData("tasks/deletelist", {
                    list_id: list.id
                },
                function(result) {
                    setLists(produce(draft => {
                        const index = draft.findIndex(l => l.name == list.name && l.id == list.id);

                        if (index != -1)
                            draft.splice(index, 1);
                    }));                

                    setList(null)
                },
                function(error) {
                    window.alert("Error deleting list.");
                },
                function() {
                    setLoading("");
                }
            );             
        }
    }
    function onToggleListComplete(complete) {
        // do we have any uncomplete tasks left in the list?
        const activeTasks = tasks ? tasks.filter(t => t.status == "Active") : [];

        if (!complete || activeTasks.length ==0) {
            if (!complete || window.confirm("Are you sure you want to mark this list as complete?")) {
                setLoading("completelist");

                postData("tasks/" + (complete ? "complete":"uncomplete"), {
                        task_id: list.id
                    },
                    function(result) {
                        setLists(produce(draft => {
                            const index = draft.findIndex(l => l.name == list.name && l.id == list.id);

                            if (index != -1 && complete) 
                                draft.splice(index, 1);                                
                            else if (index == -1 && !complete)
                                draft.push(result);
                        }));  
                        
                        setList(result);
                        setListData(result);
                    },
                    function(error) {
                        window.alert("Error updating list.");
                    },
                    function() {
                        setLoading("");
                    }
                ); 
            }
        }
        else {
            window.alert("List still has active tasks.")
        }
    }

    function handleFormChange(event) {
        if (event.persist) event.persist();
    
        let field = event.target.name;
        let value = event.target.value;
    
        setFormData(produce(draft => {
          draft[field] = value;
        }));
    }

    function onSendEmail() {
        if (!formData.subject || formData.subject.length == 0) {
            window.alert("Email subject is required.");
            return;
        }
        if (!formData.email || formData.email.length == 0) {
            window.alert("Email message body is required.");
            return;
        }

        setLoading("sendemail");

        postExternalData(settings.SITE_API_URL + "emails/send", removeNulls({
            template: "simple",
            from: user.first_name.toLowerCase() + "@signmonkey.com",
            to: task.user_email,
            subject: formData.subject,
            preview: formData.preview || null,
            body: formData.email,
            task_id: task.id
        }), 
            function(response) {
                postData("tasks/complete", {
                        task_id: task.id
                    },
                    function(taskData) {
                        const {lists, ...task} = taskData;

                        console.log("onTaskUpdted", taskData);

                        setTasks(produce(draft => {
                            const index = draft.findIndex(t => t.id == task.id);

                            console.log(index);

                            draft[index] = task;
                        }))

                        setTask(null);
                        setInfoItem(null);
                        setInfoType(null);
                    },
                    function(error) {
                        window.alert("Error updating task.");
                    },
                    function() {
                        setLoading("");
                    }
                );                  
            },
            function(error) {
                alert("An error occured sending the email.");
            }
        );        
    }

    return (
        <PageLayout>
            <PageHeader 
                title="Task Manager"
                help="tasks"
            />
            <Content padding={1}>
                <Panels>
                    <div className="lists">
                        {loading == "lists" ? 
                            <Loading /> 
                        :
                            <>
                                <div className="featured">
                                    {lists.filter(l => l.priority <= 10).map((lst,index) => (
                                        <div key={index} onClick={() => onList(lst)} className={list && list.name==lst.name ? "active":""}>
                                            {lst.title}
                                            <Badge variant="danger">{lst.tasks_count}</Badge>
                                        </div>
                                    ))}
                                </div>
                                <div className="standard">
                                    {lists.filter(l => l.name == 'assigned' && l.priority > 10 && l.priority < 90).map((lst,index) => (
                                        <div key={index} onClick={() => onList(lst)} className={list && list.id==lst.id ? "active":""}>
                                            <div>
                                                <FontAwesomeIcon icon={faUser} />
                                                {lst.title}
                                            </div>
                                            <Badge variant="warning">{lst.tasks_count}</Badge>
                                        </div>
                                    ))}
                                    {lists.filter(l => l.name != 'assigned' && l.priority > 10 && l.priority < 90).map((lst,index) => (
                                        <div key={index} onClick={() => onList(lst)} className={list && list.id==lst.id ? "active":""}>
                                            <div>
                                                <FontAwesomeIcon icon={faList} />
                                                {lst.title}
                                            </div>
                                            <Badge variant="warning">{lst.tasks_count}</Badge>
                                        </div>
                                    ))}
                                </div> 
                                <div className="actions">
                                    {selected.mode == "createlist" ? 
                                        <div style={{width:"100%"}}>
                                            <TextAreaField 
                                                name="title" 
                                                value={newListTitle} 
                                                autofocus
                                                rows={2}
                                                groupStyle={{marginBottom:"5px"}}
                                                onChange={(e) => {
                                                    setNewListTitle(e.target.value);
                                                }} />

                                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                                <div onClick={onSaveList}>
                                                    <FontAwesomeIcon icon={faSave} />
                                                    Save List
                                                </div>
                                                <div onClick={onCancelList}>
                                                    Cancel
                                                </div>                                                
                                            </div>                                            
                                        </div>
                                        :                                        
                                        <div onClick={onCreateList}>
                                            <div>
                                                <FontAwesomeIcon icon={faPlus} />
                                                Create List
                                            </div>
                                        </div>
                                    }
                                </div>                                                       
                                <div className="secondary">
                                    {lists.filter(l => l.priority >= 90).map((lst,index) => (
                                        <div key={index} onClick={() => onList(lst)} className={list && list.name==lst.name ? "active":""}>
                                            {lst.title}
                                            <Badge variant={lst.name == "complete" ? "success" : "secondary"}>
                                                {lst.tasks_count}
                                            </Badge>
                                        </div>
                                    ))}
                                </div>
                            </>
                        }
                    </div>
                    <div className="tasks">
                        {loading == "tasks" ? 
                            <div><Loading /> </div>
                        : list ?
                            
                            <>
                                <h2>
                                    <div>
                                        {listData ? listData.title : ""} Tasks
                                        {list.name == "list" && 
                                            <FontAwesomeIcon 
                                                icon={faPencilAlt} 
                                                onClick={onEditList}
                                            />
                                        }     
                                    </div>

                                    {(listData && listData.name == "list") && 
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip>{listData.status == "Complete" ? "Mark List Not Completed":"Mark List Complete"}</Tooltip>
                                            }
                                        >      
                                            <span>
                                                {loading == "completelist" && <FontAwesomeIcon icon={faSpinner} spin />}
                                                <Form.Check 
                                                    type="switch"
                                                    id={"listcomplete" + listData.id}
                                                    checked={listData.status == "Complete"}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                    onChange={(e) => {
                                                        e.stopPropagation();
                                                        console.log(e.target.checked)
                                                        onToggleListComplete(e.target.checked)
                                                    }}
                                                />  
                                            </span>
                                        </OverlayTrigger> 
                                    }                                                               
                                </h2>
                                
                                <TextField
                                    name="search"
                                    placeholder="Search"
                                    size="sm"
                                    value={searchValue}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value)
                                    }}
                                    onEnter={(e) => {
                                        console.log("enter", e.target.value)
                                        setSearch(e.target.value);
                                    }}
                                    groupStyle={{ margin: "2px" }}
                                />
                                <ListField 
                                    name="sort" 
                                    value={sortBy} 
                                    size="sm"
                                    groupStyle={{
                                        margin: "2px"
                                    }}
                                    list={[
                                        {value: "task", label: "Sort by Title"},
                                        {value: "added_date", label: "Sort by Date Added"},
                                        {value: "due_date", label: "Sort by Due Date"},
                                        {value: "completed_date", label: "Sort by Completed Date"},
                                    ]}
                                    onChange={(e) => setSortyBy(e.target.value)} 
                                />

                                {tasks.length == 0 && 
                                    <div style={{padding: "20px", textAlign: "center", color: "#999"}}>
                                        No Tasks Found
                                    </div>
                                }
                                <div className="tasklist">
                                    {tasks.map((tsk,index) => (
                                        <TaskRow 
                                            task={tsk} 
                                            key={index}
                                            assign={list && list.name=='unassigned'}
                                            onClick={onTask}
                                            onTaskChanged={(result) => {
                                                console.log("onTaskChanged", result)
                                                const {lists, ...taskData} = result;

                                                if (task && task.id == taskData.id)
                                                    setTask(taskData);
                                
                                                setTasks(produce(draft => {
                                                    const index = draft.findIndex(t => t.id == taskData.id)
                                                    
                                                    if (index != -1)
                                                        draft[index] = taskData;
                                                }));
                                
                                                // update task counts
                                                setLists(lists);                                                
                                            }}
                                            active={task && task.id==tsk.id}
                                        />
                                    ))} 

                                    {(pagination && pagination.page < pagination.pages) && 
                                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", textAlign: "center", color: "#999", margin: "10px"}}>
                                            <Button variant="outline-secondary" size="sm" disabled={pagination.page <= 1} onClick={onPrevTasksPage}>
                                                <FontAwesomeIcon icon={faChevronCircleLeft} />
                                            </Button>

                                            <div>
                                                <div><small>{pagination.total} Tasks Found</small></div>
                                                <div>Showing Tasks {pagination.start} to {pagination.end}</div>
                                            </div>

                                            <Button variant="outline-secondary" size="sm" disabled={pagination.page >= pagination.pages} onClick={onNextTasksPage}>
                                                <FontAwesomeIcon icon={faChevronCircleRight} />
                                            </Button>
                                        </div>
                                    }
                                </div>
                                <div className="actions">   
                                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                        <div onClick={onCreateTask}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            Create Task
                                        </div>
                                        {list.name == "list" && 
                                            <div>
                                                <FontAwesomeIcon 
                                                    icon={loading=="deletelist" ? faSpinner:faTrash} 
                                                    spin={loading=="deletelist"} 
                                                    onClick={onDeleteList}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>                               
                            </>
                        : null
                        }
                    </div>
                    <div className="task">
                        {loading == "task" ? 
                            <Loading /> 
                        : task ? 
                            <TaskForm 
                                task={task}
                                lists={lists.filter(l => l.name=="list")}
                                onBusy={(busy) => {
                                    setLoading(busy ? "task":"")
                                }}
                                onTask={(taskId) => {
                                    onTask({id: taskId});
                                }}
                                onTaskUpdated={(taskData) => {
                                    const {lists, ...task} = taskData;

                                    console.log("onTaskUpdted", taskData);

                                    setTasks(produce(draft => {
                                        const index = draft.findIndex(t => t.id == task.id);

                                        console.log(index);

                                        draft[index] = task;
                                    }))
                                    setTask(task);
                                    setLists(lists);
                                }}
                                onTaskDeleted={(result) => {                                    
                                    setLists(result);     
                                    setTasks(produce(draft => {
                                        const index = draft.findIndex(t => t.id == task.id);

                                        if (index != -1)
                                            draft.splice(index, 1);
                                    }))                                           
                                    setTask(null)
                                    setSelected({})
                                }}
                                onInfoLink={(type) => {
                                    setInfoType(type);
                                    setLoading("info");

                                    postData(type + "/data", { 
                                        user_id: task.user_id,
                                        sort: type=="projects" ? "created_date" : type=="orders" ? "order_date" : "added_date",
                                        sortDir: "desc"
                                    },
                                        function(result) {
                                            setInfoData(result[type]);
                                        },
                                        function(error) {
                                            window.alert("Error loading signs.");
                                        },
                                        function() {
                                            setLoading("");
                                        }
                                    );                                        
                                }}
                            />
                        : 
                            null
                        }
                    </div>
                    {(infoType) && 
                        <div className="info">
                            {loading == "info" ? 
                                <Loading /> 
                            :
                                <div>
                                    <h2>{infoType}</h2>
                                    {infoData.map((item,index) => (
                                        <div key={index} onClick={() => setInfoItem(item)}>
                                            {item.preview_url && 
                                                <GoogleImage root src={item.preview_url}/>
                                            }
                                            <div>
                                                {item.subject ?
                                                    <div>{item.subject}</div>
                                                :
                                                    <div>#{item.id}</div>
                                                }
                                                {(item.estimate || item.cost) && 
                                                    <div><small>{formatPrice(item.estimate || item.cost)}</small></div>
                                                }
                                                {(item.added_date || item.created_date || item.order_date) && 
                                                    <div><small>{formatDateTime(item.added_date || item.created_date || item.order_date)}</small></div>
                                                }

                                                {item.click_date ? 
                                                  <Label className="clicked"><small>Clicked</small></Label>
                                                : item.open_date ? 
                                                  <Label className="opened"><small>Opened</small></Label>
                                                : item.dropped_date ? 
                                                   <Label className="dropped"><small>Dropped</small></Label>
                                                : item.bounce_date ? 
                                                  <Label className="bounced"><small>Bounced</small></Label>
                                                : item.delivered_date ? 
                                                  <Label className="delivered"><small>Delivered</small></Label>
                                                :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>                              
                            }
                        </div>
                    }
                    {(task && task.type=="followupemail" && task.status=="Active") && 
                        <div className="followup">
                            <SelectField 
                                name="template" 
                                url={"emails/templates?type=manual"}   
                                onData={(templates) => setEmailTemplates(templates)}         
                                labelField="name"
                                idField="name"
                                value={formData.template} 
                                onChange={(e) => {
                                    const template = emailTemplates.find(et => et.name == e.target.value);

                                    if (template) {
                                        setFormData(produce(draft => {
                                            draft.email = (template.body || "");
                                        }))
                                    }
                                }} 
                                style={{textTransform: "capitalize"}}    
                            />
                            <TextField 
                                name="subject" 
                                label="Subject" 
                                labelBold={true}
                                required
                                value={formData.subject || ""} 
                                onChange={(e) => handleFormChange(e)} />
                            <TextField 
                                name="preview" 
                                label={<small>Short preview message shown in the email preview:</small>}
                                labelBold={true}
                                value={formData.preview || ""} 
                                onChange={(e) => handleFormChange(e)} />                                
                            <HtmlField 
                                name="email" 
                                label="Email Message" 
                                labelBold={true}
                                required
                                value={formData.email} 
                                height="calc(100vh - 420px)"
                                onChange={(e) => handleFormChange(e)} 
                            />
                            <div className="actions">   
                                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                    <div onClick={onSendEmail}>
                                        <FontAwesomeIcon icon={loading == "sendemail" ? faSpinner:faEnvelope} spin={loading == "sendemail"} />
                                        Send Email
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Panels>
            </Content>

            {infoItem && 
                <InfoPanel>
                    <button onClick={() => setInfoItem(null)}>
                        <FontAwesomeIcon icon={faTimes} size="2x" />
                    </button>

                    <iframe 
                        src={"/#/"+infoType+"/"+(infoType.substring(0,infoType.length-1))+"/" + infoItem.id + "?embeded=1"} 
                        width="100%" 
                        height={500} 
                    />
                </InfoPanel>
            }
            {(selected.mode == "createtask") && 
                <TaskDrawer 
                    show={true}
                    task={null}
                    onSaved={ (result) => {
                        const {lists, ...task} = result;

                        // add it to the current list?
                        if (list.id || list.name=="unassigned" || list.name=="active") {
                            setTasks(produce(draft => {
                                draft.push(task);
                            }));
                        }
        
                        // update task counts
                        setLists(lists);                
                        setTask(task);
                        setSelected({});                    
                    }} 
                    onHide={ () => setSelected({}) } 
                /> 
            }            
        </PageLayout>
    );
}

export default TasksPage;