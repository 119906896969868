import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import BoardField from "../components/board/board-field"

function Panel({
  product,
  onChange
}) {

  return (
    <>
        <BoardField
            type="input"
            label="Video Code"
            value={product.shipping_video_code}
            saveUrl={`products/updateprop?product_id=${product.id}&prop=shipping_video_code`}
            onSaved={onChange}
            suffix={
            <a href={"https://www.youtube.com/watch?v=" + product.shipping_video_code} target="_blank">
                <FontAwesomeIcon icon={faExternalLinkAlt} />
                &nbsp;
                Youtube
            </a>
            }
        />      
        <BoardField
            type="select"
            label="Method"
            emptyLabel="Any Method"
            optionsUrl="content/shippingmethods"
            value={product.shipping_method_id}
            saveUrl={`products/updateprop?product_id=${product.id}&prop=shipping_method_id`}
            onSaved={onChange}
        /> 
    </>
  );
}

export default Panel;