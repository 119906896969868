import React from "react";
import styled from 'styled-components'

const Table = styled.table`
    background-color: white;
    width: 100%;

    thead tr th {
        background-color: #e0e0e0;
        padding: 10px;
        font-size: 12px;
    }
    tbody tr th {
        font-weight: bold;      
        font-size: 12px;
        padding: 5px 10px;
        border-bottom: 1px solid #ccc;
    }
    tr td {
        padding: 5px 10px;
        border-bottom: 1px solid #ccc;
        font-size: 12px;

        select.form-control {
            padding: 5px 2px;
        }
    }
`


function PanelTable({
  children
}) {

  return (
    <Table>
        {children}
    </Table>
  );
}

export default PanelTable;