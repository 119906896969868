import React from "react";

import { dimensions } from "../settings/settings";

import {
    JobCard,
    OrderCard,
    OrdersCard,
    UserCard,
    SignCard,
    SignsCard,
    NotesCard,
    TasksCard,
    FilesCard,
    ProjectCard,
    EmailsCard,
    InteractiveBuilderCard
} from "./cards";

export default function CardMenu({
    cards,
    selected,
    onCard
}) {
    return (
        <div 
            className="bg-purple-100 pt-1 pb-1 pl-0 h-full"
            style={{
                width: dimensions.cardMenuWidth
            }}
        >
            <div className="grid gap-1">
                {cards.filter(c => !c.hidden).map((card,index) => {
                    return (         
                        <React.Fragment key={index}>
                        {card.type == "order" ?
                            <OrderCard card={card} onClick={() => onCard(card)} className="-left-1 w-[260px]" />
                        : card.type == "orders" ?
                            <OrdersCard card={card} onClick={() => onCard(card)} className="-left-1 w-[260px]" />
                        : card.type == "job" ?
                            <JobCard card={card} onClick={() => onCard(card)} className="-left-1 w-[260px]" />
                        : card.type == "user" ?
                            <UserCard card={card} onClick={() => onCard(card)} className="-left-1 w-[260px]" />
                        : card.type == "sign" ?
                            <SignCard card={card} onClick={() => onCard(card)} className="-left-1 w-[260px]" />
                        : card.type == "project" ?
                            <ProjectCard card={card} onClick={() => onCard(card)} className="-left-1 w-[260px]" />
                        : card.type == "signs" ?
                            <SignsCard card={card} onClick={() => onCard(card)} className="-left-1 w-[260px]" />
                        : card.type == "emails" ?
                            <EmailsCard card={card} onClick={() => onCard(card)} className="-left-1 w-[260px]" />
                        : card.type == "notes" ?
                            <NotesCard card={card} onClick={() => onCard(card)} className="-left-1 w-[260px]" />
                        : card.type == "tasks" ?
                            <TasksCard card={card} onClick={() => onCard(card)} className="-left-1 w-[260px]" />
                        : card.type == "files" ?
                            <FilesCard card={card} onClick={() => onCard(card)} className="-left-1 w-[260px]" />
                        : card.type == "interactivebuilder" ?
                            <InteractiveBuilderCard card={card} onClick={() => onCard(card)} className="-left-1 w-[260px]" />
                        :
                            <p>Unknown Card "{card.type}"?</p>
                        }
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}