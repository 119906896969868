import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faExternalLinkSquareAlt, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"
import PanelTable from "../panels/panel-table"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"
import { formatDateTime, removeNulls } from "../helpers/utility";
import storage from "../settings/storage";

function SelectResourceDrawer({
    type,
    type_id,
    title,
    label,
    existingResources,
    onAdded,
    onHide
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [resources, setResources] = useState([]);
  const [busy, setBusy] = useState("");

  const [typeFilter, setTypeFilter] = useState(null)
  const [titleFilter, setTitleFilter] = useState(null)
  const [types, setTypes] = useState([])

  useEffect(() => {
      setIsProcessing(true);

      postData("resources/data", removeNulls({
        type: type,
        type_id: type_id
      }), 
        function(results) {
          setResources(results);
        },
        function(error) {
          alert("An error occured loading the resources.");
        },
        function() {
          setIsProcessing(false);
        }
      );   
  }, []);

  useEffect(
    () => {
      console.log(resources.length )
      // only do it if we have a lot of resources
      if (resources.length >= 10) {
        let uniqueTypes = [];
        
        for (const resource of resources) {
          if (uniqueTypes.indexOf(resource.type) == -1)
            uniqueTypes.push(resource.type);
        }

        setTypes(uniqueTypes);

        console.log({uniqueTypes})
      }
    }, 
    [resources]
  );

  console.log(titleFilter)
  return (
    <>
        <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faClipboardList} />
                &nbsp;
                {title || "Select Resource"}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minWidth: "800px"}}>
                { isProcessing ? 
                    <Loading />
                :
                resources.length == 0 ?
                    <p style={{color:"red"}}>No resources found.</p>
                :
                    <PanelTable>
                        <thead>
                            <tr>
                                <th>
                                    Type
                                    {(types.length > 0) && 
                                        <div>
                                            <select 
                                                value={typeFilter} 
                                                style={{
                                                    padding: "2px"
                                                }}
                                                onChange={(e) => setTypeFilter(e.target.value)}
                                            >
                                                <option></option>
                                                {types.map(type => (
                                                    <option key={type} value={type}>{type}</option>
                                                ))}
                                            </select>
                                        </div>
                                    }
                                </th>
                                <th>
                                    Resource
                                    <div>
                                        <input 
                                            type="text" 
                                            name="search" 
                                            value={titleFilter} 
                                            style={{
                                                width: "100%"
                                            }}
                                            onChange={(e) => setTitleFilter(e.target.value)} />
                                    </div>
                                </th>
                                <th>Added Date</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resources
                                .filter(r => (!typeFilter || r.type==typeFilter) && (!titleFilter || r.title.indexOf(titleFilter) != -1))
                                .map((resource,index) => 
                            (
                                <tr key={resource.id} style={{
                                    backgroundColor: existingResources.find(r => r.id == resource.id) ? "#eee":"white"
                                }}>
                                    <td>
                                        {resource.type}
                                    </td>
                                    <td>
                                        {(resource.url || resource.file_url) ?
                                            <a href={resource.url || (storage.root + resource.file_url)} target="_blank">
                                                {resource.title}
                                                <FontAwesomeIcon icon={faExternalLinkSquareAlt} style={{marginLeft: "5px"}} />
                                            </a>
                                        :
                                            <>
                                                {resource.title}
                                                &nbsp;
                                                <span style={{color: "red"}}>(Missing URL?)</span>
                                            </>
                                        }
                                    </td>
                                    <td>
                                        {formatDateTime(resource.added_date)}
                                    </td>
                                    <td>
                                        <Button 
                                            variant="outline-success" 
                                            size="sm"
                                            style={{whiteSpace:"nowrap"}}
                                            disabled={existingResources.find(r => r.id == resource.id)}
                                            onClick={() => {
                                                setBusy("resource"+resource.id);

                                                postData("resources/add", removeNulls({
                                                    type,
                                                    type_id,
                                                    resource_id: resource.id
                                                  }), 
                                                    function(results) {
                                                        onAdded(results);
                                                    },
                                                    function(error) {
                                                      alert("An error occured adding the resources.");
                                                    },
                                                    function() {
                                                      setBusy("");
                                                    }
                                                );                                                  
                                            }}
                                        >
                                            <FontAwesomeIcon icon={busy==("resource"+resource.id) ? faSpinner:faPlus} spin={busy==("resource"+resource.id)} style={{marginRight:"5px"}} />
                                            {label || "Select"}
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </PanelTable>                                  
                }
            </Modal.Body>
            <Modal.Footer style={{display: "flex", justifyContent: "space-between"}}>
                <Button variant="outline-secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>         
    </>
    )
}

export default SelectResourceDrawer;