import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from "../components/loading";
import Error from "../components/error";

export default function DrawerTemplateData({
    title,
    icon,

    query,
    checkReloading,
    noDataFound,
    
    drawerClassName,
    
    header,
    footer,
    buttons,

    onHide,
    children
  }) {
  
  return (
      <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title className="flex items-center gap-2">
            {icon && <FontAwesomeIcon icon={icon} />}
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {header}

            { (checkReloading && query.isFetching) ? 
              <Loading />
            : query.isInitialLoading ?
              <Loading />
            : query.error ?
              <Error error={query.error} />
            : noDataFound ?
              <div className="text-gray-500 p-2">
                No data found.
              </div>
            :
              <div className={drawerClassName}>
                {children}
              </div>            
            }

            {footer}
        </Modal.Body>
        {buttons && 
          <Modal.Footer style={{justifyContent: "flex-start"}}>
            {buttons}
          </Modal.Footer>        
        }
      </Modal>
    )
}
