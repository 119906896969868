import React, { useState, useEffect } from "react";

import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStickyNote, faListAlt, faClipboardList, faCopy, faDollarSign, faTruck, faClipboardCheck, faEnvelope, faList, faPaintBrush } from "@fortawesome/free-solid-svg-icons";
import produce from "immer"

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"

import OrderOverviewPanel from "./panels/panel-order-overview"
import OrderItemsPanel from "./panels/panel-order-items"
import OrderChecklistsPanel from "./panels/panel-order-checklists"
import OrderShippingPanel from "./panels/panel-order-shipping"
import OrderBillingPanel from "./panels/panel-order-billing"
import CreateWorkflowJobPanel from "./panels/panel-order-workflowjob"
import EmailsPanel from "./panels/panel-emails"
import FilesPanel from "./panels/panel-files"
import NotesPanel from "./panels/panel-noteslist"
import TasksPanel from "./panels/panel-tasks"
import EmailDrawer from "../drawers/drawer-email";
import Button from "../components/buttons/button";

import { useLocalStorage } from "../hooks/useLocalStorage"
import { postData } from "../common/services/server"
import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import settings from "../settings"
import { colors } from "../settings/settings";
import PageLayout from "../components/page-layout";

function OrderPage(props) {
    const history = useHistory();
    let { tab, id } = useParams();

    if (!tab) tab = "overview";

    const search = props.location.search;
    const params = new URLSearchParams(search);
    const embeded = params.get("embeded") == 1;

    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const [dirty, setDirty] = useState([]);
    const [busy, setBusy] = useState({});
    const [selected, setSelected] = useState(null);

    const [orderItem, setOrderItem] = useState(null);

    useEffect(
        () => {
            setIsLoading(true);

            postData("orders/orderdata", { 
                id: id
            },
                function(result) {
                    setOrder({...result, voided: result.status_id == 14 || result.status_id == 66});
                },
                function(error) {
                    alert("Error loading order")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        [id]
    );

    function onChecklistAnswer(type, question, checked) {
        const field = type.toLowerCase().replace(/\s/g, "") + "_ind";

        console.log("checklist field update", field);

        setOrder(produce(draft => {
            draft[field] = 1;
        }));
    }

    function onOrderChange(updatedOrder) {
        console.log("onOrderChange", updatedOrder)
        if (updatedOrder)
            setOrder(updatedOrder);
    }

    function onPropChange(prop, value) {
        setOrder(produce(draft => {
            draft[prop] = value;
        }));
        setDirty(produce(draft => {
            if (draft.indexOf(prop) == -1)
                draft.push(prop);
        }))
    }
    function onSaveProp(prop, value=null, callback=null) {
        console.log("onSaveProp", prop, value)

        setBusy(produce(draft => {
            draft[prop] = true
        }));
        setDirty(produce(draft => {
            const index = draft.indexOf(prop);
            if (index != -1)
                draft.splice(index, 1);
        }))

        if (value) {
            setOrder(produce(draft => {
                draft[prop] = value;
            }));
        }

        postData("orders/updateprop", { 
            order_id: order.id,
            prop: prop,
            value: value != null ? value : order[prop]
        },
            function(result) {
                setOrder(result);
                if (callback) callback(result);
            },
            function(error, status) {
                console.log(status);

                if (prop == "coupon" && status == 404)
                    alert("Coupon/Purchase code not found")
                else
                    alert("Error updating order")

                if (callback) callback(null);
            },
            function() {
                setBusy(produce(draft => {
                    draft[prop] = false
                }));
            }
        );
    }

    return (
        <PageLayout>
            <PageHeader 
                title={"Order #" + id}
                help="order"
                parent={{
                    url: "/orders",
                    title: "Orders"
                }}
                embeded={embeded}
                tab={tab}
                tabUrl={"/orders/order/{tab}/" + id}
                tabs={[
                    {name:"overview", title:"Details"},
                    {name:"items", title:"Items", icon:faListAlt, count: order ? order.item_count : null},
                    {name:"checklists", title:"Checklists", icon:faClipboardList, highlight: order && (order.buildready_ind == 0 || order.prebuild_ind == 0), permission: Permissions.QA},
                    {name:"shipping", title:"Shipping", icon:faTruck},
                    {name:"billing", title:"Billing", icon:faDollarSign},
                    {name:"emails", title:"Emails", icon:faEnvelope},
                    {name:"files", title:"Files", icon:faCopy},
                    {name:"notes", title:"Notes", icon:faStickyNote, count: order ? order.note_count : null, hideOnNoCount: false, permission: Permissions.Notes},
                    {name:"tasks", title:"Tasks", icon:faClipboardCheck, count: order ? order.task_count : null, hideOnNoCount: false, permission: Permissions.Tasks},
                ]}             
                onTab={(tab) => {
                    setOrderItem(null);
                    history.push("/orders/order/" + tab + "/" + id);
                }}
                onNewNote={(note) => {
                    console.log("onNewNote", note)
                    if (note.order_id && note.order_id == order.id) {
                        setOrder(produce(draft => {
                            draft.note_count++;
                        }));
                    }
                }}
                onNewTask={(task) => {
                    console.log("onNewTask", task)
                    if (task.order_id && task.order_id == order.id) {
                        setOrder(produce(draft => {
                            draft.task_count++;
                        }));
                    }
                }}
            >
                {userHasPermission(Permissions.Customers) &&
                    <Button onClick={() => window.open(settings.ORDERDETAILS_URL + order.uid)} variant="success" size="sm">
                        <FontAwesomeIcon icon={faListAlt} />
                        &nbsp;
                        Customer Details Page
                    </Button>
                }
            </PageHeader>
            <Content embeded={embeded} permission={Permissions.Orders}>
            <>
                {
                    isLoading 
                ?
                    <Loading />
                : orderItem ? 
                    <CreateWorkflowJobPanel 
                        order={order}
                        orderItem={orderItem}
                    />
                :
                    <>
                        {(order && order.voided) && 
                            <div style={{
                                padding: "5px",
                                backgroundColor: colors.lightRed,
                                textAlign: "center",
                                borderRadius: "5px",
                                marginBottom: "10px",
                            }}>
                                Order is Voided
                            </div>
                        }                        
                        {tab === "overview" &&  
                            <OrderOverviewPanel 
                                order={order} 
                                busy={busy}
                                dirty={dirty}
                                onPropChange={onPropChange}
                                onSaveProp={onSaveProp}
                                onChanged={onOrderChange}
                            /> 
                        }
                        {tab === "items" &&  
                            <OrderItemsPanel 
                                order={order} 
                                orderNo={false}
                                add={true}
                                actions={true}
                                onOrderItem={(item) => setOrderItem(item)}
                                onOrderChange={onOrderChange} 
                                onApprovalChanged={(approval) => {
                                    setOrder(produce(draft => {
                                        const index = draft.approvals.findIndex(a => a.id == approval.id);

                                        if (index != -1)
                                            draft.approvals[index] = approval;
                                    }));
                                }}
                                onApprovalDeleted={(approval) => {
                                    setOrder(produce(draft => {
                                        const index = draft.approvals.findIndex(a => a.id == approval.id);

                                        if (index != -1)
                                            draft.approvals.splice(index, 1);
                                    }));
                                }}
                            /> 
                        }
                        {tab === "itemsjob" &&  
                            <OrderJobCreatePanel 
                                order={order} 
                            /> 
                        }                        
                        {tab === "checklists" &&  
                            <OrderChecklistsPanel 
                                order={order} 
                                onAnswer={onChecklistAnswer} 
                                onOrderChange={onOrderChange}
                            /> 
                            }
                        {tab === "shipping" &&  
                            <OrderShippingPanel 
                                order={order} 
                                onOrderChange={onOrderChange}
                            /> 
                        }
                        {tab === "billing" &&  
                            <OrderBillingPanel 
                                order={order} 
                                busy={busy}
                                dirty={dirty}
                                onPropChange={onPropChange}
                                onSaveProp={onSaveProp}
                                onOrderChange={onOrderChange}
                            /> 
                        }
                        {tab === "emails" &&  
                            <EmailsPanel
                                filters={{
                                    order_id: order.id
                                }}
                                haveTabs={true}
                                buttons={[
                                    {
                                        name: "Order Invoice",
                                        label: "Email Invoice",
                                        variant: "outline-secondary",
                                        icon: faList
                                    },
                                    {
                                        name: "Order Tracking",
                                        label: "Email Tracking",
                                        variant: "outline-secondary",
                                        icon: faTruck
                                    },
                                ]}
                                onButton={(name) => {
                                    console.log("button", name)
                                    let subject = "Order #" + order.id;
                                    
                                    if (name == "Order Invoice")
                                        subject += " Invoice";
                                    else if (name == "Order Tracking")
                                        subject += " Tracking";

                                    setSelected({mode:"email", template:name, subject})
                                }}
                            />
                        }
                        {tab === "files" &&  
                            <FilesPanel
                                filters={{
                                    order_id: order.id
                                }}
                                haveTabs={true}
                                uploadPath={"orders/upload?order_id=" + order.id}
                                uploadMultiple
                            />
                        }
                        {tab === "notes" &&
                            <NotesPanel
                                filters={{
                                    order_id: order.id
                                }}
                                haveTabs={true}
                            />
                        }   
                        {tab === "tasks" &&
                            <TasksPanel
                                filters={{
                                    order_id: order.id,
                                    status: "Active"
                                }}
                                haveTabs={true}
                            />
                        }                       
                    </>
                }
            </>
            </Content>
            { selected && selected.mode == "email" && 
                <EmailDrawer 
                    bodyUrl={"emails/template?template=" + escape(selected.template) + "&order_id=" + order.id}
                    email={{
                        order_id: order.id,
                        template: "simple",
                        from: "production@signmonkey.com",
                        to: order.user_email,
                        subject: selected.subject,
                        body: ""
                    }}
                    show={true}
                    onSaved={(email) => {
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
        </PageLayout>
    );
}

export default OrderPage;