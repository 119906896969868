import React from "react";
import { clsx } from "clsx";
// import Form from 'react-bootstrap/Form';
// import Col from 'react-bootstrap/Col';
// import FormControl from 'react-bootstrap/FormControl';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSpinner } from '@fortawesome/free-solid-svg-icons'

// import FieldLabel from "./label"
// import HelpText from "./help-text"
// import ErrorText from "./error-text"

import Field from "./field";
import Input from "./input";

function FieldText({
    inputClassName,
    ...props
}) {
    // const [error, setError] = useState(props.error || "");
    // const [checking, setChecking] = useState(false);

    // function onBlur(e) {
    //     if (props.onBlur)
    //         props.onBlur(e);

    //     if (props.uniqueCheckUrl && props.value && props.value.length > 0) {
    //         setChecking(true);
    //         setError("")

    //         postData(props.uniqueCheckUrl + props.value, {}, 
    //             function(response) {
    //                 if (response && response.length > 0) {
    //                     setError(props.uniqueMessage || "Duplicate Value")
    //                 }
    //             },
    //             function(error) {
    //                 setError("Error checking value")
    //             },
    //             function() {
    //                 setChecking(false);
    //             }
    //         );
    //     }
    // }

    return (
        <Field {...props}>
            <Input 
                type="text" 
                className={clsx(
                    "border-0 m-0 p-1 w-full",
                    inputClassName
                )}
                {...props}
            />
        </Field>

        // <Form.Group as={props.column ? Col:undefined} style={groupStyle}>

        //     <div>
        //     <FormControl 
        //         as="input" 
        //         type="text" 
        //         name={props.name} 
        //         size={props.size}
        //         required={props.required}
        //         readOnly={props.readonly}
        //         disabled={props.disabled}
        //         isInvalid={props.isInvalid}
        //         value={props.value} 
        //         placeholder={props.placeholder}
        //         maxLength={props.maxlength}
        //         onChange={(e) => { props.onChange({
        //                 target: {
        //                     name: props.name,
        //                     value: e.target.value
        //                 }
        //             }); 
        //         }}
        //         onKeyPress={props.onKeyPress}
        //         onKeyDown={(e) => {
        //             if (e.key === 'Enter' && props.onEnter) {
        //                 props.onEnter(e);
        //             }
        //         }}
        //         onBlur={onBlur}
        //     />
        //     {(props.instructions && error.length == 0 && !checking) && <HelpText>{props.instructions}</HelpText>}
        //     {(!checking && error.length > 0) && <ErrorText>{error}</ErrorText>}
        //     {checking && 
        //         <div><small><FontAwesomeIcon icon={faSpinner} spin />{' '}Checking...</small></div>
        //     }
        //     </div>
        // </Form.Group>
    )
}

export default FieldText