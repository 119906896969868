import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faThumbsUp, faSpinner, faCopy } from "@fortawesome/free-solid-svg-icons";

import { formatDateTime, formatPrice, removeNulls } from "../../helpers/utility";

import BoardFields from "./board-fields";
import BoardField from "./board-field";

import SignPriceDrawer from "../../drawers/signprice"
import FileSelectDrawer from "../../drawers/drawer-fileselect";
import Button from "../buttons/button";
import ButtonProcessing from "../buttons/button-processing";
import { postData, postExternalData } from "../../common/services/server";
import { Actions, Permissions, userHasPermission } from "../../common/services/auth";
import { colors } from "../../settings/settings";
import settings from "../../settings";
import Badge from "../badge";

function BoardSignFields({
    sign,
    onSignChanged,
    onSignRemoved,
    onSignCloned,
}) {
    const [selected, setSelected] = useState("");
    const [busy, setBusy] = useState("");

    function recalculatePrice(callback) {
        setBusy("recalculate");

        postExternalData(settings.SITE_API_URL + "signs/updateprice", {sign_id: sign.id}, 
            function(result) {
                if (callback)
                    callback(result);
            },
            function(error) {
              alert("An error occured calculating the sign price.");
            },
            function() {
                setBusy("");
            }
        );
    }

    function signChanged(prop, signData) {
        onSignChanged(signData);
    }

    return (
        <>
            {(sign && (sign.status == "Draft" || sign.estimate_approved_ind == 0) && userHasPermission(Permissions.Signs, Actions.Approve)) &&
                <div style={{backgroundColor: colors.lightRed, flexBasis: "100%", border: "1px solid #ccc", borderRadius: "5px", padding: "5px", margin: "2px", fontSize:"80%", textAlign:"center"}}>
                    <Button
                        size="sm"
                        variant="outline-danger"
                        style={{padding: "2px 6px"}}
                        onClick={() => {
                            setBusy("approve");
                            
                            postData("signs/approve", { 
                                sign_id: sign.id
                            },
                                function(result) {
                                    signChanged("estimate_approved_ind", {
                                        status: "Active"
                                    })
                                },
                                function(error) {
                                    alert("Error approving sign")
                                },
                                function() {
                                    setBusy("");
                                }
                            );                                    
                        }}
                    >
                        <FontAwesomeIcon icon={busy == "approve" ? faSpinner : faThumbsUp} spin={busy == "approve"} />{' '}
                        Approve Sign
                    </Button>                            
                </div>
            }

            {sign.custom_groups_ind == 1 && 
                <BoardField
                    type="badge"
                    value="Sign contains custom shapes"
                    variant="danger"
                />            
            }  
                    
            <BoardField
                label="Unique ID"
                type="label"
                value={sign.uid}
            />
            <BoardField
                label="Builder"
                type="boolean"
                value={!sign.custom_ind}
                saveUrl={`signs/updateprop?sign_id=${sign.id}&prop=custom_ind`}
                onSaved={(data) => signChanged("custom_ind", data)}
            />     

            <BoardField
                label="Preview"
                type="googleimage"
                value={sign.preview_url}
                zoom={true}
                zoomUrl={sign.preview_url}
                buttons={[
                    {
                        type: "browse",
                        busy: busy == "preview",
                        onClick: () => {
                            setSelected("preview");
                        }
                    },
                    {
                        type: "upload",
                        uploadUrl: "signs/uploadpreview?sign_id=" + sign.id,
                        busy: busy == "preview",
                        onUpload: (result) => {
                            signChanged("preview_file_id", {
                                    preview_file_id: result.preview_file_id,
                                    preview_url: result.preview_url
                            });
                        }
                    },
                ]}
            />
            <BoardField
                label="Tech File"
                type="googlelink"
                value={sign.technical_filename}
                url={sign.technical_url}
                buttons={[
                    {
                        type: "browse",
                        busy: busy == "technical",
                        onClick: () => {
                            setSelected("technical");
                        }
                    },
                    {
                        type: "upload",
                        uploadUrl: "signs/uploadtechnical?sign_id=" + sign.id,
                        busy: busy == "technical",
                        onUpload: (result) => {
                            signChanged("technical_file_id", {
                                technical_file_id: result.technical_file_id,
                                technical_filename: result.technical_filename,
                                technical_url: result.technical_url
                            });
                        }
                    },
                ]}                
            />
                      
            <BoardField
                label="Title"
                type="text"
                value={sign.title}
                saveUrl={`signs/updateprop?sign_id=${sign.id}&prop=title`}
                onSaved={(data) => signChanged("title", data)}
            />
            {sign.custom_ind == 0 ?
                <BoardField
                    label="Size"
                    type="label"
                    value={sign.size_height + '" x ' + sign.size_width + '", ' + sign.area + " sq ft"}
                />
            :
                <>
                    <BoardField
                        label="Height"
                        suffix="Inches"
                        type="number"
                        value={sign.size_height || "0"}
                        saveUrl={`signs/updateprop?sign_id=${sign.id}&prop=size_height`}
                        onSaved={(data) => signChanged("size_height", data)}
                        />   
                    <BoardField
                        label="Width"
                        suffix="Inches"
                        type="number"
                        value={sign.size_width || "0"}
                        saveUrl={`signs/updateprop?sign_id=${sign.id}&prop=size_width`}
                        onSaved={(data) => signChanged("size_width", data)}
                        />               
                </>
            }
            <BoardField
                label="Cost"
                type="children"
                buttons={[
                    {
                        label: "Recalculate", 
                        variant: "outline-success",
                        onClick: () => recalculatePrice(onSignChanged), 
                        busy: busy=="recalculate"
                    },
                    {
                        label: "Calc Data", 
                        onClick: () => setSelected("signprice")
                    },
                ]}
            >
                {sign.estimate_expire_days < 0 ?
                    <div className="grid gap-1">
                        <div className="line-through">{formatPrice(sign.estimate)}</div>
                        <div><Badge size="tiny" variant="danger">EXPIRED</Badge></div>
                    </div>
                :
                    formatPrice(sign.estimate)
                }
            </BoardField>    
            <BoardField
                label="Discount"
                type="label"
                value={sign.discount}
                format="currency"
            />
            <BoardField
                label="User"
                type="user"
                value={sign.user_id}
                saveUrl={`signs/updateprop?sign_id=${sign.id}&prop=user_id`}
                onSaved={(data) => signChanged("user_id", data)}                
            />
            <BoardField
                label="Products"
                type="commalist"
                value={sign.product}
            />
            <BoardField
                label="Description"
                type="html"
                value={sign.description}
                buttons={[
                    {
                        label: "Regenerate", 
                        busy: busy=="regenerate"
                    }
                ]}                
            />

            <BoardFields label="Options">
                <BoardField
                    label="Options"
                    info="User can see what is entered here"
                    type="textarea"
                    value={sign.options}
                    saveUrl={`signs/updateprop?sign_id=${sign.id}&prop=options`}
                    onSaved={(data) => signChanged("options", data)}
                />
                <BoardField
                    label="Options Note"
                    info="User CANT see what is entered here"
                    type="textarea"
                    value={sign.note}
                    saveUrl={`signs/updateprop?sign_id=${sign.id}&prop=note`}
                    onSaved={(data) => signChanged("note", data)}
                />
                <BoardField
                    label="Cost"
                    type="number"
                    value={sign.options_cost}
                    saveUrl={`signs/updateprop?sign_id=${sign.id}&prop=options_cost`}
                    onSaved={(data) => signChanged("options_cost", data)}
                />
            </BoardFields>

            <BoardFields label="Shipping">

                <BoardField
                    label="Method"
                    type="label"
                    value={sign.shipping_method}
                />
                <BoardField
                    type="select"
                    label="Method"
                    optionsUrl="content/shippingmethods"
                    value={sign.shipping_method_id}
                    infoWarning={sign.shipping_method_override_date ? "Shipping method was manually selected" : null}
                    saveUrl={`signs/updateprop?sign_id=${sign.id}&prop=shipping_method_id`}
                    onSaved={(data) => signChanged("shipping_method_id", data)}
                />
                <BoardField
                    label="Adjustment"
                    type="label"
                    value={sign.shipping_adjustment}
                    format="currency"
                />    
                <BoardField
                    label="Estimate"
                    type="label"
                    value={formatPrice(sign.shipping || 0) + " (base + parts + adjustment)"}
                />       
                <BoardField
                    label="Boxes"
                    type="label"
                    value={sign.shipping_boxes}
                />                                     
            </BoardFields>

            <BoardField
                label="Created"
                type="label"
                value={
                    (sign.added_user || "Anonymous") + " on " + 
                    formatDateTime(sign.added_date_local)
                }
            />
            <BoardField
                label="Source"
                type="label"
                value={sign.added_location}
            /> 

            <div style={{display:"flex", gap:"10px", margin:"10px 3px"}}>
                <ButtonProcessing
                    size="sm"
                    icon={faCopy}
                    processing={busy == "clone"}
                    caption="Clone Sign"
                    variant="outline-secondary"
                    onClick={() => {
                        setBusy("clone");

                        postData("mutate/signs/sign/clone", { 
                            sign_id: sign.id,
                            return_sign_data_ind: 1
                        },
                            function(result) {
                                onSignCloned(result);
                            },
                            function(error) {
                                alert("Error cloning sign")
                            },
                            function() {
                                setBusy("");
                            }
                        );                        
                    }}
                />

                <ButtonProcessing
                    size="sm"
                    icon={faBan}
                    processing={busy == "remove"}
                    caption="Remove From Project"
                    variant="outline-danger"
                    onClick={() => {
                        if (window.confirm("Are you sure you want to remove this sign from the project?")) {
                            setBusy("remove");

                            postData(`signs/updateprop?sign_id=${sign.id}&prop=quote_id`, removeNulls({ 
                                value: null
                            }),
                                function() {
                                    onSignRemoved();
                                },
                                function(error) {
                                    alert("Error updating site");
                                },
                                function() {
                                    setBusy("");
                                }
                            );                             
                        }
                    }}
                />
            </div>

            {selected == "signprice" && 
                <SignPriceDrawer 
                    signId={sign.id} 
                    show={true}
                    onHide={ () => setSelected("") } 
                /> 
            }      
            {(selected == "preview") && 
                <FileSelectDrawer
                    show={true}
                    filters={{
                        project_id: sign.quote_id
                    }}
                    onFile={(file) => {
                        console.log("file", file)

                        setBusy("preview");

                        postData(`signs/updateprop?sign_id=${sign.id}&prop=preview_file_id`, removeNulls({ 
                            value: file.id
                        }),
                            function(result) {
                                onSignChanged(result);
                            },
                            function(error) {
                                alert("Error updating preview image");
                            },
                            function() {
                                setBusy("");
                            }
                        ); 

                        setSelected("");
                    }}
                    onHide={() => setSelected("")}
                />
            }
            {(selected == "technical") && 
                <FileSelectDrawer
                    show={true}
                    filters={{
                        project_id: sign.quote_id
                    }}
                    onFile={(file) => {
                        console.log("file", file)

                        setBusy("technical");

                        postData(`signs/updateprop?sign_id=${sign.id}&prop=technical_file_id`, removeNulls({ 
                            value: file.id
                        }),
                            function(result) {
                                onSignChanged(result);
                            },
                            function(error) {
                                alert("Error updating technical file");
                            },
                            function() {
                                setBusy("");
                            }
                        ); 

                        setSelected("");
                    }}
                    onHide={() => setSelected("")}
                />
            }
        </>
    );
}

export default BoardSignFields