import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetInstallationCoverPage = (orderItemId, options) => {  
  return useQuery({ 
    queryKey: ["installcoverpage", orderItemId], 
    queryFn: ({signal}) => query("pdfs/installcoverpage", {
        orderitem_id: orderItemId
    }, signal),
    staleTime: 0,
    cacheTime: 0,    
    ...options
  });
};

export const useGetPackingSlips = (orderId, options) => {  
  return useQuery({ 
    queryKey: ["packingslips", orderId], 
    queryFn: ({signal}) => query("pdfs/packingslips", {
        order_id: orderId
    }, signal),
    staleTime: 0,
    cacheTime: 0,    
    ...options
  });
};
