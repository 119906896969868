import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { Badge } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import Loading from "../../components/loading"
import GoogleImage from "../../components/google-image"

import { postData } from "../../common/services/server"
import storage from "../../settings/storage";

const Layout = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;

  > div:nth-child(2) {
    background-color: #ccc;
    padding: 5px;
  }
`
const Product = styled.h2`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  background-color: white;
  padding: 10px 20px 10px 10px;
  font-size: 100%;
  margin: 0px;
`

function ProductPermitsPanel({products}) {
  const [loading, setLoading] = useState(false);
  const [permits, setPermits] = useState([]);

  useEffect(
    () => {   
        setLoading("permits");

        postData("products/permits", {},
          function(result) {
            setPermits(result);
          },
          function(error) {
              alert("Error loading product permits")
          },
          function() {
            setLoading("");
          }
        );        
    }, 
    []
  );

  return (
    <>
    {loading != "" ?
        <Loading />
    :
        <div>
            {products.filter(p => p.sign_ind == 1).map(prod => (
            <Layout key={prod.id}>
                <Product key={prod.id}>
                    <GoogleImage 
                    src={"products/" + prod.name + "/icon.jpg?v6"} 
                    style={{ maxHeight: "40px" }} 
                    />

                    {prod.title} 
                </Product>
                <div>
                {permits.filter(p => p.product_id == prod.id).map(permit => (
                    <div key={permit.id} style={{backgroundColor: "white", marginBottom: "5px", padding: "5px", borderRadius: "5px"}}>
                    <a href={storage.root + permit.preview_url} target="_blank">
                        <img src={storage.root + permit.preview_url} height="80" />
                    </a>

                    {(permit.extension.toLowerCase() != "svg") && 
                        <div style={{color: "orange"}}>
                        <small>
                            <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
                            Permit is NOT a SVG file.
                        </small>
                        </div>
                    }

                    {(permit.parts_count > 0) && 
                        <div style={{display: "flex", gap: "5px", marginTop: "10px"}}>
                        {permit.parts.split(",").map(part => (
                            <Badge variant="secondary">
                            {part}
                            </Badge>
                        ))}
                        </div>
                    }
                    </div>
                ))}
                </div>
            </Layout>
            ))}
        </div>
    }
    </>
  );
}

export default ProductPermitsPanel;