import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from "clsx";

function Alert({
    size,
    variant,
    className,
    icon,
    iconSize,
    button,
    children
}) {
    return (
        <div className={clsx(
            "rounded-md p-3 w-full bg-gray-200 flex items-center justify-between gap-2",
            size == "sm" && "!p-1 !text-sm",
            size == "tiny" && "!p-1 !text-xs",
            icon && "flex items-center gap-2",
            variant == "danger" && "!bg-red-300",
            variant == "success" && "!bg-green-300",
            variant == "secondary" && "!bg-gray-300",
            variant == "warning" && "!bg-orange-300",
            variant == "outline-danger" && "!bg-white border !border-red-500 text-red-500",
            variant == "outline-secondary" && "!bg-white border !border-gray-300 text-black",
            className
        )}>
            <div className="flex items-center gap-1">
                {icon && <FontAwesomeIcon icon={icon} size={iconSize} />}            
                {children}
            </div>
            {button}
        </div>
    );
  }

export default Alert