import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faTimes, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'

import SignDrawer from "./drawer-sign"
import Loading from "../components/loading"
import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import GoogleImage from "../components/google-image"
import { postData } from "../common/services/server"
import { formatPrice } from "../helpers/utility"
import { userHasPermission, Permissions, Actions } from "../common/services/auth"

const Items = styled.table`
    width: 100%;
    font-size: 12px;

    thead {
        th {
            background-color: #eee;
            padding: 5px;
        }
    }
    tbody {
        td {
            border-bottom: 1px solid #eee;
            padding: 5px;
        }
    }
`
const AddItems = styled.div`
    margin-top: 20px;
`

function CartItemsDrawer(props) {
  const [removing, setRemoving] = useState(null);
  const [adding, setAdding] = useState(null);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [signId, setSignId] = useState(null);
  const [designNo, setDesignNo] = useState("");

  useEffect(() => {
    setLoading(true);

    postData("carts/items", { 
        cart_id: props.cart.id
    },
      function(result) {
        setItems(result);
      },
      function(error) {
        alert("Error loading cart items")
      },
      function() {
        setLoading(false);
      }
    );
  }, [props.cart]);


  function onAddSign() {
      setAdding(true);

      postData("carts/addsign", {
        cart_id: props.cart.id,
        sign_id: parseInt(designNo)
      }, 
        function(response) {
            setItems(response.items);
            props.onChange(response.cart);
        },
        function(error) {
          alert("An error occured adding the sign.");
        },
        function() {
            setAdding(false);
        }
      );
  }

  function onRemove(item) {
      setRemoving(item)

      postData("carts/removeitem", {
            cart_id: props.cart.id,
            item_id: item.id
        }, 
        function(response) {
            const rowIndex = items.indexOf(item)

            setItems(produce(draft => {
                delete draft[rowIndex];
            }));

            props.onChange(response.cart);
        },
        function(error) {
          alert("An error occured removing the item.");
        },
        function() {
            setRemoving(null);
        }
      );
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faShoppingCart} />
            &nbsp;
            Shopping Cart
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { loading ? 
                <Loading /> 
              :
                <>
                <Items>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Cost</th>
                            <th>Discount</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                    {items.map(item => (
                        <tr key={item.id}>
                            <td onClick={() => {
                                if (item.sign_id) {
                                    setSignId(item.sign_id);
                                }
                            }}>
                                <GoogleImage 
                                    root 
                                    src={item.preview_url} 
                                    style={{
                                        maxWidth: "125px",
                                        maxHeight: "75px"
                                    }}
                                />
                                <span dangerouslySetInnerHTML={{__html: item.title}} />
                                {(item.description && item.description.length > 0) && 
                                    <span style={{color: "red"}}>
                                        ({item.description})
                                    </span>
                                }
                            </td>
                            <td>{ formatPrice(item.cost) }</td>
                            <td>{ formatPrice(item.discount || 0) }</td>
                            <td>
                                <ButtonProcessing 
                                    action="delete"
                                    size="sm"
                                    processing={removing == item}
                                    disabled={!userHasPermission(Permissions.ShoppingCarts, Actions.Delete)}
                                    variant="danger"
                                    icon={faTimes}
                                    onClick={ () => onRemove(item) } 
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Items>
                {userHasPermission(Permissions.ShoppingCarts, Actions.Edit) &&
                    <AddItems>
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Add Sign
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Design #"
                                onChange={(event) => {
                                    setDesignNo(event.target.value);
                                }}
                            />
                            <InputGroup.Append>
                                <Button onClick={onAddSign} variant="outline-secondary">
                                    <FontAwesomeIcon icon={adding ? faSpinner : faPlus} spin={adding} />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </AddItems>
                }
                { signId && 
                    <SignDrawer 
                        signId={signId} 
                        onHide={ () => setSignId(null) } 
                    /> 
                }
                </>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default CartItemsDrawer;