import React from "react";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

import FieldLabel from "./label"
import HelpText from "./help-text"

function FieldEmail(props) {
    return (
        <Form.Group>
            {props.label && 
                <FieldLabel 
                    required={props.required} 
                    bold={props.labelBold}
                    style={props.labelStyle || {marginBottom: "0px"}}
                >
                    {props.label}
                </FieldLabel>
            }
            <FormControl 
                as="input" 
                type="email" 
                name={props.name} 
                required={props.required}
                defaultValue={props.value} 
                onChange={(e) => { props.onChange({
                        target: {
                            name: props.name,
                            value: e.target.value
                        }
                    }); 
                }}
            />
            {props.instructions && <HelpText>{props.instructions}</HelpText>}
        </Form.Group>
    )
}

export default FieldEmail