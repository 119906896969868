import React, { useState, useEffect } from "react";
import produce from "immer"
import styled from 'styled-components'

import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faSave } from "@fortawesome/free-solid-svg-icons";

import Button from "../../components/buttons/button"
import Loading from "../../components/loading"
import { postData } from "../../common/services/server"

const Questions = styled.div`
    h3 {
        margin: 0px;
        font-size: 14px;        
    }

    ul {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        font-size: 12px;

        li {
            padding: 5px;
            border-bottom: 1px solid #eee;

            &.answered {
                background-color: white;

                &:hover {
                    background-color: white;
                }
            }
            &:hover {
                background-color: #eee;
            }    
            
            div.question {
                cursor: pointer;
            }
            div.answer {
                background-color: white;
                padding: 4px;
                border-radius: 5px;
                margin-top: 5px;
            }
        }
    }
`

function CheckListForm(props) {
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [selectedId, setSelectedId] = useState(0);
    const [processing, setProcessing] = useState([]);

    useEffect(
        () => {
            setLoading(true);

            postData("qa/questions", { 
                type: props.type,
                item: props.item,
                item_id: props.itemId
            },
                function(result) {
                    setCategories(result.categories);
                    setQuestions(result.questions)                    
                    //setSelected(result.questions.find(q => q.checked_user_id == null));
                },
                function(error) {
                    alert("Error loading checklist data")
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        [props.refresh]
    );

    function setQuestionNotes(question, notes) {
        setQuestions(produce(draft => {
            const exists = draft.find(q => q.id == question.id);

            if (exists)
                exists.notes = notes;
        }));
    }

    function saveQuestionNotes(question) {
        // make sure its not already being saved
        if (processing.find(q => q == question.id) != null) return;

        setProcessing(produce(draft => {
            draft.push(question.id)
        }));

        postData("qa/note", { 
            item: props.item,
            item_id: props.itemId,
            question_id: question.id,
            notes: question.notes
        },
            function(result) {
                if (props.onAnswer) {
                    props.onAnswer({
                        answer_count: questions.filter(q => q.notes && q.notes.length > 0).length
                    })
                }

                //if (selectedId == question.id)
                //    setSelectedId(0);
            },
            function(error) {
                alert("Error updating notes")
            },
            function() {
                setProcessing(produce(draft => {
                    const existIdx = draft.findIndex(p => p == question.id)
        
                    if (existIdx != -1) {
                        draft.splice(existIdx, 1);
                    }
                }));
            }
        );
    }

    return (
        <>
            {
                loading ?
                    <Loading />
                :
                <Questions>
                    {categories.map(category => (
                        <div key={category.id}>
                            {category.title && <h3>{category.title}</h3>}
                            <ul>
                                {questions.filter(q => q.category_id == category.id).map(question => {
                                    const selected = selectedId == question.id;
                                    const busy = processing.find(p => p == question.id);

                                    return (
                                        <li 
                                            key={question.id} 
                                            className={selected ? "active":""}
                                            onClick={() => setSelectedId(question.id)}
                                        >
                                            <div className="question">
                                                {question.question}
                                            </div>

                                            { selected ? 
                                                <InputGroup>
                                                    <FormControl
                                                        value={question.notes}
                                                        onChange={(event) => {
                                                            setQuestionNotes(question, event.target.value);
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' && props.onEnter) {
                                                                saveQuestionNotes(question);
                                                            }
                                                        }}                                                        
                                                        onBlur={() => saveQuestionNotes(question)}
                                                        onFocus={(e) => e.target.select()}
                                                    />

                                                    <InputGroup.Append>
                                                        <Button onClick={() => saveQuestionNotes(question)} variant="outline-secondary">
                                                            <FontAwesomeIcon icon={busy && busy.notes ? faSpinner:faSave} spin={busy && busy.notes} />
                                                        </Button>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            : question.notes ?
                                                <div className="answer">
                                                    {question.notes}
                                                </div>
                                            :
                                                null           
                                            }
                                        </li>
                                    )
                                })} 
                            </ul>
                        </div>   
                    ))}
                </Questions>             
            }
        </>
    );
}

export default CheckListForm;