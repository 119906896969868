import React, { useState, useEffect } from "react";

import Panel from "./panel";
import { Accordion, AccordionItem } from "../components/accordion";
import { formatDateTime } from "../helpers/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import FieldLabel from "../components/fields/field-label";
import ZoomableImage from "../components/image-zoomable";
import storage from "../settings/storage";
import Button from "../components/buttons/button";
import SignPriceDrawer from "../drawers/signprice";

import { useGetSign, useGetSignHistory } from "../api/queries/signs";
import settings from "../settings";

function SignHistoryPanel({signId}) {
    const [priceData, setPriceData] = useState(null);
    /*
    function onRestoreCustomVersion(historyId) {
        if (window.confirm("Are you sure you want to restore this version?")) {
            setBusy(historyId);

            postData("signs/restore", { sign_id: props.sign.id, history_id: historyId},
                function() {
                    props.onSignChange();
                },
                function(error) {
                    alert("Error restoring version.")
                },
                function() {
                    setBusy(0)
                }
            );            
        }
    }
    */

    const signQuery = useGetSign(signId);
    const historyQuery = useGetSignHistory(signId);
    const history = historyQuery.data;

    return (
      <>
        <Panel query={historyQuery}>
            {history && 
                <Accordion>
                    {history.map((item) => {
                        const isJson = item.data && item.data.substring(0,1) == "{";

                        if ((item.action == "Calculated Price" || item.action == "Version") && isJson) {
                            try {
                                const json = JSON.parse(item.data);

                                if (json.cost && json.cost.total)
                                    item.cost = json.cost.total.toFixed(2);
                                else if (json.estimate)
                                    item.cost = parseFloat(json.estimate).toFixed(2);
                                else if (json.price_data) {
                                    if (json.price_data.estimate)
                                        item.cost = parseFloat(json.price_data.estimate).toFixed(2);
                                    else if (json.price_data.cost)
                                        item.cost = parseFloat(json.price_data.cost.total).toFixed(2);
                                }
                            }
                            catch(e) {}
                        }

                        const noData = !item.action_id && (!item.data || isJson) && !item.preview_url;

                        return (
                            <AccordionItem 
                                key={item.id} 
                                size="sm" 
                                disabled={noData}
                                title={
                                    <div className="grid grid-cols-[160px,1fr,auto] w-full items-center gap-2">
                                        <div className="text-sm text-gray-400">
                                            {formatDateTime(item.action_date)}
                                        </div>

                                        <div className="flex items-center gap-1">
                                            {item.action.startsWith("Updated Property") ? 
                                                <div>Updated Property <span className="font-bold">{item.action.split(" ")[2]}</span></div>
                                            :
                                                item.action
                                            }

                                            {(item.action == "Version" && item.action_id) && 
                                                <span className="ml-1">{item.action_id}</span>
                                            }

                                            {item.cost && 
                                                <span className="text-green-500 font-bold">
                                                    ${item.cost}
                                                </span>
                                            }
                                        </div>

                                        <div className="flex items-center gap-2">
                                            {(item.action == "Calculated Price" && isJson) && 
                                                <Button
                                                    size="tiny"
                                                    variant="solid-success"
                                                    onClick={() => setPriceData(item.data)}
                                                >
                                                    View Price Details
                                                </Button>
                                            }
                                            {item.action == "Version" && 
                                                <Button 
                                                    icon={faPencilAlt}
                                                    iconSpin={signQuery.isLoading}
                                                    variant="solid-warning"
                                                    size="tiny"
                                                    disabled={!signQuery.data || signQuery.isLoading || signQuery.isError}
                                                    onClick={(e) => {window.open(settings.SIGNEDIT_URL + signQuery.data?.uid + "&version="+item.action_id); e.preventDefault()}} 
                                                    href={settings.SIGNEDIT_URL + signQuery.data?.uid + "&version=" + item.action_id}
                                                >
                                                    Load In Builder
                                                </Button>
                                            }                                            
                                            <div className="bg-gray-200 rounded p-1 flex items-center text-xs gap-1">
                                                <FontAwesomeIcon icon={faUser} />
                                                {item.user_name}
                                            </div>
                                        </div>
                                    </div>
                                }
                            >
                                {!isJson && 
                                    <FieldLabel prepend="Data:">{item.data}</FieldLabel>
                                }
                                <FieldLabel prepend="Action ID:">{item.action_id}</FieldLabel>
                                {item.preview_url && 
                                    <ZoomableImage
                                        url={storage.root + item.preview_url}
                                        maxHeight="150px"
                                    />
                                }
                            </AccordionItem>
                        )
                    })}
                </Accordion>            
            }
        </Panel>

        {priceData && 
            <SignPriceDrawer
                signId={signId}
                priceData={JSON.parse(priceData)}
                show={true}
                onHide={() => setPriceData(null)}
            />        
        }
      </>
    );
}

export default SignHistoryPanel;