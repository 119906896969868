import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { getIconForType } from "../helpers/icons";
import SignPanel from "../panels/sign-details";
import SignsPanel from "../panels/signs";
import UserPanel from "../panels/user-details";
import OrderPanel from "../panels/order-details";
import OrdersPanel from "../panels/orders";
import ProjectPanel from "../panels/project-details";
import NotesPanel from "../panels/notes";
import FilesPanel from "../panels/files";
import { dimensions } from "../settings/settings";
import EmailsPanel from "../panels/emails";

export default function CardDetails({
    card,
    onChange,
    onClose
}) {
    return (
        <div 
            className="bg-white border-8 border-purple-200 p-1 h-full"
            style={{
                width: "400px"
            }}
        >
            <div className="flex items-center p-1 justify-between bg-purple-300 mb-1">
                <div className="flex items-center gap-1 capitalize">
                    <FontAwesomeIcon icon={getIconForType(card.type)} />
                    {card.type} {card.type.substring(card.type.length-1) == "s" ? "":"Details"}
                </div>
                <div className="rounded-full px-2 py-1 hover:bg-white">
                    <FontAwesomeIcon icon={faTimes} onClick={onClose} />
                </div>
            </div>
            <div 
                className="overflow-auto"
                style={{
                    height: `calc(100vh - ${dimensions.siteHeaderHeight + dimensions.cardPanelHeaderHeight + 30}px)`
                }}
            >
                {(card && card.type == "sign") &&
                    <SignPanel
                        signId={card.id}
                        size="small"
                        onSignChanged={onChange}
                    />
                }
                {(card && card.type == "signs") &&
                    <SignsPanel
                        projectId={card.project_id}
                        ignoreSignId={card.ignore_sign_id}
                        size="small"
                        onSign={card.onSign}
                    />
                }                
                {(card && card.type == "user") &&
                    <UserPanel
                        userId={card.id}
                        size="small"
                        onUserChanged={onChange}
                    />
                }
                {(card && card.type == "order") &&
                    <OrderPanel
                        orderId={card.id}
                        size="small"
                        onOrderChanged={onChange}
                    />
                }
                {(card && card.type == "orders") &&
                    <OrdersPanel
                        size="small"
                        signId={card.sign_id}
                    />
                }                
                {(card && card.type == "project") &&
                    <ProjectPanel
                        projectId={card.id}
                        size="small"
                        onProjectChanged={onChange}
                    />
                }      
                {(card && card.type == "emails") && 
                    <EmailsPanel
                        userId={card.user_id}
                        userIds={card.user_ids}
                        orderId={card.order_id}
                        signId={card.sign_id}
                        sendSignId={card.send_sign_id}
                        projectId={card.project_id}
                        sendProjectId={card.send_project_id}
                        size="small"
                    />
                }                   
                {(card && card.type == "notes") && 
                    <NotesPanel
                        userId={card.user_id}
                        orderId={card.order_id}
                        signId={card.sign_id}
                        projectId={card.project_id}
                        jobId={card.job_id}
                        workflowJobId={card.workflowjob_id}
                        size="small"
                        onNoteChanged={onChange}
                    />
                }      
                {(card && card.type == "files") && 
                    <FilesPanel
                        userId={card.user_id}
                        orderId={card.order_id}
                        signId={card.sign_id}
                        projectId={card.project_id}
                        jobId={card.job_id}
                        workflowJobId={card.workflowjob_id}
                        size="small"
                        onFileChanged={onChange}
                    />
                }    
            </div>            
        </div>
    )
}