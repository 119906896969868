import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSpinner, faUserSecret, faSearch, faSave, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ProgressBar from "react-bootstrap/ProgressBar"
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import moment from 'moment';

import PropertyList from "../../components/properties/property-list"
import Property from "../../components/properties/property"
import SelectField from "../../components/fields/field-select"
import CheckListForm from "../../components/forms/form-checklist"
import Button from "../../components/buttons/button";

import { formatPrice, formatDate, formatDateTime } from "../../helpers/utility"
import { colors } from "../../settings/settings"
import { postData } from "../../common/services/server"
import { formatTime, formatPhoneNumber } from "../../helpers/utility"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

const FraudHeader = styled.div`
    font-size: 25px;
    padding: 5px;
`

function OrderOverviewPanel(props) {
    const { order } = props;

    const [canEdit, setCanEdit] = useState(userHasPermission(Permissions.Orders, Actions.Edit))
    const [score, setScore] = useState(order.fraud_score);
    const [busy, setBusy] = useState("");
    const [refresh, setRefresh] = useState(1);
    const [editProp, setEditProp] = useState("");

    useEffect(
        () => {
            setScore(props.order.fraud_score || 0);

            if (props.order.fraud_score == null)
                onFraudChecks();
        }, 
        [props.order]
    );

    function onStatusChange(e) {
        setBusy("status")

        postData("orders/changestatus", { 
            order_id: order.id,
            status_id: e.target.value
        },
            function(result) {
                if (props.onChange) 
                    props.onChange(result);
            },
            function(error) {
                alert("Error updating status")
            },
            function() {
                setBusy("");
            }
        );
    }

    function onFraudChecks() {
        setBusy("fraud");

        postData("orders/fraudchecks", { 
            order_id: order.id
        },
            function(result) {
                if (props.onChange) 
                    props.onChange(result);
                setRefresh(refresh+1);
            },
            function(error) {
                alert("Error updating order")
            },
            function() {
                setBusy("");
            }
        );
    }

    return (
    <Row>
        <Col xs={12} sm={6}>
            <PropertyList maxWidth={"500px"}>
                <Property label="Unique ID" value={order.uid} />
                <Property label="Company">
                    <InputGroup>
                        <SelectField 
                            name="company_id" 
                            url="orders/companies"
                            required
                            labelField="title"
                            value={order.company_id} 
                            disabled={!canEdit}
                            onChange={(e) => {
                                props.onSaveProp("company_id", e.target.value);
                            }} 
                        />
                        {props.busy.company_id && 
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSpinner} spin={true} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        }
                    </InputGroup>
                </Property>
                <Property label="User">
                    <div>                    
                        <div className="name">
                            <FontAwesomeIcon icon={faUser} />
                            {' '}
                            <Link to={"/users/user/" + order.user_id}>
                                <strong>{order.user_name}</strong>
                            </Link>
                            &nbsp;
                            <small>(#{order.user_id})</small>
                        </div>
                        <div className="email">{order.user_email}</div>
                        <div className="phone">{formatPhoneNumber(order.user_phoneno)}</div>
                    </div>
                </Property>
                <Property label="Cost" value={formatPrice(order.cost)}/>
                <Property label="Discount">
                    {formatPrice(order.total_discount)}

                    {order.coupon_id && 
                        <div style={{margin:"10px 0px"}}>
                            <span style={{border: "2px dotted white", padding: "3px 6px", backgroundColor:"#ffbb3e"}}>
                                {order.coupon_code}
                            </span>
                            &nbsp;
                            <small>{order.coupon_title}</small>
                        </div>
                    }
                    {order.coupon_admin_id && 
                        <div style={{margin:"10px 0px"}}>
                            <span style={{border: "2px dotted white", padding: "3px 6px", backgroundColor:"#ffbb3e"}}>
                                {order.admin_coupon_code}
                            </span>
                            &nbsp;
                            <small>{order.admin_coupon_title}</small>
                        </div>
                    }                    
                </Property>
                <Property label="Tax" value={formatPrice(order.tax)}/>
                {false && <Property label="Shipping Estimate" value={formatPrice(order.shipping_estimate)}/>}
                <Property label="Shipping Surcharge" value={formatPrice(order.shipping_surcharge)}/>
                <Property label="Total">
                    <span style={{color: order.voided ? "#999" : colors.green, fontWeight: "bold", textDecoration: order.voided ? "line-through":"none"}}>
                        {formatPrice(parseFloat(order.total))}
                    </span>
                    {order.voided && <span style={{color: "red", marginLeft: "8px"}}>VOID</span>}
                </Property>
                <Property label="Payments" value={formatPrice(order.voided ? 0 : order.payments_total)}/>
                <Property label="Balance">
                    <span style={{color: order.total > order.payments_total ? "red":"inherit"}}>
                        {formatPrice(order.payments_total - parseFloat(order.total))}
                    </span>
                </Property>
                <Property label="Orderd Date" value={formatDateTime(order.order_date)} />
                <Property label="Payment Processed Date">
                    {editProp == "order_payment_date" ?                     
                        <InputGroup>
                            <FormControl
                                type="date"
                                disabled={!canEdit}
                                value={order.order_payment_date ? moment.utc(order.order_payment_date).format('YYYY-MM-DD') : ""}
                                onChange={(event) => props.onSaveProp("order_payment_date", event.target.value) }            
                            />
                            <InputGroup.Append>
                                <Button onClick={() => props.onSaveProp("order_payment_date")} disabled={!canEdit} variant={props.dirty.order_payment_date ? "primary":"outline-secondary"}>
                                    <FontAwesomeIcon icon={props.busy && props.busy.order_payment_date ? faSpinner:faSave} spin={props.busy && props.busy.order_payment_date} />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    :
                        <>
                            {formatDate(order.order_payment_date)}
                            &nbsp;
                            <FontAwesomeIcon icon={faPencilAlt} onClick={() => setEditProp("order_payment_date")} style={{cursor: "pointer"}} />
                        </>
                    }
                </Property>                
                <Property label="Status">
                    <InputGroup>
                        <SelectField 
                            name="status_id" 
                            url="orders/statuses"
                            required
                            labelField="title"
                            disabled={!canEdit}
                            value={order.status_id} 
                            onChange={(e) => {
                                props.onSaveProp("status_id", e.target.value);
                            }} 
                        />
                        {props.busy.status_id && 
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faSpinner} spin={true} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        }
                    </InputGroup>
                </Property>
                <Property label="Hear About Us" value={order.hear_about_us}/>

            </PropertyList>
        </Col>
        <Col xs={12} sm={6}>        
            <FraudHeader>
                <FontAwesomeIcon icon={faUserSecret} />{' '}
                Fraudulent Checks
                <Button onClick={onFraudChecks} variant="outline-danger" size="sm" style={{float:"right"}}>
                    <FontAwesomeIcon icon={busy == "fraud" ? faSpinner:faSearch} spin={busy == "fraud"} />{' '}
                    Perform Auto Checks
                </Button>
                <div>
                    <ProgressBar variant={score > 70 ? "danger" : score > 30 ? "warning" : "success"} now={score || 0} />
                </div>
            </FraudHeader>
            <CheckListForm
                type="Fraudulent Check"
                item="order"
                itemId={order.id}
                reverseColors
                noSelection
                disabled={!canEdit}
                extraData={{
                    fraud_paid_shipping: order.shipping_estimate,
                    fraud_order_night: formatTime(order.order_date),
                    fraud_genericemail: order.user_email,
                    fraud_companymismatch: "Billing: " + order.billing_company + ", Shipping: " + order.ship_company,
                    fraud_shiptohome: "<a href='https://www.google.com/maps/search/?api=1&query=" + order.ship_address1 + "%20" + order.ship_city + "%20" + order.ship_state + "%20" + order.ship_zipcode + "' target='_blank'>" + order.ship_address1 + " " + order.ship_city + " " + order.ship_state + " " + order.ship_zipcode + "</a>",
                    fraud_addressmismatch: 
                            "<div>" + 
                                "Billing: <a href='https://www.google.com/maps/search/?api=1&query=" + order.billing_address1 + "%20" + order.billing_city + "%20" + order.billing_state + "%20" + order.billing_zipcode + "' target='_blank'>" + order.billing_address1 + " " + order.billing_city + " " + order.billing_state + " " + order.billing_zipcode + "</a>" +
                            "</div>" + 
                            "<div>" + 
                                "Shipping: <a href='https://www.google.com/maps/search/?api=1&query=" + order.ship_address1 + "%20" + order.ship_city + "%20" + order.ship_state + "%20" + order.ship_zipcode + "' target='_blank'>" + order.ship_address1 + " " + order.ship_city + " " + order.ship_state + " " + order.ship_zipcode + "</a>" + 
                            "</div>",
                }}
                refresh={refresh}
                onAnswer={(type, question, checked, result) => {
                    if (result)
                        setScore(result.score)
                }}
                onQuestion={(question) => {
                    
                }}
            /> 
        </Col>   
    </Row>
    );
}

export default OrderOverviewPanel;