import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect"

import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faUser, faEnvelope, faSpinner, faCheck, faTrash, faPaperclip, faSms, faUserPlus, faExternalLinkAlt, faShoppingCart, faArchive, faPencilAlt, faArrowRight, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'

import { formatDate, formatDateTime, linkifyText } from '../helpers/utility'
import PropertyList from "../components/properties/property-list"
import Property from "../components/properties/property"
import SmsMessageList from "../components/sms/message-list"
import Button from "../components/buttons/button";

import {postData} from "../common/services/server"
import storage from "../settings/storage"
import Loading from "../components/loading"
import Associations from "../components/associations"
import { colors } from "../settings/settings";
import { userHasPermission, Permissions, Actions } from "../common/services/auth"

const ReplyEmail = styled.div`
  padding: 5px 10px;
  border-radius: 3px;
  background-color: ${colors.lightPurple};
  margin: 2px;
  border: 1px solid #e0e0e0;

  a {
    float: right;
    border: 1px solid #ccc;
    background-color: white;
    padding: 3px 6px;
    font-size: 11px;
    border-radius: 3px;
    color: black;
  }
`
const AssociationsList = styled.ul`
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  display: flex;

  li > div {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 1px 5px;
    border-radius: 3px;
    margin: 0px 3px 3px 0px;

    > * {
      margin: 0px 2px;
    }

    button {
      padding: 0px;
      margin: 0px;
    }
  }
`
const EmailBody = styled.div`
  max-width: 90vw;
  overflow: auto;

  @media (max-width: 425px) {
    .smheader, .smfooter {
      display: none;
    }
  }  
`

function ViewEmailDrawer(props) {
  const history = useHistory();

  const [busy, setBusy] = useState("");
  const [loading, setLoading] = useState(true);
  const [checking, setChecking] = useState("");
  const [checks, setChecks] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [email, setEmail] = useState(null);
  const [editAssociations, setEditAssocations] = useState(false);
  const [associationsBusy, setAssociationsBusy] = useState({});

  const [canEdit] = useState(userHasPermission(Permissions.Emails, Actions.Edit));
  const [canSend] = useState(userHasPermission(Permissions.Emails, Actions.Send));
  const [canDelete] = useState(userHasPermission(Permissions.Emails, Actions.Delete));

  useEffect(() => {
    setLoading(true)

    postData("emails/emaildata", {id: props.email.id},
        function(result) {
            setEmail(result)
        },
        function(error) {
            alert("Error loading email data")
        },
        function() {
          setLoading(false);
        }
    );
  }, [props.email]);

  function onComplete() {
    setBusy("completed")

    postData("emails/complete", {email_id: email.id},
        function(result) {
          if (props.onCompleted)
            props.onCompleted(result);
        },
        function(error) {
            alert("Error updating email")
        },
        function() {
          setBusy("");
        }
    );
  }

  function onUncomplete() {
    setBusy("uncompleted")

    postData("emails/uncomplete", {email_id: email.id},
        function(result) {
            if (props.onCompleted)
              props.onCompleted(result);
        },
        function(error) {
            alert("Error updating email")
        },
        function() {
          setBusy("");
        }
    );
  }

  function onNotSpam() {
    setBusy("notspam")

    postData("emails/notspam", {email_id: email.id},
        function(result) {
            props.onDeleted(result);
        },
        function(error) {
            alert("Error updating email")
        },
        function() {
          setBusy("");
        }
    );
  }

  function onCreate() {
    setBusy("adduser")

    postData("emails/createuser", {email_id: email.id},
        function(result) {
            if (props.onUser)
              props.onUser(result);

            setChecks({
              user: result,
              blacklist: false
            })
        },
        function(error) {
            alert("Error adding user")
        },
        function() {
          setBusy("");
        }
    );
  }
  function onBlacklist() {
    if (window.confirm("Add this email address to the blacklist? No future emails will be accepted from it.")) {
      setBusy("blacklist")

      postData("emails/blacklist", {email_id: email.id},
          function() {
              props.onDeleted();
          },
          function(error) {
              alert("Error updating email")
          },
          function() {
            setBusy("");
          }
      );
    }
  }
  function onDelete() {
    setBusy("delete")

    postData("emails/delete", {email_id: email.id},
        function() {
            props.onDeleted();
        },
        function(error) {
            alert("Error updating email")
        },
        function() {
          setBusy("");
        }
    );
  }

  function onCreateProject() {
    setBusy("createproject")

    postData("projects/createfromemail", {email_id: email.id},
        function(result) {
            window.open("/#/projects/project/" + result.id);
        },
        function(error) {
            alert("Error creating project")
        },
        function() {
          setBusy("");
        }
    );
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title style={{display:"flex", justifyContent: "space-between", width: "100%"}}>
            <div>
              <FontAwesomeIcon icon={email && email.type == "sms" ? faSms : faEnvelope} />{' '}
              {email && email.type == "sms" ? "Text " : "Email "}
              Message
            </div>
            {email && 
              <div>
                {(email.status != "Sent" && !email.quote_id) &&
                  <Button 
                    onClick={onCreateProject} 
                    disabled={!userHasPermission(Permissions.Projects, Actions.Create) || busy=="createproject"} 
                    variant="outline-success" 
                    size="sm"
                  >
                    <FontAwesomeIcon icon={busy=="createproject" ? faSpinner:faArchive} spin={busy=="createproject"} />{' '}
                    Create Project
                  </Button>
                }
              </div>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loading ? 
                <Loading label="Loading email..." />
              :
                <>
                  {email.reply && 
                    <ReplyEmail>
                        <>
                          This is a reply to a previous message.
                          
                          <a 
                            href="#" 
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              props.onEmail(email.reply)
                            }}
                          >
                            View Message
                          </a>
                        </>
                    </ReplyEmail> 
                  }
                  <PropertyList maxWidth="100%">
                      <Property label="Subject" value={email.subject} />

                      <Property label="From" styles={{display: "flex", alignItems:"center", justifyContent: "space-between"}}>
                        <div>
                          {email.from}
                          {email.checks.users && email.checks.users.map(user => (
                              <div key={user.id}>
                                <OverlayTrigger overlay={<Tooltip>Open User Profile</Tooltip>}>
                                  <Button onClick={() => { window.open("#/users/user/" + user.id) }} variant="link" size="sm" style={{padding: "0px"}}>
                                    <FontAwesomeIcon icon={faUser} fixedWidth />
                                    {user.name}
                                  </Button>
                                </OverlayTrigger>
                              </div>
                          ))}
                        </div>

                        {(email.status == "Received" || email.status == "Completed" || email.status == "Spam") && 
                          <div style={{whiteSpace: "nowrap"}}>
                            {email.checks.user &&
                                <>
                                  {props.onFollowup && 
                                    <OverlayTrigger overlay={<Tooltip>Create Followup Task For Customer</Tooltip>}>
                                      <Button onClick={props.onFollowup} variant="success" size="sm" style={{marginLeft:"5px"}}>
                                        <FontAwesomeIcon icon={faClipboardCheck} fixedWidth />{' '}
                                        Followup
                                      </Button>
                                    </OverlayTrigger>
                                  }
                                  <OverlayTrigger overlay={<Tooltip>Open User Profile</Tooltip>}>
                                    <Button onClick={() => { window.open("#/users/user/" + email.checks.user.id) }} variant="primary" size="sm" style={{marginLeft:"5px"}}>
                                      <FontAwesomeIcon icon={faUser} fixedWidth />{' '}
                                      <FontAwesomeIcon icon={faExternalLinkAlt} fixedWidth />
                                    </Button>
                                  </OverlayTrigger>
                                </>
                            }
                            {(!email.checks.blacklist && !email.checks.user && (!email.checks.users || email.checks.users.length == 0)) && 
                              <OverlayTrigger overlay={<Tooltip>Create New User</Tooltip>}>
                                <Button onClick={onCreate} variant="success" size="sm" style={{marginLeft:"5px"}}>
                                  <FontAwesomeIcon icon={busy == "adduser" ? faSpinner:faUserPlus} spin={busy == "adduser"} fixedWidth />
                                </Button>
                              </OverlayTrigger>
                            }
                            {(email.checks && !email.checks.blacklist && !email.checks.user && (!email.checks.users || email.checks.users.length == 0)) && 
                              <OverlayTrigger overlay={<Tooltip>Add To Blacklist</Tooltip>}>
                                <Button onClick={onBlacklist} variant="dark" size="sm" style={{marginLeft:"5px"}}>
                                  <FontAwesomeIcon icon={busy == "blacklist" ? faSpinner:faBan} spin={busy == "blacklist"} fixedWidth />
                                </Button>
                              </OverlayTrigger>
                            }
                          </div>
                        }
                      </Property>
                      <Property label="To" styles={{display: "flex", alignItems:"center", justifyContent: "space-between"}}>
                        <div>
                          {email.to} 
                          {email.checks.toUsers && email.checks.toUsers.map(user => (
                                <div key={user.id}>
                                  <OverlayTrigger overlay={<Tooltip>Open User Profile</Tooltip>}>
                                    <Button onClick={() => { window.open("#/users/user/" + user.id) }} variant="link" size="sm" style={{padding: "0px"}}>
                                      <FontAwesomeIcon icon={faUser} fixedWidth />
                                      {user.name}
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                          ))}
                        </div>

                        {email.status == "Sent" && 
                          <div>
                            {email.checks.toUser &&
                              <OverlayTrigger overlay={<Tooltip>Open User Profile</Tooltip>}>
                                <Button onClick={() => { window.open("#/users/user/" + email.checks.toUser.id) }} variant="primary" size="sm" style={{marginLeft:"5px"}}>
                                  <FontAwesomeIcon icon={faUser} fixedWidth />{' '}
                                  <FontAwesomeIcon icon={faExternalLinkAlt} fixedWidth />
                                </Button>
                              </OverlayTrigger>
                            }
                          </div>
                        }
                      </Property>

                      {email.cc && 
                        <Property label="CC" value={email.cc} />
                      }
                      
                      <Property 
                        label={
                            <div style={{ display: "flex", justifyContent: "space-between"}}>
                              <span>Associations</span>
                              <Button variant="outline-secondary" disabled={!canEdit} onClick={() => setEditAssocations(true)} size="sm">
                                <FontAwesomeIcon icon={faPencilAlt} />{' '}
                                Edit
                              </Button>
                            </div>
                        }
                        styles={{
                          display: "block"
                        }}
                      >
                        {editAssociations ?
                            <Associations 
                              types={["order","project","sign"]}
                              data={email}
                              busy={associationsBusy}
                              onChange={(type, item) => {
                                setAssociationsBusy(produce(draft => {
                                  draft[type] = true;
                                }))

                                postData("emails/updateprop", {
                                      email_id: email.id,
                                      prop: type,
                                      value: item ? item.id : null
                                    },
                                    function(result) {
                                        setEmail(produce(draft => {
                                          draft[type + "_id"] = item ? item.id : null
                                        }))
                                    },
                                    function(error) {
                                        alert("Error updating email")
                                    },
                                    function() {
                                      setAssociationsBusy(produce(draft => {
                                        draft[type] = false;
                                      }))
                                    }
                                );
                              }}
                            />
                          :
                            <AssociationsList>
                              {email.order_id && 
                                <li>
                                    <div>
                                      <span>Order</span>
                                      <a href={"#/orders/order/" + email.order_id}>
                                        #{email.order_id}
                                      </a>
                                      <Button onClick={() => { window.open("#/orders/order/" + email.order_id) }} variant="link" size="icon">
                                        <FontAwesomeIcon icon={faExternalLinkAlt} fixedWidth />
                                      </Button>
                                    </div>
                                </li>
                              }
                              {email.quote_id && 
                                <li>
                                  <div>
                                    <span>Project</span>
                                    <a href={"#/projects/project/" + email.quote_id}>
                                      #{email.quote_id}
                                    </a>
                                    <Button onClick={() => { window.open("#/projects/project/" + email.quote_id) }} variant="link" size="icon">
                                      <FontAwesomeIcon icon={faExternalLinkAlt} fixedWidth />
                                    </Button>
                                  </div>
                                </li>
                              }
                              {email.sign_id && 
                                <li>
                                  <div>
                                    <span>Sign</span>
                                    <a href={"#/signs/sign/" + email.sign_id}>
                                      #{email.sign_id}
                                    </a>
                                    <Button onClick={() => { window.open("#/orders/order/" + email.sign_id) }} variant="link" size="icon">
                                      <FontAwesomeIcon icon={faExternalLinkAlt} fixedWidth />
                                    </Button>
                                  </div>
                                </li>
                              }
                            </AssociationsList>
                          }
                      </Property>
                      
                      <Property label={email.status == "Sent" ? "Date Sent" : "Date Received"} value={formatDateTime(email.added_date)} />
                      {(email.attachments.length > 0) &&
                        <Property label="Attachments" styles={{display:"block"}}>
                          {email.attachments.map(attachment => (
                              <div key={attachment.id}>
                                  <FontAwesomeIcon icon={faPaperclip} fixedWidth />
                                  {' '}
                                  <a href={storage.root + attachment.version_url} target="_blank">
                                    {attachment.filename}
                                  </a>
                              </div>
                          ))}
                        </Property>
                      }
                  </PropertyList>

                  <EmailBody>
                      <span 
                        dangerouslySetInnerHTML={{__html: email.type == "sms" ? linkifyText(email.body) : email.body}} 
                        style={{whiteSpace: email.type == "sms" ? "pre-line":"unset" }}
                      />
                  </EmailBody>

                  {(email.replies.length > 0) &&
                    <>
                      <br />

                      {(email.type == "sms") ? 
                        <SmsMessageList 
                          messages={email.replies} 
                          onMessage={(message) => {
                            props.onEmail(message);
                          }}
                        />
                      :
                        <PropertyList>
                          <Property label="Email Replies" styles={{display:"block"}}>
                            {email.replies.map(reply => (
                                <div key={reply.id}>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                    {' '}
                                    <a 
                                      href="#" 
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        props.onEmail(reply)
                                      }}
                                    >
                                      {formatDateTime(reply.added_date)}
                                    </a>
                                </div>
                            ))}
                          </Property>
                        </PropertyList>
                      }
                    </>
                  }
                </>
            }
        </Modal.Body>
        <Modal.Footer>
            {email && 
              <>
                {((email.status == "Received" || email.status == "Completed") && props.onReply) && 
                  <Button variant="primary" disabled={!canSend} onClick={props.onReply}>
                    <FontAwesomeIcon icon={faEnvelope} />{' '}
                    Reply
                  </Button>
                }
                {props.onForward && 
                  <Button variant="outline-secondary" disabled={!canSend} onClick={props.onForward}>
                    <FontAwesomeIcon icon={faArrowRight} />{' '}
                    {!isMobile ? "Forward":""}
                  </Button>
                }     
                {(!email.completed_date && email.status != "Sent" && email.status != "Spam") && 
                  <Button variant="success" disabled={!canEdit} onClick={onComplete}>
                    <FontAwesomeIcon icon={busy=="completed" ? faSpinner:faCheck} spin={busy=="completed"} />{' '}
                    {!isMobile ? "Complete":""}
                  </Button>
                }
                {email.completed_date && 
                  <Button variant="outline-warning" disabled={!canEdit} onClick={onUncomplete}>
                    <FontAwesomeIcon icon={busy=="uncompleted" ? faSpinner:faCheck} spin={busy=="uncompleted"} />{' '}
                    Uncomplete
                  </Button>
                }
                {(email.status == "Spam") && 
                  <Button variant="outline-success" disabled={!canEdit} onClick={onNotSpam}>
                    <FontAwesomeIcon icon={busy=="notspam" ? faSpinner:faCheck} spin={busy=="notspam"} />{' '}
                    Not Spam
                  </Button>
                }
                {props.onDelete &&
                  <Button variant="outline-danger" disabled={!canDelete} onClick={onDelete}>
                    <FontAwesomeIcon icon={busy=="delete" ? faSpinner:faTrash} spin={busy=="delete"} />{' '}
                  </Button>
                }
              </>
            }
        </Modal.Footer>
      </Modal>
    )
}

export default ViewEmailDrawer;