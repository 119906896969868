import React, { useState } from "react";
import styled from "styled-components"
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSpinner } from '@fortawesome/free-solid-svg-icons'

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

import { postData } from "../../common/services/server"
import storage from '../../settings/storage'

const Job = styled.div`
    cursor: pointer
    display: flex;
    font-size: 14px;
    line-height: initial;

    img {
        margin-right: 10px;
    }

    div.user {
        font-size: 12px;
    }

    small {
        color: #999;
    }
`

function JobSelect({
    name,
    required,
    queryOptions,
    onChange,
    ...props
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [jobs, setJobs] = useState([]);

    function handleSearch(query) {
        setIsLoading(true);

        postData("search/jobs", { 
            search: query,
            ...queryOptions
        },
            function(matches) {
                setJobs(matches);
            },
            function(error) {
                console.log(error);
            },
            function() {
                setIsLoading(false);
            }
        );
    }

    return (
        <AsyncTypeahead
          id={name}
          options={jobs}
          useCache={false}
          allowNew={false}
          isLoading={isLoading}
          labelKey="title"
          minLength={1} 
          defaultInputValue={props.default?.name ?? ""}
          inputProps={{ required: required }}
          onSearch={handleSearch}
          onChange={ (selected) => onChange(selected[0]) }
          placeholder="Search for job..."
          searchText={<><FontAwesomeIcon icon={faSpinner} spin />&nbsp;Searching...</>}
          filterBy={(option, props) => {
            return true;
          }}
          renderMenuItemChildren={(job) => (              
            <Job key={job.id}>
                <div className="icon">
                    <img src={storage.root + (job.preview_url || "images/nothumbnail.png")} width={40} />
                </div> 
                <div>
                    <div>
                        <strong>#{job.title}</strong>
                    </div>
                    <div>
                        <small>Order #{job.order_id}</small>
                    </div>
                    {job.user_name && 
                        <div className="user">
                            <FontAwesomeIcon icon={faUser} />{' '}
                            {job.user_name}
                        </div>
                    }
                </div>
            </Job>
          )}
        />
    );
  }

export default JobSelect