import clsx from "clsx";
import React from "react";

function HelpText(props) {
    return (
        <div className={clsx(
            "text-secondary",
            props.className
        )}>
            <small dangerouslySetInnerHTML={{__html: props.children }} style={props.style} />
        </div>
    )
}

export default HelpText