import React from "react";
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
    text-align:center;
    padding: ${props => props.padding ? props.padding : props.size=="icon" ? "0" : props.size=="sm" ? 25 : 50}px;
    font-size: ${props => props.size=="icon" ? 15 : props.size=="sm" ? 20:40}px;
    color: ${props => props.color};
`

function Loading(props) {
    return (
        <Container className="loading" size={props.size || "lg"} padding={props.padding} color={props.color || "#ccc"}>
            <FontAwesomeIcon icon={faSpinner} spin size={props.size || "lg"} />
            {(!props.nolabel && props.size != "icon") && 
                <>&nbsp;{props.label || "Loading..."}</>
            }
        </Container>
    );
  }

export default Loading