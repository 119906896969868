import React from "react";

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import Alert from "./alert";
import { isError, isObject, isString } from "../helpers/utility";

function Error({
    message,
    error,
    size,
    className
}) {
    console.log({error})
    return (
        <Alert
            icon={faExclamationCircle}
            variant="danger"
            size={size}
            className={className}
        >
            Error: {error && isObject(error) || isError(error) ? error.message : isString(error) ? error : message || "Unknown error occured"}
        </Alert>
    );
  }

export default Error