import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

import storage from '../settings/storage'

function GoogleLink(props) {
    const url = (props.root ? storage.root : storage.url) + props.src;
    
    return (
      props.src ?
        <a href={url} target="_blank" className="text-blue-500">
            <FontAwesomeIcon icon={faFile} />{' '}
            {props.label}
        </a>
      :
        ""
    );
}

export default GoogleLink