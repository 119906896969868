import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'

import { postData } from "../common/services/server";
import PanelTable from "./panel-table"
import Loading from "../components/loading";
import { formatDate } from "../helpers/utility";

function Panel({}) {
  const [loading, setLoading] = useState(false);
  const [orderItems, setOrderItems] = useState([]);

  useEffect(() => {
    postData("parts/orderitems", {}, 
        function(response) {
            setOrderItems(response);
        },
        function(error) {
            alert("An error occured loading the orders.");
        },
        function() {
            setLoading(false);
        }
    );    
  }, []);

  return (
    <>
      {loading ?
        <Loading />
      :
        <PanelTable>
          <thead>
            <tr>
              <th>Id</th>
              <th>Order Date</th>
              <th>Quantity</th>
              <th>Job #</th>
              <th>Job Status</th>
            </tr>
          </thead>
          <tbody>
              {orderItems.map(orderitem => (
                  <tr key={orderitem.id}>
                      <td>
                          {orderitem.id}
                      </td>
                      <td>
                        {formatDate(orderitem.added_date)}
                      </td>
                      <td>
                        {orderitem.quantity}
                      </td>
                      <td>
                        <Link to={"/jobs/job/" + orderitem.job_id}>
                          {orderitem.job_title}
                        </Link>
                      </td>
                      <td>
                        {orderitem.job_status_title}
                      </td>
                  </tr>
              ))}
          </tbody>
        </PanelTable>   
      }               
    </>
  );
}

export default Panel;