import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetStatuses = (type, options={}) => {
  return useQuery({ 
    queryKey: ["statuses", type], 
    queryFn: ({signal}) => query("statuses/list", {type}, signal),
    ...options
  });
}
