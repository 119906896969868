import React, { useState, useEffect } from "react";
import CheckListForm from "../components/forms/form-checklist";
import CheckListInfoForm from "../components/forms/form-checklistinfo";

export default function JobWorkflowQcPanel({jobId}) {
    const [questionName, setQuestionName] = useState(null);

    return (
        <div className="grid grid-cols-2 gap-2">
            <CheckListForm
                type="Job"
                item="job"
                itemId={jobId}
                onAnswer={null}
                onQuestion={(question) => {
                    console.log(question)
                    setQuestionName(question ? question.name : null);
                }}
            />
            <CheckListInfoForm
                questionName={questionName}
                item="job"
                itemId={jobId}
            />
        </div>
    )
}