import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutate } from '../api';

export const useDeleteHoliday = () => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (holidayId) => {
            return mutate("holidays/holiday/delete", {
                id: holidayId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["holidays"]);
        },    
  })
};

export const useAddHoliday = () => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (holiday) => {
            return mutate("holidays/add", holiday)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["holidays"]);
        },    
  })
};
