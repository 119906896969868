import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faPaintBrush, faSave, faScrewdriver } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import SelectField from "../components/fields/field-select"
import ListField from "../components/fields/field-list"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

function DimensionDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [sizes, setSizes] = useState([])
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (props.dimension) {
      setFormData(JSON.parse(JSON.stringify(props.dimension)));

      const sizeData = [];

      for (let i=1; i<= 100; i++) {
          sizeData.push({value: i, label: i});
      }

      setSizes(sizeData);
    }
  }, [props.dimension]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("shapes/savedimension", removeNulls({
          ...formData,
          custom_ind: 0
        }), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the prewire data.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();

    setFormData(produce(draft => {
      draft[event.target.name] = event.target.value;
    }));
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faLightbulb} />
            &nbsp;
            Prewire Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
            <ListField 
                name="size" 
                label="Size" 
                value={formData.size} 
                list={sizes}
                onChange={(e) => handleFormChange(e)} 
            />
            <SelectField 
                name="part_id" 
                label="Part" 
                required 
                url="parts/data?type=lighting"
                labelField="title"
                value={formData.part_id} 
                onChange={(e) => handleFormChange(e)} />

            <div style={{width:"120px"}}>
                <TextField 
                    name="num_leds" 
                    label="Number of LEDs" 
                    value={formData.num_leds} 
                    onChange={(e) => handleFormChange(e)} />
            </div>

            <TextField 
                name="prewire_sticker1" 
                label="Prewire Sticker 1" 
                value={formData.prewire_sticker1} 
                onChange={(e) => handleFormChange(e)} />
            <TextField 
                name="prewire_sticker2" 
                label="Prewire Sticker 2" 
                value={formData.prewire_sticker2} 
                onChange={(e) => handleFormChange(e)} />
            <TextField 
                name="prewire_sticker3" 
                label="Prewire Sticker 3" 
                value={formData.prewire_sticker3} 
                onChange={(e) => handleFormChange(e)} />
            <TextField 
                name="prewire_sticker4" 
                label="Prewire Sticker 4" 
                value={formData.prewire_sticker4} 
                onChange={(e) => handleFormChange(e)} />
            <TextField 
                name="prewire_sticker5" 
                label="Prewire Sticker 5" 
                value={formData.prewire_sticker5} 
                onChange={(e) => handleFormChange(e)} />
            <TextField 
                name="prewire_sticker6" 
                label="Prewire Sticker 6" 
                value={formData.prewire_sticker6} 
                onChange={(e) => handleFormChange(e)} />

          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default DimensionDrawer;