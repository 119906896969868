import React from "react"
import styled from 'styled-components'

import ColorBox from './color-box'

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;

    > div {
      cursor: pointer;
    }
`

function ColorGrid(props) {
    return (
      <Grid>
        {props.colors.map(color => (
            <ColorBox 
              key={color.id} 
              onClick={() => props.onColor ? props.onColor(color):null}
              color={color} 
              dual={props.system.name==="Day/Night"} 
            />
        ))}
      </Grid>
    );
}

export default ColorGrid