function getPosOfLastTag(body, tag) {
    let text = body.toLowerCase();
    let pos = text.indexOf("<"+tag);
    let idx = 0;

    while (pos > 0) {
        let tempPos = text.indexOf("<"+tag, pos+tag.length);

        if (tempPos != -1) 
            pos = tempPos;
        else 
            break;

        idx++;

        if (idx >= 100) break; // sanity check...
    }

    if (pos > 0) {
        let endPos = text.indexOf(">", pos);

        if (endPos != -1)
            pos = endPos;
        else 
            pos = -1; // big deal if can't find end tag
    }
}

export function parseOutReplyText(body) {
    let reply = body;

    const bodyStartPos = getPosOfLastTag("body"); //reply.indexOf("<body>");

    if (bodyStartPos >= 0) {
        const bodyEndPos = reply.indexOf("</body>", bodyStartPos);

        if (bodyEndPos >= 0) {
            reply = reply.substring(bodyStartPos, bodyEndPos);
        }
    }

    return reply;
}

export function parseOutForwardText(body) {
    // they are the same right now
    return parseOutReplyText(body);
}

export function formatReplyText(body, user) {
    return `
        <br>
        <br>
        <p>
        ` + (user && user.first_name ? user.first_name : "Thanks") + `,<br />
        SignMonkey.com<br />
        signs built for easy installation<br />
        (304) 399-3503<br />  
        </p>
    `
}

export function isOutgoingEmail(email) {
    return email.from.toLowerCase().includes("signmonkey.com");    
}

export function isIncomingEmail(email) {
    return !email.from.toLowerCase().includes("signmonkey.com");    
}

export function isAutomatedEmail(email) {
    const address = email.from.toLowerCase();

    return address.includes("website@signmonkey.com") ||
           address.includes("support@signmonkey.com") || 
           address.includes("orders@signmonkey.com") ||
           address.includes("monkey@signmonkey.com") ||
           address.includes("contact@signmonkey.com");
}