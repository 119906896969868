import React, { useRef } from "react";
import QRCode from "qrcode.react";
import ReactToPrint from "react-to-print";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BoardField from "./board/board-field"
import Button from "./buttons/button";

import { formatDate } from "../helpers/utility";
import storage from "../settings/storage";

function Kanban({
  part,
  edit,
  print,
  onChange
}) {
  const kanbanRef = useRef();

  console.log("kanban part", part)
  return (
    <>
      <div ref={kanbanRef}>
        <div className="card" style={{
          borderLeft: "20px solid DarkOrange",
          borderRight: "20px solid DarkOrange",      
          backgroundColor: "white",
          marginBottom: "25px",
        }}>
          <div className="header" style={{
            display: "flex",
            backgroundColor: "black",
            color: "white",
            textAlign: "center",
            padding: "5px",
            marginBottom: "10px"
          }}>
            <div style={{flex: 1}}>{part.part_number}-{part.version_number} {part.title}</div>
          </div>

          <div className="body" style={{
            display: "grid",
            gridTemplateColumns: "60% 40%",
            alignItems: "center",
            flex: 1
          }}>
            <div style={{
              textAlign: "center",
              padding: "3px",
            }}>
              <img 
                src={storage.root + "2020/parts/" + part.name + "/icon.jpg?" + (new Date().getTime())} 
                className="icon"
                height="70"
                style={{
                  height: "auto",
                  width: "auto",
                  maxHeight: "70px",
                  maxWidth: "200px"
                }}
              />
            </div>          
            <div style={{
              textAlign: "center"
            }}>
              <QRCode 
                  value={`https://admin.signmonkey.com/#/parts/order/${part.id}`} 
                  size={70} 
                  renderAs="canvas" 
              />
            </div>
            <div className="prop">Min qty - {part.min_stock_qty || "?"}</div>
            <div className="prop">Order qty - {part.reorder_qty || "?"}</div>
            <div className="prop">Location Shelf -</div>
            <div className="prop">{part.reorder_days || "?"} day production</div>
          </div>

          <div className="footer" style={{
            backgroundColor: "DarkOrange",
            paddingRight: "5px",
            textAlign: "right",
          }}>
            {formatDate(new Date(), "yymmdd")}
          </div>
        </div>

        <div className="card" style={{
          borderLeft: "20px solid DarkOrange",
          borderRight: "20px solid DarkOrange",      
          borderBottom: "20px solid DarkOrange",      
          backgroundColor: "white"
        }}>
          {[...Array(6)].map((e, index) => ( 
              <div className="line" key={index} style={{
                borderTop: "1px solid black", 
                padding: "5px"
              }}>
                {edit &&
                  <div className="noprint">
                    <BoardField
                      type="text"
                      label={"Line " + (index+1)}
                      value={part["kanban_line" + (index+1)] || ""}
                      saveUrl={`parts/updateprop?part_id=${part.id}&prop=kanban_line${index+1}`}
                      onSaved={onChange}
                    />
                  </div>
                }
                <div className="print" style={{
                  display: edit ? "none":"block",
                  padding: edit ? "0px":"2px",
                }}>
                    {part["kanban_line" + (index+1)] || " "}
                </div>
              </div> 
          ))}
        </div>
      </div>

      {print &&
        <ReactToPrint
            copyStyles={false}
            trigger={() => 
                <Button variant="primary" style={{margin: "10px"}}> 
                    <FontAwesomeIcon icon={faPrint} />{' '}
                    Print
                </Button> 
            }
            content={() => kanbanRef.current}
            pageStyle={`
              @page {
                  margin: 1in;
              }

              * {
                box-sizing: border-box;
              }

              html, body {
                font-family: Tahoma, Geneva, sans-serif;
                width: 3.5in;
                min-width: 3.5in;
                max-width: 3.5in;              
                margin:0;
                padding:0;                            
                font-size:12px;
              }

              div.card {
                box-sizing: border-box !important;
                height: 2in !important;
                max-height: 2in;
                width: 3.5in;
                overflow: hidden;
                font-size: 12px;
              }
              div.header {
                height: 20%;
                fontSize: 12px;
                overflow: hidden;
                white-space: nowrap;
              }
              div.body {
                height: 65%;
                overflow: hidden;
              }
              div.prop {
                padding-left: 5px;
              }
              div.footer {
                height: 10%;
                overflow: hidden;
              }
              div.line {
                height: 16%;
                padding: 0px 5px !important;
              }
              
              img.icon {
                max-height: 1in !important;
                max-width: 1.5in !important;
              }

              .noprint {
                display: none !important;
              }
              .print {
                display: block !important;
              }
            `}
        />
      }
    </>
  );
}

export default Kanban;