import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { faPlug, faSave } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading";
import Error from "../components/error";
import { useGetParts } from "../api/queries/parts";
import storage from "../settings/storage";
import Button from "../components/buttons/button";
import NumberField from "../components/fields/field-number"
import Kanban from "../components/kanban";
import ButtonProcessing from "../components/buttons/button-processing";

export default function PanelPartOrder({partId}) {
    const history = useHistory();

    const [part, setPart] = useState(null);

    const partsQuery = useGetParts();
    const parts = partsQuery.data?.filter(p => p.mode != "frontend");
  
    const [formData, setFormData] = useState({
        quantity: 1
    });

    useEffect(
        () => {
            setPart(partId ? parts.find(p => p.id==partId) : null);
        }, 
        [partId]
    );
    useEffect(
        () => {
            if (!part) return;

            setFormData({
                quantity: part.reorder_qty || 1
            });
        }, 
        [part]
    );

    function handleFormChange(event) {
        if (event.persist) event.persist();
        console.log("handleFormChange", event.target.name, event.target.value)
        const field = event.target.name;
        let value = event.target.value;
    
        setFormData(produce(draft => {
          draft[field] = value;
        }));
      }

    return (
        <>
        {partsQuery.isLoading ?
            <Loading />
        : partsQuery.isError ? 
            <div className="p-1">
                <Error message={partsQuery.error.message} />
            </div>
        :
            <div className="grid grid-cols-[400px,1fr] ga-2">
                <div className="bg-gray-200 p-1 overflow-y-auto overflow-x-hidden flex flex-col gap-1 items-start" style={{height: "calc(100vh - 150px)"}}>
                    {parts.filter(p => !partId || p.id==partId).map(part => (                
                        <button 
                            key={part.id} 
                            className="flex items-center gap-2 p-1 bg-white border rounded text-sm text-left w-full"
                            onClick={() => history.push("/parts/order/" + part.id)}
                        >
                            <div style={{width: "50px"}}>
                                <img
                                    src={storage.url + "parts/" + part.name + "/icon.jpg?" + (new Date().getTime())} 
                                    height="20"
                                    style={{
                                        width: "100%",
                                        height: "auto",
                                        maxWidth: "50px",
                                        maxHeight: "30px"
                                    }}
                                    onError={(e) => {
                                        if (e.target.src != (storage.root + "images/nothumbnail.jpg")) {
                                            e.target.src = storage.root + "images/nothumbnail.jpg";
                                        }
                                        e.target.onerror = null; // just in case to prevent recursive error
                                        e.target.onError = null; // just in case to prevent recursive error
                                    }}                                 
                                />
                            </div>
                            <div>
                                {part.part_number && <div>{part.part_number}</div>}
                                {part.title}
                            </div>
                        </button>
                    ))}  

                    {partId && 
                        <div className="">
                            <Button icon={faPlug} size="sm" variant="outline-secondary" onClick={() => history.push("/parts/order/")}>
                                Select Different Part
                            </Button>
                        </div>
                    }          
                </div>
                {part && 
                    <div className="p-2 flex flex-col gap-4">
                        <Kanban part={part} />

                        <div className="flex items-center justify-between gap-5">
                            <NumberField
                                name="quantity" 
                                label="Quantity" 
                                layout="horizontal"
                                required
                                value={formData.quantity} 
                                onChange={(e) => handleFormChange(e)} 
                            />

                            <ButtonProcessing 
                                processing={false}
                                onClick={() => {

                                }} 
                                disabled={true}
                                variant="outline-success" 
                                caption="Place Order" 
                                icon={faSave} 
                            />
                        </div>                         
                    </div>
                }
            </div>
        }               
        </>
    );
}