import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutate } from '../api';

export const useAddTask = (options={}) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (taskData) => {
            return mutate("tasks/add", taskData)
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["tasks"]);

            if (options.onSuccess)
                options.onSuccess(data, variables, context)
        },    
  })
};
