import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faCreditCard } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import DateField from "../components/fields/field-date"
import SelectField from "../components/fields/field-select"
import UserField from "../components/fields/field-user"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"

function OrderDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(props.order)));
  const [validated, setValidated] = useState(false);

  /*
  useEffect(() => {
    console.log("setting post data", props.order)
    if (props.order) {
      setFormData(props.order);
    }
  }, [props.order]);
    */

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("orders/save?id=" + (props.order.id||0), formData, 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the order.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;
    let value = event.target.value;

    if (field == "user_id") {
        value = event.target.value.id;
    }

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  const { order } = props;

  return (
      <Modal show={props.show} animation={false} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faCreditCard} />
            &nbsp;
            {props.order.id ? "Update":"Create"} Order {props.order.id ? "#"+props.order.id:""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>

            <SelectField 
                name="company_id" 
                label="Company" 
                required 
                url="orders/companies"
                labelField="title"
                value={formData.company_id} 
                onChange={(e) => handleFormChange(e)} />
            <UserField 
                id="order-user"
                name="user_id" 
                label="User" 
                required
                value={{id:formData.user_id, name: formData.user_name}}
                onChange={(e) => handleFormChange(e)} />
            <DateField 
                name="order_date" 
                label="Order Date" 
                required 
                value={formData.order_date}
                onChange={(e) => handleFormChange(e)} />
            <SelectField 
                name="status_id" 
                label="Status" 
                required 
                url="orders/statuses"
                labelField="title"
                value={formData.status_id} 
                onChange={(e) => handleFormChange(e)} />

            <Tabs defaultActiveKey="shipping">
                <Tab eventKey="shipping" title="Shipping Address" className="bordered">
                    <TextField 
                        name="ship_name" 
                        label="Care Of" 
                        value={formData.ship_name} 
                        onChange={(e) => handleFormChange(e)} />
                    <TextField 
                        name="ship_address1" 
                        label="Address 1" 
                        required
                        value={formData.ship_address1} 
                        onChange={(e) => handleFormChange(e)} />
                    <TextField 
                        name="ship_address2" 
                        label="Address 2" 
                        value={formData.ship_address2} 
                        onChange={(e) => handleFormChange(e)} />

                    <Form.Row>
                        <TextField 
                            name="ship_city" 
                            label="City" 
                            column={true}
                            required 
                            value={formData.ship_city} 
                            onChange={(e) => handleFormChange(e)} />
                        <SelectField 
                            name="ship_state_id" 
                            label="State" 
                            column={true}
                            required 
                            url="states/data"
                            labelField="abbrev2"
                            value={formData.ship_state_id} 
                            onChange={(e) => handleFormChange(e)} />  
                        <TextField 
                            name="ship_zipcode" 
                            label="Zip Code" 
                            column={true}
                            required 
                            value={formData.ship_zipcode} 
                            onChange={(e) => handleFormChange(e)} />
                    </Form.Row>
                </Tab>
                <Tab eventKey="billing" title="Billing Address" className="bordered">
                    <TextField 
                        name="billing_name" 
                        label="Name" 
                        value={formData.billing_name} 
                        onChange={(e) => handleFormChange(e)} />
                    <TextField 
                        name="billing_company" 
                        label="Company" 
                        value={formData.billing_company} 
                        onChange={(e) => handleFormChange(e)} />
                    <TextField 
                        name="billing_address1" 
                        label="Address 1" 
                        required
                        value={formData.billing_address1} 
                        onChange={(e) => handleFormChange(e)} />
                    <TextField 
                        name="billing_address2" 
                        label="Address 2" 
                        value={formData.billing_address2} 
                        onChange={(e) => handleFormChange(e)} />
                    <Form.Row>
                        <TextField 
                            name="billing_city" 
                            label="City" 
                            required 
                            column={true}
                            value={formData.billing_city} 
                            onChange={(e) => handleFormChange(e)} />
                        <SelectField 
                            name="billing_state_id" 
                            label="State" 
                            required 
                            column={true}
                            url="states/data"
                            labelField="abbrev2"
                            value={formData.billing_state_id} 
                            onChange={(e) => handleFormChange(e)} />  
                        <TextField 
                            name="billing_zipcode" 
                            label="Zip Code" 
                            required 
                            column={true}
                            value={formData.billing_zipcode} 
                            onChange={(e) => handleFormChange(e)} />
                    </Form.Row>
                </Tab>
            </Tabs>
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Order" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default OrderDrawer;