import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

import GoogleImage from "../google-image";

import BoardPill from "./board-pill";
import BoardPills from "./board-pills";

import { formatDateTime, formatFileSize } from "../../helpers/utility";
import storage from "../../settings/storage"

const Email = styled.div`
    padding: 5px;
    background-color: white;
    font-size: 10px;
    margin-bottom: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    
    &:hover {
        background-color: #eee;
    }
`

function BoardEmail({
    email,
    onClick
}) {
    return (
        <Email onClick={onClick}>
            <div>
                {email.subject}
            </div>

            <div>
                {formatDateTime(email.added_date)}
            </div>
        </Email>
    );
}

export default BoardEmail