import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import GoogleImage from "../google-image"
import AddressForm from "./form-address"
import Loading from "../loading"
import ColorBox from "../color-box"

import { postData } from "../../common/services/server"
import storage from '../../settings/storage'
import { parseISOWithOptions } from "date-fns/fp";

function CheckListInfoForm(props) {
    const questionName = props.questionName;

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [signs, setSigns] = useState(null);

    useEffect(
        () => {
            if (questionName == "address" && !order) {
                setLoading(true);

                postData("orders/orderdata", { 
                    id: props.itemId
                },
                    function(result) {
                        setOrder(result);
                    },
                    function(error) {
                        alert("Error loading order data")
                    },
                    function() {
                        setLoading(false);
                    }
                );
            } 
        }, 
        [questionName]
    );
    useEffect(
        () => {
            if (props.item == "order" && props.itemId) {
                setLoading(true);

                postData("orders/signdata", { 
                    id: props.itemId
                },
                    function(result) {
                        setSigns(result);
                    },
                    function(error) {
                        alert("Error loading  sign data")
                    },
                    function() {
                        setLoading(false);
                    }
                );
            } 
        }, 
        [props.item,props.itemId]
    );

    return (
        <div style={{padding: "25px"}}>
            <div style={{position:"sticky", top: "55px"}}>
                { loading ? 
                    <Loading />
                : (questionName == "address" && order) ? 
                    <AddressForm 
                        address={order}
                        prefix="ship_"
                        saveUrl={"orders/updateaddress?type=ship&order_id=" + order.id}
                        onChange={(response) => {
                            console.log(response);
                            if (props.onOrderChange)
                                props.onOrderChange(response)
                        }}
                    />
                : ((questionName=="face_color" || questionName=="trim_color" || questionName=="side_color" || questionName=="raceway_color") && signs) ?
                    <>
                        {signs.map(sign => (
                            <div key={sign.id} style={{marginBottom:"20px"}}>
                                <div style={{display:"flex", alignItems:"center"}}>
                                    <GoogleImage 
                                        root 
                                        src={sign.preview_url} 
                                        style={{
                                            maxWidth: "150px",
                                            maxHeight: "100px"
                                        }}
                                    />
                                    <div>
                                        <Link to={"/signs/sign/" + sign.id} target="_blank">
                                            Sign Design #{sign.id}
                                        </Link>
                                    </div>
                                </div>
                                {sign.groups.map(group => (
                                    <div key={group.id}>
                                        {group.parts.filter(p => p.color_id && p.type_name == (questionName=="face_color" ? "face" : questionName=="trim_color" ? "trim" : questionName=="side_color" ? "side" : questionName=="raceway_color" ? "mount" : "")).map(part => (
                                            <div key={part.id} style={{display:"flex"}}>
                                                <ColorBox color={{name:part.color_name, hex_day:part.hex_day}} />
                                                {part.color_code}{': '}
                                                {part.color_name}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </>
                : ((questionName=="artwork_usable" || questionName=="br.artwork") && signs) ?
                    <>
                        {signs.map(sign => (
                            <div key={sign.id} style={{marginBottom:"20px"}}>
                                <div style={{display:"flex", alignItems:"center"}}>
                                    <GoogleImage 
                                        root 
                                        src={sign.preview_url} 
                                        style={{
                                            maxWidth: "150px",
                                            maxHeight: "100px"
                                        }}
                                    />
                                    <div>
                                        <Link to={"/signs/sign/" + sign.id} target="_blank">
                                            Sign Design #{sign.id}
                                        </Link>
                                    </div>
                                </div>
                                {sign.groups.filter(g => g.graphics && g.graphics.length > 0).map(group => (
                                    group.graphics.filter(g => g.type == "svg" || g.type == "img").map(graphic => (
                                        <div key={graphic.id} style={{display:"flex", alignItems: "center"}}>
                                            <GoogleImage 
                                                root 
                                                src={graphic.faceart_url_thumb || graphic.faceart_url} 
                                                link={graphic.faceart_url} 
                                                style={{maxHeight: "25px", maxWidth: "75px"}} 
                                            />
                                            <a href={storage.root + graphic.faceart_url} target="_blank">{graphic.filename}</a>
                                        </div>
                                    ))
                                ))}                               
                            </div>
                        ))}
                    </>
                :
                    questionName
                }
            </div>
        </div>
    );
}

export default CheckListInfoForm;