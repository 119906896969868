import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Card from  'react-bootstrap/Card';
import produce from "immer"
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faCaretDown, faSpinner } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"
import PartProductsDrawer from "../drawers/drawer-partproducts"
import Button from "../components/buttons/button";
import { Accordion, AccordionItem } from "../components/accordion";

import { postData } from "../common/services/server"
import PropertyList from "../components/properties/property-list";
import Property from "../components/properties/property";
import { removeNulls } from "../helpers/utility";
import storage from "../settings/storage";

const Parts = styled.table`
    width: 100%;
    tr th {
        background-color: #e0e0e0;
        padding: 10px;
        font-size: 12px;
    }
    tr td {
        padding: 5px 10px;
        border-bottom: 1px solid #ccc;
        font-size: 12px;
    }
`
const Part = styled.tr`
    background-color: white;
`

function SelectPartsDrawer({
    label,
    icon,
    busy,
    product, 
    existingParts,
    onSelect, 
    onHide
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [parts, setParts] = useState([]);
  const [types, setTypes] = useState([]);
  const [part, setPart] = useState(null);

  useEffect(() => {
      setIsProcessing(true);

      postData("parts/data", removeNulls({
        product_id: product ? product.id : null
      }), 
        function(results) {
          setParts(results);

          let types = [];

          for (const part of results) {
            if (!types.find(t => t.name == part.type_name)) {
                types.push({
                    name: part.type_name,
                    title: part.type
                });
            }
          }

          setTypes(types);
        },
        function(error) {
          alert("An error occured loading the parts.");
        },
        function() {
          setIsProcessing(false);
        }
      );   
  }, []);

  return (
    <>
        <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faClipboardList} />
                &nbsp;
                {label || "Select Parts"}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minWidth: "800px"}}>
                { isProcessing ? 
                    <Loading />
                :
                parts.length == 0 ?
                    <p style={{color:"red"}}>No parts found.</p>
                :
                    <>
                        {product && 
                            <>
                                <PropertyList>
                                    <Property label="Product" value={product.title} />
                                </PropertyList>                    
                                <br />
                            </>
                        }
                        <Accordion>
                            {types.map(type => {
                                let typeParts = parts.filter(p => p.type_name == type.name);

                                return (
                                    <AccordionItem key={type.name} title={type.title}>
                                        <Parts>
                                            <thead>
                                                <tr>
                                                    <th>Part</th>
                                                    <th>Description</th>
                                                    <th style={{textAlign: "center"}}>Products</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {typeParts.map(part => (
                                                    <Part key={part.id}>
                                                        <td width="225">
                                                            <div style={{
                                                                display: "grid",
                                                                gridTemplateColumns: "auto 30px 1fr",
                                                                alignItems: "center",
                                                                gap: "8px"
                                                            }}>
                                                                <Form.Check 
                                                                    name="part_id"                                
                                                                    value={part.id}
                                                                    checked={part.selected_ind == 1}
                                                                    disabled={existingParts && existingParts.find(p => p.id == part.id)}
                                                                    inline 
                                                                    size="sm"
                                                                    type="checkbox" 
                                                                    onChange={(e) => {
                                                                        e.persist();
                                                                        
                                                                        setParts(produce(draft => {
                                                                            draft[parts.findIndex(p => p.id == part.id)].selected_ind = e.target.checked ? 1:0;
                                                                        }))
                                                                    }}
                                                                />
                                                                <div style={{width: "30px"}}>
                                                                    <img 
                                                                        src={storage.root + "2020/parts/" + part.name + "/icon.jpg?" + (new Date().getTime())} 
                                                                        width="30"
                                                                        onError={(e) => e.target.src = storage.root + "images/nothumbnail.jpg"}
                                                                        style={{ width: "100%", height:"auto", maxHeight: "20px", maxWidth: "30px" }} 
                                                                    />
                                                                </div>
                                                                {part.title}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{color: "#999"}}>
                                                                {part.description}
                                                            </div>
                                                        </td>
                                                        <td align="center">
                                                            <Button
                                                                variant="outline-secondary"
                                                                size="sm"
                                                                onClick={() => setPart(part)}
                                                            >
                                                                {part.product_count}
                                                            </Button>
                                                        </td>
                                                    </Part>
                                                ))}
                                            </tbody>
                                        </Parts>                                    
                                    </AccordionItem>
                                )
                            })}
                        </Accordion>
                    </>
                }
            </Modal.Body>
            <Modal.Footer style={{display: "flex", justifyContent: "space-between"}}>
                <Button 
                    variant="outline-success" 
                    disabled={parts.filter(p => p.selected_ind == 1).length == 0 || busy}
                    onClick={() => onSelect(parts.filter(p => p.selected_ind == 1))}
                >
                    {icon && 
                        <FontAwesomeIcon icon={busy ? faSpinner:icon} spin={busy} style={{marginRight:"5px"}} />
                    }
                    {label || "Select Parts"}
                </Button>

                <Button variant="outline-secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        { part && 
            <PartProductsDrawer 
                part={part} 
                onHide={ () => setPart(null) } 
            /> 
        }           
    </>
    )
}

export default SelectPartsDrawer;