import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlug } from "@fortawesome/free-solid-svg-icons";

import Panel from "./panel";
import Field from "../components/fields/field";
import SelectField from "../components/fields/field-select";
import Loading from "../components/loading";
import { List, ListItem } from "../components/list"

import { useGetJobWorkflowChecks } from "../api/queries/workflowjobs";
import { useWorkflowJobResetWorkflow, useWorkflowJobUpdateTaskProp } from "../api/mutations/workflowjobs";
import { useGetStatuses } from "../api/queries/statuses";
import { TaskStatuses } from "../helpers/data";

import diamond from "../images/diamond.svg";
import { Accordion, AccordionItem } from "../components/accordion";

function CheckListItem({jobId, check}) {

    const statusQuery = useGetStatuses("tasks");
    const updateWorklowCheckStatus = useWorkflowJobUpdateTaskProp(jobId, check.id, "status_id");

    return (
        <AccordionItem 
            title={
                <div className="flex w-full items-center justify-between gap-2">
                    <div className="flex items-center gap-2">
                        <img src={diamond} width="20" height="20" alt="Diamond Symbol" />
                        {check.name}
                    </div>
                    <div className="flex items-center gap-2 justify-end">
                        {updateWorklowCheckStatus.isLoading && 
                            <Loading size="icon" />
                        }
                        <SelectField
                            name="check_status"
                            prepend="Status?"
                            value={check.status_id}
                            itemsQuery={statusQuery}
                            idField="id"
                            labelField="title"
                            invalid={check.status_id == TaskStatuses.skipped.id || check.status_id == TaskStatuses.invalid.id}
                            valid={check.status_id != TaskStatuses.skipped.id && check.status_id != TaskStatuses.invalid.id}
                            disabled={updateWorklowCheckStatus.isLoading}
                            onChange={(e, value) => {
                                updateWorklowCheckStatus.mutate(value);
                            }}
                        />
                    </div>
                </div>
            }
        >
            <div className="">
                {(check.parts.length > 0) ?
                    <>
                        <div>
                            Parts:
                        </div>
                        <div className="flex flex-wrap gap-1">
                            {check.parts.map(part => (
                                <div 
                                    key={part.id} 
                                    className={clsx(
                                        "rounded border bg-white px-2 py-1",
                                        part.valid ? "!border-green-500 text-green-500" : "!border-red-500 text-red-500"
                                    )}
                                >
                                    {part.title}
                                </div>
                            ))}
                        </div>
                    </>
                :
                    <div className="text-red-500 text-sm">No Parts Defined?</div>
                }
            </div>
        </AccordionItem>
    )
}

export default function JobWorkflowWorkflowChecksStep({
    jobId,
    resetOnLoad
}) {
    const [initialized, setInitialized] = useState(resetOnLoad ? false:true);

    const workflowQuery = useGetJobWorkflowChecks(jobId, {enabled: initialized});
    const workflow = workflowQuery.data;

    const resetWorkflow = useWorkflowJobResetWorkflow();

    useEffect(
        () => {
            if (!initialized) {
                resetWorkflow.mutate(jobId, {
                    onSuccess: () => setInitialized(true)
                });
            }
        }, 
        []
    );

    return (        
        <Panel query={initialized ? workflowQuery : resetWorkflow} checkReloading={!initialized}>
          {workflow &&
            <>
                <Field
                    prepend="Workflow:"
                    prependClassName="w-[100px]"
                    append={"Version " + workflow.version}
                >
                    <div className="flex-1 self-center">
                        {workflow.name}
                    </div>
                </Field>
                
                {(workflow.checks.length > 0) ?
                    <Accordion className="mt-4 w-full mb-20">
                    {workflow.checks.map((check,index) => (
                        <CheckListItem 
                            key={index}
                            jobId={jobId}
                            check={check}
                        />
                    ))}
                    </Accordion>
                :
                    <div className="text-gray-400 p-2">
                        No checks found in this workflow.
                    </div>
                }
            </>
          }
        </Panel>
    )
}
