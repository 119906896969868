import { faWrench, faEye, faBoxOpen, faTruckMoving, faCheck, faHourglass, faPaintBrush, faBan, faPencilRuler, faHammer, faSearch, faTruck, faTrash, faShoppingCart, faCreditCard, faSpinner, faPause, faExclamationTriangle, faStar, faUserSecret, faUserPlus, faClock } from '@fortawesome/free-solid-svg-icons'

export function getJobStatuses() {
    return [
        {id:3, name: "wait", title:"Pre-Build", icon: faPencilRuler},
        {id:4, name: "building", title:"Manufacturing", icon: faWrench},
        {id:5, name: "qa", title:"UL/QC", icon: faEye},
        {id:6, name: "shipping", title:"Packing", icon: faBoxOpen},
        {id:7, name: "shipped", title:"Shipped", icon: faTruckMoving},
        {id:8, name: "completed", title:"Complete", icon: faCheck},
        {id:9, name: "hold", title:"Hold", icon: faHourglass},
        {id:12, name: "void", title:"Void", icon: faBan},
        {id:50, name: "approval", title:"Art Approval", icon: faPaintBrush},
    ];
}

export function getStatusIcon(statusId) {
    switch (statusId) {
        case 1: //	pending
        case 2: //	hold
        case 3: //	wait
        case 9: //	hold	
        case 15: //	pending	
            return faHourglass;
        case 4: //	building        
        case 11: //	rebuilding	
        case 22: //	working	
            return faHammer;
        case 5: //	qa	
        case 20: //	qa	
            return faSearch;
        case 6: //	shipping	
            return faTruckMoving;
        case 7: //	shipped	
            return faTruck;
        case 8: //	completed	
            return faCheck;
        case 10: //	canceled	
        case 12: //	void	
        case 14: //	paymnetvoid	
        case 88: //	skipped	
        case 89: //	invalid	
            return faBan;
        case 13: //	payment	
            return faCreditCard;
        case 16: //	inprogress	
            return faSpinner;
        case 17: //	active	
            return faClock;
        case 18: //	paused	
            return faPause;
        case 19: //	problem
            return faExclamationTriangle;	            
        case 21: //	new	
            return faStar;            
        case 50: //	approval	
            return faUserPlus;
        case 66: //	fraudulent	
            return faUserSecret;
        case 98: //	cart	
            return faShoppingCart;
        case 99: //	deleted	           
            return faTrash;
        //case 77: //	paris	            
        // case 23: //	internal	
        default:
            return faWrench;
    }
}