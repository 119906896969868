import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus, faTrash, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Badge, Button } from "react-bootstrap"

import ProductPermitDrawer from "../drawers/drawer-productpermit";

import ButtonProcessing from "../components/buttons/button-processing";
import BoardField from "../components/board/board-field"
import BoardFields from "../components/board/board-fields";
import { postData } from "../common/services/server";
import GoogleImage from "../components/google-image";

function Panel({
  product,
  categories,
  onChange
}) {
  const [permit, setPermit] = useState(null);
  const [busy, setBusy] = useState("");

  function onDeletePermit(permit) {
    if (window.confirm("Are you sure you want to delete this permit drawing?")) {
        setBusy("delete" + permit.id)

        postData("products/deletepermit", {
            product_id: product.id,
            id: permit.id
        }, 
        function(result) {
            const permitsData = JSON.parse(JSON.stringify(product.permits));
            const permitIdx = permitsData.findIndex(p => p.id == permit.id);
                
            if (permitIdx != -1) {
                permitsData.splice(permitIdx, 1);
        
                onChange({
                  permits: permitsData
                })              
            }
        },
        function(error) {
            alert("An error occured removing the sticker.");
        },
        function() {
            setBusy("")
        }
    );        
    }
  }

  return (
    <>
      <BoardField
        type="list"
        label="Category"
        value={product.category_id}
        items={categories}
        saveUrl={`products/updateprop?product_id=${product.id}&prop=category_id`}
        onSaved={onChange}
      />         
      <BoardField
        type="text"
        label="Title"
        value={product.title}
        saveUrl={`products/updateprop?product_id=${product.id}&prop=title`}
        onSaved={onChange}
      />

      <BoardField
        type="text"
        label="Short Title"
        value={product.short_title}
        saveUrl={`products/updateprop?product_id=${product.id}&prop=short_title`}
        onSaved={onChange}
      />      
      <BoardField
        type="paragraph"
        label="Description"
        value={product.description}
        saveUrl={`products/updateprop?product_id=${product.id}&prop=description`}
        onSaved={onChange}
      /> 
      <BoardField
        type="text"
        label="Part Number"
        value={product.part_number}
        saveUrl={`products/updateprop?product_id=${product.id}&prop=part_number`}
        onSaved={onChange}
      />         
      {product.bundle_id && 
        <BoardField
          type="googleimage"
          label="Product Group"
          value={"2020/products/" + product.bundle_name + "/product.jpg?v6"}
        />
      }           

      {product.sign_ind == 1 && 
        <BoardFields label="Permit Drawings">
          {product.permits.length > 0 ? 
            <>
              {product.permits.map(permit => (            
                <BoardField
                  key={permit.id}
                  type="children"
                  label="Permit Drawing"
                  infoWarning={permit.extension.toLowerCase() != "svg" ? "Permit is NOT a SVG file." : null}
                  suffix={
                    <>
                      <Button size="sm" variant="outline-primary" onClick={() => setPermit(permit)}>
                          <FontAwesomeIcon icon={faPencilAlt} />
                      </Button>                      
                      <Button size="sm" variant="outline-danger" onClick={() => onDeletePermit(permit)}>
                          <FontAwesomeIcon icon={busy == ("delete"+permit.id) ? faSpinner : faTrash} spin={busy == ("delete"+permit.id)} />
                      </Button>  
                    </>                      
                  }
                >
                  <GoogleImage 
                      root 
                      src={permit.preview_url}
                      style={{
                          maxHeight: "80px",
                          cursor: "pointer"
                      }}
                      link={permit.preview_url}
                  />
                  {permit.parts_count > 0 && 
                      <div style={{display:"flex", gap: "3px", flexFlow: "wrap", margin: "10px 3px 0px 0px"}}>
                          {permit.parts.split(",").map(part => (
                              <Badge variant="secondary" size="sm">
                                  {part}
                              </Badge>
                          ))}
                      </div>
                  }
                </BoardField>
              ))}
            </>
          :
            <p style={{textAlign:"center", color:"#999", margin:"25px"}}>
              No Permits Found
            </p>
          }

          <div style={{margin:"5px 1px", display:"flex", gap:"5px"}}>
              <ButtonProcessing
                  caption="Add Permit Drawing"
                  icon={faPlus}
                  size="sm"
                  processing={busy == "addpermit"}
                  variant="outline-primary"
                  onClick={() => setPermit({product_id: product.id})}
              />
          </div>              
        </BoardFields>        
      }
      {/* 
      {product.sign_ind == 1 && 
        <BoardField
          type="googleimage"
          label="Old Permit Drawing"
          value={"images/permits/" + (product.bundle_name || product.name) + ".png?v3"}
          zoom
        />
      } 
      */}
      {product.sign_ind == 1 && 
        <BoardField
          type="number"
          label="Face Count"
          info="Single sided, double sided, etc"
          value={product.face_count}
          saveUrl={`products/updateprop?product_id=${product.id}&prop=face_count`}
          onSaved={onChange}
        />
      }   

      {permit && 
        <ProductPermitDrawer
            permit={permit}
            product={product}
            parts={product.parts}
            onSaved={(permitData) => {
              const permitsData = JSON.parse(JSON.stringify(product.permits));
              const permitIdx = permitsData.findIndex(p => p.id == permitData.id);
                  
              if (permitIdx != -1)
                permitsData[permitIdx] = permitData
              else 
                permitsData.push(permitData);
          
              onChange({
                  permits: permitsData
              })
              setPermit(null);
            }}
            onHide={ () => setPermit(null) } 
        />
      }            
    </>
  );
}

export default Panel;