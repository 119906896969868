import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import { parseISO, format } from 'date-fns'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import { postData } from "../common/services/server"

const Items = styled.table`
    width: 100%;
    font-size: 12px;

    thead {
        th {
            background-color: #eee;
            padding: 5px;
        }
    }
    tbody {
        td {
            border-bottom: 1px solid #eee;
            padding: 5px;
        }
    }
`

function CartProblemsDrawer(props) {
  const [loading, setLoading] = useState(false);
  const [problems, setProblems] = useState([]);

  useEffect(() => {
    setLoading(true);

    postData("carts/problems", { 
        cart_id: props.cart.id
    },
      function(result) {
        setProblems(result);
      },
      function(error) {
        alert("Error loading cart problems")
      },
      function() {
        setLoading(false);
      }
    );
  }, [props.cart]);

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faShoppingCart} />
            &nbsp;
            Shopping Cart Problems
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { loading ? 
                <Loading /> 
              :
                <Items>
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th>Date</th>
                            <th>Problem</th>
                        </tr>
                    </thead>
                    <tbody>
                    {problems.map(problem => (
                        <tr key={problem.id}>
                            <td>{ problem.action }</td>
                            <td>{ format(parseISO(problem.action_date), "MM/dd/yyyy") }</td>
                            <td>{ problem.problem }</td>
                        </tr>
                    ))}
                    </tbody>
                </Items>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default CartProblemsDrawer;