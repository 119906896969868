import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutate } from '../api';

export const useCreateProjectSign = (projectId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return mutate("signs/create", {
                project_id: projectId,
                added_from: "Admin Project"
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["signs", "list"]);
            queryClient.invalidateQueries(["cards", "signs"]);

            // if (prop == "user_id") {
            //     queryClient.invalidateQueries(["cards", "user"]);
            // }
        },    
  })
};

export const useAddProjectSign = (projectId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (signId) => {
            return mutate("signs/sign/addtoproject", {
                project_id: projectId,
                sign_id: signId,
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", "list"]);
            queryClient.invalidateQueries(["signs", ""+variables]);
            queryClient.invalidateQueries(["cards", "signs"]);

            // if (prop == "user_id") {
            //     queryClient.invalidateQueries(["cards", "user"]);
            // }
        },    
  })
};

export const useSetSignProp = (signId, prop) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (value) => {
            return mutate("signs/sign/setprop", {
                id: signId,
                prop,
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["signs", ""+signId]);

            // if (prop == "user_id") {
            //     queryClient.invalidateQueries(["cards", "user"]);
            // }
        },    
  })
};

export const useAddGroupToSign = (signId, options={}) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (type) => {
            return mutate("signs/sign/group/add", {
                sign_id: signId,
                type
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["signs", ""+signId]);
        },    
        ...options
  })
};

export const useSetSignGroupProp = (signId, signGroupId, prop) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (value) => {
            return mutate("signs/sign/group/setprop", {
                sign_id: signId,
                signgroup_id: signGroupId,
                prop,
                value
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["signs", ""+signId]);

            // if (prop == "user_id") {
            //     queryClient.invalidateQueries(["cards", "user"]);
            // }
        },    
  })
};

export const useSetSignGroupSize = (signId, signGroupId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (size, height, width) => {
            return mutate("signs/sign/group/setsize", {
                sign_id: signId,
                signgroup_id: signGroupId,
                size,
                height,
                width
            });
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);

            if (onSuccess)
                onSuccess(data, variables, context)
        },  
        ...otherOptions
    })
};

export const useSetSignGroupDimensions = (signId, signGroupId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: ({height, width, overallHeight, overallWidth, size}) => {
            return mutate("signs/sign/group/setdimensions", {
                sign_id: signId,
                signgroup_id: signGroupId,
                height,
                width,
                overall_height: overallHeight || null,
                overall_width: overallWidth || null,
                size
            });
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);

            if (onSuccess)
                onSuccess(data, variables, context)
        },  
        ...otherOptions
    })
};

export const useSetSignGroupRelatedShapeSize = (signId, signGroupId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (params) => {
            return mutate("signs/sign/group/relatedshape/setsize", {
                sign_id: signId,
                signgroup_id: signGroupId,
                shapes_related_id: params.shapes_related_id,
                size: params.size,
            });
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);

            if (onSuccess)
                onSuccess(data, variables, context)
        },  
        ...otherOptions
    })
};

export const useRemoveSignGroupRelatedShape = (signId, signGroupId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (shapesRelatedId) => {
            return mutate("signs/sign/group/relatedshape/remove", {
                sign_id: signId,
                signgroup_id: signGroupId,
                shapes_related_id: shapesRelatedId,
            });
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);

            if (onSuccess)
                onSuccess(data, variables, context)
        },  
        ...otherOptions
    })
};

export const useSetSignGroupLetters = (signId, signGroupId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (letters) => {
            return mutate("signs/sign/group/setletters", {
                sign_id: signId,
                signgroup_id: signGroupId,
                letters
            });
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);

            if (onSuccess)
                onSuccess(data, variables, context)
        },  
        ...otherOptions
    })
};

export const useSetSignGroupShape = (signId, signGroupId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (shapeId) => {
            return mutate("signs/sign/group/setshape", {
                sign_id: signId,
                signgroup_id: signGroupId,
                shape_id: shapeId
            });
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);

            if (onSuccess)
                onSuccess(data, variables, context)
        },  
        ...otherOptions
    })
};

export const useChangeSignGroupProduct = (signId, signGroupId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, applyAll, ...otherOptions} = options;

    return useMutation({
        mutationFn: (productId) => {
            return mutate("signs/sign/group/changeproduct", {
                sign_id: signId,
                signgroup_id: signGroupId,
                product_id: productId,
                applyall_ind: applyAll?1:0,
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);
    
            if (onSuccess)
                onSuccess(data, variables, context)
        },    
        ...otherOptions
  })
};

export const useChangeSignGroupStyle = (signId, signGroupId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (styleId) => {
            return mutate("signs/sign/group/changestyle", {
                sign_id: signId,
                signgroup_id: signGroupId,
                style_id: styleId
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);
    
            if (onSuccess)
                onSuccess(data, variables, context)
        },    
        ...otherOptions
  })
};

export const useAddSignGroupPart = (signId, signGroupId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, applyAll, ...otherOptions} = options;

    return useMutation({
        mutationFn: (partId) => {
            return mutate("signs/sign/group/addpart", {
                sign_id: signId,
                signgroup_id: signGroupId,
                part_id: partId,
                applyall_ind: applyAll?1:0,
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);
    
            if (onSuccess)
                onSuccess(data, variables, context)
        },    
        ...otherOptions
  })
};

export const useChangeSignGroupPart = (signId, signGroupId, partId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, applyAll, ...otherOptions} = options;

    return useMutation({
        mutationFn: (newPartId) => {
            return mutate("signs/sign/group/changepart", {
                sign_id: signId,
                signgroup_id: signGroupId,
                part_id: partId,
                newpart_id: newPartId,
                applyall_ind: applyAll?1:0
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);
    
            if (onSuccess)
                onSuccess(data, variables, context)
        },    
        ...otherOptions
  })
};

export const useRemoveSignGroupPart = (signId, signGroupId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (partId) => {
            return mutate("signs/sign/group/removepart", {
                sign_id: signId,
                signgroup_id: signGroupId,
                part_id: partId,
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);
    
            if (onSuccess)
                onSuccess(data, variables, context)
        },    
        ...otherOptions
  })

};

export const useRemoveSignGroupGraphic = (signId, signGroupId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (graphicId) => {
            return mutate("signs/sign/group/removegraphic", {
                sign_id: signId,
                signgroup_id: signGroupId,
                graphic_id: graphicId,
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);
    
            if (onSuccess)
                onSuccess(data, variables, context)
        },    
        ...otherOptions
  })

};

export const useMakeSignGroupCustom = (signId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (signGroupId) => {
            return mutate("signs/sign/group/makecustom", {
                sign_id: signId,
                signgroup_id: signGroupId
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);
            queryClient.invalidateQueries(["signs", "list"]);

            if (onSuccess)
                onSuccess(data, variables, context)
        },    
        ...otherOptions
  })
};

export const useRemoveSignGroup = (signId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (signGroupId) => {
            return mutate("signs/sign/group/remove", {
                sign_id: signId,
                signgroup_id: signGroupId
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);
    
            if (onSuccess)
                onSuccess(data, variables, context)
        },    
        ...otherOptions
  })
};

export const useCloneSignGroup = (signId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (signGroupId) => {
            return mutate("signs/sign/group/clone", {
                sign_id: signId,
                signgroup_id: signGroupId
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);
    
            if (onSuccess)
                onSuccess(data, variables, context)
        },    
        ...otherOptions
  })
};

export const useRemoveSignPart = (signId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (partId) => {
            return mutate("signs/sign/group/removepart", {
                sign_id: signId,
                part_id: partId,
                applyall_ind: 1
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);
    
            if (onSuccess)
                onSuccess(data, variables, context)
        },    
        ...otherOptions
  })
};

export const useChangeSignGroupPartColor = (signId, signGroupId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, applyAll, ...otherOptions} = options;

    return useMutation({
        mutationFn: (params) => {
            return mutate("signs/sign/group/changepartcolor", {
                sign_id: signId,
                signgroup_id: signGroupId,
                part_id: params.partId,
                color_id: params.colorId,
                applyall_ind: applyAll?1:0
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+signId]);
    
            if (onSuccess)
                onSuccess(data, variables, context)
        },    
        ...otherOptions
  })
};

export const useSetSignNote = (signId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (notes) => {
            return mutate("signs/sign/setnote", {
                id: signId,
                notes
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["signs", ""+signId]);
        },    
  })
};

export const useApproveSign = (options={}) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (signId) => {
            return mutate("signs/sign/approve", {
                id: signId,
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+variables]);
        },    
  })
};

export const useGenerateSignDescription = (options={}) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (signId) => {
            return mutate("signs/sign/generatedescription", {
                id: signId,
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+variables]);
        },    
        ...options
  })
};

export const useRecalculateSignPrice = (options={}) => {
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (signId) => {
            return mutate("signs/sign/recalculateprice", {
                id: signId,
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+variables]);
            queryClient.invalidateQueries(["signs", "list"]);

            if (onSuccess)
                onSuccess(data, variables, context);
        },    
        ...otherOptions
  })
}

export const useCloneSign = (options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (signId) => {
            return mutate("signs/sign/clone", {
                sign_id: signId,
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+variables]);
            queryClient.invalidateQueries(["signs", "list"]);
            queryClient.invalidateQueries(["cards", "signs"]);

            if (onSuccess)
                onSuccess(data, variables, context);            
        },    
        ...otherOptions
  })
};

export const useDeleteSign = (options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (signId) => {
            return mutate("signs/sign/delete", {
                id: signId,
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+variables]);
            queryClient.invalidateQueries(["signs", "list"]);
            queryClient.invalidateQueries(["cards", "signs"]);

            if (onSuccess)
                onSuccess(data, variables, context);            
        },    
        ...otherOptions
  })
};

export const useRemoveSignFromProject = (options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (signId) => {
            return mutate("signs/sign/removefromproject", {
                id: signId,
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["signs", ""+variables]);
            queryClient.invalidateQueries(["signs", "list"]);
            queryClient.invalidateQueries(["cards", "signs"]);

            if (onSuccess)
                onSuccess(data, variables, context);            
        },    
        ...otherOptions
  })
};