import React, { useState, useEffect } from "react"
import styled from "styled-components"
import produce from "immer"
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar, faCalendarDay, faChevronLeft, faChevronRight, faClipboardCheck, faCreditCard, faDotCircle, faPhone, faShapes, faUser, faUsers, faWrench } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"

import TaskDrawer from "../../drawers/drawer-task"
import TaskLink from "../tasks/task-link"
import Loading from "../loading"
import Button from "../buttons/button";

import { postData } from "../../common/services/server"
import { getUser } from "../../common/services/auth"
import { formatDate, removeNulls } from "../../helpers/utility"
import { colors } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

const Days = styled.div`
    display: flex;
    min-height: 290px;

    height: ${props => props.size && props.size == "large" ? "calc(100vh - 190px)": "auto"};

    > div.day0, > div.day6 {
        background-color: #efefef;
    }
`
const Day = styled.div`
    flex: 1;
    border: 1px solid #ccc;
    margin: 2px;
    align-items: stretch;

    > h3 {
        font-size: 14px;
        background-color: #c0c0c0;
        text-align: center;
        margin: 0px;
        padding: 10px;

        &.current {
            background-color: ${colors.lightPurple};
        }
    }
    > div {
        padding: 5px;

        > div {
            border: 1px solid #eee;
            padding: 4px;
            font-size: 12px;
            cursor: pointer;
            background-color: white;

            &:hover {
                background-color: #e0e0e0;
            }
        }
    }
    a {
        color: black;

        &:hover {
            text-decoration: none;
        }
    }

    .complete {
        text-decoration: line-through;
    }
`

function CalendarWeek(props) {
    const user = getUser();
    
    const today = moment(new Date()).startOf('day');
    const [fromDate, setFromDate] = useState(moment(new Date()).subtract(1,'day').startOf('day'))
    const [dates, setDates] = useState([]);
  
    const [schedule, setSchedule] = useState([]);
    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [error, setError] = useState(null);

    useEffect(
        () => {
            let weekDates = [];

            for (let i=0; i<7; i++) {
                weekDates.push(moment(fromDate).add(i, 'days').startOf('day'));
            }

            setDates(weekDates);
        }, 
        [fromDate]
    );

    useEffect(
        () => {
            if (dates && dates.length) {
                setLoading(true);
                
                postData("calendar/data", removeNulls({
                    user_id: props.userId || null,
                    start_date: fromDate.format("YYYY-MM-DD"),
                    end_date: moment(fromDate).add(7,'days').format("YYYY-MM-DD"),
                    assigned_user_id: user.id
                }),
                    function(result) {
                        setSchedule(result);
                    },
                    function(error) {
                        setError("Error loading calendar")
                    },
                    function() {
                        setLoading(false);
                    }
                );
            }
        }, 
        [dates, props.calendarPrefs, props.userId]
    );

    function onPrevWeek() {
        setFromDate(moment(fromDate).subtract(7, 'days'))
    }
    function onNextWeek() {
        console.log("next", moment(fromDate).add(7, 'days'))
        setFromDate(moment(fromDate).add(7, 'days'))
    }

    function userHasItemPermission(item) {
        if (item.type == "job")
            return userHasPermission(Permissions.Jobs);
        else if (item.type == "signs")
            return userHasPermission(Permissions.Signs);
        else if (item.type == "orders")
            return userHasPermission(Permissions.Orders);
        else
            return true;
    }

    return (
        <>
            {loading ? 
                <Loading />
            :           
                <>
                    <Days size={props.size}>
                        {dates.map(date => (
                            <Day key={date} className={"day" + date.day()}>
                                <h3 className={date.isSame(today) ? "current":""}>
                                    {date.format("ddd MMM Do")}
                                </h3>
                                <div>
                                {schedule.filter(s => s.date == date.format("YYYY-MM-DD") && userHasItemPermission(s)).map(item => (
                                    <div key={item.title} className={item.status ? item.status.toLowerCase():""}>
                                        {item.type == "task" ? 
                                            <TaskLink 
                                                task={item} 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSelected({task: item})
                                                }}
                                            />                                                                         
                                        :
                                            <Link to={item.url}>
                                                <FontAwesomeIcon icon={item.type == "signs" ? faShapes : item.type == "users" ? faUsers : item.type == "orders" ? faCreditCard : item.type == "job" ? faWrench : item.type == "call" ? faPhone : faDotCircle} fixedWidth /> {' '}
                                                {item.title}
                                            </Link>
                                        }
                                    </div>
                                ))}
                                </div>
                            </Day>
                        ))}
                    </Days>
                    <div style={{display:"flex", justifyContent:"space-between", margin: "3px"}}>
                        <Button onClick={onPrevWeek} variant="outline-secondary" size="sm">
                            <FontAwesomeIcon icon={faChevronLeft} />
                            {' '}
                            Prev
                        </Button>
                        <Button onClick={onNextWeek} variant="outline-secondary" size="sm">
                            Next
                            {' '}
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                    </div>
                </>
            }

            {(selected && selected.task) && 
                <TaskDrawer 
                    show={true}
                    task={{id: selected.task.url}} 
                    onSaved={(taskData) => {
                        setSchedule(produce(draft => {
                            const index = draft.findIndex(i => i.type=="task" && i.url == taskData.id);
                
                            if (index != -1) {
                                draft[index].title = taskData.task;
                                draft[index].date = taskData.due_date;
                            }
                        }));
                    }} 
                    onDeleted={() => {
                        setSchedule(produce(draft => {
                            const index = draft.findIndex(i => i.type=="task" && i.url == selected.task.url);
                
                            if (index != -1) {
                                draft.splice(index, 1)
                            }
                        }));
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } /> 
            }            
        </>
    );
}

export default CalendarWeek