import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import clsx from "clsx"
import mermaid from "mermaid";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faCogs, faCopy, faPencilAlt, faPlus, faListAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from '@tanstack/react-query';

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading";
import BoardField from "../components/board/board-field";
import BoardFields from "../components/board/board-fields";
import AddWorkflowTasksDrawer from "../drawers/workflow-addtasks";
import WorkflowTaskResourcesDrawer from "../drawers/workflow-taskresources";
import MwoDrawer from "../drawers/workflow-mwo";
import MwoBrowseDrawer from "../drawers/workflow-mwos";
import Button from "../components/buttons/button";
import Alert from "../components/alert";
import DrawerPartSelect from "../drawers/part-select";
import ButtonMutate from "../components/buttons/button-mutate";

import { Permissions } from "../common/services/auth";
import { getMermaidChartData } from "../helpers/workflow";
import { getDecorationTypes } from "../helpers/data";
import { useGetWorkflow, useGetWorkflowTask } from "../api/queries/workflows";
import { 
  useWorkflowAddTaskPart, 
  useWorkflowClone, 
  useWorkflowDeleteTask, 
  useWorkflowDeleteTaskPart, 
  useWorkflowUpdateItemProp, 
  useWorkflowUpdatePartProp, 
  useWorkflowUpdateProp, 
  useWorkflowUpdateTaskDependentValue, 
  useWorkflowUpdateTaskProp 
} from "../api/mutations/workflows";
import { useGetMWOs } from "../api/queries/mwos";
import { useGetSkillRoles } from "../api/queries/roles";
import { useGetMaterialParts, useGetPartTypes } from "../api/queries/parts";
import { useCreateMwoForWorkflowPart } from "../api/mutations/mwos";
import DrawerTemplate from "../drawers/template";
import Badge from "../components/badge";
import Switch from "../components/switch";
import FieldSelect from "../components/fields/field-select";
import Tabs from "../components/tabs";
import PageLayout from "../components/page-layout";
import { getTitleForMwoStickerType, getWorkflowMwoStickerTypes } from "../helpers/mwo";
import MwoSticker from "../components/mwo-sticker";
import { getIconForType } from "../helpers/icons";

mermaid.initialize({
    startOnLoad: false,
    theme: 'default',
    securityLevel: 'loose',
    fontFamily: 'monospace',
    htmlLabels: true,
    flowchart: {htmlLabels: true}
});

const Layout = styled.div`
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: 0px;

    position: absolute;
    right: 55px;
    left: 56px;
    bottom: 0px;
    top: 93px;

    > div {
      height: calc(100vh - 93px);
      overflow: auto;
    }
    > div:first-child {

      > div {
        width: 3000px;
      }
    }
    > div:last-child {
      padding: 5px;
      background-color: #eee;
    }
`

function Mermaid({chart}) {
    const containerRef = useRef();
    const chartRef = useRef();

    const renderDiagram = async (chart) => {
      console.log("mermaid chart changed")

      const scrollLeft = containerRef.current.scrollLeft;
      const scrollTop = containerRef.current.scrollTop;

      console.log("scroll pos", scrollTop, scrollLeft);

      // chartRef.current.removeAttribute('data-processed');
      // mermaid.contentLoaded();
      // mermaid.init(undefined, $("#mermaid"));

      // Example of using the render function
      const drawDiagram = async function () {
        const graphDefinition = chart;
        const { svg, bindFunctions } = await mermaid.render('graphDiv', graphDefinition);
        chartRef.current.innerHTML = svg;

        if (bindFunctions) {
          bindFunctions(chartRef.current);
        }      
        
        console.log("scrolling to", scrollTop, scrollLeft);

        containerRef.current.scrollTo(scrollLeft, scrollTop)        
      };
    
      await drawDiagram();
    }

    useEffect(
      () => {
        if (chart && chart.length) {
          renderDiagram(chart);
        }
      },
      [chart]
    );

    return (
      <div ref={containerRef}>
        <div>
          {chart ?
            <pre ref={chartRef} className="mermaid" style={{color:"white"}}></pre>
          :
            <p>No data found</p>
          }
        </div>
      </div>  
    )
}

function WorkflowProperties({workflow}) {
  const updateWorkflowName = useWorkflowUpdateProp(workflow.id, "name");

  return (
    <BoardFields label="Workflow">
      <BoardField
        type="text"
        label="Name"
        required
        value={workflow.name || ""}
        saveMutation={updateWorkflowName}
      />
      <BoardField
        type="label"
        label="Version"
        value={workflow.version}
        buttons={[{
          label: "New Version",
          variant: "outline-success",
          disabled: true,
          onClick: () => {
            console.log("new version")
          }
        }]}
      />
    </BoardFields>
  )
}

function TaskProperties({workflow, warningTypes, taskId, onDelete}) {
  const queryClient = useQueryClient()

  const [addPart, setAddPart] = useState(false);
  const [addMWO, setAddMWO] = useState(false);
  const [configurePart, setConfigurePart] = useState(null);
  const [browseMWOs, setBrowseMWOs] = useState(false);
  const [mwo, setMwo] = useState(null);
  const [editMwo, setEditMwo] = useState(false);
  const [resources, setResources] = useState(false);
  const [variables, setVariables] = useState(false);

  const taskQuery = useGetWorkflowTask(workflow.id, taskId, { enabled: taskId != null });
  const task = taskQuery.data;

  useEffect(
    () => {
      if (task && configurePart) {
        const configPart = task.parts.find(p => p.id == configurePart.id);
        console.log("reloading configure part", configurePart.id, configPart)

        setConfigurePart(configPart);
        if (configPart)
          setMwo(configPart.mwo)
      }
    },
    [task]
  );

  const mwosQuery = useGetMWOs({}, { enabled: taskId != null});
  const skillsQuery = useGetSkillRoles({ enabled: taskId != null});
  const materialsQuery = useGetMaterialParts({ enabled: taskId != null});

  const updateItemName = useWorkflowUpdateItemProp(workflow.id, task?.workflowitem_id, task?.workflowtask_id, "name");
  const updateItemDescription = useWorkflowUpdateItemProp(workflow.id, task?.workflowitem_id, task?.workflowtask_id, "description");
  const updateItemType = useWorkflowUpdateItemProp(workflow.id, task?.workflowitem_id, task?.workflowtask_id, "type");

  const createItemMwo = useCreateMwoForWorkflowPart(task?.workflowitem_id, configurePart?.part_id);

  const updateItemRoleId = useWorkflowUpdateItemProp(workflow.id, task?.workflowitem_id, task?.workflowtask_id, "role_id");
  const updateItemDelayTime = useWorkflowUpdateItemProp(workflow.id, task?.workflowitem_id, task?.workflowtask_id, "delay_time");
  const updateItemDelayType = useWorkflowUpdateItemProp(workflow.id, task?.workflowitem_id, task?.workflowtask_id, "delay_type");
  const updateItemProdSetup = useWorkflowUpdateItemProp(workflow.id, task?.workflowitem_id, task?.workflowtask_id, "production_setup");
  const updateItemProdCalc = useWorkflowUpdateItemProp(workflow.id, task?.workflowitem_id, task?.workflowtask_id, "production_estimatecalc");
  const updateItemProdEstimate = useWorkflowUpdateItemProp(workflow.id, task?.workflowitem_id, task?.workflowtask_id, "production_estimate");
  const updateItemWarnings = useWorkflowUpdateItemProp(workflow.id, task?.workflowitem_id, task?.workflowtask_id, "warningtype_ids");

  const addTaskPart = useWorkflowAddTaskPart(workflow.id, task?.workflowtask_id);
  const deleteTaskPart = useWorkflowDeleteTaskPart(workflow.id, task?.workflowtask_id);
  const updateTaskPartDecorationType = useWorkflowUpdatePartProp(workflow.id, task?.workflowitem_id, configurePart?.part_id, "decoration_type");
  const updateTaskPartMaterial = useWorkflowUpdatePartProp(workflow.id, task?.workflowitem_id, configurePart?.part_id, "material_part_id");
  const updateTaskPartMwo = useWorkflowUpdatePartProp(workflow.id, task?.workflowitem_id, configurePart?.part_id, "mwo_id", {
    onSuccess: (result) => {
      console.log("mwo update success", result)
    }
  });
  const updateTaskPartStickers = useWorkflowUpdatePartProp(workflow.id, task?.workflowitem_id, configurePart?.part_id, "stickers");

  const updateTaskDependencies = useWorkflowUpdateTaskProp(workflow.id, task?.workflowtask_id, "dependent_workflowtask_ids");
  const updateTaskDependentCheck = useWorkflowUpdateTaskProp(workflow.id, task?.workflowtask_id, "dependent_check");
  const updateTaskDependentValue = useWorkflowUpdateTaskDependentValue(workflow.id, task?.workflowtask_id);
  const updateTaskGroup = useWorkflowUpdateTaskProp(workflow.id, task?.workflowtask_id, "workflowgroup");

  const deleteTask = useWorkflowDeleteTask(workflow.id);

  return (
    <>
      {taskQuery.isFetching ?
        <Loading />
      : task ?
        <div className="grid gap-1">
          {(task.validation && task.validation.length > 0) &&
              <div className="m-1">
              {task.validation.map((problem,index) => (
                <Alert key={index} size="tiny" variant="danger">
                  {problem}
                </Alert>
              ))}
              </div>
          }

          <BoardFields label="Task Properties">
            <BoardField
              type="text"
              label="Title"
              required
              value={task.name || ""}
              saveMutation={updateItemName}
            />
            <BoardField
              type="textarea"
              label="Description"
              value={task.description || ""}
              rows={5}
              saveMutation={updateItemDescription}
            />
            <BoardField
              type="select"
              label="Type"
              required
              value={task.type || ""}
              selectProps={{
                items: [
                  {value: "task", label: "Task"},
                  {value: "check", label: "Decision"},
                  {value: "merge", label: "Merge"},
                  {value: "delay", label: "Time Delay"},
                ]
              }}
              saveMutation={updateItemType}
            />
            {/* {(task.type == "task") &&
              <BoardField
                type="select"
                label="MWO"
                value={task.mwo_name}
                selectProps={{
                  itemsQuery: mwosQuery,
                  idField: "name",
                  labelField: "title"
                }}
                onEdit={() => { setMwoId(task.mwo_name) }}
                onEditDisabled={!task.mwo_name}
                saveMutation={updateItemMwoName}
                onBrowse={() => setBrowseMWOs(true)}
              />
            } */}
            {task.type == "task" &&
              <>
                <BoardField
                  type="select"
                  label="Skill"
                  required
                  value={task.role_id}
                  selectProps={{
                    itemsQuery: skillsQuery,
                    labelField: "name"
                  }}
                  saveMutation={updateItemRoleId}
                />
              </>
            }
            {task.type == "delay" &&
              <BoardField
                type="select"
                label="Delay Type"
                value={task.delay_type || ""}
                selectProps={{
                  items: [
                    {id: "fixed", label: "Fixed"},
                    {id: "middle-of-day", label: "Half Day"},
                    {id: "end-of-day", label: "End Of Day"},
                  ]
                }}
                saveMutation={updateItemDelayType}
              />
            }            
            {(task.type == "delay" && (!task.delay_type || task.delay_type=="fixed")) &&
              <BoardField
                type="number"
                label="Delay Time"
                value={task.delay_time || "0"}
                saveMutation={updateItemDelayTime}
              />
            }
            {task.type == "task" &&
              <BoardField
                type="label"
                label="Resources"
                onEdit={() => setResources(true)}
                value={task.resources.length +  " Resource" + (task.resources.length == 1 ? "":"s")}
              />
            }
          </BoardFields>

          {(task.type != "delay" && task.type != "merge") &&
            <BoardFields label="Parts">
              <div className="grid gap-1">
                {task.parts.map(part => (
                  <div key={part.id} className="flex items-center justify-between gap-1 border rounded p-1 text-xs">
                    <div>
                      {part.title}
                      <div className="flex items-center gap-1">
                        {part.decoration_type &&
                          <Badge size="tiny" variant="light" value={part.decoration_type.toUpperCase()} />
                        }
                        {part.material_part_title &&
                          <Badge size="tiny" variant="light" value={part.material_part_title} />
                        }
                        {part.mwo &&
                          <div className="rounded bg-gray-200 border-gray-400 p-1">
                            <FontAwesomeIcon icon={getIconForType("mwo")} className="mr-1" />
                            {part.mwo.title}
                          </div>
                        }                         
                      </div>
                    </div>

                    <div className="flex items-center gap-1">

                      <Button
                        icon={faCog}
                        variant="none-secondary"
                        size="icon"
                        onClick={() => {
                          setConfigurePart(part);
                          setMwo(part.mwo);
                        }}
                      />
                      <ButtonMutate
                        icon={faTrash}
                        variant="none-danger"
                        size="icon"
                        confirmation="Delete this part from the task?"
                        mutation={deleteTaskPart}
                        mutationData={part.id}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-1">
                <Button
                  icon={faPlus}
                  size="tiny"
                  variant="outline-success"
                  onClick={() => setAddPart(true)}
                >
                  Add Part
                </Button>
              </div>
            </BoardFields>
          }

          {task.type == "task" &&
            <BoardFields label="Production Time Estimate" collapsible collapsed>
              <BoardField
                type="number"
                label="Setup Time"
                suffix="Minutes"
                value={task.production_setup || "0"}
                saveMutation={updateItemProdSetup}
              />
              <BoardField
                type="textarea"
                label="Calculation"
                info="Use * for multiplication"
                infoType="error"
                value={task.production_estimatecalc || ""}
                saveMutation={updateItemProdCalc}
                buttons={[
                  { 
                    icon: faListAlt,
                    onClick: () => {
                      setVariables(true);
                    }
                  }
                ]}
              />
              {/* {task.production_estimatecalc == "fixed" &&
                <BoardField
                  type="number"
                  label="Time"
                  suffix="Hours"
                  value={task.production_estimate || "0"}
                  saveMutation={updateItemProdEstimate}
                />
              } */}
            </BoardFields>
          }

          {task.type == "task" &&
            <BoardFields
              label="Job Warning Checks"
              info="Selected warnings will be display on this task when applicable."
              count={task.warnings.length}
              collapsible collapsed
              className=""
            >
              <BoardField
                type="checkboxes"
                label="Checks"
                value={task.warnings.map(n => n.warningtype_id).join(",")}
                checkboxesProps={{
                  items: warningTypes || [],
                  labelField: "title",
                  direction: "vertical",
                }}
                saveMutation={updateItemWarnings}
              />
            </BoardFields>
          }

          <div className="">
            <BoardFields label="Dependencies" count={task.nodes.length} collapsible collapsed>
              <BoardField
                type="checkboxes"
                value={task.nodes.map(n => n.dependent_workflowtask_id).join(",")}
                checkboxesProps={{
                  items: workflow.tasks.filter(t => t.id != task.id),
                  labelField: "name",
                  direction: "vertical",
                }}
                saveMutation={updateTaskDependencies}
              />
              {(task.nodes.length > 1) &&
                <BoardField
                  type="select"
                  label="Dependency Check"
                  value={task.dependent_check || ""}
                  selectProps={{
                    items: [
                      {value: "all", label: "ALL"},
                      {value: "and", label: "AND"},
                      {value: "or", label: "OR"},
                    ]
                  }}
                  saveMutation={updateTaskDependentCheck}
                />
              }
              {task.nodes.filter(n => ["check"].includes(workflow.tasks.find(t => t.id == n.dependent_workflowtask_id)?.type)).map(node => {
                const depNode = workflow.tasks.find(t => t.id == node.dependent_workflowtask_id);

                return (
                  <BoardField
                    key={node.id}
                    type="select"
                    label={depNode.name}
                    value={node.dependent_value || ""}
                    selectProps={{
                      items: [
                        {value: "Yes", label: "Yes"},
                        {value: "No", label: "No"},
                      ]
                    }}
                    saveMutation={updateTaskDependentValue}
                    onSave={(value) => {
                      return {
                        dependentWorkflowTaskId: node.dependent_workflowtask_id,
                        value
                      }
                    }}
                  />
                )
              })}
            </BoardFields>

            {/* <BoardField
                type="text"
                label="Task Group"
                value={task.workflowgroup || ""}
                saveMutation={updateTaskGroup}
              />                            */}
          </div>

          <div className="mt-2 pl-1">
            <ButtonMutate
              icon={faTrash}
              caption="Remove From Workflow"
              variant="outline-danger"
              size="sm"
              confirmation="Delete the selected workflow task?"
              mutation={deleteTask}
              mutationData={task.id}
              onMutateSuccess={() => onDelete(null)}
            />
          </div>
        </div>
      :
        null
      }

      {browseMWOs &&
        <MwoBrowseDrawer
          workflow={workflow}
          mwoName={task.mwo_name}
          selectMutation={updateTaskPartMwo}
          createMutation={createItemMwo}
          onMutateSuccess={() => {
            setBrowseMWOs(false);
          }}
          onHide={() => setBrowseMWOs(null)}
        />
      }
      {editMwo &&
        <MwoDrawer
            mwoId={mwo.id}
            stickers={mwo.stickers}
            onChange={(mwoData) => setMwo(mwoData)}
            onHide={() => setEditMwo(false)}
        />
      }
      {addPart &&
        <DrawerPartSelect
            dataParams={{exists_workflowtask_id: task.id}}
            itemExistsCheck={(item) => item.exists_ind == 1}
            selectMutation={addTaskPart}
            selectMutationDataProp="id"
            onMutateSuccess={() => setAddPart(false)}
            onHide={() => setAddPart(false)}
        />
      }
      {configurePart &&
        <DrawerTemplate
          title="Configure Part"
          icon={faCogs}
          header={
            <h2 className="p-2 border rounded font-xl font-bold">
              {configurePart.title}
            </h2>
          }
          onHide={() => setConfigurePart(null)}
        >
          <BoardField
            type="select"
            label="Decoration Type"
            value={configurePart.decoration_type || ""}
            selectProps={{
              items: getDecorationTypes()
            }}
            saveMutation={updateTaskPartDecorationType}
          />
          <BoardField
            type="select"
            label="Material"
            value={configurePart.material_part_id || ""}
            selectProps={{
              itemsQuery: materialsQuery,
              labelField: "title"
            }}
            saveMutation={updateTaskPartMaterial}
          />
          {task.type == "task" && 
            <BoardField
              type="select"
              label="MWO"
              value={configurePart.mwo_id || ""}
              selectProps={{
                itemsQuery: mwosQuery,
                labelField: "title"
              }}
              saveMutation={updateTaskPartMwo}   
              onChange={(value, item) => {
                setMwo(item);
              }}
              onBrowse={() => setBrowseMWOs(true)}
            />   
          }
          {(mwo && task.type == "task") &&            
            <BoardField
                type="select"
                label="Stickers"
                required
                selectProps={{
                    items: getWorkflowMwoStickerTypes()
                }}
                value={configurePart.stickers || ""}
                saveMutation={updateTaskPartStickers}
            />                    
          }
          {(mwo && task.type == "task") &&
            <div className="grid gap-2">
              <MwoSticker
                  mwo={mwo}
                  shape={{
                          shape_index: 1,
                          shape_total: 1,
                          resembles_ind: 1,
                          path: "m 78.272569,5.3299616 c 0,-21.6150296 -17.52193,-39.1373276 -39.13585,-39.1373276 -21.61491,0 -39.13678003516,17.522298 -39.13678003516,39.1373276 0,21.6134624 17.52187003516,39.1357594 39.13581003516,39.1357594 21.61489,0 39.13682,-17.52125 39.13682,-39.1357594 l 0,0"
                  }}
                  type={mwo.type}
                  style={{
                      margin: "0px",
                      padding: "0px",
                  }}
              />
              {/* {configurePart.stickers && 
                  <div className="flex">
                      <Badge variant="light" value={getTitleForMwoStickerType(configurePart.stickers)} />
                  </div>
              } */}
              <div>
                <Button
                  icon={faPencilAlt}
                  size="sm"
                  onClick={() => setEditMwo(true)}
                >
                  Edit MWO
                </Button>
              </div>
            </div>
          }                 
        </DrawerTemplate>
      }        
      {resources &&
        <WorkflowTaskResourcesDrawer
            workflowId={workflow.id}
            task={task}
            onChange={() => {
              queryClient.invalidateQueries(["workflows", ""+workflow.id]);
            }}
            onHide={() => setResources(false)}
        />
      }     
      {variables &&
        <DrawerTemplate
          title="Time Calculation Variables"
          icon={faCogs}
          onHide={() => setVariables(false)}
        >
            <div>
              <div>&#123;shape_size&#125;</div>
              <div>&#123;shape_width&#125;</div>
              <div>&#123;shape_height&#125;</div>
              <div>&#123;shape_area&#125;</div>
              <div>&#123;shape_perimeter&#125;</div>
              <div>&#123;shape_modifier&#125;</div>
              <div>&#123;shape_consumption&#125;</div>
              <div>&#123;style_modifier&#125;</div>
              <div>&#123;shape_count&#125;</div>

              <div>&#123;modular_section_quantity&#125;</div>
              <div>&#123;divider_bar_quantity&#125;</div>
            </div>
        </DrawerTemplate>
      }           
    </>
  )
}

function TaskList({workflow, onTask}) {
  const [type, setType] = useState("");
  const [show, setShow] = useState("");

  return (
    <div className="grid gap-1 bg-white p-1 border rounded">
      <div className="mb-1">
        <FieldSelect
          prepend="Type:"
          prependClassName="w-[80px]"
          emptyLabel="All"
          items={[
            {value: "task", label: "Tasks"},
            {value: "check", label: "Decisions"},
            {value: "delay", label: "Time Delays"},
          ]}
          onChange={(e,value) => setType(value)}
        />
        <FieldSelect
          prepend="Show:"
          prependClassName="w-[80px]"
          items={[
            {id: "skill", label: "Skill"},
            {id: "parts", label: "Parts"},
          ]}
          onChange={(e,value) => setShow(value)}
        />
      </div>

      {workflow.tasks.filter(t => !type || t.type==type).map(task => (
        <div
          key={task.id}
          className={clsx(
            "text-xs border rounded p-1 flex items-center justify-between gap-2 cursor-pointer",
            show=="skill" && (task.type=="delay" || task.type=="check") && "bg-gray-200",
          )}
          onClick={() => onTask(task)}
        >
          <div className="flex items-center gap-1">
            {task.name}
            {/* <Badge size="tiny" variant="light">
              {task.type=="check" ? "Decision" : task.type=="delay" ? "Time Delay" : "Task"}
            </Badge> */}
          </div>

          <div className="flex items-center gap-1">
            { show == "skill" ?
              <Badge size="tiny" variant="light">{task.role?.replace("Production - ", "")}</Badge>
            : show == "parts" ?
              <div className="grid gap-1">
                {task.parts?.map(p => (
                  <Badge key={p.part_id} size="tiny" variant="light" className="flex items-center gap-1">
                    {p.title}

                    {p.decoration_type &&
                      <Badge size="tiny" variant="secondary" className="m-[2px]">{p.decoration_type}</Badge>
                    }
                    {p.material_part_title &&
                      <Badge size="tiny" variant="secondary" className="m-[2px]">{p.material_part_title}</Badge>
                    }
                  </Badge>
                ))}
              </div>
            :
              null
            }
          </div>
        </div>
      ))}
    </div>
  )
}

function WorkflowPage(props) {
  const history = useHistory();

  let { workflowId } = useParams();

  if (!workflowId) workflowId = 1;

  const [loading, setLoading] = useState("");
  const [diagram, setDiagram] = useState(null);
  const [add, setAdd] = useState(false);

  const workflowQuery = useGetWorkflow(workflowId);
  const workflow = workflowQuery.data?.workflow;
  const warningTypes = workflowQuery.data?.warningTypes;
  // const parts = workflowQuery.data?.parts;

  const [taskId, setTaskId] = useState(null);

  const cloneWorkflow = useWorkflowClone(workflowId, {
    onSuccess: (result) => {
      window.location = "#/workflow/" + result.id;
    }
  });

  useEffect(
    () => {
      window.callback = e => {
        const selected = workflow.tasks.find(t => t.id == parseInt(e));

        if (selected && taskId == selected.id)
          setTaskId(null);
        else
          setTaskId(selected.id);
      }
    }
  );

  useEffect(
    () => {
      console.log("workflow or task changed")
      const mermaidData = getMermaidChartData(workflow, taskId);
      // console.log(mermaidData)
      setDiagram(mermaidData)
    },
    [workflow, taskId]
  );

  return (
    <PageLayout>
      <PageHeader
        title="Workflow Designer"
        help="workflow"
      >
        <Button
          onClick={() => setAdd(true)}
          disabled={loading.length}
          variant="outline-success"
          size="sm"
        >
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;
            Add Tasks
        </Button>

        <ButtonMutate
          icon={faCopy}
          caption="Copy Workflow"
          variant="outline-warning"
          size="sm"
          mutation={cloneWorkflow}
          confirmation="Make a copy of this workflow?"
        />
      </PageHeader>

      <Content loading={workflowQuery.isLoading} error={workflowQuery.error} permission={Permissions.Products} padding={1}>
        { workflow &&
          <Layout>
            {(diagram && diagram.length > 0) ?
              <Mermaid chart={diagram} />
            :
              <div></div>
            }
            <div className="flex flex-col gap-1">
              {workflow &&
                <WorkflowProperties
                  workflow={workflow}
                />
              }

              <Tabs
                tabs={[
                  {name: "tasks", title:"Workflow Tasks"},
                  {name: "task", title:"Selected Task", hidden: !taskId?true:false},
                ]}
                selectedTab={{name: taskId ? "task":"tasks"}}
                onTab={() => setTaskId(null)}
              />

              { taskId ?
                <TaskProperties
                  workflow={workflow}
                  warningTypes={warningTypes}
                  taskId={taskId}
                  onDelete={() => setTaskId(null)}
                />
              :
                <TaskList
                  workflow={workflow}
                  onTask={(task) => setTaskId(task.id)}
                />
              }
            </div>
          </Layout>
        }
      </Content>

      {add &&
        <AddWorkflowTasksDrawer
            workflowId={workflow.id}
            onAdded={() => {
              setTaskId(null);
              setAdd(false);
            }}
            onHide={() => setAdd(false)}
        />
      }


    </PageLayout>
  );
}

export default WorkflowPage;