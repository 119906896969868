import React, { useEffect, useState } from "react";
import produce from "immer"
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faPlus, faStar, faSpinner, faPencilAlt, faTruckLoading } from '@fortawesome/free-solid-svg-icons'

import PartDrawer from "../drawers/drawer-part"
import PartProductsDrawer from "../drawers/drawer-partproducts"
import ColorBox from "../components/color-box";
import ButtonProcessing from "../components/buttons/button-processing";
import ColorSelector from "../drawers/drawer-colorselect"
import PartsDrawer from "../drawers/drawer-selectparts"
import PanelTable from "./panel-table"

import { postData } from "../common/services/server"
import MaterialDrawer from "../drawers/drawer-material";
import GoogleImage from "../components/google-image";
import Button from "../components/buttons/button";

function Panel({
  product,
  onChange,
  onReload
}) {
  const [busy, setBusy] = useState("");
  const [parts, setParts] = useState([]);
  const [sort, setSort] = useState("");

  const [selectPart, setSelectPart] = useState(false);
  const [part, setPart] = useState(null);
  const [productsPart, setProductsPart] = useState(null);
  const [colorPart, setColorPart] = useState(null);
  const [materialPart, setMaterialPart] = useState(null);

  useEffect(() => {
    console.log(product)
      setParts(product.parts);     
  }, [product]);

  useEffect(() => {
    if (parts && parts.length) {
        const sortedParts = JSON.parse(JSON.stringify(parts));

        sortedParts.sort((a,b) => (a[sort] > b[sort]) ? 1 : ((b[sort] > a[sort]) ? -1 : 0))

        setParts(sortedParts);
    }
  }, [sort]);

  function onRemovePart(part) {
    if (window.confirm("Are you sure you want to remove this part?")) {
        postData("products/part/toggle", {
                id: product.id,
                part_id: part.id,
                checked_ind: 0
            }, 
            function(response) {
                setParts(produce(draft => {
                    const idx = draft.findIndex(p => p.id == part.id);

                    if (idx != -1) {
                        draft.splice(idx, 1);
                    }
                }))
            },
            function(error) {
                alert("An error occured removing the part.");
            }
        );
    }
  }
  function onAvailabilityChange(part, availability) {
    console.log("part availability", part.name, availability)

    setParts(produce(draft => {
        const prt = draft.find(p => p.id == part.id);

        prt.required_ind = availability == "required" ? 1 : 0;
        prt.default_ind = availability == "default" ? 1 : 0;

        if (availability == "" || availability != "size") {
            prt.conditional_size_min = null;
            prt.conditional_size_max = null;
        }
        else if (availability == "lit")
            prt.conditional_lit_ind = 1;
        else if (availability == "unlit")
            prt.conditional_lit_ind = 0;
        else {
            prt.conditional_size_min = 0;
            prt.conditional_size_max = 99;
        }
    }));

    postData("products/part/update", {
            id: product.id,
            part_id: part.id,
            required_ind: availability == "required" ? 1 : 0,
            default_ind: availability == "default" ? 1 : 0,   
            conditional_lit_ind: availability == "lit" ? 1 : availability == "unlit" ? 0 : null,    
        }, 
        function(response) {

        },
        function(error) {
            alert("An error occured updating the product.");
        },
        function() {
        }
    );
  }
  function onPartPropChange(part, prop, value) {
    console.log("part prop changed", part.name, prop, value)

    setParts(produce(draft => {
        const prt = draft.find(p => p.id == part.id);

        prt[prop] = value
    }));
  } 
  function savePartProp(part, prop, value) {
    console.log("save part prop", part.name, prop, value)

    postData("products/part/update", {
            id: product.id,
            part_id: part.id,
            [prop]: value     
        }, 
        function(response) {

        },
        function(error) {
            alert("An error occured updating the part.");
        },
        function() {
        }
    );
  }    
  function onMaterialChange(part, materialId) {
    console.log("part material", part.name, materialId)

    setParts(produce(draft => {
        const prt = draft.find(p => p.id == part.id);

        prt.material_part_id = materialId;
    }));

    if (materialId == -2) {
        setMaterialPart(part);
    }
    else {
        postData("products/part/update", {
                id: product.id,
                part_id: part.id,
                material_part_id: materialId,       
            }, 
            function(response) {

            },
            function(error) {
                alert("An error occured updating the product.");
            },
            function() {
            }
        );
    }
  }  
  function onSelectColor(part) {
    setColorPart(part);
  }
  function onColorSelected(color) {
    setParts(produce(draft => {
        const part = draft.find(p => p.id == colorPart.id);

        part.default_color_id = color.id;
        part.default_color_name = color.name;
        part.default_color_hex_day = color.hex_day;
    }));

    postData("products/part/update", {
            id: product.id,
            part_id: colorPart.id,
            default_color_id: color.id
        }, 
        function(response) {

        },
        function(error) {
            alert("An error occured updating the product.");
        },
        function() {
        }
    );

    setColorPart(null);
  }

  return (
    <>
        { parts.length == 0 ?
            <p>No parts found.</p>
        :
            <PanelTable>
                <thead>
                    <tr>
                        <th colspan="2">
                            <a href="#" onClick={(e) => { e.preventDefault(); setSort("title")}}>
                                Part
                            </a>
                        </th>
                        <th>
                            <a href="#" onClick={(e) => { e.preventDefault(); setSort("type")}}>
                                Type
                            </a>
                        </th>
                        <th>Availability</th>
                        <th>Material</th>
                        <th>Options</th>
                        <th style={{textAlign:"center"}}>Products</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {parts.map(part => {
                        let availabilityInvalid = false;

                        // -- disabled since there are many instances of multiple parts for the same type that are actually allowed
                        
                        // if (part.required_ind == 1) {
                        //     // we can't have a required part if there are multiple options for the 
                        //     // same part type
                        //     if (parts.filter(p => p.type == part.type).length > 1) {
                        //         availabilityInvalid = true;
                        //     }
                        // }

                        return (
                        <tr key={part.id}>
                            <td style={{padding: "0px", textAlign: "center"}}>
                                <GoogleImage
                                    src={"parts/" + part.name + "/icon.jpg?" + (new Date().getTime())} 
                                    style={{ maxHeight: "25px" }} 
                                />
                            </td>
                            <td>
                                {part.part_number && 
                                    <>
                                        {part.part_number}
                                        &nbsp;-&nbsp;
                                    </>
                                }
                                {part.title}                                
                            </td>
                            <td>
                                {part.type}
                            </td>
                            <td>
                                <FormControl 
                                    as="select" 
                                    name="availability" 
                                    style={{
                                        fontSize: "12px",
                                    }}
                                    isInvalid={availabilityInvalid}
                                    defaultValue={
                                        part.required_ind == 1 ? "required" 
                                        : part.default_ind == 1 ? "default"
                                        : (part.conditional_size_min || part.conditional_size_max) ? "size" 
                                        : (part.conditional_lit_ind == 1) ? "lit" 
                                        : (part.conditional_lit_ind == 0) ? "unlit" 
                                        : "option"} 
                                    disabled={part.type_name == "auto" || part.type_name == "shipping"}
                                    onChange={(e) => onAvailabilityChange(part, e.target.value)}
                                >
                                    {part.type_name != "auto" && <option value="option">Optional</option>}
                                    {part.type_name != "auto" && <option value="default">Included</option>}
                                    {part.type_name != "auto" && <option value="required">Required</option>}
                                    {part.type_name != "auto" && <option value="size">Based On Size</option>}
                                    {part.type_name != "auto" && <option value="lit">Lit Signs</option>}
                                    {part.type_name != "auto" && <option value="unlit">Unlit Signs</option>}
                                    {(part.type_name == "auto" || part.type_name == "shipping") && <option value="auto">Auto</option>}
                                </FormControl>

                                {(part.conditional_size_min || part.conditional_size_max) && 
                                    <div>
                                        <InputGroup size="sm">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text style={{width: "70px", fontSize: "80%"}}>
                                                    Min Size
                                                </InputGroup.Text>
                                                <FormControl 
                                                    inputMode="number"                                                    
                                                    name="conditional_size_min" 
                                                    value={part.conditional_size_min} 
                                                    style={{fontSize: "80%"}}
                                                    onChange={(e) => {
                                                        onPartPropChange(part, "conditional_size_min", e.target.value)
                                                    }}
                                                    onBlur={() => {
                                                        savePartProp(part, "conditional_size_min", part.conditional_size_min)
                                                    }}
                                                />
                                            </InputGroup.Prepend>
                                        </InputGroup>
                                        <InputGroup size="sm">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text style={{width: "70px", fontSize: "80%"}}>
                                                    Max Size
                                                </InputGroup.Text>
                                                <FormControl 
                                                    inputMode="number"                                                    
                                                    name="conditional_size_max" 
                                                    value={part.conditional_size_max} 
                                                    style={{fontSize: "80%"}}
                                                    onChange={(e) => {
                                                        onPartPropChange(part, "conditional_size_max", e.target.value)
                                                    }}
                                                    onBlur={() => {
                                                        savePartProp(part, "conditional_size_max", part.conditional_size_max)
                                                    }}
                                                />                                                
                                            </InputGroup.Prepend>
                                        </InputGroup>
                                    

                                    </div>                            
                                }
                            </td>
                            <td>
                                <FormControl 
                                    as="select" 
                                    name="material" 
                                    style={{
                                        fontSize: "12px"
                                    }}
                                    value={part.material_part_id} 
                                    onChange={(e) => onMaterialChange(part, e.target.value)}
                                >
                                    <option value="-1"></option>
                                    {product.materials.map(material => (
                                        <option key={material.id} value={material.id}>
                                            {material.title}
                                        </option>
                                    ))}   
                                    {/* <option value="-2">(Create New Material)</option>                */}
                                </FormControl>
                            </td>                                
                            <td>
                                { (part.color_ind == 1) &&
                                    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                        <label style={{margin:"0px"}}>
                                            Color:
                                        </label>
                                        <ColorBox                                         
                                            color={part.default_color_id ? { name: part.default_color_name, hex_day: part.default_color_hex_day } : null}
                                            onClick={() => onSelectColor(part)}
                                        />
                                    </div>
                                }
                                { (part.packingslip_ind == 1) &&
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>Include On Packing Slip</Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon icon={faTruckLoading} />
                                    </OverlayTrigger>
                                }
                            </td>
                            <td align="center">
                                <Button
                                    size="sm"
                                    variant="outline-secondary"
                                    onClick={() => setProductsPart(part)}
                                >
                                    {part.product_count}
                                </Button>
                            </td>
                            <td width="100">
                                <ButtonProcessing
                                    icon={faPencilAlt}
                                    size="sm"
                                    variant="outline-primary"
                                    onClick={() => {
                                        setPart(part);
                                    }}
                                />

                                <ButtonProcessing
                                    icon={faBan}
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={() => {
                                        onRemovePart(part);
                                    }}
                                />
                            </td>
                        </tr>
                        )
                    })}
                </tbody>
            </PanelTable> 
        }

        <div style={{display: "flex", gap: "5px", marginTop: "10px"}}>
            <Button
                variant="outline-primary"
                size="sm"
                disabled={busy=="adding"}
                onClick={() => setSelectPart(true)}                
            >
                <FontAwesomeIcon icon={busy=="adding" ? faSpinner:faPlus} spin={busy=="adding"} />
                &nbsp;
                Add Existing Part
            </Button>

            <Button
                variant="outline-success"
                size="sm"
                onClick={() => setPart({id: 0, product_id: product.id})}
            >
                <FontAwesomeIcon icon={faStar} />
                &nbsp;
                Create New Part
            </Button>   
        </div>

        { selectPart && 
            <PartsDrawer 
                existingParts={parts} 
                onSelect={(newParts) => {
                    console.log(newParts)
                    if (newParts.length > 0) {
                        setBusy("adding");

                        postData("products/part/toggle", {
                                id: product.id,
                                part_ids: newParts.map(p => p.id).join(","),
                                checked_ind: 1
                            }, 
                            function() {                                
                                setParts(produce(draft => {
                                    for (let newPart of newParts) {
                                        let partProducts = (newPart.products || "").split(",");

                                        partProducts.push(product.title);

                                        draft.push({
                                            ...newPart,
                                            product_count: newPart.product_count+1,
                                            products: partProducts.join(",")
                                        });
                                    }
                                }))
                            },
                            function(error) {
                                alert("An error occured adding the parts.");
                            },
                            function() {
                                setBusy("")
                            }
                        );
                    }

                    setSelectPart(false);
                }} 
                onHide={ () => setSelectPart(false) } 
            />     
        }
        { part && 
            <PartDrawer 
                part={part} 
                product={product}
                onCloned={(partData) => {
                    onReload();
                }}
                onSaved={(partData) => {
                    setParts(produce(draft => {
                        const idx = draft.findIndex(p => p.id == partData.id);

                        if (idx == -1)
                            draft.push(partData);
                        else 
                            draft[idx] = partData;
                    }))
                    setPart(null);                    
                }} 
                onHide={ () => setPart(null) } 
            /> 
        }        
        { productsPart && 
            <PartProductsDrawer 
                part={productsPart} 
                onHide={ () => setProductsPart(null) } 
            /> 
        }
        {colorPart && 
            <ColorSelector
                part={colorPart}
                onColor={onColorSelected}
                onHide={() => setColorPart(null)}
            />
        }
        {materialPart && 
            <MaterialDrawer
                show={true}
                material={{}}
                onSaved={(materialData) => {
                    console.log(materialData);
                    
                    let updatedMaterials = JSON.parse(JSON.stringify(product.materials));

                    updatedMaterials.push(materialData);

                    onChange({
                        materials: updatedMaterials
                    });
                    setTimeout(() => {
                        onMaterialChange(materialPart, materialData.id);
                    }, 500)
                    setMaterialPart(null);
                }}
                onHide={() => setMaterialPart(null)}
            />
        }           
    </>
  );
}

export default Panel;