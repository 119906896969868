import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetWorkflowJobDashboardData = (options={}) => {  
  return useQuery({ 
    queryKey: ["workflowjobs", "dashboard"], 
    queryFn: ({signal}) => query("workflowjobs/dashboard", {}, signal),
    ...options
  });
};

export const useGetWorkflowJobsScheduleData = (options={}) => {  
  return useQuery({ 
    queryKey: ["workflowjobs", "schedule"], 
    queryFn: ({signal}) => query("workflowjobs/schedule", {}, signal),
    ...options
  });
};

export const useGetNewJobData = (orderId, orderItemId, options={}) => {  
    return useQuery({ 
      queryKey: ["newworkflowjobs", ""+orderId, ""+orderItemId], 
      queryFn: ({signal}) => query("workflowjobs/newjobdata", {order_id: orderId, orderitem_id: orderItemId}, signal),
      ...options
    });
};

export const useGetJobData = (workflowJobId, options={}) => {  
  return useQuery({ 
    queryKey: ["workflowjobs", ""+workflowJobId], 
    queryFn: ({signal}) => query("workflowjobs/job/data", {job_id: workflowJobId}, signal),
    ...options
  });
};

export const useGetJobGroups = (workflowJobId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "groups"], 
      queryFn: ({signal}) => query("workflowjobs/job/groups", {job_id: workflowJobId}, signal),
      ...options
    });
};

export const useGetJobShapes = (workflowJobId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "groups", "shapes"], 
      queryFn: ({signal}) => query("workflowjobs/job/shapes", {job_id: workflowJobId}, signal),
      ...options
    });
};

export const useGetJobWorkflowChecks = (workflowJobId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "workflow", "checks"], 
      queryFn: ({signal}) => query("workflowjobs/job/workflow/checks", {job_id: workflowJobId}, signal),
      ...options
    });
};

export const useGetJobWorkflowData = (workflowJobId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "workflow", "data"], 
      queryFn: ({signal}) => query("workflowjobs/job/workflow/data", {job_id: workflowJobId}, signal),
      ...options
    });
};

export const useGetJobWarnings = (workflowJobId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "warnings"], 
      queryFn: ({signal}) => query("workflowjobs/job/warnings", {job_id: workflowJobId}, signal),
      ...options
    });
};
  
export const useGetJobSchedule = (workflowJobId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "groups", "schedule"], 
      queryFn: ({signal}) => query("workflowjobs/job/schedule", {job_id: workflowJobId}, signal),
      ...options
    });
};

export const useGetJobNotes = (workflowJobId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "groups", "notes"], 
      queryFn: ({signal}) => query("workflowjobs/job/notes", {job_id: workflowJobId}, signal),
      ...options
    });
};

export const useGetJobColors = (workflowJobId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "groups", "colors"], 
      queryFn: ({signal}) => query("workflowjobs/job/colors", {job_id: workflowJobId}, signal),
      ...options
    });
};

export const useGetJobMaterials = (workflowJobId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "groups", "materials"], 
      queryFn: ({signal}) => query("workflowjobs/job/materials", {job_id: workflowJobId}, signal),
      ...options
    });
};
  
export const useGetJobLEDs = (workflowJobId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "groups", "leds"], 
      queryFn: ({signal}) => query("workflowjobs/job/leds", {job_id: workflowJobId}, signal),
      ...options
    });
};
  
export const useGetJobPower = (workflowJobId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "groups", "power"], 
      queryFn: ({signal}) => query("workflowjobs/job/power", {job_id: workflowJobId}, signal),
      ...options
    });
};

// export const useGetJobPowerset = (workflowJobId, jobPowersetId, options={}) => {  
//     return useQuery({ 
//       queryKey: ["workflowjobs", ""+workflowJobId, "groups", "power", "powerset", jobPowersetId], 
//       queryFn: ({signal}) => query("workflowjobs/job/power", {job_id: workflowJobId, jobpowerset_id: jobPowersetId}, signal),
//       ...options
//     });
// };

// export const useGetJobPowersetPower = (workflowJobId, jobPowersetId, options={}) => {  
//     return useQuery({ 
//       queryKey: ["workflowjobs", ""+workflowJobId, "groups", "power", "powerset", jobPowersetId, "power"], 
//       queryFn: ({signal}) => query("workflowjobs/job/power", {job_id: workflowJobId, jobpowerset_id: jobPowersetId}, signal),
//       ...options
//     });
// };

export const useGetJobParts = (workflowJobId, options={}) => {  
  return useQuery({ 
    queryKey: ["workflowjobs", ""+workflowJobId, "groups", "parts"], 
    queryFn: ({signal}) => query("workflowjobs/job/parts", {job_id: workflowJobId}, signal),
    ...options
  });
};

export const useGetJobMounts = (workflowJobId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "groups", "mounts"], 
      queryFn: ({signal}) => query("workflowjobs/job/mounts", {job_id: workflowJobId}, signal),
      ...options
    });
};

export const useGetJobBridges = (workflowJobId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "groups", "bridges"], 
      queryFn: ({signal}) => query("workflowjobs/job/bridges", {job_id: workflowJobId}, signal),
      ...options
    });
};

export const useGetJobWorkflowTask = (workflowJobId, jobWorkflowId, options={}) => {  
    return useQuery({ 
      queryKey: ["workflowjobs", ""+workflowJobId, "workflow", jobWorkflowId], 
      queryFn: ({signal}) => query("workflowjobs/job/workflowtask", {job_id: workflowJobId, job_workflow_id: jobWorkflowId}, signal),
      ...options
    });
};

export const useGetJobWorkflowTasks = (filters={}, options={}) => {  
  return useQuery({ 
    queryKey: ["workflowjobs", "tasks", filters], 
    queryFn: ({signal}) => query("workflowjobs/tasks", filters, signal),
    ...options
  });
};

export const useGetJobWorkflowTaskParts = (workflowJobId, jobWorkflowId, options={}) => {  
  return useQuery({ 
    queryKey: ["workflowjobs", ""+workflowJobId, "workflow", jobWorkflowId, "parts"], 
    queryFn: ({signal}) => query("workflowjobs/job/workflowtask/parts", {job_id: workflowJobId, job_workflow_id: jobWorkflowId}, signal),
    ...options
  });
};
