import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import styled from "styled-components"
import { format } from 'date-fns'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faSquare, faUser } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isMobile } from "react-device-detect"

import Loading from "../components/loading";
import StationTaskDrawer from "../drawers/drawer-stationtask";
import EmployeeStationPanel from "./panels/panel-station-employee";
import ListField from "../components/fields/field-list"
import storage from "../settings/storage";
import { colors } from "../settings/settings";
import { postData } from "../common/services/server";
import { getUser } from "../common/services/auth";

import logo from "../images/logo.png";
import { Helmet } from "react-helmet";

const Station = styled.div`
    header {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        z-index: 1049;
        display: flex;
        align-items: center;
        justify-contents: space-between;
        font-size: 20px;
        padding: 8px 15px;
        background-color: white;

        h1, h2 {
            margin: 0px;
            padding: 0px;
            font-size: 20px;
            flex: 1;
        }
        h2 {
            text-align: right;
        }
    }

    section {
        display: flex;
        flex-flow: wrap;
        align-items: stretch;
        gap: 5px
        margin-top: 50px;
        padding: 5px;
        padding-bottom: 100px;

        > div {
            border: 1px solid #ccc;
            border-radius: 5px;
            flex: 1;
            min-width: 350px;
            overflow-y: auto;

            > h2 {
                font-size: 20px;
                text-align: center;
                border-bottom: 1px solid #ccc;
                padding: 5px;
                margin: 0px;
                background-color: #eee;
                position: sticky;
                top: 0px;
                z-index: 999;
            }
        }
    }

    table {
        width: 100%;
        font-size: 16px;

        tr:hover {
            td {
                background-color: #eee;
            }
        }        
        span.active svg {
            color: ${colors.green} !important;
        }
        span.inprogress svg {
            color: #56b33f !important;
        }
        span.problem svg {
            color: yellow;
        }
        th {
            background-color: black;
            color: white;
            padding: 6px 12px;
            position: sticky;
            top: 35px;
            z-index: 999;
        }
        tr.pending td {
            background-color: #eee;
            color: #999;
            cursor: not-allowed;
        }
        tr.priority td {
            background-color: ${colors.lightRed};
        }
        td {
            padding: 8px 12px;
            border-bottom: 1px solid #eee;
            cursor: pointer;
            white-space: nowrap;

            svg {
                margin-right: 5px;
            }
        }
    }
    footer {
        background-color: black;
        font-size: 20px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 999;
        padding: ${isMobile ? 10:0}px;

        select {
            border: 0px;
            background-color: #333;
            color: white;
        }

        ul {
            list-style-type: none;
            margin: 0px;
            padding: 0px;
            display: flex;
            align-items: center;
            
            li {                
                a {                    
                    display: block;
                    padding: 8px 15px;
                    border-right: 1px solid #333;
                    color: white;
                    text-decoration: none;
                    text-align: center;

                    &:hover {
                        background-color: #555;
                    }
                }

                &.active {
                    a {
                        background-color: #444;
                    }
                }
            }
        }
    }
`

function StationPage(props) {
    const history = useHistory();
    const user = getUser();
    let { stationId } = useParams();

    const [loading, setLoading] = useState(true);
    
    const [data, setData] = useState(null);
    const [taskTypes, setTaskTypes] = useState([]);

    const [date, setDate] = useState(new Date());
    const [selected, setSelected] = useState(null);
    const [barcode, setBarcode] = useState(null);
    const [timer, setTimer] = useState(0);
    const [timerId, setTimerId] = useState(null);

    function onBarcodeScaned(barcode) {
        console.log("station barcode scanned", barcode);
    
        const parts = barcode.split(":");
        const jobId = parts[1];
        const mwo = parts[3];
    
        console.log("job id", jobId);
        console.log("mwo", mwo);

        let task = null;
        
        //data.tasks.find(t => t.job_id == jobId && mwo.toLowerCase() == t.mwo.toLowerCase());

        // Do a partial match so that a single task mwo name can match (prefix) of actual mwo sticker and 
        // handle multiple of them since they vary slightly in naming per product.
        for (const t of data.tasks.filter(t => t.job_id == jobId)) {
            console.log(t.id, t.mwo, mwo);
            // Is this task mwo sticker specific or can any job sticker be used?
            if (t.mwo && t.mwo.length > 1) {
                const index = mwo.toLowerCase().indexOf(t.mwo.toLowerCase());
                console.log(index);
                console.log(mwo == t.mwo, mwo.length, t.mwo.length)

                if (mwo == t.mwo)
                    task = t;
                else if (task == null && index != -1) {
                    task = t;
                }
            }
            else {
                task = t;
            }
        }

        //if (mwo.indexOf(props.task.mwo) != -1) {
        console.log("task", task);

        setSelected(task);
        setBarcode({jobId, mwo, scanned: true})
    }

    function handleKeyPress(e) {
        if (selected) return;

        console.log("station key", e.key, e.keyCode, window.barcode);
  
        if (e.keyCode === 74) { // J
          console.log("reading barcode")
          // Start of barcode
          window.readingBarcode = true;
          window.barcode = e.key;
          
          e.preventDefault();
          return;
        }
    
        if (window.readingBarcode) {
            e.preventDefault();
    
            if (e.keyCode === 13) { // Enter
                window.readingBarcode = false;
                onBarcodeScaned(window.barcode);
                return;
            }
    
            // Append the next key to the end of the list
            window.barcode += e.key;
        }
    }

    useEffect(() => {
        window.addEventListener('keypress', handleKeyPress);
        
        // Remove event listeners on cleanup
        return () => {
          window.removeEventListener('keypress', handleKeyPress);
        };
      }, [selected, data]);

    useEffect(
        () => {
            setLoading(true);
            setTimer(timer+1);
            setDate(new Date());
        }, 
        [stationId]
    );

    useEffect(
        () => {
            if (timer > 0) {
                console.log(timer);

                if (timerId)
                    window.clearTimeout(timerId)

                postData("stations/station", { id: stationId},
                    function(result) {
                        // for (const task of result.tasks) {
                        //     if (stationId == 0) {
                        //         if (task.worker_ids)
                        //             task.workers = task.worker_ids.split(",").map(id => parseInt(id));
                        //         else
                        //             task.workers = []
                        //     }
                        //     else {
                        //         task.workers = (task.workers || "").split(",");
                        //     }
                        // }

                        result.stations.unshift({
                            id: 0,
                            name: "Employee"
                        });

                        setData(result);

                        console.log("tasks", result.tasks)
                    },
                    function() {

                    },
                    function() {
                        setTimerId(window.setTimeout(() => {
                            setTimer(timer+1);
                        }, 1000*60*5)); // 5 minutes

                        setLoading(false);
                    }
                );
            }
        }, 
        [timer]
    );

    useEffect(
        () => {
            let types = [];

            if (data && data.tasks) {
                for (const task of data.tasks) {
                    if (types.indexOf(task.tasktype) == -1) {
                        types.push(task.tasktype);
                    }
                }
            }

            setTaskTypes(types);
        }, 
        [data]
    );

    function onTask(task) {
        if (task.status_name != "pending") {
            setSelected(task);
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{data && data.station ? data.station.name : "Production"} Station</title>
            </Helmet>
            <Station>
                {loading ? 
                    <Loading />
                : data ?
                    <>
                    <header>
                        {!isMobile && <h1>{data.station.name}</h1> }
                        <img src={logo} alt="SignMonkey" height="35" style={{height: "35px"}} />
                        {!isMobile && <h2>{format(date, "MM/dd/yyyy")}</h2> }
                    </header>
                    {data.station.id == 0 ?
                        <EmployeeStationPanel
                            workers={data.workers}
                            tasks={data.tasks}
                            onTask={(task) => setSelected(task)}
                        />
                    :
                        <section>
                            {taskTypes.map(taskType => {
                                return (
                                    <div key={taskType} style={{maxHeight: taskTypes.length > 4 ? (isMobile?"none":"70vh") : "none"}}>
                                        <h2>
                                            {taskType}
                                        </h2>

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Job</th>
                                                    <th>Assigned</th>
                                                    {/* <th>Due</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {data.tasks.filter(t => t.tasktype == taskType).map(task => (
                                                <tr key={task.id} onClick={() => onTask(task)} className={task.status_name + (task.priority ? " priority":"")}>
                                                    <td>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    <img src={storage.root + task.job_preview_url} style={{maxHeight: "50px", maxWidth: "150px"}} />
                                                                    <div>{task.status}</div>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className={task.status_name}>
                                                                <FontAwesomeIcon icon={task.status_id == 16 ? faSpinner : faSquare} spin={task.status_id == 16} />
                                                            </span>
                                                        </OverlayTrigger>
                                                        {task.job_title}
                                                    </td>
                                                    <td>
                                                        {task.workers}
                                                    </td>
                                                    {/* <td>{formatDate(task.target_date, "M/d")}</td> */}
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            })}
                        </section>
                    }

                    <footer>
                        {isMobile ? 
                            <ListField 
                                name="station" 
                                value={data.station.id} 
                                list={data.stations}
                                required
                                onChange={(e) => {
                                console.log(e.target.value)
                                if (e.target.value && e.target.value.length) {
                                    history.push("/station/" + e.target.value);
                                }
                                }}
                                groupClassName="nomargin"
                            />
                        :
                            <ul>
                                {data.stations.map(station => (
                                    <li key={station.id} className={station.name == data.station.name ? "active":""}>
                                        <Link to={"/station/" + station.id} className="text-sm">
                                            {station.id == 0 && 
                                            <FontAwesomeIcon icon={faUser} style={{marginRight:"5px"}} />
                                            }
                                            {station.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        }
                    </footer>

                    {selected && 
                        <StationTaskDrawer 
                            show={true}
                            station={data.station}
                            task={selected}
                            tasks={data.tasks}
                            scanned={barcode && barcode.jobId == selected.job_id && barcode.mwo == selected.mwo}
                            barcode={barcode}
                            onBarcodeScaned={onBarcodeScaned}
                            onTaskChanged={(data, task) => {
                                setData(data);
                                setSelected(task);
                                /*
                                setData(produce(draft => {
                                    const index = draft.tasks.findIndex(t => t.id == task.id);
                    
                                    draft.tasks[index] =task;
                                }));
                                */
                            }}
                            onHide={() => {
                                setSelected(null);
                                setBarcode(null);
                            }}
                        />
                    }
                    </>
                :
                    <p>Error loading station data.</p>
                }
            </Station>
        </>
    );
}

export default StationPage;