import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { addDays, differenceInCalendarDays, isWeekend, parseISO, format } from 'date-fns'

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faCalendar, faSpinner, faSquare } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import DateField from "../components/fields/field-date"
import CalendarField from "../components/fields/field-calendar"
import PropertyList from "../components/properties/property-list"
import Property from "../components/properties/property"

import { postData } from "../common/services/server"
import { formatDate, removeNulls } from "../helpers/utility";
import { colors } from "../settings/settings";

const Dates = styled.div`
    display: grid;
    gap: 5px;

    > button {
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 8px;
        background-color: white;
        display: flex;
        align-items: center;
        gap: 5px;

        &:hover {
            background-color: #eee;
        }
        &.weekend {
          background-color: #eee;

          svg {
            color: #999;
          }
        }
        &.selected {
            border-color: ${colors.green};
            color: ${colors.green};
        }
    }
`

const orderDateColor = colors.lightGreen;;
const addedDateColor = colors.orangeLight;
const shipDateColor = colors.lightRed;

function JobShipDateDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [shipDates, setShipDates] = useState([])

  const [validated, setValidated] = useState(false);

  useEffect(
    () => {
        setFormData({
            ship_date: props.job.ship_date
        });

        const startDate = parseISO(props.job.ship_date.split("T")[0]);
    
        const dates = [];
        
        for (let i=0; i<=10; i++) {
            const dayDate = addDays(startDate, i);
            const weekend = isWeekend(dayDate);
    
            dates.push({
                date: dayDate,
                weekend
            });     
                    }

        setShipDates(dates);

    }, 
    [props.job]
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;

    setFormData(produce(draft => {
      draft[field] = event.target.value;
    }));
  }

  function onSave() {
    onShipDate(formData.ship_date)
  }

  function onShipDate(shipDate) {
    setIsProcessing(shipDate);

    postData("jobs/setshipdate?job_id=" + props.job.id, {
      ship_date: shipDate
    }, 
      function(response) {
        props.onJobChange(response);
      },
      function(error) {
        alert("An error occured updating the job.");
      },
      function() {
        setIsProcessing(null);
      }
    );
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faCalendar} />
            &nbsp;
            Job Ship Date
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>

            <PropertyList>
              <Property label="Job" value={props.job.title} />
              <Property label="Order Date">
                <FontAwesomeIcon icon={faSquare} style={{color: orderDateColor}} /> {' '}
                {formatDate(props.job.order_date)}
              </Property>
              <Property label="Job Date">
                <FontAwesomeIcon icon={faSquare} style={{color: addedDateColor}} /> {' '}
                {formatDate(props.job.added_date)}
              </Property>
              <Property label="Ship Date">
                <FontAwesomeIcon icon={faSquare} style={{color: shipDateColor}} /> {' '}
                {formatDate(formData.ship_date)}
              </Property>
            </PropertyList>

            {/* <br />

            <Dates>
            {shipDates.map(shipDate => (
                <button 
                    key={shipDate.date}
                    onClick={() => onShipDate(formatDate(shipDate.date, "yyyy-MM-dd"))}
                    className={shipDate.weekend ? "weekend" : ""}
                    disabled={isProcessing}
                >
                    <FontAwesomeIcon icon={isProcessing && isProcessing == shipDate.date ? faSpinner : faCalendar} spin={isProcessing && isProcessing == shipDate.date} />
                    {formatDate(shipDate.date, "EEEE MMM do")}
                </button>
            ))}
            </Dates>

            */}

            <br />

            <CalendarField 
                name="ship_date" 
                value={formData.ship_date} 
                highlights={[
                  {date: props.job.order_date.split("T")[0], color: orderDateColor},
                  {date: props.job.added_date.split("T")[0], color: addedDateColor},
                  {date: formData.ship_date ? formData.ship_date.split("T")[0] : null, color: shipDateColor},
                ]}
                disabled={isProcessing}
                onChange={(e) => handleFormChange(e)} 
            /> 

            {/* <DateField 
                name="ship_date" 
                label="Other Ship Date" 
                required
                value={formData.ship_date} 
                disabled={isProcessing}
                onChange={(e) => handleFormChange(e)} 
            />   */}
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Update Job" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default JobShipDateDrawer;