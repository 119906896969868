import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faUser } from "@fortawesome/free-solid-svg-icons";
import { useParams, useHistory } from "react-router-dom";

import Content from "../components/content"
import PageHeader from "../components/page-header";

import ShapesPanel from "./panels/panel-shapes"
import ShapeDrawer from "../drawers/drawer-shape"
import Loading from "../components/loading"
import Button from "../components/buttons/button";
import { postData } from "../common/services/server"
import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import PageLayout from "../components/page-layout";

function PricesPage(props) {
    const history = useHistory();
    let { tab } = useParams();

    if (!tab) tab = "standard_logo";

    const [isLoading, setIsLoading] = useState(false);
    const [styles, setStyles] =  useState(null)
    const [selected, setSelected] = useState(null);
 
    useEffect(
        () => {
          setIsLoading(true);

          postData("styles/data", {},
            function(result) {
                let data = [
                    {id: 0, name: "standard_logo", title: "Standard Logos"},
                    {id: 0, name: "custom_logo", title: "Custom Logos"},
                    {id: 0, name: "user_logo", title: "User Logos", icon: faUser},
                ];

                for (const style of result) {
                    if (style.id > 0) {
                        data.push({id: style.id, name: style.code, title: style.name, secondary: style.code.indexOf("cabinet") == -1});
                    }
                }

                setStyles(data)
            },
            function(error) {
                alert("Error loading shape styles")
            },
            function() {
              setIsLoading(false);
            }
          );
        }, 
        []
    );

    function onCreate() {
        setSelected({mode:"create"})
    }
    function onSaved() {
        setSelected(null);
    }

    return (
        <PageLayout>
            <PageHeader 
                title="Shape Manager"
                help="shapes"
                tab={tab}
                tabUrl={"/shapes/{tab}"}
                tabs={styles}
                onTab={(tab) => {
                    history.push("/shapes/" + tab);
                }}
            >
                {userHasPermission(Permissions.Shapes, Actions.Create) &&
                    <Button onClick={onCreate} variant="outline-success" size="sm">
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        Create Shape
                    </Button>
                }
            </PageHeader>
            <Content permission={Permissions.Shapes}>
                {!styles && isLoading && <Loading />}
                {(tab && styles) && 
                    <ShapesPanel 
                        style={styles.find(s => s.name == tab)} 
                        filters={{
                            category_ind: tab == "standard_logo" ? 1 : tab == "custom_logo" ? 0 : -1,
                            user_ind: tab == "user_logo" ? 1 : tab == "custom_logo" ? 0 : -1,
                        }}
                    /> 
                }
            </Content>
            { selected && selected.mode == "create" && 
                <ShapeDrawer 
                    style={styles.find(s => s.name == tab)} 
                    shape={{

                    }}
                    onSaved={onSaved} 
                    onHide={ () => setSelected(null) } 
                /> 
            }
        </PageLayout>
    );
}

export default PricesPage;