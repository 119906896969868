import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTag } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import DateField from "../components/fields/field-date"
import CurrencyField from "../components/fields/field-currency"
import NumberField from "../components/fields/field-number"
import SelectField from "../components/fields/field-select"
import UserField from "../components/fields/field-user"
import SignField from "../components/fields/field-sign"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

function CouponDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(props.coupon)));
  const [validated, setValidated] = useState(false);

  /*
  useEffect(() => {
    console.log("setting post data", props.coupon)
    if (props.coupon) {
      setFormData(props.coupon);
    }
  }, [props.coupon]);
    */

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("coupons/save?id=" + (props.coupon.id||0), removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the coupon.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  const isPartnerCode = formData.type && formData.type.indexOf("Partner") != -1;

  function handleFormChange(event) {
    if (event.persist) event.persist();
    
    console.log("handleFormChange", event.target.name, event.target.value)
    
    const field = event.target.name;
    let value = event.target.value;

    if (field == "user_id") {
        value = value.id;
    }
    else if (field == "sign_id" && value) {
      value = value.id;
    }

    setFormData(produce(draft => {
      draft[field] = value;

      if (field == "user_id" && isPartnerCode) {
        draft.code = "PARTNER" + value;    
      }

      if (field == "sign_id" && event.target.value) {
        draft.sign_version = event.target.value.version;
      }
    }));
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faTag} />
            &nbsp;
            {props.coupon.id ? "Update":"Create"} Coupon
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>

            <SelectField 
                name="type" 
                label="Type" 
                layout="horizontal"
                required 
                url="coupons/types"
                labelField="type"
                value={formData.type} 
                onChange={(e) => handleFormChange(e)} />

            <UserField 
                id="coupon-user"
                name="user_id" 
                label="Customer" 
                layout="horizontal"
                required={isPartnerCode}
                value={{id:formData.user_id, name: formData.user_name || "Anonymous"}}
                onChange={(e) => handleFormChange(e)} />

            <TextField 
                name="code" 
                label="Code" 
                layout="horizontal"
                instructions={isPartnerCode ? "Code will be generated based on the selected customer.":"Best to not use any spaces or special characters."}
                disabled={isPartnerCode}
                required
                value={formData.code || ""} 
                onChange={(e) => handleFormChange(e)} />
            <TextField 
                name="description" 
                label="Description" 
                layout="horizontal"
                value={formData.description || ""} 
                onChange={(e) => handleFormChange(e)} />

            <DateField 
                name="start_date" 
                label="Start Date" 
                layout="horizontal"
                value={formData.start_date}
                onChange={(e) => handleFormChange(e)} />
            <DateField 
                name="end_date" 
                label="End Date" 
                layout="horizontal"
                value={formData.end_date}
                onChange={(e) => handleFormChange(e)} />

            {!isPartnerCode &&
              <NumberField 
                  name="max_uses" 
                  label="Max Uses" 
                  layout="horizontal"
                  value={formData.max_uses}
                  onChange={(e) => handleFormChange(e)} />
            }
            {!isPartnerCode &&
              <CurrencyField 
                  name="amount" 
                  label="Amount" 
                  layout="horizontal"
                  percentage={formData.type && formData.type.indexOf("Percentage") != -1}
                  instructions={formData.type && formData.type == "Partial Payment" ? "Amount off the order. Remaining balance will be charged during checkout.":null}
                  value={formData.amount}
                  onChange={(e) => handleFormChange(e)} />
            }
            {(formData.type == "Percentage Off" || formData.type == "Amount Off") &&
              <>
                <SignField 
                    id="coupon-sign"
                    name="sign_id" 
                    label="Sign" 
                    layout="horizontal"
                    value={formData.sign_id || 0}
                    onChange={(e) => handleFormChange(e)} />
                <NumberField 
                    name="sign_version" 
                    label="Sign Version" 
                    layout="horizontal"
                    value={formData.sign_version}
                    disabled={!formData.sign_id}
                    instructions="Limits the purchase to a particular version of the sign."
                    onChange={(e) => handleFormChange(e)} />
              </>                  
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Coupon" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default CouponDrawer;