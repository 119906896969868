import React, { useState, useEffect } from "react";
import styled from "styled-components"

import Modal from 'react-bootstrap/Modal';

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faSms, faSpinner, faUser, faPaperclip, faBan } from '@fortawesome/free-solid-svg-icons'

import MessageList from "../components/sms/message-list";
import Loading from "../components/loading";
import UserSelectDrawer from "../drawers/drawer-userselect";
import UserDrawer from "../drawers/drawer-user";
import SelectSignDrawer from "../drawers/drawer-selectsign"
import UploadField from "../components/fields/field-filesupload"
import Button from "../components/buttons/button";

import { removeNulls, formatDateTime, formatPhoneNumber, formatFileSize } from '../helpers/utility'
import { postData, postExternalData } from "../common/services/server"
import { FormControl, InputGroup } from "react-bootstrap";
import settings from "../settings";
import storage from "../settings/storage";

const Header = styled.table`
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
    background-color: #eee;
    position: sticky;
    top: 0px;

    th {
        width: 115px;
        padding: 5px 10px;
        text-align: left;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }
    td {
        padding: 5px;
        border-bottom: 1px solid #ccc;
        background-color: white;
    }
`
const Footer = styled.table`
    width: 100%;
    font-size: 12px;
    background-color: #eee;
    border-radius: 5px;
    margin-top: 15px;

    td {
        padding: 8px;
        background-color: white;
    }
`
const Users = styled.div`
    display: flex;
    flex-flow: wrap;
    align-items: center;
    gap: 5px;

    > div {
        border: 1px solid #ccc;
        background-color: white;
        padding: 3px;
        border-radius: 3px;

        svg {
            margin-right: 5px;
            color: #ccc;
        }
    }
`

function SmsHistoryDrawer({
        phoneno,
        onHide
    }) 
{
  const [busy, setBusy] = useState("load");
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({ phoneno, attachments: "" });
  const [attachments, setAttachments] = useState([]);
  const [assignUser, setAssignUser] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [addSignLink, setAddSignLink] = useState(false);

  useEffect(
    () => {
        setBusy("load");

        postData("sms/data", {
                phoneno,
            }, 
            function(response) {
                setUsers(response.users);
                setMessages(response.messages);
            },
            function(error) {
                alert("An error occured loading text messages.");
            },
            function() {
                setBusy("");
            }
        );
    }, 
    []
  );

  function onSend() {
    setBusy("send");
    
    postExternalData(settings.SITE_API_URL + "emails/send", removeNulls({
        type: "sms",
        template: "simple",
        from: "server@signmonkey.com",
        to: phoneno,
        subject: "Text Message",
        body: formData.message,
        sms: formData.message
    }), 
        function(response) {
            setMessages(produce(draft => {
                draft.unshift(response);
            }));
            setFormData(produce(draft => {
                draft.message = "";
            }))
        },
        function(error) {
            alert("An error occured sending the message.");
        },
        function() {
            setBusy("");
        }
    );
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();

    let field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  function handleFileUpload(file) {
    console.log("handleFileUpload", file)
    //console.log("handleFormChange", event.target.name, event.target.value)
    setFormData(produce(draft => {
      console.log("attachments", draft.attachments)
      draft.attachments = draft.attachments + (draft.attachments.length?",":"") + file.id;
    }));
    setAttachments(produce(draft => {
      draft.push(file);
    }))
  }

  return (
    <>
        <Modal show={true} enforceFocus={false} onHide={onHide} size="lg" keyboard={false} scrollable backdropClassName="drawer" dialogClassName="drawer">
        {/* <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faSms} />
            &nbsp;
            Text Messages
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body style={{padding: "0px"}}>
            <Header>  
                <tbody>
                    <tr>
                        <th>Phone Number</th>
                        <td>
                            <FormControl
                                as="input" 
                                type="text" 
                                name="phoneno" 
                                disabled={true}
                                value={formatPhoneNumber(phoneno)} 
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>User</th>
                        <td>
                            {(users.length > 0) ? 
                                <Users>
                                    {users.map((user, index) => (
                                        <div key={index}>
                                            <FontAwesomeIcon icon={faUser} />
                                            <a href={"#/users/user/" + user.id} target="_blank">
                                                {user.name}
                                            </a>
                                        </div>
                                    ))}
                                </Users>
                            :
                                <Button size="sm" variant="outline-secondary" onClick={() => setAssignUser(true)}>
                                    Assign User
                                </Button>
                            }
                        </td>
                    </tr>
                </tbody>          
            </Header>

            {busy == "load" ?
                <Loading />
            :                
                <div style={{margin: "10px"}}>
                    <MessageList messages={messages} />

                    <Footer>  
                        <tbody>
                            <tr>
                                <td>
                                    <InputGroup>
                                        <FormControl
                                            as="textarea" 
                                            rows={3}
                                            type="text" 
                                            name="message" 
                                            value={formData.message || ""} 
                                            onChange={(e) => handleFormChange(e)} 
                                        />         
                                        <InputGroup.Append>
                                            <Button onClick={onSend} disabled={!formData.message || formData.message.length == 0} variant="outline-success">
                                                <FontAwesomeIcon icon={busy == "send" ? faSpinner:faSms} spin={busy == "send"} />{' '}
                                                Send
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup> 
                                    <div style={{marginTop: "5px"}}>
                                        {attachments.map(attachment => (
                                            <div key={attachment.id} style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <div>
                                                    <FontAwesomeIcon icon={faPaperclip} />{' '}
                                                    <a href={storage.root + attachment.version_url} target="_blank">
                                                        {attachment.filename}
                                                    </a>
                                                    {' '}
                                                    ({formatFileSize(attachment.filesize)})
                                                </div>
                                                <Button size="sm" variant="outline-secondary" onClick={() => {
                                                    setAttachments(produce(draft => {
                                                        const index = draft.findIndex(f => f.id == attachment.id);

                                                        if (index != -1)
                                                        draft.splice(index, 1);
                                                    }))
                                                }}>
                                                    <FontAwesomeIcon icon={faBan} />
                                                </Button>
                                            </div>
                                        ))}                                
                                        <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                            <UploadField
                                                name="attachments"
                                                variant="simple"
                                                external
                                                uploadUrl="emails/upload"
                                                autoClear
                                                onUpload={(file) => handleFileUpload(file)}
                                            />                                                                                                               
                                            <Button 
                                                size="sm" 
                                                variant="outline-secondary" 
                                                style={{padding: "6px 8px"}}
                                                onClick={() => setAddSignLink(true)}
                                            >
                                                Add Sign Link
                                            </Button>
                                        </div>
                                    </div>                              
                                </td>
                            </tr>
                        </tbody>
                    </Footer>                    
                </div>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={onHide}>
                Cancel
            </Button>
        </Modal.Footer>
        </Modal> 

        { assignUser && 
            <UserSelectDrawer
                show={true}
                actions={[
                    {
                        label: "Assign User",
                        icon: faUser,
                        variant: "success",
                        busy: busy == "assignuser",
                        onClick: (userData) => {
                            setBusy("assignuser")

                            postData("users/updateprop", { 
                                user_id: userData.id,
                                prop: "phoneno",
                                value: phoneno
                            },
                                function(result) {
                                    //setMessages(result.messages);
                                    setUsers([userData]);
                                    setAssignUser(false);
                                },
                                function(error) {
                                    alert("Error assigning user.")
                                },
                                function() {
                                    setBusy("");
                                }
                            );                                
                        }
                    },                        
                    {
                        label: "Create New User",
                        icon: faUserPlus,
                        variant: "primary",
                        active: true,
                        onClick: () => {
                            setCreateUser(true);
                            setAssignUser(false);
                        }
                    },
                ]}
                onHide={ () => setAssignUser(false) } 
            /> 
        }   
        { createUser && 
            <UserDrawer 
                show={true}
                user={{
                    phoneno: formatPhoneNumber(phoneno)
                }}
                onSaved={(userData) => {
                    setUsers([userData]);
                    setCreateUser(false);
                }}
                onHide={ () => setCreateUser(false) } 
            /> 
        }  
        { addSignLink && 
            <SelectSignDrawer 
                show={true}
                filters={{
                    user_id: users.length ? users[0].id : null
                }}
                onSelect={ (sign) => {
                    setAddSignLink(false)
                    setFormData(produce(draft => {
                        draft.message = (draft.message || "") + settings.SIGNDETAILS_URL + sign.uid;
                    }))
                }} 
                onHide={ () => setAddSignLink(false) } 
            /> 
        }                     
    </>
  )
}

export default SmsHistoryDrawer;