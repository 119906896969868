import React from "react"
import styled from "styled-components"

const Pills = styled.div`
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
`

function BoardPills({children}) {
    return (
        <Pills>
            {children}
        </Pills>
    );
}

export default BoardPills