import React, { useState, useEffect } from "react";

import produce from "immer"
import { faCreditCard, faTrash, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect"

import Modal from 'react-bootstrap/Modal'

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    CurrencyCell, 
    DateCell, 
    TextCell,
    ButtonCell,
    GoogleImageCell, 
    SimpleFormCell,
    UserCell,
    SortHeaderCell,
    SortTypes,
    EditActionsCell 
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import { useHistory } from "react-router-dom";

import Loading from "../../components/loading"
import CartDrawer from "../../drawers/drawer-cart"
import CartItemsDrawer from "../../drawers/drawer-cartitems"
import CartProblemsDrawer from "../../drawers/drawer-cartproblems"
import EmailDrawer from "../../drawers/drawer-email";
import Paginator from "../../components/paginator"
import Button from "../../components/buttons/button";

import { postData } from "../../common/services/server"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { useBodyClass } from "../../hooks/useBodyClass"
import { dimensions } from "../../settings/settings"
import { removeNulls } from "../../helpers/utility"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

function CartsPanel(props) {
    const history = useHistory();
    const windowDimensions = useWindowDimensions();
    
    const [carts, setCarts] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({page:1,pages:1,total:0});
    const [sort, setSort] = useState({column: "cart_date", dir: SortTypes.DESC});
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(null);

    const [couponBusy, setCouponBusy] = useState(null);
    const [actionBusy, setActionBusy] = useState(null);

    useBodyClass(`noscroll`);

    useEffect(
        () => {
          setIsLoading(true);

          postData("carts/data", removeNulls({ 
              ...props.filters,
              id: props.params ? props.params.id : null,
              page: page,
              perPage: 100,  
              sort: sort.column,
              sortdir: sort.dir
          }),
            function(result) {
                setCarts(result.carts);
                setPagination(result.pagination);
            },
            function(error) {
                alert("Error loading carts")
            },
            function() {
              setIsLoading(false);
            }
          );
        }, 
        [props.filters, sort, page]
    );

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }
    function onItems(cart) {
        console.log("onItems", cart)
        if (cart.id)
            setSelected({mode:"items", cart});
    }
    function onProblems(cart) {
        if (cart.id)
            setSelected({mode:"problems", cart});
    }
    function onCartChanged(cart) {
        const rowIndex = carts.findIndex((c) => c.id == selected.cart.id)

        console.log("onCartChanged", cart, rowIndex)

        setCarts(produce(draft => {
            if (rowIndex == -1)
                draft.push(cart);
            else
                draft[rowIndex] = cart;
        }));
    }

    function setCoupon(rowIndex, coupon, admin) {
        setCouponBusy(null);

        setCarts(produce(draft => {
            if (admin)
                draft[rowIndex].admin_coupon_code = coupon;
            else
                draft[rowIndex].coupon_code = coupon;
        }));
    }
    function onApplyCoupon(rowIndex, admin) {
        const cart = carts[rowIndex];

        setCouponBusy(rowIndex);

        postData("carts/applycoupon", { 
            cart_id: cart.id,
            coupon: admin ? cart.admin_coupon_code : cart.coupon_code,
            admin_ind: admin ? 1 : 0
        },
          function(result) {
            if (result) {
                setCarts(produce(draft => {
                    draft[rowIndex] = result;
                }));
            }
            else alert("Invalid purchase code.")
          },
          function(error, status) {
              if (status == 404)
                alert("Invalid purchase code.")
              else
                alert("Error applying purchase code")
          },
          function() {
            setCouponBusy(null);
          }
        );
    }
    function onConvertToOrder() {
        const cart = selected.cart;

        setActionBusy(cart);
        setSelected(null);

        postData("carts/order", { 
            cart_id: cart.id
          },
          function(result) {
            const rowIndex = carts.indexOf(cart)

            setCarts(produce(draft => {
                draft.splice(rowIndex, 1);
            }));

            history.push("/orders/order/" + result.id);
          },
          function(error, status) {
              alert("Error ordering cart")
          },
          function() {
            setActionBusy(null);
          }
        );
    }
    function onCreate() {
        console.log("create")
        setSelected({mode:"create", cart:{}});
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
            <>
                <Table
                    rowHeight={50}
                    rowsCount={carts.length}
                    width={windowDimensions.width - dimensions.dataTableWidthOffset}
                    height={windowDimensions.height - dimensions.dataTableHeightOffset}
                    headerHeight={50}
                    touchScrollEnabled={true}
                >
                    <Column
                        header={<Cell>Cart #</Cell>}
                        fixed={!isMobile}
                        columnKey="id"
                        cell={<TextCell data={carts} />}
                        width={115}
                    />
                    <Column
                        header={<Cell>User</Cell>}
                        fixed={false}
                        columnKey="user_name"
                        cell={({rowIndex, ...props}) => (
                            <UserCell 
                                userId={carts[rowIndex].user_id}
                                userName={carts[rowIndex].user_name}
                                userEmail={carts[rowIndex].user_email}
                                onUser={() => history.push("/users/user/" + carts[rowIndex].user_id)}
                            />
                        )}
                        width={225}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Date Created
                            </SortHeaderCell>
                        }
                        columnKey="cart_date"
                        cell={<DateCell data={carts} />}
                        width={135}
                    />
                    <Column
                        header={<Cell>Items</Cell>}
                        columnKey="item_count"
                        cell={<ButtonCell data={carts} onClick={(cart) => onItems(cart)} />}
                        width={70}
                    />
                    <Column
                        header={<Cell>Preview</Cell>}
                        columnKey="preview_url"
                        cell={<GoogleImageCell data={carts} maxwidth={125} maxheight={30} />}
                        width={150}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Cost
                            </SortHeaderCell>
                        }
                        columnKey="cost"
                        cell={<CurrencyCell data={carts} />}
                        width={100}
                    />
                    <Column
                        header={<Cell>Purchase Code</Cell>}
                        columnKey="coupon_code"
                        cell={props => (
                            <SimpleFormCell 
                                data={carts} 
                                placeholder="Purchase Code"
                                disabled={couponBusy == props.rowIndex || !userHasPermission(Permissions.ShoppingCarts, Actions.Edit)}
                                onChange={(rowIndex, value) => {
                                    setCoupon(rowIndex, value);
                                }}                                    
                                onApply={onApplyCoupon}
                                busy={couponBusy == props.rowIndex}
                                {...props}
                            />
                        )}
                        width={225}
                    />
                    <Column
                        header={<Cell>Admin Purchase Code</Cell>}
                        columnKey="admin_coupon_code"
                        cell={props => (
                            <SimpleFormCell 
                                data={carts} 
                                placeholder="Purchase Code"
                                disabled={couponBusy == props.rowIndex || !userHasPermission(Permissions.ShoppingCarts, Actions.Edit)}
                                onChange={(rowIndex, value) => {
                                    setCoupon(rowIndex, value, true);
                                }}                                    
                                onApply={(rowIndex) => onApplyCoupon(rowIndex, true)}
                                busy={couponBusy == props.rowIndex}
                                {...props}
                            />
                        )}
                        width={225}
                    />                    
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Discount
                            </SortHeaderCell>
                        }
                        columnKey="total_discount"
                        cell={<CurrencyCell data={carts} />}
                        width={100}
                    />
                    {/* <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Shipping
                            </SortHeaderCell>
                        }
                        columnKey="shipping"
                        cell={<CurrencyCell data={carts} />}
                        width={100}
                    /> */}
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Total
                            </SortHeaderCell>
                        }
                        columnKey="total"
                        cell={<CurrencyCell data={carts} />}
                        width={100}
                    />
                    <Column
                        header={<Cell>Problems</Cell>}
                        columnKey="problem_count"
                        cell={<ButtonCell data={carts} onClick={(cart) => onProblems(cart)} />}
                        width={100}
                    />
                    <Column
                        header={<Cell>Actions</Cell>}
                        cell={
                            <EditActionsCell 
                                data={carts}  
                                actions={[
                                    {
                                        action:"invoice", 
                                        icon:faEnvelope, 
                                        title:"Email Invoice", 
                                        variant:"primary", 
                                        permission: Permissions.ShoppingCarts, 
                                        permissionAction: Actions.View,
                                        isVisible: (data) => {
                                            return data.total > 0
                                        }
                                    },
                                    {action:"order", icon:faCreditCard, title:"Convert To Order", variant:"success", permission: Permissions.Orders, permissionAction: Actions.Create},
                                    {action:"delete", title:"Delete", variant:"danger", icon:faTrash, permission: Permissions.ShoppingCarts, permissionAction: Actions.Delete},
                                ]}
                                busy={actionBusy}
                                onClick={(action,cart) => {
                                    if (action=="order") {
                                        setSelected({mode:"order", cart})
                                    }
                                    else if (action=="invoice") {
                                        setSelected({mode:"invoice", cart})
                                    }
                                    else if (action == "delete") {
                                        if (window.confirm("Delete this shopping cart?")) {
                                            setActionBusy(cart);
                                
                                            postData("carts/delete", { 
                                                cart_id: cart.id
                                            },
                                                function(result) {
                                                    setCarts(produce(draft => {
                                                        draft.splice(draft.findIndex(c => c.id == cart.id), 1);
                                                    }));
                                                },
                                                function(error) {
                                                    alert("Error deleting shopping cart")
                                                },
                                                function() {
                                                    setActionBusy(null);
                                                }
                                            );
                                        }
                                    }
                                }}
                            />
                        }
                        align="center"
                        width={150}
                    />
                </Table>
                <Paginator
                    {...pagination}
                    item="Cart"
                    items="Carts"
                    onPage={(page) => setPage(page)}
                />  
            </>              
            }
            { selected && selected.mode == "items" && 
                <CartItemsDrawer 
                    cart={selected.cart} 
                    onChange={onCartChanged} 
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { selected && selected.mode == "problems" && 
                <CartProblemsDrawer 
                    cart={selected.cart} 
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { selected && selected.mode == "create" && 
                <CartDrawer 
                    cart={selected.cart} 
                    show={true}
                    onSaved={(cart) => {
                        onCartChanged(cart);
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { selected && selected.mode == "order" && 
                <Modal show={true} onHide={ () => setSelected(null) }>
                    <Modal.Body>
                        Convert this shopping cart to an order?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ () => setSelected(null) }>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={onConvertToOrder}>
                            Create Order
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            { selected && selected.mode == "invoice" && 
                <EmailDrawer 
                    bodyUrl={"emails/template?template=Order+Invoice&order_id=" + selected.cart.id}
                    email={{
                        order_id: selected.cart.id,
                        template: "simple",
                        from: "production@signmonkey.com",
                        to: selected.cart.user_email,
                        subject: "Invoice #" + selected.cart.id,
                        body: ""
                    }}
                    show={true}
                    onSaved={(email) => {
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }            
        </>
    );
}

export default CartsPanel;