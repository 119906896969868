import React, { useState, useEffect } from "react";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt, faListAlt, faChec, faImages } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import DrawerTemplateData from "./template-data";
import ButtonMutate from "../components/buttons/button-mutate";

import { getWorkflowMwoStickerTypes } from "../helpers/mwo";
import { useGetMwoById } from "../api/queries/mwos";
import { useSetMwoProp, useSetMwoRowProp } from "../api/mutations/mwos";
import { useGetMaterialParts } from "../api/queries/parts";
import FieldSelect from "../components/fields/field-select";
import BoardField from "../components/board/board-field";
import MwoSticker from "../components/mwo-sticker";
import FieldText from "../components/fields/field-text";
import produce from "immer";
import FileSelectDrawer from "./drawer-fileselect";
import UploadButton from "../components/buttons/button-upload";

const Contents = styled.table`
    width: 100%;
    border-collapse: collapse;

    th {
        background-color: rgb(229, 231, 235);
        border: 1px solid #ccc;
        padding: 3px 5px;
        font-weight: normal;
        width: 55px;
        font-size: 90%;
    }
    td {
        border: 1px solid #ccc;
        padding: 0px;
        vertical-align: top;
    }
`

const colDataItems = [
    {value: "[product_code]", label: "Product Code", description: "The MWO code for the product. DM, RW, etc."},
    
    {value: "[shape_index]", label: "Shape Index", stickers: "shapes", description: "The index position of the shape when there are mulitple."},
    {value: "[shape_code]", label: "Shape Code", description: "The code of the shape.  SQUARE, RECT, etc."},
    {value: "[shape_size]", label: "Shape Size", stickers: "shapes", description: "The size of the shape."},
    {value: "[shape_dimensions]", label: "Shape Dimensions", stickers: "shapes", description: "The height and width of the shape in inches."},
    {value: "[shape_style]", label: "Shape Style", stickers: "shapes", description: "The typestyle of the shape."},
    
    {value: "[shape_psu_color]", label: "Shape PSU Color", description: ""},
    {value: "[shape_psu_watts]", label: "Shape Watts", description: ""},

    {value: "[led_count]", label: "LED Count", stickers: "shapes", description: "The total number of LEDs."},
    {value: "[led_type]", label: "LED Type", stickers: "shapes", description: "The name/type of the LEDs.  White, PE-4W7K, etc."},
    {value: "[led_color]", label: "LED Color", stickers: "shapes", description: "The color of the LEDs. BLUE-SM, WHT-P4, etc."},
    {value: "[led_prewire]", label: "LED Prewire", description: "Prewire sticker strings as defined in the PreFab for the job."},

    {value: "[part_name]", label: "Part Name", description: "The color of the part"},
    {value: "[part_type]", label: "Part Type", description: "Type type of the part. Face, Back, Auto, etc."},
    {value: "[part_no]", label: "Part Number", description: "The number of the part."},
    {value: "[part_size]", label: "Part Size", description: "The size of the part, if defined.  5in, 3in, etc."},
    {value: "[part_material]", label: "Part Material", description: "The material of the part."},
    {value: "[part_decoration]", label: "Part Decoration", description: "Print, Paint, Overlay, etc."},            
    {value: "[part_quantity]", label: "Part Quantity", description: "The quantity of the part."},            
    {value: "[part_color]", label: "Part Color", description: ""},            

    {value: "[psu_color]", label: "PSU Color", description: ""},
    {value: "[psu_watts]", label: "PSU Watts", description: ""},
    {value: "[psu_input]", label: "PSU Input", stickers: "powersets", description: "Volts and Amps for the power supply"},
    {value: "[psu_output]", label: "PSU Output", stickers: "powersets", description: "Volts, Watts and Amps output for the power supply."},


    {value: "[powerstrip_tcount]", label: "Powerstrip T Count", description: "Powerstrip T count."},
    {value: "[powerstrip]", label: "Powerstrip", description: "Powerstrip T string with extensions.  TTTTTTTTTTTT, etc."},
    {value: "[powerset_name]", label: "Powerset Name", stickers: "powersets", description: "Powerset name.  Powerset 1, Powerset 2, Raceway 1, etc."},            
    {value: "[powerset_type]", label: "Powerset Type", stickers: "powersets", description: "Master, slave, etc.  Includes parent when applicable."},            
    {value: "[powerset_size]", label: "Powerset Size", stickers: "powersets", description: "Height x Width"},            
    {value: "[powerset_color]", label: "Powerset Color", stickers: "powersets", description: "Color of the powerset."},            
    {value: "[powerset_index]", label: "Powerset Index", stickers: "powersets", description: "Index of the powerset when there are multiple."},
    {value: "[powerset_extension]", label: "Powerset Extension", stickers: "powersets", description: "Size of the powerset extension."},

    // {value: "[racewayext_line3]", label: "Raceway Ext Line 3", stickers: "powersets", description: "If master and power then 'Input 120 Volts AC'. Otherwize cannot be used along message."},
    // {value: "[racewayext_line4]", label: "Raceway Ext Line 4", stickers: "powersets", description: "If master then Amps, 60 Hertz, QC.  When slave then shows parent."},
    // {value: "[racewaybuild_line3]", label: "Raceway Build Line 3", stickers: "powersets", description: "If master then hardcoded to Power Supplies."},
    // {value: "[racewaybuild_line4]", label: "Raceway Build Line 4", stickers: "powersets", description: "If master then hardcoded to Master Only.  When slave then slave to parent name."},
    // {value: "[cabinet_part]", label: "Cabinet Parts", description: "Return Left, Return Right, Return Top or Return Bottom"}, 
    // {value: "[retainer_part]", label: "Cabinet Retainer Parts", description: "Retainer Horizontal, Retainer Horizontal, Retainer Vertical, Retainer Vertical, Retainer Horizontal, Retainer Horizontal, Retainer Vertical or Retainer Vertical"}, 
    // {value: "[cabinet_color]", label: "Cabinet Color", description: "Color of the cabinet Side part."}, 
    // {value: "[cabinet_face_drawing]", label: "Cabinet Face Drawing", description: "Image showing left, right, top or bottom of the cabinet face."}, 
    // {value: "[cabinet_powerside]", label: "Cabinet Power Side", description: "Indicates which side of the cabinet the power comes out."},    
    // {value: "[cabinet_bracket_type]", label: "Cabinet Bracket Type", description: "Small Exterior Plate, Large Interior Plate, Internal Clip/Brckt, etc."},
    // {value: "[cabinet_bracket_count]", label: "Cabinet Bracket Count", description: "Hardcoded to 4 ct or 8 ct based on number of cabinet faces."},
    // {value: "[cabinet_bracket_drawing]", label: "Cabinet Bracket Drawing", description: "Image showing bracket drawing based on number of cabinet faces."},    
    // {value: "[cabinet_bracket_thickness]", label: "Cabinet Bracket Thickness", description: "Hardcoded to .09 or .25 based on number of cabinet faces."},    
    // {value: "[cabinet_led_leg]", label: "Cabinet LED Leg", description: "Show LEG #"},
    // {value: "[accessory_size]", label: "Accessory Size", description: "Size of the accessory when available.  4ft, 6ft, etc."},
    // {value: "[accessory_type]", label: "Accessory Type", description: "Extension, Bracket, etc."},
    // {value: "[accessory_title]", label: "Accessory Title", description: "Title of the accessory.  EZLit Plug-n-Play Extensions, etc."},
    // {value: "[accessory_color]", label: "Accessory Color", description: "Color of the accessory when available."},

    {value: "[date]", label: "Print Date", description: "Current date."},
    {value: "[sm_logo]", label: "SM Logo", description: "SignMonkey logo."},
    {value: "image", label: "Custom Image"},
    {value: "text", label: "Custom Text"},
];

function MwoField({mwoId, prop, value}) {
    const [mode, setMode] = useState("");

    const setMwoRowProp = useSetMwoRowProp(mwoId);

    function handleFormChange(event) {
        if (event.persist) event.persist();
        console.log("handleFormChange", event.target.name, event.target.value)
        const field = event.target.name;
        const value = event.target.value;
    
        // setMwo(produce(draft => {
        //   draft[field] = value;
        // }));
      }

    function onSave(value) {
        setMwoRowProp.mutate({prop, value}, {
            onError: () => window.alert("Error updating MWO")
        })
    }

    return (
        <>
            <FieldSelect 
                size="sm"
                groupClassname="w-full"
                selectClassName="text-xs"
                className="!border-0 !p-0"
                value={value || ""} 
                items={colDataItems}
                append={
                    <Button size="icon" variant="outline-secondary" onClick={() => setMode("placeholder")}>
                        <FontAwesomeIcon icon={faListAlt} />
                    </Button>
                }
                onChange={(e,value) => {
                    onSave(value)
                }}
            />
            {(value?.includes("[file:") || value == "image") ? 
                <div className="flex justify-between">
                    <div className="p-1">
                        {value?.includes("[file:") ? 
                            value.split(":")[1].replace("]", "").split("/").pop()
                        :
                            "[image]"
                        }
                    </div>

                    <button
                        className="border p-1 w-[37px] bg-gray-200"
                        onClick={() => setMode("image")} 
                    >
                        ...
                    </button>
                </div>
            : !value?.includes("[") ? 
                <FieldText 
                    name={prop}
                    value={value || ""} 
                    size="sm"
                    onChange={handleFormChange} 
                    onEnter={() => {
                        onSave(value)
                    }}
                    onBlur={() => {
                        onSave(value)
                    }}
                />
            :
                null
            }   

            {(mode == "placeholder") && 
                <Modal show={true} onHide={() => setMode("")} scrollable backdropClassName="drawer" dialogClassName="drawer">
                    <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faListAlt} style={{marginRight:"5px"}} />
                        MWO Sticker Parameters
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="grid gap-1">
                        {colDataItems.filter(di => di.value.indexOf("[") != -1).map(sticker => (
                            <div key={sticker} className="border rounded p-1 flex items-center justify-between">
                                <div>
                                    <div><strong>{sticker.value}</strong></div>
                                    <div className="text-sm text-gray-500">{sticker.description}</div>
                                </div>
                                <ButtonMutate
                                    icon={faCheck}
                                    caption="Select"
                                    size="sm"
                                    variant="outline-success"
                                    mutation={setMwoRowProp}
                                    mutationData={{
                                        prop: prop,
                                        value: sticker.value
                                    }}
                                    onMutateSuccess={() => setMode("")}
                                />
                            </div>
                        ))}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={() => setMode("")}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            }              
            {(mode == "image") && 
                <FileSelectDrawer
                    filters={{
                        folder: "2020/files/mwos/"
                    }}
                    buttons={
                        <UploadButton
                            caption="Upload Image"
                            extensions={["jpg","png","svg"]}
                            uploadUrl={"mwos/upload"}
                            thumbnail={true}
                            onUpload={(file) => {
                                console.log({file})
                                onSave("[file:" + (file.preview_url || file.url) + "]");
                                setMode("");                                
                            }}
                        />                           
                    }
                    onFile={(file) => {
                        console.log({file})
                        onSave("[file:" + (file.preview_url || file.url) + "]");
                        setMode("");
                    }}
                    onHide={() => setMode("")}
                />
            }              
        </>     
    )
}
export default function WorkflowMwoDrawer({
    mwoId,
    onChange,
    onHide
}) {
  const [mwo, setMwo] = useState(null);

  const mwoQuery = useGetMwoById(mwoId);

  const setMwoTitle = useSetMwoProp(mwo?.id, "title");
  const setMwoType = useSetMwoProp(mwo?.id, "type");
//   const setMwoStickers = useSetMwoProp(mwo?.id, "stickers");
  const setMwoRows = useSetMwoProp(mwo?.id, "rows");
  const setMwoRowProp = useSetMwoRowProp(mwo?.id);

  useEffect(() => {
    if (mwoQuery.data) {
        setMwo(mwoQuery.data)

        if (onChange) onChange(mwoQuery.data);
    }
  }, [mwoQuery.data]);

  const rows = [];

  for (let i=1; i<=(mwo ? mwo.rows : 0); i++) {
      rows.push(i)
  }

  const isForShapes = true; //mwo?.stickers.includes("shape");

  return (
      <>
        <DrawerTemplateData
            icon={faTicketAlt}
            title={(mwoId ? "Update":"Create") + " MWO"}
            query={mwoQuery}
            onHide={onHide}
        >
            {mwo &&
                <>
                    <div className="grid gap-1">
                        <BoardField
                            type="text"
                            label="Title"
                            required
                            value={mwo.title || ""}
                            saveMutation={setMwoTitle}
                        />

                        <BoardField
                            type="select"
                            label="Type"
                            required
                            selectProps={{
                                items: [
                                    {value: "temporary", label: "Temporary"},
                                    {value: "permanent", label: "Permanent"},
                                ]
                            }}
                            value={mwo.type}
                            saveMutation={setMwoType}
                        />
                        {/* <BoardField
                            type="select"
                            label="Stickers"
                            required
                            selectProps={{
                                items: getWorkflowMwoStickerTypes()
                            }}
                            value={mwo.stickers}
                            saveMutation={setMwoStickers}
                        /> */}

                        <div className="mt-1 font-bold">
                            Contents
                        </div>
                        <BoardField
                            type="number"
                            label="Rows"
                            required                            
                            value={mwo.rows}
                            saveMutation={setMwoRows}
                        />                        
                        <Contents>
                            <tbody>
                                {rows.map(row => (
                                    <tr key={row}>
                                        <th style={{whiteSpace: "nowrap", fontSize: "80%"}}>
                                            Row {row}
                                        </th>
                                        <td>
                                            <FieldSelect
                                                items={[
                                                    {value: "1", label: "1 Col"},
                                                    {value: "2", label: "2 Cols"},
                                                    {value: "3", label: "3 Cols"},
                                                ]}
                                                value={mwo["row" + row + "cols"] || ""}
                                                size="sm"
                                                groupClassname="w-[100px] self-center"
                                                selectClassName="text-xs"
                                                className="!border-0 !p-0"
                                                required
                                                onChange={(e,value) => {
                                                    setMwoRowProp.mutate({prop: `row${row}cols`, value}, {
                                                        onError: () => window.alert("Error updating MWO")
                                                    })
                                                }}
                                            />
                                        </td>
                                        {(mwo["row" + row + "cols"] >= 1) && 
                                            <td style={{whiteSpace: "nowrap"}}>
                                                <MwoField
                                                    mwoId={mwo?.id}
                                                    prop={`row${row}col1`}
                                                    value={mwo["row" + row + "col1"]}
                                                />
                                            </td>
                                        }
                                        {(mwo["row" + row + "cols"] >= 2) && 
                                            <td style={{whiteSpace: "nowrap"}}>
                                                <MwoField
                                                    mwoId={mwo?.id}
                                                    prop={`row${row}col2`}
                                                    value={mwo["row" + row + "col2"]}
                                                />                                            
                                            </td>
                                        }
                                        {(mwo["row" + row + "cols"] >= 3) && 
                                            <td style={{whiteSpace: "nowrap"}}>
                                                <MwoField
                                                    mwoId={mwo?.id}
                                                    prop={`row${row}col3`}
                                                    value={mwo["row" + row + "col3"]}
                                                />           
                                            </td>
                                        }
                                    </tr>
                                ))}                                                  
                            </tbody>
                        </Contents>

                        <MwoSticker
                            mwo={{
                                ...mwo,
                                parts: [],
                            }}
                            shape={isForShapes ? {
                                    shape_index: 1,
                                    shape_total: 1,
                                    resembles_ind: 1,
                                    path: "m 78.272569,5.3299616 c 0,-21.6150296 -17.52193,-39.1373276 -39.13585,-39.1373276 -21.61491,0 -39.13678003516,17.522298 -39.13678003516,39.1373276 0,21.6134624 17.52187003516,39.1357594 39.13581003516,39.1357594 21.61489,0 39.13682,-17.52125 39.13682,-39.1357594 l 0,0"
                                } : null
                            }
                            type={mwo.type}                            
                            style={{
                                margin: "10px 0px",
                                padding: "0px"
                            }}
                        />
                    </div>
                </>
            }
        </DrawerTemplateData>      
      </>
    )
}
