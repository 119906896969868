import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSpinner, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading";
import UserSelect from "../components/fields/user-select"
import PropertyList from "../components/properties/property-list"
import Property from "../components/properties/property"
import { postData } from "../common/services/server"
import { formatDateTime, formatPhoneNumber } from "../helpers/utility";

export default function UserSelectDrawer(props) {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);

  function onUser(user) {
    setUserData(null); 
    setUser(user);

    if (user) {
      postData("users/userdata", {id: user.id},
          function(result) {
              setUserData(result)
          },
          function(error) {
              window.alert("Error loading user data.");
              setUser(null)
          }
      );   
    }
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faUser} />
            &nbsp;
            {props.title || "Select User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group>
                <UserSelect name="user" employees={false} onChange={onUser} />
            </Form.Group>

            {(user && !userData) ? 
                <Loading />            
            : userData ?
                <PropertyList>
                    <Property label="Name" value={userData.name} />
                    <Property label="Email">
                        <div><FontAwesomeIcon icon={faEnvelope} /> {userData.email}</div>
                        {userData.emailstats && 
                          <div>
                            <Badge pill variant="secondary">{userData.emailstats.sent_count} Sent</Badge>
                            <Badge pill variant={userData.emailstats.opened_count > 0 ? "success":"secondary"}>{userData.emailstats.opened_count} Opened</Badge>
                            <Badge pill variant={userData.emailstats.error_count > 0 ? "danger":"secondary"}>{userData.emailstats.error_count} Errors</Badge>
                          </div>
                        }
                    </Property>

                    {userData.email_ind == 0 && 
                      <Property label="Email Preference" value={"Unsubscribed"} danger />                    
                    }

                    <Property label="Phone">
                        <div><FontAwesomeIcon icon={faPhone} /> {formatPhoneNumber(userData.phoneno)}</div>
                        {userData.smsstats && 
                          <div>
                            <Badge pill variant="secondary">{userData.smsstats.sent_count} Sent</Badge>
                            <Badge pill variant={userData.smsstats.received_count > 0 ? "success":"secondary"}>{userData.smsstats.received_count} Opened</Badge>
                          </div>
                        }
                    </Property>

                    <Property label="Date Registered" value={formatDateTime(userData.added_date)} />
                    <Property label="Signs" value={""+userData.sign_count} />
                    <Property label="Projects" value={""+userData.project_count} />
                    <Property label="Orders" value={""+userData.order_count} />
                </PropertyList>
            :
                null      
            }
        </Modal.Body>
        <Modal.Footer>
            {props.actions.map((action, index) => (
                <Button 
                  key={index} 
                  variant={action.variant || "primary"} 
                  disabled={(action.busy || !userData) && !action.active} 
                  onClick={() => action.onClick(userData)}>
                  {(action.icon || action.busy) && <><FontAwesomeIcon icon={action.busy ? faSpinner:action.icon} spin={action.busy} />{' '}</>}
                  {action.label}
                </Button>

            ))}
        </Modal.Footer>        
      </Modal>
    )
}