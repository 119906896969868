import React, { useState, useEffect } from "react";
import produce from "immer"
import { faUserEdit, faTrash, faCheck, faWrench } from "@fortawesome/free-solid-svg-icons";
import TextField from "../../components/fields/field-text"
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    DateCell, 
    ButtonCell,
    LinkCell,
    GoogleImageCell, 
    UserCell,
    SortHeaderCell,
    SortTypes,
    FilterHeaderCell,
    EditActionsCell 
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import { useHistory } from "react-router-dom";

import ProjectUserDrawer from "../../drawers/drawer-projectuser"
import Loading from "../../components/loading"
import Paginator from "../../components/paginator"
import Toolbar from "../../components/toolbar"

import { postData } from "../../common/services/server"
import { removeNulls } from "../../helpers/utility"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

function ProjectsPanel(props) {
    const history = useHistory();
    const windowDimensions = useWindowDimensions();

    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({page:1,pages:1,total:0});
    const [sort, setSort] = useState({column: "requested_date", dir: SortTypes.DESC});
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    const [busyRow, setBusyRow] = useState(null);
    const [cancel, setCancel] = useState(null);
    const [filters, setFilters] = useState({
        status: ""
    });

    const haveToolbar = props.buttons && props.buttons.length > 0;

    useBodyClass(`noscroll`);

    function loadProjects(options) {
        if (cancel)
            cancel.cancel();

        setIsLoading(true);

        const cancelToken = postData("projects/data", removeNulls({ 
            sort: sort.column,
            sortdir: sort.dir,
            page: page,
            perPage: 100,
            ...props.filters,
            ...filters,
            ...options
        }),
            function(result) {
                setProjects(result.projects);
                setPagination(result.pagination);
            },
            function(error) {
                alert("Error loading projects")
            },
            function() {
                setIsLoading(false);
                setCancel(null);
            }
        );

        setCancel(cancelToken);
    }
    
    useEffect(
        () => {
            loadProjects();
        }, 
        [props.filters, page, sort]
    );

    function onProjectChanged(project) {
        const rowIndex = projects.indexOf(selected.project)

        setProjects(produce(draft => {
            if (rowIndex == -1)
                draft.push(project);
            else
                draft[rowIndex] = project;
        }));
    }

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }
    function onSigns(project) {
        if (project.id) 
            history.push("/projects/project/" + project.id);
    }
    function onProject(project) {
        if (project.id)
            history.push("/projects/project/" + project.id);
    }
    function onAction(action, project) {
        console.log("action", action, project)

        setBusyRow(project);

        postData("projects/" + action, { 
            project_id: project.id
        },
            function(result) {
                if (action == "delete") {
                    setProjects(produce(draft => {
                        draft.splice(draft.findIndex(p => p.id == project.id), 1);
                    }));
                }
                else {
                    setProjects(produce(draft => {
                        let index = draft.findIndex(p => p.id == project.id);

                        draft[index] = result;
                    }));
                }
            },
            function(error) {
                alert("Error updating project")
            },
            function() {
                setBusyRow(null);
            }
        );
    }

    function onFilterChange(filter, value) {
        setFilters(produce(draft => {
            draft[filter] = value
        })); 
    }

    function onFilter() {
        loadProjects({page:1})
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
                <>
                    {haveToolbar && 
                        <Toolbar buttons={props.buttons} onButton={props.onButton} />
                    }
                    <Table
                        rowHeight={50}
                        rowsCount={projects.length}
                        width={windowDimensions.width - dimensions.dataTableWidthOffset}
                        height={windowDimensions.height - dimensions.dataTableHeightOffset - (haveToolbar ? 40:0)}
                        headerHeight={70}
                        touchScrollEnabled={true}
                    >
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    Project #
                                </SortHeaderCell>
                            }
                            columnKey="id"
                            fixed={!isMobile}
                            cell={<LinkCell data={projects} onClick={(project) => onProject(project)} />}
                            width={90}
                        />                        
                        <Column
                            header={<Cell>Details</Cell>}                            
                            cell={({rowIndex, ...props}) => {
                                let {title,description} = projects[rowIndex];

                                if (description && description.indexOf("<table") != -1)
                                    description = null;

                                return (
                                    <Cell {...props} >
                                        <small>{title || description}</small>
                                    </Cell>
                                )
                            }}
                            width={250}
                        />
                        {props.filters.type != "internal" &&
                            <Column     
                                header={
                                    <FilterHeaderCell filter={filters.user} onFilterChange={(value) => onFilterChange("user", value)} onFilter={onFilter} sort={sort} onSortChange={onSortChange}>
                                        Customer
                                </FilterHeaderCell>
                                }                  
                                columnKey="user_name"
                                cell={({rowIndex, ...props}) => (
                                    <UserCell 
                                        userId={projects[rowIndex].user_id}
                                        userName={projects[rowIndex].user_name}
                                        userEmail={projects[rowIndex].user_email}
                                        onChange={(e) => {setSelected({mode:"changeuser", project: projects[rowIndex]})}}
                                        onUser={() => {history.push("/users/user/" + projects[rowIndex].user_id); }}
                                    />
                                )}
                                width={300}
                            />
                        }
                        {props.filters.type != "internal" &&
                            <Column
                                header={<Cell>Preview</Cell>}
                                columnKey="preview_url"
                                cell={<GoogleImageCell data={projects} maxwidth={125} maxheight={35} />}
                                width={150}
                            />
                        }
                        {props.filters.type != "internal" &&                            
                            <Column
                                header={
                                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                        Signs
                                    </SortHeaderCell>
                                }
                                columnKey="sign_count"
                                cell={<ButtonCell data={projects} onClick={(project) => onSigns(project)} />}
                                width={70}
                            />
                        }
                        {props.filters.type != "internal" &&
                            <Column
                                header={
                                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                        Added From
                                    </SortHeaderCell>
                                }
                                columnKey="created_from"
                                cell={<TextCell data={projects} />}
                                width={150}
                            />
                        }
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    Date Added
                                </SortHeaderCell>
                            }
                            columnKey="requested_date"
                            cell={<DateCell data={projects} time />}
                            width={135}
                        />
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    Manager
                                </SortHeaderCell>
                            }
                            columnKey="manager_name"
                            cell={<TextCell data={projects} />}
                            width={135}
                        />
                        <Column
                            header={<Cell>Actions</Cell>}
                            cell={
                                <EditActionsCell 
                                    data={projects} 
                                    actions={[                                    
                                        {action:"claim", title:"Become Manager", variant:"primary", icon:faUserEdit, isVisible: (project) => !project.manager_user_id, permission:Permissions.Projects, permissionAction:Actions.Manage },
                                        {action:"complete", title:"Mark Complete", variant:"success", icon:faCheck, isVisible: (project) => project.status_id != 8, permission:Permissions.Projects, permissionAction:Actions.Edit},
                                        {action:"working", title:"Mark Working", variant:"warning", icon:faWrench, isVisible: (project) => project.status_id == 8, permission:Permissions.Projects, permissionAction:Actions.Edit},
                                        {action:"delete", title:"Delete", variant:"danger", icon:faTrash, permission:Permissions.Projects, permissionAction:Actions.Delete},
                                    ]}
                                    busy={busyRow}
                                    onClick={onAction} 
                                />
                            }
                            align="center"
                            width={150}
                        />
                    </Table>
                    <Paginator
                        {...pagination}
                        item="Project"
                        items="Projectss"
                        onPage={(page) => setPage(page)}
                    />
                </>
            }
            { selected && selected.mode == "changeuser" && 
                <ProjectUserDrawer 
                    show={true}
                    project={selected.project}
                    onUpdated={(project) => {
                        console.log("project updated", project);
                        setProjects(produce(draft => {
                            draft[draft.findIndex(p => p.id == selected.project.id)] = project;
                        }));

                        setSelected(null);
                    }} 
                    onHide={ () => setSelected(null) } 
                /> 
            }
        </>
    );
}

export default ProjectsPanel;