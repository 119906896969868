import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import OrdersMonthChart from "../../components/charts/chart-ordersmonth"
import OrdersYearChart from "../../components/charts/chart-ordersyear"

import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

function OrdersDashboard(props) {
  return (
    <>
      {userHasPermission(Permissions.Orders) &&    
        <Row>
            <Col xs={12} sm={7}>
              <OrdersYearChart />
            </Col>
            <Col xs={12} sm={5}>
              <OrdersMonthChart />
            </Col>
        </Row>
      }
    </>
  );
}

export default OrdersDashboard;