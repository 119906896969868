import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"
import styled from "styled-components"
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown, faPalette, faSearch } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ColorGrid from "../components/color-grid"
import ColorBox from '../components/color-box'
import Loading from "../components/loading"
import Field from "../components/fields/field";
import { postData } from "../common/services/server"
import FieldLabel from "../components/fields/field-label";

const ColorSystems = styled.div`
`
const ColorSystem = styled.div`
  margin-bottom: 2px;

  h2 {
    margin: 0px;
    padding: 5px;
    font-size: 15px;
    font-weight: 200;
    color: #333;
    cursor: pointer;
    background-color: #eee;

    &:hover {
      background-color: #ccc;
    }

    svg {
      float: right;
    }
  }

  > div {
    display: none;
  }

  &.expanded > div {
    display: block;
  }
`
const Colors = styled.div`
  > div {
    display: flex;
    align-items: center;
  }
`

function ColorSelectDrawer({
  part,
  color,
  footer,
  onColor,
  onHide,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [colorSystems, setColorSystems] = useState([]);
    const [colors, setColors] = useState(null);
    const [expanded, setExpanded] = useState("Standard");
    const [formData, setFormData] = useState({search: ""});
    const [filterByPart, setFilterByPart] = useState(true);

    useEffect(
        () => {
            setIsLoading(true);

            postData("colors/systems", {},
                function(result) {
                    console.log("colors/systems", result)
                    setColorSystems(result);
                },
                function(error) {
                    alert("An error occured while loading the colors.");
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        []
    );

    function handleFormChange(event) {
      if (event.persist) event.persist();

      setFormData(produce(draft => {
        draft[event.target.name] = event.target.value;
      }));
    }

    function onColorSystem(system) {
      if (expanded === system.name) {
        setExpanded(null);
        return;
      }

      setExpanded(system.name);

      postData("colors/data", { colorsystem_id: system.id },
        function(result) {
            console.log("colors", result)
            
            setColorSystems(produce(draft => {
                draft.forEach( (colorSystem) => {
                    if (colorSystem.id === system.id) {
                        colorSystem.colors = result;
                    }
                })
            }));
        },
        function(error) {
          alert("An error occured while loading the colors.");
        },
        function() {
        }
      );
    }

    function onColorSelected(color) {
        console.log("onColorSelected", color)
        onColor(color);
    }

    function onSearch() {
      setColors(null);
      setIsLoading(true);

      postData("colors/search", { keyword: formData.search },
        function(result) {
          setColors(result);
        },
        function(error) {
          alert("An error occured searching colors.");
        },
        function() {
          setIsLoading(false)
        }
      );
    }

    let TBD = null;

    for (const system of colorSystems) {
      if (system.colors) {
        const color = system.colors.find(c => c.code == "TBD");

        if (color)
          TBD = color;
      }
    }

    return (
      <Modal show={true} onHide={onHide} scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPalette} />
            &nbsp;
            Color Selector
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {(part || color) &&
              <div className="mb-4">
                {part && 
                    <FieldLabel prepend="Selected Part:">
                      {part.title}
                    </FieldLabel>
                }
                {color && 
                  <Field prepend="Existing Color:">
                    <div className="flex items-center gap-1">
                        <ColorBox 
                          color={color} 
                        />
                        {color.code}
                        <span>-</span>
                        {color.name}
                      </div>
                  </Field>
                }
              </div>
            }

            {TBD && 
              <div style={{display: "flex", alignItems: "center", marginBottom: "15px"}}>
                <ColorBox 
                  key={TBD.id} 
                  onClick={() => onColorSelected(TBD)}
                  color={TBD} 
                />
                {TBD.code}
                &nbsp;-&nbsp;
                {TBD.name}
              </div>
            }
            
            <InputGroup style={{marginBottom:"10px"}}>
              <FormControl
                name="search"
                value={formData.search} 
                onChange={(e) => handleFormChange(e)} 
              />   
              <InputGroup.Append>
                <Button onClick={onSearch} variant="outline-secondary" size="sm">
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </InputGroup.Append>
            </InputGroup>

            {part && 
              <div style={{marginBottom: "10px"}}>
                <Form.Check 
                    type="checkbox"
                    label="Only Show Colors Valid For Selected Part"
                    value={1}
                    checked={filterByPart}
                    onChange={(e) => {
                      setFilterByPart(e.target.checked)
                    }}                    
                />                
              </div>
            }

            { isLoading ?
              <Loading />
            : (formData.search.length && colors) ?
              <Colors>
              {colors.filter(c => c.code != "TBD" && (filterByPart && part ? c.parts.find(p => p == part.id) : true)).map(color => (
                  <div key={color.id}>
                    <ColorBox 
                      key={color.id} 
                      onClick={() => onColorSelected(color)}
                      color={color} 
                    />
                    {color.code}
                    &nbsp;-&nbsp;
                    {color.name}
                  </div>
              ))}
              </Colors>
            :
              <ColorSystems>
              {colorSystems.map(colorSystem => (
                  <ColorSystem 
                    key={colorSystem.id} 
                    className={expanded === colorSystem.name ? "expanded":""} 
                  >
                    <h2 onClick={() => onColorSystem(colorSystem)}>
                      {colorSystem.name}

                      <FontAwesomeIcon icon={expanded === colorSystem.name ? faCaretUp : faCaretDown} />
                    </h2>

                    {expanded === colorSystem.name && 
                      <div style={{ paddingTop: "5px" }}>
                        {
                          (colorSystem.colors && colorSystem.colors.length) ?
                            <ColorGrid 
                              system={colorSystem} 
                              colors={colorSystem.colors.filter(c => c.code != "TBD" && (filterByPart && part ? c.parts.find(p => p == part.id) : true))} 
                              onColor={onColorSelected}
                            />
                            :
                            <Loading />
                        }
                      </div>
                    }
                  </ColorSystem>
              ))}
              </ColorSystems>
            }
          </>
        </Modal.Body>
        <Modal.Footer>
            {footer}
            <Button variant="outline-secondary" onClick={onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ColorSelectDrawer;