import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';

import FieldLabel from "./label"

function FieldHtmlPreview(props) {

    function tidy(html) {
        var d = document.createElement('div');
        d.innerHTML = html;
        return d.innerHTML;
    }

    return (
        <Form.Group>
            {props.label && <FieldLabel required={props.required} bold={props.labelBold}>{props.label}</FieldLabel>}
            <div
                style={{
                    border: "1px solid #ccc",
                    borderRadius: "3px",
                    padding: "8px",
                    backgroundColor: "#efefef",
                    fontSize: "10px"
                }} 
                dangerouslySetInnerHTML={{__html: tidy(props.value)}}
            >
            </div>
        </Form.Group>
    )
}

export default FieldHtmlPreview