import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { Link } from 'react-router-dom'
import produce from "immer"
import { Button, FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import { isMobile, isTablet } from "react-device-detect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown, faStickyNote } from "@fortawesome/free-solid-svg-icons";

import Loading from "../../components/loading"
import JobSchedule from "../../components/job-schedule"
import GoogleImage from "../../components/google-image"
import JobShipDateDrawer from "../../drawers/drawer-jobshipdate";
import { postData } from "../../common/services/server"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";
import { formatDate, formatDateTime } from "../../helpers/utility";

const Job = styled.div`
    display: ${isMobile ? "block":"flex"};
    margin-bottom: 25px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0px;


    h3 {
        margin: 0px;
        font-size: 15px;

        svg {
            float: right;
            cursor: pointer
        }
    }
    h4 {
        margin: 0px;
        font-size: 12px;
    }

    > div {
        padding: 10px;
        margin-right: 10px;
        background-color: white;
        position: sticky;
        left: 0px;
        z-index: 9;
    }
    > div:first-child  {
        width: 210px;
    }

    > table {
        margin: 5px;
    }
`

function ActiveJobsPanel(props) {
    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [statuses, setStatuses] = useState([])
    const [users, setUsers] = useState([])
    const [open, setOpen] = useState(null);
    const [selected, setSelected] = useState(null);

    useEffect(
        () => {
            setIsLoading(true);

            postData("jobs/active", {
                sort: "ship_date",
                sortdir: "asc"                
            },
                function(result) {
                    setJobs(result);
                },
                function(error) {
                    alert("Error loading jobs")
                },
                function() {
                    setIsLoading(false)
                }
            );

            postData("jobs/statuses", {},
                function(result) {
                    setStatuses(result);
                },
                function(error) {
                    alert("Error loading job statuses")
                }
            );

            postData("users/production", {},
                function(result) {
                    setUsers(result);
                },
                function(error) {
                    alert("Error loading employees")
                }
            );              
        }, 
        []
    );

    function onJobChanged(job) {
        console.log("panel-activejobs.onJobChanged", job);

        setJobs(produce(draft => {
            const index = draft.findIndex(j => j.id == job.id);

            if (index != -1) {
                for (const prop of Object.keys(job)) {
                    draft[index][prop] = job[prop];
                }
            }
        }));
    }

    function onStatus(jobId, statusId) {   
        setJobs(produce(draft => {
            const index = draft.findIndex(j => j.id == jobId);

            if (index != -1) {
                const status = statuses.find(s => s.id == statusId);

                draft[index].status_id = status.id;
                draft[index].status_name = status.name;
                draft[index].status_title = status.title;
            }
        })); 

        postData("jobs/setstatus", { 
            id: jobId,
            status_id: statusId
        },
            function(result) {
                setJobs(produce(draft => {
                    const index = draft.findIndex(j => j.id == jobId);
        
                    if (index != -1) {
                        draft[index].status_id = result.status_id;
                        draft[index].status_name = result.status_name;
                        draft[index].status_title = result.status_title;
                    }
                }));    
            },
            function(error) {
                alert("Error updating job")
            }
        );
    }

    function toggleOpen(type) {
        setOpen(open == type ? "" : type);
    }

    return (
        <>
            {
            isLoading ?
                <Loading />
            : (jobs.length == 0) ?
                <p>
                    No <strong>Active</strong>Jobs Found 
                </p>
            :    
                <div>
                    {jobs.map(job => (
                        <Job key={job.id}>
                            <div style={{display:"flex", flexFlow:"column"}}>
                                <h3>
                                    <Link to={"/jobs/job/" + job.id} target={isTablet ? "_top":"_blank"}>
                                        {job.title}
                                    </Link>
                                    {isMobile && 
                                        <FontAwesomeIcon icon={open == job.id?faChevronUp:faChevronDown} onClick={() => toggleOpen(job.id)} />
                                    }
                                </h3>                         

                                <div style={{flex:"1"}}>
                                    <GoogleImage root src={job.preview_url} style={{ maxWidth: "100px", maxHeight: "100px"}} />

                                    {(job.note_count > 0) &&
                                        <div style={{margin: "15px 0px"}}>
                                            {job.notes.map(note => (
                                                <div key={note.id} style={{
                                                    fontSize: "10px",
                                                    backgroundColor: "#ffc107",
                                                    color: "black",
                                                    borderRadius: "4px",
                                                    margin: "3px 0px",
                                                    padding: "3px",
                                                    textAlign: "left"
                                                }}>
                                                    {note.notes}
                                                </div>
                                            ))}
                                        </div>
                                    }                                      
                                </div>
 
                                <div>
                                    <div style={{
                                        fontSize: "14px",
                                        display: "grid",
                                        gridTemplateColumns: "100px 1fr"
                                    }}>
                                        <div>Ordered:</div><div>{formatDate(job.order_date)}</div>
                                        {(job.order_status_name != "payment" && job.order_status_name != "paris") && 
                                            <>
                                                <div>Order Status:</div><div style={{color:"red"}}>{job.order_status_title}</div>
                                            </>
                                        }
                                        <div>Job:</div><div>{formatDate(job.added_date)}</div>
                                        <div>Ships:</div>
                                        <div>
                                            <Button 
                                                size="sm" 
                                                variant="outline-secondary" 
                                                style={{padding:"1px 4px"}}
                                                onClick={() => setSelected({mode:"ShipDate", job})}
                                            >
                                                {formatDate(job.ship_date)}
                                            </Button>                                        
                                        </div>                                        
                                    </div>

                                    {userHasPermission(Permissions.Jobs, Actions.Edit) &&
                                        <div>
                                            <FormControl 
                                                as="select" 
                                                name="status_id" 
                                                value={job.status_id} 
                                                size="sm" 
                                                onChange={(e) => {
                                                    onStatus(job.id, e.target.value)
                                                }}
                                            >
                                                {statuses.map(status => (
                                                    <option key={status.id} value={status.id}>{status.title}</option>
                                                ))}
                                            </FormControl>
                                        </div> 
                                    }
                                </div>
                            </div>
                            {(!isMobile || open == job.id) && 
                                <JobSchedule 
                                    job={job} 
                                    users={users}
                                    onJobChange={onJobChanged}
                                />
                            }
                        </Job>
                    ))}
                </div>
            }

            { (selected && selected.mode == "ShipDate") && 
                <JobShipDateDrawer
                    show={true}
                    job={selected.job}
                    onJobChange={(jobData) => {
                        onJobChanged(jobData)
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } /> 
            }              
        </>
    );
}

export default ActiveJobsPanel;