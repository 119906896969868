import React, { useState, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import UserField from "../components/fields/field-user"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"

function CartDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(props.cart)));
  const [validated, setValidated] = useState(false);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("carts/save?id=" + (props.cart.id||0), formData, 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the cart.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;
    let value = event.target.value;

    if (field == "user_id") {
        value = event.target.value.id;
    }

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faShoppingCart} />
            &nbsp;
            {props.cart.id ? "Update":"Create"} Shopping Cart
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
            <UserField 
                id="cart-user"
                name="user_id" 
                label="User" 
                required
                value={{id:formData.user_id, name: formData.user_name || "Anonymous"}}
                onChange={(e) => handleFormChange(e)} />
           </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Cart" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default CartDrawer;