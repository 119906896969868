import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import produce from "immer"
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPallet } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import ColorBox from "../components/color-box";
import SelectField from "../components/fields/field-select"

import { postData } from "../common/services/server"

const Systems = styled.table`
    width: 100%;
    thead tr th {
        background-color: #e0e0e0;
        padding: 5px;
    }
    tbody tr td {
        padding: 5px;
    }
`
const Part = styled.tr`
`

function ColorSystemsDrawer(props) {
  const [loading, setLoading] = useState(true);

  const [systems, setSystems] = useState([]);

  useEffect(() => {
        setLoading(true);

        postData("colors/allsystems", { color_id: props.color.id }, 
            function(results) {
                setSystems(results);
            },
            function(error) {
                alert("An error occured loading the color data.");
            },
            function() {
                setLoading(false);
            }
        );
  }, []);

 
  function onSystemChange(e) {
    const systemId = e.target.value;
    const checked = e.target.checked;
    let selected = [];

    setSystems(produce(draft => {
        const system = draft.find(s => s.id == systemId);

        system.selected_ind = checked ? 1 : 0;

        selected = draft.filter(s => s.selected_ind == 1).map(s => s.name);
    }));

    postData("colors/togglesystem", {
            color_id: props.color.id,
            system_id: systemId,
            checked_ind: checked ? 1 : 0
        }, 
        function(response) {
            props.onChanged(selected.length)
        },
        function(error) {
            alert("An error occured updating the color.");
        },
        function() {
        }
    );
  }

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faPallet} />
                &nbsp;
                Color Systems
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: "flex", alignItems: "center", marginBottom: "15px"}}>
                    <ColorBox color={props.color} />
                    {props.color.name}
                </div> 

                { loading ? 
                    <Loading />
                :
                systems.length == 0 ?
                    <p style={{color:"red"}}>No color systems found.</p>
                : 
                    <Systems>
                        <thead>
                            <tr>
                                <th>Color System</th>
                            </tr>
                        </thead>
                        <tbody>
                        {systems.map(system => (
                            <tr key={system.id}>
                                <td>
                                    <Form.Check 
                                        name="system_id"                                
                                        value={system.id}
                                        checked={system.selected_ind == 1}
                                        inline 
                                        label={system.name} 
                                        type="checkbox" 
                                        onChange={onSystemChange}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Systems>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default ColorSystemsDrawer;