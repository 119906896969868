import axios from 'axios';
import axiosClient from './axios'
import { getToken } from '../common/services/auth';

export async function query(url, params, signal) {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    const token = getToken();
    const config = {
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token,
        },
        responseType: 'json',
        cancelToken: source.token,
        withCredentials: true,
     };

    // Cancel the request if TanStack Query signals to abort
    signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
    })

    const response = await axiosClient.post("query/" + url, params, config);
    
    return response.data;
}

export async function mutate(url, params) {
    const token = getToken();
    const config = {
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token,
        },
        responseType: 'json',
        withCredentials: true,
     };

    const response = await axiosClient.post("mutate/" + url, params, config);
    
    return response.data;
}
