import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { faDollarSign } from "@fortawesome/free-solid-svg-icons"

import ChartBox from "../charts/chartbox"
import { format } from "date-fns"

import { postData } from "../../common/services/server"
import { formatPrice } from "../../helpers/utility"

const Stats = styled.table`
    border: 1px solid #e0e0e0;

    th {
        padding: 4px 8px;
        background-color: #eee;
        border: 1px solid #e0e0e0;
        text-align: center;
    }
    td {
        padding: 4px 8px;
        text-align: right;
    }

    .separator {
        border-right: 5px solid #e0e0e0;
    }
`

function SalesStatsWidget(props) {
    const today = new Date();
    const [date, setDate] = useState(format(today, "M/1/yyyy"));

    const [stats, setStats] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(
        () => {
            setLoading(true);

            postData("orders/salesstats",  {
                day: format(Date.parse(today), "d"),
                month: format(Date.parse(date), "M"),
                year: format(Date.parse(date), "yyyy")
            },
                function(result) {
                    setStats(result);
                },
                function(error) {
                    setError("Error loading stats")
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        []
    );

    return (
        <>
            <ChartBox 
                icon={faDollarSign}
                title="Sales Stats" 
                height={85}
                loading={loading}
                error={error}
                refresh={true}
            >
                <Stats>
                <tbody>
                    <tr>
                        <th>{format(new Date(), "MMM")} MTD</th>
                        <th>{format(new Date(), "MMM")} {new Date().getFullYear() - 1} MTD</th>
                        <th className="separator">{format(new Date(), "MMM")} {new Date().getFullYear() - 1}</th>
                        <th>YTD</th>
                        <th className="separator">Last YTD</th>
                        <th>{new Date().getFullYear() - 1}</th>
                        <th>{new Date().getFullYear() - 2}</th>
                        <th>{new Date().getFullYear() - 3}</th>
                    </tr>
                    <tr>
                        <td>{formatPrice(stats.sales_mtd)}</td>
                        <td>{formatPrice(stats.sales_last_mtd)}</td>
                        <td className="separator">{formatPrice(stats.sales_last_month)}</td>
                        <td>{formatPrice(stats.sales_ytd)}</td>
                        <td className="separator">{formatPrice(stats.sales_last_ytd)}</td>
                        <td>{formatPrice(stats.sales_year1)}</td>
                        <td>{formatPrice(stats.sales_year2)}</td>
                        <td>{formatPrice(stats.sales_year3)}</td>
                    </tr>
                </tbody>
                </Stats>
            </ChartBox>
        </>
    );
}

export default SalesStatsWidget