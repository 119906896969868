import React, { useState } from "react";
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSpinner } from '@fortawesome/free-solid-svg-icons'

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

import {postData} from "../../common/services/server"

function UserItem(props) {
    return (
        <div>
            <div>
                <FontAwesomeIcon icon={faUser} />
                &nbsp;
                {props.user.name || "[No Name]"}
            </div>
            <div style={{ marginLeft: "20px", marginTop: "-4px"}} className="text-muted">
                <small>{props.user.email}</small>
            </div>
        </div>
    );
}

function UserSelect(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);

    function handleSearch(query) {
        setIsLoading(true);

        postData("users/dropdown/search", { search: query, employee_ind: (props.employees ? 1:0)},
            function(matches) {
                setUsers(matches);
            },
            function(error) {
                console.log(error);
            },
            function() {
                setIsLoading(false);
            }
        );
    }

    console.log("user default", props.default)
    return (
        <AsyncTypeahead
          id={props.name}
          options={users}
          useCache={false}
          allowNew={false}
          isLoading={isLoading}
          disabled={props.disabled}
          labelKey="email"
          minLength={2} 
          defaultInputValue={props.default ? props.default.email || props.default.name : ""}
          inputProps={{ required: props.required }}
          onSearch={handleSearch}
          onChange={ (selected) => props.onChange(selected[0]) }
          placeholder="Search for user..."
          searchText={<><FontAwesomeIcon icon={faSpinner} spin />&nbsp;Searching...</>}
          filterBy={(option, props) => {
            return true;
          }}
          renderMenuItemChildren={(option, props) => (
            <UserItem key={option.id} user={option} />
          )}
        />
    );
  }

export default UserSelect