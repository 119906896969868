import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSpinner } from '@fortawesome/free-solid-svg-icons'

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

import { postData } from "../../common/services/server"
import storage from '../../settings/storage'

const Project = styled.div`
    cursor: pointer
    display: flex;
    font-size: 14px;
    line-height: initial;

    img {
        margin-right: 10px;
    }

    div.user {
        font-size: 12px;
    }

    small {
        color: #999;
    }
`

function ProjectSelect(props) {
    const selectRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [projects, setProjects] = useState([]);

    useEffect(
        () => {
            if (!props.default) {
                selectRef.current.getInstance().clear()
            }
        }, 
        [props.default]
    );

    function handleSearch(query) {
        setIsLoading(true);

        postData("search/projects?internal_ind=" + (props.internal?1:0), { search: query },
            function(matches) {
                setProjects(matches);
            },
            function(error) {
                console.log(error);
            },
            function() {
                setIsLoading(false);
            }
        );
    }

    return (
        <AsyncTypeahead
          id={props.name}
          ref={selectRef}
          options={projects}
          useCache={false}
          allowNew={false}
          isLoading={isLoading}
          labelKey="title"
          minLength={2} 
          defaultInputValue={props.default ? props.default.title : ""}
          //selected={props.value}
          inputProps={{ required: props.required }}
          onSearch={handleSearch}
          onChange={ (selected) => props.onChange(selected[0]) }
          placeholder="Search for project..."
          searchText={<><FontAwesomeIcon icon={faSpinner} spin />&nbsp;Searching...</>}
          filterBy={(option, props) => {
            return true;
          }}
          renderMenuItemChildren={(project, props) => (              
            <Project key={project.id}>
                {!props.internal && 
                    <div className="icon">
                        <img src={storage.root + (project.preview_url || "images/nothumbnail.png")} width={40} style={{minWidth:"40px"}} />
                    </div> 
                }
                <div>
                    <div>
                        <strong>{project.title}</strong>
                    </div>
                    <div>
                        {project.subtitle && <small>{project.subtitle}</small>}
                    </div>
                    {project.user_name && 
                        <div className="user">
                            <FontAwesomeIcon icon={faUser} />
                            {project.user_name}
                        </div>
                    }
                </div>
            </Project>
          )}
        />
    );
  }

export default ProjectSelect