import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetShippingMethods = (options={}) => {
  return useQuery({ 
    queryKey: ["shippingmethods"], 
    queryFn: ({signal}) => query("shipping/methods/list", {}, signal),
    ...options
  });
}
