import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutate } from '../api';
import { TaskStatuses } from '../../helpers/data';

export const useWorkflowJobCreate = (options={}) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({order_id, orderitem_id, product_id, signgroup_ids}) => {
            return mutate("workflowjobs/createjob", {
                order_id, 
                orderitem_id, 
                product_id, 
                signgroup_ids
            })
        },
        ...options
  })
};

export const useWorkflowJobDelete = (options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (jobId) => {
            return mutate("workflowjobs/delete", {
                job_id: jobId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobUpgrade = (jobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: () => {
            return mutate("workflowjobs/upgrade", {
                job_id: jobId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobReset = (options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (jobId) => {
            return mutate("workflowjobs/reset", {
                job_id: jobId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobResetWorkflow = (options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (jobId) => {
            return mutate("workflowjobs/job/resetworkflow", {
                job_id: jobId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowResetWarnings = (options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (jobId) => {
            return mutate("workflowjobs/job/resetwarnings", {
                job_id: jobId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobResyncWorkflow = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: () => {
            return mutate("workflowjobs/job/resyncworkflow", {
                job_id: workflowJobId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobRescheduleWorkflow = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: () => {
            return mutate("workflowjobs/job/rescheduleworkflow", {
                job_id: workflowJobId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobAddSignShape = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: ({jobGroupId, signShapeId, relatedShapeId, shapeRelatedId, checked}) => {
            return mutate("workflowjobs/job/addsignshape", {
                job_id: workflowJobId,
                jobgroup_id: jobGroupId,
                signshape_id: signShapeId,
                related_shape_id: relatedShapeId,
                shape_related_id: shapeRelatedId,
                checked_ind: checked ? 1:0,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "groups"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobRemoveSignShape = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: ({jobGroupId, signShapeId}) => {
            return mutate("workflowjobs/job/removesignshape", {
                job_id: workflowJobId,
                jobgroup_id: jobGroupId,
                signshape_id: signShapeId,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "groups"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobToggleWarning = (workflowJobId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({warningTypeId, activeInd}) => {
            return mutate("workflowjobs/job/togglewarning", {
                job_id: workflowJobId,
                warningtype_id: warningTypeId,
                active_ind: activeInd
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "warnings"]);
        },    
  })
};

export const useWorkflowJobToggleNote = (workflowJobId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({noteId, activeInd}) => {
            return mutate("workflowjobs/job/togglenote", {
                job_id: workflowJobId,
                note_id: noteId,
                active_ind: activeInd
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "notes"]);
        },    
  })
};

export const useWorkflowJobUpdateProp = (workflowJobId, prop) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (value) => {
            return mutate("workflowjobs/job/updatejobprop", {
                job_id: workflowJobId,
                prop,
                value
            })
        },
        onSuccess: () => {
            if (prop == "status_id")
                queryClient.invalidateQueries(["workflowjobs"]);
            else
                queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId]);
        },    
  })
};
  
export const useWorkflowJobUpdatePartProp = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: ({jobGroupId, partId, prop, value}) => {
            return mutate("workflowjobs/job/updatejobgrouppartprop", {
                job_id: workflowJobId,
                jobgroup_id: jobGroupId,
                part_id: partId,
                prop,
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "groups"]);

            if (onSuccess) onSuccess();
        }, 
        ...otherOptions   
  })
};

export const useWorkflowJobUpdatePowersetProp = (workflowJobId, jobPowersetId, prop) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (value) => {
            return mutate("workflowjobs/job/updatejobpowersetprop", {
                job_id: workflowJobId,
                jobpowerset_id: jobPowersetId,
                prop,
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "groups"]);
        },    
  })
};

export const useWorkflowJobUpdateShapeProp = (workflowJobId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({jobShapeId, prop, value}) => {
            return mutate("workflowjobs/job/updatejobshapeprop", {
                job_id: workflowJobId,
                jobshape_id: jobShapeId,
                prop,
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "groups"]);
        },    
  })
};

export const useWorkflowJobUpdatePowerProp = (workflowJobId, jobPowerId, prop) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (value) => {
            return mutate("workflowjobs/job/updatejobpowerprop", {
                job_id: workflowJobId,
                jobpower_id: jobPowerId,
                prop,
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "groups"]);
        },    
  })
};

export const useWorkflowJobUpdateDimensionProp = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: ({jobGroupId, jobDimensionId, prop, value}) => {
            return mutate("workflowjobs/job/updatejobdimensionprop", {
                job_id: workflowJobId,
                jobgroup_id: jobGroupId,
                jobdimension_id: jobDimensionId,
                prop,
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "groups"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobUpdateTaskProp = (workflowJobId, jobWorkflowId, prop, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (value) => {
            return mutate("workflowjobs/job/workflow/updateprop", {
                job_id: workflowJobId,
                jobworkflow_id: jobWorkflowId,
                prop,
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "workflow"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobCompleteTask = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (jobWorkflowId) => {
            console.log("useWorkflowJobCompleteTask mutate", jobWorkflowId)
            return mutate("workflowjobs/job/workflow/updateprop", {
                job_id: workflowJobId,
                jobworkflow_id: jobWorkflowId,
                prop: "status_id",
                value: TaskStatuses.completed.id,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobResolveTaskProblem = (workflowJobId, jobWorkflowId, problemId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (resolution) => {
            return mutate("workflowjobs/job/workflow/resolveproblem", {
                job_id: workflowJobId,
                jobworkflow_id: jobWorkflowId,
                problem_id: problemId,
                resolution
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobResetPower = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: () => {
            return mutate("workflowjobs/job/resetpower", {
                job_id: workflowJobId,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobAddPower = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (powersetId) => {
            return mutate("workflowjobs/job/addpower", {
                job_id: workflowJobId,
                jobpowerset_id: powersetId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "groups"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobRemovePower = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (powerId) => {
            return mutate("workflowjobs/job/removepower", {
                job_id: workflowJobId,
                jobpower_id: powerId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "groups"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};
  
export const useWorkflowJobAddPowerset = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: () => {
            return mutate("workflowjobs/job/addpowerset", {
                job_id: workflowJobId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "groups"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobRemovePowerset = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (powersetId) => {
            return mutate("workflowjobs/job/removepowerset", {
                job_id: workflowJobId,
                jobpowerset_id: powersetId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId, "groups"]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};
  
export const useWorkflowJobAddPart = (workflowJobId, jobGroupId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (partId) => {
            return mutate("workflowjobs/job/addjobgrouppart", {
                job_id: workflowJobId,
                jobgroup_id: jobGroupId,
                part_id: partId,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId]);
            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};
  
export const useWorkflowJobRemoveGroupPartType = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: ({jobGroupId, type}) => {
            return mutate("workflowjobs/job/removejobgroupparttype", {
                job_id: workflowJobId,
                jobgroup_id: jobGroupId,
                type,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};

export const useWorkflowJobRemoveGroupPart = (workflowJobId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: ({jobGroupId, partId}) => {
            return mutate("workflowjobs/job/removejobgrouppart", {
                job_id: workflowJobId,
                jobgroup_id: jobGroupId,
                part_id: partId,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflowjobs", ""+workflowJobId]);

            if (onSuccess) onSuccess();
        },    
        ...otherOptions
  })
};
