import React, { useState } from "react";
import clsx from "clsx";
import { produce } from "immer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { Accordion, AccordionItem } from "../components/accordion";
import Button from "../components/buttons/button";
import DrawerTemplateData from "./template-data";

import { postData } from "../common/services/server";
import { removeNulls } from "../helpers/utility";
import ButtonMutate from "../components/buttons/button-mutate";

export default function DrawerTemplateGroupedDataSelect({    
    selectCaption,
    
    query,
    queryItemsProp="items",

    groupClassName,
    formatGroupTitle,

    itemClassName,
    itemRender,
    itemExistsCheck,

    onItemSelected,
    selectMutation,
    selectMutationDataProp,
    onMutate,
    onMutateSuccess,
    onMutateError,
    onMutateSettled,

    ...props
  }) {

  return (
    <DrawerTemplateData
      {...props}
      query={query}
      noDataFound={!query.data || query.data.length == 0}
    >
        <Accordion className={groupClassName}>
            {query.data?.map((group,index) => (
                <AccordionItem 
                    key={index}
                    initiallyExpanded={query?.data.length == 1}
                    title={formatGroupTitle(group)}
                >
                    {(group[queryItemsProp] || []).map((item,index) => {
                        const exists = itemExistsCheck ? itemExistsCheck(item) : false;

                        return (
                            <div key={index} className={clsx(
                                "flex items-center justify-between gap-2 p-1",
                                exists ? "bg-gray-100" : "bg-white",
                                itemClassName,
                            )}>
                                <div>
                                    {itemRender(item)}
                                </div>
                                
                                {!exists && 
                                  <>
                                    {selectMutation ?
                                        <ButtonMutate
                                            icon={faPlus}
                                            caption={selectCaption}
                                            size="sm"
                                            variant="outline-success"
                                            
                                            mutation={selectMutation}
                                            mutationData={item[selectMutationDataProp]}
                                            onMutate={onMutate ? () => onMutate(item) : null}
                                            onMutateSuccess={onMutateSuccess}
                                            onMutateError={onMutateError}
                                            onMutateSettled={onMutateSettled}

                                            disabled={selectMutation.isLoading}
                                            className="whitespace-nowrap"
                                        />
                                    :                                
                                        <Button
                                            icon={faPlus}
                                            size="sm"
                                            variant="outline-success"
                                            className="whitespace-nowrap"
                                            onClick={() => onItemSelected(item)}
                                        >
                                            {selectCaption || "Select"}
                                        </Button>
                                    }
                                  </>
                                }
                            </div>
                        )
                    })}                    
                </AccordionItem>
            ))}
        </Accordion>
    </DrawerTemplateData>  
  )
}
