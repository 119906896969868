import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"

import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup'

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faPlus, faUser } from '@fortawesome/free-solid-svg-icons'

import PropertyList from "../components/properties/property-list"
import Property from "../components/properties/property"
import UserSelect from "../components/fields/user-select"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import GoogleImage from "../components/google-image"
import { postData } from "../common/services/server"
import { formatPhoneNumber } from '../helpers/utility'

function ProjectUserDrawer(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [user, setUser] = useState(null)

  useEffect(
    () => {
    }, 
    [props.item]
  );

  function onSave() {
      setIsProcessing(true);

      postData("projects/updateprop", {
          project_id: props.project.id,
          prop: "user_id",
          value: user.id
      }, 
        function(response) {
          props.onUpdated(response);
        },
        function(error) {
          alert("An error occured updating the project.");
        },
        function() {
          setIsProcessing(false);
        }
      );
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faUser} />
            &nbsp;
            Project Customer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <InputGroup className="mb-2">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        Select User
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <UserSelect
                    name="user_id"
                    onChange={(user) => {
                        console.log(user)
                        setUser(user);
                    }}
                />
            </InputGroup>

            {user && 
                <PropertyList>
                    <Property label="User #" value={user.id} />
                    <Property label="Name" value={user.name} />
                    <Property label="Email" value={user.email} />
                    <Property label="Phone" value={formatPhoneNumber(user.phoneno)} />
                </PropertyList>
            } 
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Assign Project" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ProjectUserDrawer;