import React, { useState, useEffect } from "react";
import produce from "immer"
import Modal from 'react-bootstrap/Modal';
import ListField from "../components/fields/field-list"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBorderAll, faWrench } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"

import { postData } from "../common/services/server"

function SelectJobTaskTypeDrawer({
    label,
    products,
    parts,
    existingTasks,
    onSelect, 
    onHide
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [taskTypes, setTaskTypes] = useState([]);

  const [product, setProduct] = useState(null);
  const [task, setTask] = useState(null);

  console.log({existingTasks});

  useEffect(() => {
      setIsProcessing(true);

      postData("jobs/tasktypes", {}, 
        function(results) {
            console.log(results)
            setTaskTypes(results);
            // setTaskTypes(results.map(r => {
            //     const mwoPartIds = (r.parts || "").split(",");

            //     let partIds = [];

            //     for (const mwoPartId of mwoPartIds) {
            //         const part = parts.find(p => p.id == mwoPartId);

            //         if (part)
            //             partIds.push(part.id);
            //     }

            //     return {
            //         ...r,
            //         part_ids: partIds
            //     }
            // }));
        },
        function(error) {
          alert("An error occured loading the job tasks.");
        },
        function() {
          setIsProcessing(false);
        }
      );   
  }, []);

  return (
    <>
        <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faWrench} />
                &nbsp;
                {label || "Select"} Production Task
            </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minWidth: "800px"}}>
                { isProcessing ? 
                    <Loading />
                :
                taskTypes.length == 0 ?
                    <p style={{color:"red"}}>No Tasks found.</p>
                :
                    <div>
                        <ListField 
                            name="product" 
                            label="Product" 
                            value={product ? product.id : null} 
                            list={products}
                            onChange={(e) => {
                                setProduct(products.find(p => p.id == e.target.value));
                                setTask(null);
                            }} 
                        />                    
                        <ListField 
                            name="task" 
                            label="Production Task" 
                            value={task ? task.id : null} 
                            list={taskTypes.filter(t => (!product && t.product_id == null) || (product && t.product_id == product.id))}
                            onChange={(e) => {
                                setTask(taskTypes.find(t => t.id == e.target.value));
                            }} 
                        />
                        {task && 
                            <div>
                                {(existingTasks.find(t => t.name == task.name) != null) &&
                                    <p style={{color: "red"}}>
                                        This product already has a <strong>{task.name}</strong> task.
                                    </p>                             
                                }
                            </div>
                        }
                    </div>
                }
            </Modal.Body>
            <Modal.Footer style={{display: "flex", justifyContent: "space-between"}}>
                <Button 
                    variant="outline-success" 
                    disabled={!task || existingTasks.find(t => t.name == task.name)}
                    onClick={() => onSelect(task)}
                >
                    {label || "Select"} Task
                </Button>

                <Button variant="outline-secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>         
    </>
    )
}

export default SelectJobTaskTypeDrawer;