import React, { useState, useEffect } from "react";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"
import { addBusinessDays, addDays, differenceInCalendarDays, format, isWeekend, parseISO }  from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs, faSave } from '@fortawesome/free-solid-svg-icons'

import ListField from "../components/fields/field-list"
import GoogleImage from "../components/google-image"
import { postData } from "../common/services/server";
import { colors } from "../settings/settings";
import JobTask from "../components/job-task";
import Loading from "../components/loading";
import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing";

const Sign = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;

  img { margin-right: 10px; }
`

const Tasks = styled.div`
  > div {
    display: grid;
    grid-template-columns: 100px 1fr;

    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 5px;

    > div:first-child {
      background-color: #eee;
      padding: 10px;
      text-align: center;

      &.today {
        background-color: ${colors.lightGreen};
      }
    }
    > div:last-child {
      > div {
        display: grid;
        grid-template-columns: 1fr 150px 175px;
        gap: 5px;
        border-bottom: 1px solid #eee;
        padding: 3px;
      }
    }
  }
`

function JobInitialTasksDrawer(props) {
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [selected, setSelected] = useState([]);
  const [processing, setProcessing] = useState(false);

  useEffect(
    () => {  
        setLoading(true);

        postData("jobs/tasks", {
            id: props.job.id
        }, 
            function(response) {
                setTasks(response)
                setSelected(response.filter(t => t.status_name != "invalid" && t.status_name != "skipped").map(t => t.id));
            },
            function(error) {
                alert("An error occured loading task data.");
            },
            function() {
                setLoading(false);
            }
        );      
    }, 
    [props.job]
  );

  function onSave() {
    setProcessing(true);

    postData("jobs/setinitialtasks", {
        id: props.job.id,
        task_ids: selected.join(",")
    }, 
        function(response) {
            if (props.onSaved)
                props.onSaved(response)
        },
        function(error) {
            alert("An error occured updating the job.");
        },
        function() {
            setProcessing(false);
        }
    );      
  }

  const shipDate = parseISO(props.job.ship_date.split("T")[0]);
  const startDate = addBusinessDays(shipDate, -(props.job.days-1));

  const today = new Date();
  const days = [];
  let day = 1;

  for (let i=0; i<=differenceInCalendarDays(shipDate, startDate); i++) {
      const dayDate = addDays(startDate, i);
      const weekend = isWeekend(dayDate);

      days.push({
          day: weekend ? 0 : day,
          date: dayDate,
          weekend,
          today: differenceInCalendarDays(today, dayDate) == 0
      });     
      
      if (!weekend) day++
  }
  
  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faCogs} />
            &nbsp;
            Initial Job Tasks
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Sign>
                <GoogleImage 
                    root 
                    src={props.job.preview_url} 
                    style={{
                        maxWidth: "125px",
                        maxHeight: "75px"
                    }}
                />
            </Sign>
                
            {loading ? 
                <Loading />
            :
                <Tasks>
                {days.map((day,index) => (
                <div key={index}>
                    <div className={day.today ? "today" : day.weekend ? "weekend":""}>
                    <div>{day.day > 0 ? <>Day {day.day}</> : ' '}</div>
                    <div><small>{format(day.date, "EEEE")}</small></div>
                    <div><small>{format(day.date, "MMM do")}</small></div>
                    </div>
                    <div>
                    {tasks.filter(t => t.target_day == day.day).map(task => (
                        <div key={task.id}>
                            <Form.Check 
                                name="task"                                
                                value={task.id}
                                checked={selected.indexOf(task.id) != -1}
                                label={task.name} 
                                type="checkbox" 
                                disabled={task.status_name == "invalid" || task.status_name == "skipped"}
                                onChange={(e) => {
                                    setSelected(produce(draft => {
                                        const idx = draft.indexOf(task.id);

                                        if (idx == -1)
                                            draft.push(task.id)
                                        else 
                                            draft.splice(idx, 1);
                                    }))
                                }}
                            />                    
                        </div>
                    ))}
                    </div>
                </div>
                ))}              
                </Tasks>
            }
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={processing}
                onClick={onSave} 
                variant="outline-success" 
                disabled={loading || selected.length == 0}
                caption="Update Job" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>        
      </Modal>
    )
}

export default JobInitialTasksDrawer;