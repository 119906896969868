import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import { isMobile } from "react-device-detect"

import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl';

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faExclamationTriangle, faWrench, faHourglassStart, faCheck, faSearch, faQrcode ,faUserTie, faSpinner, faStickyNote, faEye } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import StationEmployees from "../components/station-employees";
import { postData } from "../common/services/server"
import storage from "../settings/storage";

const Task = styled.div`
    display: grid;
    gap: 10px;

    > button {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        padding: 20px;
    }
`
const ProblemPrompt = styled.div`
`
const Notes = styled.div`
    color: #800020;

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            padding: 3px 8px;
            width: 125px;
            margin: 0px;
        }
    }
`
const Warnings = styled.div`
    svg, strong {
        color:red;
    }
`
const Error = styled.div`
    margin: 10px 0px;
    color: red;
`

function ScanPrompt({task, error}) {
    return (
        <div>
            {task.mwo ? 
                <div>Scan <strong>{ task.job_title } "{ task.mwo_title || task.mwo}" MWO</strong> to Activate Button</div>
            :
                <div>Scan any <strong>Job #{ task.job_id} MWO</strong> to Activate Button</div>
            }

            {(error && error != "noworker") && 
                <Error dangerouslySetInnerHTML={{__html: error}} />                    
            }
        </div>
    )
}

function StationTaskDrawer(props) {
  const [loading, setLoading] = useState(true);
  const [busy, setBusy] = useState("");
  const [data, setData] = useState(null);
  const [notesRead, setNotesRead] = useState([]);

  const [scanned, setScanned] = useState(false);
  const [error, setError] = useState("");  

  const [override, setOverride] = useState(false);  
  const [code, setCode] = useState("");  
  
  function onBarcodeScaned(barcode) {
    console.log("task barcode scanned", barcode);

    const parts = barcode.split(":");
    const jobId = parts[1];
    const mwo = parts[3];

    console.log("job id", jobId);
    console.log("mwo", mwo);

    let error = "";
    
    // correct barcode?
    if (jobId == props.task.job_id) {
        // Do a partial match so that a single task mwo name can match (prefix) of actual mwo sticker and 
        // handle multiple of them since they vary slightly in naming per product.
        // console.log(mwo.toLowerCase());
        // console.log(props.task.mwo ? props.task.mwo.toLowerCase() : "no mwo");

        if (props.task.mwo && props.task.mwo.length > 0) {
            const index = mwo.toLowerCase().indexOf(props.task.mwo.toLowerCase());
            // console.log({index});
            // console.log(mwo == props.task.mwo, mwo.length, props.task.mwo.length)

            if (mwo.toLowerCase() == props.task.mwo.toLowerCase() || index != -1) {
                setScanned(true)
            }
            else error = `Scanned barcode is for <strong>"${mwo.toUpperCase()}"</strong> MWO.  This task is for <strong>"${props.task.mwo.toUpperCase()}"</strong> MWO.`;
        }
        else {
            setScanned(true);
        }
    }
    else error = `Scanned barcode is for <strong>Job #${jobId}</strong>.  This task is for <strong>Job #${props.task.job_id}</strong>.`;

    if (error.length) {
        // perhaps they scanned another valid job/task?
        if (props.tasks.find(t => t.job_id == jobId && t.mwo == mwo)) {
            props.onBarcodeScaned(barcode);
        }
        else setError(error);
    }
  }

  function handleKeyPress(e) {
      console.log("key", e.key, e.keyCode, window.barcode);

      if (e.keyCode === 74) { // J
        console.log("reading barcode")
        // Start of barcode
        window.readingBarcode = true;
        window.barcode = e.key;
        
        e.preventDefault();
        return;
      }
  
      if (window.readingBarcode) {
          e.preventDefault();
  
          if (e.keyCode === 13) { // Enter
              window.readingBarcode = false;
              onBarcodeScaned(window.barcode);
              return;
          }
  
          // Append the next key to the end of the list
          window.barcode += e.key;
      }
  }

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  useEffect(() => {
   console.log("props.scanned", props.scanned)
   setScanned(props.scanned)
  }, [props.scanned]);

  useEffect(() => {
    console.log("props.barcode", props.barcode)
    if (props.barcode && props.barcode.scanned)
        setScanned(true)
   }, [props.barcode]);

  useEffect(
    () => {
        postData("stations/stationtask", { station_id: props.station.id, jobtask_id: props.task.id },
            function(result) {
                setData(result);
            },
            function(error) {
                alert("Error loading task data")
            },
            function() {
                setLoading(false);
            }
        );
    }, 
    [props.task]
  );

  function updateTaskStatus(statusName) {
    if (data.workers.filter(w => w.working_ind == 1).length > 0 || data.task.assigned_user_id) {
        setData(produce(draft => {
            draft.task.status_name = statusName;
        }));

        postData("stations/settaskstatus", { 
                station_id: props.station.id, 
                jobtask_id: props.task.id, 
                status_name: statusName
            },
            function(result) {
                setData(produce(draft => {
                    draft.task = result.task
                }));

                props.onTaskChanged(result.station, result.task);

                if (statusName == "completed" || statusName == "inprogress") {
                    props.onHide();
                }
            },
            function(error) {
                alert("Error updating task")
            }
        );
    }
    else {
        setError("noworker");
    }
  }

  function onBeginProduction() {
    updateTaskStatus("inprogress")
  }

  function onBeginInspection() {
    updateTaskStatus("qa")
  }

  function onContinue() {
    updateTaskStatus("inprogress")
  }

  function onFinished() {
    updateTaskStatus("completed")
  }

  function onPauseBreak() {
    updateTaskStatus("paused")
  }

  function onPauseProblem() {
    updateTaskStatus("problem")
  }

  function onWorker(worker) {
    setError("");
    setData(produce(draft => {
        draft.workers[draft.workers.findIndex(u => u.id == worker.id)].busy = true;
    }));

    postData("stations/assignworker", { 
            station_id: props.station.id, 
            jobtask_id: props.task.id, 
            worker_id: worker.id,
            working_ind: worker.id == props.task.assigned_user_id ? 0:1
        },
        function(result) {
            setData(produce(draft => {
                draft.task = result.task;

                const index = draft.workers.findIndex(u => u.id == worker.id);

                draft.workers[index].busy = false;
                draft.workers[index].working_ind = worker.working_ind == 1 ? 0:1;
            }));

            props.onTaskChanged(result.station, result.task);
        },
        function(error) {
            alert("Error updating task")
        }
    );
  }

  function onSaveProblem() {
    setBusy("problem")

    postData("stations/setproblem", { 
            station_id: props.station.id, 
            jobtask_id: props.task.id, 
            problem: data.task.problem
        },
        function(result) {
            setData(produce(draft => {
                draft.task = result.task;
            }));

            props.onTaskChanged(result.station);
            props.onHide();
        },
        function(error) {
            alert("Error updating task")
        },
        function() {
            setBusy("");
        }
    );
  }

  function onOverrideCode() {
    setBusy("override")

    postData("stations/overridecode", {},
        function() {
            setCode("");
            setOverride(true);
        },
        function(error, status) {
            alert("Error getting override code")
        },
        function() {
            setBusy("");
        }
    ); 
  }

  function onOverride() {
    setBusy("override")

    postData("stations/override", { 
            code
        },
        function() {
            setCode("");
            setScanned(true);
        },
        function(error, status) {
            alert("Invalid code")
        },
        function() {
            setBusy("");
        }
    );    
  }

  return (
      <>
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <div>{props.task.job_title}</div>
            <div><small>{props.task.tasktype}</small></div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loading ? 
                <Loading />
                :
                <Task>
                    {props.station.name == "Printing" && 
                        <div>
                            <img src={storage.root + data.task.job_preview_url} style={{display: "block", margin: "0px auto", maxHeight: "100px", maxWidth: "250px"}} />
                        </div>
                    }
                    {data.task.status_name == "pending" ?
                        <Warnings>
                            <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />{' '}
                            This task is <strong>waiting</strong> on task <strong>{data.task.dependent_task_name}</strong> to 
                            be completed before it will become active.
                        </Warnings>
                    :
                        <>
                            { data.notes.length > 0 &&
                                <Notes>
                                {data.notes.map(note => (
                                    <div key={note.id} className={"priority" + note.priority}>
                                        <div>
                                            <FontAwesomeIcon icon={faStickyNote} fixedWidth />{' '}
                                            {note.notes}
                                        </div>
                                        <Button 
                                            size="sm"   
                                            disabled={notesRead.indexOf(note.id) != -1}                               
                                            variant={notesRead.indexOf(note.id) != -1 ? "success":"outline-secondary"}
                                            onClick={() => setNotesRead(produce(draft => {draft.push(note.id);}))}
                                        >
                                            <FontAwesomeIcon icon={faEye} />
                                            &nbsp;
                                            Mark Read
                                        </Button>
                                    </div>
                                ))}
                                </Notes>
                            }
                            { data.warnings.length > 0 &&
                                <Warnings>
                                {data.warnings.map((warning,index) => (
                                    <div key={index}>
                                        <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />{' '}
                                        {warning}
                                    </div>
                                ))}
                                </Warnings>
                            }

                            {(notesRead.length >= data.notes.length) ? 
                                <>
                                    <StationEmployees
                                        workers={data.workers}
                                        selected={data.task.assigned_user_id ? {
                                            id: data.task.assigned_user_id
                                        } : null}
                                        onWorker={(worker) => {
                                            onWorker(worker);
                                        }}
                                    />

                                    { data.task.status_name == "active" ?
                                        <>
                                        {data.task.dependent_qa_ind == 1 ?
                                            <Button onClick={onBeginInspection} size="lg" variant="success">
                                                <FontAwesomeIcon icon={faSearch} />{' '}
                                                Being Inspection
                                                &nbsp;
                                                <small>
                                                    of { data.task.dependent_task_name }
                                                </small>
                                            </Button>
                                        :
                                            <>
                                                {!scanned && 
                                                    <>
                                                        <ScanPrompt
                                                            task={data.task}
                                                            error={error}
                                                        />
                                                    </>
                                                }
                                                <Button onClick={onBeginProduction} size="lg" variant={scanned ? "success":"outline-secondary"} disabled={!scanned}>
                                                    <FontAwesomeIcon icon={faWrench} />{' '}
                                                    Begin Production
                                                </Button>
                                            </>
                                        }
                                        </>
                                    : data.task.status_name == "paused" ?
                                        <>
                                            {!scanned && 
                                                <ScanPrompt
                                                    task={data.task}
                                                    error={error}
                                                />
                                            }
                                            <Button onClick={onContinue} size="lg" variant={scanned ? "outline-success":"outline-secondary"} disabled={!scanned}>
                                                <FontAwesomeIcon icon={faWrench} />{' '}
                                                Continue Production
                                            </Button>
                                        </>
                                    : data.task.status_name == "problem" ?
                                        <>
                                            <ProblemPrompt>
                                                <p>Enter description of problem:</p>

                                                <InputGroup className="mb-2">
                                                    <FormControl 
                                                        as="textarea" 
                                                        name="problem" 
                                                        rows={3}
                                                        placeholder="Enter description of problem..."
                                                        value={data.task.problem} 
                                                        onBlur={onSaveProblem}
                                                        onChange={(e) => { 
                                                            e.persist();

                                                            setData(produce(draft => {
                                                                draft.task.problem = e.target.value;
                                                            }));
                                                        }}
                                                    />
                                                    <InputGroup.Append>
                                                        <Button onClick={onSaveProblem} variant="outline-secondary">
                                                            <FontAwesomeIcon icon={busy == "problem" ? faSpinner:faSave} spin={busy == "problem"} />
                                                        </Button>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </ProblemPrompt>

                                            {!scanned && 
                                                <ScanPrompt
                                                    task={data.task}
                                                    error={error}
                                                />
                                            }
                                            <Button onClick={onContinue} size="lg" variant={scanned ? "outline-success":"outline-secondary"} disabled={!scanned}>
                                                <FontAwesomeIcon icon={faWrench} />{' '}
                                                Continue Production
                                            </Button>
                                        </>
                                    : data.task.status_name == "inprogress" ?
                                        <>
                                            <Button onClick={onFinished} size="lg" variant="success">
                                                <FontAwesomeIcon icon={faCheck} />{' '}
                                                Finished
                                            </Button>
                                            <Button onClick={onPauseBreak} size="lg" variant="outline-primary">
                                                <FontAwesomeIcon icon={faHourglassStart} />{' '}
                                                Pause for Break
                                            </Button>
                                            <Button onClick={onPauseProblem} size="lg" variant="outline-warning">
                                                <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                                                Pause for Problem
                                            </Button>
                                        </>
                                    : data.task.status_name == "qa" ?
                                        <>
                                            {!scanned && 
                                                <ScanPrompt
                                                    task={data.task}
                                                    error={error}
                                                />
                                            }
                                            <Button onClick={onBeginProduction} size="lg" variant={scanned ? "success":"outline-secondary"} disabled={!scanned}>
                                                <FontAwesomeIcon icon={faWrench} />{' '}
                                                Begin Production
                                            </Button>
                                            <Button onClick={onPauseProblem} size="lg" variant="outline-warning">
                                                <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                                                QC Problem
                                            </Button>
                                        </>
                                    :
                                        null
                                    }  

                                    {error == "noworker" &&
                                        <Error>Select an employee to continue.</Error>
                                    }
                                </>
                            :
                                <Error>Please <strong>read and acknowledge</strong> each job note.</Error>
                            }
                        </>
                    }
                </Task>
            }
        </Modal.Body>
        <Modal.Footer style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
            {override ? 
                <InputGroup style={{width: "auto"}}>
                    <FormControl 
                        as="input" 
                        name="code" 
                        value={code}    
                        onChange={(e) => { 
                            e.persist();

                            setCode(e.target.value);
                        }}                    
                    />
                    <InputGroup.Append>
                        <Button onClick={onOverride} disabled={!code || code.length == 0} variant="outline-secondary">
                            <FontAwesomeIcon icon={busy == "override" ? faSpinner:faUserTie} spin={busy == "override"} style={{marginRight:"4px"}} />
                            Override Task
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            :
                <div>
                    <Button onClick={onOverrideCode} variant="outline-secondary" style={{marginRight:"2px"}}>
                        <FontAwesomeIcon icon={busy == "override" ? faSpinner:faUserTie} spin={busy == "override"} style={{marginRight:"4px"}} />
                        Get Override Code
                    </Button>
                    <Button onClick={() => setOverride(true)} variant="outline-secondary">
                        Enter Code
                    </Button>
                </div>
            }

            {!isMobile && 
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Cancel
                </Button>
            }
        </Modal.Footer>
      </Modal>
      </>
    )
}

export default StationTaskDrawer;