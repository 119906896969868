import React, { useEffect, useState, useRef } from "react";

import { useWindowDimensions } from "../hooks/useWindowDimensions"

import settings from "../settings"
import { dimensions } from "../settings/settings"
import { getUser } from "../common/services/auth";
import { postExternalData } from "../common/services/server";
import Loading from "../components/loading";

export default function SignBuilderPanel({
    signId,
    interactive,
    onChange
}) {
    const builderRef = useRef();
    const containerRef = useRef();
    const windowDimensions = useWindowDimensions();

    const [sign, setSign] = useState(null);
    const [loading, setLoading] = useState(false);

    function onBuilderMessage(event) {
        console.log("onBuilderMessage", event)
        const message = event.data;
    
        if (message.type == "Ready" && sign) {
            notifyBuilder("Reset");
            notifyBuilder("LoadSign", sign);
            notifyBuilder("User", getUser());        
        }
        else if (message.type === "SignSaved") {
          console.log("builder sign saved", message.data)
          if (onChange)
            onChange(message.data);
        }
    }

    function notifyBuilder(messageType, messageData) {
        console.log("notifyBuilder", messageType)

        if ( builderRef && builderRef.current) {
            let message = {
                type: messageType,
                data: messageData,
            }
            
            builderRef.current.contentWindow.postMessage(message, "*");
        }
        else {
            console.log("builderRef not valid")
        }
    }

    useEffect(() => {
        window.addEventListener("message", onBuilderMessage, false);    
    
        // clean up
        return () => window.removeEventListener("message", onBuilderMessage)
    })       

    useEffect(
        () => {
            if (sign) {
                notifyBuilder("Reset");
                notifyBuilder("LoadSign", sign);
                notifyBuilder("User", getUser());        
            }
        }, 
        [sign]
    );    

    useEffect(
        () => {
            if (signId) {
                setLoading(true);

                postExternalData(settings.SITE_API_URL + "builder/signdata?id=" + signId, {},
                    function(result) {
                        setSign(result);
                    },
                    function(error) {
                        alert("Error loading sign")
                    },
                    function() {
                        setLoading(false);
                    }
                );                
            }
        }, 
        [signId]
    );  

    return (
        <div ref={containerRef}>
            {loading &&
                <Loading />
            }
            <iframe 
                ref={builderRef} 
                src={interactive ? settings.BUILDER_ADMIN_INTERACTIVE_URL : settings.BUILDER_ADMIN_URL} 
                title="Sign Builder" 
                width="100%" 
                style={{
                    visibility: loading ? "hidden":"visible",
                }}                
                height={windowDimensions.height - (containerRef?.current?.offsetTop ?? 0)} 
            />     
        </div>
    );
}
