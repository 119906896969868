import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faPaintBrush } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import TextField from "../components/fields/field-text"
import NumberField from "../components/fields/field-number"

import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'


function StyleDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(
    () => {
      setFormData(JSON.parse(JSON.stringify(props.style)));
    }, 
    [props.style]
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("styles/save?id=" + (props.style.id||0), removeNulls(formData), 
        function(response) {
            props.onSaved(response);
        },
        function(error) {
            alert("An error occured saving the style.");
        },
        function() {
            setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;

    }));
  }

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faPaintBrush} />
                &nbsp;
                {props.style.name}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
                    <TextField 
                        name="name" 
                        label="Name" 
                        required 
                        value={formData.name} 
                        onChange={(e) => handleFormChange(e)} />
                    <TextField 
                        name="code" 
                        label="Code" 
                        required 
                        value={formData.code} 
                        onChange={(e) => handleFormChange(e)} />
                    <NumberField 
                        name="price_modifier" 
                        label="Price Modifier" 
                        required 
                        value={formData.price_modifier} 
                        onChange={(e) => handleFormChange(e)} />
                    <TextField 
                        name="standard_sizes" 
                        label="Standard Size List" 
                        required 
                        value={formData.standard_sizes} 
                        onChange={(e) => handleFormChange(e)} />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonProcessing 
                    processing={isProcessing}
                    onClick={onSave} 
                    variant="outline-success" 
                    caption="Save Style" 
                    icon={faSave} /> 
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default StyleDrawer;