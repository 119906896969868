import React from "react";

import BoardField from "../components/board/board-field"
import BoardColumns from "../components/board/board-columns"

function Panel({
  product,
  products,
  onChange
}) {

  return (
    <>
        <BoardField
            type="input"
            label="Standard Sizes"
            value={product.standard_sizes}
            saveUrl={`products/updateprop?product_id=${product.id}&prop=standard_sizes`}
            onSaved={onChange}
        />    

        <BoardField
            type="number"
            label="Default Size"
            value={product.default_size}
            saveUrl={`products/updateprop?product_id=${product.id}&prop=default_size`}
            onSaved={onChange}
        />            

        <BoardColumns>
            <BoardField
                type="number"
                label="Min Size"
                value={product.min_size}
                saveUrl={`products/updateprop?product_id=${product.id}&prop=min_size`}
                onSaved={onChange}
            />
            <BoardField
                type="number"
                label="Max Size"
                value={product.max_size}
                saveUrl={`products/updateprop?product_id=${product.id}&prop=max_size`}
                onSaved={onChange}
            />              
        </BoardColumns>
        <BoardColumns>
            <BoardField
                type="number"
                label="Min Size (admins)"
                value={product.min_size_admin}
                saveUrl={`products/updateprop?product_id=${product.id}&prop=min_size_admin`}
                onSaved={onChange}
            />
            <BoardField
                type="number"
                label="Max Size (admins)"
                value={product.max_size_admin}
                saveUrl={`products/updateprop?product_id=${product.id}&prop=max_size_admin`}
                onSaved={onChange}
            />              
        </BoardColumns>

        <BoardColumns>
            <BoardField
                type="number"
                label="Material Min Size"
                value={product.material_min}
                saveUrl={`products/updateprop?product_id=${product.id}&prop=material_min`}
                onSaved={onChange}
            />
            <BoardField
                type="number"
                label="Material Max Size 1"
                value={product.material_max_1}
                saveUrl={`products/updateprop?product_id=${product.id}&prop=material_max_1`}
                onSaved={onChange}
            />              
        </BoardColumns>


        <BoardColumns>
            <div>&nbsp;</div>
            <BoardField
                type="number"
                label="Material Max Size 2"
                value={product.material_max_2}
                saveUrl={`products/updateprop?product_id=${product.id}&prop=material_max_2`}
                onSaved={onChange}
            />              
        </BoardColumns>

        <BoardColumns>
            <BoardField
                type="list"
                label="Smaller Product"
                value={product.min_size_product_id}
                items={products.filter(p => p.sign_ind == 1 && p.id != product.id)}
                saveUrl={`products/updateprop?product_id=${product.id}&prop=min_size_product_id`}
                onSaved={onChange}
            />
            <BoardField
                type="list"
                label="Larger Product"
                value={product.max_size_product_id}
                items={products.filter(p => p.sign_ind == 1 && p.id != product.id)}
                saveUrl={`products/updateprop?product_id=${product.id}&prop=max_size_product_id`}
                onSaved={onChange}
            />                      
        </BoardColumns>
    </>
  );
}

export default Panel;