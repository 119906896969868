import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import PageLayout from "../components/page-layout";
import PageHeader from "../components/page-header";
import Content from "../components/content"
import CardLayout, { CardLayoutPanel } from "../components/cardlayout";

import SignBuilderPanel from "../panels/panel-sign-builder";
import { Permissions } from "../common/services/auth";
import { useGetSign } from "../api/queries/signs";

export default function InteractiveBuilderPage() {
    let { id } = useParams();

    const signQuery = useGetSign(id);
    const sign = signQuery.data;

    const [cards, setCards] = useState([]);
    
    useEffect(
        () => {
            if (!sign) return;

            // if (cards.length == 0) {
                setCards([
                    {type: "interactivebuilder", id: sign.id},
                    {type: "sign", id: sign.id},
                    {type: "user", id: sign.user_id},
                    {type: "project", id: sign.project_id, hidden: !sign.id || !sign.project_id},
                    {type: "notes", sign_id: sign.id, hidden: !sign.id},
                    {type: "files", sign_id: sign.id, hidden: !sign.id},
                    {type: "emails", sign_id: sign.id, hidden: !sign.id},
                ]);
            // }
        }, 
        [sign]
    );

    return (
        <PageLayout>
            <PageHeader pageTitle={`Interactive Sign Builder`} />        
            <Content loading={signQuery.isLoading} permission={Permissions.Signs} error={signQuery.error} padding={-1}>
                <>
                    {sign ?
                        <CardLayout cards={cards} childrenClassName="!p-0">
                            <CardLayoutPanel className="p-0 grid gap-0">
                                <SignBuilderPanel
                                    signId={sign.id}
                                    interactive={true}
                                />        
                            </CardLayoutPanel>
                        </CardLayout>
                    :
                        null
                    }
                </>
            </Content>
        </PageLayout>
    );
}