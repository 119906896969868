import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import FieldLabel from "./label"
import HelpText from "./help-text"
import ErrorText from "./error-text"

import { postData } from "../../common/services/server"

function FieldCustom(props) {
    let groupStyle = props.groupStyle || {};

    if (props.layout == "horizontal") {
        groupStyle.display = "grid";
        groupStyle.gridTemplateColumns = "125px auto";
        groupStyle.alignItems = "center";
    }

    return (
        <Form.Group as={props.column ? Col:undefined} style={groupStyle}>
            {props.label && 
                <FieldLabel 
                    required={props.required} 
                    bold={props.labelBold}
                    style={props.labelStyle || {marginBottom: "0px"}}
                >
                    {props.label}
                </FieldLabel>
            }
            <div className="border-rounded">
                {props.children}
            </div>
            {(props.instructions && (!props.error || props.error.length == 0)) && <HelpText>{props.instructions}</HelpText>}
            {(props.error && props.error.length > 0) && <ErrorText>{props.error}</ErrorText>}
        </Form.Group>
    )
}

export default FieldCustom