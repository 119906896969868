import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import produce from "immer"
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight, faCreditCard, faDotCircle, faPhone, faShapes, faWrench } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"

import TaskDrawer from "../../drawers/drawer-task"
import TaskLink from "../tasks/task-link"
import Button from "../buttons/button";

import { postData } from "../../common/services/server"
import { getUser } from "../../common/services/auth"
import { formatDate, removeNulls } from "../../helpers/utility"
import { colors } from "../../settings/settings"

const Frame = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 15px;

  table {
      width: 100%;
  }
  th {
      background-color: #eee;
      padding: 6px;
      text-align: center;
      border: 1px solid #ccc;
    }
  td {
        border: 1px solid #ccc;
        padding: 3px;
        vertical-align: top;
        font-size: 80%;
        width: 14.2%;
        
        &.current {
            background-color: ${colors.lightPurple};
        }
        &.weekend {
            background-color: #eee;
        }
        
        .complete {
            text-decoration: line-through;
        }

        h4 {
            margin: 0px;
            padding: 3px;
            font-size: 100%;
            font-weight: normal;
            text-align: center;
        }      

        > div {
            min-height: 50px;

            > div {
                color: black;
                cursor: pointer;

                a {
                    color: black;

                    &:hover {
                        text-decoration: none;
                    }                      
                }
            }
        }
  }
`;

const Header = styled.h3`
    font-size: 14px;
    background-color: #c0c0c0;
    text-align: center;
    margin: 0px;
    padding: 10px;
`;

function getDaysOfMonth(date) {
    var numberOfDays = moment(date).daysInMonth();
    var daysArray = [];
    for (var i = 1; i <= numberOfDays; i++) {
        daysArray.push(i);
    }
    return daysArray;
}

// Get number of days from previous month overlapping current month
function getPreviousMonthOverlappingDays(date) {
    var firstWeekDay = moment(date).startOf('month').weekday();
    var prevMonthDays = [];
    for (var i = 1; i <= firstWeekDay; i++) {
        prevMonthDays.push('');
    }
    return prevMonthDays;
}

// Get number of days from previous month overlapping current month
function getNextMonthOverlappingDays(date) {
    var lastWeekDay = moment(date).endOf('month').weekday();
    var nextMonthDays = [];
    var howManyNextDays = 6 - lastWeekDay;
    for (var i = 1; i <= howManyNextDays; i += 1) {
        nextMonthDays.push('');
    }
    return nextMonthDays;
}

function initWeeksDay() {
    return [0, 1, 2, 3, 4, 5, 6].map(function (el, index) {
        return moment().weekday(index).format('ddd').charAt(0);
    });
}

function CalendarMonth(props) {    
    const user = getUser();
    
    const today = moment(new Date()).startOf('day');
    const [dayDate, setDayDate] = useState(today.toDate());
    const [calendar, setCalendar] = useState([]);

    const [schedule, setSchedule] = useState([]);
    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(
        () => {
            setLoading(true);
            
            postData("calendar/data", removeNulls({
                user_id: props.userId || null,
                start_date: moment(dayDate).startOf("month").format("YYYY-MM-DD"),
                end_date: moment(dayDate).endOf("month").format("YYYY-MM-DD"),
                assigned_user_id: user.id
            }),
                function(result) {
                    setSchedule(result);
                },
                function(error) {
                    window.alert("Error loading calendar")
                },
                function() {
                    setLoading(false);
                }
            );

            var datePositionMap = {};
            var monthDays = getPreviousMonthOverlappingDays(dayDate).concat(getDaysOfMonth(dayDate)).concat(getNextMonthOverlappingDays(dayDate));
            var weekDays = initWeeksDay();
            var weeks = [];
        
            for (var j = 0, rowId = 1; j < monthDays.length; j = j + 7) {
                var week = monthDays.slice(j, j + 7);
                week.forEach(function (day, colId) {
                    datePositionMap[day] = {row: rowId, col: colId};
                });
                weeks.push(week.map(function (d) {
                    return d === '' ? d : moment(dayDate).set('date', d).startOf('day');
                }));
                rowId++;
            }
            
            setCalendar(weeks);
        }, 
        [dayDate, props.calendarPrefs, props.userId]
    );

    function onPrevMonth() {
        setDayDate(moment(dayDate).subtract(1, 'months').toDate())
    }
    function onNextMonth() {
        setDayDate(moment(dayDate).add(1, 'months').toDate())
    }

    return (
        <>
            <Frame>
                <Header>
                    {moment(dayDate).format("MMMM YYYY")}
                </Header>
                <table>
                    <thead>
                        <tr>
                            <th>SUN</th>
                            <th>MON</th>
                            <th>TUE</th>
                            <th>WED</th>
                            <th>THR</th>
                            <th>FRI</th>
                            <th>SAT</th>
                        </tr>
                    </thead>
                    <tbody>
                    {calendar.map((week,index) => (
                        <tr key={index}>
                            {week.map((day,index) => (
                                <td key={index} className={day ? day.isSame(today) ? "current": day.day() == 0 || day.day() == 6 ? "weekend" : "" : "none"}>
                                    {day ? 
                                            <>
                                                <h4>{day.format("D")}</h4>
                                                <div>
                                                {schedule.filter(s => s.day == day.format("D")).map(item => (
                                                    <div key={item.title} className={item.status ? item.status.toLowerCase():""}>
                                                        {item.type == "task" ? 
                                                            <TaskLink 
                                                                task={item} 
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSelected({task: item})
                                                                }}
                                                            />
                                                        :
                                                            <Link to={item.url}>
                                                                <FontAwesomeIcon icon={item.type == "signs" ? faShapes : item.type == "orders" ? faCreditCard : item.type == "job" ? faWrench : item.type == "call" ? faPhone : faDotCircle} fixedWidth /> {' '}
                                                                {item.title}
                                                            </Link>
                                                        }
                                                    </div>
                                                ))}                                                    
                                                </div>
                                            </>
                                        : 
                                            null
                                    }
                                </td>
                            ))}                            
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Frame>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <Button onClick={onPrevMonth} variant="outline-secondary" size="sm">
                    <FontAwesomeIcon icon={faChevronLeft} />
                    {' '}
                    Prev Month
                </Button>
                <Button onClick={onNextMonth} variant="outline-secondary" size="sm">
                    Next Month
                    {' '}
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>

            {(selected && selected.task) && 
                <TaskDrawer 
                    show={true}
                    task={{id: selected.task.url}} 
                    onSaved={(taskData) => {
                        setSchedule(produce(draft => {
                            const index = draft.findIndex(i => i.type=="task" && i.url == selected.task.url);

                            if (index != -1) {
                                draft[index].title = taskData.task;
                                draft[index].date = taskData.due_date;
                            }
                        }));
                    }} 
                    onDeleted={() => {
                        setSchedule(produce(draft => {
                            const index = draft.findIndex(i => i.type=="task" && i.url == selected.task.url);
                
                            if (index != -1) {
                                draft.splice(index, 1)
                            }
                        }));
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } /> 
            }            
        </>
    );
}

export default CalendarMonth