import React from "react";

import ResourcesPanel from "./panel-resources";

function Panel({
  product,
  onChange
}) {
  return (
    <ResourcesPanel
      type="product"
      type_id={product.id}
      resources={product.resources}
      onChange={onChange}
    />
  );
}

export default Panel;