import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import produce from "immer"
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faPlug } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import ColorBox from "../components/color-box";
import SelectField from "../components/fields/field-select"

import { postData } from "../common/services/server"

const Colors = styled.table`
    width: 100%;
    thead tr th {
        background-color: #e0e0e0;
        padding: 5px;
    }
    tbody tr td {
        padding: 5px;
    }
`

function PartColorsDrawer(props) {
  const [loading, setLoading] = useState(false);

  const [systems, setSystems] = useState([])
  const [systemId, setSystemId] = useState(null);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    if (systemId) {
        setLoading(true);

        postData("parts/allcolors", { colorsystem_id: systemId, part_id: props.part.id }, 
            function(results) {
                setColors(results);
            },
            function(error) {
                alert("An error occured loading the part colors.");
            },
            function() {
                setLoading(false);
            }
        );
    }

}, [systemId]);

 
  function onColorChange(e) {
    console.log("color changed", e.target.value, e.target.checked)
    const colorId = e.target.value;
    const checked = e.target.checked;
    let selected = [];

    setColors(produce(draft => {
        const color = draft.find(c => c.id == colorId);

        color.selected_ind = checked ? 1 : 0;
        color.upcharge_ind = 1; // default 

        selected = draft.filter(s => s.selected_ind == 1).map(s => s.name);
    }));

    postData("colors/togglepart", {
            part_id: props.part.id,
            color_id: colorId,
            checked_ind: checked ? 1 : 0
        }, 
        function(response) {
            props.onChanged(selected.length)
        },
        function(error) {
            alert("An error occured updating the part.");
        },
        function() {
        }
    );
  }

  function onDecorationTypeChange(color, type) {
    console.log("part color decoration", color.name, type)

    setColors(produce(draft => {
        const clr = draft.find(c => c.id == color.id);

        clr.decoration_type = type
    }));

    postData("colors/partupdate", {
            part_id: props.part.id,
            color_id: color.id,
            decoration_type: type
        }, 
        function(response) {

        },
        function(error) {
            alert("An error occured updating the part color.");
        },
        function() {
        }
    );
  }

  function onEditListOrder(color) {
    console.log("part color edit list order", color.name)

    setColors(produce(draft => {
        const clr = draft.find(c => c.id == color.id);

        clr.edit_listorder = true
    }));
  }

  function onListOrderChange(color, listOrder) {
    console.log("part color list order", color.name, listOrder)

    setColors(produce(draft => {
        const clr = draft.find(c => c.id == color.id);

        clr.list_order = listOrder
    }));

    postData("colors/partorderupdate", {
            part_id: props.part.id,
            color_id: color.id,
            list_order: listOrder
        }, 
        function(response) {

        },
        function(error) {
            alert("An error occured updating the part color.");
        },
        function() {
        }
    );
  }

  function onUpchargeChange(e) {
    const colorId = e.target.value;
    const checked = e.target.checked;

    setColors(produce(draft => {
        const clr = draft.find(c => c.id == colorId);

        clr.upcharge_ind = checked ? 1:0
    }));

    postData("colors/upchargeupdate", {
            part_id: props.part.id,
            color_id: colorId,
            upcharge_ind: checked ? 1:0
        }, 
        function(response) {

        },
        function(error) {
            alert("An error occured updating the part color.");
        },
        function() {
        }
    );
  }

  function onAllDecorationTypeChange(type) {
    setColors(produce(draft => {
        for (let color of draft) {
            color.decoration_type = type
        }
    }));

    postData("colors/partsystemupdate", {
            part_id: props.part.id,
            colorsystem_id: systemId,
            decoration_type: type
        }, 
        function(response) {

        },
        function(error) {
            alert("An error occured updating the part colors.");
        },
        function() {
        }
    );
  }

  function onAllColorsChange(e) {
    const checked = e.target.checked;

    setColors(produce(draft => {
        for (let color of draft) {
            color.selected_ind = checked ? 1 : 0;
        }
    }));

    const selected = checked ? colors.length : 0;

    postData("colors/togglesystempart", {
            part_id: props.part.id,
            colorsystem_id: systemId,
            checked_ind: checked ? 1 : 0
        }, 
        function(response) {
            props.onChanged(selected)
        },
        function(error) {
            alert("An error occured updating the part.");
        },
        function() {
        }
    );    
  }

  function onAllUpchargeChange(e) {
    const checked = e.target.checked;

    setColors(produce(draft => {
        for (let color of draft) {
            color.upcharge_ind = checked ? 1 : 0;
        }
    }));

    postData("colors/partupchargeupdate", {
            part_id: props.part.id,
            colorsystem_id: systemId,
            upcharge_ind: checked ? 1 : 0
        }, 
        function(response) {

        },
        function(error) {
            alert("An error occured updating the part colors.");
        },
        function() {
        }
    );   
  }

  const selectedCount = colors.filter(c => c.selected_ind == 1).length;
  const allSelected = selectedCount == colors.length;
  const allUpcharge = colors.filter(c => c.upcharge_ind == 1).length == colors.length;

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faPlug} />
                &nbsp;
                {props.part.title} Colors
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectField 
                    name="colorsystem_id" 
                    label="Color System" 
                    url={"colors/systems?part_counts_ind=1&part_id=" + props.part.id}
                    value={systemId} 
                    labelField="title"
                    onData={(systems) => {
                        let data = systems.map(s => { return {...s, title: s.name + " ("+ s.parts_count+" of " + s.colors_count + ")"}})

                        return data;
                    }}
                    onChange={(e) => {
                        setSystemId(e.target.value);
                    }} />

                { loading ? 
                    <Loading />
                :
                (systemId && colors.length == 0) ?
                    <p style={{color:"red"}}>No colors found.</p>
                : (colors.length > 0) ?
                    <Colors>
                        <thead>
                            <tr>
                                <td>
                                    <Form.Check 
                                        name="allcolors_id"
                                        checked={allSelected}
                                        inline 
                                        label="All Colors" 
                                        type="checkbox" 
                                        onChange={onAllColorsChange}
                                    />
                                </td>
                                <td align="right">
                                    <FormControl 
                                        as="select" 
                                        name="alldecoration_type" 
                                        style={{
                                            fontSize: "12px",
                                            marginBottom: "5px"
                                        }}
                                        onChange={(e) => onAllDecorationTypeChange(e.target.value)}
                                    >
                                        <option value=""></option>
                                        <option value="unpainted">Unpainted</option>
                                        <option value="coil">Coil</option>  
                                        <option value="paint">Paint</option>  
                                        <option value="plastic">Plastic</option>
                                        <option value="overlay">Overlay</option>
                                        <option value="print">Print</option>
                                    </FormControl>
                                </td>
                                <td align="right">
                                    <Form.Check 
                                        name="allupcharge_ind"
                                        checked={allUpcharge}
                                        inline 
                                        label="All" 
                                        type="checkbox" 
                                        onChange={onAllUpchargeChange}
                                    />                                    
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>Color</th>
                                <th>Decoration Type</th>
                                <th align="right">Upcharge</th>
                                <th>Ordering</th>
                            </tr>
                        </thead>
                        <tbody>
                        {colors.map(color => (
                            <tr key={color.id}>
                                <td>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <ColorBox color={color} />
                                        <Form.Check 
                                            name="color_id"
                                            value={color.id}
                                            checked={color.selected_ind == 1}
                                            inline 
                                            label={color.name} 
                                            type="checkbox" 
                                            onChange={onColorChange}
                                        />
                                    </div>
                                </td>
                                <td>
                                    {color.selected_ind == 1 && 
                                        <FormControl 
                                            as="select" 
                                            name="decoration_type" 
                                            style={{
                                                fontSize: "12px",
                                                borderColor: color.decoration_type && color.decoration_type.length ? "unset":"red"
                                            }}
                                            value={color.decoration_type}
                                            onChange={(e) => onDecorationTypeChange(color, e.target.value)}
                                        >
                                            <option value=""></option>
                                            <option value="unpainted">Unpainted</option>
                                            <option value="coil">Coil</option>  
                                            <option value="paint">Paint</option>  
                                            <option value="plastic">Plastic</option>
                                            <option value="overlay">Overlay</option>
                                            <option value="vinyl">Vinyl</option>
                                            <option value="print">Print</option>
                                        </FormControl>
                                    }
                                </td>
                                <td align="right">
                                    {color.selected_ind == 1 && 
                                        <Form.Check 
                                            name="upcharge_ind"
                                            value={color.id}
                                            checked={color.upcharge_ind == 1}
                                            inline 
                                            label={color.upcharge_ind == 1 ? "Yes":"No"} 
                                            type="checkbox" 
                                            onChange={onUpchargeChange}
                                        />                                    
                                    }
                                </td>
                                <td align="right">
                                    {(color.selected_ind == 1 && color.edit_listorder) ? 
                                        <FormControl 
                                            as="select" 
                                            name="list_order" 
                                            style={{
                                                fontSize: "12px",
                                                borderColor: !color.list_order ? "red":"unset"
                                            }}
                                            value={color.list_order}
                                            onChange={(e) => onListOrderChange(color, e.target.value)}
                                        >
                                            <option value="0"></option>

                                            {[...Array(selectedCount)].map((x, i) =>
                                                <option key={i+1} value={i+1}>{i+1}</option>
                                            )}                                            
                                        </FormControl>
                                    : (color.selected_ind == 1) ?
                                        <>
                                            {color.list_order}{' '}
                                            <FontAwesomeIcon icon={faPencilAlt}  onClick={(e) => onEditListOrder(color)} style={{cursor: "pointer"}} />
                                        </>
                                    :
                                        null
                                    } 
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Colors>
                :
                    null
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default PartColorsDrawer;