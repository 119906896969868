import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faSave } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { getJsonData, postData } from "../common/services/server"

import settings from "../settings"

function CallTemplateDrawer(props) {
  const formRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [data, setData] = useState({ triggers: [] });
  const [formData, setFormData] = useState(props.template);
  const [validated, setValidated] = useState(false);

  useEffect(
    () => {
      setIsLoading(true);

      let source = getJsonData(
        settings.API_URL + 'triggers/data',
        function(data) {
          setData(data);
        },
        function(error, status) {
          window.alert("Error loading call template data.")
        },
        function() {
          setIsLoading(false);
        }
      );

      return () => {
        if (source) source.cancel();
      };
    }, 
    []
  );

  useEffect(() => {
    console.log("setting template data", props.template)
    if (props.template) {
      setFormData(props.template);
    }
  }, [props.template]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("email/template/save", formData, 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the call template.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    console.log("handleFormChange", event.target.name, event.target.value)
    formData[event.target.name] = event.target.value;
    setFormData(formData);
  }

  var intervalOptions = [];
  for (var i=1; i<=30; i++) {
    intervalOptions.push(<option key={i} value={i}>{i}</option>);
  }

  function getTriggerDelayType(delay) {
    if (delay && delay.length)
      return delay.replace(/[0-9]/g, '');
    else 
      return "days";
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" centered scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPhone} />
            &nbsp;
            Call Template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>

            <Form.Group>
              <Form.Label>Call Name</Form.Label>
              <FormControl as="input" name="name" defaultValue={props.template.name} required onChange={(e) => handleFormChange(e)}/>
            </Form.Group>

            <Form.Group>
              <Form.Label>Call After</Form.Label>
              <FormControl as="select" name="trigger_id" defaultValue={props.template.trigger_id} required onChange={(e) => handleFormChange(e)}>
                  <option value=""></option>
                  {data.triggers.map(trigger => (
                      <option key={trigger.id} value={trigger.id}>{trigger.title}</option>
                  ))}
              </FormControl>
            </Form.Group>

            <Form.Group>
              <Form.Label>Call Delay</Form.Label>
              <Form.Row>
                <Col>
                  <FormControl as="select" name="trigger_delay_amount" defaultValue={props.template.trigger_delay_amount} required onChange={(e) => handleFormChange(e)}>
                    {intervalOptions}
                  </FormControl>
                </Col>
                <Col>
                  <FormControl as="select" name="trigger_delay_type" defaultValue={getTriggerDelayType(props.template.trigger_delay)} required onChange={(e) => handleFormChange(e)}>
                    <option value="minutes">Minutes</option>
                    <option value="hours">Hours</option>
                    <option value="days">Days</option>
                  </FormControl>
                </Col>
              </Form.Row>
            </Form.Group>

            <Form.Group>
              <Form.Label>Cancel Triggers</Form.Label>
              <FormControl as="select" name="trigger_cancel_id" defaultValue={props.template.trigger_cancel_id} onChange={(e) => handleFormChange(e)}>
                  <option value=""></option>
                  {data.triggers.map(trigger => (
                      <option key={trigger.id} value={trigger.id}>{trigger.title}</option>
                  ))}
              </FormControl>
              <FormControl as="select" name="trigger_cancel1_id" defaultValue={props.template.trigger_cancel1_id} onChange={(e) => handleFormChange(e)}>
                  <option value=""></option>
                  {data.triggers.map(trigger => (
                      <option key={trigger.id} value={trigger.id}>{trigger.title}</option>
                  ))}
              </FormControl>
              <FormControl as="select" name="trigger_cancel2_id" defaultValue={props.template.trigger_cancel2_id} onChange={(e) => handleFormChange(e)}>
                  <option value=""></option>
                  {data.triggers.map(trigger => (
                      <option key={trigger.id} value={trigger.id}>{trigger.title}</option>
                  ))}
              </FormControl>
              <FormControl as="select" name="trigger_cancel3_id" defaultValue={props.template.trigger_cancel3_id} onChange={(e) => handleFormChange(e)}>
                  <option value=""></option>
                  {data.triggers.map(trigger => (
                      <option key={trigger.id} value={trigger.id}>{trigger.title}</option>
                  ))}
              </FormControl>
            </Form.Group>

            <Form.Group>
              <Form.Label>Call Script / Notes</Form.Label>
              <FormControl as="textarea" name="body" rows={10} defaultValue={props.template.body} onChange={(e) => handleFormChange(e)}/>
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          { !isLoading && 
            <ButtonProcessing 
              processing={isProcessing}
              onClick={onSave} 
              variant="outline-success" 
              caption="Save Template" 
              icon={faSave} /> 
          }
          <Button variant="outline-secondary" onClick={props.onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default CallTemplateDrawer;