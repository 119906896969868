import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTicketAlt, faTrash, faListAlt } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import ListField from "../components/fields/field-list"
import TextField from "../components/fields/field-text"

import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'
import HelpText from "../components/fields/help-text";
import FieldLabel from "../components/fields/label";
import { getMwoStickerTypes } from "../helpers/mwo";

const Contents = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    
    th {
        background-color: #eee;
        border: 1px solid #ccc;
        padding: 3px 5px;
        font-weight: normal;
        width: 55px;
    }
    td {
        border: 1px solid #ccc;
        padding: 2px;
    }
    .form-group {
        margin: 0px;
    }
`
const Parts = styled.div`
    > div {
        padding: 1px 1px 1px 4px;
        border: 1px solid white;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.checked {
            border-color: #ccc;
        }
    }
`

function ProductMwoDrawer(props) {
  const formRef = useRef(null);

  let blankMwo = {
    part_ids: []
  }

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [tasks, setTasks] = useState([]);
  const [validated, setValidated] = useState(false);
  const [selected, setSelected] = useState(null);
  const [colDataItems, setColDataItems] = useState([
            {value: "[product_code]", label: "Product Code", description: "The MWO code for the product. DM, RW, etc."},
            {value: "[code]", label: "Shape Code", description: "The code of the shape.  SQUARE, RECT, etc."},
            {value: "[size_style]", label: "Shape Size/Style", stickers: "shapes", description: "The size and typestyle of the shape."},
            {value: "[size]", label: "Shape Size", stickers: "shapes", description: "The height and width of the shape in inches."},
            {value: "[shape_index]", label: "Shape Index", stickers: "shapes", description: "The index position of the shape when there are mulitple."},
            {value: "[part_color]", label: "Part Color", description: "The color of the part associated with the MWO"},
            //{value: "[part_finish]", label: "Part Finish", description: ""},
            {value: "[part_no]", label: "Part Number", description: "The number of the part. Only working for Clips currently."},
            {value: "[halo_color]", label: "Part Halo Color", description: "The color associated with the Back LEDs part."},
            {value: "[part_type]", label: "Part Type", description: "Type type of the part. Face, Back, Auto, etc."},
            {value: "[face_side_color]", label: "Face & Side Color", description: "The colors of the Face and Side parts."},
            {value: "[part_size]", label: "Part Size", description: "The size of the part, if defined.  5in, 3in, etc."},
            {value: "[part_title]", label: "Part Title", description: "The title or name of the part."},            
            {value: "[part_material]", label: "Part Material", description: "The material of the part, if defined."},            
            {value: "[part_quantity]", label: "Part Quantity", description: "The quantity of the part, if defined in the job prefab."},            
            {value: "[led]", label: "LED", stickers: "shapes", description: "The color/type of the LEDs. BLUE-SM, WHT-P4, etc."},
            {value: "[led_type]", label: "LED Type", stickers: "shapes", description: "The name/type of the LEDs.  White, PE-4W7K, etc."},
            {value: "[mount]", label: "Mount", stickers: "shapes", description: "The title of the mount part for the shape."},
            {value: "[psu]", label: "PSU", description: "The color of the power supply that the shape is associated with."},
            {value: "[psu_watts]", label: "PSU Watts", description: "Hardcoded to 12VDC.  If not custom then adds total watts."},
            {value: "[psu_input]", label: "PSU Input", stickers: "powersets", description: "Volts and Amps for the shape's power supply.  Hardcoded value for Deco."},
            {value: "[psu_output]", label: "PSU Output", stickers: "powersets", description: "Volts, Watts and Amps output for the shape's power supply.  None for Deco."},
            {value: "[prewire]", label: "Prewire", description: "Prewire sticker strings as defined in the PreFab for the job."},
            {value: "[powerstrip_tcount]", label: "Powerstrip T Count", description: "Powerstrip T count."},
            {value: "[powerstrip]", label: "Powerstrip", description: "Powerstrip T string with extensions.  TTTTTTTTTTTT, etc."},
            {value: "[powerset_name]", label: "Powerset Name", stickers: "powersets", description: "Powerset name.  Powerset 1, Powerset 2, Raceway 1, etc."},            
            {value: "[powerset_type]", label: "Powerset Type", stickers: "powersets", description: "Master, slave, etc.  Includes parent when applicable."},            
            {value: "[powerset_size]", label: "Powerset Size", stickers: "powersets", description: "Height x Width"},            
            {value: "[powerset_color]", label: "Powerset Color", stickers: "powersets", description: "Color of the powerset."},            
            {value: "[powerset_index]", label: "Powerset Index", stickers: "powersets", description: "Index of the powerset when there are multiple."},
            {value: "[powerset_extension]", label: "Powerset Extension", stickers: "powersets", description: "Size of the powerset extension."},
            {value: "[racewayext_line3]", label: "Raceway Ext Line 3", stickers: "powersets", description: "If master and power then 'Input 120 Volts AC'. Otherwize cannot be used along message."},
            {value: "[racewayext_line4]", label: "Raceway Ext Line 4", stickers: "powersets", description: "If master then Amps, 60 Hertz, QC.  When slave then shows parent."},
            {value: "[racewaybuild_line3]", label: "Raceway Build Line 3", stickers: "powersets", description: "If master then hardcoded to Power Supplies."},
            {value: "[racewaybuild_line4]", label: "Raceway Build Line 4", stickers: "powersets", description: "If master then hardcoded to Master Only.  When slave then slave to parent name."},
            {value: "[thickness]", label: "Material Thickness", description: "If back and >= 36 or bigger then ACM. If other part and size >= 36 then .06 else .04 "},   
            {value: "[cabinet_part]", label: "Cabinet Parts", description: "Return Left, Return Right, Return Top or Return Bottom"}, 
            {value: "[retainer_part]", label: "Cabinet Retainer Parts", description: "Retainer Horizontal, Retainer Horizontal, Retainer Vertical, Retainer Vertical, Retainer Horizontal, Retainer Horizontal, Retainer Vertical or Retainer Vertical"}, 
            {value: "[cabinet_color]", label: "Cabinet Color", description: "Color of the cabinet Side part."}, 
            {value: "[cabinet_face_drawing]", label: "Cabinet Face Drawing", description: "Image showing left, right, top or bottom of the cabinet face."}, 
            {value: "[cabinet_powerside]", label: "Cabinet Power Side", description: "Indicates which side of the cabinet the power comes out."},    
            {value: "[cabinet_bracket_type]", label: "Cabinet Bracket Type", description: "Small Exterior Plate, Large Interior Plate, Internal Clip/Brckt, etc."},
            {value: "[cabinet_bracket_count]", label: "Cabinet Bracket Count", description: "Hardcoded to 4 ct or 8 ct based on number of cabinet faces."},
            {value: "[cabinet_bracket_drawing]", label: "Cabinet Bracket Drawing", description: "Image showing bracket drawing based on number of cabinet faces."},    
            {value: "[cabinet_bracket_thickness]", label: "Cabinet Bracket Thickness", description: "Hardcoded to .09 or .25 based on number of cabinet faces."},    
            {value: "[cabinet_led_leg]", label: "Cabinet LED Leg", description: "Show LEG #"},
            {value: "[accessory_size]", label: "Accessory Size", description: "Size of the accessory when available.  4ft, 6ft, etc."},
            {value: "[accessory_type]", label: "Accessory Type", description: "Extension, Bracket, etc."},
            {value: "[accessory_title]", label: "Accessory Title", description: "Title of the accessory.  EZLit Plug-n-Play Extensions, etc."},
            {value: "[accessory_color]", label: "Accessory Color", description: "Color of the accessory when available."},
            {value: "[date]", label: "Print Date", description: "Current date."},
            {value: "[job_note]", label: "Job Note", description: "Notes associated with the job."},
            {value: "[sm_logo]", label: "SM Logo", description: "SignMonkey logo."},
            //{value: "[manual_index]", label: "Manual Index", description: ""},            
            {value: "[text]", label:"[Custom Text]", description: "Custom hardcoded text that will always be on the sticker"}
        ]);

  useEffect(
    () => {
        console.log(props.mwo);
        console.log(props.product.jobtasks);

        let mwo = props.mwo;
        let data = {
            ...mwo,
            part_ids: mwo.parts ? mwo.parts.split(",").map(p => parseInt(p)) : [],
            shippingmethod_ids: mwo.shipping_methods ? mwo.shipping_methods.split(",").map(m => parseInt(m)) : [],
            task_ids: props.product.jobtasks ? props.product.jobtasks.filter(t => t.mwo_id && t.mwo_id == mwo.id).map(t => t.id) : []
        }

        if (mwo) {
            if (data.part_ids && mwo.part_modes) {
                const partModes = mwo.part_modes.split(",")

                for (var i=0; i<data.part_ids.length; i++) {
                    if (partModes.length >= i)
                        data["partmode" + data.part_ids[i]] = partModes[i];
                }
            }

            // do we have any custom mwo sticker text?
            const customText = [];

            for (let i=1; i<=4; i++) {
                for (let j=1; j<=3; j++) {
                    const text = mwo["row" + i + "col" + j];

                    if (text && text.substring(0,1) != "[")
                        customText.push(text);
                }
            }

            if (customText.length) {
                setColDataItems(produce(draft => {
                    for (const text of customText) {
                        draft.push({value: text, label: text})
                    }
                }));
            }
        } 
        else data = blankMwo;

        console.log("formData", data);

        setFormData(JSON.parse(JSON.stringify(data)));

        // tasks for this product that are using this mwo
        if (props.product.jobtasks) {
            setTasks(props.product.jobtasks.filter(t => (t.mwo && t.mwo.name == props.mwo.name) || (t.mwo_id && t.mwo_id == props.mwo.id)));
        }
    }, 
    []
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("mwos/save?id=" + (props.mwo.id||0), removeNulls({
             ...formData,
             parts: formData.part_ids.join(","),
             shippingMethods: formData.shippingmethod_ids.join(","),
             tasks: formData.task_ids.join(",")
        }), 
        function(response) {
            props.onSaved(response);
        },
        function(error) {
            alert("An error occured saving the mwo.");
        },
        function() {
            setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  function handleNewColDataItem(event, row, col) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;
    let value = event.target.value;

    setColDataItems(produce(draft => {        
        draft.unshift({value, label: value})
      }));
    setFormData(produce(draft => {        
      draft["row" + row + "col" + col] = value;
    }));
  }

  const rows = [];

  for (let i=1; i<=formData.rows; i++) {
      rows.push(i)
  }

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title style={{width:"100%"}}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <div>
                        <FontAwesomeIcon icon={faTicketAlt} />
                        &nbsp;
                        {props.mwo.id ? "Update":"Create"} MWO
                    </div>
                    {props.onDelete && 
                        <ButtonProcessing 
                            processing={isProcessing}
                            variant="outline-danger" 
                            caption="Delete MWO" 
                            icon={faTrash} 
                            onClick={() => {
                                if (window.confirm("Are you sure you want to delete this MWO?")) {
                                    setIsProcessing(true);

                                    postData("products/removemwo", removeNulls({
                                           product_id: props.product.id,
                                           mwo_id: props.mwo.id
                                      }), 
                                      function(response) {
                                          props.onDelete();
                                      },
                                      function(error) {
                                          alert("An error occured deleting the mwo.");
                                      },
                                      function() {
                                          setIsProcessing(false);
                                      }
                                    );
                                }
                            }}                            
                        />
                    }
                </div>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(tasks.length > 0) &&
                    <div style={{marginBottom: "10px", backgroundColor: "#fdeec2", padding: "8px", borderRadius: "5px", fontSize: "90%"}}>
                        <div>
                            Warning: This MWO is being used for the following product job tasks:
                        </div>
                        {tasks.map((task) => (
                            <div key={task.id}>
                                &nbsp;
                                &bull;
                                &nbsp;
                                {task.name}
                            </div>
                        ))}
                    </div>
                }

                <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
                    <TextField 
                        name="title" 
                        label="Title" 
                        required
                        value={formData.title} 
                        onChange={(e) => handleFormChange(e)} 
                    />

                    <ListField 
                        name="type" 
                        label="Type" 
                        value={formData.type} 
                        required
                        list={[
                            {value: "temporary", label: "Temporary"},
                            {value: "permanent", label: "Permanent"},
                        ]}
                        onChange={(e) => handleFormChange(e)} 
                    />

                    <ListField 
                        name="stickers" 
                        label="Stickers" 
                        value={formData.stickers} 
                        required
                        list={getMwoStickerTypes()}
                        onChange={(e) => handleFormChange(e)} 
                    />

                    <ListField 
                        name="rows" 
                        label="Rows" 
                        value={formData.rows} 
                        required
                        list={[
                            {value: "1", label: "1"},
                            {value: "2", label: "2"},
                            {value: "3", label: "3"},
                            {value: "4", label: "4"},
                        ]}
                        onChange={(e) => handleFormChange(e)} 
                    />

                    <div>
                        Contents
                    </div>
                    <Contents>
                        <tbody>
                            {rows.map(row => (
                                <tr key={row}>
                                    <th style={{whiteSpace: "nowrap"}}>
                                        Row {row}
                                    </th>
                                    <td>
                                        <ListField 
                                            name={"row" + row + "cols"}
                                            size="sm"
                                            value={formData["row" + row + "cols"] || ""} 
                                            required
                                            list={[
                                                {value: "1", label: "1 Col"},
                                                {value: "2", label: "2 Cols"},
                                                {value: "3", label: "3 Cols"},
                                            ]}
                                            onChange={(e) => handleFormChange(e)} 
                                        />
                                    </td>
                                    {(formData["row" + row + "cols"] >= 1) && 
                                        <td style={{whiteSpace: "nowrap"}}>
                                            <InputGroup size="sm" style={{flexWrap: "nowrap"}}>
                                                <ListField 
                                                    name={"row" + row + "col1"}
                                                    size="sm"
                                                    value={formData["row" + row + "col1"] || ""} 
                                                    list={colDataItems.filter(di => !di.stickers || formData.stickers.indexOf(di.stickers) != -1)}
                                                    onChange={(e) => handleFormChange(e)} 
                                                />
                                                <InputGroup.Append>
                                                    <Button size="sm" variant="outline-secondary" onClick={() => setSelected({mode:"placeholder", row, col: 1})}>
                                                        <FontAwesomeIcon icon={faListAlt} />
                                                    </Button>
                                                </InputGroup.Append>
                                            </InputGroup>                                                
                                            {formData["row" + row + "col1"] == "[text]" && 
                                                <TextField 
                                                    name={"row" + row + "col1text"}
                                                    size="sm"
                                                    value={formData["row" + row + "col1text"] || ""} 
                                                    onChange={(e) => handleFormChange(e)} 
                                                    onEnter={(e) => handleNewColDataItem(e, row, 1)}
                                                    onBlur={(e) => handleNewColDataItem(e, row, 1)}
                                                />
                                            }
                                        </td>
                                    }
                                    {(formData["row" + row + "cols"] >= 2) && 
                                        <td style={{whiteSpace: "nowrap"}}>
                                            <InputGroup size="sm" style={{flexWrap: "nowrap"}}>
                                                <ListField 
                                                    name={"row" + row + "col2"}
                                                    size="sm"
                                                    value={formData["row" + row + "col2"] || ""} 
                                                    list={colDataItems.filter(di => !di.stickers || formData.stickers.indexOf(di.stickers) != -1)}
                                                    onChange={(e) => handleFormChange(e)} 
                                                />
                                                <InputGroup.Append>
                                                    <Button size="sm" variant="outline-secondary" onClick={() => setSelected({mode:"placeholder", row, col: 2})}>
                                                        <FontAwesomeIcon icon={faListAlt} />
                                                    </Button>
                                                </InputGroup.Append>
                                            </InputGroup>                                                
                                            {formData["row" + row + "col2"] == "[text]" && 
                                                <TextField 
                                                    name={"row" + row + "col2text"}
                                                    size="sm"
                                                    value={formData["row" + row + "col2text"] || ""} 
                                                    onChange={(e) => handleFormChange(e)} 
                                                    onEnter={(e) => handleNewColDataItem(e, row, 2)}
                                                    onBlur={(e) => handleNewColDataItem(e, row, 2)}
                                                />
                                            }
                                        </td>
                                    }
                                    {(formData["row" + row + "cols"] >= 3) && 
                                        <td style={{whiteSpace: "nowrap"}}>
                                            <InputGroup size="sm" style={{flexWrap: "nowrap"}}>
                                                <ListField 
                                                    name={"row" + row + "col3"}
                                                    size="sm"
                                                    value={formData["row" + row + "col3"] || ""} 
                                                    list={colDataItems.filter(di => !di.stickers || formData.stickers.indexOf(di.stickers) != -1)}
                                                    onChange={(e) => handleFormChange(e)} 
                                                />
                                                <InputGroup.Append>
                                                    <Button size="sm" variant="outline-secondary" onClick={() => setSelected({mode:"placeholder", row, col: 3})}>
                                                        <FontAwesomeIcon icon={faListAlt} />
                                                    </Button>
                                                </InputGroup.Append>
                                            </InputGroup>                                                
                                            {formData["row" + row + "col3"] == "[text]" && 
                                                <TextField 
                                                    name={"row" + row + "col3text"}
                                                    size="sm"
                                                    value={formData["row" + row + "col3text"] ||""} 
                                                    onChange={(e) => handleFormChange(e)} 
                                                    onEnter={(e) => handleNewColDataItem(e, row, 3)}
                                                    onBlur={(e) => handleNewColDataItem(e, row, 3)}
                                                />
                                            }
                                        </td>
                                    }
                                </tr>
                            ))}                                                  
                        </tbody>
                    </Contents>

                    <FieldLabel>MWO Parts</FieldLabel>
                    <HelpText>The parts on the product that control when the sticker is valid and the data used on it.</HelpText>
                    <Parts>
                        {props.product.parts.map(part => {
                            const checked = formData && formData.part_ids && formData.part_ids.indexOf(part.id) != -1;

                            return (
                            <div key={part.id} className={checked ? "checked":""}>
                                <Form.Check 
                                    name={part.name}
                                    value={part.id}
                                    checked={checked}
                                    label={part.type + " - " + part.title} 
                                    type="checkbox" 
                                    onChange={(e) => {
                                        e.persist();

                                        const value = parseInt(e.target.value);

                                        console.log(e.target.checked, e.target.value);
                                        console.log(formData);

                                        setFormData(produce(draft => {
                                            if (!draft.part_ids)
                                                draft.part_ids = [];

                                            if (e.target.checked)
                                                draft.part_ids.push(value);
                                            else {
                                                draft.part_ids.splice(draft.part_ids.findIndex(v => v == value));
                                            }
                                        }))
                                    }}
                                />
                                {checked && 
                                    <ListField 
                                        name={"partmode" + part.id}
                                        value={formData["partmode" + part.id] || "or"} 
                                        groupStyle={{margin: "0px"}}
                                        size="sm"
                                        list={[
                                            {value: "or", label: "OR"},
                                            {value: "and", label: "AND"},
                                        ]}
                                        onChange={(e) => handleFormChange(e)} 
                                    />
                                }
                            </div>
                            )
                        })}                        
                    </Parts>

                    <br />

                    <FieldLabel>MWO Shipping Methods</FieldLabel>
                    <HelpText>The shipping methods that control when the sticker is valid.</HelpText>
                    <Parts>
                        {props.product.shippingmethods.map(method => {
                            const checked = formData && formData.shippingmethod_ids && formData.shippingmethod_ids.indexOf(method.id) != -1;

                            return (
                            <div key={method.id} className={checked ? "checked":""}>
                                <Form.Check 
                                    name={method.name}
                                    value={method.id}
                                    checked={checked}
                                    label={method.title} 
                                    type="checkbox" 
                                    onChange={(e) => {
                                        e.persist();

                                        const value = parseInt(e.target.value);

                                        console.log(e.target.checked, e.target.value);
                                        console.log(formData);

                                        setFormData(produce(draft => {
                                            if (!draft.shippingmethod_ids)
                                                draft.shippingmethod_ids = [];

                                            if (e.target.checked)
                                                draft.shippingmethod_ids.push(value);
                                            else {
                                                draft.shippingmethod_ids.splice(draft.shippingmethod_ids.findIndex(v => v == value));
                                            }
                                        }))
                                    }}
                                />
                            </div>
                            )
                        })}                        
                    </Parts>

                    <br />

                    <FieldLabel>MWO Tasks</FieldLabel>
                    <HelpText>The tasks on the product that use this MWO sticker.</HelpText>
                    <Parts>
                        {props.product.jobtasks.map(task => {
                            const checked = formData && formData.task_ids && formData.task_ids.indexOf(task.id) != -1;

                            return (
                            <div key={task.id} className={checked ? "checked":""}>
                                <Form.Check 
                                    name={task.name}
                                    value={task.id}
                                    checked={checked}
                                    label={task.name} 
                                    type="checkbox" 
                                    onChange={(e) => {
                                        e.persist();

                                        const value = parseInt(e.target.value);


                                        setFormData(produce(draft => {
                                            if (!draft.task_ids)
                                                draft.task_ids = [];

                                            if (e.target.checked)
                                                draft.task_ids.push(value);
                                            else {
                                                draft.task_ids.splice(draft.task_ids.findIndex(v => v == value));
                                            }
                                        }))
                                    }}
                                />
                            </div>
                            )
                        })}                        
                    </Parts>                    
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonProcessing 
                    processing={isProcessing}
                    onClick={onSave} 
                    variant="outline-success" 
                    caption="Save MWO" 
                    icon={faSave} /> 
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

        {(selected && selected.mode == "placeholder") && 
            <Modal show={true} onHide={() => setSelected(null)} scrollable backdropClassName="drawer" dialogClassName="drawer">
                <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faListAlt} style={{marginRight:"5px"}} />
                    MWO Sticker Parameters
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table style={{
                        fontSize: "80%"
                    }}>
                    <tbody>
                    {colDataItems.filter(di => (!di.stickers || formData.stickers.indexOf(di.stickers) != -1) && di.value.indexOf("[") != -1).map(sticker => (
                        <tr key={sticker}>
                            <td>
                                <Button size="sm" variant="outline-primary" onClick={() => {
                                    handleFormChange({
                                        target: {
                                            name: "row" + selected.row + "col" + selected.col,
                                            value: sticker.value
                                        }
                                    });

                                    setSelected(null);                                    
                                }}>
                                    {sticker.value}
                                </Button>
                            </td>
                            <td>{sticker.description}</td>
                        </tr>
                    ))}    
                    </tbody>
                    </table>                                           
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={() => setSelected(null)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>            
        }
      </>
    )
}

export default ProductMwoDrawer;