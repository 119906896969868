import React, { useContext, useEffect, useRef } from "react";
import Pusher from "pusher-js";

export const PusherContext = React.createContext();

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: 'us2'
})      

export const PusherProvider = (props) => {
    return (
        <PusherContext.Provider value={pusher}>
            {props.children}
        </PusherContext.Provider>
    )
}