import React, { useState, useEffect } from "react";

import produce from "immer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTag, faBan, faTruck, faTrash, faPencilAlt, faTrashRestore, faHandshake } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    CurrencyCell, 
    NumberCell,
    DateCell, 
    ButtonCell,
    UserCell,
    SortHeaderCell,
    SortTypes,
    EditActionsCell 
} from "../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import { useParams, useHistory } from "react-router-dom";

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"
import CouponOrdersDrawer from "../drawers/drawer-couponorders"
import CouponProductsDrawer from "../drawers/drawer-couponproducts"
import CouponDrawer from "../drawers/drawer-coupon"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"
import { useBodyClass } from "../hooks/useBodyClass"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { dimensions } from "../settings/settings"
import { useLocalStorage } from "../hooks/useLocalStorage"
import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import PageLayout from "../components/page-layout";

function CouponsPage(props) {
    const history = useHistory();
    let { tab } = useParams();

    if (!tab) tab = "active";

    const windowDimensions = useWindowDimensions();

    const [coupons, setCoupons] = useState([]);
    const [sort, setSort] = useState({column: "id", dir: SortTypes.DESC});
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    const [busyRow, setBusyRow] = useState(null);

    useBodyClass(`noscroll`);

    useEffect(
        () => {
            setIsLoading(true);

            postData("coupons/data", { 
                status: tab,
                sort: sort.column,
                sortdir: sort.dir
            },
                function(result) {
                    for (let coupon of result) {
                        coupon.product_ids = coupon.product_ids ? coupon.product_ids.split(",").map(p => Number(p)) : [];
                        coupon.products = coupon.products ? coupon.products.split(",") : [];
                    }

                    setCoupons(result);
                },
                function(error) {
                    alert("Error loading purchase codes")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        [sort, tab]
    );

    function onCouponChanged(coupon) {
        const rowIndex = coupons.indexOf(selected.coupon)

        setCoupons(produce(draft => {
            if (rowIndex == -1)
                draft.push(coupon);
            else
                draft[rowIndex] = coupon;
        }));
    }

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }

    function onItems(coupon) {
        if (coupon.id)
            setSelected({mode:"items", coupon});
    }
    function onProducts(coupon) {
        if (coupon.id)
            setSelected({mode:"products", coupon});
    }

    function onCreate() {
        console.log("create")
        setSelected({mode:"form", coupon:{}});
    }
    function onAction(action, coupon) {
        console.log("action", action, coupon)

        if (action == "edit")
            setSelected({mode:"form", coupon});
        else if (action == "delete") {
            setBusyRow(coupon);

            postData("coupons/delete", { 
                coupon_id: coupon.id
            },
                function(result) {
                    setCoupons(produce(draft => {
                        draft.splice(draft.findIndex(c => c.id == coupon.id), 1);
                    }));
                },
                function(error) {
                    alert("Error deleting coupon")
                },
                function() {
                    setBusyRow(null);
                }
            );
        }
        else if (action == "restore") {
            setBusyRow(coupon);

            postData("coupons/restore", { 
                coupon_id: coupon.id
            },
                function(result) {
                    setCoupons(produce(draft => {
                        draft.splice(draft.findIndex(c => c.id == coupon.id), 1);
                    }));
                },
                function(error) {
                    alert("Error restoring coupon")
                },
                function() {
                    setBusyRow(null);
                }
            );
        }
    }

    return (
        <PageLayout>
            <PageHeader 
                title="Purchase Code Manager"
                help="coupons"
                tab={tab}
                tabUrl={"/coupons/{tab}"}
                tabs={[
                    {name:"active", title:"Active", icon:faTag},
                    {name:"partners", title:"Partners", icon:faHandshake},
                    {name:"freeshipping", title:"Free Shipping", icon:faTruck},
                    {name:"expired", title:"Expired", icon:faBan},
                    {name:"deleted", title:"Deleted", icon:faTrash, permission: Permissions.PurchaseCodes, action: Actions.Restore},
                ]}
                onTab={(tab) => {
                    history.push("/coupons/" + tab);
                }}
            >
                {userHasPermission("Purchase Codes", "Create") &&
                    <Button onClick={onCreate} variant="outline-success" size="sm">
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        Create Code
                    </Button>
                }
            </PageHeader>
            <Content permission="Purchase Codes">
            <>
                {
                    isLoading 
                ?
                    <Loading />
                :    
                    <Table
                        rowHeight={50}
                        rowsCount={coupons.length}
                        width={windowDimensions.width - dimensions.dataTableWidthOffset}
                        height={windowDimensions.height - dimensions.dataTableHeightOffset}
                        headerHeight={50}
                        touchScrollEnabled={true}
                    >
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    Code
                                </SortHeaderCell>
                            }
                            columnKey="code"
                            fixed={!isMobile}
                            cell={<TextCell data={coupons} />}
                            width={225}
                        />
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    Type
                                </SortHeaderCell>
                            }
                            columnKey="type"
                            cell={<TextCell data={coupons} />}
                            width={225}
                        />
                        <Column
                            header={<Cell>User</Cell>}
                            
                            columnKey="user_name"
                            cell={({rowIndex, ...props}) => (
                                <UserCell 
                                    userId={coupons[rowIndex].user_id}
                                    userName={coupons[rowIndex].user_name}
                                    userEmail={coupons[rowIndex].user_email}
                                    onUser={() => history.push("/users/user/" + coupons[rowIndex].user_id)}
                                />
                            )}
                            width={225}
                        />    
                        <Column
                            header={<Cell>Products</Cell>}
                            columnKey="products_count"
                            cell={
                                <ButtonCell 
                                    data={coupons} 
                                    isDisabled={(coupon) => coupon.type != 'Percentage Off'}
                                    onClick={(order) => onProducts(order)} 
                                />
                            }
                            width={90}
                        />                                         
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    Start Date
                                </SortHeaderCell>
                            }
                            columnKey="start_date"
                            cell={<DateCell data={coupons} />}
                            width={115}
                        />
                        <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    End Date
                                </SortHeaderCell>
                            }
                            columnKey="end_date"
                            cell={<DateCell data={coupons} />}
                            width={115}
                        />
                       <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    Max Uses
                                </SortHeaderCell>
                            }
                            columnKey="max_uses"
                            cell={<TextCell data={coupons} />}
                            width={100}
                        />
                       <Column
                            header={
                                <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                    Amount
                                </SortHeaderCell>
                            }
                            columnKey="amount"
                            cell={({rowIndex, ...props}) => (
                                coupons[rowIndex].type == "Percentage Off" ? 
                                    <NumberCell data={coupons} rowIndex={rowIndex} {...props} percentage />
                                :
                                    <CurrencyCell data={coupons} rowIndex={rowIndex} {...props} />
                            )}
                            width={100}
                        />
                       <Column
                            header={<Cell>Sign</Cell>}
                            columnKey="sign_id"
                            cell={({rowIndex, ...props}) => (
                                <Cell>
                                    {coupons[rowIndex].sign_id ? "#" + coupons[rowIndex].sign_id : ""}
                                    {coupons[rowIndex].sign_version ? <div><small>Version {coupons[rowIndex].sign_version}</small></div> : ""}
                                </Cell>
                            )}
                            width={100}
                        />                        
                        <Column
                            header={<Cell>Used</Cell>}
                            columnKey="used_count"
                            cell={<ButtonCell data={coupons} onClick={(order) => onItems(order)} />}
                            width={70}
                        />
                        <Column
                            header={<Cell>Actions</Cell>}
                            cell={
                                <EditActionsCell 
                                    data={coupons} 
                                    actions={[
                                        {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, isVisible: () => userHasPermission("Purchase Codes", "Edit")},
                                        {   
                                            action: tab != "deleted" ? "delete":"restore", 
                                            title: tab != "deleted" ? "Delete":"Restore", 
                                            variant: tab != "deleted" ? "danger":"success", 
                                            icon: tab != "deleted" ? faTrash : faTrashRestore,
                                            isVisible: () => userHasPermission("Purchase Codes", tab != "deleted" ? "Delete":"Restore")
                                        },
                                    ]}
                                    busy={busyRow}
                                    onClick={onAction} 
                                />
                            }
                            align="center"
                            width={110}
                        />
                    </Table>
                }
            </>
            </Content>
            { selected && selected.mode == "items" && 
                <CouponOrdersDrawer 
                    coupon={selected.coupon} 
                    onChange={onCouponChanged} 
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { selected && selected.mode == "products" && 
                <CouponProductsDrawer 
                    coupon={selected.coupon} 
                    onChanged={(productCount, productIds) => {
                        const rowIndex = coupons.findIndex(c => c.id == selected.coupon.id)

                        console.log({rowIndex})
                        console.log(productCount, productIds)

                        if (rowIndex != -1) {
                            setCoupons(produce(draft => {
                                draft[rowIndex].products_count = productCount;
                                draft[rowIndex].product_ids = productIds;
                            }));                        
                        }
                    }} 
                    onHide={ () => setSelected(null) } 
                /> 
            }            
            { selected && selected.mode == "form" && 
                <CouponDrawer 
                    coupon={selected.coupon} 
                    show={true}
                    onSaved={(coupon) => {
                        onCouponChanged(coupon);
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
        </PageLayout>
    );
}

export default CouponsPage;