import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutate } from '../api';

export const useCreateSignLogoSign = (signLogoId, options={}) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data) => {
            return mutate("signlogos/sign/create", {
                id: signLogoId,
                ...data
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["signlogos"]);
        },    
        ...options
  })
};

export const useRestoreSignLogoImage = (signLogoId, options={}) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return mutate("signlogos/logo/restore", {
                id: signLogoId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["signlogos"]);
        },    
        ...options
  })
};

export const useDeleteSignLogo = (options={}) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (signLogoId) => {
            return mutate("signlogos/logo/delete", {
                id: signLogoId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["signlogos"]);
        }, 
        ...options   
  })
};

export const useSetSignLogoMeasurement = (signLogoId, options={}) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (measurementData) => {
            return mutate("signlogos/logo/setmeasurement", {
                id: signLogoId,
                ...measurementData
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["signlogos"]);
        },    
  })
};

export const useAddSignLogoItem = (signLogoId, options={}) => {  
    return useMutation({
        mutationFn: (data) => {
            return mutate("signlogos/logo/additem", {
                id: signLogoId,
                ...data
            })
        },
        ...options 
  })
};

export const useUpdateSignLogoItem = (signLogoId, options={}) => {  
    return useMutation({
        mutationFn: (data) => {
            return mutate("signlogos/logo/updateitem", data)
        },
        ...options 
  })
};
export const useUpdateSignLogoItemProp = (signLogoId, prop, options={}) => {  
    return useMutation({
        mutationFn: (data) => {
            return mutate("signlogos/logo/updateitemprop", {
                signlogo_id: signLogoId,
                prop,
                ...data
            })
        },
        ...options 
  })
};
export const useUpdateSignLogoItems = (signLogoId, options={}) => {  
    return useMutation({
        mutationFn: (data) => {
            return mutate("signlogos/logo/updateitems", {items: data})
        },
        ...options 
  })
};

export const useDeleteSignLogoItem = (signLogoId, options={}) => {  
    return useMutation({
        mutationFn: (id) => {
            return mutate("signlogos/logo/deleteitem", {id})
        },
        ...options 
  })
};