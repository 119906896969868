import React, { useState, useEffect } from "react"
import produce from "immer";
import { Link } from "react-router-dom";

import Loading from "../components/loading";
import BoardFields from "../components/board/board-fields";
import BoardField from "../components/board/board-field";
import BoardPills from "../components/board/board-pills";
import BoardPill from "../components/board/board-pill";
import { postData } from "../common/services/server";
import { formatDateTime, formatPrice } from "../helpers/utility";
import ZoomableImage from "../components/image-zoomable";
import storage from "../settings/storage";

function OrderDetailsPanel({
    orderId,
    onOrderChanged
}) {
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);

    useEffect(
        () => {
            setLoading(true);

            postData("orders/orderdata", { 
                id: orderId
            },
                function(result) {
                    setOrder(result);
                },
                function(error) {
                    window.alert("Error loading order data")
                },
                function() {
                    setLoading(false);
                }
            );            
        }, 
        [orderId]
    );

    function orderChanged(prop, orderData) {
        console.log("orderChanged", prop, orderData)
        setOrder(produce(draft => {
            for (const key of Object.keys(orderData)) {
                draft[key] = orderData[key];
            }
        }));

        if (onOrderChanged)
            onOrderChanged(orderData);
    }

    return (
        <>
            {loading ? 
                <Loading />
            : order ?
                <div className="grid gap-1">
                    <BoardField
                        label="ID"
                        type="children">
                        <Link to={"/orders/order/" + order.id} className="text-blue-500">
                            #{order.id}
                        </Link>
                    </BoardField>
                    <BoardField
                        label="Order Date"
                        type="label"
                        value={formatDateTime(order.order_date)}
                    />
                    <BoardField
                        label="Total"
                        type="label"
                        value={formatPrice(order.total)}
                    />
                    <BoardField
                        label="User"
                        type="children"
                    >
                        <div>{order.user_name}</div>
                        <div>{order.user_email}</div>
                        <div>{order.user_phoneno}</div>
                    </BoardField>
                    <BoardField
                        label="Shipping"
                        type="children"
                    >
                        {order.ship_company && <div>{order.ship_company}</div>}
                        {order.ship_name && <div>{order.ship_name}</div>}
                        <div>{order.ship_address}</div>
                        <div>{order.ship_city}, {order.ship_state} {order.ship_zipcode}</div>
                    </BoardField>
                    <BoardField
                        label="Billing"
                        type="children"
                    >
                        {order.billing_company && <div>{order.billing_company}</div>}
                        {order.billing_name && <div>{order.billing_name}</div>}
                        <div>{order.billing_address}</div>
                        <div>{order.billing_city}, {order.billing_state} {order.billing_zipcode}</div>
                    </BoardField>   

                    <BoardFields label="Order Items">
                        {order.items.map(item => (
                            <BoardField key={item.id} type="children">
                                <div className="grid grid-cols-[60px,1fr] items-center gap-2">
                                    <ZoomableImage url={storage.root + item.preview_url} maxHeight={"25px"} maxWidth={"50px"} />
                                    {item.title}
                                </div>
                            </BoardField>
                        ))}
                    </BoardFields>                   
                </div>
            :
              null
            }
        </>
    );
}

export default OrderDetailsPanel