import React, { useState, useEffect } from "react";
import { postData } from "../../common/services/server"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import Loading from "../../components/loading"
import JobSchedule from "../../components/job-schedule"
import JobTasksDrawer from "../../drawers/drawer-jobtasks"
import Button from "../../components/buttons/button";

function JobTasksPanel({job, ...props}) {
    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]);
    const [selected, setSelected] = useState({});

    useEffect(
      () => {
        console.log("panel job tasks useEffect")
          setIsLoading(true);

          postData("jobs/tasks", {
                id: job.id
            }, 
            function(response) {
              setTasks(response)
            },
            function(error) {
              alert("An error occured loading task data.");
            },
            function() {
              setIsLoading(false);
            }
          );

          postData("users/production", {},
              function(result) {
                  setUsers(result);
              },
              function(error) {
                  alert("Error loading employees")
              }
          );          
      }, 
      []
    );

    function onTaskChanged(data) {
      console.log("onTaskChanged", data)
      setTasks(data.tasks);
      props.onJobChange(data)
    }

    return (
      <>
      { isLoading ? 
        <Loading />
      :
        <div>
          <JobSchedule
            job={{...job, tasks}} 
            expanded={true}
            users={users}
            onJobChange={(data) => {
              if (data.tasks)
                setTasks(data.tasks);
                
              props.onJobChange(data);
            }}
            onTaskChanged={onTaskChanged}
          />

          <div style={{marginTop: "10px"}}>
            <Button 
              variant="outline-secondary"
              onClick={() => setSelected({mode: "tasks"})}
            >
              <FontAwesomeIcon icon={faPencilAlt} />{' '}
              Edit All Tasks
            </Button>
          </div>
        </div>
      }

      { selected.mode == "tasks" && 
        <JobTasksDrawer 
            job={{...job, tasks}}
            users={users}
            show={true}
            onChange={onTaskChanged}
            onHide={ () => setSelected({}) } 
        /> 
      }      
      </>
    );
}

export default JobTasksPanel;