import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlug, faTrash } from "@fortawesome/free-solid-svg-icons";

import Panel from "./panel";
import GroupPreview from "../components/group-preview"
import DrawerPartSelect from "../drawers/part-select";
import ButtonMutate from "../components/buttons/button-mutate";
import { List, ListItem } from "../components/list"

import { useGetJobParts } from "../api/queries/workflowjobs";
import { useWorkflowJobAddPart, useWorkflowJobRemoveGroupPart, useWorkflowJobUpdatePartProp } from "../api/mutations/workflowjobs";
import BoardField from "../components/board/board-field";
import { getIconForPartType } from "../helpers/icons";

export default function JobWorkflowPartsPanel({jobId}) {
    const [addGroup, setAddGroup] = useState(null);
    const [showAllParts, setShowAllParts] = useState(false);

    const groupsQuery = useGetJobParts(jobId);
    const groups = groupsQuery.data;

    const addGroupPart = useWorkflowJobAddPart(jobId, addGroup?.id, {
        onSuccess: () => setAddGroup(null)
    });    
    const removeJobGroupPart = useWorkflowJobRemoveGroupPart(jobId, {
        // onError: () => window.alert("Error updating group")
    });   
    const updatePartProp = useWorkflowJobUpdatePartProp(jobId);

    return (
      <>
        <Panel query={groupsQuery}>
            {groups && 
                <div className="gap-2">
                    <List>
                        {groups.map(group => (
                            <ListItem 
                                key={group.id} 
                                menu={[
                                    {name: "add", caption: "Add Part", icon:faPlug},
                                ]}
                                onMenuItem={(item) => {
                                    if (item.name == "add") {
                                        setAddGroup(group);
                                    }
                                }}
                            >
                                <div className="grid grid-cols-[175px,1fr] items-center">
                                    <div className="text-xs">
                                        <GroupPreview group={group} solid={true} width={100} height={50} />
                                        <div>
                                            {group.product}
                                        </div>
                                        <div>
                                            Size {group.size}
                                        </div>
                                        {group.style_type == "letters" && 
                                            <div>
                                                {group.style}
                                            </div>
                                        }                                         
                                    </div>
                                    {(group.parts.length > 0) ?
                                        <div className="text-sm space-y-1">
                                            {group.parts.map(part => (
                                                <div key={part.id} className="flex justify-between gap-2 border border-gray-300 rounded p-1">
                                                    <div className="flex items-center gap-2">
                                                        <FontAwesomeIcon icon={getIconForPartType(part.type, part.name)} fixedWidth className="text-gray-300" />
                                                        {part.title}
                                                    </div>

                                                    <div className="flex items-center gap-2">
                                                        {part.quantity_ind == 1 && 
                                                            <BoardField
                                                                type="number"
                                                                label="Quantity"
                                                                value={part.quantity || 0}                                                            
                                                                saveMutation={updatePartProp}
                                                                onSave={(value) => {
                                                                    return {
                                                                        jobGroupId: group.id, 
                                                                        partId: part.id, 
                                                                        prop: "quantity", 
                                                                        value                                                                        
                                                                    }
                                                                }}
                                                            />
                                                        }

                                                        <ButtonMutate
                                                            icon={faTrash}
                                                            size="icon"
                                                            variant="light-danger"
                                                            className="!border-none"
                                                            confirmation="Remove this part?"
                                                            mutation={removeJobGroupPart}
                                                            mutationData={{
                                                                jobGroupId: group.id,
                                                                partId: part.id
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    :
                                        null
                                    }
                                </div>
                            </ListItem>
                        ))}
                    </List>
                </div>        
            }
        </Panel>

        {addGroup && 
            <DrawerPartSelect
                filters={{
                    // type: "mount",
                    product_id: showAllParts ? null : addGroup.product_id,
                    exists_jobworkflowgroup_id: addGroup.id
                }}
                itemClassName="mb-1 bg-gray-200"
                itemExistsCheck={(item) => item.exists_ind == 1}
                selectMutation={addGroupPart}
                selectMutationDataProp="id"
                footer={
                    <div className="mt-4">
                        {!showAllParts && 
                            <button
                                className="text-blue-500 hover:underline"
                                onClick={() => setShowAllParts(true)}
                            >
                                Show All Parts
                            </button>
                        }
                    </div>
                }                
                onHide={() => setAddGroup(null)}
            />           
        }                    
      </>
    )
}
