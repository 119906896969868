import { isMobile, isWindows } from "react-device-detect"

export const colors = {
    red: "#dc3545",
    green: "#5cb85c",
    greenText: "white",    
    blue: "#007FFF",
    blueHover: "#026dd8",
    blueText: "white",
    orange: "#ec9715",
    orangeHover: "#de7b1b",
    orangeText: "white",
    orangeLight: "#f7d0a5",
    purple: "#a473f0",

    lightPurple: "#e1ceff",
    lightPurpleHover: "#c1a7ea",
    lightRed: "#fbb9b9",
    lightGreen: "#c9f6d4",
}

export const dimensions = {
    sideBarWidth: isMobile ? 10:55,
    pagePadding: isMobile ? 0:20,
    pageWithTabsPadding: 35,
    siteHeaderHeight: 50,
    headerHeight: 90,
    cardMenuWidth: 260,
    cardPanelHeaderHeight: 40,
    scrollBarWidth: isWindows ? 25:25,
    notificationsSideBarWidth: 55,

    dataTableWidthOffset: 140,
    dataTableHeightOffset: 215,
}

export const google = {
    googleMapsKey: "AIzaSyDQq2Iz71gEA5gXb6HC3mkiBGnB0TvqkKE"
}

export const sizes = {
    mobile: '425px',
    tablet: '768px',
    laptop: '1250px',
    desktop: '2560px'
}

export const devices = {
    mobile: `(max-width: ${sizes.mobile})`,
    tablet: `(max-width: ${sizes.tablet})`,
    laptop: `(max-width: ${sizes.laptop})`,
    desktop: `(max-width: ${sizes.desktop})`
}