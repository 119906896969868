import React, { useState } from "react";

import SelectField from "./field-select";

function ProductSelect(props) {
    const [products, setProducts] = useState([]);

    function onProduct(productId) {
        props.onChange(products.find(p => p.id == productId))
    }

    return (
        <SelectField 
            name={props.name}
            label={props.label}                 
            labelField="title"
            value={props.value} 
            wide
            url={"products/data?" + (props.hasUrl ? "has_url=1":"")}
            onData={(data) => setProducts(data)}
            onChange={(e) => onProduct(e.target.value)} 
            groupStyle={{marginBottom:"0px"}}
        />
    );
  }

export default ProductSelect