import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetFrontEndPartTypes = (options={}) => {
  return useQuery({ 
    queryKey: ["parttypes", "frontend"], 
    queryFn: ({signal}) => query("parts/types/frontend", {}, signal),
    ...options
  });
}

export const useGetPartTypes = (params, options={}) => {
  return useQuery({ 
    queryKey: ["parttypes", params], 
    queryFn: ({signal}) => query("parts/types/list", params, signal),
    ...options
  });
}

export const useGetMaterialParts = (options={}) => {
  return useQuery({ 
    queryKey: ["parts", "materials"], 
    queryFn: ({signal}) => query("parts/materials/list", {}, signal),
    ...options
  });
}

export const useGetParts = (params, options={}) => {
  return useQuery({ 
    queryKey: ["parts", params], 
    queryFn: ({signal}) => query("parts/list", params, signal),
    ...options
  });
}

export const useGetPart = (partId, options={}) => {
  return useQuery({ 
    queryKey: ["parts", "part", partId], 
    queryFn: ({signal}) => query("parts/part/data", {id: partId}, signal),
    ...options
  });
}

export const useGetPartsByType = (params, options={}) => {
  console.log("useGetPartsByType params", params)
  return useQuery({ 
    queryKey: ["parts", params], 
    queryFn: ({signal}) => query("parts/listbytype", params, signal),
    ...options
  });
}