import React, { useState, useEffect } from "react"
import { GoogleMap, Marker, InfoWindow, LoadScript } from '@react-google-maps/api'

import Loading from "./loading"

import { google } from "../settings/settings"

const DEFLAT = 39.8097343;
const DEFLNG = -98.5556199;
const DEFZOOM = 4;

/*
class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true
    const isBrowser = typeof document !== "undefined" // require('@react-google-maps/api/src/utils/isbrowser')
    const isAlreadyLoaded = window.google && window.google.maps && document.querySelector('body.first-hit-completed') // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      // @ts-ignore
      if (window.google && !cleaningUp) {
        console.error("google api is already presented")
        return
      }

      this.isCleaningUp().then(this.injectScript)
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true })
    }
  }
}
*/

const MapComponent = React.memo((props) => {
  const [selected, setSelected] = useState({ coords: null, location: null});

  console.log("MapComponent render")
  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={google.googleMapsKey}
    >
      <GoogleMap
        id='example-map'
        mapContainerStyle={{
          height: "400px",
          width: "100%"
        }}
        zoom={props.zoom}
        center={{ 
          lat: selected.location ? selected.coords.lat : props.coords.lat, 
          lng: selected.location ? selected.coords.lng : props.coords.lng 
        }}
      >
        {props.markers.map((marker, index) => (
            <Marker
              key={index} 
              position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }} 
            />
        ))}
      </GoogleMap>
    </LoadScript> 
  )
})

const Map = React.memo((props) => {
  const [loading, setLoading] = useState(false);
  const [coords, setCoords] = useState({ lat: DEFLAT, lng: DEFLNG});
  const [zoom, setZoom] = useState(DEFZOOM);

  useEffect(
    () => {
        if (props.markers && props.markers.length) {
            const marker = props.markers[0];

            console.log("first marker", marker)
            setCoords({ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude)});
            setZoom(12)
        }
    }, 
    [props.markers]
  );

  async function onSearch() {
    //setLoading(true);

    console.log("search map", props.address);

    /*
    const response = await Geocode.fromAddress(props.address);    
    console.log(response);
    const { lat, lng } = response.results[0].geometry.location;
    console.log(lat, lng);
    setCoords({ lat: lat, lng: lng});
    setZoom(12)
    setMarkers([{latitude: lat, longitude: lng }])
    //setLoading(false);

    if (props.onGeocode) {
        props.onGeocode(lng, lat)
    }
    */
}

  console.log("Map render")

  return (
    <div style={{position: "relative"}}>
        <MapComponent
            coords={coords}
            zoom={zoom}
            markers={props.markers}
            googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" + google.googleMapsKey}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
        />
        {loading &&
            <div style={{position: "absolute", left: "0px", top: "0px", right: "0px"}}>
                <Loading color="black" />
            </div>
        }
    </div>
  )
})

export default Map