import React, { useState, useEffect, useRef } from "react";

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import TextField from "../components/fields/field-text"
import TextAreaField from "../components/fields/field-textarea"
import ListField from "../components/fields/field-list"
import GoogleImage from "../components/google-image"

import { formatDate, removeNulls } from '../helpers/utility'
import { postData } from "../common/services/server"

function ApprovalDrawer(props) {
  const formRef = useRef(null);

  const [busy, setBusy] = useState("");

  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(props.approval)));
  const [validated, setValidated] = useState(false);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setBusy("save");

      postData("approvals/save?id=" + (props.approval.id), removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the approval.");
        },
        function() {
          setBusy("");
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  function onDelete() {
    if (window.confirm("Delete this approval request?")) {
      setBusy("delete");

      postData("approvals/delete", {id: props.approval.id}, 
        function(response) {
          props.onDelete();
        },
        function(error) {
          alert("An error occured deleting the approval.");
        },
        function() {
          setBusy("");
        }
      );
    }
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faEnvelope} />
            &nbsp;
            Art Approval
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
              <div style={{border:"1px solid #ccc", borderRadius: "5px", padding: "10px"}}>
                <GoogleImage 
                  root 
                  src={props.approval.approval_preview_url || props.approval.preview_url} 
                  style={{
                      display: "block",
                      margin: "0px auto",
                      maxWidth: "300px",
                      maxHeight: "125px"
                  }}
                />
              </div>

              <br />

              <TextField 
                  name="requested_date" 
                  label="Date Requested" 
                  labelBold={true}
                  readonly
                  value={formatDate(formData.requested_date)} />
              <TextAreaField 
                  name="notes" 
                  label="Comments" 
                  labelBold={true}
                  value={formData.notes} 
                  onChange={(e) => handleFormChange(e)} />

              <TextField 
                name="response_date" 
                label="Responded Date" 
                labelBold={true}
                readonly
                value={formData.response_date ? formatDate(formData.response_date) : ""} />
              <ListField 
                  name="approved_ind" 
                  label="Approved" 
                  value={formData.approved_ind || "-1"} 
                  list={[
                    {value: "-1", label: "No Response"},
                    {value: "0", label: "NOT Approved"},
                    {value: "1", label: "Approved"},
                    {value: "99", label: "Manual Approval"},
                  ]}
                  onChange={(e) => handleFormChange(e)}
              />
              <TextAreaField 
                name="comments" 
                label="Response Comments" 
                labelBold={true}
                value={formData.comments} 
                onChange={(e) => handleFormChange(e)} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ButtonProcessing 
                processing={busy=="save"}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save" 
                icon={faSave} /> 
            <ButtonProcessing 
                processing={false}
                onClick={props.onEmail} 
                variant="outline-primary" 
                caption="Send Email..." 
                icon={faEnvelope} /> 
            <ButtonProcessing 
                processing={busy=="delete"}
                onClick={onDelete} 
                variant="outline-danger" 
                icon={faTrash} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ApprovalDrawer;