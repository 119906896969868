import React from "react"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Panel from "./panel";
import { useGetOrders } from "../api/queries/orders";
import { formatDateTime, formatPrice } from "../helpers/utility";
import { getIconForType } from "../helpers/icons";

export default function OrdersPanel({
    signId,
}) {
    const ordersQuery = useGetOrders({sign_id: signId});
    const orders = ordersQuery.data;

    return (
        <Panel query={ordersQuery}>
            {orders && 
                <div className="grid gap-1">
                    {orders.map(order => (
                        <Link
                            to={"/orders/order/" + order.id}
                            key={order.id} 
                            className="
                                flex justify-between gap-2 border rounded border-gray-300 p-1
                                no-underline
                                hover:bg-gray-100 hover:no-underline
                            "
                        >
                            <div>
                                <div>Order #{order.id}</div>
                                <div className="text-gray-500 text-sm">
                                    {formatDateTime(order.order_date)} 
                                </div>
                            </div>
                            <div>
                                <div className="flex gap-1 items-center text-sm">
                                    <FontAwesomeIcon icon={getIconForType("user")} />
                                    {order.user_name}
                                </div>
                                <div className="text-green-500 text-right">
                                    {formatPrice(order.total)}
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            }
        </Panel>
    );
}