import React, { useState } from "react";
import clsx from "clsx";

export default function Tooltip({
    content,
    placement = "bottom",
    align = "center",
    className,
    contentClassName,
    delay = 400,
    children
}) {
    let timeout;
    const [active, setActive] = useState(false);
  
    const showTip = () => {
        timeout = setTimeout(() => {
          setActive(true);
        }, delay || 400);
    };
    
    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    return (
        (content) ?
            <div 
                className={clsx(
                    "block relative",
                    className,
                )}
                onMouseEnter={showTip}
                onMouseLeave={hideTip}
            >
                {children}

                {active && (
                    <div 
                        className={clsx(
                            "absolute bg-black/70 text-white text-sm p-2 rounded-md",
                            "max-w-[225px] min-w-[200px]",
                            contentClassName
                        )}
                        style={{
                            left: align=="left" ? "100%": align=="right" ? "-100%" : "50%",
                            transform: 
                                placement == "top" ? "translateX(-50%) translateY(-170%)" : 
                                placement == "left" ? "translateX(-110%) translateY(-70%)" :
                                "translateX(-50%)",
                            zIndex: 888,
                        }}
                    >
                        {content}
                    </div>
                )}            
            </div>
        :
            children 
    );
}