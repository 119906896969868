import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPencilAlt, faSync, faSpinner } from "@fortawesome/free-solid-svg-icons";

import Loading from "../../components/loading"
import GoogleImage from "../../components/google-image"
import { postData } from "../../common/services/server"
import { formatDateTime, formatPrice } from "../../helpers/utility"
import settings from "../../settings"
import { isThisMinute } from "date-fns";

const History = styled.table`
    width: 100%;

    thead {
        th {
            background-color: #eee;
            padding: 4px 8px;
        }
    }
    tbody {
        td {
            padding: 4px 8px;
            border-bottom: 1px solid #eee;

            svg {
                margin-right: 5px;
            }
            img {
                max-width: 125px;
                max-height: 75px;
            }
        }
    }
`

function SignHistoryPanel(props) {
    const { sign } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [history, setHistory] = useState([]);
    const [busy, setBusy] = useState(0)
  
    useEffect(
        () => {
          postData("signs/history", { id: props.sign.id},
            function(result) {
                setHistory(result.map(h => {
                    let data = null;
                    let estimate = 0;
                    
                    try {
                        data = JSON.parse(h.data);

                        console.log(data)
                    }
                    catch (e) {
                        console.log("history parse error", e);
                    }

                    if (data) {
                        if (data.estimate)
                            estimate = parseFloat(data.estimate);
                        else if (data.price_data) {
                            if (data.price_data.estimate)
                                estimate = parseFloat(data.price_data.estimate);
                            else if (data.price_data.cost)
                                estimate = parseFloat(data.price_data.cost.total);
                        }
                        else if (data.cost) {
                            estimate = parseFloat(data.cost.total);
                        }
                    }

                    return {
                        ...h,
                        estimate
                    }
                }));
            },
            function(error) {
                alert("Error loading sign history")
            },
            function() {
              setIsLoading(false);
            }
          );
        }, 
        [props.sign]
    );

    function onRestoreCustomVersion(historyId) {
        if (window.confirm("Are you sure you want to restore this version?")) {
            setBusy(historyId);

            postData("signs/restore", { sign_id: props.sign.id, history_id: historyId},
                function() {
                    props.onSignChange();
                },
                function(error) {
                    alert("Error restoring version.")
                },
                function() {
                    setBusy(0)
                }
            );            
        }
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :   
                <History>
                    <thead>
                        <tr>
                            <th>Action Date</th>
                            <th>Action</th>
                            <th>Data</th>
                            <th>Price</th>
                            <th>User</th>
                            <th>Device</th>
                            <th>IP Address</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                    {history.map(item => (
                        <tr key={item.id}>
                            <td>{formatDateTime(item.action_date)}</td>
                            <td>
                                {item.action}{' '}
                                {(item.action == "Version" || item.action == "CustomVersion") && 
                                    item.action_id
                                }
                            </td>
                            <td>
                                {item.preview_url ? 
                                    <GoogleImage root src={item.preview_url} />
                                : item.action == "CustomVersion" || (item.data && item.data.substring(0,1) == "{") ?
                                    null
                                : 
                                    item.data
                                }
                            </td>
                            <td>
                                {formatPrice(item.estimate || 0)}
                            </td>
                            <td>
                                {item.user_id &&
                                    <>
                                        <FontAwesomeIcon icon={faUser} />
                                        <Link to={"/users/user/" + item.user_id}>
                                            {item.user_name}
                                        </Link>
                                    </>
                                }
                            </td>
                            <td>
                                {item.device && 
                                    <div>{item.device} ({item.os})</div>
                                }
                                {item.browser && 
                                    <div>{item.browser}</div>
                                }
                            </td>
                            <td>{item.ipaddress}</td>
                            <td>
                                {item.action == "Version" && 
                                    <Button 
                                        variant="outline-warning"
                                        size="sm"
                                        onClick={(e) => {window.open(settings.SIGNEDIT_URL + sign.uid + "&version="+item.action_id); e.preventDefault()}} 
                                        href={settings.SIGNEDIT_URL + sign.uid + "&version=" + item.action_id}
                                    >
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                        Load In Builder
                                    </Button>
                                }
                                {item.action == "CustomVersion" && 
                                    <Button 
                                        variant="outline-warning"
                                        size="sm"
                                        onClick={(e) => {onRestoreCustomVersion(item.id); e.preventDefault()}}                                         
                                    >
                                        <FontAwesomeIcon icon={busy == item.id ? faSpinner:faSync} spin={busy == item.id} />
                                        Restore
                                    </Button>
                                }                                
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </History>
            }
        </>
    );
}

export default SignHistoryPanel;