import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect"

import produce from "immer"
import { faCreditCard, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell, 
    ButtonCell,
    CurrencyCell,
    SortHeaderCell,
    SortTypes,
    EditActionsCell 
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import { useHistory } from "react-router-dom";

import Loading from "../../components/loading"
import StateDrawer from "../../drawers/drawer-state"

import { postData } from "../../common/services/server"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { useBodyClass } from "../../hooks/useBodyClass"
import { dimensions } from "../../settings/settings"
import { removeNulls } from "../../helpers/utility"

function StatesPanel(props) {
    const history = useHistory();
    const windowDimensions = useWindowDimensions();
    
    const [states, setStates] = useState([]);
    const [sort, setSort] = useState({column: "abbrev2", dir: SortTypes.ASC});
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(null);

    const [couponBusy, setCouponBusy] = useState(null);
    const [actionBusy, setActionBusy] = useState(null);

    useBodyClass(`noscroll`);

    useEffect(
        () => {
          setIsLoading(true);

          postData("states/data", removeNulls({ 
              ...props.filters,
              sort: sort.column,
              sortdir: sort.dir
          }),
            function(result) {
                setStates(result);
            },
            function(error) {
                alert("Error loading state data")
            },
            function() {
              setIsLoading(false);
            }
          );
        }, 
        [props.filters, sort]
    );

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }
    function onUpcharges(state) {
        if (state.id)
            setSelected({mode:"upcharges", state});
    }
    function onStateChanged(state) {
        setStates(produce(draft => {
            draft[draft.findIndex(s => s.id == state.id)] = state;
        }));
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
                <Table
                    rowHeight={50}
                    rowsCount={states.length}
                    width={windowDimensions.width - dimensions.dataTableWidthOffset}
                    height={windowDimensions.height - dimensions.headerHeight - (props.haveTabs ? dimensions.pageWithTabsPadding : dimensions.pagePadding)*2}
                    headerHeight={50}
                    touchScrollEnabled={true}
                >
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                State
                            </SortHeaderCell>
                        }
                        fixed={!isMobile}
                        columnKey="state"
                        cell={<TextCell data={states} />}
                        width={150}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Abbrev
                            </SortHeaderCell>
                        }
                        columnKey="abbrev2"
                        cell={<TextCell data={states} />}
                        width={90}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Tax Rate
                            </SortHeaderCell>
                        }
                        columnKey="tax_rate"
                        cell={<CurrencyCell data={states} />}
                        width={120}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Freight Surcharge
                            </SortHeaderCell>
                        }
                        columnKey="ship_freight_surcharge"
                        cell={<CurrencyCell data={states} />}
                        width={150}
                    />
                    <Column
                        header={
                            <Cell>
                                Area Upcharges
                            </Cell>
                        }
                        columnKey="upcharge_count"
                        cell={<ButtonCell data={states} onClick={(state) => onUpcharges(state)} />}
                        width={150}
                    />
                    <Column
                        header={<Cell>Actions</Cell>}
                        cell={
                            <EditActionsCell 
                                data={states}  
                                actions={[
                                    {action:"edit", icon:faPencilAlt, title:"Edit", variant:"warning"}
                                ]}
                                busy={actionBusy}
                                onClick={(action, state) => {
                                    setSelected({mode:"edit", state})
                                }}
                            />
                        }
                        align="center"
                        width={75}
                    />
                </Table>
            }
            { selected && selected.mode == "edit" && 
                <StateDrawer 
                    state={selected.state} 
                    show={true}
                    onSaved={(state) => {
                        onStateChanged(state);
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
            { selected && selected.mode == "upcharges" && 
                <StateDrawer 
                    state={selected.state} 
                    show={true}
                    onSaved={(state) => {
                        onStateChanged(state);
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
        </>
    );
}

export default StatesPanel;