import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import produce from "immer"
import styled from "styled-components";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faCreditCard } from '@fortawesome/free-solid-svg-icons'

import { postData } from "../common/services/server";
import { formatDateTime, formatPrice, removeNulls } from "../helpers/utility";

import Button from "../components/buttons/button";
import Loading from "../components/loading";

const Orders = styled.table`
    width: 100%;

    th {
        background-color: #eee;
        padding: 3px 6px;
    }
    td {
        border-bottom: 1px solid #eee;
        padding: 3px 6px;
        font-size: 80%;
    }
`

function OrderDrawer(props) {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    setLoading(true);

    postData("orders/data", removeNulls({ 
        ...props.filters,
        page: 1,
        perPage: 100,
    }),
        function(result) {
            setOrders(result.orders);
        },
        function(error) {
            alert("Error loading orders")
        },
        function() {
            setLoading(false);
        }
    );    
  }, []);

  return (
      <Modal show={props.show} animation={false} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faCreditCard} />
            &nbsp;
            Orders
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loading ? 
                <Loading />
            :
                <Orders>
                    <thead>
                        <tr>
                            <th>Order #</th>
                            <th>User</th>
                            <th>Date Ordered</th>
                            <th align="center">Items</th>
                            <th align="right">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order, index) => (
                            <tr key={index}>
                                <td>
                                    <a href={"/#/orders/order/" + order.id} target="_blank">
                                        {order.id}
                                    </a>
                                </td>
                                <td>
                                    {order.user_name}
                                </td>
                                <td>
                                    {formatDateTime(order.order_date)}
                                </td>
                                <td align="center">
                                    {order.item_count}
                                </td>
                                <td align="right">
                                    {formatPrice(order.total)}
                                </td>
                            </tr>
                        ))}  
                    </tbody>
                </Orders>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default OrderDrawer;