import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faPlug, faCaretUp, faCaretDown, faClone } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import TextAreaField from "../components/fields/field-textarea"
import SelectField from "../components/fields/field-select"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'
import BoardWarning from "../components/board/board-warning";

function PartDrawer(props) {
  const formRef = useRef(null);

  const [busy, setBusy] = useState("");
  const [formData, setFormData] = useState(props.part);
  const [validated, setValidated] = useState(false);
  const [productsExpanded, setProductsExpanded] = useState(false);

  useEffect(() => {
    console.log("setting part data", props.part)
    if (props.part) {
      setFormData(props.part);
    }
  }, [props.part]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    console.log("valid", formRef.current.checkValidity());

    formRef.current.reportValidity();

    if (formRef.current.checkValidity()) {
      setBusy("save");

      postData("parts/save", removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the part.");
        },
        function() {
          setBusy("");
        }
      );
    }
  }

  function onClone() {
    if (window.confirm("Are you sure you want to copy this part and make it specific to this product?")) {
      setBusy("clone")

      postData("parts/clone", removeNulls({
          ...formData,
          product_id: props.product.id
        }), 
        function(response) {
          props.onCloned(response);
        },
        function(error) {
          alert("An error occured saving the part.");
        },
        function() {
          setBusy("");
        }
      );      
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();

    const name = event.target.name;
    const value = event.target.value
      
    //console.log("handleFormChange", event.target.name, event.target.value)
    setFormData(produce(draft => {
      if (name == "price_cost" || name == "price_fixed" && value)
        draft[name] = parseFloat(value);
      else
        draft[name] = value;


      if (name == "price_type") {
        if (value.indexOf("fixed") == -1) {
          draft.price_fixed = null;
        }
      }

    }));
  }

  const { part } = props;

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPlug} />
            &nbsp;
            Part
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {(part && part.product_count > 0) && 
            <BoardWarning style={{margin: "10px 0px"}}>
                <div style={{
                        display:"flex", 
                        alignItems:"center",
                        justifyContent:"space-between", 
                        marginBottom: "5px", 
                        cursor: "pointer"
                    }}
                    onClick={() => setProductsExpanded(!productsExpanded)}
                >
                    <div>Warning: This part is used by <strong>{part.product_count}</strong> products.  Changes to these 
                    prices will affect all of them.</div>

                    <FontAwesomeIcon icon={productsExpanded ? faCaretUp:faCaretDown} size="2x" />
                </div>

                {productsExpanded && 
                    <div>
                        {part.products.split(",").map(p => (
                            <div key={p}>
                                &bull;
                                {p}
                            </div>
                        ))}
                    </div>
                }
            </BoardWarning>
        }

          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
            {formData.type_id != 10 &&
              <SelectField 
                name="type_id" 
                label="Type"                 
                labelField="title"
                required
                value={formData.type_id} 
                url={"parts/types/data"}
                onChange={(e) => handleFormChange(e)} />
            }

            {/* <TextField 
                name="name" 
                label="Name" 
                instructions={formData.type_id == 9 ? "Name of part needs to match a shipping method (freight, ups, etc) to be auto inserted" : ""}
                required={formData.id == 0}
                readonly={formData.id != 0}
                disabled={formData.id != 0}
                value={formData.name} 
                onChange={(e) => handleFormChange(e)} /> */}
            <TextField 
                name="title" 
                label="Title" 
                required 
                value={formData.title|| ""} 
                onChange={(e) => handleFormChange(e)} />
            {/* <TextField 
                name="short_title" 
                label="Short Title" 
                required 
                value={formData.short_title || ""} 
                onChange={(e) => handleFormChange(e)} /> */}

            <TextAreaField 
                name="description" 
                label="Description" 
                rows={3}
                value={formData.description|| ""} 
                onChange={(e) => handleFormChange(e)} />

            {/* <TextAreaField 
                name="notes" 
                label="Notes" 
                rows={3}
                value={formData.notes|| ""} 
                onChange={(e) => handleFormChange(e)} />

            <BooleanField 
                name="color_ind" 
                label="Has Color" 
                required
                value={formData.color_ind} 
                onChange={(e) => handleFormChange(e)} />

            <NumberField 
                name="size" 
                label="Size" 
                value={formData.size} 
                onChange={(e) => handleFormChange(e)} /> */}

            {/* <ListField 
                name="price_type" 
                label="Price Calculation Method" 
                value={formData.price_type || "size"} 
                list={[
                    {value: "size", label: "Per Size"},
                    {value: "cost_sqin", label: "Cost x Square Inches"},
                    {value: "cost_sqin_fixed", label: "Cost x Square Inches + Fixed"},
                    {value: "cost_perimin", label: "Cost x Perimeter Inches"},
                    {value: "cost_perimin_fixed", label: "Cost x Perimeter Inches + Fixed"},
                    {value: "cost_size", label: "Cost x Size"},
                    {value: "cost_size_fixed", label: "Cost x Size + Fixed"},
                    {value: "cost_part_size", label: "(Part Size x Cost) + Fixed"},
                    {value: "fixed", label: "Fixed"},
                ]}
                onChange={(e) => handleFormChange(e)} 
            />

            {formData.price_type != "fixed" && 
              <NumberField 
                  name="price_cost" 
                  label="Cost" 
                  value={formData.price_cost} 
                  percentage
                  onChange={(e) => handleFormChange(e)} />
            }
            {(formData.price_type && formData.price_type.indexOf("fixed") != -1) && 
              <NumberField 
                  name="price_fixed" 
                  label="Fixed Price" 
                  value={formData.price_fixed} 
                  onChange={(e) => handleFormChange(e)} />
            }

            <NumberField 
                name="price_setup" 
                label="Setup Price" 
                instructions="Only added once per sign"
                value={formData.price_setup} 
                onChange={(e) => handleFormChange(e)} />

            <BooleanField 
                name="packingslip_ind" 
                label="Include On Packing Slip" 
                required
                value={formData.packingslip_ind} 
                onChange={(e) => handleFormChange(e)} /> */}

            {/* <BooleanField 
                name="price_multipliers_ind" 
                label="Apply Base Modifiers" 
                required
                value={formData.price_multipliers_ind} 
                onChange={(e) => handleFormChange(e)} /> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={busy == "save"}
                disaabled={busy != ""}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Part" 
                icon={faSave} /> 

            {(props.product && part.product_count > 1) && 
              <ButtonProcessing 
                processing={busy == "clone"}
                disaabled={busy != ""}
                onClick={onClone} 
                variant="outline-secondary" 
                caption="Save As New Product Part" 
                icon={faClone} /> 
            }

            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default PartDrawer;