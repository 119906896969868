import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FieldLabel from "./label"
import HelpText from "./help-text"

function FieldList(props) {
    var styles = props.style || {}

    if (props.width && props.width == "auto") {
        styles["width"]  = "auto";
    }
      
    let groupStyle = props.groupStyle || {};

    if (props.layout == "horizontal") {
        groupStyle.display = "grid";
        groupStyle.gridTemplateColumns = "125px auto";
        groupStyle.alignItems = "center";
    }
        
    return (
        <Form.Group className={props.groupClassName} style={groupStyle}>
            {props.label && <FieldLabel required={props.required} bold={props.labelBold}>{props.label}</FieldLabel>}
            { props.type && props.type == "radio" ? 
                <div>
                    {props.list.map(item => (
                        <Form.Check 
                            key={item.value}
                            name={props.name}
                            value={item.value}
                            checked={props.value == item.value}
                            inline={!props.direction || props.direction == "horizontal"}
                            label={item.label || item.title || item.name} 
                            type="radio" 
                            onChange={(e) => {
                                props.onChange({
                                    target: {
                                        name: props.name,
                                        value: e.target.value
                                    }
                                }); 
                            }}
                        />
                    ))}
                </div>
            : props.type && props.type == "checkbox" ? 
                <div>
                    {props.list.map((item,index) => (
                        <Form.Check 
                            key={index}
                            name={props.name}
                            value={item.value || item.id || ""}
                            checked={props.value && props.value.indexOf(item.value || item.id) != -1}
                            inline={!props.direction || props.direction == "horizontal"}
                            label={item.label || item.title || item.name} 
                            type="checkbox" 
                            onChange={(e) => {
                                const values = [...props.value];

                                if (e.target.checked)
                                    values.push(item.value || item.id);
                                else 
                                    values.splice(values.findIndex(v => v == (item.value || item.id)), 1);

                                props.onChange({
                                    target: {
                                        name: props.name,
                                        value: values
                                    }
                                }); 
                            }}
                        />
                    ))}
                </div>                
            :
                <FormControl 
                    as="select" 
                    name={props.name} 
                    style={styles}
                    size={props.size}
                    className={props.className}
                    value={props.value} 
                    required={props.required} 
                    readOnly={props.readonly}
                    disabled={props.disabled}
                    onChange={(e) => props.onChange(e)}>
                    <option value="">{props.blankLabel || ""}</option>
                    {(props.list || []).map((item,index) => (
                        <option key={index} value={item.value || item.id}>{item.label===0 ? "0" : (item.label || item.title || item.name)}</option>
                    ))}
                </FormControl>
            }
            {props.instructions && <HelpText>{props.instructions}</HelpText>}
        </Form.Group>
    )
}

export default FieldList