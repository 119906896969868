import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

function ErrorText(props) {
    return (
        <div style={{color: "red"}}>
            <small><FontAwesomeIcon icon={faExclamationCircle} />{' '}</small>
            <small dangerouslySetInnerHTML={{__html: props.children }} />
        </div>
    )
}

export default ErrorText