import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip, faBan } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

import BoardPill from "./board-pill";
import BoardPills from "./board-pills";
import ButtonProcessing from "../buttons/button-processing";
import ZoomableImage from "../image-zoomable";

import storage from "../../settings/storage"
import { formatDateTime, formatFileSize } from "../../helpers/utility";
import { postData } from "../../common/services/server";

function BoardFile({
    file,
    className,
    onDelete
}) {
    const [busy, setBusy] = useState(false);

    function deleteFile(e) {
        e.preventDefault();
        e.stopPropagation();

        if (window.confirm("Are you sure you want to delete this file?")) {
            setBusy("delete");

            postData("files/delete", {
                file_id: file.id
            },
                function() {
                    onDelete();
                },
                function(error) {
                    alert("Error deleting file.")
                },
                function() {
                    setBusy("")
                }
            );
        }
    }

    return (
        <div className={clsx(
            "grid grid-cols-[60px,1fr] gap-1 border rounded border-gray-300 p-1 text-xs",
            className,
        )}>
            <div className="flex items-center justify-center p-1">
                {file.preview_url ? 
                    <ZoomableImage
                        url={storage.root + file.preview_url}
                        zoomUrl={storage.root + file.url}
                        maxHeight="40px"
                        maxWidth="50px"
                    />
                :
                    <FontAwesomeIcon icon={faPaperclip} size="3x" className="text-gray-300" />
                }
            </div>
            <div>
                <div className="flex items-center justify-between mb-1">
                    <a href={storage.root + file.url} target="_blank" className="font-bold">
                        {file.filename}
                    </a>
                    {onDelete && 
                        <ButtonProcessing
                            size="tiny"
                            variant="light-danger"
                            processing={busy=="delete"}
                            icon={faBan}
                            onClick={deleteFile}
                        />
                    }
                </div>

                <BoardPills>
                    <BoardPill label="Source" value={file.source} />
                    {file.filesize && 
                        <BoardPill value={formatFileSize(file.filesize || 0)} />
                    }                
                </BoardPills>

                <div className="text-gray-400 mt-1">
                    Added by <strong>{file.added_user_name || "?"}</strong> on <strong>{formatDateTime(file.added_date)}</strong>
                </div>
            </div>
        </div>
    );
}

export default BoardFile