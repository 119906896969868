import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCreditCard } from '@fortawesome/free-solid-svg-icons'

import Content from "../components/content"
import PageHeader from "../components/page-header";

import PartsPanel from "./panels/panel-parts";
import PanelProductOrders from "../panels/panel-part-orders";
import PanelProductOrder from "../panels/panel-part-order";
import PartDrawer from "../drawers/drawer-part"

import { Actions, Permissions, userHasPermission } from "../common/services/auth";
import { useBodyClass } from "../hooks/useBodyClass";
import Button from "../components/buttons/button";
import PageLayout from "../components/page-layout";

function PartsPage() {
  const history = useHistory();
  let { tab, partId } = useParams();

  if (!tab) tab = "frontend";

  const [addPart, setAddPart] = useState(false);

  useBodyClass(`noscroll`);

  function onPartSaved(partData) {
    console.log("part saved", partData)

    // setParts(produce(draft => {
    //     var indexes = draft.map((x) => { return parseInt(x.id); });
    //     var index = indexes.indexOf(parseInt(updatedPart.id));

    //     if (index == -1)
    //       draft.push(updatedPart);
    //     else
    //       draft[index] = updatedPart;
    // }));

    setAddPart(false);
    window.location = "#/parts/" + tab + "/" + partData.id;

    // if (!selectedPart || selectedPart.id != updatedPart.id)
    //   setSelectedPart(updatedPart);
  }

  return (
    <PageLayout>
      <PageHeader 
        title="Part Manager"
        help="parts"
        tab={tab}
        tabUrl={"/parts/{tab}"}
        tabs={[
            {id:-1, name:"frontend", title: "Front End Parts"},
            {id:-4, name:"assembly", title: "Assemblies"},
            {id:-2, name:"backend", title: "Back End Parts"},
            {id:-5, name:"material", title: "Materials"},
            {id:-6, name:"equipment", title: "Equipment"},
            {id:-3, name:"orders", title: "Part Orders", align: "right"},
        ]}
        onTab={(tab) => {
          history.push("/parts/" + tab);
        }}
      >
        {userHasPermission(Permissions.Products, Actions.Create) &&
          <>
            <Button onClick={() => setAddPart(true)} variant="outline-success" size="sm">
                <FontAwesomeIcon icon={faPlus} />
                &nbsp;
                Create Part
            </Button>
            <Button
              variant="outline-success"
              size="sm"
              onClick={() => history.push("/parts/order/" + partId)}
          >
              <FontAwesomeIcon icon={faCreditCard} />
              &nbsp;
              Place Order
          </Button>               
          </>
        }
      </PageHeader>

      <Content permission={Permissions.Products} padding={1}>
        <div style={{margin: "10px"}}>
            {tab == "orders" ?
              <PanelProductOrders />
            : tab == "order" ?
              <PanelProductOrder partId={partId} />
            :
              <PartsPanel 
                  mode={tab} 
                  partId={partId}
                  onChange={(data) => {
                    console.log("part changed", data)
                  }}
              />
            }
        </div>
      </Content>

      {addPart &&
        <PartDrawer 
            part={{}} 
            onSaved={onPartSaved} 
            onHide={ () => setAddPart(false) } 
        />       
      }      
    </PageLayout>
  );
}

export default PartsPage;