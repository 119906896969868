import React, { useState, useEffect } from "react";
import styled from "styled-components"

import produce from "immer"

import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import FormControl from 'react-bootstrap/FormControl';

import Loading from "../components/loading";
import SignSelect from "../components/fields/sign-select";
import ProductSelect from "../components/fields/product-select";
import CouponSelect from "../components/fields/coupon-select";
import HtmlField from "../components/fields/field-html"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"
import storage from '../settings/storage'
import settings from '../settings'
import { formatPrice } from "../helpers/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";

const ModalBody = styled(Modal.Body)`
    min-height: 400px;

    .input-group {
        margin-bottom: 5px;
    }
    .form-group {
        flex: 1;
    }
`
const Placeholders = styled.div`
    margin-left: 10px;
    border: 1px solid #ccc;
    min-width: 100px;

    > div {
        padding: 5px;
        border-bottom: 1px solid #ccc;
    }
`

function EmailSectionDialog(props) {
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [sections, setSections] = useState([])
    const [placeholders, setPlaceholders] = useState([])
    const [section, setSection] = useState(null)
    const [content, setContent] = useState("")
    const [busy, setBusy] = useState("")

    useEffect(
        () => {
            postData("emails/sections", {},
                function(result) {
                    setSections(result);
                },
                function(error) {
                    alert("Error loading email sections.")
                }
            );
        }, 
        []
    );

    function initPlaceholders(type) {
        let items = [];

        if (!type) type = section.type;

        if (type == "sign")
            items = ["[sign_preview_url]","[sign_details_url]","[sign_id]","[sign_uid]","[sign_title]","[sign_description]","[sign_price]"];
        else if (type == "product")
            items = ["[product_preview_url]","[product_url]","[product_title]","[product_description]"];
        else if (type == "coupon")
            items = ["[coupon_code]","[coupon_description]","[coupon_title]","[coupon_details]"];

        setPlaceholders(items);        
    }

    function onSection(name) {
        console.log("section", name);
        const section = sections.find(s => s.name == name);

        setSection(section);

        if (section && section.type == "html")
            setContent(section.content || "");
        else 
            setContent("");

        initPlaceholders(section.type);
    }

    function onSign(sign) {
        let signContent = section.content;

        signContent = signContent.replace("[sign_preview_url]", storage.root + sign.preview_url);
        signContent = signContent.replace("[sign_details_url]", settings.SIGNDETAILS_URL + sign.uid);
        signContent = signContent.replace("[sign_id]", sign.id);
        signContent = signContent.replace("[sign_uid]", sign.uid);
        signContent = signContent.replace("[sign_title]", sign.title);
        signContent = signContent.replace("[sign_description]", sign.description);
        signContent = signContent.replace("[sign_price]", formatPrice(sign.estimate));

        setContent(signContent);
    }
    function onProduct(product) {
        let prodContent = section.content;

        prodContent = prodContent.replace("[product_preview_url]", storage.root + "2020/products/"+ product.name + "/productmed.jpg?v4");
        prodContent = prodContent.replace("[product_url]", settings.SITE_URL + "/products/" + product.url.toLowerCase());
        prodContent = prodContent.replace("[product_title]", product.title);
        prodContent = prodContent.replace("[product_description]", product.description);

        setContent(prodContent);
    }
    function onCoupon(coupon) {
        let couponContent = section.content;

        couponContent = couponContent.replace("[coupon_code]", coupon.code);
        couponContent = couponContent.replace("[coupon_description]", coupon.description);
        couponContent = couponContent.replace("[coupon_title]", coupon.title);
        couponContent = couponContent.replace("[coupon_details]", coupon.details);

        setContent(couponContent);
    }

    function onInsert() {
        props.onInsert(content);
    }
    function onSave() {
        if (section.name.length == 0) {
            window.alert("Invalid section name");
            return;
        }

        setBusy("save")        

        postData("emails/savesection", {
            type: section.type,
            name: section.name,
            content: content
        },
            function(result) {
                setSections(produce(draft => {
                    sections.push(result);
                }));
                setSection(result);
                setContent(result.content);
                initPlaceholders(result.type);
                setEdit("");
            },
            function(error) {
                alert("Error saving section.")
            },
            function() {
                setBusy("");
            }
        );        
    }

    function onUpdate() {
        if (section.name.length == 0) {
            window.alert("Invalid section name");
            return;
        }

        setBusy("update")   

        postData("emails/updatesection", {
            id: section.id,
            type: section.type,
            name: section.name,
            content: content
        },
            function(result) {
                setSections(produce(draft => {
                    const index = draft.findIndex(s => s.id == result.id);

                    sections[index] = result;
                }));
                setSection(result);
                setContent(result.content);
                initPlaceholders(result.type);
                setEdit("");
            },
            function(error) {
                alert("Error updating section.")
            },
            function() {
                setBusy("");
            }
        ); 
    }
    function onDelete() {
        if (window.confirm("Delete this email section?")) {
            setBusy("delete")

            postData("emails/deletesection", {
                id: section.id,
            },
                function() {
                    setSections(produce(draft => {
                        const index = draft.findIndex(s => s.id == section.id);
    
                        if (index != -1)
                            sections.splice(index, 1);
                    }));
                    setSection(null);
                    setContent("");
                    initPlaceholders("");
                    setEdit("");
                },
                function(error) {
                    alert("Error deleting section.")
                },
                function() {
                    setBusy("");
                }
            );
        }
    }    
    function onNew(type) {
        setSection({id:0, name:"", type});

        initPlaceholders(type);
    }
    
    return (
        <Modal show={true} size="lg" enforceFocus={false} onHide={props.onHide} className="dialog">
          <Modal.Header closeButton>
            <Modal.Title>Email Section</Modal.Title>
          </Modal.Header>
          <ModalBody>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text style={{width:"100px"}}>
                            Sections</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl 
                        as="select" 
                        name="section" 
                        value={""} 
                        onChange={(e) => onSection(e.target.value)} 
                    >
                        <option value=""></option>
                        {sections.map(sec => (
                            <option key={sec.id} value={sec.name}>{sec.title}</option>
                        ))}
                    </FormControl>
                    {(section && section.id != 0) && 
                        <InputGroup.Append>
                            <Button variant="outline-primary" onClick={() => { setEdit("section"); setContent(section.content || ""); }}>
                                Edit Section
                            </Button>
                        </InputGroup.Append>                
                    }
                    <InputGroup.Append>
                        <DropdownButton variant="outline-success" title="New Section">
                            <Dropdown.Item onClick={() => onNew("html")}>HTML</Dropdown.Item>
                            <Dropdown.Item onClick={() => onNew("sign")}>Sign</Dropdown.Item>
                            <Dropdown.Item onClick={() => onNew("product")}>Product</Dropdown.Item>
                            <Dropdown.Item onClick={() => onNew("coupon")}>Coupon</Dropdown.Item>
                        </DropdownButton>
                    </InputGroup.Append>
                    {(section && section.id != 0) && 
                        <InputGroup.Append>
                            <Button variant="outline-danger" onClick={onDelete}>
                                <FontAwesomeIcon icon={busy == "delete" ? faSpinner:faTrash} spin={busy == "delete"} />
                            </Button>
                        </InputGroup.Append>                
                    }
                </InputGroup>

                {(section && (section.id == 0 || edit == "section")) &&
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text style={{width:"100px"}}>
                                Name</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl 
                            as="input" 
                            type="text" 
                            name="name" 
                            required={true}
                            defaultValue={section.name} 
                            onChange={(e) => { 
                                if (e.persist) e.persist();

                                setSections(produce(draft => {
                                    const index = sections.findIndex(s => s.id == section.id);

                                    if (index != -1)
                                        draft[index].name = e.target.value;
                                }));
                                setSection(produce(draft => {
                                    draft.name = e.target.value
                                }));
                            }}
                        />
                    </InputGroup>
                }
                {(section && section.type == "sign" && section.id != 0 && edit != "section") &&
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text style={{width:"100px"}}>
                                Sign</InputGroup.Text>
                        </InputGroup.Prepend>
                        <SignSelect
                            name="sign_id"
                            onChange={onSign}
                        />
                    </InputGroup>
                }
                {(section && section.type == "product" && section.id != 0 && edit != "section") &&
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text style={{width:"100px"}}>
                                Product</InputGroup.Text>
                        </InputGroup.Prepend>
                        <ProductSelect
                            name="product_id"
                            hasUrl
                            onChange={onProduct}
                        />
                    </InputGroup>
                }
                {(section && section.type == "coupon" && section.id != 0 && edit != "section") &&
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text style={{width:"100px"}}>
                                Coupon</InputGroup.Text>
                        </InputGroup.Prepend>
                        <CouponSelect
                            name="coupon_id"
                            hasUrl
                            onChange={onCoupon}
                        />
                    </InputGroup>
                }

                {loading &&
                    <Loading />
                }
                {(section && content.length > 0 && edit == "" && section.id != 0) && 
                    <div>
                        <div style={{margin:"15px 0px", border: "1px dotted #ccc", padding: "10px"}}>
                            <div dangerouslySetInnerHTML={{__html: content}} />
                        </div>
                        <Button size="sm" variant="outline-primary" onClick={() => setEdit("content")}>
                            Edit Content
                        </Button>
                    </div>
                }
                {(section && (content.length > 0 || section.id == 0 || edit == "section") && (edit == "content" || edit == "section" || section.id == 0)) && 
                    <div style={{marginTop:"15px", display:"flex"}}>
                        <HtmlField 
                            name="sectionbody" 
                            value={content} 
                            onChange={(e) => {
                                setContent(e.target.value)
                            }} 
                            groupStyle={{
                                marginBottom: "0px"
                            }}
                        />

                        {(edit == "section" || section.id == 0) && 
                            <Placeholders>
                                {placeholders.map(placeholder => (
                                    <div key={placeholder}>
                                        {placeholder}
                                    </div>
                                ))}
                            </Placeholders>
                        }
                    </div>
                }
          </ModalBody>
          <Modal.Footer>
            {(section && section.id > 0 && edit != "section") && 
                <Button variant="success" onClick={onInsert}>
                    Insert Section
                </Button>
            }
            {(section && section.id > 0 && edit == "section") && 
                <Button variant="primary" onClick={onUpdate}>
                    <FontAwesomeIcon icon={busy == "update" ? faSpinner:faSave} spin={busy == "update"} /> {' '}
                    Update Section
                </Button>
            }
            {(section && section.id == 0) && 
                <Button variant="primary" onClick={onSave}>
                    <FontAwesomeIcon icon={busy == "save" ? faSpinner:faSave} spin={busy == "save"} /> {' '}
                    Save Section
                </Button>
            }

            <Button variant="outline-secondary" onClick={props.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }

  export default EmailSectionDialog;