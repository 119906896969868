import { isExpired, decodeToken } from "react-jwt";

export function logToken(token) {
  //console.log("Token: ", token ? token.substring(0,9)+"..."+token.substring(token.length-10) : "NO TOKEN")
}

export const setToken = (token) => {
  //console.log("* setToken");
  logToken(token);

  localStorage.setItem("token", token);
}
export const getToken = () => {
  const token = localStorage.getItem("token");

  //console.log("* getToken");
  logToken(token);

  return token;
}
export const hasToken = () => {
  const token = getToken();

  //console.log("* hasToken", token ? true:false);

  return token ? !isExpired(token) : false;
}

export const isLoggedIn = () => {
  return hasToken();
}

export const getUser = () => {
  if (hasToken()) {
    const token = getToken();
    const user = decodeToken(token);

    if (!isExpired(token))
      return user;
    else 
      console.log("User token has expired");
  }
}

export const getCurrentUserId = () => {
  const user = getUser();

  return user ? user.id : 0;
}

export const logout = callback => {
  setToken(null)
  localStorage.clear();
  sessionStorage.clear();
  callback()
}

//
// Permission Functions
//

export const userHasAnyRole = (roles) => {
  const user = getUser();

  if (user) {
    for (const role of roles) {
      if (user.roles.findIndex(r => r.name == role) != -1)
        return true;
    }
  }
}

export const userIsAdmin = () => {
  const user = getUser();

  return user && user.roles && user.roles.find(r => r.name == "Admin");
}

export const userHasPermission = (permission, action="View") => {
  let valid = false;

  if (userIsAdmin()) valid = true;

  if (!valid) {
    const user = getUser();
    const userPermission = user && user.permissions && user.permissions.find(p => p.name == permission);

    if (userPermission) {
      valid = userPermission.actions && userPermission.actions.indexOf(action) != -1;
    }
  }
  
  if (!valid) {
    //console.log(`User doesn't have "${permission} ${action}" permission.`);
  }

  return valid;
}

export const userHasAnyPermission = (permissions, action) => {
  let hasPermission = false;

  for (const permission of permissions) {
    if (userHasPermission(permission, action))
      hasPermission = true;
  }

  return hasPermission;
}

//
// Impersonation Functions
//

export const impersonateRole = (role, permissions) => {
  sessionStorage.impersonate = {
    role,
    permissions
  }
}
export const stopImpersonating = () => {
  sessionStorage.impersonate = null;
}
export const getImpersonatingRole = () => {
  return sessionStorage.impersonate ? sessionStorage.impersonate.role : null;
}
export const isImpersonating = () => {
  return sessionStorage.impersonate && sessionStorage.impersonate.role ? true : false;
}
export const isImpersonatingRole = (role) => {
  return isImpersonating() && getImpersonatingRole().name == role.name;
}

export const Permissions = {
  Colors: "Colors",
  Companies: "Companies",
  Constructions: "Constructions",
  Customers: "Customers",
  EmailTemplates: "Email Templates",
  Emails: "Emails",
  EmailsScheduled: "Emails (Scheduled)",
  Employees: "Employees",
  FaceArt: "Face Art",
  JobTasks: "Job Tasks",
  Jobs: "Jobs",
  LandingPages: "Landing Pages",
  MWOs: "MWOs",
  News: "News",
  Notes: "Notes",
  Orders: "Orders",
  OrdersParis: "Orders (Paris)",
  Photos: "Photos",
  Pricing: "Pricing",
  ProductContent: "Product Content",
  Products: "Products",
  Projects: "Projects",
  ProjectsInternal: "Projects (Internal)",
  PurchaseCodes: "Purchase Codes",
  QA: "QA",
  QAChecklists: "QA Checklists",
  Roles: "Roles",
  Shapes: "Shapes",
  ShippingData: "Shipping Data",
  ShoppingCarts: "Shopping Carts",
  SignTemplates: "Sign Templates",
  Signs: "Signs",
  SignsCustom: "Signs (Custom)",
  Stations: "Stations",
  Styles: "Styles",
  Tasks: "Tasks",
  UL: "UL",
}
export const Actions = {
  View: "View",
  Edit: "Edit",
  Approve: "Approve",
  Create: "Create",
  Clone: "Clone",
  Manage: "Manage",
  Upload: "Upload",
  Send: "Send",
  Impersonate: "Impersonate",
  Reset: "Reset",
  Delete: "Delete",
  Restore: "Restore",
  Categorize: "Categorize",
  Repoerts: "Reports"
}