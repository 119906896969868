import React, { useState, useEffect, useRef } from "react";
import Form from 'react-bootstrap/Form';
import { faSave } from '@fortawesome/free-solid-svg-icons'

import produce from "immer"

import TextField from "../../components/fields/field-text"
import SelectField from "../../components/fields/field-select"
import ButtonProcessing from "../../components/buttons/button-processing"
import { postData } from "../../common/services/server"
import { formatPhoneNumber, removeNulls } from '../../helpers/utility'

function AddressForm(props) {
    const formRef = useRef(null);

    const [isProcessing, setIsProcessing] = useState(false);
    const [formData, setFormData] = useState({});
    const [validated, setValidated] = useState(false);

    useEffect(
      () => {
          setFormData({
              name: props.address[props.prefix + "name"],
              phoneno: props.phoneno,
              company: props.address[props.prefix + (props.companyField || "company")],
              address: props.address[props.prefix + "address"],
              address1: props.address[props.prefix + "address1"],
              address2: props.address[props.prefix + "address2"],
              city: props.address[props.prefix + "city"],
              state_id: props.address[props.prefix + "state_id"],
              state: props.address[props.prefix + "state"],
              zipcode: props.address[props.prefix + "zipcode"]
          });
      }, 
      [props.address]
    );
    
    function handleFormChange(event) {
        if (event.persist) event.persist();
        console.log("handleFormChange", event.target.name, event.target.value)
        const field = event.target.name;

        setFormData(produce(draft => {
          draft[field] = event.target.value;

          if (field == (props.prefix + "state_id")) {
            const selected = event.target.options[event.target.selectedIndex];

            console.log(selected.value, selected.text)

            draft[props.prefix + "state"] = selected.text;
          }
        }));
    }

    function onValidate(event) {
        event.preventDefault();
        event.stopPropagation();
    
        setValidated(true);
    }
    
    function onSave() {
        // force validation
        formRef.current.dispatchEvent(new Event("submit"));
        // good to go?
        if (formRef.current.checkValidity()) {
          setIsProcessing(true);
    
          if (props.onSave) {
            props.onSave(removeNulls(formData), () => {
                setIsProcessing(false);
            })
          }
          else {
            postData(props.saveUrl, removeNulls(formData), 
                function(response) {
                    props.onChange(response);
                },
                function(error) {
                    alert("An error occured updating the address.");
                },
                function() {
                    setIsProcessing(false);
                }
            );
          }
        }
    }

    function getDifferentNames() {
        const names = [];
        const name = props.address[props.prefix + "name"];

        if (props.alternativeNames) {
            for (const altName of props.alternativeNames) {
                if (altName.name != name)

                names.push(altName.type + " name is " + altName.name);
            }
        }

        return names.length ? names.join(", ") : "";
    }

    return (
        <>
            <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
                <TextField 
                    name= "company"
                    label="Company" 
                    value={formData.company} 
                    onChange={(e) => handleFormChange(e)} 
                /> 
                {!props.hideName &&
                    <TextField 
                        name="name" 
                        label="Name" 
                        required
                        value={formData.name} 
                        instructions={getDifferentNames()}
                        onChange={(e) => handleFormChange(e)} 
                    />  
                }
                {props.phoneno &&
                    <TextField 
                        name="phoneno" 
                        label="Phone Number" 
                        value={props.phonenoformat ? formData.phoneno : formatPhoneNumber(formData.phoneno)} 
                        disabled
                    />  
                }                
                <TextField 
                    name="address1" 
                    label={props.hideAddress2 ? "Address":"Address 1"}
                    required
                    value={props.hideAddress2 ? formData.address : formData.address1} 
                    onChange={(e) => handleFormChange(e)} 
                /> 
                {!props.hideAddress2 &&
                    <TextField 
                        name="address2" 
                        label="Address 2" 
                        value={formData.address2} 
                        onChange={(e) => handleFormChange(e)} 
                    />  
                }
                <Form.Row>
                    <TextField 
                        name="city" 
                        label="City" 
                        required 
                        column={true}
                        value={formData.city} 
                        onChange={(e) => handleFormChange(e)} />
                    <SelectField 
                        name="state_id" 
                        label="State" 
                        required 
                        column={true}
                        url="states/data"
                        labelField="abbrev2"
                        value={formData.state_id} 
                        onChange={(e) => handleFormChange(e)} />  
                    <TextField 
                        name="zipcode" 
                        label="Zip Code" 
                        required 
                        column={true}
                        value={formData.zipcode} 
                        onChange={(e) => handleFormChange(e)} />
                </Form.Row>                
            </Form>

            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Update Address" 
                disabled={props.disabled}
                icon={faSave} 
            /> 
        </>
    );
}

export default AddressForm;