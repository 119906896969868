import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table'
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"

import { postData } from "../common/services/server"

function RolePermissionsDrawer(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
      setIsProcessing(true);

      postData("roles/permissions", {role_id: props.role.id}, 
        function(results) {
          setPermissions(results)
        },
        function(error) {
          alert("An error occured loading the permissions.");
        },
        function() {
          setIsProcessing(false);
        }
      );
  }, [props.role]);

  function onPermissionChange(e) {
    const permissionId = e.target.value;
    const checked = e.target.checked;
    let selected = [];

    setPermissions(produce(draft => {
        const permission = draft.find(p => p.id == permissionId);

        permission.selected_ind = checked ? 1 : 0;

        if (!checked) {
            for (let action of permission.actions) {
                action.selected_ind = 0;
            }
        }

        selected = draft.filter(p => p.selected_ind == 1);
    }));

    postData("roles/togglepermission", {
            role_id: role.id,
            permission_id: permissionId,
            checked_ind: checked ? 1 : 0
        }, 
        function(response) {
            props.onChanged(selected.length)
        },
        function(error) {
            alert("An error occured updating the role.");
        }
    );
  }

  function onActionChange(e, permissionId) {
    const actionName = e.target.value;
    const checked = e.target.checked;      
    console.log(permissionId, actionName, checked)

    setPermissions(produce(draft => {
        const permission = draft.find(p => p.id == permissionId);
        const action = permission.actions.find(a => a.name == actionName);

        action.selected_ind = checked ? 1 : 0;
    }));    

    postData("roles/togglepermissionaction", {
            role_id: props.role.id,
            permission_id: permissionId,
            action: actionName,
            checked_ind: checked ? 1 : 0
        }, 
        function(response) {
        },
        function(error) {
            alert("An error occured updating the permissions.");
        }
    );    
  }

  const { role } = props;

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faLock} />
                &nbsp;
                {role.name} Permissions
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isProcessing ? 
                    <Loading />
                :
                    <Table size="sm" striped>
                        <thead>
                            <tr>
                                <th style={{border:"0px"}}>Area</th>
                                <th style={{border:"0px"}}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {permissions.map(permission => (
                            <tr key={permission.id}>
                                <td nowrap style={{whiteSpace:"nowrap"}}>
                                    <Form.Check 
                                        name="permission_id"                                
                                        value={permission.id}
                                        checked={permission.selected_ind == 1}
                                        inline 
                                        label={permission.name} 
                                        type="checkbox" 
                                        onChange={onPermissionChange}
                                    />
                                </td>
                                <td>
                                    {(permission.selected_ind == 1) && 
                                        <>
                                            {permission.actions.map(action => (
                                                <Form.Check 
                                                    name="action_id"
                                                    value={action.name}
                                                    checked={action.selected_ind == 1}
                                                    inline 
                                                    label={action.name} 
                                                    type="checkbox" 
                                                    onChange={(e) => {
                                                        onActionChange(e, permission.id)
                                                    }}
                                                />                                                
                                            ))}
                                        </>
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default RolePermissionsDrawer;