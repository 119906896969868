import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote } from "@fortawesome/free-solid-svg-icons";

function NotesIcon({notes}) {
    return (
        <>
        {(notes && notes.length > 0) &&
            <div>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                        {notes.map(note => (
                            <div key={note.id} style={{
                                fontSize: "10px",
                                backgroundColor: "#ffc107",
                                color: "black",
                                borderRadius: "4px",
                                margin: "3px 0px",
                                padding: "3px",
                                textAlign: "left"
                            }}>
                                {note.notes}
                            </div>
                        ))}
                        </Tooltip>                            
                    }
                >         
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        backgroundColor: "#ffc107",
                        padding: "0px 5px",
                        borderRadius: "4px"
                    }}>                                                  
                        <FontAwesomeIcon icon={faStickyNote} />
                        {notes.length}
                    </div>
                </OverlayTrigger>    
            </div>
        }
        </>
    );
}

export default NotesIcon;