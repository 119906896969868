import React, { useState } from "react"
import styled from "styled-components"
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Popover } from "react-bootstrap";
import { isTablet, isMobile } from "react-device-detect"

import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faSpinner, faUser, faUserCheck, faStickyNote, faCamera } from "@fortawesome/free-solid-svg-icons";
import { postData } from "../common/services/server";
import { colors } from "../settings/settings";
import { removeNulls } from "../helpers/utility";

const JobTaskButton = styled.button`
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    border-width: 1px !important;
    border-radius: 3px !important;
    border-style: solid;
    border-right-width: 1px !important;    
    padding: 6px;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 12px !important;

    &.norightside {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right-width: 0px;
    }
`
const JobDropdown = styled(Dropdown)`
    margin-left: 0px !important;
    margin-left: 2px;
`
const JobTaskDropdownToggle = styled(Dropdown.Toggle)`
    background-image: none !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    margin-right: 1px;

    &.status-active {
        color: #28a745 !important;
        background-color: white !important;
        border-color: #28a745 !important;
    }
    &.status-completed {
        color: #fff !important;
        background-color: #28a745 !important;
        border-color: #28a745 !important;
    }
    &.status-pending {
        color: #6c757d !important;
        background-color: white !important;
        border-color: #6c757d !important;
    }
    &.status-inprogress {
        color: #fff !important;
        background-color: #007bff !important;
        border-color: #007bff !important;
    }
    &.status-active {
        color: #007bff !important;
        background-color: white !important;
        border-color: #007bff !important;
    }
    &.status-paused {
        color: #212529 !important;
        background-color: white !important;
        border-color: #ffc107 !important;
    }
    &.status-problem {
        color: #212529 !important;
        background-color: #ffc107 !important;
        border-color: #ffc107 !important;
    }
    &.status-qa { 
        color: #fbb9b9 !important;
        background-color: white !important;
        border-color: #fbb9b9 !important;
    }    
    &.status-invalid, &.status-skipped, &.status-deleted {
        background-color: #eee !important;
        border-color: #ccc;
        color: #999
    }
    &.status-invalid {
        text-decoration: line-through;
    } 
`
JobTaskDropdownToggle.propTypes = Dropdown.Toggle.propTypes;
JobTaskDropdownToggle.defaultProps = Dropdown.Toggle.defaultProps;

const JobTask = ({ 
    task, 
    users, 
    disabled=false, 
    expanded=true,
    onClick,
    onTaskChanged, 
    onJobChanged 
}) => {
    const [busy, setBusy] = useState("");

    const statuses = [
        {name: "pending", title: "Waiting"},
        {name: "active", title: "Active"},
        {name: "inprogress", title: "In-progress"},
        {name: "paused", title: "Paused"},
        {name: "qa", title: "Inspection"},
        {name: "problem", title: "Problem"},
        {name: "completed", title: "Completed"},
        {name: "invalid", title: "Invalid"},
        {name: "skipped", title: "Skipped"},
    ];

    function onChangeStatus(statusName, userId) {
        console.log("onChangeStatus", task.job_id, task.id, statusName, userId)
        setBusy("status")
        
        postData("jobs/updatetaskprop", removeNulls({
            job_id: task.job_id,
            jobtask_id: task.id,
            prop: "status_name",
            value: statusName,
            assigned_user_id: userId || null,
        }),
            function(result) {
                console.log("job-task.onChangeStatus", result);
                
                if (onJobChanged)
                    onJobChanged(result)
            },
            function(error) {
                alert("Error updating job")
            },
            function() {
                setBusy("")
            }
        );          
    }

    // function onAssignUser(userId) {
    //     if (userId === undefined) return; // selected Others... parent menu item

    //     setBusy("assign")

    //     postData("jobs/updatetaskprop", {
    //         job_id: task.job_id,
    //         jobtask_id: task.id,
    //         prop: "assigned_user_id",
    //         value: userId
    //     },
    //         function(result) {
    //             if (onTaskChanged)
    //                 onTaskChanged(task, result)
    //         },
    //         function(error) {
    //             alert("Error updating job")
    //         },
    //         function() {
    //             setBusy("")
    //         }
    //     );         
    // }

    return (
        onClick ?
            <>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Popover>
                        <Popover.Content style={{backgroundColor: "black", color: "white", borderRadius: "5px"}}>
                        <div>Status: {task.status_title}</div>
                        {task.dependent_name && 
                            <div>Depends On: {task.dependent_name}</div>
                        }                                                                        
                        {task.assigned_user_id && 
                            <div>Assigned To: {task.assigned_user}</div>
                        }
                        {task.station_names && 
                            <div>Station: {task.station_names}</div>
                        }
                        {(task.production_estimate && task.production_estimate > 0) ? 
                            <div>Estimate: {task.production_estimate} mins</div>
                        :
                            null
                        }         
                        {(task.status_name == "completed" && task.completed_user_id) && 
                            <div>Completed By: {task.completed_user}</div>
                        }                                                           
                        </Popover.Content>
                    </Popover>                            
                }
            >            
                <JobTaskButton 
                    btnStyle="flat"
                    className={"norightside status-" + task.status_name}
                    disabled={disabled || busy != ""}
                    style={{
                        width: expanded ? "auto":"100%"
                    }}
                    onClick={() => expanded && onClick(task)}
                >
                    {busy != "" ?
                        <FontAwesomeIcon icon={faSpinner} spin={true} style={{marginRight:"5px"}}  />
                    : (task.computer_ind == 1 && !isTablet) && 
                        <FontAwesomeIcon icon={faDesktop} style={{marginRight:"5px"}} />
                    }
                    {expanded ?
                        <>
                        {task.tasktype_name}
                        {(task.note_count > 0) &&
                            <FontAwesomeIcon icon={faStickyNote} style={{marginLeft:"5px", color: colors.orangeLight}}  />
                        }
                        {(task.file_count > 0) &&
                            <FontAwesomeIcon icon={faCamera} style={{marginLeft:"5px"}}  />
                        }
                        {task.status_name == "inprogress" &&
                            <FontAwesomeIcon icon={faSpinner} spin={true} style={{marginLeft:"5px"}}  />
                        }
                        {task.assigned_user_id && 
                            <FontAwesomeIcon icon={faUserCheck} style={{marginLeft:"5px"}} />
                        }
                        </>
                    :
                        " "
                    }
                </JobTaskButton>
            </OverlayTrigger>
            {!isTablet && 
                <JobDropdown
                    onSelect={(eventKey) => {
                        if (eventKey != "inprogress") {
                            console.log("1", eventKey);
                            onChangeStatus(eventKey);
                        }
                    }}
                    disabled={disabled || busy != ""}
                >            
                    <JobTaskDropdownToggle
                        btnStyle="flat"
                        className={"status-" + task.status_name}
                    />
                    <Dropdown.Menu
                        onSelect={(eventKey) => {
                            console.log("2", eventKey)
                        }}                      
                    >
                        {statuses.map((status) => (
                            <MenuItem key={status.name} active={task.status_name == status.name} eventKey={status.name}>
                                {status.title}

                                {(status.name == "inprogress" && users && users.length > 0) && users.filter(u => u.role_ids.indexOf(task.role_id) != -1).map((user) => (
                                    <MenuItem 
                                        key={user.id} 
                                        active={task.assigned_user_id == user.id} 
                                        eventKey={"inprogress"}
                                        onSelect={() => onChangeStatus('inprogress', user.id)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={task.assigned_user_id == user.id ? faUserCheck : faUser} 
                                            style={{
                                                marginRight:"4px", 
                                                color: task.assigned_user_id == user.id ? "black":"#ccc"
                                            }} 
                                            fixedWidth
                                        />
                                        {user.name}
                                    </MenuItem>
                                ))}
                            </MenuItem>
                        ))}
                    </Dropdown.Menu>
                </JobDropdown>
            }
            {/* {!isTablet &&
                <JobDropdown
                onSelect={(eventKey) => {
                    console.log(eventKey);
                    onAssignUser(eventKey)
                }}
                disabled={disabled || busy != ""}
            >            
                <JobTaskDropdownToggle
                    btnStyle="flat"
                    className={"status-" + task.status_name}
                />
                <Dropdown.Menu
                    onSelect={(eventKey) => {
                        console.log("2", eventKey)
                    }}                      
                >
                    {users && users.length > 0 && users.filter(u => u.role_ids.indexOf(task.role_id) != -1).map((user) => (
                        <MenuItem 
                            key={user.id} 
                            active={task.assigned_user_id == user.id} 
                            eventKey={user.id} 
                            onSelect={() => onAssignUser(user.id)}
                        >
                            <FontAwesomeIcon 
                                icon={task.assigned_user_id == user.id ? faUserCheck : faUser} 
                                style={{
                                    marginRight:"4px", 
                                    color: task.assigned_user_id == user.id ? "black":"#ccc"
                                }} 
                                fixedWidth
                            />
                            {user.name}
                        </MenuItem>
                    ))}
                    {(users && users.length > 0) &&
                        <MenuItem onSelect={() => {
                            console.log("Others...");
                        }}>
                            Others...
                            
                            {users.filter(u => u.role_ids.indexOf(task.role_id) == -1).map((user) => (
                                <MenuItem 
                                    key={user.id} 
                                    active={task.assigned_user_id == user.id} 
                                    eventKey={user.id}
                                    onSelect={() => onAssignUser(user.id)}
                                >
                                    <FontAwesomeIcon 
                                        icon={task.assigned_user_id == user.id ? faUserCheck : faUser} 
                                        style={{
                                            marginRight:"4px", 
                                            color: task.assigned_user_id == user.id ? "black":"#ccc"
                                        }} 
                                        fixedWidth
                                    />
                                    {user.name}
                                </MenuItem>
                            ))}
                        </MenuItem>
                    }
                    {task.assigned_user_id && 
                        <MenuItem 
                            eventKey={0}
                            onSelect={() => onAssignUser(0)}                                    
                        >
                            (Unassign)
                        </MenuItem>
                    }                        
                </Dropdown.Menu>
                </JobDropdown>            
            } */}
            </>
        :
            <JobTaskButton  
                className={"status-" + task.status_name}
                disabled={disabled}
                style={{
                    cursor: "default",
                    width: expanded ? "auto":"100%"
                }}
            >
                <div>
                    {(task.computer_ind == 1 && !isTablet) && 
                        <FontAwesomeIcon icon={faDesktop} style={{marginRight:"5px"}} />
                    }
                    {expanded ?
                        <>
                        {task.tasktype_name}
                        {task.status_name == "inprogress" &&
                            <FontAwesomeIcon icon={faSpinner} spin={true} style={{marginLeft:"5px"}}  />
                        }    
                        </>                    
                    :
                        " "
                    }
                </div>

                {task.assigned_user_id && 
                    <FontAwesomeIcon icon={faUserCheck} style={{marginLeft:"5px"}} />
                }              
            </JobTaskButton>
    )
}

export default JobTask