import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect"

import ProgressBar from 'react-bootstrap/ProgressBar'

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    CurrencyCell, 
    DateCell, 
    ButtonCell,
    LinkCell,
    GoogleImageCell, 
    UserCell,
    SortHeaderCell,
    SortTypes
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import { useHistory } from "react-router-dom";

import Loading from "../../components/loading"
import Paginator from "../../components/paginator"

import { postData } from "../../common/services/server"
import { removeNulls } from "../../helpers/utility"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"

function WorkflowJobsPanel(props) {
    const history = useHistory();
    const windowDimensions = useWindowDimensions();

    const [jobs, setJobs] = useState([]);
    const [sort, setSort] = useState({column: "added_date", dir: SortTypes.DESC});
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({page:1,pages:1,total:0});
    const [isLoading, setIsLoading] = useState(false);

    useBodyClass(`noscroll`);

    useEffect(
        () => {
            console.log("jobs panel laoding data", props.filter)
            setIsLoading(true);

            postData("workflowjobs/data", removeNulls({ 
                sort: sort.column,
                sortdir: sort.dir,
                page: page,
                perPage: 100,
                ...props.filters
            }),
                function(result) {
                    setJobs(result.jobs);
                    setPagination(result.pagination)
                },
                function(error) {
                    alert("Error loading jobs")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        [props.filters, sort, page]
    );

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }
    function onJob(job) {
        if (job.id)
            history.push("/workflowjob/" + job.id);
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
            <>
                <Table
                    rowHeight={50}
                    rowsCount={jobs.length}
                    width={windowDimensions.width - dimensions.dataTableWidthOffset}
                    height={windowDimensions.height - dimensions.dataTableHeightOffset}
                    headerHeight={50}
                    touchScrollEnabled={true}
                >
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Job #
                            </SortHeaderCell>
                        }
                        columnKey="id"
                        fixed={!isMobile}
                        cell={<LinkCell data={jobs} onClick={(job) => onJob(job)} />}
                        width={90}
                    />
                    <Column
                        header={<Cell>Title</Cell>}
                        columnKey="title"
                        cell={<TextCell data={jobs} />}
                        width={200}
                    />
                    <Column
                        header={<Cell>Preview</Cell>}
                        columnKey="preview_url"
                        cell={<GoogleImageCell data={jobs} maxwidth={125} maxheight={35} />}
                        width={150}
                    />
                    <Column
                        header={<Cell>Customer</Cell>}
                        
                        columnKey="user_name"
                        cell={({rowIndex, ...props}) => (
                            <UserCell 
                                userId={jobs[rowIndex].user_id}
                                userName={jobs[rowIndex].user_name}
                                onUser={() => history.push("/users/user/" + jobs[rowIndex].user_id)}
                            />
                        )}
                        width={225}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Start Date
                            </SortHeaderCell>
                        }
                        columnKey="start_date"
                        cell={<DateCell data={jobs} />}
                        width={135}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Ship Date
                            </SortHeaderCell>
                        }
                        columnKey="ship_date"
                        cell={<DateCell data={jobs} />}
                        width={135}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Status
                            </SortHeaderCell>
                        }
                        columnKey="status_title"
                        cell={<TextCell data={jobs} />}
                        width={150}
                    />
                </Table>
                <Paginator
                    {...pagination}
                    item="Job"
                    items="Jobs"
                    onPage={(page) => setPage(page)}
                />
            </>
            }
        </>
    );
}

export default WorkflowJobsPanel;