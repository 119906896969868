import React from "react"
import Badge from "./badge";

export default function TimeBadge({
    minutes,
    ...props
}) {
    const value = parseInt(minutes) || 0;
    
    const hours = Math.floor(value / 60);          
    const mins = value % 60;

    function padToTwoDigits(num) {
        return num.toString().padStart(2, '0');
    }

    return (
        <Badge
            {...props}
            variant="light"
        >
            {hours}:{padToTwoDigits(mins)}
        </Badge>
    );
}