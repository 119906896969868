import React, { useState, useEffect } from "react"
import styled from "styled-components"
import produce from "immer"
import Table from "react-bootstrap/Table"
import { Link } from "react-router-dom"
import ProgressBar from 'react-bootstrap/ProgressBar'
import { useHistory } from "react-router-dom";

import ChartBox from "../charts/chartbox"

import { postData } from "../../common/services/server"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import GoogleImage from "../google-image"
import { formatDate, formatPrice } from "../../helpers/utility"


function UnpaidOrdersWidget(props) {
    const history = useHistory();

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [error, setError] = useState(null);

    useEffect(
        () => {
            setLoading(true);

            postData("orders/unpaid", {},
                function(result) {
                    setOrders(result);
                },
                function(error) {
                    setError("Error loading orders")
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        [refresh]
    );

    return (
        <>
            <ChartBox 
                icon={faEnvelope}
                title="Unpaid Orders" 
                loading={loading}
                error={error}
                refresh={true}
                onRefresh={() => setRefresh(refresh++)}
            >
                <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Order</th>
                        <th>Date Ordered</th>
                        <th>Total</th>
                        <th>Paid</th>
                        <th>Balance</th>
                    </tr>
                </thead>
                <tbody>
                {orders.map(order => (
                    <tr key={order.id}>
                        <td>
                            <Link to={"/orders/order/" + order.id}>
                                {order.id}
                            </Link>
                        </td>
                        <td>
                            {formatDate(order.order_date)}
                        </td>
                        <td>
                            {formatPrice(order.total)}
                        </td>
                        <td>
                            {formatPrice(order.paid)}
                        </td>
                        <td>
                            <Link to={"/orders/order/billing/" + order.id} style={{color: "red"}}>
                                {formatPrice(order.balance)}
                            </Link>
                        </td>
                    </tr>
                ))}
                </tbody>
                </Table>
                {(!loading && orders.length == 0) && 
                    <p style={{textAlign:"center"}}>
                        No unpaid orders found.
                    </p>
                }
            </ChartBox>
        </>
    );
}

export default UnpaidOrdersWidget