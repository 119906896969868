import React, { useState } from "react"
import { faBan, faCopy, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";

import BoardFields from "../components/board/board-fields";
import BoardField from "../components/board/board-field";
import SignPriceDrawer from "../drawers/signprice"
import ButtonProcessing from "../components/buttons/button-processing";
import BoardColumns from "../components/board/board-columns";
import Panel from "./panel";
import Badge from "../components/badge";

import { postData, postExternalData } from "../common/services/server";
import { Actions, Permissions, userHasPermission } from "../common/services/auth";
import { formatDateForInput, formatDateTime, formatPrice, removeNulls } from "../helpers/utility";
import settings from "../settings";

import { useGetSignDetails } from "../api/queries/signs";
import { 
    useSetSignProp,
    useRecalculateSignPrice,
    useGenerateSignDescription,
    useSetSignNote,
} from "../api/mutations/signs";
import { useGetShippingMethods } from "../api/queries/shipping";
import Alert from "../components/alert";

const SignWarning = ({warning}) => (
    <div className="rounded bg-red-300 flex items-center gap-1 px-[5px] text-xs">
        <FontAwesomeIcon icon={faExclamation} size="sm" />
        {warning.warning}
    </div>
)

function SignDetailsPanel({
    signId,
    size,
    onSignRemoved,
    onSignCloned,    
}) {
    const queryClient = useQueryClient()

    const [canEdit] = useState(userHasPermission(Permissions.Signs, Actions.Edit));
    const signQuery = useGetSignDetails(signId, {
        include_warnings: 1
    });
    const sign = signQuery.data;

    const [selected, setSelected] = useState("");
    const [busy, setBusy] = useState("");

    const shippingMethodsQuery = useGetShippingMethods();

    const setSignTitle = useSetSignProp(signId, "title");
    const setCustomInd = useSetSignProp(signId, "custom_ind");
    const setSignUserId = useSetSignProp(signId, "user_id");
    const setSignHeight = useSetSignProp(signId, "size_height");
    const setSignWidth = useSetSignProp(signId, "size_width");
    const setOverallSignHeight = useSetSignProp(signId, "overall_size_height");
    const setOverallSignWidth = useSetSignProp(signId, "overall_size_width");
    const setOptions = useSetSignProp(signId, "options");
    const setOptionsCost = useSetSignProp(signId, "options_cost");
    const setPreviewFileId = useSetSignProp(signId, "preview_file_id");
    const setTechnicalFileId = useSetSignProp(signId, "technical_file_id");
    const setSignNote = useSetSignNote(signId);
    const setShippingMethod = useSetSignProp(signId, "shipping_method_id");
    const setShippingAdjustment = useSetSignProp(signId, "shipping_adjustment"); 
    const setEstimateExpiresDate = useSetSignProp(signId, "estimate_expire_date");
    const regenerateDescription = useGenerateSignDescription();
    const recalculateSignPrice = useRecalculateSignPrice();
    
    // TODO: Remove
    function reload() {
        queryClient.invalidateQueries(["signs", signId]);
    }

    return (
        <>
            <Panel query={signQuery}>
                {/* {(sign && (sign.status == "Draft" || sign.estimate_approved_ind == 0) && userHasPermission(Permissions.Signs, Actions.Approve)) &&
                    <div style={{backgroundColor: colors.lightRed, flexBasis: "100%", border: "1px solid #ccc", borderRadius: "5px", padding: "5px", margin: "2px", fontSize:"80%", textAlign:"center"}}>
                        <Button
                            size="sm"
                            variant="outline-danger"
                            style={{padding: "2px 6px"}}
                            onClick={() => {
                                setBusy("approve");
                                
                                postData("signs/approve", { 
                                    sign_id: sign.id
                                },
                                    function(result) {
                                        signChanged("estimate_approved_ind", {
                                            status: "Active"
                                        })
                                    },
                                    function(error) {
                                        alert("Error approving sign")
                                    },
                                    function() {
                                        setBusy("");
                                    }
                                );                                    
                            }}
                        >
                            <FontAwesomeIcon icon={busy == "approve" ? faSpinner : faThumbsUp} spin={busy == "approve"} />{' '}
                            Approve Sign
                        </Button>                            
                    </div>
                } */}

                {sign && 
                    <div className="grid gap-1">                
                        {sign.custom_groups_ind == 1 && 
                            <BoardField
                                type="badge"
                                value="Sign contains custom shapes"
                                variant="danger"
                            />            
                        }  

                        <BoardColumns>
                            <BoardField
                                label="Design #"
                                type="children">
                                <Link to={"/signs/sign/" + sign.id} className="text-blue-500">
                                    #{sign.id}
                                </Link>
                            </BoardField>
                            <BoardField
                                label="Version"
                                type="label"
                                value={sign.version}
                            />       
                        </BoardColumns>    

                        <BoardColumns columns={size == "small" ? 1:2}>
                            <BoardField
                                label="Custom"
                                type="children"
                                value={sign.custom_ind}
                                buttons={sign.custom_ind == 0 ? [
                                    {
                                        label: "Make Custom", 
                                        variant: "outline-secondary",
                                        disabled: setCustomInd.isLoading,
                                        onClick: () => {
                                            if (window.confirm("Are you sure you want to convert this to a custom sign?  You will no longer be able to load it in the Builder.")) {
                                                setCustomInd.mutate(1, {
                                                    onError: () => window.alert("Error updating sign")
                                                }) 
                                            }
                                        },
                                        busy: setCustomInd.isLoading
                                    },
                                ] : null}                                
                            >
                                {sign.custom_ind == 1 ? "Yes":"No"}
                            </BoardField>
                            <BoardField
                                label="Unique ID"
                                type="label"
                                value={sign.uid}
                            />
                        </BoardColumns>  

                        <BoardColumns columns={size == "small" ? 1:2}>
                            <BoardField
                                label="Preview"
                                type="image"
                                value={sign.preview_url}
                                imageProps={{
                                    zoom: true,
                                    zoomUrl: sign.preview_url,
                                    upload: true,
                                    uploadUrl: "signs/uploadpreview?sign_id=" + sign.id,
                                    uploadFileUrlProp: "preview_url",
                                    browse: true,
                                    extensions: ["jpg","png"],
                                    browseFilters: {
                                        sign_id: sign.id,
                                        project_id: sign.quote_id,
                                        user_id: sign.user_id
                                    },
                                    browseMutation: setPreviewFileId
                                }}                              
                            />
                            {/* {sign.custom_ind == 1 && */}
                                <BoardField
                                    label="Tech File"
                                    type="file"
                                    value={sign.technical_filename}
                                    fileProps={{
                                        url: sign.technical_url,
                                        upload: true,
                                        uploadUrl: "signs/uploadtechnical?sign_id=" + sign.id,
                                        uploadFilenameProp: "technical_filename",
                                        uploadFileUrlProp: "technical_url",
                                        browse: true,
                                        browseFilters: {
                                            sign_id: sign.id,
                                            project_id: sign.quote_id,
                                            user_id: sign.user_id
                                        },
                                        browseMutation: setTechnicalFileId
                                    }}
                                />
                            {/* } */}
                        </BoardColumns>
                                
                        <BoardField
                            label="Title"
                            type="text"
                            disabled={!canEdit}
                            value={sign.title}
                            saveMutation={setSignTitle}
                        />

                        <BoardColumns columns={size == "small" ? 1:2} className="my-1">
                            <BoardFields label="Cost">
                                <BoardField
                                    label="Discount"
                                    type="label"
                                    value={sign.discount}
                                    format="currency"
                                />           
                                <BoardField
                                    label="Options"
                                    type="number"
                                    value={sign.options_cost}
                                    disabled={!canEdit}
                                    saveMutation={setOptionsCost}
                                />

                                <BoardField
                                    label="Total"
                                    type={recalculateSignPrice.isLoading ? "label" : sign.estimate_expired_ind == 1 ? "children" : "currency"}
                                    value={recalculateSignPrice.isLoading ? "calculating..." : sign.estimate}
                                    buttons={[
                                        {
                                            label: "Recalculate", 
                                            variant: "solid-success",
                                            disabled: recalculateSignPrice.isLoading,
                                            onClick: () => recalculateSignPrice.mutate(signId, {
                                                onError: () => window.alert("Error calculating sign price")
                                            }) ,                            
                                            busy: recalculateSignPrice.isLoading
                                        },
                                        {
                                            label: "Calc Data", 
                                            disabled: !canEdit,
                                            onClick: () => setSelected("signprice")
                                        },
                                    ]}
                                >
                                    <div className="flex items-center gap-1">
                                        <div className="line-through">{formatPrice(sign.estimate)}</div>
                                        <Badge size="sm" variant="danger">EXPIRED</Badge>
                                    </div>
                                </BoardField>
                                <BoardField
                                    label="Expires"
                                    type="date"
                                    value={formatDateForInput(sign.estimate_expire_date)}
                                    saveMutation={setEstimateExpiresDate}
                                />                                                         
                            </BoardFields>
                            <BoardFields label="Dimensions">
                                <BoardColumns columns={sign.overall_size_height || sign.overall_size_width || sign.backdrop_ind == 1 ? 2:1}>
                                    <div>
                                        <BoardField
                                            label="Height"
                                            suffix="In"
                                            type="number"
                                            value={sign.size_height || "0"}
                                            disabled={sign.custom_ind == 0 || !canEdit}
                                            saveMutation={sign.custom_ind == 1 ? setSignHeight : null}
                                        />   
                                        <BoardField
                                            label="Width"
                                            suffix="In"
                                            type="number"
                                            value={sign.size_width || "0"}
                                            disabled={sign.custom_ind == 0 || !canEdit}
                                            saveMutation={sign.custom_ind == 1 ? setSignWidth : null}
                                        />
                                        <BoardField
                                            label="Area"
                                            suffix="Sq Ft"
                                            type="label"
                                            value={parseFloat(sign.area_sqft).toFixed(2) || "?"}
                                        /> 
                                    </div>
                                    {(sign.overall_size_height || sign.overall_size_width || sign.backdrop_ind == 1) && 
                                        <div>
                                            <BoardField
                                                label="Overall"
                                                suffix="In"
                                                type="number"
                                                value={sign.overall_size_height || "0"}
                                                disabled={sign.custom_ind == 0 || !canEdit}
                                                saveMutation={sign.custom_ind == 1 ? setOverallSignHeight : null}
                                            />   
                                            <BoardField
                                                label="Overall"
                                                suffix="In"
                                                type="number"
                                                value={sign.overall_size_width || "0"}
                                                disabled={sign.custom_ind == 0 || !canEdit}
                                                saveMutation={sign.custom_ind == 1 ? setOverallSignWidth : null}
                                            />
                                        </div>
                                    }
                                </BoardColumns>

                                {sign.warnings.filter(w => w.prop=="size").map((warning,index) => (
                                    <SignWarning key={index} warning={warning} />
                                ))}                                                                    
                            </BoardFields>
                        </BoardColumns>

                        {(sign.options || sign.note || (sign.options_cost && sign.options_cost != 0)) &&
                            <BoardFields label="Options" collapsible>
                                <BoardField
                                    label="Options Desc"
                                    info="User can see what is entered here"
                                    type="textarea"
                                    value={sign.options}
                                    disabled={!canEdit}
                                    saveMutation={setOptions}
                                />
                                <BoardField
                                    label="Options Note"
                                    info="User CANT see what is entered here"
                                    type="textarea"
                                    value={sign.note}
                                    disabled={!canEdit}
                                    saveMutation={setSignNote}
                                />                                
                            </BoardFields>                                 
                        } 
                        <BoardFields label="Products" collapsible collapsed>
                            <BoardField
                                type="commalist"
                                value={sign.products}
                            />
                        </BoardFields>

                        <BoardFields label="Description" collapsible collapsed>
                            <BoardField
                                type="html"
                                value={sign.description}
                                buttons={[
                                    {
                                        label: "Regenerate", 
                                        disabled: !canEdit,
                                        busy: regenerateDescription.isLoading,
                                        onClick: () => regenerateDescription.mutate(sign.id, {
                                            onError: () => window.alert("Error generating sign description")
                                        })
                                    }
                                ]}
                            />
                        </BoardFields>

                        <BoardFields label="Shipping" collapsible collapsed={sign.shipping_method_id}>
                            {sign.estimate_expired_ind == 1 &&
                                <div className="p-1">
                                    <Alert variant="danger" size="tiny">
                                        Shipping data is updated when expired sign price recalculated
                                    </Alert>
                                </div>
                            }
                            <BoardField
                                type="select"
                                label="Method"
                                selectProps={{
                                    itemsQuery: shippingMethodsQuery,
                                    labelField: "title"
                                }}
                                required
                                value={sign.shipping_method_id}
                                disabled={!canEdit}
                                infoWarning={sign.shipping_method_override_date ? "Shipping method was manually selected" : null}
                                saveMutation={setShippingMethod}
                            />
                            <BoardField
                                label="Adjustment"
                                type="currency"
                                value={sign.shipping_adjustment}
                                disabled={!canEdit}
                                format="currency"
                                saveMutation={setShippingAdjustment}
                            />    
                            <BoardField
                                label="Estimate"
                                type="label"
                                value={formatPrice(sign.shipping || 0) + " (base + parts + adjustment)"}
                            />       
                            <BoardField
                                label="Boxes"
                                type="label"
                                value={sign.shipping_boxes}
                            />                                     
                        </BoardFields>

                        <BoardField
                            label="User"
                            type="user"
                            value={sign.user_id}
                            disabled={!canEdit}
                            saveMutation={setSignUserId}
                        />

                        <BoardField
                            label="Created"
                            type="label"
                            value={
                                (sign.added_user || "Anonymous") + " on " + 
                                formatDateTime(sign.added_date_local || sign.added_date)
                            }
                        />

                        <BoardColumns columns={size == "small" ? 1:2}>
                            <BoardField
                                label="Source"
                                type="label"
                                value={sign.added_location}
                            /> 
                            {sign.template_sign_id && 
                                <BoardField
                                    label="Template"
                                    type="children"
                                >
                                    <Link to={"/signs/sign/" + sign.template_sign_id} className="text-blue-500">
                                        Sign #{sign.template_sign_id}
                                    </Link>
                                </BoardField>
                            }
                        </BoardColumns>

                        <div style={{display:"flex", gap:"10px", margin:"10px 3px"}}>
                            {onSignCloned && 
                                <ButtonProcessing
                                    size="sm"
                                    icon={faCopy}
                                    processing={busy == "clone"}
                                    caption="Clone Sign"
                                    variant="outline-secondary"
                                    onClick={() => {
                                        setBusy("clone");

                                        postData("mutate/signs/sign/clone", { 
                                            sign_id: sign.id
                                        },
                                            function(result) {
                                                onSignCloned(result);
                                            },
                                            function(error) {
                                                alert("Error cloning sign")
                                            },
                                            function() {
                                                setBusy("");
                                            }
                                        );                        
                                    }}
                                />
                            }
                            {onSignRemoved && 
                                <ButtonProcessing
                                    size="sm"
                                    icon={faBan}
                                    processing={busy == "remove"}
                                    caption="Remove From Project"
                                    variant="outline-danger"
                                    onClick={() => {
                                        if (window.confirm("Are you sure you want to remove this sign from the project?")) {
                                            setBusy("remove");

                                            postData(`signs/updateprop?sign_id=${sign.id}&prop=quote_id`, removeNulls({ 
                                                value: null
                                            }),
                                                function() {
                                                    onSignRemoved();
                                                },
                                                function(error) {
                                                    alert("Error updating site");
                                                },
                                                function() {
                                                    setBusy("");
                                                }
                                            );                             
                                        }
                                    }}
                                />
                            }
                        </div>
                    </div>
                }
            </Panel>

            {selected == "signprice" && 
                <SignPriceDrawer 
                    signId={signId} 
                    onHide={ () => setSelected("") } 
                /> 
            }      
        </>
    );
}

export default SignDetailsPanel