import React, { useState, useEffect } from "react";
import produce from "immer"
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    GoogleImageCell,
    ButtonCell,
    BooleanCell,
    EditActionsCell
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Loading from "../../components/loading"
import LandingPageDrawer from "../../drawers/drawer-landingpage"
import LandingPageSignsDrawer from "../../drawers/drawer-landingpagesigns"

import { postData } from "../../common/services/server"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

function LandingPagesPanel(props) {
    const windowDimensions = useWindowDimensions();

    const [pages, setPages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    const [busyRow, setBusyRow] = useState(null);

    useBodyClass(`noscroll`);

    useEffect(
        () => {
            setIsLoading(true);

            postData("pages/data", {},
                function(result) {
                    setPages(result);
                },
                function(error) {
                    alert("Error loading landing page data")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        [props.refresh]
    );

    function onAction(action, page) {
        console.log("action", action, action)

        if (action == "delete") {
            if (window.confirm("Delete this landing page?")) {
                setBusyRow(page);

                postData("pages/delete", {id: page.id},
                    function() {
                        setPages(produce(draft => {
                            draft.splice(draft.findIndex(t => t.id == page.id), 1);
                        }));
                    },
                    function(error) {
                        alert("Error deleting page.")
                    },
                    function() {
                        setBusyRow(null);
                    }
                ); 
            }
        }
        else
            setSelected({mode:action, page});
    }
    
    function onPageChanged(page) {
        setPages(produce(draft => {
            const index = draft.findIndex(t => t.id == page.id);

            draft[index] = page;
        }));

        setSelected(null);
    }

    function onSigns(page) {
        setSelected({mode:"signs", page});
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
                <Table
                    rowHeight={125}
                    rowsCount={pages.length}
                    width={windowDimensions.width - dimensions.dataTableWidthOffset}
                    height={windowDimensions.height - dimensions.headerHeight - (props.haveTabs ? dimensions.pageWithTabsPadding : dimensions.pagePadding)*2}
                    headerHeight={50}
                    touchScrollEnabled={true}
                >
                    <Column
                        header={<Cell>Type</Cell>}
                        columnKey="type"
                        fixed={!isMobile}
                        cell={<TextCell data={pages} />}
                        width={150}
                    />
                    <Column
                        header={<Cell>Title</Cell>}
                        columnKey="title"
                        cell={<TextCell data={pages} small />}
                        width={200}
                    />
                    <Column
                        header={<Cell>Preview</Cell>}
                        columnKey="banner_phone_url"
                        cell={<GoogleImageCell data={pages} maxwidth={125} maxheight={50} />}
                        width={150}
                    />
                    <Column
                        header={<Cell>Url</Cell>}
                        cell={({rowIndex, ...props}) => (
                            <Cell {...props}>
                                {'/pages/'}{pages[rowIndex].name}
                            </Cell>
                        )}
                        width={250}
                    />
                    <Column
                        header={<Cell>Keywords</Cell>}
                        columnKey="keywords"
                        cell={<TextCell data={pages} small />}
                        width={250}
                    />
                    <Column
                        header={<Cell>Description</Cell>}
                        columnKey="description"
                        cell={<TextCell data={pages} small />}
                        width={250}
                    />
                    <Column
                        header={<Cell>Signs</Cell>}
                        columnKey="sign_count"
                        cell={<ButtonCell data={pages} onClick={(page) => onSigns(page)} />}
                        width={70}
                    />
                    <Column
                        header={<Cell>Featured</Cell>}
                        columnKey="featured_ind"
                        cell={<BooleanCell data={pages} />}
                        width={90}
                    />
                    <Column
                        header={<Cell>Actions</Cell>}
                        cell={
                            <EditActionsCell 
                                data={pages} 
                                actions={[
                                    {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, permission:Permissions.LandingPages, permissionAction:Actions.Edit},
                                    {action:"delete", title:"Delete", variant:"danger", icon:faTrash, permission:Permissions.LandingPages, permissionAction:Actions.Delete},
                                ]}
                                busy={busyRow}
                                onClick={onAction} 
                            />
                        }
                        align="center"
                        width={85}
                    />
                </Table>
            }

            {(selected && selected.mode=="edit") && 
                <LandingPageDrawer 
                    page={selected.page} 
                    onSaved={onPageChanged} 
                    onHide={ () => setSelected(null) } 
                />
            } 
            {(selected && selected.mode=="signs") && 
                <LandingPageSignsDrawer 
                    page={selected.page} 
                    onChange={(signs) => {
                        setPages(produce(draft => {
                            const index = draft.findIndex(t => t.id == selected.page.id);
                
                            draft[index].sign_count = signs.length;
                        }));
                    }} 
                    onHide={ () => setSelected(null) } 
                />
            }             
        </>
    );
}

export default LandingPagesPanel;