import React, { useState, useEffect } from "react";
import styled from "styled-components"

import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup'

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

import SignSelect from "../components/fields/sign-select"

import Button from "../components/buttons/button";
import GoogleImage from "../components/google-image"
import { postData } from "../common/services/server"
import { formatPrice, removeNulls } from '../helpers/utility'
import Loading from "../components/loading";

const Signs = styled.table`
    width: 100%;
`

function SelectSignDrawer(props) {
  const [isLoading, setLoading] = useState(false);
  const [signs, setSigns] = useState([])

  useEffect(
    () => {
        if (props.filters) {
            setLoading(true);
            
            postData("signs/data", removeNulls({ 
                ...props.filters,
            }),
                function(result) {
                    setSigns(result.signs);
                },
                function(error) {
                    alert("Error loading signs")
                },
                function() {
                    setLoading(false);
                }
            );            
        }
    }, 
    [props.filters]
  );

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;
            Select Sign
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {(props.prompt && props.prompt.length > 0) && 
                <h3 style={{fontSize: "18px", marginBottom: "25px"}}>
                    {props.prompt}
                </h3>
            }
            <InputGroup className="mb-2">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        Select Sign
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <SignSelect
                    name="sign_id"
                    onChange={(sign) => {
                        console.log(sign)

                        //props.onSelect(sign)
                        setSigns(produce(draft => {
                            draft.unshift(sign);
                        }))
                    }}
                />
            </InputGroup>

            { isLoading ? 
                <Loading />
            : signs.length ?
                <Signs>
                <tbody>
                {signs.map((sign, index) => (
                    <tr key={index}>
                        <td>
                            <GoogleImage 
                                root 
                                src={sign.preview_url} 
                                style={{
                                    maxWidth: "100px",
                                    maxHeight: "35px"
                                }}
                            />
                        </td>
                        <td>
                            Design #{sign.id}
                        </td>
                        <td>
                            {formatPrice(sign.estimate)}
                        </td>
                        <td align="right">
                            <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={() => {
                                    props.onSelect(sign)
                                }}
                            >
                                Select
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
                </Signs>
            :
                <p>No Signs Found</p>                
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default SelectSignDrawer;