import Axios from 'axios';
import settings from '../../settings'
import { getToken, hasToken, setToken, logToken, getUser } from './auth'

Axios.interceptors.request.use(
    async (config) => {
        // ignore if its a login/auth request
        if (config.url.indexOf("auth/authenticate") == -1) {
            if (!hasToken()) {
                console.log("access token has expired")

                try {
                    const response = await fetch(settings.API_URL + "auth/refresh", {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                        },
                    });

                    const responseData = await response.json();

                    const token = responseData.token;

                    console.log("* response new auth token")
                    logToken(token)

                    setToken(token);

                    if (config?.headers) {
                        config.headers["Authorization"] = `Bearer ${token}`;
                    }
                }
                catch(err) { 
                    console.log(err);
                    window.location = '/#/login/' + encodeURIComponent((window.location.hash || "#/").substring(1));
                }    
            }
        }

        return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export function postData(url, data, onSuccess, onError, onBoth) {
    const token = getToken();
    const cancelToken = Axios.CancelToken;
    const source = cancelToken.source();

    const config = {
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token,
        },
        responseType: 'json',
        cancelToken: source.token,
        withCredentials: true,
     };

    let params = new URLSearchParams();

    for (const key of Object.keys(data)) {
        params.append(key, data[key]);
    }

    Axios.post(settings.API_URL + url, params, config)
      .then(function (response) {
        if (onSuccess)
            onSuccess(response.data);
        if (onBoth) 
            onBoth();
      })
      .catch(function (error) {
        if (Axios.isCancel(error)) 
            console.log("Request was canceled");
        else {
            // did their token/session expire?
            if (error && error.response && error.response.status == 403) {
                window.location = '/#/login/' + encodeURIComponent((window.location.hash || "#/").substring(1))
                return;
            }

            console.log("error status", error.response ? error.response.status : -1);
            if (onError)
                onError(error.message, error.response ? error.response.status : -1, error.response);
            if (onBoth) 
                onBoth();
        }
      });

    return source;
}

export function postExternalData(url, data, onSuccess, onError, onBoth) {
    const config = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'json'
     };

    let params = new URLSearchParams();

    for (const key of Object.keys(data)) {
        params.append(key, data[key]);
    }

    const user = getUser();

    if (user)
        params.append("current_user_id", user.id);

    Axios.post(url, params, config)
      .then(function (response) {
        //console.log(response);
        if (onSuccess)
            onSuccess(response.data);
        if (onBoth) 
            onBoth();
      })
      .catch(function (error) {
        console.log(error);
        if (onError)
            onError(error);
        if (onBoth) 
            onBoth();
      });
}

export function getJsonData(url, onSuccess, onError, onBoth) {
    getData(url, 'json', onSuccess, onError, onBoth)
}

export function getData(url, responseType, onSuccess, onError, onBoth) {
    const token = getToken();
    const cancelToken = Axios.CancelToken;
    const source = cancelToken.source();

    const config = {
        headers: { 
            'Authorization': 'Bearer ' + token,
        },
        responseType: responseType,
        cancelToken: source.token,
        withCredentials: true,
     };

    async function fetchData() {
      try {
        const result = await Axios(url, config);

        if (onSuccess)
            onSuccess(result.data);
        if (onBoth) 
            onBoth()
      }
      catch (error) {
        if (Axios.isCancel(error)) 
            console.log("Request was canceled");
        else {
            // did their token/session expire?
            if (error && error.response && error.response.status == 403) {
                window.location = '/#/login/' + encodeURIComponent((window.location.hash || "#/").substring(1))
                return;
            }

            console.log("error status", error.response ? error.response.status : -1);
            if (onError)
                onError(error.message, error.response ? error.response.status : -1);
            if (onBoth) 
                onBoth();
        }
      }
    }

    fetchData();

    return source;
}

export function uploadImage(url, image, thumbnail, preview, onProgress, onSuccess, onError, onBoth) {
    //console.log("server.uploadImage", url, image);

    var data = new FormData();

    if (image.files)
        data.append('image', image.files[0]);
    else if (image.name)
        data.append('image', image);
    else 
        data.append('image', JSON.stringify(image)); // manual image props (used for background where we don't want to upload the original image)
    if (thumbnail)
        data.append('thumbnailData', thumbnail);
    if (preview)
        data.append('previewData', preview);

    const user = getUser();

    if (user)
        data.append("current_user_id", user.id);
    
    const token = getToken();
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token,
        withCredentials: true,
      },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        if (onProgress)
            onProgress(percentCompleted);
      }
    };

    Axios.post(url.indexOf("://") === -1 ? settings.API_URL + url : url, data, config)
        .then(function (response) {
            //console.log(response);

            if (response.data.error && response.data.error.length > 0)
                onError(response.data.error);
            else if (onSuccess)
                onSuccess(response.data);
            if (onBoth) 
                onBoth();
        })
        .catch(function (error) {
            console.log(error);
            if (onError)
                onError(error.message);
            if (onBoth) 
                onBoth();
        });
    
}

export function uploadFile(url, paramName, fileName, fileData, onProgress, onSuccess, onError, onBoth) {
    //console.log("server.uploadImage", url, image);

    var data = new FormData();

    data.append(paramName, fileData);
    data.append("filename", fileName);

    const user = getUser();

    if (user && user.id > 0)
        data.append("current_user_id", user.id);

    var config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        if (onProgress)
            onProgress(percentCompleted);
      }
    };

    Axios.post(url.indexOf("://") === -1 ? settings.API_URL + url : url, data, config)
        .then(function (response) {
            //console.log(response);

            if (response.data.error && response.data.error.length > 0)
                onError(response.data.error);
            else if (onSuccess)
                onSuccess(response.data);
            if (onBoth) 
                onBoth();
        })
        .catch(function (error) {
            //console.log(error);
            if (onError)
                onError(error.message);
            if (onBoth) 
                onBoth();
        });
    
}