import React, { useState, useEffect } from "react";
import styled from "styled-components"
import Form from 'react-bootstrap/Form';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import produce from "immer"

import Loading from "../../components/loading"

import { postData } from "../../common/services/server"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines, faCaretDown, faCaretUp, faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import ButtonProcessing from "../../components/buttons/button-processing";
import { colors } from "../../settings/settings";
import { Badge } from "react-bootstrap";

const DragList = styled.div`
  .App {
  }
  .list-container {
    display: flex;
    background-color: #eee;
    flex-direction: column;
    width: 100%;
  }
  .item-container {
    background-color: ${colors.lightPurple};
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
        cursor: grab;
    }
  }
`

function UserSkillsPanel(props) {
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [busy, setBusy] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    useEffect(
        () => {
          setIsLoading(true);

          postData("users/skills", {
              user_id: props.user.id,
              permissions_ind: 1
            },
            function(result) {
                setRoles(result);
            },
            function(error) {
                alert("Error loading skills")
            },
            function() {
              setIsLoading(false);
            }
          );       
        }, 
        [props.user]
    );
    
    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        console.log(droppedItem);

        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;

        const sourceListOrder = droppedItem.source.index;
        const destListOrder = droppedItem.destination.index;

        console.log({sourceListOrder});
        console.log({destListOrder});

        setRoles(produce(draft => {
            for (const role of draft.filter(r => r.checked_ind == 1)) {
                if (role.list_order == sourceListOrder) {
                    // this is the dropped item
                    role.list_order = destListOrder;
                    console.log("changing", role.name, role.list_order, destListOrder);
                }
                else if (sourceListOrder < destListOrder && role.list_order <= destListOrder) {
                    // being shifted uup
                    role.list_order--;
                    console.log("shifting up", role.name, role.list_order);

                }
                else if (role.list_order < destListOrder) {
                    // nothing since it's staying in place
                    console.log("same", role.name, role.list_order);
                }
                else {
                    // being shifted down
                    role.list_order++;
                    console.log("shifting down", role.name, role.list_order);
                }
            }
        }));
    };

    useEffect(
        () => {
            if (!roles || roles.length == 0) return;

            let data = {}

            for (const role of roles.filter(r => r.checked_ind == 1)) {
                data[role.name] = role.list_order;
            }

            console.log(data);

            postData("users/setrolesorder", {
                    ...data,
                    user_id: props.user.id
                },
                function(result) {
                    // nada
                },
                function(error) {
                    alert("Error updating user roles")
                }
            );
        }, 
        [roles]
    );

    function onCheck(e) {
        const roleId = e.target.value;
        const checked = e.target.checked;

        const maxListOrder = roles.reduce(function(prev, current) {
            return (prev && prev.list_order > current.list_order) ? prev.list_order : current.list_order
        }) 

        console.log({maxListOrder});

        setRoles(produce(draft => {
          const index = draft.findIndex(r => r.id == roleId);
                  
          console.log(((maxListOrder||0)+1));

          draft[index].checked_ind = checked ? 1 : 0; 
          draft[index].list_order = checked ? ((maxListOrder||0)+1) : 0; 
        }));

        setBusy(produce(draft => {
            if (draft.indexOf(""+roleId) == -1)
                draft.push(""+roleId);
        }))

        postData("users/togglerole", {
            user_id: props.user.id,
            role_id: roleId,
            checked_ind: checked ? 1:0
          },
          function(result) {              
          },
          function(error) {
              alert("Error updating roles")
          },
          function() {
            setBusy(produce(draft => {
                const index = draft.indexOf(""+roleId);
                if (index != -1)
                    draft.splice(index, 1)
            }))    
          }
        );
    }


    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
                <div>
                    {(roles.filter(r => r.checked_ind == 1).length == 0) && 
                        <div className="p-2 text-gray-400">
                            No Skills Found
                        </div>
                    }

                    <DragList>
                        <DragDropContext onDragEnd={handleDrop}>
                            <Droppable droppableId="list-container">
                            {(provided) => (
                                <div
                                    className="list-container"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                {roles
                                    .filter(r => r.checked_ind == 1)
                                    .sort((a, b) => a.list_order < b.list_order ? -1:1)
                                    .map((item, index) => (
                                    <Draggable key={item.id} draggableId={""+item.id} index={item.list_order}>
                                    {(provided) => (
                                        <div
                                            className="item-container"
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            >

                                            <Form.Check 
                                                type="checkbox"
                                                value={item.id}
                                                checked={true}
                                                label={item.name}
                                                onChange={onCheck}                    
                                            />

                                            {busy.indexOf(""+item.id) != -1 ?
                                                <FontAwesomeIcon icon={faSpinner} spin />
                                            :
                                                <FontAwesomeIcon icon={faGripLines} />
                                            }                                                
                                        </div>
                                    )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                </div>
                            )}
                            </Droppable>
                        </DragDropContext>
                    </DragList>

                    <br />

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "33.3% 33.3% 33.3%",
                        gap: "5px"
                    }}>
                        {roles.filter(r => r.checked_ind == 0).map(role => (
                            <div key={role.id} className={role.checked_ind == 1 ? "checked":""} style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                padding: "2px",
                                marginBottom: "2px",
                            }}>
                                <div>
                                    {role.name}
                                </div>

                                <ButtonProcessing
                                    caption="Add Skill"
                                    icon={faPlus}
                                    size="sm"
                                    variant="outline-success"
                                    processing={busy.indexOf(""+role.id) != -1}
                                    onClick={() => {
                                        onCheck({
                                            target: {
                                                value: role.id,
                                                checked: true
                                            }
                                        })
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            }
        </>
    );
}

export default UserSkillsPanel;