import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"
import { useHistory, Link } from "react-router-dom";
import ToggleButton from 'react-bootstrap/ToggleButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell, 
    CurrencyCell,
    DateCell,
    LinkCell,
    SortHeaderCell,
    SortTypes,
} 
from "../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"
import Paginator from "../components/paginator"
import DateField from "../components/fields/field-date"

import { postData } from "../common/services/server"
import { useBodyClass } from "../hooks/useBodyClass"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { dimensions } from "../settings/settings"
import { subYears } from "date-fns";
import { formatDate, formatPrice } from "../helpers/utility";

const Report = styled.div`
    @media print{@page {size: landscape}}

    table.data {
        display: none;
    }

    @media print
    {    
        .fixedDataTableLayout_main {
            display: none;
        }
        .paginator {
            display: none;
        }
        table.data {
            display: block;
            font-size: 12px;
            
            th {
                background-color: #eee;
                border: 1px solid #ccc;
                padding: 3px;
            }
            td {
                border: 1px solid #ccc;
                padding: 3px;
                vertical-align: top;
            }
        }
    }
`

const Filters = styled.div`
    display: flex;
    margin-bottom: 8px;
    margin-top: -8px;

    > * {
        margin-right: 8px;
    }

    label {
        background-color: #eee;
        padding: 15px 10px;
        border-radius: 5px;
        margin: 0px;
        margin-right: 10px;
    }
    span {
        font-size: 10px;
    }
`

function ShippingReport(props) {
    const history = useHistory();

    const windowDimensions = useWindowDimensions();

    const [data, setData] = useState([]);    
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [pagination, setPagination] = useState({page:1,pages:1,total:0});
    const [sort, setSort] = useState({column: "order_date", dir: SortTypes.DESC});
    const [filters, setFilters] = useState({
        start_date: subYears(new Date(), 1),
        end_date: new Date()
    });

    useBodyClass(`noscroll`);

    useEffect(
        () => {
            setLoading(true);

            postData("reports/shippingcomparison", {
                start_date: formatDate(filters.start_date, "yyyy-MM-dd"),
                end_date: formatDate(filters.end_date, "yyyy-MM-dd"),
                sort: sort.column,
                sortdir: sort.dir,
                page,
                perPage,
            },
                function(result) {
                    setData(result.data);
                    setPagination(result.pagination);
                },
                function(error) {
                    window.alert("Error loading data")
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        [page, perPage, sort]
    );

    function onSortChange(columnKey, sortDir) {
        setPage(1);
        setSort({ column: columnKey, dir: sortDir })
    }

    function onFilterChange(filter, value) {
        setFilters(produce(draft => {
            draft[filter] = value
        })); 
    }

    function onToPayInd(itemId, toPayInd) {
        setData(produce(draft => {
            const index = draft.findIndex(e => e.item_id == itemId);
            draft[index].topay_ind = toPayInd;
            draft[index].topay_date = new Date();
        }));

        postData("orders/setpayshipping", {
            orderitem_id: itemId,
            pay_ind: toPayInd
        },
            function(result) {

            },
            function(error) {
                window.alert("Error updating shipping data")
            }
        );
    }

    console.log({filters})
    return (
        <>
            <PageHeader 
                title="Shipping Comparison Report"
                help="shippingreport"
            />
            <Content>
                {
                    loading
                ?
                    <Loading />
                :  
                    <Report>
                        <Filters>
                            <label>Filters:</label>
                            <div>
                                <span>Start Date</span>
                                <DateField 
                                    name="start_date" 
                                    value={filters.start_date}                                     
                                    onChange={(e) => {
                                        onFilterChange("start_date", e.target.value);
                                    }}
                                    groupStyle={{margin: "0px"}}
                                    size="sm"
                                />
                            </div>
                            <div>
                                <span>End Date</span>
                                <DateField 
                                    name="end_date" 
                                    value={filters.end_date}                                     
                                    onChange={(e) => {
                                        onFilterChange("end_date", e.target.value);
                                    }}
                                    groupStyle={{margin: "0px"}}
                                    size="sm"
                                />
                            </div>
                        </Filters>                        
                        <Table
                            rowHeight={45}
                            rowsCount={data.length}
                            width={windowDimensions.width - dimensions.dataTableWidthOffset}
                            height={windowDimensions.height - dimensions.dataTableHeightOffset - 15}
                            headerHeight={35}
                            touchScrollEnabled={true}
                        >
                            <Column
                                header={
                                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                        Order
                                    </SortHeaderCell>
                                }
                                fixed
                                columnKey="id"
                                cell={
                                    <LinkCell 
                                        data={data} 
                                        urlPrefix="#/orders/order/"
                                        onClick={(order) => history.push("/orders/order/" + order.id)} 
                                    />
                                }
                                width={95}
                            />
                            <Column
                                header={
                                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                        Order Date
                                    </SortHeaderCell>
                                }
                                columnKey="order_date"
                                cell={<DateCell data={data} />}
                                width={125}
                            />
                            <Column
                                header={
                                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                        Jobs
                                    </SortHeaderCell>
                                }
                                columnKey="jobs"
                                cell={({rowIndex, ...props}) => {
                                    const item = data[rowIndex];
                                    const jobs = item.jobs ? item.jobs.split(",") : [];

                                    return (
                                      <Cell {...props}>
                                            {jobs.map(job => (
                                                <div key={job}>
                                                    <Link to={"/jobs/job/" + job}>{job}</Link>
                                                </div>
                                            ))}
                                      </Cell>
                                )}}
                                width={200}
                            />
                            <Column
                                header={<Cell>Customer</Cell>}
                                columnKey="name"
                                cell={<TextCell data={data} />}
                                width={175}
                            />
                            <Column
                                header={<Cell>City</Cell>}
                                columnKey="ship_city"
                                cell={({rowIndex, ...props}) => {
                                    const item = data[rowIndex];
                                    
                                    return (
                                      <Cell {...props}>
                                          {item.ship_city} {item.abbrev2}
                                      </Cell>
                                )}}
                                width={175}
                            />
                            <Column
                                header={<Cell>Item</Cell>}
                                columnKey="title"
                                cell={<TextCell data={data} />}
                                width={275}
                            />
                            <Column
                                header={<Cell>Purchase Code</Cell>}
                                columnKey="coupon_code"
                                cell={({rowIndex, ...props}) => {
                                    const item = data[rowIndex];
                                    
                                    return (
                                      <Cell {...props}>
                                          {item.coupon_code && <>{item.coupon_code} - {item.coupon_type} {item.coupon_amount && <>(${item.coupon_amount})</>}</>}
                                      </Cell>
                                )}}
                                width={200}
                            />
                            <Column
                                header={
                                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                        Ship Date
                                    </SortHeaderCell>
                                }
                                columnKey="ship_date"
                                cell={<DateCell data={data} />}
                                width={125}
                            />                            
                            <Column
                                header={<Cell>Estimate</Cell>}
                                columnKey="shipping_estimate"
                                cell={<CurrencyCell data={data} />}
                                width={100}
                            />
                            <Column
                                header={<Cell>Surcharge</Cell>}
                                columnKey="shipping_surcharge"
                                cell={<CurrencyCell data={data} />}
                                width={100}
                            />
                            <Column
                                header={<Cell>Actual</Cell>}
                                columnKey="shipping_actual"
                                cell={<CurrencyCell data={data} na />}
                                width={100}
                            />
                            <Column
                                header={<Cell>Difference</Cell>}
                                columnKey="shipping_difference"
                                cell={<CurrencyCell data={data} colorize />}
                                width={100}
                            />
                            <Column
                                header={<Cell>Order Total</Cell>}
                                columnKey="total"
                                cell={<CurrencyCell data={data} />}                              
                                width={110}
                            />
                            <Column
                                header={<Cell>To Pay</Cell>}
                                columnKey="topay_ind"
                                cell={({rowIndex, ...props}) => {
                                    const item = data[rowIndex];
                                    
                                    return (
                                        <Cell {...props}>
                                            <ButtonGroup toggle size="sm">
                                                <ToggleButton
                                                    type="radio"
                                                    variant="outline-success"
                                                    name="radio"
                                                    value={1}
                                                    checked={item.topay_ind == 1}
                                                    onChange={(e) => onToPayInd(item.item_id, e.currentTarget.value)}
                                                >
                                                    Yes
                                                </ToggleButton>
                                                <ToggleButton
                                                    type="radio"
                                                    variant="outline-danger"
                                                    name="radio"
                                                    value={0}
                                                    checked={item.topay_ind == 0}
                                                    onChange={(e) => onToPayInd(item.item_id, e.currentTarget.value)}
                                                >
                                                    No
                                                </ToggleButton>
                                            </ButtonGroup>
                                        </Cell>
                                    )
                                }}
                                width={100}
                            />
                            <Column
                                header={
                                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                        To Pay Date
                                    </SortHeaderCell>
                                }
                                columnKey="topay_date"
                                cell={<DateCell data={data} />}
                                width={135}
                            />
                        </Table>
                        <Paginator
                            {...pagination}
                            item="Order"
                            items="Orders"
                            onPage={(page) => {
                                console.log("page", page)
                                setPage(page);
                            }}
                            perPage={perPage}
                            onPerPage={(value) => {
                                setPerPage(value);
                            }}
                        />
                        <table className="data">
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Order Date</th>
                                    <th>Jobs</th>
                                    <th>Customer</th>
                                    <th>City</th>
                                    <th>Item</th>
                                    <th>Purchase Code</th>
                                    <th>Estimate</th>
                                    <th>Surcharge</th>
                                    <th>Actual</th>
                                    <th>Difference</th>
                                    <th>Order Total</th>
                                    <th>To Pay</th>
                                    <th>To Pay Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row,index) => (
                                    <tr key={index}>
                                        <td>{row.id}</td>
                                        <td>{formatDate(row.order_date)}</td>
                                        <td>{row.jobs}</td>
                                        <td>{row.name}</td>
                                        <td>{row.ship_city}</td>
                                        <td>{row.title}</td>
                                        <td>{row.coupon_code}</td>
                                        <td>{formatPrice(row.shipping_estimate)}</td>
                                        <td>{formatPrice(row.shipping_surcharge)}</td>
                                        <td>{formatPrice(row.shipping_actual)}</td>
                                        <td>{formatPrice(row.shipping_difference)}</td>
                                        <td>{formatPrice(row.total)}</td>
                                        <td>{row.topay_ind == 1 ? "Yes" : row.topay_ind == 0 ? "No" : ""}</td>
                                        <td>{formatDate(row.topay_date)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Report>
                }
            </Content>
        </>
    );
}

export default ShippingReport;