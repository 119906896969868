import React, { useState, useEffect } from "react"
import { Chart } from 'react-google-charts'
import { format, subYears } from 'date-fns'

import ChartBox from "./chartbox"
import ListField from "../fields/field-list"

import { postData } from "../../common/services/server"

function OrdersCurrentMonthChart(props) {
    const [years, setYears] = useState([]);
    const [year, setYear] = useState(format(new Date(), "yyyy"));
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [error, setError] = useState(null);
  
    useEffect(
        () => {
            let yearOptions = [];

            for (let i=0; i<5; i++) {
                const dateOption = subYears(new Date(), i);

                yearOptions.push({value: format(dateOption, "yyyy"), label: format(dateOption, "yyyy")});
            }

            setYears(yearOptions);
        }, 
        []
    );

    useEffect(
        () => {
            setLoading(true);

            postData("orders/chart/year", { year },
                function(result) {
                    setData(result);
                },
                function(error) {
                    setError("Error loading data")
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        [year, refresh]
    );

    return (
        <ChartBox 
            title={"Yearly Orders"}
            height={300}
            loading={loading}
            error={error}
            refresh={true}
            onRefresh={() => setRefresh(refresh++)}
        >
            <ListField 
                name="year" 
                value={year} 
                list={years}
                onChange={(e) => {
                    setYear(e.target.value)
                }} 
            />

            <Chart 
                width={'100%'}
                height={'300px'}
                chartType="ColumnChart"
                loader={<div>Loading Chart</div>}
                data={data} 
                options={{
                    chartArea: {width: '85%', height: '75%', top: 10, right: 0},
                    hAxis: {
                      title: 'Month',
                      showTextEvery: 1
                    },
                    vAxis: {
                      title: '',
                    },
                    legend: 'none',
                    tooltip: {isHtml: true}
                }}
            />
        </ChartBox>
    );
}

export default OrdersCurrentMonthChart