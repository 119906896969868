import React from "react";
import Form from 'react-bootstrap/Form';

function FieldLabel(props) {
    return (
        <Form.Label 
            className={props.className}
            style={props.style || {fontWeight: props.bold ? "500":"normal"}}
        >
            {props.children}
            {props.required && <strong style={{color:"red"}}>*</strong>}
        </Form.Label>
    )
}

export default FieldLabel