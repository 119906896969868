import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from 'react-bootstrap/Modal';

import { faImage, faSave } from '@fortawesome/free-solid-svg-icons'

import GoogleImage from "../components/google-image"
import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import settings from "../settings"
import storage from "../settings/storage"

const ShapeFrame = styled.iframe`

`
const ShapeFiles = styled.div`
  display: flex;

  > * {
    height: 58px;
    border: 1px solid #eee;
    border-radius: 4px;
    display: block;
    padding: 5px;
    vertical-align: middle;
    margin: 3px;
    cursor: pointer;
  }
  img {
    height: 50px;
    width: auto;
  }
`

function JobShapeGraphicsDrawer(props) {  
  const [processing, setProcessing] = useState(false);


  function downloadImage(imageData, filename) {
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        a.remove()
      };
      xhr.open('GET', imageData); // This is to download the canvas Image
      xhr.send();
  }

  function onMessage(event) {
    console.log("Admin Builder message", event.data);

    if (event.data && event.data.type && event.data.type == "Capture") {
      setProcessing(false);
      downloadImage(event.data.image, event.data.filename)
    }
  }

  useEffect(() => {
    window.addEventListener("message", onMessage);
  
    return () => window.removeEventListener("message", onMessage);
  });

  function onSave() {
    setProcessing(true);

    document.getElementById('JobShapeGraphicsFrame').contentWindow.postMessage("Capture", "*")
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faImage} />
            &nbsp;
            Job Shape Graphics
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShapeFrame 
                id="JobShapeGraphicsFrame"
                src={settings.BUILDER_BASE_URL + "?uid=" + props.sign.uid + "&signgroup_id=" + props.shape.signgroup_id + "#/groupimage"} 
                title="Sign Builder" 
                width="100%" 
                height={500} 
            />

            <ShapeFiles>
              {props.shape.faceart_url && 
                <GoogleImage
                    root 
                    src={props.shape.faceart_url_thumb || props.shape.faceart_url} 
                    link={props.shape.faceart_url}
                />   
              }
              { props.job.sign_custom_ind == 1 &&
                <>
                  <GoogleImage
                      root 
                      src={props.job.sign_preview_file_preview_url} 
                      link={props.job.sign_preview_file_url}
                  />   
                  <a href={storage.root + props.job.sign_technical_file_ur} target="_blank">
                    {props.job.sign_technical_filename}
                  </a>
                </>
              }
              {props.shape.graphics.filter(g => g.faceart_url != null).map(graphic => (
                  <GoogleImage
                      key={graphic.id}
                      root 
                      src={graphic.faceart_preview_url} 
                      link={graphic.faceart_url}
                  />   
              ))}
            </ShapeFiles>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={processing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Image" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default JobShapeGraphicsDrawer;