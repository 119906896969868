import React, { useState, useEffect } from "react";
import produce from "immer";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";

import Loading from "../../components/loading"
import GoogleImage from "../../components/google-image"
import GroupPreview from "../../components/group-preview"
import Button from "../../components/buttons/button";
import WizardNav from "../../components/wizard-nav";
import FieldLabel from "../../components/fields/field-label";
import Pill from "../../components/pill";
import Panel from "../../panels/panel";
import ButtonMutate from "../../components/buttons/button-mutate";
import { List, ListItem } from "../../components/list"

import { postData } from "../../common/services/server";
import { formatDateTime } from "../../helpers/utility";

import { getIconForType } from "../../helpers/icons";
import JobWorkflowShapesPanel from "../../panels/jobworkflow-shapes";
import JobWorkflowColorsPanel from "../../panels/jobworkflow-colors";
import JobWorkflowLEDsPanel from "../../panels/jobworkflow-leds";
import JobWorkflowMaterialsPanel from "../../panels/jobworkflow-materials";
import JobWorkflowWarningsPanel from "../../panels/jobworkflow-warnings";
import JobWorkflowWorkflowChecksPanel from "../../panels/jobworkflow-workflowchecks";
import JobWorkflowSchedulePanel from "../../panels/jobworkflow-schedule";
import { useGetJobData, useGetJobNotes, useGetNewJobData } from "../../api/queries/workflowjobs";
import { useWorkflowJobCreate, useWorkflowJobToggleNote, useWorkflowJobUpdateProp } from "../../api/mutations/workflowjobs";
import { JobStatuses } from "../../helpers/data";
import Error from "../../components/error";
import { useGetOrderNotes } from "../../api/queries/orders";
import JobWorkflowPartsPanel from "../../panels/jobworkflow-parts";

function JobsStep({order, orderItem, onJob}) {
    const [selected, setSelected] = useState(new Set());

    const getNewJobData = useGetNewJobData(order.id, orderItem.id);
    const jobs = getNewJobData.data;

    console.log(order, orderItem, jobs)

    useEffect(
        () => {
            if (jobs) {
                let selectedData = [];

                for (const job of jobs) {
                    for (const group of job.groups) {
                        selectedData.push(group.id);
                    }
                }

                setSelected(new Set(selectedData));
            }
        }, 
        [jobs]
    );

    const createNewJob = useWorkflowJobCreate({
        onSuccess: (result) => {
            onJob(result);
        }
    })

    function jobGroupsSelCount(job) {
        let count = 0;

        for (const group of job.groups) {
            if (selected.has(group.id))
                count++;
        }

        return count;
    }

    return (
        <Panel query={getNewJobData}>
            {jobs && 
                <List direction="horizontal">
                    {jobs.map(job => (
                        <ListItem key={job.id} job={job}>
                            <div className="grid gap-2">
                                <div>
                                    <h2 className="text-lg font-bold text-center bg-gray-300 p-2">
                                        {job.title}
                                    </h2>
                                    <h3 className="text-center bg-gray-200 p-2">
                                        {job.product_title} ({job.mwo_title})
                                    </h3>       
                                </div>     

                                <List className="">
                                    {(job.groups || []).map(group => (
                                        <ListItem 
                                            key={group.id} 
                                            className={clsx(
                                                "grid grid-cols-[60px,1fr] !items-start gap-2",
                                                !selected.has(group.id) && "!bg-gray-100",
                                            )}
                                        >
                                            <div className="flex items-center justify-center self-center p-2">
                                                <input 
                                                    type="checkbox"
                                                    className="h-5 w-5"
                                                    checked={selected.has(group.id)}
                                                    onChange={(e) => {                                                        
                                                        const selectData = new Set(selected);

                                                        if (e.target.checked)
                                                            selectData.add(group.id);
                                                        else 
                                                            selectData.delete(group.id);

                                                        setSelected(selectData);                                        
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <GroupPreview group={group} solid={true} width={100} height={50} />
                                            </div>
                                        </ListItem>
                                    ))}
                                </List>
                                <div className="">
                                    <ButtonMutate
                                        icon={faPlus}
                                        caption="Create Job"
                                        variant="outline-success"
                                        mutation={createNewJob}
                                        disabled={jobGroupsSelCount(job) == 0}
                                        mutationData={{
                                            order_id: job.order.id,
                                            orderitem_id: job.orderitem_id,
                                            product_id: job.product_id,
                                            signgroup_ids: [...selected].join(","),
                                        }}
                                    />
                                </div>
                            </div>
                        </ListItem>
                    ))}
                </List>
            }
        </Panel>
    )
}

function NotesStep({job}) {
    const notesQuery = useGetJobNotes(job.id);
    const notes = notesQuery.data;

    const [selected, setSelected] = useState([]);

    const toggleNote = useWorkflowJobToggleNote(job.id);

    useEffect(
        () => {
            if (notes) {
                setSelected(notes.filter(n => n.checked_ind == 1).map(n => n.id))
            }
        }, 
        [notes]
    );

    return (
        <Panel query={notesQuery}>
            {notes &&
                <List className="mt-2 w-full mb-20">
                {notes.map(note => (
                    <ListItem
                        key={note.id} 
                        disabled={!selected.includes(note.id)}
                        className="grid grid-cols-[60px,1fr] !items-start gap-2"
                    >
                        <div className="flex items-center justify-center self-center p-2">
                            <input 
                                type="checkbox"
                                className="h-5 w-5"
                                checked={selected.includes(note.id)}
                                disabled={toggleNote.isLoading && toggleNote.variables?.noteId == note.id}
                                onChange={(e) => {
                                    toggleNote.mutate({noteId: note.id, activeInd: e.target.checked ? 1:0});

                                    if (e.target.checked)
                                        setSelected(produce(draft => {
                                            draft.push(note.id)
                                        }));
                                    else {
                                        setSelected(produce(draft => {
                                            let idx = draft.indexOf(note.id);

                                            if (idx != -1)
                                                draft.splice(idx, 1);
                                        }))
                                    }
                                }}
                            />
                        </div>                    
                        <div className="grid gap-1">
                            <div className="bg-yellow-300 p-1 rounded flex items-center gap-2 text-sm">
                                <FontAwesomeIcon icon={getIconForType("note")} size="lg" />
                                {note.notes}
                            </div>                        
                            <div className="flex items-center gap-1">
                                {note.user_title && 
                                    <Pill icon={getIconForType("user")}>
                                        User: {note.user_title}
                                    </Pill>
                                }
                                {note.project_title && 
                                    <Pill icon={getIconForType("project")}>
                                        Project: {note.project_title}
                                    </Pill>
                                }                                  
                                {note.sign_title && 
                                    <Pill icon={getIconForType("sign")}>
                                        Sign: {note.sign_title}
                                    </Pill>
                                }                            
                            </div>
                            <div className="text-xs text-gray-500">
                                Added by {note.added_user_name} on {formatDateTime(note.added_date)}
                            </div>
                        </div>
                    </ListItem>
                ))}
                </List>
            }
        </Panel>
    )
}

function DoneStep({job}) {
    const updateJobStatus = useWorkflowJobUpdateProp(job.id, "status_id");

    useEffect(
        () => {
            updateJobStatus.mutate(JobStatuses.wait.id);
        }, 
        []
    );

    return (
      <>
        {updateJobStatus.isLoading ?
            <Loading />
        : updateJobStatus.error ?
            <Error message={updateJobStatus.error.message} className="m-3" />
        :
            <div className="pt-2 pl-1 text-lg">
                <a
                    href={"#/workflowjob/" + job.id}
                    className="text-blue-500"
                >
                    View Workflow Job <strong>{job.title}</strong>    
                </a>
            </div>
        }
      </>
    )
}

function NotesPanel({order}) {
    const notesQuery = useGetOrderNotes(order.id);
    const notes = notesQuery.data;

    return (
        <Panel query={notesQuery} className="flex flex-col gap-1 h-[calc(100vh-240px)] overflow-y-auto overflow-x-hidden">            
            {notes && notes.map(note => (
                <div key={note.id} className="flex flex-col gap-1 bg-yellow-200 p-1 rounded text-sm">
                    {note.notes}
                    <div className="flex items-center gap-1 whitespace-nowrap">
                        {note.user_title && 
                            <Pill icon={getIconForType("user")} size="sm" className="!bg-yellow-100 !border-yellow-300">
                                User: {note.user_title}
                            </Pill>
                        }
                        {note.project_title && 
                            <Pill icon={getIconForType("project")} size="sm" className="!bg-yellow-100 !border-yellow-300">
                                Project: {note.project_title}
                            </Pill>
                        }                                  
                        {note.sign_title && 
                            <Pill icon={getIconForType("sign")} size="sm" className="!bg-yellow-100 !border-yellow-300">
                                Sign: {note.sign_title}
                            </Pill>
                        }                            
                    </div>
                    <div className="text-xs text-gray-500">
                        Added by {note.added_user_name} on {formatDateTime(note.added_date)}
                    </div>
                </div>
            ))}  

            {(notes && notes.length == 0) && 
                <div className="p-2 text-center text-gray-400">
                    No Notes Found
                </div>
            }               
        </Panel>
    )
}


function OrderWorkflowJobPanel({order, orderItem}) {
    const [job, setJob] = useState(null);

    // const {data:job, isLoading, error} = useGetJobData(54);

    const [steps, setSteps] = useState([
        {name: "jobs", title: "Jobs", component: JobsStep},
        {name: "shapes", title: "Shapes", component: JobWorkflowShapesPanel},
        {name: "colors", title: "Colors", component: JobWorkflowColorsPanel},
        {name: "leds", title: "LEDs", component: JobWorkflowLEDsPanel},
        {name: "materials", title: "Materials", component: JobWorkflowMaterialsPanel},
        {name: "parts", title: "Parts", component: JobWorkflowPartsPanel},
        {name: "workflow", title: "Workflow", component: JobWorkflowWorkflowChecksPanel},
        {name: "schedule", title: "Schedule", component: JobWorkflowSchedulePanel},
        {name: "warnings", title: "Warnings", component: JobWorkflowWarningsPanel},
        {name: "notes", title: "Notes", component: NotesStep},
        {name: "done", title: "Done", component: DoneStep, last:true},
    ]);
    const [currentStep, setCurrentStep] = useState(steps[0]);

    useEffect(
        () => {
            if (job)
                setCurrentStep(steps[1]);
        }, 
        [job]
    );

    console.log(steps);
    console.log({currentStep})
    return (
        <>
            <div className="">
                <div className="flex items-center justify-between gap-2 mb-2">
                    <div>
                        {orderItem.sign_id ? 
                            <GoogleImage 
                                root 
                                src={orderItem.preview_url} 
                                style={{
                                    maxWidth: "125px",
                                    maxHeight: "75px"
                                }}
                            />
                        :
                            <GoogleImage 
                                root 
                                src={orderItem.preview_url} 
                                style={{
                                    maxWidth: "125px",
                                    maxHeight: "75px"
                                }}
                            />
                        }
                    </div>
                    <WizardNav
                        steps={steps}
                        current={currentStep}
                        size="sm"
                        disabled={!job}
                        onPrevStep={(step) => setCurrentStep(step)}
                        onNextStep={orderItem.job_id ? (step) => setCurrentStep(step) : null}
                    />
                </div>

                <div className="grid grid-cols-[1fr,300px] gap-2">
                    <div className="bg-gray-100 border border-gray-300 rounded p-2 h-[calc(100vh-205px)] overflow-auto">
                        {(job && currentStep && currentStep.name != "jobs") && 
                            <FieldLabel prepend="Job:" prependClassName="w-[100px]" className="mb-2">{job.title}</FieldLabel>
                        }                    
                        {currentStep &&
                            <currentStep.component
                                order={order}
                                orderItem={orderItem}
                                job={job}
                                jobId={job?.id}
                                resetOnLoad={true}
                                onJob={(job) => {
                                    setJob(job);
                                    setCurrentStep(steps[1]);
                                }}
                            />
                        }
                    </div>
                    <NotesPanel order={order} />
                </div>
            </div>

            {(currentStep && currentStep.name != "jobs" && !currentStep.last) && 
                <Button
                    size="lg"
                    variant={currentStep == "done" ? "success":"solid-success"}
                    className="fixed right-[410px] bottom-5 z-10 flex items-center gap-2"
                    onClick={() => {
                        setCurrentStep((prevStep) => {
                            let idx = steps.findIndex(s => s.name == prevStep.name);

                            return steps[idx+1];
                        })
                    }}
                >
                    {currentStep.last &&
                        <FontAwesomeIcon icon={faCheck} size="lg" />
                    }

                    Continue

                    {!currentStep.last &&
                        <FontAwesomeIcon icon={faCaretRight} size="lg" />
                    }
                </Button>
            }
        </>
    );
}

export default OrderWorkflowJobPanel;