import React, { useState, useEffect, useRef } from "react"
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

import produce from 'immer'

import { colors } from '../../settings/settings'
import { uploadImage } from '../../common/services/server'
import { generateThumbnails } from '../../helpers/thumbnails'

const Button = styled.div`
    background-color: ${colors.blue};
    border: 0px;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    padding: 6px 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
    
    &[disabled] {
        opacity: .8;
        cursor: not-allowed;
    }
    &:hover {
        background-color: ${colors.blueHover};
    }

    svg {
        margin-right: 5px;
    }
`
const Input = styled.input`
    position: absolute;
    left: 0px;
    height: 100%;
    right: ${props => props.size && props.size == "sm" ? "auto":"0px"};
    width: ${props => props.size && props.size == "sm" ? "85px":"100%"};
    bottom: 0px;
    opacity: 0;
`
const Form = styled.form`
    margin: 00px;
    position: relative;
`

function getInputFileProps(input, props) {
    const file = input.files[0];
    const data = {}

    data.name = file.name;
    data.size = file.size;
    data.dimensions = {
        width: props.width,
        height: props.height
    }

    return data;
}

function UploadButton(props) {
    const inputRef = useRef();

    const [index, setIndex] = useState(null);
    const [uploads, setUploads] = useState([]);
    const [uploading, setUploading] = useState(false);

    useEffect(
        () => {
            if (index != null) {
                const files = inputRef.current.files;

                generateThumbnails(
                    files[index],
                    props.thumbnailOptions || null,
                    (thumbnails) => {
                        uploadImage(props.uploadUrl, files[index], thumbnails?thumbnails.thumbnail:null, thumbnails?thumbnails.preview:null, 
                            function(percent) {
                                console.log(percent + " uploaded...");
                            },
                            function(response) {
                                props.onUpload(response, index+1);

                                const allUploads = uploads.concat([response]);

                                setUploads(allUploads);

                                if (props.multiple) {
                                    if (index == (files.length-1)) {
                                        if (props.onUploadsComplete) {
                                            props.onUploadsComplete(allUploads);
                                        }
                                        setUploading(false);
                                        setIndex(null);        
                                    }
                                    else {
                                        setIndex(index+1);
                                    }
                                }
                                else {
                                    setUploading(false);
                                    setIndex(null)
                                }
                            },
                            function(error) {
                                window.alert("Error uploading file.");
                                console.log("error", error)
                                setUploading(false);
                                setIndex(null);
                            }
                        );
                    }
                );
            }
        }, 
        [index]
    );

/*
    function onImageFileSelected(inputControl) {
        setUpload(produce(draft => {
            draft.uploading = true;
            draft.error = null;
            draft.progress = 0;
        }));

        if (props.thumbnail) {
            generateThumbnails(
                inputControl.files[0],
                null,
                (thumbnails) => {
                    uploadImage(props.uploadUrl, inputControl, thumbnails.thumbnail, thumbnails.preview, 
                        function(percent) {
                            console.log(percent + " uploaded...");
                        },
                        function(response) {
                            props.onUpload(response);
                            //graphicsMgr.addNewFaceArt(data.faceArt);
        
                            setUpload(produce(draft => {
                                draft.uploading = false;
                                draft.error = null;
                                draft.progress = 100;
                            }));
                        },
                        function(error) {
                            setUpload(produce(draft => {
                                draft.uploading = false;
                                draft.error = error;
                                draft.progress = 100;
                            }));
                        },
                        function() {
                            inputControl.value = null;
                        }
                    );
                }
            );
        }
        else {
            uploadImage(props.uploadUrl, inputControl, null, null, 
                function(percent) {
                    console.log(percent + " uploaded...");
                },
                function(response) {
                    props.onUpload(response);
                    //graphicsMgr.addNewFaceArt(data.faceArt);

                    setUpload(produce(draft => {
                        draft.uploading = false;
                        draft.error = null;
                        draft.progress = 100;
                    }));
                },
                function(error) {
                    setUpload(produce(draft => {
                        draft.uploading = false;
                        draft.error = error;
                        draft.progress = 100;
                    }));
                },
                function() {
                    inputControl.value = null;
                }
            );
        }
    }
*/

    function onFileSelected(event) {
        console.log(event.target.files);

        if (!event.target.files || event.target.files.length == 0) return;

        let validExt = true;
        let validCount = 0;

        for (let i=0; i<event.target.files.length; i++) {
            let filename = event.target.files[i].name
            let ext = filename.substr(filename.length - 3, 3).toLowerCase();

            if (ext === "peg") ext = "jpg";

            if (props.extensions) {
                if (props.extensions.indexOf(ext) == -1) 
                    validExt = false;
                else validCount++;
            }
            else validCount++;
        }

        if (validExt) {
            if (props.onUploading) {
                props.onUploading(validCount)
            }

            setUploads([]);
            setUploading(true);
            setIndex(0);
        }
        else {
            window.alert("Only " + (props.extensions.join(",")) + " files are suported.");
        }
    }

    return (
        <Form 
            onSubmit={() => false} 
            onClick={(e) => {
                //e.preventDefault();
                e.stopPropagation();
            }}
            className={uploading ? "busy":""}
        >
            <Button disabled={props.disabled || uploading} size={props.size} style={props.style} className={props.className}>
                <FontAwesomeIcon icon={uploading ? faSpinner : faCloudUploadAlt} spin={uploading} />
                {uploading ? "Uploading " + ((index+1) + " of " + inputRef.current.files.length) + "...": props.label || (props.caption||"Select File")}
            </Button>

            <Input 
                name="image"  
                size={props.size} 
                ref={inputRef} 
                type="file" 
                disabled={props.disabled}
                onChange={uploading || props.disabled ? null : onFileSelected} 
                className={props.inputClassName}
                multiple={props.multiple?true:false} 
            />
        </Form>      
    );
}

export default UploadButton