import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faPencilAlt, faBan, faUser, faThumbsUp, faPaperclip, faPlus, faClipboardCheck, faTrash, faSpinner, faCheck, faInfoCircle, faGlobeAmericas, faUsers, faStickyNote, faShapes, faEnvelope, faArchive, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

import produce from "immer"

import PropertyList from "../../components/properties/property-list"
import EmployeeField from "../../components/fields/field-employee"
import UserSelect from "../../components/fields/user-select"
import SignSelect from "../../components/fields/sign-select"
import ProjectSelect from "../../components/fields/project-select"
import OrderSelect from "../../components/fields/order-select"
import JobSelect from "../../components/fields/job-select"
import UploadButton from "../../components/buttons/button-upload"

import { postData } from "../../common/services/server"
import { formatDate, formatDateTime, formatPhoneNumber } from "../../helpers/utility";
import storage from '../../settings/storage'
import Loading from "../loading";
import GoogleImage from "../google-image";

const Field = styled.tr`
    td.actions {
        text-align: right;
        white-space: nowrap;
        width: 10%;
    }

    td {
        /* padding-left: 0px !important; */

        &.url {
            text-transform: unset !important;
        }
    }

    &.default {
        &:hover {
            td {
                background-color: #eee;
            }
        }
    
        td.actions {
            svg {
                visibility: hidden;
            }
        }        
    }
    &.default:hover {      
        td.actions {
            svg {
                visibility: visible;
            }
        }
    }    
`
const Pills = styled.div`
    display: flex;
    float: right;

    > div {
        margin-left: 2px;
        background-color: #eee;
        padding: 5px;
        border-radius: 4px;
        font-size: 10px;
        cursor: pointer;

        &:hover {
            background-color: #ccc;
        }
    }
`

const Actions = styled.div`
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-top: 1px solid #ccc;
    
    svg {
        margin-right: 5px;
    }    

    > * {
        cursor: pointer;
    }
`

function PropertyField(props) {
    const fieldRef = useRef(null);

    const [value, setValue] = useState("");
    const [updating, setUpdating] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [editing, setEditing] = useState(false);

    function onUpdate(value) {
        setUpdating(true);

        postData(props.updateUrl, {
                prop: props.name, 
                value
            },
            function(result) {
                props.onUpdated(result);
                setEditing(false);
            },
            function(error) {
                window.alert("An error occured while updating the task.");
            },
            function() {
                setUpdating(false);
            }
        ); 
    }

    function onDelete() {
        setRemoving(true);

        postData(props.deleteUrl, {
                prop: props.name
            },
            function(result) {
                props.onUpdated(result);
            },
            function(error) {
                window.alert("An error occured while updating the task.");
            },
            function() {
                setRemoving(false);
            }
        ); 
    }

    useEffect(
        () => {
           if (props.value == "..." || (props.title && props.title == "...")) 
            setEditing(true);
        }, 
        []
    );

    useEffect(
        () => {
            setValue(props.type == "date" && props.value ? formatDate(props.value) : props.value);
        }, 
        [props.value]
    );

    useEffect(
        () => {
            if (editing && fieldRef && fieldRef.current)
                fieldRef.current.focus()
        }, 
        [editing]
    );

    return (
        <Field className={editing ? "editing" : updating ? "updating" : "default"}>
            {props.label && <th>{props.label}</th>}
            <td 
                onClick={() => !editing && props.updateUrl && (!props.type || props.type != "url" || (props.type == "url" && !value)) ? setEditing(true) : null} 
                style={{color: props.color ? props.color : (props.placeholder && !value) ? "#ccc" : "black"}}
                className={props.type}
            >
                {(editing && (props.type == "select" || props.type == "list")) ?
                    <FormControl 
                        as="select"
                        name={props.name} 
                        value={value} 
                        ref={fieldRef}
                        onChange={(e) => {
                            setValue(e.target.value);

                            if (props.autoUpdate)
                                onUpdate(e.target.value)                            
                        }}
                    >     
                        <option value=""></option>
                        {props.items && props.items.map((item,index) => (
                            <option key={index} value={item.value || item.id}>{item.label || item.title || item.name}</option>
                        ))}
                    </FormControl>                 
                : (editing && props.type == "user") ?
                    <UserSelect
                        name={props.name} 
                        default={props.value} 
                        onChange={(user) => {
                            onUpdate(user.id)
                        }}
                    />
                : (editing && props.type == "sign") ?
                    <SignSelect
                        name={props.name} 
                        default={props.value} 
                        onChange={(sign) => {
                            onUpdate(sign.id)
                        }}
                    />    
                : (editing && (props.type == "project" || props.type == "quote")) ?
                    <ProjectSelect
                        name={props.name} 
                        internal
                        default={props.value} 
                        onChange={(project) => {
                            onUpdate(project.id)
                        }}
                    />  
                : (editing && props.type == "order") ?
                    <OrderSelect
                        name={props.name} 
                        default={props.value} 
                        onChange={(order) => {
                            onUpdate(order.id)
                        }}
                    /> 
                : (editing && props.type == "job") ?
                    <JobSelect
                        name={props.name} 
                        default={props.value} 
                        onChange={(job) => {
                            onUpdate(job.id)
                        }}
                    />                                                               
                : editing ?
                    <FormControl 
                        as={props.type == "textarea" ? "textarea" : "input" }
                        type={props.type || "text" }
                        name={props.name} 
                        rows={props.rows || 3}
                        value={value == "..." ? "" : value || ""} 
                        ref={fieldRef}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter' && (props.autoUpdate || !props.type || props.type == "text" || props.type == "url")) {
                                onUpdate(value)
                            }
                        }}
                        onChange={(e) => {
                            setValue(e.target.value);

                            if (props.autoUpdate && props.type == "date")
                                onUpdate(e.target.value)                            
                        }}
                        onBlur={(e) => {
                            if (props.autoUpdate && props.type != "date")
                                onUpdate(value)
                        }}
                    />     
                :
                    <>
                            
                        {props.icon && <FontAwesomeIcon icon={props.icon} fixedWidth />}
                        {
                            props.type == "list" && props.url ?
                                <Link to={props.url} onClick={(e) => e.stopPropagation()}>
                                    {props.title}
                                </Link>
                            :props.type == "url" && value ?
                                <a href={value} target="_blank" onClick={(e) => e.stopPropagation()}>
                                    {value && value.length > 50 ? value.substring(0,50) + "..." : value}
                                </a>
                            : props.url ?
                                <a href={props.url} target="_blank" onClick={(e) => e.stopPropagation()}>
                                    {value}
                                </a>
                            : (props.type == "user" && value) ?
                                <Link to={"/users/user/" + value}>{props.title}</Link>                            
                            : (props.type == "sign" && value) ?
                                <Link to={"/signs/sign/" + value}>{props.title}</Link>                            
                            : (props.type == "project" && value) ?
                                <Link to={"/projects/project/" + value}>{props.title}</Link>                            
                            : (props.type == "order" && value) ?
                                <Link to={"/orders/order/" + value}>{props.title}</Link>                            
                            : (props.type == "job" && value) ?
                                <Link to={"/jobs/job/" + value}>{props.title}</Link>                            
                            : (props.type == "meeting" && value) ?
                                <Link to={"/tasks/task/" + value} onClick={props.onClick}>{props.title}</Link>                            
                            : (props.type == "task" && value) ?
                                <Link to={"/tasks/task/" + value} onClick={props.onClick}>{props.title}</Link>                            
                            : props.bold ? 
                                <strong>{props.title || value || props.placeholder}</strong>
                            : (props.size && props.size == "large") ?
                                <h4>{props.title || value || props.placeholder}</h4>
                            :
                                <span dangerouslySetInnerHTML={{__html: props.title || value || props.placeholder}} />
                        }
                    </>
                }
            </td>
            <td align="right" className="actions">
                {updating ? 
                    <FontAwesomeIcon icon={faSpinner} spin />
                    : editing ? 
                        <>
                            {!props.autoUpdate && 
                                <FontAwesomeIcon icon={faCheck} onClick={() => onUpdate(value)} style={{color:"blue"}} />
                            }
                            <FontAwesomeIcon icon={faBan} onClick={() => setEditing(false)} style={{color:"#999"}} />
                        </>
                    : props.updateUrl ?
                        <FontAwesomeIcon icon={faPencilAlt} onClick={() => setEditing(true)} />
                    : null
                }  
                {removing ? 
                    <FontAwesomeIcon icon={faSpinner} spin />
                    : (!editing && props.deleteUrl) ? 
                        <FontAwesomeIcon icon={faTrash} onClick={() => onDelete()} />
                    : null
                }                   
            </td>
        </Field>        
    )
}

function TaskForm(props) {
    const [formData, setFormData] = useState({});
    const [updating, setUpdating] = useState("");
    const [loading, setLoading] = useState("");
    const [editing, setEditing] = useState("");

    const [lists, setLists] = useState([]);
    const [projects, setProjects] = useState([]);
    const [stats, setStats] = useState([])

    const task = props.task;

    useEffect(
        () => {
          if (props.lists)
            setLists(props.lists)
          else {
            postData("tasks/lists?tasksonly_ind=1", {},
                function(result) {
                    setLists(result);
                },
                function(error) {
                }
            );  
          }
          if (props.projects)
            setProjects(props.projevts)
          else {
            postData("projects/data?internal_ind=1", {},
                function(result) {
                    setProjects(result.projects);
                },
                function(error) {
                }
            );  
          }
        }, 
        []
    ); 

    useEffect(
      () => {
        setFormData(JSON.parse(JSON.stringify(props.task)));

        if (props.task.type == "followupemail" || props.task.type == "followupcall") {
            setLoading("userstats") 
            
            postData("users/stats", {user_id: props.task.user_id},
                function(result) {
                    setStats(result);
                },
                function(error) {
                    window.alert("Error loading customer information");
                },
                function() {
                    setLoading("");
                }
            );              
        }
      }, 
      [props.task]
    ); 

    function onCompleteTask() {
        props.onBusy(true);

        postData("tasks/complete", {
                task_id: task.id
            },
            function(result) {
                props.onTaskUpdated(result);
            },
            function(error) {
                window.alert("Error saving task.");
            },
            function() {
                props.onBusy(false);
            }
        );        
    }
    function onUnCompleteTask() {
        props.onBusy(true);

        postData("tasks/uncomplete", {
                task_id: task.id
            },
            function(result) {
                props.onTaskUpdated(result);
            },
            function(error) {
                window.alert("Error saving task.");
            },
            function() {
                props.onBusy(false);
            }
        );        
    }   
    function onDeleteTask() {
        if (window.confirm("Delete this task?")) {
            props.onBusy(true);

            postData("tasks/delete", {task_id: task.id},
                function(result) {
                    props.onTaskDeleted(result);
                },
                function(error) {
                    window.alert("Error deleting task.");
                },
                function() {
                    props.onBusy(false);
                }
            );
        }
    }

    function handleFileUpload(result) {
        props.onTaskUpdated(result);
    }

    function onAssignUser(user) {
        setUpdating("user"+user.id)
        // temp add it, so we get spinner
        setFormData(produce(draft => {
            const index = draft.users.findIndex(u => u.id == user.id);

            if (index == -1)
                draft.users.push(user);
        }));

        postData("tasks/assign", {task_id: task.id, user_id: user.id},
            function(result) {
                props.onTaskUpdated(result);
            },
            function(error) {
                window.alert("Error updating task.");
            },
            function() {
                setUpdating("")
            }
        );
    }
    function onRemoveUser(user) {
        setUpdating("user"+user.id)

        postData("tasks/unassign", {task_id: task.id, user_id: user.id},
            function(result) {
                props.onTaskUpdated(result);
            },
            function(error) {
                window.alert("Error updating task.");
            },
            function() {
                setUpdating("")
            }
        );        
    }

    function onAssociationType(type) {
        console.log("onAssociationType", type)

        setFormData(produce(draft => {
            draft[type + "_title"] = "..."
        }))
    }

    function onAddItem(type) {
        setUpdating(type);

        postData("tasks/additem", {task_id: task.id, type},
            function(result) {
                props.onTaskUpdated(result);
            },
            function(error) {
                window.alert("Error updating task.");
            },
            function() {
                setUpdating("")
            }
        );          
    }

    return (
        <>
            <div>
                {(formData.type != "followupemail" && formData.type != "followupcall") && 
                    <PropertyList>                    
                        <PropertyField 
                            name="quote_id"
                            label="Project"
                            type="list"   
                            items={projects}    
                            value={formData.quote_id}
                            title={formData.parent_project || formData.quote_title}
                            url={formData.quote_id ? "/projects/project/" + formData.quote_id : formData.parent_project_id ? "/projects/project/" + formData.parent_project_id : null}
                            autoUpdate
                            updateUrl={!formData.parent_id || formData.parent_type == "list" ? "tasks/update?task_id=" + task.id : null}
                            onUpdated={props.onTaskUpdated}
                        /> 
                        {(formData.type != "list") &&
                            <PropertyField 
                                name="parent_id"
                                label="List"
                                type="list"       
                                items={lists}                 
                                value={formData.parent_id}
                                title={formData.parent_list || task.parent_task}
                                url={formData.list_url}
                                autoUpdate
                                updateUrl={!formData.parent_id || formData.parent_type == "list" ? "tasks/update?task_id=" + task.id : null}
                                onUpdated={props.onTaskUpdated}
                            />                     
                        }          
                        {(task.parent_id && task.parent_type != "list" && props.onTask) && 
                            <PropertyField 
                                name="parent_id"
                                label="Parent Task"
                                type="task"       
                                value={task.parent_id}
                                title={task.parent_task}
                                onClick={(e) => {
                                    if (props.onTask) {
                                        props.onTask(task.parent_id);
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }
                                }}
                            />                     
                        }                                  
                    </PropertyList>
                }

                <PropertyList>

                    <PropertyField 
                        name="task"
                        type="textarea"
                        value={task.task}
                        size="large"
                        updateUrl={"tasks/update?task_id=" + task.id}
                        onUpdated={props.onTaskUpdated}
                    />

                    {formData && formData.items && formData.items.map(item => (
                        item.type == "note" ?
                            <PropertyField 
                                key={item.id}
                                name={"details" + item.id}
                                type="textarea"
                                rows={5}
                                icon={faStickyNote}
                                placeholder="Notes..."
                                value={item.title}
                                updateUrl={"tasks/updateitem?item_id=" + item.id}
                                deleteUrl={item.id ? "tasks/deleteitem?item_id=" + item.id : null}
                                onUpdated={props.onTaskUpdated}
                            />
                        : item.type == "link" ? 
                            <PropertyField 
                                key={item.id}
                                name={"link" + item.id}
                                type="url"
                                icon={faGlobeAmericas}
                                placeholder="Link..."
                                value={item.title}
                                updateUrl={"tasks/updateitem?item_id=" + item.id}
                                deleteUrl={item.id ? "tasks/deleteitem?item_id=" + item.id : null}
                                onUpdated={props.onTaskUpdated}
                            />     
                        : item.type == "file" ? 
                            <PropertyField 
                                key={item.id}
                                name={"file" + item.id}
                                type="file"
                                icon={faPaperclip}
                                value={item.title}
                                url={storage.root + item.url}
                                deleteUrl={"tasks/deleteitem?item_id=" + item.id}
                                onUpdated={props.onTaskUpdated}
                            /> 
                        : item.type == "meeting" ? 
                            <PropertyField 
                                key={item.id}
                                name={"meeting" + item.id}
                                type="meeting"
                                icon={faUsers}
                                value={item.id}
                                title={item.title}
                                deleteUrl={"tasks/delete?task_id=" + item.item_id + "&parent_id=" + formData.id}
                                onClick={(e) => {
                                    if (props.onTask) {
                                        props.onTask(item.item_id);
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }
                                }}
                                onUpdated={props.onTaskUpdated}
                            />                             
                        : null                        
                    ))}                                 
                </PropertyList>

                {(formData.type != "list" && formData.type != "followupemail" && formData.type != "followupcall") &&
                    <div style={{display: "flex", marginLeft:"2px", marginBottom:"20px"}}>
                        <DropdownButton variant="outline-secondary" size="sm" title={
                            <>
                                <FontAwesomeIcon icon={["details","link","meeting"].indexOf(updating) != -1 ? faSpinner:faPlus} spin={["details","link","meeting"].indexOf(updating) != -1} />
                                Add
                            </>
                        }>
                            <Dropdown.Item as="button" onClick={() => onAddItem('note')}>
                                <FontAwesomeIcon icon={updating == "details" ? faSpinner:faStickyNote} spin={updating == "details"} /> {' '}
                                Add Note
                            </Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => onAddItem('link')}>
                                <FontAwesomeIcon icon={updating == "link" ? faSpinner:faGlobeAmericas} spin={updating == "link"} /> {' '}
                                Add Link
                            </Dropdown.Item>
                            <Dropdown.Item as="button"onClick={() => onAddItem('meeting')}>
                                <FontAwesomeIcon icon={updating == "meeting" ? faSpinner:faUsers} spin={updating == "meeting"} /> {' '}
                                Add Meeting
                            </Dropdown.Item>
                        </DropdownButton>
                        &nbsp;
                        <UploadButton
                            size="sm"
                            label="Attach File"
                            color="grey"
                            uploadUrl={"tasks/upload?task_id=" + task.id}
                            onUpload={(file) => handleFileUpload(file)}
                        />                       
                    </div>
                }

                {(formData.type != "list" && formData.type != "followupemail" && formData.type != "followupcall") &&
                    <>
                        <PropertyList
                            header={
                                <tr>
                                    <th colSpan={3}>
                                        Associations
                                        <Pills>
                                            {!formData.user_title && 
                                                <div onClick={() => onAssociationType("user")}>
                                                    User
                                                </div>
                                            }   
                                            {!formData.sign_title && 
                                                <div onClick={() => onAssociationType("sign")}>
                                                    Sign
                                                </div>
                                            }                                                                        
                                            {!formData.order_title && 
                                                <div onClick={() => onAssociationType("order")}>
                                                    Order
                                                </div>
                                            }
                                            {!formData.job_title && 
                                                <div onClick={() => onAssociationType("job")}>
                                                    Job
                                                </div>
                                            }      
                                        </Pills>
                                    </th>
                                </tr>
                            }
                        >
                            {formData.user_title && 
                                <PropertyField 
                                    name="user_id"
                                    label="User"
                                    type="user"       
                                    value={formData.user_id}
                                    title={formData.user_title}
                                    autoUpdate
                                    updateUrl={"tasks/update?task_id=" + task.id}
                                    deleteUrl={"tasks/update?task_id=" + task.id}
                                    onUpdated={props.onTaskUpdated}
                                />     
                            }   
                            {formData.sign_title && 
                                <PropertyField 
                                    name="sign_id"
                                    label="Sign"
                                    type="sign"       
                                    value={formData.sign_id}
                                    title={formData.sign_title}
                                    autoUpdate
                                    updateUrl={"tasks/update?task_id=" + task.id}
                                    deleteUrl={"tasks/update?task_id=" + task.id}
                                    onUpdated={props.onTaskUpdated}
                                />                       
                            }                                        
                            {formData.order_title && 
                                <PropertyField 
                                    name="order_id"
                                    label="Order"
                                    type="order"       
                                    value={formData.order_id}
                                    title={formData.order_title}
                                    autoUpdate
                                    updateUrl={"tasks/update?task_id=" + task.id}
                                    deleteUrl={"tasks/update?task_id=" + task.id}
                                    onUpdated={props.onTaskUpdated}
                                />                        
                            }
                            {formData.job_title && 
                                <PropertyField 
                                    name="job_id"
                                    label="Job"
                                    type="job"       
                                    value={formData.job_id}
                                    title={formData.job_title}
                                    autoUpdate
                                    updateUrl={"tasks/update?task_id=" + task.id}
                                    deleteUrl={"tasks/update?task_id=" + task.id}
                                    onUpdated={props.onTaskUpdated}
                                />                        
                            }
                        </PropertyList>
                        <br />
                    </>
                }

                {(props.task.type == "followupemail" || props.task.type == "followupcall") &&
                    <div style={{marginBottom: "10px"}}>
                        {loading == "userstats" ? 
                            <Loading />
                        : stats ?
                            <PropertyList>
                                <tr><th>Customer</th><td>{stats.name}</td></tr>
                                <tr><th>Email</th><td>{stats.email}</td></tr>
                                <tr><th>Phone</th><td>{formatPhoneNumber(stats.phoneno)}</td></tr>
                                <tr><th>State</th><td>{stats.state}</td></tr>

                                {props.task.quote_id && 
                                    <tr>
                                        <th>Project</th>
                                        <td>
                                            <a href={"/#/projects/project/" + props.task.quote_id} target="_blank">
                                                #{props.task.quote_id}
                                            </a>
                                        </td>
                                    </tr>
                                }

                                {props.task.sign_id && 
                                    <tr>
                                        <th>Sign</th>
                                        <td>
                                            <a href={"/#/signs/sign/" + props.task.sign_id} target="_blank">
                                                #{props.task.sign_id}
                                            </a>

                                            {props.task.sign_preview_url && 
                                                <a href={"/#/signs/sign/" + props.task.sign_id} target="_blank">
                                                    <GoogleImage
                                                        root 
                                                        src={props.task.sign_preview_url}
                                                        style={{height: "50px"}}
                                                    />
                                                </a>
                                            }
                                        </td>
                                    </tr>
                                }

                                <tr className="info link">
                                    <th><FontAwesomeIcon icon={faShapes} /> Signs</th>
                                    <td onClick={() => props.onInfoLink("signs")}>{stats.signs}</td>
                                </tr>
                                <tr className="info link">
                                    <th><FontAwesomeIcon icon={faArchive} /> Projects</th>
                                    <td onClick={() => props.onInfoLink("projects")}>{stats.projects}</td>
                                </tr>
                                <tr className="info link">
                                    <th><FontAwesomeIcon icon={faCreditCard} /> Orders</th>
                                    <td onClick={() => props.onInfoLink("orders")}>{stats.orders}</td>
                                </tr>
                                <tr className="info link">
                                    <th><FontAwesomeIcon icon={faEnvelope} /> Emails</th>
                                    <td onClick={() => props.onInfoLink("emails")}>
                                        {stats.emails_sent} Sent {stats.emails_sent > 0 ? " ("+stats.emails_opened+" opened)":""},
                                        
                                        &nbsp;
                                        {stats.emails_received} Received 

                                        {stats.last_followup_date && 
                                            <span style={{color:"black", display:"inline-block", marginLeft:"20px"}}>
                                                Last Followup On: <span style={{color:"red"}}>{formatDate(stats.last_followup_date)}</span>
                                            </span>
                                        }
                                    </td>
                                </tr>
                            </PropertyList>
                        :
                            <p>No customer information found.</p>
                        }
                    </div>  
                }

                {formData.type != "list" && 
                    <>
                        <PropertyList
                            header={
                                <tr>
                                    <th colSpan={2}>
                                        Assigned To

                                        <EmployeeField
                                            name="user"
                                            size="sm"
                                            onChange={(e, user) => {
                                                console.log(user)

                                                if (user) {
                                                    onAssignUser(user);
                                                }
                                            }}
                                        />
                                    </th>
                                </tr>
                            }
                        >
                            {formData.users && formData.users.map(user => (
                                <tr key={user.id}>
                                    <td>
                                        <FontAwesomeIcon icon={faUser} />{' '}
                                        {user.name || user.first_name}
                                    </td>
                                    <td className="actions">
                                        <FontAwesomeIcon 
                                            icon={updating == ("user"+user.id) ? faSpinner : faTrash} 
                                            spin={updating == ("user"+user.id)}
                                            onClick={() => {
                                                onRemoveUser(user);
                                            }}                                    
                                        />                                  
                                    </td>
                                </tr>
                            ))}
                        </PropertyList>

                        <br />
                    </>
                }

                <PropertyList>
                    {formData.type != "list" &&                    
                        <PropertyField 
                            name="type"
                            label="Type"
                            type="list"       
                            items={[
                                {id: "task", title: "Task"},
                                {id: "meeting", title: "Meeting"},
                                {id: "event", title: "Event"},
                                {id: "followupemail", title: "Customer Followup Email"},
                                {id: "followupcall", title: "Customer Followup Call"},
                            ]}                 
                            value={task.type_title || task.type}
                            autoUpdate
                            updateUrl={"tasks/update?task_id=" + task.id}
                            onUpdated={props.onTaskUpdated}
                        />                        
                    }               
                    {formData.type != "list" &&                    
                        <PropertyField 
                            name="due_date"
                            label={(task.type == "meeting" ? "Meeting " : task.type == "task" ? "Due " : "Due ") + "Date"}
                            type="date"                        
                            value={formData.due_date}
                            color={task.late_ind == 1 ? "red" : "black"}
                            autoUpdate
                            updateUrl={"tasks/update?task_id=" + task.id}
                            deleteUrl={"tasks/update?task_id=" + task.id}
                            onUpdated={props.onTaskUpdated}
                        />  
                    }
                    {formData.type != "list" &&                    
                        <PropertyField 
                            name="due_time"
                            label={(task.type == "task" ? "Due " : "") + "Time"}
                            type="text"                        
                            value={formData.due_time}
                            autoUpdate
                            updateUrl={"tasks/update?task_id=" + task.id}
                            deleteUrl={"tasks/update?task_id=" + task.id}
                            onUpdated={props.onTaskUpdated}
                        />                  
                    }

                    <tr className="info"><th>Added</th><td>{task.added_user} on {formatDateTime(task.added_date)}</td></tr>
                    {task.updated_user && <tr className="info"><th>Updated</th><td>{task.updated_user} on {formatDateTime(task.updated_date)}</td></tr>}
                    {task.completed_date && <tr className="info success"><th>Completed</th><td>{task.completed_user} on {formatDateTime(task.completed_date)}</td></tr>}
                </PropertyList>
            </div>
            <Actions>
                <div>
                    {(task.status == "Active" && task.type != "list") && 
                        <div onClick={onCompleteTask} style={{color:"green"}}>
                            <FontAwesomeIcon icon={faCheck} />
                            Complete
                        </div>                                        
                    }
                    {task.status == "Complete" && 
                        <div onClick={onUnCompleteTask} style={{color:"orange"}}>
                            <FontAwesomeIcon icon={faClipboardCheck} />
                            Un-Complete
                        </div>                                        
                    }                                        
                </div>
                <div onClick={onDeleteTask}>
                    <FontAwesomeIcon icon={faTrash} />
                </div>
            </Actions>
        </>
    );
}

export default TaskForm;