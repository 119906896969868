import React, { useState } from "react";
import clsx from "clsx";
import { createPortal } from 'react-dom';

import storage from "../settings/storage";

function ZoomableImage({
    url,
    zoomUrl,
    width,
    height,
    maxWidth,
    maxHeight,
    alt,
    caption,
    containerClassName,
    captionClassName = "p-1 text-center",
    className,
}) {
    const [zoom, setZoom] = useState(false);

    return (
        <>
            <div 
                className={clsx(
                    "bg-white border !border-dashed border-gray-100 p-1 rounded text-center hover:bg-gray-100 cursor-zoom-in",
                    containerClassName
                )}
                onClick={() => setZoom(prev => !prev)}
            >
                <img
                    src={url}  
                    width={width}
                    height={height}
                    alt={alt}
                    className={className}
                    style={{
                        display: "block",
                        margin: "0px auto",
                        height: height,
                        width: width,
                        maxHeight: maxHeight || "auto",
                        maxWidth: maxWidth || "auto",
                    }}
                    onError={(e) => {
                        if (e.target.src != (storage.root + "images/nothumbnail.jpg")) {
                            e.target.src = storage.root + "images/nothumbnail.jpg";
                        }
                        e.target.onerror = null; // just in case to prevent recursive error
                        e.target.onError = null; // just in case to prevent recursive error
                    }}                    
                />
                {caption && 
                    <div className={captionClassName}>
                        {caption}
                    </div>
                }
            </div>

            {zoom && 
                createPortal(
                    <div className="fixed left-0 top-0 right-0 bottom-0 z-[1051] bg-black/50 p-10" onClick={() => setZoom(false)}>
                        <img
                            src={zoomUrl || url}  
                            alt={alt}
                            className={className}
                            style={{
                                display: "block",
                                margin: "auto auto",
                                maxHeight: "80vh",
                                maxWidth: "80vw",
                            }}
                        />                    
                    </div>,
                    document.body
                )
            }
        </>
    );
  }

export default ZoomableImage