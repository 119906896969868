import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import produce from "immer"
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faPlus, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing";
import StylesDrawer from "../drawers/drawer-selectstyles"

import { postData } from "../common/services/server"
import PanelDescription from "./panel-description";
import PanelTable from "./panel-table"

function Panel({
  product,
  onChange
}) {
  const [busy, setBusy] = useState("");
  const [styles, setStyles] = useState([]);

  const [selectStyle, setSelectStyle] = useState(false);

  useEffect(() => {
      setStyles(product.styles);     
  }, [product]);

  function onRemoveStyle(style) {
    if (window.confirm("Are you sure you want to remove this style?")) {
        setBusy("remove" + style.id)

        postData("products/style/toggle", {
                id: product.id,
                style_id: style.id,
                checked_ind: 0
            }, 
            function(response) {
                setStyles(produce(draft => {
                    const idx = draft.findIndex(s => s.id == style.id);

                    if (idx != -1) {
                        draft.splice(idx, 1);
                    }
                }));

                if (onChange) {
                    setTimeout(() => {
                        onChange({styles})
                    }, 500);
                }
            },
            function(error) {
                alert("An error occured removing the style.");
            },
            function() {
                setBusy("")
            }
        );
    }
  }
  function onMakeDefault(style) {
    setBusy("default" + style.id)
    
    postData("products/updateprop", {
            product_id: product.id,
            prop: "default_style_id",
            value: style.id
        }, 
        onChange,
        function(error) {
            alert("An error occured updating the style.");
        },
        function() {
            setBusy("")
        }
    );
  }
  return (
    <>
        <PanelDescription>
            Styles control the type styles or shapes that are available for this product in the Builder.
        </PanelDescription>

        { styles.length == 0 ?
            <p>No styles found.</p>
        :
            <PanelTable>
                <thead>
                    <tr>
                        <th>Style</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {styles.map(style => (
                        <tr key={style.id}>
                            <td>
                                <div style={{display:"flex", alignItems: "center", gap: "10px"}}>
                                    {style.name}       

                                    {product.default_style_id == style.id && 
                                        <Badge variant="primary">
                                            Default
                                        </Badge>
                                    }
                                </div>
                            </td>
                            <td align="right">
                                {(styles.length > 1 && style.id != product.default_style_id) && 
                                    <ButtonProcessing
                                        icon={faCheck}
                                        caption="Make Default"
                                        size="sm"
                                        variant="outline-secondary"
                                        processing={busy == ("default"+style.id)}
                                        onClick={() => {
                                            onMakeDefault(style);
                                        }}
                                        style={{marginRight: "5px"}}
                                    />
                                }
                                <ButtonProcessing
                                    icon={faBan}
                                    size="sm"
                                    variant="outline-danger"
                                    processing={busy == ("remove"+style.id)}
                                    onClick={() => {
                                        onRemoveStyle(style);
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </PanelTable> 
        }

        <div style={{display: "flex", gap: "5px", marginTop: "10px"}}>
            <Button
                variant="outline-primary"
                size="sm"
                disabled={busy=="adding"}
                onClick={() => setSelectStyle(true)}                
            >
                <FontAwesomeIcon icon={busy=="adding" ? faSpinner:faPlus} spin={busy=="adding"} />
                &nbsp;
                Add Style
            </Button>
        </div>

        { selectStyle && 
            <StylesDrawer 
                product={product}
                existingStyles={styles} 
                onSelect={(newStyles) => {
                    console.log(newStyles)
                    if (newStyles.length > 0) {
                        setBusy("adding");

                        postData("products/style/toggle", {
                                id: product.id,
                                style_ids: newStyles.map(s => s.id).join(","),
                                checked_ind: 1
                            }, 
                            function() {
                                setStyles(produce(draft => {
                                    for (const newStyle of newStyles) {
                                        draft.push(newStyle);
                                    }
                                }))
                            },
                            function(error) {
                                alert("An error occured adding the styles.");
                            },
                            function() {
                                setBusy("")
                            }
                        );
                    }

                    setSelectStyle(false);
                }} 
                onHide={ () => setSelectStyle(false) } 
            />     
        }      
    </>
  );
}

export default Panel;