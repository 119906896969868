import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import GoogleImage from "../components/google-image"

import { postData } from "../common/services/server"

const Products = styled.table`
    width: 100%;
    thead tr th {
        background-color: #e0e0e0;
        padding: 5px;
    }
    tbody tr td {
        padding: 5px;
    }
`

function SignProductsDrawer(props) {
  const [loading, setLoading] = useState(true);

  const [products, setProducts] = useState([]);

  useEffect(() => {
        setLoading(true);

        postData("signs/allproducts", { sign_id: props.sign.id }, 
            function(results) {
                setProducts(results);
            },
            function(error) {
                alert("An error occured loading the product parts.");
            },
            function() {
                setLoading(false);
            }
        );
  }, []);

  function onProductChange(e) {
    const productId = e.target.value;
    const checked = e.target.checked;
    let selected = [];

    setProducts(produce(draft => {
        const product = draft.find(p => p.id == productId);

        product.selected_ind = checked ? 1 : 0;

        selected = draft.filter(s => s.selected_ind == 1).map(s => s.name);
    }));

    postData("signs/toggleproduct", {
        sign_id: props.sign.id,
            product_id: productId,
            checked_ind: checked ? 1 : 0
        }, 
        function(response) {
            props.onChanged(selected.length)
        },
        function(error) {
            alert("An error occured updating the sign.");
        },
        function() {
        }
    );
  }

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faClipboardCheck} />
                &nbsp;
                Sign Products
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: "flex", alignItems: "center", marginBottom: "15px"}}>
                    <GoogleImage root src={props.sign.preview_url} style={{ width: "40px", marginRight: "10px" }} />
                    Design #{props.sign.id}
                </div> 

                <p>
                    Select the products that this sign can be converted to.
                </p>

                { loading ? 
                    <Loading />
                :
                products.length == 0 ?
                    <p style={{color:"red"}}>No products found.</p>
                : 
                    <Products>
                        <thead>
                            <tr>
                                <th>Product</th>
                            </tr>
                        </thead>
                        <tbody>
                        {products.map(product => (
                            <tr key={product.id}>
                                <td>
                                    <Form.Check 
                                        name="product_id"                                
                                        value={product.id}
                                        checked={product.selected_ind == 1}
                                        inline 
                                        label={product.title} 
                                        type="checkbox" 
                                        onChange={onProductChange}
                                    />
                                </td>                               
                            </tr>
                        ))}
                        </tbody>
                    </Products>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default SignProductsDrawer;