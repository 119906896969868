import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import SignsMonthChart from "../../components/charts/chart-signsmonth"
import SignProductsMonthChart from "../../components/charts/chart-signproductsmonth"
import SignCustomMonthChart from "../../components/charts/chart-signcustommonth"

function SignsDashboard(props) {
  return (
    <>
        <Row>
            <Col xs={12} sm={12}>
              <SignsMonthChart />
            </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <SignProductsMonthChart />
          </Col>
          <Col xs={12} sm={6}>
            <SignCustomMonthChart />
          </Col>          
        </Row>
    </>
  );
}

export default SignsDashboard;