import React from "react"
import clsx from 'clsx';

function ButtonGroup({
    children
}) {
    return (
        <div className="
            flex items-stretch 
            [&>button:first-child]:!rounded-r-none
            [&>button:last-child]:!rounded-l-none
        ">
            {children}
        </div>
    );
}

export default ButtonGroup