import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import styled from "styled-components"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faObjectGroup, faSave, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import Loading from "../components/loading"

import { postData } from "../common/services/server"
import { colors } from "../settings/settings";

const Parts = styled.div`
    > div {
        border: 1px solid #ccc;
        padding: 5px;
        margin-bottom: 2px;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;

        &.current {
            background-color: #eee;

            button {
                display: none;
            }
        }
    }
`

function SignGroupSizeDrawer(props) {
  const [isProcessing, setIsProcessing] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [size, setSize] = useState(0);

  useEffect(() => {
      setIsLoading(true);

      setSize(props.group.size);

      postData("groups/sizes", {
        signgroup_id: props.group.id
      }, 
        function(response) {
          setSizes(response);
        },
        function(error) {
          alert("An error occured loading sizes.");
        },
        function() {
          setIsLoading(false);
        }
      );      
  }, [props.group]);

  const product = props.group.product;

  return (
      <>
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faObjectGroup} />
            &nbsp;
            Sign Group Size
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {isLoading ? 
                <Loading /> 
            : 
                <>
                  {(product && product.material_min) &&
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      color: "red",
                      marginBottom: "5px"
                    }}> 
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                      Product has a material minimum size of {product.material_min}"
                    </div>
                  }
                  {(product && product.material_max_1) &&
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      color: "red",
                      marginBottom: "5px"
                    }}> 
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                      Product has a material maximum size of {product.material_max_1}"
                      {product.material_max_2 && 
                        '/' + product.material_max_2 + '"'
                      }
                    </div>
                  }                
                  <Table bordered striped style={{width:"100%"}}>
                      <tbody>
                      {sizes.map(size => {
                        let isValid = true;

                        if (product.material_min && (size.height < product.material_min || size.width < product.material_min)) {
                          console.log("material min contraint", product.material_min, size.height, size.width)
                          isValid = false;
                        }
                        if (product.material_max_1 && (size.height > product.material_max_1 || size.width > product.material_max_1)) {
                          console.log("material max contraint", product.material_max_1, size.height, size.width)
                          isValid = false;
                        }

                        return (
                          <tr key={size} style={{
                            backgroundColor: !isValid ? colors.lightRed : "inherit"
                          }}>
                              <th>Size {size.size}:</th>
                              <td>{size.height}" x {size.width}"</td>
                              <td>
                                  <ButtonProcessing 
                                      processing={isProcessing == size.size}
                                      onClick={() => {
                                          setIsProcessing(size.size);

                                          postData("groups/setsize", {
                                            sign_id: props.group.sign_id,
                                            signgroup_id: props.group.id,
                                            ...size
                                          }, 
                                            function(response) {
                                              props.onSaved(response);
                                            },
                                            function(error) {
                                              alert("An error occured updating the sign group.");
                                            },
                                            function() {
                                              setIsProcessing("");
                                            }
                                          );
                                      }} 
                                      variant="outline-success" 
                                      caption="Set Size" 
                                      icon={faSave} />                                   
                              </td>
                          </tr>  
                        )
                      })}      
                  </tbody>
                  </Table>
                </>
            }
        </Modal.Body>
        <Modal.Footer>          
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
      </>
    )
}

export default SignGroupSizeDrawer;