import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import styled from "styled-components"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner, faTablets, faTrash } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"
import PropertyList from "../components/properties/property-list"
import Property from "../components/properties/property"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"
import { removeNulls } from "../helpers/utility";

const Parts = styled.div`
    > div {
        border: 1px solid #ccc;
        padding: 5px;
        margin-bottom: 2px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.current {
            background-color: #eee;
        }
    }
`

function JobShapePartDrawer(props) {
  const [isProcessing, setIsProcessing] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [showAllParts, setShowAllParts] = useState(false);
  const [parts, setParts] = useState([]);

  useEffect(() => {
      setIsLoading(true);

      postData("parts/data", removeNulls({
        product_id: showAllParts ? null : props.shape.product_id,
        type: props.part.type_name
      }), 
        function(response) {
          setParts(response);
        },
        function(error) {
          alert("An error occured loading parts.");
        },
        function() {
            setIsLoading(false);
        }
      );

  }, [showAllParts]);

  function onSave(part) {
      setIsProcessing(part);

      postData("jobs/updatepart", {
        job_id: props.shape.job_id,
        jobpart_id: props.part.id,
        part_id: part.id
      }, 
        function(response) {
            props.onSaved(response)
        },
        function(error) {
          alert("An error occured updating the job part.");
        },
        function() {
            setIsProcessing(null);
        }
      );        
  }

  function onRemove(part) {
    if (window.confirm("Are you sure you want to remove this part from this job shape?")) {
        setIsProcessing(part);

        postData("jobs/removepart", {
            job_id: props.shape.job_id,
            jobpart_id: props.part.id,
            part_id: part.id
        }, 
            function(response) {
                props.onSaved(response)
            },
            function(error) {
              alert("An error occured removing the job part.");
            },
            function() {
                setIsProcessing(null);
            }
        );         
    }
  }

  return (
      <>
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faTablets} />
            &nbsp;
            Job Shape Part
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <PropertyList>
                <Property label="Shape" value={props.shape.code} />
                <Property label="Product" value={props.shape.product_title} />
                <Property label="Part Type" value={props.part.type_title} />
            </PropertyList>

            <br />

            {isLoading ? 
                <Loading /> 
            : 
            <>
                <Parts>
                {parts.map((part, index) => (
                    <div 
                        key={index} 
                        className={props.part && part.id == props.part.part_id ? "current":""}
                    >
                        {part.title}

                        {(props.part && part.id == props.part.part_id) ?
                            <Button 
                                onClick={() => onRemove(part)} 
                                size="sm"                             
                                variant="outline-danger"
                                disabled={isProcessing}
                            >
                                <FontAwesomeIcon icon={isProcessing && isProcessing.id == part.id ? faSpinner:faTrash} spin={isProcessing && isProcessing.id == part.id} />{' '}
                                Remove Part
                            </Button>
                        : (props.shape && props.shape.parts.find(p => p.part_id == part.id)) ?
                            null
                        :
                            <Button 
                                onClick={() => onSave(part)} 
                                size="sm"                             
                                variant="outline-primary"
                                disabled={isProcessing}
                            >
                                <FontAwesomeIcon icon={isProcessing && isProcessing.id == part.id ? faSpinner:faCheck} spin={isProcessing && isProcessing.id == part.id} />{' '}
                                Select Part
                            </Button>
                        }
                    </div>
                ))}
                </Parts>

                {!showAllParts && 
                    <div style={{marginTop: "15px"}}>
                        <Button 
                            size="sm" 
                            variant="outline-secondary"
                            onClick={() => setShowAllParts(true)}
                        >
                            Show All {props.part.type_title} Parts
                        </Button>
                    </div>
                }
            </>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
      </>
    )
}

export default JobShapePartDrawer;