import React, { useState, useEffect } from "react";
import produce from "immer"
import styled from 'styled-components'

import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import GoogleImage from "../../components/google-image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown, faSpinner, faSave, faStickyNote } from "@fortawesome/free-solid-svg-icons";

import Button from "../../components/buttons/button";
import Loading from "../../components/loading"
import { postData } from "../../common/services/server"
import { formatDate } from "../../helpers/utility"
import { colors } from "../../settings/settings";
import { Link } from "react-router-dom";

const Checklist = styled.div`
    min-width: 400px;
    border: 1px solid #ccc;
    
    img {
        max-height: 100px;
        display: block;
        margin: 10px auto;
    }
`
const Questions = styled.div`
    > div {
        padding: 0px;

        > h3 {
            font-size: 16px;
            font-weight: bold;
            padding: 12px 8px;
            background-color: #ccc;
            margin: 0px;
        }

        ul {
            list-style-type: none;
            margin: 0px;
            padding: 0px;

            li {
                border-top: 1px solid #ccc;
                padding: 8px;
                cursor: pointer;

                div.input-group {
                    margin-top: 10px;
                }
                div.question {
                    display: flex;
                    > div {
                        flex: 1;
                    }
                    div.buttons {
                        flex: 0;
                        flex-basis: 75px;

                        &.note {
                            text-align: right;
                        }

                        .btn-outline-secondary { background-color: white; }
                    }
                    span.badge {
                        margin-left: 5px;
                        border: 1px solid #999;
                        color: #999;
                        background-color: white;
                    }
                }
                div.extra {
                    font-size: 80%;
                    margin-top: 5px;
                    color: #999;

                    &.danger {
                        color: red;
                    }
                    &.success {
                        color: green;
                    }

                }

                &:hover {
                    background-color: #eee;
                }
                &.active {
                    background-color: ${colors.lightPurple};
                }
                &.deleted {
                    background-color: #ccc;
                    text-decoration: line-through;
                }
            }
        }
    }
`

function CheckListForm(props) {
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [selected, setSelected] = useState(null);
    const [processing, setProcessing] = useState([]);

    useEffect(
        () => {
            setLoading(true);

            postData("qa/questions", { 
                type: props.type,
                item: props.item,
                item_id: props.itemId
            },
                function(result) {
                    setCategories(result.categories);
                    setQuestions(result.questions)                    
                    //setSelected(result.questions.find(q => q.checked_user_id == null));
                },
                function(error) {
                    alert("Error loading checklist data")
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        [props.refresh]
    );

    useEffect(
        () => {
            if (props.onQuestion)
                props.onQuestion(selected);
        }, 
        [selected]
    );
    
    function onQuestion(question, checked) {
        console.log("onQuestion", question, checked)
        setProcessing(produce(draft => {
            const exists = draft.find(p => p.id == question.id)

            if (exists)
                exists.checked = checked;
            else
                draft.push({id: question.id, checked: checked})
        }));

        postData("qa/answer", { 
            item: props.item,
            item_id: props.itemId,
            question_id: question.id,
            checked: checked
        },
            function(result) {
                setQuestions(produce(draft => {
                    const exists = draft.find(q => q.id == question.id);

                    //console.log("existing question", exists.id, question.id, exists.checked, checked)
                    if (exists)
                        exists.checked_ind = checked;
                }));

                // auto select next question
                const index = questions.findIndex(q => q.id == question.id);
                if (index != -1 && index < (questions.length-1))
                    setSelected(questions[index+1]);
                
                if (props.onAnswer)
                    props.onAnswer(props.type, question, checked, result)
            },
            function(error) {
                alert("Error updating checklist data")
            },
            function() {
                setProcessing(produce(draft => {
                    const existIdx = draft.findIndex(p => p.id == question.id)
        
                    if (existIdx != -1) {
                        draft.splice(existIdx, 1);
                    }
                }));
            }
        );
    }

    function onQuestionNotes(question) {
        setQuestions(produce(draft => {
            const exists = draft.find(q => q.id == question.id);

            if (exists) {
                if (!exists.notes)
                    exists.notes = "Note...";
                else if (exists.notes == "Note...")
                    exists.notes = null;
            }
        }));
    }

    function setQuestionNotes(question, notes) {
        setQuestions(produce(draft => {
            const exists = draft.find(q => q.id == question.id);

            if (exists)
                exists.notes = notes;
        }));
    }

    function saveQuestionNotes(question) {
        setProcessing(produce(draft => {
            const exists = draft.find(p => p.id == question.id)

            if (exists)
                exists.notes = true;
            else
                draft.push({id: question.id, notes: true})
        }));

        postData("qa/note", { 
            item: props.item,
            item_id: props.itemId,
            question_id: question.id,
            notes: question.notes
        },
            function(result) {
            },
            function(error) {
                alert("Error updating notes")
            },
            function() {
                setProcessing(produce(draft => {
                    const existIdx = draft.findIndex(p => p.id == question.id)
        
                    if (existIdx != -1) {
                        draft.splice(existIdx, 1);
                    }
                }));
            }
        );
    }

    return (
        <Checklist>
            {
                loading ?
                    <Loading />
                :
                <>
                    {props.imageUrl && 
                        <GoogleImage root src={props.imageUrl} />
                    }
                    <Questions>
                        {categories.map(category => (
                            <div key={category.id}>
                                {category.title && <h3>{category.title}</h3>}
                                <ul>
                                    {questions.filter(q => q.category_id == category.id).map(question => {
                                        const busy = processing.find(p => p.id == question.id);

                                        return (
                                            <li 
                                                key={question.id} 
                                                className={selected && selected.id == question.id ? "active": question.status != "Active" ? "deleted" : "" }
                                                onClick={() => props.noSelection ? null : setSelected(question)}
                                            >
                                                <div className="question">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: question.checked_user_id ? 100 : 5000, hide: 400 }}
                                                        overlay={
                                                            <Tooltip>
                                                                { question.checked_user_id ? 
                                                                    <>
                                                                        Answered by {question.checked_user} on {formatDate(question.checked_date)}.
                                                                    </>
                                                                    :
                                                                    "Answer Question"
                                                                }
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className="buttons">
                                                            <Button onClick={() => onQuestion(question, 1)} disabled={props.disabled || (busy && busy.checked == 1)} variant={question.checked_ind == 1 ? props.reverseColors ? "danger":"success" : "outline-secondary"} size="icon">
                                                                <FontAwesomeIcon icon={busy && busy.checked == 1 ? faSpinner:faThumbsUp} spin={busy && busy.checked == 1} />
                                                            </Button>
                                                            <Button onClick={() => onQuestion(question, 0)} disabled={props.disabled || (busy && busy.checked == 0)} variant={question.checked_ind == 0 ? props.reverseColors ? "success":"danger" : "outline-secondary"} size="icon">
                                                                <FontAwesomeIcon icon={busy && busy.checked == 0 ? faSpinner:faThumbsDown} spin={busy && busy.checked == 0}  />
                                                            </Button>
                                                        </div>
                                                    </OverlayTrigger>
                                                    <div>
                                                        {question.question}
                                                        {question.score && 
                                                            <Badge variant="secondary">
                                                                {question.score}
                                                            </Badge>
                                                        }

                                                        {(question.reference_type && question.reference_id) && 
                                                            <div className={"extra " + (question.checked_ind == 1 ? props.reverseColors ? "danger":"success" : question.checked_ind == 0 ? props.reverseColors ? "success":"danger" : "")}>
                                                                {question.reference_type == "Order" ? 
                                                                    <Link to={"/orders/order/" + question.reference_id}>Order #{question.reference_id}</Link>
                                                                :
                                                                    <>{question.reference_type}{' '}{question.reference_id}</>
                                                                }
                                                            </div>
                                                        }
                                                        {(question.name && props.extraData && props.extraData[question.name]) &&
                                                            <div className={"extra " + (question.checked_ind == 1 ? props.reverseColors ? "danger":"success" : question.checked_ind == 0 ? props.reverseColors ? "success":"danger" : "")}>
                                                                <span dangerouslySetInnerHTML={{__html: props.extraData[question.name]}} />
                                                            </div>
                                                        }
                                                    </div>
                                                    {((question.checked_ind == 1 || question.checked_ind == 0) && !question.notes) &&
                                                        <div className="buttons note">
                                                            <Button onClick={() => onQuestionNotes(question)} disabled={busy && busy.notes} variant={"outline-warning"} size="sm">
                                                                <FontAwesomeIcon icon={faStickyNote}  />
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>

                                                {question.notes && 
                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text>
                                                                Notes
                                                            </InputGroup.Text>
                                                        </InputGroup.Prepend>

                                                        <FormControl
                                                            value={question.notes}
                                                            onChange={(event) => {
                                                                setQuestionNotes(question, event.target.value);
                                                            }}
                                                            onBlur={() => saveQuestionNotes(question)}
                                                            onFocus={(e) => e.target.select()}
                                                        />

                                                        <InputGroup.Append>
                                                            <Button onClick={() => saveQuestionNotes(question)} variant="outline-secondary">
                                                                <FontAwesomeIcon icon={busy && busy.notes ? faSpinner:faSave} spin={busy && busy.notes} />
                                                            </Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                }
                                            </li>
                                        )
                                    })} 
                                </ul>
                            </div>   
                        ))}
                    </Questions> 
            </>               
            }
        </Checklist>
    );
}

export default CheckListForm;