import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import produce from "immer"
import { addBusinessDays, parseISO, getHours, setHours }  from 'date-fns'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faMagic, faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import TextAreaField from "../components/fields/field-textarea"
import NumberField from "../components/fields/field-number"
import DateField from "../components/fields/field-date"
import ListField from "../components/fields/field-list"
import Button from "../components/buttons/button";

import GoogleImage from "../components/google-image"
import GroupPreview from "../components/group-preview"
import ShapePreview from "../components/shape-preview"
import Loading from "../components/loading"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'
import FieldLabel from "../components/fields/label";
import { Alert, Badge } from "react-bootstrap";

const Sign = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;

  img { margin-right: 10px; }
`
const Buttons = styled.div`
  margin-top: 15px;
`
function CreateJobDrawer(props) {
  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  
  const [sign, setSign] = useState(null)
  const [statuses, setStatuses] = useState([])
  const [types, setTypes] = useState([])
  const [notes, setNotes] = useState([])
  const [reviewedNotes, setReviewedNotes] = useState(false)

  const [formData, setFormData] = useState({});

  const [step, setStep] = useState(props.orderitem.type == "sign" ? "groups":"props");
  const [groups, setGroups] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [products, setProducts] = useState([]);
  
  const [validated, setValidated] = useState(false);

  useEffect(
    () => {
        const orderDate = parseISO(props.order.order_date);
        const orderHour = getHours(orderDate);
        const shipDate = setHours(addBusinessDays(orderDate, orderHour < 12 ? 4 : 5), 12);

        setFormData({
          production_days: 5,
          start_date: new Date(),
          ship_date: shipDate,
          status_id: 3,
          jobtype_id: 1,
        });

        if (props.orderitem.type == "sign") {
          setLoading(true)

          postData("signs/signdata", {id: props.orderitem.sign_id}, 
            function(response) {
              setSign(response.sign);
              setFormData(produce(draft => {
                draft.production_days = Math.max(...response.sign.groups.map(o => o.production_days))
              }))

              // if not groups (custom sign) then skip to properties
              if (response.sign.groups.length == 0)
                setStep("props")
            },
            function(error) {
              alert("An error occured loading sign data.");
            },
            function() {
              setLoading(false)
            }
          );
        }

        postData("jobs/statuses", {}, 
          function(response) {
            setStatuses(response)
          },
          function(error) {
            alert("An error occured loading job statuses.");
          }
        );

        postData("notes/possiblefornewjob", {
          order_id: props.order.id
        }, 
          function(response) {
            setNotes(response)
          },
          function(error) {
            alert("An error occured loading possible notes.");
          }
        );

        postData("jobs/types", {}, 
          function(response) {
            setTypes(response)
          },
          function(error) {
            alert("An error occured loading job types.");
          }
        );

        // postData("jobs/nextno", {orderitem_id: props.orderitem.id}, 
        //   function(response) {
        //     setFormData(produce(draft => {
        //       draft.title = response.title;
        //     }));
        //     setProductId(response.product_id);
        //   },
        //   function(error) {
        //     alert("An error occured loading job information.");
        //   }
        // );
    }, 
    [props.orderitem]
  );

  function getShapeIds() {
    const ids = [];
    for (const shape of shapes) {
      ids.push(shape.signshape_id);
    }
    return ids;
  }

  useEffect(
    () => {
      if (sign && groups.length == 0) {
        setGroups(sign.groups);

        setShapes(produce(draft => {
          for (const group of sign.groups) {
            for (const shape of group.shapes.filter(s => s.code != null && s.code != ' ')) {
              draft.push(shape);
            }
          }
        }));
      }
    }, 
    [sign]
  );
  
  useEffect(
    () => {
      if (groups && groups.length > 0) {
        setProducts([...new Set(groups.map(group => group.parent_product_title))])
      }
    }, 
    [groups]
  );
  
  
  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();


    setValidated(true);
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;

    setFormData(produce(draft => {
      draft[field] = event.target.value;
    }));
  }

  function onGroupChange(e) {
    console.log("group changed", e.target.value, e.target.checked)
    const groupId = e.target.value;
    const group = sign.groups.find(g => g.id == groupId);
    const checked = e.target.checked;

    setGroups(produce(draft => {
        const index = draft.findIndex(g => g.id == groupId);

        if (checked) {
          draft.push(group);
        }
        else if (index != -1) {
          draft.splice(index, 1)
        }
        
    }));

    setShapes(produce(draft => {
      for (const shape of group.shapes.filter(s => s.code != null && s.code != ' ')) {
        if (checked)
          draft.push(shape);
        else 
          draft.splice(draft.findIndex(s => s.signshape_id == shape.signshape_id), 1);
      }
    }));
  }
  function onShapeChange(e) {
    console.log("shape changed", e.target.value, e.target.checked)
    const shapeId = e.target.value;
    const checked = e.target.checked;

    setShapes(produce(draft => {
        const index = draft.findIndex(s => s.signshape_id == shapeId);

        if (checked) {
          for (const group of groups) {
            const shape = group.shapes.find(s => ""+s.signshape_id == ""+shapeId)

            console.log(shape)
            if (shape)
              draft.push(shape);
          }
        }
        else if (index != -1) {
          draft.splice(index, 1)
        }
        
    }));
  }

  function onCreateJob() {
    setIsProcessing(true);

    let data = {
      ...formData, 
      shape_ids: getShapeIds(),
      note_ids: notes.filter(n => n.selected).map(n => n.id).join(",")
    };

    postData("jobs/create?orderitem_id=" + props.orderitem.id, removeNulls(data), 
      function(response) {
        props.onCreate(response);
      },
      function(error) {
        alert("An error occured creating the job.");
      },
      function() {
        setIsProcessing(false);
      }
    );
  }

  const days = [];

  for (let i=1; i<=formData.production_days; i++) {
    days.push({value:i, label:"Day " + i});
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faMagic} />
            &nbsp;
            Create Job Wizard
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>

            <Sign>
                <GoogleImage 
                    root 
                    src={props.orderitem.preview_url} 
                    style={{
                        maxWidth: "125px",
                        maxHeight: "75px"
                    }}
                />
            </Sign>
                
            {loading ? 
              <Loading />
            :
              <>
                {props.orderitem.type == "sign" && 
                  <>
                    <h5>
                      Job Groups
                      {(step == "shapes" || step == "props") && 
                        <Button variant="outline-secondary" size="sm" onClick={() => setStep("groups")} style={{float:"right"}}>
                          Edit
                        </Button>
                      }
                    </h5>
                    {step == "groups" &&
                      <>
                        {sign && sign.groups.map(group => (
                            <div key={group.id} style={{display: "flex", alignItems: "center", border: "1px solid #ccc", padding: "0px 10px", borderRadius:"4px", marginBottom: "5px"}}>                            
                                <Form.Check 
                                    name="group_id"                                
                                    value={group.id}
                                    checked={groups.findIndex(g => g.id == group.id) != -1}
                                    inline 
                                    type="checkbox" 
                                    onChange={onGroupChange}
                                />
                                <GroupPreview group={group} solid={true} width={100} height={50} />

                                <div style={{flex:"1", textAlign:"right"}}>
                                  {group.product_title}
                                </div>
                            </div>
                        ))}
                        <Buttons>
                          <Button variant="outline-primary" disabled={groups.length == 0} onClick={() => setStep("shapes")}>
                            Continue {' '}
                            <FontAwesomeIcon icon={faChevronRight} />
                          </Button>
                        </Buttons>
                      </>          
                    }

                    {(step != "groups") && 
                      <h5>
                        Job Shapes
                        {(step == "props") && 
                          <Button variant="outline-secondary" size="sm" onClick={() => setStep("shapes")} style={{float:"right"}}>
                            Edit
                          </Button>
                        }
                      </h5>
                    }
                    {step == "shapes" &&
                      <>
                        {groups.map(group => (
                          (group.shapes.filter(s => s.code != null && s.code != ' ').map(shape => (
                            <div key={shape.signshape_id} style={{display: "flex", alignItems: "center", border: "1px solid #ccc", padding: "5px 10px", borderRadius:"4px", marginBottom: "5px"}}>
                                <Form.Check 
                                    name="shape_id"                                
                                    value={shape.signshape_id}
                                    checked={shapes.findIndex(s => s.signshape_id == shape.signshape_id) != -1}
                                    inline 
                                    type="checkbox" 
                                    onChange={onShapeChange}
                                />
                                {shape.path && <ShapePreview path={shape.path} solid={true} width={50} height={35} />}
                                {!shape.path && <>{shape.code}</>}
                            </div>
                          )))
                        ))}
                        <Buttons>
                          <Button variant="outline-primary" disabled={shapes.length == 0} onClick={() => setStep("props")}>
                            Continue {' '}
                            <FontAwesomeIcon icon={faChevronRight} />
                          </Button>
                        </Buttons>
                      </>
                    }
                  </>
                }

                {(step != "groups" && step != "shapes") && 
                  <h5>
                    Job Properties
                  </h5>
                }
                {step == "props" &&
                  <>
                    <ListField 
                        name="jobtype_id" 
                        label="Job Type"
                        required
                        value={formData.jobtype_id} 
                        list={types}
                        onChange={(e) => handleFormChange(e)} 
                    />

                    {formData.jobtype_id != 1 && 
                      <ListField 
                        name="parent_job_id" 
                        label="Parent Job" 
                        required
                        value={formData.parent_job_id} 
                        list={props.orderitem.jobs.map(j => {
                          return {
                            value: j.id,
                            label: j.title
                          }
                        })}
                        onChange={(e) => handleFormChange(e)} 
                      />                      
                    }
                    {formData.jobtype_id != 1 && 
                      <NumberField 
                        name="jobtype_cost" 
                        label="Repair/Replace Cost" 
                        prepend="$"
                        required
                        value={formData.jobtype_cost} 
                        onChange={(e) => handleFormChange(e)} 
                      />                      
                    }
                    {formData.jobtype_id != 1 && 
                      <TextAreaField 
                        name="jobtype_notes" 
                        label="Repair/Replace Notes" 
                        required
                        value={formData.jobtype_notes} 
                        onChange={(e) => handleFormChange(e)} 
                      />                      
                    }

                    {/* <TextField 
                          name="title" 
                          label="Title" 
                          required
                          disabled
                          value={formData.title} 
                          onChange={(e) => handleFormChange(e)} 
                      />  */}

                    <NumberField 
                          name="production_days" 
                          label="Production Days" 
                          required
                          value={formData.production_days} 
                          onChange={(e) => handleFormChange(e)} 
                      />  
                    {false && 
                      <DateField 
                          name="start_date" 
                          label="Start Date" 
                          required
                          value={formData.start_date} 
                          onChange={(e) => handleFormChange(e)} 
                      />  
                    }
                    <DateField 
                        name="ship_date" 
                        label="Ship Date" 
                        required
                        value={formData.ship_date} 
                        onChange={(e) => handleFormChange(e)} 
                    />  
                    <ListField 
                        name="status_id" 
                        label="Initial Status"
                        required
                        value={formData.status_id} 
                        list={statuses}
                        onChange={(e) => handleFormChange(e)} 
                    />

                    <FieldLabel>Possible Job Notes</FieldLabel>
                    {notes.map(note => (
                        <div key={note.id} style={{display:"flex", alignItems:"center", gap: "5px"}}>
                            <Form.Check 
                                name="product"                                
                                defaultValue={note.id}
                                checked={note.selected}
                                type="checkbox" 
                                onChange={(e) => {
                                  e.persist();

                                  setNotes(produce(draft => {                                    
                                    let idx = draft.findIndex(n => n.id == note.id);

                                    if (idx != -1)
                                      draft[idx].selected = e.target.checked;
                                  }))
                                }}
                            />
                            {note.user_id && 
                                <Badge variant="secondary">
                                  User: {note.user_title}
                                </Badge>
                              }
                            {note.sign_id && 
                              <Badge variant="secondary">
                                Sign: {note.sign_title}
                              </Badge>
                            }
                            {note.quote_id && 
                                <Badge variant="secondary">
                                    Project: {note.quote_title}
                                </Badge>
                            }
                            {note.order_id && 
                                <Badge variant="secondary">
                                    Order: {note.order_title}
                                </Badge>
                            }
                            <div>
                              {note.notes}
                            </div>
                        </div>
                    ))}    
                    {(notes.length > 0 && notes.filter(n => n.selected).length == 0) ?
                      <div style={{
                        border: "1px solid red",
                        padding: "10px",
                        borderRadius: "5px",
                        marginTop: "10px",
                      }}>
                        <Form.Check 
                            name="reviewed_ind"                                
                            value={true}
                            checked={reviewedNotes}
                            inline 
                            label="I have reviewed all of the possible job notes"
                            type="checkbox" 
                            onChange={(e) => {
                              setReviewedNotes(e.target.checked)
                            }}
                        />
                      </div>
                    : (notes.length == 0) ?
                      <p style={{color:"#ccc"}}>No Notes Found</p>
                    :
                      null
                    }                

                    {(products.length > 1) &&
                      <Alert variant="danger" style={{marginTop:"20px"}}>
                        <small>
                        Warning: This sign contains multiple sign types.  A job will be created for each of the following
                        products:
                        <ul style={{margin:"0px", paddingLeft:"10px"}}>
                        {products.map(product => (
                          <li>{product}</li>
                        ))}
                        </ul>
                        </small>
                      </Alert>
                    }

                    <Buttons>
                      <Button 
                        variant="outline-primary" 
                        disabled={isProcessing || (notes.length > 0 && notes.filter(n => n.selected).length == 0 && !reviewedNotes)}
                        onClick={onCreateJob}
                      >
                        <FontAwesomeIcon icon={isProcessing ? faSpinner:faPlus} spin={isProcessing} />{' '}
                        Create Job
                      </Button>
                    </Buttons>
                  </>
                }
              </>
            }
          </Form>
        </Modal.Body>
      </Modal>
    )
}

export default CreateJobDrawer;