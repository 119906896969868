import React, { useState, useEffect, useContext } from "react";

import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStickyNote, faListAlt, faEnvelope, faCopy, faCommentDots, faClipboardCheck, faHourglass, faPencilAlt, faShoppingCart, faCreditCard, faPaintBrush, faShapes, faUser, faCheckCircle, faSpinner, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import produce from "immer"
import moment from 'moment';

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"

import SignOverviewPanel from "./panels/panel-sign-overview"
import SignGroupsPanel from "./panels/panel-sign-groups"
import SignHistoryPanel from "./panels/panel-sign-history"
import EmailsPanel from "./panels/panel-emails"
import FilesPanel from "./panels/panel-files"
import NotesPanel from "./panels/panel-noteslist"
import TasksPanel from "./panels/panel-tasks"
import CartsPanel from "./panels/panel-carts"
import OrdersPanel from "./panels/panel-orders"
import BuilderPanel from "./panels/panel-builder"
import Button from "../components/buttons/button";

import EmailDrawer from "../drawers/drawer-email";

import { postData, postExternalData } from "../common/services/server"

import settings from "../settings"
import { colors } from "../settings/settings";
import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import Badge from "react-bootstrap/esm/Badge";
import PageLayout from "../components/page-layout";

function SignPage(props) {
    const history = useHistory();
    let { tab, id } = useParams();

    if (!tab) tab = "overview";

    const search = props.location.search;
    const params = new URLSearchParams(search);
    const embeded = params.get("embeded") == 1;

    const [isLoading, setIsLoading] = useState(true);
    const [sign, setSign] = useState(null);
    const [stats, setStats] = useState(null);
    const [room, setRoom] = useState(null);
    const [busy, setBusy] = useState({});
    const [selected, setSelected] = useState(null);
    const [dirty, setDirty] = useState({});
    
    function loadSignData() {
        setIsLoading(true);

        postExternalData(settings.SITE_API_URL + "builder/signdata?id=" + id, {},
            function(result) {
                setSign(result);
                setDirty({})
            },
            function(error) {
                alert("Error loading sign")
            },
            function() {
                setIsLoading(false);
            }
        );
    }

    useEffect(
        () => {
            loadSignData();

            postData("signs/stats", { 
                id: id
            },
                function(result) {
                    setStats(result);
                },
                function(error) {
                    alert("Error loading sign stats")
                }
            );
        }, 
        [id]
    );

    function recalculatePrice() {
        setIsLoading("true");

        postExternalData(settings.SITE_API_URL + "signs/updateprice", {sign_id: sign.id}, 
            function(result) {
              setSign(result);
            },
            function(error) {
              alert("An error occured calculating the sign price.");
            },
            function() {
                setIsLoading(false);
            }
        );
    }

    function regenerateDesc() {
        setBusy(produce(draft => {
            draft.description = true
        }));

        postExternalData("signs/regeneratedescription", {sign_id: sign.id}, 
            function(result) {
                setSign(produce(draft => {
                    draft.description = result;
                }));
            },
            function(error) {
              alert("An error occured generating the sign description.");
            },
            function() {
                setBusy(produce(draft => {
                    draft.description = false
                }));        
            }
        );
    }

    const custom = sign && sign.custom_ind == 1;

    let breadcrumb = [];

    if (sign && sign.quote_id) {
        breadcrumb.push({url:"/projects", title:"Projects"})
        breadcrumb.push({url:"/projects/project/" + sign.quote_id, title:"Project #" + sign.quote_id});
    }
    else {
        breadcrumb.push({url:"/signs", title:"Signs"})
    }

    return (
        <PageLayout>
            <PageHeader 
                title={(custom ? "Custom " : "") + "Sign #" + id}
                subtitle={sign && sign.version ? "(version " + sign.version + ")" : ""}
                help="sign"
                parents={breadcrumb}
                embeded={embeded}
                header={
                    (sign && (sign.status == "Draft" || sign.estimate_approved_ind == 0) && userHasPermission(Permissions.Signs, Actions.Approve)) ? 
                        <div style={{backgroundColor: colors.lightRed, flexBasis: "100%", border: "1px solid #ccc", borderRadius: "5px", padding: "5px", fontSize:"80%", textAlign:"center"}}>
                            <Button
                                size="sm"
                                variant="outline-danger"
                                style={{padding: "2px 6px"}}
                                onClick={() => {
                                    setBusy(produce(draft => {
                                        draft.status = true
                                    }));
                                    
                                    postData("signs/approve", { 
                                        sign_id: sign.id
                                    },
                                        function(result) {
                                            setSign(produce(draft => {
                                                draft.status = "Active"
                                            }));
                                        },
                                        function(error) {
                                            alert("Error approving sign")
                                        },
                                        function() {
                                            setBusy(produce(draft => {
                                                draft.status = false
                                            }));
                                        }
                                    );                                    
                                }}
                            >
                                <FontAwesomeIcon icon={busy.status ? faSpinner : faThumbsUp} spin={busy.status} />{' '}
                                Approve Sign
                            </Button>                            
                        </div>
                    :
                        null
                }
                tab={tab}
                tabUrl={"/signs/sign2/{tab}/" + id}
                tabs={[
                    {name:"overview", title:"Details"},
                    {name:"groups", title:"Groups", icon:faListAlt},
                    {name:"builder", title:"Builder", icon:faPaintBrush, hidden: custom},
                    {name:"emails", title:"Emails", icon:faEnvelope},
                    {name:"files", title:"Files", icon:faCopy},
                    {name:"notes", title:"Notes", icon:faStickyNote, count: stats ? stats.notes : null},
                    {name:"tasks", title:"Tasks", icon:faClipboardCheck, count: stats ? stats.tasks : null, hideOnNoCount: true},
                    {name:"history", title:"History", icon:faHourglass, count: sign ? sign.version : null, hideOnNoCount: true},
                    {name:"carts", title:"Carts", icon:faShoppingCart, count: stats ? stats.carts : null, hideOnNoCount: true},
                    {name:"orders", title:"Orders", icon:faCreditCard, count: stats ? stats.orders : null, hideOnNoCount: true},
                ]}
                onTab={(tab) => {
                    history.push("/signs/sign2/" + tab + "/" + id);

                    if (dirty.signdata && (tab=="overview" || tab=="groups" || tab=="history")) {
                        loadSignData();
                    }
                }}
                onNewNote={(note) => {
                    console.log("onNewNote", note)
                    if (note.sign_id && note.sign_id == sign.id) {
                        setStats(produce(draft => {
                            draft.notes++;
                        }));
                    }
                }}
            >
                {sign && 
                    <Button onClick={(e) => {window.open(settings.SIGNDETAILS_URL + sign.uid); e.preventDefault()}} href={settings.SIGNDETAILS_URL + sign.uid} variant="success" size="sm">
                        <FontAwesomeIcon icon={faListAlt} />
                        &nbsp;
                        Details Page
                    </Button>
                }
                {' '}
                {(sign && !custom) && 
                    <Button onClick={(e) => {window.open(settings.SIGNEDIT_URL + sign.uid + "&template=-1"); e.preventDefault()}} href={settings.SIGNEDIT_URL + sign.uid + "&template=-1"} variant="warning" size="sm">
                        <FontAwesomeIcon icon={faPencilAlt} />
                        &nbsp;
                        Builder
                    </Button>
                }
            </PageHeader>
            <Content embeded={embeded} permission={Permissions.Signs}>
            <>
                {
                    isLoading 
                ?
                    <Loading />
                :    
                    <>
                        {tab === "overview" &&  
                            <SignOverviewPanel 
                                sign={sign} 
                                stats={stats}
                                busy={busy}
                                dirty={dirty}
                                onRecalculatePrice={recalculatePrice}
                                onRegenerateDesc={regenerateDesc}
                                onSignPropChange={(prop, value) => {
                                    if (prop == "note") {
                                        setStats(produce(draft => {
                                            draft[prop] = value;
                                        }));
                                    } 
                                    else {
                                        setSign(produce(draft => {
                                            draft[prop] = value;
                                        }));
                                    }
                                    setDirty(produce(draft => {
                                        draft[prop] = true
                                    }));
                                }}
                                onSaveSignProp={(prop, value=null) => {
                                    console.log("onSaveSignProp", prop, value)

                                    setBusy(produce(draft => {
                                        draft[prop] = true
                                    }));

                                    if (value == null && prop == "note")
                                        value = stats[prop];
                                    else if (value == null)
                                        value = sign[prop];
                                        
                                    if (prop == "estimate_expire_date" && value) {
                                        value = moment.utc(value).format('YYYY-MM-DD');
                                    }

                                    if (value != null && prop != "note") {
                                        setSign(produce(draft => {
                                            draft[prop] = value;
                                        }));
                                    }

                                    postData("signs/updateprop", { 
                                        sign_id: sign.id,
                                        prop,
                                        value
                                    },
                                        function(result) {
                                            if (prop=="options_cost" || prop=="shipping_adjustment")
                                                recalculatePrice();

                                            if (result && typeof result === 'object' && result !== null) {
                                                setSign(produce(draft => {
                                                    for (const key of Object.keys(result)) {
                                                        draft[key] = result[key];
                                                    }
                                                }));
                                            }
                                        },
                                        function(error) {
                                            alert("Error updating sign")
                                        },
                                        function() {
                                            setBusy(produce(draft => {
                                                draft[prop] = false
                                            }));
                                            setDirty(produce(draft => {
                                                draft[prop] = false
                                            }));
                                        }
                                    );
                                }}
                                onSignChanged={(result) => {
                                    //recalculatePrice()
                                    if (result)
                                        setSign(result);
                                        
                                    setDirty({});
                                }}
                            /> 
                        }
                        {tab === "groups" &&  
                            <SignGroupsPanel 
                                sign={sign} 
                                stats={stats}
                                busy={busy}
                                onSignChange={(updatedSign) => {
                                    recalculatePrice();
                                }}
                                onSignApproved={() => {
                                    loadSignData()                               
                                }}
                            /> 
                        }
                        {tab === "history" &&  
                            <SignHistoryPanel 
                                sign={sign} 
                                onSignChange={() => {
                                    loadSignData();
                                }}
                            /> 
                        }
                        {tab === "builder" &&  
                            <BuilderPanel
                                sign={sign}
                                onSaved={(signData) => {
                                    setSign(signData);
                                    // setDirty(produce(draft => {
                                    //     draft.signdata = true
                                    // }));
                                }}
                                haveTabs={true}
                            />
                        }
                        {tab === "files" &&  
                            <FilesPanel
                                filters={{
                                    sign_id: sign.id
                                }}
                                sortBy="source"
                                sortDir="DESC"
                                haveTabs={true}
                                deletePath={"files/deletefromsign?sign_id="+sign.id}
                                uploadPath={"signs/upload?sign_id="+sign.id}
                                uploadMultiple
                            />
                        }
                        {tab === "notes" &&
                            <NotesPanel
                                filters={{
                                    sign_id: sign.id
                                }}
                                haveTabs={true}
                            />
                        } 
                        {tab === "tasks" &&
                            <TasksPanel
                                filters={{
                                    sign_id: sign.id
                                }}
                                haveTabs={true}
                            />
                        }                         
                        {tab === "emails" &&  
                            <EmailsPanel
                                filters={{
                                    sign_id: sign.id
                                }}
                                haveTabs={true}
                                buttons={[
                                    {
                                        name: "Send Sign",
                                        label: "Send To Customer",
                                        variant: "outline-secondary",
                                        icon: faUser,
                                        disabled: sign.status == "Draft" || !userHasPermission(Permissions.Emails, Actions.Send)
                                    },
                                    {
                                        name: "Sign Approved",
                                        label: "Send Approved To Customer",
                                        variant: "outline-secondary",
                                        icon: faCheckCircle,
                                        disabled: sign.status == "Draft" || !userHasPermission(Permissions.Emails, Actions.Send)
                                    },                                    
                                ]}
                                onButton={(name) => {
                                    console.log("button", name)
                                    let subject = "Sign Design #" + sign.id;
                                    let preview = null;
                                    
                                    if (name == "Sign Approved") {
                                        subject += " APPROVED";
                                        preview = "Your Sign Can Now Be Purchased";
                                    }

                                    setSelected({mode:"email", template:name, subject, preview})
                                }}
                            />
                        }
                        {tab === "carts" &&
                            <CartsPanel
                                filters={{
                                    sign_id: sign.id
                                }}
                                haveTabs={true}
                            />
                        } 
                        {tab === "orders" &&
                            <OrdersPanel
                                filters={{
                                    sign_id: sign.id
                                }}
                                haveTabs={true}
                            />
                        } 
                    </>
                }
            </>
            </Content>
            { selected && selected.mode == "email" && 
                <EmailDrawer 
                    bodyUrl={"emails/template?template=" + escape(selected.template) + "&sign_id=" + sign.id}
                    email={{
                        sign_id: sign.id,
                        template: "simple",
                        to: sign.user_email,
                        subject: selected.subject,
                        preview: selected.preview,
                        body: ""
                    }}
                    show={true}
                    onSaved={(email) => {
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }         
        </PageLayout>
    );
}

export default SignPage;