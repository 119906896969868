import React from "react";

import GroupPreview from "../components/group-preview"
import SelectField from "../components/fields/field-select";
import Panel from "./panel";
import { List, ListItem } from "../components/list"

import { useGetJobMaterials } from "../api/queries/workflowjobs";
import { useWorkflowJobUpdatePartProp } from "../api/mutations/workflowjobs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function JobWorkflowMaterialsPanel({jobId}) {
    const dataQuery = useGetJobMaterials(jobId);
    const data = dataQuery.data;

    const updatePartProp = useWorkflowJobUpdatePartProp(jobId, {
        onError: () => window.alert("Error updating group")
    });    

    return (
        <Panel query={dataQuery}>
            {data && 
                <div className="flex gap-2">
                    <List>
                        {data.groups.map(group => (
                            <ListItem key={group.id} className="grid grid-cols-[125px,1fr] items-center">
                                <div className="text-xs">
                                    <GroupPreview group={group} solid={true} width={100} height={50} />
                                    <div>
                                        {group.product}
                                    </div>
                                    <div>
                                        Size {group.size}
                                    </div>
                                    {group.style_type == "letters" && 
                                        <div>
                                            {group.style}
                                        </div>
                                    }                                      
                                </div>
                                <div className="flex items-stretch gap-1">
                                {group.parts.map(part => (
                                    <div key={part.id} className="border border-gray-300 flex flex-col gap-1 rounded p-1 text-sm">
                                        <div className="flex-1 flex items-center gap-1">
                                            {part.title}

                                            {(updatePartProp.isLoading && updatePartProp.variables?.jobGroupId == group.id && updatePartProp.variables?.partId == part.id) && 
                                                <FontAwesomeIcon icon={faSpinner} spin />
                                            }
                                        </div>
                                        <SelectField
                                            name="material"
                                            value={part.material_part_id}
                                            items={data.materials}
                                            labelField="title"
                                            disabled={updatePartProp.isLoading && updatePartProp.variables?.jobGroupId == group.id && updatePartProp.variables?.partId == part.id}
                                            invalid={!part.material_part_id}
                                            size="xs"
                                            onChange={(e,value) => {
                                                updatePartProp.mutate({
                                                    jobGroupId: group.id, 
                                                    partId: part.id, 
                                                    prop: "material_part_id", 
                                                    value
                                                });
                                            }}
                                        />
                                    </div>
                                ))}
                                </div>
                            </ListItem>
                        ))}
                    </List>
                </div>
            }
        </Panel>
    )
}
