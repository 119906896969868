import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaintBrush, faSave, faScrewdriver } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import SelectField from "../components/fields/field-select"
import Label from "../components/fields/label"
import Loading from "../components/loading"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

function ColorDrawer(props) {
  const formRef = useRef(null);

  const [systems, setSystems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (props.show && props.color) {
      setFormData(JSON.parse(JSON.stringify(props.color)));

      setLoading(true);

      postData("colors/allsystems", {color_id: props.color.id}, 
          function(results) {
              setSystems(results);
          },
          function(error) {
              alert("An error occured loading the color systems.");
          },
          function() {
              setLoading(false);
          }
      );  
    }
  }, [props.show, props.color]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      const data = {
        ...formData,
        colorsystem_ids: systems.filter(s => s.selected_ind == 1).map(s => s.id).join(",")
      }
      postData("colors/save", removeNulls(data), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the color.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();

    setFormData(produce(draft => {
      draft[event.target.name] = event.target.value;
    }));
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPaintBrush} />
            &nbsp;
            Color
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? 
            <Loading />
          :  
            <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
              <p>Color Systems</p>
              <p>
                {systems.map(system => (
                  <Form.Check 
                      key={system.id}
                      name="colorsystem_id"
                      value={system.id}
                      checked={system.selected_ind == 1}
                      label={system.name} 
                      onChange={(e) => {
                          if (e.persist) e.persist();

                          setSystems(produce(draft => {
                            draft[draft.findIndex(s => s.id == system.id)].selected_ind = e.target.checked ? 1 : 0;
                          }));
                      }}
                  />
                ))}
              </p>

              <TextField 
                  name="name" 
                  label="Color Name" 
                  required
                  value={formData.name} 
                  onChange={(e) => handleFormChange(e)} />
              <TextField 
                  name="code" 
                  label="Color Code" 
                  required 
                  value={formData.code} 
                  onChange={(e) => handleFormChange(e)} />

              <Label required>Day Color</Label>

              <InputGroup>
                  <InputGroup.Prepend>
                      <InputGroup.Text>#</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl 
                      as="input" 
                      type="text" 
                      name="hex_day"
                      required
                      value={formData.hex_day} 
                      onChange={(e) => handleFormChange(e)}
                  />
                  <InputGroup.Append>
                      <InputGroup.Text style={{backgroundColor: "#"+formData.hex_day}}>&nbsp;</InputGroup.Text>
                  </InputGroup.Append>
              </InputGroup>

              <Label required>Night Color</Label>
              <InputGroup style={{marginBottom:"12px"}}>
                  <InputGroup.Prepend>
                      <InputGroup.Text>#</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl 
                      as="input" 
                      type="text" 
                      name="hex_night"
                      value={formData.hex_night} 
                      onChange={(e) => handleFormChange(e)}
                  />
                  <InputGroup.Append>
                      <InputGroup.Text style={{backgroundColor: "#"+formData.hex_night}}>&nbsp;</InputGroup.Text>
                  </InputGroup.Append>
              </InputGroup>

              <SelectField 
                  name="led_id" 
                  label="LED" 
                  required 
                  url="colors/leds"
                  labelField="code"
                  value={formData.led_id} 
                  onChange={(e) => handleFormChange(e)} />

            </Form>
          }
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Color" 
                disabled={loading}
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ColorDrawer;