import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"
import styled from "styled-components"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChessBoard, faCheck } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import ColorBox from '../components/color-box'
import Loading from "../components/loading"
import { postData } from "../common/services/server"

const Materials = styled.div`
    > div {
        border: 1px solid #ccc;
        padding: 5px;
        margin-bottom: 2px;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;

        &.current {
            background-color: #eee;

            button {
                display: none;
            }
        }
    }
`

function MaterialSelectDrawer(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [materials, setMaterials] = useState([]);

    useEffect(
        () => {
            setIsLoading(true);

            postData("products/materials", {},
                function(result) {
                    setMaterials(result);
                },
                function(error) {
                    alert("An error occured while loading the materials.");
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        []
    );

    function onMaterialSelected(material) {
        console.log("onMaterialSelected", material)
        props.onMaterial(material);
    }

  return (
      <Modal show={true} onHide={props.onHide} scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faChessBoard} />
            &nbsp;
            Material Selector
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { isLoading ?
                <Loading />
            :    
              <>
                <Materials>
                {materials.map((material, index) => (
                    <div key={index} className={props.part && material.id == props.part.material_id ? "current":""}>
                        {material.title}

                        <Button onClick={() => onMaterialSelected(material)} size="sm" variant="outline-primary">
                            <FontAwesomeIcon icon={faCheck} />{' '}
                            Select Material
                        </Button>
                    </div>
                ))}
                </Materials>              
              </>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default MaterialSelectDrawer;