import React, { useState, useEffect, useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHandHolding, faHandshake, faShapes, faUser } from "@fortawesome/free-solid-svg-icons";
import { differenceInMinutes, formatDistanceToNow, parseISO } from "date-fns";
import { clsx } from "clsx";

import Panel from "./panel";
import ZoomableImage from "../components/image-zoomable";
import DropdownMenu from "../components/dropdownmenu";

import { PusherContext } from "../common/services/pusher";
import { useGetDraftSigns } from "../api/queries/signs";
import storage from "../settings/storage";

export default function DraftSignsPanel({
}) {
    const pusher = useContext(PusherContext);

    const [minutes, setMinutes] = useState(0);

    const signsQuery = useGetDraftSigns();
    const signs = signsQuery.data;

    useEffect(() => {
        if (!pusher) {
            console.log("DraftNotifications pusher undefined");
            return;
        }
        
        const channel = pusher.subscribe("signs");

        channel.bind_global(function (event, data) {
            console.log(`The event ${event} was triggered`);
            console.log(data)

            // Is is something we care about?
            if (event == "draft-changed" && data && data.uid) {
                signsQuery.refetch();
            }
        })

        return () => {
          pusher.unsubscribe("signs")
        };
    }, [pusher]); // [reconnect]

    useEffect(() => {
        const interval_time = 1000 * 30;

        // Set an interval that will update every interval_time
        const myInterval = setInterval(
            () => setMinutes(value => value+1),
            interval_time
        );

        // Clear the interval when the component unmounts
        return () => clearInterval(myInterval);
    }, []);

    return (
        <Panel query={signsQuery}>
            {signs &&
                <div className="flex items-stretch flex-wrap gap-2">
                    {signs.map(sign => {
                        const minutes = differenceInMinutes(new Date(), parseISO(sign.updated_date || sign.added_date));

                        return (
                        <div key={sign.id} className={clsx(
                            "relative border rounded p-1",
                            minutes < 5 && "!border-red-500"
                        )}>
                            {(sign.id || sign.user_id) && 
                                <DropdownMenu
                                    icon={faBars}
                                    className="text-gray-400 absolute right-1 top-1"
                                    menu={[
                                        {name: "sign", caption: "Sign Details", icon:faShapes, hidden:!sign.id},
                                        // {name: "interactive", caption: "Interactive Builder", icon:faHandshake},
                                        {name: "user", caption: "User Details", icon:faUser, hidden:!sign.user_id},
                                    ]}
                                    onMenuItem={(item) => {
                                        if (item.name == "interactive")
                                            window.open("#/interactivebuilder/" + sign.uid)
                                        else if (item.name == "sign")
                                            window.open("#/signs/sign/" + sign.id)
                                        else if (item.name == "user")
                                            window.open("#/users/user/" + sign.user_id)
                                    }}
                                />
                            }

                            <div className="grid grid-cols-2 gap-2">
                                <div>
                                    <ZoomableImage
                                        url={storage.root + sign.preview_url}
                                        maxHeight="100px"
                                        maxWidth="200px"
                                    />
                                </div>
                                <div class="space-y-1">
                                    {sign.id &&
                                        <button 
                                            className="block text-blue-500 hover:underline"
                                            onClick={() => {
                                                window.open("#/signs/sign/" + sign.id)
                                            }}
                                        >
                                            Design #{sign.id}
                                        </button>
                                    }
                                    {sign.user_id && 
                                        <button 
                                            className="block text-sm text-blue-500 hover:underline"
                                            onClick={() => {
                                                window.open("#/users/user/" + sign.user_id)
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faUser} className="mr-1" />
                                            {sign.user_name || sign.user_email || sign.user_id}
                                        </button>
                                    }
                                    <div className="text-sm">
                                        {sign.size_height}x{sign.size_width}
                                    </div>
                                    <div className="text-gray-400 space-x-1">
                                        <span>{sign.updated_date ? "Updated" : "Added"}</span>
                                        <span className={clsx(
                                            minutes < 5 && "text-red-500",
                                        )}>
                                            {formatDistanceToNow(parseISO(sign.updated_date || sign.added_date))}
                                        </span>
                                        <span>ago</span>
                                    </div>
                                    <div className="flex items-center gap-1 text-xs">
                                        {sign.device && 
                                            <div className="bg-gray-200 rounded px-2 py-1">{sign.device}</div>
                                        }
                                        {sign.os && 
                                            <div className="bg-gray-200 rounded px-2 py-1">{sign.os}</div>
                                        }
                                        {sign.browser && 
                                            <div className="bg-gray-200 rounded px-2 py-1">{sign.browser}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    })}   
                </div>
            }          
        </Panel>
    );
}