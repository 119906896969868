import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutate } from '../api';

export const useSetProjectProp = (projectId, prop) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (value) => {
            return mutate("projects/project/setprop", {
                id: projectId,
                prop,
                value
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["projects", ""+projectId]);

            if (prop == "manager_user_id")
                queryClient.invalidateQueries(["cards", "project"]);
        },    
  })
};

export const useDeleteProject = (projectId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return mutate("projects/project/delete", {
                id: projectId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["projects"]);
            queryClient.invalidateQueries(["cards", "project"]);
        },    
  })
};

export const useAddUserToProject = (projectId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (userId) => {
            return mutate("projects/project/adduser", {
                id: projectId,
                user_id: userId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["projects", ""+projectId]);
            // queryClient.invalidateQueries(["cards", "project"]);
        },    
  })
};

export const useBecomeProjectManager = (projectId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return mutate("projects/project/becomemanager", {
                id: projectId
            })
        },
        onSuccess: (data, variables, context) => {
            console.log("useBecomeProjectManager result", data )
            // only refresh the queries if we made a change to the manager
            if (data) {
                queryClient.invalidateQueries(["projects", ""+projectId]);
                queryClient.invalidateQueries(["cards", "project"]);
            }
        },    
  })
};
