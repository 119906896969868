import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faNetworkWired, faUser } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'
import FieldCustom from "../components/fields/field-custom";

function IpAddressDrawer({ipAddress, onSaved, onHide}) {
  const formRef = useRef(null);

  const [busy, setBusy] = useState("");
  const [formData, setFormData] = useState(ipAddress || {});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (ipAddress) {
      setFormData(ipAddress);
    }
  }, [ipAddress]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    console.log("valid", formRef.current.checkValidity());

    formRef.current.reportValidity();

    if (formRef.current.checkValidity()) {
      setBusy("save");

      postData("users/saveipaddress", removeNulls(formData), 
        function(response) {
          onSaved(response);
        },
        function(error) {
          alert("An error occured saving the ip address.");
        },
        function() {
          setBusy("");
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();

    const name = event.target.name;
    const value = event.target.value
      
    //console.log("handleFormChange", event.target.name, event.target.value)
    setFormData(produce(draft => {
        draft[name] = value;
    }));
  }

  return (
      <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faNetworkWired} />
            &nbsp;
            IP Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>

            <TextField 
                name="name" 
                label="Name" 
                required 
                value={formData.name || ""} 
                onChange={(e) => handleFormChange(e)} />
            <TextField 
                name="ip_address" 
                label="IP Address" 
                required 
                value={formData.ip_address || ""} 
                onChange={(e) => handleFormChange(e)} /> 

            <FieldCustom
                label="Employee Restrictions"
            >
                {(ipAddress.users && ipAddress.users.length > 0) &&
                    <div>
                        {ipAddress.users.split(",").map(user => (
                            <div key={user} className="flex gap-5">
                                <FontAwesomeIcon icon={faUser} />
                                {user}
                            </div>
                        ))}
                    </div>
                }
            </FieldCustom>
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={busy == "save"}
                disaabled={busy != ""}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save IP Address" 
                icon={faSave} /> 

            <Button variant="outline-secondary" onClick={onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default IpAddressDrawer;