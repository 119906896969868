import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import produce from "immer"
import styled from "styled-components";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import CartItemsDrawer from "./drawer-cartitems"
import Loading from "../components/loading";
import Button from "../components/buttons/button";

import { postData } from "../common/services/server";
import { formatDateTime, formatPrice, removeNulls } from "../helpers/utility";

const Orders = styled.table`
    width: 100%;

    th {
        background-color: #eee;
        padding: 3px 6px;
    }
    td {
        border-bottom: 1px solid #eee;
        padding: 3px 6px;
        font-size: 80%;
    }
`

function CartsDrawer(props) {
  const [loading, setLoading] = useState(false);
  const [carts, setCarts] = useState([]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setLoading(true);

    postData("carts/data", removeNulls({ 
        ...props.filters,
        page: 1,
        perPage: 100,
    }),
        function(result) {
            setCarts(result.carts);
        },
        function(error) {
            alert("Error loading carts")
        },
        function() {
            setLoading(false);
        }
    );    
  }, []);

  function onCartChanged(cart) {
    const rowIndex = carts.findIndex((c) => c.id == selected.cart.id)

    console.log("onCartChanged", cart, rowIndex)

    setCarts(produce(draft => {
        if (rowIndex == -1)
            draft.push(cart);
        else
            draft[rowIndex] = cart;
    }));
}  
  return (
    <>
      <Modal show={props.show} animation={false} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faShoppingCart} />
            &nbsp;
            Carts
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loading ? 
                <Loading />
            :
                <Orders>
                    <thead>
                        <tr>
                            <th>Cart #</th>
                            <th>User</th>
                            <th>Date Created</th>
                            <th align="center">Items</th>
                            <th align="right">Total</th>
                            <th>Coupon</th>
                            <th>Discount</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {carts.map((cart, index) => (
                            <tr key={index}>
                                <td>
                                    {cart.id}
                                </td>
                                <td>
                                    {cart.user_name}
                                </td>
                                <td>
                                    {formatDateTime(cart.cart_date)}
                                </td>
                                <td align="center">
                                    <button onClick={() => setSelected({mode:"items", cart})}>
                                        {cart.item_count}
                                    </button>
                                </td>
                                <td align="right">
                                    {formatPrice(cart.cost)}
                                </td>
                                <td>
                                    {cart.coupon_code}
                                </td>
                                <td align="right">
                                    {formatPrice(cart.total_discount)}
                                </td>
                                <td align="right">
                                    {formatPrice(cart.total)}
                                </td>
                            </tr>
                        ))}  
                    </tbody>
                </Orders>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
      { selected.mode == "items" && 
            <CartItemsDrawer 
                cart={selected.cart} 
                onChange={onCartChanged} 
                onHide={ () => setSelected({}) } 
            /> 
        }      
    </> 
    )
}

export default CartsDrawer;