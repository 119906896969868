import React, { useState, useEffect } from "react"
import produce from "immer";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import Loading from "../components/loading";
import BoardNote from "../components/board/board-note";
import NoteDrawer from "../drawers/drawer-note";
import { postData } from "../common/services/server";
import { formatDateTime, removeNulls } from "../helpers/utility";
import Button from "../components/buttons/button";

function NotesPanel({
    userId,
    orderId,
    signId,
    projectId,
    jobId,
    workflowJobId,
    onNoteChanged
}) {
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState(null);
    const [selected, setSelected] = useState(null);

    useEffect(
        () => {
            setLoading(true);

            postData("notes/data", removeNulls({ 
                user_id: userId,
                order_id: orderId,
                sign_id: signId,
                project_id: projectId,
                job_id: jobId,
                workflowjob_id: workflowJobId,
            }),
                function(result) {
                    setNotes(result);
                },
                function(error) {
                    window.alert("Error loading notes data")
                },
                function() {
                    setLoading(false);
                }
            );            
        }, 
        [orderId]
    );

    function noteChanged(prop, noteData) {
        console.log("noteChanged", prop, noteData)
        setNotes(produce(draft => {
            const idx = draft.findIndex(n => n.id == noteData.id);

            if (idx == -1)
                draft.push(noteData)
            else {
                for (const key of Object.keys(noteData)) {
                    draft[idx][key] = noteData[key];
                }
            }
        }));

        if (onNoteChanged)
            onNoteChanged(noteData);
    }

    console.log({signId})
    return (
        <>
            {loading ? 
                <Loading />
            : notes ?
                <div className="grid gap-1 pb-[40px]">
                    {notes.map((note, index) => (
                        <BoardNote 
                            key={index} 
                            note={note} 
                            onEdit={() => setSelected(note)} 
                        />
                    ))}   
                </div>
            :
              null
            }

            <div className="absolute bottom-2 left-2 right-2 p-1 flex mt-2 z-10 bg-purple-300">
                <Button
                    variant="solid-secondary"
                    icon={faPlus}
                    size="sm"
                    disabled={loading}
                    onClick={() => setSelected({}) }
                >
                    Add Note
                </Button>
            </div>

            { selected && 
                <NoteDrawer 
                    show={true}
                    note={selected.id ? selected : {
                        id: 0,
                        job_id: workflowJobId || jobId,
                        job_title: "#" + (workflowJobId || jobId),
                        sign_id: signId,
                        sign_title: signId ? "#"+signId : null,
                    }}
                    onSaved={ (note) => {
                        noteChanged(null, note);
                        setSelected(null);
                    }} 
                    onHide={ () => setSelected(null) } 
                /> 
            }            
        </>
    );
}

export default NotesPanel