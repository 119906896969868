import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faExternalLinkSquareAlt, faTimesCircle, faListAlt, faPencilAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap"

import ResourceDrawer from "../drawers/drawer-resource";
import ResourceVersionsDrawer from "../drawers/drawer-resourceversions";
import ResourcesDrawer from "../drawers/drawer-selectresource";

import { postData } from "../common/services/server";
import PanelTable from "./panel-table"
import storage from "../settings/storage";

function Panel({
  type,
  type_id,
  resources,
  onChange
}) {
  const [busy, setBusy] = useState("");
  const [selected, setSelected] = useState(null);
  const [add, setAdd] = useState(false);
  const [select, setSelect] = useState(false);

  const [typeFilter, setTypeFilter] = useState(null)
  const [types, setTypes] = useState([])

  useEffect(
    () => {
      console.log(resources.length )
      // only do it if we have a lot of resources
      if (resources.length >= 10) {
        let uniqueTypes = [];
        
        for (const resource of resources) {
          if (uniqueTypes.indexOf(resource.type) == -1)
            uniqueTypes.push(resource.type);
        }

        setTypes(uniqueTypes);

        console.log({uniqueTypes})
      }
    }, 
    [resources]
  );

  return (
    <>
      <PanelTable>
        <thead>
            <tr>
              <th>
                Type
                {(types.length > 0) && 
                  <div>
                    <select value={typeFilter} onChange={(e) => setTypeFilter(e.target.value)}>
                      <option></option>
                      {types.map(type => (
                        <option key={type} value={type}>{type}</option>
                      ))}
                    </select>
                  </div>
                }
              </th>
              <th>Resource</th>
              <th>Resource Link</th>
              <th align="center" style={{textAlign:"center"}}>Versions</th>
              {(type != "job") &&
                <th>&nbsp;</th>
              }
          </tr>
        </thead>
        <tbody>
            {(resources || []).filter(r => !typeFilter || r.type==typeFilter).map(resource => (
                <tr key={resource.id}>
                    <td>
                        {resource.type}
                    </td>
                    <td>
                      {resource.title}
                    </td>
                    <td style={{whiteSpace: "nowrap"}}>
                      {(resource.url || resource.file_url) ?
                        <>
                          <a href="#" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            setSelected({mode:"view", resource});
                          }}>
                            Open Resource
                          </a>
                          <a href={resource.url || (storage.root + resource.file_url)} target="_blank">
                            <FontAwesomeIcon icon={faExternalLinkSquareAlt} style={{marginLeft: "5px"}} />
                          </a>
                        </>
                      :
                        <div style={{color: "red"}}>Missing URL?</div>
                      }
                    </td>
                    <td align="center">
                      <Button 
                        size="sm"
                        variant="outline-secondary"
                        onClick={() => setSelected({mode:"versions", resource})}
                      >
                        {resource.versions}
                      </Button>
                    </td>
                    {(type != "job") &&
                      <td align="right" style={{whiteSpace: "nowrap"}}>
                        <Button
                          variant="outline-warning"
                          size="sm"
                          onClick={() => {
                            setSelected({mode:"edit", resource});
                          }}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </Button>                                             
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => {
                            if (window.confirm("Are you sure you want to delete this resource?")) {
                              setBusy("delete"+resource.id)

                              postData("resources/delete", {
                                      id: resource.id,
                                      type,
                                      type_id
                                  }, 
                                  function(response) {
                                      let updatedResources = JSON.parse(JSON.stringify(resources));

                                      let idx = updatedResources.findIndex(r => r.id = resource.id);

                                      if (idx != -1) {
                                        updatedResources.splice(idx, 1);

                                        onChange({resources: updatedResources})
                                      }
                                  },
                                  function(error) {
                                      alert("An error occured removing the resource.");
                                  },
                                  function() {
                                      setBusy("")
                                  }
                              );                            
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={busy == ("delete"+resource.id) ? faSpinner:faTrash} spin={busy == ("delete"+resource.id)} />
                        </Button>
                      </td>
                    }
                </tr>
            ))}
        </tbody>
      </PanelTable>   

      {(type != "job") &&
        <div style={{display: "flex", gap: "5px", marginTop: "10px"}}>
            <Button
                variant="outline-success"
                size="sm"
                onClick={() => setAdd(true)}
            >
                <FontAwesomeIcon icon={faPlus} />
                &nbsp;
                Add New Resource
            </Button>   
            <Button
                variant="outline-primary"
                size="sm"
                onClick={() => setSelect(true)}
            >
                <FontAwesomeIcon icon={faListAlt} />
                &nbsp;
                Add Existing Resource
            </Button>               
        </div>
      }

      {(selected && selected.mode == "view")  &&
        <div style={{
          position: "fixed",
          backgroundColor: "rgba(0,0,0,.9)",
          left: "0px",
          top: "0px",
          right: "0px",
          bottom: "0px",
          padding: "25px",
          zIndex: 9999,
        }}>
          <iframe 
            src={selected.resource.url || (storage.root + selected.resource.file_url)} 
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
            }}
          />
          <button 
            style={{
              border: "0px",
              backgroundColor: "transparent",
              fontSize: "30px",
              position: "absolute",
              right: "0px",
              top: "0px",
              color: "#ccc",
              zIndex: 99999
            }}
            onClick={() => setSelected(null)}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        </div>
      }
      {(selected && selected.mode == "edit") && 
        <ResourceDrawer
            type={type}
            typeId={type_id}
            resource={selected.resource}
            onSaved={(resourceData) => {
              console.log("onEdited", resourceData);

              const resourcesData = JSON.parse(JSON.stringify(resources));
              const index = resourcesData.findIndex(r => r.id == selected.resource.id);

              if (index != -1) {
                resourcesData[index] = resourceData;
  
                onChange({
                    resources: resourcesData
                })
              }

              setSelected(null);
          }}
            onHide={ () => setSelected(null) } 
        />
      }   
      {(selected && selected.mode == "versions") && 
        <ResourceVersionsDrawer
            type={type}
            typeId={type_id}
            resource={selected.resource}
            onHide={ () => setSelected(null) } 
        />
      }                 
      {add && 
        <ResourceDrawer
            type={type}
            typeId={type_id}
            onSaved={(resourceData) => {
              console.log("onSaved", resourceData);

              const resourcesData = JSON.parse(JSON.stringify(resources));

              resourcesData.push(resourceData);

              console.log("resources", resourcesData);

              onChange({
                  resources: resourcesData
              })
              setAdd(false);
            }}
            onHide={ () => setAdd(false) } 
        />
      }
      {select && 
        <ResourcesDrawer
            title="Add Resources"
            label="Add"
            type={type}
            type_id={type_id}
            existingResources={resources}
            onAdded={(resourceData) => {
              console.log("onSaved", resourceData);

              const resourcesData = JSON.parse(JSON.stringify(resources));

              resourcesData.push(resourceData);

              console.log("resources", resourcesData);

              onChange({
                  resources: resourcesData
              })
              //setSelect(false);
            }}
            onHide={ () => setSelect(false) } 
        />
      }
    </>
  );
}

export default Panel;