import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import Content from "../components/content"
import PageHeader from "../components/page-header";
import EmployeeField from "../components/fields/field-employee"

import CalendarPrefsButton from "../components/calendar/calendar-prefsbutton"
import CalendarDay from "../components/calendar/calendar-day"
import CalendarWeek from "../components/calendar/calendar-week"
import CalendarMonth from "../components/calendar/calendar-month"

import { postData } from "../common/services/server"
import { getUser } from "../common/services/auth";
import PageLayout from "../components/page-layout";

function CalendarPage(props) {
  const user = getUser();
  const history = useHistory();
  let { tab } = useParams();

  if (!tab) tab = "week";

  const [prefs, setPrefs] = useState({})
  const [userId, setUserId] = useState(user.id);

  return (
    <PageLayout>
      <PageHeader 
          title="Calendar"
          tab={tab}
          tabUrl={"/calendar/{tab}"}
          tabs={[
              {name:"day", title:"Day"},
              {name:"week", title:"Week"},
              {name:"month", title:"Month"},
          ]}
          onTab={(tab) => {
              history.push("/calendar/" + tab);
              //setTab(tab)
          }}
      >
        <EmployeeField
            name="user"
            value={userId}
            onChange={(e, user) => {
                console.log(user)
                setUserId(user.id);
            }}
        /> 
        <CalendarPrefsButton
          onPrefsChanged={(newPrefs) => setPrefs(newPrefs)}
        />       
      </PageHeader>
      <Content>
          {tab == "day" && 
            <CalendarDay size="large" calendarPrefs={prefs} userId={userId} />
          }
          {tab == "week" && 
            <CalendarWeek size="large" calendarPrefs={prefs} userId={userId} />
          }
          {tab == "month" && 
            <CalendarMonth size="large" calendarPrefs={prefs} userId={userId} />
          }
      </Content>
    </PageLayout>
  );
}

export default CalendarPage;