import React from "react";

import Loading from "../components/loading"
import Error from "../components/error";

export default function Panel({
    query,
    checkReloading,
    className,
    children
}) {
    return (
        <div className={className}>
            {(checkReloading && (query.isFetching || query.isLoading)) ?
                <Loading />
            : query.isInitialLoading ? 
                <Loading />
            : query.error ?
                <div className="m-2">
                    <Error error={query.error?.message ?? "Error loading data..."} />
                </div>
            : !query.data ?
                <div className="text-gray-400 p-2">
                    No Data Found
                </div>
            :
                children
            }
        </div>
    )
}
