import React from "react";
import styled from "styled-components"
import QRCode from "qrcode.react";

import StickerBox from "./mwo-stickerbox"
import ShapePreview from "./shape-preview";
import storage from "../settings/storage";

function getContentSize(content) {
    if (content && content.length > 0 && content.indexOf("strong") == -1) {
        return "size" + (Math.ceil(content.length/5)*5);
    }
    return ""
}

function MwoSticker({job, mwo, shape, type, style}) {
    const rows = [];

    for (let r=1; r<=(mwo["rows"] || 1); r++) {
        let cols = [];

        for (let c=1; c<=(mwo["row"+r+"cols"] || 1); c++) {
            let content = mwo["row"+r+"col"+c];

            if (content && (content + "").includes("[file:")) {
                content = `<img src="${storage.root + content.split(":")[1].replace("]", "")}" height="40" style="height: 40px;" />`;
            }

            cols.push(
                <div key={c} className={"column column" + c + " " + getContentSize(content)}>
                    <span dangerouslySetInnerHTML={{__html: content}} />
                </div>
            )
        }
        rows.push(
            <div key={r} className={"line line"+r + " columns" + cols.length}>
                {cols}
            </div>
        );
    }

    const singleLine = mwo["rows"] == 1 && mwo["row1cols"] == 1;

    return (
        <StickerBox 
            type={type} 
            className={"lines" + rows.length + (singleLine ? " singleline":"")} 
            style={style}
        >
            {(shape) && 
                <div className="shape">
                    <div>{shape.shape_index} of {shape.shape_total}</div>
                    {shape.resembles_ind == 1 && 
                        <div>* similar *</div>
                    }
                    <ShapePreview path={shape.path} solid={true} width={40} height={40} />
                </div>
            }
            <div className="info">
                {rows}
            </div>
            <div className="job">
                <div><small>{mwo.short_title || mwo.title}</small></div>
                <div>{mwo.id}</div>
                <QRCode 
                    value={mwo.qrcode || ("Job:" + (job ? job.id:0) + ":MWO:" + mwo.name)} 
                    size={50} 
                    imageSettings={{width:50,height:50}} 
                    renderAs="svg" 
                />
            </div>
        </StickerBox>
    )
}

export default MwoSticker;