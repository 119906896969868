import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { produce } from "immer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBatteryHalf, faCodeBranch, faLightbulb, faListAlt, faPencilAlt, faPlug, faSpinner, faTags, faThumbsDown, faThumbsUp, faTrash, faWaveSquare } from "@fortawesome/free-solid-svg-icons";

import Loading from "../components/loading"
import Button from "../components/buttons/button";
import DrawerPartSelect from "../drawers/part-select";
import DrawerTemplateBoardForm from "../drawers/template-boardform";
import TabList from "../components/tabs";

import DropdownMenu from "../components/dropdownmenu";
import ProgressBar from "../components/progressbar";
import GroupPreview from "../components/group-preview";
import ShapePreview from "../components/shape-preview";
import FieldSelect from "../components/fields/field-select";
import FieldNumber from "../components/fields/field-number";
import FieldLabel from "../components/fields/field-label";
import ButtonPost from "../components/buttons/button-post";
import { Accordion, AccordionItem } from "../components/accordion";
import { List, ListItem } from "../components/list"
import { postData } from "../common/services/server";
import { deepClone, formatDate } from "../helpers/utility";
import DrawerTemplateDataSelect from "../drawers/template-dataselect";
import Field from "../components/fields/field";
import ZoomableImage from "../components/image-zoomable";
import storage from "../settings/storage";
import UploadButton from "../components/buttons/button-upload";
import FieldText from "../components/fields/field-text";
import { useGetJobPower, useGetJobPowerset } from "../api/queries/workflowjobs";
import Panel from "./panel";
import { useWorkflowJobAddPower, useWorkflowJobAddPowerset, useWorkflowJobRemovePower, useWorkflowJobRemovePowerset, useWorkflowJobUpdateDimensionProp, useWorkflowJobUpdatePowerProp, useWorkflowJobUpdatePowersetProp, useWorkflowJobUpdateShapeProp } from "../api/mutations/workflowjobs";

function PowerColor({power, className}) {
    return (
        <div className={clsx("flex w-10 border rounded", className)}>
        {power.color.split("/").map(color => (
            <div key={color} style={{
                flex: 1,
                backgroundColor: color.toLowerCase()
            }}>
                &nbsp;
            </div>
        ))}
        </div>
    )
}

function ShapeData({
    jobId,
    shape,
    shapesCount,
    showPowerset=false,
    showPower=false,
    showPowerExtension=false,
    showLetterExtension=false,
    showBridge=false,
    showOrder=false,
    powersets,
    onChange
}) {
    const [busy, setBusy] = useState([]);
    const [powerset, setPowerset] = useState(null);
    const [power, setPower] = useState(null);
    const [order, setOrder] = useState(1);

    useEffect(
        () => {
            if (shape && powersets) {
                const ps = powersets.find(ps => ps.id == shape.powerset_id);
                const p = ps && shape.power_id && ps.power.find(p => p.id == shape.power_id);

                setPowerset(ps);
                setPower(p);
                setOrder(shape.powerset_order);
            }
        }, 
        [shape]
    );

    const updateShapeProp = useWorkflowJobUpdateShapeProp(jobId);

    return (
        <div className="flex flex-col gap-1 self-start">
            <div className="flex justify-center">
                <ShapePreview path={shape.path} solid={true} width={25} height={15} />
                {updateShapeProp.isLoading &&
                    <FontAwesomeIcon icon={faSpinner} spin color="#eee" />
                }
            </div>
            <div className="grid gap-1 text-xs">
                {showPowerExtension && 
                    <FieldSelect
                        prepend="Power:"
                        value={shape.extension_power_size}
                        items={[
                            {id:2, label:"2 ft Ext"},
                            {id:4, label:"4 ft Ext"},
                            {id:6, label:"6 ft Ext"},
                            {id:8, label:"8 ft Ext"},
                            {id:10, label:"10 ft Ext"},
                            {id:12, label:"12 ft Ext"},                                                            
                        ]}
                        className="min-w-[100px]"
                        size="xs"
                        onChange={(e,value) => updateShapeProp.mutate({jobShapeId: shape.jobshape_id, prop: "extension_power_size", value})}
                        disabled={updateShapeProp.isLoading && updateShapeProp.variables?.prop == "extension_power_size"}
                    />
                }
                {showLetterExtension && 
                    <FieldSelect
                        prepend="Letter:"
                        value={shape.extension_letter_size}
                        items={[
                            {id:2, label:"2 ft Ext"},
                            {id:4, label:"4 ft Ext"},
                            {id:6, label:"6 ft Ext"},
                            {id:8, label:"8 ft Ext"},
                            {id:10, label:"10 ft Ext"},
                            {id:12, label:"12 ft Ext"},                                                            
                        ]}
                        className="min-w-[100px]"
                        size="xs"
                        onChange={(e,value) => updateShapeProp.mutate({jobShapeId: shape.jobshape_id, prop: "extension_letter_size", value})}
                        disabled={updateShapeProp.isLoading && updateShapeProp.variables?.prop == "extension_letter_size"}
                    />       
                }                                             

                {showBridge && 
                    <FieldSelect
                        value={shape.bridge_ind}
                        items={[
                            {id:0, label:"No Bridge"},
                            {id:1, label:"Bridge"},
                        ]}
                        size="xs"
                        valid={shape.bridge_ind == 1}
                        onChange={(e,value) => updateShapeProp.mutate({jobShapeId: shape.jobshape_id, prop: "bridge_ind", value})}
                        disabled={updateShapeProp.isLoading && updateShapeProp.variables?.prop == "bridge_ind"}

                    />
                }     

                {showPowerset && 
                    <FieldSelect
                        value={shape.powerset_id}
                        required
                        invalid={!shape.powerset_id}
                        labelField="name"
                        items={powersets}
                        size="xs"
                        onChange={(e,value) => updateShapeProp.mutate({jobShapeId: shape.jobshape_id, prop: "powerset_id", value})}
                        disabled={updateShapeProp.isLoading && updateShapeProp.variables?.prop == "powerset_id"}
                    /> 
                }

                {(showPower && shape.powerset_id) && 
                    <FieldSelect
                        name="power_id"
                        value={shape.power_id}
                        required
                        invalid={!shape.power_id}
                        labelField="color"
                        items={powerset?.power || []}
                        prepend={
                            power ?
                                <PowerColor power={power} className="!w-6 h-full" />
                            :
                                null 
                        }
                        prependClassName="!p-0 w-6"
                        size="xs"
                        disabled={updateShapeProp.isLoading && updateShapeProp.variables?.prop == "power_id"}
                        onChange={(e,value) => updateShapeProp.mutate({jobShapeId: shape.jobshape_id, prop: "power_id", value})}
                    />                 
                }       

                {showOrder && 
                    <FieldSelect
                        name="powerset_order"
                        prepend="Order"
                        size="sm"
                        required
                        invalid={!shape.powerset_order}
                        items={new Array(shapesCount).fill().map((e,i) => {
                            return {
                                id: i+1,
                                label: i+1
                            }
                        })}
                        value={shape.powerset_order}
                        disabled={updateShapeProp.isLoading && updateShapeProp.variables?.prop == "powerset_order"}
                        onChange={(e,value) => updateShapeProp.mutate({jobShapeId: shape.jobshape_id, prop: "powerset_order", value})}
                    />                
                }                
            </div>
        </div>
    )
}

function Power({
    jobId,
    powerset,
    power,
}) {
    const [edit, setEdit] = useState(false);

    const updatePowerSupply = useWorkflowJobUpdatePowerProp(jobId, power.id, "power_supply_id");
    const updatePowerColor = useWorkflowJobUpdatePowerProp(jobId, power.id, "color");
    const removePower = useWorkflowJobRemovePower(jobId);

    let usedPercent = (parseFloat(power.watts_used)/parseFloat(power.watts_total))*100;

    if (usedPercent > 100)
        usedPercent = 100;

    return (
        <>
            <div key={power.id} className={clsx(
                "border border-gray-300 flex flex-col gap-1 min-w-[150px] rounded p-1 text-sm",
                (!power.watts_used || power.watts_used == 0) && "!border-red-500"
            )}>
                {removePower.isLoading ?
                    <div className="flex items-center justify-center p-1 h-full">
                        <FontAwesomeIcon icon={faSpinner} spin={true} />
                    </div>
                :
                    <>
                        <div className="flex items-center justify-between gap-2">
                            <div className="flex items-center gap-1">
                                <PowerColor power={power} />
                                {power.model}
                            </div>

                            <DropdownMenu
                                icon={faBars}
                                menu={[
                                    {name: "edit", caption: "Edit Power", icon:faPencilAlt},
                                    {name: "remove", caption: "Remove Power", icon:faTrash, confirmation:"Remove this power from the powerset?"},
                                ]}
                                onMenuItem={(item) => {
                                    if (item.name == "edit")
                                        setEdit(true)
                                    else if (item.name == "remove") {
                                        removePower.mutate(power.id)
                                    }
                                }}
                            />
                        </div>
                        <div>
                            <ProgressBar
                                className="!h-4"
                                progressClassName={parseFloat(power.watts_used) > parseFloat(power.watts_max) ? "!bg-red-500" : "!bg-green-500"
                                }
                                progress={usedPercent || 0}
                            />
                            <div className="text-center text-sm text-gray-400">
                                {power.watts_used || "0"} Watts
                            </div>
                        </div>
                    </>
                }
            </div>
            {edit && 
                <DrawerTemplateBoardForm
                    icon={faBatteryHalf}
                    title="Edit Power"
                    data={power}
                    fields={[
                        {
                            type:"select", 
                            label:"Power Supply", 
                            valueProp: "power_supply_id", 
                            saveProp: "power_supply_id",
                            mutation: updatePowerSupply,
                            required: true,
                            selectProps: {
                                url: "jobs/powersupplies",
                                labelField: "model",
                            }
                        }, 
                        {
                            type:"select", 
                            label:"Color", 
                            valueProp: "color", 
                            saveProp: "color",
                            mutation: updatePowerColor,
                            required: true,
                            selectProps: {
                                url: "jobs/powersupplycolors",
                                idField: "color",
                                labelField: "color",
                            },
                        }, 
                    ]}
                    onHide={() => setEdit(false)}
                />                   
            }
        </>
    )
}

function Powerset({
    jobId,
    powerset,
    powersets,
}) {
    const [edit, setEdit] = useState(false);
    const [changePart, setChangePart] = useState(false);

    const addPower = useWorkflowJobAddPower(jobId);
    const removePowerset = useWorkflowJobRemovePowerset(jobId, {
        onError: () => window.alert("Error removing powerset")
    });

    const updatePowersetPart = useWorkflowJobUpdatePowersetProp(jobId, powerset.id, "part_id");
    const updatePowersetType = useWorkflowJobUpdatePowersetProp(jobId, powerset.id, "type");
    const updatePowersetParent = useWorkflowJobUpdatePowersetProp(jobId, powerset.id, "parent_id");
    const updatePowersetExtSize = useWorkflowJobUpdatePowersetProp(jobId, powerset.id, "extension_size");
    const updatePowersetHeight = useWorkflowJobUpdatePowersetProp(jobId, powerset.id, "height");
    const updatePowersetLength = useWorkflowJobUpdatePowersetProp(jobId, powerset.id, "length");
    const updatePowersetPowerSide = useWorkflowJobUpdatePowersetProp(jobId, powerset.id, "power_side");

    const isRaceway = powerset.part.toLowerCase().indexOf("raceway") != -1;

    const haveShapesWithoutPower = powerset.shapes.filter(s => !s.power_id).length > 0;

    return (
        <>
            <AccordionItem 
                size="sm" 
                disabled={removePowerset.isLoading}
                menu={[
                    {name: "edit", caption: "Edit Powerset", icon:faPencilAlt},
                    {name: "add", caption: "Add Power Supply", icon:faBatteryHalf},
                    {name: "remove", caption: "Remove Powerset", icon:faTrash, confirmation:"Remove Powerset from Job?", disabled: powersets.length == 1},
                ]}
                onMenuItem={(item) => {
                    if (item.name == "edit")
                        setEdit(true);
                    else if (item.name == "add") {
                        addPower.mutate(powerset.id);
                    }
                    else if (item.name == "remove") {
                        removePowerset.mutate(powerset.id);                        
                    }                    
                }}     
                title={
                    <div className="grid grid-cols-[175px,1fr] gap-2 items-stretch">
                        <div className="bg-purple-100 grid gap-2 rounded p-3">
                            {powerset.name}
                            <div className="bg-white rounded p-1 flex items-center gap-1 text-xs">
                                <FontAwesomeIcon icon={faPlug} />
                                <div className="flex-1">
                                    {powerset.part}
                                </div>

                                <Button
                                    icon={faPencilAlt}
                                    size="icon"
                                    variant="none-light"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setChangePart(true);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-[150px,1fr] items-stretch gap-1 p-1">
                            {removePowerset.isLoading ? 
                                <div className="flex items-center p-1">
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                </div>
                            :
                                <>
                                <div className="text-xs flex flex-col gap-1">
                                    {powerset.height && 
                                        <div>
                                            {powerset.height}" x {powerset.length}"
                                        </div>
                                    }
                                    {powerset.color_id && 
                                        <div className="flex items-center gap-2">
                                            <div style={{backgroundColor: "#"+ powerset.color_hex}}>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </div>
                                            {powerset.color_name}
                                        </div>
                                    }
                                    {powerset.type == "Attached" ?
                                        <div>
                                            Attached to {powerset.parent || "?"}
                                        </div>
                                    : powerset.type == "Separated" ?
                                        <div>
                                            Separated from {powerset.parent || "?"}
                                        </div>
                                    :
                                        <div>
                                            {powerset.type}
                                        </div>
                                    }
                                    {(powerset.extension_size && powerset.extension_size != 0) && 
                                        <div>
                                            {powerset.extension_size} ft extension
                                        </div>
                                    }
                                    {powerset.power_side && 
                                        <div>
                                            Power on the {powerset.power_side}
                                        </div>
                                    }
                                </div>
                                <div className="flex items-stretch gap-1">
                                    {powerset.power.map(power => (
                                        <Power 
                                            key={power.id}
                                            jobId={jobId}
                                            powerset={powerset}
                                            power={power}
                                        />
                                    ))}

                                    {addPower.isLoading && 
                                        <div className="border border-gray-300 p-1 flex justify-center items-center">
                                            <FontAwesomeIcon icon={faSpinner} spin />
                                        </div>
                                    }
                                </div>
                                </>
                            }
                        </div>
                    </div>
                }                       
            >
                {(powerset.shapes.length > 0) ?
                    <List direction="horizontal" size="sm" className="flex-wrap">
                        {powerset.shapes.map(shape => (
                            <ListItem key={shape.jobshape_id} size="sm" className="flex flex-col gap-1 max-w-[125px]">
                                <ShapeData
                                    jobId={jobId}
                                    powersets={powersets}
                                    shape={shape}
                                    shapesCount={Math.max(powerset.shapes.length, Math.max(...powerset.shapes.map(s => s.powerset_order)))}
                                    showOrder={true}
                                    showPowerset={true}
                                    showPower={powerset.power.length > 1 || haveShapesWithoutPower}
                                />
                            </ListItem>
                        ))}
                    </List>
                :
                    <div className="p-2 text-gray-400">
                        No Shapes Found
                    </div>
                }
            </AccordionItem>

            {edit && 
                <DrawerTemplateBoardForm
                    icon={faPencilAlt}
                    title="Edit Powerset"
                    data={powerset}
                    fields={[
                        {type:"label", label:"Name", valueProp: "name"},
                        {
                            type:"select", 
                            label:"Type", 
                            valueProp: "type", 
                            mutation: updatePowersetType,
                            required: true,
                            selectProps: {
                                items: [
                                    {value:"Master", label:"Master"},
                                    {value:"Attached", label:"Attached"},
                                    {value:"Separated", label:"Separated"},
                                ],
                            },
                            hiddenCheck: () => !isRaceway
                        },
                        {
                            type:"select",
                            label:"Parent",
                            required: powerset.type != "Master",
                            valueProp: "parent_id",
                            mutation: updatePowersetParent,
                            items: powersets.filter(ps => ps.id != powerset.id),
                            hiddenCheck: () => !isRaceway || powerset.type == "Master"
                        },
                        {
                            type:"select",
                            label:"Extension",
                            valueProp:"extension_size",
                            mutation: updatePowersetExtSize,
                            selectProps: {
                                items: [
                                    {value:"0", label:"0 ft"},
                                    {value:"4", label:"4 ft"},
                                    {value:"6", label:"6 ft"},
                                    {value:"8", label:"8 ft"},
                                    {value:"10", label:"10 ft"},
                                    {value:"12", label:"12 ft"},
                                ],
                            },
                            hiddenCheck: () => !isRaceway
                        },
                        {
                            type:"columns",
                            hiddenCheck: () => !isRaceway,
                            columns: [
                                {type:"number", label:"Height", valueProp:"height", mutation: updatePowersetHeight, required: true, suffix: "Inches"} ,   
                                {type:"number", label:"Length", valueProp:"length", mutation: updatePowersetLength, required: true, suffix: "Inches"},  
                            ]
                        },  
                        {
                            type:"color",
                            label:"Color",
                            valueProp:"color_id",
                            // saveProp: "color_id",
                            colorProps: {
                                colorName: powerset.color_name,
                                colorHex: powerset.color_hex,                            
                            },
                            required: true,
                            hiddenCheck: () => !isRaceway,
                        },         
                        {
                            type:"select",
                            label:"Power Side",
                            valueProp:"power_side",
                            mutation: updatePowersetPowerSide,
                            selectProps: {
                                items: [
                                    {value:"Left", label:"Left"},
                                    {value:"Right", label:"Right"},
                                    {value:"Top", label:"Top"},
                                    {value:"Bottom", label:"Bottom"},
                                    {value:"Other", label:"Other"},                                    
                                ],
                            },
                            hiddenCheck: () => !isRaceway
                        },                      
                    ]}
                    onHide={() => setEdit(false)}
                />             
            }
            {changePart && 
                <DrawerPartSelect
                    filters={{
                        type: "power",
                        exists_jobpowerset_id: powerset.id
                    }}
                    selectMutation={updatePowersetPart}
                    onMutate={(item) => {
                        return item.id;
                        // return {
                        //     prop: "part_id",
                        //     value: item.id
                        // }
                    }}
                    onMutateSuccess={() => setChangePart(false)}
                    onHide={() => setChangePart(false)}
                />             
            }            
        </>
    )        
}

function GroupsPanel({
    groups,
    direction="horizontal",
    onGroupCount,
    onRenderShape,
}) {
    return (
        <Accordion>
            {groups.map(group => (
                <AccordionItem 
                    key={group.id}
                    count={onGroupCount ? onGroupCount(group) : null}
                    countVariant="positive-success"
                    title={
                        <div className="grid grid-cols-[125px,200px,1fr] gap-1 items-center">
                            <GroupPreview group={group} solid={true} width={100} height={50} />
                            <div className="flex items-stretch gap-1 text-xs">
                                <List>
                                    <ListItem size="sm">
                                        Size {group.size}
                                    </ListItem>
                                    <ListItem size="sm">
                                        {group.product}
                                    </ListItem>
                                    <ListItem size="sm">
                                        <div>
                                        {group.shapes.length ? group.shapes[0].style : group.style}
                                        {group.resembles_ind == 1 && 
                                            <span className="text-xs text-red-500 ml-1">
                                                * Similar
                                            </span>
                                        }
                                        </div>
                                    </ListItem>
                                </List>
                            </div>
                        </div>                                    
                    }
                >
                    <List direction={direction} className="items-stretch flex-wrap gap-1">
                        {group.shapes.map(shape => (
                            <ListItem key={shape.jobshape_id} size="sm">
                                {onRenderShape(shape, group)}
                            </ListItem>
                        ))}
                    </List>
                </AccordionItem>
            ))}
        </Accordion>
    )    
}

function GroupExtensionsPanel({
    jobId,
    groups,
    powersets,
    onChange
}) {
    return (
        <GroupsPanel
            groups={groups}
            onRenderShape={(shape) => (
                <ShapeData
                    jobId={jobId}
                    powersets={powersets}
                    shape={shape}
                    showPowerExtension={true}
                    showLetterExtension={true}
                    onChange={onChange}
                />
            )}
        />
    )    
}

function ShapeLEDsPanel({
    jobId,
    group,
    shape,
    powersets,
    dimensions,
    leds,    
    onChange
}) {
    const [busy, setBusy] = useState([]);
    const [parts, setParts] = useState([]);
    const [powerset, setPowerset] = useState(null);
    const [power, setPower] = useState(null);
    const [selectedPart, setSelectedPart] = useState(null);

    useEffect(
        () => {
            console.log("shapeledspanel group changed")
            let groupParts = deepClone(group.parts);

            for (let groupPart of groupParts) {
                groupPart.dimension = dimensions.find(d => d.jobshape_id == shape.jobshape_id && d.jobpart_id == groupPart.jobpart_id) || {};
            }

            setParts(groupParts);
        }, 
        []
    );

    useEffect(
        () => {
            if (shape && shape.powerset_id)
                setPowerset(powersets.find(p => p.id == shape.powerset_id));
            if (shape && shape.power_id)
                setPower(powersets.find(p => p.id == shape.powerset_id).power.find(p => p.id == shape.power_id));
        }, 
        [shape]
    );

    const updatePartDimensionProp = useWorkflowJobUpdateDimensionProp(jobId);

    // function updatePartDimensionProp(part, dimension, prop, value) {
    //     setBusy(produce(draft => {
    //         draft.push(part.id + prop);
    //     }));

    //     postData("workflows/jobdata/updatejobdimensionprop", { 
    //         job_id: jobId,
    //         jobdimension_id: dimension.id,
    //         prop,
    //         value
    //     },
    //         function() {
    //             onChange();
    //         },
    //         function(error) {
    //             alert("Error updating job dimension")
    //         },
    //         function() {
    //             setBusy(produce(draft => {
    //                 let idx = draft.indexOf(part.id + prop);

    //                 if (idx != -1){
    //                     draft.splice(idx,1);
    //                 }
    //             }))
    //         }
    //     ); 
    // }

    const stickers = Array(1,2,3,4,5,6);

    return (
        <>
            <div className="flex gap-3">            
                <div className="bg-purple-100 rounded p-3 min-w-[125px] text-xs whitespace-nowrap">
                    <div>{powerset ? powerset.name : "?"}</div>
                    {power && 
                        <div>
                            <PowerColor power={power} className="!w-full" />
                        </div>
                    }
                </div>

                {parts.map(part => {
                    const isBusy = (busy.indexOf(part.id + "num_leds") != -1 || busy.indexOf(part.id + "led_id") != -1);

                    return (
                        <div key={part.id} className="flex flex-col gap-1">
                            <div className="flex items-center gap-1 p-1 font-bold">
                                <FontAwesomeIcon icon={isBusy ? faSpinner:faLightbulb} spin={isBusy} size="sm" className="text-gray-300" />
                                {part.title}
                            </div>

                            <div className="grid grid-cols-[100px,100px,90px] items-center gap-1">
                                <FieldNumber
                                    append="LEDs"
                                    appendClassName={part.dimension?.dimension_temp_ind == 1 ? "text-red-500":""}
                                    value={part.dimension?.num_leds || ""}
                                    size="sm"
                                    disabled={busy.indexOf(part.id + "num_leds") != -1}
                                    onChange={(e,value) => {
                                        setParts(produce(draft => {
                                            let p = draft[draft.findIndex(p => p.id == part.id)];
                                            let led = leds.find(l => l.id == p.dimension.led_id);

                                            console.log(value, led, p.dimension)
                                            p.dimension.num_leds = value;
                                            p.dimension.watts = (value * (led?.watts || 0)).toFixed(2);
                                        }))
                                    }}
                                    onBlur={() => {
                                        updatePartDimensionProp.mutate({
                                            jobGroupId: part.jobgroup_id, 
                                            jobDimensionId: part.dimension.id, 
                                            prop: "num_leds", 
                                            value: part.dimension.num_leds
                                        })
                                    }}
                                />
                                <FieldSelect
                                    value={part.dimension?.led_id || ""}
                                    items={leds}
                                    labelField="name"
                                    disabled={busy.indexOf(part.id + "led_id") != -1}
                                    onChange={(e,value) => {
                                        setParts(produce(draft => {
                                            let p = draft[draft.findIndex(p => p.id == part.id)];
                                            let led = leds.find(l => l.id == value);

                                            p.dimension.led_id = value;
                                            p.dimension.watts =  (p.dimension.num_leds * (led?.watts || 0)).toFixed(2);
                                        }));

                                        updatePartDimensionProp.mutate({
                                            jobGroupId: part.jobgroup_id, 
                                            jobDimensionId: part.dimension.id, 
                                            prop: "led_id", 
                                            value
                                        })
                                    }}
                                />
                                <FieldLabel className="text-xs text-gray-400">
                                    {part.dimension.watts} watts
                                </FieldLabel>
                            </div>

                            {part.dimension && stickers.map((sticker, index) => (
                                <React.Fragment key={index}>
                                {((sticker == 1 || (part["prewire_sticker" + sticker] && part["prewire_sticker" + sticker].length))) &&
                                    <FieldLabel
                                        prepend={"Prewire " + sticker}
                                        prependClassName={part.dimension.dimension_temp_ind == 1 ? "text-red-500":""}
                                        value={part.dimension["prewire_sticker" + sticker]}
                                        size="sm"
                                        className="text-xs"
                                        append={
                                            <div className="flex items-center gap-1">
                                                {(sticker == 1 && part.dimension.dimension_id) ?
                                                    <ButtonPost
                                                        icon={part.dimension.confirmed_date ? faThumbsUp:faThumbsDown}
                                                        postUrl="workflows/jobdata/confirmdimension"
                                                        postData={{
                                                            job_id: jobId,
                                                            jobdimension_id: part.dimension.id,
                                                            dimension_id: part.dimension.dimension_id,
                                                            size: group.size,
                                                            num_leds: part.dimension.num_leds,
                                                        }}
                                                        onPostResult={onChange}
                                                        confirmation="Confirm this shape/size prewire sticker?"
                                                        variant={part.dimension.confirmed_date ? "solid-success":"solid-warning"}
                                                        size="tiny"
                                                        tooltip={part.dimension.confirmed_date ? `Confirmed by ${part.dimension.confirmed_user} on ${formatDate(part.dimension.confirmed_date)}` : null}
                                                        tooltipPlacement="top"
                                                        disabled={part.dimension.confirmed_date}
                                                    />
                                                :
                                                    null
                                                }

                                                <Button 
                                                    icon={faListAlt}
                                                    variant="outline-secondary" 
                                                    size="tiny" 
                                                    tooltip="View/Change Stickers"
                                                    tooltipPlacement="top"
                                                    onClick={() => {
                                                        setSelectedPart(part)
                                                    }}
                                                />
                                            </div>
                                        }
                                    />
                                }
                                </React.Fragment>
                            ))}

                            {(part.dimension?.dimension_temp_ind == 1) && 
                                <div className="text-xs text-red-500" style={{fontSize: "10px"}}>
                                    * Estimated
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
            {selectedPart && 
                <DrawerTemplateDataSelect
                    icon={faLightbulb}
                    title={"Shape Prewire Data"}
                    dataUrl="shapes/dimensions"
                    dataParams={{
                        shape_id: shape.id,
                        style_id: shape.style_id
                    }}
                    itemRender={(item) => (
                        <div className="grid grid-cols-[90px,1fr,70px] !items-stretch gap-1 text-sm border rounded border-gray-300 w-full">
                            <Field prepend="Size" className="!items-center h-full">{item.size}</Field>
                            <div className="grid gap-1 p-1">
                                {[...Array(6)].map((e, index) => (
                                    item["prewire_sticker" + (index+1)] && item["prewire_sticker" + (index+1)].length > 0 ?
                                        <div className="bg-gray-100 text-xs px-1 rounded">
                                            {item["prewire_sticker" + (index+1)]}
                                        </div>
                                    :
                                        null                                
                                ))}

                                {item.confirmed_user_id && 
                                    <div className="bg-green-100 rounded p-1 text-xs">
                                        Confirmed by {item.confirmed_user_name} on {formatDate(item.confirmed_date)}
                                    </div>
                                }
                            </div>
                            <div>
                                {item.url ? 
                                    <ZoomableImage
                                        url={storage.root + item.preview_url}
                                        zoomUrl={storage.root + item.url}
                                        maxHeight="50px"
                                        maxWidth="70px"
                                    />
                                : item.confirmed_user_id ?
                                    <UploadButton
                                        uploadUrl={"shapes/uploaddimension?dimension_id=" + item.id}
                                        label="Photo"
                                        size="sm"
                                        thumbnail={true}
                                        multiple={false}
                                        extensions={["jpg"]}
                                        onUpload={(file) => {
                                            // temporary
                                            setSelectedPart(null)

                                            // setDimensions(produce(draft => {
                                            // let idx = draft.findIndex(d => d.id == dimension.id);

                                            // if (idx != -1) {
                                            //     draft[idx].confirmed_file_id = file.id;
                                            //     draft[idx].url = file.url;
                                            //     draft[idx].preview_url = file.url;
                                            // }
                                            // }));
                                        }}
                                    /> 
                                :
                                    null
                                }
                            </div>
                        </div>                
                    )}
                    addItemUrl="workflows/additem?workflowitem_id="
                    addItemParams={{
                        jobdimension_id: selectedPart.dimension.id
                    }}
                    onItemAdded={onChange}          
                    selectCaption="Use Prewire"
                    header={
                        <div className="mx-1 mb-2">
                            <Field prepend="Shape" prependClassName="w-[100px]" className="!items-center text-xs">
                                {shape.code}</Field>
                            <Field prepend="Style" prependClassName="w-[100px]" className="!items-center text-xs">
                                {shape.style}</Field>
                            <Field prepend="Size" prependClassName="w-[100px]" className="!items-center text-xs">
                                {group.size}</Field>
                        </div>
                    }
                    onHide={() => setSelectedPart(null)}
                />            
            }
        </>
    )
}

function GroupLEDsPanel({
    jobId,
    groups,
    powersets,
    dimensions,
    leds,
    onChange
}) {
    return (
        <GroupsPanel
            groups={groups}
            direction="vertical"
            onRenderShape={(shape, group) => (
                <div className="flex items-center gap-2 text-sm">
                    <div className="p-3">
                        <ShapePreview path={shape.path} solid={true} width={25} height={15} />
                    </div>

                    <ShapeLEDsPanel
                        jobId={jobId}
                        group={group}
                        shape={shape}
                        powersets={powersets}
                        dimensions={dimensions}
                        leds={leds}
                        onChange={onChange}
                    />
                </div>
            )}
        />
    )    
}

function GroupUlPanel({
    jobId,
    groups,
    powersets,
    onChange
}) {
    const [loading, setLoading] = useState(false);
    const [ul, setUl] = useState([]);

    const isRacewayJob = powersets && powersets.length > 0 && powersets[0].name.toLowerCase().indexOf("raceway") != -1;

    return (
        <>
        { loading ? 
            <Loading />
        :
            <List>
                {powersets.map(powerset => (
                    <ListItem key={powerset.id}>
                        <div className="grid grid-cols-[190px,1fr] gap-2">
                            <div className="bg-purple-100 p-2 rounded">
                                {powerset.name}
                            </div>

                            <FieldText
                                prepend="UL:"
                                value={powerset.ul}
                            />
                        </div>
                    </ListItem>
                ))}
                {groups.map(group => (
                    <ListItem key={group.id}>
                        <div className="grid grid-cols-[120px,1fr] gap-2">
                            <div>
                                <GroupPreview group={group} solid={true} width={100} height={50} />
                                <List className="text-xs mt-2">
                                    <ListItem size="sm">
                                        Size {group.size}
                                    </ListItem>
                                    <ListItem size="sm">
                                        {group.product}
                                    </ListItem>
                                    <ListItem size="sm">
                                        <div>
                                        {group.shapes.length ? group.shapes[0].style : group.style}
                                        {group.resembles_ind == 1 && 
                                            <div className="text-xs text-red-500 ml-1">
                                                * Similar
                                            </div>
                                        }
                                        </div>
                                    </ListItem>
                                </List>
                            </div>

                            <List>
                                {group.shapes.map(shape => (
                                    <ListItem key={shape.jobshape_id} size="sm">
                                        <div className="grid grid-cols-[53px,1fr] items-center gap-1 pl-2">
                                            <ShapePreview path={shape.path} solid={true} width={45} height={20} />

                                            <FieldText
                                                prepend="UL:"
                                                value={shape.ul}
                                                disabled={isRacewayJob}
                                            />
                                        </div>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </ListItem>
                ))}
            </List>
        }
        </>
    )    
}

export default function JobWorkflowPowerPanel({jobId}) {
    const powerQuery = useGetJobPower(jobId);
    const powersets = powerQuery.data?.powersets;
    const groups = powerQuery.data?.groups;
    const dimensions = powerQuery.data?.dimensions;
    const leds = powerQuery.data?.leds;

    useEffect(
        () => {
            if (powersets) {
                setTabs([
                    {
                        name: "leds", 
                        title: "LEDs", 
                        icon: faLightbulb, 
                        disabled: powersets.length == 0, 
                        component: GroupLEDsPanel
                    },
                    {
                        name: "extensions", 
                        title: "Extensions", 
                        icon: faWaveSquare, 
                        disabled: powersets.length == 0,
                        component: GroupExtensionsPanel
                    },
                    {
                        name: "ul", 
                        title: "UL", 
                        icon: faTags, 
                        disabled: powersets.length == 0,
                        component: GroupUlPanel
                    },
                ]);
            }
        }, 
        [powersets]
    );

    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(null);

    useEffect(
        () => {
            if (selectedTab == null)
                setSelectedTab(tabs.filter(t => !t.disabled)[0])
        }, 
        [tabs]
    );

    let haveShapesWithoutPowerset = false;
    
    if (powersets?.length) {
        for (const group of groups.filter(g => g.lit_ind == 1)) {
            if (group.shapes.filter(s => !s.powerset_id).length > 0) {
                haveShapesWithoutPowerset = true;
                break;
            }
        }
    }

    return (
        <Panel query={powerQuery}>
            {powersets && 
              <>
                <div className="flex gap-2 mb-3">
                    {(powersets.length > 0) ?
                        <>
                            <Accordion>
                                {powersets.map(powerset => (
                                    <Powerset 
                                        key={powerset.id}
                                        jobId={jobId}
                                        powerset={powerset}
                                        powersets={powersets}
                                    />
                                ))}

                                {haveShapesWithoutPowerset && 
                                    <div className="border rounded p-1 mt-2">
                                        <div className="grid grid-cols-[175px,1fr] gap-2 items-center">
                                            <div className="bg-red-100 grid gap-2 rounded p-3">
                                                Unassigned Shapes
                                            </div>
                                            <div className="flex items-stretch gap-1">
                                                {groups.filter(g => g.lit_ind == 1).map(group => (
                                                    <React.Fragment key={group.id}>
                                                        {group.shapes.filter(s => !s.powerset_id).map(shape => (
                                                            <ShapeData
                                                                jobId={jobId}
                                                                powersets={powersets}
                                                                shape={shape}
                                                                shapesCount={group.shapes.length}
                                                                showOrder={false}
                                                                showPowerset={true}
                                                                showPower={false}
                                                            />
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                }                                
                            </Accordion>
                        </>
                    :
                        <div className="p-2 text-gray-300">
                            No Powersets Found 
                        </div>
                    }
                </div>

                <div className="grid grid-cols-[150px,1fr] gap-2 mt-1">
                    <TabList
                        direction="vertical"
                        tabs={tabs}
                        selectedTab={selectedTab}
                        className="bg-gray-50"
                        onTab={(tab) => {
                            setSelectedTab(tab);
                        }}
                    />
                    <div>
                        {selectedTab &&
                            <selectedTab.component
                                jobId={jobId}
                                groups={groups}
                                powersets={powersets}
                                dimensions={dimensions}
                                leds={leds}
                            />   
                        }
                    </div>
                </div>
              </>
            }
        </Panel>
    )
}
