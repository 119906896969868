import React, { useState, useEffect } from "react";
import { faBoxOpen, faCodeBranch, faLightbulb, faObjectGroup, faPalette, faPuzzlePiece, faScroll, faShapes, faThumbtack } from "@fortawesome/free-solid-svg-icons";

import TabList from "../components/tabs";

import JobWorkflowGroupsPanel from "./jobworkflow-groups";
import JobWorkflowShapesPanel from "./jobworkflow-shapes";
import JobWorkflowColorsPanel from "./jobworkflow-colors";
import JobWorkflowLEDsPanel from "./jobworkflow-leds";
import JobWorkflowMaterialsPanel from "./jobworkflow-materials";
import JobWorkflowPartsPanel from "./jobworkflow-parts";
import JobWorkflowBridgesPanel from "./jobworkflow-bridges";

export default function JobWorkflowShapesPrefabPanel({jobId}) {
    const [tabs, setTabs] = useState([
        {name: "groups", title: "Groups", icon: faObjectGroup, component: JobWorkflowGroupsPanel},
        {name: "shapes", title: "Shapes", icon: faShapes, component: JobWorkflowShapesPanel},
        {name: "colors", title: "Colors", icon: faPalette, component: JobWorkflowColorsPanel},
        {name: "leds", title: "LEDs", icon: faLightbulb, component: JobWorkflowLEDsPanel},
        {name: "materials", title: "Materials", icon: faScroll, component: JobWorkflowMaterialsPanel},
        {name: "parts", title: "Parts", icon: faPuzzlePiece, component: JobWorkflowPartsPanel},
        {name: "bridges", title: "Bridges", icon: faCodeBranch, component: JobWorkflowBridgesPanel},
    ]);
    const [selectedTab, setSelectedTab] = useState(null);

    useEffect(
        () => {
            setSelectedTab(tabs[0])
        }, 
        [tabs]
    );

    return (
        <div className="grid grid-cols-[150px,1fr] mt-1">
            <TabList 
                direction="vertical"
                tabs={tabs}
                selectedTab={selectedTab}
                className="bg-gray-50"
                onTab={(tab) => {
                    setSelectedTab(tab);
                }}
            />
            <div className="p-2">
                {selectedTab &&
                    <selectedTab.component
                        jobId={jobId}
                    />   
                }
            </div>
        </div>
    )
}