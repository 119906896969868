import React from "react"
import styled from 'styled-components'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Color = styled.div`
  display: flex;

  &.dual > div {
    width: 11px;
  }
  &.dual > div:first-child {
   margin-right: 0px;
   border-top-right-radius: 0px;
   border-bottom-right-radius: 0px;
  }
  &.dual > div:last-child {
    margin-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  } 
`
const Box = styled.div`
    border: 1px solid #333;
    width: ${props => props.size && props.size==="small" ? "16px" : props.size=="large" ? "30px" : "22px"};
    height: ${props => props.size && props.size==="small" ? "16px" : props.size=="large" ? "30px" : "22px"};
    margin: 3px;
    border-radius: 2px;
    text-align: center;
`

function ColorBox(props) {
    let color = props.color ;

    if (props.led && !color.hex_day) {
      if (color.name == "Blue" || color.name == "BLUE")
        color.hex_day = "#0000ff";
      else if (color.name == "Red" || color.name == "RED")
        color.hex_day = "#ff0000";
      else if (color.name == "White" || color.name == "WHT")
        color.hex_day = "#ffffff";
      else if (color.name == "Green" || color.name == "GRN")
        color.hex_day = "#00ff00";
    }

    const hexDay = color && color.hex_day ? color.hex_day.indexOf("#") === -1 ? "#"+color.hex_day : color.hex_day : "#ffffff";
    
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>
                    {color ? color.name : "None"}
                </Tooltip>
            }
        >
            <Color onClick={props.onClick} className={props.dual ? "color dual":"color"}>
            <Box
                size={props.size || ""} 
                style={{
                    backgroundColor: hexDay
                }}
            >
                {color && color.hex_day ? " " : "?"}
                {props.icon && 
                  <FontAwesomeIcon icon={props.icon} color="#efefef" />
                }
            </Box>
            { (props.dual && color) && 
                <Box 
                style={{
                    backgroundColor: "#"+color.hex_night
                }}
                >
                &nbsp;
                </Box>
            }
            </Color>
      </OverlayTrigger>
    );
}

export default ColorBox