import React, { useMemo, useState } from "react";
import { format, parseISO } from "date-fns";
import { faExclamationCircle, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import Panel from "./panel";
import ButtonMutate from "../components/buttons/button-mutate";
import FieldDate from "../components/fields/field-date";
import FieldSelect from "../components/fields/field-select";
import Alert from "../components/alert";
import { List, ListItem } from "../components/list";
import { convertDateWithoutTime } from "../helpers/utility";

import { useGetFutureHolidays } from "../api/queries/holidays";
import { useDeleteHoliday, useAddHoliday } from "../api/mutations/holidays";

export default function HolidaysPanel() {
    const [holiday, setHoliday] = useState({});

    const holidaysQuery = useGetFutureHolidays({
        select: (holidays) => {
            let years = [];

            // group the holidays by year
            for (let holiday of holidays) {
                holiday.date = new Date(convertDateWithoutTime(holiday.date));

                const dateYear = holiday.date.getFullYear();

                if (!years.find(y => y.year == dateYear)) {
                    years.push({
                        year: dateYear,
                        dates: []
                    })
                }

                years.find(y => y.year == dateYear).dates.push(holiday);
            }

            return years;
        }
    });
    const years = holidaysQuery.data;

    const deleteHoliday = useDeleteHoliday();
    const addHoliday = useAddHoliday();

    return (
        <Panel query={holidaysQuery}>
            <div className="grid gap-2">
                <Alert
                    icon={faExclamationCircle}
                    variant="warning"
                    size="sm"
                >
                    Changes to the holidays won't be reflected on the website until the next time it is published.
                </Alert>

                {years && 
                    <List>
                    {years.map((year) => {
                        return (
                            <ListItem key={year.year}>
                                <div className="grid grid-cols-[115px,1fr] gap-2">
                                    <div className="bg-purple-100 rounded p-3 font-bold text-center">
                                        {year.year}
                                    </div>
                                    <div className="flex items-center gap-2 flex-wrap">
                                        {year.dates.map(date => (
                                            <div key={date.date} className="grid gap-1 border rounded border-gray-300 p-1">
                                                <div className="flex items-center gap-2">
                                                    {format(date.date, "MMM do")}

                                                    <ButtonMutate
                                                        mutation={deleteHoliday}
                                                        mutationData={date.id}
                                                        icon={faTrash}
                                                        size="tiny"
                                                        variant="none-light"
                                                        confirmation="Delete this holiday?"
                                                    />
                                                </div>
                                                <div className="text-xs text-gray-400 text-center">
                                                    {date.type}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </ListItem>
                        )
                    })}
                    </List>
                }

                <div>
                    <h5 className="font-bold">
                        Add Holiday
                    </h5>
                    <div className="flex items-stretch gap-1">
                        <FieldSelect
                            prepend="Type"
                            prependClassName="flex items-center justify-center"
                            value={holiday.type}                            
                            items={[
                                {id:"holiday", label:"Holiday"},
                                {id:"shutdown", label:"Shutdown"},
                            ]}
                            onChange={(e,value) => setHoliday({...holiday, type: value})}
                            disabled={addHoliday.isLoading}
                            className="h-full"
                        />                        
                        <FieldDate
                            prepend="Date"
                            prependClassName="flex items-center justify-center"
                            value={holiday.date}   
                            disabled={addHoliday.isLoading}
                            onChange={(e,value) => setHoliday({...holiday, date: value})}
                        />

                        <ButtonMutate
                            mutation={addHoliday}
                            mutationData={holiday}
                            icon={faPlus}
                            caption="Add"
                            size="sm"
                            disabled={!holiday.type || !holiday.date || addHoliday.isLoading}
                            variant="outline-success"
                        />                        
                    </div>
                </div>
            </div>
        </Panel>
    );
}