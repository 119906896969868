import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetCardData = (type, typeId, params={}, options={}) => {
  return useQuery({ 
    queryKey: ["cards", type, typeId, params], 
    queryFn: ({signal}) => query("cards/card", {
        type,
        id: typeId,
        ...params
      },
      signal
    ),
    ...options
  });
}

export const useGetCardsData = (cardList, cardData, options={}) => {
  return useQuery({ 
    queryKey: ["cards", cardList, cardData], 
    queryFn: ({signal}) => query("cards/data", { card: cardList, ...cardData }, signal),
    ...options
  });
}