import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";

import CardMenu from "../components/cardmenu";
import CardDetails from "../components/carddetails";
import { dimensions } from "../settings/settings";

// Specialty panel that overflows the width.  Used on pages with headers/tabs where we don't
// want the top part to scroll when we have wide pages.
export function CardLayoutPanel({
    className,
    autoHeight,
    autoHeightOffset = 0,
    children
}) {
    const panelRef = useRef();
    const [panelTop, setPanelTop] = useState(null);

    useEffect(() => {
        if (panelRef.current) {
            setPanelTop(panelRef.current.offsetTop);
        }

    }, [panelRef.current]);

    return (
        <div 
            ref={panelRef}
            id="card-layout-panel"
            className={clsx(
                "overflow-auto",
                className
            )}
            style={{
                width: `calc(100vw - ${dimensions.sideBarWidth*2}px - ${dimensions.cardMenuWidth}px - ${dimensions.scrollBarWidth}px)`,
                height: autoHeight ? `calc(100vh - ${panelTop + (autoHeightOffset||0)}px)` : "auto",
            }}
        >
            {children}
        </div>
    )
}

export default function CardLayout({
    cards,
    className,
    header,
    embedded,
    noScroll,
    children,
    childrenClassName
}) {
    const [card, setCard] = useState(null);

    //useBodyClass(`noscroll`);

    return (
      <>
        <div className={clsx(
            !embedded && "grid grid-cols-[1fr,260px]", 
            className
        )}>
            <div className={clsx(
                "p-1",
                !noScroll && "overflow-auto", 
                !embedded && "h-[calc(100vh-55px)]",
                childrenClassName
            )}>
                {children}
            </div>
            {!embedded && 
                <div>
                    <CardMenu
                        cards={cards}
                        selected={card}
                        onCard={(card) => setCard(card)}
                    />
                </div>
            }
        </div>

        {card && 
            <div
                className={`fixed bottom-0 z-20`}
                style={{
                    top: dimensions.siteHeaderHeight + "px",
                    right: (dimensions.cardMenuWidth + dimensions.notificationsSideBarWidth) + "px",
                }}
            >
                <CardDetails
                    card={card}
                    onChange={(data) => {
                        // update the card timestamp so that it will be reloaded
                        // setLocalCards(produce(draft => {
                        //     draft[draft.findIndex(c => c.name == card.name)].timestamp = Date.now();
                        // }))
                    }}
                    onClose={() => setCard(null)}
                />
            </div>
        }        
      </>
    )
}