import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetMWOs = (params={}, options={}) => {      
  return useQuery({ 
    queryKey: ["mwos"], 
    queryFn: ({signal}) => query("mwos/list", params, signal),
    ...options
  });
};

// export const useGetMwosForPart = (partId, options={}) => {      
//   return useQuery({ 
//     queryKey: ["mwos", "part", partId], 
//     queryFn: ({signal}) => query("mwos/part/list", {part_id:partId}, signal),
//     ...options
//   });
// };

export const useGetMwoById = (id, options={}) => {      
  return useQuery({ 
    queryKey: ["mwos", "mwo", id], 
    queryFn: ({signal}) => query("mwos/mwo/byid", {id}, signal),
    ...options
  });
};

export const useGetMwoByName = (name, options={}) => {      
  return useQuery({ 
    queryKey: ["mwos", "mwo", name], 
    queryFn: ({signal}) => query("mwos/mwo/byname", {name}, signal),
    ...options
  });
};

export const useGetJobMwos = (workflowJobId, options={}) => {  
  return useQuery({ 
    queryKey: ["workflowjobs", ""+workflowJobId, "mwos"], 
    queryFn: ({signal}) => query("mwos/job", {job_id: workflowJobId}, signal),
    ...options
  });
};

export const useGetJobTaskMwoStickers = (workflowJobId, jobWorkflowId, options={}) => {  
  return useQuery({ 
    queryKey: ["workflowjobs", ""+workflowJobId, "mwos", "stickers", jobWorkflowId], 
    queryFn: ({signal}) => query("mwos/job/task/stickers", {
      job_id: workflowJobId, 
      job_workflow_id: jobWorkflowId, 
    }, signal),
    ...options
  });
};

export const useGetJobMwoStickers = (workflowJobId, partIdList, options={}) => {  
  return useQuery({ 
    queryKey: ["workflowjobs", ""+workflowJobId, "mwos", "stickers", partIdList], 
    queryFn: ({signal}) => query("mwos/job/stickers", {
      job_id: workflowJobId, 
      part_ids: partIdList
    }, signal),
    ...options
  });
};