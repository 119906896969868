import React from "react";

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';

import FieldLabel from "./label"
import HelpText from "./help-text"
import GoogleImage from "../google-image"

import { generateThumbnails } from "../../helpers/thumbnails"

function FieldFile(props) {

    function onFileSelected(event) {
        const filename = event.target.value;

        console.log("file selected", filename)

        if (filename != "") {
            let ext = filename.substr(filename.length - 3, 3).toLowerCase();
            let inputControl = event.target;

            if (ext === "jpeg") ext = "jpg";

            let validExt = true;

            if (props.extensions) {
                validExt = false;

                for (const extension of props.extensions) {
                    if (extension.toLowerCase() == ext) {
                        validExt = true;
                    }
                }
            }
            
            //if (ext === "jpg" || ext === "peg" || ext === "png" || ext === "eps" || ext === "pdf" || ext === "svg") {
            if (validExt) {
                props.onFile(inputControl.files[0]);
                
                if (ext == "jpg" || ext == "png") {
                    generateThumbnails(
                        inputControl.files[0],
                        null,
                        (thumbnails) => {
                            props.onThumbnail(thumbnails.thumbnail);
                            props.onPreview(thumbnails.preview);
                        }
                    );
                }
            }
        } 
    }

    return (
        <div style={{display:"flex", justifyContent: "space-between"}}>
            <Form.Group as={props.column ? Col:undefined}>
                {props.label && <FieldLabel required={props.required} bold={props.labelBold}>{props.label}</FieldLabel>}
                <FormControl 
                    as="input" 
                    type="file" 
                    name={props.name} 
                    required={props.required}
                    readOnly={props.readonly}
                    disabled={props.disabled}
                    defaultValue={props.value}
                    onChange={onFileSelected} 
                />
                {props.instructions && <HelpText>{props.instructions}</HelpText>}
            </Form.Group>
            {props.previewUrl &&
                <div>
                    <GoogleImage 
                        root 
                        src={props.previewUrl} 
                        style={{
                            maxWidth: "200px",
                            maxHeight: "75px"
                        }}
                    />
                </div>
            }
        </div>
    )
}

export default FieldFile