import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"
import styled from 'styled-components'
import ListField from "../components/fields/field-list"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faCaretDown } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"

import { postData } from "../common/services/server"
import GoogleImage from "../components/google-image";

function SelectContentDrawer({
    label,
    products,
    existingContent,
    onSelect, 
    onHide
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [contents, setContents] = useState([]);

  const [type, setType] = useState(null);
  const [product, setProduct] = useState(null);
  const [content, setContent] = useState(null);
  const [optional, setOptional] = useState(false);

  const types = [
    {value: "construction", title: "Product Construction"},
    {value: "installation", title: "Product Installation"},
    {value: "breakdown", title: "Product Breakdown", description: "Used in the builder when selecting a product."},
    {value: "includes", title: "Included With Product"},
    {value: "notincludes", title: "Not Included With Product"},
    {value: "other", title: "Other Content"},
  ]

  useEffect(() => {
      setIsProcessing(true);

      postData("content/data", {}, 
        function(results) {
          setContents(results);
        },
        function(error) {
          alert("An error occured loading the content.");
        },
        function() {
          setIsProcessing(false);
        }
      );   
  }, []);

  return (
    <>
        <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faFile} />
                &nbsp;
                {label || "Select"} Content
            </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minWidth: "800px"}}>
                { isProcessing ? 
                    <Loading />
                :
                contents.length == 0 ?
                    <p style={{color:"red"}}>No content found.</p>
                :
                    <div>
                        <ListField 
                            name="type" 
                            label="Content Type" 
                            value={type} 
                            list={types}
                            onChange={(e) => setType(e.target.value)} 
                        />
                        {type && 
                            <ListField 
                                name="product" 
                                label="Product" 
                                value={product ? product.id : null} 
                                list={products}
                                onChange={(e) => {
                                    setProduct(products.find(p => p.id == e.target.value));
                                }} 
                            />
                        }                        
                        {(type && product) && 
                            <ListField 
                                name="content" 
                                label="Content" 
                                value={content ? content.id : null} 
                                list={contents.filter(c => c.type == type && c.products && c.products.indexOf(product.id) != -1 && existingContent.find(ec => ec.id == c.id) == null)}
                                onChange={(e) => {
                                    setContent(contents.find(c => c.id == e.target.value));
                                }} 
                            />
                        }
                        {content && 
                            <div>
                                <div 
                                    style={{
                                        border: "1px solid #ccc",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        marginBottom: "10px",
                                        backgroundColor: "white",
                                        display: "grid",
                                        gridTemplateColumns: "125px 1fr",
                                        gap: "5px",
                                    }}
                                >
                                    <div>
                                        <GoogleImage
                                            root
                                            src={content.preview_url}
                                            width="125"
                                        />
                                    </div>
                                    <div>
                                        <strong>{content.title}</strong>
                                        <div style={{fontSize: "85%", color: "#999"}}>
                                            {content.description}
                                        </div>
                                    </div>
                                </div>

                                <Form.Check 
                                    name="optional"
                                    defaultValue={optional}
                                    checked={optional == true}
                                    label="Content Is Optional" 
                                    type="checkbox" 
                                    onChange={(e) => {
                                        setOptional(e.target.checked);
                                    }}
                                />    

                                {(content.product_list && content.product_list.length > 0) && 
                                    <div style={{marginTop: "20px"}}>
                                        This content is already associated with the following products:
                                        <ul>
                                        {content.product_list.split(",").map(product => (
                                            <li key={product}>
                                                {product}
                                            </li>
                                        ))}
                                        </ul>
                                    </div>
                                }                            
                            </div>
                        }
                    </div>
                }
            </Modal.Body>
            <Modal.Footer style={{display: "flex", justifyContent: "space-between"}}>
                <Button 
                    variant="outline-success" 
                    disabled={!content}
                    onClick={() => onSelect({
                        ...content,
                        optional_ind: optional ? 1:0,
                        status: optional ? "Optional" : "Required"

                    })}
                >
                    {label || "Select"} Content
                </Button>

                <Button variant="outline-secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>         
    </>
    )
}

export default SelectContentDrawer;