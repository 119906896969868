import React, { useState, useEffect, useRef } from "react";

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Col from 'react-bootstrap/Col';

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faEnvelope} from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import HtmlField from "../components/fields/field-html"
import ListField from "../components/fields/field-list"
import FieldLabel from "../components/fields/label"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import settings from '../settings'
import { removeNulls } from '../helpers/utility'
import { getUser } from "../common/services/auth"

import { postData, postExternalData } from "../common/services/server"

function EmailTemplateDrawer(props) {
  const formRef = useRef(null);

  const [processing, setProcessing] = useState("");
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  const [types, setTypes] = useState([]);
  
  useEffect(
    () => {
      setFormData(JSON.parse(JSON.stringify({...props.template, testemail: getUser().email })));

      let validTypes = [];

      if (props.template.type == "auto")
        validTypes.push({id: "auto", title: "Automatic"});
      else {
        validTypes.push({id: "manual", title: "Manual"});
        validTypes.push({id: "scheduled", title: "Scheduled"});
      }

      setTypes(validTypes);
    }, 
    [props.template]
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setProcessing("save");

      postData("emails/savetemplate", removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the email template.");
        },
        function() {
          setProcessing("");
        }
      );
    }
  }

  function onTest() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setProcessing("test");

      postExternalData(settings.SITE_API_URL + "emails/testtemplate", removeNulls(formData),
          function(result) {

          },
          function(error) {
              alert("Error testing email template.")
          },
          function() {
            setProcessing("");
          }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    console.log("handleFormChange", event.target.name, event.target.value)
    let field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  return (
      <Modal show={props.show} enforceFocus={false} onHide={props.onHide} size="lg" backdrop="static" keyboard={false} scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faEnvelope} />
            &nbsp;
            Email Template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef} style={{maxWidth:"600px"}}>
            <ListField 
              name="type" 
              label="Type"
              labelBold={true}
              required
              disabled={props.template && props.template.type == "auto"}
              value={formData.type} 
              list={types}
              onChange={(e) => handleFormChange(e)}
            />
            <TextField 
                name="name" 
                label="Name" 
                labelBold={true}
                required
                disabled={props.template.type == "auto"}
                value={formData.name} 
                onChange={(e) => handleFormChange(e)} />

            {formData.type == "scheduled" && 
              <>
                <ListField 
                  name="schedule_trigger" 
                  label="Trigger"
                  labelBold={true}
                  required
                  value={formData.schedule_trigger} 
                  list={[
                    {id: "sign_saved", title: "Sign Saved"},
                    {id: "addedsign_cart", title: "Sign Added To Cart"}
                  ]}
                  onChange={(e) => handleFormChange(e)}
                />   

                <FieldLabel required={true} bold={true}>Schedule</FieldLabel>                
                <InputGroup>
                  <InputGroup.Prepend>
                      <InputGroup.Text>
                        Send After
                      </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl 
                      as="input" 
                      type="number" 
                      step={1}
                      name="schedule" 
                      required={true}
                      value={formData.schedule} 
                      onChange={(e) => handleFormChange(e)}
                  />
                  <InputGroup.Append>
                    <ListField 
                      name="schedule_type" 
                      required
                      value={formData.schedule_type} 
                      groupStyle={{marginBottom:"0px"}}
                      list={[
                        {id: "days", title: "Days"}
                      ]}
                      onChange={(e) => handleFormChange(e)}
                    /> 
                  </InputGroup.Append>
                </InputGroup>
              </>        
            }

            <div style={{marginTop:"8px"}}>
              <FieldLabel required={true} bold={true}>
                Addresses
              </FieldLabel>                

              <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                      <InputGroup.Text style={{width:"60px"}}>
                      From
                      </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      name="from"
                      value={formData.from} 
                      onChange={(e) => handleFormChange(e)} 
                  /> 
              </InputGroup>
              <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                      <InputGroup.Text style={{width:"60px"}}>
                      To
                      </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      name="to"
                      value={formData.to} 
                      onChange={(e) => handleFormChange(e)} 
                  /> 
              </InputGroup>
              <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                      <InputGroup.Text style={{width:"60px"}}>
                      CC
                      </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      name="cc"
                      value={formData.cc} 
                      onChange={(e) => handleFormChange(e)} 
                  />        
              </InputGroup>      
              <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                      <InputGroup.Text style={{width:"60px"}}>
                      BCC
                      </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      name="bcc"
                      value={formData.bcc} 
                      onChange={(e) => handleFormChange(e)} 
                  />      
              </InputGroup>        
            </div>

            <TextField 
                name="subject" 
                label="Subject" 
                labelBold={true}
                required
                value={formData.subject} 
                onChange={(e) => handleFormChange(e)} />
            <TextField 
                name="preview" 
                label="Preview Message" 
                labelBold={true}
                value={formData.preview} 
                onChange={(e) => handleFormChange(e)} />                
            {props.template.type != "auto" &&
              <HtmlField 
                  name="body" 
                  label="Body" 
                  labelBold={true}
                  required
                  value={formData.body} 
                  onChange={(e) => handleFormChange(e)} />
            }

            <ListField 
              name="status" 
              label="Status"
              labelBold={true}
              required
              value={formData.status} 
              list={[
                {id: "Active", title: "Active"},
                {id: "Inactive", title: "Inactive"}
              ]}
              onChange={(e) => handleFormChange(e)}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div style={{display:"flex", flexBasis:"100%", justifyContent:"space-between"}}>
            <InputGroup style={{width:"225px"}}>
              <FormControl 
                  as="input" 
                  name="testemail" 
                  value={formData.testemail} 
                  onChange={(e) => handleFormChange(e)}
              />
              <InputGroup.Append>
                <ButtonProcessing 
                  processing={processing == "test"}
                  onClick={onTest} 
                  variant="outline-secondary" 
                  icon={faEnvelope} />                 
              </InputGroup.Append>
            </InputGroup>

            <div>
              <ButtonProcessing 
                  processing={processing == "save"}
                  onClick={onSave} 
                  variant="outline-success" 
                  caption="Save Template" 
                  icon={faSave} /> 
              <Button variant="outline-secondary" onClick={props.onHide}>
                  Cancel
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    )
}

export default EmailTemplateDrawer;