import React from "react";

import Field from "./field";
import Input from "./input";
import { format, isDate, parse, parseISO } from "date-fns";

function FieldDate({value, ...props}) {
    let dateValue = value;

    if (!isDate(dateValue)) {
        if (value) {
            if (value.indexOf("T") != -1) // iso
                dateValue = parseISO(value);
            else if (value.indexOf("-") != -1 && value.indexOf(" ") == -1) // yyy-mm-dd
                dateValue = parse(value, "yyyy-MM-dd", new Date());
            else
                dateValue = new Date(value);
        }
    }

    return (
        <Field {...props}>
            <Input 
                type="date" 
                className="border-0 m-0 p-1 w-full"
                value={dateValue ? format(dateValue, "yyyy-MM-dd") : ""}
                {...props}
            />
        </Field>
    )
}

export default FieldDate

/*
import React from "react";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { Badge } from "react-bootstrap";

import FieldLabel from "./label"

function FieldDate(props) {
    let groupStyle = props.groupStyle || {};

    if (props.layout == "horizontal") {
        groupStyle.display = "grid";
        groupStyle.gridTemplateColumns = "125px auto";
        groupStyle.alignItems = "center";
    }
        
    var defaultValue = props.default ? moment.utc(props.default).format('YYYY-MM-DD') : undefined;
    var value = props.value ? moment.utc(props.value).format('YYYY-MM-DD') : "";

    return (
        <Form.Group as={props.column ? Col:undefined} style={groupStyle}>
            {props.label && 
                <FieldLabel 
                    required={props.required} 
                    bold={props.labelBold}
                    style={props.labelStyle || {marginBottom: "0px"}}
                >
                    {props.label}
                </FieldLabel>
            }
            {(props.quickValues) && 
                <div style={{display:"flex", gap:"2px", marginBottom:"5px"}}>
                    {Object.keys(props.quickValues).map(qv => (
                        <Badge 
                            key={qv}
                            variant="secondary" 
                            style={{cursor: "pointer"}} 
                            onClick={() => props.onChange({target: {value: props.quickValues[qv]}})}
                        >
                            {qv}
                        </Badge>
                    ))}
                </div>
            }

            <FormControl 
                as="input" 
                type="date" 
                name={props.name} 
                required={props.required}
                defaultValue={defaultValue} 
                value={value}
                onChange={(e) => props.onChange(e)}/>
        </Form.Group>
    )
}

export default FieldDate
*/