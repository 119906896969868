import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faQrcode } from "@fortawesome/free-solid-svg-icons";

import Loading from "../components/loading"
import GroupPreview from "../components/group-preview"
import Error from "../components/error";
import ShapePreview from "../components/shape-preview";
import Button from "../components/buttons/button";
import DrawerTemplateDataList from "../drawers/template-datalist";
import MwoStickerBox from "../components/mwo-stickerbox";
import MwoSticker from "../components/mwo-sticker";
import { List, ListItem } from "../components/list"

import { usePostData } from "../hooks/usePostData";
import { TaskStatuses } from "../helpers/data";
import { Accordion, AccordionItem } from "../components/accordion";
import { getTitleForMwoStickerType } from "../helpers/mwo";
import { getIconForType } from "../helpers/icons";
import { useGetJobMwoStickers, useGetJobMwos } from "../api/queries/mwos";
import Panel from "./panel";
import Badge from "../components/badge";
import clsx from "clsx";

export default function JobWorkflowMWOsPanel({jobId}) {
    const mwosQuery = useGetJobMwos(jobId);

    const [mwos, setMwos] = useState(new Set());
    const [print, setPrint] = useState(false);

    const stickersQuery = useGetJobMwoStickers(jobId, [...mwos].join(","), {
        enabled: print
    });

    function selectMwos(mwos, check) {
        console.log("selectMwos", mwos)

        if (check)
            setMwos(new Set(mwos.map(m => m.part_id)));
        else 
            setMwos(new Set());
    }

    console.log({mwos})

    useEffect(
        () => {
            if (mwosQuery.data && mwos.size == 0) {
                // everything selected by default
                selectMwos(mwosQuery.data, true);
            }
        }, 
        [mwosQuery.data]
    );

    function allMwosChecked() {
        return mwos.size == mwosQuery.data.length;
    }

    return (
      <div>
        <Panel query={mwosQuery}>
            {mwosQuery.data && 
                <div className="grid gap-1 pb-10">
                    <div className="flex items-stretch gap-2">
                        <Button
                            size="tiny"
                            variant="outline-secondary"
                            onClick={() => selectMwos(mwosQuery.data, allMwosChecked() ? false:true)}
                        >
                            All
                        </Button>
                        <Button
                            size="tiny"
                            variant="outline-secondary"
                            onClick={() => selectMwos(mwosQuery.data.filter(m => m.type == "temporary"), true)}
                        >
                            Temporary
                        </Button>
                        <Button
                            size="tiny"
                            variant="outline-secondary"
                            onClick={() => selectMwos(mwosQuery.data.filter(m => m.type == "permanent"), true)}
                        >
                            Permanent
                        </Button>
                    </div>
                    
                    <List>
                        {mwosQuery.data?.map(mwo => {
                            const disabled = false;
                            const isForShapes = (mwo.stickers || "").includes("shape");

                            return (
                                <ListItem 
                                    key={mwo.id} 
                                    size="sm"
                                    className="justify-between"
                                    disabled={disabled}
                                >
                                    <div className="flex flex-1 items-center justify-between gap-2 p-1">
                                        <div>
                                            <div className="flex items-center gap-2">
                                                <input 
                                                    type="checkbox" 
                                                    disabled={disabled}
                                                    checked={mwos.has(mwo.part_id)}
                                                    onClick={(e) => {
                                                        e.stopPropagation();

                                                        const mwosData = new Set(mwos);

                                                        if (e.target.checked)
                                                            mwosData.add(mwo.part_id);
                                                        else 
                                                            mwosData.delete(mwo.part_id);

                                                        setMwos(mwosData);
                                                    }}
                                                />
                                                {mwo.part_title}
                                                <Badge variant="light" size="tiny">
                                                    {mwo.part_type}
                                                </Badge>
                                            </div>
                                            <div className="text-xs text-gray-400">
                                                {getTitleForMwoStickerType(mwo.stickers)}
                                            </div>
                                        </div>
                                        <div className="w-[400px] grid gap-1">
                                            <div className="text-xs">{mwo.title}</div>
                                            <div className={clsx(mwo.type == "permanent" && "bg-gray-300")}>
                                                <MwoSticker
                                                    mwo={{
                                                        ...mwo,
                                                        short_title: mwo.job_title.split(" ")[0],
                                                        id: jobId
                                                    }}
                                                    shape={isForShapes ? {
                                                            shape_index: 1,
                                                            shape_total: 1,
                                                            resembles_ind: 1,
                                                            path: "m 78.272569,5.3299616 c 0,-21.6150296 -17.52193,-39.1373276 -39.13585,-39.1373276 -21.61491,0 -39.13678003516,17.522298 -39.13678003516,39.1373276 0,21.6134624 17.52187003516,39.1357594 39.13581003516,39.1357594 21.61489,0 39.13682,-17.52125 39.13682,-39.1357594 l 0,0"
                                                        } : null
                                                    }
                                                    type={mwo.type}
                                                    style={{
                                                        margin: "0px",
                                                        padding: "0px",
                                                    }}
                                                />
                                            </div>                          
                                        </div>
                                    </div>
                                </ListItem>
                            )
                        })}
                    </List>
                </div>
            }
        </Panel>

        <div className="sticky bottom-2">
            <Button
                icon={faPrint}
                variant="solid-success"
                disabled={mwos.size == 0}
                onClick={() => setPrint(true)}
            >                        
                Print Stickers
            </Button>  
        </div>  

        {print && 
            <DrawerTemplateDataList
                title="MWOs"
                icon={faQrcode}
                query={stickersQuery}
                itemRender={(sticker) => (
                    <MwoSticker job={{id: jobId}} shape={sticker.shape} mwo={sticker} />
                )}
                onHide={() => setPrint(false)}
            />
        }
      </div>
    )
}
