import React, { useState, useEffect } from "react";
import { Badge, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus, faTrash, faEye, faPlug, faSpinner, faTruckMoving } from "@fortawesome/free-solid-svg-icons";

import ProductMwoDrawer from "../drawers/drawer-productmwo";
import SelectMwoDrawer from "../drawers/drawer-selectmwo";
import SelectSignDrawer from "../drawers/drawer-selectsign";

import BoardField from "../components/board/board-field"
import MwoSticker from "../components/mwo-sticker"
import styled from "styled-components";
import BoardFields from "../components/board/board-fields";
import ButtonProcessing from "../components/buttons/button-processing";
import { postData } from "../common/services/server";
import { getTitleForMwoStickerType } from "../helpers/mwo";
import { colors } from "../settings/settings";
import SignMwosDrawer from "../drawers/drawer-signmwos";

const MwoContainer = styled.div`
    .stickerBox {
        margin: 0px !important;
    }
`

function Panel({
  product,
  products,
  onChange  
}) {
  const [busy, setBusy] = useState("");
  const [mwo, setMwo] = useState(null);
  const [selectMwo, setSelectMwo] = useState(false);
  const [selectSign, setSelectSign] = useState(false);
  const [previewSign, setPreviewSign] = useState(false);

  function onMwoChange(mwoData) {
    console.log("onMwoChange",mwoData)

    const mwosData = JSON.parse(JSON.stringify(product.mwos));
    const mwoIdx = mwosData.findIndex(m => m.id == mwoData.id);

    console.log({mwoIdx});

    if (mwoIdx != -1)
        mwosData[mwoIdx] = mwoData
    else 
        mwosData.push(mwoData);

    onChange({
        mwos: mwosData
    })    
  }

  function onDelete(mwo) {
    if (window.confirm("Are you sure you want to delete this MWO sticker?")) {
        setBusy("delete" + mwo.id)

        postData("products/removemwo", {
            id: product.id,
            mwo_id: mwo.id
        }, 
            function(result) {
                const mwosData = JSON.parse(JSON.stringify(product.mwos));
                const mwoIdx = mwosData.findIndex(m => m.id == mwo.id);
            
                console.log({mwoIdx});
            
                if (mwoIdx != -1) {
                    mwosData.splice(mwoIdx, 1);
            
                    onChange({
                        mwos: mwosData
                    })              
                }
            },
            function(error) {
                alert("An error occured removing the sticker.");
            },
            function() {
                setBusy("")
            }
        );        
    }
  }

  return (
    <>
        <BoardField
          type="text"
          label="MWO Title"
          info="Title or prefix used on Micro Work Orders in jobs for this product."
          value={product.mwo_title}
          saveUrl={`products/updateprop?product_id=${product.id}&prop=mwo_title`}
          onSaved={onChange}
        />    
            
        <BoardFields label="Stickers">
            {(product.mwos.length > 0) ? 
                <>
                {product.mwos.map((mwo) => {
                    const parts = (mwo.part_list || "").split(",").map(p => {
                        return {title: p};
                    });
                    const modes = (mwo.part_modes || "").split(",");

                    for (let i=0; i<parts.length; i++) {
                        parts[i].mode =  modes.length >= i ? modes[i] || "OR" : "OR";
                    }

                    const validParts = parts.filter(p => product.parts.find(pp => pp.title == p.title));

                    return (
                    <BoardField 
                        key={mwo.id}
                        type="children"
                        label={mwo.title}
                        suffix={
                            <>
                            <Button size="sm" variant="outline-secondary" onClick={() => setMwo(mwo)}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>
                            <Button size="sm" variant="outline-danger" onClick={() => onDelete(mwo)}>
                                <FontAwesomeIcon icon={busy == ("delete"+mwo.id) ? faSpinner : faTrash} spin={busy == ("delete"+mwo.id)} />
                            </Button>  
                            </>                      
                        }
                    >
                        <MwoContainer>
                            <MwoSticker
                                mwo={mwo}
                                type={mwo.type}
                                noMargin
                            />

                            <div style={{display:"flex", gap: "3px", flexFlow: "wrap", margin: "3px 5px", textTransform: "capitalize"}}>
                                <Badge variant="outline-primary" size="sm" style={{border: "1px solid "+ colors.blue}}>
                                    {getTitleForMwoStickerType(mwo.stickers)}
                                </Badge>

                                {(mwo.part_list && mwo.part_list.length) && 
                                    <>
                                    {validParts.map(part => (
                                        <Badge variant="secondary" size="sm">
                                            <FontAwesomeIcon icon={faPlug} style={{marginRight: "3px"}} />
                                            {part.title}
                                            {validParts.length > 1 && 
                                                <span style={{backgroundColor:"white", color: "#333", padding: "0px 2px", fontWeight: "normal", borderRadius: "3px", marginLeft:"4px"}}>
                                                    {part.mode.toUpperCase()}
                                                </span>
                                            }
                                        </Badge>
                                    ))}
                                    </>
                                }
                                {(mwo.shipping_method_list && mwo.shipping_method_list.length) && 
                                    <>
                                    {mwo.shipping_method_list.split(",").map(method => (
                                        <Badge variant="secondary" size="sm">
                                            <FontAwesomeIcon icon={faTruckMoving} style={{marginRight: "3px"}} />
                                            {method}
                                        </Badge>
                                    ))}
                                    </>
                                }                                
                            </div>
                        </MwoContainer>
                    </BoardField>
                    )
                })}
                </>
            :
                <p>No MWO Stickers Found</p>
            }

            <div style={{margin:"5px 1px", display:"flex", gap:"5px"}}>
                <ButtonProcessing
                    caption="Add Existing Sticker"
                    icon={faPlus}
                    size="sm"
                    processing={busy == "addexisting"}
                    variant="outline-primary"
                    onClick={() => setSelectMwo(true)}
                />
                <Button 
                    size="sm"
                    variant="outline-success"
                    onClick={() => setMwo({
                        product_id: product.id
                    })}
                >
                    Create Sticker
                </Button>
                <ButtonProcessing 
                    caption="Preview Sign"
                    icon={faEye}
                    size="sm"
                    variant="outline-secondary"
                    processing={busy == "preview"}
                    style={{marginLeft: "auto"}}
                    onClick={() => {
                        setSelectSign(true)
                    }}
                />
            </div>            
        </BoardFields>

        {mwo && 
            <ProductMwoDrawer
                product={product}
                mwo={mwo}
                onSaved={(mwoData) => {
                    onMwoChange(mwoData);
                    setMwo(null);
                }}
                onHide={ () => setMwo(null) } 
            />
        }
        {selectMwo && 
            <SelectMwoDrawer
                label="Add"
                products={products.filter(p => p.id != product.id)}
                parts={product.parts}
                existingMWOs={product.mwos}
                onSelect={(mwoData) => {
                    setSelectMwo(false);
                    setBusy("addexisting");
                    
                    postData("products/addmwo", {
                            id: product.id,
                            mwo_id: mwoData.id,
                            part_ids: (mwoData.part_ids || []).join(",")
                        }, 
                        function(result) {
                            onMwoChange(result);
                        },
                        function(error) {
                            alert("An error occured adding the sticker.");
                        },
                        function() {
                            setBusy("")
                        }
                    );
                }}
                onHide={ () => setSelectMwo(false) } 
            />
        }      
        {selectSign && 
            <SelectSignDrawer 
                show={true}
                prompt="Which sign do you want to preview?"
                filters={{
                    product_id: product.id
                }}
                onSelect={(sign) => {
                    console.log({sign})
                    setBusy("preview");
                    setSelectSign(false);
                    setPreviewSign(sign);
                }} 
                onHide={ () => setSelectSign(false) } 
            /> 
        }   
        {previewSign && 
          <SignMwosDrawer 
            show={true}
            sign={previewSign}
            onHide={() => setPreviewSign(null)}
          />
        }                
    </>
  );
}

export default Panel;