import React, { useState, useEffect } from "react";
import produce from "immer"
import {  useHistory } from "react-router-dom";

import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    EditActionsCell
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Loading from "../../components/loading"
import StyleDrawer from "../../drawers/drawer-style"

import { postData } from "../../common/services/server"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

function StylesPanel(props) {
    const windowDimensions = useWindowDimensions();
    const history = useHistory();

    const [styles, setStyles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(null);

    useBodyClass(`noscroll`);

    useEffect(
        () => {
            setIsLoading(true);

            postData("styles/data", {},
                function(result) {
                    setStyles(result);
                },
                function(error) {
                    alert("Error loading styles")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        []
    );

    function onAction(action, style) {
        if (action == "kerning")
            history.push("/kerning/" + style.id);
        else if (action == "shapes")
            history.push("/shapes/" + style.code);
        else
            setSelected({mode:action, style});
    }
    
    function onStyleChanged(style) {
        setStyles(produce(draft => {
            const index = draft.findIndex(t => t.id == style.id);

            draft[index] = style;
        }));

        setSelected(null);
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
                <Table
                    rowHeight={50}
                    rowsCount={styles.length}
                    width={windowDimensions.width - dimensions.dataTableWidthOffset}
                    height={windowDimensions.height - dimensions.dataTableHeightOffset}
                    headerHeight={50}
                    touchScrollEnabled={true}
                >
                    <Column
                        header={<Cell>Style Name</Cell>}
                        columnKey="name"
                        fixed={!isMobile}
                        cell={<TextCell data={styles} />}
                        width={200}
                    />
                   <Column
                        header={<Cell>Style Code</Cell>}
                        columnKey="code"
                        cell={<TextCell data={styles} />}
                        width={200}
                    />
                    <Column
                        header={<Cell>Pricing Mod</Cell>}
                        columnKey="price_modifier"
                        cell={<TextCell data={styles} />}
                        width={150}
                    />
                    <Column
                        header={<Cell>Standard Sizes</Cell>}
                        columnKey="standard_sizes"
                        cell={<TextCell data={styles} />}
                        width={250}
                    />
                    <Column
                        header={<Cell>Actions</Cell>}
                        cell={
                            <EditActionsCell 
                                data={styles} 
                                actions={[
                                    {action:"kerning", title:"Kerning", caption:"Kerning", variant:"secondary", isVisible: (style) => style.id > 0 && style.code.indexOf("cabinet") == -1, permission:Permissions.Shapes, permissionAction:Actions.Edit},
                                    {action:"shapes", title:"Shapes", caption:"Shapes", variant:"primary", isVisible: (style) => style.code.indexOf("logo") == -1 && style.code.indexOf("custom") == -1},
                                    {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, permission:Permissions.Shapes, permissionAction:Actions.Edit},
                                ]}
                                busy={null}
                                onClick={onAction} 
                            />
                        }
                        align="center"
                        width={200}
                    />
                </Table>
            }

            {(selected && selected.mode=="edit") && 
                <StyleDrawer 
                    style={selected.style} 
                    onSaved={onStyleChanged} 
                    onHide={ () => setSelected(null) } 
                />
            } 
        </>
    );
}

export default StylesPanel;