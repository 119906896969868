import React from "react"

function Coupon(props) {
    return (
        <span style={{border: "2px dotted white", padding: "3px 6px", backgroundColor:"#ffbb3e"}}>
            {props.code}
        </span>
    );
}

export default Coupon