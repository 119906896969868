import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import queryString from 'query-string'

import Content from "../components/content"
import PageHeader from "../components/page-header";
import SignsPanel from "./panels/panel-signs"
import SignTemplatesPanel from "./panels/panel-signtemplates"
import SignDraftsPanel from "../panels/draftsigns"
import SignChatsPanel from "../panels/signchats"
import ActionButton from '../components/buttons/button-action'

import { postData } from "../common/services/server"
import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import PageLayout from "../components/page-layout";
import SignLogosPanel from "../panels/signlogos";

function SignsPage(props) {
    const [busy, setBusy] = useState("");

    const history = useHistory();
    const params = queryString.parse(props.location ? props.location.search : "");
    let { tab } = useParams();

    if (!tab) tab = "all";

    function onCreateSign() {
        setBusy("creating")

        postData("signs/createcustom", {},
            function(result) {
                history.push("/signs/sign/" + result.id)
            },
            function(error) {
                alert("Error creating sign")
            },
            function() {
                setBusy("");
            }
        );
    }

    return (
        <PageLayout>
            <PageHeader 
                title="Sign Manager"
                help="signs"
                tab={tab}
                tabUrl={"/signs/{tab}"}
                tabs={[
                    {name:"all", title:"All Signs"},
                    {name:"templates", title:"Template Signs", permission: Permissions.SignTemplates},
                    {name:"logos", title:"Sign Logos", permission: Permissions.Signs},
                    {name:"drafts", title:"Draft Signs", permission: Permissions.Signs},
                    {name:"chats", title:"Sign Chats", permission: Permissions.Signs},
                ]}
                onTab={(tab) => {
                    history.push("/signs/" + tab);
                }}
            >
                {userHasPermission(Permissions.SignsCustom, Actions.Create) &&
                    <ActionButton
                        caption="Create Custom Sign"
                        icon={faPlus}
                        variant="success" 
                        size="sm"
                        onClick={onCreateSign} 
                        busy={busy == "creating"}
                    />
                }
            </PageHeader>
            <Content>
                {tab == "all" && 
                    <SignsPanel
                        filters={{
                            type: tab,
                            date: params.date
                        }}
                        haveTabs={true}
                    />
                }
                {tab == "templates" && 
                    <SignTemplatesPanel 
                        haveTabs={true}
                    />
                }  
                {tab == "drafts" && 
                    <SignDraftsPanel 
                        haveTabs={true}
                    />
                }    
                {tab == "chats" && 
                    <SignChatsPanel 
                        haveTabs={true}
                    />
                }   
                {tab == "logos" && 
                    <SignLogosPanel 
                        haveTabs={true}
                    />
                }                                  
            </Content>
        </PageLayout>
    );
}

export default SignsPage;