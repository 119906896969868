import React from "react";

import GroupPreview from "../components/group-preview"
import ShapePreview from "../components/shape-preview"
import Panel from "./panel";
import { List, ListItem } from "../components/list"

import { useWorkflowJobAddSignShape, useWorkflowJobRemoveSignShape, useWorkflowJobUpdateShapeProp } from "../api/mutations/workflowjobs";
import { useGetJobShapes } from "../api/queries/workflowjobs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Alert from "../components/alert";
import BoardField from "../components/board/board-field";

function ShapeProperties({jobId, group, shape}) {
    const updateShapeProp = useWorkflowJobUpdateShapeProp(jobId);

    return (
        <>
            <div className="w-[125px]">
                <BoardField
                    type="number"
                    label="Faces"
                    labelClassName="!w-[55px]"
                    value={shape.face_count || 0}
                    saveMutation={updateShapeProp}
                    onSave={(value) => {
                        return {
                            jobGroupId: group.id, 
                            jobShapeId: shape.jobshape_id, 
                            prop: "face_count", 
                            value                                                                        
                        }
                    }}
                />
            </div>
            <div className="w-[125px]">
                <BoardField
                    type="number"
                    label="Sides"
                    labelClassName="!w-[55px]"
                    value={shape.side_count || 0}
                    saveMutation={updateShapeProp}
                    onSave={(value) => {
                        return {
                            jobGroupId: group.id, 
                            jobShapeId: shape.jobshape_id, 
                            prop: "side_count", 
                            value                                                                        
                        }
                    }}
                />
            </div>
            <div className="w-[125px]">
                <BoardField
                    type="number"
                    label="Backs"
                    labelClassName="!w-[55px]"
                    value={shape.back_count || 0}
                    saveMutation={updateShapeProp}
                    onSave={(value) => {
                        return {
                            jobGroupId: group.id, 
                            jobShapeId: shape.jobshape_id, 
                            prop: "back_count", 
                            value                                                                        
                        }
                    }}
                />
            </div>               
            {group.cabinet_ind == 1 && 
                <div className="w-[125px]">
                    <BoardField
                        type="number"
                        label="Tops"
                        labelClassName="!w-[55px]"
                        value={shape.top_count || "0"}
                        saveMutation={updateShapeProp}
                        onSave={(value) => {
                            return {
                                jobGroupId: group.id, 
                                jobShapeId: shape.jobshape_id,     
                                prop: "top_count", 
                                value                                                                        
                            }
                        }}
                    />
                </div>
            }
            {group.cabinet_ind == 1 && 
                <div className="w-[125px]">
                    <BoardField
                        type="number"
                        label="Bottoms"
                        labelClassName="!w-[55px]"
                        value={shape.bottom_count || "0"}
                        saveMutation={updateShapeProp}
                        onSave={(value) => {
                            return {
                                jobGroupId: group.id, 
                                jobShapeId: shape.jobshape_id,     
                                prop: "bottom_count", 
                                value                                                                        
                            }
                        }}
                    />
                </div>
            }            
        </>
    )
}
export default function JobWorkflowShapesPanel({jobId}) {
    const groupsQuery = useGetJobShapes(jobId);
    const groups = groupsQuery.data;

    const addSignShape = useWorkflowJobAddSignShape(jobId, {
        onError: () => window.alert("Error adding shape")
    });

    const removeSignShape = useWorkflowJobRemoveSignShape(jobId, {
        onError: () => window.alert("Error removing shape")
    });

    return (
        <Panel query={groupsQuery}>
            {groups &&
                <div className="flex gap-2">
                    <List>
                        {groups.map(group => (
                            <ListItem key={group.id} className="grid grid-cols-[125px,1fr] items-center">
                                <div className="text-xs">
                                    <GroupPreview group={group} solid={true} width={100} height={50} />
                                    <div>
                                        {group.product}
                                    </div>
                                    <div>
                                        Size {group.size}
                                    </div>
                                    {group.style_type == "letters" && 
                                        <div>
                                            {group.style}
                                        </div>
                                    }
                                </div>

                                <div className="space-y-1">
                                    <List direction="horizontal" className="flex-wrap">
                                        {group.shapes.filter(s => s.code != null && s.code != ' ').map(shape => (
                                            <>
                                            {shape.related_count > 0 ? 
                                                <>
                                                {shape.related.filter(s => s.code != null && s.code != ' ').map(related => (
                                                    <ListItem 
                                                        key={related.related_id} 
                                                        size="sm" 
                                                        className="flex-column !justify-center gap-2"
                                                        disabled={!related.checked}
                                                    >
                                                        <input 
                                                            type="checkbox"
                                                            name="shape_id"                                
                                                            value={related.related_id}
                                                            checked={related.checked}
                                                            disabled={addSignShape.isLoading || removeSignShape.isLoading}
                                                            style={{margin: "0px", padding: "0px"}}
                                                            onChange={(e) => {
                                                                e.stopPropagation();

                                                                if (e.target.checked) {
                                                                    addSignShape.mutate({
                                                                        jobGroupId: group.id, 
                                                                        signShapeId: shape.signshape_id,
                                                                        relatedShapeId: related.related_shape_id, 
                                                                        shapeRelatedId: related.shape_related_id, 
                                                                    });
                                                                }
                                                                else {
                                                                    removeSignShape.mutate({
                                                                        jobGroupId: group.id, 
                                                                        signShapeId: shape.signshape_id,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        { (addSignShape.isLoading && addSignShape.variables?.relatedShapeId == related.related_shape_id && addSignShape.variables?.shapeRelatedId == related.shape_related_id) ?
                                                            <FontAwesomeIcon icon={faSpinner} spin size="xl" className="text-gray-300 mx-2 my-1" />
                                                        : (removeSignShape.isLoading && removeSignShape.variables?.signShapeId == related.signshape_id) ?
                                                            <FontAwesomeIcon icon={faSpinner} spin size="xl" className="text-gray-300 mx-2 my-1" />
                                                        :
                                                            <div className="space-y-1">
                                                                {related.path && <div className="m-2"><ShapePreview path={related.path} solid={true} width={35} height={25} /></div>}
                                                                {!related.path && <>{related.code}</>}

                                                                {shape.jobshape_id && 
                                                                    <ShapeProperties 
                                                                        jobId={jobId}
                                                                        group={group}
                                                                        shape={shape}
                                                                    />
                                                                }
                                                            </div>
                                                        }
                                                    </ListItem>                                                
                                                ))}
                                                </>
                                            :
                                                <ListItem 
                                                    key={shape.signshape_id} 
                                                    size="sm"
                                                    className="flex-column !justify-center gap-2"
                                                    disabled={!shape.checked}
                                                >
                                                    <input type="checkbox" 
                                                        name="shape_id"                                
                                                        value={shape.signshape_id}
                                                        checked={shape.checked}
                                                        disabled={addSignShape.isLoading || removeSignShape.isLoading}
                                                        style={{margin: "0px", padding: "0px"}}                                                
                                                        onChange={(e) => {
                                                            e.stopPropagation();

                                                            if (e.target.checked) {
                                                                addSignShape.mutate({
                                                                    jobGroupId: group.id, 
                                                                    signShapeId: shape.signshape_id,
                                                                });
                                                            }
                                                            else {
                                                                removeSignShape.mutate({
                                                                    jobGroupId: group.id, 
                                                                    signShapeId: shape.signshape_id,
                                                                });
                                                            }                                                        
                                                        }}
                                                    />
                                                    {(addSignShape.isLoading && addSignShape.variables?.signShapeId == shape.signshape_id) ?
                                                        <FontAwesomeIcon icon={faSpinner} spin size="lg" className="text-gray-300 mx-2 my-1" />
                                                    :(removeSignShape.isLoading && removeSignShape.variables?.signShapeId == shape.signshape_id) ?
                                                        <FontAwesomeIcon icon={faSpinner} spin size="lg" className="text-gray-300 mx-2 my-1" />
                                                    :
                                                        <div className="space-y-1">                                                
                                                            {shape.path && <div className="m-2"><ShapePreview path={shape.path} solid={true} width={35} height={25} /></div>}
                                                            {!shape.path && <>{shape.code}</>}

                                                            {shape.jobshape_id && 
                                                                <ShapeProperties 
                                                                    jobId={jobId}
                                                                    group={group}
                                                                    shape={shape}
                                                                />
                                                            }
                                                        </div>
                                                    }
                                                </ListItem>
                                            }
                                            </>
                                        ))}
                                    </List>

                                    {group.multipiece_ind == 1 &&
                                        <Alert size="tiny" variant="danger">
                                            Group is multipiece
                                        </Alert>
                                    }      
                                </div>                          
                            </ListItem>
                        ))}
                    </List>
                </div>
            }
        </Panel>
    )
}