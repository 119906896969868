import clsx from "clsx";
import React from "react";

function Input({
    type,
    name,
    size,
    required,
    readonly,
    disabled,
    isInvalid,
    value,
    className,
    style,
    onBlur,
    onEnter,
    onChange,
    ...props
}) {
    return (
        <input 
            type={type || "text"}
            name={name} 
            required={required}
            readOnly={readonly}
            disabled={disabled}
            value={value == null || value == undefined ? "" : value} 
            onBlur={onBlur}
            className={clsx(
                "invalid:bg-red-100 invalid:text-red-500",
                size == "sm" && "text-sm",
                className,
            )}
            style={style}
            {...props}
            onChange={(e) => {
                if (onChange)
                    onChange(e, e.target.value) 
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter' && type != "textarea" && onEnter) {
                    onEnter(e, e.target.value);
                }
            }}             
        />
    )
}

export default Input