import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { useParams, useHistory, Link } from "react-router-dom";
import queryString from 'query-string'

import ListGroup from 'react-bootstrap/ListGroup'

import Content from "../components/content"
import PageHeader from "../components/page-header";
import OrdersPanel from "./panels/panel-orders";
import CartsPanel from "./panels/panel-carts"
import OrderDrawer from "../drawers/drawer-order";
import Button from "../components/buttons/button";

import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import PageLayout from "../components/page-layout";

function OrdersPage(props) {
    const history = useHistory();
    const params = queryString.parse(props.location ? props.location.search : "");
    let { tab } = useParams();

    if (!tab) tab = "signmonkey";

    const [selected, setSelected] = useState(null);
    const [counter, setCounter] = useState(0);

    function onCreate() {
        console.log("create")
        setSelected({mode:"create", order:{
            order_date: moment.utc(new Date()).format('YYYY-MM-DD')
        }});
    }

    return (
        <PageLayout>
            <PageHeader 
                title="Order Manager"
                help="orders"
                tab={tab}
                tabUrl={"/orders/{tab}"}
                tabs={[
                    {name:"signmonkey", title:"Sign Monkey"},
                    {name:"paris", title:"Paris Signs", permission: Permissions.OrdersParis},
                    {name:"carts", title:"Shopping Carts", icon: faShoppingCart, permission: Permissions.ShoppingCarts},                    
                    {name:"reports", title:"Reports", align: "right", permission: Permissions.Orders, action: Actions.Reports},
                ]}
                onTab={(tab) => {
                    history.push("/orders/" + tab);
                }}
            >
                {userHasPermission(Permissions.Orders, Actions.Create) &&
                    <Button onClick={onCreate} variant="outline-success" size="sm">
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        Create Order
                    </Button>
                }
            </PageHeader>
            <Content permission={Permissions.Orders}>
                {(tab == "signmonkey" || tab == "paris") &&
                    <OrdersPanel
                        filters={{
                            company_id: tab == "signmonkey" ? 1 : 2,
                            date: params.date,
                            counter: counter
                        }}
                        haveTabs={true}
                    />
                }
                {tab == "carts" &&
                    <CartsPanel
                        filters={{
                            counter: counter
                        }}
                        params={params}
                        haveTabs={true}
                    />        
                }        
                {tab == "reports" &&
                    <ListGroup>
                        <ListGroup.Item>
                            <Link to="/reports/shipping">
                                Shipping Comparison Report
                            </Link>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Link to="/reports/orders">
                                Orders Report
                            </Link>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Link to="/reports/payments">
                                Payments Report
                            </Link>
                        </ListGroup.Item>                        
                    </ListGroup>
                }
            </Content>
            { selected && selected.mode == "create" && 
                <OrderDrawer 
                    order={selected.order} 
                    show={true}
                    onSaved={(order) => {
                        setCounter(counter+1); // force refresh
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
        </PageLayout>
    );
}

export default OrdersPage;