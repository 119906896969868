import React from "react";
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons'

import DrawerTemplateData from "./template-data";
import { useGetMwoById } from "../api/queries/mwos";
import MwoSticker from "../components/mwo-sticker";
import Badge from "../components/badge";
import { getTitleForMwoStickerType } from "../helpers/mwo";

export default function WorkflowMwoPreviewDrawer({
    mwoId,
    stickers,
    onHide
}) {
  const mwoQuery = useGetMwoById(mwoId);
  const mwo = mwoQuery.data;

  const rows = [];

  for (let i=1; i<=(mwo ? mwo.rows : 0); i++) {
      rows.push(i)
  }

  const isForShapes = stickers?.includes("shape");

  console.log({isForShapes})
  return (
      <>
        <DrawerTemplateData
            icon={faTicketAlt}
            title={"MWO"}
            query={mwoQuery}
            onHide={onHide}
        >
            {mwo &&
              <div className="grid gap-2">
                <MwoSticker
                    mwo={mwo}
                    shape={isForShapes ? {
                            shape_index: 1,
                            shape_total: 1,
                            resembles_ind: 1,
                            path: "m 78.272569,5.3299616 c 0,-21.6150296 -17.52193,-39.1373276 -39.13585,-39.1373276 -21.61491,0 -39.13678003516,17.522298 -39.13678003516,39.1373276 0,21.6134624 17.52187003516,39.1357594 39.13581003516,39.1357594 21.61489,0 39.13682,-17.52125 39.13682,-39.1357594 l 0,0"
                        } : null
                    }
                    type={mwo.type}
                    style={{
                        margin: "0px",
                        padding: "0px",
                    }}
                />

                {stickers && 
                    <div className="flex">
                        <Badge variant="light" value={getTitleForMwoStickerType(stickers)} />
                    </div>
                }
              </div>
            }
        </DrawerTemplateData>     
      </>
    )
}
