import React, { useState, useEffect } from "react"
import styled from "styled-components"
import produce from "immer"
import Table from "react-bootstrap/Table"
import { Link } from "react-router-dom"
import ProgressBar from 'react-bootstrap/ProgressBar'
import { useHistory } from "react-router-dom";

import ChartBox from "../charts/chartbox"

import { postData } from "../../common/services/server"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import GoogleImage from "../google-image"
import { formatDate } from "../../helpers/utility"

const Jobs = styled(Table)`
    tr {
        cursor: pointer;
    }
`

function ActiveJobsWidget(props) {
    const history = useHistory();

    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [error, setError] = useState(null);

    useEffect(
        () => {
            setLoading(true);

            postData("jobs/active", {},
                function(result) {
                    setJobs(result);
                },
                function(error) {
                    setError("Error loading jobs")
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        [refresh]
    );

    function onJob(job) {
        history.push("/jobs/job/" + job.id);
    }

    return (
        <>
            <ChartBox 
                icon={faEnvelope}
                title="Active Jobs" 
                loading={loading}
                error={error}
                refresh={true}
                onRefresh={() => setRefresh(refresh++)}
            >
                <Jobs striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Job</th>
                        <th>Preview</th>
                        <th>Start Date</th>
                        <th>Ship Date</th>
                        <th>Progress</th>
                    </tr>
                </thead>
                <tbody>
                {jobs.map(job => (
                    <tr key={job.id} onClick={() => onJob(job)}>
                        <td>
                            {job.title}
                        </td>
                        <td>
                            <GoogleImage root src={job.preview_url} style={{ height: "40px" }} />
                        </td>
                        <td>
                            {formatDate(job.start_date)}
                        </td>
                        <td>
                            {formatDate(job.ship_date)}
                        </td>
                        <td>
                            <ProgressBar now={parseFloat(job.progress)} label={`${job.progress}%`} />
                        </td>
                    </tr>
                ))}
                </tbody>
                </Jobs>
                {(!loading && jobs.length == 0) && 
                    <p style={{textAlign:"center"}}>
                        No active jobs found.  View <Link to="/jobs">all jobs</Link>.
                    </p>
                }
            </ChartBox>
        </>
    );
}

export default ActiveJobsWidget