import React, { useEffect, useState } from "react";
import { addHours, differenceInMinutes, parseISO } from "date-fns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheck, faClipboardList, faExternalLinkSquareAlt, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'

import BoardField from "../components/board/board-field";
import BoardFields from "../components/board/board-fields";
import DrawerTemplateData from "./template-data";

import storage from "../settings/storage";

import { formatDateTime } from "../helpers/utility";
import { useGetJobWorkflowTask, useGetJobWorkflowTaskParts } from "../api/queries/workflowjobs";
import { useGetProductionEmployees } from "../api/queries/users";
import { useWorkflowJobUpdateTaskProp, useWorkflowJobResolveTaskProblem } from "../api/mutations/workflowjobs";
import { useGetStatuses } from "../api/queries/statuses";
import { TaskStatuses } from "../helpers/data";
import MwoSticker from "../components/mwo-sticker";
import { useGetJobTaskMwoStickers } from "../api/queries/mwos";
import Badge from "../components/badge";
import WorkflowMwoPreviewDrawer from "./workflow-mwopreview";
import Button from "../components/buttons/button";

export default function JobWorkflowTaskDrawer({
  jobId, 
  jobWorkflowId, 
  onHide
}) {
  const [currentMinutes, setCurrentMinutes] = useState(null);
  const [mwo, setMwo] = useState(null);

  const taskQuery = useGetJobWorkflowTask(jobId, jobWorkflowId);
  const task = taskQuery.data;

  const partsQuery = useGetJobWorkflowTaskParts(jobId, jobWorkflowId);
  const parts = partsQuery.data;

  const productionEmployeesQuery = useGetProductionEmployees(task?.role_id, "Active", {
    enabled: (task && task.type == "task" && task.role_id != null) ? true:false
  })
  const mwoStickersQuery = useGetJobTaskMwoStickers(jobId, jobWorkflowId)
  const statusesQuery = useGetStatuses("tasks");

  const updateWorkflowTaskAssigned = useWorkflowJobUpdateTaskProp(jobId, jobWorkflowId, "assigned")
  const updateWorkflowTaskStatus = useWorkflowJobUpdateTaskProp(jobId, jobWorkflowId, "status_id")
  const resolveWorkflowTaskProblem = useWorkflowJobResolveTaskProblem(jobId, jobWorkflowId, task?.problems?.length ? task.problems[task.problems.length-1].id : null)

  useEffect(
    () => {
      if (!task) return;

      if (task.started_date && !task.completed_date)
        setCurrentMinutes(differenceInMinutes(new Date(), parseISO(task.started_date)));
    }, 
    [task]
  ); 

  return (
    <DrawerTemplateData
      icon={faClipboardList}
      title={"Job Workflow Task"}
      drawerClassName="max-w-[500px]"
      query={taskQuery}
      onHide={onHide}
    >
      {task &&
        <div className="space-y-1">
          <h2 className="font-bold text-center p-2 bg-purple-100 rounded">
            {task.job_title}
          </h2>
          <BoardField
            type="label"
            label="Task"
            value={task.name}
          />
          <BoardField
            type="label"
            label="Type"
            value={
              task.type=="check" ? "Decision" : 
              task.type=="delay" ? "Time Delay" : 
              "Task"
            }
          />         
          
          {task.type=="delay" && 
            <BoardField
              type="label"
              label="Delay"
              value={task.delay_time + " Hours"}
            />
          }        
          {task.role && 
            <BoardField
              type="label"
              label="Skill"
              value={task.role.replace("Production - ", "")}
            />
          }     
          {(task.type=="task" && productionEmployeesQuery.data) && 
            <BoardField
              type="checkboxes"
              checkboxesProps={{
                itemsQuery: productionEmployeesQuery,
                idField: "id",
                labelField: "name",
                direction: "vertical",
              }}
              label="Assigned To"
              value={(task.users || []).map(u => u.user_id).join(",")}
              saveMutation={updateWorkflowTaskAssigned}
            />          
          }

          <BoardField
            type="select"
            label="Status"
            value={task.status_id || ""}
            info={task.status_date ? `Last updated on ${formatDateTime(task.status_date)} by ${task.status_user || "?"}`:""}
            selectProps={{
              itemsQuery: statusesQuery,
              labelField: "title"
            }}
            saveMutation={updateWorkflowTaskStatus}
          />

          {(task.status_id == TaskStatuses.problem.id) && 
            <BoardFields label="Task Problem" className="mt-2">
              {task.problems.length > 0 && 
                <>
                  <div className="text-red-500 text-xs">
                    {task.problems[task.problems.length-1].problem}
                  </div>
                  <div className="text-gray-500 text-xs">
                    Added by <strong>{task.problems[task.problems.length-1].added_user_name}</strong> on <strong>{formatDateTime(task.problems[task.problems.length-1].added_date)}</strong>
                  </div>
                </>
              }
              <BoardField
                type="textarea"
                label="Resolution"
                saveMutation={resolveWorkflowTaskProblem}
              />
            </BoardFields>
          }     

          {(task.type == "task") && 
            <BoardFields label="Parts" className="my-2" collapsible collapsed={true}>
              <div className="grid gap-1">
                {task.parts.map(part => (
                  <div key={part.id} className="flex items-center justify-between gap-1 border rounded p-1 text-xs">
                    <div className="flex items-center gap-1">
                      <FontAwesomeIcon icon={part.exists_ind==1 ? faThumbsUp:faThumbsDown} fixedWidth className={part.exists_ind==1 ? "text-green-500":"text-red-500"} />
                      {part.part}
                      {part.decoration_type && 
                        <Badge size="tiny" variant="light" value={part.decoration_type.toUpperCase()} />
                      }
                      {part.material_part_title && 
                        <Badge size="tiny" variant="light" value={part.material_part_title} />
                      }
                    </div>                          
                  </div>
                ))}
              </div> 
            </BoardFields>
          }

          {(task.type == "task") && 
            <BoardFields label="MWOs" className="my-2" collapsible collapsed={true}>
              {/* <BoardField
                type="label"
                label="MWO"
                value={task.mwo.title}
              /> */}
              <div className="grid gap-1">
                {task.mwos && task.mwos.map(mwo => (
                  <div key={mwo.id} className="flex items-center justify-between gap-1 border rounded p-1 text-xs">
                    <div className="flex items-center gap-1">
                        <Button
                          key={mwo.id}
                          variant="light"
                          size="tiny"
                          onClick={() => setMwo(mwo)}
                        >
                          {mwo.title}
                        </Button>
                    </div>                            
                  </div>
                ))}
              </div>  
 
              {mwoStickersQuery.data && 
                <div className="grid gap-2 mt-2">
                  {mwoStickersQuery.data.map((mwo,index) => (
                    <div key={index}>
                      <MwoSticker
                          mwo={mwo}
                          shape={mwo.shape}
                          type={mwo.type}
                          style={{
                              margin: "0px",
                              padding: "0px"
                          }}
                      />                      
                    </div>
                  ))}

                  {mwoStickersQuery.data.length == 0 && 
                    <div className="text-gray-400 px-1 text-sm">No MWOs Found</div>
                  }
                </div>
              }
            </BoardFields>
          }

          {task.type == "task" && 
            <BoardFields label="Schedule" className="my-2">
              <BoardField
                type="label"
                label="Target Day"
                value={task.target_day}
              />

              {task.completed_date &&
                <BoardField
                  type="label"
                  label="Actual Day"
                  value={task.actual_day}
                />
              }
            </BoardFields>
          }

          {task.type == "task" && 
            <BoardFields label="Production Time" className="my-2">
              <BoardField
                type="label"
                label="Setup Time"
                value={task.production_setup}
                suffix="Minutes"
              />
              <BoardField
                type="label"
                label="Formula"
                value={task.production_estimateformula}
              />
              <BoardField
                type="label"
                label="Calculation"
                value={task.production_estimatecalc}
              />              
              <BoardField
                type="label"
                label="Estimate"
                value={task.production_estimate}
                suffix="Minutes"
              />

              {task.started_date &&
                <BoardField
                  type="label"
                  label="Started"
                  value={formatDateTime(task.started_date) + " by " + (task.started_user || "?")}
                />
              }
              {(task.started_date && !task.completed_date) &&
                <BoardField
                  type="label"
                  label="In-Progress"
                  value={currentMinutes}
                  suffix={"Minutes"}
                />
              }              
              {task.completed_date &&
                <BoardField
                  type="label"
                  label="Completed"
                  value={formatDateTime(task.completed_date) + " by " + (task.completed_user || "?")}
                />
              }   
              {(task.started_date && task.completed_date) &&
                <BoardField
                  type="label"
                  label="Actual"
                  highlight={task.production_actual <= task.production_estimate}
                  error={task.production_actual > task.production_estimate}
                  value={task.production_actual}
                  suffix={"Minutes"}
                />
              }                           
              
            </BoardFields>
          }

          {(task.type == "delay" && task.started_date && !task.completed_date) && 
            <>
              <BoardField
                type="label"
                label="Started"
                value={formatDateTime(task.started_date) + " by " + (task.started_user || "?")}
              />            
              <BoardField
                type="label"
                label="In-Progress"
                highlight={(currentMinutes/60) <= task.delay_time}
                error={(currentMinutes/60) > task.delay_time}
                value={currentMinutes}
                suffix="Minutes"
              />
              <BoardField
                type="label"
                label="Completion"
                value={formatDateTime(addHours(parseISO(task.started_date), parseFloat(task.delay_time)))}
              />              
            </>
          }

          {(task.resources && task.resources.length > 0) && 
            <BoardFields label="Task Resources" className="!p-0 my-2">
                {task.resources.map((resource,index) => (
                  <div key={index} className="border-t p-1 text-sm">
                    <a href={resource.url || (storage.root + resource.file_url)} target="_blank">
                      {resource.title}
                      <FontAwesomeIcon icon={faExternalLinkSquareAlt} style={{marginLeft: "5px"}} />
                    </a>
                  </div>
                ))}
            </BoardFields>
          }          
        </div> 
      }

      {mwo && 
        <WorkflowMwoPreviewDrawer
            mwoId={mwo.id}
            stickers={mwo.stickers}
            onHide={() => setMwo(null)}
        />
      }       
    </DrawerTemplateData>  
  )
}