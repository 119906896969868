import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetUser = (userId, options={}) => {  
  return useQuery({ 
    queryKey: ["users", ""+userId], 
    queryFn: ({signal}) => query("users/user", {id: userId}, signal),
    ...options
  });
};

export const useGetProductionEmployees = (roleId, roleStatus, options={}) => {  
  console.log("useGetProductionEmployees", roleId, options)
  return useQuery({ 
    queryKey: ["employees", "production", roleId], 
    queryFn: ({signal}) => query("users/production", {role_id: roleId, role_status: roleStatus}, signal),
    ...options
  });
};
