import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutate } from '../api';

export const useCreateMwoForWorkflowItem = (workflowItemId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return mutate("mwos/create", {
                workflowitem_id: workflowItemId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["mwos"]);
            queryClient.invalidateQueries(["workflows"]);
        },    
  })
};

export const useCreateMwoForWorkflowPart = (workflowItemId, partId, options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: () => {
            return mutate("mwos/create", {
                workflowitem_id: workflowItemId,
                part_id: partId
            })
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["mwos"]);
            queryClient.invalidateQueries(["workflows"]);

            if (onSuccess)
                onSuccess(data, variables, context);              
        },    
        ...otherOptions
  })
};

export const useSetMwoProp = (mwoId, prop) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (value) => {
            return mutate("mwos/mwo/setprop", {
                mwo_id: mwoId,
                prop,
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["mwos"]);
        },    
  })
};

export const useSetMwoRowProp = (mwoId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({prop, value}) => {
            return mutate("mwos/mwo/setprop", {
                mwo_id: mwoId,
                prop,
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["mwos"]);
        },    
  })
};