import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetProject = (projectId) => {  
  return useQuery({ 
    queryKey: ["projects", ""+projectId], 
    queryFn: ({signal}) => query("projects/project", {id: projectId}, signal) 
  });
};

export const useGetProjectSigns = (projectId) => {  
  return useQuery({ 
    queryKey: ["signs", "project"+projectId], 
    queryFn: ({signal}) => query("projects/project/signs", {id: projectId}, signal) 
  });
};