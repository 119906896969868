import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"
import { useParams, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faBorderAll, faPlus, faImages, faClone, faTablets, faPaperclip, faDesktop, faListAlt, faCreditCard, faFont, faTruck, faPaintBrush, faHammer, faRuler, faWrench, faExchangeAlt, faClipboardList, faScrewdriver } from '@fortawesome/free-solid-svg-icons'
import { Badge, Card } from "react-bootstrap"

import Button from "../components/buttons/button"
import Content from "../components/content"
import PageHeader from "../components/page-header";
import { Accordion, AccordionItem } from "../components/accordion";

import Loading from "../components/loading";
import GoogleImage from "../components/google-image"

import PanelProductProps from "../panels/panel-product-props";
import PanelProductParts from "../panels/panel-product-parts";
import PanelProductSizes from "../panels/panel-product-sizes";
import PanelProductStyles from "../panels/panel-product-styles";
import PanelProductShipping from "../panels/panel-product-shipping";
import PanelProductInstallation from "../panels/panel-product-installation";
import PanelProductRelated from "../panels/panel-product-related";
import PanelProductMWOs from "../panels/panel-product-mwos";
import PanelProductProduction from "../panels/panel-product-production";
import PanelProductPricing from "../panels/panel-product-prices";
import PanelProductContent from "../panels/panel-product-content";
import PanelProductBuilder from "../panels/panel-product-builder";
import PanelProductImages from "../panels/panel-product-images";
import PanelProductResources from "../panels/panel-product-resources";

import ProductPermitsPanel from "./panels/panel-product-permits";
import ProductTasksPanel from "./panels/panel-product-tasks";
import ProductMWOsPanel from "./panels/panel-product-mwos";
import ProductDrawer from "../drawers/drawer-product";

import { Actions, Permissions, userHasPermission } from "../common/services/auth";
import { postData } from "../common/services/server"
import PageLayout from "../components/page-layout";

const Layout = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;

  > div:nth-child(2) {
    background-color: #ccc;
    padding: 5px;
  }
`
const Product = styled.h2`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  background-color: white;
  padding: 10px 20px 10px 10px;
  cursor: pointer;
  font-size: 100%;
  margin: 0px;

  > div {
    display: flex;
    align-items: center;  
    gap: 10px;
  }

  &:hover {
    background-color: #eee;
  }

  &.selected {
    background-color: #ccc;

    &:hover {
      background-color: #ccc;
    }      
  }
`

function ProductsPage(props) {
  const history = useHistory();
  let { tab } = useParams();

  if (!tab) tab = "lit-channel-letters-and-logos";

  const [loading, setLoading] = useState("");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productId, setProductId] = useState(null);
  const [product, setProduct] = useState(null);

  const [tabs, setTabs] = useState([]);
  const [addProduct, setAddProduct] = useState(false);
  const [cloneProduct, setCloneProduct] = useState(false);

  useEffect(
    () => {
      setLoading("products");

      postData("products/data2", {},
        function(result) {
          setCategories(JSON.parse(JSON.stringify(result.categories)));

          result.categories.push({id:-1, name:"tasks", title: "Production Tasks", secondary:true});
          result.categories.push({id:-1, name:"mwos", title: "MWO Stickers", secondary:true});
          result.categories.push({id:-1, name:"permits", title: "Permit Drawings", secondary:true});

          setProducts(result.products);          
          setTabs(result.categories);
        },
        function(error) {
            alert("Error loading products")
        },
        function() {
          setLoading("");
        }
      );
    }, 
    []
  );

  function onProduct(prod) {
    setProductId(prod.id);
    setProduct(null);
    setLoading("product");

    postData("products/productdata", {
      product_id: prod.id
    },
      function(result) {
          setProduct(result);
      },
      function(error) {
          alert("Error loading product data")
      },
      function() {
        setLoading("");
      }
    );      
  }

  function reloadProduct() {
    onProduct(product);
  }

  const panels = [
    {name:"properties", title:"Properties", icon:faListAlt, panel: PanelProductProps},
    {name:"images", title:"Images", icon:faImages, panel: PanelProductImages},
    {name:"parts", title:"Parts", icon:faTablets, panel: PanelProductParts},
    {name:"sizes", title:"Sizes", icon:faRuler, panel: PanelProductSizes, hidden: product && product.type == "Part"},
    {name:"styles", title:"Styles", icon:faPaintBrush, panel: PanelProductStyles, hidden: product && (product.sign_ind==0 || product.type == "Part")},
    {name:"shipping", title:"Shipping", icon:faTruck, panel: PanelProductShipping, hidden: product && product.type == "Part"},
    {name:"install", title:"Installation", icon:faHammer, panel: PanelProductInstallation, hidden: product && product.type == "Part"},
    {name:"related", title:"Related Products", icon:faExchangeAlt, panel: PanelProductRelated, hidden: product && product.type == "Part"},
    {name:"builder", title:"Sign Builder", icon:faDesktop, panel: PanelProductBuilder, hidden: product && product.type == "Part"},
    {name:"mwos", title:"Production MWOs", icon:faBorderAll, panel: PanelProductMWOs, hidden: product && product.type == "Part" && product.production_method == "Outsite Order"},
    {name:"production", title:"Production Tasks", icon:faWrench, panel: PanelProductProduction, hidden: product && product.type == "Part" && product.production_method == "Outsite Order"},
    {name:"prices", title:"Pricing", icon:faCreditCard, panel: PanelProductPricing, hidden: product && product.type == "Part"},
    {name:"content", title:"Content", icon:faFont, panel: PanelProductContent},
    {name:"resources", title:"Resources", icon:faPaperclip, panel: PanelProductResources},
  ]
        
  return (
    <PageLayout>
      <PageHeader 
        title="Product Manager"
        help="products"
        tab={tab}
        tabUrl={"/products/{tab}"}
        tabs={tabs}
        onTab={(tab) => {
          history.push("/products/" + tab);
          setProduct(null);
        }}
      >
        {userHasPermission(Permissions.Products, Actions.Create) &&
          <>
            <Button onClick={() => setAddProduct(true)} variant="outline-success" size="sm">
                <FontAwesomeIcon icon={faPlus} />
                &nbsp;
                Create Product
            </Button>
            <Button onClick={() => setCloneProduct(true)} variant="outline-primary" size="sm">
                <FontAwesomeIcon icon={faClone} />
                &nbsp;
                Clone Product
            </Button>
          </>
        }
      </PageHeader>

      <Content permission={Permissions.Products} padding={1}>
        {loading == "products" ? 
          <Loading />
        : tab == "permits" ?
          <ProductPermitsPanel
            products={products}            
          />
        : tab == "tasks" ?
          <ProductTasksPanel
            products={products}
            categories={categories}
          />
        : tab == "mwos" ?
          <ProductMWOsPanel
            products={products}
            categories={categories}
          />          
        : 
          <Layout>
            <div>
              {products.filter(p => p.category_name == tab).map(prod => (
                <Product 
                  key={prod.id} 
                  className={productId && prod.id == productId ? "selected" : ""}
                  onClick={() => onProduct(prod)}
                >
                  <div>
                    <GoogleImage 
                      src={"products/" + prod.name + "/icon.jpg?" + (new Date().getTime())} 
                      style={{ maxHeight: "40px" }} 
                    />

                    <div>
                      {prod.part_number && 
                        <div className="text-sm text-gray-400">{prod.part_number}</div>
                      }
                      {prod.title} 
                    </div>
                  </div>
                  <div>
                    {(prod.status == "Draft" || prod.status == "Internal") && 
                      <Badge variant="secondary">
                        {prod.status}
                      </Badge>
                    }
                  </div>
                </Product>
              ))}
            </div>
            <div>
              {loading == "product" ? 
                <Loading label="Loading Product Data..." color="black" size="sm" />
              : product ?              
                <Accordion>
                  {panels.filter(p => !p.hidden).map(panel => {
                    const Component = panel.panel;

                    return (
                      <AccordionItem 
                        key={panel.name}
                        title={
                          <div>
                            <FontAwesomeIcon icon={panel.icon} fixedWidth />
                            &nbsp;
                            {panel.title}
                          </div>  
                        }
                      >
                        <Component
                          product={product}
                          products={products}
                          categories={tabs}
                          onChange={(productData) => {
                            // merge updated data
                            if (productData) {
                              const idx = products.findIndex(p => p.id == product.id);

                              if (idx != -1) {
                                setProducts(produce(draft => {
                                  for (const prop of Object.keys(productData)) {
                                    draft[idx][prop] = productData[prop];
                                  }  
                                }));

                                setProduct(produce(draft => {
                                  for (const prop of Object.keys(productData)) {
                                    draft[prop] = productData[prop];
                                  }  
                                }));
                              }                                
                            }
                          }}
                          onReload={() => {
                            reloadProduct()
                          }}
                        />
                      </AccordionItem>
                    )
                  })}                                                                                                                                       
                </Accordion>
              :
                <p style={{textAlign: "center", margin: "50px"}}>
                  No Product Selected
                </p>
              }
            </div>
          </Layout>
        }
      </Content>

      {addProduct &&
        <ProductDrawer
          product={{
            
          }}
          onSaved={(productData) => {
            setProducts(produce(draft => {
              draft.push(productData);
            }));
            onProduct(productData);
            setAddProduct(false);
          }}
          onHide={() => setAddProduct(false)}
        />
      }
      {cloneProduct &&
        <ProductDrawer
          clone={true}
          products={products}
          product={{
            
          }}
          onSaved={(productData) => {
            /*
            setProducts(product(draft => {
              draft.push(productData);
            }));

            const tab = tabs.find(t => t.id == productData.category_id);
            history.push("/products/" + tab);
            */

            //onProduct(productData);

            const tab = tabs.find(t => t.id == productData.category_id);

            if (tab)
              window.location = "/products/" + tab.name;
            else 
              window.location.reload();

            setCloneProduct(false);
          }}
          onHide={() => setCloneProduct(false)}
        />
      }      
    </PageLayout>
  );
}

export default ProductsPage;