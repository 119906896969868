import React, { useState, useEffect, useRef, createFactory } from "react";
import styled from "styled-components"

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup'

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faPlus, faExclamationCircle, faSpinner, faThumbtack, faRandom } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import SignSelect from "../components/fields/sign-select"
import CurrencyField from "../components/fields/field-currency"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import GoogleImage from "../components/google-image"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

const Header = styled.h3`
    margin: 5px 0px;
    font-size: 15px;
`
const Sign = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;

    img { margin-right: 10px; }
`

function OrderChangeSignDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  const [sign, setSign] = useState(null)

  useEffect(
    () => {
    }, 
    [props.item]
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("orders/changesign?id=" + props.item.id + "&order_id=" + props.item.order_id, removeNulls(formData), 
        function(response) {
          props.onChange(response);
        },
        function(error) {
          alert("An error occured updating the order.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;

    setFormData(produce(draft => {
      draft[field] = event.target.value;
    }));
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faRandom} />
            &nbsp;
            Change Order Sign
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
            <Header>Existing Sign</Header>
            <Sign>
                <GoogleImage 
                    root 
                    src={props.item.preview_url} 
                    style={{
                        maxWidth: "125px",
                        maxHeight: "75px"
                    }}
                />
                <div>
                    Sign Design #{props.item.sign_id}
                </div>
            </Sign>

            <Header>Replacement Sign</Header>

            <InputGroup className="mb-2">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        Select Sign
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <SignSelect
                    name="sign_id"
                    onChange={(sign) => {
                        console.log(sign)
                        setSign(sign);
                        setFormData({
                            sign_id: sign.id,
                            title: "Sign Design #" + sign.id,
                            cost: sign.estimate,
                            discount: sign.discount,
                            shipping: sign.shipping
                        })
                    }}
                />
            </InputGroup>

            {sign && 
                <>
                    <Sign>
                        <GoogleImage 
                            root 
                            src={sign.preview_url} 
                            style={{
                                maxWidth: "125px",
                                maxHeight: "75px"
                            }}
                        />
                        <div>
                            Sign Design #{sign.id}
                        </div>
                    </Sign>
                    
                    <TextField 
                            name="title" 
                            label="Title" 
                            required
                            value={formData.title} 
                            onChange={(e) => handleFormChange(e)} 
                        />  
                    <TextField 
                            name="description" 
                            label="Reason" 
                            value={formData.description} 
                            onChange={(e) => handleFormChange(e)} 
                        />  
                    <Form.Row style={{maxWidth: "475px"}}>
                        <Form.Group as={Col}>
                            <CurrencyField 
                                name="cost" 
                                label="Cost" 
                                labelBold={false}
                                required
                                value={formData.cost} 
                                onChange={(e) => handleFormChange(e)} 
                            />  
                        </Form.Group>
                        <Form.Group as={Col}>
                            <CurrencyField 
                                name="discount" 
                                label="Discount" 
                                labelBold={false}
                                required
                                value={formData.discount} 
                                onChange={(e) => handleFormChange(e)} 
                            />  
                        </Form.Group>
                        <Form.Group as={Col}>
                            <CurrencyField 
                                name="shipping" 
                                label="Shipping" 
                                labelBold={false}
                                required
                                value={formData.shipping} 
                                onChange={(e) => handleFormChange(e)} 
                            /> 
                        </Form.Group>
                    </Form.Row>
                </>
            } 
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Change Sign" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default OrderChangeSignDrawer;