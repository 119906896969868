import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import { FormControl } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas, faPlus, faProjectDiagram, faSpinner } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"

function AddVendorDrawer({
    part,
    onAdded,
    onHide
}) {
  const [loading, setLoading] = useState("vendors");
  const [vendors, setVendors] = useState([])
  const [newVendorName, setNewVendorName] = useState("");

  useEffect(() => {
        postData("parts/vendors", {}, 
            function(results) {
                setVendors(results);
            },
            function(error) {
                alert("An error occured loading the vendors.");
            },
            function() {
                setLoading("");
            }
        );
  }, []);

  function onCreateVendor() {
    setLoading("new")

    postData("parts/createvendor", {
      part_id: part.id,
      name: newVendorName
    },
      function(result) {
        onAdded(result);
        onHide();
      },
      function(error) {
          alert("Error creating vendor.")
      },
      function() {
        setLoading("");
      }
    );    
  }

  function onAddVendor(vendor) {
    setLoading("adding" + vendor.id)

    postData("parts/addvendor", {
      part_id: part.id,
      vendor_id: vendor.id
    },
      function(result) {
        onAdded(result);
      },
      function(error) {
          alert("Error adding vendor.")
      },
      function() {
        setLoading("");
      }
    );      
  }

  return (
      <>
        <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faGlobeAmericas} />
                &nbsp;
                Add Vendor
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading == "vendors" ? 
                    <Loading />
                :
                  <>
                    <InputGroup size="sm" className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>New Vendor:</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            value={newVendorName}
                            disabled={loading == "new"}
                            onChange={(e) => { 
                                setNewVendorName(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onCreateVendor();
                                }
                            }}
                        />          
                        <InputGroup.Append>
                            <Button
                            size="sm"
                            onClick={onCreateVendor} 
                            disabled={newVendorName.length == 0 || loading == "newvendor"}
                            variant="outline-success"
                            >
                                <FontAwesomeIcon icon={loading == "newvendor" ? faSpinner:faPlus} spin={loading == "newvendor"} />
                                &nbsp;
                                Create
                            </Button>        
                        </InputGroup.Append>
                    </InputGroup>    
                    <div style={{textAlign: "center"}}>
                        <strong>Existing Vendors</strong>
                    </div>                 
                    {vendors.map(vendor => {
                        const exists = part.vendors.filter(v => v.id == vendor.id).length > 0;
                        const busy = loading == ("adding"+vendor.id);
                        
                        return (
                            <div key={vendor.id} style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "2px",
                                marginBottom: "1px",
                                backgroundColor: exists ? "#eee" : "white"
                            }}>
                                <div>
                                    {vendor.name}
                                </div>

                                {!exists && 
                                    <Button
                                        size="sm"
                                        variant="outline-success"
                                        onClick={() => onAddVendor(vendor)}
                                        disabled={busy}
                                    >
                                        <FontAwesomeIcon icon={busy ? faSpinner:faPlus} spin={busy} />{' '}
                                        Add To Part
                                    </Button>
                                }
                            </div>
                        )
                    })}
                  </>
                }                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default AddVendorDrawer;