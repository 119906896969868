import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"
import { useHistory, Link } from "react-router-dom";

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell, 
    CurrencyCell,
    DateCell,
    LinkCell,
    UserCell,
    SortHeaderCell,
    SortTypes,
} 
from "../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"
import Paginator from "../components/paginator"
import ListField from "../components/fields/field-list"

import { postData } from "../common/services/server"
import { useBodyClass } from "../hooks/useBodyClass"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { dimensions } from "../settings/settings"
import { removeNulls, formatPrice, formatPriceList, formatDateTime } from "../helpers/utility"

const Report = styled.div`
    @media print{@page {size: landscape}}

    table.data {
        display: none;
    }

    @media print
    {    
        .fixedDataTableLayout_main {
            display: none;
        }
        .paginator {
            display: none;
        }
        table.data {
            display: block;
            font-size: 12px;
            
            th {
                background-color: #eee;
                border: 1px solid #ccc;
                padding: 3px;
            }
            td {
                border: 1px solid #ccc;
                padding: 3px;
                vertical-align: top;
            }
        }
    }
`

const Filters = styled.div`
    display: flex;
    margin-bottom: 8px;
    margin-top: -8px;

    > * {
        margin-right: 8px;
    }

    label {
        background-color: #eee;
        padding: 15px 10px;
        border-radius: 5px;
        margin: 0px;
        margin-right: 10px;
    }
    span {
        font-size: 10px;
    }
`

function PaymentsReport(props) {
    const history = useHistory();

    const windowDimensions = useWindowDimensions();

    const [data, setData] = useState([]);    
    const [totals, setTotals] = useState({});    
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({page:1,pages:1,total:0});
    const [sort, setSort] = useState({column: "payment_date", dir: SortTypes.DESC});
    const [filters, setFilters] = useState({
        company_id: 1,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    });

    useBodyClass(`noscroll`);

    function loadData(options={}) {
        setLoading(true);

        postData("reports/payments", removeNulls({ 
            ...filters,
            sort: sort.column,
            sortdir: sort.dir,
            page: page,
            perPage: filters.month && filters.year ? 1000 : 100,
            ...options
        }),
            function(result) {
                for (let record of result.data) {
                    if (record.status_id == 1 || record.status_id == 2)
                        record.notes = (record.notes || "") + "(Processing)";
                    else if (record.status_id == 14 || record.status_id == 66)
                        record.notes = (record.notes || "") + "(Void)";
                }

                setData(result.data);
                setPagination(result.pagination)

                const records = result.data
                    .filter(r => r.status_id != 1 && r.status_id != 2 && r.status_id != 14 && r.status_id != 66)
                    .map(payment => payment.amount || 0);

                if (records.length) {                    
                    setTotals({
                        amount: records.reduce((prev, next) => parseFloat(prev) + parseFloat(next))
                    })
                }
            },
            function(error) {
                alert("Error loading data")
            },
            function() {
                setLoading(false);
            }
        );
    }

    useEffect(
        () => {
            loadData();
        }, 
        [page, sort, filters]
    );

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }

    function onFilterChange(filter, value) {
        setFilters(produce(draft => {
            draft[filter] = value
        })); 
    }

    function onFilter() {
        loadData({page:1})
    }

    const years = [];

    for (let i=-5; i<5; i++) {
        const year = new Date().getFullYear() + i;

        years.push({id:year, title: year});
    }

    return (
        <>
            <PageHeader 
                title="Payments Report"
                help="paymentsreport"
            />
            <Content>
                {
                    loading
                ?
                    <Loading />
                :  
                    <Report>
                        <Filters>
                            <label>Filters:</label>
                            <div>
                                <span>Company</span>
                                <ListField 
                                    name="company_id" 
                                    value={filters.company_id} 
                                    list={[
                                        {id:1, title:"SignMonkey"},
                                        {id:2, title:"Paris Signs"},
                                    ]}
                                    onChange={(e) => {
                                        onFilterChange("company_id", e.target.value);
                                    }}
                                    groupClassName="nomargin"
                                    size="sm"
                                />
                            </div>
                            <div>
                                <span>Month</span>
                                <ListField 
                                    name="month" 
                                    value={filters.month} 
                                    list={[
                                        {id:1, title:"January"},
                                        {id:2, title:"February"},
                                        {id:3, title:"March"},
                                        {id:4, title:"April"},
                                        {id:5, title:"May"},
                                        {id:6, title:"June"},
                                        {id:7, title:"July"},
                                        {id:8, title:"August"},
                                        {id:9, title:"September"},
                                        {id:10, title:"October"},
                                        {id:11, title:"November"},
                                        {id:12, title:"December"},
                                    ]}
                                    onChange={(e) => {
                                        onFilterChange("month", e.target.value);
                                    }}
                                    groupClassName="nomargin"
                                    size="sm"
                                />
                            </div>
                            <div>
                                <span>Year</span>
                                <ListField 
                                    name="year" 
                                    value={filters.year} 
                                    list={years}
                                    onChange={(e) => {
                                        onFilterChange("year", e.target.value);
                                    }}
                                    groupClassName="nomargin"
                                    size="sm"
                                />
                            </div>
                        </Filters>
                        <Table
                            rowHeight={85}
                            rowsCount={data.length}
                            width={windowDimensions.width - dimensions.dataTableWidthOffset}
                            height={windowDimensions.height - dimensions.dataTableHeightOffset - 15}
                            headerHeight={50}
                            footerHeight={35}
                            touchScrollEnabled={true}
                            rowClassNameGetter={(rowIndex) => {
                                const statusId = data[rowIndex].status_id;

                                if (statusId == 1 || statusId == 2) 
                                    return "Pending"
                                else if (statusId == 14 || statusId == 66)
                                    return "Invalid"
                            }}
                        >
                            <Column
                                header={
                                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                        Order
                                    </SortHeaderCell>
                                }
                                fixed
                                columnKey="order_id"
                                cell={
                                    <LinkCell data={data} onClick={(order) => history.push("/orders/order/" + order.order_id)} />
                                }
                                width={105}
                            />
                            <Column
                                header={
                                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                        Order Date
                                    </SortHeaderCell>
                                }
                                columnKey="order_date"
                                cell={({rowIndex, ...props}) => (
                                    <TextCell 
                                        data={data}
                                        rowIndex={rowIndex}
                                        {...props}
                                        className={data[rowIndex].payment_diffmonth_ind == 1 ? "Invalid":""}
                                    />
                                )}
                                width={135}
                            />  
                            <Column
                                header={
                                    <Cell>
                                        Status
                                    </Cell>
                                }
                                columnKey="status"
                                cell={<TextCell data={data} />}
                                width={125}
                            />
                            {!filters.company_id && 
                                <Column
                                    header={
                                        <Cell>
                                            Company
                                        </Cell>
                                    }
                                    columnKey="company"
                                    cell={<TextCell data={data} />}
                                    width={125}
                                />
                            }                            
                            <Column
                                header={<Cell>Customer</Cell>}                        
                                columnKey="user_name"
                                cell={({rowIndex, ...props}) => (
                                    <UserCell 
                                        userId={data[rowIndex].user_id}
                                        userName={data[rowIndex].user_name}
                                        userEmail={data[rowIndex].user_email}
                                        onUser={() => history.push("/users/user/" + data[rowIndex].user_id)}
                                    />
                                )}
                                width={225}
                            />
                            <Column
                                header={<Cell>Type</Cell>}
                                columnKey="type"
                                cell={<TextCell data={data} />}
                                width={100}
                            />
                            <Column
                                header={<Cell>Info</Cell>}
                                columnKey="info"
                                cell={<TextCell data={data} />}
                                width={100}
                            />                            
                            <Column
                                header={<Cell>Amount</Cell>}
                                columnKey="amount"
                                cell={<CurrencyCell data={data} />}
                                footer={<Cell align="right">{formatPrice(totals.amount)}</Cell>}
                                width={125}
                            />
                            <Column
                                header={
                                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                        Payment Date
                                    </SortHeaderCell>
                                }
                                columnKey="payment_date"
                                cell={<TextCell data={data} />}
                                width={135}
                            />
                            <Column
                                header={<Cell>Notes</Cell>}
                                columnKey="notes"
                                cell={<TextCell data={data} />}
                                width={250}
                            />                                                        
                        </Table>
                        <Paginator
                            {...pagination}
                            item="Payment"
                            items="Payments"
                            onPage={(page) => {
                                console.log("page", page)
                                setPage(page);
                            }}
                        />
                        <table className="data">
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Order Date</th>
                                    {!filters.company_id && <th>Company</th>}
                                    <th>Customer</th>
                                    <th>Type</th>
                                    <th>Info</th>
                                    <th>Amount</th>
                                    <th>Payment Date</th>
                                    <th>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row,index) => {
                                    let className = "";

                                    if (row.status_id == 1 || row.status_id == 2) 
                                        className = "Pending"
                                    else if (row.status_id == 14 || row.status_id == 66)
                                        className = "Invalid"

                                    return (
                                        <tr key={index} index={index} className={className}>
                                            <td>{row.order_id}</td>
                                            <td>{row.order_date}</td>
                                            {!filters.company_id && <td>{row.company}</td>}
                                            <td>
                                                <div>{row.user_email}</div>
                                            </td>
                                            <td>{row.type}</td>
                                            <td>{row.info}</td>
                                            <td>{formatPrice(row.amount)}</td>
                                            <td>{row.payment_date}</td>
                                            <td>{row.notes}</td>
                                        </tr> 
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    {!filters.company_id && <td>&nbsp;</td>}
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>{formatPrice(totals.amount)}</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tfoot>
                        </table>
                    </Report>
                }
            </Content>
        </>
    );
}

export default PaymentsReport;