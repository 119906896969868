import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetNewTask = (params, options={}) => {
  return useQuery({ 
    queryKey: ["tasks", "new", params], 
    queryFn: ({signal}) => query("tasks/newtask", params, signal),
    ...options
  });
}
