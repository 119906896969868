import React from "react"
import clsx from 'clsx';

export default function Switch({
    label,
    checked,
    onChange,    
}) {
    return (
        <label class="relative inline-flex items-center cursor-pointer m-0">
            <input type="checkbox" value="" checked={checked} className="sr-only peer" onChange={(e) => onChange(e, e.target.checked)} />
            <div className={clsx(
                "w-11 h-6",
                "bg-gray-200 rounded-full",
                "peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600",
            )}></div>
            <span className="ms-1 text-sm text-black">{label}</span>
        </label>
    );
}