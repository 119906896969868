import React from "react";

function PanelDescription({
  children
}) {

  return (
    <div style={{
        fontSize: "10px",
        marginBottom: "10px",
        backgroundColor: "#f5f3d4",
        borderRadius: "3px",
        padding: "8px",
        color: "#666",
    }}>
        {children}
    </div>
  );
}

export default PanelDescription;