import React, { useState, useEffect, useCallback } from "react";
import Form from 'react-bootstrap/Form';

import { Editor } from '@tinymce/tinymce-react';

import FieldLabel from "./label"

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import EmailSectionSelectDialog from "../../dialogs/dialog-emailsectionselect"
import EmailSectionDialog from "../../dialogs/dialog-emailsection"
import Loading from "../loading";

import settings from '../../settings'
import { postExternalData } from "../../common/services/server"
import { postData } from "../../common/services/server"

function FieldHtml(props) {
    const [editor, setEditor] = useState(null);
    const [busy, setBusy] = useState(false);
    const [section, setSection] = useState(null);
    const [select, setSelect] = useState("");

    useEffect(
      () => {
        console.log(section, props.sectionData)
        if (section && section.name != "manage") {
          setBusy(true);
          
          postExternalData(settings.SITE_API_URL + "emails/placeholder", {section: section.name, ...props.sectionData},
              function(result) {
                window.tinymce.get(props.name).insertContent(result.content);
              },
              function(error) {
                  console.log(error);
                  window.alert("Error formatting section content")
              },
              function() {
                setBusy(false)
                setSection(null);
              }
          );
        }
      }, 
      [section]
    );

    return (
      <>
        <Form.Group style={props.groupStyle}>
            {props.label && <FieldLabel required={props.required} bold={props.labelBold}>{props.label}</FieldLabel>}

            <div style={{position:"relative"}}>
              <Editor
                  id={props.name}
                  apiKey="punene8u8w2z4altmd87mui0n4kvmyyyrsn8eszfexnmr0qe"
                  licenseKey="gpl"
                  value={props.value}
                  init={{
                      setup: (editor) => {
                        setEditor(editor);

                        editor.ui.registry.addButton('box', {
                            icon: 'unselected',
                            tooltip: 'Insert Padded Box',
                            onAction: function (_) {
                              editor.insertContent("<table width='100%' border='0'><tr><td style='padding: 10px;'></td></tr></table>");
                            },
                        });
                        
                        editor.ui.registry.addMenuButton('sections', {
                            text: 'Sections',
                            fetch: (callback) => {
                                // var items = [
                                //   {
                                //     type: 'menuitem',
                                //     text: 'Sign Design...',
                                //     onAction: () => setSelect("sign")                                   
                                //   },
                                //   {
                                //     type: 'menuitem',
                                //     text: 'User Recent Sign Design',
                                //     onAction: () => setSection("[user_last_sign_design]")
                                //   },
                                //   {
                                //     type: 'menuitem',
                                //     text: 'User 5 Recent Sign Designs',
                                //     onAction: () => setSection("[user_last_5_sign_design]")
                                //   },
                                //   {
                                //     type: 'menuitem',
                                //     text: 'Product...',
                                //     onAction: () => setSelect("product")                                   
                                //   },
                                //   {
                                //     type: 'menuitem',
                                //     text: 'Purchase Code...',
                                //     onAction: () => setSelect("coupon")                                   
                                //   },   
                                //   {
                                //     type: 'menuitem',
                                //     text: 'HTML Test',
                                //     onAction: () => setSection("[html_test]")                                   
                                //   },                                
                                // ];
                  
                                postData("emails/sections", {},
                                  function(sections) {
                                    let items = sections.map(s => {
                                      return {
                                        type: 'menuitem',
                                        text: s.title,
                                        onAction: () => {
                                          if (s.type != "html")
                                            setSelect(s.type)
                                          else
                                            setSection(s)
                                        }
                                      }
                                    });

                                    items.push({
                                        type: 'separator',
                                    });
                                    items.push({
                                      type: 'menuitem',
                                      text: 'Manage Sections',
                                      onAction: () => {
                                        setSection({
                                          name: "manage"
                                        })
                                      }
                                    });

                                    callback(items);
                                  },
                                  function(error) {
                                      alert("Error loading email sections.")
                                  }
                                );

                            }
                        });

                        editor.ui.registry.addMenuButton('placeholders', {
                            text: 'Placeholders',
                            fetch: (callback) => {
                                const items = [];

                                const placeholders = [
                                  "[shopping_cart]",
                                  "[shopping_cart_url]",
                                  "[shopping_cart_button]",
                                ]
                                
                                for (const placeholder of placeholders) {
                                  items.push({
                                    type: 'menuitem',
                                    text: placeholder,
                                    onAction: function (_) {
                                      editor.insertContent(placeholder);
                                    },                                      
                                  });
                                }

                                callback(items);
                            }
                        });                        
                      },
                      height: props.height || 300,
                      menubar: false,
                      plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code textcolor'
                      ],
                      toolbar:
                          'fullscreen code | undo redo | formatselect | fontsizeselect | bold italic | forecolor backcolor | link | image | table | box | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | sections | placeholders ',
                      fontsize_formats: "8px 10px 12px 14px 18px 20px 24px 30px 36px",
                      table_sizing_mode: 'relative',
                      table_toolbar: '',
                      object_resizing : 'img',
                      image_uploadtab: true,
                      images_upload_url: settings.API_URL + 'emails/upload',
                      browser_spellcheck: true,
                      contextmenu: false
                  }}             
                  onEditorChange={ (content, editor) => {
                      props.onChange({
                          target: {
                              name: props.name,
                              value: content
                          }
                      })
                  }}
              />
              {busy && 
                <div style={{position:"absolute", left:"0px", top:"0px", right:"0px", bottom:"0px", backgroundColor:"rgba(0,0,0,.1)", zIndex:99, paddingTop:"50px"}}>
                  <Loading size="sm" />
                </div>
              }
            </div>
        </Form.Group>
        {select != "" && 
          <EmailSectionSelectDialog
              show={true}
              type={select}
              onInsert={(content) => {
                window.tinymce.get(props.name).insertContent(content);
                setSelect("");
              }}
              onHide={() => setSelect("")}
          />
        }
        {(section && section.name == "manage") && 
          <EmailSectionDialog
              show={true}
              onInsert={(content) => {
                window.tinymce.get(props.name).insertContent(content);
                setSelect("");
              }}
              onHide={() => setSection(null)}
          />
        }        
      </>
    )
}

export default FieldHtml