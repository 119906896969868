import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faSyncAlt, faSpinner, faHeart } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import NumberField from "../components/fields/field-number"
import TextAreaField from "../components/fields/field-textarea"
import BooleanField from "../components/fields/field-boolean"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'
import Loading from "../components/loading";

function ShapeDrawer(props) {
  const formRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [isProcessing, setIsProcessing] = useState("");
 
  const [formData, setFormData] = useState(props.shape);
  const [sizes, setSizes] = useState([]);

  const [validated, setValidated] = useState(false);

  const isCustom = formData && (formData.code == 'CUSTOM' || formData.style_id == 99);

  useEffect(() => {

    console.log("setting shape data", props.shape, props.shape.category_ids ? props.shape.category_ids.split(",") : "")
    if (props.shape) {
      setIsLoading(true);

      postData("shapes/shapedata", { 
         shape_id: props.shape.id,
         size: props.size,
         sizes_ind: 1
      },
          function(result) {
            setFormData({
              ...result,
              category_ids: result.category_ids ? result.category_ids.split(",") : []
            });
            setSizes(result.sizes);
          },
          function(error) {
              alert("Error loading shape data");
          },
          function () {
            setIsLoading(false);
          }
      ); 
    }

    postData("categories/data", { 
        type: "shape"
    },
        function(result) {
            setCategories(result)
        },
        function(error) {
            console.log(error)
        }
    );
  }, [props.shape]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing("save");

      postData("shapes/save", removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the shape.");
        },
        function() {
          setIsProcessing("");
        }
      );
    }
  }

  function handleFormChange(event) {
      if (event.persist) event.persist();

      const prop = event.target.name;
      const value = event.target.value;

      console.log("handleFormChange", prop, value, event.target.checked)

      setFormData(produce(draft => {
        if (prop == "category_id") {
            const index = draft.category_ids.indexOf(value);

            console.log("index", index)
            console.log("category_ids", draft.category_ids)
            if (index != -1)
                draft.category_ids.splice(index, 1);

            if (event.target.checked)
                draft.category_ids.push(value);
        }
        else {  
            draft[prop] = value;
        }
      }));
  }

  function onUpdateSizes() {
    setIsProcessing("sizes");

    postData("shapes/shapedata", { 
        shape_id: props.shape.id,
        size: props.size,
        width_ratio: formData.width_ratio,
        height_ratio: formData.height_ratio
    },
        function(result) {
          setSizes(result.sizes);
        },
        function(error) {
            alert("Error loading size data");
        },
        function () {
          setIsProcessing("");
        }
    );     
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faHeart} />
            &nbsp;
            Shape
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? 
            <Loading />
          :  
            <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>

            <TextField 
                name="code" 
                label="Code" 
                required 
                value={formData.code} 
                onChange={(e) => handleFormChange(e)} />
                
            <TextField 
                name="name" 
                label="Name" 
                required
                value={formData.name} 
                onChange={(e) => handleFormChange(e)} />
            <TextAreaField 
                name="description" 
                label="Description" 
                rows={3}
                value={formData.description} 
                onChange={(e) => handleFormChange(e)} />

            {!isCustom &&
              <>
                <div>Categories</div>
                <div>
                    {categories.map(category => (
                        <Form.Check 
                            key={category.id}
                            name="category_id"                                
                            value={category.id}
                            checked={formData.category_ids.indexOf(""+category.id) != -1}
                            label={category.title} 
                            type="checkbox" 
                            onChange={handleFormChange}
                        />
                    ))}
                </div>
                <br />
              </>
            }

            <div style={{display: "flex"}}>
              <div>
                <NumberField 
                  name="pricing_modifier" 
                  label="Pricing Modifier" 
                  required
                  value={formData.pricing_modifier} 
                  onChange={(e) => handleFormChange(e)} />
              <NumberField 
                  name="width_ratio" 
                  label="Width Ratio" 
                  required
                  step={0.01}
                  value={formData.width_ratio} 
                  onChange={(e) => handleFormChange(e)} />
              <NumberField 
                  name="height_ratio" 
                  label="Height Ratio" 
                  required
                  step={0.01}
                  value={formData.height_ratio} 
                  onChange={(e) => handleFormChange(e)} />
              <NumberField 
                  name="top_offset" 
                  label="Top Offset" 
                  required
                  value={formData.top_offset} 
                  onChange={(e) => handleFormChange(e)} />
              <NumberField 
                  name="consumption" 
                  label="Consumption" 
                  required
                  value={formData.consumption} 
                  onChange={(e) => handleFormChange(e)} />
              </div>
              <div style={{padding: "10px"}}>
                {sizes.length && 
                  <>
                    <div style={{textAlign:"center"}}>
                      <strong>Sample Sizes</strong>
                    </div>
                      <>
                        <Table bordered striped style={{width:"100%"}}>
                          <tbody>
                          {sizes.map(size => (
                            <tr key={size}>
                              <th>Size {size.size}:</th>
                              <td>{size.height}" x {size.width}"</td>
                            </tr>  
                          ))}      
                        </tbody>
                        </Table>
                        <Button variant="outline-secondary" size="sm" onClick={onUpdateSizes}>
                          <FontAwesomeIcon icon={isProcessing == "sizes" ? faSpinner : faSyncAlt} spin={isProcessing == "sizes"} />{' '}
                          Refresh Sizes
                        </Button>
                      </>
                  </>
                }
              </div>
            </div>
            {!props.style.secondary && 
              <>
                <TextField 
                    name="standard_sizes" 
                    label="Standard Size List" 
                    instructions="This size list will override the defaults on the style."
                    value={formData.standard_sizes} 
                    onChange={(e) => handleFormChange(e)} />
                <NumberField 
                    name="default_size" 
                    label="Default Size" 
                    instructions="This size list will override the defaults on the product."
                    value={formData.default_size} 
                    onChange={(e) => handleFormChange(e)} />

                <Form.Row>
                  <Col>
                    <NumberField 
                        name="min_size" 
                        label="Minimum Size" 
                        value={formData.min_size} 
                        onChange={(e) => handleFormChange(e)} />
                  </Col>
                  <Col>
                    <NumberField 
                        name="max_size" 
                        label="Max Size" 
                        value={formData.max_size} 
                        onChange={(e) => handleFormChange(e)} />
                  </Col>
                </Form.Row>
              </>
            }

            <BooleanField 
                name="sizeable_ind" 
                label="Resizeable?" 
                required
                value={formData.sizeable_ind} 
                onChange={(e) => handleFormChange(e)} />

          </Form>
          }
          </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing == "save"}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Shape" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ShapeDrawer;