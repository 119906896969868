import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faArchive } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import TextAreaField from "../components/fields/field-textarea"
import UserField from "../components/fields/field-user"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

function ProjectDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(props.project);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    console.log("setting project data", props.project)
    if (props.project) {
      setFormData(props.project);
    }
  }, [props.project]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("projects/save", removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the project.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    
    setFormData(produce(draft => {
      if (event.target.name == "user_id")
        draft[event.target.name] = event.target.value ? event.target.value.id : null;
      else
        draft[event.target.name] = event.target.value;
    }));
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faArchive} />
            &nbsp;
            Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
            <Form.Check 
                checked={formData.status_id == 23}
                label={"Internal Project"}
                type="checkbox" 
                onChange={(e) => {
                  e.persist();

                  setFormData(produce(draft => {
                    draft.status_id = e.target.checked ? 23:22;
                  }))                              
                }}
            />
            <br />

            {(!formData.status_id || formData.status_id != 23) && 
              <UserField 
                  id="project-user"
                  name="user_id" 
                  label="User" 
                  required
                  value={{id:formData.user_id, name: formData.user_name || ""}}
                  onChange={(e) => handleFormChange(e)} />
            }
            <TextField 
                name="title" 
                label="Title" 
                required 
                value={formData.title} 
                onChange={(e) => handleFormChange(e)} />
            <TextAreaField 
                name="description" 
                label="Description" 
                rows={3}
                value={formData.description} 
                onChange={(e) => handleFormChange(e)} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Project" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ProjectDrawer;