import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faPlus, faSpinner, faTrash, faPlug, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import produce from "immer"

import Button from './buttons/button';
import Loading from "./loading"
import ColorBox from "./color-box"
import ShapePreview from "./shape-preview"
import GoogleImage from "./google-image";

import ShapeGraphicsDrawer from "../drawers/drawer-jobshapegraphics"
import AddJobShapeDrawer from "../drawers/drawer-jobaddshape"
import ColorSelectDrawer from "../drawers/drawer-colorselect"
import LedSelectDrawer from "../drawers/drawer-ledselect"
import PowerShapeDrawer from "../drawers/drawer-jobshapepower"
import MaterialSelectDrawer from "../drawers/drawer-materialselect"

import { postData } from "../common/services/server"
import { colors, devices } from '../settings/settings'
import { removeNulls } from "../helpers/utility"

const Shapes = styled.table`
    width: 100%;
    font-size: 80%;

    tr.manual {
        td {
            background-color: ${colors.lightPurple};
        }

        .color {
            cursor: pointer;
        }
    }
    th {
        background-color: #eee;
        border: 1px solid #ccc;
        padding: 4px 6px;
        vertical-align: top;
    }
    td {
        padding: 4px 6px;
        white-space: nowrap;

        div.color > div {
            margin-left: 0px;
        }

        border: 1px solid #ccc;
    }

    h3 {
        font-size: 17px;
        margin: 15px 0px 5px 0px;
    }
    div.color {
        display: flex;
        align-items: center;
    }

`

function JobShapes({job, ...props}) {
    const [isLoading, setIsLoading] = useState(true);
    //const [isProcessing, setIsProcessing] = useState(false);
    const [shapes, setShapes] = useState([]);
    const [partTypes, setPartTypes] = useState([]);
    const [haveManual, setHaveManual] = useState(false);
    const [selected, setSelected] = useState(null);
    const [busy, setBusy] = useState(null)

    const [trimParts, setTrimParts] = useState([]);
    const [editTrim, setEditTrim] = useState(false);

    useEffect(
      () => {
          setIsLoading(true);

          postData("jobs/shapes", {
                id: job.id
            }, 
            function(response) {
              setShapes(response)

              let uniquePartTypes = [];

              for (const shape of response) {
                for (const part of shape.parts) {
                    if (uniquePartTypes.findIndex(pt => pt.name == part.type_name) == -1) {
                        uniquePartTypes.push({
                            id: part.type_id,
                            name: part.type_name,
                            title: part.type_title,
                            color_ind: part.color_ind
                        })
                    }
                }
              }

              setPartTypes(uniquePartTypes);

              if (props.onSelectAll)
                props.onSelectAll(response)
            },
            function(error) {
              alert("An error occured loading job shape data.");
            },
            function() {
              setIsLoading(false);
            }
          );
      }, 
      [job]
    );

    useEffect(
        () => {
            setHaveManual(!props.selectable && shapes ? shapes.filter(s => s.manual_ind == 1).length > 0 : false);
        }, 
        [shapes]
      );  

    function onPrintedGraphics(shape) {
        setSelected({mode:"graphics", shape});
    }

    function onAddShape() {
        setSelected({mode:"addshape"});
    }
    function onDeleteShape(shape) {
        if (window.confirm("Delete this manually added job shape?")) {
            setBusy(shape.id);

            postData("jobs/deleteshape?job_id=" + job.id, { jobshape_id: shape.id }, 
                function(response) {
                    setShapes(response);
                },
                function(error) {
                    alert("An error occured updating the job.");
                },
                function() {
                    setBusy(null);
                }
            );
        }
    }

    function onPartLED(shape, part) {
        setSelected({mode:"partled", shape, part})
    }

    function onEditTrim() {
        setBusy("trim")

        postData("parts/data", {type_id: 5}, 
            function(response) {
                setTrimParts(response);
                setEditTrim(true);
            },
            function(error) {
                alert("An error occured loading trim parts.");
            },
            function() {
                setBusy(null);
            }
        );        
    }

    function onTrimChange(shape, part, trimId) {
        setBusy("trim" + part.id)

        setShapes(produce(draft => {
            const shapeIndex = draft.findIndex(s => s.id == shape.id);

            if (shapeIndex != -1) {
                const partIndex = draft[shapeIndex].parts.findIndex(p => p.id == part.id);

                if (partIndex != -1) {
                    const trim = trimParts.find(t => t.id == trimId);

                    draft[shapeIndex].parts[partIndex].part_id = trimId;
                    draft[shapeIndex].parts[partIndex].title = trim.title;
                }
            }
        }));

        postData("jobs/updatepart", {
            job_id: job.id,
            jobshape_id: shape.id,
            jobpart_id: part.id,
            part_id: trimId
        }, 
            function(response) {
                // nada
            },
            function(error) {
                alert("An error occured updating the trim.");
            },
            function() {
                setBusy(null);
            }
        );         
    }

    function onPropChanged(prop, value) {
        setBusy(prop);

        postData("jobs/saveprop", {
            job_id: job.id,
            prop,
            value
        }, 
        function(response) {
            if (props.onPropChanged) {
                props.onPropChanged(prop, value);
            }
        },
        function(error) {
        },
        function() {
            setBusy(""); 
        }
      );
    }

    function onPower(shape, partType, part) {
        setSelected({mode:"power", shape, partType, part})
    }

    function canChangePower(partType, part) {
        if (part) {
            return part.type_name == "power" && props.changepower;
        }
        else if (partType) {
            return partType.name == "power" && props.changepower;
        }
    }

    function canChangeMaterial(partType, part) {
        let change = false;

        if (part) {
            change = part.material_title;
        }
        else if (partType) {
            for (const shape of shapes) {
                const partsWithMaterials = shape.parts.filter(p => p.type_name == partType.name && p.material_id);

                if (partsWithMaterials.length > 0) {
                    change = true;
                    break;
                }
            }
        }

        return change;
    }
    function onMaterial(shape, partType, part) {
        setSelected({mode:"material", shape, partType, part});
    }

    function doesItHaveColor(partType, part, shape) {
        let has = false;
        
        if (part) {
            has = part.color_id && (part.type_name != "face" || shape.printed_ind == 0);
        }
        else if (partType) {
            for (const shape of shapes) {
                const partsWithColor = shape.parts.filter(p => p.color_id && p.type_name == partType.name && (p.type_name != "face" || shape.printed_ind == 0));

                if (partsWithColor.length > 0) {
                    has = true;
                    break;
                }
            }            
        }

        return has;
    }    
    function canEditColor(partType, part, shape) {
        let change = false;
        
        if (part) {
            // allow color change if shape was manually added OR if it has a color
            // that isn't managed in the builder
            change = doesItHaveColor(partType, part, shape) && ((shape && (shape.manual_ind == 1 || shape.style_name == "Custom")) || partType.color_ind == 0);
        }
        else if (partType) {
            for (const shape of shapes) {
                const partsWithColor = shape.parts.filter(p => 
                        p.type_name == partType.name &&
                        doesItHaveColor(null, p, shape) && 
                        ((shape && shape.manual_ind == 1) || partType.color_ind == 0)
                    );

                if (partsWithColor.length > 0) {
                    change = true;
                    break;
                }
            }            
        }

        return change;
    }
    function onPartColor(part, partType, shape) {
        setSelected({mode:"partcolor", shape, partType, part})
    }

    return (
    <>
        { isLoading ? 
          <Loading />
        :
        <>
          <Shapes>
              <thead>
                  <tr>
                      {props.selectable && 
                        <th width="65">
                            <Button onClick={() => props.onSelectAll(shapes)} variant="outline-secondary" className="w-full">
                                All
                            </Button>
                        </th>
                      }
                      <th>Shape</th>
                      <th>Product</th>
                      <th>Style</th>
                      <th>Size</th>
                      {partTypes.filter(pt => !props.partTypes || props.partTypes.indexOf(pt.name) != -1).map(type => {
                        const editMaterial = canChangeMaterial(type);
                        const editPower = canChangePower(type);
                        const editColor = canEditColor(type) && type.name != "lighting";

                        return (
                            <th key={type.name}>
                                <div>
                                    {type.title}
                                </div>

                                {(type.name == "trim" && props.changetrim) && 
                                    <Button 
                                        onClick={onEditTrim} 
                                        variant="outline-secondary" 
                                        size="tiny"
                                        className="w-full"
                                    >
                                        <FontAwesomeIcon icon={busy == "trim" ? faSpinner:faPencilAlt} spin={busy == "trim"} />
                                        &nbsp;
                                        Edit
                                    </Button>
                                }

                                {(type.name == "backdrop" && props.changeprops) &&
                                    <div>
                                        <FormControl 
                                            as="select" 
                                            name="backdrop"
                                            size="sm"
                                            disabled={busy == "backdrop_finish"}
                                            value={job.backdrop_finish} 
                                            onChange={(e) => {
                                                onPropChanged("backdrop_finish", e.target.value);
                                            }}
                                        >
                                            <option value="">
                                                Matte
                                            </option>
                                            <option value="notmatte">
                                                Not Matte
                                            </option>                                                        
                                        </FormControl>  
                                    </div>
                                }     
                                {editPower && 
                                    <div>
                                        <Button 
                                            size="tiny" 
                                            variant="outline-secondary"
                                            className="w-full"
                                            onClick={(e) => { e.preventDefault(); onPower(null, type, null); }}
                                        >
                                            <FontAwesomeIcon 
                                                icon={busy && busy==type ? faSpinner:faPencilAlt} 
                                                spin={busy && busy==type} 
                                            />
                                            &nbsp;
                                            Power
                                        </Button>
                                    </div>
                                }            
                                {editColor && 
                                    <div>
                                        <Button 
                                            size="tiny" 
                                            variant="outline-secondary"
                                            className="w-full"
                                            onClick={(e) => { e.preventDefault(); onPartColor(null, type, null); }}
                                        >
                                            <FontAwesomeIcon 
                                                icon={busy && busy==type ? faSpinner:faPencilAlt} 
                                                spin={busy && busy==type} 
                                            />
                                            &nbsp;
                                            Color
                                        </Button>
                                    </div>
                                }                                                         
                                {editMaterial && 
                                    <div>
                                        <Button 
                                            size="tiny" 
                                            variant="outline-secondary"
                                            className="w-full"
                                            onClick={(e) => { e.preventDefault(); onMaterial(null, type, null); }}
                                        >
                                            <FontAwesomeIcon 
                                                icon={busy && busy==type ? faSpinner:faPencilAlt} 
                                                spin={busy && busy==type} 
                                            />
                                            &nbsp;
                                            Material
                                        </Button>
                                    </div>
                                }                         
                            </th>
                        )
                      })}

                      {haveManual && <th>Notes</th>}
                      {haveManual && <th>Actions</th>}
                  </tr>
              </thead>
              <tbody>
              {shapes.map(shape => {
                return (
                    <tr key={shape.id} className={shape.manual_ind == 1 ? "manual":""}>
                        {props.selectable && 
                            <td width="65" align="center">
                                <Form.Check 
                                    type="checkbox"
                                    value={shape.id}
                                    checked={props.selected && props.selected.findIndex(s => s.id == shape.id) != -1}
                                    onChange={(e) => {
                                        props.onSelection(shape, e.target.checked)
                                    }}                    
                                />
                            </td>
                        }
                        <td>
                            <ShapePreview
                                path={shape.path}
                                width={100}
                                height={50}
                            />
                            {shape.style_id == 0 && 
                                <div>{shape.code}</div>
                            }
                        </td>
                        <td>
                            {shape.product_title}
                        </td>
                        <td>
                            {shape.style_name}
                            {shape.resembles_ind == 1 && 
                                <span style={{color:"red"}}>
                                    &nbsp; * Similar
                                </span>
                            }
                        </td>
                        <td>{shape.size}</td>

                        {partTypes.filter(pt => !props.partTypes || props.partTypes.indexOf(pt.name) != -1).map(type => {
                            const parts = shape.parts.filter(p => p.type_name == type.name);

                            return (
                                <td key={type.name}>
                                    {parts.map(part => {
                                        const editMaterial = canChangeMaterial(null, part);
                                        const editPower = canChangePower(null, part);
                                        
                                        const hasColor = doesItHaveColor(type, part, shape);                                        
                                        const editColor = canEditColor(type, part, shape);

                                        return (
                                            <div key={part.id}>
                                                { (!part.color_id || part.title != part.type_title) && 
                                                    <div>
                                                        {editPower ? 
                                                            <a href="#" onClick={(e) => { e.preventDefault(); onPower(shape, null, part); }}>
                                                                <span style={{width: "16px", border: "1px solid #ccc", borderRadius: "3px", paddingLeft: "1px"}}>
                                                                    <FontAwesomeIcon icon={busy && busy==part ? faSpinner:faPlug} spin={busy && busy==part} />
                                                                </span>{' '}
                                                                {part.title}
                                                            </a>
                                                        : (type.name == "trim" && editTrim) ? 
                                                            <FormControl 
                                                                as="select" 
                                                                name="trim"
                                                                size="sm"
                                                                disabled={busy == ("trim" + part.id)}
                                                                value={part.part_id} 
                                                                onChange={(e) => {
                                                                    onTrimChange(shape, part, e.target.value)
                                                                }}
                                                            >
                                                                {trimParts.map((trim,index) => (
                                                                    <option key={index} value={trim.id}>
                                                                        {trim.title}
                                                                    </option>
                                                                ))}
                                                            </FormControl>                                                        
                                                        :
                                                            part.title
                                                        }
                                                    </div> 
                                                }
                                                {(part.led_id && part.led_code && part.led_code.length) && 
                                                    <div className="color">
                                                        <ColorBox 
                                                            led
                                                            color={{name: part.led_code}} 
                                                            size="small" 
                                                            onClick={shape.manual_ind == 1 ? () => onPartLED(shape, part) : null}
                                                        />
                                                        {part.led_code}
                                                    </div>
                                                }
                                                { hasColor &&
                                                    <>
                                                        { editColor ? 
                                                            <a href="#" onClick={(e) => { e.preventDefault(); onPartColor(part, null, shape); }} style={{display:"flex"}}>
                                                                <ColorBox 
                                                                    color={{name: part.color_name, hex_day: part.color_hex}} 
                                                                    size="small" 
                                                                />
                                                                &nbsp;
                                                                { part.color_code }
                                                            </a>
                                                        :
                                                            <div style={{display: "flex"}}>
                                                                <ColorBox 
                                                                    color={{name: part.color_name, hex_day: part.color_hex}} 
                                                                    size="small" 
                                                                />
                                                                &nbsp;
                                                                { part.color_code }
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                { (part.type_name == "face" && shape.printed_ind == 1) &&
                                                    <div>
                                                        <a href="#" onClick={(e) => { e.preventDefault(); onPrintedGraphics(shape); }}>
                                                            <span style={{width: "16px", border: "1px solid #ccc", borderRadius: "3px", paddingLeft: "1px"}}>
                                                                <FontAwesomeIcon icon={busy && busy==part ? faSpinner:faPrint} spin={busy && busy==part} size="1x" fixedWidth />
                                                            </span>{' '}
                                                            Printed Graphics
                                                        </a>
                                                    </div>
                                                }
                                                { (part.type_name == "face" && (shape.faceart_url || shape.faceart_url_thumb)) && 
                                                    <GoogleImage
                                                        root 
                                                        src={shape.faceart_url_thumb || shape.faceart_url} 
                                                        link={shape.faceart_url}
                                                        style={{maxHeight: "25px", maxWidth: "75px"}} 
                                                    />                                            
                                                }
                                                { editMaterial &&
                                                    <div>
                                                        <a href="#" onClick={(e) => { e.preventDefault(); onMaterial(shape, null, part); }}>
                                                            <span style={{width: "16px", border: "1px solid #ccc", borderRadius: "3px", paddingLeft: "1px"}}>
                                                                <FontAwesomeIcon icon={busy && busy==part ? faSpinner:faPencilAlt} spin={busy && busy==part} size="1x" fixedWidth />
                                                            </span>
                                                            &nbsp;
                                                            {part.material_title}
                                                        </a>
                                                    </div>
                                                }                                           
                                            </div>
                                        )
                                    })}
                                </td>
                            )
                        })}

                        {haveManual &&                         
                            <td>
                                {shape.notes}
                            </td>
                        }
                        {haveManual && 
                            <td align="center">
                                {shape.manual_ind == 1 &&
                                    <Button onClick={() => onDeleteShape(shape)} variant="outline-danger" size="icon">
                                        <FontAwesomeIcon icon={shape.id == busy ? faSpinner:faTrash} spin={shape.id == busy} />
                                    </Button>
                                }
                            </td>
                        }
                    </tr>
                )
              })}
              </tbody>
            </Shapes>
          
            <br />
            {!props.selectable && 
            <div>
                <Button onClick={onAddShape} variant="outline-success" size="sm">
                    <FontAwesomeIcon icon={faPlus} />{' '}
                    Add Shape
                </Button>
            </div>
            }
        </>
        }
        { (selected && selected.mode == "graphics") &&
            <ShapeGraphicsDrawer 
                job={job}
                sign={{
                    uid: job.sign_uid
                }}
                shape={selected.shape} 
                show={true}                
                onHide={ () => setSelected(null) } 
            /> 
        }
        { (selected && selected.mode == "addshape") &&
            <AddJobShapeDrawer 
                show={true}    
                job={job}  
                shapes={shapes}    
                onShapesChanged={ (shapes) => {
                    setShapes(shapes);
                    setSelected(null);
                }}      
                onHide={ () => setSelected(null) } 
            /> 
        }
        {(selected && selected.mode == "partcolor") && 
            <ColorSelectDrawer
                show={true}
                partType={selected.partType}
                part={selected.part}
                onColor={(color) => {
                    setBusy(selected.part || selected.partType)

                    postData("jobs/updatepartcolor", removeNulls({ 
                        job_id: job.id, 
                        jobshape_id: selected.shape ? selected.shape.id : null, 
                        parttype_id: selected.partType ? selected.partType.id : null,
                        part_id: selected.part ? selected.part.id : null, 
                        color_id: color.id 
                    }),
                        function(result) {
                            setShapes(result);
                        },
                        function(error) {
                            alert("Error updating part.")
                        },
                        function() {
                            setBusy(null)
                        }
                    );

                    setSelected(null);
                }}
                onHide={() => setSelected(null)}
            />
        }
        {(selected && selected.mode == "partled") && 
            <LedSelectDrawer
                show={true}
                onLed={(led) => {
                    setBusy(selected.part)

                    postData("jobs/updatepartled", { job_id: job.id, jobshape_id: selected.shape.id, part_id: selected.part.id, led_id: led.id },
                        function(result) {
                            setShapes(result);
                        },
                        function(error) {
                            alert("Error updating part.")
                        },
                        function() {
                            setBusy(null)
                        }
                    );

                    setSelected(null);
                }}
                onHide={() => setSelected(null)}
            />
        }
        {(selected && selected.mode == "material") && 
            <MaterialSelectDrawer
                show={true}
                partType={selected.partType}
                part={selected.part}
                onMaterial={(material) => {
                    setBusy(selected.partType || selected.part)

                    postData("jobs/updatepartmaterial", removeNulls({ 
                        job_id: job.id, 
                        jobshape_id: selected.shape ? selected.shape.id : null, 
                        parttype_id: selected.partType ? selected.partType.id : null,
                        part_id: selected.part ? selected.part.id : null, 
                        material_id: material.id 
                    }),
                        function(result) {
                            setShapes(result);
                        },
                        function(error) {
                            alert("Error updating part.")
                        },
                        function() {
                            setBusy(null)
                        }
                    );

                    setSelected(null);
                }}
                onHide={() => setSelected(null)}
            />
        }
        {(selected && selected.mode == "power") && 
            <PowerShapeDrawer
                show={true}
                shape={selected.shape}
                shapes={shapes}
                onSaved={(part) => {
                    setBusy(selected.part || selected.partType)

                    postData("jobs/updatepartpower", removeNulls({ 
                        job_id: job.id, 
                        jobshape_id: selected.shape ? selected.shape.id : null, 
                        parttype_id: selected.partType ? selected.partType.id : null,
                        part_id: part.id
                    }),
                        function(result) {
                            setShapes(result);
                        },
                        function(error) {
                            alert("Error updating part.")
                        },
                        function() {
                            setBusy(null)
                        }
                    );

                    setSelected(null);
                }}
                onHide={() => setSelected(null)}
            />
        }   
    </>
    );
}

export default JobShapes;