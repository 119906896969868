import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp, faCreditCard, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import BoardField from "../components/board/board-field";
import BoardColumns from "../components/board/board-columns";
import BoardWarning from "../components/board/board-warning";
import { postData } from "../common/services/server";

function PricesDrawer({
    product,
    part,
    prices,
    onChange,
    onHide
}) {
  const [busy, setBusy] = useState("");
  const [adjAmount, setAdjAmount] = useState(0);
  const [adjPercent, setAdjPercent] = useState(0);
  const [productsExpanded, setProductsExpanded] = useState(false);

  function onPricesChange(results) {
    console.log("drawer onPricesChange", results)

    if (onChange) {
        let updatedPrices = JSON.parse(JSON.stringify(prices));

        for (const price of results) {
            let idx = updatedPrices.findIndex(p => 
                (price.part_id ? p.part_id == price.part_id : p.product_id = price.product_id) && 
                p.size == price.size
            );

            console.log({idx});

            if (idx != -1) {
                updatedPrices[idx] = price;
            }
        }

        onChange({prices: updatedPrices});
    }
  }

  function onPriceChange(result) {
    console.log("drawer onPriceChange", result)

    onPricesChange([result]);
  }

  function onSaveAdjAmount() {
    setBusy("saveamt")

    postData("products/adjustprices", {
        product_id: part ? null : product.id,
        part_id: part ? part.id : 0,
        amount: adjAmount
    },
        function(result) {
            console.log("drawer onPriceAdj", result)
            onPricesChange(result);
        },
        function(error) {
            alert("Error updating price")
        },
        function() {
            setBusy("");
        }
    );    
  }

  function onSaveAdjPercent() {
    setBusy("saveper")

    postData("products/adjustprices", {
        product_id: part ? null : product.id,
        part_id: part ? part.id : 0,
        percent: adjPercent
    },
        function(result) {
            console.log("drawer onPriceAdj", result)
            onPricesChange(result);
        },
        function(error) {
            alert("Error updating price")
        },
        function() {
            setBusy("");
        }
    );     
  }

  const maxSize = prices.reduce((max, curren) => max.size > curren.size ? max : curren).size;
  const standardSizes = product.standard_sizes ? product.standard_sizes.split(",") : [];

  return (
      <>
        <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faCreditCard} />
                    &nbsp;
                    Pricing Editor
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="grid gap-1">
                    <BoardField
                        type="label"
                        label="Product"
                        value={product.title}
                    />   
                    {part && 
                        <BoardField
                            type="label"
                            label="Part"
                            value={part.title}
                        />   
                    }
                </div>

                {(part && part.product_count > 0) && 
                    <BoardWarning style={{margin: "10px 0px"}}>
                        <div style={{
                                display:"flex", 
                                alignItems:"center",
                                justifyContent:"space-between", 
                                marginBottom: "5px", 
                                cursor: "pointer"
                            }}
                            onClick={() => setProductsExpanded(!productsExpanded)}
                        >
                            <div>Warning: This part is used by <strong>{part.product_count}</strong> products.  Changes to these 
                            prices will affect all of them.</div>

                            <FontAwesomeIcon icon={productsExpanded ? faCaretUp:faCaretDown} size="2x" />
                        </div>

                        {productsExpanded && 
                            <div>
                                {part.products.split(",").map(p => (
                                    <div key={p}>
                                        &bull;
                                        {p}
                                    </div>
                                ))}
                            </div>
                        }
                    </BoardWarning>
                }

                <BoardColumns>
                    <div className="space-y-1">
                        {[...Array(maxSize)].map((x,idx) => {
                            const size = idx+1; //product.min_size + idx;
                            const priceData = prices.find(p => p.part_id == (part?part.id:null) && p.size==size);
                            const price = priceData ? priceData.price : 0;

                            return (
                                <BoardField
                                    key={idx}
                                    type="number"
                                    label={"Size " + size}
                                    highlight={standardSizes.find(s => s == size)?true:false}
                                    error={!priceData}
                                    value={price}
                                    saveUrl={`products/updateprice?product_id=${product.id}&part_id=${part?part.id:0}&size=${size}`}
                                    onSaved={onPriceChange}                                                                     
                                />
                            )
                        })}
                    </div>
                    <div className="space-y-1">
                        <p style={{textAlign:"center"}}>
                            Make Adjustments To All Prices
                        </p>
                        <BoardField
                            label="Adjustment Amount"
                            type="number"
                            value={adjAmount}
                            numberProps={{
                                format: "currency"
                            }}
                            onChange={(value) => setAdjAmount(value)}
                            suffix={
                                <Button size="sm" variant="solid-primary" onClick={onSaveAdjAmount} disabled={!adjAmount} style={{display:"flex", alignItems:"center", gap:"5px"}}>
                                    <FontAwesomeIcon icon={busy=="saveamt"?faSpinner:faSave} spin={busy=="saveamt"} />
                                    Update
                                </Button>
                            }
                        />
                        <BoardField
                            label="Adjustment Percent"
                            type="number"
                            value={adjPercent}
                            numberProps={{
                                format: "percent"
                            }}
                            onChange={(value) => setAdjPercent(value)}
                            suffix={
                                <Button size="sm" variant="solid-primary" onClick={onSaveAdjPercent} disabled={!adjPercent} style={{display:"flex", alignItems:"center", gap:"5px"}}>
                                    <FontAwesomeIcon icon={busy=="saveper"?faSpinner:faSave} spin={busy=="saveper"} />
                                    Update
                                </Button>
                            }
                        />                        
                    </div>
                </BoardColumns>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default PricesDrawer;