import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faBan, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'

import produce from "immer"

import Button from "../buttons/button";
import FieldLabel from "./label"
import { postData } from "../../common/services/server"

function FieldCategory(props) {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [creating, setCreating] = useState(false);
    const [saving, setSaving] = useState(false);
    
    let newCategoryInput = null;

    useEffect(
      () => {
          setLoading(true);

          postData("categories/data", { type: props.type }, 
            function(data) {
                setCategories(data);
            },
            function(error) {
                alert("An error occured loading category data.");
            },
            function() {
                setLoading(false);
            }
          );
      }, 
      []
    );

    function onCategoryChange(catId) {
        props.onChange({
            target: {
                name: props.name,
                value: catId
            }
        });
    }

    function onNewCategory() {
        console.log("onNewCategory", creating)
        setCreating(true);
    }
    function onSaveNewCategory() {
        setSaving(true);

        postData("category/save", { type: props.type, title: newCategoryInput.value }, 
            function(data) {
                setCategories(produce(categories, draft => {
                    draft.unshift(data);
                }));

                setCreating(false);
            },
            function(error) {
                alert("An error occured saving the category.");
            },
            function() {
                setSaving(false);
            }
        );
    }
    function onCancelNewCategory() {
        setCreating(false);
        setSaving(false);
    }

    return (
        <Form.Group>
            {props.label && <FieldLabel required={props.required} bold={props.labelBold}>{props.label}</FieldLabel>}

            <InputGroup>
                {
                    creating 
                    ?
                        <FormControl 
                            as="input" 
                            type="text" 
                            disabled={props.disabled || saving}
                            name={props.newName} 
                            required={props.required}
                            onChange={(e) => props.onChange(e)}
                            ref={(input) => { newCategoryInput = input; }}
                        />
                    :
                        <FormControl 
                            as="select" 
                            name={props.name} 
                            required={props.required}
                            value={props.value} 
                            onChange={ (e) => onCategoryChange(e.target.value) }>
                            <option>{loading?'Loading...':''}</option>
                            {categories.map(category => (
                                <option value={category.id} key={category.id}>
                                    {category.title}
                                </option>
                            ))}
                        </FormControl>
                }

                { props.allowNew &&
                    <InputGroup.Append>
                        {
                            creating 
                            ?
                                <>
                                    <Button disabled={saving} onClick={onSaveNewCategory} variant="success">
                                        {
                                            saving 
                                            ?
                                            <FontAwesomeIcon icon={faSpinner} spin />
                                            :
                                            <FontAwesomeIcon icon={faCheck} />
                                        }
                                        &nbsp;
                                        Save
                                    </Button>
                                    <Button onClick={onCancelNewCategory} variant="outline-danger">
                                        <FontAwesomeIcon icon={faBan} />
                                        &nbsp;
                                        Cancel
                                    </Button>
                                </>
                            :
                            <Button onClick={onNewCategory} variant="outline-success">
                                <FontAwesomeIcon icon={faStar} />
                                &nbsp;
                                New
                            </Button>
                        }
                    </InputGroup.Append>
                }
            </InputGroup>
        </Form.Group>
    );
}

export default FieldCategory