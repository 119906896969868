import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect"

import { colors, devices } from '../settings/settings'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faClipboardList, 
    faShapes, 
    faCreditCard, 
    faTag, 
    faChartPie, 
    faUser,
    faHeart,
    faArchive,
    faWrench,
    faIcons,
    faCameraRetro,
    faRss,
    faEllipsisH,
    faPaintBrush, 
    faPalette, 
    faDesktop, 
    faClipboardCheck, 
    faFileAlt, 
    faFlagUsa,
    faBuilding,  
    faUserLock,
    faSms,
    faEnvelope,
    faPlug,
    faBirthdayCake,
    faScrewdriver,
    faProjectDiagram
} from "@fortawesome/free-solid-svg-icons";

import Overlay from "./overlay"

import { isLoggedIn, Permissions, userHasPermission, userHasAnyPermission, getUser } from "../common/services/auth"
import { useWindowDimensions } from "../hooks/useWindowDimensions"

const Menu = styled.div`
    background-color: ${colors.lightPurple};
    width: ${isMobile ? 200:55}px;
    position: ${isMobile ? "absolute":"static"}
    top: ${isMobile ? "50px":"auto"};
    z-index: 99999;

    height: 100%;
    
    @media print
    {            
        display: none !important;
    }

    ul {
        list-style-type: none;
        margin: 0px;
        padding: 0px;

        li {
            margin: 0px;
            padding: 0px;

            a {
                display: ${isMobile ? "flex":"block"};
                align-items: center;
                padding: 5px;
                text-align: center;
                text-decoration: none;
                border-bottom: 1px solid white;
                color: black;
                font-size: 8px;

                &:hover {
                    background-color: ${colors.lightPurpleHover};
                }

                svg {
                    display: ${isMobile ? "inline-block":"block"};
                    margin: ${isMobile ? "0px 10px 0px 0px":"0px auto"};
                    color: #333;
                    margin-bottom: 5px;
                }

                span {
                    font-size: ${isMobile ? "14px":"inherit"};
                }                
            }

            &.active {
                background-color: white;
            }
        }
    }

    ul.more {
        position: fixed;
        width: 55px;
        bottom: 0px;
        left: 0px;
        background-color: ${colors.lightPurple};

        svg {
            color: white;
        }
    }

    @media ${devices.mobile} {
        overflow: auto;
        max-height: calc(100vh - 40px);
    }

    @media print
    {    
        display: none !important;
    }
`
const MoreMenu = styled.div`
    background-color: ${colors.lightPurple};
    width: 200px;
    position: fixed;
    left: ${isMobile ? 151:56}px;
    bottom: 0px;
    top: ${isMobile ? 50:48}px;
    z-index: 1001;
    overflow: hidden;

    ul {
        list-style-type: none;
        margin: 0px;
        padding: 0px;

        li {            
            &:hover {
                background-color: ${colors.lightPurpleHover};
            }            
        }

        a {
            display: block;
            padding: 5px 10px;
            color: black;

            svg {
                margin-right: 5px;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
`

function SiteMenu(props) {
    const windowDimensions = useWindowDimensions();
    const location = useLocation();
    const path = location.pathname;

    const [moreOpen, setMoreOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [moreItems, setMoreItems] = useState([]);

    const allItems = [
        {to: "/", icon: faChartPie , title: "Dashboard"},
        //{to: "/calls", icon: faPhone, title: "Calls"},
        {to: "/emails", icon: faEnvelope , title: "Emails",       permission: Permissions.Emails},
        {to: "/sms", icon: faSms , title: "Text Msgs",              permission: Permissions.Emails},
        {to: "/signs", icon: faShapes, title: "Signs",              permission: Permissions.Signs},
        {to: "/projects", icon: faArchive, title: "Projects",       permission: Permissions.Projects},
        {to: "/coupons", icon: faTag, title: "Codes",               permission: Permissions.PurchaseCodes},
        {to: "/orders", icon: faCreditCard , title: "Orders",       permission: Permissions.Orders},
        {to: "/jobs", icon: faWrench , title: "Jobs",               permission: Permissions.Jobs},
        {to: "/products", icon: faClipboardList, title: "Products", permission: Permissions.Products},
        {to: "/parts", icon: faPlug, title: "Parts",                permission: Permissions.Products},
        {to: "/users", icon: faUser, title: "Users",                permission: Permissions.Customers},
        {to: "/shapes", icon: faHeart, title: "Shapes",             permission: Permissions.Shapes},
        {to: "/art", icon: faIcons, title: "Art",                   permission: Permissions.FaceArt},
        {to: "/photos", icon: faCameraRetro, title: "Photos",       permission: Permissions.Photos},
        {to:"/blog", icon: faRss, title: "Blog",                    permission: Permissions.News},
        {to:"/data/qa", icon:faClipboardCheck, title:"QA Checklists", permission: Permissions.QAChecklists},
        //{to:"/data/jobtasks", icon:faTasks, title:"Job Tasks",      permission: Permissions.JobTasks},
        {to:"/data/colors", icon:faPalette, title:"Colors",         permission: Permissions.Colors},
        {to:"/data/styles", icon:faPaintBrush, title:"Styles",      permission: Permissions.Styles},
        {to:"/data/pages", icon:faFileAlt, title:"Landing Pages",   permission: Permissions.LandingPages},
        {to:"/data/stations", icon:faDesktop, title:"Production Stations", permission: Permissions.Stations},
        {to:"/data/states", icon:faFlagUsa, title:"States",         permission: Permissions.ShippingData},
        {to:"/data/companies", icon:faBuilding, title:"Companies",  permission: Permissions.Companies},        
        {to:"/data/roles", icon:faUserLock, title:"Security Roles",  permission: Permissions.Roles},        
        {to:"/data/skills", icon:faScrewdriver, title:"Production Skills",  permission: Permissions.Roles},        
        {to:"/data/holidays", icon:faBirthdayCake, title:"Holidays",  permission: Permissions.Companies},        
        {to:"/emailtemplates", icon:faUserLock, title:"Email Templates",  permission: Permissions.EmailTemplates},        
        {to:"/workflows", icon:faProjectDiagram, title:"Workflows",  permission: Permissions.Workflows},        
        //{to:"/calls", icon:faPhone, title:"Customer Calls",  permission: Permissions.Emails},        
    ]

    useEffect(
        () => {
            const validItems = [];
            const extraItems = [];
            const maxItems = Math.floor((windowDimensions.height - 70) / 55);

            for (const item of allItems) {
                if (!item.permission || userHasPermission(item.permission)) {
                    if (validItems.length >= maxItems && !isMobile)
                        extraItems.push(item);
                    else
                        validItems.push(item);
                }
            }

            setItems(validItems);
            setMoreItems(extraItems);
        }, 
        []
    );

    //console.log("location", location)
    return (
            isLoggedIn() ? 
                <>
                    {moreOpen && 
                        <Overlay onClick={() => setMoreOpen(false)} />
                    }
                    <Menu>
                        <ul>
                            {items.filter(i => !i.roles || userHasAnyPermission(i.roles, "View")).map(item => (
                                <li key={item.to} className={item.to.length > 1 && path.indexOf(item.to) === 0 ? "active":""}>
                                    <Link to={item.to}>
                                        <FontAwesomeIcon icon={item.icon} size="3x" fixedWidth />
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        {(moreItems.length > 0) && 
                            <ul className="more">
                                <li>
                                    <a onClick={(e) => {
                                        e.preventDefault();
                                        setMoreOpen(!moreOpen);
                                    }}>
                                        <FontAwesomeIcon icon={faEllipsisH} size="3x" fixedWidth />
                                    </a>
                                </li>
                            </ul>
                        }
                    </Menu>
                    {moreOpen && 
                        <MoreMenu>
                            <ul>
                            {moreItems.filter(i => !i.roles || userHasPermission(i.permission)).map(item => (
                                <li key={item.to}>
                                    <Link to={item.to} onClick={() => setMoreOpen(false)}>
                                        <FontAwesomeIcon icon={item.icon} fixedWidth />
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            ))}
                            </ul>
                        </MoreMenu>
                    }
                </>
            :
                <div></div>            
    );
}

export default SiteMenu