import clsx from "clsx";
import React from "react";

function TextArea({
    name,
    rows,
    required,
    readonly,
    disabled,
    value,
    className,
    style,
    onBlur,
    onChange
}) {
    return (
        <textarea 
            name={name} 
            rows={rows}
            required={required}
            readOnly={readonly}
            disabled={disabled}
            onBlur={onBlur}
            className={clsx(
                "invalid:bg-red-100 invalid:text-red-500",
                className,
            )}            
            value={value}
            style={style}
            onChange={(e) => {
                if (onChange)
                    onChange(e, e.target.value) 
            }}
        >
            {value}
        </textarea>
    )
}

export default TextArea;