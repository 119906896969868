import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import produce from "immer"
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPallet } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import ColorBox from "../components/color-box";
import SelectField from "../components/fields/field-select"

import { postData } from "../common/services/server"

const Parts = styled.table`
    width: 100%;
    thead tr th {
        background-color: #e0e0e0;
        padding: 5px;
    }
    tbody tr td {
        padding: 5px;
    }
`
const Part = styled.tr`
`

function ColorPartsDrawer(props) {
  const [loading, setLoading] = useState(true);

  const [parts, setParts] = useState([]);

  useEffect(() => {
        setLoading(true);

        postData("colors/allparts", { color_id: props.color.id }, 
            function(results) {
                setParts(results);
            },
            function(error) {
                alert("An error occured loading the product parts.");
            },
            function() {
                setLoading(false);
            }
        );
  }, []);

 
  function onPartChange(e) {
    console.log("part changed", e.target.value, e.target.checked)
    const partId = e.target.value;
    const checked = e.target.checked;
    let selected = [];

    setParts(produce(draft => {
        const part = draft.find(p => p.id == partId);

        part.selected_ind = checked ? 1 : 0;
        if (checked)
            part.upcharge_ind = 1;

        selected = draft.filter(s => s.selected_ind == 1).map(s => s.name);
    }));

    postData("colors/togglepart", {
            color_id: props.color.id,
            part_id: partId,
            checked_ind: checked ? 1 : 0
        }, 
        function(response) {
            props.onChanged(selected.length)
        },
        function(error) {
            alert("An error occured updating the color.");
        },
        function() {
        }
    );
  }

  function onDecorationTypeChange(part, type) {
    console.log("part decoration", part.name, type)

    setParts(produce(draft => {
        const prt = draft.find(p => p.id == part.id);

        prt.decoration_type = type
    }));

    postData("colors/partupdate", {
            color_id: props.color.id,
            part_id: part.id,
            decoration_type: type
        }, 
        function(response) {

        },
        function(error) {
            alert("An error occured updating the part.");
        },
        function() {
        }
    );
  }

  function onUpchargeChange(e) {
    console.log("upcharge changed", e.target.value, e.target.checked)
    const partId = e.target.value;
    const checked = e.target.checked;
    let selected = [];

    setParts(produce(draft => {
        const prt = draft.find(p => p.id == partId);

        prt.upcharge_ind = checked ? 1:0
    }));

    postData("colors/upchargeupdate", {
            color_id: props.color.id,
            part_id: partId,
            upcharge_ind: checked ? 1:0
        }, 
        function(response) {

        },
        function(error) {
            alert("An error occured updating the color.");
        },
        function() {
        }
    );
  }

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faPallet} />
                &nbsp;
                Color Parts
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: "flex", alignItems: "center", marginBottom: "15px"}}>
                    <ColorBox color={props.color} />
                    {props.color.name}
                </div> 

                { loading ? 
                    <Loading />
                :
                parts.length == 0 ?
                    <p style={{color:"red"}}>No parts found.</p>
                : 
                    <Parts>
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Part</th>
                                <th>Type</th>
                                <th>Decoration Type</th>
                                <th>Upcharge?</th>
                            </tr>
                        </thead>
                        <tbody>
                        {parts.map(part => (
                            <tr key={part.id}>
                                <td>
                                    {part.product_title}
                                </td>
                                <td>
                                    <Form.Check 
                                        name="part_id"                                
                                        value={part.id}
                                        checked={part.selected_ind == 1}
                                        inline 
                                        label={part.title} 
                                        type="checkbox" 
                                        onChange={onPartChange}
                                    />
                                </td>
                                <td>
                                    {part.type}
                                </td>
                                <td>
                                    {part.selected_ind == 1 && 
                                        <FormControl 
                                            as="select" 
                                            name="decoration_type" 
                                            style={{
                                                fontSize: "12px"
                                            }}
                                            value={part.decoration_type}
                                            onChange={(e) => onDecorationTypeChange(part, e.target.value)}
                                        >
                                            <option value=""></option>
                                            <option value="unpainted">Unpainted</option>
                                            <option value="coil">Coil</option>  
                                            <option value="paint">Paint</option>  
                                            <option value="plastic">Plastic</option>
                                            <option value="overlay">Overlay</option>
                                            <option value="vinyl">Vinyl</option>
                                            <option value="print">Print</option>
                                        </FormControl>
                                    }
                                </td>
                                <td>
                                    {(part.selected_ind == 1) && 
                                        <Form.Check 
                                            name="upcharge_ind"                                
                                            value={part.id}
                                            checked={part.upcharge_ind == 1}
                                            inline 
                                            label={part.upcharge_ind == 1 ? "Yes":"No"}
                                            type="checkbox" 
                                            onChange={onUpchargeChange}
                                        />
                                    }
                                </td>                                
                            </tr>
                        ))}
                        </tbody>
                    </Parts>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default ColorPartsDrawer;