import React, { useState, useEffect } from "react";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal';
import produce from "immer"
import { addBusinessDays, addDays, differenceInCalendarDays, format, isWeekend, parseISO }  from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs } from '@fortawesome/free-solid-svg-icons'

import ListField from "../components/fields/field-list"
import GoogleImage from "../components/google-image"
import { postData } from "../common/services/server";
import { colors } from "../settings/settings";
import JobTask from "../components/job-task";

const Sign = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;

  img { margin-right: 10px; }
`

const Tasks = styled.div`
  > div {
    display: grid;
    grid-template-columns: 100px 1fr;

    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 5px;

    > div:first-child {
      background-color: #eee;
      padding: 10px;
      text-align: center;

      &.today {
        background-color: ${colors.lightGreen};
      }
    }
    > div:last-child {
      > div {
        display: grid;
        grid-template-columns: 1fr 150px;
        gap: 5px;
        border-bottom: 1px solid #eee;
        padding: 3px;
      }
    }
  }
`

function JobTasksDrawer(props) {
  
  const [tasks, setTasks] = useState([])
  const [statuses, setStatuses] = useState([]);
  const [busy, setBusy] = useState([]);

  useEffect(
    () => {      
        postData("jobs/taskstatuses", {},
            function(results) {
                setStatuses(results)
            },
            function(error) {
                alert("Error loading tasks statuses")
            }
        );       
    }, 
    []
  );

  useEffect(
    () => {      
      setTasks(JSON.parse(JSON.stringify(props.job.tasks)));
    }, 
    [props.job]
  );

  const shipDate = parseISO(props.job.ship_date.split("T")[0]);
  const startDate = addBusinessDays(shipDate, -(props.job.days-1));

  const today = new Date();
  const days = [];
  let day = 1;

  for (let i=0; i<=differenceInCalendarDays(shipDate, startDate); i++) {
      const dayDate = addDays(startDate, i);
      const weekend = isWeekend(dayDate);

      days.push({
          day: weekend ? 0 : day,
          date: dayDate,
          weekend,
          today: differenceInCalendarDays(today, dayDate) == 0
      });     
      
      if (!weekend) day++
  }

  function removeBusy(name) {
    setBusy(produce(draft => {
      let idx = draft.indexOf(name);

      if (idx != -1) {
        draft.splice(idx, 1);
      }  
    }))
  }
  
  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faCogs} />
            &nbsp;
            Job Tasks
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Sign>
                <GoogleImage 
                    root 
                    src={props.job.preview_url} 
                    style={{
                        maxWidth: "125px",
                        maxHeight: "75px"
                    }}
                />
            </Sign>
                
            <Tasks>
            {days.map((day,index) => (
              <div key={index}>
                <div className={day.today ? "today" : day.weekend ? "weekend":""}>
                  <div>{day.day > 0 ? <>Day {day.day}</> : ' '}</div>
                  <div><small>{format(day.date, "EEEE")}</small></div>
                  <div><small>{format(day.date, "MMM do")}</small></div>
                </div>
                <div>
                {tasks.filter(t => t.target_day == day.day).map(task => (
                    <div key={task.id}>
                      <JobTask
                        task={task}
                      />
                      <ListField 
                          name="status_id" 
                          value={task.status_id} 
                          list={statuses}
                          disabled={busy.indexOf("status" + task.id) != -1}
                          groupStyle={{
                            margin: "0px"
                          }}
                          onChange={(e) => {
                            e.persist();

                            const statusId = e.target.value;

                            setBusy(produce(draft => {
                              draft.push("status" + task.id)
                            }));

                            postData("jobs/updatetaskprop", {
                              job_id: props.job.id,
                              jobtask_id: task.id,
                              prop: "status_id",
                              value: statusId
                            },
                                function(response) {
                                  props.onChange({
                                    ...response,
                                    ship_date: response.ship_date.split("T")[0]
                                  });
                                },
                                function(error) {
                                    alert("Error updating job task status")
                                },
                                function() {
                                  removeBusy("status" + task.id);
                                }
                            );
                          }} 
                      />
                      {/* <ListField 
                          name="assigned_user_id" 
                          value={task.assigned_user_id || ""} 
                          list={props.users}
                          disabled={busy.indexOf("assigned" + task.id) != -1}
                          groupStyle={{
                            margin: "0px"
                          }}
                          onChange={(e) => {
                            e.persist();

                            const userId = e.target.value;

                            setBusy(produce(draft => {
                              draft.push("assigned" + task.id)
                            }));

                            postData("jobs/updatetaskprop", {
                              job_id: props.job.id,
                              jobtask_id: task.id,
                              prop: "assigned_user_id",
                              value: userId
                            },
                                function() {
                                  let jobData = JSON.parse(JSON.stringify(props.job));
                                  let taskIdx = jobData.tasks.findIndex(t => t.id == task.id);

                                  if (taskIdx != -1) {
                                    jobData.tasks[taskIdx].assigned_user_id = userId;
                                    jobData.tasks[taskIdx].assigned_user = userId ? props.users.find(u => u.id == userId).name : "";

                                    props.onChange(jobData);
                                  }
                                },
                                function(error) {
                                    alert("Error updating job task")
                                },
                                function() {
                                  removeBusy("assigned" + task.id);
                                }
                            );
                          }} 
                      /> */}
                    </div>
                ))}
                </div>
              </div>
            ))}              
            </Tasks>
        </Modal.Body>
      </Modal>
    )
}

export default JobTasksDrawer;