import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";


 export default function DropdownMenu({
    icon,
    caption,
    size,
    className,
    direction="down", // down/up
    align="right", // right/left
    busy,
    disabled,
    menu,
    onMenuItem
}) {
    const [open, setOpen] = useState(false);

    useEffect(
        () => {
            if (disabled)
                setOpen(false);
        }, 
        [disabled]
    );
    
    return (
        <div className="relative">
            <button
                className={clsx(
                    "flex items-center gap-1 py-1 px-2 hover:bg-gray-100 whitespace-nowrap",
                    caption ? "rounded" : "rounded-full",
                    size == "sm" && "text-sm",
                    className
                )}
                disabled={disabled || busy}
                onClick={(e) => {
                    e.stopPropagation();

                    setOpen(prev => !prev);
                }}
            >
                {icon && <FontAwesomeIcon icon={busy ? faSpinner:icon} spin={busy} className={caption ? "mr-1":""} size={size=="sm"?"sm":"1x"} />}
                {caption && <div>{caption}</div>}
            </button>
            {open && 
                <>
                    <div 
                        className="fixed left-0 top-0 right-0 bottom-0 z-40"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(false);
                        }}
                    >
                        &nbsp;
                    </div>
                    <div className={clsx(
                        "absolute border border-gray-300 rounded bg-white z-50",
                        align == "left" ? "left-0" : "right-0",
                        direction == "up" ? "bottom-8" : "top-8",
                    )}>
                        {menu.filter(m => !m.hidden).map((item,index) => (
                            <button 
                                key={index}
                                className={clsx(
                                    "border-b border-gray-300 p-2 w-full",
                                    "hover:bg-gray-100 ",
                                    "disabled:bg-gray-100 disabled:text-gray-400",
                                    "flex items-center gap-1 ",
                                    "text-black",
                                    "whitespace-nowrap ",
                                    "text-sm",
                                    "cursor-pointer",
                                    item.className
                                )}
                                disabled={item.disabled}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    
                                    if (!item.confirmation || item.confirmation.length==0 || window.confirm(item.confirmation)) {
                                        onMenuItem(item);
                                        setOpen(false)
                                    }
                                }}
                            >
                                {item.icon && <FontAwesomeIcon icon={item.icon} fixedWidth />}
                                {item.caption}
                            </button>
                        ))}
                    </div>
                </>
            }
        </div>
    )
}