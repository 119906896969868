import React, { useEffect, useState } from "react";

import BoardField from "../components/board/board-field"
import { postData } from "../common/services/server";

function Panel({
  product,
  onChange
}) {
  const [shapes, setShapes] = useState([]);

  useEffect(
    () => {
      if (product.builder_type == "shapes" && product.styles.length > 0) {
        postData("shapes/data", {
          style_id: product.styles[0].id,
          user_ind: 0
        },
          function(result) {
              setShapes(result);
          },
          function(error) {
              alert("Error loading shapes")
          }
        );
      }
    }, 
    []
  );

  return (
    <>
      {product.sign_ind == 1 && 
        <BoardField
          type="list"
          label="Builder Type"
          info="Controls what the user is shown when adding this product in the builder"
          value={product.builder_type}
          items={[
            {value: "letters", label: "Letters"},
            {value: "shapes", label: "Shape"},
            {value: "cabinet", label: "Cabinet"},
          ]}
          saveUrl={`products/updateprop?product_id=${product.id}&prop=builder_type`}
          onSaved={onChange}
        />
      }     
      {(shapes && shapes.length > 0) &&
        <BoardField
          type="list"
          label="Default Shape"
          info="Shape that is selected by default in the Builder for this product."
          value={product.default_shape_id}
          items={shapes}
          saveUrl={`products/updateprop?product_id=${product.id}&prop=default_shape_id`}
          onSaved={onChange}
        />     
      }    
      <BoardField
        type="googleimage"
        label="Default Background"
        value={product.default_background_url}
      />         
    </>
  );
}

export default Panel;