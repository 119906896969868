import React, { useState } from "react";
import clsx from "clsx";
import { produce } from "immer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";

import Button from "../components/buttons/button";
import DrawerTemplateData from "./template-data";

import { postData } from "../common/services/server";
import { removeNulls } from "../helpers/utility";
import ButtonMutate from "../components/buttons/button-mutate";

export default function DrawerTemplateDataSelect({    
    query,
    selectCaption,
    itemClassName,
    itemRender,
    itemFilter,
    itemExistsCheck,
    onItemSelected,
    selectMutation,
    selectMutationDataProp,
    selectMutationSuccess,
    ...props
  }) {

  return (
    <DrawerTemplateData
      {...props}
      query={query}
      noDataFound={!query.data || query.data.length == 0}
    >
        <div className="grid gap-1">
            {query.data?.filter(i => !itemFilter || itemFilter(i)).map((item,index) => {
                const exists = itemExistsCheck ? itemExistsCheck(item) : false;

                return (
                    <div key={index} className={clsx(
                        "flex items-center justify-between gap-2 p-1 border border-gray-200 rounded",
                        exists && "bg-gray-100",
                        itemClassName,
                    )}>
                        {itemRender(item)}
                        
                        {!exists && 
                          <>
                            {selectMutation ?
                              <ButtonMutate
                                icon={faPlus}
                                caption={selectCaption}
                                size="sm"
                                variant="outline-success"
                                mutation={selectMutation}
                                mutationData={item[selectMutationDataProp]}
                                onMutateSuccess={selectMutationSuccess}
                                disabled={selectMutation.isLoading}
                                className="whitespace-nowrap"
                              />
                            :
                              <Button
                                icon={faPlus}
                                size="sm"
                                variant="outline-success"
                                onClick={() => onItemSelected(item)}
                                className="whitespace-nowrap"
                              >
                                {selectCaption || "Select"}
                              </Button>
                            }
                          </>
                        }
                    </div>
                )
            })}
        </div>
    </DrawerTemplateData>  
  )
}
