import React from "react";
import clsx from "clsx";

import Field from "./field";
import Select from "./select";

function FieldSelect({
  selectClassName,
  className,
  ...props
}) {
  return (
    <Field className={className} {...props}>
      <Select
        className={clsx(
          "border-0 m-0 p-1 w-full",
          selectClassName
        )}        
        {...props}
      />
    </Field>
  )
}

export default FieldSelect