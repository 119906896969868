import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetOrders = (params, options={}) => {
  return useQuery({ 
    queryKey: ["orders", params], 
    queryFn: ({signal}) => query("orders/list", params, signal),
    ...options
  });
}

export const useGetOrderNotes = (orderId, options={}) => {
  return useQuery({ 
    queryKey: ["orders", ""+orderId, "notes"], 
    queryFn: ({signal}) => query("orders/order/notes", { order_id: orderId }, signal),
    ...options
  });
}
