import React, { useEffect, useState, useRef } from "react";
import ReactToPrint from 'react-to-print';
import styled from "styled-components"

import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faTicketAlt } from '@fortawesome/free-solid-svg-icons'

import '../mwos.css'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import MwoSticker from "../components/mwo-sticker"
import MwoStickerBox from "../components/mwo-stickerbox"

import { postData } from "../common/services/server"

const Stickers = styled.div`

`

function MWOsDrawer(props) {
  const stickersRef = useRef();

  const [loading, setLoading] = useState(false);
  const [stickers, setStickers] = useState([]);

  useEffect(
    () => {
        setLoading(true);

        const shapeIds = [];
        const mwoIds = [];

        if (props.shapes) {
            for (const shape of props.shapes) {
                shapeIds.push(shape.id);
            }
        }
        if (props.mwos) {
            for (const mwo of props.mwos) {
                for (const mwoId of mwo.ids.split(",")) {
                    mwoIds.push(mwoId);
                }
            }
        }

        postData("mwos/stickers", {
                job_id: props.job.id,
                shape_ids: shapeIds,
                mwo_ids: mwoIds,
                ...props.counts
            }, 
            function(response) {
                setStickers(response.stickers);
            },
            function(error) {
                alert("An error occured loading mwo stickers.");
            },
            function() {
                setLoading(false);
            }
        );
    }, 
    []
  );

  function onPrint() {

  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faTicketAlt} />
            &nbsp;
            MWOs
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { loading ? 
                <Loading />
            :
                <Stickers ref={stickersRef}>
                {(stickers.map((sticker,index) => (
                    <div key={index}>
                    {
                    sticker.type == "header" ? 
                        <MwoStickerBox className="lines1">
                            <div className="line line1 columns1">
                                <div className="column">
                                    <h5 style={{textAlign:"right"}}>
                                        {sticker.job}
                                    </h5>
                                    <h3>{sticker.title}</h3>
                                </div>
                            </div>
                        </MwoStickerBox>
                    :
                        <MwoSticker job={props.job} mwo={sticker} />
                    }
                </div>
                )))}
                </Stickers>
            }
        </Modal.Body>
        <Modal.Footer>
            {!loading && 
                
                <ReactToPrint
                    copyStyles={false}
                    trigger={() => 
                        <Button variant="primary"> 
                            <FontAwesomeIcon icon={faPrint} />{' '}
                            Print
                        </Button> 
                    }
                    content={() => stickersRef.current}
                    pageStyle={`
                        @page {
                            size: landscape;
                            size: 0.96in 2.95in landscape;
                            margin: 0;
                        }

                        html, body {
                            font-family:Tahoma, Geneva, sans-serif;
                            width:2.95in;
                            height:0.96in;
                            min-width: 2.95in;
                            max-width: 2.95in;
                            min-height: 0.96in;
                            max-height: 0.96in;
                            margin:0;
                            padding:0;                            
                            font-size:10px;
                        }
                        * {
                            box-sizing: border-box;
                        }
                        .stickerBox {
                            background-color: transparent;
                            width: 2.95in;
                            height: 0.96in;
                            margin: 0;
                            padding: 0;
                            overflow: hidden;
                            border-radius: 0px;
                            page-break-after: always;
                            border: 4px solid transparent;
                        }
                        .stickerBox > div {
                            padding: 0px;
                            border: 0px;
                            display: flex;
                            font-size: 9px;
                        }
                        .stickerBox div.info {
                            flex: 1;
                            border-top: 1px solid black;
                        }
                        .stickerBox div.job {
                            flex-basis: 65px;
                            border: 0px !important;
                            text-align: center;
                            display: flex;
                            flex-flow: column;
                            align-items: center;
                            padding: 0px;
                            font-size: 10px;
                        }
                        .stickerBox div.job svg {
                            margin-top: 0px;
                        }
                        .stickerBox div.line {
                            display: flex;
                        }
                        .stickerBox div.line > div {
                            height: .215in;
                            overflow: hidden;
                            padding: 2px;
                            flex: 1;
                            border: 1px solid black;
                            border-top: 0px;
                            vertical-align: center;
                            padding-top: 3px;
                        }
                        
                        .stickerBox div.lines1 {
                            border-top: 0px;
                        }
                        .stickerBox div.lines1 .line, .stickerBox div.lines1 .column {
                            height: 0.80in;
                            overflow: hidden;
                            border: 0px;
                            padding: 3px;                            
                        }
                        .stickerBox div.lines2 .line {
                            height: .43in;
                        }
                        .stickerBox div.lines2 .line > div {
                            height: .43in;
                        }
                        .stickerBox div.lines3 .line3 {
                            height: .43in;
                        }
                        .stickerBox div.lines3 .line3 > div {
                            height: .43in;
                        }
                        
                        .stickerBox .columns1 {
                            flex-basis: 100%;
                        }

                        .stickerBox .columns2 > div:nth-child(1) {
                            border-right: 0px;
                        }  
                        .stickerBox .columns3 > div:nth-child(1), .stickerBox .columns3 > div:nth-child(2) {
                            border-right: 0px;
                        }                     

                        .stickerBox .columns2 > div.size30,
                        .stickerBox .columns2 > div.size35,
                        .stickerBox .columns2 > div.size40,

                        .stickerBox .columns3 > div.size20,
                        .stickerBox .columns3 > div.size25,
                        .stickerBox .columns3 > div.size30
                        {
                            padding: 2px;
                            font-size: 8px;
                            line-height: 1em;
                        }

                        .stickerBox img {
                            height: .18in;
                            display: block;
                            margin: 0px auto;
                        }
                 
                        .stickerBox .dynamic {
                            display: flex;
                            margin: -3px 0px;
                        }
                        .stickerBox .dynamic2 > div {
                            flex-basis: 50%;
                            padding: 2px;
                        }
                        .stickerBox .dynamic3 > div {
                            flex-basis: 33%;
                            padding: 2px;
                        }
                        .stickerBox .dynamic .column2 {
                            border-left: 2px solid black;
                        }
                        .stickerBox .dynamic .column3 {
                            border-left: 2px solid black;
                        }
                        .stickerBox div.lines3 .line3 .dynamic {
                            height: 50px;
                        }

                        h3 {
                            font-weight: bold;
                            font-size: 24px;
                            margin-top: 20px;
                        }
                        h5 {
                            margin: 0px 0px 5px 0px;
                            font-size: 18px;
                        }
                    `}
                />
            }
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default MWOsDrawer;