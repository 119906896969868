import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote, faCreditCard, faTrash, faPencilAlt, faEnvelope, faBan, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

import produce from "immer"

import PropertyList from "../../components/properties/property-list"
import Property from "../../components/properties/property"
import PaymentDrawer from "../../drawers/drawer-payment"
import EmailDrawer from "../../drawers/drawer-email";
import AddressForm from "../../components/forms/form-address"
import Loading from "../../components/loading"
import Button from "../../components/buttons/button";
import ButtonProcessing from "../../components/buttons/button-processing"
import { postData } from "../../common/services/server"
import { formatDateTime, formatPrice } from '../../helpers/utility'
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";
import { colors } from "../../settings/settings"
import Coupon from "../../components/coupon";
import settings from "../../settings";

function OrderBillingPanel(props) {
    const { order } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [payments, setPayments] = useState([]);
    const [selected, setSelected] = useState(null);

    const [canEdit, setCanEdit] = useState(userHasPermission(Permissions.Orders, Actions.Edit))
    const [editProp, setEditProp] = useState("");

    useEffect(
      () => {
          setIsLoading(true);

          postData("orders/payments", {
                order_id: order.id
            }, 
            function(response) {
              setPayments(response)
            },
            function(error) {
              alert("An error occured loading payment data.");
            },
            function() {
              setIsLoading(false);
            }
          );
      }, 
      []
    );
    
    function onAddPayment() {
        setSelected({mode:"addpayment"});
    }
    function onRefundPayment() {
        setSelected({mode:"addpayment", refund: true});
    }
    function onEditPayment(payment) {
        console.log("edit payment", payment)
        setSelected({mode:"editpayment", payment})
    }
    function onDeletePayment(payment) {
        setSelected({mode:"deletepayment", payment})
        setIsProcessing(true)

        postData("orders/deletepayment?order_id=" + order.id, {
                id: payment.id
            }, 
            function(response) {
                setPayments(produce(draft => {
                    const index = draft.findIndex(p => p.id == payment.id);

                    if (index !== -1)
                        draft.splice(index, 1)
                }));
            },
            function(error) {
                alert("An error occured updating the order.");
            },
            function() {
                setIsProcessing(false);
                setSelected(null)
            }
        );
    }

    const total = payments.reduce((a, b) => +a + +b.amount, 0);
    const orderTotal = parseFloat(order.total);
    const balance = orderTotal - total;

    return (
    <>
        <Row>
            <Col xs={12} sm={5}>
                <AddressForm 
                    address={order}
                    phoneno={order.user_phoneno}
                    disabled={!userHasPermission(Permissions.Orders, Actions.Edit)}
                    prefix="billing_"
                    saveUrl={"orders/updateaddress?type=billing&order_id=" + order.id}
                    onChange={(response) => props.onOrderChange(response)}
                    alternativeNames={[
                        {type: "Customer", name :order.user_name},
                        {type: "Shipping", name: order.ship_name}
                    ]}
                />
            </Col>
            <Col>
                <PropertyList>
                    <Property label="Order Cost">
                        {editProp == "cost" ?
                            <InputGroup>
                                <FormControl
                                    disabled={!canEdit}
                                    value={order.cost}
                                    onChange={(event) => props.onPropChange("cost", event.target.value) }            
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => props.onSaveProp("cost")} disabled={!canEdit} variant={props.dirty.cost ? "primary":"outline-secondary"}>
                                        <FontAwesomeIcon icon={props.busy && props.busy.cost ? faSpinner:faSave} spin={props.busy && props.busy.cost} />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        :
                            <>
                                {formatPrice(order.cost)}
                                &nbsp;
                                <FontAwesomeIcon icon={faPencilAlt} onClick={() => setEditProp("cost")} style={{cursor: "pointer"}} />
                            </>
                        }                        
                    </Property>
                    <Property label="Discount">
                        {editProp == "discount" ?
                            <InputGroup>
                                <FormControl
                                    disabled={!canEdit}
                                    value={order.discount}
                                    onChange={(event) => props.onPropChange("discount", event.target.value) }            
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => props.onSaveProp("discount")} disabled={!canEdit} variant={props.dirty.discount ? "primary":"outline-secondary"}>
                                        <FontAwesomeIcon icon={props.busy && props.busy.discount ? faSpinner:faSave} spin={props.busy && props.busy.discount} />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        :
                            <>
                                {formatPrice(order.discount)}
                                &nbsp;
                                <FontAwesomeIcon icon={faPencilAlt} onClick={() => setEditProp("discount")} style={{cursor: "pointer"}} />
                            </>
                        }
                    </Property>
                    <Property label="Coupon">
                        {editProp == "coupon_code" ?
                            <InputGroup>
                                <FormControl
                                    disabled={!canEdit}
                                    value={order.coupon_code}
                                    onChange={(event) => props.onPropChange("coupon_code", event.target.value) }            
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => props.onSaveProp("coupon_code")} disabled={!canEdit} variant={props.dirty.coupon_code ? "primary":"outline-secondary"}>
                                        <FontAwesomeIcon icon={props.busy && props.busy.coupon_code ? faSpinner:faSave} spin={props.busy && props.busy.coupon_code} />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        :
                            <>
                                {order.coupon_code ?
                                    <>
                                        <Coupon code={order.coupon_code} />
                                        &nbsp;
                                        <small>{order.coupon_title}</small>
                                    </>
                                :
                                    <span>None</span>
                                }                                
                                &nbsp;
                                <FontAwesomeIcon icon={faPencilAlt} onClick={() => setEditProp("coupon_code")} style={{cursor: "pointer"}} />
                            </>
                        }
                    </Property>
                    {order.coupon_discount && 
                        <Property label="Coupon Discount" value={formatPrice(order.coupon_discount)} />
                    }
                    <Property label="Tax">
                        {editProp == "tax" ?
                            <InputGroup>
                                <FormControl
                                    disabled={!canEdit}
                                    value={order.tax}
                                    onChange={(event) => props.onPropChange("tax", event.target.value) }            
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => props.onSaveProp("tax")} disabled={!canEdit} variant={props.dirty.tax ? "primary":"outline-secondary"}>
                                        <FontAwesomeIcon icon={props.busy && props.busy.tax ? faSpinner:faSave} spin={props.busy && props.busy.tax} />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        :
                            <>
                                {formatPrice(order.tax)}
                                &nbsp;
                                <FontAwesomeIcon icon={faPencilAlt} onClick={() => setEditProp("tax")} style={{cursor: "pointer"}} />
                            </>
                        }                        
                    </Property>
                    <Property label="Shipping Surcharge">
                        {editProp == "shipping_surcharge" ?
                            <InputGroup>
                                <FormControl
                                    disabled={!canEdit}
                                    value={order.shipping_surcharge}
                                    onChange={(event) => props.onPropChange("shipping_surcharge", event.target.value) }            
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => props.onSaveProp("shipping_surcharge")} disabled={!canEdit} variant={props.dirty.shipping_surcharge ? "primary":"outline-secondary"}>
                                        <FontAwesomeIcon icon={props.busy && props.busy.shipping_surcharge ? faSpinner:faSave} spin={props.busy && props.busy.shipping_surcharge} />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        :
                            <>
                                {formatPrice(order.shipping_surcharge)}
                                &nbsp;
                                <FontAwesomeIcon icon={faPencilAlt} onClick={() => setEditProp("shipping_surcharge")} style={{cursor: "pointer"}} />
                            </>
                        }                           
                    </Property>
                    <Property label="Total">
                        <span style={{color: order.voided ? "#999" : colors.green, fontWeight: "bold", textDecoration: order.voided ? "line-through":"none"}}>
                            {formatPrice(parseFloat(order.total))}
                        </span>
                        {order.voided && <span style={{color: "red", marginLeft: "8px"}}>VOID</span>}
                    </Property>
                </PropertyList>

                <div style={{margin: "15px 0px"}}>
                    Payments
                </div>
                {isLoading ? 
                    <Loading size="sm" />
                :
                    <>
                        <Table size="sm" bordered>                    
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Data</th>
                                    <th>Amount</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                            {payments.map(payment => (
                                <>
                                    <tr>
                                        <td>{formatDateTime(payment.payment_date)}</td>
                                        <td>{payment.type}</td>
                                        <td>
                                            {payment.check_no && <>Check No: {payment.check_no}</>}
                                            {payment.affirm_id && <>Affirm ID: {payment.affirm_id}</>}
                                        </td>
                                        <td>
                                            {formatPrice(payment.amount)}
                                        </td>
                                        <td>
                                            <ButtonProcessing 
                                                processing={false}
                                                onClick={() => onEditPayment(payment)} 
                                                variant="outline-primary" 
                                                icon={faPencilAlt}
                                                size="sm" 
                                                style={{padding:"2px"}}
                                            /> 
                                            <ButtonProcessing 
                                                processing={isProcessing && selected && selected.mode=="deletepayment" && selected.payment && selected.payment == payment}
                                                onClick={() => onDeletePayment(payment)} 
                                                variant="outline-danger" 
                                                icon={faTrash}
                                                size="sm" 
                                                style={{padding:"2px"}}
                                            /> 
                                        </td>
                                    </tr>
                                    {payment.notes && 
                                        <tr>
                                            <td colSpan={5}>
                                                <FontAwesomeIcon icon={faStickyNote} />{' '}
                                                {payment.notes}
                                            </td>
                                        </tr>
                                    }
                                </>
                            ))}
                            </tbody>
                            {(payments.length > 0) && 
                                <tfoot>
                                    <tr>
                                        <td colSpan={5} style={{backgroundColor:"#eee", textAlign:"right", paddingRight:"75px"}}>
                                            Payments Total: { formatPrice(total) }
                                        </td>                                       
                                    </tr>
                                    <tr>
                                        <td colSpan={5} style={{backgroundColor:"#eee", textAlign:"right", paddingRight:"75px"}}>
                                            Order Total: 
                                            
                                            {order.voided && 
                                                <span style={{color:"red", margin: "0px 5px"}}>VOID</span>
                                            }

                                            <span style={{textDecoration: order.voided ? "line-through":"none"}}>
                                                { formatPrice(order.voided ? 0 : orderTotal) }
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5} style={{backgroundColor:"#eee", textAlign:"right", paddingRight:"75px", color: balance > 0 && !order.voided ? "red":"inherit"}}>
                                            Balance: { formatPrice(order.voided ? 0 : balance) }
                                        </td>
                                    </tr>
                                </tfoot>
                            }
                        </Table>

                        <ButtonProcessing 
                            processing={false}
                            onClick={onAddPayment} 
                            variant={order.voided ? "outline-danger" : "outline-success"}
                            caption={order.voided ? "Void Payment" : "Add Payment"}
                            disabled={!userHasPermission(Permissions.Orders, Actions.Edit)}
                            icon={faCreditCard} 
                        /> 

                        {(!order.voided) && 
                            <ButtonProcessing 
                                processing={false}
                                onClick={onRefundPayment} 
                                variant="outline-secondary"
                                caption="Refund Partial"
                                disabled={!userHasPermission(Permissions.Orders, Actions.Edit)}
                                icon={faBan} 
                            />                         
                        }

                        {(balance > 0 && !order.voided) && 
                            <>
                            <Button
                                variant="outline-secondary"
                                onClick={() => setSelected({mode:"paymentemail"})}
                            >
                                <FontAwesomeIcon icon={faEnvelope} />{' '}
                                Request Payment
                            </Button>
                            <Button
                                icon={faCreditCard}
                                variant="outline-secondary"
                                onClick={() => window.open(settings.ORDERDETAILS_URL + order.uid)}
                            >
                                Make Customer Payment
                            </Button>                        
                            </>
                        }
                    </>
                }
            </Col>
        </Row>
        { selected && (selected.mode == "addpayment" || selected.mode == "editpayment") && 
            <PaymentDrawer 
                order={order} 
                payment={selected.payment || {amount: selected.refund ? -1 : "", notes: selected.refund ? "Partial Refund":""}}
                refund={selected.refund}
                show={true}
                onSaved={(result) => {
                    setPayments(produce(draft => {
                        const index = draft.findIndex(p => p.id == result.id);

                        if (index == -1)
                            draft.push(result);
                        else 
                            draft[index] = result;
                    }));
                    setSelected(null);
                }} 
                onHide={ () => setSelected(null) } 
            /> 
        }
        { selected && selected.mode == "paymentemail" && 
            <EmailDrawer 
                bodyUrl={"emails/template?template=Payment%20Request&order_id=" + order.id}
                email={{
                    order_id: order.id,
                    template: "simple",
                    from: "sales@signmonkey.com",
                    to: order.user_email,
                    subject: "Order #" + order.id + " Payment Request",
                    body: ""
                }}
                show={true}
                onSaved={(email) => {
                    setSelected(null);
                }}
                onHide={ () => setSelected(null) } 
            /> 
        } 
    </>
    );
}

export default OrderBillingPanel;