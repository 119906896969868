import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal';
import { produce } from 'immer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faInfoCircle, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import Error from "../components/error"
import GoogleImage from "../components/google-image"
import PropertyList from "../components/properties/property-list"
import Property from "../components/properties/property"

import { postData, postExternalData } from "../common/services/server"
import settings from "../settings"

import { useGetSign, useGetSignPriceData } from "../api/queries/signs";

const GroupsData = styled.ul`
    list-style-type: none;
    padding: 0px;
    margin: 0px;

    > li {
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 3px;
        
        > h3 {
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1em;
            background-color: #eee;
            margin: 1px;
            border-radius: 5px;
            font-weight: normal;
            cursor: pointer;
        }

        svg {
            margin-left: 8px;
        }
    }
`

function SignPriceDrawer({
    signId,
    priceData, // for historical calculations
    onHide
}) {  
  const [expanded, setExpanded] = useState([]);

  const [shape, setShape] = useState(null);
  const [show, setShow] = useState("");

  const { data:sign, isLoading: isSignLoading, error: signError } = useGetSign(signId, {
    cacheTime: 0,
    staleTime: 0
  });
  const { data, isLoading, error } = useGetSignPriceData(signId, {
    enabled: !priceData,
    initialData: priceData
  }); 

  /*
  useEffect(() => {
    if (props.show && sign && !props.data) {
        setError("");
        setLoading("Loading Price...")

        postExternalData(settings.PRICE_URL, { partnotes_ind: 1, sign_id: sign.id },
            function(result) {
                setData(result);
            },
            function(error) {
                console.log(error);
                setError("Unable to load pricing data.");
            },
            function() {
                setLoading("");
            }
        );

    }
  }, [sign]);
  */
  /*
  useEffect(() => {
    if (props.data) {
        setLoading("Loading Sign...")

        postData("signs/signdata", { id: props.signId },
            function(result) {
                setSign(result.sign);
            },
            function() {
                window.alert("Error loading sign data");
            },
            function() {
                setLoading("")
            }
        );
    }
  }, [props.data]);
  */

  function isExpanded(index) {
    return expanded.indexOf(index) != -1;    
  }

  function onExpand(index) {
    setExpanded(produce(draft => {
        const pos = draft.indexOf(index);

        if (pos == -1)
            draft.push(index);
        else 
            draft.splice(pos, 1)
    }))
  }

  function getGroupShippingPartsTotal(groupId) {
    let total = 0;

    for (const part of data.shipping.parts.filter(p => p.signgroup_id == groupId)) {
        total += parseFloat(part.total);
    }

    return total;
  }

  return (
    <>
      <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faDollarSign} />
            &nbsp;
            Sign Price
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                (isLoading || isSignLoading) > 0 ? 
                    <Loading />
                : (error || signError) ?
                    <Error error={error || signError} />
                : (data && sign) ?
                    <>
                        <GoogleImage root src={sign.preview_url} style={{
                            maxHeight: "200px",
                            display: "block",
                            margin: "0px auto"
                        }} />

                        <GroupsData>
                        {data.groups.map((group,index) => {
                            return (
                                <li key={index}>
                                    <h3 onClick={(e) => onExpand(index)}>
                                        Group: {group.codes}
                                        <div>
                                            <strong>
                                                {group.estimateWithoutShipping ? <span>${group.estimateWithoutShipping.toFixed(2)}</span> : <span style={{color:"red"}}>ERROR</span>}
                                            </strong>

                                            <FontAwesomeIcon icon={isExpanded(index) ? faCaretUp : faCaretDown} />
                                        </div>
                                    </h3>
                                    <div style={{display: isExpanded(index) ? "block":"none"}}>
                                        <PropertyList>
                                            <Property label="Id" value={group.id} />
                                            <Property label="Product" value={group.product || "?"} danger={!group.product} />
                                            <Property label="Size" value={group.size} />
                                            <Property label="Style" value={group.style || "?"} danger={!group.style} />
                                            
                                            <Property label="Shapes">   
                                                <GroupsData>
                                                {group.shapes.map((shape,shapeIndex) => (
                                                    <li key={shapeIndex}>
                                                        <h3 onClick={(e) => onExpand(index + "shape" + shapeIndex)}>
                                                            {shape.code}
                                                            <div>
                                                                <strong>
                                                                    ${parseFloat(shape.priceWithoutShipping).toFixed(2)}
                                                                </strong>

                                                                <FontAwesomeIcon icon={isExpanded(index + "shape" + shapeIndex) ? faCaretUp : faCaretDown} />
                                                            </div>                                                            
                                                        </h3>
                                                        <div style={{display: isExpanded(index + "shape" + shapeIndex) ? "block":"none"}}>
                                                            <PropertyList>
                                                                {shape.shape_size != group.size && 
                                                                    <Property label="Size" value={shape.shape_size} danger />
                                                                }
                                                                <Property label="Dimensions">
                                                                    <div>{shape.shape_height.toFixed(2) + " x " + shape.shape_width.toFixed(2)}</div>
                                                                    {/* <div>
                                                                        <small>
                                                                            (
                                                                                {signGroup.size}*{shape.shape_height_ratio.toFixed(2)} 
                                                                                x
                                                                                {signGroup.size}*{shape.shape_width_ratio.toFixed(2)} 
                                                                            )
                                                                        </small>
                                                                    </div> */}

                                                                    <div><small>({shape.shape_sq_inches.toFixed(2)} Sq In)</small></div>
                                                                    <div><small>({shape.shape_perim_inches.toFixed(2)} Perim In)</small></div>
                                                                </Property>
                                                                <Property label="Shape Base Price" value={"$" + parseFloat(shape.shape_base_price).toFixed(2)} />  
                                                                <Property label="Parts Total">
                                                                    ${parseFloat(shape.partsTotalWithoutShipping).toFixed(2)}
                                                                    &nbsp;
                                                                    <FontAwesomeIcon icon={faInfoCircle} style={{cursor: "pointer"}} onClick={() => {
                                                                        setShape(shape);
                                                                        setShow("parts")
                                                                    }} />
                                                                </Property>
                                                                <Property label="Shape Price" value={"$" + shape.totalWithoutShipping.toFixed(2)} />
                                                                <Property label="Style Mod" value={parseFloat(shape.style_modifier).toFixed(2)} />
                                                                <Property label="Shape Mod" value={parseFloat(shape.shape_modifier).toFixed(2)} />
                                                                <Property label="Shape Consumption" value={shape.shape_consumption} />
                                                                <Property label="Total" value={"$" + parseFloat(shape.priceWithoutShipping).toFixed(2)} styles={{fontWeight: "bold"}} />
                                                            </PropertyList>                                                          
                                                        </div>
                                                    </li>
                                                ))}
                                                </GroupsData>
                                            </Property>
                                        </PropertyList>
                                    </div>
                                </li>
                            )
                        })}
                        </GroupsData>
                        {(data.groups.length > 1) && 
                            <div style={{textAlign: "right", paddingRight: "25px"}}>
                                Base Groups Cost = <strong>${(parseFloat(data.cost.base) - parseFloat(data.shipping.partsCost)).toFixed(2)}</strong>
                            </div>
                        }

                        <br />

                        <PropertyList>
                            <Property label="Groups Cost">
                                ${(parseFloat(data.cost.base) - parseFloat(data.shipping.partsCost)).toFixed(2)}
                                &nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} style={{cursor: "pointer"}} onClick={() => setShow("groups")} />
                            </Property>
                            <Property label="Setup Cost">
                                ${parseFloat(data.setup.cost).toFixed(2)}
                                &nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} style={{cursor: "pointer"}} onClick={() => setShow("setup")} />
                            </Property>
                            <Property label="Options Cost">
                                ${parseFloat(data.cost.options).toFixed(2)}
                            </Property>
                            <Property label="Shipping Cost">
                                <PropertyList>
                                    <Property label="Base">
                                        ${parseFloat(data.shipping.baseCost).toFixed(2)}
                                        &nbsp;
                                        (                                            
                                            {data.shipping.method.title}
                                            {(data.shipping.boxData.boxes.length > 1) && 
                                                <span>, &nbsp;{data.shipping.boxData.boxes.length} boxes</span>
                                            }                           
                                        )             
                                        &nbsp;
                                        <FontAwesomeIcon icon={faInfoCircle} style={{cursor: "pointer"}} onClick={() => setShow("shippingbase")} />
                                    </Property>
                                    <Property label="Parts">
                                        ${parseFloat(data.shipping.partsCost).toFixed(2)}
                                        &nbsp;
                                        <FontAwesomeIcon icon={faInfoCircle} style={{cursor: "pointer"}} onClick={() => setShow("shippingparts")} />
                                    </Property>
                                    <Property label="Adjustment" value={"$" + parseFloat(data.shipping.adjustment).toFixed(2)} />
                                    <Property label="Surcharge" value={"$" + parseFloat(data.shipping.upcharge.cost).toFixed(2)} />
                                </PropertyList>
                            </Property>                                                       
                            <Property label="Discount">
                                -${parseFloat(data.discount.total).toFixed(2)}
                                &nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} style={{cursor: "pointer"}} onClick={() => setShow("discount")} />
                            </Property>
                            <Property label="Calculated Total">
                                <strong>${parseFloat(data.cost.total).toFixed(2)}</strong>
                            </Property>
                            <Property label="Existing Total">
                                ${parseFloat(sign.estimate).toFixed(2)}

                                {(parseFloat(sign.estimate).toFixed(2) != parseFloat(data.cost.total).toFixed(2)) && 
                                    <strong style={{color: "red"}}>
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        ${(parseFloat(sign.estimate) - parseFloat(data.cost.total)).toFixed(2)} difference
                                    </strong>
                                }
                            </Property>
                        </PropertyList>
                    </>
                :
                    <>No data found?</>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>

      {(sign && data) && 
        <Modal show={show == "groups"} onHide={() => setShow("")}>
            <Modal.Header closeButton>
                <Modal.Title>Groups Cost</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: "80vh", overflow: "auto"}}>                
                <PropertyList>
                    {data.groups.map((group,index) => (   
                        <Property key={index} label={`Group: ${group.codes}`}>
                            <PropertyList>
                                <Property label="Cost" value={"$" + parseFloat(group.estimate).toFixed(2)} />
                                <Property label="Shipping Parts" value={"-$" + getGroupShippingPartsTotal(group.id).toFixed(2)} />
                            </PropertyList>
                            
                        </Property>
                    ))}
                    <Property label="Total" value={"$" + (parseFloat(data.cost.base) - parseFloat(data.shipping.partsCost)).toFixed(2)} styles={{fontWeight: "bold"}} />
                </PropertyList>
            </Modal.Body>
        </Modal>
      }      
      {(sign && data) && 
        <Modal show={show == "setup"} onHide={() => setShow("")}>
            <Modal.Header closeButton>
                <Modal.Title>Setup Parts</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: "80vh", overflow: "auto"}}>                
                <PropertyList>
                    {data.setup.parts.map((part,index) => (   
                        <Property key={index} label={part.title}>
                            ${parseFloat(part.price_setup).toFixed(2)}
                        </Property>
                    ))}
                    <Property label="Total" value={"$" + parseFloat(data.setup.cost).toFixed(2)} styles={{fontWeight: "bold"}} />
                </PropertyList>
            </Modal.Body>
        </Modal>
      }
      {(sign && data) && 
        <Modal show={show == "shippingparts"} onHide={() => setShow("")}>
            <Modal.Header closeButton>
                <Modal.Title>Shipping Parts</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: "80vh", overflow: "auto"}}>                
                <PropertyList>
                    {data.shipping.parts.map((part,index) => (   
                        <Property key={index} label={`Shape ${part.shape_code}, Part ${part.title}`}>
                            ${parseFloat(part.total).toFixed(2)}
                        </Property>
                    ))}
                    <Property label="Total" value={"$" + parseFloat(data.shipping.partsCost).toFixed(2)} styles={{fontWeight: "bold"}} />
                </PropertyList>
            </Modal.Body>
        </Modal>
      }  
      {(sign && data) && 
        <Modal show={show == "shippingdiscount"} onHide={() => setShow("")}>
            <Modal.Header closeButton>
                <Modal.Title>Shipping Part Discount</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: "80vh", overflow: "auto"}}>                
                <PropertyList>
                    {data.shipping.partsDiscounted.map((part,index) => (   
                        <Property key={index} label={`Shape ${part.shape_code}, Part ${part.title}`}>
                            ${parseFloat(part.shipping_discount).toFixed(2)}
                        </Property>
                    ))}
                    <Property label="Total" value={"$" + parseFloat(data.shipping.partsDiscount).toFixed(2)} styles={{fontWeight: "bold"}} />
                </PropertyList>
            </Modal.Body>
        </Modal>
      }  
      {(sign && data) && 
        <Modal show={show == "discount"} size="xl" onHide={() => setShow("")} >
            <Modal.Header closeButton>
                <Modal.Title>Discount Breakdown</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: "80vh", overflow: "auto"}}>                
                <PropertyList>
                    {/* <Property label="Groups Total">
                        <GroupsData>                
                            <li>
                                <h3 onClick={(e) => onExpand("groupsdiscount")}>
                                    ${parseFloat(data.discount.groupsTotal).toFixed(2)}
                                    <div>
                                        <FontAwesomeIcon icon={isExpanded("groupsdiscount") ? faCaretUp : faCaretDown} />
                                    </div>
                                </h3>
                                <div style={{display: isExpanded("groupsdiscount") ? "block":"none"}}>
                                    <PropertyList>
                                        {data.groups.map((group,index) => (
                                            <Property label={group.codes} key={index} value={"$" + parseFloat(group.discount).toFixed(2)} />
                                        ))}
                                    </PropertyList>
                                </div>
                            </li>
                        </GroupsData> 
                    </Property>                        */}
                    <Property label="Shapes Total">
                        <GroupsData>                
                            <li>
                                <h3 onClick={(e) => onExpand("shapesdiscount")}>
                                    ${parseFloat(data.discount.shapesTotal).toFixed(2)}
                                    <div>
                                        <FontAwesomeIcon icon={isExpanded("shapesdiscount") ? faCaretUp : faCaretDown} />
                                    </div>
                                </h3>
                                <div style={{display: isExpanded("shapesdiscount") ? "block":"none"}}>
                                    <div>
                                        * Shapes are sorted by their price.
                                    </div>
                                    <PropertyList header={
                                        <tr>
                                            <th>Group</th>
                                            <th>Shape</th>
                                            <th>Price</th>
                                            <th>Index</th>
                                            <th>%</th>
                                            <th>Discount</th>
                                        </tr>
                                    }>
                                        {data.discount.shapes.map((shape,index) => {
                                            const shapeGroup = data.groups.find(g => g.id == shape.signgroup_id);

                                            return (
                                                <tr key={index}>
                                                    <td>{shapeGroup ? shapeGroup.codes : "?"}</td>
                                                    <td>{shape.code}</td>
                                                    <td>${parseFloat(shape.price).toFixed(2)}</td>
                                                    <td>{shape.index}</td>
                                                    <td>{Math.round(shape.discountPercent*100)}%</td>
                                                    <td>${parseFloat(shape.discount).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                    </PropertyList>
                                </div>
                            </li>
                            <li>
                                <h3 onClick={(e) => onExpand("shapediscountrates")}>
                                    Discount Rates
                                    <div>
                                        <FontAwesomeIcon icon={isExpanded("shapediscountrates") ? faCaretUp : faCaretDown} />
                                    </div>
                                </h3>
                                <div style={{display: isExpanded("shapediscountrates") ? "block":"none"}}>
                                    <PropertyList header={
                                        <tr>
                                            <th>Index Range</th>
                                            <th>Discount %</th>
                                        </tr>
                                    }>
                                        {data.discount.shapeRates.map((rate,index) => (
                                            <tr key={index}>
                                                <td>{rate.indexStart} - {rate.indexEnd}</td>
                                                <td>{Math.round(rate.discountPercent*100)}%</td>
                                            </tr>
                                        ))}
                                    </PropertyList>
                                </div>
                            </li>                             
                        </GroupsData>
                    </Property>
                    <Property label="Multipart Total">
                    <GroupsData>                
                            <li>
                                <h3 onClick={(e) => onExpand("multipartdiscount")}>
                                    ${parseFloat(data.discount.multipartTotal).toFixed(2)}
                                    <div>
                                        <FontAwesomeIcon icon={isExpanded("multipartdiscount") ? faCaretUp : faCaretDown} />
                                    </div>
                                </h3>
                                <div style={{display: isExpanded("multipartdiscount") ? "block":"none"}}>
                                    <div>
                                        * Groups are sorted by their price.
                                    </div>                                    
                                    <PropertyList header={
                                        <tr>
                                            <th>Group</th>
                                            <th>Price</th>
                                            <th>Index</th>
                                            <th>%</th>
                                            <th>Discount</th>
                                        </tr>
                                    }>
                                        {data.discount.multipartGroups.map((group,index) => (
                                            <tr key={index}>
                                                <td>{group.codes}</td>
                                                <td>${parseFloat(group.estimate).toFixed(2)}</td>
                                                <td>{group.multipart_index}</td>
                                                <td>{Math.round(group.multipart_percent*100)}%</td>
                                                <td>${parseFloat(group.multipart_discount).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                    </PropertyList>
                                </div>
                            </li>
                            <li>
                                <h3 onClick={(e) => onExpand("multipartdiscountrates")}>
                                    Discount Rates
                                    <div>
                                        <FontAwesomeIcon icon={isExpanded("multipartdiscountrates") ? faCaretUp : faCaretDown} />
                                    </div>
                                </h3>
                                <div style={{display: isExpanded("multipartdiscountrates") ? "block":"none"}}>
                                    <PropertyList header={
                                        <tr>
                                            <th>Index Range</th>
                                            <th>Discount %</th>
                                        </tr>
                                    }>
                                        {data.discount.multipartRates.map((rate,index) => (
                                            <tr key={index}>
                                                <td>{rate.indexStart} - {rate.indexEnd}</td>
                                                <td>{Math.round(rate.discountPercent*100)}%</td>
                                            </tr>
                                        ))}
                                    </PropertyList>
                                </div>
                            </li>                               
                        </GroupsData>                        
                    </Property>
                    <Property label="Shipping Total">
                        <GroupsData>                
                            <li>
                                <h3 onClick={(e) => onExpand("shippingdiscount")}>
                                    ${parseFloat(data.shipping.partsDiscount).toFixed(2)}
                                    <div>
                                        <FontAwesomeIcon icon={isExpanded("shippingdiscount") ? faCaretUp : faCaretDown} />
                                    </div>
                                </h3>
                                <div style={{display: isExpanded("shippingdiscount") ? "block":"none"}}>
                                    <div>
                                        * Parts are sorted by their price.
                                    </div>
                                    <PropertyList header={
                                        <tr>
                                            <th>Group</th>
                                            <th>Shape</th>
                                            <th>Part</th>
                                            <th>Price</th>
                                            <th>Index</th>
                                            <th>%</th>
                                            <th>Discount</th>
                                        </tr>
                                    }>
                                        {data.shipping.partsDiscounted.map((part,index) => {
                                            const shapeGroup = data.groups.find(g => g.id == part.signgroup_id);
                                            const shape = shapeGroup ? shapeGroup.shapes.find(s => s.id == part.shape_id) : null;

                                            return (
                                                <tr key={index}>
                                                    <td>{shapeGroup ? shapeGroup.codes : "?"}</td>
                                                    <td>{shape ? shape.code : "?"}</td>
                                                    <td>{part.title}</td>
                                                    <td>${parseFloat(part.total).toFixed(2)}</td>
                                                    <td>{part.shipping_index}</td>
                                                    <td>{Math.round(part.shipping_discount_percent*100)}%</td>
                                                    <td>${parseFloat(part.shipping_discount).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                    </PropertyList>
                                </div>
                            </li>
                            <li>
                                <h3 onClick={(e) => onExpand("shippingdiscountrates")}>
                                    Discount Rates
                                    <div>
                                        <FontAwesomeIcon icon={isExpanded("shippingdiscountrates") ? faCaretUp : faCaretDown} />
                                    </div>
                                </h3>
                                <div style={{display: isExpanded("shippingdiscountrates") ? "block":"none"}}>
                                    <PropertyList header={
                                        <tr>
                                            <th>Index Range</th>
                                            <th>Discount %</th>
                                        </tr>
                                    }>
                                        {data.shipping.partsDiscountRates.map((rate,index) => (
                                            <tr key={index}>
                                                <td>{rate.indexStart} - {rate.indexEnd}</td>
                                                <td>{Math.round(rate.discountPercent*100)}%</td>
                                            </tr>
                                        ))}
                                    </PropertyList>
                                </div>
                            </li>                            
                        </GroupsData>                        
                    </Property>
                    <Property label="Total" value={"$" + parseFloat(data.discount.total).toFixed(2)} styles={{fontWeight: "bold"}} />
                </PropertyList>
            </Modal.Body>
        </Modal>
      }  
      {(sign && data) && 
        <Modal show={show == "shippingbase"} size="xl" onHide={() => setShow("")} >
            <Modal.Header closeButton>
                <Modal.Title>Shipping Base Calculation</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: "80vh", overflow: "auto"}}>                
                <ul>
                {data.shipping.baseCostLog.map((log,index) => (
                    <li key={index}>
                        {log}
                    </li>
                ))}
                </ul>
            </Modal.Body>
        </Modal>
      }        
      {/* {(sign && data) && 
        <Modal show={show == "total"} onHide={() => setShow("")}>
            <Modal.Header closeButton>
                <Modal.Title>Total Cost Breakdown</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: "80vh", overflow: "auto"}}>                
                <PropertyList>
                    <Property label="Base Groups Cost" value={"$" + parseFloat(data.cost.base).toFixed(2)} />
                    <Property label="Setup Cost" value={"$" + parseFloat(data.setup.cost).toFixed(2)} />
                    <Property label="Options Cost" value={"$" + parseFloat(data.cost.options).toFixed(2)} />
                    <Property label="Shipping Base Cost" value={"$" + parseFloat(data.shipping.baseCost).toFixed(2)} />
                    <Property label="Shipping Adjustment" value={"$" + parseFloat(data.shipping.adjustment).toFixed(2)} />
                    <Property label="Total Discounts" value={"$" + parseFloat(data.discount.total).toFixed(2)} />

                    <Property label="Total" value={"$" + parseFloat(data.cost.total).toFixed(2)} styles={{fontWeight: "bold"}} />
                </PropertyList>
            </Modal.Body>
        </Modal>
      }   */}
      {(sign && data && shape) && 
        <Modal show={show == "parts"} onHide={() => {setShow(""); setShape(null) }}>
            <Modal.Header closeButton>
                <Modal.Title>Shape Parts</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{maxHeight: "80vh", overflow: "auto"}}>                
                <PropertyList>
                    {shape.parts.filter(p => p.type != "shipping").map((part,index) => (
                        <Property label={part.title} key={index}>
                            <PropertyList>
                                <Property label="Price Type" value={part.price_type} />
                                {part.price_type == "cost_sqin_fixed" && 
                                    <Property label="Shape Sq In" value={shape.shape_sq_inches.toFixed(2)} />
                                }
                                {part.price_type == "cost_perimin_fixed" && 
                                    <Property label="Shape Perim In" value={shape.shape_perim_inches.toFixed(2)} />
                                }

                                {(part.price && part.price > 0) && 
                                    <Property label="Base Price" value={"$" + parseFloat(part.price).toFixed(2)} />
                                }
                                {(part.price_fixed && part.price_fixed > 0) && 
                                    <Property label="Fixed" value={part.price_fixed} />
                                }                                                                
                                {(part.price_cost && part.price_cost > 0) && 
                                    <Property label="Cost">
                                            {part.calculated_cost ? 
                                                <>
                                                    {part.calculated_cost.toFixed(2)}
                                                    ({part.price_cost})
                                                </>
                                            :
                                                part.price_cost
                                            }
                                    </Property>
                                }
                                {part.size_height && 
                                    <Property label="Size" value={part.size_height} />
                                }
                                <Property label="Total" value={"$" + part.total.toFixed(2)} styles={{fontWeight: "bold"}} />
                                {(part.notes && part.notes.length > 0) && 
                                    <Property label="Notes" value={part.notes} />
                                }
                            </PropertyList>
                        </Property>
                    ))}

                    <Property label="Total" value={"$" + parseFloat(shape.partsTotalWithoutShipping).toFixed(2)} styles={{fontWeight: "bold"}} />
                </PropertyList>
            </Modal.Body>
        </Modal>
      }                            
    </>
    )
}

export default SignPriceDrawer;