import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faPlus, faSpinner, faTrash  } from '@fortawesome/free-solid-svg-icons'

import Loading from "../../components/loading"
import { Accordion, AccordionItem } from "../../components/accordion";

import PartColorsDrawer from "../../drawers/drawer-partcolors"
import PartProductsDrawer from "../../drawers/drawer-partproducts"
import PartChildrenDrawer from "../../drawers/drawer-partchildren"
import VendorAddDrawer from "../../drawers/drawer-vendoradd"
import MwoEditDrawer from "../../drawers/workflow-mwo";
import MwoBrowseDrawer from "../../drawers/workflow-mwos";

import ResourcesPanel from "../../panels/panel-resources";
import ChildPartsPanel from "../../panels/panel-partchildren";
import PartParentsPanel from "../../panels/panel-partparents";

import BoardField from "../../components/board/board-field"
import BoardFields from "../../components/board/board-fields";
import BoardColumns from "../../components/board/board-columns";
import Kanban from "../../components/kanban";

import { postData } from "../../common/services/server"
import { colors } from "../../settings/settings"
import storage from "../../settings/storage";
import { sanitizeFilename, sanitizePartIconFilename } from "../../helpers/utility";
import { getWorkflowMwoStickerTypes } from "../../helpers/mwo";
import ButtonMutate from "../../components/buttons/button-mutate";
import ButtonAction from "../../components/buttons/button-action";
import { useGetPart, useGetPartTypes, useGetParts } from "../../api/queries/parts";
import Error from "../../components/error";

function PartsPanel({
  mode,
  partId,
  onChange,
}) {
  const history = useHistory();

  const partTypesQuery = useGetPartTypes({mode});
  const partTypes = partTypesQuery.data;

  const partsQuery = useGetParts({mode});
  const parts = partsQuery.data;

  const partQuery = useGetPart(partId, { enabled:!!partId});
  const part = partQuery.data;

  // const deletePartMwo = usePartDeleteMwo(partId, {
  //   onSuccess: (data) => onChange({id: partId, ...data})
  // });
  // const addPartMwo = usePartAddMwo(partId, {
  //   onSuccess: (data) => onChange({id: partId, ...data})
  // });  
  // const updatePartMwoStickers = usePartUpdateMwoProp(partId, "stickers", {
  //   onSuccess: (data) => onChange({id: partId, ...data})
  // });
  // const createMwo = useCreateMwoForPart(partId, {
  //   onSuccess: (data) => onChange({id: partId, ...data})
  // });

  // const [browseMWOs, setBrowseMWOs] = useState(false);
  // const [mwoId, setMwoId] = useState(null);

  const [selected, setSelected] = useState(null);
  const [busy, setBusy] = useState("");

  function onColors(part) {
    console.log("onColors", part)
    setSelected({mode:"colors", part})
  }

  function onPartChanged(data) {
    partsQuery.refetch();
    partQuery.refetch();
  }

  function onVendorChanged(vendor, data) {
    partQuery.refetch();
  }

  return (
    <>
      {(partsQuery.isLoading || partTypesQuery.isLoading) ?
          <Loading />
      :(partsQuery.isError || partTypesQuery.isError) ? 
          <div className="p-2">
            <Error message={partsQuery.error?.message || partTypesQuery.error?.message} />
          </div>          
      :    
          <div className="grid grid-cols-[400px,1fr] ga-2">
            <div className="overflow-y-auto" style={{height: "calc(100vh - 150px)"}}>
              <Accordion 
                  style={{
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  {partTypes.map(partType => (
                    <AccordionItem 
                      key={partType.name} 
                      title={partType.title}
                      initiallyExpanded={partTypes.length == 1 || (part && part.type_id==partType.id) ? true:false}
                    >
                      <div>
                        {parts.length ?
                          <>
                          {parts.filter(p => p.type_id == partType.id).map(part => (
                            <button 
                              key={part.id} 
                              className="text-sm p-2 border border-gray-200 text-left hover:!bg-gray-100"
                              style={{
                                display: "grid",
                                gridTemplateColumns: mode != "frontend" ? "50px 1fr" : "1fr",
                                gap: "5px",
                                alignItems: "center",
                                width: "100%",
                                backgroundColor: partId == part.id ? colors.lightPurple : "white",
                              }}
                              onClick={() => {
                                history.push(`/parts/${mode}/${part.id}`);
                              }}
                            >
                              {mode != "frontend" && 
                                <div>
                                  <img 
                                    src={storage.root + "2020/parts/" + sanitizePartIconFilename(part.name) + "/icon.jpg?" + (new Date().getTime())} 
                                    height="30"
                                    onError={(e) => e.target.src = storage.root + "images/nothumbnail.jpg"}
                                    style={{ maxHeight: "30px", maxWidth: "50px" }} 
                                  />
                                </div>
                              }
                              <div>
                                {(part.part_number) && 
                                  <>
                                    {part.part_number}
                                    &nbsp;-&nbsp;
                                  </>
                                }
                                {part.title}
                              </div>
                            </button>
                          ))}
                          </>
                        :
                          <Loading size="sm" color="white" />
                        }
                      </div>
                    </AccordionItem>
                  ))}
              </Accordion>
            </div>
            <div className="bg-gray-200 p-1 overflow-y-auto overflow-x-hidden" style={{height: "calc(100vh - 150px)"}}>
              {(partId && partQuery.isLoading) ?
                  <Loading color="white" />
              : partQuery.isError ? 
                  <div className="p-2">
                    <Error message={partQuery.error?.message} />
                  </div>
              : part ?
                <>
                  <BoardField
                    id={partId}
                    type="list"
                    label="Type"
                    value={part.type_id}
                    items={partTypes}
                    saveUrl={`parts/updateprop?part_id=${partId}&prop=type_id`}
                    onSaved={onPartChanged}
                  />                       
                  <BoardField
                    id={partId}
                    type="text"
                    label="Title"
                    inset
                    value={part.title || ""}
                    saveUrl={`parts/updateprop?part_id=${partId}&prop=title`}
                    onSaved={onPartChanged}
                  />
                  <BoardField
                    id={partId}
                    type="text"
                    label="Short Title"
                    info="Used in places where space is limited like MWOs."
                    value={part.short_title || ""}
                    saveUrl={`parts/updateprop?part_id=${partId}&prop=short_title`}
                    onSaved={onPartChanged}
                  />        
                  <BoardField
                    id={partId}
                    type="googleimage"
                    label="Icon"
                    info="150px by 100px"
                    infoWarning="Needs to be a JPG image file."
                    value={"2020/parts/" + sanitizePartIconFilename(part.name) + "/icon.jpg?" + (new Date().getTime())}
                    buttons={[
                      {
                          type: "upload",
                          uploadUrl: "parts/uploadicon?part_id=" + partId + "&filename=" + sanitizePartIconFilename(part.name),
                          // busy: busy == "preview",
                          onUpload: (result) => {
                            console.log("uploaded", result)
                            onPartChanged({
                              preview_url: result.url + "?" + (new Date().getTime())
                            })
                          }
                      },
                    ]}        
                  />                            
                  <BoardField
                    id={partId}
                    type="paragraph"
                    label="Description"
                    infoWarning="Might be displayed to customers in certain locations."
                    value={part.description || ""}
                    saveUrl={`parts/updateprop?part_id=${partId}&prop=description`}
                    onSaved={onPartChanged}
                  />     
                  <BoardField
                    id={partId}
                    type="number"
                    label="Size"
                    info="Size value varies based on the part type, but typically this is in inches."
                    value={part.size || ""}
                    saveUrl={`parts/updateprop?part_id=${partId}&prop=size`}
                    onSaved={onPartChanged}
                  />       
                  <BoardField
                    id={partId}
                    type="text"
                    label="Size Info"
                    value={part.size_info || ""}
                    saveUrl={`parts/updateprop?part_id=${partId}&prop=size_info`}
                    onSaved={onPartChanged}
                  />                    
                  <BoardField
                    id={partId}
                    type="text"
                    label="Part Number"
                    value={part.part_number || ""}
                    saveUrl={`parts/updateprop?part_id=${partId}&prop=part_number`}
                    onSaved={onPartChanged}
                  />   
                  <BoardField
                    id={partId}
                    type="text"
                    label="Version Number"
                    value={part.version_number || ""}
                    saveUrl={`parts/updateprop?part_id=${partId}&prop=version_number`}
                    onSaved={onPartChanged}
                  />                   

                  {(part.type_name == "bod") &&
                    <BoardField
                      type="select"
                      label="Workflow"
                      value={part.workflow_id || ""}
                      optionsUrl="query/workflows/list?type=workflow"
                      suffix={
                        <Button
                            size="sm"
                            variant="outline-primary"
                            disabled={!part.workflow_id}
                            onClick={() => {
                                window.open("#/workflow/" + part.workflow_id);
                            }}
                        >
                            Edit Workflow
                        </Button>
                      }            
                      saveUrl={`parts/updateprop?part_id=${part.id}&prop=workflow_id`}
                      onSaved={onPartChanged}
                    />
                  }

                  <BoardField
                    id={partId}
                    type="boolean"
                    label="Include on Packing Slip"
                    value={part.packingslip_ind}
                    saveUrl={`parts/updateprop?part_id=${partId}&prop=packingslip_ind`}
                    onSaved={onPartChanged}
                  />   
                  <BoardField
                    id={partId}
                    type="paragraph"
                    label="Notes (Internal)"
                    value={part.notes || ""}
                    saveUrl={`parts/updateprop?part_id=${partId}&prop=notes`}
                    onSaved={onPartChanged}
                  />  

                  {/* <BoardFields label="MWOs" count={mwos?.length} collapsible collapsed marginTop={8}>
                    {mwos?.map(mwo => (
                      <BoardColumns key={mwo.id} className="!w-[96%]">
                        <BoardField
                          type="label"
                          label="MWO"
                          value={mwo.title}
                          onEdit={() => { setMwoId(mwo.id) }}
                        />   

                        <div className="flex items-center gap-1">
                          <BoardField
                              type="select"
                              label="Quantity"
                              required
                              selectProps={{
                                  items: getWorkflowMwoStickerTypes()
                              }}
                              value={mwo.stickers}
                              saveMutation={updatePartMwoStickers}
                              onSave={(value) => {
                                return {
                                  mwoId: mwo.id,
                                  value
                                }
                              }}
                          />

                          <ButtonMutate
                            icon={faTrash}
                            variant="none-danger"
                            size="icon"
                            confirmation="Delete this MWO from this part?"
                            mutation={deletePartMwo}
                            mutationData={mwo.id}
                          />
                        </div>
                      </BoardColumns>
                    ))}

                    <div className="my-1">
                      <ButtonAction
                        icon={faPlus}
                        caption="Add MWO"
                        variant="success"
                        size="sm"
                        onClick={() => setBrowseMWOs(true)}
                      />
                    </div>
                  </BoardFields> */}

                  {/* <BoardField
                    type="list"
                    label="Production Method"
                    value={product.production_method}
                    items={[
                      {value: "BOD", label: "BOD"},
                      {value: "Stock", label: "Stock"},
                      {value: "Outsite Order", label: "Outsite Order"},              
                    ]}
                    saveUrl={`products/updateprop?product_id=${product.id}&prop=production_method`}
                    onSaved={onPartChanged}
                  />     */}

                  {(part.type_name == "stock" || part.type_name == "order") &&
                    <BoardFields label="Stock Information" collapsible collapsed marginTop={8}>
                      <BoardField
                          id={partId}
                          type="number"
                          label="Min Stock Qty"
                          value={part.min_stock_qty}
                          saveUrl={`parts/updateprop?part_id=${partId}&prop=min_stock_qty`}
                          onSaved={onPartChanged}
                      />
                      <BoardField
                        id={partId}
                        type="number"
                        label="Reorder Qty"
                        value={part.reorder_qty}
                        saveUrl={`parts/updateprop?part_id=${partId}&prop=reorder_qty`}
                        onSaved={onPartChanged}
                      />
                      <BoardField
                        id={partId}
                        type="number"
                        label="Reorder Days"
                        value={part.reorder_days}
                        saveUrl={`parts/updateprop?part_id=${partId}&prop=reorder_days`}
                        onSaved={onPartChanged}
                      />
                    </BoardFields>
                  }

                  {(part.type_name == "order") &&                    
                    <BoardFields label="Vendors" collapsible collapsed count={part.vendors?.length || 0} marginTop={8} marginBottom={8}>
                      {part.vendors.map(vendor => (
                        <div className="grid grid-cols-[1fr,50px] gap-2 items-center">
                          <BoardFields label={vendor.name} marginBottom={2}>
                            <BoardField
                              type="text"
                              label="Vendor Phone No"
                              value={vendor.phone_no || ""}
                              saveUrl={`parts/updatevendorprop?part_id=${partId}&vendor_id=${vendor.id}&prop=phone_no`}
                              onSaved={(result) => onVendorChanged(vendor, result)}
                            />
                            <BoardField
                              type="text"
                              label="Vendor Email"
                              value={vendor.email || ""}
                              saveUrl={`parts/updatevendorprop?part_id=${partId}&vendor_id=${vendor.id}&prop=email`}
                              onSaved={(result) => onVendorChanged(vendor, result)}
                            />                              
                            <BoardField
                              type="text"
                              label="Part Number"
                              value={vendor.part_number || ""}
                              saveUrl={`parts/updatevendorprop?part_id=${partId}&vendor_id=${vendor.id}&prop=part_number`}
                              onSaved={(result) => onVendorChanged(vendor, result)}
                            />
                            <BoardField
                              type="text"
                              label="Part Contact"
                              value={vendor.contact || ""}
                              saveUrl={`parts/updatevendorprop?part_id=${partId}&vendor_id=${vendor.id}&prop=contact`}
                              onSaved={(result) => onVendorChanged(vendor, result)}
                            />                                                      
                            <BoardField
                              type="number"
                              label="Part Price"
                              value={vendor.current_price || ""}
                              saveUrl={`parts/updatevendorprop?part_id=${partId}&vendor_id=${vendor.id}&prop=current_price`}
                              onSaved={(result) => onVendorChanged(vendor, result)}
                            />                            
                          </BoardFields>
                          <div>
                            <Button
                              variant="outline-danger"
                              disabled={busy == ("removevendor"+vendor.id)}
                              onClick={() => {
                                if (window.confirm("Are you sure you want to remove this vendor?")) {
                                  setBusy("removevendor"+vendor.id);

                                  postData("parts/removevendor", {
                                    part_id: partId,
                                    vendor_id: vendor.id
                                  },
                                    function() {
                                      let vendors = JSON.parse(JSON.stringify(part.vendors || []));
                                      let idx = vendors.findIndex(v => v.id == vendor.id);

                                      vendors.splice(idx, 1);
                      
                                      onPartChanged({
                                        vendors,
                                        vendor_count: vendors.length
                                      });
                                    },
                                    function(error) {
                                        alert("Error removing vendor.")
                                    },
                                    function() {
                                      setBusy("");
                                    }
                                  );                                    
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={busy == ("removevendor"+vendor.id) ? faSpinner:faBan} spin={busy == ("removevendor"+vendor.id)} />
                            </Button>
                          </div>
                        </div>
                      ))}

                      <div style={{margin:"3px"}}>
                        <Button
                          size="sm"
                          variant="outline-success"
                          disabled={busy.length}
                          onClick={() => setSelected({mode:"addvendor"})}
                        >
                          Add Vendor
                        </Button>
                      </div>
                    </BoardFields>
                  }  

                  {(mode == "frontend") &&
                    <BoardFields label="Part Pricing" collapsible collapsed marginTop={8}>
                      <BoardField
                        id={partId}
                        type="list"
                        label="Type"
                        value={part.price_type}
                        items={[
                          {value: "size", label: "Per Size"},
                          {value: "cost_sqin", label: "Cost x Square Inches"},
                          {value: "cost_sqin_fixed", label: "Cost x Square Inches + Fixed"},
                          {value: "cost_perimin", label: "Cost x Perimeter Inches"},
                          {value: "cost_perimin_fixed", label: "Cost x Perimeter Inches + Fixed"},
                          {value: "cost_size", label: "Cost x Size"},
                          {value: "cost_size_fixed", label: "Cost x Size + Fixed"},
                          {value: "cost_part_size", label: "(Part Size x Cost) + Fixed"},
                          {value: "fixed", label: "Fixed"},                        
                        ]}
                        saveUrl={`parts/updateprop?part_id=${partId}&prop=price_type`}
                        onSaved={onPartChanged}
                      />   

                      {(part.price_type && part.price_type != "fixed") &&
                        <BoardField
                          id={partId}
                          type="number"
                          label="Cost"
                          value={part.price_cost}
                          saveUrl={`parts/updateprop?part_id=${partId}&prop=price_cost`}
                          onSaved={onPartChanged}
                        />
                      }
                      {(part.price_type && part.price_type.indexOf("fixed") != -1) &&
                        <BoardField
                          id={partId}
                          type="number"
                          label="Fixed Price"
                          value={part.price_fixed}
                          saveUrl={`parts/updateprop?part_id=${partId}&prop=price_fixed`}
                          onSaved={onPartChanged}
                        />
                      }

                      <BoardField
                        id={partId}
                        type="number"
                        label="Setup Cost"
                        value={part.price_setup}
                        saveUrl={`parts/updateprop?part_id=${partId}&prop=price_setup`}
                        onSaved={onPartChanged}
                      />                                                                                                 
                    </BoardFields>
                  }
                  {(mode == "frontend") &&
                    <BoardFields label="Part Colors" collapsible collapsed marginTop={8}>
                      <BoardField
                        id={partId}
                        type="boolean"
                        label="Supports Colors"
                        value={part.color_ind}
                        saveUrl={`parts/updateprop?part_id=${partId}&prop=color_ind`}
                        onSaved={onPartChanged}
                      />   
                      {(part.color_ind == 1) &&                       
                        <BoardField
                          id={partId}
                          type="children"
                          label="Colors"
                          onEdit={() => setSelected({mode:"colors"})}
                        >
                          {part.colors_count} Colors 
                        </BoardField>                        
                      }
                    </BoardFields>
                  }
                  {/* {(mode == "frontend") &&
                    <BoardField
                      type="pills"
                      label="Products"
                      value={part.products}
                      onEdit={() => {
                        setSelected({mode: "products"})
                      }}
                    />   
                  } */}
                  
                  {(mode == "frontend" || mode == "assemblies" || mode == "backend") &&
                    <BoardFields label="Back End Parts" collapsible collapsed count={part.children?.length || 0} marginTop={8}> 
                      <ChildPartsPanel
                        parts={part.children || []}
                        onChange={() => {
                          setSelected({mode: "children"})
                        }}
                      />
                    </BoardFields>
                  }

                  {((part.parents && part.parents.length) || (part.products && part.products.length)) &&
                    <BoardFields label="Part Usage" collapsible collapsed count={part.parents.length} marginTop={8}>
                      <PartParentsPanel
                        parents={part.parents || []}
                      />
                    </BoardFields>
                  }

                  <BoardFields label="Part Resources" collapsible collapsed count={part.resources?.length || 0} marginTop={8}>                  
                    <ResourcesPanel
                      type="part"
                      type_id={partId}
                      resources={part.resources || []}
                      onChange={onPartChanged}
                    />
                  </BoardFields>

                  {(mode == "backend") &&
                    <BoardFields label="Kanban" collapsible collapsed marginTop={8}>
                      <Kanban 
                        part={part} 
                        edit 
                        print
                        onChange={onPartChanged} 
                      />
                    </BoardFields>
                  }                  
                </>
              :
                null            
              }
            </div>
          </div>
      }

      {(selected && selected.mode == "addvendor") && 
          <VendorAddDrawer 
              part={part}
              onAdded={(vendor) => {
                let vendors = JSON.parse(JSON.stringify(part.vendors || []));

                vendors.push(vendor);

                onPartChanged({
                  vendors,
                  vendor_count: vendors.length
                });
                setSelected(null);
              }}
              onHide={() => setSelected(null)}
          />
      }    
      {(selected && selected.mode == "colors") && 
          <PartColorsDrawer 
              part={part}
              show={true}
              onChanged={(colorCount) => {
                onChange({
                  id: partId,
                  color_count: colorCount
                })
              }}
              onHide={() => setSelected(null)}
          />
      }  
      {(selected && selected.mode == "products") && 
          <PartProductsDrawer 
              part={part}
              show={true}
              onHide={() => setSelected(null)}
          />
      }     
      {(selected && selected.mode == "children") && 
          <PartChildrenDrawer 
              types={partTypes}
              parts={parts}
              part={part}
              show={true}
              onPartRemoved={(part) => {
                let children = JSON.parse(JSON.stringify(part.children || []));
                let idx = children.findIndex(p => p.id == part.id)

                if (idx != -1) {
                  children.splice(idx, 1);

                  onPartChanged({
                    children,
                    child_count: children.length
                  });
                }
              }}
              onPartAdded={(part) => {
                let children = JSON.parse(JSON.stringify(part.children || []));

                children.push(part);

                onPartChanged({
                  children,
                  child_count: children.length
                });
              }}
              onHide={() => setSelected(null)}
          />
      }   

      {/* {browseMWOs &&
        <MwoBrowseDrawer
          existingIds={mwos ? mwos.map(m => Number(m.id)) : []}
          selectMutation={addPartMwo}
          createMutation={createMwo}
          onMutateSuccess={() => {
            setBrowseMWOs(false);
          }}
          onHide={() => setBrowseMWOs(null)}
        />      
      }

      {mwoId && 
        <MwoEditDrawer
            mwoId={mwoId}
            onSaved={() => {
              setMwoId(null);
            }}
            onHide={() => setMwoId(null)}
        />
      }                       */}
    </>
  );
}

export default PartsPanel;