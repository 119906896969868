import React, { useState, useEffect } from "react";
import styled from "styled-components"

import InputGroup from 'react-bootstrap/InputGroup'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons'

import Button from './buttons/button'
import SignSelect from "./fields/sign-select";
import ProjectSelect from "./fields/project-select";
import OrderSelect from "./fields/order-select";

const AssociationsContainer = styled.div`
    .input-group {
        margin-bottom: 2px;
    }
    .input-group-text {
        min-width: 80px;
    }
`

function Associations(props) {
  return (
        <AssociationsContainer>
            {props.types.indexOf("project") != -1 && 
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Project
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <ProjectSelect
                        name="project"
                        default={props.data.quote_id ? {id: props.data.quote_id, title: "#"+props.data.quote_id} : null}
                        onChange={(project) => {
                            props.onChange("quote", project);
                        }}
                    />
                    {(props.busy.project || props.data.quote_id) && 
                        <InputGroup.Append>
                            <Button variant="outline-secondary" onClick={() => props.onChange("quote", null)} size="icon">
                                <FontAwesomeIcon icon={props.busy.quote ? faSpinner : faBan} spin={props.busy.quote} />
                            </Button>
                        </InputGroup.Append>
                    }
                </InputGroup>
            }
            {props.types.indexOf("order") != -1 && 
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Order
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <OrderSelect
                        name="order"
                        default={props.data.order_id ? {id: props.data.order_id, title: "#"+props.data.order_id} : null}
                        onChange={(order) => {
                            props.onChange("order", order);
                        }}
                    />
                    {(props.busy.order || props.data.order_id) && 
                        <InputGroup.Append>
                            <Button variant="outline-secondary" onClick={() => props.onChange("order", null)} size="icon">
                                <FontAwesomeIcon icon={props.busy.order ? faSpinner : faBan} spin={props.busy.order} />
                            </Button>
                        </InputGroup.Append>
                    }
                </InputGroup>
            }
            {props.types.indexOf("sign") != -1 && 
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            Sign
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <SignSelect
                        name="sign"
                        default={props.data.sign_id ? {id: props.data.sign_id, name: "#"+props.data.sign_id} : null}
                        onChange={(sign) => {
                            props.onChange("sign", sign);
                        }}
                    />
                    {(props.busy.sign || props.data.sign_id) && 
                        <InputGroup.Append>
                            <Button variant="outline-secondary" onClick={() => props.onChange("sign", null)} size="icon">
                                <FontAwesomeIcon icon={props.busy.sign ? faSpinner : faBan} spin={props.busy.sign} />
                            </Button>
                        </InputGroup.Append>
                    }
                </InputGroup>
            }
        </AssociationsContainer>
    )
}

export default Associations;