import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import ListField from "../components/fields/field-list"
import TextField from "../components/fields/field-text"
import BooleanField from "../components/fields/field-boolean"
import NumberField from "../components/fields/field-number"
import SelectField from "../components/fields/field-select"

import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

const CheckboxList = styled.div`
    padding: 10px;
    font-size: 14px;
    margin-bottom: 8px;
    max-height: 400px;
    overflow: auto;
`

function ProductTaskDrawer({
    product,
    task,
    onSaved,
    onHide
}) {
  const formRef = useRef(null);

  let blankTask = {
    level: "Global",
    parts: [],
    shippingMethods: [],
  }

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(
    () => {
        let data = {
            level: "Global",
            ...task,
            parts: [],
            shippingMethods: [],
        }

      if (task) {
        if (task.parts) {
            const partIds = task.parts.split(",")

            for (const partId of partIds) {
                const part = product.parts.find(p => p.id == partId.trim());

                if (part)
                    data.parts.push(""+part.id);
            }
            data.level = "Part"
        } 
        if (task.shippingMethods || task.shippingmethod_ids) {
            const shippingMethodIds = (task.shippingMethods || task.shippingmethod_ids).split(",")

            for (const shippingMethodId of shippingMethodIds) {
                const method = product.shippingMethods.find(p => p.id == shippingMethodId.trim());

                if (method)
                    data.shippingMethods.push(""+method.id);
            }
            data.level = "Shipping Method"
        } 
      } 
      else data = blankTask;

      console.log("product", product)
      console.log("task data", data)
      setFormData(JSON.parse(JSON.stringify(data)));

      console.log("product mwos", product.mwos)
      console.log("product tasks", product.jobtasks)
    }, 
    []
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("products/savetasktype?id=" + (task.id||0), removeNulls(formData), 
        function(response) {
            onSaved(response);
        },
        function(error) {
            alert("An error occured saving the task.");
        },
        function() {
            setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;

      if (field == "level") {
          if (value != "Part")
            draft.parts = [];
          if (value != "Shipping Method")
            draft.shippingMethods = [];
      }
    }));
  }

  function onPartChange(e) {
    const part = e.target.value;
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.parts ? draft.parts.indexOf(part) : -1;

        if (index != -1)
            draft.parts.splice(index, 1);
        if (!draft.parts)
            draft.parts = [];
        if (checked)
            draft.parts.push(part);
    }));
  }
  function onShippingMethodChange(e) {
    const method = e.target.value;
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.shippingMethods ? draft.shippingMethods.indexOf(method) : -1;

        if (index != -1)
            draft.shippingMethods.splice(index, 1);
        if (!draft.shippingMethods)
            draft.shippingMethods = [];
        if (checked)
            draft.shippingMethods.push(method);
    }));
  }

  return (
      <>
        <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faClipboardCheck} />
                &nbsp;
                Product Task
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
                    <TextField 
                        name="name" 
                        label="Name" 
                        required
                        value={formData.name} 
                        onChange={(e) => handleFormChange(e)} />

                    <ListField 
                        name="mwo_id" 
                        label="MWO"                 
                        value={formData.mwo_id} 
                        list={product.mwos}
                        onChange={(e) => handleFormChange(e)} />

                    <ListField 
                        name="dependent_tasktype_id" 
                        label="Dependent Task"                 
                        labelField="name"
                        value={formData.dependent_tasktype_id} 
                        list={product.jobtasks}
                        onChange={(e) => handleFormChange(e)} />

                    <BooleanField 
                        name="dependent_qa_ind" 
                        label="Needs QA?" 
                        required
                        value={formData.dependent_qa_ind} 
                        onChange={(e) => handleFormChange(e)} />

                    <ListField 
                        name="target_day" 
                        label="Target Day" 
                        required
                        value={formData.target_day} 
                        list={[...Array(product.production_days || 5)].map((x, i) => {
                            const day = i+1;
                            return {
                                label: "Day " + day,
                                value: day
                            }
                        })}
                        onChange={(e) => handleFormChange(e)} 
                    />

                    {/* <NumberField 
                        name="target_day" 
                        label="Target Day" 
                        required
                        value={formData.target_day} 
                        step={1}
                        onChange={(e) => handleFormChange(e)} /> */}

                    <SelectField 
                        name="job_status_id" 
                        label="Job Status"                 
                        labelField="title"
                        required
                        value={formData.job_status_id} 
                        url={"jobs/statuses"}
                        onChange={(e) => handleFormChange(e)} />

                    <SelectField 
                        name="station_id" 
                        label="Production Station" 
                        url="stations/data"
                        labelField="name"
                        value={formData.station_id} 
                        onChange={(e) => handleFormChange(e)} />

                    <SelectField 
                        name="role_id" 
                        label="User Role"                 
                        labelField="name"
                        value={formData.role_id} 
                        url={"users/rolesata"}
                        onChange={(e) => handleFormChange(e)} />

                    <ListField 
                        name="level" 
                        label="Level" 
                        instructions="Level controls when the task is assigned to jobs."
                        value={formData.level} 
                        list={[
                            {value: "Global", label: "Global"},
                            {value: "Part", label: "Part"},
                            {value: "Shipping Method", label: "Shipping Method"},
                        ]}
                        onChange={(e) => handleFormChange(e)} 
                    />

                    {formData.level == "Part" && 
                        <CheckboxList>
                        {product.parts.map(part => (
                            <div key={part.id}>
                                <Form.Check 
                                    name="part"                                
                                    defaultValue={part.id}
                                    checked={formData.parts && formData.parts.indexOf(""+part.id) != -1}
                                    inline 
                                    label={part.title} 
                                    type="checkbox" 
                                    onChange={onPartChange}
                                />
                            </div>
                        ))}
                        </CheckboxList>
                    }
                    {formData.level == "Shipping Method" && 
                        <CheckboxList>
                        {product.shippingMethods.map(method => (
                            <div key={method.id}>
                                <Form.Check 
                                    name="shippingmethod"                                
                                    defaultValue={method.id}
                                    checked={formData.shippingMethods && formData.shippingMethods.indexOf(""+method.id) != -1}
                                    inline 
                                    label={method.title} 
                                    type="checkbox" 
                                    onChange={onShippingMethodChange}
                                />
                            </div>
                        ))}
                        </CheckboxList>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonProcessing 
                    processing={isProcessing}
                    onClick={onSave} 
                    variant="outline-success" 
                    caption="Save Task" 
                    icon={faSave} /> 
                <Button variant="outline-secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default ProductTaskDrawer;