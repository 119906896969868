import React from "react";

import FieldLabel from "./label"
import HelpText from "./help-text"
import clsx from "clsx";
import ErrorText from "./error-text";

function Field({
    groupStyle,
    groupClassname,
    layout,
    required,
    disabled,
    className,
    label,
    labelClassName,
    labelStyle,
    instructions,
    instructionsStyle,
    invalid,
    valid,
    error,
    prepend,
    prependClassName,
    append,
    appendClassName,
    children
}) {
    if (!groupStyle)
        groupStyle = {};

    if (layout == "horizontal") {
        groupStyle.display = "grid";
        groupStyle.gridTemplateColumns = "125px auto";
        groupStyle.alignItems = "center";
    }

    return (
        <div 
            style={groupStyle}
            className={groupClassname}
        >
            {label && 
                <FieldLabel 
                    required={required} 
                    className={labelClassName}
                    style={labelStyle}
                >
                    {label}
                </FieldLabel>
            }
            <div className={clsx(
                (prepend || append) && "items-stretch gap-[2px]",
                (prepend && append) && "grid grid-cols-[auto,1fr,auto]",
                (prepend && !append) && "grid grid-cols-[auto,1fr]",
                (!prepend && append) && "grid grid-cols-[1fr,auto]",
                "border border-gray-300 bg-white rounded p-1",
                invalid && "!border-red-500 !bg-red-100",
                valid && "!border-green-500 !bg-green-100",
                disabled && "!bg-gray-100",
                className
            )}>
                {prepend && 
                    <div className={clsx("bg-gray-200 py-1 px-2 align-middle", prependClassName)}>
                        {prepend}
                    </div>
                }
                {instructions ?
                    <div className="grid gap-1">
                        {children}
                        <div 
                            className="text-xs text-gray-500 px-1"
                            style={instructionsStyle}
                        >
                            {instructions}
                        </div>

                        {error && 
                            <ErrorText>{error}</ErrorText>
                        }
                    </div>
                : error ? 
                    <div>
                        {children}
                        <ErrorText>{error}</ErrorText>
                    </div>
                :
                    children
                }
                {append && 
                    <div className={clsx("bg-gray-200 py-1 px-2", appendClassName)}>
                        {append}
                    </div>
                }                
            </div>
        </div>
    )
}

export default Field