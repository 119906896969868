import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLock } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"

import { postData } from "../common/services/server"

function RoleEmployeesDrawer(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
      setIsProcessing(true);

      postData("users/employees", {all_ind: 1, role_id: props.role.id}, 
        function(results) {
          setEmployees(results)
        },
        function(error) {
          alert("An error occured loading the employees.");
        },
        function() {
          setIsProcessing(false);
        }
      );
  }, [props.role]);

  function onEmployeeChange(e) {
    const employeeId = e.target.value;
    const checked = e.target.checked;
    let selected = [];

    setEmployees(produce(draft => {
        const employee = draft.find(e => e.id == employeeId);

        employee.selected_ind = checked ? 1 : 0;

        selected = draft.filter(e => e.selected_ind == 1).map(s => s.name);
    }));

    postData("roles/toggleemployee", {
            role_id: role.id,
            employee_id: employeeId,
            checked_ind: checked ? 1 : 0
        }, 
        function(response) {
            props.onChanged(selected.length)
        },
        function(error) {
            alert("An error occured updating the employee.");
        },
        function() {
        }
    );
  }

  const { role } = props;

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faUserLock} />
                &nbsp;
                {role.name} Employees
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isProcessing ? 
                    <Loading />
                :
                    <div>
                        {employees.map(employee => (
                            <div key={employee.id} style={{display:"flex", justifyContent:"space-between"}}>
                                <Form.Check 
                                    name="employee_id"                                
                                    value={employee.id}
                                    checked={employee.selected_ind == 1}
                                    inline 
                                    label={employee.name} 
                                    type="checkbox" 
                                    onChange={onEmployeeChange}
                                />
                                <div>
                                    <small>{employee.email}</small>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default RoleEmployeesDrawer;