import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetSkillRoles = (options={}) => {  
  return useQuery({ 
    queryKey: ["roles", "skills"], 
    queryFn: ({signal}) => query("roles/skills/list", {}, signal),
    ...options
  });
};
