import React from "react";
import { faClipboardList, faPaintBrush } from '@fortawesome/free-solid-svg-icons'

import DrawerTemplateDataSelect from "./template-dataselect";
import { useGetStyles } from "../api/queries/styles";

export default function DrawerStyleSelect({
    filters,
    ...props
}) { 
  const getStyles = useGetStyles(filters);

  return (
      <>
        <DrawerTemplateDataSelect
            {...props}
            icon={faPaintBrush}
            title={props.title || "Select Style"}
            query={getStyles}
            itemRender={(product) => (
                <div className="flex items-center gap-1">
                    {product.name}
                </div>                
            )}
            itemExistsCheck={(item) => item.exists_ind == 1}
        />

      </>
    )
}