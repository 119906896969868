import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"

import Field from "./field";
import Button from "../buttons/button";
import { colors } from "../../settings/settings"

const Frame = styled.div`
  table {
      width: 100%;
  }
  th {
      background-color: #eee;
      padding: 6px;
      text-align: center;
      border: 1px solid #ccc;
    }
  td {
        border: 1px solid #ccc;
        padding: 3px;
        vertical-align: top;
        font-size: 80%;
        width: 14.2%;
        
        &:hover {
            background-color: #efefef;
        }
        &.current {
            color: ${colors.purple};
            font-weight: bold;
        }
        &.weekend {
            background-color: #eee;
        }
        
        .complete {
            text-decoration: line-through;
        }

        button {
            margin: 0px;
            padding: 3px;
            font-size: 100%;
            font-weight: normal;
            text-align: center;
            width: 100%;
            border: 0px;
            background-color: transparent;
        }      
  }
`;

function getDaysOfMonth(date) {
    var numberOfDays = moment(date).daysInMonth();
    var daysArray = [];
    for (var i = 1; i <= numberOfDays; i++) {
        daysArray.push(i);
    }
    return daysArray;
}

// Get number of days from previous month overlapping current month
function getPreviousMonthOverlappingDays(date) {
    var firstWeekDay = moment(date).startOf('month').weekday();
    var prevMonthDays = [];
    for (var i = 1; i <= firstWeekDay; i++) {
        prevMonthDays.push('');
    }
    return prevMonthDays;
}

// Get number of days from previous month overlapping current month
function getNextMonthOverlappingDays(date) {
    var lastWeekDay = moment(date).endOf('month').weekday();
    var nextMonthDays = [];
    var howManyNextDays = 6 - lastWeekDay;
    for (var i = 1; i <= howManyNextDays; i += 1) {
        nextMonthDays.push('');
    }
    return nextMonthDays;
}

function initWeeksDay() {
    return [0, 1, 2, 3, 4, 5, 6].map(function (el, index) {
        return moment().weekday(index).format('ddd').charAt(0);
    });
}

function CalendarMonth({
    name,
    disabled,
    highlights, 
    onChange,
    ...props
}) {        
    const today = moment(new Date()).startOf('day');
    const [dayDate, setDayDate] = useState(today.toDate());
    const [calendar, setCalendar] = useState([]);

    useEffect(
        () => {            
            var datePositionMap = {};
            var monthDays = getPreviousMonthOverlappingDays(dayDate).concat(getDaysOfMonth(dayDate)).concat(getNextMonthOverlappingDays(dayDate));
            var weekDays = initWeeksDay();
            var weeks = [];
        
            for (var j = 0, rowId = 1; j < monthDays.length; j = j + 7) {
                var week = monthDays.slice(j, j + 7);
                week.forEach(function (day, colId) {
                    datePositionMap[day] = {row: rowId, col: colId};
                });
                weeks.push(week.map(function (d) {
                    return d === '' ? d : moment(dayDate).set('date', d).startOf('day');
                }));
                rowId++;
            }
            
            setCalendar(weeks);
        }, 
        [dayDate]
    );

    function onPrevMonth() {
        setDayDate(moment(dayDate).subtract(1, 'months').toDate())
    }
    function onNextMonth() {
        setDayDate(moment(dayDate).add(1, 'months').toDate())
    }

    return (
        <Field {...props}>
            <div className="p-1">
                <Frame>
                    <h3 className="bg-gray-300 text-center p-2">
                        {moment(dayDate).format("MMMM YYYY")}
                    </h3>
                    <table>
                        <thead>
                            <tr>
                                <th>SUN</th>
                                <th>MON</th>
                                <th>TUE</th>
                                <th>WED</th>
                                <th>THR</th>
                                <th>FRI</th>
                                <th>SAT</th>
                            </tr>
                        </thead>
                        <tbody>
                        {calendar.map((week,index) => (
                            <tr key={index}>
                                {week.map((day,index) => {
                                    let highlight = null;

                                    if (day && highlights) {
                                        highlight = highlights.find(h => day.isSame(h.date, 'day'));
                                    }

                                    return (
                                        <td 
                                            key={index} 
                                            className={day ? day.isSame(today, 'day') ? "current": day.day() == 0 || day.day() == 6 ? "weekend" : "" : "none"}
                                            style={highlight && highlight.color ? {backgroundColor: highlight.color} : {}}
                                        >
                                            {day ? 
                                                    <>
                                                        <button
                                                            disabled={disabled}
                                                            className="grd gap-1 text-center"
                                                            onClick={(e) => {
                                                                if (onChange) {
                                                                    onChange({
                                                                        target: {
                                                                            name: name,
                                                                            value: day.format("YYYY-MM-DD"),
                                                                        }
                                                                    }, day.format("YYYY-MM-DD"))
                                                                }
                                                            }}
                                                        >
                                                            <div>{day.format("D")}</div>
                                                            <div className="flex gap-1 items-center justify-center">
                                                                {(highlight && highlight.icon) && 
                                                                    <FontAwesomeIcon icon={highlight.icon} />
                                                                }
                                                                {highlight && highlight.label && 
                                                                    highlight.label
                                                                }            
                                                            </div>                                                
                                                        </button>
                                                    </>
                                                : 
                                                    null
                                            }
                                        </td>
                                    )
                                })}                            
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Frame>
                <div className="flex items-center justify-between mt-1">
                    <Button onClick={onPrevMonth} variant="outline-secondary" size="sm">
                        <FontAwesomeIcon icon={faChevronLeft} />
                        {' '}
                        Prev Month
                    </Button>
                    <Button onClick={onNextMonth} variant="outline-secondary" size="sm">
                        Next Month
                        {' '}
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                </div>  
            </div>       
        </Field>
    );
}

export default CalendarMonth