import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetProducts = (params, options={}) => {
  return useQuery({ 
    queryKey: ["products", params], 
    queryFn: ({signal}) => query("products/list", params, signal),
    ...options
  });
}
