import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faSave, faTruck, faCheck, faPlus, faTrash, faPrint, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {
    setKey,
    setLanguage,
    fromAddress
} from "react-geocode";

import produce from "immer"

import ShippingItemsDrawer from "../../drawers/drawer-shippingitems"
import EmailDrawer from "../../drawers/drawer-email";

import AddressForm from "../../components/forms/form-address"
import ListField from "../../components/fields/field-list"
import SelectField from "../../components/fields/field-select"
import Loading from "../../components/loading"
import Button from "../../components/buttons/button";
import ButtonProcessing from "../../components/buttons/button-processing"
import { postData } from "../../common/services/server"
import { formatDateTime, formatPrice } from '../../helpers/utility'
import GoogleImage from "../../components/google-image"
import Map from "../../components/map"

import { google } from "../../settings/settings"
import settings from '../../settings'
import FieldNumber from "../../components/fields/field-number";

setKey(google.googleMapsKey);
setLanguage("en");

function OrderShipingPanel(props) {
    const { order } = props;

    const [formData, setFormData] = useState({});
    const [shipping, setShipping] = useState(null);
    const [mapLocation, setMapLocation] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false);
    const [selected, setSelected] = useState(null);

    const [mapBusy, setMapBusy] = useState("");
    const [shippingBusy, setShippingBusy] = useState(false);

    useEffect(
      () => {
          let shipping = {};

          for (const item of order.items) {
            shipping["item" + item.id + "_actualshipping"] = item.shipping_actual;
          }

          setFormData(shipping);

          setMapBusy("loading");
          setShippingBusy(true);

          postData("map/orderdata?order_id=" + order.id, {}, 
                function(response) {
                    setMapLocation(response);
                },
                function(error) {
                    alert("An error occured loading map data.");
                },
                function() {
                    setMapBusy("");
                }
          );

          postData("orders/shippingdata", {id: order.id}, 
                function(response) {
                    setShipping(response || []);
                },
                function(error) {
                    alert("An error occured loading shipping data.");
                },
                function() {
                    setShippingBusy(false);
                }
          );
      }, 
      [order]
    );
    
    function handleFormChange(event) {
        if (event.persist) event.persist();
        console.log("handleFormChange", event.target.name, event.target.value)
        const field = event.target.name;
    
        setFormData(produce(draft => {
          draft[field] = event.target.value;
        }));
    }

    function onShipingMethodChange(item, methodId) {
        setSelected({mode: "method", item});
        setIsProcessing(true);

        postData("orders/changeshippingmethod?order_id=" + order.id, {
                id: item.id,
                shipping_method_id: methodId
            }, 
            function(response) {
                props.onOrderChange(response);
            },
            function(error) {
                alert("An error occured updating the order.");
            },
            function() {
                setIsProcessing(false);
                setSelected(null)
            }
        );
    }

    function onSaveShippingActual(item) {
        setSelected({mode: "actual", item});
        setIsProcessing(true);

        postData("orders/updateshippingactual?order_id=" + order.id, {
                id: item.id,
                actual: formData["item" + item.id + "_actualshipping"]
            }, 
            function(response) {
                props.onOrderChange(response);
            },
            function(error) {
                alert("An error occured updating the order.");
            },
            function() {
                setIsProcessing(false);
                setSelected(null)
            }
        );
    }

    function onSaveTrackingNo(item) {
        setSelected({mode: "tracking", item});
        setIsProcessing(true);

        postData("orders/updatetracking", {
                id: order.id,
                shipping_id: item.id,
                trackingno: item.trackingno
            }, 
            function(response) {
                
            },
            function(error) {
                alert("An error occured updating the order.");
            },
            function() {
                setIsProcessing(false);
                setSelected(null)
            }
        );
    }

    function onGenerateShippingData() {
        setSelected({mode: "generateship"});
        setIsProcessing(true);

        postData("orders/generateshipping", {
                id: order.id
            }, 
            function(response) {
                setShipping(response);
            },
            function(error) {
                alert("An error occured updating the order.");
            },
            function() {
                setIsProcessing(false);
                setSelected(null)
            }
        );
    }

    function isDirty(item) {
        var shipping = formData["item" + item.id + "_actualshipping"];

        return shipping != order.items.find(x => x.id == item.id).shipping_actual;
    }
    function isTrackingDirty(item) {
        var tracking = formData["shipping" + item.id + "_trackingno"];

        return tracking != shipping.boxes.find(x => x.id == item.id).trackingno;
    }

    function onAddShipment(shipMethodId) {
        setIsProcessing(true);
        setSelected({mode:"addbox"})

        postData("orders/addshipment", {
            id: order.id,
            shippingmethod_id: shipMethodId
        }, 
        function(response) {
            setShipping(response);
        },
        function(error) {
            alert("An error occured updating the order.");
        },
        function() {
            setIsProcessing(false);
            setSelected(null)
        });
    }

    function onDeleteShipment(item) {
        if (window.confirm("Delete this shipment?")) {
            setIsProcessing(true);
            setSelected({mode:"deletebox", item})

            postData("orders/deleteshipment", {
                id: order.id,
                shipping_id: item.id
            }, 
            function(response) {
                setShipping(response);
            },
            function(error) {
                alert("An error occured updating the order.");
            },
            function() {
                setIsProcessing(false);
                setSelected(null)
            }
        );
        }
    }

    async function addToMap() {
        setMapBusy("adding");

        const response = await fromAddress(order.ship_address + " " + order.ship_city + " " + order.ship_state + " " + order.ship_zipcode);    
        console.log(response);
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng);
                          
        postData("map/addorder?order_id=" + order.id, { lat, lng }, 
            function(response) {
                setMapLocation(response);
            },
            function(error) {
                alert("An error occured updating the order.");
            },
            function() {
                setMapBusy("");
            }
        );
    }

    async function updateMap(addressData, callback) {
        setMapBusy("updating", addressData);

        const response = await fromAddress(addressData.address + " " + addressData.city + " " + addressData.state + " " + addressData.zipcode);    
        console.log(response);
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng);
                          
        postData("map/update?maplocation_id=" + mapLocation.id, { ...addressData, lat, lng }, 
            function(response) {
                setMapLocation(response);
            },
            function(error) {
                alert("An error occured updating the map.");
            },
            function() {
                setMapBusy("");
                callback();
            }
        );
    }

    function removeFromMap() {
        setMapBusy("removing");
                          
        postData("map/remove", { maplocation_id: mapLocation.id }, 
            function() {
                setMapLocation(null);
            },
            function(error) {
                alert("An error occured updating the order.");
            },
            function() {
                setMapBusy("");
            }
        );   
    }

    function onShippingBox(shipItem, box) {
        console.log(shipItem, box)
    }

    const shippingTotal = order.items.reduce((a, b) => +a + +b.shipping, 0);
    const actualTotal = order.items.reduce((a, b) => +a + +b.shipping_actual, 0);
    const difference = parseFloat(order.shipping_total) - shippingTotal;
    const haveBoxes = order.items.filter(oi => oi.shipping_method == "ups").length > 0;

    let activeJobs = [];

    for (const orderItem of order.items) {
       for (const job of orderItem.jobs.filter(j => j.status_name != 'completed' && j.status_name != "void")) {
        activeJobs.push(job)
       }
    }

    console.log("active job count", activeJobs.length);
    console.log({activeJobs})
    return (
    <>
        <Row>
            <Col xs={12} sm={5}>
                <AddressForm 
                    address={order}
                    phoneno={order.user_phoneno}
                    phonenoformat={true}
                    prefix="ship_"
                    saveUrl={"orders/updateaddress?type=ship&order_id=" + order.id}
                    onChange={(response) => props.onOrderChange(response)}
                    alternativeNames={[
                        {type: "Customer", name :order.user_name},
                        {type: "Billing", name: order.billing_name}
                    ]}
                />
            </Col>
            <Col>
                <Table striped bordered size="sm">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Method</th>
                            <th>Estimate</th>
                            <th>Actual</th>
                            <th>Job</th>
                        </tr>
                    </thead>
                    <tbody>
                    {props.order.items.map(item => (
                        <tr key={item.id}>
                            <td>
                                <GoogleImage 
                                    root 
                                    src={item.preview_url} 
                                    style={{
                                        maxWidth: "125px",
                                        maxHeight: "45px"
                                    }}
                                />
                                <div><small dangerouslySetInnerHTML={{__html: item.title}} /></div>
                            </td>
                            <td>
                                <ListField 
                                    name="shipping_method_id" 
                                    value={item.shipping_method_id == 0 ? -1 : item.shipping_method_id} 
                                    list={[
                                        {value: -1, label: "None"},
                                        {value: 1, label: "UPS"},
                                        {value: 2, label: "Freight"},
                                        {value: 3, label: "Pickup"},
                                        {value: 4, label: "Other"},
                                    ]}
                                    onChange={(e) => onShipingMethodChange(item, e.target.value)} 
                                    groupClassName="nomargin"
                                />
                                {isProcessing && selected && selected.mode == "method" && selected.item == item && <>&nbsp;&nbsp;<FontAwesomeIcon icon={faSpinner} spin={true} />{' '}Updating...</>}
                            </td>
                            <td style={{textAlign:"right"}}>
                                { formatPrice(item.shipping || 0) }
                            </td>
                            <td style={{textAlign:"right", width:"185px"}} className="small-number">
                                <FieldNumber
                                    name={"item" + item.id + "_actualshipping"} 
                                    value={formData["item" + item.id + "_actualshipping"] || 0} 
                                    onChange={handleFormChange}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                
                                        onSaveShippingActual(item);
                                    }}
                                    prepend="$"
                                    append={
                                        <Button 
                                            onClick={() => onSaveShippingActual(item)} 
                                            variant={isDirty(item) ? "primary": "outline-primary"}
                                            size="sm"
                                        >
                                            <FontAwesomeIcon icon={isProcessing && selected && selected.mode == "actual" && selected.item == item ? faSpinner : faSave} spin={isProcessing && selected && selected.mode == "actual" && selected.item == item} />
                                        </Button>
                                    }
                                />
                            </td>
                            <td>
                                {item.jobs.filter(j => j.status_name != 'void').map(job => (
                                    <div key={job.id}>
                                        <ButtonProcessing
                                            caption={"Mark '" + job.title + "' Complete"}
                                            icon={faTruck}
                                            variant="outline-primary"
                                            processing={isProcessing && selected && ((selected.mode == "job" && selected.job == job) || selected.mode == "activejobs")}
                                            disabled={job.status_name == "completed"}
                                            onClick={() => {
                                                setSelected({mode: "job", job});
                                                setIsProcessing(true);

                                                postData("jobs/setstatus", { id: job.id, status_id: 8 }, 
                                                    function() {
                                                        const updatedOrder = JSON.parse(JSON.stringify(props.order));

                                                        const itemIdx = updatedOrder.items.findIndex(oi => oi.id == item.id);

                                                        if (itemIdx != -1) {
                                                            const jobIdx = updatedOrder.items[itemIdx].jobs.findIndex(j => j.id == job.id);

                                                            if (jobIdx != -1) {
                                                                updatedOrder.items[itemIdx].jobs[jobIdx].status_id = 8;
                                                                updatedOrder.items[itemIdx].jobs[jobIdx].status_name = "completed";

                                                                props.onOrderChange(updatedOrder);
                                                            }
                                                        }
                                                    },
                                                    function(error) {
                                                        alert("An error occured updating the job.");
                                                    },
                                                    function() {
                                                        setIsProcessing(false);
                                                        setSelected(null)
                                                    }
                                                );                                                 
                                            }}
                                        />
                                    </div>
                                ))}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={2} style={{backgroundColor:"#eee"}}>
                                &nbsp;
                            </td>
                            <td style={{backgroundColor:"#eee", textAlign:"right"}}>
                                { formatPrice(shippingTotal) }
                            </td>
                            <td style={{backgroundColor:"#eee", textAlign:"right"}}>
                                { formatPrice(actualTotal) }
                            </td>
                        </tr>
                    </tfoot>
                </Table>
                <br/>
                {(shipping) ?
                    <>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Shipment</th>
                                    {haveBoxes && 
                                        <th>Box</th>
                                    }
                                    <th>Items</th>
                                    <th>Tracking No</th>
                                    <th style={{width:"50px"}}>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                            {shipping.boxes.map((item, index) => (
                                <tr key={item.id}>
                                    <td>
                                        {item.shippingmethod_title}
                                        -
                                        {item.boxno}
                                    </td>
                                    {haveBoxes && 
                                        <td>
                                            {item.shippingmethod_name == "ups" ?
                                                <SelectField 
                                                    name="shippingbox"
                                                    labelField="dimensions"
                                                    value={item.box_id} 
                                                    url={"orders/shippingboxes?shippingmethod_id=" + item.shippingmethod_id}
                                                    onChange={(e) => onShippingBox(item, e.target.value)} 
                                                    groupStyle={{marginBottom:"0px"}}
                                                />
                                                : item.shippingmethod_name == "freight" ?
                                                    "Freight"
                                                :
                                                    "?"
                                            }
                                        </td>
                                    }
                                    <td>
                                        <button onClick={() => setSelected({mode:"shippingitems", item})} className="border rounded p-1 !border-blue-500 hover:bg-blue-200">
                                            {item.items.length}
                                        </button>
                                    </td>
                                    <td>
                                        <InputGroup size="sm">
                                            <FormControl 
                                                as="input" 
                                                name={"shipping" + item.id + "_trackingno"} 
                                                value={item.trackingno || ""} 
                                                onChange={(e) => {
                                                    e.persist();

                                                    setShipping(produce(draft => {
                                                        const index = draft.boxes.findIndex(s => s.id == item.id)
                                                        //console.log("tracking change", item.id, index, e.target.value)
                                                        draft.boxes[index].trackingno = e.target.value;
                                                    }));
                                                }}
                                                onKeyPress={event => {                        
                                                    if (event.key === "Enter") {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                
                                                        onSaveTrackingNo(item);
                                                    }
                                                }}
                                            />
                                            <InputGroup.Append>
                                                <Button 
                                                    onClick={() => onSaveTrackingNo(item)} 
                                                    variant={isTrackingDirty(item) ? "primary": "outline-primary"}
                                                    size="sm"
                                                >
                                                    <FontAwesomeIcon icon={isProcessing && selected && selected.mode == "tracking" && selected.item == item ? faSpinner : faSave} spin={isProcessing && selected && selected.mode == "tracking" && selected.item == item} />
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </td>
                                    <td style={{paddingLeft:"0px", paddingRight:"0px"}}>
                                        <ButtonProcessing 
                                            processing={isProcessing && selected && selected.mode == "deletebox" && selected.item == item}
                                            onClick={() => onDeleteShipment(item)} 
                                            variant="outline-danger" 
                                            disabled={item.items.length > 0}
                                            size="sm"
                                            icon={faTrash} 
                                        /> 
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>

                        {(shipping.items.filter(i => !i.shipping_id).length > 0) && 
                            <Alert variant="danger" size="sm">
                                <strong>{shipping.items.filter(i => !i.shipping_id).length}</strong> items 
                                haven't been added to a shipment box.
                            </Alert>
                        }

                        <div style={{display: "flex", gap: "5px"}}>
                            <DropdownButton 
                                variant="outline-success" 
                                title={
                                    <>
                                        <FontAwesomeIcon icon={isProcessing && selected && selected.mode == "addbox" ? faSpinner : faPlus} spin={isProcessing && selected && selected.mode == "addbox"} />{' '}
                                        Add Shipment
                                    </>
                                }
                            >
                                <Dropdown.Item size="sm" onClick={ (e) => onAddShipment(1) }>
                                    UPS
                                </Dropdown.Item>
                                <Dropdown.Item size="sm" onClick={ (e) => onAddShipment(2) }>
                                    Freight
                                </Dropdown.Item>
                            </DropdownButton>
                            <Button
                                variant="outline-primary"
                                href={"/#/pdfs/packingslips/" + order.id} target="_blank"
                                disabled={shipping.length == 0}
                            >
                                <FontAwesomeIcon icon={faPrint} />{' '}
                                Packing Slips
                            </Button>
                            <Button 
                                variant="outline-secondary"
                                onClick={() => {
                                    setSelected({mode:"email", template:"Order Tracking", subject:"Order #" + order.id + " Tracking"});                               
                                }}
                                disabled={shipping.length == 0}
                            >
                                <FontAwesomeIcon icon={faEnvelope} />{' '}
                                Email Tracking
                            </Button>
                        </div>
                    </>
                    : false ?
                    <div>
                        <ButtonProcessing 
                            processing={isProcessing && selected && selected.mode == "generateship"}
                            onClick={onGenerateShippingData} 
                            variant="outline-primary" 
                            caption="Generate Shipping Data" 
                            icon={faTruck} 
                        /> 
                    </div>
                    :
                     null
                }
            </Col>
        </Row>

        <br />
        <br />

        <Row>
            <Col xs={12} sm={5}>
                <Map 
                    markers={mapLocation ? [mapLocation] : []}
                />
            </Col>
            <Col>
                {mapLocation ? 
                    <>
                        <AddressForm 
                            hideName={true}
                            hideAddress2={true}
                            companyField="title"
                            address={mapLocation}
                            prefix=""
                            onSave={updateMap}
                            saveUrl={"map/update?maplocation_id=" + mapLocation.id}
                            onChange={(response) => setMapLocation(response)}
                        />    
                        <Button onClick={removeFromMap} variant="outline-danger" style={{float:"right"}}>
                            <FontAwesomeIcon icon={mapBusy == "removing" ? faSpinner : faTrash} spin={mapBusy == "removing"} />{' '}
                            Remove From Map
                        </Button>
                    </>        
                : mapBusy == "loading" ? 
                    <Loading />
                :
                    <Button onClick={addToMap} variant="outline-success">
                        <FontAwesomeIcon icon={mapBusy == "adding" ? faSpinner : faPlus} spin={mapBusy == "adding"} />{' '}
                        Add Address To Sign Map
                    </Button>
                }
            </Col>
        </Row>
        { selected && selected.mode == "shippingitems" && 
            <ShippingItemsDrawer 
                show={true}
                order={order}
                shipping={shipping}
                shipment={selected.item}
                onChange={(result) => {
                    setShipping(result);
                    setSelected(null);
                }}
                onHide={ () => setSelected(null) } 
            /> 
        }
        { selected && selected.mode == "email" && 
            <EmailDrawer 
                bodyUrl={"emails/template?template=" + escape(selected.template) + "&order_id=" + order.id}
                email={{
                    order_id: order.id,
                    template: "simple",
                    from: "production@signmonkey.com",
                    to: order.user_email.trim(),
                    subject: selected.subject,
                    body: ""
                }}
                show={true}
                onSaved={(email) => {
                    setSelected(null);

                    if (activeJobs.length > 0 && window.confirm("Mark all active jobs Complete?")) {
                        setIsProcessing(true);
                        setSelected({mode:"activejobs"});

                        postData("jobs/setstatus", { ids: activeJobs.map(j => j.id).join(","), status_id: 8 }, 
                            function() {
                                const updatedOrder = JSON.parse(JSON.stringify(props.order));

                                for (const activeJob of activeJobs) {
                                    const itemIdx = updatedOrder.items.findIndex(oi => oi.id == activeJob.orderitem_id);

                                    if (itemIdx != -1) {
                                        const jobIdx = updatedOrder.items[itemIdx].jobs.findIndex(j => j.id == activeJob.id);

                                        if (jobIdx != -1) {
                                            updatedOrder.items[itemIdx].jobs[jobIdx].status_id = 8;
                                            updatedOrder.items[itemIdx].jobs[jobIdx].status_name = "completed";

                                            props.onOrderChange(updatedOrder);
                                        }
                                    }
                                }
                            },
                            function(error) {
                                alert("An error occured updating the jobs.");
                            },
                            function() {
                                setIsProcessing(false);
                                setSelected(null)
                            }
                        );
                    }
                }}
                onHide={ () => setSelected(null) } 
            /> 
        }
    </>
    );
}

export default OrderShipingPanel;