import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTicketAlt } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import ListField from "../components/fields/field-list"
import TextField from "../components/fields/field-text"
import TextAreaField from "../components/fields/field-textarea"
import HtmlField from "../components/fields/field-html"
import FileField from "../components/fields/field-fileupload"
import FieldLabel from "../components/fields/label"
import HelpText from "../components/fields/help-text"
import UploadButton from "../components/buttons/button-upload"
import GoogleImage from "../components/google-image"

import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

import notfound from '../images/nothumbnail.jpg'

function LandingPageDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(
    () => {
      setFormData(JSON.parse(JSON.stringify(props.page)));
    }, 
    [props.page]
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      const {banner_desktop_file, banner_phone_file,  ...data} = formData;


      postData("pages/save?id=" + (props.page.id||0), removeNulls(formData), 
        function(response) {
            props.onSaved(response);
        },
        function(error) {
            alert("An error occured saving the page.");
        },
        function() {
            setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();

    const field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faTicketAlt} />
                &nbsp;
                {props.page.id ? "Update":"Create"} Landing Page
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
                    <FieldLabel required>Url</FieldLabel>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                /shop/
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            name="name"
                            required
                            value={formData.name}
                            onChange={(e) => handleFormChange(e)} 
                        />
                    </InputGroup>
                    <HelpText>* No spaces or special characters</HelpText>

                    <br />

                    <TextField 
                        name="type" 
                        label="Type" 
                        required
                        value={formData.type}
                        instructions="* Typically a single word like Chiropractic" 
                        onChange={(e) => handleFormChange(e)} 
                    />

                    <TextField 
                        name="title" 
                        label="Title" 
                        required
                        value={formData.title} 
                        onChange={(e) => handleFormChange(e)} 
                    />
                    <TextAreaField 
                        name="keywords" 
                        label="Keywords" 
                        value={formData.keywords} 
                        rows={4}
                        onChange={(e) => handleFormChange(e)} 
                    />
                    <TextAreaField 
                        name="description" 
                        label="Description" 
                        required
                        value={formData.description} 
                        rows={6}
                        onChange={(e) => handleFormChange(e)} 
                    />

                    <ListField 
                        name="featured_ind" 
                        label="Featured" 
                        value={formData.featured_ind} 
                        required
                        list={[
                            {value: "0", label: "No"},
                            {value: "1", label: "Yes"},
                        ]}
                        onChange={(e) => handleFormChange(e)} 
                    />

                    <HtmlField 
                        name="seo" 
                        label="SEO Text" 
                        value={formData.seo} 
                        rows={6}
                        onChange={(e) => handleFormChange(e)} 
                    />

                    <HtmlField 
                        name="seo2" 
                        label="SEO Text Collapsed" 
                        value={formData.seo2} 
                        rows={6}
                        onChange={(e) => handleFormChange(e)} 
                    />


                    <div style={{display: "flex", border: "1px solid #ccc", borderRadius: "4px", padding: "5px", marginBottom:"10px"}}>
                        <div>
                          {formData.banner_desktop_url  ?
                              <GoogleImage 
                                root 
                                src={formData.banner_desktop_url} 
                                style={{
                                    maxWidth: "200px",
                                    maxHeight: "75px"
                                }}
                              />  
                            :
                              <img src={notfound} width="200" />                                                
                          }
                        </div>
                        <div>
                          <UploadButton
                            uploadUrl={"pages/upload"}
                            label="Upload Desktop Image"
                            thumbnail={false}
                            multiple={false}
                            extensions={["jpg"]}
                            onUpload={(file) => {
                                console.log("desktop file", file)
                                setFormData(produce(draft => {
                                  draft.banner_desktop_file_id = file.id;
                                  draft.banner_desktop_url = file.url;
                                }));
                              }}
                          /> 

                          <p>JPG image 1350px by 541px</p>
                        </div>
                    </div>

                    <div style={{display: "flex", border: "1px solid #ccc", borderRadius: "4px", padding: "5px", marginBottom:"10px"}}>
                        <div>
                          {formData.banner_tablet_url ? 
                            <GoogleImage 
                              root 
                              src={formData.banner_tablet_url} 
                              style={{
                                  maxWidth: "200px",
                                  maxHeight: "75px"
                              }}
                            />     
                            :
                              <img src={notfound} width="200" />                                                    
                        }
                        </div>
                        <div>
                          <UploadButton
                            uploadUrl={"pages/upload"}
                            label="Upload Tablet Image"
                            thumbnail={false}
                            multiple={false}
                            extensions={["jpg"]}
                            onUpload={(file) => {
                                console.log("tablet file", file)
                                setFormData(produce(draft => {
                                  draft.banner_tablet_file_id = file.id;
                                  draft.banner_tablet_url = file.url;
                                }));
                              }}
                          /> 

                          <p>JPG image 880px by 353px</p>
                        </div>
                    </div>

                    <div style={{display: "flex", border: "1px solid #ccc", borderRadius: "4px", padding: "5px", marginBottom:"10px"}}>
                        <div>
                          {formData.banner_phone_url ? 
                            <GoogleImage 
                              root 
                              src={formData.banner_phone_url} 
                              style={{
                                  maxWidth: "200px",
                                  maxHeight: "75px"
                              }}
                            />    
                            :
                              <img src={notfound} width="200" />                                                     
                        }
                        </div>
                        <div>
                          <UploadButton
                            uploadUrl={"pages/upload"}
                            label="Upload Phone Image"
                            thumbnail={false}
                            multiple={false}
                            extensions={["jpg"]}
                            onUpload={(file) => {
                                console.log("phone file", file)
                                setFormData(produce(draft => {
                                  draft.banner_phone_file_id = file.id;
                                  draft.banner_phone_url = file.url;
                                }));
                              }}
                          /> 

                          <p>JPG image 500px by 172px</p>
                        </div>
                    </div>


                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonProcessing 
                    processing={isProcessing}
                    onClick={onSave} 
                    variant="outline-success" 
                    caption="Save Page" 
                    icon={faSave} /> 
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default LandingPageDrawer;