import React, { useState, useEffect } from "react";

import Form from 'react-bootstrap/Form';
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { postData } from "../../common/services/server"

function CalendarPrefsButton(props) {

  const [prefs, setPrefs] = useState({})
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
        setLoading(true);

        postData("users/prefs", {},
            function(result) {
                setPrefs(result);
                props.onPrefsChanged(result);
            },
            function(error) {
            },
            function() {
              setLoading(false)
            }
        );
    }, 
    []
  );

  function onPrefChange(name, value) {
      setLoading(true);

      postData("users/setpref", {
          name,
          value
        },
        function(result) {
          setPrefs(result);
          props.onPrefsChanged(result);
        },
        function(error) {
        },
        function() {
          setLoading(false)
        }
      );
  }

  return (
    <DropdownButton
        variant="secondary"
        title={
            <>
                <FontAwesomeIcon icon={loading ? faSpinner : faCalendarAlt} spin={loading} />{' '}
                {'Calendar Preferences'}
            </>
        }
        size={props.size}
        alignRight
    >
        <Dropdown.Item>
            <Form.Check 
                type="checkbox"
                inline
                label="Signs Data"
                value={1}
                checked={prefs.calendar_signs == "true"}    
                onClick={(e) => {
                    e.stopPropagation();
                }}              
                onChange={(e) => {
                    onPrefChange("calendar_signs", e.target.checked ? "true":"false")
                }}                    
            />
        </Dropdown.Item>
        <Dropdown.Item>
            <Form.Check 
                type="checkbox"
                inline
                label="Users Data"
                value={1}
                checked={prefs.calendar_users == "true"}    
                onClick={(e) => {
                    e.stopPropagation();
                }}              
                onChange={(e) => {
                    onPrefChange("calendar_users", e.target.checked ? "true":"false")
                }}                    
            />
        </Dropdown.Item>        
        <Dropdown.Item>
            <Form.Check 
                type="checkbox"
                inline
                label="Orders Data"
                value={1}
                checked={prefs.calendar_orders == "true"} 
                onClick={(e) => {
                    e.stopPropagation();
                }}                                    
                onChange={(e) => {
                    onPrefChange("calendar_orders", e.target.checked ? "true":"false")
                }}                    
            />
        </Dropdown.Item>
        <Dropdown.Item>
            <Form.Check 
                type="checkbox"
                inline
                label="Jobs Data"
                value={1}
                checked={prefs.calendar_jobs == "true"}       
                onClick={(e) => {
                    e.stopPropagation();
                }}                             
                onChange={(e) => {
                    onPrefChange("calendar_jobs", e.target.checked ? "true":"false")
                }}                     
            />
        </Dropdown.Item>
        <Dropdown.Item>
            <Form.Check 
                type="checkbox"
                inline
                label="Tasks Data"
                value={1}
                checked={prefs.calendar_tasks == "true"}  
                onClick={(e) => {
                    e.stopPropagation();
                }}                                 
                onChange={(e) => {
                    onPrefChange("calendar_tasks", e.target.checked ? "true":"false")
                }}                  
            />
        </Dropdown.Item>   
        <Dropdown.Item>
            <Form.Check 
                type="checkbox"
                inline
                label="Call Data"
                value={1}
                checked={prefs.calendar_calls == "true"}  
                onClick={(e) => {
                    e.stopPropagation();
                }}                                 
                onChange={(e) => {
                    onPrefChange("calendar_calls", e.target.checked ? "true":"false")
                }}                  
            />
        </Dropdown.Item>                           
    </DropdownButton>        
  );
}

export default CalendarPrefsButton;