import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faSave, faSms, faUserFriends, faUserTimes, faUserPlus, faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { Link, useHistory } from "react-router-dom";

import UserSelectDrawer from "../../drawers/drawer-userselect"
import IpAddressDrawer from "../../drawers/drawer-ipaddress"
import PropertyList from "../../components/properties/property-list"
import Property from "../../components/properties/property"
import Button from "../../components/buttons/button";

import { formatDateTime, parsePhoneNumber, formatPhoneNumber, isValidPhoneNumber } from "../../helpers/utility"
import { postExternalData, postData } from "../../common/services/server"
import settings from "../../settings"
import { userHasPermission, Permissions, Actions, userIsAdmin } from "../../common/services/auth";
import produce from "immer";
import { Badge } from "react-bootstrap";

const Properties = styled.div`
  display: flex;
  align-items: flex-start;
`
const Duplicates = styled.div`
    margin-bottom: 15px;
`
const Related = styled.div`
    margin-bottom: 15px;
`

function UserOverviewPanel(props) {
    const history = useHistory();

    const [ipAddresses, setIpAddresses] = useState([]);
    const [selected, setSelected] = useState(null);
    const [busy, setBusy] = useState(null);

    const { user } = props;
    const isEmployee = user && user.role == "admin";
    const [canEdit, setCanEdit] = useState(userHasPermission(isEmployee ? Permissions.Employees:Permissions.Customers, Actions.Edit))
    
    useEffect(
        () => {
            postData("users/ipaddresses", { 
                user_id: user.id
            },
                function(result) {
                    setIpAddresses(result);
                },
                function(error) {
                    alert("Error loading ip addresses")
                }
            );
        }, 
        [user]
    );

    function onPasswordReset() {
        if (window.confirm("Send this user a password reset email?")) {
            props.onUserPropBusy("password");

            postExternalData(settings.SITE_API_URL + "users/forgotpassword", {email: user.email},
                function(result) {
                    
                },
                function(error) {
                    window.alert("Error sending email");
                },
                function() {
                    props.onUserPropBusy("password", false);
                }
            );
        }
    }

    function onMerge(duplicate) {
        setBusy(duplicate);

        postData("users/merge", {from_user_id: duplicate.id, to_user_id: user.id},
            function(result) {
                window.location.reload();
            },
            function(error) {
                window.alert("Error merging users.");
            },
            function() {
                setBusy(null)
            }
        );
    }

    function onRelate(related) {
        setBusy("addingrelated");

        postData("users/relate", {related_user_id: related.id, user_id: user.id},
            function(result) {
                window.location.reload();
                
            },
            function(error) {
                window.alert("Error updating user.");
            },
            function() {
                setBusy(null)
            }
        );
    }   

    function onUnrelate(related) {
        setBusy(related);

        postData("users/unrelate", {related_user_id: related.id, user_id: user.id},
            function(result) {
                window.location.reload();
            },
            function(error) {
                window.alert("Error updating user.");
            },
            function() {
                setBusy(null)
            }
        );
    }    

    return (
    <>
        {(user.duplicates && user.duplicates.length) > 0 && 
            <Duplicates>
                {user.duplicates.map(duplicate => (
                    <Alert variant="danger" size="sm" key={duplicate.id} style={{display:"flex", justifyContent:"space-between", marginBottom:"5px"}}>
                        <div>
                            User{' '}<Link to={"/users/user/" + duplicate.id}>#{duplicate.id} {duplicate.name}</Link>{' '}has 
                            duplicate email address.
                        </div>
                        <Button size="sm" disabled={!canEdit} onClick={() => onMerge(duplicate)} variant="danger">
                            <FontAwesomeIcon icon={busy==duplicate ? faSpinner:faUserFriends} spin={busy==duplicate} /> {' '}
                            Merge Duplicate
                        </Button>
                    </Alert>
                ))}
            </Duplicates>
        }
        {(user.related && user.related.length) > 0 && 
            <Related>
                {user.related.map(related => (
                    <Alert variant="info" size="sm" key={related.id} style={{display:"flex", justifyContent:"space-between", marginBottom:"5px"}}>
                        <div>
                            User{' '}
                            <Link to={"/users/user/" + related.id}>#{related.id} {related.name}</Link>{' '} is related to this user.                            
                        </div>
                        <div>
                            {related.email}
                        </div>
                        <Button size="sm" disabled={!canEdit} onClick={() => onUnrelate(related)} variant="info">
                            <FontAwesomeIcon icon={busy==related ? faSpinner:faUserTimes} spin={busy==related} /> {' '}
                            Unrelate User
                        </Button>                        
                    </Alert>
                ))}
            </Related>
        }        
        <Properties>
            <PropertyList maxWidth={"500px"}>
                <Property label="Unique ID" value={user.id} />
                <Property label="First Name">
                    <InputGroup>
                        <FormControl
                            value={user.first_name || ""}
                            disabled={!canEdit}
                            onChange={(event) => props.onUserPropChange("first_name", event.target.value) }
                            onKeyDown={(e) => {
                                if (canEdit && e.key === 'Enter') {
                                    props.onSaveUserProp("first_name");
                                }
                            }}                    
                        />

                        <InputGroup.Append>
                            <Button onClick={() => props.onSaveUserProp("first_name")} disabled={!canEdit} variant={props.dirty.first_name ? "primary":"outline-secondary"}>
                                <FontAwesomeIcon icon={props.busy && props.busy.first_name ? faSpinner:faSave} spin={props.busy && props.busy.first_name} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Property>
                <Property label="Last Name">
                    <InputGroup>
                        <FormControl
                            value={user.last_name || ""}
                            disabled={!canEdit}
                            onChange={(event) => props.onUserPropChange("last_name", event.target.value) }
                            onKeyDown={(e) => {
                                if (canEdit && e.key === 'Enter') {
                                    props.onSaveUserProp("last_name");
                                }
                            }}                    
                        />

                        <InputGroup.Append>
                            <Button onClick={() => props.onSaveUserProp("last_name")} disabled={!canEdit} variant={props.dirty.last_name ? "primary":"outline-secondary"}>
                                <FontAwesomeIcon icon={props.busy && props.busy.last_name ? faSpinner:faSave} spin={props.busy && props.busy.last_name} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Property>
                <Property label="Email">
                    <InputGroup>
                        <FormControl
                            value={user.email || ""}
                            disabled={!canEdit}
                            onChange={(event) => props.onUserPropChange("email", event.target.value) }
                            onKeyDown={(e) => {
                                if (canEdit && e.key === 'Enter') {
                                    props.onSaveUserProp("email");
                                }
                            }}                    
                        />

                        <InputGroup.Append>
                            <Button onClick={() => props.onSaveUserProp("email")} disabled={!canEdit} variant={props.dirty.email ? "primary":"outline-secondary"}>
                                <FontAwesomeIcon icon={props.busy && props.busy.email ? faSpinner:faSave} spin={props.busy && props.busy.email} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                    <div align="right">
                        <a href="#" className="text-blue-500" onClick={(e) => {
                            e.preventDefault();
                            setSelected({mode:"addrelated"})
                        }}>
                            Add Related Email Address
                        </a>
                    </div>
                </Property>
                {!isEmployee && 
                    <Property label="Email Unsubscribe">
                        <Form.Check 
                            id="email_unsubscribe_ind"
                            value={1}
                            disabled={!canEdit}
                            checked={user.email_ind == 0}
                            label="Unsubcribed from automated emails?"
                            type="switch"
                            onChange={(e) => {
                                console.log("email_ind", e.target.checked)
                                props.onSaveUserProp("email_ind", e.target.checked ? 0:1);
                            }}
                        />

                        {user.email_ind == 0 &&
                            <div>
                                <small style={{color:"red"}}>
                                    This user will not be sent any automated emails, but will still receive
                                    emails that are the result of thier action like saving a sign.
                                </small>
                            </div>
                        }
                    </Property>   
                }
                <Property label="Phone No" hint="Format: (999) 999-9999">
                    <InputGroup>
                        <FormControl
                            value={formatPhoneNumber(user.phoneno || "")}
                            disabled={!canEdit}
                            isInvalid={!isValidPhoneNumber(user.phoneno)}
                            onChange={(event) => props.onUserPropChange("phoneno", parsePhoneNumber(event.target.value)) }
                            onKeyDown={(e) => {
                                if (canEdit && e.key === 'Enter') {
                                    props.onSaveUserProp("phoneno");
                                }
                            }}                    
                        />

                        <InputGroup.Append>
                            <Button onClick={() => props.onSaveUserProp("phoneno")} disabled={!canEdit} variant={props.dirty.phoneno ? "primary":"outline-secondary"}>
                                <FontAwesomeIcon icon={props.busy && props.busy.phoneno ? faSpinner:faSave} spin={props.busy && props.busy.phoneno} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Property>
                {!isEmployee && 
                    <Property label="Text Unsubscribe">
                        <Form.Check 
                            id="text_unsubscribe_ind"
                            value={1}
                            disabled={!canEdit}
                            checked={user.text_ind == 0}
                            label="Unsubcribed from text messages?"
                            type="switch"
                            onChange={(e) => {
                                console.log("text_ind", e.target.checked)
                                props.onSaveUserProp("text_ind", e.target.checked ? 0:1);
                            }}
                        />

                        {user.text_ind == 0 &&
                            <div>
                                <small style={{color:"red"}}>
                                    This user has requested to not be sent any text messages
                                </small>
                            </div>
                        }
                    </Property>   
                }                
                <Property label="Has Password">
                    <Form.Check 
                        type="radio"
                        value={0}
                        disabled={!canEdit}
                        checked={user.password_ind != 1}
                        label="No"
                        inline
                        onChange={(e) => {
                            if (e.target.checked)
                                props.onSaveUserProp("password_ind", 0);
                        }}
                    />
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <Form.Check 
                        type="radio"
                        value={1}
                        disabled={!canEdit}
                        checked={user.password_ind == 1}
                        label="Yes"
                        inline
                        onChange={(e) => {
                            if (e.target.checked)
                                props.onSaveUserProp("password_ind", 1);
                        }}
                    />
                </Property>
                {user.password_ind == 1 && 
                    <>
                        <Property label="Password">
                            <InputGroup>
                                <FormControl
                                    value={user.password || "*****"}
                                    disabled={!canEdit}
                                    onChange={(event) => props.onUserPropChange("password", event.target.value) }
                                    onKeyDown={(e) => {
                                        if (canEdit && e.key === 'Enter') {
                                            props.onSaveUserProp("password");
                                        }
                                    }}                    
                                />

                                <InputGroup.Append>
                                    <Button onClick={() => props.onSaveUserProp("password")} disabled={!canEdit} variant={props.dirty.password ? "primary":"outline-secondary"}>
                                        <FontAwesomeIcon icon={props.busy && props.busy.password ? faSpinner:faSave} spin={props.busy && props.busy.password} />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Property>
                        <Property>
                            <Button onClick={onPasswordReset} variant="link" size="sm">
                                Send Password Reset Email
                            </Button>
                        </Property>
                    </>
                }
            </PropertyList>

            <PropertyList maxWidth={"500px"}>
                {isEmployee && 
                    <Property label="Login Restrictions">
                        <div>
                            Which locations/ip addresses are they allowed to login from?
                        </div>

                        {ipAddresses.map(ipAddress => (
                            <div key={ipAddress.id} className="flex items-center justify-between p-1 gap-2 border rounded border-gray-100">
                                <div className="flex">
                                    <Form.Check 
                                        type="checkbox"
                                        value={true}
                                        checked={ipAddress.checked_ind == 1}
                                        onChange={(e) => {
                                            e.persist();

                                            setIpAddresses(produce(draft => {
                                                let idx = draft.findIndex(ip => ip.id == ipAddress.id);

                                                if (idx != -1) {
                                                    draft[idx].checked_ind = e.target.checked ? 1 : 0
                                                }                                                            
                                            }))

                                            postData("users/toggleipaddress", {
                                                    user_id: user.id,
                                                    ipaddress_id: ipAddress.id,
                                                    checked_ind: e.target.checked ? 1 : 0
                                                }, 
                                                function(response) {
               
                                                },
                                                function(error) {
                                                    alert("An error occured saving the ip address.");
                                                }
                                            );                                              
                                        }}
                                    />
                                    <div>
                                        {ipAddress.name}
                                        {ipAddress.name != ipAddress.ip_address && 
                                            <small style={{color: "#999", marginLeft: "10px"}}>{ipAddress.ip_address}</small>
                                        }
                                    </div>
                                </div>
                                {userIsAdmin() && 
                                    <div className="flex gap-1">
                                        <Badge 
                                            variant="secondary"
                                            onClick={() => setSelected({mode: "ipaddress", ipAddress})} 
                                        >
                                            {ipAddress.users_count}
                                        </Badge>
                                        <Button 
                                            onClick={() => setSelected({mode: "ipaddress", ipAddress})} 
                                            variant="outline-secondary" 
                                            size="tiny"
                                            disabled={busy==("deleteip" + ipAddress.id)}
                                        >
                                            <FontAwesomeIcon icon={faPencilAlt} fixedWidth />
                                        </Button>
                                        <Button
                                            variant="outline-danger" 
                                            size="tiny" 
                                            disabled={busy==("deleteip" + ipAddress.id)}
                                            onClick={() => {
                                                if (window.confirm("Are you sure you want to delete this IP Address?")) {
                                                    setBusy("deleteip" + ipAddress.id)
                                                    
                                                    postData("users/deleteipaddress", {
                                                            ipaddress_id: ipAddress.id
                                                        }, 
                                                        function(response) {
                                                            setIpAddresses(produce(draft => {
                                                                let idx = draft.findIndex(ip => ip.id == ipAddress.id);

                                                                if (idx != -1) {
                                                                    draft.splice(idx, 1);
                                                                }                                                            
                                                            }))
                                                        },
                                                        function(error) {
                                                            alert("An error occured saving the ip address.");
                                                        },
                                                        function() {
                                                            setBusy(null);
                                                        }
                                                    );                                                     
                                                }
                                            }}>
                                            <FontAwesomeIcon icon={busy && busy==("deleteip" + ipAddress.id) ? faSpinner:faTrash} spin={busy==("deleteip" + ipAddress.id)} fixedWidth />
                                        </Button>
                                    </div>
                                }
                            </div>
                        ))}

                        {userIsAdmin() && 
                            <div style={{textAlign: "right", marginTop: "5px"}}>
                                <Button onClick={() => setSelected({mode: "ipaddress"})} variant="outline-success" size="sm">
                                    Add IP Address
                                </Button>
                            </div>
                        }                        
                    </Property>
                }                
                {isEmployee && 
                    <Property label="Status">
                        <Form.Check 
                            type="radio"
                            value={"Active"}
                            disabled={!canEdit}
                            checked={user.status == "Active"}
                            label="Active"
                            inline
                            onChange={(e) => {
                                if (e.target.checked)
                                    props.onSaveUserProp("status", "Active");
                            }}
                        />
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <Form.Check 
                            type="radio"
                            value={"Inactive"}
                            disabled={!canEdit}
                            checked={user.status == "Inactive"}
                            label="Inactive"
                            inline
                            onChange={(e) => {
                                if (e.target.checked)
                                    props.onSaveUserProp("status", "Inactive");
                            }}
                        />
                    </Property>                
                }
                <Property label="Added Date" value={formatDateTime(user.added_date)} />
                <Property label="Added From" value={user.added_location} />
                <Property label="Session" value={user.sessionid} />
            </PropertyList>

        </Properties>

        {(selected && selected.mode == "addrelated") && 
            <UserSelectDrawer
                show={true}
                title="Add Related User"
                actions={[
                    {
                        label: "Add Related User",
                        icon: faUserPlus,
                        variant: "success",
                        busy: busy=="addingrelated",
                        onClick: onRelate
                    }
                ]}
                onHide={() => setSelected(null)}
            />
        }   
        {(selected && selected.mode == "ipaddress") && 
            <IpAddressDrawer
                show={true}
                ipAddress={selected.ipAddress || {
                    user_id: user.id
                }}
                onSaved={(ipData) => {
                    setIpAddresses(produce(draft => {
                        if (selected.ipAddress) {
                            let idx = draft.findIndex(ip => ip.id == selected.ipAddress.id);

                            if (idx != -1) {
                                draft[idx].name = ipData.name;
                                draft[idx].ip_address = ipData.ip_address;
                                draft[idx].status = ipData.status;
                            }
                        }
                        else {
                            draft.push(ipData);
                        }
                    }));

                    setSelected(null);
                }}
                onHide={() => setSelected(null)}
            />
        }                     
    </>
    );
}

export default UserOverviewPanel;