import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faSpinner, faSave } from "@fortawesome/free-solid-svg-icons";
import FormControl from 'react-bootstrap/FormControl';
import styled from "styled-components";
import produce from "immer"
import { fabric } from 'fabric'

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"
import Button from "../components/buttons/button";

import ListField from "../components/fields/field-list"
import NumberField from "../components/fields/field-number"

import { postData } from "../common/services/server"
import { Permissions, Actions } from "../common/services/auth";
import PageLayout from "../components/page-layout";

const KerningTable = styled.table`
    td {
        vertical-align: top;
        padding: 5px;

        > div {
            display: flex;
            margin-bottom: 10px;
        }

        input {
            width: 125px !important;
            text-align: center;
        }

        label.default { color: blue; }
        label.minimum { color: green; }
    }
`
const Header = styled.div`
    position: sticky;
    top: 48px;
    display: flex;
    align-items: center;
    background-color: #eee;
    padding: 5px;

    label {
        font-weight: bold;
        margin: 0px 10px 0px 0px;
    }
`

function KerningPage(props) {
    const canvasRefs = useRef([]);
    let { styleId } = useParams();

    const [loading, setLoading] = useState(true);
    const [canvases, setCanvases] = useState({});
    const [style, setStyle] = useState(null);
    const [shapes, setShapes] = useState(null);
    const [shape, setShape] = useState(null);
    const [kerning, setKerning] = useState(null);
    const [dirty, setDirty] = useState(false);
    const [saving, setSaving] = useState(false);

    const codes = ["&","'",",","-",".","0","1","2","3","4","5","6","7","8","9","A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z"];

    useEffect(
        () => {
            setLoading(true);

            postData("styles/styledata", { style_id: styleId },
                function(result) {
                    setStyle(result);

                    let shapesData = [];

                    for (const shape of result.shapes) {
                        shapesData.push({
                            ...shape, 
                            label: shape.code + (!shape.kerning || shape.kerning.length==0 ? "*":"")                            
                        })
                    }

                    setShapes(shapesData);
                },
                function(error) {
                    alert("Error loading style kerning data")
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        []
    );

    const exportSize = 10;
    const pixelsInInch = 7;
    const baseline = exportSize * pixelsInInch;

    useEffect(
        () => {
            if (shapes) {
                const canvases = {};

                for (const letter of shapes) {
                    const element = canvasRefs.current[letter.code];
                    const canvas = new fabric.StaticCanvas(element, { backgroundColor: 'rgb(200,200,200)' });

                    // keep ref
                    canvases[letter.code] = canvas;

                    canvas.setWidth(300);
                    canvas.setHeight(150);

                    const line = new fabric.Line([0, baseline, canvas.getWidth(), baseline], {
                                      fill: 'red',
                                      stroke: 'red',
                                      strokeWidth: 2
                                    });
    
                    canvas.add(line);  

                    var path2 = new fabric.Path(letter.path);
                    var path3 = new fabric.Path(letter.path);

                    path2.type = "default";
                    path3.type = "minimum";

                    canvas.add(path2);
                    canvas.add(path3);
  
                    path2.scaleX = ( (pixelsInInch * exportSize) * letter.width_ratio ) / path2.width;
                    path2.scaleY = ( (pixelsInInch * exportSize) * letter.height_ratio ) / path2.height;
  
                    path3.scaleX = ( (pixelsInInch * exportSize) * letter.width_ratio ) / path3.width;
                    path3.scaleY = ( (pixelsInInch * exportSize) * letter.height_ratio ) / path3.height;
  
                    path2.center();
                    path3.center();

                    path2.left = 20;
                    path3.left = 0;
  
                    path2.fill = "blue";
                    path3.fill = "green";
  
                    path3.opacity = .7;
  
                    path2.top = (baseline - (letter.top_offset * (exportSize * pixelsInInch)));
                    path3.top = (baseline - (letter.top_offset * (exportSize * pixelsInInch)));

                    if (letter.code == "i") {
                        //console.log(path2)
                        //path2.left = 20;
                        //path2.top = 0;
                        //console.log(path2)
                    }

                    canvas.renderAll();               
                }

                setCanvases(canvases);
            }
        }, 
        [shapes]
    );

    function getCanvasPath(canvas, type) {
        var objects = canvas.getObjects(type);

        return objects.length ? objects[0] : null;
    }

    useEffect(
        () => {
            if (shape) {
                console.log(shape)
                for (const letter of shapes) {
                    const canvas = canvases[letter.code];
  
                    var previous = getCanvasPath(canvas, "letter");

                    if (previous)
                        canvas.remove(previous);

                    var path = new fabric.Path(shape.path);
  
                    canvas.add(path);
  
                    path.scaleX = ( (pixelsInInch * exportSize) * shape.width_ratio ) / path.width;
                    path.scaleY = ( (pixelsInInch * exportSize) * shape.height_ratio ) / path.height;
    
                    path.type = "letter";
                    path.fill = "black";
  
                    path.left = 0;
                    path.top = (baseline - (shape.top_offset * (exportSize * pixelsInInch)));
    
                    // set initial kerning for this shape
                    var minPath = getCanvasPath(canvas, "minimum");
                    var defPath = getCanvasPath(canvas, "default");

                    if (shape.kerningData[letter.code]) {
                        defPath.left = path.width + shape.kerningData[letter.code].default * pixelsInInch;
                        minPath.left = path.width + shape.kerningData[letter.code].minimum * pixelsInInch;
                    }
                    else {
                        console.log(letter.code + " NOT FOUND");
                    }

                    canvas.renderAll();                
                }

                setKerning(shape.kerningData);
            }

            setDirty(false);
        }, 
        [shape]
    );  

    useEffect(
        () => {
            if (shape && kerning) {
                for (const letter of shapes) {
                    const canvas = canvases[letter.code];
  
                    var path = getCanvasPath(canvas, "letter");
                    var minPath = getCanvasPath(canvas, "minimum");
                    var defPath = getCanvasPath(canvas, "default");

                    if (kerning[letter.code]) {
                        defPath.left = path.width + kerning[letter.code].default * pixelsInInch;
                        minPath.left = path.width + kerning[letter.code].minimum * pixelsInInch;
                    }
                    else {
                        console.log(letter.code + " NOT FOUND")
                    }

                    canvas.renderAll();                
                }
            }


        }, 
        [kerning]
    ); 

   function onAdjustKerning(letter, type, amount) {         
        setKerning(produce(draft => {
            let value = draft[letter][type] += amount;

            draft[letter][type] = Math.round(value*100)/100;
        }));

        setDirty(true);
    }
    function onSetKerning(letter, type, value) {
        const canvas = canvases[letter];

        setKerning(produce(draft => {
            draft[letter][type] = Math.round(value*100)/100;
        }));

        setDirty(true);
    }  

    function onSave() {
        setSaving(true);

        postData("shapes/savekerning", { style_id: styleId, shape_id: shape.id, kerning: JSON.stringify(kerning) },
            function(result) {
                
            },
            function(error) {
                alert("Error saving kerning data")
            },
            function() {
                setSaving(false);
            }
        );        
    }

    return (
        <PageLayout>
            <PageHeader 
                title={(style ? style.name + " " : "") + "Kerning"}
                parent={{
                    url: "/data/styles",
                    title: "Styles"
                }}                
                help="kerning"
            />
            <Content permission={Permissions} action={Actions.Create} padding={1}>
                <div>
                    <Header>
                        <label>Letter: </label>
                        <ListField 
                            name="shape" 
                            value={shape ? shape.id : ""} 
                            list={shapes || []}
                            disabled={saving}
                            onChange={(e) => {
                                console.log(e.target.value);
                                console.log(shapes.find(s => s.id == e.target.value));

                                setShape(shapes.find(s => s.id == e.target.value));
                            }}
                            groupClassName="nomargin"
                            size="sm"
                        />  
                    </Header>                     

                    <KerningTable>
                        <tbody>
                        {codes.map(code => (
                            <tr key={code}>
                                <td>
                                    <canvas ref={el => canvasRefs.current[code] = el} />
                                </td>
                                <td>
                                    <label className="default">Default Kerning</label>
                                    <div>                                
                                        <Button variant="secondary" onClick={() => onAdjustKerning(code, 'default', -.1)} disabled={!shape || saving}>
                                            <FontAwesomeIcon icon={faMinus} />
                                        </Button>
                                        <NumberField 
                                            name="defaultspacing" 
                                            value={kerning && kerning[code] ? kerning[code].default : 0} 
                                            disabled={!shape || saving}                                            
                                            onChange={(e) => {
                                                onSetKerning(code, 'default', parseFloat(e.target.value));
                                            }}
                                            groupStyle={{marginBottom:"0px"}}
                                        />                                        
                                        <Button variant="secondary" onClick={() => onAdjustKerning(code, 'default', .1)} disabled={!shape || saving}>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </div>

                                    <label className="minimum">Minimum Kerning</label>
                                    <div>
                                        <Button variant="secondary" onClick={() => onAdjustKerning(code, 'minimum', -.1)} disabled={!shape || saving}>
                                            <FontAwesomeIcon icon={faMinus} />
                                        </Button>
                                        <NumberField 
                                            name="minimumspacing" 
                                            value={kerning && kerning[code] ? kerning[code].minimum : 0} 
                                            disabled={!shape || saving}
                                            onChange={(e) => {
                                                onSetKerning(code, 'minimum', parseFloat(e.target.value));
                                            }}
                                            groupStyle={{marginBottom:"0px"}}
                                        />
                                        <Button variant="secondary" onClick={() => onAdjustKerning(code, 'minimum', .1)} disabled={!shape || saving}>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </KerningTable>

                    {dirty && 
                        <Button variant="success" onClick={onSave} size="lg" style={{position: "fixed", right: "25px", bottom: "25px", zIndex: "9999"}}>
                            <FontAwesomeIcon icon={saving ? faSpinner : faSave} spin={saving} />{' '}
                            Save Kerning Data
                        </Button> 
                    }                   
                </div>
            </Content>
        </PageLayout>
    );
}

export default KerningPage;


/*

{% set menuName = "shapes" %}
{% extends 'AdminBundle::adminlayout.html.twig' %}

{% block styles %}

    label { display:block; }

    #shapes input { width:60px; background-color:#eee; text-align:center; }
    input[type=submit] { width:auto; }
    #shapes td { padding:5px; }

    .minimum label { color: green; }
    .default label { color:blue; }

{% endblock %}

{% block scripts %}
   <script type="text/javascript">

    var canvases = {};
    var exportSize = 10;
    var pixelsInInch = 7;
    var baseline = exportSize * pixelsInInch;

    function onAdjustAll(type) {
		var $ctrl = $("input[name=" + type + "spacing]");
		var value = parseFloat($ctrl.val());

		$("div." + type + " input").val(0);

		{% for letter in shapes %}
			{% if letter.path|length > 0 %}
				onAdjust({{ letter.id }}, type, value)
			{% endif %}
		{% endfor %}		
    }

	function onAdjust(shapeId, type, amount) {
		var $ctrl = $("input[name=" + type + "spacing" + shapeId + "]");
		var value = parseFloat($ctrl.val()) + amount;

		value = Math.round(value*100)/100;

		$ctrl.val(value);

		var canvas = canvases["shape" + shapeId];
		var path = type == "minimum" ? canvas.path3 : canvas.path2;

		var x = canvas.path1.getWidth();// + letterDepth;

		x = x + (value * pixelsInInch);

		path.setLeft(x);
		
		if (x == 0)
			path.setStroke("black");
		else
			path.setStroke("red");

		canvas.renderAll();

		return false;
	}

    $(function() {

	{% if shapeId >0 and not shape is empty %}

		{% for letter in shapes %}

			{% if letter.path|length > 0 %}
				var element = document.getElementById("shape{{ letter.id }}");
			    var canvas = new fabric.StaticCanvas(element, { backgroundColor: 'rgb(200,200,200)' });
			    
			    canvas.setWidth(300);
			    canvas.setHeight(150);
			    //canvas.setBackgroundColor('rgba(255, 73, 64, 0.6)');
			    //canvas.selection = false;

			    var line = new fabric.Line([0, baseline, canvas.getWidth(), baseline], {
							      fill: 'red',
							      stroke: 'red',
							      strokeWidth: 2
							    });

			    canvas.add(line);

		      	var path1 = new fabric.Path("{{ shape.path }}");
		      	var path2 = new fabric.Path("{{ letter.path }}");
		      	var path3 = new fabric.Path("{{ letter.path }}");

		      	canvas.add(path1);
		      	canvas.add(path2);
		      	canvas.add(path3);

			    path1.setScaleX( ( (pixelsInInch * exportSize) * {{ shape.widthRatio }} ) / path1.getWidth())
			    path1.setScaleY( ( (pixelsInInch * exportSize) * {{ shape.heightRatio }} ) / path1.getHeight())

			    path2.setScaleX( ( (pixelsInInch * exportSize) * {{ letter.width_ratio }} ) / path2.getWidth())
			    path2.setScaleY( ( (pixelsInInch * exportSize) * {{ letter.height_ratio }} ) / path2.getHeight())

			    path3.setScaleX( ( (pixelsInInch * exportSize) * {{ letter.width_ratio }} ) / path3.getWidth())
			    path3.setScaleY( ( (pixelsInInch * exportSize) * {{ letter.height_ratio }} ) / path3.getHeight())

			    //path1.setCoords();

		      	path1.setLeft(0)
		      	path2.setLeft(path1.getWidth());
		      	path3.setLeft(path1.getWidth());

		      	path2.setFill("blue");
		      	path3.setFill("green");

		      	path3.setOpacity(.7);

		      	//console.log("{{ shape.code }}", path1.getLeft(), path1.getTop(), path1.getWidth());
		      	//console.log("{{ letter.code }}", path2.getLeft(), path2.getTop(), path2.getWidth());

		      	path1.setTop(baseline - ({{ shape.topOffset }} * (exportSize * pixelsInInch)));
		      	path2.setTop(baseline - ({{ letter.top_offset }} * (exportSize * pixelsInInch)));
		      	path3.setTop(baseline - ({{ letter.top_offset }} * (exportSize * pixelsInInch)));

		      	canvas.path1 = path1;
		      	canvas.path2 = path2;
		      	canvas.path3 = path3;

		      	canvas.renderAll();

			    canvases["shape{{ letter.id }}"] = canvas;

				{% if kerning['_'~letter.code] is defined %}
					console.log("initial adjustment {{ letter.code }}")
					onAdjust({{ letter.id }}, 'default', {{ kerning['_'~letter.code].default }});
					onAdjust({{ letter.id }}, 'minimum', {{ kerning['_'~letter.code].minimum }});
				{% endif %}

		      	//drawRect({{ shape.id }});
		    {% endif %}

		{% endfor %}

    {% endif %}

    });

    </script>
{% endblock %}  

{% block title %}
	Shape Kerning
{% endblock %}

{% block content %}
    
	<div class="row">
		<div class="col-sm-8">
			<form method="post" action="{{ path('kerningUpdate') }}?fontId={{ fontId }}&shapeId={{ shapeId }}">

				<div>

					<label>Font:</label>

					<select name="font_id" onchange="window.location='?fontId=' + $(this).val();">
						<option value="0"></option>
					{% for font in fonts %}
						{% if font.id > 0 %}
						<option value="{{ font.id }}" {% if font.id == fontId  %} selected="selected" {% endif %}>
							{{ font.name }} ({{ font.id }})
						</option>
						{% endif %}
					{% endfor %}
					</select>

				</div>
				
				<br>

				{% if not shapes is empty %}
					<div>
						<label>Letter:</label>

						<select name="letter" onchange="window.location='?fontId={{ fontId }}&shapeId=' + $(this).val();">
							<option value="></option>

							{% for letter in shapes %}
								{% if letter.path|length > 0 %}
									<option value="{{ letter.id }}" {% if letter.id == shapeId  %} selected="selected" {% endif %}>
										{{ letter.code }}

										{% if letter.kerning and letter.kerning|length > 0 %}
											*
										{% endif %}

									</option>
								{% endif %}
							{% endfor %}

						</select>
					</div>
				{% endif %}

				<br>


			</form>
		</div>

		<div class="col-sm-4">

			<div class="well">
				<form method="post" action="{{ path('kerningImport') }}" enctype="multipart/form-data">
				<div>

					<label>Font:</label>

					<select name="font_id" onchange="window.location='?fontId=' + $(this).val();">
						<option value="0"></option>
					{% for font in fonts %}
						{% if font.id > 0 %}
						<option value="{{ font.id }}" {% if font.id == fontId  %} selected="selected" {% endif %}>
							{{ font.name }} ({{ font.id }})
						</option>
						{% endif %}
					{% endfor %}
					</select>

				</div>
				
				<br>

				<div>
					<label>Font Kerning XML File (TTX):</label>

					<input type="file" name="ttx" />
				</div>

				<br>

				<div>
					<input type="submit" class="btn btn-primary" value="Import Font Kerning Data" />
				</div>

				</form>
			</div>

			<div class="well">
				<div class="default">
					<label>Default Kerning:</label>
					<input type="number" name="defaultspacing" value="0" size="5" />	
					<button onclick="onAdjustAll('default')" class="btn btn-primary">
						Adjust All
					</button>					
				</div>			
				<br>
				<div class="minimum">
					<label>Minimum Kerning:</label>
					<input type="number" name="minimumspacing" value="0" size="5" />						
					<button onclick="onAdjustAll('minimum')" class="btn btn-success">
						Adjust All
					</button>					
				</div>				
			</div>
		</div>
	</div>

{% endblock %}

*/