import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faSave } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import UserSelect from "../components/fields/user-select"
import DateField from "../components/fields/field-date"
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"

function CallDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(props.call);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    console.log("setting call data", props.call)
    if (props.call) {
      setFormData(props.call);
    }
  }, [props.call]);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("call/save", formData, 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the call.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    console.log("handleFormChange", event.target.name, event.target.value)
    setFormData(produce(formData, draft => {
      draft[event.target.name] = event.target.value;
    }));
  }
  function onUserChange(user) {
      //console.log("user selected", user)
      setFormData(produce(formData, draft => {
        draft["user_id"] = user.id;
      }));
  }

  const { call } = props;

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPhone} />
            &nbsp;
            Schedule Call
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>

            <Form.Group>
              <Form.Label>User</Form.Label>
              <UserSelect name="user" required default={{id: call.user_id, name: call.name }} onChange={onUserChange} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Reason For Call</Form.Label>
              <FormControl as="input" name="reason" required value={formData.reason} onChange={(e) => handleFormChange(e)}/>
            </Form.Group>

            <DateField name="scheduled_date" label="Call Date" required value={formData.scheduled_date} onChange={(e) => handleFormChange(e)} />

            <Form.Group>
              <Form.Label>Notes</Form.Label>
              <FormControl as="textarea" name="notes" value={formData.notes} rows={10} onChange={(e) => handleFormChange(e)}/>
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Call" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default CallDrawer;