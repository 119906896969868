import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faUsers, faClipboardCheck, faListAlt, faPhone, faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

function TaskIcon({task}) {

    return (
        <>
            <FontAwesomeIcon icon={
                    (task && (task.icon || task.type))=="meeting" ? faUsers : 
                    (task && (task.icon || task.type))=="event" ? faCalendarDay : 
                    (task && (task.icon || task.type))=="list" ? faListAlt : 
                    (task && (task.icon || task.type))=="call" ? faPhone : 
                    (task && (task.icon || task.type))=="followupemail" ? faEnvelope : 
                    (task && (task.icon || task.type))=="followupcall" ? faPhoneAlt : 
                    faClipboardCheck
                } 
                style={{
                    color: task && task.status=="Complete" ? "green" : task && task.late_ind==1 ? "red": "#333"}
                } 
                fixedWidth 
            />
        </>
    );
}

export default TaskIcon;