import React, { useState, useEffect } from "react";
import styled from "styled-components"

import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup'

import Loading from "../components/loading";
import SignSelect from "../components/fields/sign-select";
import ProductSelect from "../components/fields/product-select";
import CouponSelect from "../components/fields/coupon-select";
import Button from "../components/buttons/button";

import settings from '../settings'
import { postExternalData } from "../common/services/server"

const ModalBody = styled(Modal.Body)`
    min-height: 400px;

    .input-group {
        margin-bottom: 5px;
    }
    .form-group {
        flex: 1;
    }
`

function EmailSectionSelectDialog(props) {
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState("")

    function onSection(placeholder, data) {
        setContent("");
        setLoading(true);

        postExternalData(settings.SITE_API_URL + "emails/placeholder", {body:placeholder, ...data},
            function(result) {
                setContent(result.content);
            },
            function(error) {
                alert("Error processing content.")
            },
            function() {
                setLoading(false);
            }
        );
    }

    function onInsert() {
        props.onInsert(content);
    }

    return (
        <Modal show={true} size="lg" enforceFocus={false} onHide={props.onHide} className="dialog">
          <Modal.Header closeButton>
            <Modal.Title>Insert Content</Modal.Title>
          </Modal.Header>
          <ModalBody>
                {props.type == "sign" && 
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Sign</InputGroup.Text>
                        </InputGroup.Prepend>
                        <SignSelect
                            name="sign_id"
                            onChange={(sign) => onSection("[sign_box]", {sign_id: sign.id})}
                        />
                    </InputGroup>
                }
                {props.type == "product" &&
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Product</InputGroup.Text>
                        </InputGroup.Prepend>
                        <ProductSelect
                            name="product_id"
                            hasUrl
                            onChange={(product) => onSection("[product_box]", {product_id: product.id})}
                        />
                    </InputGroup>
                }
                {props.type == "coupon" && 
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Coupon</InputGroup.Text>
                        </InputGroup.Prepend>
                        <CouponSelect
                            name="coupon_id"
                            hasUrl
                            onChange={(coupon) => onSection("[coupon_box]", {coupon_id: coupon.id})}
                        />
                    </InputGroup>
                }

                {loading &&
                    <Loading />
                }
                {content != "" && 
                    <div dangerouslySetInnerHTML={{__html: content}} />
                }
          </ModalBody>
          <Modal.Footer>
            <Button variant="primary" disabled={!content || content.length == 0} onClick={onInsert}>
                Insert
            </Button>

            <Button variant="outline-secondary" onClick={props.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }

  export default EmailSectionSelectDialog;