import React from "react";
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from "clsx";

export default function DrawerTemplate({
    title,
    icon,
    
    drawerClassName,

    header,
    footer,
    buttons,

    onHide,
    children
  }) {
  
  return (
      <Modal 
        show={true} 
        onHide={onHide} 
        size="lg" 
        scrollable 
        backdropClassName="drawer" 
        dialogClassName="drawer"
        onClick={(e) => {
          // prevent click through (overlay/etc)
          e.stopPropagation();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="flex items-center gap-2">
            {icon && <FontAwesomeIcon icon={icon} />}
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className={clsx("grid gap-2", drawerClassName)}>
                {header}
                {children}
                {footer}
            </div>
        </Modal.Body>
        {buttons && 
          <Modal.Footer>
            {buttons}
          </Modal.Footer>        
        }
      </Modal>
    )
}
