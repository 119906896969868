import React from "react";
import styled from 'styled-components'
import { isMobile } from "react-device-detect"
import FormControl from 'react-bootstrap/FormControl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import Button from './buttons/button';

const Bar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 15px;
    gap: 10px;

    > div {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    select {
        width: 100px;
    }

    button span {        
        display: ${isMobile ? "none":"inline-block"};
        margin: 0px 5px;
    }
`

function Paginator(props) {
    return (
        <Bar className="paginator">
            <Button variant={props.page <= 1?"light":"primary"} size="sm" onClick={() => props.onPage(parseInt(props.page)-1)} disabled={props.loading || props.page <= 1}>
                <FontAwesomeIcon icon={faChevronLeft} /> 
                <span>Previous</span>
            </Button>
            { props.loading ?
                <div>Loading...</div>
                :
                <div>
                    <span>{ props.total } {props.item}{ props.total == 1 ? "":"s"} Found</span>
                    {props.total > 0 && 
                        <span>, {isMobile?"":"Showing"} {props.start} to {props.end}</span>
                    }

                    {props.perPage && 
                        <FormControl 
                            as="select" 
                            name="perpage"
                            value={props.perPage} 
                            onChange={(e) => props.onPerPage(e.target.value)}
                        >
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={500}>500</option>
                                <option value={1000}>1000</option>
                                <option value={-1}>All</option>
                        </FormControl>
                    }
                </div>
            }
            <Button variant={props.page >= props.pages ? "light":"primary"} size="sm" onClick={() => props.onPage(parseInt(props.page)+1)} disabled={props.loading || props.page >= props.pages}>
                <span>Next</span>
                <FontAwesomeIcon icon={faChevronRight} /> 
            </Button>
        </Bar>
    );
}

export default Paginator