import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash, faPlus, faListAlt, faUserSecret, faSpinner, faUserLock } from "@fortawesome/free-solid-svg-icons";
import { useParams, useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect"
import queryString from 'query-string'

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    DateCell, 
    ButtonCell,
    LinkCell,
    EmailCell, 
    TextCell,
    SortHeaderCell,
    FilterHeaderCell,
    SortTypes,
    EditActionsCell 
} from "../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Content from "../components/content"
import PageHeader from "../components/page-header";
import Loading from "../components/loading"
import Paginator from "../components/paginator"
import Map from "../components/map"
import Button from "../components/buttons/button";

import UserDrawer from "../drawers/drawer-user"
import RoleDrawer from "../drawers/drawer-role"
import RolesPanel from "./panels/panel-roles"

import { postData } from "../common/services/server"
import { copyTextToClipboard, formatPhoneNumber, removeNulls } from "../helpers/utility"
import { useBodyClass } from "../hooks/useBodyClass"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { dimensions } from "../settings/settings"
import { google } from "../settings/settings"
import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import { ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import PageLayout from "../components/page-layout";

const CategoriesWidth = 200;
const Padding = 10;

const Interface = styled.div`
    display: flex;
`
const Categories = styled.div`
    width: ${CategoriesWidth}px;
    margin-right: ${Padding}px;
    border: 1px solid #ccc;
    padding: 0px;
    overflow: auto;

    div {
        padding: 5px;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
        font-size: 12px;
        display: flex;
        justify-items: center;
        justify-content: space-between;

        &:hover {
            background-color: #eee;
        }

        &.selected {
            background-color: #ccc;
        }

        .badge {
            flex: 0;
        }
    }
`

function UsersPage(props) {
    const history = useHistory();
    const params = queryString.parse(props.location ? props.location.search : "");

    let { tab } = useParams();

    if (!tab) tab = "customers";

    const windowDimensions = useWindowDimensions();

    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({page:1,pages:1,total:0});
    const [sort, setSort] = useState({column: "added_date", dir: SortTypes.DESC});
    const [isLoading, setIsLoading] = useState(false);
    const [busy, setBusy] = useState("");
    const [busyRow, setBusyRow] = useState(null);
    const [filters, setFilters] = useState({lastName: "", email: "", phoneno: "", added_date: params.date});
    const [selected, setSelected] = useState(null);
    const [category, setCategory] = useState("ordered");
    const [password, setPassword] = useState("");

    useBodyClass(`noscroll`);

    function loadUsers(options={}) {
        setIsLoading(true);

        postData("users/data", removeNulls({ 
            ...filters,
            type: tab,
            category,
            page: page,
            perPage: 100,
            sort: sort.column,
            sortdir: sort.dir,
            ...options
        }),
            function(result) {
                setUsers(result.users);
                setPagination(result.pagination)
            },
            function(error) {
                alert("Error loading users")
            },
            function() {
                setIsLoading(false);
            }
        );
    }

    useEffect(
        () => {
            loadUsers();
        }, 
        [sort, tab, page, category]
    );

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }

    function onUser(user) {
        if (user.id)
            history.push("/users/user/" + user.id);
    }

    function onAction(action, user) {
        console.log("action", action, user)
    
        if (action == "delete") {
            setBusyRow(user);

            postData("users/delete", { 
                user_id: user.id
            },
                function(result) {
                    setUsers(produce(draft => {
                        draft.splice(draft.findIndex(u => u.id == user.id), 1);
                    }));
                },
                function(error) {
                    alert("Error deleting user")
                },
                function() {
                    setBusyRow(null);
                }
            );
        }
        else if (action == "edit") {
            history.push("/users/user/" + user.id);
        }
    }

    function onUserSigns(user) {
        history.push("/users/user/signs/" + user.id);
    }

    function onFilterChange(filter, value) {
        setFilters(produce(draft => {
            draft[filter] = value
        })); 
    }

    function onFilter() {
        loadUsers({page:1})
    }

    function onCreate() {
        setSelected("create")
    }

    function onNewPassword() {
        setBusy("password")
        setPassword("");

        postData("users/impersonateemployee", {}, 
            function(response) {
                setPassword(""+response);
                copyTextToClipboard(response);
            },
            function(error) {
                alert("An error occured generating the password.");
            },
            function() {
                setBusy("");
            }
        );
    }
    function onCopyPassword() {
        copyTextToClipboard(password);
    }

    return (
        <PageLayout>
            <PageHeader 
                title="User Manager"
                help="users"
                tab={tab}
                tabUrl={"/users/{tab}"}
                tabs={[
                    {name:"customers", title:"Customers"},
                    {name:"employees", title:"Employees", hidden: !userHasPermission("Employees", "View")},
                    {name:"installers", title:"Sign Installers"}
                ]}
                onTab={(tab) => {
                    history.push("/users/" + tab);
                }}
            >
                {(tab == "employees" && userHasPermission(Permissions.Employees, Actions.Create)) ?
                    <Button onClick={onCreate} variant="outline-success" size="sm">
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        Create Employee
                    </Button>
                : (tab == "roles" && userHasPermission(Permissions.Roles, Actions.Create)) ?
                   <Button onClick={onCreate} variant="outline-success" size="sm">
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        Create Role
                    </Button>
                : userHasPermission(Permissions.Customers, Actions.Create) ?
                    <Button onClick={onCreate} variant="outline-success" size="sm">
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        Create Customer
                    </Button>
                :
                    null
                }           

                {(tab == "employees" && userHasPermission(Permissions.Employees, Actions.Impersonate)) &&
                    <ButtonGroup className="mr-2">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip>Generate Impersonate Employee Password</Tooltip>
                            }
                        >
                            <Button onClick={onNewPassword} variant="outline-secondary" size="sm">
                                <FontAwesomeIcon icon={busy=="password" ? faSpinner:faUserLock} spin={busy=="password"} />
                            </Button>
                        </OverlayTrigger>  
                        {(password && password.length > 0) && 
                            <Button onClick={onCopyPassword} variant="outline-secondary" size="sm">
                                {password}
                            </Button>
                        }
                    </ButtonGroup>                   
                }                   
            </PageHeader>
            <Content permission={Permissions.Customers}>
                <Interface>
                {tab == "installers" && 
                    <Categories>
                        <div onClick={() => setCategory("all")} className={category == "all" ? "selected":""}>
                            All
                        </div>
                        <div onClick={() => setCategory("address")} className={category == "address" ? "selected":""}>
                            Have Address
                        </div>
                        <div onClick={() => setCategory("noaddress")} className={category == "noaddress" ? "selected":""}>
                            Missing Address
                        </div>                                                                
                        <div onClick={() => setCategory("ordered")} className={category == "ordered" ? "selected":""}>
                            Placed Order
                        </div>
                        <div onClick={() => {setIsLoading(true); setCategory("map");}} className={category == "map" ? "selected":""}>
                            Map
                        </div>
                        <div onClick={() => setCategory("nomap")} className={category == "nomap" ? "selected":""}>
                            Not On Map
                        </div>                                
                    </Categories>
                }                    
                {
                    isLoading 
                ?
                    <Loading />
                : 
                    (tab == "installers" && category == "map") ? 
                        <Map markers={[]} />                    
                    : tab == "roles" ?
                        <RolesPanel
                            haveTabs={true}
                        />
                    :
                        <div>
                            <Table
                                rowHeight={50}
                                rowsCount={users.length}
                                width={windowDimensions.width - dimensions.dataTableWidthOffset}
                                height={windowDimensions.height - dimensions.dataTableHeightOffset}
                                headerHeight={70}
                                touchScrollEnabled={true}
                                rowClassNameGetter={(rowIndex) => {
                                    //console.log(rowIndex, users[rowIndex].status)
                                    if (users[rowIndex].status == "Inactive") {
                                        return "Inactive"
                                    }
                                }}
                                createdRow={(row, data, index) => {
                                    console.log(index)
                                    console.log(row)
                                    console.log(data)
                                }}
                            >
                                <Column
                                    header={
                                        <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                            ID
                                        </SortHeaderCell>
                                    }
                                    columnKey="id"
                                    fixed={!isMobile}
                                    cell={<LinkCell data={users} onClick={(user) => onUser(user)} />}
                                    width={80}
                                />
                                <Column
                                    header={
                                        <FilterHeaderCell filter={filters.lastName} onFilterChange={(value) => onFilterChange("lastName", value)} onFilter={onFilter}>
                                            Last Name
                                       </FilterHeaderCell>                                        

                                    }
                                    columnKey="last_name"
                                    fixed={!isMobile}
                                    cell={<TextCell data={users} />}
                                    width={200}
                                />
                                <Column
                                    header={
                                        <FilterHeaderCell filter={filters.firstName} onFilterChange={(value) => onFilterChange("firstName", value)} onFilter={onFilter}>
                                             First Name
                                        </FilterHeaderCell>                                        
                                    }
                                    columnKey="first_name"
                                    cell={<TextCell data={users} />}
                                    width={125}
                                />
                                <Column
                                    header={
                                        <FilterHeaderCell filter={filters.email} onFilterChange={(value) => onFilterChange("email", value)} onFilter={onFilter}>
                                            Email
                                        </FilterHeaderCell>
                                    }
                                    columnKey="email"
                                    cell={<EmailCell data={users} />}
                                    width={300}
                                />
                                <Column
                                    header={
                                        <FilterHeaderCell filter={filters.phoneno} onFilterChange={(value) => onFilterChange("phoneno", value)} onFilter={onFilter}>
                                            Phone
                                        </FilterHeaderCell>
                                    }
                                    columnKey="phoneno"
                                    cell={({rowIndex, ...props}) => {
                                        const user = users[rowIndex];

                                        return (
                                            <Cell {...props}>
                                                {formatPhoneNumber(user.phoneno)}
                                            </Cell>                                
                                        )
                                    }}                                    
                                    width={140}
                                />
                                { tab != "employees" &&                          
                                    <Column
                                        header={
                                            <FilterHeaderCell filter={filters.ship_city} onFilterChange={(value) => onFilterChange("ship_city", value)} onFilter={onFilter}>
                                                City
                                            </FilterHeaderCell>
                                        }
                                        columnKey="ship_city"
                                        cell={<TextCell data={users} />}
                                        width={130}
                                    />         
                                }
                                { tab != "employees" &&                          
                                    <Column
                                        header={
                                            <FilterHeaderCell filter={filters.ship_state} onFilterChange={(value) => onFilterChange("ship_state", value)} onFilter={onFilter}>
                                                State
                                            </FilterHeaderCell>
                                        }
                                        columnKey="ship_state"
                                        cell={<TextCell data={users} />}
                                        width={90}
                                    />
                                }
                                { tab == "employees" && 
                                    <Column
                                        header={
                                            <Cell>
                                                Roles
                                            </Cell>
                                        }
                                        columnKey="roles"
                                        cell={<TextCell data={users} small />}
                                        width={400}
                                    />
                                }         
                               { tab == "employees" &&                          
                                    <Column
                                        header={
                                            <FilterHeaderCell filter={filters.status} onFilterChange={(value) => onFilterChange("status", value)} onFilter={onFilter}>
                                                Status
                                            </FilterHeaderCell>
                                        }
                                        columnKey="status"
                                        cell={<TextCell data={users} />}
                                        width={100}
                                    />         
                                }                                
                                { tab == "employees" && 
                                    <Column
                                        header={
                                            <Cell>
                                                Access IP Restrictions
                                            </Cell>
                                        }
                                        columnKey="access_ips"
                                        cell={<TextCell data={users} small />}
                                        width={200}
                                    />
                                }                                                             
                                { tab == "customers" && 
                                    <Column
                                        header={
                                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                                Signs
                                            </SortHeaderCell>
                                        }
                                        columnKey="sign_count"
                                        cell={<ButtonCell data={users} onClick={(user) => onUserSigns(user)} />}
                                        align="center"
                                        width={80}
                                    />
                                }
                                { tab == "customers" && 
                                    <Column
                                        header={
                                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                                Type
                                            </SortHeaderCell>
                                        }
                                        columnKey="type"
                                        cell={<TextCell data={users} />}
                                        width={100}
                                    />
                                }
                                { tab == "customers" && 
                                    <Column
                                        header={
                                            <FilterHeaderCell filter={filters.added_date} onFilterChange={(value) => onFilterChange("added_date", value)} onFilter={onFilter}>
                                                Registered
                                            </FilterHeaderCell>                                            
                                        }
                                        columnKey="added_date"
                                        cell={<DateCell data={users} />}
                                        width={135}
                                    />
                                }
                                <Column
                                    header={<Cell>Actions</Cell>}
                                    cell={
                                        <EditActionsCell 
                                            data={users} 
                                            actions={[
                                                {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, isVisible: () => userHasPermission(tab == "customers" ? Permissions.Customers:Permissions.Employees, Actions.Edit)},
                                                {action:"delete", title:"Delete", variant:"danger", icon:faTrash, isVisible: () => userHasPermission(tab == "customers" ? Permissions.Customers:Permissions.Employees, Actions.Delete)},
                                            ]}
                                            busy={busyRow}
                                            onClick={onAction} 
                                        />
                                    }
                                    align="center"
                                    width={110}
                                />
                            </Table>
                            <Paginator
                                {...pagination}
                                item="User"
                                items="Users"
                                onPage={(page) => setPage(page)}
                            />
                        </div>
                }
                </Interface>
            </Content>
            {(selected && selected == "create" && tab != "roles") && 
                <UserDrawer
                    show={true}
                    user={{
                        role: tab == "employees" ? "admin":"user",                        
                        added_location:"Admin"
                    }}
                    onSaved={(user) => {
                        history.push("/users/user/" + user.id);
                    }}
                    onHide={() => setSelected(null)}
                />
            }
            {(selected && selected == "create" && tab == "roles") && 
                <RoleDrawer
                    show={true}
                    onSaved={() => {
                        window.location.reload();
                    }}
                    onHide={() => setSelected(null)}
                />
            }            
        </PageLayout>
    );
}

export default UsersPage;