import React, { useState } from "react";

import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faShapes, faPlus } from '@fortawesome/free-solid-svg-icons'
import TextAreaField from "../components/fields/field-textarea"
import Button from "../components/buttons/button";

import ShapePreview from "../components/shape-preview"
import { postData } from "../common/services/server"

function JobAddShapeDrawer(props) {
  const [selected, setSelected] = useState(null)
  const [processing, setProcessing] = useState(null);
  const [notes, setNotes] = useState("");

  function onAdd() {
    setProcessing(selected.id);

    postData("jobs/cloneshape?job_id=" + props.job.id, { 
        jobshape_id: selected.id,
        notes
      }, 
      function(response) {
        props.onShapesChanged(response);
      },
      function(error) {
        alert("An error occured updating the job.");
      },
      function() {
        setProcessing(null);
      }
    );
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faShapes} />
            &nbsp;
            Add Job Shape
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <>
                {props.shapes.map(shape => (
                    <div 
                        key={shape.id} 
                        style={{
                            display: "flex", 
                            justifyContent: "space-between",
                            border: "1px solid #ccc", 
                            padding: "5px 10px", 
                            borderRadius:"4px", 
                            marginBottom: "5px",
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            if (shape != selected)
                                setSelected(shape);
                        }}
                    >
                        <ShapePreview path={shape.path} solid={true} width={50} height={35} align="left" />

                        {shape == selected && 
                            <div>
                                <TextAreaField 
                                    name="notes" 
                                    label="Notes" 
                                    row={3}
                                    value={notes} 
                                    onChange={(e) => {
                                        setNotes(e.target.value);
                                    }} 
                                /> 

                                <Button variant="outline-primary" onClick={onAdd} style={{display:"block", width: "100%"}}>
                                    <FontAwesomeIcon icon={processing==shape.id ? faSpinner:faPlus} spin={processing==shape.id} />{' '}
                                    Clone Shape
                                </Button>
                            </div>
                        }
                    </div>
                ))}
            </>
        </Modal.Body>
      </Modal>
    )
}

export default JobAddShapeDrawer;