import React, { useState } from "react";

import GroupPreview from "../components/group-preview"
import Button from "../components/buttons/button";
import SelectField from "../components/fields/field-select";
import ColorBox from "../components/color-box";
import ColorSelectDrawer from "../drawers/drawer-colorselect";
import Panel from "./panel";
import { List, ListItem } from "../components/list"

import { getDecorationTypes } from "../helpers/data";
import { useGetJobColors } from "../api/queries/workflowjobs";
import { useWorkflowJobUpdatePartProp } from "../api/mutations/workflowjobs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function JobWorkflowColorsPanel({jobId}) {
    const groupsQuery = useGetJobColors(jobId);
    const groups = groupsQuery.data;

    const [part, setPart] = useState(null);

    const updatePartProp = useWorkflowJobUpdatePartProp(jobId, {
        onError: () => window.alert("Error updating shape")
    });    

    return (
      <>
        <Panel query={groupsQuery}>
            {groups && 
                <div className="flex gap-2">
                <List>
                    {groups.map(group => (
                        <ListItem key={group.id} className="grid grid-cols-[125px,1fr] items-center">
                            <div className="text-xs">
                                <GroupPreview group={group} solid={true} width={100} height={50} />
                                <div>
                                    {group.product}
                                </div>
                                <div>
                                    Size {group.size}
                                </div>
                                {group.style_type == "letters" && 
                                    <div>
                                        {group.style}
                                    </div>
                                }
                            </div>
                            <div className="flex items-stretch gap-1">
                            {group.parts.map(part => (
                                <div key={part.id} className="border border-gray-300 flex flex-col gap-1 justify-between rounded p-1">
                                    <div className="flex-1 flex items-center gap-1 text-sm">
                                        {part.title}

                                        {(updatePartProp.isLoading && updatePartProp.variables?.jobGroupId == group.id && updatePartProp.variables?.partId == part.id) && 
                                            <FontAwesomeIcon icon={faSpinner} spin />
                                        }
                                    </div>
                                    <div>
                                        <Button 
                                            size="tiny"
                                            variant="outline-secondary"
                                            className="flex items-center gap-1 !py-1 w-full"
                                            onClick={() => setPart(part)}
                                        >
                                            <ColorBox color={part.color} />
                                            {part.color.name}
                                        </Button>
                                        <SelectField
                                            name="decoration_type"
                                            value={part.decoration_type}
                                            items={getDecorationTypes()}
                                            invalid={!part.decoration_type || part.decoration_type.length == 0}
                                            disabled={updatePartProp.isLoading && updatePartProp.variables?.jobGroupId == group.id && updatePartProp.variables?.partId == part.id}
                                            idField="value"
                                            size="xs"
                                            onChange={(e,value) => {
                                                updatePartProp.mutate({
                                                    jobGroupId: group.id, 
                                                    partId: part.id, 
                                                    prop: "decoration_type", 
                                                    value
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                            </div>
                        </ListItem>
                    ))}
                </List>
                </div>
            }
        </Panel>

        {part && 
            <ColorSelectDrawer
                part={part}
                color={part.color}
                onColor={(color) => {
                    updatePartProp.mutate({
                        jobGroupId: part.jobgroup_id, 
                        partId: part.id, 
                        prop: "color_id", 
                        value: color.id
                    });

                    setPart(null);
                }}
                onHide={() => setPart(null)}
            />
        }
      </>
    )
}
