import React, { useState, useEffect } from "react";
import produce from "immer"
import { faPencilAlt, faTrash, faDesktop, faEye } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    EditActionsCell,
    ButtonCell
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Loading from "../../components/loading"
import StationDrawer from "../../drawers/drawer-station"

import { postData } from "../../common/services/server"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

function StationsPanel(props) {
    const history = useHistory();
    const windowDimensions = useWindowDimensions();

    const [stations, setStations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(null);

    const [busyRow, setBusyRow] = useState(null);

    useBodyClass(`noscroll`);

    useEffect(
        () => {
            setIsLoading(true);

            postData("stations/data", {},
                function(result) {
                    setStations(result);
                },
                function(error) {
                    alert("Error loading station data")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        []
    );

    function onAction(action, station) {
        console.log("action", action, action)

        if (action == "delete") {
            if (window.confirm("Delete this station?")) {
                setBusyRow(station);

                postData("stations/delete", {id: station.id},
                    function() {
                        setStations(produce(draft => {
                            draft.splice(draft.findIndex(t => t.id == station.id), 1);
                        }));
                    },
                    function(error) {
                        alert("Error deleting station.")
                    },
                    function() {
                        setBusyRow(null);
                    }
                ); 
            }
        }
        else if (action == "view") {
            history.push("/station/" + station.id);
        }
        else
            setSelected({mode:action, station});
    }
    
    function onStationChanged(station) {
        setStations(produce(draft => {
            const index = draft.findIndex(t => t.id == station.id);

            draft[index] = station;
        }));

        setSelected(null);
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
                <Table
                    rowHeight={75}
                    rowsCount={stations.length}
                    width={windowDimensions.width - dimensions.dataTableWidthOffset}
                    height={windowDimensions.height - dimensions.headerHeight - (props.haveTabs ? dimensions.pageWithTabsPadding : dimensions.pagePadding)*2}
                    headerHeight={50}
                    touchScrollEnabled={true}
                >
                    <Column
                        header={<Cell>Name</Cell>}
                        columnKey="name"
                        fixed={!isMobile}
                        cell={({rowIndex, ...props}) => (
                            <Cell {...props}>
                                <FontAwesomeIcon icon={faDesktop} />{' '}
                                {stations[rowIndex].name}
                            </Cell> 
                        )}
                        width={200}
                    />
                   <Column
                        header={<Cell>Workflow Tasks</Cell>}
                        columnKey="workflowitems"
                        cell={<TextCell data={stations} small />}
                        width={450}
                    />
                    <Column
                        header={<Cell>Actions</Cell>}
                        cell={
                            <EditActionsCell 
                                data={stations} 
                                actions={[
                                    {action:"view", title:"View", variant:"success", icon:faEye},
                                    {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, permission:Permissions.Stations, permissionAction:Actions.Edit},
                                    {action:"delete", title:"Delete", variant:"danger", icon:faTrash, permission:Permissions.Stations, permissionAction:Actions.Delete},
                                ]}
                                busy={busyRow}
                                onClick={onAction} 
                            />
                        }
                        align="center"
                        width={125}
                    />
                </Table>
            }

            {(selected && selected.mode=="edit") && 
                <StationDrawer 
                    station={selected.station} 
                    onSaved={onStationChanged} 
                    onHide={ () => setSelected(null) } 
                />
            } 
        </>
    );
}

export default StationsPanel;