import React from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import Button from "./button";

function ButtonProcessing(props) {
    return (
        /*
        <OverlayTrigger
            placement={props.position || "left"}
            trigger="hover"
            overlay={
                <Tooltip>
                    {props.processing ? (props.action||"Processing")+"..." : props.tooltip}
                </Tooltip>
            }
        >
        */
            <Button 
                disabled={props.processing||props.disabled} 
                size={props.size} 
                onClick={props.onClick} 
                variant={props.disabled ? "outline-secondary":props.variant} 
                className={props.className}
                style={props.style}
                icon={props.processing ? faSpinner : props.icon}
                iconSpin={props.processing}
            >
                {props.caption && " " + props.caption}
            </Button>
        /*
        </OverlayTrigger>
        */
    );
}

ButtonProcessing.defaultProps = {
    variant: "outline-primary",
    processing: false
};

export default ButtonProcessing