import React, { useState, useEffect } from "react";
import styled from "styled-components"
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import produce from "immer"
import { faTrash, faSpinner, faPencilAlt, faBars } from "@fortawesome/free-solid-svg-icons";

import Loading from "../../components/loading"
import FaceArtDrawer from "../../drawers/drawer-faceart";
import GoogleImage from "../../components/google-image"
import Button from "../../components/buttons/button";

import { postData } from "../../common/services/server"
import storage from "../../settings/storage"

import { userHasPermission } from "../../common/services/auth";

const FaceArts = styled.div`
    display: flex;
    flex-flow: wrap;

    > div {
        margin: 5px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: relative;

        > div:nth-child(1) {
            min-height: 105px;
        }

        img {
            margin-bottom: 10px;
            max-height: 100px;
        }
        button {
            margin-top: 10px;
            margin-right: 3px;
        }
        small {
            word-break: break-word;
            max-width: 150px;
            display: inline-block;
        }
        .badge {
            position: absolute;
            top: -5px;
            right: -5px;
        }
    }
`
const More = styled.div`
    margin-top: 25px;
    text-align: center;

    button {
        padding: 10px 30px;
        svg {
            margin-right: 10px;
        }
    }
`

function FaceArtGridPanel(props) {
    const [categories, setCategories] = useState([]);

    const [page, setPage] = useState(1);
    const [more, setMore] = useState(true);

    const [faceArts, setFaceArts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [busy, setBusy] = useState(null);
    const [selected, setSelected] = useState(null);

    useEffect(
        () => {
            if (categories.length == 0) {
                postData("categories/data?type=faceart", { 
                        uncategorized: 0
                    },
                    function(result) {
                        setCategories(result);
                    },
                    function(error) {
                        alert("Error loading categories")
                    }
                );
            }

            setIsLoading(true);

            postData("facearts/data", { 
                    type: props.type,
                    page
                },
                function(result) {
                    if (result.length) {
                        setFaceArts(produce(draft => {
                            for (const faceart of result) {
                                draft.push(faceart);
                            }
                        }));  
                    }
                    else {
                        setMore(false);
                    }
                },
                function(error) {
                    alert("Error loading face art")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        [props.type, page]
    );

    function onMore() {
        setIsLoading(true);
        setPage(page+1);
    }

    function onEdit(faceart) {
        setSelected({mode:"faceart", faceart})
    }

    function onRemove(faceart) {
        setFaceArts(produce(draft => {
            const artIndex = draft.findIndex(f => f.id == faceart.id);

            draft[artIndex].removing = true;
        }));    

        postData("facearts/delete", {faceart_id: faceart.id},
            function(result) {
                setFaceArts(produce(draft => {
                    const artIndex = draft.findIndex(f => f.id == faceart.id);
        
                    draft.splice(artIndex, 1);
                }));    
            },
            function(error) {
                alert("Error removing face art")
            }
        );  
    }

    return (
        <>
            <FaceArts>
                {faceArts.map(faceart => (
                    <div key={faceart.id}>
                        <a href={storage.root + faceart.url} target="_blank">
                            <GoogleImage root src={faceart.url_thumb || faceart.url} style={{ width: "75px" }} />
                        </a>
                        <div>
                            <small>{faceart.name}</small>
                        </div>
                        <div align="center">
                            {userHasPermission("Face Art", "Edit") &&
                                <Button variant="outline-warning" size="sm" onClick={() => onEdit(faceart)}>
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>
                            }
                            {userHasPermission("Face Art", "Delete") &&
                                <Button variant="outline-danger" size="sm" onClick={() => onRemove(faceart)}>
                                    <FontAwesomeIcon icon={faceart.removing ? faSpinner: faTrash} spin={faceart.removing} />
                                </Button>
                            }
                        </div>
                        <Badge variant="secondary" title="Used">{faceart.used}</Badge>
                    </div>
                ))}
            </FaceArts>
            { isLoading ?
                <Loading />
            : more ?
                <More>
                    <Button variant="outline-secondary" onClick={onMore}>
                        <FontAwesomeIcon icon={faBars} />
                        Load More...
                    </Button>
                </More>
            :
                <p>No more face art found.</p>
            }

            { selected && selected.mode == "faceart" && 
                <FaceArtDrawer 
                    categories={categories}
                    faceart={selected.faceart} 
                    show={true}
                    onSaved={(faceart) => {
                        //setCounter(counter+1); // force refresh
                        setFaceArts(produce(draft => {
                            const artIndex = draft.findIndex(f => f.id == faceart.id);

                            if (artIndex == -1)
                                draft.push(faceart);
                            else 
                                draft[artIndex] = faceart;
                        }));  

                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } 
                /> 
            }
        </>
    );
}

export default FaceArtGridPanel;