import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutate } from '../api';

export const useWorkflowUpdateProp = (workflowId, prop) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (value) => {
            return mutate("workflows/workflow/updateprop", {
                workflow_id: workflowId,
                prop,
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflows", ""+workflowId]);
        },    
  })
};

export const useWorkflowUpdateItemProp = (workflowId, workflowItemId, workflowTaskId, prop) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (value) => {
            return mutate("workflows/workflowitem/updateprop", {
                workflow_id: workflowId,
                workflowitem_id: workflowItemId,
                workflowtask_id: workflowTaskId,
                prop,
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflows", ""+workflowId]);
        },    
  })
};

export const useWorkflowUpdateTaskProp = (workflowId, workflowTaskId, prop) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (value) => {
            return mutate("workflows/workflowtask/updateprop", {
                workflow_id: workflowId,
                workflowtask_id: workflowTaskId,
                prop,
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflows", ""+workflowId]);
        },    
  })
};

export const useWorkflowUpdateTaskDependentValue = (workflowId, workflowTaskId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({dependentWorkflowTaskId, value}) => {
            return mutate("workflows/workflowtask/updateprop", {
                workflow_id: workflowId,
                workflowtask_id: workflowTaskId,
                dependent_workflowtask_id: dependentWorkflowTaskId,
                prop: "dependent_value",
                value
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflows", ""+workflowId]);
        },    
  })
};

export const useWorkflowAddTaskPart = (workflowId, workflowTaskId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (partId) => {
            return mutate("workflows/workflowtask/addpart", {
                workflow_id: workflowId,
                workflowtask_id: workflowTaskId,
                part_id: partId,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflows", ""+workflowId]);
        },    
  })
};
export const useWorkflowDeleteTaskPart = (workflowId, workflowTaskId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (partId) => {
            return mutate("workflows/workflowtask/deletepart", {
                workflow_id: workflowId,
                workflowtask_id: workflowTaskId,
                part_id: partId,
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflows", ""+workflowId]);
        },    
  })
};
export const useWorkflowUpdatePartProp = (workflowId, workflowItemId, partId, prop, options={}) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (value) => {
            return mutate("workflows/workflowtask/updatepartprop", {
                workflow_id: workflowId,
                workflowitem_id: workflowItemId,
                part_id: partId,
                prop,
                value
            })
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(["workflows", ""+workflowId]);

            if (options.onSuccess)
                options.onSuccess(result)
        },    
  })
};

export const useWorkflowDeleteTask = (workflowId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (workflowTaskId) => {
            return mutate("workflows/workflowtask/delete", {
                workflow_id: workflowId,
                workflowtask_id: workflowTaskId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflows", ""+workflowId]);
        },    
  })
};

export const useWorkflowAddItem = (workflowId, options={}) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (workflowItemId) => {
            return mutate("workflows/workflow/additem", {
                workflow_id: workflowId,
                workflowitem_id: workflowItemId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflows", ""+workflowId]);

            if (options.onSuccess)
                options.onSuccess();
        },    
  })
};

export const useWorkflowCreateTask = (workflowId) => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (name) => {
            return mutate("workflows/workflow/createtask", {
                workflow_id: workflowId,
                name
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflows", ""+workflowId]);
        },    
  })
};

export const useWorkflowClone = (workflowId, options={}) => {  
    const queryClient = useQueryClient()

    
    return useMutation({
        mutationFn: () => {
            return mutate("workflows/workflow/clone", {
                workflow_id: workflowId
            })
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(["workflows"]);

            if (options && options.onSuccess)
                options.onSuccess(result);
        },    
  })
};

export const useWorkflowCreate = () => {  
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (name) => {
            return mutate("workflows/workflow/create", {
                name
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["workflows", "list"]);
        },    
  })
};
