import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetRecentSignLogos = (options) => {  
  return useQuery({ 
    queryKey: ["signlogos", "list", "recent"], 
    queryFn: ({signal}) => query("signlogos/recent", {}, signal),
    ...options
  });
};

export const useGetSignLogo = (logoId, options) => {  
    return useQuery({ 
      queryKey: ["signlogos", logoId], 
      queryFn: ({signal}) => query("signlogos/logo/data", {id: logoId}, signal),
      ...options
    });
  };
