import React from "react";
import styled from 'styled-components'
import { isMobile } from "react-device-detect"

import Error from "./error";
import Loading from "./loading";
import { userHasPermission } from "../common/services/auth";
import { isError, isObject, isString } from "../helpers/utility";

const Container = styled.div`
  padding: ${props => props.padding || (isMobile ? 5:15)}px;

  @media print
  {    
      padding: 10px;
  }
`

function Content({
    loading,
    embeded,
    padding,
    permission,
    action,
    error,
    children
}) {
  return (
    <Container embeded={embeded} padding={padding}>
      {loading ?
        <Loading />
      :
        <>
        { (error && isString(error) && error.length > 0) ?
          <div className="p-2"><Error message={error} /></div>
        : (error && (isObject(error) || isError(error))) ?
          <div className="p-2"><Error message={error.message} /></div>
        : (!permission || userHasPermission(permission, action || "View")) ? 
          children
        :
          <div className="m-2">
            <Error message={`You do not have the proper permissions "${permission}" to view this content.`} />
          </div>
        }
        </>
    }
    </Container>
  );
}

export default Content