import React, { useState, useEffect } from "react";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal';
import produce from "immer"
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faShapes, faShoppingCart, faCreditCard, faWrench, faStickyNote, faPalette, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import PropertyList from "../components/properties/property-list"
import Property from "../components/properties/property"
import GoogleImage from "../components/google-image"

import { postData } from "../common/services/server"
import { formatPrice, formatDateTime } from "../helpers/utility"

const Items = styled.div`
  div.card {
    margin-bottom: 1px;
    cursor: pointer;
  }
`
const Preview = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 15px;

  img {
    display: block;
    margin: 0px auto;
    max-height: 100px;
  }
`

function SignDrawer(props) {
  const history = useHistory();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    postData("signs/signdata", {id: props.signId},
        function(result) {
            setData(result)
        },
        function(error) {
            console.log(error);
            alert("An error occured loading the sign data.")
        },
        function() {
            setLoading(false);
        }
    );
  }, [props.signId]);

  function onItemUrl(url) {
    history.push(url + "?filter=sign&filter_id=" + props.signId + "&filter_title=Sign+#" + props.signId);
    props.onHide();
  }
  function onAllProperties(e) {
    e.preventDefault();
    e.stopPropagation();
    history.push("/signs/sign/" + props.signId);
    props.onHide();
  }

  return (
      <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faShapes} />
            &nbsp;
            Sign Design #{props.signId}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { loading ? 
                <Loading /> 
                : 
                <>
                  <Preview>
                    <GoogleImage root src={data.sign.preview_url} />
                  </Preview>

                  <PropertyList>
                    <Property label="Title" value={data.sign.title} />
                    {data.sign.user_id && <Property label="User" value={data.sign.user_name} icon={faUser} />}
                    {!data.sign.user_id && <Property label="User" value="Anonymous" />}
                    <Property label="Size" value={data.sign.size_height + '" x ' + data.sign.size_width + '"'} />
                    <Property label="Cost" value={formatPrice(data.sign.estimate)} />
                    <Property label="Description" value={data.sign.description} />
                    <Property label="Versions" value={data.sign.version} />
                    <Property label="Date Created" value={formatDateTime(data.sign.added_date)} />
                    {data.sign.quote_id && 
                      <Property label="Project" value={data.sign.quote_id} onClick={() => onItemUrl('/projects')} />
                    }
                  </PropertyList>
                  <div>
                    <a href="#" onClick={onAllProperties}>
                      View All Properties...
                    </a>
                  </div>

                  <br />
                  
                  <Items>
                    <Card onClick={() => onItemUrl('/carts')}>
                      <Card.Header className="hasbadge">
                        <div>
                          <FontAwesomeIcon icon={faShoppingCart} />
                          &nbsp;
                          Shopping Carts 
                        </div>
                        <Badge variant={data.stats.cart > 0 ? "primary":"secondary"}>{data.stats.carts}</Badge>
                      </Card.Header>
                    </Card>

                    <Card onClick={() => onItemUrl('/orders')}>
                      <Card.Header className="hasbadge">
                        <div>
                          <FontAwesomeIcon icon={faCreditCard} />
                          &nbsp;
                          Orders
                        </div>
                        <Badge variant={data.stats.orders > 0 ? "primary":"secondary"}>{data.stats.orders}</Badge>
                      </Card.Header>
                    </Card>

                    <Card onClick={() => onItemUrl('/emails')}>
                      <Card.Header className="hasbadge">
                        <div>
                          <FontAwesomeIcon icon={faEnvelope} />
                          &nbsp;
                          Emails
                        </div>
                        <Badge variant={data.stats.emails > 0 ? "primary":"secondary"}>{data.stats.emails}</Badge>
                      </Card.Header>
                    </Card>

                    <Card onClick={() => onItemUrl('/notes')}>
                      <Card.Header className="hasbadge">
                        <div>
                          <FontAwesomeIcon icon={faStickyNote} />
                          &nbsp;
                          Notes
                        </div>
                        <Badge variant={data.stats.notes > 0 ? "primary":"secondary"}>{data.stats.notes}</Badge>
                      </Card.Header>
                    </Card>
                  </Items>
                </>
            }
      
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default SignDrawer;