import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from '@tanstack/react-query';
import { faThumbsDown, faBars, faUser, faEnvelope, faShapes, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { produce } from "immer";

import Panel from "./panel";
import { formatDate, formatPrice, formatTime, stripEmojis } from "../helpers/utility";
import { useGetEmails } from "../api/queries/emails";
import ViewEmailDrawer from "../drawers/drawer-viewemail";
import EmailDrawer from "../drawers/drawer-email";
import Button from "../components/buttons/button";
import { Actions, Permissions, userHasPermission } from "../common/services/auth";
import { useGetSign, useGetSigns } from "../api/queries/signs";
import { isAutomatedEmail, isOutgoingEmail } from "../helpers/email";

import monkey from "../images/monkey-face.svg";
import DropdownMenu from "../components/dropdownmenu";
import DrawerTemplateData from "../drawers/template-data";
import { getIconForType } from "../helpers/icons";
import storage from "../settings/storage";
import ZoomableImage from "../components/image-zoomable";

function SelectProjectSignsDrawer({
    projectId, 
    onSigns,
    onHide
}) {
    const [selected, setSelected] = useState([]);

    const signsQuery = useGetSigns({
        project_id: projectId
    });    
    const signs = signsQuery.data;
    
    useEffect(
        () => {
            if (!signs) return;    
            
            setSelected(signs.map(s => s.id))
        }, 
        [signs]
    );

    return (
        <DrawerTemplateData
            icon={getIconForType("signs")}
            title="Select Project Signs"
            query={signsQuery}
            onHide={onHide}
            buttons={
                <Button
                    icon={faEnvelope}
                    variant="outline-secondary"
                    disabled={selected.length == 0}
                    onClick={() => onSigns(selected)}
                >
                    Send Email
                </Button>
            }
        >
            <div className="grid gap-1">
                {(signs || []).map(sign => {
                    const isSelected = selected.includes(sign.id);

                    return (
                        <div 
                            key={sign.id}
                            className={clsx(
                                "rounded border border-gray-100 p-1 text-sm",
                                "grid grid-cols-[160px,1fr,auto] items-center gap-2",
                                isSelected ? "bg-purple-100" : "bg-gray-100",
                            )}
                        >
                            <ZoomableImage
                                url={storage.root + sign.preview_url}
                                containerClassName="h-[60px] w-[160px] border border-gray-200 !border-dashed rounded p-1"
                                maxHeight="50px"
                                maxWidth="150px"
                            />
                            <div className="grid">
                                <div>#{sign.id}</div>
                                {sign.title && <div className="text-xs">{sign.title}</div>}
                                <div className="text-green-500">{formatPrice(sign.estimate)}</div>
                                <div className="text-xs text-gray-500">{sign.size_height} x {sign.size_width}</div>
                            </div>
                            <div>
                                <Button
                                    size="sm"
                                    // icon={isSelected ? faThumbsUp : faThumbsDown}
                                    variant={isSelected ? "solid-danger":"solid-success"}
                                    onClick={() => {
                                        if (selected.includes(sign.id)) {
                                            setSelected(produce(draft => {
                                                draft.splice(selected.indexOf(sign.id), 1);
                                            }))
                                        }
                                        else 
                                            setSelected([...selected, sign.id]);
                                    }}
                                >
                                    {isSelected ? "Remove":"Select"}
                                </Button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </DrawerTemplateData>
    )
}

export default function EmailsPanel({
    signId,
    sendSignId, // just for sending, not for loading/filtering (project selected sign)
    userId,
    userIds,
    orderId,
    projectId,
    sendProjectId, // just for send emails
}) {
    const queryClient = useQueryClient()

    const [selected, setSelected] = useState(null);
    const [reply, setReply] = useState(null);
    const [emailTemplate, setEmailTemplate] = useState(null);
    const [selectSigns, setSelectSigns] = useState(false);

    const emailsQuery = useGetEmails({
            sign_id: signId,
            user_id: userId,
            user_ids: userIds,
            project_id: projectId,            
        });
    const emails = emailsQuery.data;

    const signQuery = useGetSign(signId, {}, {enabled: !!signId});

    function reloadEmails() {
        // TODO: Remove after other email queries are converted to reactquery (drawers)
        queryClient.invalidateQueries(["emails"]);
        queryClient.invalidateQueries(["cards", "emails"]);
    }

    return (
      <>
        <Panel query={emailsQuery}>
            {emails && 
                <div className="grid gap-1 pb-[40px]">
                    {emails.map(email => {
                        const isOutgoing = isOutgoingEmail(email);
                        const isAutomated = isOutgoing && isAutomatedEmail(email);

                        return (
                            <div
                                key={email.id} 
                                className={clsx(
                                    "grid grid-cols-[20px,1fr,auto] gap-1 items-center border rounded border-gray-300 p-1",
                                    "text-sm cursor-pointer",
                                    "hover:bg-gray-100 hover:no-underline",
                                    isAutomated ? "bg-gray-200" : isOutgoing ? "bg-purple-100" : "bg-white",
                                )}
                                onClick={() => setSelected(email)}
                            >
                                <div className="rounded bg-gray-100 p-[2px] flex justify-center items-center">
                                    {isAutomated ?
                                        <>&nbsp;</>
                                    : isOutgoing ?
                                        <img src={monkey} />
                                    :                                    
                                        <FontAwesomeIcon icon={faUser} />
                                    }
                                </div>
                                <div>
                                    {stripEmojis(email.subject)}
                                    <div className="text-xs text-gray-400">
                                        {isOutgoing ? "To:":"From:"} {isOutgoing ? email.to : email.from}
                                    </div>
                                </div>
                                <div>
                                    <div>{formatDate(email.added_date)}</div>
                                    <div className="text-gray-500 text-xs">{formatTime(email.added_date)}</div>
                                </div>
                            </div>
                        )
                    })}

                    {(emails.length >= 100) && 
                        <div className="p-2 text-center text-sm text-red-500">
                            Only the first 100 emails are being displayed
                        </div>
                    }
                </div>
            }
        </Panel>

        <div className="absolute bottom-2 left-2 right-2 p-1 flex gap-1 mt-2 z-10 bg-purple-300">
            {(signId || sendSignId || sendProjectId) &&
                <>
                    <DropdownMenu
                        icon={faBars}
                        caption="Send Email"
                        size="sm"
                        direction="up"
                        align="left"
                        className="bg-white"
                        disabled={!userHasPermission(Permissions.Emails, Actions.Send)}
                        menu={[
                            {name: "blank", caption: "Email Customer", icon:faUser, hidden: !userId && !userIds},
                            {name: "sign", caption: "Email Sign", icon:faEnvelope, hidden: !signId && !sendSignId},
                            {name: "signapproved", caption: "Email Sign Approved", icon:faThumbsUp, hidden: !signId && !sendSignId},
                            {name: "projectsigns", caption: "Email Project Signs", icon:faShapes, hidden: !sendProjectId},
                        ]}
                        onMenuItem={(item) => {
                            if (item.name == "blank") {
                                setEmailTemplate({})                                
                            }                            
                            else if (item.name == "sign") {
                                setEmailTemplate({
                                    name:"Send Sign",
                                    subject: "Sign Design #" + (signId || sendSignId)
                                })                                
                            }
                            else if (item.name == "signapproved") {
                                setEmailTemplate({
                                    name: "Sign Approved",
                                    subject: "Sign Design #" + (signId || sendSignId) + " APPROVED",
                                    preview: "Your Sign Can Now Be Purchased",
                                })
                            }
                            else if (item.name == "projectsigns") {
                                setSelectSigns(true)
                            }
                        }}
                    />
                </>
            }
        </div>  

        { selectSigns && 
            <SelectProjectSignsDrawer
                projectId={sendProjectId}
                onSigns={(signIds) => {
                    setEmailTemplate({
                        name: "Sends Signs",
                        subject: "Sign Designs",
                        templateUrl: "emails/template?template=Send+Signs&sign_ids=" + signIds.join(",")
                    });
                }}
                onHide={ () => setSelectSigns(false) } 
            />             
        }
        { selected && 
            <ViewEmailDrawer 
                show={true}
                email={selected}
                onSaved={(email) => {
                    reloadEmails();
                    setSelected(null);
                }} 
                onDeleted={() => {
                    reloadEmails();
                    setSelected(null);
                }}
                onEmail={(email) => {
                    setSelected(email);
                }}
                onReply={() => {
                    setReply(selected);
                    setSelected(null);
                }}
                onCompleted={() => setSelected(null)}
                onHide={ () => setSelected(null)} 
            /> 
        }   
        { emailTemplate && 
            <EmailDrawer 
                bodyUrl={emailTemplate.templateUrl || emailTemplate.name ? (emailTemplate.templateUrl || "emails/template?template=" + escape(emailTemplate.name) + "&sign_id=" + (signId || sendSignId)) : null}
                email={{
                    sign_id: (signId || sendSignId),
                    template: "simple",
                    to: signQuery.data?.user_email,

                    subject: emailTemplate.subject,
                    preview: emailTemplate.preview,
                    body: ""
                }}    
                userIds={userIds}        
                show={true}
                onSaved={(email) => {
                    reloadEmails();
                    setEmailTemplate(null);
                }}
                onHide={ () => setEmailTemplate(null) } 
            />         
        }          
        { reply && 
            <EmailDrawer 
                show={true}
                reply={reply}
                email={reply}
                onSaved={(email) => {
                    reloadEmails();
                    setReply(null)
                }} 
                onHide={ () => setReply(null) } 
            /> 
        }                 
      </>
    );
}