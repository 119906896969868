import React from "react"
import styled from "styled-components"
import { colors } from "../../settings/settings";

const Panel = styled.div`
    border: 1px solid #ccc;
    border-radius: 5px;   
    padding: 5px; 

    > h2 {
        font-size: 16px;
        margin: 0px 0px 5px 0px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    button {
        width: 100%;
        border: 0px;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 5px;
        text-align: center;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        font-size: 12px !important;

        &.btn-success {
            border-color: ${colors.green};
            color: ${colors.green};
            height: 100%;
        }
        &.blue {
            border-color: ${colors.blue};
            color: ${colors.blue};
        }

        &:hover {
            background-color: #eee;
        }
    }

    .green {
        
    }

    .buttons.side {
        display: grid;
        gap:  5px;
    }
`

function BoardPanel({children}) {
    return (
        <Panel>
            {children}
        </Panel>
    );
}

export default BoardPanel