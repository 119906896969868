import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faPaperclip } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import ListField from "../components/fields/field-list"
import TextField from "../components/fields/field-text"
import UrlField from "../components/fields/field-url"
import UploadField from "../components/fields/field-filesupload"

import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

function ResourceDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(
    () => {
      setFormData(JSON.parse(JSON.stringify(props.resource || {})));
    }, 
    []
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();

    console.log("handleFormChange", event.target.name, event.target.value)
    
    const field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("resources/save", removeNulls({
            resourcetype: props.type,
            type_id: props.typeId,
            resource_id: props.resource ? props.resource.id : null,
            ...formData
        }), 
        function(response) {
            props.onSaved(response);
        },
        function(error) {
            alert("An error occured saving the resource.");
        },
        function() {
            setIsProcessing(false);
        }
      );
    }
  }

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faPaperclip} />
                &nbsp;
                {props.resource ? "Edit":"Add"} Resource
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
                    <ListField 
                        name="type" 
                        label="Type" 
                        layout="horizontal"                            
                        value={formData.type} 
                        required
                        list={[
                            {value: "Instructions", label: "Instructions"},
                            {value: "Other", label: "Other"},


                        ]}
                        onChange={(e) => handleFormChange(e)} 
                    />

                    <TextField 
                        name="title" 
                        label="Title" 
                        layout="horizontal"                            
                        required
                        value={formData.title} 
                        onChange={(e) => handleFormChange(e)} 
                    />
                    
                    <div style={{margin: "30px 0px 20px 0px"}}>
                        One of the following options:
                    </div>

                    <UploadField
                        name="attachments"
                        label="File Upload"
                        layout="horizontal"
                        variant="simple"
                        uploadUrl={"resources/upload?type="+props.type+"&type_id=" + props.type_id}
                        onUpload={(file) => {
                            setFormData(produce(draft => {
                                draft.file_id = file.id;
                                draft.file = file;
                            }));
                        }}
                    />

                    <UrlField 
                        name="url" 
                        label="File Link URL" 
                        layout="horizontal"                            
                        value={formData.url} 
                        onChange={(e) => handleFormChange(e)} 
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonProcessing 
                    processing={isProcessing}
                    onClick={onSave} 
                    variant="outline-success" 
                    caption="Save Resource" 
                    icon={faSave} /> 
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default ResourceDrawer;