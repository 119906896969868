import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

export default function WizardNav({
    steps,
    current,
    size,
    disabled,
    onStep,
    onPrevStep,
    onNextStep,
}) {
    return (
        <div className="flex items-center gap-2">
            {steps.map((step, index) => {
                const stepIdx = steps.findIndex(s => s.name == step.name);
                const curIdx = current ? steps.findIndex(s => s.name == current.name) : -1;

                const isPrevStep = current && stepIdx < curIdx;
                const isNextStep = current && stepIdx > curIdx;

                let stepDisabled = disabled || step.disabled;
                
                if (!stepDisabled && isPrevStep && !onPrevStep) stepDisabled = true;
                if (!stepDisabled && isNextStep && !onNextStep) stepDisabled = true;
                
                if (stepDisabled && current && current.name == step.name)
                    stepDisabled = false;
                
                //&& (!current || step.name != current.name)
                return (
                    <React.Fragment key={step.name}>
                        <button 
                            type="button"
                            className={clsx(
                                "relative border border-gray-300 rounded-2xl px-3 py-4 text-center",
                                size && size == "sm" && "text-xs !px-2 !py-2",
                                current && step.name == current.name && "bg-purple-200",
                                //!onStep && (!onPrevStep || !isPrevStep) ? "cursor-not-allowed" : "!cursor-pointer",
                                stepDisabled && "bg-gray-100 text-gray-400",
                            )}
                            onClick={() => {
                                if (!disabled && !step.disabled) {
                                    if (onStep)
                                        onStep(step)
                                    else if (onPrevStep && current && isPrevStep)
                                        onPrevStep(step)
                                    else if (onNextStep && current && isNextStep)
                                        onNextStep(step)
                                }
                            }}
                        >
                            {step.title}

                            {(step.info) && 
                                <div className="absolute -top-1 -right-1 border border-gray-300 bg-gray-200 rounded-full p-1 text-xs">
                                    {step.info}
                                </div>
                            }
                        </button>
                        {step.name != "done" &&
                            <FontAwesomeIcon icon={faCaretRight} size={size && size=="sm" ? "lg":"2x"} style={{color: "#e0e0e0"}} />
                        }
                    </React.Fragment>
                )
            })}
        </div>
    )
}