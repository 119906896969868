import { 
    faArchive,
    faClipboardCheck,
    faClock,
    faCreditCard,
    faExclamationTriangle,
    faHandshake,
    faProjectDiagram,
    faPaintRoller,
    faPaperclip,
    faPlug,
    faQuestion, 
    faQuestionCircle, 
    faScroll, 
    faShapes, 
    faShoppingCart, 
    faSquare, 
    faStickyNote, 
    faTicketAlt, 
    faTruckMoving, 
    faUser,
    faWrench,
    faEnvelope,
    faCommentDots,
    faCogs,
    faLightbulb,
    faPuzzlePiece,
    faThumbtack,
    faBoxOpen,
    faScrewdriver,
    faToiletPaper,
    faDolly
} from "@fortawesome/free-solid-svg-icons";

export function getIconForType(type){
    switch (type.toLowerCase()) {
        case "cart":
            return faShoppingCart;
        case "chat":
            return faCommentDots;            
        case "sign":
        case "signs":
                return faShapes;
        case "note":
        case "notes":
            return faStickyNote;
        case "warning":
            return faExclamationTriangle;
        case "user":
        case "users":
            return faUser;
        case "project":
            return faArchive;
        case "part":
        case "parts":
            return faPlug;
        case "order":
        case "orders":
                return faCreditCard;
        case "shipping":
            return faTruckMoving;
        case "job":
        case "jobs":
            return faWrench;
        case "mwo":
        case "mwos":
            return faTicketAlt;
        case "material":
            return faScroll;
        case "decoration":
        case "decorationtype":
        case "decoration_type":
            return faPaintRoller;            
        case "email":
        case "emails":
                return faEnvelope;              
        case "file":
        case "files":
                return faPaperclip;     
        case "resource":
        case "resources":
                return faArchive;                
        case "task":
        case "tasks":
                return faClipboardCheck;       
        case "workflow":
        case "workflows":
                return faProjectDiagram;
        case "workflow_task":
                return faSquare;
        case "workflow_parts":
                return faQuestionCircle;            
        case "workflow_delay":
                return faClock;            
        case "interactivebuilder":
            return faHandshake;   

        default:
            console.log("missing icon type", type)
            return faQuestion;
    }
}

export function getIconForPartType(type, partName="") {
    switch (type.toLowerCase()) {
        case "mount":        
            return faThumbtack;
        case "power":        
            return faPlug;
        case "lighting":
            return faLightbulb;
        case "backdrop":
            return ;
        case "shipping":        
            return faTruckMoving;
        case "auto":
            return faCogs;
        case "other":
            return faQuestionCircle;
        case "bod":
            return faWrench;
        case "stock":
            return faBoxOpen;
        case "order":
            return faCreditCard;
        case "assembly":
            return faScrewdriver;
        case "material":
            return faToiletPaper;
        case "equipment":
            return faDolly;
        default:
            return faPuzzlePiece;
    }
}