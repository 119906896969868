import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Pill = styled.div`
    background-color: #eee;
    border: 1px solid ${props => props.onClick ? "#039aff" : "#ccc"};
    border-radius: 3px;
    padding: ${props => props.dense ? "1px 3px" : "3px 6px"};
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: ${props => props.onClick ? "pointer" : "default"}
    white-space: nowrap;

    &:hover {
        background-color: ${props => props.onClick ? "#95cef4" : "#eee"}
    }

    label {
        font-weight: 500;
        margin: 0px;
    }

    span.count {
        background-color: #666;
        color: white;
        border-radius: 3px;
        padding: 1px 3px;
    }

    button {
        border: 1px solid #333;
        background-color: white;
        border-radius: 3px;
        padding: 1px 4px;
        color: white;

        &:hover {
            background-color: #eee;            
        }
    }
`

function BoardPill({
    label, 
    
    count,
    countType,

    dense,

    value, 
    valueColor,
    icon, 
    color,     
    buttonIcon, 
    buttonColor, 
    buttonClick, 
    onClick,    
    children
}) {
    return (
        <Pill 
            dense 
            style={{
                backgroundColor: color || "default"
            }}
            onClick={onClick}
        >
            {icon && <FontAwesomeIcon icon={icon} />}

            {label && <><label>{label}</label><span>:</span></>} 
            {(count || countType) && <label><span className="count">{count}</span> {countType}{count != 1 && "s"}</label>} 
            
            <div style={{
                backgroundColor: valueColor || "default",
                borderRadius: (valueColor ? 3:0) + "px",
                padding: valueColor ? "1px 3px" : "0px"
            }}>            
                {value || children}
            </div>

            {buttonIcon && 
                <button onClick={buttonClick} style={{borderColor: buttonColor, color: buttonColor}}>
                    <FontAwesomeIcon icon={buttonIcon} />
                </button>
            }
        </Pill>
    );
}

export default BoardPill