import React, { useState, useRef } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { postData } from "../common/services/server";
import { setToken } from "../common/services/auth";
import Button from "../components/buttons/button";

const LoginForm = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 50px auto;
  max-width: 350px;
  padding: 25px;

  > div {
    display: flex;
    margin-bottom: 10px;

    label {
      flex-basis: 100px;
    }
  }

  button {
    display: inline-block;
    margin: 0px auto;
    padding: 5px 10px;
    border-radius: 5px;
  }
`

function LoginPage(props) {
  let { returnto } = useParams();
  const location = useLocation();
  //const returnto = location.state ? location.state.returnto || "/" : "/";

  const [loading, setLoading] = useState(false);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  console.log(location)
  console.log(decodeURIComponent(returnto));

  function onLogin() {
    if (!emailRef.current.value || !passwordRef.current.value ) {
      alert("Email and password are required.");
      return;
    }
    
    setLoading(true);
    
    postData('auth/authenticate', {
      email: emailRef.current.value,
      password: passwordRef.current.value    
    },
        function(result) {
          console.log(returnto);

          if (result.message && result.message.length)
              alert(result.message);
          else {
              setToken(result.token);

              let returnTo = decodeURIComponent(returnto || "/");

              returnTo = returnTo.replace("/login/", "");

              props.history.push(returnTo)
          }
        },
        function(error) {
          alert("Error authenticating.");
        },
        function() {
          setLoading(false);
        }
    );
  }

  return (
    <>
      <div></div>
      <LoginForm>
        <div>
          <label>Email:</label> <input ref={emailRef} required type="text" name="user" className="border border-gray-300" />
        </div>
        <div>
          <label>Password:</label> <input ref={passwordRef} required type="password" name="password" className="border border-gray-300" />
        </div>

        <Button 
          disabled={loading} 
          onClick={onLogin}
        >
            <FontAwesomeIcon icon={loading ? faSpinner : faUser} spin={loading} />{' '}
            Login 
        </Button>
      </LoginForm>
      <div></div>
    </>
  );
}

export default LoginPage;