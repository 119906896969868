import React from "react";
import Form from 'react-bootstrap/Form';

import FieldLabel from "./label"
import HelpText from "./help-text"

function FieldBoolean(props) {

    function onChange(e) {
        props.onChange({
            target: {
                name: props.name,
                value: e.target.value
            }
        }); 
    }

    let groupStyle = props.groupStyle || {};

    if (props.layout == "horizontal") {
        groupStyle.display = "grid";
        groupStyle.gridTemplateColumns = "125px auto";
        groupStyle.alignItems = "center";
    }
    
    return (
        <Form.Group className={props.groupClassName} style={groupStyle}>
            {props.label && <FieldLabel required={props.required} bold={props.labelBold}>{props.label}</FieldLabel>}

            <div>
                <Form.Check 
                    name={props.name}
                    value={1}
                    checked={props.value == 1}
                    inline 
                    label={props.yesLabel || "Yes"} 
                    type="radio" 
                    onChange={onChange}
                />
                <Form.Check 
                    name={props.name}
                    value={0}
                    checked={props.value == 0}
                    inline 
                    label={props.noLabel || "No"} 
                    type="radio" 
                    onChange={onChange}
                />
            </div>
            {props.instructions && <HelpText>{props.instructions}</HelpText>}
        </Form.Group>
    )
}

export default FieldBoolean