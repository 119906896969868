import React, { useState, useEffect } from "react";
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faSpinner, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import produce from "immer"
import Progress from "../progress"
import GoogleImage from "../google-image"

import settings from '../../settings'
import { generateThumbnails } from '../../helpers/thumbnails'
import { getToken, getUser } from '../../common/services/auth'

const Filename = styled.span`
    font-size: 14px;
    color: #555;

    span {
        max-width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
const Row = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    margin-left: 5px;
    overflow: hidden;
    box-sizing: border-box;
`
const ProgressWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
`
const Actions = styled.div`
    text-align: right;
`
const Files = styled.div`
    flex: 1;
`

function FieldFileUpload(props) {
    const [upload, setUpload] = useState({
            progress: 0,
            uploading: false,
            error: "",
            data: { id: 0 }
        });

    useEffect(
        () => {
            if (props.thumbnail) {
                generateThumbnails(
                    props.file,
                    null,
                    (thumbnails) => {
                        uploadFile(props.file, thumbnails.thumbnail, thumbnails.preview);
                    }
                );
            }
            else {
                uploadFile(props.file)
            }
        }, 
        []
    );

    function uploadFile(file, thumbnailData, previewData) {
       console.log("uploadFile", file)
        //return new Promise((resolve, reject) => {

        /*
        setUploads(produce(uploads, draft => {
            var index = getFileIndex(file);

            if (index)
              draft[index].uploading = true;
        }));
        */

        const req = new XMLHttpRequest();

        req.upload.addEventListener("progress", event => {
            console.log("upload progress", event);
            if (event.lengthComputable) {
                setUpload(produce(upload, draft => {
                    draft.uploading = true;
                    draft.progress = (event.loaded / event.total) * 100;
                }));
            }
        });
        
        req.upload.addEventListener("load", event => {
            console.log("upload load", event);
            setUpload(produce(upload, draft => {
                draft.uploading = true;
                draft.progress = 90; // only 90 since we have to wait on the processing/response from the server
            }));
        });
        
        req.upload.addEventListener("error", event => {
            console.log("upload error", event);

            setUpload(produce(upload, draft => {
                draft.uploading = false;
                draft.error = "Error uploading";
                draft.progress = 100;
            }));
        });

        req.addEventListener("readystatechange", event => {
          var status, text, readyState;

          try {
            readyState = event.target.readyState;
            text = event.target.responseText;
            status = event.target.status;
          }
          catch(e) {
            return;
          }

          console.log("readystatechange", file.name, readyState, status);

          if (readyState == 4 && status == '200') {
            var data = JSON.parse(text);
            console.log("Done", data);

            setUpload(produce(upload, draft => {
                draft.data = data.files ? data.files[0] : data;
                draft.uploading = false;
                draft.error = "";
                draft.progress = 100;
            }));

            props.onUpload(data.files ? data.files[0] : data);
          }
          else if (readyState == 4 && (status == '500' || status == '400')) {
            setUpload(produce(upload, draft => {
                draft.uploading = false;
                draft.error = "Error uploading";
                draft.progress = 100;
            }));
          }
        });

        const formData = new FormData();
        formData.append("files", file, file.name);

        const user = getUser();
        const token = getToken();

        // if (user)
        //     formData.append("current_user_id", user.id);

        if (thumbnailData)
            formData.append("thumbnailData", thumbnailData);
        if (previewData)
            formData.append("previewData", previewData);

        if (props.external)
            req.open("POST", settings.SITE_API_URL + props.uploadUrl);
        else
            req.open("POST", settings.API_URL + props.uploadUrl);

        if (!props.external) {
            req.setRequestHeader('Authorization', 'Bearer ' + token);
            req.withCredentials = true;
        }        
    
        req.send(formData);
        
        //});
    }

    return (
        <Row>
            <Filename>
                <span>{props.file && props.file.name}</span>
                {upload.error.length > 0 && <small className="float-right text-danger">{upload.error}</small>}
                {upload.data.id != 0 && <small className="float-right text-muted">#{upload.data.id}</small>}
            </Filename>
            <input type="hidden" name={props.name + "[]"} value={upload.data.id} />

            <ProgressWrapper>
              <Progress error={upload.error.length} progress={upload.progress} />
              <FontAwesomeIcon
                icon={upload.error.length ? faExclamationCircle : upload.progress == 100 ? faCheckCircle : faSpinner}
                spin={upload.uploading}
              />
            </ProgressWrapper>

            {props.previewUrl &&
                <div>
                    <GoogleImage 
                        root 
                        src={props.previewUrl} 
                        style={{
                            maxWidth: "200px",
                            maxHeight: "75px"
                        }}
                    />
                </div>
            }            
        </Row>
    )
}

export default FieldFileUpload