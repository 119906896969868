import React from "react";
import styled from 'styled-components'

const List = styled.table`
    width: 100%;
    font-size: 12px;
    border-collapse: separate;

    thead tr th {
        font-weight: normal;
        font-size: 15px;
        background-color: white;
        color: black;
        border: 1px solid #e0e0e0;        
        border-left: 5px solid #e0e0e0;
        border-radius: 0px;
        padding: 5px;

        .form-group {
            float: right;
            margin: 0px;

            select {
                width: auto;
                font-size: 10px;
            }
        }
    }

    tbody {
        tr.important {
            color: red;
        }
        tr {
            &.link {
                td {
                    cursor: pointer;
                    color: blue;
                }
                td:hover {
                    text-decoration: underline;
                }
            }
            &.info {
                th {
                    background-color: white;
                }
            }
            &.success {
                td {
                    color: green;
                }
            }

            button.btn-sm {
                padding: 0px 4px;
                font-size: 12px;
            }
        
            th {
                background-color: #eee;
                padding: 3px 6px;
                width: 35%;
                white-space: nowrap;
                border-radius: 4px;
                border: 1px solid #e0e0e0;
        
                &.empty {
                    background-color: white;
                    border-color: white;
                }
                &.danger {
                    background-color: #dc3545;
                    color: white;
                }
            }
            td {
                &.capitalize {
                    text-transform: capitalize;
                }
                
                > div.cols {
                    display: flex;
                    align-items: center;    
                }

                overflow-wrap: break-word;
                white-space: break-spaces;
                
                padding: 3px 6px;
                background-color: white;
        
                &.link {
                    color: blue;
                    text-decoration: underline;
                }
                &.actions {
                    text-align: right;

                    > * {
                        color: #999;
                        cursor: pointer;

                        &:hover {
                            color: black;
                        }
                    }
                }

                div.form-group {
                    margin-bottom: 0px;
                }
        
                svg {
                    margin-right: 5px;
                }
        
                button {
                    svg {
                        margin-right: 0px;
                    }
                }
            }            
        }        
    }
`

function PropertyList(props) {
    return (
        <List style={{maxWidth: props.maxWidth || "none"}} className="properties">
            {props.header && 
                <thead>
                    {props.header}
                </thead>
            }
            <tbody>{props.children}</tbody>
        </List>
    )
}

export default PropertyList