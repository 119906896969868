import React, { useState, useEffect } from "react";
import { addDays, differenceInCalendarDays, format, formatDistance, parseISO, subBusinessDays } from "date-fns";
import { faTruckMoving, faWrench } from "@fortawesome/free-solid-svg-icons";

import CalendarField from "../components/fields/field-calendar";
import Panel from "./panel";
import BoardField from "../components/board/board-field";

import { colors } from "../settings/settings";
import { useGetJobSchedule } from "../api/queries/workflowjobs";
import { useWorkflowJobUpdateProp } from "../api/mutations/workflowjobs";
import { convertDateStringWithoutTime, convertDateWithoutTime, formatDate } from "../helpers/utility";
import BoardColumns from "../components/board/board-columns";

export default function JobWorkflowSchedulePanel({jobId}) {
    const [shipDate, setShipDate] = useState(null);
    const [highlightDays, setHighlightDays] = useState([]);

    const scheduleQuery = useGetJobSchedule(jobId);
    const schedule = scheduleQuery.data;

    const updateProductionDays = useWorkflowJobUpdateProp(jobId, "production_days");
    const updateShipDate = useWorkflowJobUpdateProp(jobId, "ship_date");
    const updateStartDate = useWorkflowJobUpdateProp(jobId, "start_date");

    useEffect(
        () => {
            if (schedule) {
                setShipDate(schedule.ship_date);
            }
        }, 
        [schedule]
    );

    useEffect(
        () => {
            if (shipDate) {
                const startDate = convertDateStringWithoutTime(schedule.start_date);
                const endDate = convertDateStringWithoutTime(schedule.ship_date);

                // console.log({startDate})
                // console.log({endDate})
                const totalDays = differenceInCalendarDays(endDate, startDate);
                // console.log({totalDays})
                let days = [];
                
                for (var i=0; i<=totalDays; i++) {
                    days.push({
                        date: addDays(startDate, i),
                        color: i==0 || i==totalDays ? colors.purple : colors.lightPurple,
                        icon: i==totalDays ? faTruckMoving : i==0 ? faWrench : null,
                        label: i==totalDays ? "Ships" : i==0 ? "Production" : null,
                    })
                }
                
                console.log({days})
                setHighlightDays(days);
            }
        }, 
        [shipDate, schedule]
    );

    const today = new Date(); today.setHours(0,0,0,0);

    return (
        <Panel query={scheduleQuery}>
          {schedule &&
            <div className="grid gap-1">
                <BoardColumns>
                    <BoardField
                        type="label"
                        label="Product:"
                        labelClassName="w-[150px]"
                        value={schedule.product_title}
                    />
                    <BoardField
                        type="label"
                        label="Product Production:"
                        labelClassName="w-[175px]"
                        value={schedule.production_days}
                        suffix="Days"
                        // saveMutation={updateProductionDays}                 
                    />                    
                </BoardColumns>
                <BoardColumns>
                    <BoardField
                        type="children"
                        label="Order Date:"
                        labelClassName="w-[150px]"
                        value={schedule.product_title}
                    >
                        <div>
                            {format(parseISO(schedule.order_date), "yyyy-MM-dd")} 
                            &nbsp;
                            <span className="text-sm text-gray-400">-&nbsp;{formatDistance(parseISO(schedule.order_date), new Date())} ago</span>
                        </div>
                    </BoardField>
                    <BoardField
                        type="label"
                        label="Order Ship Date:"
                        labelClassName="w-[175px]"
                        value={formatDate(schedule.order_ship_date)}
                        info="Based on the date the order was placed and the product production days"
                        infoType="icon"
                    />
                </BoardColumns>
                <BoardColumns>
                    <BoardField
                        type="date"
                        label="Job Start Date:"
                        labelClassName="w-[150px]"
                        value={schedule.start_date}
                        info="Date production will start. Can be set in the future to delay the job."
                        saveMutation={updateStartDate}
                    />
                </BoardColumns>


                <div className="mt-2">
                    <CalendarField 
                        name="ship_date" 
                        label="Job Ship Date:"
                        labelClassName="text-sm"
                        value={shipDate} 
                        highlights={highlightDays}
                        disabled={false}
                        onChange={(e, value) => {
                            setShipDate(value);
                            updateShipDate.mutate(value);
                        }} 
                    />   
                </div> 
            </div>
          }      
        </Panel>
    )
}
