import React, { useState } from "react";
import {Helmet} from "react-helmet";
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import FormControl from 'react-bootstrap/FormControl'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { isTablet, isMobile } from "react-device-detect"

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Badge from 'react-bootstrap/Badge'

import SiteHeader from "./site-header"
import HelpDrawer from "../drawers/drawer-help"

import { postData } from "../common/services/server";
import { userHasPermission, Actions } from "../common/services/auth";

const Header = styled.header`
    background-color: #eee;
    padding: 0px 5px;

    @media print
    {    
        min-height: auto;
        padding: 10px;
        margin-bottom: 10px;
    }

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        min-height: 34px;
    }

    h1 {
        font-size: 24px;
        color: #333;
        margin: 0px 0px 0px 5px;
        display: flex;
        align-items: center;

        a {
            font-size: 75%;
            color: #999;

            &:hover {
                color: blue;
            }
        }
        span {
            font-size: 75%;
            color: #999;
            margin: 0px 5px;
        }

        svg {
            margin-left: 10px;
            cursor: pointer;
        }
    }

    nav.nav-tabs {
        margin-top: 5px;

        svg {
            margin-right: 5px;

            &.info {
                margin-right: 0px;
                margin-left: 8px;
                color: #ccc;
            }
        }

        span.highlight {
            color: red !important;
        }
    }

    div.buttons {
        display: flex;
        align-items: center;
        
        > * {
            margin-left: 3px;
        }

        .form-group {
            margin-bottom: 0px;
        }
    }
`
const AllTabs = styled.div`
    display: ${isMobile ? "block":"flex"} !important;
    align-items: center;

    @media print
    {            
        display: none !important;
    }    
`
const RightTabs = styled(Tabs)`
    float: right;
    flex: 1;
    flex-flow: row-reverse;
`
const Secondary = styled.div`
    select {
        width: ${isTablet ? "100%":"auto"};
        display: inline-block;
        margin-top: ${isTablet ? "0px":"10px"};
        margin-bottom: ${isTablet ? "5px":"0px"};
    }
`
const Notices = styled.div`
    margin: 5px 0px;
    justify-content: flex-start !important;

    .alert {
        font-size:12px;
        padding: 4px;
        margin: 1px;
        white-space: nowrap;
    }
`

function PageHeaader(props) {
    const [title, setTitle] = useState(props.title);
    const [selected, setSelected] = useState(null);
    const [busy, setBusy] = useState(false);

    let tabs = []
    let items = []
    let rightTabs = [];

    if (!isMobile) {
        tabs = props.tabs ? props.tabs.filter(t => !t.secondary) : null;
        items = props.tabs ? props.tabs.filter(t => t.secondary) : null;
        rightTabs = tabs && tabs.filter(t => t.align && t.align=="right");
    }
    else {
        items = props.tabs;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{props.title || props.pageTitle}</title>
            </Helmet>
            {/* {!props.embeded && 
                <SiteHeader 
                    onNewNote={props.onNewNote}
                    onNewTask={props.onNewTask}
                    onNewEmail={props.onNewEmail}
                    newNoteData={props.newNoteData}
                    newTaskData={props.newTaskData}
                    newEmailData={props.newEmailData}
                />
            } */}
            {props.title && 
              <Header embeded={props.embeded}>
                <div>
                    <h1>
                        {(!props.embeded && props.parents) && 
                            <>
                            {props.parents.map(parent => (
                                <div key={parent.url}>
                                    <Link  to={parent.url}>
                                        {parent.title}
                                    </Link>
                                    <span>/</span>
                                </div>
                            ))}
                            </>
                        }
                        {(!props.embeded && props.parent) && 
                            <div>
                                <Link to={props.parent.url}>
                                    {props.parent.title}
                                </Link>
                                <span>/</span>
                            </div>
                        }

                        {props.title}

                        {(!props.embeded && props.subtitle) && 
                            <small style={{color: "#999", marginLeft: "8px"}}>{props.subtitle}</small>
                        }

                        {(!props.embeded && props.help) && 
                            <FontAwesomeIcon onClick={() => setSelected("help")} icon={faQuestionCircle} />
                        }
                    </h1>

                    {!props.embeded && 
                        <div className="buttons" style={{margin: "3px"}}>
                            {props.children}
                        </div>
                    }
                </div>

                {(!props.embeded && props.header) && 
                    <div>
                        {props.header}
                    </div>
                }
                {(!props.embeded && props.notices) && 
                    <Notices>
                        {props.notices}
                    </Notices>
                }

                {tabs && 
                    <AllTabs>
                        <Tabs as={<div />} activeKey={props.tab} transition={false} onSelect={k => props.onTab(k)}>
                            {tabs.filter(t => !t.align || t.align=="left").map(tab => {
                                let hide = tab.hidden;
                                
                                if (!hide && tab.permission)
                                    hide = !userHasPermission(tab.permission, tab.action||Actions.View);
                                    
                                if (!hide &&tab.hideOnNoCount) 
                                    hide = !tab.count || tab.count == 0;
                                
                                return (
                                    hide ? null :
                                    <Tab 
                                        key={tab.name} 
                                        eventKey={tab.name} 
                                        title={
                                            <span className={tab.highlight ? "highlight":""}>
                                            {tab.icon && 
                                                <FontAwesomeIcon icon={tab.icon} />
                                            }
                                            {props.tabUrl ? 
                                                <a href={props.tabUrl.replace("{tab}", tab.name)} onClick={(e) => e.preventDefault()}>
                                                    {tab.title}
                                                </a>
                                                : 
                                                tab.title
                                            }
                                            {(tab.count > 0) && 
                                                <Badge variant="danger" style={{top:"-2px"}}>
                                                    {tab.count}
                                                </Badge>
                                            }
                                            {tab.tooltip && 
                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip>
                                                        <span dangerouslySetInnerHTML={{__html: tab.tooltip}} />
                                                        </Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} className="info" />
                                                </OverlayTrigger>
                                            }
                                            </span>
                                        } 
                                    />
                                )
                            })}
                        </Tabs>
                        {(items && items.length > 0) && 
                            <Secondary>
                                <FormControl 
                                    as="select" 
                                    name="tab" 
                                    value={props.tab} 
                                    size="sm" 
                                    onChange={(e) => props.onTab(e.target.value)}>
                                    <option value=""></option>
                                    {items.map(item => (
                                        <option key={item.name} value={item.name}>{item.title}</option>
                                    ))}
                                </FormControl>
                            </Secondary>
                        }
                        {rightTabs.length > 0 && 
                            <RightTabs activeKey={props.tab} transition={false} onSelect={k => props.onTab(k)}>
                            {rightTabs.map(tab => {
                                const hide = tab.permission ? !userHasPermission(tab.permission, tab.action||Actions.View) : tab.hideOnNoCount ? (!tab.count || tab.count == 0) : tab.hidden;

                                return (
                                    hide ? null :
                                    <Tab 
                                        key={tab.name} 
                                        eventKey={tab.name} 
                                        title={
                                            <span className={tab.highlight ? "highlight":""}>
                                            {tab.icon && 
                                                <FontAwesomeIcon icon={tab.icon} />
                                            }
                                            {tab.title}
                                            {(tab.count > 0) && 
                                                <Badge variant="danger" style={{top:"-2px"}}>
                                                    {tab.count}
                                                </Badge>
                                            }
                                            {tab.tooltip && 
                                                <OverlayTrigger
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip>
                                                        <span dangerouslySetInnerHTML={{__html: tab.tooltip}} />
                                                        </Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} className="info" />
                                                </OverlayTrigger>
                                            }
                                            </span>
                                        } 
                                    />
                                )
                            })}
                            </RightTabs>
                        }
                    </AllTabs>
                }
              </Header>
            }

            {(selected && selected == "help") && 
                <HelpDrawer
                    show={true}
                    type={props.help}
                    onHide={() => setSelected(null)}
                />
            }
        </>
    );
}

export default PageHeaader