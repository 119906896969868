import React from "react";
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import FieldLabel from "./label"
import HelpText from "./help-text"

function FieldCurrency(props) {
    let groupStyle = props.groupStyle || {};

    if (props.layout == "horizontal") {
        groupStyle.display = "grid";
        groupStyle.gridTemplateColumns = "125px auto";
        groupStyle.alignItems = "center";
    }

    return (
        <Form.Group style={groupStyle}>
            {props.label && <FieldLabel required={props.required} bold={props.labelBold}>{props.label}</FieldLabel>}
            <div>
            <InputGroup style={{width: props.width && props.width=="small" ? "120px":"auto"}}>
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        {props.percentage ? "%":"$"}
                    </InputGroup.Text>
                </InputGroup.Prepend>

                <FormControl 
                    as="input" 
                    type="number" 
                    name={props.name} 
                    required={props.required}
                    defaultValue={props.value} 
                    onChange={(e) => { props.onChange({
                            target: {
                                name: props.name,
                                value: e.target.value
                            }
                        }); 
                    }}
                />
            </InputGroup>
            {props.instructions && <HelpText>{props.instructions}</HelpText>}
            </div>
        </Form.Group>
    )
}

export default FieldCurrency