import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"
import { faPlus, faClipboardCheck, faListAlt } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell, 
    DateCell,
    LinkCell,
    SortHeaderCell,
    SortTypes,
    CollapseCell
} 
from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Loading from "../../components/loading"
import Toolbar from "../../components/toolbar"

import TaskDrawer from "../../drawers/drawer-task"
import TaskIcon from "../../components/tasks/task-icon"
import { postData } from "../../common/services/server"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"

const SubTasks = styled.table`
  border: 1px solid #eee;
  width: 100%;

  td {
    padding: 4px;
  }
`

function TasksPanel(props) {
  const windowDimensions = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState({column: "added_date", dir: SortTypes.DESC});
  const [tasks, setTasks] = useState([]);
  const [selected, setSelected] = useState(null);
  const [collapsedRows, setCollapsedRows] = useState(new Set());

  useBodyClass(`noscroll`);

  useEffect(
    () => {
      setIsLoading(true);

      postData("tasks/data?full=1", {
          sort: sort.column,
          sortdir: sort.dir,
          ...props.filters
        },
        function(result) {
            setTasks(result.tasks);
        },
        function(error) {
            alert("Error loading tasks")
        },
        function() {
          setIsLoading(false);
        }
      );
    }, 
    [props.filters, sort]
  );

  function onSortChange(columnKey, sortDir) {
    setSort({ column: columnKey, dir: sortDir })
  }

  function handleCollapseClick(rowIndex) {
    const shallowCopyOfCollapsedRows = new Set([...collapsedRows]);
    let scrollToRow = rowIndex;

    if (shallowCopyOfCollapsedRows.has(rowIndex)) {
      shallowCopyOfCollapsedRows.delete(rowIndex);
      scrollToRow = null
    } else {
      shallowCopyOfCollapsedRows.add(rowIndex);
    }

    setCollapsedRows(shallowCopyOfCollapsedRows);
  }

  function subRowHeightGetter(index) {
    let height = 0;

    if (collapsedRows.has(index)) {
      const subTasks = tasks.filter(p => p.parent_id == tasks[index].id);

      height = subTasks.length * 30
    }

    return height;
  }

  function rowExpandedGetter({rowIndex, width, height}) {
    if (!collapsedRows.has(rowIndex)) {
      return null;
    }

    const style = {
      height: height,
      width: width - 2,
    };

    const subTasks = tasks.filter(p => p.parent_id == tasks[rowIndex].id);

    return (
      <div style={style}>
        <SubTasks>
        <tbody>
          {subTasks.map(task => (
            <tr key={task.id}>
              <td>
                <TaskIcon task={task} />
                <a href="#" onClick={(e) => { setSelected({mode:'task', task}); e.preventDefault();}}>
                  {task.task}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
        </SubTasks>
      </div>
    );
  }

  return (
    <>
      {
          isLoading 
      ?
          <Loading />
      :    
        <>
          <Toolbar
            buttons={[
                {
                    name: "add",
                    label: "Add Task",
                    variant: "outline-success",
                    icon: faClipboardCheck
                }              
            ]}
            onButton={(name) => {
                console.log("button", name)
                setSelected({mode:name})
            }}
          />        
          <Table
              rowHeight={60}
              rowsCount={tasks.length}
              subRowHeightGetter={subRowHeightGetter}
              rowExpanded={rowExpandedGetter}              
              width={windowDimensions.width - dimensions.dataTableWidthOffset}
              height={windowDimensions.height - dimensions.dataTableHeightOffset}
              headerHeight={50}
              touchScrollEnabled={true}
          >
              <Column
                cell={<CollapseCell callback={handleCollapseClick} data={tasks} collapsedRows={collapsedRows} />}
                columnKey="child_count"
                fixed={!isMobile}
                width={30}
              />            
              <Column
                  header={<Cell>Task</Cell>}
                  fixed={!isMobile}
                  columnKey="task"
                  cell={
                    <LinkCell 
                      data={tasks} 
                      iconFormatter={(task) => <TaskIcon task={task} />}
                      onClick={(task) => setSelected({mode:'task', task})} 
                    />
                  }
                  width={400}
              />

              <Column
                  header={<Cell>Type</Cell>}
                  columnKey="type_title"
                  cell={<TextCell data={tasks} />}
                  width={100}
              />

              <Column
                  header={<Cell>Users</Cell>}
                  columnKey="assigned_users"
                  cell={<TextCell data={tasks} />}
                  width={200}
              />
              <Column
                  header={<Cell>Status</Cell>}
                  columnKey="status"
                  cell={<TextCell data={tasks} />}
                  width={100}
              />              
              <Column
                  header={
                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                        Date
                    </SortHeaderCell>
                  }
                  columnKey="due_date"
                  cell={<DateCell data={tasks} timeColumnKey="due_time" late={props.filters.status == "Active"} />}
                  width={155}
              />
          </Table>
        </>
      }
      { selected && 
        <TaskDrawer 
            show={true}
            task={selected.task} 
            onSaved={(task) => {
                setTasks(produce(draft => {
                    const index = draft.findIndex(t => t.id == task.id);
        
                    if (index != -1) 
                        draft[index] = task;
                    else 
                        draft.push(task);
                }));
            }} 
            onDeleted={() => {
                setTasks(produce(draft => {
                    const index = draft.findIndex(t => t.id == selected.id);
        
                    if (index != -1) 
                        draft.splice(index, 1)
                }));
                setSelected(null);
            }}
            onHide={() => setSelected(null)} /> 
      }
    </>
  );
}

export default TasksPanel;