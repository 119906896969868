import React, { useState, useEffect } from "react";

import { postData } from "../common/services/server";

import DrawerTemplateSimpleList from "./template-simplelist"

function RoleWorkflowTasksDrawer({role, onHide}) {
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([])

  useEffect(
    () => {      
        setLoading(true);

        postData("roles/workflowtasks", {
            role_id: role.id
        },
            function(results) {
                setTasks(results)
            },
            function(error) {
                alert("Error loading workflow tasks")
            },
            function() {
                setLoading(false);
            }
        );       
    }, 
    []
  );
  
  return (
    <DrawerTemplateSimpleList
        title={role.name + " Workflow Tasks"}
        loading={loading}
        items={tasks}
        itemClassName="border border-gray-300 rounded p-1"
        itemRender={(item) => (
            <div>
                {item.name}
                {item.description && 
                    <div className="text-xs text-gray-400">
                        {item.description}
                    </div>
                }
            </div>
        )}
        onHide={onHide}
    />
  )
}

export default RoleWorkflowTasksDrawer;