import React, { useState, useEffect } from "react";
import produce from "immer"
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    SortHeaderCell,
    SortTypes,    
    EditActionsCell
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Loading from "../../components/loading"

import { postData } from "../../common/services/server"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

function CompaniesPanel(props) {
    const windowDimensions = useWindowDimensions();

    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [sort, setSort] = useState({column: "id", dir: SortTypes.ASC});

    useBodyClass(`noscroll`);

    useEffect(
        () => {
            setIsLoading(true);

            postData("companies/data", {
            },
                function(result) {
                    setCompanies(result);
                },
                function(error) {
                    alert("Error loading company data")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        [sort]
    );

    function onAction(action, company) {
        console.log("action", action, company)
        setSelected({mode:action, company});
    }
    
    function onCompanyChanged(company) {
        setCompanies(produce(draft => {
            const index = draft.findIndex(c => c.id == company.id);

            draft[index] = company;
        }));

        setSelected(null);
    }

    function onSortChange(columnKey, sortDir) {
        setSort({ column: columnKey, dir: sortDir })
    }

    return (
        <>
            {
                isLoading 
            ?
                <Loading />
            :    
                <Table
                    rowHeight={50}
                    rowsCount={companies.length}
                    width={windowDimensions.width - dimensions.dataTableWidthOffset}
                    height={windowDimensions.height - dimensions.headerHeight - (props.haveTabs ? dimensions.pageWithTabsPadding : dimensions.pagePadding)*2}
                    headerHeight={35}
                    touchScrollEnabled={true}
                >
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Company
                            </SortHeaderCell>
                        }
                        columnKey="title"
                        fixed={!isMobile}
                        cell={<TextCell data={companies} />}
                        width={200}
                    />
                    <Column
                        header={
                            <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                                Type
                            </SortHeaderCell>
                        }
                        columnKey="type_title"
                        cell={<TextCell data={companies} />}
                        width={100}
                    />
                    <Column
                        header={<Cell>Actions</Cell>}
                        cell={
                            <EditActionsCell 
                                data={companies} 
                                actions={[
                                    {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, permission:Permissions.Companies, permissionAction:Actions.Edit},
                                ]}
                                busy={null}
                                onClick={onAction} 
                            />
                        }
                        align="center"
                        width={75}
                    />
                </Table>
            }
        </>
    );
}

export default CompaniesPanel;