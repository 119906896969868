import React, { useState, useEffect } from "react"
import styled from "styled-components"
import produce from "immer"
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCreditCard, faDotCircle, faShapes, faWrench, faPhone } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { getDay } from 'date-fns'

import TaskDrawer from "../../drawers/drawer-task"
import TaskRow from "../tasks/task-row"

import Loading from "../loading"

import { postData } from "../../common/services/server"
import { getUser } from "../../common/services/auth"
import { removeNulls } from "../../helpers/utility"
import { colors } from "../../settings/settings"

import { enUS } from 'date-fns/locale'
import { DatePickerCalendar } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'

const Panels = styled.div`
    display: flex;
`
const Day = styled.div`
    flex: 1;
    border: 1px solid #ccc;
    margin: 2px;
    align-items: stretch;

    min-height: 290px;
    height: ${props => props.size && props.size == "large" ? "calc(100vh - 170px)": "auto"};

    > h3 {
        font-size: 14px;
        background-color: #c0c0c0;
        text-align: center;
        margin: 0px;
        padding: 10px;
    }
    div.signs, div.jobs, div.orders {
        padding: 5px;

        border: 1px solid #eee;
        padding: 4px;
        font-size: 12px;
        cursor: pointer;
        background-color: white;

        &:hover {
            background-color: #e0e0e0;
        }
    }

    a {
        color: black;

        &:hover {
            text-decoration: none;
        }
    }
`
const Calendar = styled.div`
    flex: 0;
    min-width: 300px;
    padding-left: 10px;

    .highlight {
        background-color: ${colors.lightPurple};
    }
    .weekend {
        background-color: #eee;
    }
`

function CalendarDay(props) {
    const user = getUser();
    
    const [dayDate, setDayDate] = useState(new Date());//moment(new Date()).startOf('week').startOf('day'))
    const [highlightDays, setHighlightDays] = useState([]);
  
    const [schedule, setSchedule] = useState([]);
    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(
        () => {
            postData("calendar/monthsummary", removeNulls({
                user_id: props.userId || null,
                year: moment(new Date()).format("YYYY"),
                month: moment(new Date()).format("M")
            }),
                function(result) {
                    setHighlightDays(result)
                },
                function(error) {
                }
            );
        }, 
        [props.userId]
    );

    useEffect(
        () => {
            setLoading(true);
            
            postData("calendar/data", removeNulls({
                user_id: props.userId || null,
                start_date: moment(dayDate).format("YYYY-MM-DD"),
                end_date: moment(dayDate).format("YYYY-MM-DD"),
                assigned_user_id: user.id
            }),
                function(result) {
                    setSchedule(result);
                },
                function(error) {
                    window.alert("Error loading calendar")
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        [dayDate, props.calendarPrefs, props.userId]
    );

    return (
        <>
            <Panels>
                <Day size={props.size}>
                    {loading ? 
                        <Loading />
                    :
                        <>                    
                            <h3>{moment(dayDate).format("ddd MMM Do")}</h3>
                            <div>
                            {schedule.map(item => (
                                <>
                                    <div key={item.title} className={item.type}>
                                        {item.type == "task" ? 
                                            <TaskRow 
                                                task={item} 
                                                onClick={() => {
                                                    setSelected({task: item})
                                                }}
                                                onTaskChanged={taskData => {
                                                    setSchedule(produce(draft => {
                                                        const index = draft.findIndex(t => t.type == "task" && t.id == taskData.id)
                                                        
                                                        if (index != -1)
                                                            draft[index].status = taskData.status;
                                                    }));
                                                }}
                                            />                                          
                                        :
                                            <Link to={item.url}>
                                                <FontAwesomeIcon icon={item.type == "signs" ? faShapes : item.type == "orders" ? faCreditCard : item.type == "job" ? faWrench : item.type == "call" ? faPhone : faDotCircle} /> {' '}
                                                {item.title}
                                            </Link>
                                        }
                                    </div>
                                </>
                            ))}
                            </div>                  
                        </>
                    }
                </Day>
                <Calendar>
                    <DatePickerCalendar 
                        locale={enUS} 
                        date={dayDate}
                        onDateChange={setDayDate}
                        modifiers={{
                            highlight: date => highlightDays.findIndex(d => d.date == moment(date).format("YYYY-MM-DD")) >= 0, 
                            weekend: date => getDay(date) === 0 || getDay(date) === 6
                        }}
                        modifiersClassNames={{
                            highlight: 'highlight',
                            weekend: 'weekend'
                        }}
                    />
                </Calendar>
            </Panels>

            {(selected && selected.task) && 
                <TaskDrawer 
                    show={true}
                    task={{id: selected.task.url}} 
                    onSaved={(taskData) => {
                        setSchedule(produce(draft => {
                            const index = draft.findIndex(i => i.type=="task" && i.url == taskData.id);
                
                            if (index != -1) {
                                draft[index].title = taskData.task;
                                draft[index].date = taskData.due_date;
                            }
                        }));
                    }} 
                    onDeleted={() => {
                        setSchedule(produce(draft => {
                            const index = draft.findIndex(i => i.type=="task" && i.url == selected.task.url);
                
                            if (index != -1) {
                                draft.splice(index, 1)
                            }
                        }));
                        setSelected(null);
                    }}
                    onHide={ () => setSelected(null) } /> 
            }            
        </>
    );
}

export default CalendarDay