import React from "react";
import styled from 'styled-components'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import Button from "./buttons/button";

const Bar = styled.div`
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    button {
      margin-right: 5px;
    }
    label {
        margin: 0px 10px 0px 0px;
    }
`

function Toolbar(props) {

    return (
        <Bar>
            {props.buttons && props.buttons.map(button => (
            <Button
                key={button.name}
                variant={button.variant}
                disabled={button.disabled}
                size="sm"
                onClick={() => props.onButton(button.name)}
            >
                <FontAwesomeIcon icon={button.busy ? faSpinner : button.icon} spin={button.busy == button.name} />{' '}
                {button.label}
            </Button>
            ))}
            {props.children}
        </Bar>
    );
  }

export default Toolbar