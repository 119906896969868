import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"
import { faPencilAlt, faTrash, faPaperclip, faCheck, faBug, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell, 
    EditActionsCell,
    DateCell,
    LinkCell,
    SortHeaderCell,
    SortTypes,
} 
from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';
import { useHistory } from "react-router-dom";

import Loading from "../../components/loading"
import Toolbar from "../../components/toolbar"
import Paginator from "../../components/paginator"

import EmailDrawer from "../../drawers/drawer-email"

import { postData, postExternalData } from "../../common/services/server"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { Permissions, Actions } from "../../common/services/auth";
import settings from '../../settings'

function EmailQueuePanel(props) {
  const history = useHistory();
  const windowDimensions = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState({column: "scheduled_date", dir: SortTypes.ASC});
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({page:1,pages:1,total:0});
  const [cancel, setCancel] = useState(null);
  const [emails, setEmails] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [selected, setSelected] = useState(null);
  const [busyRow, setBusyRow] = useState(null);

  const haveToolbar = props.buttons && props.buttons.length > 0;

  useBodyClass(`noscroll`);

  useEffect(
    () => {
      if (cancel)
        cancel.cancel();

      setIsLoading(true);

      console.log("email data", props.filters, refresh, sort, page)
      const cancelToken = postData("emails/queue", {
          sort: sort.column,
          sortdir: sort.dir,
          page: page,
          perPage: 100,
          ...props.filters
        },
        function(result) {
            setEmails(result.emails);
            setPagination(result.pagination)
        },
        function(error) {
            alert("Error loading queued emails")
        },
        function() {
          setIsLoading(false);
          setCancel(null);
        }
      );

      setCancel(cancelToken);
    }, 
    [refresh, sort, page]
  );

  function onSortChange(columnKey, sortDir) {
    setSort({ column: columnKey, dir: sortDir })
  }

  function onAction(action, email) {
    console.log("action", action, email)

    if (action == "send") {
      setBusyRow(email);

      postExternalData(settings.SITE_API_URL + "cron/sendemail", {
        email_id: email.id
      },
        function(result) {
          setEmails(produce(draft => {
            const index = draft.findIndex(e => e.id == email.id);
            draft.splice(index, 1);
          }));
        },
        function(error) {
            alert("Error sending email.")
        },
        function() {
          setBusyRow(null)
        }
      );
    }
    else if (action == "delete") {
        setBusyRow(email);
  
        postData("emails/queuedelete", {
          email_id: email.id
        },
          function(result) {
            setEmails(produce(draft => {
              const index = draft.findIndex(e => e.id == email.id);
              draft.splice(index, 1);
            }));
          },
          function(error) {
              alert("Error deleting email.")
          },
          function() {
            setBusyRow(null)
          }
        );
      }
  }

  return (
    <>
      {
          isLoading 
      ?
          <Loading />
      :    
        <>    
          {haveToolbar && 
            <Toolbar buttons={props.buttons} onButton={props.onButton} />
          }
          <Table
              rowHeight={65}
              rowsCount={emails.length}
              width={windowDimensions.width - dimensions.dataTableWidthOffset}
              height={windowDimensions.height - dimensions.dataTableHeightOffset}
              headerHeight={35}
              touchScrollEnabled={true}
          >
              <Column
                  header={<Cell>From</Cell>}
                  columnKey="from"
                  cell={<TextCell data={emails} small />}
                  width={225}
              />
              <Column
                  header={<Cell>To</Cell>}
                  columnKey="to"
                  cell={<TextCell data={emails} small />}
                  width={200}
              />
              <Column
                  header={<Cell>Subject</Cell>}
                  columnKey="subject"
                  cell={<LinkCell data={emails} onClick={(email) => setSelected({mode:"email", email})} />}
                  width={300}
              />
              <Column
                  header={
                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                        Scheduled Date
                    </SortHeaderCell>
                  }
                  columnKey="scheduled_date"
                  cell={<DateCell data={emails} time={false} />}
                  width={165}
              />

              <Column
                  header={<Cell>Actions</Cell>}
                  cell={
                    <EditActionsCell 
                        data={emails} 
                        actions={[
                            {action:"send", title:"Send", variant:"success", icon:faEnvelope, permission:Permissions.EmailsScheduled, permissionAction:Actions.Send},
                            {action:"delete", title:"Delete", variant:"danger", icon:faTrash, permission:Permissions.EmailsScheduled, permissionAction:Actions.Delete},
                        ]}
                        busy={busyRow}
                        onClick={onAction} 
                    />
                  }
                  align="center"
                  width={150}
              />
          </Table>
          <Paginator
            {...pagination}
            item="Email"
            items="Emails"
            onPage={(page) => {
              console.log("page", page)
              setPage(page);
            }}
          />
        </>
      }
      { (selected && selected.mode == "email") && 
        <EmailDrawer 
            show={true}
            email={{...selected.email, id: 0}} 
            onSaved={() => {
              setEmails(produce(draft => {
                const index = draft.findIndex(e => e.id == selected.email.id);

                draft.splice(index, 1);
              }));

              setSelected(null);
            }}
            onHide={ () => setSelected(null) } /> 
      }
    </>
  );
}

export default EmailQueuePanel;