import React from "react";

import BoardSignGroups from "../../components/board/board-signgroups";

function SignGroupsPanel(props) {
    return (
        <BoardSignGroups 
            {...props} 
            hideApprove={true}
            onSignApproved={props.onSignApproved}
        />
    );
}

export default SignGroupsPanel;