import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"

import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup'

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faPlus, faUser } from '@fortawesome/free-solid-svg-icons'

import PropertyList from "../components/properties/property-list"
import Property from "../components/properties/property"
import SignSelect from "../components/fields/sign-select"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import GoogleImage from "../components/google-image"
import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'

const Sign = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;

    > div {
        margin: 0px auto;
    }
`

function AddSignDrawer(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [sign, setSign] = useState(null)

  useEffect(
    () => {
    }, 
    [props.item]
  );

  function onSave() {
      setIsProcessing(true);

      let url = "";
      let params = { sign_id: sign.id };

      if (props.project) {
          url = "projects/addsign";
          params.project_id = props.project.id;
      }
      else if (props.order) {
        url = "orders/addsign";
        params.order_id = props.order.id;
      }

      postData(url, params, 
        function(response) {
          props.onAdd(response, sign);
        },
        function(error) {
          alert("An error occured adding the sign.");
        },
        function() {
          setIsProcessing(false);
        }
      );
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;
            Add Sign
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <InputGroup className="mb-2">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        Select Sign
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <SignSelect
                    name="sign_id"
                    onChange={(sign) => {
                        console.log(sign)
                        setSign(sign);
                    }}
                />
            </InputGroup>

            {sign ? 
                <>
                    <Sign>
                        <GoogleImage 
                            root 
                            src={sign.preview_url} 
                            style={{
                                maxWidth: "125px",
                                maxHeight: "75px"
                            }}
                        />
                    </Sign>
                    
                    <PropertyList>
                        <Property label="Design #" value={sign.id} />
                        <Property label="User" value={sign.user_name || "Anonymous"} icon={faUser} url={sign.user_id ? "/users/user/" + sign.user_id : null} />
                        <Property label="Title" value={sign.subtitle} />
                        <Property label="Description" value={sign.description} html={true} />
                        <Property label="Cost" value={sign.estimate} />
                        {sign.quote_id && <Property label="Project" value={"#" + sign.quote_id} url={"/projects/project/" + sign.quote_id} />}
                    </PropertyList>
                </>
              :
                <div style={{color:"red"}}>
                  Please select a sign to add.
                </div>
            } 
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                disabled={!sign}
                caption="Add Sign" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default AddSignDrawer;