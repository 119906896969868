import React, { useState, useEffect } from "react";
import produce from "immer"
import Modal from 'react-bootstrap/Modal';
import ListField from "../components/fields/field-list"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBorderAll } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"
import Button from "../components/buttons/button";

import { postData } from "../common/services/server"
import MwoSticker from "../components/mwo-sticker";

function SelectContentDrawer({
    label,
    products,
    parts,
    existingMWOs,
    onSelect, 
    onHide
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [mwos, setMwos] = useState([]);

  const [product, setProduct] = useState(null);
  const [mwo, setMwo] = useState(null);

  useEffect(() => {
      setIsProcessing(true);

      postData("mwos/data", {}, 
        function(results) {
            console.log(results)
            setMwos(results.map(r => {
                const mwoPartIds = (r.parts || "").split(",");

                let partIds = [];

                for (const mwoPartId of mwoPartIds) {
                    const part = parts.find(p => p.id == mwoPartId);

                    if (part)
                        partIds.push(part.id);
                }

                return {
                    ...r,
                    part_ids: partIds
                }
            }));
        },
        function(error) {
          alert("An error occured loading the micro work orders.");
        },
        function() {
          setIsProcessing(false);
        }
      );   
  }, []);

  return (
    <>
        <Modal show={true} onHide={onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faBorderAll} />
                &nbsp;
                {label || "Select"} MWO
            </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minWidth: "800px"}}>
                { isProcessing ? 
                    <Loading />
                :
                mwos.length == 0 ?
                    <p style={{color:"red"}}>No MWOs found.</p>
                :
                    <div>
                        <ListField 
                            name="product" 
                            label="Product" 
                            value={product ? product.id : null} 
                            list={products}
                            onChange={(e) => {
                                setProduct(products.find(p => p.id == e.target.value));
                                setMwo(null);
                            }} 
                        />                    
                        <ListField 
                            name="mwo" 
                            label="MWO" 
                            value={mwo ? mwo.id : null} 
                            list={mwos.filter(m => (!product && m.product_id == null) ||  (product && m.product_id == product.id))}
                            onChange={(e) => {
                                setMwo(mwos.find(m => m.id == e.target.value));
                            }} 
                        />
                        {mwo && 
                            <div>
                                <MwoSticker mwo={mwo} />

                                {(existingMWOs.find(m => m.title == mwo.title) != null) ?
                                    <p style={{color: "red"}}>
                                        This product already has a <strong>{mwo.title}</strong> MWO sticker.
                                    </p>
                                :
                                    <ListField 
                                        type="checkbox"
                                        direction="vertical"
                                        name="part_ids" 
                                        label="MWO Parts" 
                                        instructions="The parts on the product that are used for the MWO data."
                                        value={mwo.part_ids} 
                                        list={parts.map(p => {
                                            return {
                                                value: p.id, 
                                                label: p.title
                                            }
                                        })}
                                        onChange={(e) => {
                                            setMwo(produce(draft => {
                                                draft.part_ids = e.target.value;
                                            }))
                                        }} 
                                    />                                
                                }
                            </div>
                        }
                    </div>
                }
            </Modal.Body>
            <Modal.Footer style={{display: "flex", justifyContent: "space-between"}}>
                <Button 
                    variant="outline-success" 
                    disabled={!mwo || existingMWOs.find(m => m.title == mwo.title)}
                    onClick={() => onSelect(mwo)}
                >
                    {label || "Select"} MWO
                </Button>

                <Button variant="outline-secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>         
    </>
    )
}

export default SelectContentDrawer;