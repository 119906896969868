import { useQuery } from '@tanstack/react-query';
import { query } from '../api';

export const useGetEmails = (params, options={}) => {
  return useQuery({ 
    queryKey: ["emails", params], 
    queryFn: ({signal}) => query("emails/list", params, signal),
    // TODO: Remove after other email queries are updated to reactQuery
    staleTime: 0,
    cacheTime: 0,    
    ...options    
  });
}
