import React, { useEffect, useState } from "react";
import produce from "immer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faPlus, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import ProductsDrawer from "../drawers/drawer-selectproducts"
import PanelDescription from "./panel-description"
import PanelTable from "./panel-table"

import { postData } from "../common/services/server"

function Panel({
  product,
  onChange
}) {
  const [busy, setBusy] = useState("");
  const [related, setRelated] = useState([]);

  const [selectProduct, setSelectProduct] = useState(false);

  useEffect(() => {
      setRelated(product.related);     
  }, []);

  useEffect(() => {
    if (onChange) {
        onChange({related})
    }  
  }, [related]);

  function onRemoveProduct(prod) {
    setBusy("remove" + prod.id)

    postData("products/related/toggle", {
            id: product.id,
            product_id: prod.id,
            checked_ind: 0
        }, 
        function(response) {
            setRelated(produce(draft => {
                const idx = draft.findIndex(p => p.id == prod.id);

                if (idx != -1) {
                    draft.splice(idx, 1);
                }
            }));
        },
        function(error) {
            alert("An error occured removing the product.");
        },
        function() {
            setBusy("")
        }
    );
  }

  return (
    <>
        <PanelDescription>
            Related products are products that <strong>{product.title}</strong> can be converted to in the Builder.
        </PanelDescription>

        { related.length == 0 ?
            <p>No related products found.</p>
        :
            <PanelTable>
                <thead>
                    <tr>
                        <th>Related Product</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {related.map(product => (
                        <tr key={product.id}>
                            <td>
                                {product.title}       
                            </td>
                            <td align="right">
                                <ButtonProcessing
                                    icon={faBan}
                                    size="sm"
                                    variant="outline-danger"
                                    processing={busy == ("remove"+product.id)}
                                    onClick={() => {
                                        onRemoveProduct(product);
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </PanelTable> 
        }

        <div style={{display: "flex", gap: "5px", marginTop: "10px"}}>
            <Button
                variant="outline-primary"
                size="sm"
                disabled={busy=="adding"}
                onClick={() => setSelectProduct(true)}                
            >
                <FontAwesomeIcon icon={busy=="adding" ? faSpinner:faPlus} spin={busy=="adding"} />
                &nbsp;
                Add Product
            </Button>
        </div>

        { selectProduct && 
            <ProductsDrawer 
                product={product}
                existingProducts={related} 
                onSelect={(newProducts) => {
                    console.log(newProducts)
                    if (newProducts.length > 0) {
                        setBusy("adding");

                        postData("products/related/toggle", {
                                id: product.id,
                                product_ids: newProducts.map(p => p.id).join(","),
                                checked_ind: 1
                            }, 
                            function() {
                                setRelated(produce(draft => {
                                    for (const newProduct of newProducts) {
                                        draft.push(newProduct);
                                    }
                                }))
                            },
                            function(error) {
                                alert("An error occured adding the products.");
                            },
                            function() {
                                setBusy("")
                            }
                        );
                    }

                    setSelectProduct(false);
                }} 
                onHide={ () => setSelectProduct(false) } 
            />     
        }      
    </>
  );
}

export default Panel;