import React, { useState, useEffect } from "react"
import styled from "styled-components"
import produce from "immer"
import { Link } from "react-router-dom"

import ChartBox from "../charts/chartbox"
import NoteDrawer from "../../drawers/drawer-note"
import ActionButton from "../buttons/button-action"

import { postData } from "../../common/services/server"
import { faThumbtack, faStickyNote } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Notes = styled.div`
    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            cursor: pointer;

            &:hover {
                color: blue;
            }
        }
    }
`

function PinnedNotes(props) {
    const [notes, setNotes] = useState([]);
    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [error, setError] = useState(null);
    const [busyNote, setBusyNote] = useState(null);

    useEffect(
        () => {
            setLoading(true);

            postData("notes/data", {
                pinned_ind: 1
            },
                function(result) {
                    setNotes(result);
                },
                function(error) {
                    setError("Error loading notes")
                },
                function() {
                    setLoading(false);
                }
            );
        }, 
        [refresh]
    );

    function onUnpin(note) {
        setBusyNote(note)

        postData("notes/pin", {
            note_id: note.id
        },
            function(result) {
                onNoteSaved(result)
            },
            function(error) {
                setError("Error updating notes")
            },
            function() {
                setBusyNote(null);
            }
        );
    }

    function onNoteSaved(note) {
        setSelected(null)

        setNotes(produce(draft => {
            const index = draft.findIndex(n => n.id == note.id);

            if (index != -1) {
                if (note.pinned_ind != 1)
                    draft.splice(index, 1);
                else 
                    draft[index] = note;
            }
        }));
    }

    return (
        <>
            <ChartBox 
                icon={faThumbtack}
                title="Pinned Notes" 
                height={150}
                loading={loading}
                error={error}
                refresh={true}
                onRefresh={() => setRefresh(refresh++)}
            >
                <Notes>
                {notes.map(note => (
                    <div key={note.id}>
                        <div onClick={() => setSelected(note)}>
                            <FontAwesomeIcon icon={faStickyNote} />
                            {' '}
                            {note.notes}
                        </div>

                        <ActionButton
                            active={true}
                            reset="reset"
                            busy={busyNote == note}
                            icon={faThumbtack}
                            variant="primary"
                            size="sm"
                            onClick={() => onUnpin(note)}
                        />
                    </div>
                ))}
                {(!loading && notes.length == 0) && 
                    <p style={{textAlign:"center"}}>
                        No notes found.  View <Link to="/notes">all notes</Link>.
                    </p>
                }
                </Notes>
            </ChartBox>
            {selected && 
                <NoteDrawer 
                    show={true}
                    note={selected} 
                    onSaved={onNoteSaved} 
                    onHide={ () => setSelected(null) } /> 
            }
        </>
    );
}

export default PinnedNotes