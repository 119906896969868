import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faSave } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import TextField from "../components/fields/field-text"
import BooleanField from "../components/fields/field-boolean"
import TextAreaField from "../components/fields/field-textarea"
import FileField from "../components/fields/field-file"
import ListField from "../components/fields/field-list"

import { postData, uploadImage } from "../common/services/server"
import { removeNulls } from '../helpers/utility'
import { colors } from "../settings/settings"

const CheckboxList = styled.div`
    padding: 10px;
    font-size: 14px;
    margin-bottom: 8px;
    max-height: 400px;
    overflow: auto;
`

function ContentDrawer(props) {
  const formRef = useRef(null);

  let blankContent = {
    type: props.type,
    parts: [],
    shippingMethods: [],
  }

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(
    () => {
        let content = props.content;
        let data = {
            ...content,
            product_id: props.product.id,
            parts: [],
            shippingMethods: [],
        }

      if (content) {
        if (content.parts) {
            const partIds = content.parts.split(",")

            for (const partId of partIds) {
                const part = props.parts.find(p => p.id == partId.trim());

                if (part)
                    data.parts.push(""+part.id);
            }
        } 
        if (content.shippingMethiods) {
            const shippingMethiodIds = content.shippingMethiods.split(",")

            for (const shippingMethiodId of shippingMethiodIds) {
                const method = props.shippingMethiods.find(p => p.id == shippingMethiodId.trim());

                if (method)
                    data.shippingMethods.push(""+method.id);
            }
        } 
      } 
      else data = blankContent;

      console.log("content data", data)
      setFormData(JSON.parse(JSON.stringify(data)));
    }, 
    [props.content]
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      const {file, thumbnailData, previewData, ...data} = formData;

      postData("products/content/save?id=" + (props.content.id||0), removeNulls(data), 
        function(response) {
          if (file) {
            uploadImage("content/upload?id=" + response.id, file, thumbnailData, previewData, 
              function(progress) {
                console.log("progress", progress)
              },
              function(response) {
                props.onSaved(response);
              },
              function(error) {
                alert("An error occured uploading the content image.");
              },
              function() {
                setIsProcessing(false);
              }
            );
          }
          else {
            setIsProcessing(false);
            props.onSaved(response);
          }
        },
        function(error) {
          alert("An error occured saving the content.");
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  function onPartChange(e) {
    const part = e.target.value;
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.parts ? draft.parts.indexOf(part) : -1;

        if (index != -1)
            draft.parts.splice(index, 1);
        if (!draft.parts)
            draft.parts = [];
        if (checked)
            draft.parts.push(part);
    }));
  }

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faFile} />
                &nbsp;
                Content
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
                    <FileField 
                        name="file" 
                        label="Content Image" 
                        instructions={
                            (
                            formData.type == "includes" ?
                                "Icon needs to be a SVG file that is already colored orange (" + colors.orange + ")."
                            :
                                "Image needs to be a JPG file that is 800x500."
                            ) + 
                            " No spaces in filename."
                        }
                        onFile={(file) => {
                          console.log("image file", file)
                          setFormData(produce(draft => {
                            draft.file = file;
                          }));
                        }} 
                        onThumbnail={(thumbnailData) => {
                          setFormData(produce(draft => {
                            draft.thumbnailData = thumbnailData;
                          }));
                        }}
                        onPreview={(previewData) => {
                          setFormData(produce(draft => {
                            draft.previewData = previewData;
                          }));
                        }}
                    />

                    <ListField 
                        name="type" 
                        label="Type" 
                        value={formData.type} 
                        list={[
                            {value: "construction", label: "Construction"},
                            {value: "breakdown", label: "Breakdown"},
                            {value: "installation", label: "Installation"},
                            {value: "includes", label: "Includes"},
                            {value: "notincludes", label: "Not Included"},
                        ]}
                        onChange={(e) => handleFormChange(e)} 
                    />

                    <TextField 
                        name="title" 
                        label="Title" 
                        instructions="Needs to be unique.  Used to name the uploaded image file the first time."
                        value={formData.title}                         
                        onChange={(e) => handleFormChange(e)} 
                        uniqueCheckUrl={"content/titlecheck?id=" + (props.content.id||0) + "&type=" + formData.type + "&title="}
                        uniqueMessage="Title has already been used"
                      />
                    <TextAreaField 
                        name="description" 
                        label="Description"
                        rows={10} 
                        value={formData.description} 
                        onChange={(e) => handleFormChange(e)} />

                    <BooleanField 
                      name="optional_ind" 
                      label="Optional" 
                      value={formData.optional_ind} 
                      onChange={(e) => handleFormChange(e)} />


                    {(formData.type == "construction" || formData.type == "includes" || formData.type == "installation") && 
                      <>
                        <label>Parts</label>
                        <CheckboxList>
                        {props.parts.map(part => (
                            <div key={part.id}>
                                <Form.Check 
                                    name="part"                                
                                    defaultValue={part.id}
                                    checked={formData.parts && formData.parts.indexOf(""+part.id) != -1}
                                    inline 
                                    label={part.title} 
                                    type="checkbox" 
                                    onChange={onPartChange}
                                />
                            </div>
                        ))}
                        </CheckboxList>
                      </>
                    }

                    <TextField 
                        name="list_order" 
                        label="Ordering" 
                        value={formData.list_order} 
                        instructions="Number from 0 to 100 that controls the order the content is displayed in a list.  Order defaults to the content title."
                        onChange={(e) => handleFormChange(e)} />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonProcessing 
                    processing={isProcessing}
                    onClick={onSave} 
                    variant="outline-success" 
                    caption="Save Content" 
                    icon={faSave} /> 
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default ContentDrawer;