import React from "react";
import { Button } from "react-bootstrap"

import PanelTable from "./panel-table"
import storage from "../settings/storage";

function Panel({
  parents
}) {

  return (
    <>
      <PanelTable>
        <thead>
            <tr>
              <th>Part Reference</th>
              <th>Type</th>
          </tr>
        </thead>
        <tbody>      
            {(parents || []).map(parent => (
                <tr key={parent.id}>
                    <td>
                      <div style={{display: "grid", gridTemplateColumns: "40px 1fr", gap: "10px"}}>
                        <img 
                            src={storage.root + "2020/" + parent.type + "/" + parent.name + "/icon.jpg?" + (new Date().getTime())} 
                            height="30"
                            onError={(e) => e.target.src = storage.root + "images/nothumbnail.jpg"}
                            style={{ maxHeight: "20px", maxWidth: "40px" }} 
                        />

                        <div>
                            {parent.title}
                        </div>
                      </div>
                    </td>
                    <td>
                      {parent.type_title}
                    </td>
                </tr>
            ))}
        </tbody>
      </PanelTable>  
    </>
  );
}

export default Panel;