import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { formatDateTime } from "../../helpers/utility";

import BoardPill from "./board-pill";
import BoardPills from "./board-pills";

const Note = styled.div`
    margin: 2px 2px 0px 2px;
    padding: 5px;
    background-color: white;
    border: 1px solid #ffc008;
    border-left: 8px solid #ffc008;
    font-size: 10px;
    border-radius: 5px;

    div.info {
        margin-top: 5px;
        color: #666;
    }
`

function BoardNote({
    note,
    onEdit
}) {
    return (
        <Note>
            {onEdit && 
                <FontAwesomeIcon icon={faEdit} onClick={onEdit} style={{cursor: "pointer", float: "right", margin: "3px"}} />
            }
            
            <div style={{marginBottom:"5px"}}>
                {note.notes}
            </div>

            <BoardPills>
                {note.job_id && 
                    <BoardPill label="Job" value={note.job_title} />
                }
                {note.order_id && 
                    <BoardPill label="Order" value={note.order_title} />
                }
                {note.quote_id && 
                    <BoardPill label="Project" value={note.quote_title} />
                }
                {note.sign_id && 
                    <BoardPill label="Sign" value={note.sign_title} />
                }
                {note.task_id && 
                    <BoardPill label="Task" value={note.task_title} />
                }
                {note.user_id && 
                    <BoardPill label="User" value={note.user_title} color="rgba(255,255,255,.5)" />
                }
            </BoardPills>

            <div className="info">
                Added by <strong>{note.added_user_name}</strong> on <strong>{formatDateTime(note.added_date)}</strong>
            </div>
        </Note>
    );
}

export default BoardNote