import React, { useEffect, useState, useRef } from "react";

import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faTicketAlt } from '@fortawesome/free-solid-svg-icons'

import '../mwos.css'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import PanelJobMwos from "../panels/panel-job-mwos";

import { postData } from "../common/services/server"

function SignMwosDrawer(props) {
  const [loading, setLoading] = useState(false);
  const [previewJob, setPreviewJob] = useState(null);
  const [shapes, setShapes] = useState([]);

  useEffect(
    () => {
        setLoading(true);

        postData("jobs/preview", {
            sign_id: props.sign.id
        }, 
            function(result) {
                setPreviewJob(result);
            },
            function(error) {
                alert("An error occured generating the preview.");
            },
            function() {
                setLoading(false)
            }
        );    
    }, 
    []
  );
  useEffect(
    () => {
        if (previewJob) {
            setLoading(true);

            postData("jobs/shapes", {
                id: previewJob.id
            }, 
                function(result) {
                    setShapes(result);
                },
                function(error) {
                    alert("An error occured loading the preview job.");
                },
                function() {
                    setLoading(false)
                }
            );    
        }
    }, 
    [previewJob]
  );

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faTicketAlt} />
            &nbsp;
            MWOs
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { loading ? 
                <Loading />
            :
                <PanelJobMwos
                    job={previewJob}
                    shapes={shapes}
                />
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default SignMwosDrawer;