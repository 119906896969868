import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components"
import { useParams, useLocation } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Badge from 'react-bootstrap/Badge'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faEnvelope, faUser, faUserPlus, faSpinner, faSms, faBan, faPaperclip, faEye, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import FieldLabel from "../components/fields/label"
import TextField from "../components/fields/field-text"
import TextAreaField from "../components/fields/field-textarea"
import SelectField from "../components/fields/field-select"
import ListField from "../components/fields/field-list"
import HtmlField from "../components/fields/field-html"
import HtmlPreviewField from "../components/fields/field-htmlpreview"
import UploadField from "../components/fields/field-filesupload"

import UserSelectDrawer from "../drawers/drawer-userselect"
import FilesSelectrawer from "./drawer-fileselect";

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postExternalData } from "../common/services/server"
import { removeNulls, formatFileSize, isValidPhoneNumber, isValidEmail, getIdInfoFromUrl } from '../helpers/utility'
import { getUser } from "../common/services/auth"
import settings from '../settings'
import Loading from "../components/loading"
import storage from '../settings/storage'

import { formatReplyText, parseOutForwardText, parseOutReplyText } from "../helpers/email"
import { postData } from "../common/services/server"

const Associations = styled.ul`
    margin: 0px 0px 10px 0px;
    padding: 0px;
    display: flex;
    flex-flow: wrap;
    list-style-type: none;
    max-width: 460px;

    li {
      padding: 0px;
      margin: 0px 5px 5px 0px;
      max-width: 225px;

      div.input-group {
        margin: 0px;
      }
      button {
        min-width: 66px;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
      }
    }
`
const Attachments = styled.ul`
    list-style-type: none;
    padding: 0px;
    margin: 5px 0px;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-bottom: 2px;
    }
`

function EmailDrawer(props) {
  const formRef = useRef(null);
  const user = getUser();
  
  let curUserInbox = user.first_name.toLowerCase() + "@signmonkey.com";

  if (user.roles && user.roles.find(r => r.name == "Production Manager"))
    curUserInbox = "production@signmonkey.com";

  let blankEmail = {
      id: 0,
      type: "email",
      template: "simple",
      from: curUserInbox,
      to: "",
      subject: "",
      body: "",
      types: [],
      users: [],
      attachments: [],
      priority: 0
  }

  let location = useLocation();

  const [processing, setProcessing] = useState("");
  
  const [templates, setTemplates] = useState([]);

  const [formData, setFormData] = useState(blankEmail);
  const [attachments, setAttachments] = useState([]);
  const [validated, setValidated] = useState(false);
  const [ccBcc, setCcBcc] = useState(false)

  const [selectUser, setSelectUser] = useState(false);
  const [selectFiles, setSelectFiles] = useState(false);

  const [userData, setUserData] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);

  const [isSearching, setIsSearching] = useState({});
  const [searchResults, setSearchResults] = useState({});
  const [selected, setSelected] = useState({})

  const associationTypes = [
    {name: "signs", column: "sign_id", title: "Sign"},
    {name: "projects", column: "quote_id", title: "Project"},
    {name: "orders", column: "order_id", title: "Order"},
  ]

  useEffect(
    () => {
      let email = Object.assign({}, blankEmail, props.email, {from: curUserInbox});
      
      const info = getIdInfoFromUrl(location.pathname);

      if (info.id && info.type) {
        email[info.type + "_id"] = info.id;
        email[info.type + "_title"] = "#" + info.id;
      }

      console.log("initial email", email)

      if ((!email.to || email.to.length == 0) && (email.user_id || email.sign_id || email.project_id || email.job_id || email.order_id)) {
        setLoadingUser(true)
  
        postData("users/contactfor", removeNulls({
              user_id: email.user_id,
              sign_id: email.sign_id,
              project_id: email.project_id,
              job_id: email.job_id,
              order_id: email.order_id,
              type: formData.type
            }),
            function(result) {
                setFormData(produce(draft => {
                  draft.to = result || "";
                }))
            },
            function(error) {
            },
            function() {
              setLoadingUser(false);
            }
        );  
      }

      // Ignore if we already have a formData.body.  Attempt to fix Grace's weird losing the 
      // email body bug
      if (formData.body == "") {
        if (!email.body || email.body.length == "") {
          let user = getUser();
          
          email.body = `
            Thanks,<br />
            ${user.first_name} ${user.last_name}<br />
            ${user.avatar_url ? `<img src="${user.avatar_url}" width="75" alt="${user.first_name}" /><br />` : ""}
            <br />
            <a href="https://signmonkey.com">SignMonkey.com</a><br />
            800-863-0107<br />
          `
        }
        setFormData(JSON.parse(JSON.stringify(email)));
        setCcBcc((email.cc && email.cc.length) || (email.bcc && email.bcc.length));

        if (email.to && email.to.length) {
          onUserEmail(null, email.to);
        }
      }
    }, 
    [props.email]
  );

  useEffect(
    () => {
      if (props.reply) {
        let email = Object.assign({}, blankEmail, props.reply, {
          complete_ind: 0, 
          reply_email_id: props.reply.id, 
          from: curUserInbox, 
          to: props.reply ? props.reply.from : "",
          cc: props.reply ? props.reply.cc : "",
        });

        if (email.subject && email.subject.length && email.subject.toLowerCase().indexOf("re") != 0)
          email.subject = "Re: " + email.subject;

        email.body = formatReplyText(email.body, getUser());

        console.log("reply email", email)

        setFormData(JSON.parse(JSON.stringify(email)));
        setCcBcc((email.cc && email.cc.length) || (email.bcc && email.bcc.length));
      }
    }, 
    [props.reply]
  );

  useEffect(
    () => {
      if (props.forward) {
        let email = Object.assign({}, blankEmail, props.forward, {
          complete_ind: 0, 
          from: curUserInbox, 
          to: "",
          forward_email_id: props.forward.id, 
        });

        if (email.subject && email.subject.length && email.subject.toLowerCase().indexOf("re") != 0)
          email.subject = "Re: " + email.subject;

        email.body = parseOutForwardText(email.body);

        setFormData(JSON.parse(JSON.stringify(email)));

        console.log("forward email data", email)
        // setProcessing("body");

        // postData("emails/getforwardtext", {
        //   email_id: props.forward.id
        // }, 
        //   function(response) {
        //     let email = Object.assign({}, blankEmail, props.forward, {complete_ind: 0, from: curUserInbox, to: ""});

        //     if (email.subject && email.subject.length && email.subject.toLowerCase().indexOf("re") != 0)
        //       email.subject = "Re: " + email.subject;
    
        //     email.body = response;

        //     setFormData(JSON.parse(JSON.stringify(email)));
        //   },
        //   function(error) {
        //     alert("An error occured processing the email.");
        //   },
        //   function() {
        //     setProcessing("");
        //   }
        // );
      }
    }, 
    [props.forward]
  );

  useEffect(
    () => {
      console.log("bodyUrl", props.bodyUrl)
      if (props.bodyUrl && props.bodyUrl.length) {
        setProcessing("body");

        postExternalData(settings.SITE_API_URL + props.bodyUrl, {}, 
          function(response) {
            console.log(response)
            setFormData(produce(draft => {
              if (draft.type == "sms" || (props.email && props.email.type == "sms"))
                draft.sms = response;
              else
                draft.body = response;
            }));
          },
          function(error) {
            alert("An error occured loading the template.");
          },
          function() {
            setProcessing("");
          }
        );
      }
    }, 
    [props.bodyUrl]
  );

  useEffect(
    () => {
      const index = templates.findIndex(t => t.name == formData.template);

      if (index != -1) {
        setFormData(produce(draft => {
          draft.subject = templates[index].subject;
          draft.preview = templates[index].preview;
          draft.cc = templates[index].cc;
          draft.bcc = templates[index].bcc;
          if (templates[index].body)
            draft.body = templates[index].body;
          if (templates[index].sms)
            draft.sms = templates[index].sms;
        }));
      }
    }, 
    [formData.template]
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setProcessing("send");

      const data = {...formData};

      console.log("email data", removeNulls(data))
      postExternalData(settings.SITE_API_URL + "emails/send", removeNulls(data), 
        function(response) {
          props.onSaved(response, {completed: data.complete_ind == 1});
        },
        function(error) {
          alert("An error occured sending the email.");
        },
        function() {
          setProcessing("");
        }
      );
    }
  }

  function onPreview() {
    var winName='EmailPreviewWindow';
    var params = {...formData};      
    var form = document.createElement("form");

    form.setAttribute("method", "post");
    form.setAttribute("action", settings.SITE_API_URL + "emails/preview");
    form.setAttribute("target", winName);  

    console.log("email preview data")
    for (var i in params) {
      if (params.hasOwnProperty(i)) {
        console.log(i, params[i]);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = i;
        input.value = params[i];
        form.appendChild(input);
      }
    }             

    document.body.appendChild(form);                       
    window.open('', winName, "resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1");
    form.target = winName;
    form.submit();                 
    document.body.removeChild(form);      
  }  

  function handleFormChange(event) {
    if (event.persist) event.persist();

    let field = event.target.name;
    let value = event.target.value;

    if (field == "user") {
        field = "to";
        value = event.target.value.email;
    }

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  function handleFileUpload(file) {
    console.log("handleFileUpload", file)
    //console.log("handleFormChange", event.target.name, event.target.value)
    setFormData(produce(draft => {
      console.log("attachments", draft.attachments)
      draft.attachments = draft.attachments + (draft.attachments.length?",":"") + file.id;
    }));
    setAttachments(produce(draft => {
      draft.push(file);
    }))
  }

  function onAddAssociation(type) {
    if (!formData[type.title.toLowerCase() + "_title"]) {
      setFormData(produce(draft => {
        console.log(type.title, draft[type.column], draft[type.title.toLowerCase() + "_title"])

        if (draft[type.column] == 0)
          draft[type.column] = null;
        else
          draft[type.column] = 0;
      }));
    }
  }

  function handleSearch(type, query) {
    setIsSearching(produce(draft => {
      draft[type.name] = true;
    }));

    postData("search/" + type.name, {search: query},
        function(matches) {
            console.log("search", type.name, matches)
            
            setSearchResults(produce(draft => {
              draft[type.name] = matches;
            }));
        },
        function(error) {
            console.log(error);
        },
        function() {
          setIsSearching(produce(draft => {
            draft[type.name] = false;
          }));
        }
    );
  }

  function onAssociationChange(type, result) {
      console.log("onAssociationChange", type, result)
      setFormData(produce(draft => {
        draft[type.column] = result ? result.id : 0;
      }));
  }

  function onUserEmail(e, email) {
    console.log("onUserEmail", email)
    console.log({userData})
    console.log({formData});

    // only do it if the data has changed
    if (!userData || !userData.email || userData.email != (email || formData.to)) {
      setUserData({});
      setLoadingUser(true)
  
      postData("users/userdata", {
            id: -1, 
            email: formData.type == "sms" || (props.email && props.email.type == "sms") ? null : email || formData.to,
            phoneno: formData.type == "sms" || (props.email && props.email.type == "sms") ? email || formData.to : null,
          },
          function(result) {
              setUserData(result)

              if (formData.type == "sms") {
                setFormData(produce(draft => {
                  draft.to = result.phoneno;
                }))
              }
          },
          function(error) {
          },
          function() {
            setLoadingUser(false);
          }
      );     
    }
  }

  return (
    <>
      <Modal show={props.show} enforceFocus={false} onHide={props.onHide} size="lg" backdrop="static" keyboard={false} scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={formData.type == "sms" ? faSms:faEnvelope} />
            &nbsp;
            {props.email && props.email.id ? "Update":"Create"} {formData.type == "sms" ? "Message":"Email"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef} style={{maxWidth:"600px"}} className="grid gap-2">
            {false && <ListField 
                name="template" 
                label="Template" 
                value={formData.template} 
                list={[
                  {value: "simple", label: "Simple"},
                  {value: "marketing", label: "Marketing"},
                ]}
                onChange={(e) => handleFormChange(e)} />
            }
            <SelectField 
                name="template" 
                label="Template" 
                required 
                url={"emails/templates?type=manual&status=Active"}   
                onData={(templates) => setTemplates(templates)}         
                labelField="name"
                idField="name"
                value={formData.template} 
                onChange={(e) => handleFormChange(e)} 
                style={{textTransform: "capitalize"}}    
            />  

            <SelectField 
                name="from" 
                label="From" 
                required 
                url={"emails/inboxes?current_user_id=" + getUser().id}            
                labelField="name"
                value={formData.from} 
                disabled={formData.type == "sms"}
                onChange={(e) => handleFormChange(e)} />  

            <div>
              <div className="flex items-center justify-between">
                <FieldLabel required>
                  To {formData.type == "sms" ? "Phone Number" : "Email"}
                </FieldLabel>
                <Button onClick={() => setCcBcc(true)} variant="outline-secondary" size="tiny">
                    CC / BCC
                </Button>
              </div>

              {props.userIds ?
                <SelectField 
                  name="to" 
                  required 
                  url={"emails/addresses?user_ids=" + props.userIds}
                  idField="email"
                  labelField="email"
                  value={formData.to} 
                  disabled={props.reply}
                  onChange={(e) => handleFormChange(e)} 
                  onBlur={onUserEmail}
                />
              :
                <InputGroup>
                    <FormControl
                      name="to"
                      required
                      disabled={props.reply}
                      value={formData.to} 
                      isInvalid={formData.type == "sms" ? !isValidPhoneNumber(formData.to) : !isValidEmail(formData.to)}
                      onChange={handleFormChange} 
                      onBlur={onUserEmail}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          onUserEmail(e, formData.to);
                        }
                      }}                  
                    />              
                    <InputGroup.Append>
                        <Button onClick={() => setSelectUser(true)} size="sm">
                          <FontAwesomeIcon icon={faUser} />{' '}
                          Select User
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
              }
            </div>

            {userData && 
              <div>
                <small>
                {loadingUser ? 
                  <div><FontAwesomeIcon icon={faSpinner} spin /> Loading user data...</div>
                : userData ?
                  <>
                    {userData.emailstats &&
                      <div>
                        <FontAwesomeIcon icon={faEnvelope} />
                        &nbsp;
                        <Badge pill variant="secondary">{userData.emailstats.sent_count} Sent</Badge>
                        <Badge pill variant={userData.emailstats.opened_count > 0 ? "success":"secondary"}>{userData.emailstats.opened_count} Opened</Badge>
                        <Badge pill variant={userData.emailstats.error_count > 0 ? "danger":"secondary"}>{userData.emailstats.error_count} Errors</Badge>

                        {userData.email_ind == 0 && 
                          <Badge variant="danger">Unsubscribed</Badge>
                        }
                      </div>  
                    }
                    {userData.smsstats &&
                      <div>
                        <FontAwesomeIcon icon={faSms} />
                        &nbsp;
                        <Badge pill variant="secondary">{userData.smsstats.sent_count} Sent</Badge>
                        <Badge pill variant="secondary">{userData.smsstats.received_count} Received</Badge>

                        {userData.text_ind == 0 && 
                          <Badge variant="danger">STOP</Badge>
                        }
                      </div>  
                    }                    
                  </>             
                :
                  <div>No data found</div>   
                }
                </small>
              </div>
            }
            {ccBcc && 
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text style={{width:"60px"}}>
                    CC
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  name="cc"
                  value={formData.cc} 
                  onChange={(e) => handleFormChange(e)} 
                />        
              </InputGroup>      
            }
            {ccBcc && 
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text style={{width:"60px"}}>
                    BCC
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  name="bcc"
                  value={formData.bcc} 
                  onChange={(e) => handleFormChange(e)} 
                />      
              </InputGroup>        
            }
            {/* {users && 
              <UserField 
                  name="user" 
                  label="User" 
                  labelBold={true}
                  required
                  value={formData.user} 
                  onChange={(e) => handleFormChange(e)} />
            } */}

            <h6>
              Associations
              <strong style={{color:"red"}}>*</strong>
            </h6>

            <Associations>
              {associationTypes.map(type => (
              <li key={type.name}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <Button onClick={() => onAddAssociation(type)} variant="outline-secondary" size="sm">
                      {type.title}
                    </Button>
                  </InputGroup.Prepend>
                  { formData[type.column] != null && 
                    <AsyncTypeahead
                        id={"email_"+type.name+"_search"}
                        options={searchResults[type.name]}
                        useCache={false}
                        allowNew={false}
                        isLoading={isSearching[type.name] || false}
                        labelKey="title"
                        minLength={2} 
                        defaultInputValue={formData[type.title.toLowerCase() + "_title"] || ""}
                        onSearch={(query) => handleSearch(type, query)}
                        onChange={ (selected) => onAssociationChange(type, selected[0]) }
                        placeholder="Search..."
                        searchText={<><FontAwesomeIcon icon={faSpinner} spin />&nbsp;Searching...</>}
                        renderMenuItemChildren={(option, props) => (
                            <div key={option.id} style={{display: "flex"}}>
                              <img src={storage.root + (option.preview_url || "images/nothumbnail.png")} width={40} style={{minWidth:"40px", marginRight:"8px"}} />
                              <div>
                                { option.title }
                              </div>
                            </div>
                        )}
                        filterBy={(option, props) => {
                          return true;
                        }}
                    />
                  }
                </InputGroup>
              </li>
              ))}
            </Associations>

            <div className="mb-2">
              {attachments.length > 0 &&
                <>
                  <div>Attachments</div>
                  <Attachments>
                    {attachments.map(attachment => (
                      <li key={attachment.id}>
                        <div>
                          <FontAwesomeIcon icon={faPaperclip} />{' '}
                          <a href={storage.root + attachment.version_url} target="_blank">
                            {attachment.filename}
                          </a>
                          {' '}
                          ({formatFileSize(attachment.filesize)})
                        </div>
                        <Button size="sm" variant="outline-secondary" onClick={() => {
                              setAttachments(produce(draft => {
                                const index = draft.findIndex(f => f.id == attachment.id);

                                if (index != -1)
                                  draft.splice(index, 1);
                              }))
                        }}>
                          <FontAwesomeIcon icon={faBan} />
                        </Button>
                      </li>
                    ))}
                  </Attachments>
                </>
              }

              {formData.type == "sms" && 
                <p style={{color: "red"}}>
                  Files sent by text message shouldn't be larger than 600K.
                </p>
              }

              <div style={{display:"flex", gap: "10px"}}>
                <UploadField
                  name="attachments"
                  variant="simple"
                  external
                  uploadUrl="emails/upload"
                  autoClear
                  onUpload={(file) => handleFileUpload(file)}
                />
                {(userData && userData.id) && 
                  <Button 
                    variant="outline-secondary"
                    style={{
                      padding: "0px 10px"
                    }}
                    onClick={() => setSelectFiles(true)}
                  >
                    Attach Existing File
                  </Button>
                }
              </div>
            </div>

            <div>
            <Tabs 
              defaultActiveKey="email" 
              activeKey={formData.type}
              onSelect={(tab, e) => {
                console.log("tab", tab)

                setFormData(produce(draft => {                  
                  draft.type = tab;

                  if (tab == "email") {
                    draft.sms = "";

                    if (userData)
                      draft.to = userData.email;

                  }
                  else if (tab == "sms") {
                    draft.body = "";
                    draft.subject = "Text Message"
                    draft.preview = "";
                    draft.cc = "";
                    draft.bcc = "";
                    
                    console.log({userData});

                    if (userData)
                      draft.to = userData.phoneno;

                    setCcBcc(false);
                  }
                }));                
              }}
            >
              <Tab eventKey="email" title="Email" className="bordered">
                <TextField 
                    name="subject" 
                    label="Subject" 
                    labelBold={true}
                    required={formData.type != "sms"}
                    value={formData.subject || ""} 
                    onChange={(e) => handleFormChange(e)} />

                <TextField 
                    name="preview" 
                    label="Preview" 
                    instructions="Short message displayed as the preview for the email."
                    labelBold={true}
                    value={formData.preview || ""} 
                    onChange={(e) => handleFormChange(e)} />

                {processing == "body" ? 
                  <Loading size="sm" />
                :
                  <HtmlField 
                      name="body" 
                      label="Body" 
                      labelBold={true}
                      required={formData.type != "sms"}
                      value={formData.body} 
                      sectionData={{
                        user_email: formData.to
                      }}
                      onChange={(e) => handleFormChange(e)} />
                }

                {props.reply && 
                  <HtmlPreviewField 
                    name="reply" 
                    label="Reply Email" 
                    labelBold={true}
                    value={parseOutReplyText(props.reply.body)} />
                }
              </Tab>
              <Tab eventKey="sms" title="Text Message" className="bordered">
                {(userData && userData.text_ind == 0) && 
                  <div style={{color: "red"}}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
                    This user/phone number has requested to <strong>STOP</strong> receiving text messages
                  </div>
                }

                {processing == "body" ? 
                  <Loading size="sm" />
                :                          
                  <TextAreaField 
                    name="sms" 
                    label="Message" 
                    labelBold={true}
                    required={formData.type == "sms"}
                    value={formData.sms || ""} 
                    onChange={(e) => handleFormChange(e)} />
                }
              </Tab>
            </Tabs>
            </div>

          </Form>
        </Modal.Body>
        <Modal.Footer>
            {props.reply && 
              <div>
                <Form.Check
                  inline
                  label="Mark Complete"
                  type="checkbox"
                  value="1"
                  checked={formData.complete_ind == 1}
                  onChange={(e) => {
                    e.persist();

                    setFormData(produce(draft => {
                      draft.complete_ind = e.target.checked ? 1 : 0
                    }));
                  }}
                />
              </div>
            }
            <ButtonProcessing 
                processing={processing == "send"}
                onClick={onSave} 
                variant="outline-success" 
                caption={formData.type == "sms" ? "Send Message" : "Send Email"}
                disabled={!formData.to || (formData.type == "email" && !formData.subject) || (formData.type == "sms" ? !isValidPhoneNumber(formData.to) : !isValidEmail(formData.to))}                
                icon={faSave} /> 
            {(formData.type != "sms") && 
              <Button variant="outline-secondary" onClick={onPreview}>
                <FontAwesomeIcon icon={faEye} />{' '}
                Preview
              </Button>
            }
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>

      {selectUser && 
        <UserSelectDrawer
            show={true}
            title="Select Email Receipient"
            actions={[
                {
                    label: "Send To User",
                    icon: faUserPlus,
                    variant: "success",
                    onClick: (user) => {
                      setFormData(produce(draft => {
                        draft.to = user.email;
                      }))
                      setSelectUser(false);
                      setUserData(user);
                    }
                }
            ]}
            onHide={() => setSelectUser(false)}
        />
      }
      {selectFiles && 
        <FilesSelectrawer
            show={true}
            title="Select File To Attach"
            filters={{
              user_id: userData.id
            }}
            onFile={(file) => {
              handleFileUpload(file);
            }}
            onHide={() => setSelectFiles(false)}
        />
      }          
    </>
  )
}

export default EmailDrawer;