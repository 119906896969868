import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"
import styled from "styled-components"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faClipboardList, faCaretUp, faCaretDown, faPalette, faLightbulb } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import ColorBox from '../components/color-box'
import Loading from "../components/loading"
import { postData } from "../common/services/server"

function LedSelectDrawer({
    onLed,
    onHide
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [leds, setLeds] = useState([]);
    const [expanded, setExpanded] = useState("Standard");

    useEffect(
        () => {
            setIsLoading(true);

            postData("colors/leds", {},
                function(result) {
                    setLeds(result);
                },
                function(error) {
                    alert("An error occured while loading the leds.");
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        []
    );

    function onLedSelected(led) {
        console.log("onLedSelected", led)
        onLed(led);
    }

  return (
      <Modal show={true} onHide={onHide} scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faLightbulb} />
            &nbsp;
            LED Selector
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { isLoading ?
                <Loading />
            :    
              <>
              {leds.map(led => (
                  <div 
                    key={led.id} 
                    onClick={() => onLedSelected(led)}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer"
                    }}
                  >
                      <ColorBox
                        led 
                        color={led}
                      />
                      {led.name}
                      {led.watts && 
                        <small className="text-gray-500 ml-2">
                            ({led.watts} watts)
                        </small>
                      }
                  </div>
              ))}
              </>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default LedSelectDrawer;