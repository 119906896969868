import React from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import Button from './button'

function ButtonAction(props) {
    const disabled = props.disabled || (props.active && !props.reset);

    return (
        <Button 
            disabled={disabled}
            onClick={ () => props.onClick(props.active && props.reset ? props.reset:props.action) } 
            variant={props.busy || disabled ? "outline-light" : props.active ? props.variant : "outline-"+props.variant}
            size={props.size || "md"}
            style={props.style}
            className={props.className}
        >
            {
                <FontAwesomeIcon icon={props.busy ? faSpinner : props.icon} spin={props.busy} />
            }
            {props.caption && " " + props.caption}
        </Button>
    );
}

export default ButtonAction