import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"
import { faSms, faEnvelope, faTrash, faPaperclip, faUser, faCheck, faBug, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell, 
    EditActionsCell,
    DateCell,
    LinkCell,
    FilterHeaderCell,
    SortHeaderCell,
    SortTypes,
} 
from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';
import { useHistory } from "react-router-dom";

import Loading from "../../components/loading"
import Toolbar from "../../components/toolbar"
import Paginator from "../../components/paginator"
import TextField from "../../components/fields/field-text"
import ListField from "../../components/fields/field-list"

import EmailDrawer from "../../drawers/drawer-email"
import EmailViewDrawer from "../../drawers/drawer-viewemail"
import TaskDrawer from "../../drawers/drawer-task"

import { postData } from "../../common/services/server"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions, colors } from "../../settings/settings"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";
import { removeNulls } from "../../helpers/utility";

const Label = styled.span`
  display: inline-block;
  background-color: #ccc;
  border-radius: 4px;
  margin: 0px 1px 1px 0px;
  padding: 2px 8px;
  font-size: 80%;

  &.sent {
    background-color: #e7f7e7;
  }
  &.delivered {
    background-color: #c4f5c4;
  }
  &.bounced {
    background-color: #dc3545;
    color: white;
  }
  &.dropped {
    background-color: #dc3545;
    color: white;
  }
  &.clicked {
    background-color: #338e33;
    color: white;
  }
  &.opened {
    background-color: #5ebb5e;
    color: white;
  }
`

function parseEmailFrom(str) {
  // 1. John Miranda <john@gmail.com>
  // 2. john@gmail.com
  var extract = { name: "", email: "" };
  var emails = str.match(/[^@<\s]+@[^@\s>]+/g);

  if (emails) {
    extract.email = emails[0];
  }

  var names = str.split(/\s+/);

  if (names.length > 1) {
    names.pop();
    extract.name = names.join(" ").replace(/"/g, "");
  }

  //console.log(str, extract);
  return extract.email || str;// "unknown";
}
function parseEmailTo(str) {
  if (str.indexOf(",") != -1) {
    let emails = str.split(",");
    let list = "";

    for (const email of emails) {
      list = list + parseEmailFrom(email) + "<br />";
    }

    return list;
  }
  else return parseEmailFrom(str);
}

function EmailsPanel(props) {
  const history = useHistory();
  const windowDimensions = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState({column: "added_date", dir: SortTypes.DESC});
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({page:1,pages:1,total:0});
  const [cancel, setCancel] = useState(null);
  const [emails, setEmails] = useState([]);
  const [filters, setFilters] = useState({});
  const [selected, setSelected] = useState(null);
  const [busyRow, setBusyRow] = useState(null);

  const haveToolbar = props.buttons && props.buttons.length > 0;

  useBodyClass(`noscroll`);

  function loadEmails(filterOverrides={}) {
    console.log("loading email data", sort, page, filters)

    if (cancel)
      cancel.cancel();

    setIsLoading(true);

    const cancelToken = postData("emails/data" + (props.mode ? "/" + props.mode : ""), removeNulls({
        sort: sort.column,
        sortdir: sort.dir,
        page: page,
        perPage: 100,
        ...props.filters,
        ...filters,
        ...filterOverrides
      }),
      function(result) {
          setEmails(result.emails);
          setPagination(result.pagination)
      },
      function(error) {
          alert("Error loading emails")
      },
      function() {
        setIsLoading(false);
        setCancel(null);
      }
    );

    setCancel(cancelToken);    
  }

  useEffect(
    () => {
      console.log("filters, sort or page changes, loading emails", sort, page, props.filters);   
      loadEmails();
    }, 
    [props.filters, sort, page]
  );

  function onSortChange(columnKey, sortDir) {
    setSort({ column: columnKey, dir: sortDir })
  }

  function onAction(action, email) {
    console.log("action", action, email)

    if (action == "complete" || action == "spam" || action == "restore") {
      setBusyRow(email);

      postData("emails/" + action, {
        email_id: email.id
      },
        function(result) {
          setEmails(produce(draft => {
            const index = draft.findIndex(e => e.id == email.id);
            if (index != -1)
              draft[index] = result
          }));
        },
        function(error) {
            alert("Error updating email.")
        },
        function() {
          setBusyRow(null)
        }
      );
    }
    else if (action == "delete") {
      setBusyRow(email);

      postData("emails/delete", {
        email_id: email.id
      },
        function(result) {
          setEmails(produce(draft => {
            const index = draft.findIndex(e => e.id == email.id);
            draft.splice(index, 1);
          }));
        },
        function(error) {
            alert("Error deleting email.")
        },
        function() {
          setBusyRow(null)
        }
      );
    }
  }

  function onFilter(name, value) {
    console.log("onFilter", name, value)
    setFilters(produce(draft => {
      draft[name] = value;
    }));
  }

  return (
    <>
      {
          isLoading 
      ?
          <Loading />
      :    
        <>    
          {haveToolbar && 
            <Toolbar buttons={props.buttons} onButton={props.onButton} />
          }
          <Table
              rowHeight={75}
              rowsCount={emails.length}
              width={windowDimensions.width - dimensions.dataTableWidthOffset}
              height={windowDimensions.height - dimensions.dataTableHeightOffset - (haveToolbar ? 40:0)}
              headerHeight={70}
              touchScrollEnabled={true}
          >           
              <Column
                  header={<Cell>
                      Subject
                      <TextField 
                            name="subject" 
                            value={filters.subject} 
                            onChange={(e) => onFilter("subject", e.target.value)} 
                            onEnter={(e) => loadEmails()} 
                            groupClassName="nomargin"
                            size="sm"
                      />
                  </Cell>}
                  fixed={!isMobile}
                  columnKey="subject"
                  cell={
                    <LinkCell 
                      data={emails} 
                      onClick={(email) => setSelected({mode:"email", email})} 
                    />
                  }
                  width={300}
              />            
              <Column
                  header={
                    <Cell>
                        From
                        <TextField 
                            name="from" 
                            value={filters.from} 
                            onChange={(e) => onFilter("from", e.target.value)} 
                            onEnter={(e) => loadEmails()} 
                            groupClassName="nomargin"
                            size="sm"
                        />
                    </Cell>
                  }
                  columnKey="from"
                  cell={({rowIndex, ...props}) => {
                    const email = emails[rowIndex];
                    
                    return (
                      <Cell {...props}>
                        <div>
                          {email.type == "sms" ? 
                            <small>{email.from}</small>
                          :
                            <small>{parseEmailFrom(email.from)}</small>
                          }
                        </div>
                        {email.from_user_name && 
                          <div><small><FontAwesomeIcon icon={faUser} /> {email.from_user_name}</small></div>
                        }
                      </Cell>
                    )}}
                  width={250}
              />
              <Column
                  header={
                    <Cell>
                        To
                        <TextField 
                            name="to" 
                            value={filters.to} 
                            onChange={(e) => onFilter("to", e.target.value)} 
                            onEnter={(e) => loadEmails()} 
                            groupClassName="nomargin"
                            size="sm"
                        />
                    </Cell>
                  }
                  columnKey="to"
                  cell={({rowIndex, ...props}) => {
                    const email = emails[rowIndex];
                    
                    return (
                      <Cell {...props}>
                          <div><small dangerouslySetInnerHTML={{__html: parseEmailTo(email.to)}} /></div>
                          {email.to_user_name && 
                            <div><small><FontAwesomeIcon icon={faUser} /> {email.to_user_name}</small></div>
                          }
                      </Cell>
                    )}}
                  width={250}
              />
              <Column
                  header={<Cell><FontAwesomeIcon icon={faPaperclip} /></Cell>}
                  cell={({rowIndex, ...props}) => {
                    const email = emails[rowIndex];
                    
                    return (
                      <Cell {...props}>
                          {email.attachment_count > 0 && 
                              <>{email.attachment_count}</>
                          }
                      </Cell>
                    )}}
                  width={35}
              />
              <Column
                  header={<Cell>Replies</Cell>}
                  cell={({rowIndex, ...props}) => {
                    const email = emails[rowIndex];
                    
                    return (
                      <Cell {...props}>
                          {email.reply_count > 0 && 
                              <>{email.reply_count}</>
                          }
                      </Cell>
                    )}}
                  width={75}
              />
              <Column
                  header={
                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                        Date
                    </SortHeaderCell>
                  }
                  columnKey="added_date"
                  cell={<DateCell data={emails} time={true} />}
                  width={135}
              />
{/* 
              <Column
                  header={
                    <Cell>
                        Status
                        {( props.filters.inbox == "all" || props.filters.inbox == "incoming" || props.filters.inbox == "mine") && 
                          <ListField 
                              name="status" 
                              value={filters.status} 
                              list={statusFilters}
                              onChange={(e) => {
                                onFilter("status", e.target.value);
                                loadEmails();
                              }}
                              groupClassName="nomargin"
                              size="sm"
                          />
                        }
                    </Cell>
                  }
                  columnKey="status"
                  cell={<TextCell data={emails} />}
                  width={150}
              /> */}
              <Column
                  header={<Cell>Tracking</Cell>}
                  cell={({rowIndex, ...props}) => {
                    const email = emails[rowIndex];
                    const received = email.to && email.to.toLowerCase().indexOf("signmonkey.com") != -1;
                    const sent = email.from && email.from.toLowerCase().indexOf("signmonkey.com") != -1;

                    return (
                      <Cell {...props}>
                          {received && <Label className="received">Received</Label>}
                          {sent && <Label className="sent">Sent</Label>}
                          {email.delivered_date && <Label className="delivered">Delivered</Label>}
                          {email.bounce_date && <Label className="bounced">Bounced</Label>}
                          {email.dropped_date && <Label className="dropped">Dropped</Label>}
                          {email.open_date && <Label className="opened">Opened</Label>}
                          {email.click_date && <Label className="clicked">Clicked</Label>}
                      </Cell>
                    )}}
                  width={150}
              />
              <Column
                  header={<Cell>Actions</Cell>}
                  cell={
                    <EditActionsCell 
                        data={emails} 
                        actions={[
                            {action:"complete", title:"Complete", variant:"success", icon:faCheck, isVisible: (email) => email.status == "Received" || (props.filters && props.filters.inbox && props.filters.inbox == "unopened"), permission:Permissions.Emails, permissionAction:Actions.Edit},
                            {action:"spam", title:"Spam", variant:"warning", icon:faBug, isVisible: (email) => email.status == "Received", permission:Permissions.Emails, permissionAction:Actions.Edit},
                            {action:"delete", title:"Delete", variant:"danger", icon:faTrash, isVisible: (email) => email.status != "Deleted", permission:Permissions.Emails, permissionAction:Actions.Delete},
                            {action:"restore", title:"Restore", variant:"danger", icon:faPlusCircle, isVisible: (email) => email.status == "Deleted", permission:Permissions.Emails, permissionAction:Actions.Delete},
                        ]}
                        busy={busyRow}
                        onClick={onAction} 
                    />
                  }
                  align="center"
                  width={150}
              />
          </Table>
          <Paginator
            {...pagination}
            item="Email"
            items="Emails"
            onPage={(page) => {
              console.log("page", page)
              setPage(page);
            }}
          />
        </>
      }
      { (selected && selected.mode == "email") && 
        <EmailViewDrawer 
            show={true}
            email={selected.email} 
            onReply={ () => {
              setSelected({mode: "reply", email: selected.email})
            }}
            onForward={ () => {
              setSelected({mode: "forward", email: selected.email})
            }}
            onEmail={(email) => {
              setSelected({mode: "email", email});
            }}
            onUser={(user) => {
              
            }}
            onFollowup={() => {
              setSelected({mode: "email", submode: "createtask", email: selected.email})  
            }}
            onCompleted={ (email) => {
              setEmails(produce(draft => {
                const index = draft.findIndex(x => x.id == selected.email.id);

                if (index != -1)
                  draft[index] = email
              })); 

              setSelected(null);
            }}
            onUpdated={ (email) => {
              setEmails(produce(draft => {
                const index = draft.findIndex(x => x.id == selected.email.id);

                if (index != -1)
                  draft[index] = email
              })); 
            }}
            onDeleted={ () => {
              setEmails(produce(draft => {
                const index = draft.findIndex(x => x.id == selected.email.id);

                if (index != -1)
                  draft.splice(index, 1)
              }));

              setSelected(null);
            }}
            onHide={ () => setSelected(null) } /> 
      }
      { selected && (selected.mode == "reply" || selected.mode == "forward") && 
          <EmailDrawer 
              show={true}
              reply={selected.mode == "reply" ? selected.email : null}
              forward={selected.mode == "forward" ? selected.email : null}
              onSaved={(email, options) => { 
                  if (options.completed) {
                    setEmails(produce(draft => {
                      const index = draft.findIndex(x => x.id == selected.email.id);
      
                      if (index != -1)
                        draft[index].status = "Completed"
                    }));  
                  }

                  setSelected(null);
              }}
              onHide={ () => setSelected(null) } 
          /> 
      }
      {(selected && selected.submode == "createtask") && 
          <TaskDrawer 
              show={true}
              task={{
                id: 0,
                type: "followupemail",
                user_id: selected.email.checks ? selected.email.checks.user.id : null,
                user_email: selected.email.from,
              }}
              onSaved={ (result) => {
                setSelected({mode:"email", email:selected.email})
              }} 
              onHide={ () => setSelected({mode:"email", email:selected.email}) } 
          /> 
      }        
    </>
  );
}

export default EmailsPanel;