import React, { useState, useEffect } from "react";
import styled from "styled-components";
import produce from "immer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faPencilAlt, faPlus, faTrash, faThumbtack, faUser, faShapes, faArchive, faCreditCard, faWrench, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";

import Loading from "../../components/loading"
import Toolbar from "../../components/toolbar"

import NoteDrawer from "../../drawers/drawer-note"
import TaskDrawer from "../../drawers/drawer-task"

import { postData } from "../../common/services/server"
import { formatDateTime } from "../../helpers/utility";
import { Button } from "react-bootstrap";

const Note = styled.div`
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;

    display: grid;
    grid-template-columns: 1fr auto;
    gap: 5px;

    margin-bottom: 5px;

    > div {
        &:last-child {
            display: flex;
            gap: 5px;
        }
        svg {
            margin-right: 4px;
        }
        button {
            width: 100%;
            white-space: nowrap;
        }

        > div {
            margin-top: 6px;
            display: flex;
            gap: 5px;
            align-items: center;

            > * {
                border: 1px solid #ccc;
                border-radius: 4px;
                font-size: 80%;
                padding: 2px 4px;

                &.badge {
                    background-color: #666;
                    color: white;
                    text-transform: capitalize;
                }

                label {
                    margin-bottom: 0px;
                    margin-right:  5px;
                }
            }
        }
    }
`

function NotesPanel(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState({column: "added_date", dir: "ASC"});
  const [page, setPage] = useState(1);
  const [notes, setNotes] = useState([]);
  const [filters, setFilters] = useState({ type: "" });
  const [selected, setSelected] = useState(null);
  const [busy, setBusy] = useState(null);

  function loadNotes() {
    setIsLoading(true);

    postData("notes/alldata", {
        sort: sort.column,
        sortdir: sort.dir,
        page: page,
        perPage: 500,
        ...props.filters,
        ...filters
      },
      function(result) {
          setNotes(result.notes);
      },
      function(error) {
          alert("Error loading notes")
      },
      function() {
        setIsLoading(false);
      }
    );
  }

  function onAction(action, note) {
    console.log("action", action, note)

    if (action == "edit")
        setSelected({mode:"edit", note: note});
    else if (action == "pin") {
      setBusy(action + note.id);

      postData("notes/pin", {
        note_id: note.id
      },
        function(result) {
          setNotes(produce(draft => {
            const index = draft.findIndex(n => n.id == note.id);
            draft[index] = result;
          }));
        },
        function(error) {
            alert("Error updating note.")
        },
        function() {
          setBusy(null)
        }
      );
    }
    else if (action == "delete") {
      if (window.confirm("Are you sure you want to delete this note?")) {
        setBusy(action + note.id);

        postData("notes/delete", {
            note_id: note.id
        },
            function(result) {
            setNotes(produce(draft => {
                const index = draft.findIndex(n => n.id == note.id);
                draft.splice(index, 1);
            }));
            },
            function(error) {
                alert("Error deleting note.")
            },
            function() {
            setBusy(null)
            }
        );
      }
    }
 }

  useEffect(
    () => {
      loadNotes();
    }, 
    [filters, page, sort]
  );

  function onNoteSaved(updatedPart) {
    console.log("note saved", updatedPart)

    setNotes(produce(draft => {
        var indexes = draft.map((x) => { return parseInt(x.id); });
        var index = indexes.indexOf(parseInt(updatedPart.id));

        if (index == -1)
          draft.push(updatedPart);
        else
          draft[index] = updatedPart;
      }));

    setSelected(null);
  }

  return (
    <>
      {
          isLoading 
      ?
          <Loading />
      :    
          <>
            <Toolbar
              buttons={[
                  {
                      name: "add",
                      label: "Add Note",
                      variant: "outline-warning",
                      icon: faPlus
                  }
              ]}
              onButton={(name) => {
                  console.log("button", name)
                  if (name == "add")
                      setSelected({mode:"edit"})
              }}
            />
            {notes.map(note => {  
                return (
                    <Note>
                        <div>
                            {note.notes}

                            <div>
                                <div style={{backgroundColor: "#eee"}}>
                                    Added by <strong>{note.added_user_name}</strong>&nbsp;
                                    on {formatDateTime(note.added_date)}
                                </div>
                                {note.user_id && 
                                    <div>
                                        <label>User:</label> 
                                        <Link to={"/users/user/" + note.user_id}>
                                            <FontAwesomeIcon icon={faUser} />{' '}
                                            {note.user_title}
                                        </Link>
                                    </div>
                                }
                                {note.sign_id && 
                                    <div>
                                        <label>Sign:</label> 
                                        <Link to={"/signs/sign/" + note.sign_id}>
                                        <FontAwesomeIcon icon={faShapes} />{' '}
                                        {note.sign_title}
                                        </Link>
                                    </div>
                                }
                                {note.quote_id && 
                                    <div>
                                        <label>Project:</label> 
                                        <Link to={"/projects/project/" + note.quote_id}>
                                            <FontAwesomeIcon icon={faArchive} />{' '}
                                            {note.quote_title}
                                        </Link>
                                    </div>
                                }
                                {note.order_id && 
                                    <div>
                                        <label>Order:</label> 
                                        <Link to={"/orders/order/" + note.order_id}>
                                            <FontAwesomeIcon icon={faCreditCard} />{' '}
                                            {note.order_title}
                                        </Link>
                                    </div>
                                }
                                {note.task_id && 
                                    <div>
                                        <label>Task:</label> 
                                        <Link to={"/tasks/task/" + note.task_id} onClick={(e) => { setSelected({mode:"task", note}); e.preventDefault();}}>
                                            <FontAwesomeIcon icon={faClipboardCheck} />{' '}
                                            {note.task_title}
                                        </Link>
                                    </div>
                                }                          
                                {note.job_id && 
                                    <div>
                                        <label>Job:</label> 
                                        <Link to={"/jos/job/" + note.job_id}>
                                            <FontAwesomeIcon icon={faWrench} />{' '}
                                            {note.job_title}
                                        </Link>
                                    </div>
                                }

                                {note.type && note.type.split(",").map((t, index) => {  
                                    return <div key={index} className="badge">{t}</div>
                                })}
                            </div>
                        </div>
                        <div>
                            <Button
                                size="sm"
                                variant={note.pinned_ind > 0 ? "primary" : "outline-primary"}
                                disabled={busy}                          
                                onClick={() => {
                                    onAction("pin", note);
                                }}
                            >
                                <FontAwesomeIcon icon={busy && busy == ("pin"+note.id) ? faSpinner:faThumbtack} spin={busy && busy == ("pin"+note.id)} />
                                Pin
                            </Button>
                            <Button
                                size="sm"
                                variant="outline-warning"      
                                disabled={busy}                          
                                onClick={() => {
                                    onAction("edit", note);
                                }}
                            >
                                <FontAwesomeIcon icon={faPencilAlt} />
                                Edit
                            </Button>
                            <Button
                                size="sm"
                                variant="outline-danger"  
                                disabled={busy}                          
                                onClick={() => {
                                    onAction("delete", note);
                                }}
                            >
                                <FontAwesomeIcon icon={busy && busy == ("delete"+note.id) ? faSpinner:faTrash} spin={busy && busy == ("delete"+note.id)} />
                                Delete
                            </Button>
                        </div>
                    </Note>
                )
            })}
          </>
      }
      { (selected && selected.mode == "edit") && 
        <NoteDrawer 
            show={true}
            note={selected.note} 
            internal={props.filters.internal}
            onSaved={onNoteSaved} 
            onHide={ () => setSelected(null) } /> 
      }
      { (selected && selected.mode == "task") && 
        <TaskDrawer 
            show={true}
            task={{id: selected.note.task_id}} 
            onSaved={(taskData) => {
                setNotes(produce(draft => {
                  const taskNotes = draft.filter(n => n.task_id == selected.note.task_id);
                    
                  for (const note of taskNotes) {
                    const index = draft.findIndex(i => i.id == note.id);
        
                    if (index != -1) {
                        draft[index].task_title = taskData.task;
                    }
                  }                  
                }));
            }} 
            onDeleted={() => {
                setNotes(produce(draft => {
                    const taskNotes = draft.filter(n => n.task_id == selected.note.task_id);
                    
                    for (const note of taskNotes) {
                      const index = draft.findIndex(i => i.id == note.id);
          
                      if (index != -1) {
                          draft.splice(index, 1)
                      }
                    }
                }));
                setSelected(null);
            }}
            onHide={ () => setSelected(null) } 
        /> 
      }      
    </>
  );
}

export default NotesPanel;