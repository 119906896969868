import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import produce from 'immer';
import { Badge, Card } from "react-bootstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faPlug, faPlus, faMinus, faSpinner } from '@fortawesome/free-solid-svg-icons'

import Loading from "../components/loading"
import Button from "../components/buttons/button";
import { Accordion, AccordionItem } from "../components/accordion";

import { postData } from "../common/services/server"
import storage from "../settings/storage";

function PartProductsDrawer(props) {
  const [busy, setBusy] = useState([]);

  useEffect(() => {
  }, []);

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faPlug} />
                &nbsp;
                {props.part.title} Related Parts
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Accordion>
                    {props.types.filter(pt => pt.mode != "frontend").map(partType => {
                        const selCount = props.part.children.filter(cp => cp.type_id == partType.id).length;

                        return (
                            <AccordionItem 
                                key={partType.name}
                                title={
                                    <>
                                        <div>
                                            {partType.title}
                                        </div>  
                                        <div>
                                            <Badge variant={selCount > 0 ? "success":"secondary"} style={{marginRight:"8px"}}>
                                                {selCount}
                                            </Badge>
                                            <FontAwesomeIcon icon={(selectedType && selectedType==partType.name) ? faChevronUp:faChevronDown} />{' '}
                                        </div>
                                    </>
                                }
                            >
                                <div>
                                    {props.parts.length ?
                                    <>
                                    {props.parts.filter(p => p.type_id == partType.id && p.id != props.part.id).map(part => {
                                        const selected = props.part.children.find(cp => cp.id == part.id);

                                        return (
                                        <div 
                                            key={part.id} 
                                            style={{
                                                display: "grid",
                                                gridTemplateColumns: "50px 1fr auto",
                                                gap: "5px",
                                                alignItems: "center",
                                                width: "100%",
                                                textAlign: "left",
                                                backgroundColor: "white",
                                                border: "1px solid #ccc",
                                                borderRadius: "0px",
                                                padding: "10px",
                                                fontSize: "90%",                               
                                            }}                                        
                                        >
                                            <div>
                                                <img 
                                                    src={storage.root + "2020/parts/" + part.name + "/icon.jpg?" + (new Date().getTime())} 
                                                    height="30"
                                                    style={{ maxHeight: "30px", maxWidth: "50px" }} 
                                                />
                                            </div>
                                            <div>
                                                {(part.part_number) && 
                                                    <>
                                                    {part.part_number}
                                                    &nbsp;-&nbsp;
                                                    </>
                                                }
                                                {part.title}
                                            </div>
                                            <Button
                                                size="sm"
                                                variant={selected ? "outline-danger" : "outline-success"}
                                                disabled={busy.indexOf("busy"+part.id) != -1}
                                                onClick={() => {
                                                    setBusy(produce(draft => {
                                                        draft.push("busy"+part.id);
                                                    }))

                                                    postData("parts/"+(selected?"remove":"add")+"child", { part_id: props.part.id, child_part_id: part.id },
                                                        function(result) {
                                                            if (selected)
                                                                props.onPartRemoved(part)
                                                            else 
                                                                props.onPartAdded(part);
                                                        },
                                                        function(error) {
                                                            alert("Error updating the part")
                                                        },
                                                        function() {
                                                            setBusy(produce(draft => {
                                                                const idx = draft.findIndex(b => b == ("busy"+part.id));

                                                                if (idx != -1)
                                                                    draft.splice(idx, 1);
                                                            }))
                                                        }
                                                    );                                                   
                                                }}
                                            >
                                                <FontAwesomeIcon icon={busy.indexOf("busy"+part.id) != -1 ? faSpinner : selected ? faMinus:faPlus} spin={busy.indexOf("busy"+part.id) != -1} />{' '}
                                                {selected ? "Remove" : "Add"}
                                            </Button>
                                        </div>
                                        )
                                    })}
                                    </>
                                    :
                                    <Loading size="sm" color="white" />
                                    }
                                </div>
                            </AccordionItem>
                        )
                    })}
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default PartProductsDrawer;