import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import styled from "styled-components"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import Loading from "../components/loading"
import ZoomableImage from "../components/image-zoomable";
import storage from "../settings/storage";
import { postData } from "../common/services/server"
import { formatDate, formatTime, formatFileSize } from "../helpers/utility";

const Files = styled.table`
    width: 100%;
    
    th {
        background-color: #eee;
        padding: 5px;
        font-size: 12px;

        img {
            margin-right: 5px;
        }
    }
    td {
        padding: 5px;
        border-bottom: 1px solid #ccc;
        font-size: 12px;
    }
    td:nth-child(1) {
        word-break: break-all;
    }
    td:nth-child(2) {
        white-space: nowrap;
    }
    td:nth-child(3) {
        white-space: nowrap;
    }
`

function FileSelectDrawer(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);

    useEffect(
        () => {
            setIsLoading(true);

            postData("files/data", props.filters,
                function(result) {
                    setFiles(result)
                },
                function(error) {
                    alert("An error occured while loading the files.");
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        []
    );

    return (
      <Modal show={true} onHide={props.onHide} scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faFile} />
            &nbsp;
            File Selector
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            { isLoading ?
              <Loading />
            : 
                <Files>
                    <thead>
                        <tr>
                            <th>Filename</th>
                            <th>Size</th>
                            <th>Date Added</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                    {files.map(file => (
                        <tr key={file.id}>
                            <td width="40%">
                                {file.preview_url ?
                                    <ZoomableImage
                                        url={storage.root + (file.preview_url || file.url)}
                                        zoomUrl={storage.root + (file.version_url || file.url)}
                                        maxWidth="100px" 
                                        maxHeight="30px"
                                        alt={file.filename}
                                        caption={file.filename}
                                        captionClassName="text-xs"
                                    />
                                :
                                    <a href={storage.root + (file.version_url || file.url)} target="_blank" className="text-blue-500">
                                        {file.filename}
                                    </a>
                                }
                            </td>
                            <td>
                                {formatFileSize(file.filesize)}
                            </td>
                            <td>
                                <div>{formatDate(file.added_date)}</div>
                                <div><small>{formatTime(file.added_date)}</small></div>
                            </td>
                            <td align="right">
                                <Button onClick={() => props.onFile(file)} variant="outline-success" size="sm">
                                    Select File
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Files>
            }
          </>
        </Modal.Body>
        <Modal.Footer>
            {props.buttons}
            <Button variant="outline-secondary" onClick={props.onHide}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default FileSelectDrawer;