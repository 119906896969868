import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faStar, faWrench, faCheck, faListAlt, faHome } from "@fortawesome/free-solid-svg-icons";
import { useParams, useHistory } from "react-router-dom";

import Content from "../components/content"
import PageHeader from "../components/page-header";
import ProjectsPanel from "./panels/panel-projects"
import ProjectDrawer from "../drawers/drawer-project"
import Button from "../components/buttons/button";

import { userHasPermission, Permissions, Actions } from "../common/services/auth";
import PageLayout from "../components/page-layout";

function ProjectsPage(props) {
    const history = useHistory();
    let { tab } = useParams();

    if (!tab) tab = "new";

    const [selected, setSelected] = useState(null);
    const [selectedTab, setSelectedTab] = useState(tab);

    function onCreate() {
        setSelected("create")
    }

    return (
        <PageLayout>
            <PageHeader 
                title="Project Manager"
                help="projects"
                tab={tab}
                tabUrl={"/projects/{tab}"}
                tabs={[
                    {name:"new", title:"New", icon:faStar},
                    {name:"working", title:"Working", icon:faWrench},
                    {name:"completed", title:"Completed", icon:faCheck},
                    {name:"all", title:"All", icon:faListAlt},
                    {name:"internal", title:"Internal", icon:faHome, align: "right", permission: Permissions.ProjectsInternal},
                ]}
                onTab={(tab) => {
                    history.push("/projects/" + tab);
                    setSelectedTab(tab)
                }}
            >
                {userHasPermission(Permissions.Projects, Actions.Create) &&
                    <Button onClick={onCreate} variant="outline-success" size="sm">
                        <FontAwesomeIcon icon={faPlus} />
                        &nbsp;
                        Create Project
                    </Button>
                }
            </PageHeader>
            <Content permission={Permissions.Projects}>
                <ProjectsPanel
                    filters={{
                        type: selectedTab
                    }}
                    haveTabs={true}
                />
            </Content>
            {(selected && selected == "create") && 
                <ProjectDrawer
                    show={true}
                    project={{
                        created_from:"Admin"
                    }}
                    onSaved={(project) => {
                        history.push("/projects/project/" + project.id);
                    }}
                    onHide={() => setSelected(null)}
                />
            }
        </PageLayout>
    );
}

export default ProjectsPage;