import React, { useState, useEffect } from "react";
import produce from "immer"
import { faClone, faEnvelope, faMailBulk, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell, 
    EditActionsCell,
    PropertiesCell,
    SortHeaderCell,
    SortTypes,
} 
from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';
import { useHistory } from "react-router-dom";

import Loading from "../../components/loading"
import Toolbar from "../../components/toolbar"
import ListField from "../../components/fields/field-list"

import EmailTemplateDrawer from "../../drawers/drawer-emailtemplate"
import EmailTemplateSendDrawer from "../../drawers/drawer-emailtemplatesend"

import { postData } from "../../common/services/server"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

function EmailTemplatesPanel(props) {
  const history = useHistory();
  const windowDimensions = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState({column: "name", dir: SortTypes.ASC});
  const [templates, setTemplates] = useState([]);
  const [filters, setFilters] = useState(props.filters || {});
  const [refresh, setRefresh] = useState(0);
  const [selected, setSelected] = useState(null);
  const [busyRow, setBusyRow] = useState(null);

  const haveToolbar = props.buttons && props.buttons.length > 0;

  useBodyClass(`noscroll`);

  useEffect(
    () => {
      setIsLoading(true);

      postData("emails/templates", {
          sort: sort.column,
          sortdir: sort.dir,
          ...filters,
          ...props.filters
        },
        function(result) {
            setTemplates(result);
        },
        function(error) {
            alert("Error loading email templates")
        },
        function() {
          setIsLoading(false);
        }
      );
    }, 
    [props.filters, refresh, sort]
  );

  function filter() {
    setRefresh(refresh+1);
  }

  function onSortChange(columnKey, sortDir) {
    setSort({ column: columnKey, dir: sortDir })
  }

  function onAction(action, template) {
    console.log("action", action, template)

    if (action == "edit")
        setSelected({mode:"edit", template});
    else if (action == "send")
        setSelected({mode:"send", template});
    else if (action == "clone")
        setSelected({mode:"edit", template:{...template, id:0, name: ""}});
    else if (action == "delete") {
      if (window.confirm("Delete this email template?")) {
        setBusyRow(template);

        postData("emails/deletetemplate", {
          template_id: template.id
        },
          function(result) {
            setTemplates(produce(draft => {
              const index = draft.findIndex(e => e.id == template.id);
              draft.splice(index, 1);
            }));
          },
          function(error) {
              alert("Error deleting template.")
          },
          function() {
            setBusyRow(null)
          }
        );
      }
    }
  }

 function onFilter(name, value) {
    setFilters(produce(draft => {
      draft[name] = value;
    }));
  }

  return (
    <>
      {
          isLoading 
      ?
          <Loading />
      :    
        <>    
          {haveToolbar && 
            <Toolbar buttons={props.buttons} onButton={props.onButton} />
          }
          <Table
              rowHeight={90}
              rowsCount={templates.length}
              width={windowDimensions.width - dimensions.dataTableWidthOffset}
              height={windowDimensions.height - dimensions.dataTableHeightOffset}
              headerHeight={70}
              touchScrollEnabled={true}
          >
              <Column
                  header={
                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                        Name
                    </SortHeaderCell>
                  }
                  columnKey="name"
                  fixed
                  cell={<TextCell data={templates} style={{textTransform:"capitalize"}} />}
                  width={200}
              />
              <Column
                  header={
                    <Cell>
                        Type
                        <ListField 
                            name="type" 
                            value={filters.type} 
                            list={[
                              {value: "auto", label: "Auto"},
                              {value: "scheduled", label: "Scheduled"},
                              {value: "manual", label: "Manual"},
                            ]}
                            onChange={(e) => {
                              onFilter("type", e.target.value);
                              filter();
                            }}
                            groupClassName="nomargin"
                            size="sm"
                        />                        
                    </Cell>
                  }
                  columnKey="type"
                  cell={<TextCell data={templates} />}
                  width={125}
              />
              <Column
                  header={
                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                        Subject
                    </SortHeaderCell>
                  }
                  columnKey="subject"
                  cell={<TextCell data={templates} />}
                  width={250}
              />
              <Column
                  header={
                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                        From
                    </SortHeaderCell>
                  }
                  columnKey="from"
                  cell={<TextCell data={templates} small />}
                  width={200}
              />
              <Column
                  header={
                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                        To / CC / BCC
                    </SortHeaderCell>
                  }
                  columnKey="to"
                  cell={({rowIndex, ...props}) => {
                    const template = templates[rowIndex];

                    return (
                      <PropertiesCell {...props} className="properties">
                        <div className="prop"><label>To:</label><small>{template.to ? template.to.replace(/,/g,", ") : ""}</small></div>
                        <div className="prop"><label>CC:</label><small>{template.cc ? template.cc.replace(/,/g,", ") : ""}</small></div>
                        <div className="prop"><label>BCC:</label><small>{template.bcc ? template.bcc.replace(/,/g,", ") : ""}</small></div>
                      </PropertiesCell>
                    )
                  }}
                  width={325}
              />           
              <Column
                  header={<Cell>Schedule</Cell>}
                  cell={({rowIndex, ...props}) => {
                    const template = templates[rowIndex];

                    return (
                      template.schedule ? 
                        <PropertiesCell {...props} className="properties">
                          <div className="prop"><label>Trigger:</label><small>{template.schedule_trigger}</small></div>
                          <div className="prop"><label>Type:</label><small>{template.schedule_type}</small></div>
                          <div className="prop"><label>Schedule:</label><small>{template.schedule}</small></div>
                        </PropertiesCell>
                      :
                        <Cell {...props}>&nbsp;</Cell>
                    )
                  }}
                  width={325}
              />         
              <Column
                  header={<Cell>Actions</Cell>}
                  cell={
                    <EditActionsCell 
                        data={templates} 
                        actions={[
                            {action:"send", title:"Send", variant:"success", icon:faEnvelope, isVisible: (template) => template.type == "manual", permission:Permissions.EmailTemplates, permissionAction:Actions.Send },
                            {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, permission:Permissions.EmailTemplates, permissionAction:Actions.Edit },
                            {action:"clone", title:"Clone", variant:"secondary", icon:faClone, permission:Permissions.EmailTemplates, permissionAction:Actions.Create},
                            {action:"delete", title:"Delete", variant:"danger", icon:faTrash, isVisible: (template) => template.locked_ind == 0, permission:Permissions.EmailTemplates, permissionAction:Actions.Delete},
                        ]}
                        busy={busyRow}
                        onClick={onAction} 
                    />
                  }
                  align="center"
                  width={155}
              />
          </Table>
        </>
      }
      { (selected && selected.mode == "edit") && 
        <EmailTemplateDrawer 
            show={true}
            template={selected.template} 
            onSaved={(template) => {
              setTemplates(produce(draft => {
                const index = draft.findIndex(t => t.id == template.id);

                if (index == -1) 
                  draft.push(template)
                else
                  draft[index] = template;
              }));

              setSelected(null);
            }}
            onHide={ () => setSelected(null) } 
        /> 
      }
      { (selected && selected.mode == "send") && 
        <EmailTemplateSendDrawer 
            show={true}
            template={selected.template} 
            onSent={ () => setSelected(null) } 
            onHide={ () => setSelected(null) } 
        /> 
      }      
    </>
  );
}

export default EmailTemplatesPanel;