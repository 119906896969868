import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons'

import { postData } from "../common/services/server"
import { formatDateTime, removeNulls } from '../helpers/utility'
import Button from "../components/buttons/button";
import Loading from "../components/loading";
import PanelTable from "../panels/panel-table"

function ResourceVersionsDrawer(props) {
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      setLoading(true);

      postData("resources/history", removeNulls({
            resource_id: props.resource.id
        }), 
        function(response) {
            setResources(response);
        },
        function(error) {
            alert("An error occured loading the resources.");
        },
        function() {
            setLoading(false);
        }
      );      
    }, 
    []
  );

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faHistory} />
                &nbsp;
                Resource Version History
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? 
                    <Loading />
                :
                    <PanelTable>
                        <thead>
                            <tr>
                            <th>Version</th>
                            <th>Resource Type</th>
                            <th>Resource</th>
                            <th>Resource Link</th>
                            <th>Added Date</th>
                            <th>Added User</th>
                        </tr>
                        </thead>
                        <tbody>
                            {resources.map((resource,index) => (
                                <tr key={resource.id}>
                                    <td>
                                        {index+1}
                                    </td>
                                    <td>
                                        {resource.type}
                                    </td>
                                    <td>
                                        {resource.title}
                                    </td>
                                    <td>
                                        <a href={resource.url || resource.file_url} target="_blank" style={{whiteSpace: "nowrap"}}>
                                            Open Resource
                                            <FontAwesomeIcon icon={faExternalLinkSquareAlt} style={{marginLeft: "5px"}} />
                                        </a>
                                    </td>
                                    <td>
                                        {formatDateTime(resource.added_date)}
                                    </td>
                                    <td>
                                        {resource.added_name}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </PanelTable> 
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default ResourceVersionsDrawer;