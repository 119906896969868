import React, { useState, useEffect } from "react";
import produce from "immer"
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from "react-device-detect"

import { useHistory } from "react-router-dom";

import {Table, Column, Cell} from 'fixed-data-table-2';
import { TextCell, EditActionsCell, UserCell, PropertiesCell, ButtonCell } from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Loading from "../../components/loading"
import ShapeDrawer from "../../drawers/drawer-shape"
import DimensionsDrawer from "../../drawers/drawer-dimensions"
import ShapePreview from "../../components/shape-preview"
import { postData } from "../../common/services/server"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

function ShapesPanel(props) {
  const history = useHistory();
  const windowDimensions = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(true);
  const [shapes, setShapes] = useState([]);
  const [selected, setSelected] = useState(null);
  const [busyRow, setBusyRow] = useState(null);

  useBodyClass(`noscroll`);

  useEffect(
    () => {
      setIsLoading(true);

      postData("shapes/data", { 
          style_id: props.style.id,
          ...props.filters
        },
        function(result) {
            setShapes(result);
        },
        function(error) {
            alert("Error loading shapes")
        },
        function() {
          setIsLoading(false);
        }
      );
    }, 
    [props.style]
  );

  function onAction(action, shape) {
    if (action == "edit")
      setSelected({mode:"edit", shape});
    else if (action == "delete") {
        setBusyRow(shape);

        postData("shapes/delete", { 
            shape_id: shape.id
        },
            function(result) {
                setShapes(produce(draft => {
                    draft.splice(draft.findIndex(s => s.id == shape.id), 1);
                }));
            },
            function(error) {
                alert("Error deleting shape")
            },
            function() {
                setBusyRow(null);
            }
        );
    }      
  }

  function onSaved(updated) {
    console.log("saved", updated)

    setShapes(produce(draft => {
        let indexes = draft.map((x) => { return parseInt(x.id); });
        let index = indexes.indexOf(parseInt(updated.id));

        if (index == -1)
          draft.push(updated);
        else
          draft[index] = updated;
      }));

    setSelected(null);
  }

  function onShapeUpdated(result) {
    setShapes(produce(draft => {
        const shape = draft.find(s => s.id == result.id)

        console.log("exisgting shape", shape);
        if (shape) {
            const index = draft.indexOf(shape);

            console.log(result, index);

            if (index >= 0)
               draft[index] = result;
        }
      }));
  }

  return (
    <>
      {
          isLoading 
      ?
          <Loading />
      :    
          <Table
              rowHeight={100}
              rowsCount={shapes.length}
              width={windowDimensions.width - dimensions.sideBarWidth - dimensions.pagePadding}
              height={windowDimensions.height - dimensions.headerHeight - (props.haveTabs ? dimensions.pageWithTabsPadding : dimensions.pagePadding)*2}
              headerHeight={60}
              touchScrollEnabled={true}
          >
              <Column
                  header={<Cell>Code</Cell>}
                  fixed={!isMobile}
                  columnKey="code"
                  cell={<TextCell data={shapes} />}
                  width={100}
              />
              <Column
                  header={<Cell>Name</Cell>}
                  columnKey="name"
                  cell={<TextCell data={shapes} />}
                  width={125}
              />
              <Column
                  header={<Cell>Shape</Cell>}
                  cell={({rowIndex, ...props}) => (
                      <Cell {...props}>
                        <ShapePreview 
                            path={shapes[rowIndex].path} 
                            width={100} 
                            height={80} 
                            upload={true}
                            uploadUrl={"shapes/upload?id=" + shapes[rowIndex].id}
                            onUpload={onShapeUpdated}
                        />
                      </Cell>
                  )}
                  width={125}
              />
              <Column
                  header={<Cell>Background</Cell>}
                  cell={({rowIndex, ...props}) => (
                      <Cell {...props}>
                        <ShapePreview 
                            path={shapes[rowIndex].backdrop_path} 
                            width={100} 
                            height={80} 
                            upload={true}
                            uploadUrl={"shapes/uploadbackdrop?id=" + shapes[rowIndex].id}
                            onUpload={onShapeUpdated}
                        />
                      </Cell>
                  )}
                  width={125}
              />
              {props.filters.user_ind == 1 && 
                <Column
                    header={<Cell>User</Cell>}
                    
                    columnKey="user_name"
                    cell={({rowIndex, ...props}) => (
                        <UserCell 
                            userId={shapes[rowIndex].user_id}
                            userName={shapes[rowIndex].user_name}
                            onUser={() => history.push("/users/user/" + shapes[rowIndex].user_id)}
                        />
                    )}
                    width={225}
                />
              }
              <Column
                  header={<Cell>Width Ratio</Cell>}
                  columnKey="width_ratio"
                  cell={<TextCell data={shapes} />}
                  width={70}
              />
              <Column
                  header={<Cell>Height Ratio</Cell>}
                  columnKey="height_ratio"
                  cell={<TextCell data={shapes} />}
                  width={70}
              />
              <Column
                  header={<Cell>Top Offset</Cell>}
                  columnKey="top_offset"
                  cell={<TextCell data={shapes} />}
                  width={70}
              />
              <Column
                  header={<Cell>Price Mod</Cell>}
                  columnKey="pricing_modifier"
                  cell={<TextCell data={shapes} />}
                  width={70}
              />
              <Column
                  header={<Cell>Consumption</Cell>}
                  columnKey="consumption"
                  cell={<TextCell data={shapes} />}
                  width={120}
              />

              {(props.filters.category_ind == 1) && 
                <Column
                    header={<Cell>Categories</Cell>}
                    columnKey="categories"
                    cell={<TextCell data={shapes} />}
                    width={200}
                />
              }
              {!props.style.secondary && 
                <Column
                    header={<Cell>Sizes</Cell>}
                    cell={({rowIndex, ...props}) => {
                      const shape = shapes[rowIndex];
  
                      return (
                        <PropertiesCell {...props} className="properties">
                          <div className="prop"><label>Standard:</label>{shape.standard_sizes}</div>
                          <div className="prop"><label>Default:</label>{shape.default_size}</div>
                          <div className="prop"><label>Min:</label>{shape.min_size}</div>
                          <div className="prop"><label>Max:</label>{shape.max_size}</div>
                        </PropertiesCell>
                      )
                    }}
                    width={200}
                />
              }

              <Column
                  header={<Cell>Used</Cell>}
                  columnKey="sign_count"
                  cell={<TextCell data={shapes} />}
                  width={100}
              />
              <Column
                  header={<Cell>Prewire</Cell>}
                  columnKey="dimensions_count"
                  cell={<ButtonCell data={shapes} onClick={(shape) => setSelected({mode:"dimensions", shape})} />}
                  width={100}
              />
              <Column
                  header={<Cell>Actions</Cell>}
                  cell={
                    <EditActionsCell 
                        data={shapes} 
                        actions={[
                            {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, permission:Permissions.Shapes, permissionAction:Actions.Edit},
                            {action:"delete", title:"Delete", variant:"danger", icon:faTrash, isVisible: (shape) => shape.sign_count == 0, permission:Permissions.Shapes, permissionAction:Actions.Delete},
                        ]}
                        busy={busyRow}
                        onClick={onAction} 
                    />
                  }
                  align="center"
                  width={90}
              />
          </Table>
      }
      { selected && selected.mode == "edit" && 
        <ShapeDrawer 
          shape={selected.shape} 
          style={props.style}
          onSaved={onSaved} 
          onHide={ () => setSelected(null) } 
        /> 
      }
      { selected && selected.mode == "dimensions" && 
        <DimensionsDrawer show={true} shape={selected.shape} onChange={onSaved} onHide={ () => setSelected(null) } /> 
      }
    </>
  );
}

export default ShapesPanel;