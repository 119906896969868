import React, { useState } from "react";
import styled from 'styled-components'
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { Accordion, AccordionItem } from "../../components/accordion";
import GoogleImage from "../../components/google-image"
import CheckListForm from "../../components/forms/form-checklist"
import CheckListInfoForm from "../../components/forms/form-checklistinfo"
import Button from "../../components/buttons/button";
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";
import { formatPhoneNumber } from "../../helpers/utility";

const Checklists = styled.div`
    display: flex;

    > div {
        flex-basis: 50%;
    }

    .card-header {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`
const Customer = styled.div`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0px 10px 10px 10px;
    display: flex;

    svg {
        margin-right: 8px;
    }
`

function OrderChecklistsPanel(props) {
    const [questionName, setQuestionName] = useState("");
    const [signId, setSignId] = useState(null);

    const { order } = props;
    const signs = order.items.filter(oi => oi.type=="sign");
    const uniqueSigns = signs.filter((sign, index, self) => self.findIndex(t => t.sign_id === sign.sign_id) === index)

    return (
        <Checklists>
            <Accordion>
                {uniqueSigns.map(item => (
                    <AccordionItem key={item.id}
                        title={
                            <div>
                                Build Ready
                                <GoogleImage root src={item.preview_url} style={{maxHeight: "30px"}} />
                            </div>
                        }
                    >
                        <CheckListForm
                            type="Build Ready"
                            item="orderitem"
                            itemId={item.id}
                            disabled={!userHasPermission(Permissions.Orders, Actions.Edit)}
                            onAnswer={props.onAnswer}
                            onQuestion={(question) => {
                                setQuestionName(question ? question.name : "");
                                setSignId(item.sign_id)
                            }}
                        />
                    </AccordionItem>
                ))}

                <AccordionItem title="Pre Build">
                    <CheckListForm
                        type="Pre Build"
                        item="order"
                        itemId={order.id}
                        onAnswer={props.onAnswer}
                        onQuestion={(question) => {
                            setQuestionName(question ? question.name : "");
                            setSignId(null);
                        }}
                    />
                </AccordionItem>
            </Accordion>
            <div>
                <Customer>
                    <FontAwesomeIcon icon={faUser} size="2x" />
                    <div style={{flex: 1}}>
                        <div>{order.user_name}</div>
                        <div>{formatPhoneNumber(order.user_phoneno)}</div>
                    </div>
                    <Button variant="outline-primary" onClick={() => { window.open("#/users/user/" + order.user_id) }}>
                        Open Profile
                    </Button>
                </Customer>
                <CheckListInfoForm
                    questionName={questionName}
                    item="order"
                    itemId={order.id}
                    signId={signId}
                    onOrderChange={props.onOrderChange}
                />
            </div>
        </Checklists>
    );
}

export default OrderChecklistsPanel;