import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLevelUpAlt } from "@fortawesome/free-solid-svg-icons";
import JobTask from "./job-task";

const JobTaskList = ({ 
    tasks, 
    users,
    disabled=false, 
    parentTaskName=null, 
    level=0, 
    expanded=true,
    onTask, 
    onTaskChanged,
    onJobChanged 
}) => {
    //const hasChildren = items && items.length
  
    const levelTasks = tasks.filter(t => 
      (t.dependent_name == parentTaskName) || // child
      (t.dependent_name == null && parentTaskName == null) || // root
      (t.dependent_name && level==0 && tasks.find(t2 => t2.name == t.dependent_name) == null) // prev day
    );

//    console.log("JobTaskList", parentTaskName, levelTasks.map(t => t.name).join(","))

    return (
      <>
        {levelTasks.map(task => {
            const childTasks = tasks.filter(t => t.dependent_name == task.name);

            return (
                <div key={task.id}>
                    {disabled ? 
                        <div style={{display: "flex", alignItems: "center", marginLeft: (expanded ? level * 16 : 0)+"px"}}>
                            {(level > 0 && expanded) && 
                                <FontAwesomeIcon icon={faLevelUpAlt} rotation={90} style={{marginRight:"5px"}} />
                            }
                            <JobTask
                                expanded={expanded}
                                task={task}
                                users={users}
                                disabled={disabled}
                                onClick={onTask}
                                onTaskChanged={onTaskChanged}
                                onJobChanged={onJobChanged}
                            />
                        </div>
                    :
                        <div style={{display: "flex", alignItems: "center", marginLeft: (expanded ? level * 16 : 0)+"px"}}>
                            {(level > 0 && expanded) && 
                                <FontAwesomeIcon icon={faLevelUpAlt} rotation={90} style={{marginRight:"5px"}} />
                            }
                            <JobTask
                                expanded={expanded}
                                task={task}
                                users={users}
                                disabled={disabled}
                                onClick={onTask}
                                onTaskChanged={onTaskChanged}
                                onJobChanged={onJobChanged}
                            />
                        </div>
                    }

                    {(childTasks.length > 0) &&
                        <JobTaskList 
                            tasks={tasks} 
                            users={users}
                            disabled={disabled} 
                            expanded={expanded}
                            parentTaskName={task.name} 
                            level={level+1} 
                            onTask={onTask} 
                            onTaskChanged={onTaskChanged}
                            onJobChanged={onJobChanged}
                        />
                    }
                </div>
            )
        })}
      </>
    )
}

export default JobTaskList