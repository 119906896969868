import React from "react"
import styled from "styled-components"
import Img from 'react-image'

import UploadButton from "./buttons/button-upload"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

import storage from '../settings/storage'
import notfound from '../images/nothumbnail.jpg'

const Image = styled.div`
  position: relative;

  form { 
    display: none;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  form.busy  {
    display: block;
  }

  &:hover {
    form {
      display: block;
    }
  }
`


function GoogleImage(props) {
    const url = (props.root ? storage.root : storage.url) + props.src;
    return (
      <Image>
        <img 
          src={url} 
          onClick={() => {
            if (props.link)
              window.open((props.root ? storage.root : storage.url) + props.link);
          }}
          alt={props.alt} 
          style={Object.assign({maxWidth: "100%"}, props.style)} 
          onError={(e) => {
            if (e.target.src != (storage.root + "images/nothumbnail.jpg")) {
                e.target.src = storage.root + "images/nothumbnail.jpg";
            }
            e.target.onerror = null; // just in case to prevent recursive error
            e.target.onError = null; // just in case to prevent recursive error
          }}            
        />
        { props.upload && 
            <UploadButton
              uploadUrl={props.uploadUrl}
              onUpload={props.onUpload}
            />   
        }
      </Image>
    );
}

export default GoogleImage