import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from "react-router-dom";

function Property(props) {
    const history = useHistory();

    return (
        <tr className={props.url ? "link":""}>
            <th className={!props.label || props.label.length == 0 ? "empty" : props.danger ? "danger":""}>
                {props.label}
                {props.sublabel && 
                    <div><small>{props.sublabel}</small></div>
                }
            </th>
            <td onClick={() => props.url ? props.newtab ? window.open(props.url) : history.push(props.url) : undefined} className={props.url ? "link":""} style={props.styles}>
                {props.value ? 
                    <>
                        {props.icon && 
                            <FontAwesomeIcon icon={props.icon} />
                        }
                        {props.html ? 
                            <span dangerouslySetInnerHTML={{__html: props.value}} />
                            :
                            props.url ? 
                                <a href={"#"+ props.url} onClick={(e) => e.preventDefault()}>
                                    {props.value}
                                </a>
                            :
                            props.value
                        }
                    </>
                :
                    props.children 
                }
                {props.hint && 
                    <div>
                        <small style={{color: "#ccc"}}>
                            {props.hint}
                        </small>
                    </div>
                }
            </td>
        </tr>
    )
}

export default Property