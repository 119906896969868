import React, { useState, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlagUsa, faSave } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import NumberField from "../components/fields/field-number"
import CurrencyField from "../components/fields/field-currency"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"

function StateDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(props.state)));
  const [validated, setValidated] = useState(false);

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("states/save?id=" + (props.state.id||0), formData, 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the state.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();
    //console.log("handleFormChange", event.target.name, event.target.value)
    const field = event.target.name;
    let value = event.target.value;

    setFormData(produce(draft => {
      draft[field] = value;
    }));
  }

  return (
      <Modal show={props.show} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faFlagUsa} />
            &nbsp;
            State
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
            <TextField 
                name="state" 
                label="Name" 
                required
                value={formData.state} 
                onChange={(e) => handleFormChange(e)} />
            <TextField 
                name="abbrev2" 
                label="Abbreviation" 
                required 
                value={formData.abbrev2} 
                onChange={(e) => handleFormChange(e)} />
            <NumberField 
                name="tax_rate" 
                label="Tax Rate" 
                required 
                width="small"
                value={formData.tax_rate} 
                onChange={(e) => handleFormChange(e)} />
            <CurrencyField 
                name="ship_freight_surcharge" 
                label="Freight Surcharge" 
                required 
                width="small"
                value={formData.ship_freight_surcharge} 
                onChange={(e) => handleFormChange(e)} />
           </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save State" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default StateDrawer;