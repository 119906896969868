import React, { useState, useEffect } from "react";
import produce from "immer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faStar, faPencilAlt, faPlus, faTrash, faThumbtack, faUser, faShapes, faArchive, faCreditCard, faWrench, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell, 
    PropertiesCell, 
    EditActionsCell,
    FilterHeaderCell,
    DateCell,
    UserCell,
    ButtonCell,
    SortHeaderCell,
    SortTypes,
} 
from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';
import { useHistory } from "react-router-dom";

import Loading from "../../components/loading"
import Toolbar from "../../components/toolbar"
import Paginator from "../../components/paginator"

import NoteDrawer from "../../drawers/drawer-note"
import TaskDrawer from "../../drawers/drawer-task"

import { postData } from "../../common/services/server"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

function NotesPanel(props) {
  const history = useHistory();
  const windowDimensions = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState({column: "added_date", dir: SortTypes.DESC});
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({page:1,pages:1,total:0});
  const [notes, setNotes] = useState([]);
  const [filters, setFilters] = useState({ type: "" });
  const [types, setTypes] = useState([]);
  const [selected, setSelected] = useState(null);
  const [busyRow, setBusyRow] = useState(null);

  useBodyClass(`noscroll`);

  function loadNotes() {
    setIsLoading(true);

    postData("notes/alldata", {
        sort: sort.column,
        sortdir: sort.dir,
        page: page,
        perPage: 100,
        ...props.filters,
        ...filters
      },
      function(result) {
          setNotes(result.notes);
          setPagination(result.pagination)

      },
      function(error) {
          alert("Error loading notes")
      },
      function() {
        setIsLoading(false);
      }
    );
  }

  useEffect(
    () => {
      postData("notes/types", {},
        function(result) {
            setTypes(result);
        },
        function(error) {
            alert("Error loading note data")
        },
        function() {
        }
      );      
    }, 
    []
  );

  function onSortChange(columnKey, sortDir) {    
    setPage(1);
    setSort({ column: columnKey, dir: sortDir })
  }

  function onAction(action, note) {
    console.log("action", action, note)

    if (action == "edit")
        setSelected({mode:"edit", note: note});
    else if (action == "pin") {
      setBusyRow(note);

      postData("notes/pin", {
        note_id: note.id
      },
        function(result) {
          setNotes(produce(draft => {
            const index = draft.findIndex(n => n.id == note.id);
            draft[index] = result;
          }));
        },
        function(error) {
            alert("Error updating note.")
        },
        function() {
          setBusyRow(null)
        }
      );
    }
    else if (action == "delete") {
      setBusyRow(note);

      postData("notes/delete", {
        note_id: note.id
      },
        function(result) {
          setNotes(produce(draft => {
            const index = draft.findIndex(n => n.id == note.id);
            draft.splice(index, 1);
          }));
        },
        function(error) {
            alert("Error deleting note.")
        },
        function() {
          setBusyRow(null)
        }
      );
    }
 }

  useEffect(
    () => {
      loadNotes();
    }, 
    [filters, page, sort]
  );

  function onFilterChange(filter, value) {
    setPage(1);
    setFilters(produce(draft => {
          draft[filter] = value
      })); 
  }

  function onFilter() {
      if (page != 1)
        setPage(1);
      else
        loadNotes()
  }

  function onNoteSaved(updatedPart) {
    console.log("note saved", updatedPart)

    setNotes(produce(draft => {
        var indexes = draft.map((x) => { return parseInt(x.id); });
        var index = indexes.indexOf(parseInt(updatedPart.id));

        if (index == -1)
          draft.push(updatedPart);
        else
          draft[index] = updatedPart;
      }));

    setSelected(null);
  }
  
  function onTasks(note) {

  }

  return (
    <>
      {
          isLoading 
      ?
          <Loading />
      :    
          <>
            <Toolbar
              buttons={[
                  {
                      name: "add",
                      label: "Add Note",
                      variant: "outline-warning",
                      icon: faPlus
                  }
              ]}
              onButton={(name) => {
                  console.log("button", name)
                  if (name == "add")
                      setSelected({mode:"edit"})
              }}
            />
                
            <Table
              rowHeight={60}
              rowsCount={notes.length}
              width={windowDimensions.width - dimensions.dataTableWidthOffset}
              height={windowDimensions.height - dimensions.dataTableHeightOffset}
              headerHeight={75}
              touchScrollEnabled={true}
          >
              <Column
                  header={<Cell>Note</Cell>}
                  fixed={!isMobile}
                  columnKey="notes"
                  cell={<TextCell data={notes} small />}
                  width={300}
              />

              <Column
                  header={<Cell>Associations</Cell>}
                  cell={({rowIndex, ...props}) => {
                    const note = notes[rowIndex];

                    return (
                      <PropertiesCell {...props} className="properties">
                          {note.user_id && 
                              <div>
                                  <label>User:</label> 
                                  <Link to={"/users/user/" + note.user_id}>
                                    <FontAwesomeIcon icon={faUser} />{' '}
                                    {note.user_title}
                                  </Link>
                              </div>
                            }
                          {note.sign_id && 
                              <div>
                                <label>Sign:</label> 
                                <Link to={"/signs/sign/" + note.sign_id}>
                                  <FontAwesomeIcon icon={faShapes} />{' '}
                                  {note.sign_title}
                                </Link>
                              </div>
                          }
                          {note.quote_id && 
                              <div>
                                  <label>Project:</label> 
                                  <Link to={"/projects/project/" + note.quote_id}>
                                    <FontAwesomeIcon icon={faArchive} />{' '}
                                    {note.quote_title}
                                  </Link>
                              </div>
                          }
                          {note.order_id && 
                              <div>
                                  <label>Order:</label> 
                                  <Link to={"/orders/order/" + note.order_id}>
                                    <FontAwesomeIcon icon={faCreditCard} />{' '}
                                    {note.order_title}
                                  </Link>
                              </div>
                          }
                          {note.task_id && 
                              <div>
                                  <label>Task:</label> 
                                  <Link to={"/tasks/task/" + note.task_id} onClick={(e) => { setSelected({mode:"task", note}); e.preventDefault();}}>
                                    <FontAwesomeIcon icon={faClipboardCheck} />{' '}
                                    {note.task_title}
                                  </Link>
                              </div>
                          }                          
                          {note.job_id && 
                              <div>
                                  <label>Job:</label> 
                                  <Link to={"/jos/job/" + note.job_id}>
                                    <FontAwesomeIcon icon={faWrench} />{' '}
                                    {note.job_title}
                                  </Link>
                              </div>
                          }
                      </PropertiesCell>
                  )}}
                  width={225}
              />
              
              <Column
                  header={
                    <FilterHeaderCell 
                      filter={filters.type} 
                      options={types}
                      idField="type"
                      labelField="type"
                      onFilterChange={(value) => onFilterChange("type", value)} 
                      onFilter={onFilter}
                    >
                      Tags
                    </FilterHeaderCell>                    
                  }
                  columnKey="type"
                  cell={({rowIndex, ...props}) => {
                      const note = notes[rowIndex];

                      return (
                        <Cell {...props}>
                            {note.type && note.type.split(",").map((t, index) => {  
                                return <span key={index}><Badge variant="secondary">{t}</Badge>{' '}</span>
                            })}
                        </Cell>
                      )}}
                  width={225}
              />
              <Column
                  header={
                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                        Priority
                    </SortHeaderCell>
                  }
                  columnKey="priority"
                  cell={({rowIndex, ...props}) => {
                    const priority = notes[rowIndex].priority;

                    return (
                      <Cell {...props}>
                          {priority == 2 ?
                            <span style={{color:"red"}}>
                                <FontAwesomeIcon icon={faExclamationCircle} />{' '}
                                Urgent
                            </span>
                           : priority == 1 ? 
                           <span style={{color:"orange"}}>
                               <FontAwesomeIcon icon={faStar} />{' '}
                               Important
                            </span>
                           : 
                            "Normal"
                          }
                      </Cell>
                  )}}
                  width={110}
              />
              {false && 
                <Column
                      header={<Cell>Tasks</Cell>}
                      columnKey="task_count"
                      cell={<ButtonCell data={notes} onClick={(note) => onTasks(note)} />}
                      width={70}
                />
              }
              <Column
                  header={
                    <SortHeaderCell sort={sort} onSortChange={onSortChange}>
                        Date Created
                    </SortHeaderCell>
                  }
                  columnKey="added_date"
                  cell={<DateCell data={notes} />}
                  width={135}
              />
              <Column
                header={<Cell>Added By</Cell>}
                
                columnKey="added_user_name"
                cell={({rowIndex, ...props}) => (
                    <UserCell 
                        userId={notes[rowIndex].added_user_id}
                        userName={notes[rowIndex].added_user_name}
                        onUser={() => history.push("/users/user/" + notes[rowIndex].added_user_id)}
                    />
                )}
                width={225}
              />
              <Column
                  header={<Cell>Actions</Cell>}
                  cell={
                    <EditActionsCell 
                        data={notes} 
                        actions={[
                            {action:"pin", title:"Pin To Dashboard", variant:"primary", icon:faThumbtack, isActive: (note) => note.pinned_ind > 0 },
                            {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, permission:Permissions.Notes, permissionAction:Actions.Edit},
                            {action:"delete", title:"Delete", variant:"danger", icon:faTrash, permission:Permissions.Notes, permissionAction:Actions.Delete},
                        ]}
                        busy={busyRow}
                        onClick={onAction} 
                    />
                  }
                  align="center"
                  width={150}
              />
          </Table>
          <Paginator
            {...pagination}
            item="Note"
            items="Notes"
            onPage={(page) => {
              console.log("page", page)
              setPage(page);
            }}
          />          
          </>
      }
      { (selected && selected.mode == "edit") && 
        <NoteDrawer 
            show={true}
            note={selected.note} 
            internal={props.filters.internal}
            onSaved={onNoteSaved} 
            onHide={ () => setSelected(null) } /> 
      }
      { (selected && selected.mode == "task") && 
        <TaskDrawer 
            show={true}
            task={{id: selected.note.task_id}} 
            onSaved={(taskData) => {
                setNotes(produce(draft => {
                  const taskNotes = draft.filter(n => n.task_id == selected.note.task_id);
                    
                  for (const note of taskNotes) {
                    const index = draft.findIndex(i => i.id == note.id);
        
                    if (index != -1) {
                        draft[index].task_title = taskData.task;
                    }
                  }                  
                }));
            }} 
            onDeleted={() => {
                setNotes(produce(draft => {
                    const taskNotes = draft.filter(n => n.task_id == selected.note.task_id);
                    
                    for (const note of taskNotes) {
                      const index = draft.findIndex(i => i.id == note.id);
          
                      if (index != -1) {
                          draft.splice(index, 1)
                      }
                    }
                }));
                setSelected(null);
            }}
            onHide={ () => setSelected(null) } 
        /> 
      }      
    </>
  );
}

export default NotesPanel;