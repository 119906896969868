import React, { useState, useEffect } from "react";
import styled from "styled-components"
import produce from "immer"

import {Table, Column, Cell} from 'fixed-data-table-2';
import { 
    TextCell,
    EditActionsCell, 
    BooleanCell
} from "../../components/datatable/cells"
import 'fixed-data-table-2/dist/fixed-data-table.css';

import Loading from "../../components/loading"
import QaQuestionDrawer from "../../drawers/drawer-qaquestion"

import { postData } from "../../common/services/server"
import { removeNulls } from "../../helpers/utility"
import { useBodyClass } from "../../hooks/useBodyClass"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { dimensions } from "../../settings/settings"
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { userHasPermission, Permissions, Actions } from "../../common/services/auth";

const CategoriesWidth = 200;
const Padding = 10;

const Interface = styled.div`
    display: flex;
`
const Categories = styled.div`
    width: ${CategoriesWidth}px;
    flex-shrink: 0;
    margin-right: ${Padding}px;
    border: 1px solid #ccc;
    padding: 0px;
    overflow: auto;  
    position: relative;

    ul {
        list-style-type: none;
        margin: 0px 0px 25px 0px;
        padding: 0px;

        li {
            padding: 5px;
            border-bottom: 1px solid #ccc;
            cursor: pointer;
            font-size: 12px;
            display: flex;
            justify-items: center;
            justify-content: space-between;

            &:hover {
                background-color: #eee;
            }

            &.selected {
                background-color: #ccc;
            }
        }
    }

    > div {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding: 0px;

        button {
            padding: 8px;
            width: 100%;
        }
    }
`

function QaPanel(props) {
    const windowDimensions = useWindowDimensions();

    const [category, setCategory] = useState("");
    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    const [busyRow, setBusyRow] = useState(null);

    const [products, setProducts] = useState([]);
    const [parts, setParts] = useState([]);
    const [shippingMethods, setShippingMethods] = useState([]);

    useBodyClass(`noscroll`);

    useEffect(
        () => {
            setIsLoading(true);

            postData("qa/questions", { 
                type: category
            },
                function(result) {
                    setQuestions(result.questions);
                },
                function(error) {
                    alert("Error loading questions")
                },
                function() {
                    setIsLoading(false);
                }
            );
        }, 
        [category]
    );

    useEffect(
        () => {
            postData("content/categories", {},
                function(result) {
                    setProducts(result.products);
                    setParts(result.parts);
                    setShippingMethods(result.shippingMethods);
                },
                function(error) {
                    alert("Error loading data")
                }
            );   
        }, 
        []
    );

    function onAction(action, question) {
        console.log("action", action, question)

        if (action == "delete") {
            setBusyRow(question);

            postData("qa/deletequestion", { 
                question_id: question.id
            },
                function(result) {
                    setQuestions(produce(draft => {
                        draft.splice(draft.findIndex(q => q.id == question.id), 1);
                    }));
                },
                function(error) {
                    alert("Error deleting question")
                },
                function() {
                    setBusyRow(null);
                }
            );
        }
        else if (action == "edit") {
            setSelected({mode:"question", question})
        }
    }

    function onCreateQuestion() {
        setSelected({mode:"question", question:{type: category}})
    }

    return (
    <>
        <Interface>
            <Categories style={{height: windowDimensions.height - dimensions.headerHeight - dimensions.pageWithTabsPadding*2}}>
                <>
                    <ul>
                        <li onClick={() => setCategory("Fraudulent Check")} className={category=="Fraudulent Check" ? "selected":""}>
                            Fraudulent Check
                        </li> 
                        <li onClick={() => setCategory("Build Ready")} className={category=="Build Ready" ? "selected":""}>
                            Build Ready
                        </li> 
                        <li onClick={() => setCategory("Pre Build")} className={category=="Pre Build" ? "selected":""}>
                            Pre Build
                        </li> 
                        <li onClick={() => setCategory("Job")} className={category=="Job" ? "selected":""}>
                            Job QC
                        </li> 
                        <li onClick={() => setCategory("Project")} className={category=="Project" ? "selected":""}>
                            Project
                        </li>                         
                        <li onClick={() => setCategory("Shipping")} className={category=="Shipping" ? "selected":""}>
                            Shipping
                        </li> 
                    </ul>
                    {(category.length > 0) && 
                        <div>
                            <button onClick={onCreateQuestion}>
                                Create Question
                            </button>
                        </div>
                    }
                </>
            </Categories>
            {
                isLoading 
            ?
                <Loading />
            :    
                <Table
                    rowHeight={70}
                    rowsCount={questions.length}
                    width={windowDimensions.width - dimensions.sideBarWidth - dimensions.pagePadding - CategoriesWidth - Padding}
                    height={windowDimensions.height - dimensions.headerHeight - (props.haveTabs ? dimensions.pageWithTabsPadding : dimensions.pagePadding)*2}
                    headerHeight={50}
                    touchScrollEnabled={true}
                >
                <Column
                        header={<Cell>Category</Cell>}
                        columnKey="category"
                        cell={<TextCell data={questions} small />}
                        width={200}
                    />
                    <Column
                        header={<Cell>Question</Cell>}
                        columnKey="question"
                        cell={<TextCell data={questions} small />}
                        width={400}
                    />
                    {category == "Fraudulent Check" && 
                        <Column
                            header={<Cell>Score</Cell>}
                            columnKey="score"
                            cell={<TextCell data={questions} />}
                            width={100}
                        />        
                    }
                    <Column
                        header={<Cell>Level</Cell>}
                        columnKey="level"
                        cell={<TextCell data={questions} />}
                        width={200}
                    />
                    <Column
                        header={<Cell>References</Cell>}
                        columnKey="reference"
                        cell={<TextCell data={questions} />}
                        width={200}
                    />
                    {(category == "Build Ready" || category == "Pre Build" || category == "Job") && 
                        <Column
                            header={<Cell>Job Warning</Cell>}
                            columnKey="warning_ind"
                            cell={<BooleanCell data={questions} />}
                            width={125}
                        />        
                    }
                    <Column
                        header={<Cell>Actions</Cell>}
                        cell={
                            <EditActionsCell 
                                data={questions} 
                                actions={[
                                    {action:"edit", title:"Edit", variant:"warning", icon:faPencilAlt, permission:Permissions.QAChecklists, permissionAction:Actions.Edit},
                                    {action:"delete", title:"Delete", variant:"danger", icon:faTrash, permission:Permissions.QAChecklists, permissionAction:Actions.Delete},
                                ]}
                                busy={busyRow}
                                onClick={onAction} 
                            />
                        }
                        align="center"
                        width={110}
                    />
                </Table>
            }
        </Interface>
        {(selected && selected.mode == "question") && 
            <QaQuestionDrawer 
                type={category}
                question={selected.question}
                show={true}
                products={products}
                parts={parts}
                shippingMethods={shippingMethods}
                onSaved={(question) => {
                    setQuestions(produce(draft => {
                        const index = draft.findIndex(q => q.id == question.id);

                        if (index == -1)
                            draft.push(question);
                        else
                            draft[index] = question;
                    }));
                    setSelected(null);
                }}
                onHide={() => setSelected(null)}
            />
        }
    </>
    );
}

export default QaPanel;