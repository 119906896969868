import React from "react";
import { useHistory } from "react-router-dom";

import Content from "../components/content"
import PageHeader from "../components/page-header";

import PageLayout from "../components/page-layout";
import UploadButton from "../components/buttons/button-upload";
import GoogleImage from "../components/google-image";

import { useGetRecentSignLogos } from "../api/queries/signlogos";
import { useDeleteSignLogo } from "../api/mutations/signlogos";
import ButtonAction from "../components/buttons/button-action";
import ButtonMutate from "../components/buttons/button-mutate";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function SignLogosPanel(props) {
    const history = useHistory();

    const logosQuery = useGetRecentSignLogos();
    const logos = logosQuery.data;

    const deleteLogo = useDeleteSignLogo();

    return (
        <div className="flex flex-wrap gap-2">
            <div className="grid w-[200px] h-full p-10 border !border-dashed border-gray-400 rounded">
                <div className="flex flex-col gap-5">
                    <UploadButton
                        extensions={["png","jpg"]}
                        caption="Upload File"
                        uploadUrl="signs/uploadlogo"
                        thumbnail={true}
                        thumbnailOptions={{
                            thumbnail: true,
                            preview: false,
                        }}
                        onUpload={(data) => {
                            history.push("/signlogos/" + data.signlogo_id);
                        }}
                    />
                </div>
            </div>

            {logos?.map(logo => (
                <button 
                    key={logo.id} 
                    className="w-[200px] border !border-dashed border-gray-400 rounded hover:bg-gray-200 relative"
                    onClick={() => history.push("/signlogos/" + logo.id)}
                >
                    <GoogleImage root src={logo.logo_url_preview || logo.logo_url} style={{
                        width: "200px",
                        height: "100px",
                        objectFit: "contain"
                    }} />

                    <ButtonMutate
                        icon={faTrash}
                        variant="solid-danger"
                        size="icon"
                        className="absolute -right-1 -top-1"
                        mutation={deleteLogo}
                        mutationData={logo.id}
                        confirmation="Delete this Sign Logo?"
                        capture={true}
                    />
                </button>
            ))}
        </div>                   
    );
}

export default SignLogosPanel;