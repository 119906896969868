import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faRss, faSpinner } from '@fortawesome/free-solid-svg-icons'

import TextField from "../components/fields/field-text"
import TextAreaField from "../components/fields/field-textarea"
import UrlField from "../components/fields/field-url"
import HtmlField from "../components/fields/field-html"
import CategoryField from "../components/fields/field-category"
import ListField from "../components/fields/field-list"

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import { postData } from "../common/services/server"
import { removeNulls } from "../helpers/utility";
import Loading from "../components/loading";

const CheckboxList = styled.div`
    padding: 10px;
    font-size: 14px;
    margin-bottom: 8px;
    max-height: 400px;
    overflow: auto;
`

function BlogPostDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(props.post);
  const [validated, setValidated] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [savingNewCategory, setSavingNewCategory] = useState(false);

  let blankPost = {
    products: [],
    parts: [],
    shippingMethods: [],
  }

  useEffect(
    () => {
        postData("categories/data?type=blog", {},
          function(result) {
            setCategories(result);
          },
          function(error) {
            alert("Error loading categories")
          }
        );      
    }, 
    []
  );

  useEffect(
    () => {
        let post = props.post;
        let data = {
            ...post,
            products: [],
            parts: [],
            shippingMethods: [],
            categoryIds: []
        }

      if (post) {
        if (post.products) {
            const productIds = post.products.split(",")

            for (const productId of productIds) {
                const prod = props.products.find(p => p.id == productId.trim());

                if (prod)
                    data.products.push(""+prod.id);
            }
        } 
        if (post.parts) {
            const partIds = post.parts.split(",")

            for (const partId of partIds) {
                const part = props.parts.find(p => p.id == partId.trim());

                if (part)
                    data.parts.push(""+part.id);
            }
        } 
        if (post.shippingMethiods) {
            const shippingMethiodIds = post.shippingMethiods.split(",")

            for (const shippingMethiodId of shippingMethiodIds) {
                const method = props.shippingMethiods.find(p => p.id == shippingMethiodId.trim());

                if (method)
                    data.shippingMethods.push(""+method.id);
            }
        } 
        if (post.category_ids) {
          data.categoryIds = post.category_ids.split(",");
        }
      } 
      else data = blankPost;

      setFormData(JSON.parse(JSON.stringify(data)));
    }, 
    [props.post]
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("blog/save", removeNulls(formData), 
        function(response) {
          props.onSaved(response);
        },
        function(error) {
          alert("An error occured saving the blog post.");
        },
        function() {
          setIsProcessing(false);
        }
      );
    }
  }

  function saveNewCategory() {
    setSavingNewCategory(true);

    postData("categories/save?type=blog", { name: newCategory.toLowerCase().replace(/\ /g, "_"), title: newCategory }, 
      function(response) {
        setCategories(produce(draft => {
          draft.push(response);
        }))

        setNewCategory("");
      },
      function(error) {
        alert("An error occured saving the new category.");
      },
      function() {
        setSavingNewCategory(false);
      }
    );    
  }

  function handleFormChange(event) {
    if (event.persist) event.persist();

    let field = event.target.name;
    let value = event.target.value;

    console.log("handleFormChange", field, value)
    //console.log("handleFormChange", event.target.name, event.target.value)
    setFormData(produce(draft => {
      draft[field] = value;

      if (field == "level") {
        if (value != "Product")
          draft.products = [];
        if (value != "Part")
          draft.parts = [];
        if (value != "Shipping Method")
          draft.shippingMethods = [];
      }

    }));
  }

  function handleFileUpload(file) {
      console.log("handleFileUpload", file)
    //console.log("handleFormChange", event.target.name, event.target.value)
    setFormData(produce(draft => {
      console.log("attachments", draft.attachments, draft.attachments)
      draft.attachments = draft.attachments + (draft.attachments.length?",":"") + file.id;
    }));
  }

  function onAttachmentDeleted(file, postData) {
    // pass it up the chain
    props.onPostChanged(postData)

    // force a refresh
    //setFormData(postData);
  }

  function onProductChange(e) {
    const product = e.target.value;
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.products ? draft.products.indexOf(product) : -1;

        if (index != -1)
            draft.products.splice(index, 1);
        if (!draft.products)
            draft.products = [];
        if (checked)
            draft.products.push(product);
    }));
  }
  function onPartChange(e) {
    const part = e.target.value;
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.parts ? draft.parts.indexOf(part) : -1;

        if (index != -1)
            draft.parts.splice(index, 1);
        if (!draft.parts)
            draft.parts = [];
        if (checked)
            draft.parts.push(part);
    }));
  }
  function onShippingMethodChange(e) {
    const method = e.target.value;
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.shippingMethods ? draft.shippingMethods.indexOf(method) : -1;

        if (index != -1)
            draft.shippingMethods.splice(index, 1);
        if (!draft.shippingMethods)
            draft.shippingMethods = [];
        if (checked)
            draft.shippingMethods.push(method);
    }));
  }

  function onCategoryChange(e) {
    const categoryId = e.target.value;
    const checked = e.target.checked;

    setFormData(produce(draft => {
        const index = draft.categoryIds ? draft.categoryIds.indexOf(categoryId) : -1;

        if (index != -1)
            draft.categoryIds.splice(index, 1);
        if (!draft.categoryIds)
            draft.categoryIds = [];
        if (checked)
            draft.categoryIds.push(categoryId);
    }));
  }

  const { post } = props;

  return (
      <Modal show={props.show} onHide={props.onHide} autoFocus={false} enforceFocus={false} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faRss} />
            &nbsp;
            Blog Post
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>

            {false && <CategoryField 
                name="category_id" 
                label="Category" 
                type="blog"
                allowNew={true}
                value={formData.category_id} 
                onChange={(e) => handleFormChange(e)} />
            }
            <TextField 
                name="title" 
                label="Post Title" 
                required 
                value={formData.title} 
                onChange={(e) => handleFormChange(e)} />
            <TextAreaField 
                name="description" 
                label="Description" 
                rows={3}
                required 
                value={formData.description} 
                onChange={(e) => handleFormChange(e)} />
            <HtmlField 
                name="summary" 
                label="Summary" 
                value={formData.summary} 
                onChange={(e) => handleFormChange(e)}/>                
            <HtmlField 
                name="content" 
                label="Content" 
                value={formData.content} 
                onChange={(e) => handleFormChange(e)}/>
            <TextAreaField 
                name="keywords" 
                label="Keywords" 
                rows={2}
                value={formData.keywords} 
                onChange={(e) => handleFormChange(e)} />
            <TextAreaField 
                name="seo" 
                label="SEO" 
                rows={3}
                value={formData.seo} 
                onChange={(e) => handleFormChange(e)} />

            <div>Categories</div>
            <div>
              {(categories.length > 0) ?       
                <>
                  {categories.map(category => (
                        <div key={category.id}>
                            <Form.Check 
                                name="category"                                
                                defaultValue={category.id}
                                checked={formData.categoryIds && formData.categoryIds.indexOf(""+category.id) != -1}
                                inline 
                                label={category.title} 
                                type="checkbox" 
                                onChange={onCategoryChange}
                            />
                        </div>
                  ))}  
                  
                  <InputGroup style={{marginTop: "10px"}}>
                    <InputGroup.Prepend>
                      <InputGroup.Text>New Category</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl 
                        as="input" 
                        type="text" 
                        name="newcategory" 
                        disabled={savingNewCategory}
                        value={newCategory} 
                        onChange={(e) => { 
                          setNewCategory(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                saveNewCategory();
                            }
                        }}
                    />         
                    <InputGroup.Append>
                      <Button onClick={saveNewCategory} disabled={newCategory.length == 0} variant="outline-success">
                        <FontAwesomeIcon icon={savingNewCategory ? faSpinner:faSave} spin={savingNewCategory} />{' '}
                        Save
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </>
              :
                <Loading />
              }
            </div>

            <br />

            <ListField 
                name="level" 
                label="Level" 
                instructions="Level controls when the content is chosen for display."
                value={formData.level} 
                list={[
                    {value: "Product", label: "Product"},
                    {value: "Part", label: "Part"},
                    {value: "Shipping Method", label: "Shipping Method"},
                ]}
                onChange={(e) => handleFormChange(e)} 
            />

            {formData.level == "Product" && 
                <CheckboxList>
                {props.products.map(product => (
                    <div key={product.id}>
                        <Form.Check 
                            name="product"                                
                            defaultValue={product.id}
                            checked={formData.products && formData.products.indexOf(""+product.id) != -1}
                            inline 
                            label={product.title} 
                            type="checkbox" 
                            onChange={onProductChange}
                        />
                    </div>
                ))}
                </CheckboxList>
            }
            {formData.level == "Part" && 
                <CheckboxList>
                {props.parts.map(part => (
                    <div key={part.id}>
                        <Form.Check 
                            name="part"                                
                            defaultValue={part.id}
                            checked={formData.parts && formData.parts.indexOf(""+part.id) != -1}
                            inline 
                            label={part.title} 
                            type="checkbox" 
                            onChange={onPartChange}
                        />
                    </div>
                ))}
                </CheckboxList>
            }
            {formData.level == "Shipping Method" && 
                <CheckboxList>
                {props.shippingMethods.map(method => (
                    <div key={method.id}>
                        <Form.Check 
                            name="shippingmethod"                                
                            defaultValue={method.id}
                            checked={formData.shippingMethods && formData.shippingMethods.indexOf(""+method.id) != -1}
                            inline 
                            label={method.title} 
                            type="checkbox" 
                            onChange={onShippingMethodChange}
                        />
                    </div>
                ))}
                </CheckboxList>
            }

            {/* <FilesField 
                label="Attachments"
                files={post.files}
                deleteUrl={"blogpost/deletefile?post_id=" + post.id}
                onDelete={onAttachmentDeleted} />
            <UploadField
                name="attachments"
                uploadUrl="files/upload?path=files/blog"
                onUpload={(file) => handleFileUpload(file)}
                /> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <ButtonProcessing 
                processing={isProcessing}
                onClick={onSave} 
                variant="outline-success" 
                caption="Save Blog Post" 
                icon={faSave} /> 
            <Button variant="outline-secondary" onClick={props.onHide}>
                Cancel
            </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default BlogPostDrawer;