import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutate } from '../api';

export const useMarkNotificationViewed = (options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: (notificationId) => {
            return mutate("notifications/markviewed", {
                id: notificationId
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["notifications"]);

            if (onSuccess)
                onSuccess();
        },    
        ...otherOptions
  })
};

export const useMarkAllNotificationsViewed = (options={}) => {  
    const queryClient = useQueryClient()

    const {onSuccess, ...otherOptions} = options;

    return useMutation({
        mutationFn: () => {
            return mutate("notifications/markallviewed", {
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["notifications"]);

            if (onSuccess)
                onSuccess();
        },    
        ...otherOptions
  })
};

