import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import produce from "immer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faBook } from '@fortawesome/free-solid-svg-icons'

import Button from "../components/buttons/button";
import ButtonProcessing from "../components/buttons/button-processing"
import ListField from "../components/fields/field-list"
import UploadField from "../components/fields/field-filesupload"

import { postData } from "../common/services/server"
import { removeNulls } from '../helpers/utility'
import GoogleImage from "../components/google-image";

function ProductPermitDrawer(props) {
  const formRef = useRef(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  useEffect(
    () => {
        let permit = props.permit;
        let data = {
            ...permit,
            part_ids: permit.part_ids ? permit.part_ids.split(",").map(p => parseInt(p)) : []
        }
      console.log("formData", data);

      setFormData(JSON.parse(JSON.stringify(data)));
    }, 
    []
  );

  function onValidate(event) {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  }

  function onSave() {
    // force validation
    formRef.current.dispatchEvent(new Event("submit"));
    // good to go?
    if (formRef.current.checkValidity()) {
      setIsProcessing(true);

      postData("products/savepermit", removeNulls({
             ...formData,
             parts: formData.part_ids.join(",")
        }), 
        function(response) {
            props.onSaved(response);
        },
        function(error) {
            alert("An error occured saving the permit.");
        },
        function() {
            setIsProcessing(false);
        }
      );
    }
  }

  return (
      <>
        <Modal show={true} onHide={props.onHide} size="lg" scrollable backdropClassName="drawer" dialogClassName="drawer">
            <Modal.Header closeButton>
            <Modal.Title>
                <FontAwesomeIcon icon={faBook} />
                &nbsp;
                {props.permit.id ? "Update":"Create"} Permit
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={(e) => onValidate(e)} ref={formRef}>
                    <GoogleImage
                        root 
                        src={formData.preview_url}
                        style={{
                            maxHeight: "80px"
                        }}
                    />

                    <UploadField
                        name="attachments"
                        variant="simple"
                        autoClear
                        uploadUrl={"products/uploadpermit?id=" + props.product.id}
                        onUpload={(file) => {
                            setFormData(produce(draft => {
                                draft.file_id = file.id;
                                draft.preview_url = file.url;
                            }));
                        }}
                    />

                    <br />
                    <br />

                    <ListField 
                        type="checkbox"
                        direction="vertical"
                        name="part_ids" 
                        label="Product Parts" 
                        instructions="The parts on the product that are control when this permit is used."
                        value={formData.part_ids} 
                        list={props.product.parts.map(p => {
                            return {
                                value: p.id, 
                                label: p.title
                            }
                        })}
                        onChange={(e) => {
                            setFormData(produce(draft => {
                                draft.part_ids = e.target.value;
                            }))
                        }} 
                    />  
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonProcessing 
                    processing={isProcessing}
                    onClick={onSave} 
                    disabled={!formData.file_id}
                    variant="outline-success" 
                    caption="Save Permit" 
                    icon={faSave} /> 
                <Button variant="outline-secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
      </>
    )
}

export default ProductPermitDrawer;