import React from "react"
import clsx from 'clsx';

export default function Badge({
    value,
    variant,
    size,
    className,
    onClick,
    children
}) {
    return (
        <div 
            className={clsx(
                "rounded whitespace-nowrap text-center",

                size == "tiny" ? 
                    "text-xs p-[2px]" : 
                size == "sm" ? 
                    "text-xs p-1" : 
                size == "lg" ? 
                    "p-3" : 
                    "text-sm py-1 px-2",

                variant == "success" && "bg-green-500 text-white",
                variant == "primary" && "bg-blue-500 text-white",
                variant == "secondary" && "bg-gray-500 text-white",
                variant == "light" && "bg-gray-200 text-black",
                variant == "warning" && "bg-yellow-500 text-white",
                variant == "danger" && "bg-red-500 text-white",

                variant == "positive-success" && (value > 0 ? "bg-green-500":"bg-gray-300"),

                onClick && "cursor-pointer",
                
                className,
            )}
            onClick={(e) => {
                e.stopPropagation();

                if (onClick) {
                    onClick(e);
                }
            }} 
        >
            {value != undefined ? value : children}
        </div>
    );
}