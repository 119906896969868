import React, { useState, useEffect } from "react";
import { produce } from "immer";
import { useParams, useHistory } from "react-router-dom";
import { faDesktop, faFont, faHourglass, faList, faShapes } from "@fortawesome/free-solid-svg-icons";

import PageHeader from "../components/page-header";
import Content from "../components/content"
import CardLayout, { CardLayoutPanel } from "../components/cardlayout";
import TabList from "../components/tabs";

import Loading from "../components/loading";
import Error from "../components/error";
import Alert from "../components/alert";
import SignDetailsPanel from "../panels/sign-details";
import SignGroupsPanel from "../panels/sign-groups";
import SignBuilderPanel from "../panels/panel-sign-builder";
import SignHistoryPanel from "../panels/sign-history";
import { Permissions } from "../common/services/auth";
import { useGetSign } from "../api/queries/signs";

import { useAddGroupToSign} from "../api/mutations/signs";
import SignHeaderPanel from "../panels/sign-header";
import PageLayout from "../components/page-layout";

export default function SignPage({embedded}) {
    const history = useHistory();
    let { tab, id } = useParams();

    if (!tab) tab = "overview";

    const signQuery = useGetSign(id, {
        include_orderids: 1,
        include_jobids: 1,
    });
    const sign = signQuery.data;

    const [loadingTab, setLoadingTab] = useState("");
    const [tabError, setTabError] = useState(null);

    const [cards, setCards] = useState([]);
    const [tabs, setTabs] = useState([
        {name: "overview", title: "Details", icon: faList, component: SignDetailsPanel},
        {name: "groups", title: "Groups", icon: faShapes, component: SignGroupsPanel},
        {name: "builder", title: "Builder", icon: faDesktop, component: SignBuilderPanel},
        {name: "history", title: "History", icon: faHourglass, component: SignHistoryPanel},
    ]);
    const [selectedTab, setSelectedTab] = useState(null);
    
    const addSignGroup = useAddGroupToSign(id, {
        onError: () => window.alert("Error updating the sign")
    });

    useEffect(
        () => {
            console.log("sign changed")
            if (!sign) return;

            if (sign.custom_ind == 1) {
                setTabs(produce(draft => {
                    draft.find(t => t.name == "builder").disabled = true;
                    // TODO: Add cabinet options?
                    draft.find(t => t.name == "groups").menu = [
                        {name: "addletters", caption: "Add Letters Group", icon:faFont},
                        {name: "addshape", caption: "Add Shape Group", icon:faShapes},
                    ];
                }));
            }

            // if (cards.length == 0) {
                setCards([
                    {type: "user", id: sign.user_id},
                    {type: "project", id: sign.project_id, hidden: !sign.project_id},
                    {type: "order", id: sign.order_ids, hidden: !sign.order_ids || sign.order_ids.includes(",")},
                    {type: "orders", sign_id: sign.id, hidden: !sign.order_ids || !sign.order_ids.includes(",")},
                    {type: "job", id: sign.job_ids, hidden: !sign.job_ids || sign.job_ids.includes(",")},
                    {type: "signs", project_id: sign.project_id, ignore_sign_id: sign.id, hidden: !sign.project_id},
                    {type: "notes", sign_id: sign.id},
                    {type: "files", sign_id: sign.id},
                    {type: "emails", sign_id: sign.id},
                ]);
            // }
        }, 
        [sign]
    );

    useEffect(
        () => {
            setSelectedTab(tabs.find(t => t.name == tab));
        }, 
        [tab]
    );

    const signIsDeleted = sign && sign.status == "Deleted";

    return (
        <PageLayout embedded={embedded}>
            <PageHeader pageTitle={`Sign Design #${id}`} />        
            <Content loading={signQuery.isLoading} permission={Permissions.Signs} error={signQuery.error} padding={1}>
                <>
                    {signIsDeleted ?
                        <div className="p-2">
                            <Alert variant="danger">Sign Is Deleted</Alert>
                        </div>
                    :sign ?
                        <CardLayout cards={cards} embedded={embedded}>
                            <SignHeaderPanel
                                onCloned={(newSign) => history.push("/signs/sign/" + newSign.id)}
                                signQuery={signQuery}
                            />

                            {/* <div className="flex gap-2 mb-2">
                                <ZoomableImage
                                    url={storage.root + sign.preview_url}
                                    maxHeight="150px"
                                    maxWidth="400px"
                                />
                                <div className="flex flex-col flex-1 gap-2">
                                    <h1 className="flex items-center gap-2 text-xl font-bold mb-2">
                                        {sign.custom_ind == 1 && "Custom "}
                                        Design #{sign.id}

                                        <DropdownMenu
                                            icon={faBars}
                                            className="text-gray-400"
                                            menu={[
                                                {name: "delete", caption: "Delete Sign", icon:faTrash, disabled:!canDelete, confirmation:"Delete this sign?"},
                                            ]}
                                            onMenuItem={(item) => {
                                                setLoadingTab("Deleting...");

                                                postData("signs/" + item.name, { 
                                                    id
                                                },
                                                    function() {
                                                        window.location.reload();
                                                    },
                                                    function(error) {
                                                        setTabError("Error deleting sign")
                                                    },
                                                    function() {
                                                        setLoadingTab("");
                                                    }
                                                );                                                        
                                            }}
                                        />
                                    </h1> 

                                    {(sign.estimate_expired_ind == 1) ? 
                                        <Alert 
                                            icon={faExclamationCircle} 
                                            size="sm" 
                                            variant="danger"
                                            className="!pl-2"
                                            button={
                                                <ButtonMutate
                                                    mutation={recalculateSignPrice}
                                                    mutationData={sign.id}
                                                    caption="Recalculate"
                                                    size="sm"
                                                    variant="danger"
                                                    disabled={!canEdit}
                                                />
                                            }
                                        >
                                            Sign price has expired.
                                        </Alert>
                                    : reCalcData ? 
                                        <Alert 
                                            size="sm"
                                            variant="outline-secondary"
                                        >
                                            New price is <strong>{formatPrice(reCalcData.estimate)}</strong>
                                            <span className={clsx(
                                                "ml-2",
                                                reCalcData.estimate > reCalcData.previous ? "text-green-500" :
                                                reCalcData.estimate < reCalcData.previous ? "text-red-500" : 
                                                "text-gray-500"
                                            )}>
                                                {reCalcData.estimate > reCalcData.previous ? "+" : ""}
                                                {(reCalcData.estimate - reCalcData.previous).toFixed(2)}
                                            </span>
                                        </Alert>
                                    :
                                      null
                                    }

                                    <div className="flex gap-1">
                                        {((sign.status == "Draft" || sign.estimate_approved_ind == 0) && userHasPermission(Permissions.Signs, Actions.Approve)) && 
                                            <ButtonMutate
                                                mutation={approveSign}
                                                mutationData={sign.id}
                                                confirmation="Approve this sign?"
                                                icon={faThumbsUp}
                                                size="sm"
                                                variant="danger"
                                            >
                                                Approve Sign
                                            </ButtonMutate>
                                        }
                                                                
                                        <Button 
                                            icon={faListAlt}
                                            onClick={(e) => {window.open(settings.SIGNDETAILS_URL + sign.uid); e.preventDefault()}} 
                                            href={settings.SIGNDETAILS_URL + sign.uid} 
                                            variant="outline-success" 
                                            size="sm"
                                        >
                                            Website Details
                                        </Button>

                                        {sign.custom_ind == 0 && 
                                            <Button 
                                                icon={faPencilAlt}
                                                onClick={(e) => {window.open(settings.SIGNEDIT_URL + sign.uid + "&template=-1"); e.preventDefault()}} 
                                                href={settings.SIGNEDIT_URL + sign.uid + "&template=-1"} 
                                                variant="outline-warning" 
                                                size="sm"
                                            >
                                                Website Builder
                                            </Button>
                                        }     
                                    </div>     

                                    {(sign.job_ids && sign.job_ids.length) && 
                                        <Alert size="sm" variant="danger" icon={faExclamationTriangle}>
                                            <div>
                                                <div>This sign is associated with the following jobs:</div>
                                                <div className="flex items-center gap-1 flex-wrap">
                                                    {sign.job_ids.split(",").map(jobId => (
                                                        <Link to={"/jobs/job/" + jobId} className="underline">
                                                            #{jobId}
                                                        </Link>
                                                    ))}
                                                </div>
                                            </div>
                                        </Alert>
                                    }                                                                  
                                </div>
                            </div> */}

                            <TabList 
                                tabs={tabs}
                                selectedTab={selectedTab}
                                tabBusy={addSignGroup.isLoading}
                                onTab={(tab) => {
                                    window.location.hash = `#/signs/sign/${tab.name}/${id}`;
                                    //setSelectedTab(tab);
                                }}
                                onTabMenuItem={(item) => {
                                    if (item.name.startsWith("add")) {
                                        addSignGroup.mutate(item.name.substring(3));
                                    }
                                }}
                            />

                            <CardLayoutPanel className="p-1 grid gap-1">
                                {(loadingTab.length > 0) ?
                                <Loading label={loadingTab} />   
                                : tabError ? 
                                    <Error message={tabError} />                             
                                : selectedTab ? 
                                    <selectedTab.component
                                        signId={sign.id}
                                    />        
                                :
                                null
                                }
                            </CardLayoutPanel>
                        </CardLayout>
                    :
                        null
                    }
                </>
            </Content>
        </PageLayout>
    );
}